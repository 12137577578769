import React from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';

export function getTabId(formId, nodeId) {
    return "_" + formId + "_" + nodeId.replace(/\./g, "_");
}

class TabHeader extends React.Component {

    selfRef;

    constructor(props) {
        super(props);

        this.selfRef = null;

        this.tabClick = this.tabClick.bind(this);
    }

    changeTab() {
        if (this.props.activeTab == this.props.nodeId) {
            return;
        }
        this.props.changeTab(this.props.nodeId);
    }

    tabClick(event) {
        event.preventDefault();
        this.changeTab();
    }

    showTab() {
        $("a", this.selfRef).tab("show");
    }

    componentWillReceiveProps(nextProps) {
        /* Tab was changed from reducer */
        if (nextProps.activeTab != this.props.activeTab && nextProps.activeTab == nextProps.nodeId) {
            this.showTab();
        }
    }

    componentDidMount() {
        this.selfRef = ReactDOM.findDOMNode(this);
        if (this.props.activeTab == this.props.nodeId) {
            this.showTab();
        }
    }

    render() {
        const id = getTabId(this.props.formId, this.props.nodeId);
        let cls = "";
        if (this.props.showErrors && this.props.error) {
            cls += " card-invalid-tab "
        } else if (this.props.activeTab == this.props.nodeId) {
            cls += " active";
        }
        const style = this.props.visible && !this.props.node.options.hidden ? null : { display: "none" };
        return (
            <li className="nav-item" style={style}>
                <a className={"nav-link" + cls} data-toggle="tab" href={`#${id}`} onClick={this.tabClick}>
                    <strong>{this.props.node.label}</strong>
                </a>
            </li>);
    }
}

export default TabHeader;