import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';

function operationFormatter(cell, row) {
    if (cell == 0) {
        return (<FormattedMessage
            id="SUBJECT_HISTORY_FRAGMENT_OPER_ADD"
            defaultMessage="Added"
            description="Fragment was added" />);
    }
    if (cell == 1) {
        return (<FormattedMessage
            id="SUBJECT_HISTORY_FRAGMENT_OPER_UPDATE"
            defaultMessage="Updated"
            description="Fragment was updated" />);
    }
    if (cell == 2) {
        return (<FormattedMessage
            id="SUBJECT_HISTORY_FRAGMENT_OPER_REMOVE"
            defaultMessage="Removed"
            description="Fragment was removed" />);
    }
    return "-";
}

class FragmentHistory extends React.Component {

    constructor(props) {
        super(props);
    }

    getPredicate(triple) {
        if (!triple.predicate) {
            return "-";
        }
        return triple.predicate.label;
    }

    getLabel(f) {
        if (!f.fragment.label) {
            return "-";
        }
        return f.fragment.label;
    }

    getDescription(f) {
        if (!f.fragment.description) {
            return "-";
        }
        return f.fragment.description;
    }

    render() {
        let tableData = this.props.data.map((f) => {
            return {
                id: f.id,
                name: this.getPredicate(f),
                label: this.getLabel(f),
                description: this.getDescription(f),
                operation: f.operation,
            }
        })
        return (
            <div>
                <h4><FormattedMessage
                    id="SUBJECT_HISTORY_FRAGMENT_HEADER"
                    defaultMessage="Fragment changes:"
                    description="Fragment changes table header" />
                </h4>
                <BootstrapTable version='4'data={tableData} striped={true} hover={true}>
                    <TableHeaderColumn isKey={true} hidden={true} dataField='id'>ID</TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='name'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_FRAGMENT_PREDICATE_NAME"
                            defaultMessage="Name"
                            description="Name of predicate that has been changed" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='label'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_FRAGMENT_LABEL"
                            defaultMessage="Label"
                            description="Fragment label" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='description'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_FRAGMENT_DESCRIPTION"
                            defaultMessage="Description"
                            description="Fragment description" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='operation' dataFormat={operationFormatter}>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_FRAGMENT_OPERATION"
                            defaultMessage="Operation"
                            description="Fragment operation" />
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default FragmentHistory;


