/* Validate string with allowed special symbols that specified in regExp */
export function getValidCronValue({ value, type }) {
    const standartRegExp = /[^0-9\,\*\-\/]/g;
    const monthDayRegExp = /[^0-9LW\?\,\*\-\/]/g;
    const weekDayRegExp = /[^0-9L#\?\,\*\-\/]/g;

    let regExp;
    switch (type) {
        case "second":
        case "minute":
        case "hour":
        case "month":
            regExp = standartRegExp;
            break;
        case "day":
            regExp = monthDayRegExp;
            break;
        case "weekDay":
            regExp = weekDayRegExp;
            break;
        default:
            return value;
    }

    return value.replace(regExp, "");
}

function isValidNumber({ value, minValue, maxValue }) {
    let number = Number(value);
    if (isNaN(number)) {
        return false;
    }
    if (number < minValue || number > maxValue) {
        return false;
    }
    return true;
}

/* Returns -1 if haven't, 0 if have one and 1 if have more than one */
function containsCharacter(value, character) {
    let firstIndex = value.indexOf(character);
    if (firstIndex == -1) {
        return -1;
    }
    let lastIndex = value.lastIndexOf(character);
    if (firstIndex == lastIndex) {
        return 0;
    }
    return 1;
}

function getSeparatedStringWithL(value) {
    let repeatSeparated = value.split("-");
    if (repeatSeparated.length == 1) {
        return repeatSeparated;
    }

    let isContainsL = {};
    let matchL = 0;
    for (let i = 0; i < repeatSeparated.length; ++i) {
        if (repeatSeparated[i] == "") {
            return null;
        }
        isContainsL[i] = repeatSeparated[i].indexOf("L") >= 0;
        if (isContainsL[i]) {
            ++matchL;
        }
    }

    if (matchL > 2 || repeatSeparated.length - matchL > 2) {
        return null;
    }

    for (let i = repeatSeparated.length - 2; i >= 0; --i) {
        if (isContainsL[i] && !isContainsL[i + 1]) {
            repeatSeparated.splice(i, 2, [repeatSeparated[i], repeatSeparated[i + 1]].join("-"));
        }
    }
    console.log(repeatSeparated);

    return repeatSeparated;
}

function validateSeparatedPartWithL({ value, minValue, maxValue }) {
    /* Split value and check if numbers at it sides is correct*/
    let repeatL = value.split("L");
    if (repeatL[0] != "" && !isValidNumber({ value: repeatL[0], minValue, maxValue })) {
        return false;
    }
    /* Value after L, if exist, must have unary minus */
    if (repeatL[1] != "") {
        if (repeatL[1].indexOf("-") == -1 || !isValidNumber({ value: repeatL[1].replace("-", ""), minValue, maxValue })) {
            return false;
        }
    }
    return true;
}

function validateSingleCronValue({ value, minValue, maxValue, type }) {
    /* Empty value */
    if (value.length == 0) {
        return false;
    }
    /* Value "all cases" */
    if (value == "*") {
        return true;
    }
    let number = Number(value);
    if (!isNaN(number)) {
        if (number < minValue || number > maxValue) {
            return false;
        }
        return true;
    }
    let repeatSeparated;
    switch (type) {
        case "monthDay":
            repeatSeparated = getSeparatedStringWithL(value);
            if (repeatSeparated == null || repeatSeparated.length > 2) {
                return false;
            }
            for (let repeatPart of repeatSeparated) {
                let containsL = containsCharacter(repeatPart, "L");
                /* L must be single in value */
                if (containsL == 1) {
                    return false;
                }
                let containsW = containsCharacter(repeatPart, "W");
                /* W must be single in value */
                if (containsW == 1) {
                    return false;
                }
                if (containsW == 0) {
                    /* LW */
                    if (containsL == 0) {
                        if (repeatPart == "LW") {
                            continue;
                        } else {
                            return false;
                        }
                    }
                    /* W must be last character in value */
                    if (repeatPart.charAt(repeatPart.length - 1) != "W") {
                        return false;
                    }
                    /* Value before W must be valid number */
                    if (!isValidNumber({ value: repeatPart.replace("W", ""), minValue, maxValue })) {
                        return false;
                    }
                    continue;
                }
                /* Value doesn't have 'W' character */

                /* Value doesn't have 'L' character either - must be valid number*/
                if (containsL == -1) {
                    if (isValidNumber({ value: repeatPart, minValue, maxValue })) {
                        continue;
                    } else {
                        return false;
                    }
                }

                if (!validateSeparatedPartWithL({ value: repeatPart, minValue, maxValue })) {
                    return false;
                }
            }
            break;
        case "weekDay":
            repeatSeparated = getSeparatedStringWithL(value);
            if (repeatSeparated == null || repeatSeparated.length > 2) {
                return false;
            }
            for (let repeatPart of repeatSeparated) {
                let containsL = containsCharacter(repeatPart, "L");
                if (containsL == 1) {
                    return false;
                }
                let containsHash = containsCharacter(repeatPart, "#");
                if (containsHash == 1) {
                    return false;
                }

                if (containsHash == 0) {
                    /* Contains both L and # - error */
                    if (containsL == 0) {
                        return false;
                    }

                    /* Split value and check if numbers at it sides is correct*/
                    let repeatHash = repeatPart.split("#");
                    if (isValidNumber({ value: repeatHash[0], minValue, maxValue })
                        && isValidNumber({ value: repeatHash[1], minValue, maxValue: 5 })) {
                        continue
                    } else {
                        return false;
                    }
                }
                /* Value doesn't have '#' character */

                /* Value doesn't have 'L' character either - must be valid number*/
                if (containsL == -1) {
                    if (isValidNumber({ value: repeatPart, minValue, maxValue })) {
                        continue;
                    } else {
                        return false;
                    }
                }

                if (!validateSeparatedPartWithL({ value: repeatPart, minValue, maxValue })) {
                    return false;
                }
            }
            break;
        default:
            repeatSeparated = value.split("-");
            if (repeatSeparated.length > 2) {
                /* Multiple dashes in one element */
                return false;
            }
            for (let repeatPart of repeatSeparated) {
                if (!isValidNumber({ value: repeatPart, minValue, maxValue })) {
                    return false;
                }
            }
    }
    return true;
}

function validateCronValue({ value, minValue, maxValue, type }) {
    let indexOfAsterisk = value.indexOf("*");
    if (indexOfAsterisk > 0) {
        return false;
    }
    if (value.indexOf("?") > 0) {
        return false;
    }
    /* Get repeated values */
    let repeatSeparated = value.split("/");
    if (indexOfAsterisk != -1 && (repeatSeparated[0].indexOf("*") == -1 || repeatSeparated[0].length > 1)) {
        return false;
    }
    switch (repeatSeparated.length) {
        case 2:
            /* Value after separator must be numeric */
            if (!isValidNumber({ value: repeatSeparated[1], minValue, maxValue })) {
                return false;
            }
        case 1:
            if (!validateSingleCronValue({ value: repeatSeparated[0], minValue, maxValue, type })) {
                return false;
            }
            break;
        default:
            /* Multiple separators in one element */
            return false;
    }
    return true;
}

function validateSecondElement(element) {
    return validateCronValue({ value: element, minValue: 0, maxValue: 59, type: "standart" });
}

function validateMinuteElement(element) {
    return validateCronValue({ value: element, minValue: 0, maxValue: 59, type: "standart" });
}

function validateHourElement(element) {
    return validateCronValue({ value: element, minValue: 0, maxValue: 23, type: "standart" });
}

function validateDayElement(element) {
    return validateCronValue({ value: element, minValue: 1, maxValue: 31, type: "monthDay" });
}

function validateMonthElement(element) {
    return validateCronValue({ value: element, minValue: 1, maxValue: 12, type: "standart" });
}

function validateWeekDayElement(element) {
    return validateCronValue({ value: element, minValue: 1, maxValue: 7, type: "weekDay" });
}

export function validateCronStringElement({ value, type }) {
    if (value.length == 0) {
        return false;
    }

    let validator;
    switch (type) {
        case "second":
            validator = validateSecondElement;
            break;
        case "minute":
            validator = validateMinuteElement;
            break;
        case "hour":
            validator = validateHourElement;
            break;
        case "day":
            if (value == "?") {
                return true;
            }
            validator = validateDayElement;
            break;
        case "month":
            validator = validateMonthElement;
            break;
        case "weekDay":
            if (value == "?") {
                return true;
            }
            validator = validateWeekDayElement;
            break;
        default:
            console.error(`Unknown cron type '${type}'`);
            return false;
    }

    let allowedValue = getValidCronValue({ value, type });
    /* Check if value contains only allowed symbols */
    if (allowedValue != value) {
        return false;
    }

    /* Break string to substrings via comma and parse each */
    let commaSeparated = value.split(",");
    for (let commaPart of commaSeparated) {
        /* Empty value */
        if (commaPart.length == 0) {
            return false;
        }
        if (!validator(commaPart)) {
            return false;
        }
    }
    return true;
}




export function validateLabel({ label }) {
    return label.length != 0;
}

export function validatePath({ path }) {
    if (path == null || path.length == 0) {
        return false;
    }
    return true;
}

function validateTaskCronValues({ task }) {

    if (!validateCronStringElement({ value: task.second, type: "second" })) {
        return false;
    }
    if (!validateCronStringElement({ value: task.minute, type: "minute" })) {
        return false;
    }
    if (!validateCronStringElement({ value: task.hour, type: "hour" })) {
        return false;
    }
    if (!validateCronStringElement({ value: task.day, type: "day" })) {
        return false;
    }
    if (!validateCronStringElement({ value: task.month, type: "month" })) {
        return false;
    }
    if (!validateCronStringElement({ value: task.weekDay, type: "weekDay" })) {
        return false;
    }

    return true;;
}

export function validateMonthAndWeekDay({ monthDay, weekDay }) {
    if (monthDay == "?") {
        if (weekDay == "?") {
            return false;
        }
    } else {
        if (weekDay != "?") {
            return false;
        }
    }
    return true;
}

export function validateDescription({ description }) {
    return description.length != 0;
}

export function validateTask({ task }) {
    if (!validateLabel({ label: task.label })) {
        return false;
    }
    if (!validatePath({ path: task.scriptPath })) {
        return false;
    }
    if (!validateTaskCronValues({ task })) {
        return false;
    }
    if (!validateMonthAndWeekDay({ monthDay: task.day, weekDay: task.weekDay })) {
        return false;
    }
    if (!validateDescription({ description: task.description })) {
        return false;
    }
    return true;
}