import React from 'react';
import { FormattedMessage } from 'react-intl';

import { printInheritanceTree, printClassInTree, getClassLayoutPath, getViewLink } from '../../services/classcard';

class Toolbar extends React.Component {

    constructor(props) {
        super(props);

        this.editClass = this.editClass.bind(this);
        this.removeClass = this.removeClass.bind(this);
    }

    editClass() {
        this.props.editClass(this.props.classData);
    }

    removeClass() {
        this.props.removeClass(this.props.classData);
    }

    render() {
        let layoutLink = getViewLink(this.props.classData, this.props.contextPath);
        return [
            <div className="d-flex">
                <div className="row col-auto align-items-end" style={{ maxWidth: "60%" }}>
                    {printClassInTree(this.props.classData, null, this.props.contextPath)}
                </div>
                <div className="col"></div>
                <div className="btn-toolbar col-auto" role="toolbar" aria-label="...">
                    <div className="btn-group btn-group-xs btn-group-separated" role="group" aria-label="..." style={{ maxHeight: "1.85rem" }}>
                        {!this.props.classData.layout && <a className="btn btn-secondary" href={layoutLink}>
                            <i className="fa fa-eye fa-fw" aria-hidden="true"></i>
                            <FormattedMessage
                                id="CLASSCARD_ADD_LAYOUT"
                                defaultMessage="Add layout"
                                description="User should click the button to add layout" />
                        </a>}
                        <a className="btn btn-secondary" onClick={this.editClass}>
                            <i className="fa fa-pencil fa-fw" aria-hidden="true"></i>
                            <FormattedMessage
                                id="CLASSCARD_CLASS_EDIT"
                                defaultMessage="Edit class"
                                description="User should click the button to edit class" />
                        </a>
                        <a className="btn btn-secondary" onClick={this.removeClass}>
                            <i className="fa fa-times fa-fw" aria-hidden="true"></i>
                            <FormattedMessage
                                id="CLASSCARD_CLASS_DELETE"
                                defaultMessage="Delete class"
                                description="User should click the button to delete class" />
                        </a>
                    </div>
                </div>
            </div>,
            printInheritanceTree(this.props.classData, "parentList", "parentClassId", this.props.contextPath)
        ];
    }
}

export default Toolbar;


