import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class Toolbar extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className={"npt-table-toolbar"}>
            <form className={"form-inline col-md-12 nopadding"}>
                <div className="btn-group btn-group-xs">
                    <Button icon='plus' onClick={this.props.openEventModal.bind(this, null)}><FormattedMessage id="MSG_BUTTON_ADD" /></Button>
                    <Button icon='minus' disabled={this.props.selectedRows.length == 0} onClick={this.props.openDeleteModal}><FormattedMessage id="MSG_BUTTON_DELETE" /></Button>
                </div>
            </form>
        </div>;
    }
}

class Button extends React.PureComponent {
    render() {
        return <div className="form-group">
            <button type="button" className="btn btn-secondary" onClick={this.props.onClick} disabled={this.props.disabled}>
                <i className={"fa fa-fw fa-" + this.props.icon}></i>
                <span>
                    {this.props.children}
                </span>
            </button>
        </div>;
    }
}