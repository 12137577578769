import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import MaskedInput from './maskedinput.jsx';
import { MSG_SELECT_LOADING, MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS } from '../messages.jsx';
import { getFilename, getBasename } from '../../services/npt-treebeard';
import { allowedNodeSymbols } from '../../constants/srctree';

function getOptions(sourceTypes) {
    let options = [];
    for (let mime of sourceTypes) {
        options.push({ value: mime, label: mime });
    }
    return options;
}

const NEW_FILE = "new";

class CodePopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            path: props.path || "",
            mime: props.mime || "text/plain",
            description: props.description || ""
        }
        if (props.adding) {
            this.selectionOffset = this.props.startsWith.length;
            this.state.path += NEW_FILE;
            this.selectionWidth = NEW_FILE.length;
        } else {
            let base = getBasename(this.state.path);
            this.selectionOffset = base.length + 1;
            this.selectionWidth = this.state.path.length - this.selectionOffset;
        }
        this.mimeOptions = getOptions(props.sourceTypes);
    }

    inputChange(p, event) {
        this.propertyChange(p, event.target.value);
    }

    propertyChange(p, newValue) {
        this.setState({ [p]: newValue });
    }

    render() {
        return (
            <form role="form" className="form-horizontal col-md-12 nopadding">
                <div className="form-group row mb-2">
                    <label className="col-md-3 mb-0" for="pathInput">
                        <FormattedMessage id="SRC_EDITOR_PATH"
                            defaultMessage="Path"
                            description="Path of editing element" />
                    </label>
                    <div className="col-md-12">
                        <MaskedInput
                            className="form-control card-input"
                            id="pathInput"
                            value={this.state.path}
                            onChange={this.propertyChange.bind(this, "path")}
                            startsWith={this.props.startsWith || "/"}
                            allowedSymbols={"/" + allowedNodeSymbols}
                            initialSelectionStart={this.selectionOffset}
                            initialSelectionEnd={this.selectionOffset + this.selectionWidth}
                            initialFocus={true}
                        />
                    </div>
                </div>
                <div className="form-group row mb-2">
                    <div className="col-md-3">
                        <label className="mb-0" for="descInput">
                            <FormattedMessage id="SRC_EDITOR_MIME"
                                defaultMessage="MIME"
                                description="MIME type of editing element" />
                        </label>
                        <a className="fa fa-fw fa-question-circle" aria-hidden="true" style={{ cursor: "pointer" }} href="https://ru.wikipedia.org/wiki/%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA_MIME-%D1%82%D0%B8%D0%BF%D0%BE%D0%B2" target="_blank"></a>
                    </div>
                    <div className="col-md-12">
                        <Select
                            searchable="true"
                            loadingPlaceholder={MSG_SELECT_LOADING}
                            placeholder={MSG_SELECT_PLACEHOLDER}
                            noResultsText={MSG_SELECT_NO_RESULTS}
                            simpleValue
                            value={this.state.mime}
                            onChange={this.propertyChange.bind(this, "mime")}
                            clearable={false}
                            searchable={false}
                            className="minified-react-select card-input"
                            options={this.mimeOptions}></Select>
                    </div>
                </div>
                <div className="form-group row mb-2">
                    <label className="col-md-3 mb-0" for="descInput">
                        <FormattedMessage id="SRC_EDITOR_DESCRIPTION"
                            defaultMessage="Description"
                            description="Description of editing element" />
                    </label>
                    <div className="col-md-12">
                        <textarea className="form-control card-input" id="descInput" rows="3" value={this.state.description} onChange={this.inputChange.bind(this, "description")} style={{ resize: "none", width: "100%" }} />
                    </div>
                </div>
            </form>
        );
    }
}

export default CodePopup;


