import { connect } from 'react-redux';
import React from 'react';
import shortid from 'shortid';

import CardArea from './boxes/cardarea.jsx';
import Panel from './boxes/panel.jsx';
import Group from './boxes/group.jsx';
import Grid from './boxes/grid.jsx';
import GridCell from './boxes/gridcell.jsx';
import TabNav from './boxes/tabnav.jsx';
import Tab from './boxes/tab.jsx';
import TabHeader from './boxes/tabheader.jsx';

import Text from './inputs/text.jsx';
import TextArea from './inputs/textarea.jsx';
import File from './inputs/file.jsx';
import ComboBox from './inputs/combobox.jsx';
import ReferenceTable from './inputs/reftable.jsx';
import Checkbox from './inputs/checkbox.jsx';
import FragmentBox from './inputs/fragment.jsx';
import ObjectTable from './inputs/objtable.jsx';
import Label from './inputs/label.jsx';
import Description from './inputs/description.jsx';

import Image from './views/img.jsx';
import Table from './views/table.jsx';
import Card from './views/card.jsx';
import RPASetpoints from './views/rpasetpoints.jsx';
import Plugin from './views/plugin.jsx';

import Link from './utilities/link.jsx';
import Button from './utilities/button.jsx';


import { OBJECTCARD, UI_TEXT_AREA, UI_FLOAT, UI_INT, UI_DATE, UI_DATE_TIME, UI_LINK, UI_BUTTON } from '../../constants/objectcard';
import { LOCATION } from '../../constants/location';
import { SECURITY } from '../../constants/security';
import { I18N } from '../../constants/i18n';

import {
    fetchFragmentInitialNode,
    fetchFragmentChildren,
    change,
    link,
    add,
    remove,
    changeAt,
    patchUpload,
    registerUpload,
    ajaxFetchEnumeration,
    pasteRefs,
    checkRef,
    changeTab,
    click,
    linkClick
} from '../../actions/objectcard';

import { SAVE_STATE_SHOW_ERRORS } from '../../constants/objectcard';

import { openModal } from '../../actions/modal';

import {
    UI_CARD_AREA,
    UI_PANEL,
    UI_GROUP,
    UI_TEXT,
    UI_LABEL,
    UI_DESCRIPTION,
    UI_FILE,
    UI_GRID,
    UI_GRID_CELL,
    UI_TAB_NAV,
    UI_TAB,
    UI_TAB_HEADER,
    UI_CHECKBOX,
    UI_FRAGMENT,
    UI_COMBOBOX,
    UI_REF_TABLE,
    UI_OBJECT_TABLE,
    UI_IMAGE,
    UI_TABLE,
    UI_CARD,
    UI_RPA_SETPOINTS,
    UI_PLUGIN
} from '../../constants/objectcard';
import { addAlert } from '../../actions/alert.js';
import { ALERT_DANGER, ALERT_WARNING } from '../../constants/alert.js';


/*

console.log("Add node to tree: " + node);
        let type = this.props.library[node.ui];
        if (!type) {
            console.error("Unknown UI type: ", node.ui)
            return null;
        }
        const error = this.props.form.getError(node.id);
        if (error) {
            console.log("Invalid node: ", node, error);
            return null;
        }
        let props = {
            key: node.id,
            
            contextPath: this.props.contextPath,
            locale: this.props.locale,
            fragmentList: this.props.fragmentList,
            showErrors: this.props.showErrors,
            error, valid: error ? false : true
        };
        if (node.path) {
            props.editable = this.props.form.isEditable(node.path, node) && !node.systemDriven;
        }

        */

export function connectUi(Component) {
    return connect((_, initialProps) => { //mapStateToProps
        const { formId, nodeId, navId } = initialProps;
        return (globalState) => {
            const card = globalState[OBJECTCARD];
            const data = card.data[formId];
            const values = card.values[formId];
            const validation = card.validation[formId];
            const saveState = card.saveState[formId];
            const layout = data && data.$class ? card.layoutCache[data.$class] : null;
            const node = layout && layout.byId[nodeId] ? layout.byId[nodeId] : { id: nodeId, options: {}, mock: true };
            /*if (node.mock) {
                console.log("!!!Invalid layout:", nodeId, JSON.stringify(layout));
            }*/
            const valueId = node.options.src || nodeId; //Support for values from other other sources
            const value = values && values[valueId];
            const error = validation && validation[valueId];
            //Serverl lock (means editable)
            const serverLock = data && data.$lock; //card lock status
            const serverLockReady = serverLock && serverLock.status;
            //Automation lock (means not editable)
            const editLocks = card.lock[formId];
            const editLock = (editLocks && editLocks[valueId]) ? true : false;
            const systemDriven = node.options.src ? (layout && layout.byId[node.options.src] && layout.byId[nodeId].options.systemDriven) : node.options.systemDriven;
            const virtual = node.options.virtual;
            //Subject is new (means editable)
            const isNew = data && data.$isNew;
            //Value was bound in automation (means not editable)
            const valueBound = (layout && layout.automation && layout.automation.valueBindings[valueId]) ? true : false;
            const cardEditable = serverLockReady || isNew;
            const editable = (cardEditable && !editLock && !systemDriven && !virtual && !valueBound) ? true : false;
            const visibility = card.visibility[formId];
            const visible = (visibility && visibility[nodeId]) ? true : false;
            const valid = (!error || !cardEditable) ? true : false; //do not show errors while form is not editable
            const showErrors = (saveState && saveState == SAVE_STATE_SHOW_ERRORS) ? true : false;
            //Children ids
            const childrenIds = layout && layout.childrenIdsByParentId && layout.childrenIdsByParentId[nodeId];
            //Enumeration info
            const enumerationCls = node.ui == UI_COMBOBOX ? card.enumerationCls[node.options.cls] : null
            //Active tab
            const tabNavId = navId || node.options.navId || node.id;
            const tabs = card.tabs[formId];
            const activeTab = tabs && tabs[tabNavId] && tabs[tabNavId].active;
            return {
                //Main properties
                node, editable, value, layout, error, valid, visible, showErrors, childrenIds,
                serverLock, //Used for object table to edit item
                fragmentList: card.fragmentList, //TODO remove fragment list
                //Global properties
                locale: globalState[I18N].locale,
                contextPath: globalState[LOCATION].contextPath,
                superUser: globalState[SECURITY].superUser,
                generalAccessRules: globalState[SECURITY].generalAccessRules,
                data,
                values,
                //ComboBox properties
                enumerationCls,
                //Tab properties
                tabs, activeTab,
                //Fragments
                fragments: card.fragmentTree
            };
        }
    }, (_, initialProps) => {
        const { formId, nodeId, navId } = initialProps;
        return (dispatch) => {
            return {
                changeNode: function (data, options, nodeId) {
                    dispatch(change(formId, nodeId, data, options));
                },
                change: function (data, options) {
                    dispatch(change(formId, nodeId, data, options));
                },
                link: function (data) {
                    dispatch(link(formId, nodeId, data));
                },
                changeAt: function (index, data) {
                    dispatch(changeAt(formId, nodeId, index, data));
                },
                add: function (data) {
                    dispatch(add(formId, nodeId, data));
                },
                remove: function (index) {
                    dispatch(remove(formId, nodeId, index));
                },
                registerUpload: function (files, multiple) {
                    dispatch(registerUpload(formId, nodeId, files, multiple));
                },
                signalPredicateError: function (data, error) {
                    dispatch(changePredicateData(formId, nodeId, data, CHANGE_SET, { error }));
                },
                openModal: function (type, options, okCallback, cancelCallback, closeCallback) {
                    const modalId = shortid.generate();
                    dispatch(openModal(modalId, type, options, okCallback, cancelCallback, closeCallback));
                },
                openNamedModal: function (modalName, type, options, okCallback, cancelCallback, closeCallback) {
                    dispatch(openModal(modalName, type, options, okCallback, cancelCallback, closeCallback));
                },
                addAlert: function (msg) {
                    dispatch(addAlert(ALERT_DANGER, msg));
                },
                addWarning: function (msg) {
                    dispatch(addAlert(ALERT_WARNING, msg));
                },
                //Support for fragment selection in cards
                fetchFragmentList: function (fragmentRdfId) {
                    dispatch(fetchFragmentInitialNode(fragmentRdfId));
                },
                updateFragments: function (fragmentRdfId) {
                    dispatch(fetchFragmentChildren(fragmentRdfId));
                },
                click: function (nodeId) {
                    dispatch(click(formId, nodeId));
                },
                //Support for combobox dynamic enumerations loading
                fetchEnumeration: function (className) {
                    dispatch(ajaxFetchEnumeration(className));
                },
                //Support for references paste in ref-tables
                pasteRefs: function (relatedClass, isMultiple, isFromHistory, references) {
                    dispatch(pasteRefs(formId, nodeId, relatedClass, isMultiple, isFromHistory, references));
                },
                checkRef: function (relatedClass, reference, callback) {
                    dispatch(checkRef(relatedClass, reference, callback));
                },
                //Store active tab in state
                changeTab: function (tabId, specifiedNavId) {
                    dispatch(changeTab(formId, tabId, specifiedNavId || navId))
                },
                //Click on link element
                linkClick: function (link, filter) {
                    dispatch(linkClick(link, filter))
                }
            }
        }
    })(Component);
}

const typeMap = {
    [UI_CARD_AREA]: CardArea,
    [UI_PANEL]: Panel,
    [UI_GROUP]: Group,
    [UI_TEXT]: Text,
    [UI_FLOAT]: Text,
    [UI_INT]: Text,
    [UI_DATE]: Text,
    [UI_DATE_TIME]: Text,
    [UI_TEXT_AREA]: TextArea,
    [UI_FILE]: File,
    [UI_GRID]: Grid,
    [UI_GRID_CELL]: GridCell,
    [UI_TAB_NAV]: TabNav,
    [UI_TAB]: Tab,
    [UI_TAB_HEADER]: TabHeader,
    [UI_CHECKBOX]: Checkbox,
    [UI_FRAGMENT]: FragmentBox,
    [UI_COMBOBOX]: ComboBox,
    [UI_REF_TABLE]: ReferenceTable,
    [UI_OBJECT_TABLE]: ObjectTable,
    [UI_IMAGE]: Image,
    [UI_TABLE]: Table,
    [UI_CARD]: Card,
    [UI_LABEL]: Label,
    [UI_DESCRIPTION]: Description,
    [UI_RPA_SETPOINTS]: RPASetpoints,
    [UI_LINK]: Link,
    [UI_BUTTON]: Button,
    [UI_PLUGIN]: Plugin
}

const connectedTypeMap = {};


for (let ui in typeMap) {
    const type = typeMap[ui];
    const connectedType = connectUi(type);
    connectedTypeMap[ui] = connectedType;
}

console.log("CONNECTED UI init finished", connectedTypeMap);

/**
 * 
 */
export function createUi(ui, formId, nodeId) {
    const connectedType = connectedTypeMap[ui];
    if (!connectedType) {
        console.log("Unknown UI type:", ui);
        return null;
    }
    return connectedType;
}