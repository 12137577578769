import * as Action from '../constants/history';
import { SERVER_CONFIG } from '../constants/serverconfig';
import { correctServerTimestamp } from '../services/serverconfig';

function waitForHistoryData() {
    return {
        type: Action.WAIT_FOR_HISTORY_DATA,
        payload: null
    }
}

function receiveHistoryData(data, hashParams) {
    return {
        type: Action.RECEIVE_HISTORY_DATA,
        payload: { data, hashParams }
    }
}

function receiveErrorHistoryData(error) {
    return {
        type: Action.RECEIVE_ERROR_HISTORY_DATA,
        payload: { error }
    }
}

///////////
//Utility//
///////////
function parseHistoryData(data, timeDifference) {
    if (!data || !Array.isArray(data)) {
        return data;
    }
    for (let entry of data) {
        /* Server stores timestamps in UTC time but without 'z' in the end of timestamp */
        if (typeof entry.operationTimestamp == "string" && entry.operationTimestamp.toLowerCase()[entry.operationTimestamp.length - 1] != "z") {
            entry.operationTimestamp += "Z";
        }
        /* Time on server isn't synchronized with local pc and we must add time difference between them to display correct time */
        entry.operationTimestamp = correctServerTimestamp(entry.operationTimestamp, timeDifference);
    }
    return data;
}

///////////
//Actions//
///////////
export function fetchHistoryData(rdfId, hashParams) {
    return function (dispatch, getState) {
        //Fetch menu from server
        let url = `/rest/subject/history/${rdfId}`;
        if (rdfId.indexOf(":") >= 0) {
            let parts = rdfId.split(":");
            let ns = parts[0];
            rdfId = parts[1];
            url = `/rest/subject/history/${ns}/${rdfId}`;
        }
        dispatch(waitForHistoryData());
        $.get(url, function (data) {
            const timeDifference = getState()[SERVER_CONFIG].timeDifference;
            dispatch(receiveHistoryData(parseHistoryData(data, timeDifference), hashParams));
        }).fail(function (error) {
            dispatch(receiveErrorHistoryData(error));
        });
    }
}

export function hashChanged(hashParams) {
    return {
        type: Action.HASH_CHANGED,
        payload: { hashParams }
    }
}