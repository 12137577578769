import { connect, Provider } from 'react-redux';
import { LOCATION } from './constants/location.js';

import Player from './components/player/player.jsx';
import Gallery from './components/player/gallery.jsx';
import { parseGalleryElm } from './services/player.js';
import { changeHash } from './actions/player.js';

const ATTR_PLAYER = "npt-player";
const ATTR_PLAYER_ID = "id";
const ATTR_PLAYER_SRC = "src";
const ATTR_PLAYER_HASH_SRC_PARAM = "hash-src-param";
const ATTR_PLAYER_TYPE = "type";

const ATTR_GALLERY = "npt-video-gallery";

function hasAttr(elm, attr) {
    const value = $(elm).attr(attr);
    // For some browsers, `attr` is undefined; for others, `attr` is false. Check for both.
    if (typeof value !== typeof undefined && value !== false) {
        return true;
    }
    return false;
}

const PlayerContainer = connect(
    (globalState) => {
        return {
            hashParams: globalState[LOCATION].params
        };
    }
)(Player)

const GalleryContainer = connect(
    (globalState) => {
        return {
            hashParams: globalState[LOCATION].params
        };
    },
    (dispatch) => {
        return {
            changeHash:function (params){
                dispatch(changeHash(params));
            }
        }
    }
)(Gallery)

//Initialization function
export default (dispatcher) => {

    //Register components
    dispatcher.registerComponent(ATTR_PLAYER, (elm) => {
        let initialSrc = $(elm).attr(ATTR_PLAYER_SRC);
        let hashSrcParam = $(elm).attr(ATTR_PLAYER_HASH_SRC_PARAM);
        let type = $(elm).attr(ATTR_PLAYER_TYPE);
        let autoplay = hasAttr(elm, "autoplay");
        return (<PlayerContainer initialSrc={initialSrc} hashSrcParam={hashSrcParam} type={type} autoplay={autoplay} />);
    });

    //Register components
    dispatcher.registerComponent(ATTR_GALLERY, (elm) => {
        const id = $(elm).attr(ATTR_PLAYER_ID) || "videoId";
        const data = parseGalleryElm(elm);
        elm.innerHTML = "";
        return (<GalleryContainer data={data} id={id}/>);
    });
};

