import React from 'react';
import moment from 'moment';
import shortid from 'shortid';
import { FormattedMessage } from 'react-intl';
import DebounceInput from '../debounceinput.jsx';
import { obtainData } from '../../services/automation';
import { aclChecker } from '../../services/security.js';
import { buildRequestParams } from '../../services/table';
import { getFileName } from '../../services/reports.js';
import { VIEW_MODE_LIST, READ, PLANNED_DATE, CHOSEN_DATE } from '../../constants/gant';

function getIcon(icon) {
    if (!icon) {
        return null;
    }
    let cls = "fa fa-fw " + icon;
    return (<i className={cls} aria-hidden="true"></i>);
}

const initialConfirmState = {
    visible: false,
    changes: {}
};

class Toolbar extends React.PureComponent {

    constructor(props) {
        super(props);
        this.aclChecker = aclChecker(props.generalAccessRules);

        this.state = {
            confirm: initialConfirmState
        }

        this.confirmChanges = this.confirmChanges.bind(this);
        this.denyChanges = this.denyChanges.bind(this);

        this.selectColumn = this.selectColumn.bind(this);
        this.selectViewType = this.selectViewType.bind(this);
        this.selectGroup = this.selectGroup.bind(this);
        this.saveGant = this.saveGant.bind(this);
    }

    /****** Functions for automations ***********/
    reload(parameters) {
        this.props.reloadData(parameters);
    }

    //Modals
    openModal(type, options, okCallback, cancelCallback, closeCallback) {
        const id = shortid.generate();
        this.props.openModal(id, type, options, okCallback, cancelCallback, closeCallback);
    }

    updateOptions(options) {
        if (options.headers) {
            this.props.updateHeaders(this.props.tableId, options.headers);
        }
    }

    getFilteredRows() {
        return this.props.data;
    }

    isSelected(row) {
        return this.props.selectedRows[row.key];
    }

    finderSearch() {
        this.props.finderSearch();
    }

    getColumnData(column) {
        const data = this.getFilteredRows();
        if (!Array.isArray(data)) {
            return [];
        }
        const columnData = [];
        for (let row of data) {
            columnData.push(row[column]);
        }
        return columnData;
    }

    getFieldData(field) {
        return this.props.fields[field];
    }
    /*****************************/

    denyChanges() {
        this.updateFlag = true;
        this.setState({ confirm: initialConfirmState })
    }

    confirmChanges() {
        this.props.changeParameters(this.state.confirm.changes);
        this.setState({ confirm: initialConfirmState })
    }

    changeParameter(path, value) {
        if (this.props.confirmFields == false) {
            this.props.changeParameters({ [path]: value });
            return;
        }
        let confirm = Object.assign({}, this.state.confirm);
        confirm.changes = Object.assign({}, confirm.changes);
        confirm.visible = true;
        confirm.changes[path] = value;
        this.setState({ confirm });
    }

    changeCheckbox(path, event) {
        const value = event.target.checked;
        this.changeParameter(path, value)
    }

    changeDate(path, format, outputFormat, value) {
        let date;
        if (outputFormat) {
            date = moment(value, format).format(outputFormat);
        } else {
            date = moment(value, format).toISOString();
        }
        this.changeParameter(path, date)
    }

    selectColumn(item, option) {
        this.changeParameter(item.path, option.field);
    }

    selectViewType(item, option) {
        if (option.value == this.props.gant.viewType) {
            return;
        }
        this.props.changeViewType(option.value);
    }

    selectGroup(item, option) {
        if (option.id == this.props.gant.selectedGroup) {
            return;
        }
        this.props.changeSelectedGroup(option.id);
    }

    saveGant() {
        this.props.saveGant();
    }

    printFieldSelect(item, options, selectedOption, callback) {
        return (
            <div key={item.id} className="form-group">
                <div className="input-group input-group-sm">
                    <div className="input-group-btn">
                        <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown">
                            {getIcon(item.icon)} <strong>{item.label}</strong>
                        </button>
                        <div className="dropdown-menu">
                            {options.map((option) => {
                                return (
                                    <a className="dropdown-item" onClick={callback.bind(this, item, option)}>
                                        {option.formattedId ? <FormattedMessage id={option.formattedId} /> : option.label || option.name}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                    <input type="text" className="form-control" disabled={true} value={this.props.messages[selectedOption] || selectedOption} style={{ padding: 2, width: item.width || 40 }} />
                </div>
            </div>);
    }

    printButton(item, callback) {
        return (
            <div key={item.id} className="form-group">
                <button type="button" className="btn btn-secondary" onClick={callback.bind(this, item.id, this)}>
                    {getIcon(item.icon)} {item.formattedId ? <FormattedMessage id={item.formattedId} /> : item.label || item.name}
                </button>
            </div>);
    }

    getCopyRefsButton(btn) {
        if (!this.aclChecker.checkAcl(btn)) {
            return null;
        }
        return this.printButton(btn, this.props.copySelectedRowsRefs.bind(this, btn.namespace, btn.rdfId));
    }

    getButton(btn) {
        if (!this.aclChecker.checkAcl(btn)) {
            return null;
        }
        //By default use buttons
        return this.printButton(btn, this.props.buttonClick);
    }

    getGroup(group) {
        if (!this.aclChecker.checkAcl(group) || !group.item) {
            return null;
        }
        group.item = this.aclChecker.checkGroupAcl(group.item);
        if (group.item.length == 0) {
            return null;
        }
        return (<div key={group.id} className="form-group">
            <div className="btn-group">
                <button type="button" className={"btn btn-secondary dropdown-toggle"} data-toggle="dropdown">
                    {getIcon(group.icon)} {group.label}
                </button>
                <div className="dropdown-menu">
                    {group.item.map((item) => {
                        return (
                            <a className="dropdown-item" key={item.id} onClick={this.props.buttonClick.bind(this, item.id, this)}>{item.label}</a>
                        );
                    })}
                </div>
            </div>
        </div>);
    }

    getField(item) {
        const param = this.props.parameters[item.path];
        const editable = param && this.props.editable;
        let value = typeof this.state.confirm.changes[item.path] != "undefined" ? this.state.confirm.changes[item.path] : obtainData(item.path.split("."), this.props.fields);
        if (typeof value == "undefined" || value == null) {
            value = "";
        }
        if (item.format == "selectColumn") {
            const columns = this.props.header.slice();
            let selectedColumn = "--";
            for (let i = columns.length - 1; i >= 0; --i) {
                let column = columns[i];
                if (column.hidden) {
                    columns.splice(i, 1);
                    continue;
                }
                if (column.field == value) {
                    selectedColumn = column.label;
                }
            }
            return this.printFieldSelect(item, columns, selectedColumn, this.selectColumn);
        }
        if (item.format == "boolean") {
            return (
                <div key={item.id} className="form-group">
                    <div className="input-group">
                        <span style={{ padding: "2px" }} className="input-group-prepend input-group-text" >{getIcon(item.icon)} <strong>{item.label}</strong></span>
                        <div className="form-control" style={{ padding: "6px 2px", background: editable ? null : "#eee" }}>
                            <input type="checkbox" checked={value} disabled={!editable} onChange={this.changeCheckbox.bind(this, item.path)} />
                        </div>
                    </div>
                </div>);
        }
        var callback;
        switch (item.format) {
            case "date":
                callback = this.changeDate.bind(this, item.path, "L", "YYYY-MM-DD");
                break;
            case "dateTime":
                callback = this.changeDate.bind(this, item.path, "L LTS", null);
                break;
            default:
                callback = this.changeParameter.bind(this, item.path);
                break;
        }
        return (
            <div key={item.id} className="form-group">
                <DebounceInput
                    type="text"
                    format={item.format}
                    editable={editable && !this.updateFlag}
                    style={{ padding: 2, width: item.width || 40 }}
                    className="form-control"
                    onFinish={callback}
                    value={value}
                    locale={this.props.locale} >
                    <span style={{ padding: "2px" }} className="input-group-prepend input-group-text" >{getIcon(item.icon)} <strong>{item.label}</strong></span>
                </DebounceInput>
            </div>);
    }

    getReport(btn) {
        if (!this.props.parameters || !this.props.fields || !this.props.reports) {
            return null;
        }
        const cp = this.props.contextPath;
        const tableId = this.props.tableId;
        const params = $.param(buildRequestParams(this.props.parameters, this.props.fields, this.props.hashParams));
        /**Check if table have any active filters */
        let reportData = null;
        if (this.props.data.length !== this.props.originalData.length) {
            reportData = [];
            /**Remove rows with null key value */
            for (let row of this.props.data) {
                if (!row.key) {
                    continue;
                }
                reportData.push(row);
            }
            /**Check if all keys don't have key value */
            if (reportData.length === 0 && this.props.data.length !== 0) {
                reportData = null;
            }
        }
        //By default use buttons
        return (<div key={btn.id} className="form-group">
            <div className="btn-group">
                <button type="button" className={"btn btn-secondary dropdown-toggle" + (this.props.disabled ? " disabled" : "")} data-toggle="dropdown">
                    {getIcon(btn.icon)} {btn.label}
                </button>
                <div className="dropdown-menu">
                    {this.props.reports.map((report) => {
                        const href = `${cp}rest/table/report${tableId}?_report=${report.name}&${params}`;

                        const filename = getFileName(report.label, report.type, report.filename);

                        return (
                            <a className="dropdown-item" key={report.name} onClick={() => this.props.disabled ? null : (this.props.downloadReport(href, filename, reportData))} link={href} file={filename}>{report.label}</a>
                        );
                    })}
                </div>
            </div>
        </div>);
    }

    printGantView(item) {
        let options = VIEW_MODE_LIST;
        if (this.props.gant.permissions.value != READ && this.props.gant.permissions.chosenOnly) {
            options = options.chosenOnly;
        }
        options = options[this.props.gant.permissions.value];
        let viewType = this.props.gant.viewType;
        /* get view type formatted id */
        for (let option of options) {
            if (option.value == viewType) {
                viewType = option.formattedId;
                break;
            }
        }
        return this.printFieldSelect(item, options, viewType, this.selectViewType);
    }

    printGantGroup(item) {
        let options = [];
        switch (this.props.gant.viewType) {
            case PLANNED_DATE:
                options = this.props.gant.plannedGroups;
                break;
            case CHOSEN_DATE:
                options = this.props.gant.groups;
                break;
        }
        return this.printFieldSelect(item, options, this.props.gant.selectedGroup && this.props.gant.selectedGroup.name, this.selectGroup);
    }

    printGantSave(item) {
        return this.printButton(item, this.saveGant);
    }

    getGantField(item) {
        if (!this.props.gant || typeof item.format != "string") {
            return null;
        }
        let format = item.format.toLowerCase();
        switch (format) {
            case "view":
                return this.printGantView(item);
            case "group":
                return this.printGantGroup(item);
            case "save":
                return this.printGantSave(item);
        }
        return null;
    }

    printItem(item) {
        let type = item.type.toLowerCase();
        switch (type) {
            case "field":
                return this.getField(item);
            case "report":
                return this.getReport(item);
            case "dropdown":
                /* Only for groups */
                return this.getGroup(item);
            case "gant":
                return this.getGantField(item);
            case "copy_refs":
                return this.getCopyRefsButton(item);
            default:
                return this.getButton(item);
        }
    }

    printConfirmPanel() {
        return (
            <div className="confirm-panel d-flex">
                <div className="item d-flex" onClick={this.confirmChanges}>
                    <i className="fa fa fa-check" aria-hidden="true"></i>
                    <FormattedMessage
                        id="NPT_TABLE_CONFIRM"
                        defaultMessage="Confirm"
                        description="Confirm toolbar changes button" />
                </div>
                <div className="item d-flex" onClick={this.denyChanges}>
                    <i className="fa fa fa-times" aria-hidden="true"></i>
                    <FormattedMessage
                        id="NPT_TABLE_DENY"
                        defaultMessage="Deny"
                        description="Deny toolbar changes button" />
                </div>
            </div>
        );
    }

    componentDidUpdate() {
        if (this.updateFlag) {
            this.updateFlag = false;
            this.forceUpdate();
        }
    }

    render() {
        if (!this.props.toolbar || this.props.toolbar.length == 0) {
            return null;
        }
        return (
            <div className={"npt-table-toolbar" + (this.state.confirm.visible ? " not-confirmed" : "")}>
                <form className={"form-inline col-md-12 nopadding"}>
                    {this.props.toolbar.map((item) => this.printItem(item))}
                </form>
                {this.state.confirm.visible ? this.printConfirmPanel() : null}
            </div>
        )
    }
}

export default Toolbar;