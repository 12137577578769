import shortid from 'shortid';

const globalStylesMap = {};

export function addStyle(stylesheet) {
    /* Styles not working when first symbol is number */
    const id = "_" + shortid.generate() + "_";
    let stylesheetHtml = "";
    for (let item of stylesheet) {
        stylesheetHtml += "." + id + item.name + "\n{" + item.style + "}\n";
    }
    let styleTag = $('<style>').attr('type', 'text/css').text(stylesheetHtml).appendTo('head');
    globalStylesMap[id] = styleTag;
    return id;
}

export function removeStyle(id) {
    if (!globalStylesMap[id]) {
        return;
    }
    globalStylesMap[id].remove();
    delete (globalStylesMap[id]);
}