import React from 'react';
import { createRPASetpointsContainer } from '../../../rpasetpoints.jsx';
import { changeHash } from '../../../actions/location';

const RPASetpointsContainer = createRPASetpointsContainer();

class RPASetpoints extends React.Component {

    constructor(props) {
        super(props);

        changeHash({ params: { file: props.value || null } });
    }

    componentDidMount() {
        $(window).bind("rpasetpoints.save", (event, sha1) => {
            this.props.changeNode(sha1, null, this.props.node.options.src);
        });
    }

    componentDidUpdate() {
        /* Trigger resize for fillers update */
        window.setTimeout(() => {
            $(window).trigger("resize");
        }, 50)
    }

    componentWillUnmount() {
        $(window).unbind("rpasetpoints.save");
    }

    render() {
        return (
            <div className="npt-rpasetpoints-inset">
                <RPASetpointsContainer objectcard={this.props.formId} node={this.props.node}></RPASetpointsContainer>
            </div>
        );
    }
}

export default RPASetpoints;