import React from 'react';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import Record from './record.jsx';
import {
    MSG_SELECT_PLACEHOLDER,
    MSG_SELECT_NO_RESULTS,
    MSG_SELECT_LOADING,
    MSG_STATUS_FORBIDDEN,
    MSG_LOADING_ERROR,
    MSG_STATUS_NOT_FOUND
} from '../messages.jsx';

function sortedPush(array, value, sortingFunction) {
    for (let i = 0; i < array.length; ++i) {
        if (sortingFunction(value, array[i]) == 1) {
            continue;
        }
        array.splice(i, 0, value);
        return;
    }
    array.push(value);
}

function getOptions(predicates) {
    let options = [];
    if (predicates) {
        for (let predicateId of predicates.allIds) {
            sortedPush(options, { value: predicateId.toString(), label: predicates.byId[predicateId].predicate.label }, function (a, b) {
                if (a.label > b.label) {
                    return 1;
                }
                if (a.label == b.label) {
                    return 0;
                }
                return -1;
            });
        }
    }
    return options;
}

class History extends React.Component {

    constructor(props) {
        super(props);

        this.filterByPredicate = this.filterByPredicate.bind(this);
    }

    filterByPredicate(selection) {
        if (selection) {
            this.props.changeHash({ params: { parameterId: selection.value } });
        } else {
            this.props.changeHash({ params: { parameterId: null } });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.hashParams != nextProps.hashParams) {
            nextProps.hashChanged(nextProps.hashParams);
        }
    }

    componentDidMount() {
        this.props.fetchHistoryData(this.props.rdfId, this.props.hashParams);
    }

    render() {
        let data = this.props.history.filteredData ? this.props.history.filteredData : this.props.history.data;
        let error = null;
        if (this.props.history.error) {
            switch (this.props.history.error.status) {
                case 403:
                    error = MSG_STATUS_FORBIDDEN;
                    break;
                case 404:
                    error = MSG_STATUS_NOT_FOUND;
                    break;
                default:
                    error = MSG_LOADING_ERROR;
                    break;
            }
        }
        return (
            <div className="container-fluid">
                <div className="row clearfix">
                    <div className="col-md-12">
                        <h3 className="cim-history-header">
                            <FormattedMessage
                                id="SUBJECT_HISTORY_HEADER"
                                defaultMessage="History of subject"
                                description="Subject history header" />
                            {data && data.length > 0 ? <span> "{data[0].label}":</span> : null}
                        </h3>
                        {(error || this.props.history.loading) ? null :
                            <div className="btn-group pull-right" style={{ margin: "5px 0px 8px 5px" }} role="group">
                                {this.props.hashParams.timestamp ?
                                    <button type="button" className="btn btn-secondary" onClick={() => this.props.changeHash({ params: { timestamp: null } })}>
                                        <i className="fa fa-times fa-fw" aria-hidden="true"></i>
                                        <FormattedMessage
                                            id="SUBJECT_HISTORY_RESET_TIMESTAMP"
                                            defaultMessage="Reset timestamp"
                                            description="Reset timestamp" />
                                    </button>
                                    :
                                    null}
                                <button type="button" className="btn btn-secondary" onClick={() => window.location.assign(window.location.pathname.replace("history", "objectcard"))}>
                                    <i className="fa fa-eye fa-fw" aria-hidden="true"></i>
                                    <FormattedMessage
                                        id="SUBJECT_HISTORY_VIEW_CARD"
                                        defaultMessage="View card"
                                        description="View card" />
                                </button>
                            </div>}
                        {(error || this.props.history.loading || this.props.hashParams.timestamp) ? null : <div className="cim-history-filter-container">
                            <div className="cim-history-filter-label">
                                <FormattedMessage
                                    id="SUBJECT_HISTORY_FILTER_BY_PARAMETER"
                                    defaultMessage="Filtered by parameter:"
                                    description="Filtered by parameter" />
                            </div>
                            <div className="cim-history-filter">
                                <Select
                                    name="predicate-filter"
                                    loadingPlaceholder={MSG_SELECT_LOADING}
                                    placeholder={MSG_SELECT_PLACEHOLDER}
                                    noResultsText={MSG_SELECT_NO_RESULTS}
                                    value={this.props.history.filteredPredicateId}
                                    options={getOptions(this.props.history.predicates)}
                                    onChange={this.filterByPredicate}
                                    clearable={true}
                                />
                            </div>
                        </div>}
                    </div>
                </div>
                {error ?
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                    :
                    this.props.history.loading ?
                        <FormattedMessage
                            id="SUBJECT_HISTORY_LOADING"
                            defaultMessage="Please wait, history is loading..."
                            description="Please wait, history is loading..." />
                        :
                        data != null ?
                            data.map((record) => <Record key={record.id} data={record} contextPath={this.props.contextPath} enum={this.props.hashParams.enum} />)
                            :
                            <div>
                                <FormattedMessage
                                    id="SUBJECT_EMPTY_HISTORY"
                                    defaultMessage="History is empty"
                                    description="History is empty" />
                            </div>}
            </div>);
    }
}

export default History;


