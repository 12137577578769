export function printFloat(value) {
    if (typeof value != "number") {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
            return value;
        }
        value = parsedValue;
    }
    if (value.toString().search(/[eE]/) == -1) {
        return value.toString();
    }
    value = value.toString().replace(".", "");
    const exponentIndex = value.search(/[eE]/);
    const scientificNumber = parseInt(value.substring(exponentIndex + 1));
    value = value.substring(0, exponentIndex);
    if (scientificNumber < 0) {
        value = "0." + "0".repeat(-scientificNumber - 1) + value;
    } else {
        value = value.padEnd(scientificNumber + 1, "0");
    }
    return value;
}