import { connect, Provider } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import I18NProvider from './components/provider.jsx';
import { I18N } from './constants/i18n';
import CronTasks from './components/crontasks/crontasks.jsx';
import { CRONTASKS } from './constants/crontasks';
import {
    updateScripts,
    selectRow,
    deleteRows,
    callConfigurePanel,
    saveTask,
    closeConfigurePanel,
    callConfirmDialog,
    closeConfirmDialog
} from './actions/crontasks';
import crontasksReducer from './reducers/crontasks';

const ATTR_CRON_TASKS = "cron-tasks";
const CronTasksContainer = createCronTasksContainer();

//Map global (redux) state to navigation tree state
function createMapStateToProps() {
    return function (globalState) {
        return {
            crontasks: globalState[CRONTASKS],
            locale: globalState[I18N].locale,
            messages: globalState[I18N].messages,
        };
    }
}

function createMapDispachToProps() {
    return function (dispatch) {
        return {
            updateScripts: function () {
                dispatch(updateScripts());
            },
            selectRow: function ({ data, selected }) {
                dispatch(selectRow({ data, selected }));
            },
            deleteRows: function ({ selectedRows }) {
                dispatch(deleteRows({ selectedRows }));
            },
            callConfigurePanel: function ({ task }) {
                dispatch(callConfigurePanel({ task }));
            },
            okConfigurePanel: function ({ task }) {
                dispatch(saveTask({ task }));
            },
            closeConfigurePanel: function () {
                dispatch(closeConfigurePanel());
            },
            callConfirmDialog: function ({ ok, cancel }) {
                dispatch(callConfirmDialog({ ok, cancel }));
            },
            closeConfirmDialog: function () {
                dispatch(closeConfirmDialog());
            }
        }
    }
}


function createCronTasksContainer() {
    //Connect redux to our navigation tree
    return connect(
        createMapStateToProps(),
        createMapDispachToProps()
    )(CronTasks);
}

//Initialization function
export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(CRONTASKS, crontasksReducer);

    //Register components
    dispatcher.registerComponent(ATTR_CRON_TASKS, (elm) => {
        return (<CronTasksContainer />);
    })
};

