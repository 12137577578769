import ReactDOM from 'react-dom';
import I18NProvider from '../components/provider.jsx';
import shortid from 'shortid';
import {
    TYPE,
    FINDER_SELECTION_AREA,
    FINDER_SELECTION_CRITERIA,
    isFileRelation,
    numberRelationTypeList,
    dateRelationTypeList,
    stringRelationTypeList,
    fileRelationTypeList,
    fragmentRelationTypeList,
    referenceRelationTypeList,
    enumerationRelationTypeList,
    booleanRelationTypeList,
    booleanRelationValueList
} from "../constants/finder";
import { MSG_OR, MSG_FROM, MSG_TO } from '../components/messages.jsx';


function generateUniqueId(idMap) {
    let id = shortid.generate();
    if (idMap) {
        /* Prevent id duplicate */
        while (idMap[id]) {
            id = shortid.generate();
        }
    }
    return id;
}

function compareFieldsById(normalizedFields, firstId, secondId) {
    if (normalizedFields.byId[firstId].label < normalizedFields.byId[secondId].label) {
        return -1;
    }
    if (normalizedFields.byId[firstId].label == normalizedFields.byId[secondId].label) {
        return 0;
    }
    return 1;
}

export function isRdfId(value) {
    if (typeof value != "string") {
        return false;
    }
    /** Platform rdfId can have any string value, so RegExp matcher of rdfId structure is redundant */
    //const matcher = new RegExp(/^_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/);
    const matcher = new RegExp(/^[a-zA-Z_]/);
    return matcher.test(value);
}

const initialFields = {
    byId: {},
    fetched: {},
    loading: {},
    idByPredicateName: {},
    children: {},
    rootIds: [],
    allIds: []
};

const initialPredicates = {
    byName: {}
};

const initialEnumerations = {
    byId: {},
    fetched: {},
    idByPredicateName: {},
    rootIds: [],
    allIds: []
};

const initialClasses = {
    byId: {},
    fetched: {},
    loading: {},
    idByClassId: {},
    children: {},
    allIds: []
};

const initialFragments = {
    byId: {},
    fetched: {},
    loading: {},
    children: {},
    rootIds: [],
    allIds: []
};

const initialObjectcards = {
    byId: {},
    fetched: {}
};

function safeGetFields(fields) {
    return fields ? fields : initialFields;
}

function safeGetPredicates(predicates) {
    return predicates ? predicates : initialPredicates;
}

function safeGetEnumerations(enumerations) {
    return enumerations ? enumerations : initialEnumerations;
}

function safeGetClasses(classes) {
    return classes ? classes : initialClasses;
}

function safeGetFragments(fragments) {
    return fragments ? fragments : initialFragments;
}

function safeGetObjectcards(objectcards) {
    return objectcards ? objectcards : initialObjectcards;
}

function recursiveAddEnumerations(loadedEnumerations, children, parentId) {
    if (!children) {
        return;
    }
    for (let child of children) {
        if (!child.data) {
            continue;
        }
        let predicateName = child.data.$namespace + ":" + child.data.$rdfId;
        if (loadedEnumerations.idByPredicateName[predicateName]) {
            continue;
        }
        let id = child.data.id ? child.data.id : generateUniqueId(loadedEnumerations.byId);
        let field = {
            id,
            label: child.data.$label,
            children: []
        }
        loadedEnumerations.byId[id] = field;
        loadedEnumerations.idByPredicateName[predicateName] = id;
        if (parentId) {
            field.parentId = parentId;
            loadedEnumerations.byId[parentId].children.push(id);
        } else {
            field.parentId = null;
            loadedEnumerations.rootIds.push(id);
        }
        if (child.children) {
            recursiveAddEnumerations(loadedEnumerations, child.children, id);
        }
    }
    if (parentId) {
        loadedEnumerations.byId[parentId].children.sort((a, b) => compareFieldsById(loadedEnumerations, a, b));
    }
}

function parseFields(loadedFields, data, parentId) {
    for (let child of data) {
        let id = child.id ? child.id : generateUniqueId(loadedFields.byId);
        let field = Object.assign({ id, joinIdPredicate: getPredicateName(child) }, child);
        loadedFields.byId[id] = field;
        if (!loadedFields.children[id]) {
            loadedFields.children[id] = [];
        }
        if (child.predicate) {
            loadedFields.idByPredicateName[child.predicate] = id;
        }
        loadedFields.allIds.push(id);
        if (parentId) {
            field.parentId = parentId;
            if (!loadedFields.children[parentId]) {
                loadedFields.children[parentId] = [];
            }
            loadedFields.children[parentId].push(id);
        } else {
            field.parentId = null;
            loadedFields.rootIds.push(id);
        }
    }
    if (parentId && loadedFields.children[parentId]) {
        loadedFields.children[parentId].sort((a, b) => compareFieldsById(loadedFields, a, b));
    }
}

function deleteKeyFromArray(array, value) {
    for (let i = 0; i < array.length; ++i) {
        if (array[i] == value) {
            array.splice(i, 1);
            return;
        }
    }
}

function changeKeyInArray(array, oldValue, newValue) {
    for (let i = 0; i < array.length; ++i) {
        if (array[i] == oldValue) {
            array.splice(i, 1, newValue);
            return;
        }
    }
}

export function resetCriteria(nextState) {
    nextState.criteria = {
        byId: {},
        allIds: []
    };

    nextState.criteriaGroup = {
        byId: {},
        allIds: []
    };

    nextState.criteriaGroupList = [];
}

export function addNewCriteria(nextState, criteriaGroupId) {
    /* Check if we adding "or" criteria */
    if (criteriaGroupId == null) {
        /* Create new "and" group */
        criteriaGroupId = generateUniqueId(nextState.criteriaGroup.byId);
        nextState.criteriaGroup = Object.assign({}, nextState.criteriaGroup);
        nextState.criteriaGroup.byId = Object.assign({}, nextState.criteriaGroup.byId);
        nextState.criteriaGroup.byId[criteriaGroupId] = {
            criteriaList: []
        };
        nextState.criteriaGroup.allIds = nextState.criteriaGroup.allIds.slice();
        nextState.criteriaGroup.allIds.push(criteriaGroupId);

        /* Add new criteriaGroup to criteriaGroupList */
        nextState.criteriaGroupList = nextState.criteriaGroupList.slice();
        nextState.criteriaGroupList.push(criteriaGroupId);
    }

    let criteriaId = generateUniqueId(nextState.criteria.byId);
    let newCriteria = {
        fieldId: null,
        relations: [],
        criteriaGroupId: criteriaGroupId,
        id: criteriaId
    }

    /* Add new criteria to group */
    nextState.criteriaGroup = Object.assign({}, nextState.criteriaGroup);
    nextState.criteriaGroup.byId = Object.assign({}, nextState.criteriaGroup.byId);
    nextState.criteriaGroup.byId[criteriaGroupId] = Object.assign({}, nextState.criteriaGroup.byId[criteriaGroupId]);
    nextState.criteriaGroup.byId[criteriaGroupId].criteriaList = nextState.criteriaGroup.byId[criteriaGroupId].criteriaList.slice();
    nextState.criteriaGroup.byId[criteriaGroupId].criteriaList.push(criteriaId);

    /* Add new criteria to criteria map */
    nextState.criteria = Object.assign({}, nextState.criteria);
    nextState.criteria.byId = Object.assign({}, nextState.criteria.byId);
    nextState.criteria.byId[criteriaId] = newCriteria;
    nextState.criteria.allIds = nextState.criteria.allIds.slice();
    nextState.criteria.allIds.push(criteriaId);

    return { criteriaGroupId, criteriaId };
}

export function removeCriteriaGroup(nextState, criteriaGroupId) {
    /* If group have children - remove them and return (when last child will be removed it will triger actual deletion of group) */
    if (nextState.criteriaGroup.byId[criteriaGroupId].criteriaList.length != 0) {
        /* Remove childs from group */
        let criteriaIdList = nextState.criteriaGroup.byId[criteriaGroupId].criteriaList.slice();
        for (let criteriaId of criteriaIdList) {
            removeCriteria(nextState, criteriaId);
        }
        return;
    }

    /* Remove criteria from parent */
    let orCriteriaGroupId = nextState.criteriaGroup.byId[criteriaGroupId].orCriteriaGroupId;
    nextState.criteriaGroupList = nextState.criteriaGroupList.slice();
    deleteKeyFromArray(nextState.criteriaGroupList, criteriaGroupId);

    /* Delete criteriaGroup */
    nextState.criteriaGroup = Object.assign({}, nextState.criteriaGroup);
    nextState.criteriaGroup.byId = Object.assign({}, nextState.criteriaGroup.byId);
    nextState.criteriaGroup.allIds = nextState.criteriaGroup.allIds.slice();
    deleteKeyFromArray(nextState.criteriaGroup.allIds, criteriaGroupId)
    delete (nextState.criteriaGroup.byId[criteriaGroupId]);
}

export function removeCriteria(nextState, criteriaId) {
    /* Remove criteria from parent */
    let criteriaGroupId = nextState.criteria.byId[criteriaId].criteriaGroupId;
    nextState.criteriaGroup = Object.assign({}, nextState.criteriaGroup);
    nextState.criteriaGroup.byId = Object.assign({}, nextState.criteriaGroup.byId);
    nextState.criteriaGroup.byId[criteriaGroupId] = Object.assign({}, nextState.criteriaGroup.byId[criteriaGroupId]);
    deleteKeyFromArray(nextState.criteriaGroup.byId[criteriaGroupId].criteriaList, criteriaId);

    /* Check if removed criteria was last one in group */
    if (nextState.criteriaGroup.byId[criteriaGroupId].criteriaList.length == 0) {
        removeCriteriaGroup(nextState, criteriaGroupId);
    }

    /* Delete criteria */
    nextState.criteria = Object.assign({}, nextState.criteria);
    nextState.criteria.byId = Object.assign({}, nextState.criteria.byId);
    nextState.criteria.allIds = nextState.criteria.allIds.slice();
    deleteKeyFromArray(nextState.criteria.allIds, criteriaId);
    delete (nextState.criteria.byId[criteriaId]);
}

export function unlockCriteriaRelations(nextState, criteriaId) {
    /* Unlock criteria relations */
    nextState.criteria.byId = Object.assign({}, nextState.criteria.byId);
    nextState.criteria.byId[criteriaId] = Object.assign({}, nextState.criteria.byId[criteriaId]);
    nextState.criteria.byId[criteriaId].locked = false;
}

export function changeRelation(nextState, criteriaId, relation, relationIdx) {
    nextState.criteria = Object.assign({}, nextState.criteria);
    nextState.criteria.byId = Object.assign({}, nextState.criteria.byId);
    nextState.criteria.byId[criteriaId] = Object.assign({}, nextState.criteria.byId[criteriaId]);
    nextState.criteria.byId[criteriaId].relations = nextState.criteria.byId[criteriaId].relations.slice();
    nextState.criteria.byId[criteriaId].relations[relationIdx] = relation;
}

export function addRelation(nextState, criteriaId) {
    nextState.criteria = Object.assign({}, nextState.criteria);
    nextState.criteria.byId = Object.assign({}, nextState.criteria.byId);
    nextState.criteria.byId[criteriaId] = Object.assign({}, nextState.criteria.byId[criteriaId]);
    nextState.criteria.byId[criteriaId].relations = nextState.criteria.byId[criteriaId].relations.slice();

    let newRelation = {
        type: null
    }
    nextState.criteria.byId[criteriaId].relations.push(newRelation);
}

export function removeRelation(nextState, criteriaId, relationIdx) {
    nextState.criteria = Object.assign({}, nextState.criteria);
    nextState.criteria.byId = Object.assign({}, nextState.criteria.byId);
    nextState.criteria.byId[criteriaId] = Object.assign({}, nextState.criteria.byId[criteriaId]);
    nextState.criteria.byId[criteriaId].locked = true;
    nextState.criteria.byId[criteriaId].relations = nextState.criteria.byId[criteriaId].relations.slice();
    nextState.criteria.byId[criteriaId].relations.splice(relationIdx, 1);
}

export function changeField(nextState, criteriaId, fieldId) {
    nextState.criteria = Object.assign({}, nextState.criteria);
    nextState.criteria.byId = Object.assign({}, nextState.criteria.byId);
    nextState.criteria.byId[criteriaId] = Object.assign({}, nextState.criteria.byId[criteriaId]);
    nextState.criteria.byId[criteriaId].fieldId = fieldId;
    nextState.criteria.byId[criteriaId].relations = [];
    if (fieldId && nextState.loadedFields.byId[fieldId].id) {
        addRelation(nextState, criteriaId)
    }
}

function getDepthOfFragment(fragments, fragmentId) {
    let fragment = fragments.byId[fragmentId];
    let depth = 0;
    while (fragment && fragment.parentId && fragment.parentId != fragment.id) {
        ++depth;
        fragment = fragments.byId[fragment.parentId];
    }
    return depth;
}

export function removeFragmentChildrens(nextState, depth, fragmentId) {
    if (depth >= nextState.sideBar.fragmentLevels.length - 1) {
        return;
    }
    let selectedChildren = nextState.sideBar.fragmentLevels[depth + 1].selected.slice();
    for (let selectedChildrenId of selectedChildren) {
        let fragment = nextState.loadedFragments.byId[selectedChildrenId];
        if (fragment.parentId == fragmentId) {
            removeFragment(nextState, selectedChildrenId);
        }
    }
}

export function removeFragment(nextState, fragmentId) {
    let depth = getDepthOfFragment(nextState.loadedFragments, fragmentId);
    if (depth >= nextState.sideBar.fragmentLevels.length) {
        return;
    }
    nextState.sideBar.fragmentLevels = nextState.sideBar.fragmentLevels.slice();
    nextState.sideBar.fragmentLevels[depth] = Object.assign({}, nextState.sideBar.fragmentLevels[depth]);
    nextState.sideBar.fragmentLevels[depth].selected = nextState.sideBar.fragmentLevels[depth].selected.slice();
    deleteKeyFromArray(nextState.sideBar.fragmentLevels[depth].selected, fragmentId);
    removeFragmentChildrens(nextState, depth, fragmentId);
}

export function addFragment(nextState, fragmentId) {
    let depth = getDepthOfFragment(nextState.loadedFragments, fragmentId);
    if (depth >= nextState.sideBar.fragmentLevels.length) {
        return;
    }
    nextState.sideBar.fragmentLevels = nextState.sideBar.fragmentLevels.slice();
    nextState.sideBar.fragmentLevels[depth] = Object.assign({}, nextState.sideBar.fragmentLevels[depth]);
    nextState.sideBar.fragmentLevels[depth].selected = nextState.sideBar.fragmentLevels[depth].selected.slice();
    nextState.sideBar.fragmentLevels[depth].selected.push(fragmentId);
}

export function changeFragment(nextState, oldFragmentId, newFragmentId) {
    let oldDepth = getDepthOfFragment(nextState.loadedFragments, oldFragmentId);
    let newDepth = getDepthOfFragment(nextState.loadedFragments, newFragmentId);

    /* Check if error is occused and depth of fragments is different */
    if (oldDepth != newDepth) {
        console.error("Depths not matching: old - ", oldDepth, ", new - ", newDepth);
        return;
    }
    if (oldDepth >= nextState.sideBar.fragmentLevels.length) {
        return;
    }

    nextState.sideBar.fragmentLevels = nextState.sideBar.fragmentLevels.slice();
    nextState.sideBar.fragmentLevels[depth] = Object.assign({}, nextState.sideBar.fragmentLevels[depth]);
    nextState.sideBar.fragmentLevels[depth].selected = nextState.sideBar.fragmentLevels.selected.slice();
    changeKeyInArray(nextState.sideBar.fragmentLevels[depth].selected, oldFragmentId, newFragmentId);
    removeFragmentChildrens(nextState, oldDepth, oldFragmentId);
}

export function selectFragment(nextState, oldFragmentId, newFragmentId) {
    nextState.sideBar = Object.assign({}, nextState.sideBar);

    /* If new id is null then user wants to remove fragment from selection */
    if (!newFragmentId) {
        /* Check if both ids is undefined */
        if (!oldFragmentId) {
            return;
        }
        removeFragment(nextState, oldFragmentId);
        return;
    }

    /* If old id is null then user wants to add fragment to selection */
    if (!oldFragmentId) {
        /* Check if both ids is undefined */
        if (!newFragmentId) {
            return;
        }
        addFragment(nextState, newFragmentId);
        return;
    }

    /* If both id is not null then user wants to change fragment in selection */
    changeFragment(nextState, oldFragmentId, newFragmentId);
}

export function removeClass(nextState, classId, level) {
    nextState.sideBar.classLevels = nextState.sideBar.classLevels.slice();
    nextState.sideBar.classLevels[level] = Object.assign({}, nextState.sideBar.classLevels[level]);
    nextState.sideBar.classLevels[level].selected = nextState.sideBar.classLevels[level].selected.slice();
    deleteKeyFromArray(nextState.sideBar.classLevels[level].selected, classId);
}

export function addClass(nextState, classId, level) {
    nextState.sideBar.classLevels = nextState.sideBar.classLevels.slice();
    nextState.sideBar.classLevels[level] = Object.assign({}, nextState.sideBar.classLevels[level]);
    nextState.sideBar.classLevels[level].selected = nextState.sideBar.classLevels[level].selected.slice();
    nextState.sideBar.classLevels[level].selected.push(classId);
}

export function changeClass(nextState, oldClassId, newClassId, level) {
    nextState.sideBar.classLevels = nextState.sideBar.classLevels.slice();
    nextState.sideBar.classLevels[level] = Object.assign({}, nextState.sideBar.classLevels[level]);
    nextState.sideBar.classLevels[level].selected = nextState.sideBar.classLevels.selected.slice();
    changeKeyInArray(nextState.sideBar.classLevels[level].selected, oldClassId, newClassId);
}

export function selectClass(nextState, oldClassId, newClassId, level) {
    nextState.sideBar = Object.assign({}, nextState.sideBar);

    /* If new id is null then user wants to remove class from selection */
    if (!newClassId) {
        /* Check if both ids is undefined */
        if (!oldClassId) {
            return;
        }
        removeClass(nextState, oldClassId, level);
        return;
    }

    /* If old id is null then user wants to add class to selection */
    if (!oldClassId) {
        /* Check if both ids is undefined */
        if (!newClassId) {
            return;
        }
        addClass(nextState, newClassId, level);
        return;
    }

    /* If both id is not null then user wants to change class in selection */
    changeClass(nextState, oldClassId, newClassId, level);
}

export function importFinder(nextState, finderData) {
    if (!nextState.sideBar) {
        console.error("Error. Wrong loading sequence: importing filter before total loading.");
        return;
    }
    if (!finderData.finder) {
        return;
    }

    nextState.sideBar = Object.assign({}, nextState.sideBar);

    if (finderData.finder.fragmentList) {
        let usedFragmentIdsMap = {};
        for (let fragmentId of finderData.finder.fragmentList) {
            while (fragmentId && !usedFragmentIdsMap[fragmentId]) {
                addFragment(nextState, fragmentId);
                usedFragmentIdsMap[fragmentId] = true;
                let fragment = nextState.loadedFragments.byId[fragmentId];
                fragmentId = fragment.parentId;
            }
        }
    }

    if (finderData.finder.classList) {
        for (let classId of finderData.finder.classList) {
            let classShortId = nextState.loadedClasses.idByClassId[classId];
            if (classShortId) {
                let classObject = nextState.loadedClasses.byId[classShortId];
                addClass(nextState, classShortId, classObject.level);
            }
        }
    }

    resetCriteria(nextState);
    if (finderData.finder.orBlocks) {
        for (let orBlock of finderData.finder.orBlocks) {
            let idPool = addNewCriteria(nextState, null);
            let orCriteriaId = idPool.criteriaGroupId;
            if (!orBlock.andPredicates) {
                continue;
            }
            let andCriteriaId = idPool.criteriaId;
            for (let andBlock of orBlock.andPredicates) {
                if (!andCriteriaId) {
                    andCriteriaId = addNewCriteria(nextState, orCriteriaId).criteriaId;
                }
                if (!andBlock.orConditions) {
                    continue;
                }
                changeField(nextState, andCriteriaId, nextState.loadedFields.idByPredicateName[andBlock.predicate]);
                for (let i = 0; i < andBlock.orConditions.length; ++i) {
                    let orCondition = andBlock.orConditions[i];
                    if (i != 0) {
                        addRelation(nextState, andCriteriaId);
                    }
                    let relation = {
                        type: orCondition.operator
                    };
                    switch (orCondition.operator) {
                        case "between":
                            relation.value = {
                                from: orCondition.parameters[0],
                                to: orCondition.parameters[1]
                            };
                            break;
                        default:
                            relation.value = orCondition.parameters[0]
                    }
                    changeRelation(nextState, andCriteriaId, relation, i);
                }
                andCriteriaId = null;
            }
        }
    }
}

export function parseFinderOptions(nextState, finderOptions) {
    Object.assign(nextState, {
        isFetching: false,
        loadedFields: safeGetFields(nextState.loadedFields),
        loadedPredicates: safeGetPredicates(nextState.loadedPredicates),
        loadedEnumerations: safeGetEnumerations(nextState.loadedEnumerations),
        loadedFragments: safeGetFragments(nextState.loadedFragments),
        loadedObjectcards: safeGetObjectcards(nextState.loadedObjectcards),
        loadedClasses: safeGetClasses(nextState.loadedClasses)
    }, finderOptions);
    resetCriteria(nextState);
    addNewCriteria(nextState, null);
}

export function classLevelsReceived(nextState, levels) {
    nextState.loadedClasses = Object.assign({}, nextState.loadedClasses);
    if (!nextState.sideBar) {
        nextState.sideBar = {};
    } else {
        nextState.sideBar = Object.assign({}, nextState.sideBar);
    }
    for (let classLevel of levels) {
        Object.assign(classLevel, {
            selected: [],
            selectionIds: []
        })
    }
    nextState.sideBar.classLevels = levels;
}

export function waitForClasses(nextState, levelId) {
    nextState.loadedClasses = Object.assign({}, nextState.loadedClasses);
    nextState.loadedClasses.loading = Object.assign({}, nextState.loadedClasses.loading);
    nextState.loadedClasses.loading[levelId] = true;
}

export function classesReceived(nextState, levelId, classes) {
    nextState.sideBar = Object.assign({}, nextState.sideBar);
    nextState.sideBar.classLevels = nextState.sideBar.classLevels.slice();

    let levelIdx = null;
    for (let i = 0; i < nextState.sideBar.classLevels.length; ++i) {
        if (nextState.sideBar.classLevels[i].id == levelId) {
            levelIdx = i;
            break;
        }
    }
    if (levelIdx == null) {
        console.error("Can't find level by id: '" + levelId + "'");
        return;
    }
    nextState.sideBar.classLevels[levelIdx] = Object.assign({}, nextState.sideBar.classLevels[levelIdx]);

    nextState.loadedClasses = Object.assign({}, nextState.loadedClasses);
    nextState.loadedClasses.fetched = Object.assign({}, nextState.loadedClasses.fetched);
    nextState.loadedClasses.fetched[levelId] = true;
    nextState.loadedClasses.loading = Object.assign({}, nextState.loadedClasses.loading);
    nextState.loadedClasses.loading[levelId] = false;

    let classLevel = nextState.sideBar.classLevels[levelIdx];
    classLevel.selectionIds = classLevel.selectionIds.slice();

    for (let classObject of classes) {
        let id = classObject.id ? classObject.id : generateUniqueId(nextState.loadedClasses.byId);
        Object.assign(classObject, {
            id,
            label: classObject.name,
            level: levelIdx
        });

        nextState.loadedClasses.byId[id] = classObject;
        nextState.loadedClasses.idByClassId[getClassId(classLevel, classObject)] = id;
        nextState.loadedClasses.allIds.push(id);

        classLevel.selectionIds.push(id);
    }
    classLevel.selectionIds.sort((a, b) => compareFieldsById(nextState.loadedClasses, a, b));
}

export function fragmentLevelsReceived(nextState, levels) {
    nextState.loadedFragments = Object.assign({}, nextState.loadedFragments);
    if (!nextState.sideBar) {
        nextState.sideBar = {};
    } else {
        nextState.sideBar = Object.assign({}, nextState.sideBar);
    }
    for (let level of levels) {
        level.selected = [];
    }
    nextState.sideBar.fragmentLevels = levels;
}

export function waitForFragments(nextState, parentId) {
    nextState.loadedFragments = Object.assign({}, nextState.loadedFragments);
    nextState.loadedFragments.loading = Object.assign({}, nextState.loadedFragments.loading);
    nextState.loadedFragments.loading[parentId] = true;
}

export function fragmentsReceived(nextState, parentId, fragments) {
    nextState.loadedFragments = Object.assign({}, nextState.loadedFragments);
    nextState.loadedFragments.fetched = Object.assign({}, nextState.loadedFragments.fetched);
    nextState.loadedFragments.fetched[parentId] = true;
    nextState.loadedFragments.loading = Object.assign({}, nextState.loadedFragments.loading);
    nextState.loadedFragments.loading[parentId] = false;
    nextState.loadedFragments.children = Object.assign({}, nextState.loadedFragments.children);

    for (let child of fragments) {
        let id = child.id ? child.id : generateUniqueId(nextState.loadedFragments.byId);
        let fragment = Object.assign(child, {
            id,
            label: child.name
        });
        if (!nextState.loadedFragments.children[id]) {
            nextState.loadedFragments.children[id] = [];
        }

        nextState.loadedFragments.byId[id] = fragment;
        nextState.loadedFragments.allIds.push(id);
        if (parentId) {
            fragment.parentId = parentId;
            if (!nextState.loadedFragments.children[parentId]) {
                nextState.loadedFragments.children[parentId] = [];
            }
            nextState.loadedFragments.children[parentId].push(id);
        } else {
            fragment.parentId = null;
            nextState.loadedFragments.rootIds.push(id);
        }
    }
    if (parentId) {
        nextState.loadedFragments.children[parentId].sort((a, b) => compareFieldsById(nextState.loadedFragments, a, b));
    }
}

function parsePredicate(loadedEnumerations, predicate) {
    let fieldType;
    if (typeof predicate.classRelationInfo != 'undefined' && predicate.classRelationInfo != null) {
        let peerClass = predicate.classRelationInfo.peerClass;
        if (peerClass.stereotypeInfo == "enumeration") {
            fieldType = TYPE.ENUMERATION;
            recursiveAddEnumerations(loadedEnumerations, peerClass.enumerationInfo.children, null);
        } else {
            let relationTypeInfo = predicate.classRelationInfo.relationTypeInfo.toLowerCase();
            if (relationTypeInfo == 'composition') {
                fieldType = TYPE.TABLE;
            } else {
                fieldType = TYPE.REFERENCE;
            }
        }
    } else if (typeof predicate.dataType != 'undefined' && predicate.dataType != null) {
        let dataType = predicate.dataType.name;
        if (dataType === 'anyURI') {
            fieldType = TYPE.FRAGMENT;
        } else if (dataType === "boolean") {
            fieldType = TYPE.BOOLEAN;
        } else if (dataType === "base64Binary") {
            fieldType = TYPE.FILE;
        } else {
            fieldType = TYPE.STRING;
            predicate.format = dataType;
        }
    } else {
        fieldType = TYPE.STRING;
    }

    if (fieldType == TYPE.STRING && predicate.dataType) {
        switch (predicate.dataType.name) {
            case "double": //double
            case "float": //float
            case "decimal": //decimal
            case "int": //int
            case "short": //short
            case "positiveInteger": //positive integer
            case "negativeInteger": //negative integer
            case "integer": //integer
                fieldType = TYPE.NUMBER;
                break;
            case "dateTime": //date
            case "date": //date
                fieldType = TYPE.DATE;
                break;
            default: //string
                break;
        }
    }

    predicate.type = fieldType;
}

export function waitForField(nextState, parentId, data) {
    nextState.loadedFields = Object.assign({}, nextState.loadedFields);
    nextState.loadedFields.loading = Object.assign({}, nextState.loadedFields.loading);
    nextState.loadedFields.loading[parentId] = true;
}

export function fieldsReceived(nextState, parentId, data) {
    nextState.loadedFields = Object.assign({}, nextState.loadedFields);
    nextState.loadedFields.fetched = Object.assign({}, nextState.loadedFields.fetched);
    nextState.loadedFields.fetched[parentId] = true;
    nextState.loadedFields.loading = Object.assign({}, nextState.loadedFields.loading);
    nextState.loadedFields.loading[parentId] = false;
    nextState.loadedFields.children = Object.assign({}, nextState.loadedFields.children);
    parseFields(nextState.loadedFields, data, parentId);
}

export function objectcardReceived(nextState, rdfId, data) {
    nextState.loadedObjectcards = Object.assign({}, nextState.loadedObjectcards);
    nextState.loadedObjectcards.fetched = Object.assign({}, nextState.loadedObjectcards.fetched);
    nextState.loadedObjectcards.fetched[rdfId] = true;
    nextState.loadedObjectcards.byId = Object.assign({}, nextState.loadedObjectcards.byId);
    nextState.loadedObjectcards.byId[rdfId] = data;
}

export function objectcardErrorReceived(nextState, rdfId, error) {
    nextState.loadedObjectcards = Object.assign({}, nextState.loadedObjectcards);
    nextState.loadedObjectcards.fetched = Object.assign({}, nextState.loadedObjectcards.fetched);
    nextState.loadedObjectcards.fetched[rdfId] = true;
    nextState.loadedObjectcards.byId = Object.assign({}, nextState.loadedObjectcards.byId);
    nextState.loadedObjectcards.byId[rdfId] = null;
}

export function predicateReceived(nextState, fieldId, predicateName, data) {
    nextState.loadedFields = Object.assign({}, nextState.loadedFields);
    nextState.loadedFields.idByPredicateName = Object.assign({}, nextState.loadedFields.idByPredicateName);
    nextState.loadedFields.idByPredicateName[predicateName] = fieldId;
    nextState.loadedPredicates = Object.assign({}, nextState.loadedPredicates);
    nextState.loadedPredicates.byName = Object.assign({}, nextState.loadedPredicates.byName);
    nextState.loadedPredicates.byName[predicateName] = data;
    nextState.loadedEnumerations = Object.assign({}, nextState.loadedEnumerations);
    parsePredicate(nextState.loadedEnumerations, data);
}

function parseByType(value, type) {
    switch (type) {
        case TYPE.NUMBER:
            value = Number(value);
            break;
        case TYPE.BOOLEAN:
            value = value == "true";
            break;
        default:
            break;
    }
    return value;
}

function getPredicateName(field) {
    if (field.joinId) {
        return field.joinId + "[" + field.predicate + "]";
    }
    return field.predicate;
}

function getClassId(level, classObject) {
    let classId;
    let className = classObject.className;
    if (typeof level.joinId != "undefined" && level.joinId != null) {
        classId = level.joinId + "[" + className + "]";
    } else {
        classId = className;
    }
    return classId;
}

function getFieldName(finder, field) {
    if (!field) {
        return "";
    }
    let fieldName = field.label;
    while (field.parentId) {
        field = finder.loadedFields.byId[field.parentId];
        fieldName = field.label + "/" + fieldName;
    }
    return fieldName;
}

function getEnumerationName(finder, enumeration) {
    if (!enumeration) {
        return "";
    }
    let fieldName = enumeration.label;
    while (enumeration.parentId) {
        enumeration = finder.loadedEnumerations.byId[enumeration.parentId];
        fieldName = enumeration.label + "/" + fieldName;
    }
    return fieldName;
}

function findRelationNameByValue(relationArray, value) {
    if (value == null) {
        return relationArray[0].label;
    }
    for (let relation of relationArray) {
        if (relation.value == value) {
            return relation.label;
        }
    }
    return "";
}

function getRelationValue(value, type, messages) {
    /* Check if relation type isn't simple - parse relation */
    switch (type) {
        case "between":
            let result = "";
            if (value.from != null) {
                result += messages["MSG_FROM"] + ": " + value.from + "   ";
            }
            if (value.to != null) {
                result += messages["MSG_TO"] + ": " + value.to;
            }
            return result;
        default:
            return value;
    }
}

function createFinderQueryText(finder, messages) {
    let header = "";
    if (!finder.sideBar) {
        return null;
    }
    if (finder.sideBar.fragmentLevels && finder.sideBar.fragmentLevels.length > 0) {
        header += messages["FINDER_SELECTION_AREA"] + ":\n";
        for (let level of finder.sideBar.fragmentLevels) {
            if (level.selected.length == 0) {
                continue;
            }
            header += "\t" + level.name + ":\n";
            for (let selectedId of level.selected) {
                header += "\t\t" + finder.loadedFragments.byId[selectedId].label + "\n";
            }
        }
        header += "\n";
    }
    if (finder.sideBar.classLevels && finder.sideBar.classLevels.length > 0) {
        for (let level of finder.sideBar.classLevels) {
            if (level.selected.length == 0) {
                continue;
            }
            header += level.name + ":\n";
            for (let selectedId of level.selected) {
                header += "\t" + finder.loadedClasses.byId[selectedId].label + "\n";
            }
        }
        header += "\n";
    }
    if (finder.criteria && finder.criteria.allIds.length > 0) {
        header += messages["FINDER_SELECTION_CRITERIA"] + ":\n";
        for (let i = 0; i < finder.criteriaGroup.allIds.length; ++i) {
            let criteriaGroup = finder.criteriaGroup.byId[finder.criteriaGroup.allIds[i]];
            if (i > 0) {
                header += "=========================" + messages["MSG_OR"] + "=========================\n";
            }
            for (let criteriaId of criteriaGroup.criteriaList) {
                let criteria = finder.criteria.byId[criteriaId];
                if (!criteria.fieldId) {
                    continue;
                }
                let field = finder.loadedPredicates.byName[finder.loadedFields.byId[criteria.fieldId].joinIdPredicate];
                if (!field) {
                    continue;
                }
                let fieldType = field.type;
                let dataType = field.dataType;
                header += "\t" + getFieldName(finder, field) + ":\n";
                for (let relation of criteria.relations) {
                    let relationName;
                    let relationValue;
                    switch (fieldType) {
                        case TYPE.STRING:
                            if (!dataType) {
                                relationName = findRelationNameByValue(stringRelationTypeList, relation.type);
                                relationValue = getRelationValue(relation.value, relation.type, messages);
                                break;
                            }
                            switch (dataType.name) {
                                case "date": //date
                                    relationName = findRelationNameByValue(numberRelationTypeList, relation.type);
                                    relationValue = getRelationValue(relation.value, relation.type, messages);
                                    break;
                                default: //string
                                    relationName = findRelationNameByValue(stringRelationTypeList, relation.type);
                                    relationValue = getRelationValue(relation.value, relation.type, messages);
                                    break;
                            }
                            break;
                        case TYPE.NUMBER:
                            relationName = findRelationNameByValue(numberRelationTypeList, relation.type);
                            relationValue = getRelationValue(relation.value, relation.type, messages);
                            break;
                        case TYPE.DATE:
                            relationName = findRelationNameByValue(dateRelationTypeList, relation.type);
                            relationValue = getRelationValue(relation.value, relation.type, messages);
                            break;
                        case TYPE.BOOLEAN:
                            relationName = findRelationNameByValue(booleanRelationTypeList, "equal");
                            relationValue = messages[booleanRelationValueList[relation.value == "true" ? 0 : 1].label.props.id];
                            break;
                        case TYPE.FILE:
                            relationName = findRelationNameByValue(fileRelationTypeList, relation.type);
                            relationValue = getRelationValue(relation.value, relation.type, messages);
                            break;
                        case TYPE.FRAGMENT:
                            relationName = findRelationNameByValue(fragmentRelationTypeList, relation.type);
                            relationValue = getRelationValue(relation.value, relation.type, messages);
                            break;
                        case TYPE.ENUMERATION:
                            relationName = findRelationNameByValue(enumerationRelationTypeList, relation.type);
                            relationValue = getEnumerationName(finder, finder.loadedEnumerations.byId[finder.loadedEnumerations.idByPredicateName[relation.value]]);
                            break;
                        case TYPE.REFERENCE:
                        case TYPE.TABLE:
                        /* Reference data type may be class name string */
                        default:
                            relationName = findRelationNameByValue(referenceRelationTypeList, relation.type);
                            relationValue = "";
                            break;

                    }

                    header += "\t\t" + messages[relationName.props.id] + ": " + relationValue + "\n";
                }
            }
        }
    }
    if (header === "") {
        return null;
    }
    console.log(header)
    return header;
}

/* By reverse parsing find necessary fragments */
function getRequiredFragments(finder, lastFragments) {
    if (!finder.loadedFragments || !lastFragments) {
        return [];
    }
    let fragments = [];
    let fragmentMap = {};
    for (let fragmentId of lastFragments) {
        let fragment = finder.loadedFragments.byId[fragmentId];
        while (fragment && fragment.parentId) {
            fragment = finder.loadedFragments.byId[fragment.parentId];
            if (fragmentMap[fragment.id]) {
                break;
            }
            fragmentMap[fragment.id] = true;
            fragments.push(fragment.id);
        }
    }
    /* Reverse fragments for proper downloading order */
    return fragments.reverse();
}

/* By reverse parsing find necessary fields */
function getFieldFetchList(finder, predicates) {
    let fields = [];
    let fieldMap = {};
    for (let predicateName of predicates) {
        let fieldId = finder.loadedFields.idByPredicateName[predicateName];
        let field = finder.loadedFields.byId[fieldId];
        while (field && field.parentId) {
            field = finder.loadedFields.byId[field.parentId];
            if (!field || fieldMap[field.id]) {
                break;
            }
            fieldMap[field.id] = true;
            fields.push(field.id);
        }
    }
    /* Reverse fields for proper downloading order */
    return fields.reverse();
}

function setCriteriaValues(result, finder) {
    let allFieldsFilled = true;
    let predicates = [];
    /* Parse local values */
    for (let criteriaGroupId of finder.criteriaGroupList) {
        let criteriaGroup = finder.criteriaGroup.byId[criteriaGroupId];
        let orBlock = {
            andPredicates: []
        }
        result.orBlocks.push(orBlock);
        for (let criteriaId of criteriaGroup.criteriaList) {
            let criteria = finder.criteria.byId[criteriaId];
            if (!criteria.fieldId) {
                /* Selected field isn't last */
                continue;
            }

            let field = finder.loadedFields.byId[criteria.fieldId];
            predicates.push(field.predicate);

            let andPredicate = {
                predicate: field.predicate,
                orConditions: []
            }

            if (field.joinId) {
                andPredicate.predicate = field.joinId + "[" + andPredicate.predicate + "]";
            }

            orBlock.andPredicates.push(andPredicate);
            relationCycle: for (let relation of criteria.relations) {
                if (!relation.value && !isFileRelation[relation.type]) {
                    /* Relation value wasn't selected */
                    if (allFieldsFilled) {
                        allFieldsFilled = false;
                    }
                    continue;
                }
                let orCondition = {
                    operator: null,
                    parameters: []
                }

                andPredicate.orConditions.push(orCondition);

                /* Check if relation type isn't simple - parse relation */
                switch (relation.type) {
                    case fileRelationTypeList[0].value:
                    case fileRelationTypeList[1].value:
                        orCondition.operator = relation.type;
                        orCondition.parameters = null;
                        break;
                    case "between":
                        if (relation.value.from == null || relation.value.to == null) {
                            continue relationCycle;
                        }

                        let from = parseByType(relation.value.from, field.type);
                        let to = parseByType(relation.value.to, field.type);

                        orCondition.operator = relation.type;
                        orCondition.parameters.push(from);
                        orCondition.parameters.push(to);
                        break;
                    default:
                        let value = parseByType(relation.value, field.type);
                        /* Simple operator - just add it */
                        orCondition.operator = relation.type;
                        orCondition.parameters.push(value);
                }
            }
        }
    }

    /* Cleanup result */
    for (let i = result.orBlocks.length - 1; i >= 0; --i) {
        let orBlock = result.orBlocks[i];
        for (let j = orBlock.andPredicates.length - 1; j >= 0; --j) {
            let andPredicate = orBlock.andPredicates[j];
            for (let k = andPredicate.orConditions.length - 1; k >= 0; --k) {
                let orCondition = andPredicate.orConditions[k];
                if (orCondition.operator == null) {
                    andPredicate.orConditions.splice(k, 1);
                }
            }
            if (andPredicate.orConditions.length == 0) {
                orBlock.andPredicates.splice(j, 1);
                if (allFieldsFilled) {
                    allFieldsFilled = false;
                }
            }
        }
        if (orBlock.andPredicates.length == 0) {
            result.orBlocks.splice(i, 1);
        }
    }
    result.allFieldsFilled = allFieldsFilled;

    // if (result.orBlocks.length == 0) {
    //     console.log("Nothing is selected");
    //     return;
    // }
    result.criteriaFetchList = getFieldFetchList(finder, predicates);
}

export function getResult(finder, messages) {
    let result = {
        orBlocks: [
            /* {
                andPredicates: [
                    {
                        predicate: "",
                        orConditions: [
                            {
                                operator: "equal",
                                parameters: [0]
                            }
                        ]
                    }
                ]
            }
             */
        ]
    }

    if (finder.criteriaGroupList) {
        setCriteriaValues(result, finder);
    }

    result.classList = [];
    result.classLevelFetchList = [];
    if (finder.sideBar && finder.sideBar.classLevels) {
        for (let level of finder.sideBar.classLevels) {
            if (level.selected.length != 0) {
                result.classLevelFetchList.push(level.id);
            }
            for (let classShortId of level.selected) {
                let classObject = finder.loadedClasses.byId[classShortId];
                let classId = getClassId(level, classObject);
                result.classList.push(classId);
            }
        }
    }
    if (result.classList.length == 0) {
        result.classList = null;
    }

    let fragmentList = null;
    if (finder.sideBar && finder.sideBar.fragmentLevels) {
        for (let i = finder.sideBar.fragmentLevels.length - 1; i >= 0; --i) {
            let level = finder.sideBar.fragmentLevels[i];
            if (level.selected.length > 0) {
                fragmentList = level.selected.slice();
                break;
            }
        }
    }
    result.fragmentFetchList = getRequiredFragments(finder, fragmentList);
    result.fragmentList = fragmentList ? fragmentList : null;

    result.queryText = createFinderQueryText(finder, messages);
    return result;
}