/* Get minimal cell value */
export const minScaleValue = {
    "year": function (date) {
        let year = date.getFullYear();
        return Date.parse(year + 1) - Date.parse(year);
    },
    "month": function (date) {
        let preMonth = new Date(date.getFullYear(), date.getMonth()).getTime();
        let postMonth = new Date(date.getFullYear(), date.getMonth() + 1).getTime();
        return postMonth - preMonth;
    },
    "week": function (date) {
        return 604800000; //7 * 24 * 3600 * 1000
    },
    "day": function (date) {
        return 86400000; //24 * 3600 * 1000
    },
    "half-day": function (date) {
        return 43200000; //12 * 3600 * 1000
    },
    "hour": function (date) {
        return 3600000; //3600 * 1000
    }
}

export function isUndefined(variable) {
    return typeof variable === "undefined";
}

export function isDefined(variable) {
    return typeof variable !== "undefined";
}

/* get random number in range 0...max*/
export function getRandomInteger(max) {
    return Math.floor(Math.random() * (max + 1));
}

/* Create random RGB color*/
export function getRandomRGB() {
    return "rgb(" + getRandomInteger(255) + "," + getRandomInteger(255) + "," + getRandomInteger(255) + ")";
}

/* Add zero digit before number value*/
export function addZeroDigits(value, requiredLength) {
    while (value.toString().length < requiredLength) {
        value = "0" + value;
    }
    return value;
}

/* Parse object to array */
export function parseObjToArray(possibleObj) {
    if (!Array.isArray(possibleObj)) {
        return Object.keys(possibleObj).map(function (key) { return possibleObj[key]; });
    }
    return possibleObj;
}

/* getDay() returns 0 for sunday, so use +6%7 to get right correction*/
export function getCorrectDay(date) {
    return (date.getDay() + 6) % 7;
}

/* Transform date parameter to offsetLeft parameter in px*/
export function getPointOffset(dateLimits, dragLimits, point) {
    if (typeof dateLimits == "undefined") {
        return 0;
    }
    if (isNaN(Number(point))) {
        point = Date.parse(point);
    }
    var dataOffset = point - dateLimits.from;
    var limitsWidth = dateLimits.to - dateLimits.from;
    var widthMultipler = dataOffset / limitsWidth;
    /* Use floor to get rid of DOM roundind to floor*/
    return Math.floor(dragLimits.left + dragLimits.width * widthMultipler);
};

/* Create new color based on existing */
export function shadeBlendConvert(p, from, to) {
    if (typeof (p) != "number" || p < -1 || p > 1 || typeof (from) != "string" || (from[0] != 'r' && from[0] != '#') || (typeof (to) != "string" && typeof (to) != "undefined")) return null; //ErrorCheck
    let sbcRip = function (d) {
        var l = d.length, RGB = new Object();
        if (l > 9) {
            d = d.split(",");
            if (d.length < 3 || d.length > 4) return null;//ErrorCheck
            RGB[0] = i(d[0].slice(4)), RGB[1] = i(d[1]), RGB[2] = i(d[2]), RGB[3] = d[3] ? parseFloat(d[3]) : -1;
        } else {
            if (l == 8 || l == 6 || l < 4) return null; //ErrorCheck
            if (l < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (l > 4 ? d[4] + "" + d[4] : ""); //3 digit
            d = i(d.slice(1), 16), RGB[0] = d >> 16 & 255, RGB[1] = d >> 8 & 255, RGB[2] = d & 255, RGB[3] = l == 9 || l == 5 ? r(((d >> 24 & 255) / 255) * 10000) / 10000 : -1;
        }
        return RGB;
    }
    var i = parseInt, r = Math.round, h = from.length > 9, h = typeof (to) == "string" ? to.length > 9 ? true : to == "c" ? !h : false : h, b = p < 0, p = b ? p * -1 : p, to = to && to != "c" ? to : b ? "#000000" : "#FFFFFF", f = sbcRip(from), t = sbcRip(to);
    if (!f || !t) return null; //ErrorCheck
    if (h) return "rgb(" + r((t[0] - f[0]) * p + f[0]) + "," + r((t[1] - f[1]) * p + f[1]) + "," + r((t[2] - f[2]) * p + f[2]) + (f[3] < 0 && t[3] < 0 ? ")" : "," + (f[3] > -1 && t[3] > -1 ? r(((t[3] - f[3]) * p + f[3]) * 10000) / 10000 : t[3] < 0 ? f[3] : t[3]) + ")");
    else return "#" + (0x100000000 + (f[3] > -1 && t[3] > -1 ? r(((t[3] - f[3]) * p + f[3]) * 255) : t[3] > -1 ? r(t[3] * 255) : f[3] > -1 ? r(f[3] * 255) : 255) * 0x1000000 + r((t[0] - f[0]) * p + f[0]) * 0x10000 + r((t[1] - f[1]) * p + f[1]) * 0x100 + r((t[2] - f[2]) * p + f[2])).toString(16).slice(f[3] > -1 || t[3] > -1 ? 1 : 3);
}

/* Returns week number in month at selected date moment */
export function getWeekNumber(date) {
    /* Get first day of selected month */
    let monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    let firstWeek = monthStart;
    let week = 0;
    let day = 0;
    /* Check if first week is belong to seelected month */
    if ((day = getCorrectDay(monthStart)) !== 0) {
        if (day < 4) {
            ++week;
        }
        firstWeek.setDate(firstWeek.getDate() + 7 - day);
    }
    let dateTime = date.getTime();
    let firstWeekTime = firstWeek.getTime();
    if (dateTime < firstWeekTime) {
        return week;
    }
    return week + Math.floor((dateTime - firstWeekTime) / 1000 / 3600 / 24 / 7) + 1;
}


/* Find nearest appropriate date for point
 * @param roundingType = [floor, auto, ceil] - type of rounding 
 * @param pointType = [from, to] */
export function roundDate(state, point, roundingType = "auto", pointType = null) {
    var date = new Date(point);
    /* Set timezone offset*/
    var timezoneOffset = 0;
    //var timezoneOffset = -date.getTimezoneOffset() * 60 * 1000;
    /* Minimum scale value*/
    var value = minScaleValue[state.rounding](date);
    /* round date to first day of week */
    if (state.rounding === "week") {
        date.setDate(date.getDate() - getCorrectDay(date));
    }
    /* Specify stringDate until get to needed scale*/
    var stringDate = date.getFullYear();
    /* Use like goto*/
    createStringDate: {
        if (state.rounding === "year") {
            let month = date.getMonth();
            if (month > 5) {
                date.setDate(1);
                date.setFullYear(date.getFullYear() + 1);
            }
            stringDate = date.getFullYear() + "/01/01";
            break createStringDate;
        }
        if (state.rounding === "month") {
            let day = date.getDate();
            if (day > 15) {
                date.setDate(1);
                date.setMonth(date.getMonth() + 1);
            }
            stringDate = date.getFullYear() + "/" + addZeroDigits(date.getMonth() + 1, 2) + "/01";
            break createStringDate;
        }
        stringDate = stringDate + "/" + addZeroDigits(date.getMonth() + 1, 2);
        stringDate = stringDate + "/" + addZeroDigits(date.getDate(), 2);
        /* Next scales use locale timezone*/
        timezoneOffset = 0;
        if (state.rounding === "day") {
            stringDate = stringDate + " 0:";
            break createStringDate;
        } else if (state.rounding === "half-day") {
            stringDate = stringDate + " 12:";
            break createStringDate;
        } else if (state.rounding === "hour") {
            stringDate = stringDate + " " + date.getHours() + ":";
            break createStringDate;
        }
    }
    var roundedDate = new Date(Date.parse(stringDate) - timezoneOffset);
    var roundedTime = roundedDate.getTime();
    var difference = roundedTime - point;
    if (difference == 0) {
        return roundedTime;
    }
    if (Math.abs(difference) > value / 2) {
        roundedTime -= value * Math.sign(difference);
    }
    difference = roundedTime - point;
    if (roundingType != "auto") {
        let roundingSign = 1;
        if (roundingType == "floor") {
            roundingSign *= -1;
        }
        if (pointType == "from") {
            console.log(roundedDate, new Date(roundedTime), new Date(point))
            roundingSign *= -1;
        }
        if (roundingSign != Math.sign(difference)) {
            roundedTime += value * roundingSign;
        }
    }
    return roundedTime;
}

/* Get data of element by parsing it's location and width*/
export function getData(state, dragLimits, left, right) {
    var data = Object.assign({});
    var dateWidth = state.dateLimits.to - state.dateLimits.from;

    var fromOffset = left - dragLimits.left;
    var fromMultipler = fromOffset / dragLimits.width;
    var originalFrom = state.dateLimits.from + dateWidth * fromMultipler;
    data.from = roundDate(state, state.dateLimits.from + dateWidth * fromMultipler, state.roundingType, "from");

    var dateShift = data.from - originalFrom;

    var toOffset = right - dragLimits.left;
    var toMultipler = toOffset / dragLimits.width;
    data.to = roundDate(state, state.dateLimits.from + dateWidth * toMultipler + dateShift, state.roundingType, "to");

    return data;
};

/* Transform x parameter in px to date parameter*/
export function getPointDate(dateLimits, dragLimits, point) {
    var pointOffset = point - dragLimits.left;
    var limitsWidth = dragLimits.right - dragLimits.left;
    var widthMultipler = pointOffset / limitsWidth;
    /* Use floor to get rid of DOM roundind to floor*/
    return Math.floor(dateLimits.from + (dateLimits.to - dateLimits.from) * widthMultipler);
};

/* Returns value within object by connectedValue link */
/* FIXME? */
function getConnectedValue({ data, connectedValue, dataType }) {
    let path = connectedValue.split("/");
    let currentObject = data;
    let value;
    let i = 0;
    for (; i < path.length; ++i) {
        if (Array.isArray(currentObject)) {
            if (currentObject.length == 0) {
                break;
            }
            let parsed = false;
            switch (path[i]) {
                case "first":
                    currentObject = currentObject[0];
                    parsed = true;
                    break;
                case "last":
                    currentObject = currentObject[currentObject.length - 1];
                    parsed = true;
                    break;
            }
            if (parsed) {
                continue;
            }
        }
        if (typeof currentObject[path[i]] == "undefined") {
            break;
        }
        currentObject = currentObject[path[i]];
    }
    if (i == path.length) {
        if (dataType.toLowerCase() == "date") {
            return moment(currentObject).format('L');
        }
        return currentObject;
    }
    return "";
}

/* Get value of header field with all extended bindings */
export function getInnerValue(row, header) {
    let innerValue;
    if (header.connectedValue) {
        innerValue = getConnectedValue({ data: row, connectedValue: header.connectedValue, dataType: header.dataType });
    } else if (row[header.field]) {
        if (header.formatter) {
            innerValue = header.formatter(row[header.field], row);
        } else {
            innerValue = row[header.field];
        }
    } else {
        innerValue = "";
    }
    if (header.textBindings) {
        innerValue = header.textBindings(innerValue, row);
    }
    return innerValue;
}