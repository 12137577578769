import React from 'react';

class Listener extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.values && nextProps.values && this.props.values != nextProps.values) {
            for (let predicateId in nextProps.values) {
                if (nextProps.values[predicateId] != this.props.values[predicateId]) {
                    nextProps.listenerPredicateChanged(predicateId, nextProps.values[predicateId]);
                }
            }
        }
    }

    render() {
        return null;
    }
}

export default Listener;