/**
 * Service to manage local storage data
 * Current storage structure:
 *   stringified json data of stored objectcard view history for specified className
 *   [objectcardClassName_history]: [
 *     {
 *       timestamp: string
 *       $rdfId: string
 *       $namespace: string | null
 *       $class: string
 *       $classId: number
 *       $label: string
 *     }
 *   ]
 */

/**Main common function to store any item into storage */
export function storeLocalItem(key, value) {
    if (typeof value != 'string') {
        console.warn(`Value will not be saved in storage. Only strings can be stored in local storage, but have: '${typeof value}'`, value);
        return;
    }
    localStorage.setItem(key, value);
}

/**Main common function to get any item from storage */
export function getLocalItem(key) {
    if (typeof key != 'string') {
        console.warn(`Invalid storage key value. Only strings can be keys in local storage, but have: '${typeof key}'`, key);
        return;
    }
    return localStorage.getItem(key);
}

/***********************************
 *  Objectcard-specified handlers  *
 ***********************************/

let historyMaxDepth = 5;

export function setHistoryMaxDepth(depth) {
    if (typeof depth != "number") {
        console.warn(`Wrong new objectcard history depth value:`, depth);
        return;
    }
    historyMaxDepth = depth;
}

function getObjectcardStorageKey(className) {
    return className + "_history";
}

/**Store objectard key data into view history by class name */
export function storeObjectcardHistory(objectcard) {
    if (!objectcard) {
        console.warn(`Wrong objectcard to store in local storage:`, objectcard);
        return;
    }
    if (typeof objectcard.$class != "string" || objectcard.$class == "") {
        console.warn(`Objectcard have incorrect class and cannot be stored:`, objectcard);
        return;
    }
    if (typeof objectcard.$classId != "number") {
        console.warn(`Objectcard have incorrect class id and cannot be stored:`, objectcard);
        return;
    }
    if (typeof objectcard.$rdfId != "string" || objectcard.$rdfId == "") {
        console.warn(`Objectcard have incorrect rdfId and cannot be stored:`, objectcard);
        return;
    }
    const historyData = {
        timestamp: new Date().toISOString(),
        $rdfId: objectcard.$rdfId,
        $class: objectcard.$namespace || null,
        $class: objectcard.$class,
        $classId: objectcard.$classId,
        $label: objectcard.$label
    }
    /**Get history and remove item if it was already stored in history */
    const viewHistory = getObjectcardHistory(historyData.$class).filter(data => data.$rdfId != historyData.$rdfId);
    viewHistory.unshift(historyData);
    const storageKey = getObjectcardStorageKey(historyData.$class);
    storeLocalItem(storageKey, JSON.stringify(viewHistory.slice(0, historyMaxDepth)));
}

/**Get objectard key data from view history by class name */
export function getObjectcardHistory(className) {
    if (typeof className != 'string') {
        console.warn(`Wrong class name to get local storage objectcard history:`, className);
        return null;
    }
    const storageKey = getObjectcardStorageKey(className);
    const stringifiedData = getLocalItem(storageKey);
    if (!stringifiedData) {
        return [];
    }
    return JSON.parse(stringifiedData);
}