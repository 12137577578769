import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {
    CLASSCARD_DATA_TYPE_OBJECT,
    CLASSCARD_DATA_TYPE_OBJECTS,
    CLASSCARD_CLASS_HAVE_NO_PREDICATES,
    CLASSCARD_ID_OF_PREDICATE,
    CLASSCARD_NAME_OF_PREDICATE,
    CLASSCARD_LABEL_OF_PREDICATE,
    CLASSCARD_NAMESPACE_OF_PREDICATE,
    CLASSCARD_TYPE_OF_DATA,
    CLASSCARD_MULTIPLICITY,
    CLASSCARD_LAYOUT_COLUMN,
    CLASSCARD_NO_LAYOUT,
    CLASSCARD_EDIT,
    VIEW_EDITOR_URL,
    PREDICATE_BASE_URL
} from '../../../constants/classcard';
import { printBadge, parsePredicate } from '../../../services/classcard';

const predicateOptions = {
    noDataText: CLASSCARD_CLASS_HAVE_NO_PREDICATES
};

const PRIMITIVE_STEREOTYPE = "Primitive";

class PredicateTable extends React.Component {

    constructor(props) {
        super(props);

        this.predicateList = [];
        this.parsePredicateList(props, props.predicateList);

        this.predicateLabelFormatter = this.predicateLabelFormatter.bind(this);
        this.predicateNameFormatter = this.predicateNameFormatter.bind(this);
        this.dataTypeFormatter = this.dataTypeFormatter.bind(this);
        this.dataTypeSorter = this.dataTypeSorter.bind(this);
        this.multiplicityTypeFormatter = this.multiplicityTypeFormatter.bind(this);
        this.layoutFormatter = this.layoutFormatter.bind(this);
        this.reversePredicateFormatter = this.reversePredicateFormatter.bind(this);
    }

    labelClick(row, reactEvent) {
        reactEvent.stopPropagation();
        reactEvent.preventDefault();
        if (this.props.disabled) {
            this.props.viewPredicate(row);
            return;
        }
        this.props.editPredicate(row);
    }

    predicateLabelFormatter(cell, row) {
        return (<a href="" style={{ cursor: "pointer" }} onClick={this.labelClick.bind(this, row)} >
            {cell}
        </a>)
    }

    predicateNameFormatter(cell, row) {
        return `${row.namespace.prefix}:${cell}`
    }

    dataTypeFormatter(cell, row) {
        if (cell.primitive !== false) {
            return cell.label;
        }
        return (<a href={`#/?id=${cell.id}`}>
            {cell.namespace.prefix}:{cell.name}
        </a>);
    }

    dataTypeSorter(a, b, order, field) {   // order is desc or asc
        let stringA = a[field];
        let stringB = b[field];
        if (typeof stringA == "object") {
            stringA = stringA.namespace.prefix + ":" + stringA.name;
        }
        if (typeof stringB == "object") {
            stringB = stringB.namespace.prefix + ":" + stringB.name;
        }
        stringA = stringA.toLowerCase();
        stringB = stringB.toLowerCase();
        if (stringA === stringB) {
            return 0;
        }
        if (order === 'desc') {
            return stringA < stringB ? 1 : -1;
        }
        return stringA >= stringB ? 1 : -1;
    }

    multiplicityTypeFormatter(cell, row) {
        return this.props.multiplicityByValue[cell] && this.props.multiplicityByValue[cell].template;
    }

    addLayout(contextPath, link, reactEvent) {
        reactEvent.stopPropagation();
        this.props.addLayout(contextPath, link);
    }

    layoutFormatter(cell, row) {
        let layoutLink = `${this.props.contextPath}${VIEW_EDITOR_URL}#${PREDICATE_BASE_URL}/${row.namespace.prefix}/${row.name.replace(/\./g, "/")}/layout`;
        if (!cell || !row.namespace) {
            return <a className="btn btn-secondary" href={layoutLink}>
                <i className="fa fa-eye fa-fw" aria-hidden="true"></i>
                <FormattedMessage id="CLASSCARD_ADD_LAYOUT" />
            </a>;
        }
        return printBadge(layoutLink, "primary", "CLASSCARD_LAYOUT");
    }

    reversePredicateFormatter(cell, row) {
        if (!cell) {
            return null;
        }
        return (<a href="" style={{ cursor: "pointer" }} onClick={this.labelClick.bind(this, cell)} >
            {cell.namespace.prefix}:{cell.name}
        </a>)
    }

    parsePredicateList(props, dataList) {
        this.predicateList = dataList.slice();
        for (let i = 0; i < this.predicateList.length; ++i) {
            this.predicateList[i] = parsePredicate(this.predicateList[i]);
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.predicateList != nextProps.predicateList) {
            this.parsePredicateList(nextProps, nextProps.predicateList);
        }
    }

    render() {
        let predicateSelectRowProp;
        if (this.props.noSelection) {
            predicateSelectRowProp = {
                mode: 'none'
            };
        } else {
            predicateSelectRowProp = {
                mode: 'checkbox',
                bgColor: '#e4e4e4',
                clickToSelect: true,
                onSelect: (row, isSelected) => {
                    isSelected ? this.props.selectRows([row]) : this.props.deselectRows([row]);
                },
                onSelectAll: (isSelected, rows) => {
                    isSelected ? this.props.selectRows(rows) : this.props.deselectRows(rows);
                },
                selected: this.props.selectedRows
            };
        }
        return <BootstrapTable
            version='4'
            className={"columns-normal-wrap"}
            maxHeight={this.props.height}
            data={this.predicateList}
            striped={true}
            hover={true}
            options={predicateOptions}
            selectRow={predicateSelectRowProp}
            keyField='id'>
            <TableHeaderColumn dataField="id" dataSort={true}>
                {CLASSCARD_ID_OF_PREDICATE}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="label" dataSort={true} dataFormat={this.predicateLabelFormatter}>
                {CLASSCARD_LABEL_OF_PREDICATE}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort={true} dataFormat={this.predicateNameFormatter}>
                {CLASSCARD_NAME_OF_PREDICATE}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="dataType" dataSort={true} dataFormat={this.dataTypeFormatter} sortFunc={this.dataTypeSorter}>
                {CLASSCARD_TYPE_OF_DATA}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="multiplicity" dataSort={true} dataFormat={this.multiplicityTypeFormatter}>
                {CLASSCARD_MULTIPLICITY}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="layout" dataFormat={this.layoutFormatter}>
                {CLASSCARD_LAYOUT_COLUMN}
            </TableHeaderColumn>
        </BootstrapTable>
    }
}

export default PredicateTable;