import { FormattedMessage } from 'react-intl';

//Actions
export const LIST_RECEIVED = "@@CACHE_LIST_RECEIVED";
export const ERROR_RECEIVED = "@@CACHE_ERROR_RECEIVED";

//Global periodic scripts state path
export const CACHE = "cache";

//Common messages
export const CACHE_CONFIRM_CLEAR_TITLE = <FormattedMessage
    id="CACHE_CONFIRM_CLEAR_TITLE"
    defaultMessage='Confirm clear of cache'
    description="Cache clear popup title" />

export const CACHE_CONFIRM_CLEAR_BODY = <FormattedMessage
    id="CACHE_CONFIRM_CLEAR_BODY"
    defaultMessage='Are you sure you want to clear cache'
    description="Cache clear popup body" />

export const CACHE_CLEAR_SUCCESS = <FormattedMessage
    id="CACHE_CLEAR_SUCCESS"
    defaultMessage='Cache "{id}" was succesfully cleared'
    description="Success cache clear alert" />

export const CACHE_CLEAR_ERROR = <FormattedMessage
    id="CACHE_CLEAR_ERROR"
    defaultMessage='Error occured while clearing cache "{id}"'
    description="Error cache clear alert" />

export const CACHE_CLEAR_ALL_SUCCESS = <FormattedMessage
    id="CACHE_CLEAR_ALL_SUCCESS"
    defaultMessage='All cache were succesfully cleared'
    description="Success cache clear alert" />

export const CACHE_CLEAR_ALL_ERROR = <FormattedMessage
    id="CACHE_CLEAR_ALL_ERROR"
    defaultMessage='Error occured while clearing cache'
    description="Error cache clear alert" />

//Cache labels
export const CACHE_MENU = <FormattedMessage
    id="CACHE_MENU"
    defaultMessage='Menu cache'
    description="Menu cache" />

export const CACHE_TABLE_HEADER = <FormattedMessage
    id="CACHE_TABLE_HEADER"
    defaultMessage='Table header cache'
    description="Table header cache" />

export const CACHE_TABLE_DATA = <FormattedMessage
    id="CACHE_TABLE_DATA"
    defaultMessage='Table data cache'
    description="Table data cache" />

export const CACHE_FINDER_SOURCE_INFO = <FormattedMessage
    id="CACHE_FINDER_SOURCE_INFO"
    defaultMessage='Finder cache'
    description="Finder cache" />

export const CACHE_TREE_DATA = <FormattedMessage
    id="CACHE_TREE_DATA"
    defaultMessage='Tree data cache'
    description="Tree data cache" />

export const CACHE_TREE_NODE = <FormattedMessage
    id="CACHE_TREE_NODE"
    defaultMessage='Tree node cache'
    description="Tree node cache" />

export const CACHE_TREE_SUBJECT = <FormattedMessage
    id="CACHE_TREE_SUBJECT"
    defaultMessage='Tree subject cache'
    description="Tree subject cache" />

export const CACHE_TREE_CHILDREN = <FormattedMessage
    id="CACHE_TREE_CHILDREN"
    defaultMessage='Tree children cache'
    description="Tree children cache" />

export const CACHE_TREE_PARENTS = <FormattedMessage
    id="CACHE_TREE_PARENTS"
    defaultMessage='Tree parents cache'
    description="Tree parents cache" />

export const CACHE_CRITERIA_TREE_DATA = <FormattedMessage
    id="CACHE_CRITERIA_TREE_DATA"
    defaultMessage='Criteria tree cache'
    description="Criteria tree cache" />

export const CACHE_FRAGMENT_TREE_DATA = <FormattedMessage
    id="CACHE_FRAGMENT_TREE_DATA"
    defaultMessage='Fragment tree cache'
    description="Fragment tree cache" />

export const CACHE_CLASS_TREE_DATA = <FormattedMessage
    id="CACHE_CLASS_TREE_DATA"
    defaultMessage='Class tree cache'
    description="Class tree cache" />

export const CACHE_CLASS_INFO = <FormattedMessage
    id="CACHE_CLASS_INFO"
    defaultMessage='Class info cache'
    description="Class info cache" />

export const CACHE_SIMPLE_CLASS_INFO = <FormattedMessage
    id="CACHE_SIMPLE_CLASS_INFO"
    defaultMessage='Simple class info cache'
    description="Simple class info cache" />

export const CACHE_RELATION_BINDINGS = <FormattedMessage
    id="CACHE_RELATION_BINDINGS"
    defaultMessage='Relations cache'
    description="Relations cache" />

export const CACHE_PREDICATE_BINDINGS = <FormattedMessage
    id="CACHE_PREDICATE_BINDINGS"
    defaultMessage='Predicates cache'
    description="Predicates cache" />

export const CACHE_ENCODED_LOGIN_INFO = <FormattedMessage
    id="CACHE_ENCODED_LOGIN_INFO"
    defaultMessage='Encoded login cache'
    description="Encoded login cache" />

export const CACHE_USERS_BY_NAME = <FormattedMessage
    id="CACHE_USERS_BY_NAME"
    defaultMessage='Users cache'
    description="Users cache" />

export const CACHE_LOGIN_INFO = <FormattedMessage
    id="CACHE_LOGIN_INFO"
    defaultMessage='Login cache'
    description="Login cache" />

export const CACHE_IOT_EVENT = <FormattedMessage
    id="CACHE_IOT_EVENT"
    defaultMessage='IOT event cache'
    description="IOT event cache" />

export const CACHE_SUBJECT_ID = <FormattedMessage
    id="CACHE_SUBJECT_ID"
    defaultMessage='Subject id cache'
    description="Subject id cache" />

export const CACHE_QUERY = <FormattedMessage
    id="CACHE_QUERY"
    defaultMessage='Query cache'
    description="Query cache" />
