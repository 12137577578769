import React from 'react';

class Toolbar extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="btn-toolbar" role="toolbar" aria-label="...">
                <span className="pull-right">
                    <button type="button" className="btn btn-secondary btn-sm" data-toggle="tooltip" data-placement="bottom" data-original-title="Новый объект">
                        <em className="fa fa-fw fa-plus" />
                    </button>
                </span>
            </div>);
    }
}

export default Toolbar;