import { connect } from 'react-redux';
import RPASetpoints from './components/rpasetpoints/container.jsx'
import rpaSetpointsReducer from './reducers/rpasetpoints';
import * as ObjectcardConstants from './constants/objectcard';
import * as LocationConstants from './constants/location';
import * as I18NConstants from './constants/i18n';
import * as SecurityConstants from './constants/security';
import { RPA_SETPOINTS, SOURCE, TARGET } from './constants/rpasetpoints'
import {
    uploadSetpoints,
    uploadSignals,
    changeHash,
    unbindSetpoints,
    bindSetpoints,
    changeParameter,
    changeTab,
    changeConfirmationRequired,
    changeBindedFlag,
    changeDetailedView,
    changeFilter,
    changeDetailsParameter,
    saveToFileSystem,
    saveToServer,
    updateSetpointsFile,
    updateSignalsFile,
    importFile,
    confirmFileReset,
    fileReset,
    loadFromServer,
    setOptions,
    loadCommonSetpoints,
    loadCommonSignals,
    cancelRPASetpointsUpdates,
    showLinkedElement,
    showConfig
} from './actions/rpasetpoints';
import { getTabTree, getVendorShortName, getVoltageLevel } from './services/rpasetpoints'
import { obtainData } from './services/automation.js';

const ATTR_RPA_SETPOINTS = "rpa-setpoints-linker";

function createMapStateToProps(_, initialProps) {
    const { objectcard, node } = initialProps;
    return function (globalState) {
        let disabled = false;
        let embedded = false;
        let vendor = null;
        let voltage = null;
        if (objectcard) {
            const card = globalState[ObjectcardConstants.OBJECTCARD];
            const data = card.data[objectcard];
            const serverLock = data && data.$lock; //card lock status
            const serverLockReady = serverLock && serverLock.status;
            disabled = !serverLockReady;
            embedded = true;
            if (node && node.options.vendor) {
                let vendorName = obtainData(node.options.vendor, data) || null;
                if (vendorName != null && typeof vendorName == "object") {
                    vendorName = vendorName.$label;
                }
                if (vendorName) {
                    vendor = getVendorShortName(vendorName);
                }
            }
            if (node && node.options.voltage) {
                let voltageLevel = obtainData(node.options.voltage, data) || null;
                if (voltageLevel != null && typeof voltageLevel == "object") {
                    voltageLevel = voltageLevel.$label;
                }
                if (voltageLevel) {
                    voltage = getVoltageLevel(voltageLevel);
                }
            }
        }
        return {
            hashParams: globalState[LocationConstants.LOCATION].params,
            locale: globalState[I18NConstants.I18N].locale,
            messages: globalState[I18NConstants.I18N].messages,
            superUser: globalState[SecurityConstants.SECURITY].superUser,
            filter: globalState[RPA_SETPOINTS].filter,
            file: globalState[RPA_SETPOINTS].file,
            activeTab: globalState[RPA_SETPOINTS].activeTab,
            source: getTabTree(globalState[RPA_SETPOINTS], SOURCE),
            target: getTabTree(globalState[RPA_SETPOINTS], TARGET),
            uploadState: globalState[RPA_SETPOINTS].uploadState,
            state: globalState[RPA_SETPOINTS].state,
            primary: globalState[RPA_SETPOINTS].primary,
            withoutConfirmation: globalState[RPA_SETPOINTS].withoutConfirmation,
            bindedFlag: globalState[RPA_SETPOINTS].bindedFlag,
            detailedView: globalState[RPA_SETPOINTS].detailedView,
            bayType: globalState[RPA_SETPOINTS].bayType,
            bayTypeList: globalState[RPA_SETPOINTS].bayTypeList,
            disabled,
            embedded,
            vendor,
            voltage,
            objectcard
        };
    }
}

function createMapDispachToProps() {
    return function (dispatch) {
        return {
            changeHash: function (params) {
                dispatch(changeHash(params));
            },
            changeParameter: function (params) {
                dispatch(changeParameter(params));
            },
            uploadSetpoints: function (fileData, vendor, bayType, encode) {
                dispatch(uploadSetpoints(fileData, vendor, bayType, encode));
            },
            uploadSignals: function (fileData, encode) {
                dispatch(uploadSignals(fileData, encode));
            },
            confirmFileReset: function () {
                dispatch(confirmFileReset());
            },
            fileReset: function () {
                dispatch(fileReset());
            },
            loadFromServer: function (file) {
                dispatch(loadFromServer(file));
            },
            setOptions: function (options) {
                dispatch(setOptions(options));
            },
            loadCommonSetpoints: function () {
                dispatch(loadCommonSetpoints());
            },
            loadCommonSignals: function () {
                dispatch(loadCommonSignals());
            },
            unbindSetpoints: function () {
                dispatch(unbindSetpoints());
            },
            bindSetpoints: function () {
                dispatch(bindSetpoints());
            },
            changeTab: function (tabId) {
                dispatch(changeTab(tabId));
            },
            changeConfirmationRequired: function () {
                dispatch(changeConfirmationRequired());
            },
            changeBindedFlag: function () {
                dispatch(changeBindedFlag());
            },
            changeDetailedView: function () {
                dispatch(changeDetailedView());
            },
            changeFilter: function (value) {
                dispatch(changeFilter(value));
            },
            changeDetailsParameter: function (field, value) {
                dispatch(changeDetailsParameter(field, value));
            },
            showLinkedElement: function (nodeId, sourceId) {
                dispatch(showLinkedElement(nodeId, sourceId));
            },
            saveToFileSystem: function () {
                dispatch(saveToFileSystem());
            },
            saveToServer: function (embedded) {
                dispatch(saveToServer(embedded));
            },
            updateSetpointsFile: function () {
                dispatch(updateSetpointsFile());
            },
            updateSignalsFile: function () {
                dispatch(updateSignalsFile());
            },
            importFile: function () {
                dispatch(importFile());
            },
            showConfig: function () {
                dispatch(showConfig());
            },
            cancel: function () {
                dispatch(cancelRPASetpointsUpdates());
            }
        }
    }
}

export function createRPASetpointsContainer() {
    return connect(
        createMapStateToProps,
        createMapDispachToProps()
    )(RPASetpoints);
}

export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(RPA_SETPOINTS, rpaSetpointsReducer);
    //Register components
    dispatcher.registerComponent(ATTR_RPA_SETPOINTS, (elm) => {
        let RPASetpointsContainer = createRPASetpointsContainer();
        return (<RPASetpointsContainer />);
    });
}