import * as Action from '../constants/eventtasks';
import { openModal, updateModal } from './modal';
import EventDetailsModal from '../components/eventtasks/eventdetailsmodal.jsx';
import { addAlert } from './alert';
import { ALERT_SUCCESS, ALERT_DANGER } from '../constants/alert';

//Path to scripts
const EVENT_TASKS_MAIN = "/rest/event/";
const EVENT_TASKS_LIST_URL = EVENT_TASKS_MAIN + "list";
const EVENT_TASKS_ENTITY_URL = EVENT_TASKS_MAIN + "entity";
const EVENT_TASKS_DELETE_URL = EVENT_TASKS_MAIN + "delete/";
const EVENT_TASKS_SOURCES_URL = EVENT_TASKS_MAIN + "sources";

const SCRIPTS_LIST_URL = "/rest/scripteditor/list";

/*******************
 * Utils funcitons *
 *******************/
/* Convert data to appropriate format */
function prepareData(data) {
    for (let row of data) {
        prepareSingleEvent(row);
    }
    return data;
}

function prepareSingleEvent(event) {
    event.key = event.id;
    event.server = event.server || Action.LOCAL_SERVER_KEY;
    return event;
}

function prepareEventToSave(event) {
    let server = event.server;
    if (!server || server == Action.LOCAL_SERVER_KEY) {
        server = null;
    }
    return {
        id: event.id,
        scriptId: event.scriptId,
        event: event.event,
        label: event.label,
        description: event.description,
        scriptPath: event.scriptPath,
        server: server
    }
}

function prepareScripts(scripts) {
    const preparedData = [];
    for (let path of scripts) {
        preparedData.push({ path: path });
    }
    return preparedData;
}

function prepareSources(sources) {
    const preparedData = [{ server: null, compositeName: null }];
    for (let source of sources) {
        source.compositeName = `${source.server} [${source.uri}]`;
        preparedData.push(source);
    }
    return sources;
}

/******************
 * Ajax functions *
 ******************/
function ajaxFetchData(dispatch) {
    return $.getJSON(EVENT_TASKS_LIST_URL, function (data) {
        dispatch(sendEventsData(prepareData(data)));
    }).fail(function (error) {
        dispatch(sendEventsError(error));
    });
}

function ajaxFetchScripts(dispatch) {
    return $.getJSON(SCRIPTS_LIST_URL, function (data) {
        dispatch(sendScriptsData(prepareScripts(data)));
    }).fail(function (error) {
        dispatch(sendScriptsError(error));
    });
}

function ajaxFetchSources(dispatch) {
    return $.getJSON(EVENT_TASKS_SOURCES_URL, function (data) {
        dispatch(sendSourcesData(prepareSources(data)));
    }).fail(function (error) {
        dispatch(sendSourcesError(error));
    });
}

function ajaxSaveEvent(dispatch, event) {
    return $.ajax({
        contentType: 'application/json',
        data: JSON.stringify(prepareEventToSave(event)),
        dataType: 'json',
        processData: false,
        type: 'POST',
        url: EVENT_TASKS_ENTITY_URL
    }).done(function (event) {
        if (event.id == null) {
            dispatch(addAlert(ALERT_SUCCESS, { id: "EVENT_TASKS_ADD_SUCCESS" }));
        } else {
            dispatch(addAlert(ALERT_SUCCESS, { id: "EVENT_TASKS_SAVE_SUCCESS" }));
        }
        dispatch(sendSingleEvent(prepareSingleEvent(event)));
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "EVENT_TASKS_SAVE_ERROR" }))
    });
}

function ajaxDeleteEvent(dispatch, event) {
    return $.ajax({
        type: 'DELETE',
        url: EVENT_TASKS_DELETE_URL + event.id
    }).done(function () {
        dispatch(addAlert(ALERT_SUCCESS, { id: "EVENT_TASKS_DELETE_SUCCESS" }));
        dispatch(sendDeletedEvent(event));
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "EVENT_TASKS_DELETE_ERROR" }))
    });
}

/*****************
 * Plain actions *
 *****************/
function sendEventsLoading() {
    return {
        type: Action.SEND_EVENTS_LOADING,
        payload: null
    }
}

function sendEventsData(data) {
    return {
        type: Action.SEND_EVENTS_DATA,
        payload: { data: data }
    }
}

function sendEventsError(error) {
    return {
        type: Action.SEND_EVENTS_ERROR,
        payload: { error: error }
    }
}

function sendScriptsData(data) {
    return {
        type: Action.SEND_SCRIPTS_DATA,
        payload: { data: data }
    }
}

function sendScriptsError(error) {
    return {
        type: Action.SEND_SCRIPTS_ERROR,
        payload: { error: error }
    }
}

function sendSourcesData(data) {
    return {
        type: Action.SEND_SOURCES_DATA,
        payload: { data: data }
    }
}

function sendSourcesError(error) {
    return {
        type: Action.SEND_SOURCES_ERROR,
        payload: { error: error }
    }
}

function sendSingleEvent(data) {
    return {
        type: Action.SEND_SINGLE_EVENT,
        payload: { data: data }
    }
}

function sendDeletedEvent(event) {
    return {
        type: Action.SEND_DELETED_EVENT,
        payload: { event: event }
    }
}

export function selectRow({ data, selected }) {
    return {
        type: Action.SELECT_ROW,
        payload: { data, selected }
    }
}

/***********
 * Actions *
 ***********/
export function fetchData() {
    return function (dispatch, getState) {
        dispatch(sendEventsLoading());
        ajaxFetchData(dispatch);
        ajaxFetchScripts(dispatch);
        ajaxFetchSources(dispatch);
    }
}

export function openEventModal(event) {
    return function (dispatch, getState) {
        const eventTasksState = getState()[Action.EVENTTASKS];
        let resultReceive = null;
        let modalDataReceive = null;
        const setResultReceiver = (receiver) => {
            resultReceive = receiver;
        }
        const setDataReceiver = (receiver) => {
            modalDataReceive = receiver;
        }
        const updateParentModal = () => {
            dispatch(updateModal("eventModal"));
        }
        const options = {
            title: { id: event ? "EVENT_TASKS_CHANGE_TITLE" : "EVENT_TASKS_ADD_TITLE" },
            body: <EventDetailsModal
                event={event}
                scriptList={eventTasksState.scriptList}
                scriptListError={eventTasksState.scriptListError}
                sourceList={eventTasksState.sourceList}
                sourceListError={eventTasksState.sourceListError}
                sourceByName={eventTasksState.sourceByName}
                setDataReceiver={setDataReceiver}
                setResultReceiver={setResultReceiver}
                updateParentModal={updateParentModal}
            />,
            result: resultReceive
        }
        dispatch(openModal("eventModal", "common", options, function (result) {
            const eventData = modalDataReceive ? modalDataReceive() : null;
            ajaxSaveEvent(dispatch, eventData);
        }));
    }
}

export function openDeleteModal() {
    return function (dispatch, getState) {
        const eventTasksState = getState()[Action.EVENTTASKS];
        const selectedRows = eventTasksState.selectedRows;
        if (!selectedRows.length) {
            return;
        }
        const options = {
            title: { id: "EVENT_TASKS_CONFIRM_DELETION_TITLE" },
            body: { id: "EVENT_TASKS_CONFIRM_DELETION_BODY", values: { count: selectedRows.length } }
        }
        dispatch(openModal("deletionConfirmModal", "confirm", options, function () {
            for (let row of selectedRows) {
                ajaxDeleteEvent(dispatch, row);
            }
        }));
    }
}