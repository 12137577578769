import { connect } from 'react-redux';
import { I18N } from './constants/i18n.js';
import { EVENTTASKS } from './constants/eventtasks.js';
import eventtasksReducer from './reducers/eventtasks.js';
import { fetchData, selectRow, openEventModal, openDeleteModal } from './actions/eventtasks.js';
import EventTasks from './components/eventtasks/eventtasks.jsx';

const ATTR_EVENT_TASKS = "event-tasks";

const EventTasksContainer = connect(
    (globalState) => {
        return {
            messages: globalState[I18N].messages,
            loading: globalState[EVENTTASKS].loading,
            data: globalState[EVENTTASKS].data,
            error: globalState[EVENTTASKS].error,
            sourceByName: globalState[EVENTTASKS].sourceByName,
            selectedRows: globalState[EVENTTASKS].selectedRows
        };
    },
    (dispatch) => {
        return {
            fetchData: function () {
                dispatch(fetchData());
            },
            selectRow: function ({ data, selected }) {
                dispatch(selectRow({ data, selected }));
            },
            openEventModal: function (event) {
                dispatch(openEventModal(event));
            },
            openDeleteModal: function () {
                dispatch(openDeleteModal());
            }
        }
    }
)(EventTasks);

//Initialization function
export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(EVENTTASKS, eventtasksReducer);

    //Register components
    dispatcher.registerComponent(ATTR_EVENT_TASKS, (elm) => {
        return (<EventTasksContainer />);
    })
};

