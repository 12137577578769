import React from 'react';
import { FormattedMessage } from 'react-intl';
import { aclChecker } from '../../services/security.js';
import { getFileName } from '../../services/reports.js';

function generateButtonContent(btn) {
    let icon = null;
    if (btn.icon) {
        icon = <i className={"fa fa-fw " + btn.icon} aria-hidden="true"></i>;
    }
    return [
        icon,
        btn.label
    ]
}

function getButtonClass(btn) {
    let btnClass = "btn btn-secondary card-toolbtn";
    if (btn.cls) {
        btnClass += ` ${btn.cls}`;
    }
    return btnClass;
}

class UserToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.aclChecker = aclChecker(props.generalAccessRules);
    }

    click(id) {
        this.props.click(id);
    }

    reportClick(id, href, params) {
        this.props.reportClick(id, href, params);
    }

    generateReportLink(btn, isButton) {
        if (!this.props.data || !this.props.data.$class || !this.props.data.$rdfId) {
            console.error("Can't get class data", btn);
        } else if (!btn.report) {
            console.error("Type of report is undefined", btn);
        } else if (!btn.file) {
            console.error("Template of report is undefined", btn);
        } else {
            const cp = this.props.contextPath ? this.props.contextPath : "/";
            let href = cp + "rest/subject/report/";
            const ns = this.props.data.$namespace;
            if (ns) {
                href += ns + "/";
            }
            href += this.props.data.$rdfId;
            const filename = getFileName(btn.label, btn.report);
            const params = {
                file: btn.file,
                filename: filename,
                type: btn.report
            };
            return <a key={btn.id} className={isButton ? getButtonClass(btn) : "dropdown-item"} onClick={this.reportClick.bind(this, btn.id, href, params)} target="_blank">
                {generateButtonContent(btn)}
            </a>
        }
    }

    recursiveBuildGroup(btn) {
        if (!this.aclChecker.checkAcl(btn)) {
            return null;
        }
        if (btn.type == "group") {
            if (!btn.group) {
                console.error("Group button must have parameter \"group\"", btn);
            } else {
                btn.group = this.aclChecker.checkGroupAcl(btn.group);
                if (btn.group.length == 0) {
                    return null;
                }
                return <div key={btn.id} className={"dropdown-item dropdown-submenu" + (btn.align == "left" ? " dropleft" : " dropright")}>
                    <a className="dropdown-toggle">
                        {generateButtonContent(btn)}
                    </a>
                    <div className="dropdown-container">
                        <div className="dropdown-menu">
                            {btn.group.map((sub_btn) => {
                                return this.recursiveBuildGroup(sub_btn);
                            })}
                        </div>
                    </div>
                </div>
            }
        } else if (btn.report) {
            return this.generateReportLink(btn);
        }
        return (<a className="dropdown-item" key={btn.id} onClick={this.click.bind(this, btn.id)}>
            {generateButtonContent(btn)}
        </a>);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.generalAccessRules != nextProps.generalAccessRules) {
            this.aclChecker.setGeneralAccessRules(nextProps.generalAccessRules);
        }
    }

    render() {
        if (!this.props.layout || !this.props.layout.toolbar || this.props.layout.toolbar.length == 0) {
            return null;
        }
        const buttonList = this.props.layout.toolbar.map((btn) => {
            if (!this.aclChecker.checkAcl(btn)) {
                return null;
            }
            if (btn.group) {
                btn.group = this.aclChecker.checkGroupAcl(btn.group);
                if (btn.group.length == 0) {
                    return null;
                }
                return (<div className="btn-group btn-group-xs">
                    <button key={btn.id} type="button" className={`${getButtonClass(btn)} dropdown-toggle`} data-toggle="dropdown">
                        {generateButtonContent(btn)}
                    </button>
                    <div className={"dropdown-menu" + (btn.align == "left" ? " pull-right" : "")}>
                        {btn.group.map((sub_btn) => {
                            return this.recursiveBuildGroup(sub_btn);
                        })}
                    </div>
                </div>);
            } else if (btn.report) {
                return this.generateReportLink(btn, true);
            }
            return (<button key={btn.id} type="button" className={getButtonClass(btn)} onClick={this.click.bind(this, btn.id)}>
                {generateButtonContent(btn)}
            </button>);
        });
        return buttonList;
    }
}

export default UserToolbar;


