import Dropzone from 'react-dropzone';
import ProgressBar from './progressbar.jsx'
import { FormattedMessage } from 'react-intl';

const dropZoneStyle = {
    style: {
        width: "auto",
        height: "100px",
        borderWidth: 2,
        borderColor: '#666',
        borderStyle: 'dashed',
        borderRadius: 5,
        cursor: "pointer"
    },
    activeStyle: {
        borderStyle: 'solid',
        backgroundColor: '#eee'
    },
    rejectStyle: {
        borderStyle: 'solid',
        backgroundColor: '#ffdddd'
    }
}

const previewStyle = {
    height: "auto",
    width: "auto",
    maxWidth: "100px",
    maxHeight: "100px"
}

class DropFile extends React.Component {
    constructor(props) {
        super(props);

        this.onDrop = this.onDrop.bind(this);
    }

    onDrop(acceptedFiles) {
        //Save for preview
        this.fileMap = {};
        for (let file of acceptedFiles) {
            this.fileMap[file.name] = file;
        }
        this.props.registerUpload({ files: acceptedFiles });
    }

    render() {
        if (this.props.filedrop.filesToUpload.length != 0) {
            return <ProgressBar {...this.props}></ProgressBar>;
        }
        return (
            <Dropzone multiple={this.props.multiple} onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)} accept={this.props.accept} {...dropZoneStyle}>
                <div>
                    <FormattedMessage
                        id="FILEDROP_DROP_FILE_HERE"
                        defaultMessage="Try to drop file here, or click to select file to upload"
                        description="Inform user how to upload file" />
                </div>
            </Dropzone>
        )
    }
}

export default DropFile;