import * as Action from '../constants/rpasetpoints';

import * as NPTTreeReducers from './npt-treebeard';
import { getTabTreeId, getTabTree } from '../services/rpasetpoints';
import { modifyCommonSetpointsView, modifyCommonSignalsView, getValueFromRawValue } from '../services/rpasetpoints-parser';
import { validate } from '../services/formatvalidator';

const initialState = {
    uploadState: {
        [Action.COMMON_SETPOINTS]: Action.FILE_SELECTION,
        [Action.COMMON_SIGNALS]: Action.FILE_SELECTION,
        [Action.SETPOINTS]: Action.FILE_SELECTION,
        [Action.SIGNALS]: Action.FILE_SELECTION,
    },
    vendor: null,
    voltage: null,
    bayType: null,
    bayTypeList: [],
    state: Action.FILE_SELECTION,
    activeTab: Action.TAB_ANALOG,
    primary: Action.SOURCE_TREE,
    withoutConfirmation: false,
    bindedFlag: false,
    filter: "",
    commonSetpoints: NPTTreeReducers.initialTreeState,
    commonAnalogSignals: NPTTreeReducers.initialTreeState,
    commonDiscreteSignals: NPTTreeReducers.initialTreeState,
    initialCommonSetpoints: NPTTreeReducers.initialTreeState,
    initialCommonAnalogSignals: NPTTreeReducers.initialTreeState,
    initialCommonDiscreteSignals: NPTTreeReducers.initialTreeState,
    setpoints: NPTTreeReducers.initialTreeState,
    analogSignals: NPTTreeReducers.initialTreeState,
    discreteSignals: NPTTreeReducers.initialTreeState,
    cachedState: {}
}

/***************
 *   Utility   *
 ***************/
function changeComparedSetpoint(nextState, treeType, treeId, sourceId, targetId) {
    let sourceTree = nextState[treeId];
    let reverseTree = nextState[Action.reverse[treeId]];
    sourceTree.nodeById[sourceId] = Object.assign({}, sourceTree.nodeById[sourceId]);
    let sourceNode = sourceTree.nodeById[sourceId];
    if (sourceNode.comparedId && reverseTree.nodeById[sourceNode.comparedId]) {
        reverseTree.nodeById[sourceNode.comparedId] = Object.assign({}, reverseTree.nodeById[sourceNode.comparedId]);
        const reverseNode = reverseTree.nodeById[sourceNode.comparedId];
        if (reverseNode.comparedId != sourceId) {
            reverseNode.comparedId = popValue(reverseNode.comparedId, sourceNode.id);
            if (treeType == Action.TARGET && reverseNode.linkedId) {
                reverseNode.linkedId = popValue(reverseNode.linkedId, sourceNode.linkedId);
            }
        }
    }
    sourceNode.comparedId = targetId;
    /* Change linkedId if changing tree is == SOURCE */
    if (treeType != Action.SOURCE) {
        return;
    }
    if (targetId == null) {
        sourceNode.linkedId = null;
        return;
    }
    if (reverseTree.nodeById[targetId] && reverseTree.nodeById[targetId].linkedId) {
        sourceNode.linkedId = reverseTree.nodeById[targetId].linkedId;
    }
}

/* Search for value in nodeValue and remove all matching rows */
function popValue(nodeValue, value) {
    if (!Array.isArray(nodeValue)) {
        nodeValue = [nodeValue];
    }
    for (let i = 0; i < nodeValue.length; ++i) {
        if (nodeValue[i] == value) {
            nodeValue.splice(i, 1);
            break;
        }
    }
    if (nodeValue.length == 1) {
        nodeValue = nodeValue[0]
    } else if (nodeValue.length == 0) {
        nodeValue = null;
    }
    return nodeValue;
}

function deselectBindedNode(tree) {
    if (!tree.active) {
        return;
    }
    let selectedNode = tree.nodeById[tree.active];
    if (!selectedNode.comparedId) {
        return;
    }
    tree.active = null;
}

function checkBindings(nextState, treeId) {
    const reverseId = Action.reverse[treeId];
    let sourceTree = nextState[treeId];
    let reverseTree = nextState[reverseId];
    for (let nodeId in sourceTree.nodeById) {
        const reverseNodeId = sourceTree.nodeById[nodeId].comparedId;
        if (reverseNodeId && reverseTree.nodeById[reverseNodeId]) {
            changeComparedSetpoint(nextState, Action.SOURCE, treeId, nodeId, reverseNodeId);
            changeComparedSetpoint(nextState, Action.TARGET, reverseId, reverseNodeId, nodeId);
        }
    }
}

function checkReady(state) {
    if (state.uploadState[Action.COMMON_SETPOINTS] != Action.READY
        || state.uploadState[Action.SETPOINTS] != Action.READY
        || state.uploadState[Action.COMMON_SIGNALS] != Action.READY
        || state.uploadState[Action.SIGNALS] != Action.READY) {
        return;
    }
    state.state = Action.READY;
}

function filterCommon(state) {
    if (state.activeTab != Action.TAB_SETPOINTS) {
        return;
    }
    let treeId = getTabTreeId(state, Action.TARGET_TREE, true);
    if (!state.filter || $.isEmptyObject(state.filter)) {
        state[treeId + "Filtered"] = null;
        return;
    }
    state[treeId + "Filtered"] = NPTTreeReducers.filterTreeHandler(state[treeId], { keyWords: state.filter.split(" ") });
}

function getLinkMap(tree) {
    let map = {};
    for (let nodeId in tree.nodeById) {
        let node = tree.nodeById[nodeId];
        if (!node.comparedId) {
            continue;
        }
        map[node.id] = {
            comparedId: node.comparedId,
            value: node.value,
            isEnum: node.isEnum
        }
    }
    return map;
}

function bindTreeFromMap(nextState, treeId, map) {
    const reverseId = Action.reverse[treeId];
    let sourceTree = nextState[treeId];

    for (let nodeId in sourceTree.nodeById) {
        let node = sourceTree.nodeById[nodeId];
        if (!map[node.id]) {
            continue;
        }
        const nodeId = node.id;
        const comparedId = map[node.id].comparedId;
        if (map[node.id].value) {
            const sourceNode = nextState[treeId].nodeById[nodeId];
            const targetNode = nextState[reverseId].nodeById[comparedId];
            if (targetNode) {
                addSetpointLink(sourceNode, targetNode, map[node.id].value);
            }
        }
        changeComparedSetpoint(nextState, Action.SOURCE, treeId, nodeId, comparedId);
        changeComparedSetpoint(nextState, Action.TARGET, reverseId, comparedId, nodeId);
    }
}

function isNew(updateState, treeId) {
    if (updateState[treeId] == Action.READY) {
        return false;
    }
    return true;
}

function cacheParameter(state, param) {
    state.cachedState = Object.assign({}, state.cachedState);
    state.cachedState[param] = state[param];
}

function cacheParameters(state, params) {
    for (let p of params) {
        cacheParameter(state, p);
    }
}

function restoreNodesFromCache(state, treeId) {
    state[treeId] = Object.assign({}, NPTTreeReducers.initialTreeState, state.cachedState[treeId], { toggled: state[treeId].toggled, active: state[treeId].active });
}

function restoreTreesFromCache(state, treeIdsList) {
    for (let treeId of treeIdsList) {
        restoreNodesFromCache(state, treeId);
    }
}

function openComparedNode(state, comparedNodeId, treeId, reverseId) {
    if (!state[reverseId].nodeById[comparedNodeId]) {
        return;
    }
    const comparedNode = state[reverseId].nodeById[comparedNodeId];
    state[treeId].comparedId = comparedNode.comparedId;
    state[reverseId] = NPTTreeReducers.openNodeHandler(state[reverseId], { id: comparedNode.id });
}

function openComparedNodeList(state, node, treeId, reverseId) {
    if (!node.comparedId) {
        return;
    }
    if (!Array.isArray(node.comparedId)) {
        openComparedNode(state, node.comparedId, treeId, reverseId);
        return;
    }
    for (let nodeId of node.comparedId) {
        openComparedNode(state, nodeId, treeId, reverseId);
        state[reverseId].active = node.comparedId;
    }
}

function addSetpointLink(sourceNode, targetNode, value) {
    const enumeration = targetNode ? targetNode.enumeration : null;
    sourceNode.value = value || getValueFromRawValue(sourceNode.rawValue);
    sourceNode.enumeration = enumeration || null;
    sourceNode.isEnum = !!enumeration;
    sourceNode.isNumber = targetNode.isNumber;
    sourceNode.nodeIdList = targetNode.nodeIdList;
}

function deleteSetpointLink(sourceNode) {
    sourceNode.value = getValueFromRawValue(sourceNode.rawValue);
    sourceNode.enumeration = null;
    sourceNode.isEnum = false;
    sourceNode.isNumber = false;
    sourceNode.nodeIdList = null;
}

function removeEmptyFolders(tree) {
    for (let folderId in tree.children) {
        if (tree.children[folderId].length != 0) {
            continue;
        }
        const node = tree.nodeById[folderId];
        const parentId = node.parentId;
        let parentNodeChildren;
        if (parentId == 0) {
            parentNodeChildren = tree.rootNodesIds;
        } else {
            parentNodeChildren = tree.children[parentId];
        }
        for (let i = 0; i < parentNodeChildren.length; ++i) {
            if (parentNodeChildren[i] == folderId) {
                parentNodeChildren.splice(i, 1);
                if (parentNodeChildren.length == 1) {
                    tree.children[parentId] = tree.children[parentNodeChildren[0]].slice();
                }
                break;
            }
        }
    }
}

/****************
 *   Reducers   *
 ****************/
function changeParameter(state, params) {
    return Object.assign({}, state, params);
}

function changeTab(state, { tabId }) {
    return Object.assign({}, state, { activeTab: tabId });
}

function setOptions(state, { vendor, voltage, bayType }) {
    let nextState = Object.assign({}, state);
    if (typeof vendor != "undefined") {
        nextState.vendor = vendor || null;
    }
    if (typeof voltage != "undefined") {
        nextState.voltage = voltage || null;
    }
    if (typeof bayType != "undefined") {
        nextState.bayType = bayType || null;
    }
    return nextState;
}

function commonSetpointsRecieve(state, { commonSetpoints, bayTypes }) {
    let nextState = Object.assign({}, state);
    nextState.initialCommonSetpoints = NPTTreeReducers.receiveTreeHandler(nextState.commonSetpoints, { data: commonSetpoints });
    nextState.commonSetpoints = nextState.initialCommonSetpoints;
    nextState.uploadState = Object.assign({}, nextState.uploadState, { [Action.COMMON_SETPOINTS]: Action.READY });
    nextState.bayTypeList = bayTypes.slice();
    cacheParameters(nextState, ["commonSetpoints"]);
    checkReady(nextState)
    return nextState;
}

function commonSetpointsRecieveError(state, { error }) {
    let nextState = Object.assign({}, state, { state: Action.ERROR });
    nextState.uploadState = Object.assign({}, nextState.uploadState, { [Action.COMMON_SETPOINTS]: Action.ERROR });
    return nextState;
}

function commonSignalsRecieve(state, { commonSignals }) {
    let nextState = Object.assign({}, state);
    nextState.initialCommonAnalogSignals = NPTTreeReducers.receiveTreeHandler(nextState.discreteSignals, { data: commonSignals.analog });
    nextState.initialCommonDiscreteSignals = NPTTreeReducers.receiveTreeHandler(nextState.discreteSignals, { data: commonSignals.discrete });
    removeEmptyFolders(nextState.initialCommonAnalogSignals);
    removeEmptyFolders(nextState.initialCommonDiscreteSignals);
    nextState.commonAnalogSignals = nextState.initialCommonAnalogSignals;
    nextState.commonDiscreteSignals = nextState.initialCommonDiscreteSignals;
    nextState.uploadState = Object.assign({}, nextState.uploadState, { [Action.COMMON_SIGNALS]: Action.READY });
    cacheParameters(nextState, ["commonAnalogSignals", "commonDiscreteSignals"]);
    checkReady(nextState)
    return nextState;
}

function commonSignalsRecieveError(state, { error }) {
    let nextState = Object.assign({}, state, { state: Action.ERROR });
    nextState.uploadState = Object.assign({}, nextState.uploadState, { [Action.COMMON_SIGNALS]: Action.ERROR });
    return nextState;
}

function setpointsRecieve(state, { setpoints, vendor, bayType, ignoreCache }) {
    let nextState = Object.assign({}, state);
    nextState.commonSetpoints = Object.assign({}, nextState.initialCommonSetpoints);
    nextState.vendor = vendor;
    nextState.bayType = bayType;
    if (isNew(nextState.uploadState, Action.SETPOINTS)) {
        nextState.setpoints = NPTTreeReducers.receiveTreeHandler(nextState.setpoints, { data: setpoints });
        nextState.uploadState = Object.assign({}, nextState.uploadState, { [Action.SETPOINTS]: Action.READY });
        checkReady(nextState)
    } else {
        let map = getLinkMap(nextState.setpoints);
        nextState.setpoints = NPTTreeReducers.initialTreeState;
        nextState.setpoints = NPTTreeReducers.receiveTreeHandler(nextState.setpoints, { data: setpoints });
        bindTreeFromMap(nextState, "setpoints", map);
    }
    if (!ignoreCache) {
        cacheParameters(nextState, ["setpoints"]);
    }
    modifyCommonSetpointsView(nextState.commonSetpoints, nextState.vendor, nextState.voltage, nextState.bayType);
    if (bayType) {
        nextState.commonAnalogSignals = Object.assign({}, nextState.initialCommonAnalogSignals);
        nextState.commonDiscreteSignals = Object.assign({}, nextState.initialCommonDiscreteSignals);
        modifyCommonSignalsView(nextState.commonAnalogSignals, nextState.commonDiscreteSignals, nextState.bayType);
    }
    return nextState;
}

function setpointsRecieveError(state, { error }) {
    let nextState = Object.assign({}, state, { state: Action.ERROR });
    nextState.uploadState = Object.assign({}, nextState.uploadState, { [Action.SETPOINTS]: Action.ERROR });
    return nextState;
}

function signalsRecieve(state, { signals, ignoreCache }) {
    let nextState = Object.assign({}, state);
    nextState.commonAnalogSignals = Object.assign({}, nextState.initialCommonAnalogSignals);
    nextState.commonDiscreteSignals = Object.assign({}, nextState.initialCommonDiscreteSignals);
    if (isNew(nextState.uploadState, Action.SIGNALS)) {
        nextState.analogSignals = NPTTreeReducers.receiveTreeHandler(nextState.analogSignals, { data: signals.analog });
        nextState.discreteSignals = NPTTreeReducers.receiveTreeHandler(nextState.discreteSignals, { data: signals.discrete });
        nextState.uploadState = Object.assign({}, nextState.uploadState, { [Action.SIGNALS]: Action.READY });
        checkReady(nextState);
    } else {
        let analogMap = getLinkMap(nextState.analogSignals);
        let discreteMap = getLinkMap(nextState.discreteSignals);
        nextState.analogSignals = NPTTreeReducers.initialTreeState;
        nextState.discreteSignals = NPTTreeReducers.initialTreeState;
        nextState.analogSignals = NPTTreeReducers.receiveTreeHandler(nextState.analogSignals, { data: signals.analog });
        nextState.discreteSignals = NPTTreeReducers.receiveTreeHandler(nextState.discreteSignals, { data: signals.discrete });
        bindTreeFromMap(nextState, "analogSignals", analogMap);
        bindTreeFromMap(nextState, "discreteSignals", discreteMap);
    }
    modifyCommonSignalsView(nextState.commonAnalogSignals, nextState.commonDiscreteSignals, nextState.bayType);
    if (!ignoreCache) {
        cacheParameters(nextState, ["analogSignals", "discreteSignals"]);
    }
    return nextState;
}

function signalsRecieveError(state, { error }) {
    let nextState = Object.assign({}, state, { state: Action.ERROR });
    nextState.uploadState = Object.assign({}, nextState.uploadState, { [Action.SIGNALS]: Action.ERROR });
    return nextState;
}

function fileReset(state) {
    return initialState;
}

function fileRecieve(state, { data }) {
    let nextState = Object.assign({}, state);
    let sourceTreeId = getTabTreeId(nextState, Action.SOURCE_TREE, true);
    nextState.uploadState = Object.assign({}, nextState.uploadState);
    nextState.commonSetpoints = Object.assign({}, nextState.initialCommonSetpoints);
    nextState.commonSetpoints.nodeById = Object.assign({}, nextState.commonSetpoints.nodeById);
    nextState.commonAnalogSignals = Object.assign({}, nextState.initialCommonAnalogSignals);
    nextState.commonAnalogSignals.nodeById = Object.assign({}, nextState.commonAnalogSignals.nodeById);
    nextState.commonDiscreteSignals = Object.assign({}, nextState.initialCommonDiscreteSignals);
    nextState.commonDiscreteSignals.nodeById = Object.assign({}, nextState.commonDiscreteSignals.nodeById);
    let selectedNodeId = nextState.setpoints.active;
    nextState.setpoints = NPTTreeReducers.receiveTreeHandler(nextState.setpoints, { data: data.setpoints });
    nextState.analogSignals = NPTTreeReducers.receiveTreeHandler(nextState.analogSignals, { data: data.analog });
    nextState.discreteSignals = NPTTreeReducers.receiveTreeHandler(nextState.discreteSignals, { data: data.discrete });
    nextState.bayType = data.bayType || null;

    for (let file in nextState.uploadState) {
        nextState.uploadState[file] = Action.READY;
    }
    checkReady(nextState)
    for (let tabId in Action.SOURCE) {
        checkBindings(nextState, Action.SOURCE[tabId]);
    }
    if (selectedNodeId) {
        nextState = selectNode(nextState, { id: selectedNodeId, sourceId: Action.SOURCE_TREE });
    }
    modifyCommonSetpointsView(nextState.commonSetpoints, nextState.vendor, nextState.voltage, nextState.bayType);
    modifyCommonSignalsView(nextState.commonAnalogSignals, nextState.commonDiscreteSignals, nextState.bayType);
    cacheParameters(nextState, ["commonSetpoints", "commonAnalogSignals", "commonDiscreteSignals", "setpoints", "analogSignals", "discreteSignals"]);
    filterCommon(nextState);
    return nextState;
}

function fileRecieveError(state, { error }) {
    return Object.assign({}, state, { state: Action.ERROR });
}

function selectNode(state, { id, sourceId }) {
    let nextState = Object.assign({}, state);
    let treeId = getTabTreeId(nextState, sourceId, true);
    nextState[treeId] = Object.assign({}, nextState[treeId]);
    nextState[treeId].active = id;
    let node = nextState[treeId].nodeById[id];
    if (node && nextState.primary == sourceId) {
        let reverseId = Action.reverse[treeId];
        nextState[reverseId] = Object.assign({}, nextState[reverseId]);
        nextState[reverseId].comparedId = node.comparedId;
        nextState[treeId].comparedId = node.comparedId;
        openComparedNodeList(nextState, node, treeId, reverseId);
    }
    return nextState;
}

function toggleNode(state, { id, sourceId }) {
    let treeId = getTabTreeId(state, sourceId, true);
    if (!state[treeId].children[id]) {
        return state;
    }
    let nextState = Object.assign({}, state);
    let sourceTreeId = getTabTreeId(nextState, Action.SOURCE_TREE, true);
    let targetTreeId = getTabTreeId(nextState, Action.TARGET_TREE, true);
    nextState[sourceTreeId] = Object.assign({}, nextState[sourceTreeId]);
    nextState[targetTreeId] = Object.assign({}, nextState[targetTreeId]);
    let toggled = !nextState[treeId].toggled[id];
    nextState[treeId] = NPTTreeReducers.toggleSubTreeHandler(nextState[treeId], { id: id, toggled: toggled, noSelect: true });
    return nextState;
}

function unbindSetpoints(state, { sourceId, targetId }) {
    let nextState = Object.assign({}, state);
    let sourceTreeId = getTabTreeId(nextState, Action.SOURCE_TREE, true);
    let targetTreeId = getTabTreeId(nextState, Action.TARGET_TREE, true);
    nextState[sourceTreeId] = Object.assign({}, nextState[sourceTreeId]);
    nextState[targetTreeId] = Object.assign({}, nextState[targetTreeId]);
    nextState[sourceTreeId].nodeById = Object.assign({}, nextState[sourceTreeId].nodeById);
    nextState[targetTreeId].nodeById = Object.assign({}, nextState[targetTreeId].nodeById);
    nextState[sourceTreeId].nodeById[sourceId] = Object.assign({}, nextState[sourceTreeId].nodeById[sourceId]);
    const sourceNode = nextState[sourceTreeId].nodeById[sourceId];
    deleteSetpointLink(sourceNode);
    changeComparedSetpoint(nextState, Action.SOURCE, sourceTreeId, sourceId, null);
    changeComparedSetpoint(nextState, Action.TARGET, targetTreeId, targetId, null);
    return selectNode(nextState, { id: sourceId, sourceId: nextState.primary });
}

function bindSetpoints(state, { sourceId, targetId, value }) {
    const nextState = Object.assign({}, state);
    const sourceTreeId = getTabTreeId(nextState, Action.SOURCE_TREE, true);
    const targetTreeId = getTabTreeId(nextState, Action.TARGET_TREE, true);
    nextState[sourceTreeId] = Object.assign({}, nextState[sourceTreeId]);
    nextState[targetTreeId] = Object.assign({}, nextState[targetTreeId]);
    nextState[sourceTreeId].nodeById = Object.assign({}, nextState[sourceTreeId].nodeById);
    nextState[targetTreeId].nodeById = Object.assign({}, nextState[targetTreeId].nodeById);
    nextState[sourceTreeId].nodeById[sourceId] = Object.assign({}, nextState[sourceTreeId].nodeById[sourceId]);
    const sourceNode = nextState[sourceTreeId].nodeById[sourceId];
    const targetNode = nextState[targetTreeId].nodeById[targetId];
    addSetpointLink(sourceNode, targetNode, value);
    changeComparedSetpoint(nextState, Action.SOURCE, sourceTreeId, sourceId, targetId);
    changeComparedSetpoint(nextState, Action.TARGET, targetTreeId, targetId, sourceId);
    if (nextState.bindedFlag) {
        deselectBindedNode(getTabTree(nextState, Action.SOURCE, true));
        return nextState;
    }
    return selectNode(nextState, { id: sourceId, sourceId: nextState.primary });
}

function setWithoutConfirmation(state, { withoutConfirmation }) {
    return Object.assign({}, state, { withoutConfirmation });
}

function setBindedFlag(state, { bindedFlag }) {
    let nextState = Object.assign({}, state);
    let sourceTreeId = getTabTreeId(nextState, Action.SOURCE_TREE, true);
    let targetTreeId = getTabTreeId(nextState, Action.TARGET_TREE, true);
    nextState[sourceTreeId] = Object.assign({}, nextState[sourceTreeId], { comparedId: null });
    nextState[targetTreeId] = Object.assign({}, nextState[targetTreeId], { comparedId: null });
    nextState.bindedFlag = bindedFlag;
    if (bindedFlag) {
        deselectBindedNode(nextState[sourceTreeId]);
        deselectBindedNode(nextState[targetTreeId]);
    }
    return nextState;
}

function setDetailedView(state, { detailedView }) {
    return Object.assign({}, state, { detailedView });
}

function changeFilter(state, { value }) {
    let nextState = Object.assign({}, state);
    nextState.filter = value;
    filterCommon(nextState);
    return nextState;
}

function changeDetailsParameter(state, { field, value }) {
    let nextState = Object.assign({}, state);
    let sourceTreeId = getTabTreeId(nextState, Action.SOURCE_TREE, true);
    nextState[sourceTreeId] = Object.assign({}, nextState[sourceTreeId]);
    nextState[sourceTreeId].nodeById = Object.assign({}, nextState[sourceTreeId].nodeById);
    nextState[sourceTreeId].nodeById[nextState[sourceTreeId].active] = Object.assign({}, nextState[sourceTreeId].nodeById[nextState[sourceTreeId].active]);
    nextState[sourceTreeId].nodeById[nextState[sourceTreeId].active][field] = value;
    return nextState;
}

function showLinkedElement(state, { nodeId, sourceId }) {
    let nextState = Object.assign({}, state);
    let sourceTreeId = getTabTreeId(nextState, sourceId, true);
    let reverseTreeId = Action.reverse[sourceTreeId];
    nextState[sourceTreeId] = Object.assign({}, nextState[sourceTreeId]);
    nextState[reverseTreeId] = Object.assign({}, nextState[reverseTreeId]);
    let node = nextState[sourceTreeId].nodeById[nodeId];
    openComparedNode(nextState, node.comparedId, sourceTreeId, reverseTreeId);
    nextState = selectNode(nextState, { id: node.comparedId, sourceId: sourceId == Action.SOURCE_TREE ? Action.TARGET_TREE : Action.SOURCE_TREE });
    return nextState;
}

function changeConfig(state, { voltage, vendor, bayType }) {
    if (voltage == state.voltage && vendor == state.vendor && bayType == state.bayType) {
        return state;
    }
    let nextState = Object.assign({}, state, { voltage, vendor });
    nextState.commonSetpoints = Object.assign({}, nextState.initialCommonSetpoints);
    nextState.commonSetpoints.nodeById = Object.assign({}, nextState.commonSetpoints.nodeById);
    if (bayType != nextState.bayType) {
        nextState.commonAnalogSignals = Object.assign({}, nextState.initialCommonAnalogSignals);
        nextState.commonAnalogSignals.nodeById = Object.assign({}, nextState.commonAnalogSignals.nodeById);
        nextState.commonDiscreteSignals = Object.assign({}, nextState.initialCommonDiscreteSignals);
        nextState.commonDiscreteSignals.nodeById = Object.assign({}, nextState.commonDiscreteSignals.nodeById);
        nextState.bayType = bayType;
        modifyCommonSignalsView(nextState.commonAnalogSignals, nextState.commonDiscreteSignals, nextState.bayType);
    }
    modifyCommonSetpointsView(nextState.commonSetpoints, nextState.vendor, nextState.voltage, nextState.bayType);
    return nextState;
}

function cancelUpdates(state) {
    let nextState = Object.assign({}, state);
    restoreTreesFromCache(nextState, ["commonSetpoints", "commonAnalogSignals", "commonDiscreteSignals", "setpoints", "analogSignals", "discreteSignals"]);
    return nextState;
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.CHANGE_PARAMETER: return changeParameter(state, action.payload);
        case Action.CHANGE_TAB: return changeTab(state, action.payload);

        case Action.SET_OPTIONS: return setOptions(state, action.payload);

        case Action.COMMON_SETPOINTS_RECEIVE: return commonSetpointsRecieve(state, action.payload);
        case Action.COMMON_SETPOINTS_RECEIVE_ERROR: return commonSetpointsRecieveError(state, action.payload);
        case Action.COMMON_SIGNALS_RECEIVE: return commonSignalsRecieve(state, action.payload);
        case Action.COMMON_SIGNALS_RECEIVE_ERROR: return commonSignalsRecieveError(state, action.payload);

        case Action.SETPOINTS_RECEIVE: return setpointsRecieve(state, action.payload);
        case Action.SETPOINTS_RECEIVE_ERROR: return setpointsRecieveError(state, action.payload);
        case Action.SIGNALS_RECEIVE: return signalsRecieve(state, action.payload);
        case Action.SIGNALS_RECEIVE_ERROR: return signalsRecieveError(state, action.payload);

        case Action.FILE_RESET: return fileReset(state, action.payload);
        case Action.FILE_RECEIVE: return fileRecieve(state, action.payload);
        case Action.FILE_RECEIVE_ERROR: return fileRecieveError(state, action.payload);

        case Action.SELECT_NODE: return selectNode(state, action.payload);
        case Action.TOGGLE_NODE: return toggleNode(state, action.payload);

        case Action.UNBIND_SETPOINTS: return unbindSetpoints(state, action.payload);
        case Action.BIND_SETPOINTS: return bindSetpoints(state, action.payload);
        case Action.SET_WITHOUT_CONFIRMATION: return setWithoutConfirmation(state, action.payload);
        case Action.SET_BINDED_FLAG: return setBindedFlag(state, action.payload);
        case Action.SET_DETAILED_VIEW: return setDetailedView(state, action.payload);
        case Action.CHANGE_FILTER: return changeFilter(state, action.payload);

        case Action.CHANGE_DETAILS_PARAMETER: return changeDetailsParameter(state, action.payload);

        case Action.SHOW_LINKED_ELEMENT: return showLinkedElement(state, action.payload);

        case Action.CHANGE_CONFIG: return changeConfig(state, action.payload);

        case Action.CANCEL_UPDATES: return cancelUpdates(state, action.payload);
        default: return state;
    }
}