import * as Action from '../constants/srctree.js';
import {
    initialTreeState,
    waitForTreeHandler,
    receiveTreeHandler,
    receiveTreeErrorHandler,
    toggleSubTreeHandler,
    updateNodeHandler,
    openNodeHandler
} from './npt-treebeard';
// import { getInitialState, renamePath, findNodeInTree, getBasename } from '../services/srctree';

const initialState = Object.assign(initialTreeState, {
    nodeMap: {},
    /* Setup default sorting to tree */
    sortedBy: "default"
});

function waitForTree(state, payload) {
    /* Use default treebeard reducer */
    return waitForTreeHandler(state, payload);
}

function receiveTree(state, payload) {
    let newState = Object.assign({}, state);
    newState.nodeMap = {};
    for (let path of payload.originalData) {
        newState.nodeMap[path] = true;
    }
    /* Use default treebeard reducer */
    return receiveTreeHandler(newState, payload);
}

function receiveTreeError(state, payload) {
    /* Use default treebeard reducer */
    return receiveTreeErrorHandler(state, payload);
}

function toggleSubTree(state, payload) {
    /* Use default treebeard reducer */
    return toggleSubTreeHandler(state, payload);
}

function getLastChild(node) {
    while (node.children) {
        node = node.children[0];
    }
    return node;
}

/* Get array of nodes with code in node with nodeId == id */
function findNodesWithCode(newState, id, nodes) {
    if (newState.nodeMap[id]) {
        nodes.push(id);
    }
    if (newState.children[id]) {
        for (let childId of newState.children[id]) {
            findNodesWithCode(newState, childId, nodes)
        }
    }
    return nodes;
}

/* payload = { id, node, children, loading, error, type, directory, movePath } */
function updateNode(state, payload) {
    let newState = Object.assign({}, state);
    let nodesAdded = [];
    let nodesRemoved = [];
    /* Change map <node:haveCode> */
    switch (payload.type) {
        case "add":
            newState.nodeMap = Object.assign({}, newState.nodeMap);
            nodesAdded.push(getLastChild(payload).id);
            break;
        case "remove":
            newState.nodeMap = Object.assign({}, newState.nodeMap);
            nodesRemoved = findNodesWithCode(newState, payload.id, []);
            break;
        case "move":
            nodesRemoved = findNodesWithCode(newState, payload.id, []);
            for (let nodeId of nodesRemoved) {
                nodesAdded.push(nodeId.replace(payload.id, payload.movePath));
            }
            break;
        case "copy":
            for (let nodeId of findNodesWithCode(newState, payload.id, [])) {
                nodesAdded.push(nodeId.replace(payload.id, payload.movePath));
            }
            break;
    }
    newState.nodeMap = Object.assign({}, newState.nodeMap);
    for (let nodeId of nodesAdded) {
        newState.nodeMap[nodeId] = true;
    }
    for (let nodeId of nodesRemoved) {
        delete (newState.nodeMap[nodeId]);
    }
    /* Use default treebeard reducer */
    return updateNodeHandler(newState, payload);
}

function openNode(state, payload) {
    /* Use default treebeard reducer */
    return openNodeHandler(state, payload);
}

function editNodeStart(state, { path }) {
    return Object.assign({}, state, { editNodeId: path });
}

function editNodeCancel(state) {
    return Object.assign({}, state, { editNodeId: null });
}

function searchCode(state, { searchString }) {
    return Object.assign({}, state, { searchString });
}

export default (state = initialState, action) => {
    switch (action.type) {
        // case Action.ADD_DIRECTORY: return addDirectory(state, action.payload);
        // case Action.ADD_FILE: return addFile(state, action.payload);
        // case Action.REMOVE_FILE: return removeFile(state, action.payload);
        // case Action.RENAME_FILE: return renameFile(state, action.payload);
        // case Action.MOVE_FILE: return moveFile(state, action.payload);
        // case Action.FIX_CAPTION: return fixCaption(state, action.payload);

        case Action.WAIT_FOR_TREE: return waitForTree(state, action.payload);
        case Action.RECEIVE_TREE: return receiveTree(state, action.payload);
        case Action.RECEIVE_TREE_ERROR: return receiveTreeError(state, action.payload);
        case Action.TOGGLE_SUBTREE: return toggleSubTree(state, action.payload);
        case Action.UPDATE_NODE: return updateNode(state, action.payload);
        case Action.OPEN_NODE: return openNode(state, action.payload);
        case Action.EDIT_NODE_START: return editNodeStart(state, action.payload);
        case Action.EDIT_NODE_CANCEL: return editNodeCancel(state, action.payload);
        case Action.SEARCH_CODE: return searchCode(state, action.payload);
        default: return state;
    }
}