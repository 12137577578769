import React from 'react';
import Dropzone from 'react-dropzone';
import Basic from './basic.jsx';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { MSG_TABLE_NO_DATA_TO_DISPLAY } from '../../messages.jsx';
import { REMOVE_BUTTON_ICON_STYLE } from './style.jsx';

const dropZoneStyle = {
    style: {
        width: "auto",
        height: "100px",
        borderWidth: 2,
        borderColor: '#666',
        borderStyle: 'dashed',
        borderRadius: 5
    },
    activeStyle: {
        borderStyle: 'solid',
        backgroundColor: '#eee'
    },
    rejectStyle: {
        borderStyle: 'solid',
        backgroundColor: '#ffdddd'
    }
}

const previewStyle = {
    height: "auto",
    width: "auto",
    maxWidth: "100px",
    maxHeight: "100px"
}

function refFormatter(cell, row, cp) {
    if (!cell) {
        return "-";
    }
    let filename = row.$originalName;
    if (typeof filename == 'undefined') {
        filename = row.$label;
    }
    return (<a href={`${cp}rest/file/download/${cell}`} download={filename}><i className="fa fa-download" aria-hidden="true"></i></a>);
}

function getPreview(preview) {
    return (<img src={preview} style={previewStyle} />);
}

function nameFormatter(cell, row) {
    if (row.preview) {
        return (<div>{cell} {getPreview(row.preview)}</div>);
    }
    return cell;
}

class File extends Basic {

    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
        this.removeClicked = this.removeClicked.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.state = { selected: {} };
    }

    removeClicked(event) {
        let data = this.props.value;
        if (this.props.node.multiple) {
            let indexList = [];
            for (var i in data) {
                let row = data[i];
                let key = row.$sha1;
                if (typeof key == 'undefined') {
                    key = row.$uploadKey;
                }
                if (this.state.selected[key]) {
                    indexList.push(i);
                }
            }
            if (indexList.length > 0) {
                this.props.remove(indexList);
            }
        } else {
            let key = data.$sha1 || data.$uploadKey;
            if (key && this.state.selected[key]) {
                this.props.change({});
            }
        }
        this.setState({ selected: {} }); //clear selection
    }

    onSelect(row, isSelected, e) {
        let selected = Object.assign({}, this.state.selected, { [row.key]: isSelected });
        this.setState({ selected });
    }

    onSelectAll(isSelected, rows) {
        let selected = Object.assign({}, this.state.selected);
        for (let row of rows) {
            selected[row.key] = isSelected;
        }
        this.setState({ selected });
    }

    getToolbar() {
        return (
            <div className="btn-group btn-group-xs pull-right" role="group" aria-label="...">
                <button type="button" className="btn btn-secondary" onClick={this.removeClicked}>
                    <i className="fa fa-minus-circle fa-fw" style={REMOVE_BUTTON_ICON_STYLE} aria-hidden="true"></i>
                    <FormattedMessage
                        id="OBJECTCARD_FILE_REMOVE_BUTTON"
                        defaultMessage="Remove"
                        description="User should click this button to remove file from table" />
                </button>
            </div>);
    }


    onDrop(acceptedFiles) {
        //Save for preview
        if (!this.fileMap) {
            this.fileMap = {};
        }
        for (let file of acceptedFiles) {
            this.fileMap[file.name] = file;
        }
        this.props.registerUpload(acceptedFiles, this.props.node.multiple);
    }

    getFooter() {
        return (<Dropzone multiple={this.props.node.multiple} onDrop={this.onDrop} {...dropZoneStyle}>
            <div style={dropZoneStyle}>
                <FormattedMessage
                    id="OBJECTCARD_FILE_DROP_FILE_HERE"
                    defaultMessage="Try to drop file here, or click to select file to upload"
                    description="Inform user how to upload file" />
            </div>
        </Dropzone>);
    }

    getData() {
        //Get data
        let data = this.props.value;
        //Validate data
        if (this.props.node.multiple) {
            if ($.type(data) != 'array') {
                data = [];
            }
        } else {
            if ($.type(data) != 'object') {
                data = [];
            } else {
                //Put value into the array
                if (data.$sha1 || data.$uploadKey) { //check if is not empty object
                    data = [data];
                } else {
                    data = [];
                }
            }
        }
        let fileMap = this.fileMap;
        data = data.map(function (row) {
            let preview = null;
            if (row.$tmpName && fileMap) {
                let file = fileMap[row.$tmpName];
                preview = file.preview;
            }
            let key = row.$sha1;
            if (typeof key == 'undefined') {
                key = row.$uploadKey;
            }
            return Object.assign({}, row, { key, preview });
        });

        return data;
    }

    render() {
        let editable = this.props.editable;
        let optional = {};
        if (editable) {
            optional.selectRow = {
                mode: 'checkbox',
                onSelect: this.onSelect,
                onSelectAll: this.onSelectAll,
                bgColor: '#80BEE6',
                //hideSelectColumn: !,
                clickToSelect: true
            };
        }
        const tableStye = {
            margin: "0px",
            borderRadius: "0px"
        };

        let containerStyle = {
            overflowX: 'auto'
        };
        let options = {
            noDataText: MSG_TABLE_NO_DATA_TO_DISPLAY
        }

        let contents = (
            <div>
                <BootstrapTable
                    version='4'
                    options={options}
                    tableStyle={tableStye}
                    containerStyle={containerStyle}
                    data={this.getData()}
                    striped={true}
                    hover={true}
                    {...optional}>
                    <TableHeaderColumn isKey={true} dataField='key' hidden={true}>
                        SHA1
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='$label' dataSort={true} dataFormat={nameFormatter} >
                        <FormattedMessage
                            id="OBJECTCARD_FILE_TABLE_HEADER_NAME"
                            defaultMessage="Name"
                            description="File name in table header" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='$description' dataSort={true}>
                        <FormattedMessage
                            id="OBJECTCARD_FILE_TABLE_HEADER_DESCRIPION"
                            defaultMessage="Description"
                            description="File description in table header" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='$contentType' dataSort={true}>
                        <FormattedMessage
                            id="OBJECTCARD_FILE_TABLE_HEADER_TYPE"
                            defaultMessage="Type"
                            description="File type in table header" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='$contentSize' dataSort={true}>
                        <FormattedMessage
                            id="OBJECTCARD_FILE_TABLE_HEADER_SIZE"
                            defaultMessage="Size"
                            description="File size in table header" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='$sha1' dataFormat={refFormatter} formatExtraData={this.props.contextPath} dataAlign='center'>
                        <FormattedMessage
                            id="OBJECTCARD_FILE_TABLE_HEADER_DOWNLOAD"
                            defaultMessage="Download"
                            description="File download column in table header" />
                    </TableHeaderColumn>
                </BootstrapTable>
                {editable && this.getFooter()}
            </div>);
        //Add toolbar to delete items
        if (editable) {
            return this.wrapToolbar(this.props.node.label, this.getToolbar(), contents);
        }
        return this.wrapLarge(this.props.node.label, contents);
    }
}

export default File;