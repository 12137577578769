import React from 'react';
import PropTypes from 'prop-types';

const numberRegExp = /^(\-|\+)?([0-9]+(\.[0-9]*)?)$/;

function numberLimit(limit, value) {
    if (typeof value == 'string') {
        value = value.replace(/\s+/, '').replace(',', '.');
        if (!numberRegExp.test(value)) {
            return;
        }
        value = parseFloat(value);
    } else if (typeof value != 'number') {
        return;
    }
    if (typeof limit.min != "number") {
        limit.min = value;
        limit.max = value;
    } else if (value < limit.min) {
        limit.min = value;
    } else if (value > limit.max) {
        limit.max = value;
    }
}

function numberLimitFinish(limit) {
    if (typeof limit.min != "number") {
        limit.min = '0';
        limit.max = '0';
        //limit.min = '5';
        //limit.max = '100';
    } else {
        limit.min = limit.min.toString();
        limit.max = limit.max.toString();
    }
}

function generateCallback(fromNumber, toNumber) {
    fromNumber = parseFloat(fromNumber.replace(",", "."));
    toNumber = parseFloat(toNumber.replace(",", "."));
    return function (targetValue) {
        if (typeof targetValue == 'string') {
            if (!numberRegExp.test(targetValue)) {
                return false;
            }
            targetValue = parseFloat(targetValue);
        } else if (typeof targetValue != 'number') {
            return false;
        }
        return targetValue >= fromNumber && targetValue <= toNumber;
    }
}

class NumberFilter extends React.Component {

    constructor(props) {
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
        this.apply = this.apply.bind(this);
        this.clear = this.clear.bind(this);
        this.state = { fromNumber: null, toNumber: null };
    }

    apply({ fromNumber, toNumber, cleared }) {
        if (cleared) {
            this.clear();
            return;
        }
        if (this.limits.min == this.limits.max) {
            return;
        }
        fromNumber = fromNumber || this.limits.min;
        toNumber = toNumber || this.limits.max;
        let callback = generateCallback(fromNumber, toNumber);
        this.props.filterHandler({ callback, type: this.props.type, from: fromNumber, to: toNumber });
        this.setState({ fromNumber, toNumber });
    }

    clear() {
        this.props.filterHandler();
        this.setState({ fromNumber: null, toNumber: null });
    }

    clickHandler() {
        this.limits = this.getPanelLimits(this.props.getFilteredRows());
        let options = {
            label: this.props.label,
            size: this.props.size,
            fromNumber: this.state.fromNumber,
            toNumber: this.state.toNumber,
            format: this.props.format,
            limits: this.limits,
            originalLimits: this.originalLimits
        }
        this.props.openModal("numberfilter", options, this.apply);
    }

    getPanelLimits(data) {
        if (!data) {
            return null;
        }
        let limits = {};
        for (let row of data) {
            let value = row[this.props.field];
            numberLimit(limits, value);
        }
        numberLimitFinish(limits);
        return limits;
    }

    componentDidMount() {
        if (this.props.rows) {
            this.originalLimits = this.getPanelLimits(this.props.rows);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.rows != nextProps.rows) {
            this.originalLimits = this.getPanelLimits(nextProps.rows);
        }
    }

    render() {
        const activeStyle = { color: "#90EE90" };
        let optional = {};
        if (this.props.active) {
            optional.style = activeStyle;
        }
        return (<i className="fa fa-filter" aria-hidden="true" {...optional} onClick={this.clickHandler}></i>);
    }
}

NumberFilter.propTypes = {
    filterHandler: PropTypes.func.isRequired,
    showFilterDialog: PropTypes.func.isRequired,
    getFilteredRows: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired
};

export default NumberFilter;