import shortid from 'shortid';

/**Anti pattern! But nobody knows how to do it better. Global upload map used to link between automation function id and function itself
}*/
const globalFunctionMap = {};

export function registerFunction(func) {
    const id = shortid.generate();
    globalFunctionMap[id] = func;
    return id;
}

export function retrieveFunction(id) {
    return globalFunctionMap[id];
}

//Obtain data by path
export function obtainData(path, data) {
    //Support for string path
    if (typeof path == 'string') {
        path = path.split(".");
    }
    if ($.type(path) != 'array') {
        return null;
    }
    let result = data;
    for (let p of path) {
        if (typeof p == 'string' && /^[0-9]+$/.test(p)) { //because javascript array index is string not number
            p = parseInt(p);
        }
        if (typeof p == 'string') {
            if ($.type(result) == 'object' && typeof result[p] != 'undefined') {
                result = result[p];
                continue;
            }
        } else if (typeof p == 'number') {
            if ($.type(result) == 'array' && typeof result[p] != 'undefined') {
                result = result[p];
                continue;
            }
        }
        return null;
    }
    return result;
}

/*Illustration for function
let test = {
    cim : {
        IdentifiedObject : {
            name : "xx"
        }
    }
};
let testPath = ["cim", "IdentifiedObject", "name"];
--> prev = cim, p = IdentifiedObject*/

export function changeData(data, path, callback) {
    //Support for string path
    if (typeof path == 'string') {
        path = path.split(".");
    }
    //Create new data
    let newData = Object.assign({}, data);
    //Assign data to current and start search
    let current = newData;
    let prev = null; //previous path value
    //Check first value (which will be skiped in loop)
    if ($.type(path) != 'array' || path.length == 0 || typeof path[0] != 'string') {
        console.error("Invalid path (first value): ", path);
        return data;
    }
    for (let p of path) {
        if (typeof p == 'string' && /^[0-9]+$/.test(p)) { //because javascript array index is string not number
            p = parseInt(p);
        }
        if (prev == null) {
            prev = p;
            continue; //skip first value
        }
        if (typeof p == 'string') {
            //current[prev] should be object
            //Check undefined
            if ($.type(current[prev]) != 'object') {
                current[prev] = {}; //force object
            } else {
                current[prev] = Object.assign({}, current[prev]);
            }
        } else if (typeof p == 'number') {
            if ($.type(current[prev]) != 'array') { //force array
                current[prev] = [];
            } else {
                current[prev] = [].concat(current[prev]);
            }
        } else {
            console.error("Invalid path (string and integer shoud be used): ", path);
            return data;
        }
        //Change current
        current = current[prev];
        //Change previous value 
        prev = p;
    }
    //Call updater
    callback(current, prev);
    //If something changed then return new data
    return newData;
}

export function scriptCompiler(scriptList, bindings) {
    if (typeof scriptList == 'string') {
        scriptList = [scriptList];
    }
    for (let script of scriptList) {
        try {
            let f = new Function(script);
            f.call(bindings);
        } catch (ex) {
            console.log(ex);
        }
    }
}

export function checkMandatory(data) {
    if (data == null) {
        return false;
    }
    if ($.type(data) == "array") { //Check empty arrays
        if (data.length == 0) {
            return false;
        }
    } else if ($.type(data) == "object") {
        if (typeof data.$value == 'string') { //Special variant of string
            if (data.$value.length === 0 || !data.$value.trim()) {
                return false;
            }
        } else { //Check empty objects (without $rdfId, $tmpKey and so on)
            let empty = true;
            for (let key in data) {
                if (key.indexOf("$") == 0) {
                    empty = false;
                    break;
                }
            }
            if (empty) {
                return false;
            }
        }
    } else if (typeof data == 'string' && (data.length === 0 || !data.trim())) { //Check empty string
        return false;
    }
    return true;
}

export function checkMinimumItems(layoutNode, data) {
    if (layoutNode == null || !layoutNode.multiple || !layoutNode.options.min) {
        return true;
    }
    if(!Array.isArray(data)){
        return false;
    }
    return layoutNode.options.min <= data.length;
}

export function checkMaximumItems(layoutNode, data) {
    if (layoutNode == null || !layoutNode.multiple || !layoutNode.options.max) {
        return true;
    }
    if(!Array.isArray(data)){
        return false;
    }
    return layoutNode.options.max >= data.length;
}