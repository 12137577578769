import React from 'react';
import { getPointOffset, getPointDate } from '../../../services/gant';
import { READ, PLANNED_DATE, CHOSEN_DATE } from '../../../constants/gant';

function getMouseX(reactEvent, htmlList) {
    let offsetX = htmlList.getBoundingClientRect().left;
    let scrollX = htmlList.scrollLeft;
    return reactEvent.pageX - offsetX + scrollX;
}

function getHTMLList(htmlElement) {
    while (htmlElement.parentNode) {
        htmlElement = htmlElement.parentNode;
        if (htmlElement.className && htmlElement.className.indexOf("npt-gant-list") != -1) {
            return htmlElement;
        }
    }
    return null;
}

class BackgroundRow extends React.Component {

    constructor(props) {
        super(props);

        this.setupDragLimits(props);
        this.resetPosition();

        this.grabRow = this.grabRow.bind(this);
        this.resizeRow = this.resizeRow.bind(this);
        this.releaseRow = this.releaseRow.bind(this);
    }

    setupDragLimits(props) {
        this.dragLimits = {
            left: 0,
            right: props.rowWidth,
            width: props.rowWidth
        }
    }

    resetPosition() {
        this.position = {
            left: this.dragLimits.left,
            right: this.dragLimits.right,
            width: this.dragLimits.width
        }
    }

    resetHandlers() {
        if (this.moveHandler) {
            this.props.removeHandler(this.moveHandler);
            this.moveHandler = null;
        }
        if (this.releaseHandler) {
            this.props.removeHandler(this.releaseHandler);
            this.releaseHandler = null;
        }
    }


    grabRow(reactEvent) {
        if (!this.htmlList) {
            this.htmlList = getHTMLList(reactEvent.target);
            if (!this.htmlList) {
                return;
            }
        }
        let positionX = getMouseX(reactEvent, this.htmlList);
        this.position = {
            left: positionX,
            right: positionX,
            width: 0
        }
        this.grabbing = true;
        this.resetHandlers();
        this.moveHandler = this.props.registerHandler("mousemove", this.resizeRow.bind(this, positionX));
        this.releaseHandler = this.props.registerHandler("mouseup", this.releaseRow);
        this.forceUpdate();
    }

    resizeRow(startPositionX, reactEvent) {
        let positionX = getMouseX(reactEvent, this.htmlList);
        this.position = {
            left: Math.min(positionX, startPositionX),
            right: Math.max(positionX, startPositionX)
        }
        if (this.position.left < 0) {
            this.position.left = 0;
        }
        if (this.position.right > this.props.rowWidth) {
            this.position.right = this.props.rowWidth;
        }
        this.position.width = this.position.right - this.position.left;
        /* If user move mouse to left setup reverse flag */
        this.reverse = positionX < startPositionX;
        this.forceUpdate();
    }

    releaseRow(reactEvent) {
        this.resetHandlers();
        let item = {
            from: getPointDate(this.props.dateLimits, this.dragLimits, this.position.left),
            to: getPointDate(this.props.dateLimits, this.dragLimits, this.position.right)
        };
        this.props.addItem({
            from: this.reverse ? item.to : item.from,
            to: this.reverse ? item.from : item.to
        });
        this.grabbing = false;
        this.resetPosition();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.rowWidth != nextProps.rowWidth) {
            this.setupDragLimits(nextProps);
            this.resetPosition(nextProps);
        }
    }

    render() {
        if (this.props.permissions.value != "full" || this.props.viewType == READ || (this.props.singleElementOnRow && this.props.row.gantData[this.props.viewType].length != 0)) {
            return null;
        }
        return (
            <div className={"npt-gant-background-row" + (this.grabbing ? " grabbing" : "")} onMouseDown={this.grabRow} style={{ left: this.position.left, width: this.position.width, }}>
            </div>);
    }
}

export default BackgroundRow;
