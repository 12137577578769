import Tree from './components/tree/tree.jsx';
import Menu from './components/tree/menu.jsx';
import { connectNavTree } from './services/navtree';
import { subjectsChanged } from './actions/navtree';
import { NOTIFY_SUBJECT_CHANGED } from './constants/objectcard';
import shortid from 'shortid';
import { NAVTREE } from './constants/navtree';
import navtree from './reducers/navtree';

import { FormattedMessage } from 'react-intl';

const NAVTREE_UPDATE_FAILED = (<FormattedMessage id="NAVTREE_UPDATE_FAILED"
    defaultMessage='Failed to update node "{label}"'
    description="Inform user that update tree node failed" />);

const NAVTREE_ADD_FAILED = (<FormattedMessage id="NAVTREE_ADD_FAILED"
    defaultMessage="Failed to add node to tree"
    description="Inform user that add node to tree failed" />);

const NAVTREE_COPY_FAILED = (<FormattedMessage id="NAVTREE_COPY_FAILED"
    defaultMessage="Failed to copy node in tree"
    description="Inform user that copy node in tree failed" />);

const NAVTREE_MOVE_FAILED = (<FormattedMessage id="NAVTREE_MOVE_FAILED"
    defaultMessage="Failed to move node in tree"
    description="Inform user that move node in tree failed" />);

const NAVTREE_DELETE_FAILED = (<FormattedMessage id="NAVTREE_DELETE_FAILED"
    defaultMessage="Failed to delete node from tree"
    description="Inform user that delete node from tree failed" />);

const NAVTREE_CONFIRM_MOVE = (<FormattedMessage id="NAVTREE_CONFIRM_MOVE"
    defaultMessage="Confirm tree node move {node}"
    description="Ask user to confirm move of node" />);

const NAVTREE_DELETE_CONFIRM_DIALOG = (<FormattedMessage
    id="NAVTREE_DELETE_CONFIRM"
    defaultMessage="Please confirm the deletion of the tree node {node}"
    description="Title of deletion dialog" />);

const ATTR_CIM_TREE = "cim-tree";
const ATTR_EXPAND_ROOT = "expand-root";
const ATTR_DISABLE_MOVE = "disable-move";

const NavTreeContainer = connectNavTree(Tree);
const MenuContainer = connectNavTree(Menu);

//Initialization function
export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(NAVTREE, navtree);
    //Register components
    dispatcher.registerComponent(ATTR_CIM_TREE, (elm) => {
        let treeId = $(elm).attr(ATTR_CIM_TREE);
        let expandRoot = elm.hasAttribute(ATTR_EXPAND_ROOT);
        let disableMove = elm.hasAttribute(ATTR_DISABLE_MOVE);
        const treeComponentId = shortid.generate(); //Unique tree component ID to connect tree with menu
        //<Menu {...this.props} showCopyTextDialog={this.showCopyTextDialog} showDeleteConfirmDialog={this.showDeleteConfirmDialog} />
        return (<div style={{ height: "100%" }}>
            <NavTreeContainer
                treeId={treeId}
                treeComponentId={treeComponentId}
                expandRoot={expandRoot} />
            <MenuContainer
                treeId={treeId}
                treeComponentId={treeComponentId}
                disableMove={disableMove} />
        </div>);
    })
    //Subscribe subject changes
    dispatcher.registerEventHandler(document, NOTIFY_SUBJECT_CHANGED, (store, _, data) => {
        let _updatedSubjects = [].concat(data.updatedSubjects);
        let _notifyId = data.notifyId;
        setTimeout(function () { //Wait for server to update cache
            store.dispatch(subjectsChanged(_updatedSubjects, _notifyId));
        }, 300);
    });
};

