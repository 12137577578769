import * as Action from '../constants/history';
import shortid from 'shortid';

const historyInitialState = {
    loading: false,
    data: null,
    filteredData: null,
    filteredPredicateId: null,
    error: null
}

function isTableRelation(rh) {
    let relationTypeInfo = rh.predicate.classRelationInfo.relationTypeInfo.toLowerCase();
    return relationTypeInfo == "composition";
}

function parseTableRelation(record) {
    if (record.relationHistories == null || record.relationHistories.length == 0) {
        record.tableRelationRows = null;
        return;
    }
    record.tableRelationRows = record.relationHistories.filter(function (rh) {
        return isTableRelation(rh);
    });
}

function parseRelationHistory(record) {
    if (record.relationHistories == null || record.relationHistories.length == 0) {
        record.relationHistoryRows = null;
        return;
    }
    record.relationHistoryRows = record.relationHistories.filter(function (rh) {
        return !isTableRelation(rh);
    });
}

function addPredicate(nextState, predicate) {
    let id = predicate.id;
    nextState.predicates.byId[id] = {
        predicate: predicate,
        records: []
    }
    nextState.predicates.allIds.push(id);
}

function findPredicates(nextState, record, key) {
    let data = record[key];
    if (data == null || data.length == 0) {
        return;
    }
    data.map(function (historyEntry) {
        if(!historyEntry.predicate){
            return;
        }
        if (!nextState.predicates.byId[historyEntry.predicate.id]) {
            addPredicate(nextState, historyEntry.predicate);
        }
        let newRecord = Object.assign({}, record, { tripleHistories: [], fileHistories: [], fragmentHistories: [], relationHistoryRows: [], tableRelationRows: [] });
        newRecord[key].push(historyEntry);
        let predicate = nextState.predicates.byId[historyEntry.predicate.id];
        predicate.records.push(newRecord);
    })
}

function parseHistoryData(nextState, data) {
    nextState.predicates = {
        byId: {},
        allIds: []
    };
    data.map(function (record) {
        parseTableRelation(record);
        parseRelationHistory(record);
        findPredicates(nextState, record, "tripleHistories");
        findPredicates(nextState, record, "fileHistories");
        findPredicates(nextState, record, "fragmentHistories");
        findPredicates(nextState, record, "relationHistoryRows");
        findPredicates(nextState, record, "tableRelationRows");
    });
    return data;
}

function filterByPredicateId(state, predicateId) {
    let nextState = Object.assign({}, state);
    if (predicateId == null) {
        nextState.filteredData = null;
        nextState.filteredPredicateId = null;
        return nextState;
    }
    nextState.filteredData = nextState.predicates.byId[predicateId].records;
    nextState.filteredPredicateId = predicateId;
    return nextState;
}

function filterByTimestamp(state, timestamp) {
    let nextState = Object.assign({}, state);
    nextState.filteredTimestamp = timestamp;
    if (typeof timestamp != "number") {
        timestamp = Date.parse(timestamp);
        if (isNaN(timestamp)) {
            return state;
        }
    }
    if (!state.data) {
        return state;
    }
    nextState.filteredData = [];
    for (let record of state.data) {
        if (record.operationTimestamp == timestamp) {
            nextState.filteredData.push(record);
        }
    }
    return nextState;
}

function hashChanged(state, { hashParams }) {
    let nextState = Object.assign({}, state);
    if (nextState.filteredTimestamp != hashParams.timestamp) {
        if (hashParams.timestamp) {
            nextState = filterByTimestamp(nextState, hashParams.timestamp);
        } else {
            nextState.filteredTimestamp = null;
            nextState.filteredData = null;
        }
    } else if (nextState.filteredPredicateId != hashParams.parameterId) {
        if (hashParams.parameterId) {
            nextState = filterByPredicateId(nextState, hashParams.parameterId);
        } else {
            nextState.filteredPredicateId = null;
            nextState.filteredData = null;
        }
    }
    return nextState;
}

function waitForHistoryData(state) {
    return Object.assign({}, state, { loading: true });
}

function receiveHistoryData(state, { data, hashParams }) {
    let nextState = Object.assign({}, state);
    parseHistoryData(nextState, data);
    nextState.data = data;
    if (hashParams.timestamp) {
        nextState = filterByTimestamp(nextState, hashParams.timestamp);
    } else if (hashParams.parameterId) {
        nextState = filterByPredicateId(nextState, hashParams.parameterId);
    }
    return Object.assign(nextState, { loading: false });
}

function receiveErrorHistoryData(state, { error }) {
    return Object.assign({}, state, { error, loading: false });
}

export default (state = historyInitialState, action) => {
    switch (action.type) {
        case Action.WAIT_FOR_HISTORY_DATA: return waitForHistoryData(state, action.payload);
        case Action.RECEIVE_HISTORY_DATA: return receiveHistoryData(state, action.payload);
        case Action.RECEIVE_ERROR_HISTORY_DATA: return receiveErrorHistoryData(state, action.payload);
        case Action.HASH_CHANGED: return hashChanged(state, action.payload);
        default: return state;
    }
}