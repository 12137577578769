import React from 'react';
import { FormattedMessage } from 'react-intl';

class LoginGroup extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.props.onChange(event.target.value);
    }

    getDomainLoginSample() {
        if (!this.props.ldapAuth) {
            return ":";
        }
        return (<span>
            {" ("}
            <FormattedMessage
                id="LOGIN_PANEL_LOGIN_SAMPLE_DOMAIN"
                defaultMessage="Domain"
                description="Domain part of login sample" />
            \
            <FormattedMessage
                id="LOGIN_PANEL_LOGIN_SAMPLE_USER"
                defaultMessage="Username"
            description="Username part of login sample" />
            {"):"}
        </span>);
    }

    getLabel() {
        return (<label htmlFor="loginInput">
            <FormattedMessage
                id="LOGIN_PANEL_SIMPLE_LOGIN_LABEL"
                defaultMessage="Login"
                description="Label for login field for simple authentification" />
            {this.getDomainLoginSample()}
        </label>);
    }

    render() {
        return (<div className="form-group">
            {this.getLabel()}
            <input id="loginInput" className="form-control" onChange={this.onChange} ref={this.props.setupInput} />
        </div>);
    }
}

export default LoginGroup;