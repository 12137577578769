import React from 'react';

import Form from '../../objectcard/form.jsx';
import UploadStatus from '../../objectcard/uploadstatus.jsx';
import { FormattedMessage } from 'react-intl';
import Modal from '../modal.jsx';
import { connectObjectCard } from '../../../services/objectcard';
import {
    SAVE_STATE_START,
    SAVE_STATE_UPLOADS_READY,
    SAVE_STATE_SERVER_ERROR,
    SAVE_STATE_WAIT_SERVER
} from '../../../constants/objectcard';



class CardModal extends React.Component {

    constructor(props) {
        super(props);
        this.getResult = this.getResult.bind(this);
        this.saveSubject = this.saveSubject.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
    }

    saveSubject() {
        if (!this.saveDeferred) {
            this.saveDeferred = $.Deferred();
            this.props.startSave();
        }
        return this.saveDeferred;
    }

    cancelEdit() {
        if (this.props.saveState) { //Save is in progress
            this.props.cancelSave(); //Try to stop save progress
            return false; //But now we cannot do anything more so return false
        }
        return true;
    }

    componentDidUpdate(prevProps, prevState) {
        ////////////////
        //Track events//
        ////////////////
        const prevSaveState = prevProps.saveState;
        const saveState = this.props.saveState;
        if (saveState == prevSaveState) {
            return;
        }
        if (!saveState) { //Save was canceled or terminated
            if (this.saveDeferred) {
                if (prevSaveState == SAVE_STATE_WAIT_SERVER && !this.props.error) { //Subject was saved OK
                    console.log("Save modal card done!");
                    this.saveDeferred.resolve(this.props.data);
                    this.saveDeferred = null;
                } else { //Someting went wrong!
                    console.log("Save modal card canceled!");
                    this.saveDeferred.reject(this.props.data);
                    this.saveDeferred = null;
                }
            }
        } else if (saveState == SAVE_STATE_START) {
            //We do not have upload component
            if (!this.props.options.save && !this.props.options.uploadFiles) {
                this.resolveWithoutServer();
            }
        } else if (saveState == SAVE_STATE_UPLOADS_READY) {
            if (!this.props.options.save) { //Check if we should not go futher
                this.resolveWithoutServer();
            } else {
                this.props.saveSubject();
            }
        }
    }

    resolveWithoutServer() {
        if (this.saveDeferred) {
            this.props.saveSubject(this.saveDeferred);
            this.saveDeferred = null;
        }
    }

    componentDidMount() {
        if (this.props.options.isNew && this.props.options.className) {
            this.props.createNewSubject(
                this.props.options.className,
                this.props.options.parent,
                this.props.options.parentRef,
                this.props.options._prototype,
                this.props.options.notifyId,
                this.props.options.initialData
            );
        } else if (this.props.options.fetch) {
            this.props.fetchSubject(this.props.options.rdfId, this.props.options.namespace, true);
        } else {
            this.props.initializeStore(this.props.options.initialData, this.props.options.layoutClass);
        }
        this.doNotSave = this.props.options.save ? false : true; //Do not save data to server
        if (this.doNotSave && this.props.options.uploadFiles) { //Should we upload files ? 
            this.doUploadFiles = true;
        }
    }

    getTitle() {
        return (<FormattedMessage
            id="OBJECTCARD_POPUP_TITLE"
            defaultMessage="Please fill parameters of object"
            description="User should fill parameters of object to be added into table" />);
    }

    getBody() {
        return (<Form {...this.props} />);
    }

    getUploadStatus() {
        if (!this.props.options.save && !this.props.options.uploadFiles) {
            return null;
        }
        const style = {};
        if (this.props.saveState != SAVE_STATE_START) {
            style.display = "none";
        }
        return (<div className="row" style={style}>
            <div className="col-md-12"><UploadStatus {...this.props} /></div>
        </div>);
    }

    getResult() {
        //Always return true or false to enable or disable ok button
        return this.props.valid;
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for CardModal*/
            title={this.getTitle()}
            body={this.getBody()}
            footer={this.getUploadStatus()}
            /**Specific callbacks for CardModal*/
            onOk={this.saveSubject}
            onClose={this.cancelEdit}
            onCancel={this.cancelEdit}
            onHidden={this.props.destroyStore}
            result={this.getResult} />);
    }
}

export default connectObjectCard(CardModal);


