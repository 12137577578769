class Button extends React.Component {

    render() {
        return <div className="form-group">
            <button type="button" className="btn btn-secondary" onClick={this.props.onClick} disabled={this.props.disabled}>
                <i className={"fa fa-fw fa-" + this.props.icon}></i>
                <span>
                    {this.props.children}
                </span>
            </button>
        </div>;
    }
}

export default Button;