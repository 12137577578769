import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MODAL_STATUS_OK, MODAL_STATUS_CANCEL, MODAL_STATUS_CLOSE } from '../../constants/modal.js';
import { MSG_BUTTON_OK, MSG_BUTTON_CANCEL } from '../messages.jsx';

export const ERROR_INVALID_FORMAT = "ERROR_INVALID_FORMAT";
export const ERROR_RANGE_ERROR = "ERROR_RANGE_ERROR";
export const ERROR_RANGE_INTERSECTION = "ERROR_RANGE_INTERSECTION";

class Modal extends React.Component {

    constructor(props) {
        super(props);
        this.onOpen = this.onOpen.bind(this);
        this.onOk = this.onOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onHidden = this.onHidden.bind(this);
    }

    closeModal(status, result) {
        this.status = status;
        if (!result) {
            if (status == MODAL_STATUS_OK) {
                this.result = this.props.result ? this.props.result() : null;
            } else {
                this.result = null;
            }
        } else {
            this.result = result;
        }
        $(this.modal).modal('hide');
    }

    onOpen(e) {
        $(window).trigger("resize.cimfiller");
    }

    onOk() {
        if (!this.props.onOk) {
            this.closeModal(MODAL_STATUS_OK);
            return;
        }
        const rc = this.props.onOk(this);
        if (!rc) {
            return; //do nothing here
        }
        if ($.isFunction(rc.then)) { //Deferred which will be resolved later
            const _this = this;
            rc.then(function (result) {
                console.log("Modal deferred OK!!!");
                _this.closeModal(MODAL_STATUS_OK, result);
            });
            return;
        }
        this.closeModal(MODAL_STATUS_OK);
    }

    onCancel() {
        if (!this.props.onCancel) {
            this.closeModal(MODAL_STATUS_CANCEL);
            return;
        }
        const rc = this.props.onCancel(this);
        if (!rc) {
            return; //do nothing here
        }
        if ($.isFunction(rc.then)) { //Deferred which will be resolved later
            const _this = this;
            rc.then(function (result) {
                console.log("Modal deferred cancel!!!");
                _this.closeModal(MODAL_STATUS_CANCEL, result);
            });
            return;
        }
        this.closeModal(MODAL_STATUS_CANCEL);
    }

    onClose(e) {
        if (this.status) { //Status was already set in closeModal
            return;
        }
        if (!this.props.onClose) {
            return;
        }
        const rc = this.props.onClose(this);
        if (!rc) {
            e.preventDefault();
            return; //do nothing here
        }
        if ($.isFunction(rc.then)) { //Deferred which will be resolved later
            e.preventDefault();
            const _this = this;
            rc.then(function (result) {
                console.log("Modal deferred close!!!");
                _this.closeModal(MODAL_STATUS_CLOSE);
            });
            return;
        }
    }

    onHidden() {
        console.log("On hidden");
        if (!this.status) {
            this.status = MODAL_STATUS_CLOSE;
        }
        if (this.props.onHidden) {
            this.props.onHidden();
        }
        this.props.closeModal(this.props.id, this.status, this.result);

    }

    componentDidMount() {
        $(this.modal).modal({ backdrop: "static" });
        $(this.modal).on('shown.bs.modal', this.onOpen);
        $(this.modal).on('hide.bs.modal', this.onClose);
        $(this.modal).on('hidden.bs.modal', this.onHidden);
    }

    componentWillUnmount() {
        console.log("Remove handler");
        $(this.modal).off();
    }

    getTitle() {
        if (this.props.options && this.props.options.title) {
            if (this.props.options.title.id) {
                return (<FormattedMessage
                    id={this.props.options.title.id}
                    values={this.props.options.title.values} />);
            }
            return this.props.options.title;
        }
        return this.props.title || "";
    }

    getHeader() {
        const title = this.getTitle();
        return (<div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>);
    }

    getBody() {
        if (this.props.options && this.props.options.body) {
            if (this.props.options.body.id) {
                return (<div className="modal-body">
                    <FormattedMessage
                        id={this.props.options.body.id}
                        values={this.props.options.body.values} />
                </div>);
            }
            return (<div className="modal-body">{this.props.options.body}</div>);
        }
        return (<div className="modal-body">{this.props.body}</div>);
    }

    getFooter() {
        let okDisabled = false;
        if (this.props.result) {
            okDisabled = this.props.result() ? false : true;
        }
        return (<div className="modal-footer">
            {this.props.footer}
            <div className="row w-100">
                <div className="btn-toolbar w-100">
                    <div className="btn-group" role="group" aria-label="...">
                        <button onClick={this.onOk} disabled={okDisabled} type="button" className="btn btn-secondary modal-button-ok">
                            {MSG_BUTTON_OK}
                        </button>
                    </div>
                    <div className="d-flex flex-fill justify-content-end">
                        <div className="btn-group" role="group" aria-label="...">
                            {this.getFooterButtons()}
                        </div>
                    </div>
                    <div className="btn-group" role="group" aria-label="...">
                        <button onClick={this.onCancel} type="button" className="btn btn-secondary modal-button-cancel">
                            {MSG_BUTTON_CANCEL}
                        </button>
                    </div>
                </div>
            </div>
        </div>);
    }

    getFooterButtons() {
        if (!this.props.footerButtons) {
            return null;
        }
        let onOk = this.onOk;
        let onCancel = this.onCancel;
        let onClose = this.onClose;
        return this.props.footerButtons.map((button, index) =>
            <button
                key={button.key ? button.key : index}
                onClick={() => button.onClick({ onOk, onCancel, onClose })}
                disabled={button.disabled}
                type="button"
                className={button.className ? button.className : "btn btn-secondary"}>
                {button.message}
            </button>);
    }

    render() {
        const options = this.props.options || {};
        let modalDialogCls = "modal-dialog"
        if (options.size == "small") {
            modalDialogCls += " modal-sm";
        } else if (options.size == "large") {
            modalDialogCls += " modal-lg";
        } else if (options.size == "fullscreen") {
            modalDialogCls += " modal-fscr";
        }
        return (<div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog" ref={(modal) => { this.modal = modal; }}>
            <div className={modalDialogCls} role="document">
                <div className="modal-content">
                    {this.getHeader()}
                    {this.getBody()}
                    {this.getFooter()}
                </div>
            </div>
        </div>);
    }
}

export default Modal;