import * as Action from '../constants/treesearch';
import { getResult } from '../services/finder';
import { composeUrl, BASE_SEARCH_URL, BASE_OPTIONS_URL } from '../services/npt-treebeard';
import { FINDER_CLASS_LEVELS_URL, FINDER_CLASSES_URL, FINDER_FRAGMENT_LEVELS_URL, FINDER_FRAGMENT_URL } from './table';


/////////////////////
//Utility functions//
/////////////////////
function ajaxSeachTree(treeId, request, dispatch) {
    console.log("AJAX call to search: " + request);
    let url = composeUrl(BASE_SEARCH_URL, treeId);
    dispatch(waitForTreeSearch(request));
    return $.ajax({
        type: 'POST',
        contentType: 'application/json',
        processData: false,
        url,
        data: JSON.stringify(request),
        dataType: 'json',
        success: function (data) {
            dispatch(receiveSearchResult(data));
        },
        error: function (error) {
            dispatch(receiveSearchResultError(error));
        }
    });
}

function ajaxFetchClassLevels(classTree, dispatch) {
    const url = composeUrl(FINDER_CLASS_LEVELS_URL, classTree);
    $.get(url, function (data) {
        dispatch(classLevelsReceived(data));
    }).fail(function (error) {
        console.error("Failed to download class levels: ", error);
    });
}

function ajaxFetchClasses(classTree, levelId, dispatch) {
    const url = composeUrl(FINDER_CLASSES_URL, classTree, { node: levelId });
    dispatch(waitForClasses(levelId));
    return $.get(url, function (data) {
        dispatch(classesReceived(levelId, data));
    }).fail(function (error) {
        console.error("Failed to download classes: ", error);
    });
}

function ajaxFetchFragmentLevels(fragmentsPath, dispatch) {
    const url = composeUrl(FINDER_FRAGMENT_LEVELS_URL, fragmentsPath);
    $.get(url, function (data) {
        dispatch(fragmentLevelsReceived(data));
    }).fail(function (error) {
        console.error("Failed to download fragment levels: ", error);
    });
}

function ajaxFetchFragments(fragmentTree, parentId, dispatch) {
    const url = composeUrl(FINDER_FRAGMENT_URL, fragmentTree, parentId ? { node: parentId } : null);
    dispatch(waitForFragments(parentId));
    return $.get(url, function (data) {
        dispatch(fragmentsReceived(parentId, data));
    }).fail(function (error) {
        console.error("Failed to download fragments: ", error);
    });
}


///////////
//Actions//
///////////
function waitForTreeSearch(request) {
    return {
        type: Action.WAIT_FOR_TREE_SEARCH,
        payload: { request }
    }
}

function receiveSearchResult(result) {
    return {
        type: Action.RECEIVE_SEARCH_RESULT,
        payload: { result }
    }
}

function receiveSearchResultError(error) {
    return {
        type: Action.RECEIVE_SEARCH_RESULT_ERROR,
        payload: { error }
    }
}

export function search(treeId, request) {
    return function (dispatch, getState) {
        let searchState = getState()[Action.TREESEARCH];
        //Fetch subtree if needed
        if (searchState.loading) {
            return;
        }
        if (searchState.finder) {
            request.finder = getResult(searchState.finder, getState()[I18N].messages);
        }
        console.log("Search:", request);
        ajaxSeachTree(treeId, request, dispatch);
    }
}

export function selectSearchFragment(oldFragmentId, newFragmentId) {
    return {
        type: Action.SELECT_FRAGMENT,
        payload: { oldFragmentId, newFragmentId }
    }
}

export function fetchFragments(parentFragmentIds) {
    return function (dispatch, getState) {
        if (parentFragmentIds == null) {
            parentFragmentIds = [null];
        }
        let searchState = getState()[Action.TREESEARCH];
        for (let fragmentId of parentFragmentIds) {
            if (searchState.finder.loadedFragments.fetched[fragmentId]) {
                continue;
            }
            ajaxFetchFragments(searchState.finder.fragmentTree, fragmentId, dispatch);
        }
    }
}

export function selectSearchClass(oldClassId, newClassId, level) {
    return {
        type: Action.SELECT_CLASS,
        payload: { oldClassId, newClassId, level }
    }
}

export function fetchClasses(levelId) {
    return function (dispatch, getState) {
        let searchState = getState()[Action.TREESEARCH];
        if (searchState.finder.loadedClasses.fetched[levelId]) {
            return;
        }
        ajaxFetchClasses(searchState.finder.classTree, levelId, dispatch);
    }
}

export function toggleFinder() {
    return {
        type: Action.TOGGLE_FINDER,
        payload: {}
    }
}

function receiveFinderOptions(data) {
    return {
        type: Action.RECEIVE_FINDER_OPTIONS,
        payload: { data }
    }
}

function receiveFinderOptionsError(error) {
    return {
        type: Action.RECEIVE_FINDER_OPTIONS_ERROR,
        payload: { error }
    }
}

export function getFinderOptions(treeId) {
    return function (dispatch, getState) {
        let url = composeUrl(BASE_OPTIONS_URL, treeId);
        return $.ajax({
            type: 'GET',
            contentType: 'application/json',
            processData: false,
            url,
            dataType: 'json',
            success: function (data) {
                dispatch(receiveFinderOptions(data));
                if (data.finderOptions.classTree) {
                    ajaxFetchClassLevels(data.finderOptions.classTree, dispatch);
                }
                if (data.finderOptions.fragmentTree) {
                    ajaxFetchFragmentLevels(data.finderOptions.fragmentTree, dispatch);
                }
            },
            error: function (error) {
                dispatch(receiveFinderOptionsError(error));
            }
        });
    }
}

function classLevelsReceived(levels) {
    return {
        type: Action.CLASS_LEVELS_RECEIVED,
        payload: { levels }
    }
}

function waitForClasses(levelId) {
    return {
        type: Action.WAIT_FOR_CLASSES,
        payload: { levelId }
    }
}

function classesReceived(levelId, classes) {
    return {
        type: Action.CLASSES_RECEIVED,
        payload: { levelId, classes }
    }
}

function fragmentLevelsReceived(levels) {
    return {
        type: Action.FRAGMENT_LEVELS_RECEIVED,
        payload: { levels }
    }
}

function waitForFragments(parentId) {
    return {
        type: Action.WAIT_FOR_FRAGMENTS,
        payload: { parentId }
    }
}

function fragmentsReceived(parentId, fragments) {
    return {
        type: Action.FRAGMENTS_RECEIVED,
        payload: { parentId, fragments }
    }
}
