export const MESSAGEBOX = "messagebox";

//Actions
export const WAIT_FOR_MESSAGES = "@@MESSAGEBOX_WAIT_FOR_MESSAGES";
export const MESSAGES_RECEIVED = "@@MESSAGEBOX_MESSAGES_RECEIVED";
export const MESSAGES_RECEIVED_ERROR = "@@MESSAGEBOX_MESSAGES_RECEIVED_ERROR";
export const CHANGE_PAGE_SIZE = "@@MESSAGEBOX_CHANGE_PAGE_SIZE";
export const UPDATE_MESSAGE = "@@MESSAGEBOX_UPDATE_MESSAGE";
export const SELECT_ROW = "@@MESSAGEBOX_SELECT_ROW";
export const SELECT_ALL = "@@MESSAGEBOX_SELECT_ALL";
export const MESSAGE_DOWNLOADED = "@@MESSAGEBOX_MESSAGE_DOWNLOADED";
export const MESSAGE_DOWNLOAD_ERROR = "@@MESSAGEBOX_MESSAGE_DOWNLOAD_ERROR";
export const CLOSE_ALERT = "@@MESSAGEBOX_CLOSE_ALERT";
export const CALL_ALERT = "@@MESSAGEBOX_CALL_ALERT";