const pickerTemplate = "<div class='col-sm-12 nopadding'>" +
    "   <div class='col-sm-12 nopadding d-flex'>" +
    "       <div class='timepicker-col col timepicker-button'><button type='button' class='fa fa-chevron-up' id='hour-increase'></button></div>" +
    "       <div class='timepicker-col col separator'></div>" +
    "       <div class='timepicker-col col timepicker-button'><button type='button' class='fa fa-chevron-up' id='minute-increase'></button></div>" +
    "       <div class='timepicker-col col separator'></div>" +
    "       <div class='timepicker-col col timepicker-button'><button type='button' class='fa fa-chevron-up' id='second-increase'></button></div>" +
    "       <div class='timepicker-col col milliseconds d-none separator'></div>" +
    "       <div class='timepicker-col col milliseconds d-none timepicker-button'><button type='button' class='fa fa-chevron-up' id='millisecond-increase'></button></div>" +
    "   </div>" +
    "   <div class='col-sm-12 nopadding d-flex'>" +
    "       <div class='timepicker-col col value'><span id='hour-value'></span></div>" +
    "       <div class='timepicker-col col separator'><span>:</span></div>" +
    "       <div class='timepicker-col col value'><span id='minute-value'></span></div>" +
    "       <div class='timepicker-col col separator'><span>:</span></div>" +
    "       <div class='timepicker-col col value'><span id='second-value'></span></div>" +
    "       <div class='timepicker-col col milliseconds d-none separator'><span>.</span></div>" +
    "       <div class='timepicker-col col milliseconds d-none value'><span id='millisecond-value'></span></div>" +
    "   </div>" +
    "   <div class='col-sm-12 nopadding d-flex'>" +
    "       <div class='timepicker-col col timepicker-button'><button type='button' class='fa fa-chevron-down' id='hour-decrease'></button></div>" +
    "       <div class='timepicker-col col separator'></div>" +
    "       <div class='timepicker-col col timepicker-button'><button type='button' class='fa fa-chevron-down' id='minute-decrease'></button></div>" +
    "       <div class='timepicker-col col separator'></div>" +
    "       <div class='timepicker-col col timepicker-button'><button type='button' class='fa fa-chevron-down' id='second-decrease'></button></div>" +
    "       <div class='timepicker-col col milliseconds d-none separator'></div>" +
    "       <div class='timepicker-col col milliseconds d-none timepicker-button'><button type='button' class='fa fa-chevron-down' id='millisecond-decrease'></button></div>" +
    "   </div>" +
    "</div>";

const PICKER = "picker";
const HOURS = "hours";
const MINUTES = "minutes";
const SECONDS = "seconds";
const MILLISECONDS = "milliseconds";

function toFixedLength(number, length) {
    let string = number.toString();
    while (string.length < length) {
        string = "0" + string;
    }
    return string;
}

function getHoursPicker(timePicker) {
    let container = $("<div class='col-sm-12 nopadding timepicker-choose'></div>");
    for (let i = 0; i < 6; ++i) {
        let row = $("<div class='col-sm-12 nopadding d-flex'></div>");
        container.append(row);
        for (let j = 0; j < 4; ++j) {
            let hours = i * 4 + j;
            let cell = $("<div class='col-sm-3'><span>" + toFixedLength(hours, 2) + "</span></div>");
            row.append(cell);
            cell.on("click", function () {
                timePicker.setHours(Number(hours));
                timePicker.changeDate();
                timePicker.changeView(PICKER);
            });
        }
    }
    return container;
}

function getMinutesPicker(timePicker) {
    let container = $("<div class='col-sm-12 nopadding timepicker-choose'></div>");
    for (let i = 0; i < 5; ++i) {
        let row = $("<div class='col-sm-12 nopadding d-flex'></div>");
        container.append(row);
        for (let j = 0; j < 4; ++j) {
            let minutes = i * 12 + j * 3;
            let cell = $("<div class='col-sm-3'><span>" + toFixedLength(minutes, 2) + "</span></div>");
            row.append(cell);
            cell.on("click", function () {
                timePicker.setMinutes(Number(minutes));
                timePicker.changeDate();
                timePicker.changeView(PICKER);
            });
        }
    }
    return container;
}

function getSecondsPicker(timePicker) {
    let container = $("<div class='col-sm-12 nopadding timepicker-choose'></div>");
    for (let i = 0; i < 5; ++i) {
        let row = $("<div class='col-sm-12 nopadding d-flex'></div>");
        container.append(row);
        for (let j = 0; j < 4; ++j) {
            let seconds = i * 12 + j * 3;
            let cell = $("<div class='col-sm-3'><span>" + toFixedLength(seconds, 2) + "</span></div>");
            row.append(cell);
            cell.on("click", function (event) {
                timePicker.setSeconds(Number(seconds));
                timePicker.changeDate();
                timePicker.changeView(PICKER);
            });
        }
    }
    return container;
}

function getMillisecondsPicker(timePicker) {
    let container = $("<div class='col-sm-12 nopadding timepicker-choose'></div>");
    for (let i = 0; i < 5; ++i) {
        let row = $("<div class='col-sm-12 nopadding d-flex'></div>");
        container.append(row);
        for (let j = 0; j < 4; ++j) {
            let milliseconds = i * 200 + j * 50;
            let cell = $("<div class='col-sm-3'><span>" + toFixedLength(milliseconds, 3) + "</span></div>");
            row.append(cell);
            cell.on("click", function () {
                timePicker.setMilliseconds(Number(milliseconds));
                timePicker.changeDate();
                timePicker.changeView(PICKER);
            });
        }
    }
    return container;
}

export function timepicker(options) {
    let timePicker = {};
    timePicker.picker = $("<div class='timepicker'></div>");
    timePicker.pickerElement = $(pickerTemplate);
    timePicker.hoursElement = $("#hour-value", timePicker.pickerElement);
    timePicker.minutesElement = $("#minute-value", timePicker.pickerElement);
    timePicker.secondsElement = $("#second-value", timePicker.pickerElement);
    timePicker.millisecondsElement = $("#millisecond-value", timePicker.pickerElement);

    timePicker.pickerHoursElement = getHoursPicker(timePicker);
    timePicker.pickerMinutesElement = getMinutesPicker(timePicker);
    timePicker.pickerSecondsElement = getSecondsPicker(timePicker);
    timePicker.pickerMillisecondsElement = getMillisecondsPicker(timePicker);

    $("#hour-increase", timePicker.pickerElement).on("click", function () {
        timePicker.setHours(++timePicker.hours);
        timePicker.changeDate();
    });

    $("#minute-increase", timePicker.pickerElement).on("click", function () {
        timePicker.setMinutes(++timePicker.minutes);
        timePicker.changeDate();
    });

    $("#second-increase", timePicker.pickerElement).on("click", function () {
        timePicker.setSeconds(++timePicker.seconds);
        timePicker.changeDate();
    });

    $("#millisecond-increase", timePicker.pickerElement).on("click", function () {
        timePicker.setMilliseconds(++timePicker.milliseconds);
        timePicker.changeDate();
    });

    $("#hour-decrease", timePicker.pickerElement).on("click", function () {
        timePicker.setHours(--timePicker.hours);
        timePicker.changeDate();
    });

    $("#minute-decrease", timePicker.pickerElement).on("click", function () {
        timePicker.setMinutes(--timePicker.minutes);
        timePicker.changeDate();
    });

    $("#second-decrease", timePicker.pickerElement).on("click", function () {
        timePicker.setSeconds(--timePicker.seconds);
        timePicker.changeDate();
    });

    $("#millisecond-decrease", timePicker.pickerElement).on("click", function () {
        timePicker.setMilliseconds(--timePicker.milliseconds);
        timePicker.changeDate();
    });

    timePicker.hoursElement.on("click", function () {
        timePicker.changeView(HOURS);
    });

    timePicker.minutesElement.on("click", function () {
        timePicker.changeView(MINUTES);
    });

    timePicker.secondsElement.on("click", function () {
        timePicker.changeView(SECONDS);
    });

    timePicker.millisecondsElement.on("click", function () {
        timePicker.changeView(MILLISECONDS);
    });

    timePicker.setHours = (function (hours) {
        if (hours < 0) {
            hours = 23;
        } else if (hours > 23) {
            hours = 0;
        }
        this.hours = hours;
        return;
    }).bind(timePicker)

    timePicker.setMinutes = (function (minutes) {
        if (minutes < 0) {
            minutes = 59;
            this.setHours(--this.hours);
        } else if (minutes > 59) {
            minutes = 0;
            this.setHours(++this.hours);
        }
        this.minutes = minutes;
        return;
    }).bind(timePicker)

    timePicker.setSeconds = (function (seconds) {
        if (seconds < 0) {
            seconds = 59;
            this.setMinutes(--this.minutes);
        } else if (seconds > 59) {
            seconds = 0;
            this.setMinutes(++this.minutes);
        }
        this.seconds = seconds;
        return;
    }).bind(timePicker)

    timePicker.setMilliseconds = (function (milliseconds) {
        if (milliseconds < 0) {
            milliseconds = 999;
            this.setSeconds(--this.seconds);
        } else if (milliseconds > 999) {
            milliseconds = 0;
            this.setSeconds(++this.seconds);
        }
        this.milliseconds = milliseconds;
        return;
    }).bind(timePicker)

    timePicker.setHoursView = (function (hours) {
        this.hoursElement[0].innerText = toFixedLength(hours, 2);
        return;
    }).bind(timePicker)

    timePicker.setMinutesView = (function (minutes) {
        this.minutesElement[0].innerText = toFixedLength(minutes, 2);
        return;
    }).bind(timePicker)

    timePicker.setSecondsView = (function (seconds) {
        this.secondsElement[0].innerText = toFixedLength(seconds, 2);
        return;
    }).bind(timePicker)

    timePicker.setMillisecondsView = (function (milliseconds) {
        this.millisecondsElement[0].innerText = toFixedLength(milliseconds, 3);
        return;
    }).bind(timePicker)

    timePicker.updateView = (function () {
        this.setHoursView(this.hours);
        this.setMinutesView(this.minutes);
        this.setSecondsView(this.seconds);
        this.setMillisecondsView(this.milliseconds);
        return;
    }).bind(timePicker)

    timePicker.setCurrentTime = (function () {
        let date = new Date();
        this.hours = date.getHours();
        this.minutes = date.getMinutes();
        this.seconds = date.getSeconds();
        this.milliseconds = date.getMilliseconds();
        this.updateView();
        return;
    }).bind(timePicker)

    timePicker.setTime = (function (time) {
        if (!time) {
            this.setCurrentTime();
            return;
        }
        switch (typeof time) {
            case "object":
                if (time instanceof Date) {
                    this.hours = time.getHours();
                    this.minutes = time.getMinutes();
                    this.seconds = time.getSeconds();
                    this.milliseconds = time.getMilliseconds();
                    this.updateView();
                    return;
                }
                break;
            case "string":
                let parsedDate = Date.parse(time);
                if (isNaN(parsedDate)) {
                    break;
                }
                let date = new Date(parsedDate);
                this.hours = date.getHours();
                this.minutes = date.getMinutes();
                this.seconds = date.getSeconds();
                this.milliseconds = time.getMilliseconds();
                this.updateView();
                return;
            default:
                break;
        }
        this.setCurrentTime();
        return;
    }).bind(timePicker);

    timePicker.getTime = (function () {
        if (options && options.showMilliseconds) {
            return this.hours + ":" + this.minutes + ":" + this.seconds + "." + this.milliseconds;
        }
        return this.hours + ":" + this.minutes + ":" + this.seconds;
    }).bind(timePicker);

    timePicker.clear = (function () {
        var pickerHTML = this.picker[0];
        while (pickerHTML.firstChild) {
            pickerHTML.removeChild(pickerHTML.firstChild);
        }
    }).bind(timePicker);

    timePicker.changeView = (function (view) {
        this.clear();
        switch (view) {
            case HOURS:
                this.picker.append(this.pickerHoursElement);
                break;
            case MINUTES:
                this.picker.append(this.pickerMinutesElement);
                break;
            case SECONDS:
                this.picker.append(this.pickerSecondsElement);
                break;
            case MILLISECONDS:
                this.picker.append(this.pickerMillisecondsElement);
                break;
            case PICKER:
            default:
                this.picker.append(this.pickerElement);
                break;
        }
    }).bind(timePicker);

    timePicker.changeDate = (function () {
        this.updateView();
        this.onChangeDate();
    }).bind(timePicker);

    timePicker.setCurrentTime();
    if (options) {
        if (options.initialTime) {
            timePicker.setTime(options.initialTime);
        }
        if (options.changeDate) {
            timePicker.onChangeDate = (function () {
                options.changeDate(this.getTime(), { hours: this.hours, minutes: this.minutes, seconds: this.seconds, milliseconds: this.milliseconds });
            }).bind(timePicker);
        } else {
            timePicker.onChangeDate = function () {
                console.log("Change date function wasn't implemented");
            };
        }
        
        if(options.showMilliseconds){
            $(".milliseconds", timePicker.pickerElement).removeClass("d-none");
        }
    }

    timePicker.changeView(PICKER);
    timePicker.updateView();

    return timePicker;
}