import React from 'react';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import { DETAILS, TAB_SETPOINTS } from '../../constants/rpasetpoints';
import DebounceInput from '../debounceinput.jsx';

import { MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../messages.jsx';

const STRING = 0;
const BOOLEAN = 1;
const ARRAY = 2;
const STRING_OR_ENUMERATION = 3;

class Details extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: true
        }

        this.ignoreMap = {
            id: true,
            folder: true,
            parentId: true,
            comparedId: true,
            enumeration: true
        };

        this.folderDetails = [
            {
                label: "RPA_SETPOINTS_NAME",
                field: "name"
            }
        ]

        this.setpointsDetails = [
            {
                label: "RPA_SETPOINTS_NAME",
                field: "name"
            },
            {
                label: "RPA_SETPOINTS_ORIGINAL_ID",
                field: "originalId"
            },
            {
                label: "RPA_SETPOINTS_LINKED_ID",
                field: "linkedId",
                type: ARRAY
            },
            {
                label: "RPA_SETPOINTS_IS_ENUM",
                field: "isEnum",
                type: BOOLEAN,
                editable: true
            },
            {
                label: "RPA_SETPOINTS_VALUE",
                field: "value",
                type: STRING_OR_ENUMERATION,
                editable: true
            },
            {
                label: "RPA_SETPOINTS_RAW_VALUE",
                field: "rawValue"
            },
            {
                label: "RPA_SETPOINTS_DIMENSION",
                field: "dimension"
            },
            {
                label: "RPA_SETPOINTS_IS_PRIMARY",
                field: "primary"
            }
        ]

        this.signalsDetails = [
            {
                label: "RPA_SETPOINTS_NAME",
                field: "name"
            },
            {
                label: "RPA_SETPOINTS_CHANNEL_ID",
                field: "chId"
            },
            {
                label: "RPA_SETPOINTS_LINKED_ID",
                field: "linkedId"
            },
            {
                label: "RPA_SETPOINTS_CCBM",
                field: "ccbm"
            },
            {
                label: "RPA_SETPOINTS_CHANNEL_MULTIPLIER",
                field: "channelMultiplier"
            },
            {
                label: "RPA_SETPOINTS_CHANNEL_OFFSET",
                field: "channelOffset"
            },
            {
                label: "RPA_SETPOINTS_IEC_CHANNEL_ID",
                field: "iecChannelId"
            },
            {
                label: "RPA_SETPOINTS_INDEX",
                field: "index"
            },
            {
                label: "RPA_SETPOINTS_MAX",
                field: "max"
            },
            {
                label: "RPA_SETPOINTS_MIN",
                field: "min"
            },
            {
                label: "RPA_SETPOINTS_PH",
                field: "ph",
                type: STRING,
                editable: true
            },
            {
                label: "RPA_SETPOINTS_PRIMARY",
                field: "primary"
            },
            {
                label: "RPA_SETPOINTS_PS",
                field: "ps"
            },
            {
                label: "RPA_SETPOINTS_SECONDARY",
                field: "secondary"
            },
            {
                label: "RPA_SETPOINTS_SKEW",
                field: "skew"
            },
            {
                label: "RPA_SETPOINTS_UNIT",
                field: "unit"
            }
        ]
    }

    changeCheckbox(field, event) {
        this.changeDetailsParameter(field, Boolean(event.target.checked))
    }

    changeDetailsParameter(field, value) {
        if(this.props.node[field] == value){
            return;
        }
        this.setState(Object.assign({}, this.state, { editable: false }));
        this.props.changeDetailsParameter(field, value)
    }

    selectDetailsParameter(field, option) {
        this.changeDetailsParameter(field, option.value)
    }

    printStringInput(detail, forcedValue) {
        return <DebounceInput
            editable={!this.props.disabled && this.state.editable}
            valid={true}
            value={forcedValue || this.props.node[detail.field]}
            onFinish={this.changeDetailsParameter.bind(this, detail.field)}
            className="form-control"
        />;
    }

    printBooleanInput(detail) {
        return <input type="checkbox" className="d-flex" checked={Boolean(this.props.node[detail.field])} onChange={this.changeCheckbox.bind(this, detail.field)} disabled={this.props.disabled} />
    }

    printEnumerationInput(detail) {
        const options = [];
        for (let value of this.props.node.enumeration) {
            options.push({ value: value, label: value });
        }
        return <Select
            name="enumValue"
            loadingPlaceholder={MSG_SELECT_LOADING}
            placeholder={MSG_SELECT_PLACEHOLDER}
            noResultsText={MSG_SELECT_NO_RESULTS}
            value={this.props.node[detail.field]}
            backspaceRemoves={false}
            clearable={false}
            options={options}
            className="minified-react-select card-input"
            onChange={this.selectDetailsParameter.bind(this, detail.field)}
            disabled={this.props.disabled || !this.state.editable}
        />
    }

    printInput(detail) {
        if (detail.type == STRING_OR_ENUMERATION && this.props.node.enumeration) {
            return this.printEnumerationInput(detail);
        }
        if (detail.type == BOOLEAN) {
            return this.printBooleanInput(detail);
        }
        return this.printStringInput(detail);
    }

    printArrayField(detail) {
        let value = this.props.node[detail.field];
        if (Array.isArray(value)) {
            value = value.join("/");
        }
        return <span className="d-flex">{value}</span>;
    }

    printField(detail) {
        if (detail.type == ARRAY) {
            return this.printArrayField(detail);
        }
        return <span className="d-flex">{this.props.node[detail.field]}</span>;
    }

    printDetails(details) {
        return (
            <div className="npt-rpasetpoints-details-container">
                <table className="table">
                    <tr>
                        <th style={{ width: 230 }}>
                            <FormattedMessage
                                id="RPA_SETPOINTS_LABEL"
                                defaultMessage="Label"
                                description="Label column" />
                        </th>
                        <th style={{ width: 150 }}>
                            <FormattedMessage
                                id="RPA_SETPOINTS_IDENTIFIER"
                                defaultMessage="Identifier"
                                description="Identifier column" />
                        </th>
                        <th>
                            <FormattedMessage id="RPA_SETPOINTS_VALUE" />
                        </th>
                    </tr>
                    {details.map((d) => <tr>
                        <td>
                            <FormattedMessage id={d.label} />
                        </td>
                        <td>
                            {d.field}
                        </td>
                        <td>
                            {this.props.primary && d.editable ? this.printInput(d) : this.printField(d)}
                        </td>
                    </tr>)}
                </table>
            </div>
        );
    }

    componentDidUpdate() {
        if (!this.state.editable) {
            this.setState(Object.assign({}, this.state, { editable: true }));
        }
    }

    render() {
        if (!this.props.node) {
            return null;
        }
        let params = [];
        for (let p in this.props.node) {
            if (!this.ignoreMap[p]) {
                params.push(p);
            }
        }
        let details;
        if (this.props.node.folder) {
            details = this.folderDetails;
        } else if (this.props.activeTab == TAB_SETPOINTS) {
            details = this.setpointsDetails;
        } else {
            details = this.signalsDetails;
        }
        return (
            <div className="npt-rpasetpoints-details" style={{ width: this.props.width }}>
                <h4>{DETAILS}</h4>
                {this.printDetails(details)}
            </div>
        );
    }
}

export default Details;