import { FormattedMessage } from 'react-intl';
import {
    MSG_MONTH_JANUARY,
    MSG_MONTH_FEBURARY,
    MSG_MONTH_MARCH,
    MSG_MONTH_APRIL,
    MSG_MONTH_MAY,
    MSG_MONTH_JUNE,
    MSG_MONTH_JULY,
    MSG_MONTH_AUGUST,
    MSG_MONTH_SEPTEMBER,
    MSG_MONTH_OCTOBER,
    MSG_MONTH_NOVEMBER,
    MSG_MONTH_DECEMBER,
    MSG_WEEK_DAY_MONDAY,
    MSG_WEEK_DAY_TUESDAY,
    MSG_WEEK_DAY_WEDNESDAY,
    MSG_WEEK_DAY_THURSDAY,
    MSG_WEEK_DAY_FRIDAY,
    MSG_WEEK_DAY_SATURDAY,
    MSG_WEEK_DAY_SUNDAY
} from '../components/messages.jsx';

//Global periodic scripts state path
export const CRONTASKS = "crontasks";

//Actions
export const WAIT_FOR_DATA = "@@CRONTASKS_WAIT_FOR_DATA";
export const RECEIVE_DATA = "@@CRONTASKS_RECEIVE_DATA";
export const RECEIVE_DATA_ERROR = "@@CRONTASKS_RECEIVE_DATA_ERROR";
export const SAVE_SCRIPT_ERROR = "@@CRONTASKS_SAVE_SCRIPT_ERROR";
export const SELECT_ROW = "@@CRONTASKS_SELECT_ROW";
export const CALL_CONFIGURE_PANEL = "@@CRONTASKS_CALL_CONFIGURE_PANEL";
export const CLOSE_CONFIGURE_PANEL = "@@CRONTASKS_CLOSE_CONFIGURE_PANEL";
export const CALL_CONFIRM_DIALOG = "@@CRONTASKS_CALL_CONFIRM_DIALOG";
export const CLOSE_CONFIRM_DIALOG = "@@CRONTASKS_CLOSE_CONFIRM_DIALOG";


//Time units
const REPEATED_TIME = {
    "*/2": <FormattedMessage
        id="CRON_TASK_EVERY_TWO"
        defaultMessage="Every two"
        description="Every two" />,
    "*/3": <FormattedMessage
        id="CRON_TASK_EVERY_THREE"
        defaultMessage="Every three"
        description="Every three" />,
    "*/4": <FormattedMessage
        id="CRON_TASK_EVERY_FOUR"
        defaultMessage="Every four"
        description="Every four" />,
    "*/5": <FormattedMessage
        id="CRON_TASK_EVERY_FIVE"
        defaultMessage="Every five"
        description="Every five" />,
    "*/6": <FormattedMessage
        id="CRON_TASK_EVERY_SIX"
        defaultMessage="Every six"
        description="Every six" />,
    "*/8": <FormattedMessage
        id="CRON_TASK_EVERY_EIGHT"
        defaultMessage="Every eight"
        description="Every eight" />,
    "*/10": <FormattedMessage
        id="CRON_TASK_EVERY_TEN"
        defaultMessage="Every ten"
        description="Every ten" />,
    "*/12": <FormattedMessage
        id="CRON_TASK_EVERY_TWELVE"
        defaultMessage="Every twelve"
        description="Every twelve" />,
    "*/15": <FormattedMessage
        id="CRON_TASK_EVERY_FIFTEEN"
        defaultMessage="Every fifteen"
        description="Every fifteen" />,
    "*/20": <FormattedMessage
        id="CRON_TASK_EVERY_TWENTY"
        defaultMessage="Every twenty"
        description="Every twenty" />,
    "*/24": <FormattedMessage
        id="CRON_TASK_EVERY_TWENTY_FOUR"
        defaultMessage="Every twenty four"
        description="Every twenty four" />,
    "*/30": <FormattedMessage
        id="CRON_TASK_EVERY_THIRTY"
        defaultMessage="Every thirty"
        description="Every thirty" />
}

export const TIME_UNITS = {
    second: [
        {
            originalValue: "*",
            value: <FormattedMessage
                id="CRON_TASK_EVERY_SECOND"
                defaultMessage="Every second"
                description="Every second" />
        },
        {
            originalValue: "*/2",
            value: <FormattedMessage
                id="CRON_TASK_EVERY_TWO_SECONDS"
                defaultMessage="Every two"
                description="Every two seconds" />
        },
        {
            originalValue: "*/3",
            value: REPEATED_TIME["*/3"]
        },
        {
            originalValue: "*/4",
            value: REPEATED_TIME["*/4"]
        },
        {
            originalValue: "*/5",
            value: REPEATED_TIME["*/5"]
        },
        {
            originalValue: "*/6",
            value: REPEATED_TIME["*/6"]
        },
        {
            originalValue: "*/10",
            value: REPEATED_TIME["*/10"]
        },
        {
            originalValue: "*/15",
            value: REPEATED_TIME["*/15"]
        },
        {
            originalValue: "*/20",
            value: REPEATED_TIME["*/20"]
        },
        {
            originalValue: "*/30",
            value: REPEATED_TIME["*/30"]
        },
        { originalValue: "00", value: "00" },
        { originalValue: "01", value: "01" },
        { originalValue: "02", value: "02" },
        { originalValue: "03", value: "03" },
        { originalValue: "04", value: "04" },
        { originalValue: "05", value: "05" },
        { originalValue: "06", value: "06" },
        { originalValue: "07", value: "07" },
        { originalValue: "08", value: "08" },
        { originalValue: "09", value: "09" },
        { originalValue: "10", value: "10" },
        { originalValue: "11", value: "11" },
        { originalValue: "12", value: "12" },
        { originalValue: "13", value: "13" },
        { originalValue: "14", value: "14" },
        { originalValue: "15", value: "15" },
        { originalValue: "16", value: "16" },
        { originalValue: "17", value: "17" },
        { originalValue: "18", value: "18" },
        { originalValue: "19", value: "19" },
        { originalValue: "20", value: "20" },
        { originalValue: "21", value: "21" },
        { originalValue: "22", value: "22" },
        { originalValue: "23", value: "23" },
        { originalValue: "24", value: "24" },
        { originalValue: "25", value: "25" },
        { originalValue: "26", value: "26" },
        { originalValue: "27", value: "27" },
        { originalValue: "28", value: "28" },
        { originalValue: "29", value: "29" },
        { originalValue: "30", value: "30" },
        { originalValue: "31", value: "31" },
        { originalValue: "32", value: "32" },
        { originalValue: "33", value: "33" },
        { originalValue: "34", value: "34" },
        { originalValue: "35", value: "35" },
        { originalValue: "36", value: "36" },
        { originalValue: "37", value: "37" },
        { originalValue: "38", value: "38" },
        { originalValue: "39", value: "39" },
        { originalValue: "40", value: "40" },
        { originalValue: "41", value: "41" },
        { originalValue: "42", value: "42" },
        { originalValue: "43", value: "43" },
        { originalValue: "44", value: "44" },
        { originalValue: "45", value: "45" },
        { originalValue: "46", value: "46" },
        { originalValue: "47", value: "47" },
        { originalValue: "48", value: "48" },
        { originalValue: "49", value: "49" },
        { originalValue: "50", value: "50" },
        { originalValue: "51", value: "51" },
        { originalValue: "52", value: "52" },
        { originalValue: "53", value: "53" },
        { originalValue: "54", value: "54" },
        { originalValue: "55", value: "55" },
        { originalValue: "56", value: "56" },
        { originalValue: "57", value: "57" },
        { originalValue: "58", value: "58" },
        { originalValue: "59", value: "59" }
    ],
    minute: [
        {
            originalValue: "*",
            value: <FormattedMessage
                id="CRON_TASK_EVERY_MINUTE"
                defaultMessage="Every minute"
                description="Every minute" />
        },
        {
            originalValue: "*/2",
            value: <FormattedMessage
            id="CRON_TASK_EVERY_TWO_MINUTES"
            defaultMessage="Every two"
            description="Every two minutes" />
        },
        {
            originalValue: "*/3",
            value: REPEATED_TIME["*/3"]
        },
        {
            originalValue: "*/4",
            value: REPEATED_TIME["*/4"]
        },
        {
            originalValue: "*/5",
            value: REPEATED_TIME["*/5"]
        },
        {
            originalValue: "*/6",
            value: REPEATED_TIME["*/6"]
        },
        {
            originalValue: "*/10",
            value: REPEATED_TIME["*/10"]
        },
        {
            originalValue: "*/15",
            value: REPEATED_TIME["*/15"]
        },
        {
            originalValue: "*/20",
            value: REPEATED_TIME["*/20"]
        },
        {
            originalValue: "*/30",
            value: REPEATED_TIME["*/30"]
        },
        { originalValue: "00", value: "00" },
        { originalValue: "01", value: "01" },
        { originalValue: "02", value: "02" },
        { originalValue: "03", value: "03" },
        { originalValue: "04", value: "04" },
        { originalValue: "05", value: "05" },
        { originalValue: "06", value: "06" },
        { originalValue: "07", value: "07" },
        { originalValue: "08", value: "08" },
        { originalValue: "09", value: "09" },
        { originalValue: "10", value: "10" },
        { originalValue: "11", value: "11" },
        { originalValue: "12", value: "12" },
        { originalValue: "13", value: "13" },
        { originalValue: "14", value: "14" },
        { originalValue: "15", value: "15" },
        { originalValue: "16", value: "16" },
        { originalValue: "17", value: "17" },
        { originalValue: "18", value: "18" },
        { originalValue: "19", value: "19" },
        { originalValue: "20", value: "20" },
        { originalValue: "21", value: "21" },
        { originalValue: "22", value: "22" },
        { originalValue: "23", value: "23" },
        { originalValue: "24", value: "24" },
        { originalValue: "25", value: "25" },
        { originalValue: "26", value: "26" },
        { originalValue: "27", value: "27" },
        { originalValue: "28", value: "28" },
        { originalValue: "29", value: "29" },
        { originalValue: "30", value: "30" },
        { originalValue: "31", value: "31" },
        { originalValue: "32", value: "32" },
        { originalValue: "33", value: "33" },
        { originalValue: "34", value: "34" },
        { originalValue: "35", value: "35" },
        { originalValue: "36", value: "36" },
        { originalValue: "37", value: "37" },
        { originalValue: "38", value: "38" },
        { originalValue: "39", value: "39" },
        { originalValue: "40", value: "40" },
        { originalValue: "41", value: "41" },
        { originalValue: "42", value: "42" },
        { originalValue: "43", value: "43" },
        { originalValue: "44", value: "44" },
        { originalValue: "45", value: "45" },
        { originalValue: "46", value: "46" },
        { originalValue: "47", value: "47" },
        { originalValue: "48", value: "48" },
        { originalValue: "49", value: "49" },
        { originalValue: "50", value: "50" },
        { originalValue: "51", value: "51" },
        { originalValue: "52", value: "52" },
        { originalValue: "53", value: "53" },
        { originalValue: "54", value: "54" },
        { originalValue: "55", value: "55" },
        { originalValue: "56", value: "56" },
        { originalValue: "57", value: "57" },
        { originalValue: "58", value: "58" },
        { originalValue: "59", value: "59" }
    ],
    hour: [
        {
            originalValue: "*",
            value: <FormattedMessage
                id="CRON_TASK_EVERY_HOUR"
                defaultMessage="Every hour"
                description="Every hour" />
        },
        {
            originalValue: "*/2",
            value: REPEATED_TIME["*/2"]
        },
        {
            originalValue: "*/3",
            value: REPEATED_TIME["*/3"]
        },
        {
            originalValue: "*/4",
            value: REPEATED_TIME["*/4"]
        },
        {
            originalValue: "*/6",
            value: REPEATED_TIME["*/6"]
        },
        {
            originalValue: "*/8",
            value: REPEATED_TIME["*/8"]
        },
        {
            originalValue: "*/12",
            value: REPEATED_TIME["*/12"]
        },
        { originalValue: "00", value: "00" },
        { originalValue: "01", value: "01" },
        { originalValue: "02", value: "02" },
        { originalValue: "03", value: "03" },
        { originalValue: "04", value: "04" },
        { originalValue: "05", value: "05" },
        { originalValue: "06", value: "06" },
        { originalValue: "07", value: "07" },
        { originalValue: "08", value: "08" },
        { originalValue: "09", value: "09" },
        { originalValue: "10", value: "10" },
        { originalValue: "11", value: "11" },
        { originalValue: "12", value: "12" },
        { originalValue: "13", value: "13" },
        { originalValue: "14", value: "14" },
        { originalValue: "15", value: "15" },
        { originalValue: "16", value: "16" },
        { originalValue: "17", value: "17" },
        { originalValue: "18", value: "18" },
        { originalValue: "19", value: "19" },
        { originalValue: "20", value: "20" },
        { originalValue: "21", value: "21" },
        { originalValue: "22", value: "22" },
        { originalValue: "23", value: "23" }
    ],
    day: [
        {
            originalValue: "?",
            value: <FormattedMessage
                id="CRON_TASK_DAY_DOESNT_MATTER"
                defaultMessage="Doesn't matter"
                description="Doesn't matter" />
        },
        {
            originalValue: "*",
            value: <FormattedMessage
                id="CRON_TASK_EVERY_DAY"
                defaultMessage="Every day"
                description="Every day" />
        },
        { originalValue: "01", value: "01" },
        { originalValue: "02", value: "02" },
        { originalValue: "03", value: "03" },
        { originalValue: "04", value: "04" },
        { originalValue: "05", value: "05" },
        { originalValue: "06", value: "06" },
        { originalValue: "07", value: "07" },
        { originalValue: "08", value: "08" },
        { originalValue: "09", value: "09" },
        { originalValue: "10", value: "10" },
        { originalValue: "11", value: "11" },
        { originalValue: "12", value: "12" },
        { originalValue: "13", value: "13" },
        { originalValue: "14", value: "14" },
        { originalValue: "15", value: "15" },
        { originalValue: "16", value: "16" },
        { originalValue: "17", value: "17" },
        { originalValue: "18", value: "18" },
        { originalValue: "19", value: "19" },
        { originalValue: "20", value: "20" },
        { originalValue: "21", value: "21" },
        { originalValue: "22", value: "22" },
        { originalValue: "23", value: "23" },
        { originalValue: "24", value: "24" },
        { originalValue: "25", value: "25" },
        { originalValue: "26", value: "26" },
        { originalValue: "27", value: "27" },
        { originalValue: "28", value: "28" },
        { originalValue: "29", value: "29" },
        { originalValue: "30", value: "30" },
        { originalValue: "31", value: "31" }
    ],
    month: [
        {
            originalValue: "*",
            value: <FormattedMessage
                id="CRON_TASK_EVERY_MONTH"
                defaultMessage="Every month"
                description="Every month" />
        },
        {
            originalValue: "01",
            value: MSG_MONTH_JANUARY
        },
        {
            originalValue: "02",
            value: MSG_MONTH_FEBURARY
        },
        {
            originalValue: "03",
            value: MSG_MONTH_MARCH
        },
        {
            originalValue: "04",
            value: MSG_MONTH_APRIL
        },
        {
            originalValue: "05",
            value: MSG_MONTH_MAY
        },
        {
            originalValue: "06",
            value: MSG_MONTH_JUNE
        },
        {
            originalValue: "07",
            value: MSG_MONTH_JULY
        },
        {
            originalValue: "08",
            value: MSG_MONTH_AUGUST
        },
        {
            originalValue: "09",
            value: MSG_MONTH_SEPTEMBER
        },
        {
            originalValue: "10",
            value: MSG_MONTH_OCTOBER
        },
        {
            originalValue: "11",
            value: MSG_MONTH_NOVEMBER
        },
        {
            originalValue: "12",
            value: MSG_MONTH_DECEMBER
        }
    ],
    weekDay: [
        {
            originalValue: "?",
            value: <FormattedMessage
                id="CRON_TASK_WEEK_DAY_DOESNT_MATTER"
                defaultMessage="Doesn't matter"
                description="Doesn't matter" />
        },
        {
            originalValue: "*",
            value: <FormattedMessage
                id="CRON_TASK_EVERY_WEEK_DAY"
                defaultMessage="Every day"
                description="Every week day" />
        },
        {
            originalValue: "01",
            value: MSG_WEEK_DAY_MONDAY
        },
        {
            originalValue: "02",
            value: MSG_WEEK_DAY_TUESDAY
        },
        {
            originalValue: "03",
            value: MSG_WEEK_DAY_WEDNESDAY
        },
        {
            originalValue: "04",
            value: MSG_WEEK_DAY_THURSDAY
        },
        {
            originalValue: "05",
            value: MSG_WEEK_DAY_FRIDAY
        },
        {
            originalValue: "06",
            value: MSG_WEEK_DAY_SATURDAY
        },
        {
            originalValue: "07",
            value: MSG_WEEK_DAY_SUNDAY
        }
    ]
}