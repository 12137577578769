import React from 'react';
import { FormattedMessage } from 'react-intl';
import UserToolbar from './usertoolbar.jsx';
import { PROFILE_EDITOR_BASE_URL } from '../../constants/classcard';
import { UI_LABEL } from '../../constants/objectcard.js';
import { createUi } from './library.jsx';

const UiLabel = createUi(UI_LABEL);

class Toolbar extends React.Component {

    constructor(props) {
        super(props);
    }

    getEditButton() {
        const disableEdit = this.props.empty; //Data is not received from server
        if (this.props.editable) {
            return (<button type="button" className="btn btn-secondary card-toolbtn card-cancel" onClick={this.props.cancel} disabled={disableEdit}>
                <i className="fa fa-ban fa-fw" aria-hidden="true"></i>
                <FormattedMessage
                    id="OBJECTCARD_CANCEL_EDIT_OBJECT"
                    defaultMessage="Cancel edit"
                    description="User should click the button to cancel object changes" />
            </button>);
        } else {
            return (<button type="button" className="btn btn-secondary card-toolbtn card-edit" onClick={this.props.edit} disabled={disableEdit}>
                <i className="fa fa-pencil fa-fw" aria-hidden="true"></i>
                <FormattedMessage
                    id="OBJECTCARD_EDIT_OBJECT"
                    defaultMessage="Edit card"
                    description="User should click the button to edit card" />
            </button>);
        }
    }

    getSaveButton() {
        let disableSave = true;

        //Enable save if object
        if (!this.props.empty && this.props.editable) {
            disableSave = false;
        }
        if (disableSave) {
            return null;
        }
        return <button type="button" className="btn btn-secondary card-toolbtn card-save" onClick={this.props.save}>
            <i className="fa fa-upload fa-fw" aria-hidden="true"></i>
            <FormattedMessage
                id="OBJECTCARD_SAVE_OBJECT"
                defaultMessage="Save"
                description="User should click the button to save changes" />
        </button>
    }

    getObjectInfo() {
        return ([
            <div className="card" style={{ marginBottom: 5 }}>
                <div className="card-header bg-info">
                    <div className="card-title font-weight-bold">
                        <FormattedMessage
                            id="OBJECTCARD_OBJECT"
                            defaultMessage="Object"
                            description="Object" />
                    </div>
                </div>
                <div className="card-body">{this.getObjectPanel()}</div>
            </div>,
            <div className="card" style={{ marginBottom: 5 }}>
                <div className="card-header bg-info">
                    <div className="card-title font-weight-bold">
                        <FormattedMessage
                            id="OBJECTCARD_CLASS"
                            defaultMessage="Class"
                            description="Class" />
                    </div>
                </div>
                <div className="card-body">{this.getClassPanel()}</div>
            </div>,
            <div className="card">
                <div className="card-header bg-info">
                    <div className="card-title font-weight-bold">
                        <FormattedMessage
                            id="OBJECTCARD_FRAGMENT"
                            defaultMessage="Fragment"
                            description="Fragment" />
                    </div>
                </div>
                <div className="card-body">{this.getFragmenPanel()}</div>
            </div>
        ])
    }

    getFragmenPanel() {
        if (!this.props.data || !this.props.data.$fragment) {
            return <FormattedMessage
                id="OBJECTCARD_HAVE_NO_FRAGMENT"
                defaultMessage="Object doesn't have fragment."
                description="Object doesn't have any fragment." />;
        }
        let info = this.props.data.$fragment;

        let fragmentRdfIdRow = null;
        let fragmentDescriptionRow = null;
        if (this.props.superUser) {
            fragmentRdfIdRow = <div className="row">
                <label className="col-md-3 control-label text-right">
                    <b><FormattedMessage
                        id="OBJECTCARD_RDF_ID" />
                        :</b>
                </label>
                <div className="col-md-9">
                    {info.$rdfId}
                </div>
            </div>

            fragmentDescriptionRow = <div className="row">
                <label className="col-md-3 control-label text-right">
                    <b><FormattedMessage
                        id="OBJECTCARD_FRAGMENT_DESCRIPTION"
                        defaultMessage="Description"
                        description="Description of fragment" />
                        :</b>
                </label>
                <div className="col-md-9">
                    {info.$description}
                </div>
            </div>
        }

        return [<div className="row">
            <label className="col-md-3 control-label text-right">
                <b><FormattedMessage
                    id="OBJECTCARD_FRAGMENT_LABEL"
                    defaultMessage="Label"
                    description="Label of fragment" />
                    :</b>
            </label>
            <div className="col-md-9">
                {info.$label}
            </div>
        </div>,
            fragmentRdfIdRow,
            fragmentDescriptionRow]
    }

    getClassPanel() {
        if (!this.props.data || (!this.props.data.$classId && !this.props.data.$class)) {
            return <FormattedMessage
                id="OBJECTCARD_CLASS_NOT_FOUND"
                defaultMessage="Can't find info about object class."
                description="Can't find info about object class." />;
        }
        let id = this.props.data.$classId;
        let label = this.props.data.$class;

        let classLabel = label;
        let classIdRow = null;
        if (this.props.superUser && typeof id != "undefined") {
            classLabel = <a href={this.props.contextPath + PROFILE_EDITOR_BASE_URL + "#/?id=" + id} target="_blank">{label}</a>
            classIdRow = <div className="row">
                <label className="col-md-3 control-label text-right">
                    <b><FormattedMessage
                        id="OBJECTCARD_CLASS_ID"
                        defaultMessage="Id"
                        description="Id" />:</b>
                </label>
                <div className="col-md-9">
                    {id}
                </div>
            </div>
        }

        return [<div className="row">
            <label className="col-md-3 control-label text-right">
                <b><FormattedMessage
                    id="OBJECTCARD_CLASS_LABEL"
                    defaultMessage="Label"
                    description="Label of class" />:</b>
            </label>
            <div className="col-md-9">
                {classLabel}
            </div>
        </div>,
            classIdRow]
    }

    getObjectPanel() {
        if (!this.props.data || (!this.props.data.$rdfId && !this.props.data.$id)) {
            return <FormattedMessage
                id="OBJECTCARD_OBJECT_NOT_FOUND"
                defaultMessage="Can't find info about object."
                description="Can't find info about object." />;
        }
        let rdf = this.props.data.$rdfId;
        if (this.props.data.$namespace) {
            rdf = `${this.props.data.$namespace}:${rdf}`;
        }
        return (<div className="row">
            <label className="col-md-3 control-label text-right">
                <b><FormattedMessage
                    id="OBJECTCARD_RDF_ID"
                    defaultMessage="RdfId"
                    description="RdfId" />:</b>
            </label>
            <div className="col-md-9">
                {rdf}
            </div>
            <label className="col-md-3 control-label text-right">
                <b><FormattedMessage
                    id="OBJECTCARD_ID"
                    defaultMessage="Id"
                    description="Id" />:</b>
            </label>
            <div className="col-md-9">
                {this.props.data.$id}
            </div>
        </div>);
    }

    getSystemToolbar() {
        let cp = this.props.contextPath;
        let namespace = this.props.data.$namespace;
        let historyRef = `${cp}history/${this.props.data.$rdfId}`;
        if (namespace) {
            historyRef = `${cp}history/${namespace}/${this.props.data.$rdfId}`;
        }
        if (this.props.data.$fragment && this.props.data.$fragment.$rdfId == "_f_enum_fragment") {
            historyRef += "#/?enum=true";
        }
        let printRef = `${cp}printcard/${this.props.data.$rdfId}`;
        if (namespace) {
            printRef = `${cp}printcard/${namespace}/${this.props.data.$rdfId}`;
        }
        //Default button state: all disabled
        let disableHistory = true;
        let disablePrint = true;
        let disableEdit = true;
        if (!this.props.empty) { //Fom is not empty
            //Enable edit/cancel
            disableEdit = false;
            //Enable history and print if object is not new
            if (!this.props.data.$isNew) {
                disableHistory = false;
                disablePrint = false;
            }
        }

        let adminMenu = [{
            key: "show_fragment",
            icon: "fa-info-circle",
            label: <FormattedMessage
                id="OBJECTCARD_SHOW_INFO"
                defaultMessage="Show information"
                description="Show information of object" />,
            click: () => {
                let options = {
                    title: <FormattedMessage
                        id="OBJECTCARD_OBJECT_INFO"
                        defaultMessage="Object information"
                        description="Information of object" />,
                    body: this.getObjectInfo()
                };
                this.props.openModal("common", options);
            }
        }, {
            key: "export_to_word",
            icon: "fa-file-word-o",
            label: <FormattedMessage
                id="OBJECTCARD_EXPORT_TO_WORD"
                defaultMessage="Export to Word"
                description="Export objectcard to Word" />,
            click: this.props.exportToWord.bind(this, this.props.data.$rdfId, this.props.data.$namespace)
        }, {
            key: "export_to_excel",
            icon: "fa-file-excel-o",
            label: <FormattedMessage
                id="OBJECTCARD_EXPORT_TO_EXCEL"
                defaultMessage="Export to Excel"
                description="Export objectcard to Excel" />,
            click: this.props.exportToExcel.bind(this, this.props.data.$rdfId, this.props.data.$namespace)
        }];
        if (this.props.superUser) {
            adminMenu.concat([{
                key: "divider",
                type: "divider"
            },
            {
                key: "change_fragment",
                label: <FormattedMessage
                    id="OBJECTCARD_CHANGE_FRAGMENT"
                    defaultMessage="Move to another fragment"
                    description="Move object to another fragment" />
            },
            {
                key: "copy_id",
                label: <FormattedMessage
                    id="OBJECTCARD_COPY_ID"
                    defaultMessage="Copy id"
                    description="Copy id of object" />
            },
            {
                key: "copy_object",
                label: <FormattedMessage
                    id="OBJECTCARD_COPY_OBJECT"
                    defaultMessage="Copy object"
                    description="Copy object" />
            },
            {
                key: "block_status",
                label: <FormattedMessage
                    id="OBJECTCARD_BLOCK_STATUS"
                    defaultMessage="Block status"
                    description="Block status of object" />
            },
            {
                key: "remove_block",
                label: <FormattedMessage
                    id="OBJECTCARD_REMOVE_BLOCK"
                    defaultMessage="Remove block"
                    description="Remove block of object" />
            }]);
        }

        let isEditing = this.props.editable;

        return (<div className="btn-group btn-group-xs btn-group-separated" role="group" aria-label="...">
            <div className="btn-group btn-group-xs btn-group-separated">
                <a className="btn btn-secondary card-toolbtn card-print" href={printRef} target="_blank" disabled={disablePrint || isEditing}>
                    <i className="fa fa-print fa-fw" aria-hidden="true"></i>
                    <FormattedMessage
                        id="OBJECTCARD_PRINT_OBJECT"
                        defaultMessage="Print"
                        description="User should click the button to print object" />
                </a>
                <a className="btn btn-secondary card-toolbtn card-history" href={historyRef} disabled={disableHistory || isEditing}>
                    <i className="fa fa-history fa-fw" aria-hidden="true"></i>
                    <FormattedMessage
                        id="OBJECTCARD_OBJECT_HISTORY"
                        defaultMessage="History"
                        description="User should click the button to get object history" />
                </a>
            </div>
            <div className="btn-group btn-group-xs">
                <button type="button" className="btn btn-secondary dropdown-toggle card-toolbtn card-additional" data-toggle="dropdown">
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    {adminMenu.map(function (item) {
                        if (item.type == "divider") {
                            return (<div className="dropdown-divider"></div>);
                        }
                        return (
                            <a className="dropdown-item" key={item.key} onClick={item.click}>
                                {item.icon && <i className={"fa fa-fw " + item.icon} aria-hidden="true"></i>}
                                {item.label}
                            </a>)
                    })}
                </div>
            </div>
        </div>);
    }

    getLabel() {
        return (<UiLabel formId={this.props.id} nodeId="$label" isNew={this.props.isNew} />);
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {this.getLabel()}
                    <div className="btn-toolbar pull-right" role="toolbar" aria-label="...">
                        <div className="btn-group btn-group-xs btn-group-separated d-inline">
                            {this.getEditButton()}
                            {this.getSaveButton()}
                            <UserToolbar {...this.props} />
                            {this.getSystemToolbar()}
                        </div>
                    </div>
                </div>
            </div>);
    }
}

export default Toolbar;