import React from 'react';
import { FormattedMessage } from 'react-intl';
import Switcher from './switcher.jsx';
import { MSG_BUTTON_CLOSE } from '../messages.jsx'

export const LANG_SWITCHER_DLG = "language-switcher-dialog";

export const MSG_SELECT_LANG = (
    <FormattedMessage id="USERMENU_SELECT_LANGUAGE"
        defaultMessage="Select language"
        description="Menu item to choose language" />);

class SwitcherDialog extends React.Component {

    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <div id={LANG_SWITCHER_DLG} className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">
                                {MSG_SELECT_LANG}
                            </h4>
                        </div>
                        <div className="modal-body">
                            <Switcher {...this.props}/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                {MSG_BUTTON_CLOSE}
                            </button>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

export default SwitcherDialog;