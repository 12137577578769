import * as Action from '../constants/cache';

const initialState = {
    loading: true,
    error: false,
    list: []
}

function listReceived(state, { list }) {
    return Object.assign({}, state, { loading: false, list });
}

function errorReceived(state, { error }) {
    return Object.assign({}, state, { loading: false, error: true });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.LIST_RECEIVED: return listReceived(state, action.payload);
        case Action.ERROR_RECEIVED: return errorReceived(state, action.payload);
        default: return state;
    }
}