import React from 'react';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import { LOADING, SELECT_BAY_TYPE, BAY_TYPE, SELECT_VENDOR, VENDOR, COMMON_VENDORS_IDS, VOLTAGE, SELECT_VOLTAGE, COMMON_VOLTAGES } from '../../constants/rpasetpoints';

class Configurations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            voltage: props.voltage,
            vendor: props.vendor,
            bayType: props.bayType
        }

        this.changeVoltage = this.changeVoltage.bind(this);
        this.changeVendor = this.changeVendor.bind(this);
        this.changeBayType = this.changeBayType.bind(this);
    }

    changeVoltage({ voltage }) {
        this.setState(Object.assign({}, this.state, { voltage }));
    }

    changeVendor({ vendor }) {
        this.setState(Object.assign({}, this.state, { vendor }));
    }

    changeBayType({ bayType }) {
        this.setState(Object.assign({}, this.state, { bayType }));
    }

    printVoltageSelect() {
        const options = [];
        if (this.props.voltage) {
            let voltageLabel;
            if (typeof this.props.voltage == "number") {
                voltageLabel = `${this.props.voltage} ${this.props.messages["RPA_SETPOINTS_KILOVOLT"]}`;
            } else if (this.props.voltage.min == this.props.voltage.max) {
                voltageLabel = `${this.props.voltage.min} ${this.props.messages["RPA_SETPOINTS_KILOVOLT"]}`;
            } else {
                voltageLabel = `${this.props.voltage.min}-${this.props.voltage.max} ${this.props.messages["RPA_SETPOINTS_KILOVOLT"]}`;
            }
            options.push({ voltage: this.props.voltage, label: voltageLabel });
        } else {
            for (let voltage of COMMON_VOLTAGES) {
                const localName = `${voltage} ${this.props.messages["RPA_SETPOINTS_KILOVOLT"]}`;
                options.push({ voltage: voltage, label: localName });
            }
        }
        return [
            VOLTAGE,
            <Select
                name="voltageSelect"
                className="mb-2"
                loadingPlaceholder={LOADING}
                placeholder={SELECT_VOLTAGE}
                searchable={false}
                clearable={false}
                backspaceRemoves={false}
                value={this.state.voltage}
                valueKey={"voltage"}
                options={options}
                onChange={this.changeVoltage}
                disabled={Boolean(this.props.voltage)}
            />
        ];
    }

    printVendorSelect() {
        const options = [];
        for (let vendorId of COMMON_VENDORS_IDS) {
            options.push({ vendor: vendorId, label: this.props.messages[`RPA_SETPOINTS_VENDOR_${vendorId.toUpperCase()}`] });
        }
        return [
            VENDOR,
            <Select
                name="vendorSelect"
                className="mb-2"
                loadingPlaceholder={LOADING}
                placeholder={SELECT_VENDOR}
                searchable={false}
                clearable={false}
                backspaceRemoves={false}
                value={this.state.vendor}
                valueKey={"vendor"}
                options={options}
                onChange={this.changeVendor}
                disabled={Boolean(this.props.vendor)}
            />
        ];
    }

    printBayTypeSelect() {
        const options = [{
            bayType: null, label: "--"
        }];
        for (let bayType of this.props.bayTypeList) {
            options.push({ bayType: bayType, label: bayType });
        }
        return [
            BAY_TYPE,
            <Select
                name="bayTypeSelect"
                loadingPlaceholder={LOADING}
                placeholder={SELECT_BAY_TYPE}
                searchable={false}
                clearable={false}
                backspaceRemoves={false}
                value={this.state.bayType}
                valueKey={"bayType"}
                options={options}
                onChange={this.changeBayType}
                disabled={this.props.disabled}
            />
        ];
    }

    render() {
        return (
            <div className="npt-rpasetpoints-configurations" style={{ width: this.props.width }}>
                {this.printVoltageSelect()}
                {this.printVendorSelect()}
                {this.printBayTypeSelect()}
            </div>
        );
    }
}

export default Configurations;