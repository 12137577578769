import React from 'react';
import { getPointOffset, getPointDate } from '../../../services/gant';

const LEFT = 0;
const RIGHT = 1;

const MOUSE_LEFT = 0;
const MOUSE_CIRCLE = 1;
const MOUSE_RIGHT = 2;

const MOVE_TYPE = 0;
const RESIZE_LEFT_TYPE = 1;
const RESIZE_RIGHT_TYPE = 2;

function getMouseX(reactEvent) {
    return reactEvent.clientX;
}

function isLeftClick(reactEvent) {
    return reactEvent.button == 0;
}

function isRightClick(reactEvent) {
    return reactEvent.button == 2;
}

function preventPropagation(reactEvent) {
    reactEvent.preventDefault();
    reactEvent.stopPropagation();
}

function getGradientColor(colors) {
    if (!colors || !colors.length) {
        return null;
    }
    const step = 100 / colors.length;
    const disperse = 2 / colors.length;
    let currentPosition = 0;
    let background = "linear-gradient(135deg"
    for (let i = 0; i < colors.length; ++i) {
        const color = colors[i];
        const leftDisperse = i == 0 ? 0 : disperse;
        const rightDisperse = i == colors.length - 1 ? 0 : disperse;
        background += `, ${color} ${currentPosition + leftDisperse}%`;
        currentPosition += step;
        background += `, ${color} ${currentPosition - rightDisperse}%`;
    }
    background += ")";
    return background;
}

class GantItem extends React.Component {

    constructor(props) {
        super(props);

        this.rowLimits = this.getRowLimits(props);
        this.dragLimits = this.getDragLimits(props);
        this.position = this.getPosition(props);

        this.initializeMenuItems(props);
        this.initializeItem(props);

        this.itemMouseDown = this.itemMouseDown.bind(this);
        this.resizerMouseDown = this.resizerMouseDown.bind(this);
        this.grabItem = this.grabItem.bind(this);
        this.moveItem = this.moveItem.bind(this);
        this.releaseItem = this.releaseItem.bind(this);
        this.callContextMenu = this.callContextMenu.bind(this);
    }

    initializeMenuItems(props) {
        this.menuItems = [{
            formattedId: "NPT_GANT_REMOVE_ITEM",
            action: this.props.removeItem
        }];
    }

    initializeItem(props = this.props) {
        this.group = {};
        if (!Array.isArray(props.item.group)) {
            for (let stateGroup of this.props.groups) {
                if (stateGroup.id == props.item.group.id) {
                    this.group = stateGroup;
                    break;
                }
            }
            return;
        }
        const compositeGroup = {
            colors: [],
            borders: []
        };
        for (let stateGroup of this.props.groups) {
            for (let itemGroup of props.item.group) {
                if (stateGroup.id == itemGroup.id) {
                    compositeGroup.colors.push(stateGroup.color);
                    compositeGroup.borders.push(stateGroup.border);
                    break;
                }
            }
        }
        this.group = {
            gradient: getGradientColor(compositeGroup.colors),
            color: compositeGroup.colors[0],
            border: compositeGroup.borders[0]
        };
    }

    getRowLimits(props) {
        return { left: 0, right: props.rowWidth, width: props.rowWidth };
    }

    getDragLimits(props) {
        let limits = { left: this.rowLimits.left, right: this.rowLimits.right, width: this.rowLimits.width };
        if (props.prevItem && props.prevItem.to > props.dateLimits.from) {
            limits.left = getPointOffset(props.dateLimits, this.rowLimits, props.prevItem.to);
        }
        if (props.nextItem && props.nextItem.from < props.dateLimits.to) {
            limits.right = getPointOffset(props.dateLimits, this.rowLimits, props.nextItem.from);
        }
        return limits;
    }

    getPosition(props) {
        let position = {
            left: getPointOffset(props.dateLimits, this.rowLimits, props.item.from),
            right: getPointOffset(props.dateLimits, this.rowLimits, props.item.to)
        }
        position.width = position.right - position.left;
        return position;
    }

    grabItem(reactEvent) {
        let positionX = getMouseX(reactEvent);
        this.resetHandlers();
        this.grabType = MOVE_TYPE;
        this.moveHandler = this.props.registerHandler("mousemove", this.moveItem.bind(this, this.position, positionX));
        this.releaseHandler = this.props.registerHandler("mouseup", this.releaseItem);
    }

    grabResizer(side, reactEvent) {
        let positionX = getMouseX(reactEvent);
        this.resetHandlers();
        this.grabType = side == LEFT ? RESIZE_LEFT_TYPE : RESIZE_RIGHT_TYPE;
        this.moveHandler = this.props.registerHandler("mousemove", this.resizeItem.bind(this, side, this.position, positionX));
        this.releaseHandler = this.props.registerHandler("mouseup", this.releaseItem);
    }

    itemMouseDown(reactEvent) {
        preventPropagation(reactEvent);
        switch (reactEvent.button) {
            case MOUSE_LEFT:
                this.grabItem(reactEvent);
                break;
        }
    }

    resizerMouseDown(side, reactEvent) {
        preventPropagation(reactEvent);
        switch (reactEvent.button) {
            case MOUSE_LEFT:
                this.grabResizer(side, reactEvent);
                break;
        }
    }

    callContextMenu(reactEvent) {
        preventPropagation(reactEvent);
        this.props.callContextMenu(reactEvent, this.menuItems);
    }

    moveItem(oldPositions, startPositionX, reactEvent) {
        let positionX = getMouseX(reactEvent);
        let difference = positionX - startPositionX;
        if (oldPositions.left + difference < this.dragLimits.left) {
            difference = this.dragLimits.left - oldPositions.left;
        }
        if (oldPositions.right + difference > this.dragLimits.right) {
            difference = this.dragLimits.right - oldPositions.right;
        }
        this.position = {
            left: oldPositions.left + difference,
            right: oldPositions.right + difference,
            width: oldPositions.width
        }
        this.forceUpdate();
    }

    resizeItem(side, oldPositions, startPositionX, reactEvent) {
        let positionX = getMouseX(reactEvent);
        let point, min, max;
        switch (side) {
            case LEFT:
                point = oldPositions.left;
                min = this.dragLimits.left;
                max = oldPositions.right - 1;
                break;
            case RIGHT:
                point = oldPositions.right;
                min = oldPositions.left + 1;
                max = this.dragLimits.right;
                break;
        }
        let difference = positionX - startPositionX;
        if (point + difference < min) {
            difference = min - point;
        }
        if (point + difference > max) {
            difference = max - point;
        }
        this.position = {};
        switch (side) {
            case LEFT:
                this.position.left = oldPositions.left + difference;
                this.position.right = oldPositions.right;
                break;
            case RIGHT:
                this.position.left = oldPositions.left;
                this.position.right = oldPositions.right + difference;
                break;
        }
        this.position.width = this.position.right - this.position.left;
        this.forceUpdate();
    }

    resetHandlers() {
        if (this.moveHandler) {
            this.props.removeHandler(this.moveHandler);
            this.moveHandler = null;
        }
        if (this.releaseHandler) {
            this.props.removeHandler(this.releaseHandler);
            this.releaseHandler = null;
        }
    }

    releaseItem(reactEvent) {
        this.resetHandlers();
        this.props.changeItem({
            from: this.grabType == RESIZE_RIGHT_TYPE ? this.props.item.from : getPointDate(this.props.dateLimits, this.rowLimits, this.position.left + 1),
            to: this.grabType == RESIZE_LEFT_TYPE ? this.props.item.to : getPointDate(this.props.dateLimits, this.rowLimits, this.position.right)
        });
    }

    printResizer(side) {
        let sideClass = side == RIGHT ? "right" : "left";
        return (
            <div className={"npt-gant-item-resizer " + sideClass} onMouseDown={this.resizerMouseDown.bind(this, side)}></div>);
    }

    printDash() {
        const bgColor = this.group.border || "#bbb";
        const background = `repeating-linear-gradient(-60deg, ${bgColor} 0, ${bgColor} 1px, transparent 2px, transparent 5px)`;
        return <div className="npt-gant-row-item-dash" style={{ background: background }}></div>;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.rowWidth != nextProps.rowWidth) {
            this.rowLimits = this.getRowLimits(nextProps);
        }
        if (this.props.prevItem != nextProps.prevItem || this.props.nextItem != nextProps.nextItem || this.props.rowWidth != nextProps.rowWidth) {
            this.dragLimits = this.getDragLimits(nextProps);
        }
        if (this.props.rowWidth != nextProps.rowWidth || this.props.dateLimits != nextProps.dateLimits || this.props.item != nextProps.item) {
            this.position = this.getPosition(nextProps);
        }
        if (this.props.item.group != nextProps.item.group) {
            this.initializeItem(nextProps);
        }
    }

    render() {
        const draggable = this.props.viewType == this.props.type;
        const haveContextMenu = draggable && this.props.permissions.value == "full";
        const dashed = this.props.item.dashed;
        return (
            <div
                className={"npt-gant-row-item" + (draggable ? " editable" : "")}
                onContextMenu={haveContextMenu ? this.callContextMenu : null}
                onMouseDown={draggable ? this.itemMouseDown : null}
                style={{
                    top: this.props.top,
                    left: this.position.left,
                    width: this.position.width,
                    height: this.props.height,
                    backgroundColor: this.group.color || "#ccc",
                    background: this.group.gradient || this.group.color || "#ccc",
                    borderColor: this.group.border || "#bbb"
                }}
            >
                {draggable && this.printResizer(LEFT)}
                {draggable && this.printResizer(RIGHT)}
                {dashed && this.printDash()}
            </div>);
    }
}

export default GantItem;
