import React from 'react';
import { FormattedMessage } from 'react-intl';
import CodeMirror from 'react-codemirror';
import htmlMode from 'codemirror/mode/htmlmixed/htmlmixed';
import jsMode from 'codemirror/mode/javascript/javascript';
import xmlMode from 'codemirror/mode/xml/xml';
import CimFiller from '../filler/cimfiller.jsx';
import Toolbar from './toolbar.jsx';
import { TOOLBAR_CONTAINER_STYLE, CARD_BODY_STYLE } from './styles.jsx';
import { STATUS_ERROR } from '../../constants/srceditor.js';

//import { POPUP_ID, SAVE_CHANGES_ID } from '../../constants/objectcard';
//import CardArea from './cardarea.jsx';
//import PopupCard from './popupcard.jsx';
//import SaveDlg from './savedlg.jsx';

const editorStyle = { height: "100%", overflow: "hidden" };

class SourceEditor extends React.Component {

    constructor(props) {
        super(props);
        //this.showPopup = this.showPopup.bind(this);
        //this.addPopupData = this.addPopupData.bind(this);
        this.updateCode = this.updateCode.bind(this);
        //Set initial state as redux store value
        this.state = { value: null, waitingForTimer: false };
    }

    updateState(props) {
        //Obtain data from redux if we do not have unsaved information
        if (!this.state.waitingForTimer) {
            let value = null;
            if (props.entity && typeof props.entity.code != "undefined") {
                value = props.entity.code;
            }
            this.setState({ value });
        }
    }

    updateCode(value) {
        //Clear previous delay if needed
        if (this.delayTimer) {
            clearTimeout(this.delayTimer);
        }
        let _this = this;
        let _value = value;
        //Change component state immediately (show to user)
        this.setState({ value, waitingForTimer: true });
        //Delay change when sent to redux store (helpful for undo/redo)
        this.delayTimer = setTimeout(function () {
            _this.setState({ waitingForTimer: false });
            _this.props.changeCode(_value);
        }, 200);
    }

    /*
    showPopup(path, cls) {
        this.popupPath = path;
        this.props.layoutPopup(cls);
        $(`#${POPUP_ID}`).modal('show');
    }
    */

    /*
    addPopupData() {
        this.props.addMainData(this.popupPath, this.props.popupData);
    }
    */

    /*
    showSaveChages() {
        $(`#${SAVE_CHANGES_ID}`).modal('show');
    }
    */

    componentWillReceiveProps(nextProps) {
        //Update code
        this.updateState(nextProps);
        //Update path
        if (this.props.path != nextProps.path) {
            console.log(this.props.path, nextProps.path);
            if (typeof nextProps.path == 'string') {
                //Object was in editing status. Ask to save
                if (this.props.edit) {
                    //this.showSaveChages();
                } else {
                    this.props.fetchCode(nextProps.path);
                }
            }
        }
    }

    componentDidMount() {
        if (this.props.path && this.props.path != "/") {
            this.props.fetchCode(this.props.path);
        }
    }

    render() {
        if (this.props.status == STATUS_ERROR) {
            return (
                <div className="alert alert-danger" role="alert">
                    <FormattedMessage
                        id="SOURCEEDITOR_CODE_DOWNLOAD_ERROR"
                        defaultMessage="Error occured while downloading code"
                        description="Info text on code downloading error" />
                </div>);
        }
        //Check if path was selected
        if (this.props.entity && this.props.entity.path) {
            //Check of code was loaded
            if (this.state.value == null) {
                return (
                    <div className="alert alert-info" role="alert">
                        <FormattedMessage
                            id="SOURCEEDITOR_WAIT_CODE"
                            defaultMessage="Wait while code is loading"
                            description="User should wait while source code is loading from server" />
                    </div>);
            }
            let options = {
                lineNumbers: true,
                mode: this.props.mimeView || this.props.entity.mime
            }
            if (!this.props.edit) {
                options.readOnly = 'nocursor';
            } else {
                options.readOnly = false;
            }
            return (<div style={editorStyle}>
                <div className="container-fluid" style={TOOLBAR_CONTAINER_STYLE}>
                    <Toolbar {...this.props} script={this.state.value} />
                </div>
                <CimFiller updateDelay={5}>
                    <CodeMirror value={this.state.value} onChange={this.updateCode} options={options} />
                </CimFiller>
            </div>);
        }

        return (
            <div className="alert alert-info" role="alert">
                <FormattedMessage
                    id="SOURCEEDITOR_SELECT_FILE"
                    defaultMessage="Select file to be shown in the editor"
                    description="User should select file to be shown in the editor" />
            </div>);

    }
}

export default SourceEditor;