import React from 'react';
import { FormattedMessage } from 'react-intl';


class Switcher extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.changeLocale(event.target.value);
    }

    render() {
        return (
            <div className="input-group">
                <span className="input-group-addon">
                    <FormattedMessage
                        id="I18N_LANG"
                        defaultMessage="Language:"
                        description="Language caption" />
                </span>
                <select value={this.props.locale} onChange={this.handleChange} className="form-control" placeholder="Language">
                    <option value="en">English</option>
                    <option value="ru">Русский</option>
                    <option value="es">Español</option>
                </select>
            </div>);
    }
}

export default Switcher;