import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';

function fileRefFormatter(field, contextPath) {
    return function (cell, row) {
        if (row[field] == null) {
            return (<FormattedMessage
                id="SUBJECT_HISTORY_FILE_REMOVED"
                defaultMessage="File was removed"
                description="Inform user that file was removed" />);
        }
        let file = row[field];
        let filename = file.originalName;
        return (<a href={`${contextPath}rest/file/download/${file.sha1}`} download={filename}><i className="fa fa-download" aria-hidden="true"></i></a>)
    }
}

function operationFormatter(cell, row) {
    if (cell == 0) {
        return (<FormattedMessage
            id="SUBJECT_HISTORY_FILE_OPER_ADD"
            defaultMessage="Added"
            description="File was added" />);
    }
    if (cell == 1) {
        return (<FormattedMessage
            id="SUBJECT_HISTORY_FILE_OPER_UPDATE"
            defaultMessage="Updated"
            description="File was updated" />);
    }
    if (cell == 2) {
        return (<FormattedMessage
            id="SUBJECT_HISTORY_FILE_OPER_REMOVE"
            defaultMessage="Removed"
            description="File was removed" />);
    }
    return "-";
}

class FileHistory extends React.Component {

    constructor(props) {
        super(props);
    }

    getPredicate(triple) {
        if (!triple.predicate) {
            return "-";
        }
        return triple.predicate.label;
    }

    getLabel(f) {
        if (!f.label) {
            return "-";
        }
        return f.label;
    }

    getDescription(f) {
        if (!f.description) {
            return "-";
        }
        return f.description;
    }

    render() {
        let tableData = this.props.data.map((f) => {
            return {
                id: f.id,
                name: this.getPredicate(f),
                label: this.getLabel(f),
                description: this.getDescription(f),
                operation: f.operation,
                value: f.file
            }
        })
        let fileFormatter = fileRefFormatter('value', this.props.contextPath)
        return (
            <div>
                <h4><FormattedMessage
                    id="SUBJECT_HISTORY_FILE_HEADER"
                    defaultMessage="File changes:"
                    description="File changes table header" />
                </h4>
                <BootstrapTable version='4' data={tableData} striped={true} hover={true}>
                    <TableHeaderColumn isKey={true} hidden={true} dataField='id'>ID</TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='name'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_FILE_PREDICATE_NAME"
                            defaultMessage="Name"
                            description="Name of predicate that has been changed" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='label'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_FILE_LABEL"
                            defaultMessage="Label"
                            description="File label" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='description'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_FILE_DESCRIPTION"
                            defaultMessage="Description"
                            description="File description" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='operation' dataFormat={operationFormatter}>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_FILE_OPERATION"
                            defaultMessage="Operation"
                            description="File operation" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='value' dataFormat={fileFormatter} dataAlign='center'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_FILE_VALUE"
                            defaultMessage="Download"
                            description="File link to be downloaded" />
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default FileHistory;


