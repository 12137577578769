import React from 'react';
import { FormattedMessage } from 'react-intl';
import Basic from './basic.jsx';
import { ADD_BUTTON_ICON_STYLE, REMOVE_BUTTON_ICON_STYLE } from './style.jsx';
import ComboBox from './combobox.jsx';

function generateRemoveHandler(index, callback) {
    return function (event) {
        callback(event, index);
    };
}

class FragmentBox extends Basic {

    enumerationCls;
    enumNodeById;

    constructor(props) {
        super(props);
        this.getEnumerationCls = this.getEnumerationCls.bind(this);
        this.link = this.link.bind(this);
        this.select = this.select.bind(this);
        this.remove = this.remove.bind(this);
        this.state = { editableValue: null };
        this.enumNodeById = {};
        this.enumerationCls = this.getEnumerationCls(props.fragments, this.getFragmentRdfId(props.value));
    }

    componentDidMount() {
        this.props.fetchFragmentList(this.getFragmentRdfId(this.props.value));
    }

    getFragmentRdfId(fragment) {
        return fragment && fragment.$rdfId || null;
    }

    recursiveGetEnum(fragments, nodePath, depth = 0) {
        const nodeId = nodePath[depth];
        const nodeLoading = nodeId === null ? fragments.loading : fragments.nodeById[nodeId].loading;
        if (nodeLoading) {
            return null;
        }
        let nodeData = this.enumNodeById[nodeId];
        if (!nodeData) {
            nodeData = {};
        }
        if (typeof nodeData.children === "undefined") {
            nodeData.children = [];
            let childIdList = nodeId === null ? fragments.roots : fragments.childrenById[nodeId];
            for (let childId of childIdList) {
                const node = fragments.nodeById[childId];
                let enumNode;
                if (this.enumNodeById[childId]) {
                    enumNode = this.enumNodeById[childId];
                } else {
                    enumNode = {};
                    if (node) {
                        enumNode.data = {
                            $rdfId: childId,
                            $label: node.name
                        }
                    } else {
                        enumNode.data = {
                            $rdfId: childId,
                            $label: childId
                        }
                    }
                    this.enumNodeById[childId] = enumNode;
                }
                nodeData.children.push(enumNode);
            }
        }
        if (nodeData.children.length === 0) {
            nodeData.children = null;
        } else if (depth < nodePath.length - 1) {
            const childNodeId = nodePath[depth + 1];
            for (let enumNode of nodeData.children) {
                if (enumNode.data && enumNode.data.$rdfId === childNodeId) {
                    const children = this.recursiveGetEnum(fragments, nodePath, depth + 1);
                    if (children !== null) {
                        enumNode.children = children;
                    }
                    break;
                }
            }
        }
        if (nodeId === null && nodeData.children === null) {
            delete (nodeData.children);
        }
        this.enumNodeById[nodeId] = nodeData;
        return nodeData.children;
    }

    getEnumerationCls(fragments, value) {
        let currentNodeId = value || null;
        const nodePath = [];

        do {
            nodePath.unshift(currentNodeId);
            currentNodeId = fragments.parentIdByNodeId[currentNodeId];
        } while (currentNodeId)
        nodePath.unshift(null);

        return {
            error: fragments.error,
            loading: fragments.loading,
            enumerationInfo: {
                children: this.recursiveGetEnum(fragments, nodePath) || []
            }
        };
    }

    link(value) {
        const rdfId = this.getFragmentRdfId(value);
        if (this.props.node.multiple) {
            this.props.add(value);
        } else {
            this.props.change(value);
            this.props.updateFragments(rdfId);
        }
    }
    select(value) {
        const rdfId = this.getFragmentRdfId(value);
        this.props.updateFragments(rdfId);
    }
    remove(index) {
        this.props.remove(index);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.fragments !== nextProps.fragments || this.props.value !== nextProps.value) {
            this.enumerationCls = this.getEnumerationCls(nextProps.fragments, this.getFragmentRdfId(nextProps.value));
        }
    }

    render() {
        const rdfId = this.getFragmentRdfId(this.props.value);
        return <ComboBox
            allowPartialSelection={true}
            dynamicEnumeration={true}
            node={this.props.node}
            data={this.props.data}
            values={this.props.values}
            automation={this.props.automation}
            value={rdfId}
            valid={this.props.valid}
            error={this.props.error}
            visible={this.props.visible}
            editable={this.props.editable}
            enumerationCls={this.enumerationCls}
            link={this.link}
            select={this.select}
            remove={this.remove}
        />
    }
}

export default FragmentBox;