import React from 'react';
import { FormattedMessage } from 'react-intl';
import CimTableBase from '../table/table.jsx';
import CimFiller from '../filler/cimfiller.jsx';
import { LOCAL_SERVER_KEY, EVENT_TASKS_LOCAL_SERVER } from '../../constants/eventtasks.js';

export default class Table extends React.PureComponent {

    constructor(props) {
        super(props);

        this.columns = [
            {
                format: "selectColumn",
                sortable: false,
                select: true,
                radio: false,
                width: 50
            },
            {
                sortable: true,
                field: "event",
                dataFormat: this.eventColumnFormatter.bind(this),
                label: <FormattedMessage
                    id="EVENT_TASKS_EVENT"
                    defaultMessage="Event"
                    description="Label of event column" />
            },
            {
                sortable: true,
                field: "label",
                label: <FormattedMessage
                    id="EVENT_TASKS_LABEL"
                    defaultMessage="Label"
                    description="Label of label column" />
            },
            {
                sortable: true,
                field: "description",
                label: <FormattedMessage
                    id="EVENT_TASKS_DESCRIPTION"
                    defaultMessage="Description"
                    description="Label of description column" />
            },
            {
                sortable: true,
                field: "scriptPath",
                label: <FormattedMessage
                    id="EVENT_TASKS_SCRIPT_PATH"
                    defaultMessage="Script path"
                    description="Label of script path column" />
            },
            {
                sortable: true,
                field: "server",
                dataFormat: this.serverColumnFormatter.bind(this),
                label: <FormattedMessage
                    id="EVENT_TASKS_SOURCE"
                    defaultMessage="Source"
                    description="Label of source column" />
            },
            {
                sortable: false,
                field: "configBtn",
                width: 100,
                dataFormat: this.configColumnFormatter.bind(this),
                label: <FormattedMessage
                    id="EVENT_TASKS_CONFIG"
                    defaultMessage="Config"
                    description="Label of config column" />
            }
        ];
    }

    eventColumnFormatter(cell, row) {
        return cell;
    }

    serverColumnFormatter(cell, row) {
        if (cell == LOCAL_SERVER_KEY) {
            return EVENT_TASKS_LOCAL_SERVER;
        }
        return row.compositeSourceName || cell;
    }

    configColumnFormatter(cell, row) {
        return <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <button type="button" className="btn btn-secondary" style={{ width: 40, height: 40, fontSize: "1.2rem" }} onClick={this.props.openEventModal.bind(this, row)}>
                <i className={"fa fa-fw fa-cog"}></i>
            </button>
        </div>;
    }

    render() {
        return <CimFiller updateDelay={5}>
            <CimTableBase
                withoutFetch={true}
                data={this.props.data}
                messages={this.props.messages}
                header={this.columns}
                selectType="checkbox"
                selected={this.props.selectedRows}
                onSelect={this.props.selectRow}
            >
            </CimTableBase>
        </CimFiller>
    }
}