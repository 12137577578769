import * as Action from '../constants/filedrop.js';

//Initial reducer state
const initialState = {
    filesToUpload: [],
    sha1List: null
};

function registerUpload(state, { files }) {
    return Object.assign({}, state, { filesToUpload: files, sha1List: null, started: false });
}

function startUpload(state) {
    return Object.assign({}, state, { started: true });
}

function clearUpload(state) {
    return Object.assign({}, state, { filesToUpload: [], sha1List: null, started: false });
}

function uploadFinished(state, { uploadList }) {
    $(document).trigger({ type: "cim-upload-finished", uploadList: uploadList });
    return Object.assign({}, state, { filesToUpload: [], uploadList, started: false });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.REGISTER_UPLOAD: return registerUpload(state, action.payload);
        case Action.START_UPLOAD: return startUpload(state, action.payload);
        case Action.CLEAR_UPLOAD: return clearUpload(state, action.payload);
        case Action.UPLOAD_FINISHED: return uploadFinished(state, action.payload);
        default: return state;
    }
}