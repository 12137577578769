import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../modal.jsx';


class QuizModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: []
        };

        if (props.options.selected) {
            if (props.options.type === "radio") {
                this.state.selected = Array.isArray(props.options.selected) ? [props.options.selected[0]] : [props.options.selected]
            } else {
                this.state.selected = Array.isArray(props.options.selected) ? props.options.selected : [props.options.selected];
            }
        }

        this.select = this.select.bind(this);
        this.getResult = this.getResult.bind(this);
    }

    select(value) {
        if (this.state.selected.indexOf(value) !== -1) {
            if (this.props.options.type === "radio") {
                if (this.props.options.allowDeselect) {
                    this.setState({ selected: [] });
                }
                return;
            }
            this.setState({ selected: this.state.selected.filter((selectedValue) => value !== selectedValue) });
            return;
        }
        if (this.props.options.type === "radio") {
            this.setState({ selected: [value] });
            return;
        }
        const selected = this.state.selected.slice();
        selected.push(value);
        this.setState({ selected: selected });
        return;
    }

    getTitle() {
        if (this.props.options.title) {
            return this.props.options.title;
        }
        return (<FormattedMessage
            id="QUIZ_MODAL_TITLE"
            defaultMessage="Please select options"
            description="Please select options" />);
    }

    getBody() {
        return (<div className="container-fluid">
            {this.props.options.options.map((option, index) => <Row
                key={index}
                radio={this.props.options.type === "radio"}
                option={option}
                selected={this.state.selected}
                select={this.select}
            />)}
        </div>);
    }

    getResult() {
        if (this.state.selected.length !== 0) {
            return this.state.selected;
        }
        if (this.props.options.allowEmpty) {
            return [];
        }
        return null;
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for Quiz*/
            title={this.getTitle()}
            body={this.getBody()}
            /**Specific callbacks for Quiz*/
            result={this.getResult} />);
    }
}

const Row = React.memo((props) => {
    const type = props.radio ? "radio" : "checkbox";
    return <div className="row d-flex align-items-center">
        <input className="mr-2" type={type} checked={props.selected.indexOf(props.option.value) !== -1} onClick={() => props.select(props.option.value)} />
        <span>{props.option.label}</span>
    </div>;
});

export default QuizModal;