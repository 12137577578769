import React from 'react';
import Select from 'react-select';
import FileDrop from '../filedrop/filedrop.jsx';
import { SETPOINTS, READY, SIGNALS, VENDOR, SELECT_VENDOR, LOADING, ENCODE_ASCII, ENCODE_UTF, ENCODE_VALUES, ENCODE_NAMES, COMMON_VENDORS_IDS, BAY_TYPE, SELECT_BAY_TYPE, COMMON_EXTENSIONS } from '../../constants/rpasetpoints';

class FileUploader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            setpointsFile: null,
            signalsFile: null,
            encode: {
                setpoints: ENCODE_VALUES["ASCII"],
                signals: ENCODE_VALUES["ASCII"]
            },
            options: {
                bayType: props.bayType || null,
                vendor: props.vendor || null
            }
        }

        this.setOptions = this.setOptions.bind(this);
        this.changeBayType = this.changeBayType.bind(this);
        this.changeVendor = this.changeVendor.bind(this);
        this.uploadSetpoints = this.uploadSetpoints.bind(this);
        this.uploadSignals = this.uploadSignals.bind(this);
    }

    isModal() {
        return this.props.modal || false;
    }

    vendorNeeded() {
        return !this.props.hideFiledrop || !this.props.hideFiledrop[SETPOINTS];
    }

    getAcceptableExt() {
        if (!this.state.options.vendor) {
            return "";
        }
        switch (this.state.options.vendor) {
            case COMMON_VENDORS_IDS[0]: //ekra
                return ` ${COMMON_EXTENSIONS.doc}, ${COMMON_EXTENSIONS.xml}`;
            case COMMON_VENDORS_IDS[1]: //siemens
                return ` ${COMMON_EXTENSIONS.csv}`;
            case COMMON_VENDORS_IDS[2]: //relematika
                return ` ${COMMON_EXTENSIONS.excel}`;
            case COMMON_VENDORS_IDS[3]: //abb
                return ` ${COMMON_EXTENSIONS.csv}, ${COMMON_EXTENSIONS.txt}`;
        }
        return "";
    }

    setOptions(options) {
        const nextOptions = Object.assign({}, this.state.options, options);
        this.setState(Object.assign({}, this.state, { options: nextOptions }));
    }

    changeBayType({ bayType }) {
        this.setOptions({ bayType });
    }

    changeVendor({ vendor }) {
        this.setOptions({ vendor });
    }

    changeEncode(uploadId, reactEvent) {
        let newState = Object.assign({}, this.state);
        newState.encode = Object.assign({}, newState.encode);
        newState.encode[uploadId] = reactEvent.target.value;
        this.setState(newState)
    }

    uploadSetpoints({ files }) {
        this.setState(Object.assign({}, this.state, { setpointsFile: files[0].name }));
        this.props.uploadSetpoints(files[0], this.state.options.vendor, this.state.options.bayType, this.state.encode["setpoints"]);
    }

    uploadSignals({ files }) {
        this.setState(Object.assign({}, this.state, { signalsFile: files[0].name }));
        this.props.uploadSignals(files[0], this.state.encode["signals"]);
    }

    printBayTypeSelect() {
        const options = [{
            bayType: null, label: "--"
        }];
        for (let bayType of this.props.bayTypeList) {
            options.push({ bayType: bayType, label: bayType });
        }
        return [
            BAY_TYPE,
            <Select
                name="bayTypeSelect"
                loadingPlaceholder={LOADING}
                placeholder={SELECT_BAY_TYPE}
                searchable={false}
                clearable={false}
                backspaceRemoves={false}
                value={this.state.options.bayType}
                valueKey={"bayType"}
                options={options}
                onChange={this.changeBayType}
                disabled={this.props.disabled}
            />
        ];
    }

    printVendorSelect() {
        const options = [];
        for (let vendorId of COMMON_VENDORS_IDS) {
            options.push({ vendor: vendorId, label: this.props.messages[`RPA_SETPOINTS_VENDOR_${vendorId.toUpperCase()}`] });
        }
        return [
            VENDOR,
            <Select
                name="vendorSelect"
                loadingPlaceholder={LOADING}
                placeholder={SELECT_VENDOR}
                searchable={false}
                clearable={false}
                backspaceRemoves={false}
                value={this.state.options.vendor}
                valueKey={"vendor"}
                options={options}
                onChange={this.changeVendor}
                disabled={this.props.disabled || Boolean(this.props.vendor)}
            />
        ];
    }

    printUploadedFile(fileName) {
        return (
            <div className="npt-rpasetpoints-fileuploaded">
                <span>{fileName}</span><i className='fa fa-fw fa-check'></i>
            </div>
        );
    }

    printEncodeOption(uploadId, encode) {
        const value = ENCODE_VALUES[encode];
        const name = ENCODE_NAMES[encode];
        return (
            <option selected={this.state.encode[uploadId] == value} value={value}>{name}</option>
        );
    }

    printEncodeSelect(uploadId) {
        return (
            <select disabled={this.props.uploadState[uploadId] == READY} onChange={this.changeEncode.bind(this, uploadId)}>
                {this.printEncodeOption(uploadId, "ASCII")}
                {this.printEncodeOption(uploadId, "UTF-8")}
            </select>
        );
    }

    printUpload(label, uploadFunc, uploadId, uploadedFile) {
        if (this.props.hideFiledrop && this.props.hideFiledrop[uploadId]) {
            return null;
        }
        return (
            <div className={"npt-rpasetpoints-filedrop" + (this.props.uploadState[uploadId] == READY ? " uploaded" : "")}>
                <div className="npt-rpasetpoints-filedrop-label">{label}</div>
                {this.props.uploadState[uploadId] == READY ?
                    this.printUploadedFile(uploadedFile)
                    :
                    <FileDrop
                        multiple={false}
                        filedrop={{ filesToUpload: [] }}
                        registerUpload={uploadFunc}
                    />
                }
                <div className="npt-rpasetpoints-filedrop-encode">{this.printEncodeSelect(uploadId)}</div>
            </div>
        );
    }

    render() {
        return (
            <div className={"npt-rpasetpoints-files" + (this.vendorNeeded() && this.state.options.vendor == null ? " disabled" : "")}>
                {!this.isModal() && this.printBayTypeSelect()}
                {this.vendorNeeded() ? this.printVendorSelect() : null}
                {this.printUpload(this.props.messages["RPA_SETPOINTS_UPLOAD_SETPOINTS"] + this.getAcceptableExt(), this.uploadSetpoints, SETPOINTS, this.state.setpointsFile)}
                {this.printUpload(this.props.messages["RPA_SETPOINTS_UPLOAD_SIGNALS"], this.uploadSignals, SIGNALS, this.state.signalsFile)}
            </div>
        );
    }
}

export default FileUploader;