const ATTR_TABS = "cim-tabs";

//Initialization function
export default function (dispatcher) {
    //Register components
    dispatcher.registerComponent(ATTR_TABS, (elm) => {
        const $elm = $(elm);

        const $navbar = $("<ul class='nav nav-pills nav-fill'></ul>");
        $elm.append($navbar);
        const $tabs = $("> div", $elm);
        
        const $contents = $("<div class='tab-content' style='display:flex;flex:1;'></div>");
        $elm.append($contents);

        for (let i = $tabs.length - 1; i >= 0; --i) {
            const $tab = $($tabs[i]);
            const id = $tab.attr("id");
            const title = $tab.attr("data-title");
            const active = $tab.attr("active");
            const $li = $("<li class='nav-item'></ul>");
            const $a = $(`<a class='nav-link' data-toggle='tab' href='#${id}'>${title}</a>`);
            if (active) {
                $a.addClass("active").addClass("show");
                $tab.addClass("active").addClass("show");
            }
            $tab.addClass("tab-pane").addClass("fade").css("height", "100%").css("width", "100%");
            $contents.prepend($tab);
            $li.append($a);
            $navbar.prepend($li);
        }
        return null;
    });
};

