import React from 'react';
import Select from 'react-select';
import MaskedInput from '../../srceditor/maskedinput.jsx';

import { MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../../messages.jsx';
import {
    CLASSCARD_NOT_EMPTY_FIELD,
    CLASSCARD_NAME_OF_PREDICATE,
    CLASSCARD_LABEL_OF_PREDICATE,
    CLASSCARD_TYPE_OF_DATA,
    CLASSCARD_MULTIPLICITY,
    CLASSCARD_MANDATORY,
    CLASSCARD_DESCRIPTION,
    CLASSCARD_RELATION_TYPE,
    CLASSCARD_CREATE_REVERSE_PREDICATE,
    CLASSCARD_NAMESPACE,
    CLASSCARD_DATA_TYPE_OBJECT,
    CLASSCARD_DATA_TYPE_OBJECTS,
    CLASSCARD_SELECT_PRIMITIVES,
    CLASSCARD_SELECT_CLASSES,
    NAME_SYMBOLS,
    NAME_FIRST_SYMBOL,
    LABEL_SYMBOLS,
    LABEL_FIRST_SYMBOL
} from '../../../constants/classcard';
import { sortByAttr } from '../../../services/classcard';

const fullSize = { width: "100%", hetght: "100%" };

const RELATION_ASSOTIATION = 0;
const RELATION_AGGREGATION = 1;
const RELATION_COMPOSITION = 2;

class PredicatePopup extends React.Component {

    constructor(props) {
        super(props);

        this.typeOptions = [];
        if (props.classList && props.classList.length > 0) {
            for (let classData of props.classList) {
                let className = classData.namespace + ":" + classData.name;
                let stereotype = props.stereotypeMap[classData.stereotype];
                this.typeOptions.push(Object.assign({}, classData, { label: className, primitive: Boolean(stereotype && stereotype.stereotype && stereotype.stereotype.toLowerCase() === "primitive") }));
            }
            this.typeOptions = sortByAttr(this.typeOptions, "label");
        }
        this.namespaceList = sortByAttr(props.namespaceList, "prefix");

        this.relationTypeList = [];
        for (let relationType of props.relationTypeList) {
            if (relationType.value == RELATION_COMPOSITION) {
                continue;
            }
            this.relationTypeList.push(relationType);
        }

        this.state = {
            predicate: {
                multiplicity: 0,
                dataType: Object.assign({ primitive: true }, props.dataTypeList[0]),
                namespace: props.namespaceMap[this.props.classData.namespaceId]
            },
            reversePredicate: {
                multiplicity: 0,
                namespace: props.namespaceMap[this.props.classData.namespaceId]
            },
            relationType: null
        };

        this.validation = {
            predicate: {},
            reversePredicate: {}
        }

        if (props.predicate) {
            this.parsePredicate(props.predicate);
        }
        this.validation.predicate.name = this.validateName(this.state.predicate.name);
        this.validation.predicate.label = this.validateLabel(this.state.predicate.label);

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.maskedInputChangeHandler = this.maskedInputChangeHandler.bind(this);
        this.changeMandatoryHandler = this.changeMandatoryHandler.bind(this);
        this.changeMultiplicityHandler = this.changeMultiplicityHandler.bind(this);
        this.changeDataTypeHandler = this.changeDataTypeHandler.bind(this);
        this.changeRelationType = this.changeRelationType.bind(this);
        this.changeReversePredicate = this.changeReversePredicate.bind(this);
        this.changeNamespaceHandler = this.changeNamespaceHandler.bind(this);
    }

    parsePredicate(predicate) {
        this.state.predicate = Object.assign({}, predicate);
        let predicateName = predicate.name.split(".");
        this.state.predicate.name = predicateName[predicateName.length - 1];
        this.validation.reversePredicate = {
            name: true,
            label: true
        };
        if (predicate.classRelationInfo) {
            this.state.relationType = predicate.classRelationInfo.relationType;
            if (predicate.reversePredicate) {
                this.state.withReverse = true;
                this.state.reversePredicate = predicate.reversePredicate;
                let predicateName = predicate.reversePredicate.name.split(".");
                this.state.reversePredicate.name = predicateName[predicateName.length - 1];
            }
        }
    }

    isPrimitive() {
        return this.state.predicate.dataType.primitive !== false && this.state.predicate.dataType.name != "base64Binary";
    }

    validateName(name) {
        return !!name;
    }

    validateLabel(label) {
        return !!label;
    }

    changeAndValidate(nextState, predicateName, variable, newValue) {
        if (nextState[predicateName][variable] == newValue) {
            return false;
        }
        nextState[predicateName] = Object.assign({}, nextState[predicateName]);
        nextState[predicateName][variable] = newValue;
        switch (variable) {
            case "name":
                this.validation[predicateName].name = this.validateName(newValue);
                break;
            case "label":
                this.validation[predicateName].label = this.validateLabel(newValue);
                break;
        }
        return true;
    }

    maskedInputChangeHandler(predicateName, variable, newValue) {
        let nextState = Object.assign({}, this.state);
        if (!this.changeAndValidate(nextState, predicateName, variable, newValue)) {
            return;
        }
        this.setState(nextState);
    }

    inputChangeHandler(predicateName, variable, reactEvent, event) {
        let newValue = reactEvent.nativeEvent.target.value;
        this.maskedInputChangeHandler(predicateName, variable, newValue);
    }

    changeNamespaceHandler(value) {
        if (this.state.predicate.namespace.id == value.id) {
            return;
        }
        let nextState = Object.assign({}, this.state);
        nextState.predicate = Object.assign({}, nextState.predicate);
        nextState.reversePredicate = Object.assign({}, nextState.reversePredicate);
        nextState.predicate.namespace = this.props.namespaceMap[value.id];
        nextState.reversePredicate.namespace = nextState.predicate.namespace;
        this.setState(nextState);
    }

    changeMandatoryHandler(reactEvent) {
        let nextState = Object.assign({}, this.state);
        nextState.predicate = Object.assign({}, nextState.predicate);
        nextState.predicate.multiplicity = reactEvent.target.checked ? 1 : 0;
        this.setState(nextState);
    }

    changeMultiplicityHandler(predicateName, value) {
        let nextState = Object.assign({}, this.state);
        nextState[predicateName] = Object.assign({}, nextState[predicateName]);
        nextState[predicateName].multiplicity = value.value;
        this.setState(nextState);
    }

    changeDataTypeClass(value) {
        let nextState = Object.assign({}, this.state);
        nextState.predicate = Object.assign({}, nextState.predicate);
        nextState.predicate.dataType = value;
        nextState.hideRelation = false;
        let stereotype = this.props.stereotypeMap[value.stereotype];
        if (stereotype && stereotype.stereotype) {
            switch (stereotype.stereotype.toLowerCase()) {
                case "enumeration":
                    nextState.relationType = this.props.relationTypeMap[RELATION_ASSOTIATION];
                    nextState.hideRelation = true;
                    nextState.withReverse = false;
                    break;
                case "compound":
                    nextState.relationType = this.props.relationTypeMap[RELATION_COMPOSITION];
                    nextState.hideRelation = true;
                    nextState.withReverse = false;
                    break;
            }
        }
        if (nextState.relationType == null) {
            nextState.relationType = this.props.relationTypeMap[RELATION_ASSOTIATION];
        }
        if (!nextState.withReverse) {
            nextState.reversePredicate = Object.assign({}, nextState.reversePredicate);
            this.changeAndValidate(nextState, "reversePredicate", "name", this.props.classData.name);
            this.changeAndValidate(nextState, "reversePredicate", "label", this.props.classData.label);
        }
        this.setState(nextState);
    }

    changeDataTypePrimitive(value) {
        let nextState = Object.assign({}, this.state);
        nextState.relationType = null;
        nextState.predicate = Object.assign({}, nextState.predicate);
        if (nextState.predicate.dataType.primitive === true && nextState.predicate.multiplicity > 1) {
            nextState.predicate.multiplicity = 0;
        }
        nextState.predicate.dataType = value;
        this.setState(nextState);
    }

    changeDataTypeHandler(value) {
        if (value.primitive === true) {
            this.changeDataTypePrimitive(value);
            return;
        }
        this.changeDataTypeClass(value);
    }

    changeRelationType(value) {
        this.setState(Object.assign({}, this.state, { relationType: value }));
    }

    changeReversePredicate(reactEvent) {
        let nextState = Object.assign({}, this.state);
        nextState.withReverse = reactEvent.target.checked;
        this.setState(nextState);
    }

    printName(predicateName, disabled) {
        return (
            <div className="row card-row">
                <label htmlFor="name" className="col-sm-4 control-label">
                    {CLASSCARD_NAME_OF_PREDICATE}
                </label>
                <div className={"col-sm-8"}>
                    <MaskedInput
                        className={"form-control" + (this.validation[predicateName].name ? "" : " is-invalid")}
                        id={predicateName + "Name"}
                        value={this.state[predicateName].name}
                        onChange={this.maskedInputChangeHandler.bind(this, predicateName, "name")}
                        disabled={this.props.disabled || disabled}
                        allowedSymbols={NAME_SYMBOLS}
                        firstSymbol={NAME_FIRST_SYMBOL}
                    />
                    {this.validation[predicateName].name ? null : <div className="invalid-feedback text-danger">{CLASSCARD_NOT_EMPTY_FIELD}</div>}
                </div>
            </div>);
    }

    printLabel(predicateName, disabled) {
        return (
            <div className="row card-row">
                <label htmlFor="label" className="col-sm-4 control-label">
                    {CLASSCARD_LABEL_OF_PREDICATE}
                </label>
                <div className={"col-sm-8"}>
                    <MaskedInput
                        className={"form-control" + (this.validation[predicateName].label ? "" : " is-invalid")}
                        id={predicateName + "Label"}
                        value={this.state[predicateName].label}
                        onChange={this.maskedInputChangeHandler.bind(this, predicateName, "label")}
                        disabled={this.props.disabled || disabled}
                        allowedSymbols={LABEL_SYMBOLS}
                        firstSymbol={LABEL_FIRST_SYMBOL}
                    />
                    {this.validation[predicateName].label ? null : <div className="invalid-feedback text-danger">{CLASSCARD_NOT_EMPTY_FIELD}</div>}
                </div>
            </div>);
    }

    printDescription(predicateName, disabled) {
        return (
            <div className="row card-row">
                <label htmlFor="description" className="col-sm-4 control-label">
                    {CLASSCARD_DESCRIPTION}
                </label>
                <div className="col-sm-8">
                    <textarea type="text" id={predicateName + "Description"} className="form-control" value={this.state[predicateName].description} onChange={this.inputChangeHandler.bind(this, predicateName, "description")} disabled={this.props.disabled || disabled} />
                </div>
            </div>);
    }

    printNamespace() {
        return (
            <div className="row card-row">
                <label htmlFor="namespace" className="col-sm-4 control-label">
                    {CLASSCARD_NAMESPACE}
                </label>
                <div className="col-sm-8">
                    <Select
                        name="namespace"
                        disabled={this.props.disabled}
                        loadingPlaceholder={MSG_SELECT_LOADING}
                        placeholder={MSG_SELECT_PLACEHOLDER}
                        noResultsText={MSG_SELECT_NO_RESULTS}
                        value={this.state.predicate.namespace.id}
                        valueKey={"id"}
                        backspaceRemoves={false}
                        clearable={false}
                        options={this.namespaceList}
                        labelKey={"prefix"}
                        onChange={this.changeNamespaceHandler}
                        disabled={this.props.predicate}
                    />
                </div>
            </div>);
    }

    printDataType(predicateName) {
        return (
            <div className="row card-row">
                <label htmlFor="dataType" className="col-sm-4 control-label">
                    {CLASSCARD_TYPE_OF_DATA}
                </label>
                <div className="col-sm-8">
                    <Select
                        name="dataType"
                        loadingPlaceholder={MSG_SELECT_LOADING}
                        placeholder={MSG_SELECT_PLACEHOLDER}
                        noResultsText={MSG_SELECT_NO_RESULTS}
                        value={this.state[predicateName].dataType.id}
                        valueKey={"id"}
                        backspaceRemoves={false}
                        clearable={false}
                        disabled={this.props.disabled || this.props.predicate}
                        options={this.typeOptions}
                        formatGroupLabel={this.formatGroupLabel}
                        onChange={this.changeDataTypeHandler}
                    />
                </div>
            </div>);
    }

    printRelationType() {
        if (this.state.predicate.dataType.primitive !== false || this.state.hideRelation) {
            return null;
        }
        return (
            <div className="row card-row">
                <label htmlFor="relationType" className="col-sm-4 control-label">
                    {CLASSCARD_RELATION_TYPE}
                </label>
                <div className="col-sm-8">
                    <Select
                        name="relationType"
                        loadingPlaceholder={MSG_SELECT_LOADING}
                        placeholder={MSG_SELECT_PLACEHOLDER}
                        noResultsText={MSG_SELECT_NO_RESULTS}
                        value={this.state.relationType}
                        backspaceRemoves={false}
                        clearable={false}
                        searchable={false}
                        disabled={this.props.disabled || this.props.predicate}
                        options={this.relationTypeList}
                        labelKey={"relation"}
                        onChange={this.changeRelationType}
                    />
                </div>
            </div>);
    }

    printPrimitiveMultiplicity(predicateName) {
        return (
            <div className="row card-row">
                <label htmlFor="multiplicity" className="col-sm-4 control-label">
                    {CLASSCARD_MANDATORY}
                </label>
                <div className="col-sm-8">
                    <input disabled={this.props.disabled} type="checkbox" onClick={this.changeMandatoryHandler} checked={Boolean(this.state[predicateName].multiplicity)} />
                </div>
            </div>);
    }

    printRelationMupltiplicity(predicateName, disabled) {
        return (
            <div className="row card-row">
                <label htmlFor="multiplicity" className="col-sm-4 control-label">
                    {CLASSCARD_MULTIPLICITY}
                </label>
                <div className="col-sm-8">
                    <Select
                        name="multiplicity"
                        loadingPlaceholder={MSG_SELECT_LOADING}
                        placeholder={MSG_SELECT_PLACEHOLDER}
                        noResultsText={MSG_SELECT_NO_RESULTS}
                        value={this.state[predicateName].multiplicity}
                        backspaceRemoves={false}
                        clearable={false}
                        searchable={false}
                        disabled={disabled || this.props.disabled}
                        options={this.props.multiplicityList}
                        labelKey={"template"}
                        onChange={this.changeMultiplicityHandler.bind(this, predicateName)}
                    />
                </div>
            </div>);
    }

    printReversePredicate() {
        if (this.state.relationType == null || this.state.hideRelation) {
            return null;
        }
        let reversePredicateView = [
            <hr />,
            <div className="row card-row form-check">
                <input className="form-check-input" type="checkbox" onClick={this.changeReversePredicate} disabled={this.props.predicate} checked={Boolean(this.state.withReverse)} style={{ cursor: "pointer" }} />
                <label className="form-check-label" for="inlineCheckbox1">{CLASSCARD_CREATE_REVERSE_PREDICATE}</label>

            </div>
        ];
        if (this.state.withReverse) {
            reversePredicateView.push(this.printName("reversePredicate", this.props.predicate));
            reversePredicateView.push(this.printLabel("reversePredicate", this.props.predicate));
            reversePredicateView.push(this.printDescription("reversePredicate", this.props.predicate));
            reversePredicateView.push(this.printRelationMupltiplicity("reversePredicate", this.props.predicate));
        }
        return reversePredicateView;
    }

    render() {
        return (<div style={fullSize} className="form-horizontal">
            {this.printNamespace("predicate")}
            {this.printName("predicate")}
            {this.printLabel("predicate")}
            {this.printDescription("predicate")}
            {this.printDataType("predicate")}
            {this.printRelationType()}
            {this.isPrimitive() ? this.printPrimitiveMultiplicity("predicate") : this.printRelationMupltiplicity("predicate")}
            {this.printReversePredicate()}
        </div>);
    }
}

export default PredicatePopup;


