import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../modal.jsx';
import DebounceInput from '../../debounceinput.jsx';
import { ERROR_INVALID_FORMAT, ERROR_RANGE_ERROR, ERROR_RANGE_INTERSECTION } from "../modal.jsx";


function setMilliseconds(ISOString, milliseconds) {
    let date = moment(ISOString).toDate();
    date.setMilliseconds(milliseconds);
    return date.toISOString();
}

class DateTimeFilterModal extends React.Component {

    constructor(props) {
        super(props);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.signalInvalidFormat = this.signalInvalidFormat.bind(this);
        this.filterClearHandler = this.filterClearHandler.bind(this);
        this.saveNewFilter = this.saveNewFilter.bind(this);
        this.state = { fromDate: props.options.fromDate, toDate: props.options.toDate };
    }

    signalInvalidFormat() {
        this.setState(Object.assign({}, this.state));
    }

    getTitle() {
        return (<FormattedMessage
            id="NPT_TABLE_DATE_FILTER_TITLE"
            defaultMessage="Please select range of dates for field '{name}'"
            description="User should select range of dates in filter" values={{ name: this.props.options.label }} />);
    }

    getBody() {
        let limits = this.props.options.limits;
        let fromDateValue = this.state.fromDate || limits.min;
        let toDateValue = this.state.toDate || limits.max;

        let fromDate = moment(fromDateValue).utc();
        let toDate = moment(toDateValue).utc();

        let localeData = moment.localeData();
        let dateFormat = localeData.longDateFormat('L');
        let weekStartDay = localeData.firstDayOfWeek();

        let error = {
            background: "red"
        }

        return (<div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <div>
                        <DebounceInput
                            className="form-control"
                            editable={true}
                            value={fromDate.toDate()}
                            valid={!this.state.fromError}
                            style={this.state.fromError ? error : null}
                            format={"dateTime"}
                            locale={this.props.options.locale}
                            change={this.handleFromDateChange}
                            signalInvalidFormat={this.signalInvalidFormat} >
                            <div className="input-group-prepend input-group-text">
                                <FormattedMessage
                                    id="NPT_TABLE_DATE_FILTER_FROM"
                                    defaultMessage="From: "
                                    description="'From' word in date filter" />
                            </div>
                        </DebounceInput>
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <DebounceInput
                            className="form-control"
                            editable={true}
                            value={toDate.toDate()}
                            format={"dateTime"}
                            locale={this.props.options.locale}
                            valid={!this.state.toError}
                            style={this.state.toError ? error : null}
                            change={this.handleToDateChange}
                            signalInvalidFormat={this.signalInvalidFormat} >
                            <div className="input-group-prepend input-group-text">
                                <FormattedMessage
                                    id="NPT_TABLE_DATE_FILTER_TO"
                                    defaultMessage="To: "
                                    description="'To' word in date filter" />
                            </div>
                        </DebounceInput>
                    </div>
                </div>
            </div>
        </div>);
    }

    getFooterButtons() {
        return [{
            onClick: this.filterClearHandler,
            className: "btn btn-danger",
            message: <FormattedMessage
                id="NPT_TABLE_CLEAR_FILTER"
                defaultMessage="Clear filter"
                description="User could clear applied filter" />
        }];
    }

    handleFromDateChange(value) {
        let limits = this.props.options.originalLimits;
        if (moment(limits.min).isAfter(value)) { //check limits
            this.setState({ fromDate: value, fromError: ERROR_RANGE_ERROR });
            return;
        }
        let toDateValue = this.state.toDate || limits.max;
        if (toDateValue && moment(toDateValue).isBefore(value)) { //Check intersection
            this.setState({ toNumber: value, fromError: ERROR_RANGE_INTERSECTION, toError: ERROR_RANGE_INTERSECTION });
            return;
        }
        if (this.state.fromError == ERROR_RANGE_INTERSECTION) {
            this.setState({ fromDate: value, fromError: null, toError: null });
        } else {
            this.setState({ fromDate: value, fromError: null });
        }
    }

    handleToDateChange(value) {
        value = setMilliseconds(value, 999); // Set maximum value to include items with milliseconds != 0
        let limits = this.props.options.originalLimits;
        if (moment(limits.max).isBefore(value)) { //check limits
            this.setState({ toDate: value, toError: ERROR_RANGE_ERROR });
            return;
        }
        let fromDateValue = this.state.fromDate || limits.min;
        if (fromDateValue && moment(fromDateValue).isAfter(value)) { //Check intersection
            this.setState({ toDate: value, fromError: ERROR_RANGE_INTERSECTION, toError: ERROR_RANGE_INTERSECTION });
            return;
        }
        if (this.state.toError == ERROR_RANGE_INTERSECTION) {
            this.setState({ toDate: value, fromError: null, toError: null });
        } else {
            this.setState({ toDate: value, toError: null });
        }
    }

    filterClearHandler({ onOk }) {
        this.cleared = true;
        onOk();
    }

    saveNewFilter() {
        if (this.cleared) {
            return { cleared: true };
        }
        if (this.state.fromError || this.state.toError) {
            return null;
        }
        return { fromDate: this.state.fromDate, toDate: this.state.toDate };
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for StringFilter*/
            title={this.getTitle()}
            body={this.getBody()}
            footerButtons={this.getFooterButtons()}
            /**Specific callbacks for StringFilter*/
            result={this.saveNewFilter} />);
    }
}

export default DateTimeFilterModal;


