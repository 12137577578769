import React from 'react';
import { FormattedMessage } from 'react-intl';
import listener from 'react-contextmenu/modules/globalEventListener';
import { ContextMenu, MenuItem, SubMenu } from 'react-contextmenu';
import { MENU_ID } from '../../constants/navtree';

const ADD_TITLE = (
    <span>
        <i className="fa fa-plus-circle fa-fw"></i>
        <FormattedMessage
            id="NAVTREE_MENU_ADD_ITEM"
            defaultMessage="Add"
            description="User should click this item to add tree node" />
    </span>);

const ADDITIONAL_TITLE = (
    <FormattedMessage
        id="NAVTREE_MENU_ADDITIONAL_ITEM"
        defaultMessage="Additionally..."
        description="User should click this item to open additional items" />);

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            node: null,
            clickPosition: {},
            sizesCalculated: {},
            refs: {
                main: null,
                add: null,
                additional: null
            }
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.collect = this.collect.bind(this);
        this.menuId = MENU_ID + this.props.treeComponentId;
    }

    setupMenuSize(element) {
        const rect = element.getBoundingClientRect();
        const subMenu = $(".react-contextmenu", element);
        if (subMenu.length == 0) {
            return false;
        }
        const mainRect = ReactDOM.findDOMNode(this.state.refs.main).getBoundingClientRect();
        const correction = this.state.clickPosition.y - mainRect.top;
        const padding = 10;
        const heightToBottom = window.innerHeight - rect.top - padding - correction;
        const heightToTop = rect.bottom - padding + correction;
        $(subMenu).css("max-height", Math.max(heightToBottom, heightToTop));
        $(subMenu).css("overflow", "auto");
        return true;
    }

    setupSubMenuSizes() {
        for (let refId in this.state.refs) {
            if (!this.state.refs[refId] || this.state.sizesCalculated[refId]) {
                continue;
            }
            let element = ReactDOM.findDOMNode(this.state.refs[refId]);
            if (typeof element == "undefined" || element == null || $(element).css("display") == "none") {
                continue;
            }
            if (this.setupMenuSize(element)) {
                this.state.sizesCalculated[refId] = true;
            }
        }
    }

    componentDidMount() {
        this.listenId = listener.register(this.handleShow, this.handleHide);
    }

    componentWillUnmount() {
        if (this.listenId) {
            listener.unregister(this.listenId);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.node && this.state.node.loading) { //Try to get new version of node
            const node = nextProps.getNodeById(this.state.node.id);
            this.setState(Object.assign({}, this.state, { node }));
        }
    }

    componentDidUpdate(prevProps) {
        this.setupSubMenuSizes();
    }

    handleShow(e) {
        console.log("Context menu (handle show): ", e.detail.id, this.menuId);
        if (e.detail.id !== this.menuId)
            return;
        const node = this.props.getNodeById(e.detail.data.nodeId);
        if (node && node.loading) {
            //Alway try to load subtree
            this.props.fetchSubTree(node.id);
        }
        this.setState(Object.assign({}, this.state, { node, sizesCalculated: {}, clickPosition: { x: e.detail.position.x, y: e.detail.position.y } }));
    }

    handleHide() {
        this.setState(Object.assign({}, this.state, { node: null }));
    }

    collect(data, isCopy) {
        /* Manually update state to prevent race state with hide handler */
        console.log("Collected: ", data, " is copy: ", isCopy);
        this.state.collected = data;
        this.state.isCopy = isCopy;
        this.forceUpdate();
    }

    generateAddSubMenu() {
        if (!this.props.getAddActions) {
            return null;
        }
        //Get current state of node
        const node = this.state.node;
        if (node.loading) {
            return (
                <SubMenu title={ADD_TITLE}>
                    <MenuItem disabled={true}>
                        <FormattedMessage
                            id="NAVTREE_MENU_NODE_LOADING"
                            defaultMessage="Loading..."
                            description="User should wait while node is loading" />
                    </MenuItem>
                </SubMenu>);
        }
        let addActions = this.props.getAddActions(node, this.props.getNodeById);
        if ($.type(addActions) != 'array' || addActions.length == 0) {
            return null;
        }
        let menuItems = [];
        for (let addItem of addActions) {
            let item = (
                <MenuItem key={addItem.id} data={addItem} onClick={addItem.onAction.bind(this, this.props.header)}>
                    {addItem.label}
                </MenuItem>);
            menuItems.push(item);
        }
        return (
            <SubMenu title={ADD_TITLE} ref={(ref) => this.state.refs.add = ref}>
                {menuItems}
            </SubMenu>);
    }

    generateRemoveSubMenu() {
        if (!this.props.getRemoveAction) {
            return null;
        }
        if (this.state.node.deleteLock) {
            if (!this.props.superUser) {
                return null;
            }
        }
        let removeAction = this.props.getRemoveAction(this.state.node);
        if ($.type(removeAction) != 'object') {
            return null;
        }
        return (
            <MenuItem key={"remove"} onClick={removeAction.onAction.bind(this, this.props.header)}>
                <span>
                    <i className="fa fa-trash fa-fw"></i>
                    <FormattedMessage
                        id="NAVTREE_MENU_REMOVE_ITEM"
                        defaultMessage="Remove"
                        description="User should click this item to remove tree node" />
                </span>
            </MenuItem>);
    }

    generateCopySubMenu() {
        if (!this.props.getCollectNodeAction) {
            return null;
        }
        let copyAction = this.props.getCollectNodeAction(this.state.node, this.collect, true);
        if ($.type(copyAction) != 'object') {
            return null;
        }
        return (
            <MenuItem key={"copy"} onClick={copyAction.onAction.bind(this, this.props.header)}>
                <span>
                    <i className="fa fa-clone fa-fw"></i>
                    <FormattedMessage
                        id="NAVTREE_MENU_COPY_ITEM"
                        defaultMessage="Copy"
                        description="User should click this item to copy tree node" />
                </span>
            </MenuItem>);
    }

    generateCopyRefSubMenu() {
        if (!this.state.node.data) {
            return null;
        }
        return (
            <MenuItem key={"copyNodeRef"}>
                <span className="clipboard" onClick={this.props.copyNodeRef.bind(this, this.state.node)}>
                    <i className="fa fa-clone fa-fw"></i>
                    <FormattedMessage
                        id="NAVTREE_MENU_COPY_NODE_REF"
                        defaultMessage="Copy node reference"
                        description="User should click this item to copy node reference" />
                </span>
            </MenuItem>);
    }

    generateCutSubMenu() {
        if (!this.props.getCollectNodeAction) {
            return null;
        }
        //Get current state of node
        let cutAction = this.props.getCollectNodeAction(this.state.node, this.collect, false);
        if ($.type(cutAction) != 'object') {
            return null;
        }
        return (
            <MenuItem key={"cut"} onClick={cutAction.onAction.bind(this, this.props.header)}>
                <span>
                    <i className="fa fa-scissors fa-fw"></i>
                    <FormattedMessage
                        id="NAVTREE_MENU_CUT_ITEM"
                        defaultMessage="Cut"
                        description="User should click this item to cut tree node" />
                </span>
            </MenuItem>);
    }

    generatePasteSubMenu() {
        if (!this.state.collected) {
            return null;
        }
        if (this.state.isCopy && !this.props.getCopyAction) {
            return null;
        }
        if (!this.state.isCopy && !this.props.getCutAction) {
            return null;
        }
        //Get current state of node
        const node = this.state.node;
        let pasteAction = null;
        if (this.state.isCopy) {
            pasteAction = this.props.getCopyAction(this.state.collected, node);
        } else {
            pasteAction = this.props.getCutAction(this.state.collected, node);
        }

        if ($.type(pasteAction) != 'object') {
            return null;
        }
        return (
            <MenuItem key={"paste"} onClick={pasteAction.onAction.bind(this, this.props.header)}>
                <span>
                    <i className="fa fa-clipboard fa-fw"></i>
                    <FormattedMessage
                        id="NAVTREE_MENU_PASTE_ITEM"
                        defaultMessage="Paste"
                        description="User should click this item to paste tree node" />
                </span>
            </MenuItem>);
    }

    generateAdditionalSubMenu() {
        /**.clipboard class is from foundation/app.jsx */
        return (
            <SubMenu title={ADDITIONAL_TITLE} ref={(ref) => this.state.refs.additional = ref}>
                <MenuItem key={"copyName"}>
                    <span className="clipboard" data-clipboard-text={this.state.node.label}>
                        <FormattedMessage
                            id="NAVTREE_MENU_NAME"
                            defaultMessage="Copy node name"
                            description="User should click this item to copy node name" />
                    </span>
                </MenuItem>
                <MenuItem key={"copyId"}>
                    <span className="clipboard" data-clipboard-text={this.state.node.id}>
                        <FormattedMessage
                            id="NAVTREE_MENU_COPY_ID"
                            defaultMessage="Copy node id"
                            description="User should click this item to copy node identifier" />
                    </span>
                </MenuItem>
                {this.props.copyNodeWithDescendantsRefs && <MenuItem key={"copyRef"}>
                    <span className="clipboard" onClick={this.props.copyNodeWithDescendantsRefs.bind(this, this.state.node.id)}>
                        <FormattedMessage
                            id="NAVTREE_MENU_COPY_REF"
                            defaultMessage="Copy node ref with childrens"
                            description="User should click this item to copy node reference with childrens" />
                    </span>
                </MenuItem>}
                {this.state.node.e && this.props.showError &&
                    <MenuItem key={"showError"} onClick={this.props.showError.bind(this, this.state.node.e)}>
                        <span>
                            <FormattedMessage
                                id="NAVTREE_MENU_SHOW_ERROR"
                                defaultMessage="Show error"
                                description="User should click this item to show node error" />
                        </span>
                    </MenuItem>}
            </SubMenu>);
    }

    generateRenameMenu() {
        if (!this.props.getRenameAction) {
            return null;
        }
        let renameAction = this.props.getRenameAction(this.state.node);
        if ($.type(renameAction) != 'object') {
            return null;
        }
        return (
            <MenuItem key={"renameId"} onClick={renameAction.onAction.bind(this, this.props.header)}>
                <FormattedMessage
                    id="NAVTREE_MENU_RENAME_ID"
                    defaultMessage="Rename"
                    description="User should click this item to rename node" />
            </MenuItem>);
    }

    generateEditMenu() {
        if (!this.props.getEditAction) {
            return null;
        }
        let editAction = this.props.getEditAction(this.state.node);
        if ($.type(editAction) != 'object') {
            return null;
        }
        return (
            <MenuItem key={"editId"} onClick={editAction.onAction.bind(this, this.props.header)}>
                <i className="fa fa-cog fa-fw"></i>
                <FormattedMessage
                    id="NAVTREE_MENU_EDIT_ID"
                    defaultMessage="Edit"
                    description="User should click this item to edit node" />
            </MenuItem>);
    }

    render() {
        return (
            <ContextMenu id={this.menuId} ref={(ref) => this.state.refs.main = ref}>
                {this.state.node && this.generateCopySubMenu()}
                {this.state.node && this.generateCopyRefSubMenu()}
                {this.state.node && this.generateCutSubMenu()}
                {this.state.node && this.generatePasteSubMenu()}
                {this.state.node && this.generateAddSubMenu()}
                {this.state.node && this.generateRenameMenu()}
                {this.state.node && this.generateEditMenu()}
                {this.state.node && this.generateRemoveSubMenu()}
                {this.state.node && this.generateAdditionalSubMenu()}
            </ContextMenu>)
    }
}

export default Menu;

