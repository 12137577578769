import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function generateCallback(fromDate, toDate) {
    return function (targetValue) {
        if (typeof targetValue != 'string') {
            return false;
        }
        return fromDate.isSameOrBefore(targetValue) && toDate.isSameOrAfter(targetValue);
    }
}

function dateLimit(limit, value) {
    if (typeof value != 'string') {
        return;
    }
    let m = moment(value);
    if (!m.isValid()) {
        return;
    }
    if (!limit.min) {
        limit.min = value;
        limit.max = value;
    } else if (m.isBefore(limit.min)) {
        limit.min = value;
    } else if (m.isAfter(limit.max)) {
        limit.max = value;
    }
}

function dateLimitFinish(limit) {
    if (!limit.min) {
        limit.min = '1970-01-01';
        limit.max = limit.min;
        //limit.min = '2017-01-01';
        //limit.max = '2018-01-01';
    }
}

class DateFilter extends React.Component {

    constructor(props) {
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
        this.apply = this.apply.bind(this);
        this.clear = this.clear.bind(this);
        this.state = { fromDate: null, toDate: null };
    }

    apply({ fromDate, toDate, cleared }) {
        if (cleared) {
            this.clear();
            return;
        }
        if (this.limits.min == this.limits.max) {
            return;
        }
        let fromDateValue = fromDate || this.limits.min;
        let toDateValue = toDate || this.limits.max;

        let parsedFromDate = moment(fromDateValue);
        let parsedToDate = moment(toDateValue);

        let callback = generateCallback(parsedFromDate, parsedToDate);
        this.props.filterHandler({ callback, type: this.props.type, from: fromDateValue, to: toDateValue });
        this.setState({ fromDate, toDate });
    }

    clear() {
        this.props.filterHandler();
        this.setState({ fromDate: null, toDate: null });
    }

    clickHandler() {
        this.limits = this.getPanelLimits(this.props.getFilteredRows());
        let options = {
            label: this.props.label,
            size: this.props.size,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            limits: this.limits,
            originalLimits: this.originalLimits,
            format: this.props.format,
            locale: this.props.locale
        }
        this.props.openModal("datefilter", options, this.apply);
    }

    getPanelLimits(data) {
        if (!data) {
            return null;
        }
        let limits = {};
        for (let row of data) {
            let value = row[this.props.field];
            dateLimit(limits, value);
        }
        dateLimitFinish(limits);
        return limits;
    }

    componentDidMount() {
        if (this.props.rows) {
            this.originalLimits = this.getPanelLimits(this.props.rows);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.rows != nextProps.rows) {
            this.originalLimits = this.getPanelLimits(nextProps.rows);
        }
    }

    render() {
        const activeStyle = { color: "#90EE90" };
        let optional = {};
        if (this.props.active) {
            optional.style = activeStyle;
        }
        return (<i className="fa fa-filter" aria-hidden="true" {...optional} onClick={this.clickHandler}></i>);
    }
}

DateFilter.propTypes = {
    filterHandler: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    getFilteredRows: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default DateFilter;