import React, { PureComponent } from 'react';
import Select from 'react-select';
import { SelectObserver } from '../../services/select';

export default class ObservedSelect extends React.PureComponent {

    selectElement;
    selectInput;
    observer;

    constructor(props) {
        super(props);
        
        this.selectElement = null;
        this.selectInput = null;
        this.observer = new SelectObserver();
    }

    getSelectElement() {
        return ReactDOM.findDOMNode(this) || null;
    }

    getSelectInput() {
        if (!this.selectElement) {
            return null;
        }
        return $(".Select-control", this.selectElement)[0] || null;
    }

    startObserve() {
        if (!this.selectElement || !this.selectInput) {
            return;
        }
        this.observer.start(this.selectElement, this.selectInput);
    }

    componentDidUpdate() {
        const element = this.getSelectElement();
        if (element == this.selectElement) {
            return;
        }
        this.selectElement = element;
        this.selectInput = this.getSelectInput();
        this.observer.start(this.selectElement, this.selectInput);
    }

    componentDidMount() {
        this.selectElement = this.getSelectElement();
        this.selectInput = this.getSelectInput();
        this.observer.start(this.selectElement, this.selectInput);
    }

    componentWillUnmount() {
        this.observer.stop();
    }

    render() {
        return <Select {...this.props} />
    }
}