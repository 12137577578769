import React from 'react';

class Button extends React.Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(reactEvent) {
        this.props.click(this.props.node.id);
    }

    getIcon() {
        if (!this.props.node.options.icon) {
            return null;
        }
        return <i className={`fa ${this.props.node.options.icon} fa-fw`} aria-hidden="true"></i>
    }

    shouldComponentUpdate(nextProps) {
        return this.props.node != nextProps.node || this.props.layout != nextProps.layout;
    }

    render() {
        const bg = this.props.node.options.bg || "primary";
        return <button type="button" className={`npt-objectcard-button btn btn-${bg}`} onClick={this.handleClick}>
            {this.getIcon()}
            <span>{this.props.node.label}</span>
        </button>
    }
}

export default Button;