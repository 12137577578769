export const ALERT = "alert";

//Actions
export const ADD_ALERT = "@@ALERT_ADD_ALERT";
export const REMOVE_ALERT = "@@ALERT_REMOVE_ALERT";

//Alert types
export const ALERT_INFO = "info";
export const ALERT_SUCCESS = "success";
export const ALERT_WARNING = "warning";
export const ALERT_DANGER = "danger";