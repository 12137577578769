import React from 'react';
import shortid from 'shortid';
import { StyleRoot } from 'radium';
import { FormattedMessage } from 'react-intl';
import { Treebeard } from '../npt-treebeard/index';
import { loadingMessage, CAPTION_CHANGED, MOVE_NODE } from '../npt-treebeard/common.jsx';
import nptTheme from './theme';
import Toolbar from './toolbar.jsx';
import CimFiller from '../filler/cimfiller.jsx';
import generateDecorators from './decorators.jsx';
import { COPY_TEXT_FIELD_ID, COPY_TEXT_DIALOG_ID, DELETE_CONFIRM_DIALOG_ID } from '../../constants/navtree';
import { MSG_BUTTON_OK, MSG_BUTTON_CANCEL, MSG_SELECT_LOADING } from '../messages.jsx';

const FILTERS = <FormattedMessage
    id="NAVTREE_FILTERS"
    defaultMessage="Filters"
    description="Filters" />

class Tree extends React.Component {

    constructor(props) {
        super(props);
        this.state = { copyText: "", nodeToDelete: null, onDeleteAction: null };
        this.decorators = generateDecorators(props);

        if (this.props.isModal) {
            if (this.props.active) {
                this.props.selectObject(this.props.getNodeById(this.props.active));
            } else if (this.props.initialRdfId) {
                this.props.selectObject(this.props.getNodeByRdfId(this.props.initialRdfId));
            }
        }
    }

    showCopyTextDialog(text) {
        console.log(text);
        this.setState({ copyText: text });
        $(`#${COPY_TEXT_DIALOG_ID + treeId}`).modal('show');
    }

    fetchData(props) {
        if (props.initialRdfId) {
            props.expandNode(props.initialRdfId, props.initialNamespace, true);
        } else {
            //Always call fetch. Actions should handle this case
            props.fetchSubTree(null, true);
        }
    }

    toggleFilter(filter, reactEvent) {
        this.props.toggleFilter(filter, reactEvent.target.checked);
    }

    isLoading() {
        return this.props.loading;
    }

    isEmpty() {
        return this.props.nodeMap && $.isEmptyObject(this.props.nodeMap);
    }

    printFilter() {
        if (!this.props.header || !this.props.header.filter) {
            return null;
        }
        return <div className="col-sm-12 row cim-navtree-filter" style={{ flexBasis: 0 }}>
            <div>{FILTERS}:</div>
            {this.props.header.filter.option.map((option) =>
                <div>
                    <input className="pull-left" type="checkbox" checked={this.props.enabledFilters[option.value]} onChange={this.toggleFilter.bind(this, option.value)} />
                    <div className="pull-left">{option.label}</div>
                </div>)}
        </div>;
    }

    componentDidMount() {
        if (this.props.fetchInfo) {
            this.props.fetchInfo();
        } else {
            this.fetchData(this.props);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.infoLoaded != nextProps.infoLoaded && nextProps.infoLoaded) {
            this.fetchData(nextProps);
        }
        if (nextProps.hashSelect && nextProps.initialRdfId != this.props.initialRdfId) {
            nextProps.expandNode(nextProps.initialRdfId, nextProps.initialNamespace);
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.roots != this.props.roots
            || nextProps.enabledFilters != this.props.enabledFilters) {
            return true;
        }
        return false;
    }

    render() {
        if (this.isEmpty()) {
            return <EmptyAlert />;
        }
        if (this.isLoading()) {
            return <LoadingAlert />;
        }
        return (<div className="npt-fill-size">
            {this.printFilter()}
            <div className="cim-filler-flex">
                <StyleRoot className="w-100">
                    <Treebeard
                        roots={this.props.roots}
                        style={nptTheme}
                        decorators={this.decorators}
                        connectNodeFunction={this.props.connectNodeFunction} />
                </StyleRoot>
            </div>
        </div>
        );
    }
}

class EmptyAlert extends React.PureComponent {
    render() {
        return <div className="alert alert-info">
            <FormattedMessage
                id="NAVTREE_SEARSH_NO_RESULTS"
                defaultMessage="Search results are empty"
                description="Search results are empty" />
        </div>;
    }
}

class LoadingAlert extends React.PureComponent {
    render() {
        return <span>
            <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            {MSG_SELECT_LOADING}
        </span>;
    }
}

export default Tree;