import * as Action from '../constants/i18n';
import { LOCALE_MAP } from '../services/i18n';

//Initial reducer state
function changeLocale(state, {locale}) {
    Object.assign({}, state, {locale, messages: LOCALE_MAP[locale]});
}

export default (state = {}, action) => {
    switch (action.type) {
        case Action.CHANGE_LOCALE: return changeLocale(state, action.payload);
        default: return state;
    }
}