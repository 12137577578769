import { connect } from 'react-redux';
import History from './components/history/history.jsx';
import { LOCATION } from './constants/location';
import { HISTORY } from './constants/history';
import history from './reducers/history';
import { fetchHistoryData, filterByPredicate, hashChanged } from './actions/history';
import { changeHash } from './actions/location';

const ATTR_OBJECT_HISTORY = "cim-objecthistory";

//Map global (redux) state to navigation tree state
function createMapStateToProps(rdfId) {
    return function (globalState) {
        return {
            hashParams: globalState[LOCATION].params,
            contextPath: globalState[LOCATION].contextPath,
            history: globalState[HISTORY],
            rdfId: rdfId
        };
    }
}

function createMapDispachToProps(rdfId) {
    return function (dispatch) {
        return {
            fetchHistoryData: function (rdfId, hashParams) {
                dispatch(fetchHistoryData(rdfId, hashParams));
            },
            hashChanged: function (hashParams) {
                dispatch(hashChanged(hashParams));
            },
            changeHash: function (hash) {
                changeHash(hash);
            }
        }
    }
}


function createHistoryContainer(rdfId) {
    //Connect redux to our navigation tree
    return connect(
        createMapStateToProps(rdfId),
        createMapDispachToProps(rdfId)
    )(History);
}

//Initialization function
export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(HISTORY, history);
    //Register components
    dispatcher.registerComponent(ATTR_OBJECT_HISTORY, (elm) => {
        let rdfId = $(elm).attr(ATTR_OBJECT_HISTORY);
        if (typeof rdfId == 'string') {
            rdfId = $.trim(rdfId);
            if (rdfId.length == 0) {
                rdfId = null;
            }
        } else {
            rdfId = null;
        }
        if (rdfId != null) {
            let HistoryContainer = createHistoryContainer(rdfId);
            return (<HistoryContainer />);
        }
        return null;
    });
};

