import React from 'react';
import CimFiller from '../../filler/cimfiller.jsx';
import PredicateTable from './predicatetable.jsx';
import RelationTable from './relationtable.jsx';
import { printInheritanceTree, printClassInTree, printEnumeration } from '../../../services/classcard';
import { MSG_SELECT_LOADING, MSG_BUTTON_ADD, MSG_BUTTON_DELETE } from '../../messages.jsx';

class TabContainer extends React.Component {

    constructor(props) {
        super(props);

        this.addPredicate = this.addPredicate.bind(this);
        this.removePredicates = this.removePredicates.bind(this);
        this.printPredicates = this.printPredicates.bind(this);
        this.printRelations = this.printRelations.bind(this);
    }

    addPredicate() {
        this.props.editPredicate(null);
    }

    removePredicates() {
        let predicatesToRemove = [];
        let removeMap = {};
        for (let predicateId of this.props.selectedRows) {
            removeMap[predicateId] = true;
        }
        for (let predicateData of this.props.classCard.data.predicateList) {
            if (removeMap[predicateData.id]) {
                predicatesToRemove.push(predicateData);
            }
        }
        this.props.removePredicates(predicatesToRemove, this.props.classCard.data);
    }

    checkActive(tab) {
        if (this.props.classCard.tab == tab) {
            return " active in";
        }
        return "";
    }

    printPredicateTable(classData, noSelection, isParent) {
        return (<PredicateTable
            disabled={isParent}
            predicateList={classData.predicateList}
            classData={classData}
            viewPredicate={this.props.viewPredicate}
            editPredicate={this.props.editPredicate}
            primitiveTypes={this.props.classCard.primitiveTypes}
            multiplicityByValue={this.props.classCard.multiplicity.multiplicityByValue}
            selectRows={this.props.selectRows}
            deselectRows={this.props.deselectRows}
            selectedRows={this.props.selectedRows}
            contextPath={this.props.contextPath}
            noSelection={noSelection} />);
    }

    printRelationTable(classData, noSelection) {
        return (<RelationTable
            messages={this.props.messages}
            classData={classData}
            viewRelation={this.props.viewRelation}
            predicateList={classData.predicateList}
            relationTypeByValue={this.props.classCard.relationType.relationTypeByValue}
            multiplicityByValue={this.props.classCard.multiplicity.multiplicityByValue}
            selectRows={this.props.selectRows}
            deselectRows={this.props.deselectRows}
            selectedRows={this.props.selectedRows}
            noSelection={noSelection} />);
    }

    printPredicatesToolbar(classData) {
        return (
            <div className="nav-tabs btn-group btn-group-xs col-auto" role="group" aria-label="...">
                <a className="btn btn-secondary" style={{ fontSize: "0.75rem", padding: "0rem 0.2rem" }} onClick={this.addPredicate}>
                    <i className="fa fa-plus fa-fw" aria-hidden="true"></i>
                    {MSG_BUTTON_ADD}
                </a>
                <a className={"btn btn-secondary" + (this.props.selectedRows.length == 0 ? " disabled" : "")} style={{ fontSize: "0.75rem", padding: "0rem 0.2rem" }} onClick={this.props.selectedRows.length == 0 ? null : this.removePredicates}>
                    <i className="fa fa-trash fa-fw" aria-hidden="true"></i>
                    {MSG_BUTTON_DELETE}
                </a>
            </div>);
    }

    printPredicates(classData, toolbar, isParent) {
        return (<div className="card" style={{ marginTop: 5 }}>
            <div className="card-header bg-info row m-0">
                <div className="card-title font-weight-bold col">[{classData.id}] {classData.label} ({classData.namespace.prefix}:{classData.name})</div>
                {toolbar && this.printPredicatesToolbar(classData)}
            </div>
            <div className="card-body">
                {this.printPredicateTable(classData, !toolbar, isParent)}
            </div>
        </div>);
    }

    printRelations(classData) {
        return (<div className="card" style={{ marginTop: 5 }}>
            <div className="card-header bg-info">
                <div className="card-title font-weight-bold">[{classData.id}] {classData.label} ({classData.namespace.prefix}:{classData.name})</div>
            </div>
            <div className="card-body">
                {this.printRelationTable(classData, true)}
            </div>
        </div>);
    }

    recursivePrintParentTable(classData, printFunction, tables = []) {
        for (let parent of classData.parentList) {
            tables.push(printFunction(parent.peerClass, false, true));
            if (parent.peerClass.parentList) {
                this.recursivePrintParentTable(parent.peerClass, printFunction, tables)
            }
        }
        return tables;
    }

    render() {
        return <div className="tab-content card-tab-content cim-filler-flex flex-column overflow-auto">
            <div id="predicateList" className={"tab-pane fade" + this.checkActive("predicateList")} style={{ height: this.props.height }}>
                {this.printPredicates(this.props.classCard.data, true)}
                {this.props.classCard.data && this.recursivePrintParentTable(this.props.classCard.data, this.printPredicates)}
            </div>
            <div id="relations" className={"tab-pane fade" + this.checkActive("relations")} style={{ height: this.props.height, overflowY: "auto" }}>
                {this.printRelations(this.props.classCard.data)}
                {this.props.classCard.data && this.recursivePrintParentTable(this.props.classCard.data, this.printRelations)}
            </div>
            <div id="children" className={"tab-pane fade" + this.checkActive("children")} style={{ height: this.props.height, overflowY: "auto" }}>
                {this.props.classCard.data.childrenList == null ?
                    <span><i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>{MSG_SELECT_LOADING}</span>
                    :
                    [
                        printClassInTree(this.props.classCard.data, null, this.props.contextPath),
                        printInheritanceTree(this.props.classCard.data, "childrenList", "childClassId", this.props.contextPath)
                    ]}
            </div>
            {this.props.classCard.data && this.props.classCard.data.enumerationInfo &&
                <div id="enumeration" className={"tab-pane fade" + this.checkActive("enumeration")} style={{ height: this.props.height, overflowY: "auto" }}>
                    {printEnumeration(this.props.classCard.data.enumerationInfo, this.props.contextPath)}
                </div>
            }
        </div >
    }
}

export default TabContainer;