import React from 'react';
import { FormattedMessage } from 'react-intl';
import CardArea from './cardarea.jsx';

class Group extends CardArea {

    constructor(props) {
        super(props);
    }

    render() {
        const style = this.props.visible? null: {display: "none"};
        return (
            <fieldset style={style}>
                <legend>
                    {this.props.node.label}
                </legend>
                {this.getChildren()}
            </fieldset>);
    }
}

export default Group;