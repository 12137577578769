import React from 'react';
import { createUi } from '../library.jsx'
import { FormattedMessage } from 'react-intl';
import { UI_TAB_NAV } from '../../../constants/objectcard.js';

class CardArea extends React.Component {

    constructor(props) {
        super(props);
    }

    getChildren() {
        const result = [];
        if (!this.props.layout) {
            return result;
        }
        if (!this.props.childrenIds) {
            return result;
        }
        let navId;
        if (this.props.node.ui == UI_TAB_NAV) {
            navId = this.props.node.id;
        }
        for (let id of this.props.childrenIds) {
            const node = this.props.layout.byId[id];
            if (!node) {
                console.log("No node found: " + id + " in parent " + this.props.node.id);
                continue;
            }
            const UI = createUi(node.ui);
            if (!UI) {
                continue;
            }
            result.push(<UI key={node.id} formId={this.props.formId} nodeId={node.id} navId={navId} />);
        }
        return result;
    }

    render() {
        return (
            <div className={this.props.className}>
                {this.getChildren()}
            </div>
        );
    }
}

export default CardArea;


