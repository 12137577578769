import React from 'react';
import { createUi } from '../library.jsx'
import { FormattedMessage } from 'react-intl';
import CardArea from './cardarea.jsx';

import { UI_TAB, UI_TAB_HEADER } from '../../../constants/objectcard';

class TabNav extends CardArea {

    constructor(props) {
        super(props);
    }

    getTabs() {
        const result = [];
        if (!this.props.layout) {
            return result;
        }
        if (!this.props.childrenIds || this.props.childrenIds.length == 0) {
            return result;
        }
        for (let id of this.props.childrenIds) {
            const node = this.props.layout.byId[id];
            if (!node) {
                console.log("No node found: " + id + " in parent " + this.props.node.id);
                continue;
            }
            if (node.ui != UI_TAB) {
                continue;
            }
            const UI = createUi(UI_TAB_HEADER);
            if (!UI) {
                continue;
            }
            result.push(<UI key={"tab_header_" + node.id} formId={this.props.formId} nodeId={node.id} navId={this.props.node.id} />);
        }
        return result;
    }

    showTab(tabId) {
        if (tabId == this.props.activeTab) {
            return;
        }
        this.props.changeTab(tabId, this.props.node.id);
    }

    componentDidMount() {
        this.domElement = ReactDOM.findDOMNode(this);
        /* Show first tab */
        if (this.props.childrenIds && this.props.childrenIds[0]) {
            this.showTab(this.props.childrenIds[0]);
        }
        //Bind window resize for correct filling of filters
        $('.nav-tabs a').on('shown.bs.tab', function (event) {
            $(window).trigger("resize");
        });

        /* Fixing element on its place */
        if (this.props.node.options.fixed) {
            const parent = this.domElement.parentNode;
            const $element = $($("ul", this.domElement)[0]);
            $(parent).on("scroll.fixingnavtab", function (event) {
                let top = parent.scrollTop - 5;
                $element.css("top", parent.scrollTop + "px");
            });
        }
    }

    render() {
        const style = this.props.visible ? null : { display: "none" };
        let className = "row nav-tabs-container";
        if(this.props.node && this.props.node.options.cls){
            className += ` ${this.props.node.options.cls}`;
        }
        return (
            <div className={className} style={style}>
                <ul className={"col-md-12 nav nav-tabs" + (this.props.node.options.fixed ? " nav-tabs-fixed" : "")}>
                    {this.getTabs()}
                </ul>
                <div className="tab-content card-tab-content">
                    {this.getChildren()}
                </div>
            </div>);
    }
}

export default TabNav;