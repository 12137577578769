export default {
	analog: [
		{
			name: "Ток",
			children: [
				{
					name: "Линия",
					nodes: [
						{
							name: "Фазные токи В1 (фаза А)",
							linkedId: "RMSMMXU1.A.phsA"
						},
						{
							name: "Фазные токи В1 (фаза B)",
							linkedId: "RMSMMXU1.A.phsB"
						},
						{
							name: "Фазные токи В1 (фаза С)",
							linkedId: "RMSMMXU1.A.phsC"
						},
						{
							name: "Фазные токи В2 (фаза А)",
							linkedId: "RMSMMXU2.A.phsA"
						},
						{
							name: "Фазные токи В2 (фаза B)",
							linkedId: "RMSMMXU2.A.phsB"
						},
						{
							name: "Фазные токи В2 (фаза С)",
							linkedId: "RMSMMXU2.A.phsC"
						},
						{
							name: "Фазные токи В3 (фаза B)",
							linkedId: "RMSMMXU3.A.phsB"
						},
						{
							name: "Фазные токи В3 (фаза С)",
							linkedId: "RMSMMXU3.A.phsC"
						},
						{
							name: "Фазные токи ВШР (фаза А)",
							linkedId: "RMSMMXU3.A.phsA"
						},
						{
							name: "Фазные токи ВШР (фаза B)",
							linkedId: "RMSMMXU3.A.phsB"
						},
						{
							name: "Фазные токи ВШР (фаза С)",
							linkedId: "RMSMMXU3.A.phsC"
						},
						{
							name: "Дифференциальные токи (фаза A)",
							linkedId: "TIMTPDIF1.DifAClc.phsA"
						},
						{
							name: "Дифференциальные токи (фаза B)",
							linkedId: "TIMTPDIF1.DifAClc.phsB"
						},
						{
							name: "Дифференциальные токи (фаза C)",
							linkedId: "TIMTPDIF1.DifAClc.phsC"
						},
						{
							name: "Тормозные токи (фаза A)",
							linkedId: "TIMTPDIF1.RstA.phsA"
						},
						{
							name: "Тормозные токи (фаза B)",
							linkedId: "TIMTPDIF1.RstA.phsB"
						},
						{
							name: "Тормозные токи (фаза C)",
							linkedId: "TIMTPDIF1.RstA.phsC"
						}
					]
				},
				{
					name: "Трансформатор",
					nodes: [
						{
							name: "Дифференциальные токи (фаза A)",
							linkedId: "TIMTPDIF1.DifAClc.phsA"
						},
						{
							name: "Дифференциальные токи (фаза B)",
							linkedId: "TIMTPDIF1.DifAClc.phsB"
						},
						{
							name: "Дифференциальные токи (фаза C)",
							linkedId: "TIMTPDIF1.DifAClc.phsC"
						},
						{
							name: "Тормозные токи (фаза A)",
							linkedId: "TIMTPDIF1.RstA.phsA"
						},
						{
							name: "Тормозные токи (фаза B)",
							linkedId: "TIMTPDIF1.RstA.phsB"
						},
						{
							name: "Тормозные токи (фаза C)",
							linkedId: "TIMTPDIF1.RstA.phsC"
						},
						{
							name: "Фазные токи ВН (фаза А)",
							linkedId: "RMSMMXU1.A.phsA"
						},
						{
							name: "Фазные токи ВН (фаза B)",
							linkedId: "RMSMMXU1.A.phsB"
						},
						{
							name: "Фазные токи ВН (фаза C)",
							linkedId: "RMSMMXU1.A.phsC"
						},
						{
							name: "Фазные токи СН(/НН1) (фаза А)",
							linkedId: "RMSMMXU2.A.phsA"
						},
						{
							name: "Фазные токи СН(/НН1) (фаза B)",
							linkedId: "RMSMMXU2.A.phsB"
						},
						{
							name: "Фазные токи СН(/НН1) (фаза C)",
							linkedId: "RMSMMXU2.A.phsC"
						},
						{
							name: "Фазные токи НН(/НН2) (фаза А)",
							linkedId: "RMSMMXU3.A.phsA"
						},
						{
							name: "Фазные токи НН(/НН2) (фаза B)",
							linkedId: "RMSMMXU3.A.phsB"
						},
						{
							name: "Фазные токи НН(/НН2) (фаза C)",
							linkedId: "RMSMMXU3.A.phsC"
						},
						{
							name: "Фазные токи НН1 (фаза A)",
							linkedId: "RMSMMXU4.A.phsA"
						},
						{
							name: "Фазные токи НН1 (фаза B)",
							linkedId: "RMSMMXU4.A.phsB"
						},
						{
							name: "Фазные токи НН1 (фаза C)",
							linkedId: "RMSMMXU4.A.phsC"
						},
						{
							name: "Фазные токи НН2 (фаза A)",
							linkedId: "RMSMMXU5.A.phsA"
						},
						{
							name: "Фазные токи НН2 (фаза B)",
							linkedId: "RMSMMXU5.A.phsB"
						},
						{
							name: "Фазные токи НН2 (фаза C)",
							linkedId: "RMSMMXU5.A.phsC"
						},
						{
							name: "Фазные токи НН3 (фаза A)",
							linkedId: "RMSMMXU6.A.phsA"
						},
						{
							name: "Фазные токи НН3 (фаза B)",
							linkedId: "RMSMMXU6.A.phsB"
						},
						{
							name: "Фазные токи НН3 (фаза C)",
							linkedId: "RMSMMXU6.A.phsC"
						},
						{
							name: "Фазные токи НН4 (фаза A)",
							linkedId: "RMSMMXU7.A.phsA"
						},
						{
							name: "Фазные токи НН4 (фаза B)",
							linkedId: "RMSMMXU7.A.phsB"
						},
						{
							name: "Фазные токи НН4 (фаза C)",
							linkedId: "RMSMMXU7.A.phsC"
						},
						{
							name: "Ток нейтрали 1",
							linkedId: "RMSMMXN1.Amp"
						},
						{
							name: "Ток нейтрали 2",
							linkedId: "RMSMMXN2.Amp"
						}
					]
				},
				{
					name: "Шунтирующий реактор",
					nodes: [
						{
							name: "Дифференциальные токи (фаза A)",
							linkedId: "TIMTPDIF1.DifAClc.phsA"
						},
						{
							name: "Дифференциальные токи (фаза B)",
							linkedId: "TIMTPDIF1.DifAClc.phsB"
						},
						{
							name: "Дифференциальные токи (фаза C)",
							linkedId: "TIMTPDIF1.DifAClc.phsC"
						},
						{
							name: "Тормозные токи (фаза A)",
							linkedId: "TIMTPDIF1.RstA.phsA"
						},
						{
							name: "Тормозные токи (фаза B)",
							linkedId: "TIMTPDIF1.RstA.phsB"
						},
						{
							name: "Тормозные токи (фаза C)",
							linkedId: "TIMTPDIF1.RstA.phsC"
						},
						{
							name: "Фазные токи В1 (фаза А)",
							linkedId: "RMSMMXU1.A.phsA"
						},
						{
							name: "Фазные токи В1(фаза B)",
							linkedId: "RMSMMXU1.A.phsB"
						},
						{
							name: "Фазные токи В1 (фаза C)",
							linkedId: "RMSMMXU1.A.phsC"
						},
						{
							name: "Фазные токи В2 (фаза А)",
							linkedId: "RMSMMXU2.A.phsA"
						},
						{
							name: "Фазные токи В2 (фаза B)",
							linkedId: "RMSMMXU2.A.phsB"
						},
						{
							name: "Фазные токи В2 (фаза C)",
							linkedId: "RMSMMXU2.A.phsC"
						},
						{
							name: "Фазные токи НВ1 (фаза А)",
							linkedId: "RMSMMXU3.A.phsA"
						},
						{
							name: "Фазные токи НВ1 (фаза B)",
							linkedId: "RMSMMXU3.A.phsB"
						},
						{
							name: "Фазные токи НВ1 (фаза C)",
							linkedId: "RMSMMXU3.A.phsC"
						},
						{
							name: "Фазные токи НВ2 (фаза A)",
							linkedId: "RMSMMXU4.A.phsA"
						},
						{
							name: "Фазные токи НВ2 (фаза B)",
							linkedId: "RMSMMXU4.A.phsB"
						},
						{
							name: "Фазные токи НВ2 (фаза C)",
							linkedId: "RMSMMXU4.A.phsC"
						},
						{
							name: "Ток нейтрали 1",
							linkedId: "RMSMMXN1.Amp"
						},
						{
							name: "Ток нейтрали 2",
							linkedId: "RMSMMXN2.Amp"
						}
					]
				},
				{
					name: "Управляемый шунтирующий реактор",
					nodes: [
						{
							name: "Дифференциальные токи (фаза A)",
							linkedId: "RCWTPDIF1.DifAClc.phsA"
						},
						{
							name: "Дифференциальные токи (фаза B)",
							linkedId: "RCWTPDIF1.DifAClc.phsB"
						},
						{
							name: "Дифференциальные токи (фаза C)",
							linkedId: "RCWTPDIF1.DifAClc.phsC"
						},
						{
							name: "Тормозные токи (фаза A)",
							linkedId: "RCWTPDIF1.RstA.phsA"
						},
						{
							name: "Тормозные токи (фаза B)",
							linkedId: "RCWTPDIF1.RstA.phsB"
						},
						{
							name: "Тормозные токи (фаза C)",
							linkedId: "RCWTPDIF1.RstA.phsC"
						},
						{
							name: "Фазные токи КОР (фаза А)",
							linkedId: "RMSMMXU1.A.phsA"
						},
						{
							name: "Фазные токи КОР(фаза B)",
							linkedId: "RMSMMXU1.A.phsB"
						},
						{
							name: "Фазные токи КОР (фаза C)",
							linkedId: "RMSMMXU1.A.phsC"
						},
						{
							name: "Фазные токи выводов НН КОР (фаза А)",
							linkedId: "RMSMMXU2.A.phsA"
						},
						{
							name: "Фазные токи выводов НН КОР (фаза B)",
							linkedId: "RMSMMXU2.A.phsB"
						},
						{
							name: "Фазные токи выводов НН КОР (фаза C)",
							linkedId: "RMSMMXU2.A.phsC"
						},
						{
							name: "Ток(и) заземленного вывода средней точки ОУР",
							linkedId: "RMSMMXU5.A"
						}
					]
				},
				{
					name: "Сборные шины / ошиновки",
					children: [
						{
							name: "Фазные токи В1 (фаза А)",
							linkedId: "RMSMMXU1.A.phsA"
						},
						{
							name: "Фазные токи В1 (фаза B)",
							linkedId: "RMSMMXU1.A.phsB"
						},
						{
							name: "Фазные токи В1 (фаза C)",
							linkedId: "RMSMMXU1.A.phsC"
						},
						{
							name: "Фазные токи В2 (фаза А)",
							linkedId: "RMSMMXU2.A.phsA"
						},
						{
							name: "Фазные токи В2 (фаза B)",
							linkedId: "RMSMMXU2.A.phsB"
						},
						{
							name: "Фазные токи В2 (фаза C)",
							linkedId: "RMSMMXU2.A.phsC"
						},
						{
							name: "Фазные токи В3 (фаза А)",
							linkedId: "RMSMMXU3.A.phsA"
						},
						{
							name: "Фазные токи В3 (фаза B)",
							linkedId: "RMSMMXU3.A.phsB"
						},
						{
							name: "Фазные токи В3 (фаза C)",
							linkedId: "RMSMMXU3.A.phsC"
						},
						{
							name: "Фазные токи В4 (фаза A)",
							linkedId: "RMSMMXU4.A.phsA"
						},
						{
							name: "Фазные токи В4 (фаза B)",
							linkedId: "RMSMMXU4.A.phsB"
						},
						{
							name: "Фазные токи В4 (фаза C)",
							linkedId: "RMSMMXU4.A.phsC"
						},
						{
							name: "Фазные токи В5 (фаза A)",
							linkedId: "RMSMMXU5.A.phsA"
						},
						{
							name: "Фазные токи В5 (фаза B)",
							linkedId: "RMSMMXU5.A.phsB"
						},
						{
							name: "Фазные токи В5 (фаза C)",
							linkedId: "RMSMMXU5.A.phsC"
						},
						{
							name: "Фазные токи В6 (фаза A)",
							linkedId: "RMSMMXU6.A.phsA"
						},
						{
							name: "Фазные токи В6 (фаза B)",
							linkedId: "RMSMMXU6.A.phsB"
						},
						{
							name: "Фазные токи В6 (фаза C)",
							linkedId: "RMSMMXU6.A.phsC"
						},
						{
							name: "Фазные токи В7 (фаза A)",
							linkedId: "RMSMMXU7.A.phsA"
						},
						{
							name: "Фазные токи В7 (фаза B)",
							linkedId: "RMSMMXU7.A.phsB"
						},
						{
							name: "Фазные токи В7 (фаза C)",
							linkedId: "RMSMMXU7.A.phsC"
						},
						{
							name: "Фазные токи В8 (фаза А)",
							linkedId: "RMSMMXU8.A.phsA"
						},
						{
							name: "Фазные токи В8 (фаза B)",
							linkedId: "RMSMMXU8.A.phsB"
						},
						{
							name: "Фазные токи В8 (фаза C)",
							linkedId: "RMSMMXU8.A.phsC"
						},
						{
							name: "Фазные токи В9 (фаза А)",
							linkedId: "RMSMMXU9.A.phsA"
						},
						{
							name: "Фазные токи В9 (фаза B)",
							linkedId: "RMSMMXU9.A.phsB"
						},
						{
							name: "Фазные токи В9 (фаза C)",
							linkedId: "RMSMMXU9.A.phsC"
						},
						{
							name: "Фазные токи В10 (фаза А)",
							linkedId: "RMSMMXU10.A.phsA"
						},
						{
							name: "Фазные токи В11 (фаза B)",
							linkedId: "RMSMMXU11.A.phsB"
						},
						{
							name: "Фазные токи В11 (фаза C)",
							linkedId: "RMSMMXU11.A.phsC"
						},
						{
							name: "Фазные токи В12 (фаза A)",
							linkedId: "RMSMMXU12.A.phsA"
						},
						{
							name: "Фазные токи В12 (фаза B)",
							linkedId: "RMSMMXU12.A.phsB"
						},
						{
							name: "Фазные токи В12 (фаза C)",
							linkedId: "RMSMMXU12.A.phsC"
						},
						{
							name: "Фазные токи В13 (фаза A)",
							linkedId: "RMSMMXU13.A.phsA"
						},
						{
							name: "Фазные токи В13 (фаза B)",
							linkedId: "RMSMMXU13.A.phsB"
						},
						{
							name: "Фазные токи В13 (фаза C)",
							linkedId: "RMSMMXU13.A.phsC"
						},
						{
							name: "Фазные токи В14 (фаза A)",
							linkedId: "RMSMMXU14.A.phsA"
						},
						{
							name: "Фазные токи В14 (фаза B)",
							linkedId: "RMSMMXU14.A.phsB"
						},
						{
							name: "Фазные токи В14 (фаза C)",
							linkedId: "RMSMMXU14.A.phsC"
						},
						{
							name: "Фазные токи В15 (фаза A)",
							linkedId: "RMSMMXU15.A.phsA"
						},
						{
							name: "Фазные токи В15 (фаза B)",
							linkedId: "RMSMMXU15.A.phsB"
						},
						{
							name: "Фазные токи В15 (фаза C)",
							linkedId: "RMSMMXU15.A.phsC"
						},
						{
							name: "Фазные токи В16 (фаза A)",
							linkedId: "RMSMMXU16.A.phsA"
						},
						{
							name: "Фазные токи В16 (фаза B)",
							linkedId: "RMSMMXU16.A.phsB"
						},
						{
							name: "Фазные токи В16 (фаза C)",
							linkedId: "RMSMMXU16.A.phsC"
						},
						{
							name: "Фазные токи В17 (фаза A)",
							linkedId: "RMSMMXU17.A.phsA"
						},
						{
							name: "Фазные токи В17 (фаза B)",
							linkedId: "RMSMMXU17.A.phsB"
						},
						{
							name: "Фазные токи В17 (фаза C)",
							linkedId: "RMSMMXU17.A.phsC"
						},
						{
							name: "Фазные токи ОВ (фаза A)",
							linkedId: "RMSMMXU18.A.phsA"
						},
						{
							name: "Фазные токи ОВ (фаза B)",
							linkedId: "RMSMMXU18.A.phsB"
						},
						{
							name: "Фазные токи ОВ (фаза C)",
							linkedId: "RMSMMXU18.A.phsC"
						},
						{
							name: "Фазные токи ШСВ/СВ со стороны 1секц (фаза A)",
							linkedId: "RMSMMXU19.A.phsA"
						},
						{
							name: "Фазные токи ШСВ/СВ со стороны 1секц (фаза B)",
							linkedId: "RMSMMXU19.A.phsB"
						},
						{
							name: "Фазные токи ШСВ/СВ со стороны 1секц (фаза C)",
							linkedId: "RMSMMXU19.A.phsC"
						},
						{
							name: "Фазные токи ШСВ/СВ со стороны 2секц (фаза A)",
							linkedId: "RMSMMXU20.A.phsA"
						},
						{
							name: "Фазные токи ШСВ/СВ со стороны 2секц (фаза B)",
							linkedId: "RMSMMXU20.A.phsB"
						},
						{
							name: "Фазные токи ШСВ/СВ со стороны 2секц (фаза C)",
							linkedId: "RMSMMXU20.A.phsC"
						},
						{
							name: "Ток нейтрали 1",
							linkedId: "RMSMMXN1.Amp"
						},
						{
							name: "Ток нейтрали 2",
							linkedId: "RMSMMXN2.Amp"
						},
						{
							name: "Дифференциальные токи (фаза A)",
							linkedId: "TIMBTPDIF1.DifAClc.phsA"
						},
						{
							name: "Дифференциальные токи (фаза B)",
							linkedId: "TIMBTPDIF1.DifAClc.phsB"
						},
						{
							name: "Дифференциальные токи (фаза C)",
							linkedId: "TIMBTPDIF1.DifAClc.phsC"
						},
						{
							name: "Тормозные токи (фаза A)",
							linkedId: "TIMBTPDIF1.RstA.phsA"
						},
						{
							name: "Тормозные токи (фаза B)",
							linkedId: "TIMBTPDIF1.RstA.phsB"
						},
						{
							name: "Тормозные токи (фаза C)",
							linkedId: "TIMBTPDIF1.RstA.phsC"
						}

					]
				}
			]
		},
		{
			name: "Напряжение",
			children: [
				{
					name: "Линия",
					nodes: [

						{
							name: "Фазные напряжения (фаза A)",
							linkedId: "RMSMMXU1.PNV.phsA"
						},
						{
							name: "Фазные напряжения (фаза B)",
							linkedId: "RMSMMXU1.PNV.phsB"
						},
						{
							name: "Фазные напряжения (фаза C)",
							linkedId: "RMSMMXU1.PNV.phsC"
						}

					]
				},
				{
					name: "Трансформатор",
					nodes: [
						{
							name: "Фазные напряжения ВН (фаза A)",
							linkedId: "RMSMMXU1.PNV.phsA"
						},
						{
							name: "Фазные напряжения ВН (фаза B)",
							linkedId: "RMSMMXU1.PNV.phsB"
						},
						{
							name: "Фазные напряжения ВН (фаза C)",
							linkedId: "RMSMMXU1.PNV.phsC"
						},
						{
							name: "Фазные напряжения СН/НН1 (фаза A)",
							linkedId: "RMSMMXU2.PNV.phsA"
						},
						{
							name: "Фазные напряжения СН/НН1 (фаза B)",
							linkedId: "RMSMMXU2.PNV.phsB"
						},
						{
							name: "Фазные напряжения СН/НН1 (фаза C)",
							linkedId: "RMSMMXU2.PNV.phsC"
						},
						{
							name: "Фазные напряжения НН/НН2(фаза A)",
							linkedId: "RMSMMXU3.PNV.phsA"
						},
						{
							name: "Фазные напряжения НН/НН2(фаза B)",
							linkedId: "RMSMMXU3.PNV.phsB"
						},
						{
							name: "Фазные напряжения НН/НН2(фаза C)",
							linkedId: "RMSMMXU3.PNV.phsC"
						}


					]
				},
				{
					name: "Шунтирующий реактор",
					nodes: [
						{
							name: "Фазные напряжения ВН (фаза A)",
							linkedId: "RMSMMXU1.PNV.phsA"
						},
						{
							name: "Фазные напряжения ВН (фаза B)",
							linkedId: "RMSMMXU1.PNV.phsB"
						},
						{
							name: "Фазные напряжения ВН (фаза C)",
							linkedId: "RMSMMXU1.PNV.phsC"
						},
						{
							name: "Фазные напряжения НВ (фаза A)",
							linkedId: "RMSMMXU5.PNV.phsA"
						},
						{
							name: "Фазные напряжения НВ (фаза B)",
							linkedId: "RMSMMXU5.PNV.phsB"
						},
						{
							name: "Фазные напряжения НВ (фаза C)",
							linkedId: "RMSMMXU5.PNV.phsC"
						}

					]
				},
				{
					name: "Управляемый шунтирующий реактор",
					nodes: [
						{
							name: "Фазные напряжения ВН (фаза A)",
							linkedId: "RMSMMXU1.PNV.phsA"
						},
						{
							name: "Фазные напряжения ВН (фаза B)",
							linkedId: "RMSMMXU1.PNV.phsB"
						},
						{
							name: "Фазные напряжения ВН (фаза C)",
							linkedId: "RMSMMXU1.PNV.phsC"
						},
						{
							name: "Фазные напряжения НВ (фаза A)",
							linkedId: "RMSMMXU5.PNV.phsA"
						},
						{
							name: "Фазные напряжения НВ (фаза B)",
							linkedId: "RMSMMXU5.PNV.phsB"
						},
						{
							name: "Фазные напряжения НВ (фаза C)",
							linkedId: "RMSMMXU5.PNV.phsC"
						}

					]
				},
				{
					name: "Сборные шины / ошиновка",
					nodes: [
						{
							name: "Фазные напряжения 1 СШ(фаза A)",
							linkedId: "RMSMMXU19.PNV.phsA"
						},
						{
							name: "Фазные напряжения 1 СШ(фаза B)",
							linkedId: "RMSMMXU19.PNV.phsB"
						},
						{
							name: "Фазные напряжения 1 СШ(фаза C)",
							linkedId: "RMSMMXU19.PNV.phsC"
						},
						{
							name: "Фазные напряжения 2 СШ(фаза A)",
							linkedId: "RMSMMXU20.PNV.phsA"
						},
						{
							name: "Фазные напряжения 2 СШ(фаза B)",
							linkedId: "RMSMMXU20.PNV.phsB"
						},
						{
							name: "Фазные напряжения 2 СШ(фаза C)",
							linkedId: "RMSMMXU20.PNV.phsC"
						}

					]
				}
			]
		}
	],
	discrete: [
		{
			name: "Линия",
			children: [
				{
					name: "ДЗ общие сигналы",
					nodes: [
						{
							name: "ДЗ пуск",
							linkedId: "TPDIS.Str"
						},
						{
							name: "ДЗ срабатывание 3ф.",
							linkedId: "TPDIS.Op"
						},
						{
							name: "ДЗ срабатывание (фаза А)",
							linkedId: "TPDIS.Op.phsA"
						},
						{
							name: "ДЗ срабатывание (фаза B)",
							linkedId: "TPDIS.Op.phsB"
						},
						{
							name: "ДЗ срабатывание (фаза C)",
							linkedId: "TPDIS.Op.phsC"
						}
					]
				},
				{
					name: "ДЗ фз",
					nodes: [
						{
							name: "ДЗз1 пуск",
							linkedId: "ZERTPDIS1.Str"
						},
						{
							name: "ДЗз1.пуск (фаза А)",
							linkedId: "ZERTPDIS1.Str.phsA"
						},
						{
							name: "ДЗз1.пуск (фаза B)",
							linkedId: "ZERTPDIS1.Str.phsB"
						},
						{
							name: "ДЗз1.пуск (фаза С)",
							linkedId: "ZERTPDIS1.Str.phsC"
						},
						{
							name: "ДЗз1 срабатывание 3ф.",
							linkedId: "ZERTPDIS1.Op"
						},
						{
							name: "ДЗз1 срабатывание 1ф.",
							linkedId: "ZERPHSTPDIS1.Op"
						},
						{
							name: "ДЗз2 пуск",
							linkedId: "ZERTPDIS2.Str"
						},
						{
							name: "ДЗз2 срабатывание",
							linkedId: "ZERTPDIS2.Op"
						},
						{
							name: "ДЗз1B.пуск",
							linkedId: "ZERTPDIS7.Str"
						},
						{
							name: "ДЗз1B.пуск (фаза А)",
							linkedId: "ZERTPDIS7.Str.phsA"
						},
						{
							name: "ДЗз1B.пуск (фаза B)",
							linkedId: "ZERTPDIS7.Str.phsB"
						},
						{
							name: "ДЗз1B.пуск (фаза С)",
							linkedId: "ZERTPDIS7.Str.phsC"
						},
						{
							name: "ДЗз1B.срабатывание",
							linkedId: "ZERTPDIS7.Op"
						},
					]
				},
				{
					name: "ДЗ фф",
					nodes: [
						{
							name: "ДЗ_АУ.срабатывание",
							linkedId: "AUADISTPDIS1.Op"
						},
						{
							name: "ДЗ_ОУ.пуск",
							linkedId: "OPADISTPDIS1.Str"
						},
						{
							name: "ДЗ_ОУ.срабатывание",
							linkedId: "OPADISTPDIS1.Op"
						},
						{
							name: "ДЗ_ОУбвв.пуск",
							linkedId: "OPADISTPDIS2.Str"
						},
						{
							name: "ДЗ_ОУбвв.срабатывание",
							linkedId: "OPADISTPDIS2.Op"
						},

						{
							name: "ДЗф.пуск",
							linkedId: "PHSTPDIS.Str"
						},
						{
							name: "ДЗф.срабатывание",
							linkedId: "PHSTPDIS.Op"
						},
						{
							name: "ДЗф1.пуск",
							linkedId: "PHSTPDIS1.Str"
						},
						{
							name: "ДЗф1.пуск (фаза АB)",
							linkedId: "PHSTPDIS1.Str.phsAB"
						},
						{
							name: "ДЗф1.пуск (фаза BC)",
							linkedId: "PHSTPDIS1.Str.phsBC"
						},
						{
							name: "ДЗф1.пуск (фаза СA)",
							linkedId: "PHSTPDIS1.Str.phsCA"
						},
						{
							name: "ДЗф1.срабатывание",
							linkedId: "PHSTPDIS1.Op"
						},
						{
							name: "ДЗф2.пуск",
							linkedId: "PHSTPDIS2.Str"
						},
						{
							name: "ДЗф2.срабатывание",
							linkedId: "PHSTPDIS2.Op"
						},
						{
							name: "ДЗф3.пуск",
							linkedId: "PHSTPDIS3.Str"
						},
						{
							name: "ДЗф3.срабатывание",
							linkedId: "PHSTPDIS3.Op"
						},
						{
							name: "ДЗф4.пуск",
							linkedId: "PHSTPDIS4.Str"
						},
						{
							name: "ДЗф4.срабатывание",
							linkedId: "PHSTPDIS4.Op"
						},
						{
							name: "ДЗф5.пуск",
							linkedId: "PHSTPDIS5.Str"
						},
						{
							name: "ДЗф5.срабатывание",
							linkedId: "PHSTPDIS5.Op"
						},
						{
							name: "ДЗф6.пуск",
							linkedId: "PHSTPDIS6.Str"
						},
						{
							name: "ДЗф6.срабатывание",
							linkedId: "PHSTPDIS6.Op"
						},
						{
							name: "ДЗф1B.пуск",
							linkedId: "PHSTPDIS7.Str"
						},
						{
							name: "ДЗф1B.пуск (фаза АB)",
							linkedId: "PHSTPDIS7.Str.phsAB"
						},
						{
							name: "ДЗф1B.пуск (фаза BC)",
							linkedId: "PHSTPDIS7.Str.phsBC"
						},
						{
							name: "ДЗф1B.пуск (фаза СA)",
							linkedId: "PHSTPDIS7.Str.phsCA"
						},
						{
							name: "ДЗф1B.срабатывание",
							linkedId: "PHSTPDIS7.Op"
						},
						{
							name: "Блокировка от качаний",
							linkedId: "DISTRPSB1.BlkZn"
						}
					]
				},
				{
					name: "ДЗЛ",
					nodes: [
						{
							name: "Пуск ДЗЛ",
							linkedId: "TIMTPDIF.Str"
						},
						{
							name: "Пуск ДЗЛ (фаза A)",
							linkedId: "TIMTPDIF.Str.phsA"
						},
						{
							name: "Пуск ДЗЛ (фаза B)",
							linkedId: "TIMTPDIF.Str.phsB"
						},
						{
							name: "Пуск ДЗЛ (фаза C)",
							linkedId: "TIMTPDIF.Str.phsC"
						},
						{
							name: "Срабатывание ДЗЛ",
							linkedId: "TIMTPDIF.Op"
						},
						{
							name: "Срабатывание ДЗЛ (фаза A)",
							linkedId: "TIMTPDIF.Op.phsA"
						},
						{
							name: "Срабатывание ДЗЛ (фаза B)",
							linkedId: "TIMTPDIF.Op.phsB"
						},
						{
							name: "Срабатывание ДЗЛ (фаза C)",
							linkedId: "TIMTPDIF.Op.phsC"
						},

						{
							name: "Срабатывание ДЗЛ 1ст.",
							linkedId: "TIMTPDIF1.Op"
						},
						{
							name: "Срабатывание ДЗЛ 1ст.(фаза A)",
							linkedId: "TIMTPDIF1.Op.phsA"
						},
						{
							name: "Срабатывание ДЗЛ 1ст.(фаза B)",
							linkedId: "TIMTPDIF1.Op.phsB"
						},
						{
							name: "Срабатывание ДЗЛ 1ст.(фаза C)",
							linkedId: "TIMTPDIF1.Op.phsC"
						},
						{
							name: "Срабатывание ДЗЛ 2ст.",
							linkedId: "TIMTPDIF2.Op"
						},
						{
							name: "Срабатывание ДЗЛ 2ст.(фаза A)",
							linkedId: "TIMTPDIF2.Op.phsA"
						},
						{
							name: "Срабатывание ДЗЛ 2ст.(фаза B)",
							linkedId: "TIMTPDIF2.Op.phsB"
						},
						{
							name: "Срабатывание ДЗЛ 2ст.(фаза C)",
							linkedId: "TIMTPDIF2.Op.phsC"
						}
					]
				},
				{
					name: "ДФЗ",
					nodes: [
						{
							name: "Неисправность канала",
							linkedId: "PCPTITPC1.LosSig"
						},
						{
							name: "Неисправность ПП",
							linkedId: "PCPPSCH1.Health"
						},
						{
							name: "Пуск ИПФ",
							linkedId: "PHSSPDIS1.Str"
						},
						{
							name: "Сигнал ВЧ ПРД",
							linkedId: "PCPPSCH1.TxPRM"
						},
						{
							name: "Сигнал ВЧ ПРМ",
							linkedId: "PCPPSCH1.RxPRM"
						},
						{
							name: "Срабатывание ДФЗ",
							linkedId: "PCPTPDIF1.Op"
						}
					]
				},
				{
					name: "ЗНР",
					nodes: [
						{
							name: "ЗНР.срабатывание",
							linkedId: "BALTPTOC1.Op"
						}
					]
				},
				{
					name: "ЗНФ",
					nodes: [
						{
							name: "В1_ЗНФ1.срабатывание",
							linkedId: "Q1PHXCBR4.Dsc1"
						},
						{
							name: "В1_ЗНФ2.срабатывание",
							linkedId: "Q1PHXCBR4.Dsc2"
						}
					]
				},
				{
					name: "МТЗ",
					nodes: [
						{
							name: "МТЗ.пуск",
							linkedId: "PHSTPTOC.Str"
						},
						{
							name: "МТЗ.пуск (фаза А)",
							linkedId: "PHSTPTOC.Str.phsA"
						},
						{
							name: "МТЗ.пуск (фаза B)",
							linkedId: "PHSTPTOC.Str.phsB"
						},
						{
							name: "МТЗ.пуск (фаза C)",
							linkedId: "PHSTPTOC.Str.phsC"
						},
						{
							name: "МТЗ.срабатывание",
							linkedId: "PHSTPTOC.Op"
						},
						{
							name: "МТЗ.срабатывание (фаза А)",
							linkedId: "PHSTPTOC.Op.phsA"
						},
						{
							name: "МТЗ.срабатывание (фаза B)",
							linkedId: "PHSTPTOC.Op.phsB"
						},
						{
							name: "МТЗ.срабатывание (фаза C)",
							linkedId: "PHSTPTOC.Op.phsC"
						},
						{
							name: "МТЗ1.пуск",
							linkedId: "PHSTPTOC1.Str"
						},
						{
							name: "МТЗ1.пуск (фаза А)",
							linkedId: "PHSTPTOC1.Str.phsA"
						},
						{
							name: "МТЗ1.пуск (фаза B)",
							linkedId: "PHSTPTOC1.Str.phsB"
						},
						{
							name: "МТЗ1.пуск (фаза C)",
							linkedId: "PHSTPTOC1.Str.phsC"
						},

						{
							name: "МТЗ1.срабатывание",
							linkedId: "PHSTPTOC1.Op"
						},
						{
							name: "МТЗ1.срабатывание (фаза А)",
							linkedId: "PHSTPTOC1.Op.phsA"
						},
						{
							name: "МТЗ1.срабатывание (фаза B)",
							linkedId: "PHSTPTOC1.Op.phsB"
						},
						{
							name: "МТЗ1.срабатывание (фаза C)",
							linkedId: "PHSTPTOC1.Op.phsC"
						},
						{
							name: "МТЗ2.пуск",
							linkedId: "PHSTPTOC2.Str"
						},
						{
							name: "МТЗ2.пуск (фаза А)",
							linkedId: "PHSTPTOC2.Str.phsA"
						},
						{
							name: "МТЗ2.пуск (фаза B)",
							linkedId: "PHSTPTOC2.Str.phsB"
						},
						{
							name: "МТЗ2.пуск (фаза C)",
							linkedId: "PHSTPTOC2.Str.phsC"
						},
						{
							name: "МТЗ2.срабатывание",
							linkedId: "PHSTPTOC2.Op"
						},
						{
							name: "МТЗ2.срабатывание (фаза А)",
							linkedId: "PHSTPTOC2.Op.phsA"
						},
						{
							name: "МТЗ2.срабатывание (фаза B)",
							linkedId: "PHSTPTOC2.Op.phsB"
						},
						{
							name: "МТЗ2.срабатывание (фаза C)",
							linkedId: "PHSTPTOC2.Op.phsC"
						},
						{
							name: "МТЗ3.пуск",
							linkedId: "PHSTPTOC3.Str"
						},
						{
							name: "МТЗ3.пуск (фаза А)",
							linkedId: "PHSTPTOC3.Str.phsA"
						},
						{
							name: "МТЗ3.пуск (фаза B)",
							linkedId: "PHSTPTOC3.Str.phsB"
						},
						{
							name: "МТЗ3.пуск (фаза C)",
							linkedId: "PHSTPTOC3.Str.phsC"
						},
						{
							name: "МТЗ3.срабатывание",
							linkedId: "PHSTPTOC3.Op"
						},
						{
							name: "МТЗ3.срабатывание (фаза А)",
							linkedId: "PHSTPTOC3.Op.phsA"
						},
						{
							name: "МТЗ3.срабатывание (фаза B)",
							linkedId: "PHSTPTOC3.Op.phsB"
						},
						{
							name: "МТЗ3.срабатывание (фаза C)",
							linkedId: "PHSTPTOC3.Op.phsC"
						},
						{
							name: "МТЗ_АУ.срабатывание",
							linkedId: "AUAPHSTPTOC1.Op"
						}
					]
				},
				{
					name: "МФТО",
					nodes: [
						{
							name: "МФТО.срабатывание",
							linkedId: "PHSTPIOC1.Op"
						},
						{
							name: "МФТО.срабатывание (фаза А)",
							linkedId: "PHSTPIOC1.Op.phsA"
						},
						{
							name: "МФТО.срабатывание(фаза B)",
							linkedId: "PHSTPIOC1.Op.phsB"
						},
						{
							name: "МФТО.срабатывание (фаза C)",
							linkedId: "PHSTPIOC1.Op.phsC"
						}
					]
				},
				{
					name: "ОАПВ",
					nodes: [
						{
							name: "ОАПВ1_A.успешное/неуспешное",
							linkedId: "Q1PHRREC1.AutoRecSt"
						},
						{
							name: "ОАПВ1_В.успешное/неуспешное",
							linkedId: "Q1PHRREC2.AutoRecSt"
						},
						{
							name: "ОАПВ1_С.успешное/неуспешное",
							linkedId: "Q1PHRREC3.AutoRecSt"
						},

						{
							name: "ОАПВ2_A.успешное/неуспешное",
							linkedId: "Q2PHRREC1.AutoRecSt"
						},
						{
							name: "ОАПВ2_В.успешное/неуспешное",
							linkedId: "Q2PHRREC2.AutoRecSt"
						},
						{
							name: "ОАПВ2_С.успешное/неуспешное",
							linkedId: "Q2PHRREC3.AutoRecSt"
						}
					]
				},
				{
					name: "ТАПВ",
					nodes: [
						{
							name: "ТАПВ1.успешное/неуспешное",
							linkedId: "Q1TRREC1.AutoRecSt"
						},
						{
							name: "ТАПВ2.успешное/неуспешное",
							linkedId: "Q2TRREC1.AutoRecSt"
						}
					]
				},
				{
					name: "ТНЗНП",
					nodes: [
						{
							name: "ОНМНПпрям.срабатывание",
							linkedId: "ZERTOCTRDIR1.Dir"
						},
						{
							name: "ТНЗНП.пуск",
							linkedId: "DZERTPTOC.Str"
						},
						{
							name: "ТНЗНП.срабатывание",
							linkedId: "DZERTPTOC.Op"
						},
						{
							name: "ТНЗНП1.пуск",
							linkedId: "DZERTPTOC1.Str"
						},
						{
							name: "ТНЗНП1.срабатывание",
							linkedId: "DZERTPTOC1.Op"
						},
						{
							name: "ТНЗНП2.пуск",
							linkedId: "DZERTPTOC2.Str"
						},
						{
							name: "ТНЗНП2.срабатывание",
							linkedId: "DZERTPTOC2.Op"
						},
						{
							name: "ТНЗНП3.пуск",
							linkedId: "DZERTPTOC3.Str"
						},
						{
							name: "ТНЗНП3.срабатывание",
							linkedId: "DZERTPTOC3.Op"
						},
						{
							name: "ТНЗНП4.пуск",
							linkedId: "DZERTPTOC4.Str"
						},
						{
							name: "ТНЗНП4.срабатывание",
							linkedId: "DZERTPTOC4.Op"
						},
						{
							name: "ТНЗНП5.пуск",
							linkedId: "DZERTPTOC5.Str"
						},
						{
							name: "ТНЗНП5.срабатывание",
							linkedId: "DZERTPTOC5.Op"
						},
						{
							name: "ТНЗНП6.пуск",
							linkedId: "DZERTPTOC6.Str"
						},
						{
							name: "ТНЗНП6.срабатывание",
							linkedId: "DZERTPTOC6.Op"
						},
						{
							name: "ТНЗНП_АУ.срабатывание",
							linkedId: "AUATOCTPTOC1.Op"
						},
						{
							name: "ТНЗНП_ОУ.пуск",
							linkedId: "OPATOCTPTOC1.Str"
						},
						{
							name: "ТНЗНП_ОУ.срабатывание",
							linkedId: "OPATOCTPTOC1.Op"
						},
						{
							name: "ТНЗНП_ОУбвв.пуск",
							linkedId: "OPATOCTPTOC2.Str"
						},
						{
							name: "ТНЗНП_ОУбвв.срабатывание",
							linkedId: "OPATOCTPTOC2.Op"
						}
					]
				},
				{
					name: "УРОВ",
					nodes: [
						{
							name: "УРОВ1.срабатывание",
							linkedId: "TRBRF1.OpEx"
						},
						{
							name: "УРОВ1.срабатывание (фаза A)",
							linkedId: "TRBRF1.OpEx.phsA"
						},
						{
							name: "УРОВ1.срабатывание (фаза B)",
							linkedId: "TRBRF1.OpEx.phsB"
						},
						{
							name: "УРОВ1.срабатывание (фаза C)",
							linkedId: "TRBRF1.OpEx.phsC"
						},
						{
							name: "УРОВ2.срабатывание",
							linkedId: "TRBRF2.OpEx"
						}
					]
				},
				{
					name: "УТАПВ",
					nodes: [
						{
							name: "УТАПВ1.успешное/неуспешное",
							linkedId: "Q1TRREC2.AutoRecSt"
						},
						{
							name: "УТАПВ2.успешное/неуспешное",
							linkedId: "Q2TRREC2.AutoRecSt"
						}
					]
				},
			]
		},
		{
			name: "Трансформатор",
			children: [
				{
					name: "ДЗ фз",
					nodes: [
						{
							name: "ДЗз1 пуск",
							linkedId: "ZERTPDIS1.Str"
						},
						{
							name: "ДЗз1.пуск (фаза А)",
							linkedId: "ZERTPDIS1.Str.phsA"
						},
						{
							name: "ДЗз1.пуск (фаза B)",
							linkedId: "ZERTPDIS1.Str.phsB"
						},
						{
							name: "ДЗз1.пуск (фаза С)",
							linkedId: "ZERTPDIS1.Str.phsC"
						},
						{
							name: "ДЗз1 срабатывание 3ф.",
							linkedId: "ZERTPDIS1.Op"
						},
						{
							name: "ДЗз1 срабатывание 1ф.",
							linkedId: "ZERPHSTPDIS1.Op"
						},
						{
							name: "ДЗз2 пуск",
							linkedId: "ZERTPDIS2.Str"
						},
						{
							name: "ДЗз2 срабатывание",
							linkedId: "ZERTPDIS2.Op"
						},
						{
							name: "ДЗз1B.пуск",
							linkedId: "ZERTPDIS7.Str"
						},
						{
							name: "ДЗз1B.пуск (фаза А)",
							linkedId: "ZERTPDIS7.Str.phsA"
						},
						{
							name: "ДЗз1B.пуск (фаза B)",
							linkedId: "ZERTPDIS7.Str.phsB"
						},
						{
							name: "ДЗз1B.пуск (фаза С)",
							linkedId: "ZERTPDIS7.Str.phsC"
						},
						{
							name: "ДЗз1B.срабатывание",
							linkedId: "ZERTPDIS7.Op"
						},
					]
				},
				{
					name: "ДЗ фф",
					nodes: [
						{
							name: "ДЗ_АУ.срабатывание",
							linkedId: "AUADISTPDIS1.Op"
						},
						{
							name: "ДЗ_ОУ.пуск",
							linkedId: "OPADISTPDIS1.Str"
						},
						{
							name: "ДЗ_ОУ.срабатывание",
							linkedId: "OPADISTPDIS1.Op"
						},
						{
							name: "ДЗ_ОУбвв.пуск",
							linkedId: "OPADISTPDIS2.Str"
						},
						{
							name: "ДЗ_ОУбвв.срабатывание",
							linkedId: "OPADISTPDIS2.Op"
						},

						{
							name: "ДЗф.пуск",
							linkedId: "PHSTPDIS.Str"
						},
						{
							name: "ДЗф.срабатывание",
							linkedId: "PHSTPDIS.Op"
						},
						{
							name: "ДЗф1.пуск",
							linkedId: "PHSTPDIS1.Str"
						},
						{
							name: "ДЗф1.пуск (фаза АB)",
							linkedId: "PHSTPDIS1.Str.phsAB"
						},
						{
							name: "ДЗф1.пуск (фаза BC)",
							linkedId: "PHSTPDIS1.Str.phsBC"
						},
						{
							name: "ДЗф1.пуск (фаза СA)",
							linkedId: "PHSTPDIS1.Str.phsCA"
						},
						{
							name: "ДЗф1.срабатывание",
							linkedId: "PHSTPDIS1.Op"
						},
						{
							name: "ДЗф2.пуск",
							linkedId: "PHSTPDIS2.Str"
						},
						{
							name: "ДЗф2.срабатывание",
							linkedId: "PHSTPDIS2.Op"
						},
						{
							name: "ДЗф3.пуск",
							linkedId: "PHSTPDIS3.Str"
						},
						{
							name: "ДЗф3.срабатывание",
							linkedId: "PHSTPDIS3.Op"
						},
						{
							name: "ДЗф4.пуск",
							linkedId: "PHSTPDIS4.Str"
						},
						{
							name: "ДЗф4.срабатывание",
							linkedId: "PHSTPDIS4.Op"
						},
						{
							name: "ДЗф5.пуск",
							linkedId: "PHSTPDIS5.Str"
						},
						{
							name: "ДЗф5.срабатывание",
							linkedId: "PHSTPDIS5.Op"
						},
						{
							name: "ДЗф6.пуск",
							linkedId: "PHSTPDIS6.Str"
						},
						{
							name: "ДЗф6.срабатывание",
							linkedId: "PHSTPDIS6.Op"
						},
						{
							name: "ДЗф1B.пуск",
							linkedId: "PHSTPDIS7.Str"
						},
						{
							name: "ДЗф1B.пуск (фаза АB)",
							linkedId: "PHSTPDIS7.Str.phsAB"
						},
						{
							name: "ДЗф1B.пуск (фаза BC)",
							linkedId: "PHSTPDIS7.Str.phsBC"
						},
						{
							name: "ДЗф1B.пуск (фаза СA)",
							linkedId: "PHSTPDIS7.Str.phsCA"
						},
						{
							name: "ДЗф1B.срабатывание",
							linkedId: "PHSTPDIS7.Op"
						},
						{
							name: "Блокировка от качаний",
							linkedId: "DISTRPSB1.BlkZn"
						}
					]
				},
				{
					name: "ДЗТ",
					nodes: [
						{
							name: "Срабатывание ДЗТ",
							linkedId: "TIMTPDIF.Tr"
						},
						{
							name: "Срабатывание ДЗТ (фаза A)",
							linkedId: "TIMTPDIF.Tr.phsA"
						},
						{
							name: "Срабатывание ДЗТ (фаза B)",
							linkedId: "TIMTPDIF.Tr.phsB"
						},
						{
							name: "Срабатывание ДЗТ (фаза C)",
							linkedId: "TIMTPDIF.Tr.phsC"
						},
						{
							name: "Срабатывание ДЗТ 1ст.",
							linkedId: "TIMTPDIF1.Tr"
						},
						{
							name: "Срабатывание ДЗТ 1ст.(фаза A)",
							linkedId: "TIMTPDIF1.Tr.phsA"
						},
						{
							name: "Срабатывание ДЗТ 1ст.(фаза B)",
							linkedId: "TIMTPDIF1.Tr.phsB"
						},
						{
							name: "Срабатывание ДЗТ 1ст.(фаза C)",
							linkedId: "TIMTPDIF1.Tr.phsC"
						},
						{
							name: "Срабатывание ДЗТ 2ст.",
							linkedId: "TIMTPDIF2.Tr"
						},
						{
							name: "Срабатывание ДЗТ 2ст.(фаза A)",
							linkedId: "TIMTPDIF2.Tr.phsA"
						},
						{
							name: "Срабатывание ДЗТ 2ст.(фаза B)",
							linkedId: "TIMTPDIF2.Tr.phsB"
						},
						{
							name: "Срабатывание ДЗТ 2ст.(фаза C)",
							linkedId: "TIMTPDIF2.Tr.phsC"
						}
					]
				},
				{
					name: "ДЗО",
					nodes: [
						{
							name: "Срабатывание ДЗО",
							linkedId: "TIMTPDIF.Op"
						},
						{
							name: "Срабатывание ДЗО (фаза A)",
							linkedId: "TIMTPDIF.Op.phsA"
						},
						{
							name: "Срабатывание ДЗО (фаза B)",
							linkedId: "TIMTPDIF.Op.phsB"
						},
						{
							name: "Срабатывание ДЗО (фаза C)",
							linkedId: "TIMTPDIF.Op.phsC"
						},

						{
							name: "Срабатывание ДЗО 1ст.",
							linkedId: "TIMTPDIF1.Op"
						},
						{
							name: "Срабатывание ДЗО 2ст.",
							linkedId: "TIMTPDIF2.Op"
						},
						{
							name: "Срабатывание ДЗО 1ст.(фаза A)",
							linkedId: "TIMTPDIF1.Op.phsA"
						},
						{
							name: "Срабатывание ДЗО 1ст.(фаза B)",
							linkedId: "TIMTPDIF1.Op.phsB"
						},
						{
							name: "Срабатывание ДЗО 1ст.(фаза C)",
							linkedId: "TIMTPDIF1.Op.phsC"
						},
						{
							name: "Срабатывание ДЗО 2ст.(фаза A)",
							linkedId: "TIMTPDIF2.Op.phsA"
						},
						{
							name: "Срабатывание ДЗО 2ст.(фаза B)",
							linkedId: "TIMTPDIF2.Op.phsB"
						},
						{
							name: "Срабатывание ДЗО 2ст.(фаза C)",
							linkedId: "TIMTPDIF2.Op.phsC"
						}

					]
				},
				{
					name: "ТЗНП (общий сигнал)",
					nodes: [
						{
							name: "ТЗНП.пуск",
							linkedId: "ZERTPTOC.Str"
						},
						{
							name: "ТЗНП.срабатывание",
							linkedId: "ZERTPTOC.Op"
						}
					]
				},

				{
					name: "ТЗНП ВН",
					nodes: [
						{
							name: "ТЗНП ВН ст.1.пуск",
							linkedId: "HVNEUTPTOC1.Str"
						},
						{
							name: "ТЗНП ВН ст.1.срабатывание",
							linkedId: "HVNEUTPTOC1.Op"
						},
						{
							name: "ТЗНП ВН ст.2.пуск",
							linkedId: "HVNEUTPTOC2.Str"
						},
						{
							name: "ТЗНП ВН ст.2.срабатывание",
							linkedId: "HVNEUTPTOC2.Op"
						}
					]
				},
				{
					name: "ТЗНП СН",
					nodes: [
						{
							name: "ТЗНП СН ст.1.пуск",
							linkedId: "MVNEUTPTOC1.Str"
						},
						{
							name: "ТЗНП СН ст.1.срабатывание",
							linkedId: "MVNEUTPTOC1.Op"
						},
						{
							name: "ТЗНП СН ст.2.пуск",
							linkedId: "MVNEUTPTOC2.Str"
						},
						{
							name: "ТЗНП СН ст.2.срабатывание",
							linkedId: "MVNEUTPTOC2.Op"
						}
					]
				},
				{
					name: "ТЗНП НН",
					nodes: [
						{
							name: "ТЗНП НН/U ст.1.пуск",
							linkedId: "LVNEUTPTOC1.Str"
						},
						{
							name: "ТЗНП НН/U ст.1.срабатывание",
							linkedId: "LVNEUTPTOC1.Op"
						},
						{
							name: "ТЗНП НН/U ст.2.пуск",
							linkedId: "LVNEUTPTOC2.Str"
						},
						{
							name: "ТЗНП НН/U ст.2.срабатывание",
							linkedId: "LVNEUTPTOC2.Op"
						},
						{
							name: "ТЗНП НН1/U ст.1.пуск",
							linkedId: "LV1NEUTPTOC1.Str"
						},
						{
							name: "ТЗНП НН1/U ст.1.срабатывание",
							linkedId: "LV1NEUTPTOC1.Op"
						},
						{
							name: "ТЗНП НН1/U ст.2.пуск",
							linkedId: "LV1NEUTPTOC2.Str"
						},
						{
							name: "ТЗНП НН1/U ст.2.срабатывание",
							linkedId: "LV1NEUTPTOC2.Op"
						},
						{
							name: "ТЗНП НН2/U ст.1.пуск",
							linkedId: "LV2NEUTPTOC1.Str"
						},
						{
							name: "ТЗНП НН2/U ст.1.срабатывание",
							linkedId: "LV2NEUTPTOC1.Op"
						},
						{
							name: "ТЗНП НН2/U ст.2.пуск",
							linkedId: "LV2NEUTPTOC2.Str"
						},
						{
							name: "ТЗНП НН2/U ст.2.срабатывание",
							linkedId: "LV2NEUTPTOC2.Op"
						}

					]
				},
				{
					name: "МТЗ (общий сигнал)",
					nodes: [
						{
							name: "МТЗ.пуск",
							linkedId: "PHSTPTOC.Str"
						},
						{
							name: "МТЗ.срабатывание",
							linkedId: "PHSTPTOC.Op"
						},
						{
							name: "МТЗ_АУ.срабатывание",
							linkedId: "AUAPHSTPTOC1.Op"
						}
					]
				},
				{
					name: "МТЗ ВН",
					nodes: [
						{
							name: "МТЗ ВН пуск",
							linkedId: "HVPHSTPTOC.Str"
						},
						{
							name: "МТЗ ВН пуск (фаза А)",
							linkedId: "HVPHSTPTOC.Str.phsA"
						},
						{
							name: "МТЗ ВН пуск (фаза B)",
							linkedId: "HVPHSTPTOC.Str.phsB"
						},
						{
							name: "МТЗ ВН пуск (фаза C)",
							linkedId: "HVPHSTPTOC.Str.phsC"
						},
						{
							name: "МТЗ ВН срабатывание",
							linkedId: "HVPHSTPTOC.Op"
						},
						{
							name: "МТЗ ВН срабатывание (фаза А)",
							linkedId: "HVPHSTPTOC.Op.phsA"
						},
						{
							name: "МТЗ ВН срабатывание (фаза B)",
							linkedId: "HVPHSTPTOC.Op.phsB"
						},
						{
							name: "МТЗ ВН срабатывание (фаза C)",
							linkedId: "HVPHSTPTOC.Op.phsC"
						},
						{
							name: "МТЗ ВН ст.1.пуск",
							linkedId: "HVPHSTPVOC1.Str"
						},
						{
							name: "МТЗ ВН ст.1.пуск (фаза А)",
							linkedId: "HVPHSTPVOC1.Str.phsA"
						},
						{
							name: "МТЗ ВН ст.1.пуск (фаза B)",
							linkedId: "HVPHSTPVOC1.Str.phsB"
						},
						{
							name: "МТЗ ВН ст.1.пуск (фаза C)",
							linkedId: "HVPHSTPVOC1.Str.phsC"
						},
						{
							name: "МТЗ ВН ст.1.срабатывание",
							linkedId: "HVPHSTPVOC1.Op"
						},
						{
							name: "МТЗ ВН ст.1.срабатывание (фаза А)",
							linkedId: "HVPHSTPVOC1.Op.phsA"
						},
						{
							name: "МТЗ ВН ст.1.срабатывание (фаза B)",
							linkedId: "HVPHSTPVOC1.Op.phsB"
						},
						{
							name: "МТЗ ВН ст.1.срабатывание (фаза C)",
							linkedId: "HVPHSTPVOC1.Op.phsC"
						},
						{
							name: "МТЗ ВН ст.2.пуск",
							linkedId: "HVPHSTPVOC2.Str"
						},
						{
							name: "МТЗ ВН ст.2.пуск (фаза А)",
							linkedId: "HVPHSTPVOC2.Str.phsA"
						},
						{
							name: "МТЗ ВН ст.2.пуск (фаза B)",
							linkedId: "HVPHSTPVOC2.Str.phsB"
						},
						{
							name: "МТЗ ВН ст.2.пуск (фаза C)",
							linkedId: "HVPHSTPVOC2.Str.phsC"
						},
						{
							name: "МТЗ ВН ст.2.срабатывание",
							linkedId: "HVPHSTPVOC2.Op"
						},
						{
							name: "МТЗ ВН ст.2.срабатывание (фаза А)",
							linkedId: "HVPHSTPVOC2.Op.phsA"
						},
						{
							name: "МТЗ ВН ст.2.срабатывание (фаза B)",
							linkedId: "HVPHSTPVOC2.Op.phsB"
						},
						{
							name: "МТЗ ВН ст.2.срабатывание (фаза C)",
							linkedId: "HVPHSTPVOC2.Op.phsC"
						}
					]
				},
				{
					name: "МТЗ СН",
					nodes: [
						{
							name: "МТЗ СН пуск",
							linkedId: "MVPHSTPTOC.Str"
						},
						{
							name: "МТЗ СН пуск (фаза А)",
							linkedId: "MVPHSTPTOC.Str.phsA"
						},
						{
							name: "МТЗ СН пуск (фаза B)",
							linkedId: "MVPHSTPTOC.Str.phsB"
						},
						{
							name: "МТЗ СН пуск (фаза C)",
							linkedId: "MVPHSTPTOC.Str.phsC"
						},
						{
							name: "МТЗ СН срабатывание",
							linkedId: "MVPHSTPTOC.Op"
						},
						{
							name: "МТЗ СН срабатывание (фаза А)",
							linkedId: "MVPHSTPTOC.Op.phsA"
						},
						{
							name: "МТЗ СН срабатывание (фаза B)",
							linkedId: "MVPHSTPTOC.Op.phsB"
						},
						{
							name: "МТЗ СН срабатывание (фаза C)",
							linkedId: "MVPHSTPTOC.Op.phsC"
						},
						{
							name: "МТЗ СН ст.1.пуск",
							linkedId: "MVPHSTPVOC1.Str"
						},
						{
							name: "МТЗ СН ст.1.пуск (фаза А)",
							linkedId: "MVPHSTPVOC1.Str.phsA"
						},
						{
							name: "МТЗ СН ст.1.пуск (фаза B)",
							linkedId: "MVPHSTPVOC1.Str.phsB"
						},
						{
							name: "МТЗ СН ст.1.пуск (фаза C)",
							linkedId: "MVPHSTPVOC1.Str.phsC"
						},
						{
							name: "МТЗ СН ст.1.срабатывание",
							linkedId: "MVPHSTPVOC1.Op"
						},
						{
							name: "МТЗ СН ст.1.срабатывание (фаза А)",
							linkedId: "MVPHSTPVOC1.Op.phsA"
						},
						{
							name: "МТЗ СН ст.1.срабатывание (фаза B)",
							linkedId: "MVPHSTPVOC1.Op.phsB"
						},
						{
							name: "МТЗ СН ст.1.срабатывание (фаза C)",
							linkedId: "MVPHSTPVOC1.Op.phsC"
						},
						{
							name: "МТЗ СН ст.2.пуск",
							linkedId: "MVPHSTPVOC2.Str"
						},
						{
							name: "МТЗ СН ст.2.пуск (фаза А)",
							linkedId: "MVPHSTPVOC2.Str.phsA"
						},
						{
							name: "МТЗ СН ст.2.пуск (фаза B)",
							linkedId: "MVPHSTPVOC2.Str.phsB"
						},
						{
							name: "МТЗ СН ст.2.пуск (фаза C)",
							linkedId: "MVPHSTPVOC2.Str.phsC"
						},
						{
							name: "МТЗ СН ст.2.срабатывание",
							linkedId: "MVPHSTPVOC2.Op"
						},
						{
							name: "МТЗ СН ст.2.срабатывание (фаза А)",
							linkedId: "MVPHSTPVOC2.Op.phsA"
						},
						{
							name: "МТЗ СН ст.2.срабатывание (фаза B)",
							linkedId: "MVPHSTPVOC2.Op.phsB"
						},
						{
							name: "МТЗ СН ст.2.срабатывание (фаза C)",
							linkedId: "MVPHSTPVOC2.Op.phsC"
						}
					]
				},
				{
					name: "МТЗ НН",
					nodes: [
						{
							name: "МТЗ НН/U пуск",
							linkedId: "LVPHSTPVOC.Str"
						},
						{
							name: "МТЗ НН/U срабатывание",
							linkedId: "LVPHSTPVOC.Op"
						},
						{
							name: "МТЗ НН/U ст.1.пуск",
							linkedId: "LVPHSTPVOC1.Str"
						},
						{
							name: "МТЗ НН/U ст.1.пуск (фаза А)",
							linkedId: "LVPHSTPVOC1.Str.phsA"
						},
						{
							name: "МТЗ НН/U ст.1.пуск (фаза B)",
							linkedId: "LVPHSTPVOC1.Str.phsB"
						},
						{
							name: "МТЗ НН/U ст.1.пуск (фаза C)",
							linkedId: "LVPHSTPVOC1.Str.phsC"
						},
						{
							name: "МТЗ НН/U ст.2.пуск",
							linkedId: "LVPHSTPVOC2.Str"
						},
						{
							name: "МТЗ НН/U ст.2.пуск (фаза А)",
							linkedId: "LVPHSTPVOC2.Str.phsA"
						},
						{
							name: "МТЗ НН/U ст.2.пуск (фаза B)",
							linkedId: "LVPHSTPVOC2.Str.phsB"
						},
						{
							name: "МТЗ НН/U ст.2.пуск (фаза C)",
							linkedId: "LVPHSTPVOC2.Str.phsC"
						},
						{
							name: "МТЗ НН1/U ст.1.пуск",
							linkedId: "LV1PHSTPVOC1.Str"
						},
						{
							name: "МТЗ НН1/U ст.1.пуск (фаза А)",
							linkedId: "LV1PHSTPVOC1.Str.phsA"
						},
						{
							name: "МТЗ НН1/U ст.1.пуск (фаза B)",
							linkedId: "LV1PHSTPVOC1.Str.phsB"
						},
						{
							name: "МТЗ НН1/U ст.1.пуск (фаза C)",
							linkedId: "LV1PHSTPVOC1.Str.phsC"
						},
						{
							name: "МТЗ НН1/U ст.2.пуск",
							linkedId: "LV1PHSTPVOC2.Str"
						},
						{
							name: "МТЗ НН1/U ст.2.пуск (фаза А)",
							linkedId: "LV1PHSTPVOC2.Str.phsA"
						},
						{
							name: "МТЗ НН1/U ст.2.пуск (фаза B)",
							linkedId: "LV1PHSTPVOC2.Str.phsB"
						},
						{
							name: "МТЗ НН1/U ст.2.пуск (фаза C)",
							linkedId: "LV1PHSTPVOC2.Str.phsC"
						},
						{
							name: "МТЗ НН2/U ст.1.пуск",
							linkedId: "LV2PHSTPVOC1.Str"
						},
						{
							name: "МТЗ НН2/U ст.1.пуск (фаза А)",
							linkedId: "LV2PHSTPVOC1.Str.phsA"
						},
						{
							name: "МТЗ НН2/U ст.1.пуск (фаза B)",
							linkedId: "LV2PHSTPVOC1.Str.phsB"
						},
						{
							name: "МТЗ НН2/U ст.1.пуск (фаза C)",
							linkedId: "LV2PHSTPVOC1.Str.phsC"
						},

						{
							name: "МТЗ НН2/U ст.2.пуск",
							linkedId: "LV2PHSTPVOC2.Str"
						},
						{
							name: "МТЗ НН2/U ст.2.пуск (фаза А)",
							linkedId: "LV2PHSTPVOC2.Str.phsA"
						},
						{
							name: "МТЗ НН2/U ст.2.пуск (фаза B)",
							linkedId: "LV2PHSTPVOC2.Str.phsB"
						},
						{
							name: "МТЗ НН2/U ст.2.пуск (фаза C)",
							linkedId: "LV2PHSTPVOC2.Str.phsC"
						},

						{
							name: "МТЗ НН/U ст.1.срабатывание",
							linkedId: "LVPHSTPVOC1.Op"
						},
						{
							name: "МТЗ НН/U ст.1.срабатывание (фаза А)",
							linkedId: "LVPHSTPVOC1.Op.phsA"
						},
						{
							name: "МТЗ НН/U ст.1.срабатывание (фаза B)",
							linkedId: "LVPHSTPVOC1.Op.phsB"
						},
						{
							name: "МТЗ НН/U ст.1.срабатывание (фаза C)",
							linkedId: "LVPHSTPVOC1.Op.phsC"
						},
						{
							name: "МТЗ НН/U ст.2.срабатывание",
							linkedId: "LVPHSTPVOC2.Op"
						},
						{
							name: "МТЗ НН/U ст.2.срабатывание (фаза А)",
							linkedId: "LVPHSTPVOC2.Op.phsA"
						},
						{
							name: "МТЗ НН/U ст.2.срабатывание (фаза B)",
							linkedId: "LVPHSTPVOC2.Op.phsB"
						},
						{
							name: "МТЗ НН/U ст.2.срабатывание (фаза C)",
							linkedId: "LVPHSTPVOC2.Op.phsC"
						},
						{
							name: "МТЗ НН1/U ст.1.срабатывание",
							linkedId: "LV1PHSTPVOC1.Op"
						},
						{
							name: "МТЗ НН1/U ст.1.срабатывание (фаза А)",
							linkedId: "LV1PHSTPVOC1.Op.phsA"
						},
						{
							name: "МТЗ НН1/U ст.1.срабатывание (фаза B)",
							linkedId: "LV1PHSTPVOC1.Op.phsB"
						},
						{
							name: "МТЗ НН1/U ст.1.срабатывание (фаза C)",
							linkedId: "LV1PHSTPVOC1.Op.phsC"
						},
						{
							name: "МТЗ НН1/U ст.2.срабатывание",
							linkedId: "LV1PHSTPVOC2.Op"
						},
						{
							name: "МТЗ НН1/U ст.2.срабатывание (фаза А)",
							linkedId: "LV1PHSTPVOC2.Op.phsA"
						},
						{
							name: "МТЗ НН1/U ст.2.срабатывание (фаза B)",
							linkedId: "LV1PHSTPVOC2.Op.phsB"
						},
						{
							name: "МТЗ НН1/U ст.2.срабатывание (фаза C)",
							linkedId: "LV1PHSTPVOC2.Op.phsC"
						},
						{
							name: "МТЗ НН2/U ст.1.срабатывание",
							linkedId: "LV2PHSTPVOC1.Op"
						},
						{
							name: "МТЗ НН2/U ст.1.срабатывание (фаза А)",
							linkedId: "LV2PHSTPVOC1.Op.phsA"
						},
						{
							name: "МТЗ НН2/U ст.1.срабатывание (фаза B)",
							linkedId: "LV2PHSTPVOC1.Op.phsB"
						},
						{
							name: "МТЗ НН2/U ст.1.срабатывание (фаза C)",
							linkedId: "LV2PHSTPVOC1.Op.phsC"
						},

						{
							name: "МТЗ НН2/U ст.2.срабатывание",
							linkedId: "LV2PHSTPVOC2.Op"
						},
						{
							name: "МТЗ НН2/U ст.2.срабатывание (фаза А)",
							linkedId: "LV2PHSTPVOC2.Op.phsA"
						},
						{
							name: "МТЗ НН2/U ст.2.срабатывание (фаза B)",
							linkedId: "LV2PHSTPVOC2.Op.phsB"
						},
						{
							name: "МТЗ НН2/U ст.2.срабатывание (фаза C)",
							linkedId: "LV2PHSTPVOC2.Op.phsC"
						}


					]
				},
				{
					name: "Технологические защиты",
					nodes: [
						{
							name: "ТЗ.пуск",
							linkedId: "TECHPTRC1.Str"
						},
						{
							name: "ТЗ.срабатывание",
							linkedId: "TECHPTRC1.Op"
						},
						{
							name: "Аварийная температура масла АТ (срабатывание)",
							linkedId: "OTOSTMP.Trip"
						},
						{
							name: "Аварийная температура масла АТ ф.А (срабатывание)",
							linkedId: "OTOSTMP1.Trip.phsA"
						},
						{
							name: "Аварийная температура масла АТ ф.B (срабатывание)",
							linkedId: "OTOSTMP2.Trip.phsB"
						},
						{
							name: "Аварийная температура масла АТ ф.C (срабатывание)",
							linkedId: "OTOSTMP3.Trip.phsC"
						},
						{
							name: "Повышение температуры масла АТ (на сигнал)",
							linkedId: "OTOSTMP.Alm"
						},
						{
							name: "Повышение температуры масла АТ  ф.А (на сигнал)",
							linkedId: "OTOSTMP1.Alm.phsA"
						},
						{
							name: "Повышение температуры масла АТ  ф.B (на сигнал)",
							linkedId: "OTOSTMP2.Alm.phsB"
						},
						{
							name: "Повышение температуры масла АТ  ф.C (на сигнал)",
							linkedId: "OTOSTMP3.Alm.phsC"
						},
						{
							name: "Отказ системы охлаждения на откл.",
							linkedId: "CCGR.PmpAlm"
						},
						{
							name: "Отказ системы охлаждения ф.А на откл.",
							linkedId: "CCGR1.PmpAlm.phsA"
						},
						{
							name: "Отказ системы охлаждения ф.B на откл.",
							linkedId: "CCGR2.PmpAlm.phsB"
						},
						{
							name: "Отказ системы охлаждения ф.C на откл.",
							linkedId: "CCGR3.PmpAlm.phsC"
						}
					]
				},
				{
					name: "Газовая защита",
					nodes: [
						{
							name: "ГЗ Т(АТ). отключение",
							linkedId: "GASTPTRC1.Op"
						},
						{
							name: "ГЗ РПН. отключение",
							linkedId: "GASLPTRC.Op"
						},
						{
							name: "ГЗ РПН. отключение (фаза А)",
							linkedId: "GASLPTRC1.Op.phsA"
						},
						{
							name: "ГЗ РПН. отключение (фаза B)",
							linkedId: "GASLPTRC2.Op.phsB"
						},
						{
							name: "ГЗ РПН. отключение (фаза C)",
							linkedId: "GASLPTRC3.Op.phsC"
						},
						{
							name: "ГЗ ЛРТ. отключение",
							linkedId: "GASVPTRC1.Op"
						},
						{
							name: "Срабатывание газового реле на сигнал",
							linkedId: "GASTSIML.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле ф.А на сигнал",
							linkedId: "GASTSIML1.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле ф.В на сигнал",
							linkedId: "GASTSIML2.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле ф.C на сигнал",
							linkedId: "GASTSIML3.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле на откл.",
							linkedId: "GASTSIML.GasInsTr"
						},
						{
							name: "Срабатывание газового реле ф.А на откл.",
							linkedId: "GASTSIML1.GasInsTr"
						},
						{
							name: "Срабатывание газового реле ф.В на откл.",
							linkedId: "GASTSIML2.GasInsTr"
						},
						{
							name: "Срабатывание газового реле ф.С на откл.",
							linkedId: "GASTSIML3.GasInsTr"
						}
					]
				},
				{
					name: "ЗП (защита от перегрузки)",
					nodes: [
						{
							name: "ЗП ВН.пуск",
							linkedId: "OVCTPTOC1.Str"
						},
						{
							name: "ЗП ВН. отключение",
							linkedId: "OVCTPTOC1.Op"
						},
						{
							name: "ЗП СН(НН). пуск",
							linkedId: "OVCTPTOC2.Str"
						},
						{
							name: "ЗП СН(НН). отключение",
							linkedId: "OVCTPTOC2.Op"
						},
						{
							name: "ЗП НН. пуск",
							linkedId: "OVCTPTOC3.Str"
						},
						{
							name: "ЗП НН. отключение",
							linkedId: "OVCTPTOC3.Op"
						}

					]
				}
			]
		},
		{
			name: "Шунтирующий реактор",
			children: [
				{
					name: "ДЗР",
					nodes: [
						{
							name: "Срабатывание ДЗР",
							linkedId: "TIMTPDIF.Op"
						},
						{
							name: "Срабатывание ДЗР (фаза A)",
							linkedId: "TIMTPDIF.Op.phsA"
						},
						{
							name: "Срабатывание ДЗР (фаза B)",
							linkedId: "TIMTPDIF.Op.phsB"
						},
						{
							name: "Срабатывание ДЗР (фаза C)",
							linkedId: "TIMTPDIF.Op.phsC"
						},
						{
							name: "Срабатывание ДЗР 1ст.",
							linkedId: "TIMTPDIF1.Op"
						},
						{
							name: "Срабатывание ДЗР 2ст.",
							linkedId: "TIMTPDIF2.Op"
						},
						{
							name: "Срабатывание ДЗР 1ст.(фаза A)",
							linkedId: "TIMTPDIF1.Op.phsA"
						},
						{
							name: "Срабатывание ДЗР 1ст.(фаза B)",
							linkedId: "TIMTPDIF1.Op.phsB"
						},
						{
							name: "Срабатывание ДЗР 1ст.(фаза C)",
							linkedId: "TIMTPDIF1.Op.phsC"
						},
						{
							name: "Срабатывание ДЗР 2ст.(фаза A)",
							linkedId: "TIMTPDIF2.Op.phsA"
						},
						{
							name: "Срабатывание ДЗР 2ст.(фаза B)",
							linkedId: "TIMTPDIF2.Op.phsB"
						},
						{
							name: "Срабатывание ДЗР 2ст.(фаза C)",
							linkedId: "TIMTPDIF2.Op.phsC"
						}
					]
				},
				{
					name: "ПДЗР",
					nodes: [
						{
							name: "Срабатывание ПДЗР",
							linkedId: "TPDIF.Op"
						},
						{
							name: "Срабатывание ПДЗР (фаза A)",
							linkedId: "TPDIF1.Op.phsA"
						},
						{
							name: "Срабатывание ПДЗР (фаза B)",
							linkedId: "TPDIF1.Op.phsB"
						},
						{
							name: "Срабатывание ПДЗР (фаза C)",
							linkedId: "TPDIF1.Op.phsC"
						},
						{
							name: "Срабатывание ПДЗР 1ст.",
							linkedId: "TPDIF1.Op"
						},
						{
							name: "Срабатывание ПДЗР 2ст.",
							linkedId: "TPDIF2.Op"
						},
						{
							name: "Срабатывание ПДЗР 1ст.(фаза A)",
							linkedId: "TPDIF1.Op.phsA"
						},
						{
							name: "Срабатывание ПДЗР 1ст.(фаза B)",
							linkedId: "TPDIF1.Op.phsB"
						},
						{
							name: "Срабатывание ПДЗР 1ст.(фаза C)",
							linkedId: "TPDIF1.Op.phsC"
						},
						{
							name: "Срабатывание ПДЗР 2ст.(фаза A)",
							linkedId: "TPDIF2.Op.phsA"
						},
						{
							name: "Срабатывание ПДЗР 2ст.(фаза B)",
							linkedId: "TPDIF2.Op.phsB"
						},
						{
							name: "Срабатывание ПДЗР 2ст.(фаза C)",
							linkedId: "TPDIF2.Op.phsC"
						},
					]
				},
				{
					name: "МТЗ",
					nodes: [
						{
							name: "МТЗ.пуск",
							linkedId: "PHSTPTOC.Str"
						},
						{
							name: "МТЗ.срабатывание",
							linkedId: "PHSTPTOC.Op"
						},
						{
							name: "МТЗ ст.1.пуск",
							linkedId: "PHSTPTOC1.Str"
						},
						{
							name: "МТЗ ст.1.срабатывание",
							linkedId: "PHSTPTOC1.Op"
						},
						{
							name: "МТЗ ст.2.пуск",
							linkedId: "PHSTPTOC2.Str"
						},
						{
							name: "МТЗ ст.2.срабатывание",
							linkedId: "PHSTPTOC2.Op"
						}
					]
				},
				{
					name: "ТНЗНП (общие сигналы)",
					nodes: [
						{
							name: "ТНЗНП.пуск",
							linkedId: "ZERTPTOC.Str"
						},
						{
							name: "ТНЗНП.срабатывание",
							linkedId: "ZERTPTOC.Op"
						}
					]
				},
				{
					name: "ТНЗНП ВН",
					nodes: [

						{
							name: "ТНЗНПВН ст.1.пуск",
							linkedId: "ZERTPTOC1.Str"
						},
						{
							name: "ТНЗНП ВН ст.1.срабатывание",
							linkedId: "ZERTPTOC1.Op"
						},
						{
							name: "ТНЗНП ВН ст.2.пуск",
							linkedId: "ZERTPTOC2.Str"
						},
						{
							name: "ТНЗНП ВН ст.2.срабатывание",
							linkedId: "ZERTPTOC2.Op"
						}
					]
				},
				{
					name: "ТНЗНП НВ",
					nodes: [

						{
							name: "ТНЗНП НВ ст.1.пуск",
							linkedId: "ZERTPTOC3.Str"
						},
						{
							name: "ТНЗНП НВ ст.1.срабатывание",
							linkedId: "ZERTPTOC3.Op"
						},
						{
							name: "ТНЗНП НВ ст.2.пуск",
							linkedId: "ZERTPTOC4.Str"
						},
						{
							name: "ТНЗНП НВ ст.2.срабатывание",
							linkedId: "ZERTPTOC4.Op"
						}
					]
				},
				{
					name: "ТЗОП",
					nodes: [

						{
							name: "ТЗОП.пуск",
							linkedId: "NEGTPTOC.Str"
						},
						{
							name: "ТЗОП.срабатывание",
							linkedId: "NEGTPTOC.Op"
						}
					]
				},
				{
					name: "Технологические защиты",
					nodes: [
						{
							name: "ТЗ.пуск",
							linkedId: "TECHPTRC1.Str"
						},
						{
							name: "ТЗ.срабатывание",
							linkedId: "TECHPTRC1.Tr"
						},
						{
							name: "Аварийная температура масла АТ (срабатывание)",
							linkedId: "OTOSTMP.Trip"
						},
						{
							name: "Аварийная температура масла АТ ф.А (срабатывание)",
							linkedId: "OTOSTMP1.Trip.phsA"
						},
						{
							name: "Аварийная температура масла АТ ф.B (срабатывание)",
							linkedId: "OTOSTMP2.Trip.phsB"
						},
						{
							name: "Аварийная температура масла АТ ф.C (срабатывание)",
							linkedId: "OTOSTMP3.Trip.phsC"
						},
						{
							name: "Повышение температуры масла АТ (на сигнал)",
							linkedId: "OTOSTMP.Alm"
						},
						{
							name: "Повышение температуры масла АТ  ф.А (на сигнал)",
							linkedId: "OTOSTMP1.Alm.phsA"
						},
						{
							name: "Повышение температуры масла АТ  ф.B (на сигнал)",
							linkedId: "OTOSTMP2.Alm.phsB"
						},
						{
							name: "Повышение температуры масла АТ  ф.C (на сигнал)",
							linkedId: "OTOSTMP3.Alm.phsC"
						},
						{
							name: "Отказ системы охлаждения на откл.",
							linkedId: "CCGR.PmpAlm"
						},
						{
							name: "Отказ системы охлаждения ф.А на откл.",
							linkedId: "CCGR1.PmpAlm.phsA"
						},
						{
							name: "Отказ системы охлаждения ф.B на откл.",
							linkedId: "CCGR2.PmpAlm.phsB"
						},
						{
							name: "Отказ системы охлаждения ф.C на откл.",
							linkedId: "CCGR3.PmpAlm.phsC"
						}
					]
				},
				{
					name: "Газовая защита",
					nodes: [
						{
							name: "ГЗ реактора. отключение",
							linkedId: "GASTPTRC1.Tr"
						},
						{
							name: "Срабатывание газового реле на сигнал",
							linkedId: "GASTSIML.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле ф.А на сигнал",
							linkedId: "GASTSIML1.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле ф.В на сигнал",
							linkedId: "GASTSIML2.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле ф.C на сигнал",
							linkedId: "GASTSIML3.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле на откл.",
							linkedId: "GASTSIML.GasInsTr"
						},
						{
							name: "Срабатывание газового реле ф.А на откл.",
							linkedId: "GASTSIML1.GasInsTr"
						},
						{
							name: "Срабатывание газового реле ф.В на откл.",
							linkedId: "GASTSIML2.GasInsTr"
						},
						{
							name: "Срабатывание газового реле ф.С на откл.",
							linkedId: "GASTSIML3.GasInsTr"
						}
					]
				},
				{
					name: "УРОВ",
					nodes: [
						{
							name: "Срабатывание УРОВ В1",
							linkedId: "Q1TRBRF1.OpEx"
						},
						{
							name: "Срабатывание УРОВ В2",
							linkedId: "Q2TRBRF1.OpEx"
						}

					]
				}
			]
		},
		{
			name: "Управляемый шунтирующий реактор",
			children: [
				{
					name: "ДЗР",
					nodes: [
						{
							name: "Срабатывание ДЗР",
							linkedId: "TIMTPDIF.Op"
						},
						{
							name: "Пуск ДЗР",
							linkedId: "TIMTPDIF.Str"
						},
						{
							name: "Срабатывание ДЗР (фаза A)",
							linkedId: "TIMTPDIF1.Op.phsA"
						},
						{
							name: "Срабатывание ДЗР (фаза B)",
							linkedId: "TIMTPDIF1.Op.phsB"
						},
						{
							name: "Срабатывание ДЗР (фаза C)",
							linkedId: "TIMTPDIF1.Op.phsC"
						},
						{
							name: "Пуск ДЗР (фаза A)",
							linkedId: "TIMTPDIF1.Str.phsA"
						},
						{
							name: "Пуск ДЗР (фаза B)",
							linkedId: "TIMTPDIF1.Str.phsB"
						},
						{
							name: "Пуск ДЗР (фаза C)",
							linkedId: "TIMTPDIF1.Str.phsC"
						},
						{
							name: "Срабатывание ДЗР 1ст.",
							linkedId: "TIMTPDIF1.Op"
						},
						{
							name: "Пуск ДЗР 1ст.",
							linkedId: "TIMTPDIF1.Str"
						},
						{
							name: "Срабатывание ДЗР 2ст.",
							linkedId: "TIMTPDIF2.Op"
						},
						{
							name: "Пуск ДЗР 2ст.",
							linkedId: "TIMTPDIF2.Str"
						},
						{
							name: "Срабатывание ДЗР 1ст.(фаза A)",
							linkedId: "TIMTPDIF1.Op.phsA"
						},
						{
							name: "Пуск ДЗР 1ст.(фаза A)",
							linkedId: "TIMTPDIF1.Str.phsA"
						},
						{
							name: "Срабатывание ДЗР 1ст.(фаза B)",
							linkedId: "TIMTPDIF1.Op.phsB"
						},
						{
							name: "Пуск ДЗР 1ст.(фаза B)",
							linkedId: "TIMTPDIF1.Str.phsB"
						},
						{
							name: "Срабатывание ДЗР 1ст.(фаза C)",
							linkedId: "TIMTPDIF1.Op.phsC"
						},
						{
							name: "Пуск ДЗР 1ст.(фаза C)",
							linkedId: "TIMTPDIF1.Str.phsC"
						},
						{
							name: "Срабатывание ДЗР 2ст.(фаза A)",
							linkedId: "TIMTPDIF2.Op.phsA"
						},
						{
							name: "Пуск ДЗР 2ст.(фаза A)",
							linkedId: "TIMTPDIF2.Str.phsA"
						},
						{
							name: "Срабатывание ДЗР 2ст.(фаза B)",
							linkedId: "TIMTPDIF2.Op.phsB"
						},
						{
							name: "Пуск ДЗР 2ст.(фаза B)",
							linkedId: "TIMTPDIF2.Str.phsB"
						},
						{
							name: "Срабатывание ДЗР 2ст.(фаза C)",
							linkedId: "TIMTPDIF2.Op.phsC"
						},
						{
							name: "Пуск ДЗР 2ст.(фаза C)",
							linkedId: "TIMTPDIF2.Str.phsC"
						}
					]
				},
				{
					name: "ПДЗР",
					nodes: [
						{
							name: "Срабатывание ПДЗР",
							linkedId: "TPDIF.Op"
						},
						{
							name: "Пуск ПДЗР",
							linkedId: "TPDIF.Str"
						},
						{
							name: "Срабатывание ПДЗР (фаза A)",
							linkedId: "TPDIF1.Op.phsA"
						},
						{
							name: "Срабатывание ПДЗР (фаза B)",
							linkedId: "TPDIF1.Op.phsB"
						},
						{
							name: "Срабатывание ПДЗР (фаза C)",
							linkedId: "TPDIF1.Op.phsC"
						},
						{
							name: "Пуск ПДЗР (фаза A)",
							linkedId: "TPDIF1.Str.phsA"
						},
						{
							name: "Пуск ПДЗР (фаза B)",
							linkedId: "TPDIF1.Str.phsB"
						},
						{
							name: "Пуск ПДЗР (фаза C)",
							linkedId: "TPDIF1.Str.phsC"
						},
						{
							name: "Срабатывание ПДЗР 1ст.",
							linkedId: "TPDIF1.Op"
						},
						{
							name: "Пуск ПДЗР 1ст.",
							linkedId: "TPDIF1.Str"
						},
						{
							name: "Срабатывание ПДЗР 2ст.",
							linkedId: "TPDIF2.Op"
						},
						{
							name: "Пуск ПДЗР 2ст.",
							linkedId: "TPDIF2.Str"
						},
						{
							name: "Срабатывание ПДЗР 1ст.(фаза A)",
							linkedId: "TPDIF1.Op.phsA"
						},
						{
							name: "Пуск ПДЗР 1ст.(фаза A)",
							linkedId: "TPDIF1.Str.phsA"
						},
						{
							name: "Срабатывание ПДЗР 1ст.(фаза B)",
							linkedId: "TPDIF1.Op.phsB"
						},
						{
							name: "Пуск ПДЗР 1ст.(фаза B)",
							linkedId: "TPDIF1.Str.phsB"
						},
						{
							name: "Срабатывание ПДЗР 1ст.(фаза C)",
							linkedId: "TPDIF1.Op.phsC"
						},
						{
							name: "Пуск ПДЗР 1ст.(фаза C)",
							linkedId: "TPDIF1.Str.phsC"
						},
						{
							name: "Срабатывание ПДЗР 2ст.(фаза A)",
							linkedId: "TPDIF2.Op.phsA"
						},
						{
							name: "Пуск ПДЗР 2ст.(фаза A)",
							linkedId: "TPDIF2.Str.phsA"
						},
						{
							name: "Срабатывание ПДЗР 2ст.(фаза B)",
							linkedId: "TPDIF2.Op.phsB"
						},
						{
							name: "Пуск ПДЗР 2ст.(фаза B)",
							linkedId: "TPDIF2.Str.phsB"
						},
						{
							name: "Срабатывание ПДЗР 2ст.(фаза C)",
							linkedId: "TPDIF2.Op.phsC"
						},
						{
							name: "Пуск ПДЗР 2ст.(фаза C)",
							linkedId: "TPDIF2.Str.phsC"
						}
					]
				},
				{
					name: "ДЗО",
					nodes: [
						{
							name: "Срабатывание ДЗО",
							linkedId: "RCWTPDIF.Op"
						},
						{
							name: "Пуск ДЗО",
							linkedId: "RCWTPDIF.Str"
						},
						{
							name: "Срабатывание ДЗО (фаза A)",
							linkedId: "RCWTPDIF.Op.phsA"
						},
						{
							name: "Срабатывание ДЗО (фаза B)",
							linkedId: "RCWTPDIF.Op.phsB"
						},
						{
							name: "Срабатывание ДЗО (фаза C)",
							linkedId: "RCWTPDIF.Op.phsC"
						},
						{
							name: "Пуск ДЗО (фаза A)",
							linkedId: "RCWTPDIF.Str.phsA"
						},
						{
							name: "Пуск ДЗО (фаза B)",
							linkedId: "RCWTPDIF.Str.phsB"
						},
						{
							name: "Пуск ДЗО (фаза C)",
							linkedId: "RCWTPDIF.Str.phsC"
						},
						{
							name: "Срабатывание ДЗО 1ст.",
							linkedId: "RCWTPDIF1.Op"
						},
						{
							name: "Пуск ДЗО 1ст.",
							linkedId: "RCWTPDIF1.Str"
						},
						{
							name: "Срабатывание ДЗО 1ст.(фаза A)",
							linkedId: "RCWTPDIF1.Op.phsA"
						},
						{
							name: "Пуск ДЗО 1ст.(фаза A)",
							linkedId: "RCWTPDIF1.Str.phsA"
						},
						{
							name: "Срабатывание ДЗО 1ст.(фаза B)",
							linkedId: "RCWTPDIF1.Op.phsB"
						},
						{
							name: "Пуск ДЗО 1ст.(фаза B)",
							linkedId: "RCWTPDIF1.Str.phsB"
						},
						{
							name: "Срабатывание ДЗО 1ст.(фаза C)",
							linkedId: "RCWTPDIF1.Op.phsC"
						},
						{
							name: "Пуск ДЗО 1ст.(фаза C)",
							linkedId: "RCWTPDIF1.Str.phsC"
						},
						{
							name: "Срабатывание ДЗО 2ст.",
							linkedId: "RCWTPDIF2.Op"
						},
						{
							name: "Пуск ДЗО 2ст.",
							linkedId: "RCWTPDIF2.Str"
						},
						{
							name: "Срабатывание ДЗО 2ст.(фаза A)",
							linkedId: "RCWTPDIF2.Op.phsA"
						},
						{
							name: "Пуск ДЗО 2ст.(фаза A)",
							linkedId: "RCWTPDIF2.Str.phsA"
						},
						{
							name: "Срабатывание ДЗО 2ст.(фаза B)",
							linkedId: "RCWTPDIF2.Op.phsB"
						},
						{
							name: "Пуск ДЗО 2ст.(фаза B)",
							linkedId: "RCWTPDIF2.Str.phsB"
						},
						{
							name: "Срабатывание ДЗО 2ст.(фаза C)",
							linkedId: "RCWTPDIF2.Op.phsC"
						},
						{
							name: "Пуск ДЗО 2ст.(фаза C)",
							linkedId: "RCWTPDIF2.Str.phsC"
						}
					]
				},
				{
					name: "МТЗ КОР",
					nodes: [
						{
							name: "МТЗ КОР.пуск",
							linkedId: "RCWTPTOC.Str"
						},
						{
							name: "МТЗ КОР.срабатывание",
							linkedId: "RCWTPTOC.Op"
						},
						{
							name: "МТЗ КОР ст.1.пуск",
							linkedId: "RCWTPTOC1.Str"
						},
						{
							name: "МТЗ КОР ст.1.срабатывание",
							linkedId: "RCWTPTOC1.Op"
						},
						{
							name: "МТЗ КОР ст.2.пуск",
							linkedId: "RCWTPTOC2.Str"
						},
						{
							name: "МТЗ КОР ст.2.срабатывание",
							linkedId: "RCWTPTOC2.Op"
						}
					]
				},
				{
					name: "МТЗ НН",
					nodes: [
						{
							name: "МТЗ НН.пуск",
							linkedId: "PHSTPTOC.Str"
						},
						{
							name: "МТЗ НН.срабатывание",
							linkedId: "PHSTPTOC.Op"
						},
						{
							name: "МТЗ НН ст.1.пуск",
							linkedId: "PHSTPTOC1.Str"
						},
						{
							name: "МТЗ НН ст.1.срабатывание",
							linkedId: "PHSTPTOC1.Op"
						},
						{
							name: "МТЗ НН ст.2.пуск",
							linkedId: "PHSTPTOC2.Str"
						},
						{
							name: "МТЗ НН ст.2.срабатывание",
							linkedId: "PHSTPTOC2.Op"
						}
					]
				},
				{
					name: "МТЗ ОУР",
					nodes: [
						{
							name: "МТЗ ОУР.пуск",
							linkedId: "PHSPTOC1.Str"
						},
						{
							name: "МТЗ ОУР.срабатывание",
							linkedId: "PHSPTOC1.Op"
						}
					]
				},
				{
					name: "ТЗНП КОР",
					nodes: [
						{
							name: "ТНЗНП КОР.пуск",
							linkedId: "ZERTPTOC1.Str"
						},
						{
							name: "ТНЗНП КОР.срабатывание",
							linkedId: "ZERTPTOC1.Op"
						}
					]
				},

				{
					name: "ТЗОП",
					nodes: [

						{
							name: "ТЗОП.пуск",
							linkedId: "NEGTPTOC1.Str"
						},
						{
							name: "ТЗОП.срабатывание",
							linkedId: "NEGTPTOC1.Op"
						}
					]
				},
				{
					name: "Технологические защиты",
					nodes: [
						{
							name: "ТЗ.пуск",
							linkedId: "TECHPTRC1.Str"
						},
						{
							name: "ТЗ.срабатывание",
							linkedId: "TECHPTRC1.Tr"
						},
						{
							name: "Аварийная температура масла АТ (срабатывание)",
							linkedId: "OTOSTMP.Trip"
						},
						{
							name: "Аварийная температура масла АТ ф.А (срабатывание)",
							linkedId: "OTOSTMP1.Trip.phsA"
						},
						{
							name: "Аварийная температура масла АТ ф.B (срабатывание)",
							linkedId: "OTOSTMP2.Trip.phsB"
						},
						{
							name: "Аварийная температура масла АТ ф.C (срабатывание)",
							linkedId: "OTOSTMP3.Trip.phsC"
						},
						{
							name: "Повышение температуры масла АТ (на сигнал)",
							linkedId: "OTOSTMP.Alm"
						},
						{
							name: "Повышение температуры масла АТ  ф.А (на сигнал)",
							linkedId: "OTOSTMP1.Alm.phsA"
						},
						{
							name: "Повышение температуры масла АТ  ф.B (на сигнал)",
							linkedId: "OTOSTMP2.Alm.phsB"
						},
						{
							name: "Повышение температуры масла АТ  ф.C (на сигнал)",
							linkedId: "OTOSTMP3.Alm.phsC"
						},
						{
							name: "Отказ системы охлаждения на откл.",
							linkedId: "CCGR.PmpAlm"
						},
						{
							name: "Отказ системы охлаждения ф.А на откл.",
							linkedId: "CCGR1.PmpAlm.phsA"
						},
						{
							name: "Отказ системы охлаждения ф.B на откл.",
							linkedId: "CCGR2.PmpAlm.phsB"
						},
						{
							name: "Отказ системы охлаждения ф.C на откл.",
							linkedId: "CCGR3.PmpAlm.phsC"
						}
					]
				},
				{
					name: "Газовая защита",
					nodes: [
						{
							name: "ГЗ реактора. отключение",
							linkedId: "GASTPTRC1.Tr"
						},
						{
							name: "Срабатывание газового реле на сигнал",
							linkedId: "GASTSIML.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле ф.А на сигнал",
							linkedId: "GASTSIML1.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле ф.В на сигнал",
							linkedId: "GASTSIML2.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле ф.C на сигнал",
							linkedId: "GASTSIML3.GasInsAlm"
						},
						{
							name: "Срабатывание газового реле на откл.",
							linkedId: "GASTSIML.GasInsTr"
						},
						{
							name: "Срабатывание газового реле ф.А на откл.",
							linkedId: "GASTSIML1.GasInsTr"
						},
						{
							name: "Срабатывание газового реле ф.В на откл.",
							linkedId: "GASTSIML2.GasInsTr"
						},
						{
							name: "Срабатывание газового реле ф.С на откл.",
							linkedId: "GASTSIML3.GasInsTr"
						}
					]
				},
				{
					name: "УРОВ",
					nodes: [
						{
							name: "Срабатывание УРОВ В1",
							linkedId: "Q1TRBRF1.OpEx"
						},
						{
							name: "Срабатывание УРОВ В2",
							linkedId: "Q2TRBRF1.OpEx"
						}

					]
				}
			]
		},
		{
			name: "Сборные шины",
			children: [

				{
					name: "ДЗШ",
					nodes: [
						{
							name: "Срабатывание ДЗШ 1 СШ.",
							linkedId: "TIMTPDIF1.Op"
						},
						{
							name: "Срабатывание ДЗШ 2 СШ.",
							linkedId: "TIMTPDIF2.Op"
						}
					]
				},
				{
					name: "ДЗО",
					nodes: [
						{
							name: "Срабатывание ДЗО",
							linkedId: "TIMBTPDIF1.Op"
						},
						{
							name: "Срабатывание ДЗО (фаза A)",
							linkedId: "TIMBTPDIF1.Op.phsA"
						},
						{
							name: "Срабатывание ДЗО (фаза B)",
							linkedId: "TIMBTPDIF1.Op.phsB"
						},
						{
							name: "Срабатывание ДЗО (фаза C)",
							linkedId: "TIMBTPDIF1.Op.phsC"
						}
					]
				},
				{
					name: "УРОВ",
					nodes: [
						{
							name: "Срабатывание УРОВ В1",
							linkedId: "TRBRF1.OpEx"
						}
					]
				}
			]
		},
		{
			name: "Общие сигналы",
			children: [
				{
					name: "Блокировки",
					nodes: [


					]
				},
				{
					name: "Общие сигналы",
					nodes: [
						{
							name: "Срабатывание основных защит",
							linkedId: "PRIMPTRC1.Op"
						},
						{
							name: "Пуск основных защит",
							linkedId: "PRIMPTRC1.Str"
						},
						{
							name: "Срабатывание резервных защит",
							linkedId: "SCNDPTRC1.Op"
						},
						{
							name: "КЦН.неисправность",
							linkedId: "DISTSVTR1.BlkOp"
						},
						{
							name: "Неисправность общая",
							linkedId: "LLN0.Health"
						},
						{
							name: "Состояние аппаратной части ИЭУ",
							linkedId: "LPHD1.PhyHealth"
						},
						{
							name: "Состояние АЦП модулей ввода аналоговых сигналов",
							linkedId: "LPHD1.AdcFail"
						},
						{
							name: "Состояние модулей аналоговых входов",
							linkedId: "LPHD1.AIunitSt"
						},
						{
							name: "Состояние модулей дискретных входов /релейных выходов",
							linkedId: "LPHD1.DIOunitSt"
						},
						{
							name: "Состояние измерительных цепей",
							linkedId: "MMXU.Health"
						},
						{
							name: "В1 аварийно отключен",
							linkedId: "Q1XCBR1.AlmOpn"
						},
						{
							name: "В2 аварийно отключен",
							linkedId: "Q2XCBR1.AlmOpn"
						},
						{
							name: "Неисправность ЦУ В1",
							linkedId: "Q1OUTSOCC1.OCAlm "
						},
						{
							name: "Неисправность ЦУ В2",
							linkedId: "Q2OUTSOCC1.OCAlm"
						},
						{
							name: "РПО В1 (3ф)",
							linkedId: "Q1XCBR1.Pos.stVal.Off"
						},
						{
							name: "РПО В1 (фаза A)",
							linkedId: "Q1XCBR1.Pos.stVal.Off"
						},
						{
							name: "РПО В1 (фаза B)",
							linkedId: "Q1XCBR2.Pos.stVal.Off"
						},
						{
							name: "РПО В1 (фаза C)",
							linkedId: "Q1XCBR3.Pos.stVal.Off"
						},
						{
							name: "РПВ В1 (3ф)",
							linkedId: "Q1XCBR1.Pos.stVal.On"
						},
						{
							name: "РПВ В1 (фаза A)",
							linkedId: "Q1XCBR1.Pos.stVal.On"
						},
						{
							name: "РПВ В1 (фаза B)",
							linkedId: "Q1XCBR2.Pos.stVal.On"
						},
						{
							name: "РПВ В1 (фаза C)",
							linkedId: "Q1XCBR3.Pos.stVal.On"
						},
						{
							name: "РПО В2 (3ф)",
							linkedId: "Q2XCBR1.Pos.stVal.Off"
						},
						{
							name: "РПО В2 (фаза A)",
							linkedId: "Q2XCBR1.Pos.stVal.Off"
						},
						{
							name: "РПО В2 (фаза B)",
							linkedId: "Q2XCBR2.Pos.stVal.Off"
						},
						{
							name: "РПО В2 (фаза C)",
							linkedId: "Q2XCBR3.Pos.stVal.Off"
						},
						{
							name: "РПВ В2 (3ф)",
							linkedId: "Q2XCBR1.Pos.stVal.On"
						},
						{
							name: "РПВ В2 (фаза A)",
							linkedId: "Q2XCBR1.Pos.stVal.On"
						},
						{
							name: "РПВ В2 (фаза B)",
							linkedId: "Q2XCBR2.Pos.stVal.On"
						},
						{
							name: "РПВ В2 (фаза C)",
							linkedId: "Q2XCBR3.Pos.stVal.On"
						},
						{
							name: "РПО В3 (3ф)",
							linkedId: "Q3XCBR1.Pos.stVal.Off"
						},
						{
							name: "РПО В3 (фаза A)",
							linkedId: "Q3XCBR1.Pos.stVal.Off"
						},
						{
							name: "РПО В3 (фаза B)",
							linkedId: "Q3XCBR2.Pos.stVal.Off"
						},
						{
							name: "РПО В3 (фаза C)",
							linkedId: "Q3XCBR3.Pos.stVal.Off"
						},
						{
							name: "РПВ В3 (3ф)",
							linkedId: "Q3XCBR1.Pos.stVal.On"
						},
						{
							name: "РПВ В3 (фаза A)",
							linkedId: "Q3XCBR1.Pos.stVal.On"
						},
						{
							name: "РПВ В3 (фаза B)",
							linkedId: "Q3XCBR2.Pos.stVal.On"
						},
						{
							name: "РПВ В3 (фаза C)",
							linkedId: "Q3XCBR3.Pos.stVal.On"
						}
					]
				}
			]
		}
	]
}