import React from 'react';
import { FormattedMessage } from 'react-intl';

import SideBar from '../finder/sidebar.jsx';
import CimFiller from '../filler/cimfiller.jsx';

const styleFirstRow = {
    marginTop: "16px"
};

const styleFoundRef = {
    paddingLeft: "16px",
    marginBottom: "16px"
};

const allHeightStyle = { height: "100%" };

class TreeSearchPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleKeyPress(event) {
        if (event.charCode == 13) {
            this.handleSearch(event);
        }
    }

    handleSearch(event) {
        if (this.disabled) { //block is disabled
            return;
        }
        let request = {
            text: this.state.value
        };
        this.props.search(request);
    }

    getObjectUrl(item) {
        if (!item.rdfId) {
            return null;
        }
        let params = { object: item.rdfId };
        if (item.namespace) {
            params.namespace = item.namespace;
        }
        return `${this.props.page}#?${$.param(params)}`;
    }

    getFoundRef(item) {
        const ref = this.getObjectUrl(item);
        if (ref) {
            return (<a href={ref}>{item.label}</a>);
        }
        return item.label;
    }

    getPath(path) {
        let _this = this;
        return (<ul className="breadcrumb">
            {path.map(function (item, idx) {
                return (<li key={idx}>{_this.getFoundRef(item)}</li>);
            })}
        </ul>);
    }

    getOneResult(entry, first) {
        let path = [].concat(entry.path);
        let found = path.pop();
        let optional = {};
        if (first) {
            optional.style = styleFirstRow;
        }
        return (<div key={found.id} {...optional}>
            <div className="col-md-12 nopadding">
                {this.getPath(path)}
                <div style={styleFoundRef}>
                    {this.getFoundRef(found)}
                </div>
            </div>
        </div>)
    }

    getStatusRow(icon, message) {
        return (<div style={styleFirstRow}>
            <div className="col-md-4 col-md-offset-4">
                <i className={"fa fa-3x fa-fw " + icon}></i>
                {message}
            </div>
        </div>)
    }

    getResults() {
        if (this.props.loading) {
            return this.getStatusRow("fa-spinner fa-spin", <FormattedMessage
                id="NAVTREE_SEARCH_IN_PROGRESS"
                defaultMessage="Search is in progress. Please wait."
                description="Search is not ready" />);
        }
        if (this.props.error) {
            return this.getStatusRow("fa-exclamation-triangle", <FormattedMessage
                id="NAVTREE_SEARCH_ERROR"
                defaultMessage="Error occured while searching"
                description="Search failed" />);
        }
        if (!this.props.result) {
            return null;
        }
        if (this.props.result.limitExceeds) {
            return this.getStatusRow("fa-exclamation-triangle", <FormattedMessage
                id="NAVTREE_SEARCH_LIMIT_EXCEEDS"
                defaultMessage="Too many results for the specified request. Please refine the query."
                description="Too many results in tree search" />);
        }
        if (this.props.result.entries.length == 0) {
            return this.getStatusRow("fa-info-circle", <FormattedMessage
                id="NAVTREE_SEARCH_EMPTY"
                defaultMessage="No results for the specified request. Please refine the query."
                description="No results in tree search" />);
        }
        let _this = this;
        return this.props.result.entries.map(function (entry, index) {
            return _this.getOneResult(entry, index == 0);
        });
    }

    isClassSelected() {
        if (!this.props.finder.sideBar || !this.props.finder.sideBar.classLevels) {
            return false;
        }
        for (let level of this.props.finder.sideBar.classLevels) {
            if (level.selected.length > 0) {
                return true;
            }
        }
        return false;
    }

    isFragmentSelected() {
        if (!this.props.finder.sideBar || !this.props.finder.sideBar.fragmentLevels) {
            return false;
        }
        for (let level of this.props.finder.sideBar.fragmentLevels) {
            if (level.selected.length > 0) {
                return true;
            }
        }
        return false;
    }

    componentDidMount() {
        if (this.input && typeof this.input.focus == "function") {
            this.input.focus();
        }
        this.props.getFinderOptions();
    }

    render() {

        if (!this.props.ready) { //block until header is ready
            if (this.props.loading) {
                return (<span>
                    <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    <FormattedMessage
                        id="CIM_NAVTREE_LOADING"
                        defaultMessage="Loading..."
                        description="This message will be showed while finder options is loading" />
                </span>);
            } else {
                return (<span>
                    <i className="fa fa-exclamation-triangle fa-3x fa-fw"></i>
                    <FormattedMessage
                        id="NPT_TABLE_ERROR"
                        defaultMessage="Error while loading options!"
                        description="This message will displayed if finder options loading failed" />
                </span>);
            }
        }
        this.disabled = (this.state.value.length == 0);
        if (this.props.finder && this.disabled) {
            this.disabled = !this.isClassSelected() && !this.isFragmentSelected();
        };
        return (
            <div className="container-fluid cim-navtree-search">
                <div className="row container-fill" style={allHeightStyle}>
                    {this.props.finder ?
                        this.props.finder.isShowed && this.props.finder.sideBar ?
                            <SideBar
                                isFetching={this.props.finder.isFetching}
                                fragments={this.props.finder.loadedFragments}
                                classes={this.props.finder.loadedClasses}
                                sideBar={this.props.finder.sideBar}
                                fetchFragments={this.props.fetchFragments}
                                selectFragment={this.props.selectSearchFragment}
                                fetchClasses={this.props.fetchClasses}
                                selectClass={this.props.selectSearchClass}
                                minimize={this.props.toggleFinder}
                            />
                            :
                            null
                        :
                        null
                    }
                    <div className={this.props.finder ? (this.props.finder.isShowed && this.props.finder.sideBar ? "col-md-10 nopadding container-fill" : "col-md-12 nopadding cim-navtree-sidebar-collapsed container-fill") : "col-md-12 nopadding container-fill"}>
                        {this.props.finder ?
                            this.props.finder.isShowed ?
                                null
                                :
                                <div className="cim-navtree-sidebar-container" onClick={this.props.toggleFinder}><div><div>&#9654;</div></div></div>
                            :
                            null
                        }
                        <div className='card' style={{ height: "100%", margin: "0px" }}>
                            <div className="cim-finder-table-heading card-header bg-info">
                                <h4>
                                    <FormattedMessage
                                        id="NAVTREE_SEARCH"
                                        defaultMessage="Search in tree"
                                        description="Search in tree" />
                                </h4>
                            </div>
                            <CimFiller updateDelay={5} style={{ padding: "0px 5px" }}>
                                <div className='card-body panel-bar' style={allHeightStyle}>
                                    <div className="input-group">
                                        <input type="text"
                                            className="form-control"
                                            placeholder="..."
                                            ref={input => this.input = input}
                                            onChange={this.handleChange}
                                            onKeyPress={this.handleKeyPress} />
                                        <span className="input-group-btn">
                                            <button className="btn btn-secondary" type="button" onClick={this.handleSearch} disabled={this.disabled} style={allHeightStyle}>
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                            </button>
                                        </span>
                                    </div>
                                    <CimFiller static={true}>
                                        <div className="cim-navtree-search-body">
                                            {this.getResults()}
                                        </div>
                                    </CimFiller>
                                </div>
                            </CimFiller>
                        </div>
                    </div>
                </div>
            </div>);
    }
};

export default TreeSearchPanel;