import * as Action from '../constants/cache';
import { addAlert } from './alert';
import { ALERT_DANGER, ALERT_SUCCESS } from '../constants/alert';
import { openModal } from './modal';

//Path to scripts
const CACHE_LIST_PATH = "/rest/cache/list";
const CACHE_CLEAR_ALL_PATH = "/rest/cache/clear";
const CACHE_CLEAR_PATH = CACHE_CLEAR_ALL_PATH + "/";

function successfullAlert(dispatch, id) {
    dispatch(addAlert(ALERT_SUCCESS, { id: "CACHE_CLEAR_SUCCESS", values: { id } }));
}

function errorAlert(dispatch, id) {
    dispatch(addAlert(ALERT_DANGER, { id: "CACHE_CLEAR_ERROR", values: { id } }));
}

function successfullAllAlert(dispatch) {
    dispatch(addAlert(ALERT_SUCCESS, { id: "CACHE_CLEAR_ALL_SUCCESS" }));
}

function errorAllAlert(dispatch) {
    dispatch(addAlert(ALERT_DANGER, { id: "CACHE_CLEAR_ALL_ERROR" }));
}

function ajaxClearCache(dispatch, id) {
    $.ajax({
        type: "GET",
        url: CACHE_CLEAR_PATH + id
    })
        .done(function (response) {
            successfullAlert(dispatch, id);
        })
        .fail(function (error) {
            if (error.status == 200) {
                successfullAlert(dispatch, id);
                return;
            }
            errorAlert(dispatch, id);
        });
}

function ajaxClearAllCache(dispatch) {
    $.ajax({
        type: "GET",
        url: CACHE_CLEAR_ALL_PATH
    })
        .done(function (response) {
            successfullAllAlert(dispatch);
        })
        .fail(function (error) {
            if (error.status == 200) {
                successfullAllAlert(dispatch);
                return;
            }
            errorAllAlert(dispatch);
        });
}

function ajaxFetchList(dispatch) {
    $.getJSON(CACHE_LIST_PATH, function (data) {
        dispatch(listReceived(data));
    }).fail(function (error) {
        dispatch(listErrorReceived(error));
    });
}

/////////////////
//Plain actions//
/////////////////
function listReceived(list) {
    return {
        type: Action.LIST_RECEIVED,
        payload: { list }
    }
}

function listErrorReceived(error) {
    return {
        type: Action.ERROR_RECEIVED,
        payload: { error }
    }
}

///////////
//Actions//
///////////

export function fetchList() {
    return function (dispatch, getState) {
        ajaxFetchList(dispatch);
    }
}

export function clearCache({ id }) {
    return function (dispatch, getState) {
        ajaxClearCache(dispatch, id);
    }
}

export function clearAllCache() {
    return function (dispatch, getState) {
        let options = {
            title: { id: "CACHE_CONFIRM_CLEAR_TITLE" },
            body: { id: "CACHE_CONFIRM_CLEAR_BODY" }
        }
        dispatch(openModal("cacheClear", "confirm", options, () => ajaxClearAllCache(dispatch)));
    }
}