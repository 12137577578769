import * as Action from '../constants/location';

function hashChanged(state, {path, params}) {
    return Object.assign({}, state, {path, params});
}

function contextPathChanged(state, {contextPath}) {
    return Object.assign({}, state, {contextPath});
}

export default (state = {}, action) => {
    switch (action.type) {
        case Action.HASH_CHANGED: return hashChanged(state, action.payload);
        case Action.CONTEXT_PATH_CHANGED: return contextPathChanged(state, action.payload);
        default: return state;
    }
}