export function parseLoginInfo(jsonBase64Encoded) {
    if (!jsonBase64Encoded) {
        return {
            ldapAuth: false
        };
    }
    let jsonUrlEncoded = atob(jsonBase64Encoded);
    let json = decodeURIComponent(jsonUrlEncoded);
    let loginInfo = JSON.parse(json);
    if (loginInfo.domains) { //Create domain map for fast lookup
        loginInfo.netbiosMap = {};
        loginInfo.fqdnMap = {};
        for (let domain of loginInfo.domains) {
            loginInfo.netbiosMap[domain.name] = domain;
            loginInfo.fqdnMap[domain.fqdn] = domain;
        }
    }
    return loginInfo;
}