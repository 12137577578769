//Basic url for all ajax requests
export const BASE_FETCH_INFO_URL = "/rest/tree/header";
export const BASE_FETCH_URL = "/rest/tree/fetch";
export const BASE_ADD_URL = "/rest/tree/new";
export const BASE_MOVE_URL = "/rest/tree/move";
export const BASE_PATH_URL = "/rest/tree/path";
export const BASE_SEARCH_URL = "/rest/tree/search";
export const BASE_OPTIONS_URL = "/rest/tree/options";

function cleanParams(params) {
    if (!params) {
        return null;
    }
    for (let p in params) {
        if (typeof params[p] == "undefined" || params[p] == null) {
            delete (params[p]);
        }
    }
    return params;
}

export function composeUrl(baseUrl, treeId, parameters) {
    let url = treeId;
    if (!url.startsWith("/")) {
        url = "/" + url;
    }
    let params = "";
    if (parameters) {
        params = "?" + $.param(parameters);
    }
    return `${baseUrl}${url}${params}`;
}

export function composeFetchInfoUrl(treeId) {
    let url = composeUrl(BASE_FETCH_INFO_URL, treeId);
    return `${url}`;
}

export function composeFetchUrl(treeId, id) {
    let url = composeUrl(BASE_FETCH_URL, treeId);
    let params = "";
    if (id != null) {
        params = "?" + $.param({ node: id });
    }
    return `${url}${params}`;
}

export function composeFetchPathUrl(treeId, rdfId, namespace) {
    const url = composeUrl(BASE_PATH_URL, treeId);
    let params = { rdfId };
    if (namespace) {
        params.namespace = namespace;
    }
    return `${url}?${$.param(params)}`;
}

export function composeAddUrl(treeId, id, addId, params) {
    let url = composeUrl(BASE_ADD_URL, treeId);
    let urlParams = cleanParams(Object.assign({ node: id, add: addId }, params));
    return `${url}?${$.param(urlParams)}`;
}

export function composeMoveUrl(treeId, id, addId, sourceId) {
    let url = composeUrl(BASE_MOVE_URL, treeId);
    let p = { add: addId, target: id, source: sourceId };
    let params = $.param(p);
    return `${url}?${params}`;
}

export function getBasename(path) {
    let idx = path.lastIndexOf('/');
    return path.substring(0, idx);
}

export function getFilename(path) {
    let idx = path.lastIndexOf('/');
    return path.substring(idx + 1);
}