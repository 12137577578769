import * as Action from '../constants/security';
import { parseGeneralAccessRules } from '../services/security';

function usernameChanged(state, username) {
    return Object.assign({}, state, { username })
}

function superuserChanged(state, superUser) {
    return Object.assign({}, state, { superUser })
}

function generalAccessRulesChanged(state, rules) {
    return Object.assign({}, state, { generalAccessRules: parseGeneralAccessRules(rules) })
}

export default (state = {}, action) => {
    switch (action.type) {
        case Action.USERNAME_CHANGED: return usernameChanged(state, action.payload);
        case Action.SUPERUSER_CHANGED: return superuserChanged(state, action.payload);
        case Action.GENERAL_ACCESS_RULES_CHANGED: return generalAccessRulesChanged(state, action.payload);
        default: return state;
    }
}