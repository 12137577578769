import React from 'react';
import Toolbar from './toolbar.jsx';
import FileUploader from './fileuploader.jsx';
import RPASetpoints from './rpasetpoints.jsx';
import Filler from '../filler/cimfiller.jsx';
import { FILE_SELECTION, FILE_UPLOADING, READY, COMMON_VENDORS } from '../../constants/rpasetpoints';


class Container extends React.Component {

    constructor(props) {
        super(props);

        props.setOptions({ vendor: props.vendor, voltage: props.voltage });
        props.loadCommonSetpoints();
        props.loadCommonSignals();

        /* If RPA setpoints have file link or page have link in hash - load file and setup file link */
        this.checkInitialParam("file", props.loadFromServer);
    }

    checkInitialParam(param, loadCallback) {
        if (this.props[param]) {
            if (this.props[param] != this.props.hashParams[param]) {
                this.props.changeHash({ [param]: this.props[param] });
            } else {
                loadCallback({ [param]: this.props[param] });
            }
            return true;
        } else if (this.props.hashParams[param]) {
            this.props.changeParameter({ [param]: this.props.hashParams[param] });
            return true;
        }
        return false;
    }

    printLoadingBanner() {
        if (!this.props.loadingState) {
            return null;
        }
        return <div className="npt-rpasetpoints-loading"></div>;
    }

    printState() {
        switch (this.props.state) {
            case FILE_SELECTION:
            case FILE_UPLOADING:
                return <FileUploader
                    disabled={this.props.disabled}
                    embedded={this.props.embedded}
                    vendor={this.props.vendor}
                    messages={this.props.messages}
                    bayType={this.props.bayType}
                    bayTypeList={this.props.bayTypeList}
                    uploadState={this.props.uploadState}
                    uploadSetpoints={this.props.uploadSetpoints}
                    uploadSignals={this.props.uploadSignals}
                />;
            case READY:
                return <RPASetpoints
                    locale={this.props.locale}
                    messages={this.props.messages}
                    activeTab={this.props.activeTab}
                    filter={this.props.filter}
                    source={this.props.source}
                    target={this.props.target}
                    primary={this.props.primary}
                    changeFilter={this.props.changeFilter}
                    disabled={this.props.disabled}
                    embedded={this.props.embedded}
                    objectcard={this.props.objectcard}
                    detailedView={this.props.detailedView}
                    changeDetailsParameter={this.props.changeDetailsParameter}
                    showLinkedElement={this.props.showLinkedElement}
                />;
            default:
                return null
        }
    }

    compareToHash(nextProps, param) {
        if (this.props.hashParams[param] != nextProps.hashParams[param] && nextProps.hashParams[param] != nextProps[param]) {
            nextProps.changeParameter({ [param]: nextProps.hashParams[param] });
        }
    }

    compareParam(nextProps, param, callback) {
        if (this.props[param] != nextProps[param]) {
            callback(nextProps[param]);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.compareToHash(nextProps, "file");
        this.compareParam(nextProps, "file", nextProps.loadFromServer);
    }

    componentWillUnmount() {
        this.props.fileReset();
    }

    render() {
        return (
            <Filler updateDelay={5}>
                <Toolbar
                    activeTab={this.props.activeTab}
                    source={this.props.source}
                    target={this.props.target}
                    state={this.props.state}
                    superUser={this.props.superUser}
                    withoutConfirmation={this.props.withoutConfirmation}
                    bindedFlag={this.props.bindedFlag}
                    detailedView={this.props.detailedView}
                    unbindSetpoints={this.props.unbindSetpoints}
                    bindSetpoints={this.props.bindSetpoints}
                    changeTab={this.props.changeTab}
                    changeConfirmationRequired={this.props.changeConfirmationRequired}
                    changeDetailedView={this.props.changeDetailedView}
                    changeBindedFlag={this.props.changeBindedFlag}
                    saveToFileSystem={this.props.saveToFileSystem}
                    saveToServer={this.props.saveToServer.bind(this, this.props.embedded)}
                    updateSetpointsFile={this.props.updateSetpointsFile}
                    updateSignalsFile={this.props.updateSignalsFile}
                    importFile={this.props.importFile}
                    confirmFileReset={this.props.confirmFileReset}
                    showConfig={this.props.showConfig}
                    disabled={this.props.disabled}
                    embedded={this.props.embedded}
                    cancel={this.props.cancel}
                />
                <Filler updateDelay={5}>
                    {this.printLoadingBanner()}
                    {this.printState()}
                </Filler>
            </Filler>
        );
    }
}

export default Container;