import React, { useState, useRef, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import Basic from './basic.jsx';


const checked = () => ({
    left: 'calc(100% - 2px)',
    transform: 'translateX(-100%)'
})
const neutral = (containerWidth) => ({
    left: 'calc(100% - 2px)',
    transform: `translateX(-${(containerWidth / 2) + 8}px)`
});

const unchecked = () => ({
    left: '2px'
});

const SliderCheckbox = (props) => {
    const { change, variants, value } = props;
    const ref = useRef(null)
    const chooseValue = () => {
        let variant = variants[0];
        if (value === true) {
            variant = variants.length === 3 ? variants[2] : variants[1]
        } else if (value === false) {
            variant = variants.length === 3 ? variants[1] : variants[0]
        }
        return variant;
    }
    const [currVariant, setCurrentVariant] = useState(chooseValue());
    const [prevVariant, setPrevVariant] = useState(null);
    const [cbWidth, setWidth] = useState(0);

    let labelLength = 0;
    let longestVariant = variants[1];
    variants.forEach(v => {
        if (v.label.length > labelLength && v.key !== 'nothing') {
            labelLength = v.label.length;
            longestVariant = v;
        }
    })
    const clickHandler = () => {
        if (variants.length === 2) {
            let newVariant = variants[1];
            if (currVariant.key === 'yes') {
                newVariant = variants[0]
            }
            setCurrentVariant(newVariant)
            change(newVariant.value)
        } else {

            if (currVariant.key === 'no') {
                setPrevVariant(variants[1]);
                setCurrentVariant(variants[0])
                change(variants[0].value)
                return;
            }
            if (currVariant.key === 'yes') {
                setPrevVariant(variants[2]);
                setCurrentVariant(variants[0])
                change(variants[0].value)
                return;
            }
            if (!prevVariant) {
                setPrevVariant(variants[0]);
                setCurrentVariant(variants[2])
                change(variants[2].value)
                return;
            }

            let newVariant = prevVariant.key === 'no' ? variants[2] : variants[1];
            setCurrentVariant(newVariant)
            change(newVariant.value)
        }
    }
    const containerColorCss = currVariant.key === 'yes' ? 'bg-success border border-success' :
        currVariant.key === 'no' ? 'bg-danger border border-danger' : 'bg-secondary border border-primary';

    let roundStyle = currVariant.key === 'yes' ? checked() :
        currVariant.key === 'no' ? unchecked() : neutral(cbWidth);

    const roundBackGround = currVariant.key === 'nothing' ? 'bg-primary' : '';

    const label = currVariant.key !== 'nothing' ? currVariant.label : null;

    let containerStyle = { width: `${cbWidth}px` };
    if (currVariant.key === 'no') {
        containerStyle['textAlign'] = 'right';
    }
    return (
        <div  >
            <span className='position-absolute npt-objectcard-checkbox-hiddenLabel' ref={ref => {
                if (ref) {
                    let width = ref.offsetWidth || 0;
                    if (width) {
                        width += 40;
                    }
                    setWidth(width)
                }
            }} >{longestVariant.label}</span>
            <div style={containerStyle} onClick={() => clickHandler()} className={`  ${containerColorCss} npt-objectcard-checkbox-container  shadow-sm text-white pb-2 px-2`}>
                {label}
                <div style={roundStyle} className={` ${roundBackGround} npt-objectcard-checkbox-round`}></div>
            </div>
        </div>
    )
}

const ButtonsCheckbox = (props) => {
    const { variants, change, value, disabled } = props;
    const chooseValue = () => {
        let variant = variants[0];
        if (value === true) {
            variant = variants.length === 3 ? variants[2] : variants[1]
        } else if (value === false) {
            variant = variants.length === 3 ? variants[1] : variants[0]
        }
        return variant;
    }
    const [variant, setVariant] = useState(chooseValue());
    let firstBtnClass = 'secondary'
    let secondBtnClass = 'secondary'
    switch (variant.key) {
        case 'yes': firstBtnClass = 'success'; break;
        case 'no': secondBtnClass = 'danger'; break;
    }
    const firstLabel = variants.length === 3 ? variants[2].label : variants[1].label
    const secondLabel = variants.length === 3 ? variants[1].label : variants[0].label
    const clickYesHandler = () => {
        if (disabled) {
            return
        }
        let newVariant = variants.length === 3 ? variants[2] : variants[1]
        if (variant.key === 'yes') {
            newVariant = variants[0];
        }
        setVariant(newVariant)
        const { value } = newVariant
        change(value);
    }
    const clickNoHandler = () => {
        if (disabled) {
            return
        }
        let newVariant = variants.length === 3 ? variants[1] : variants[0]
        if (variant.key === 'no') {
            newVariant = variants.length === 3 ? variants[0] : variants[1]
        }
        setVariant(newVariant);
        const { value } = newVariant
        change(value);
    }
    return (
        <div className="btn-group" role="group" aria-label="Basic example">
            <button disabled={disabled} type="button" onClick={() => clickYesHandler()} className={`btn btn-${firstBtnClass}`}>{firstLabel}</button>
            <button disabled={disabled} type="button" onClick={() => clickNoHandler()} className={`btn btn-${secondBtnClass}`}>{secondLabel}</button>
        </div>
    )
}

const DropDownCheckbox = (props) => {
    const { variants, change, value, disabled } = props;
    const chooseValue = () => {
        let variant = variants[0];
        if (value === true) {
            variant = variants.length === 3 ? variants[2] : variants[1]
        } else if (value === false) {
            variant = variants.length === 3 ? variants[1] : variants[0]
        }
        return variant;
    }


    const [label, setLabel] = useState(chooseValue().label);
    const [visible, setVisible] = useState(false);
    const handleValueChange = (idx) => {
        if (disabled) {
            return
        }
        const { value, label } = variants[idx];
        setLabel(label);
        console.log('v ', value, label)
        change(value);
        setVisible(false)
    }
    return (
        <div className="dropdown"  >
            <button disabled={disabled} className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {label}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" >
                {variants.map((v, idx) => (
                    <a className={`dropdown-item dr-${idx}`} onClick={() => handleValueChange(idx)} href="#">{v.label}</a>)
                )}
            </div>
        </div>

    )
}



class Checkbox extends Basic {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        console.log("Checkbox: ", event.target);
        this.props.change(event.target.checked);
    }

    chooseType(node) {
        const { editable, value, change } = this.props;
        if (!node.options || !node.options.type) {
            return (<input disabled={!editable} type="checkbox" label={node.label}
                onClick={(e) => change(e.target.checked)}
                checked={value} />)
        }
        const { type, values } = node.options;
        if (type && typeof type === 'string') {
            //нестандартный вид
            let variants = []
            if (values && Array.isArray(values)) {
                //нестандартные опции
                if (values.length === 2) {
                    variants.push({ label: values[0], value: false, key: 'no' })
                    variants.push({ label: values[1], value: true, key: 'yes' })
                    //Опции нет да по порядку
                } else if (values.length === 3) {
                    variants.push({ label: values[0], value: {}, key: 'nothing' })
                    variants.push({ label: values[1], value: false, key: 'no' })
                    variants.push({ label: values[2], value: true, key: 'yes' })
                    //Опции опция когда значение не задано, нет, да, по порядку 
                }
            }
            let component = null;
            switch (type) {
                case 'slider':
                    return <SliderCheckbox variants={variants} change={change} value={value} disabled={!editable} />;
                case 'buttons':
                    return <ButtonsCheckbox variants={variants} change={change} value={value} disabled={!editable} />;
                case 'dropdown':
                    return <DropDownCheckbox variants={variants} change={change} value={value} disabled={!editable} />;
                default:
                    return null;

            }
        }
        return null;
    }

    render() {
        let value = this.props.value;
        if (typeof value != 'boolean') {
            if (typeof value == 'string') {
                value = (value.toLowerCase() == 'true');
            } else if (typeof value == 'number') {
                value = (value != 0);
            } else {
                value = false;
            }
        }
        let id = this.getFieldId();

        const checkbox = this.chooseType(this.props.node);
        if (!checkbox) {
            return null
        }
        return this.wrapCheckbox(this.props.node.label, checkbox, this.props.node.description);
    }
}

export default Checkbox;