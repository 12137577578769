import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Filler from '../filler/cimfiller.jsx';
import Tree from '../tree/tree.jsx';
import Debounce from '../debounceinput.jsx';
import * as I18NConstants from '../../constants/i18n';
import * as ObjectcardConstants from '../../constants/objectcard';
import { RPA_SETPOINTS, TAB_SETPOINTS, SOURCE, TAB_DISCRETE, TARGET, TAB_ANALOG } from '../../constants/rpasetpoints';
import { selectNode, toggleNode, bindSetpoints } from '../../actions/rpasetpoints';
import { getTabTreeId } from '../../services/rpasetpoints';
import Menu from './menu.jsx';

const GREEN_COLOR = "green";
const RED_COLOR = "red";

function checkBinding(sourceState, node) {
    if (!sourceState.children[node.id]) {
        return typeof node.comparedId != "undefined" && node.comparedId != null;
    }
    for (let childId of sourceState.children[node.id]) {
        if (!sourceState.nodeById[childId].comparedId) {
            return false;
        }
    }
    return true;
}

function isNodeActive(activeId, nodeId) {
    if (!Array.isArray(activeId)) {
        return activeId == nodeId;
    }
    for (let childId of activeId) {
        if (childId == nodeId) {
            return true;
        }
    }
    return false;
}

function createNodeConnector(sourceId, objectcard) {
    return connect(function (_, initialProps) { //mapStateToProps
        const { id } = initialProps;
        return (globalState) => {
            const messages = globalState[I18NConstants.I18N].messages;
            const rpaSetpointsState = globalState[RPA_SETPOINTS];
            const sourceTreeId = getTabTreeId(rpaSetpointsState, sourceId);
            const sourceTree = rpaSetpointsState[sourceTreeId];
            const node = sourceTree.nodeById[id];
            if (!node) {
                return null;
            }
            let icon = null;
            let customStyle = null;
            let nodeBinding = checkBinding(sourceTree, node);
            customStyle = {
                title: {
                    color: nodeBinding ? GREEN_COLOR : null,
                    fontWeight: nodeBinding ? "bold" : null
                }
            }
            if (!sourceTree.children[id]) {
                if (isNodeActive(sourceTree.comparedId, id)) {
                    icon = "fa-check-circle";
                    customStyle.title.fontWeight = "bold";
                    customStyle.icon = {
                        color: GREEN_COLOR
                    }
                }
            }
            let disabled = false;
            if (objectcard) {
                const card = globalState[ObjectcardConstants.OBJECTCARD];
                const data = card.data[objectcard];
                const serverLock = data && data.$lock; //card lock status
                const serverLockReady = serverLock && serverLock.status;
                disabled = !serverLockReady;
            }
            let name = node.name;
            if (!node.folder) {
                switch (sourceTreeId) {
                    case SOURCE[TAB_SETPOINTS]:
                        name += ` = ${typeof node.value == 'string' ? node.value : ""}`;
                        if (node.rawValue && node.rawValue != node.value) {
                            name += ` (${messages["RPA_SETPOINTS_ORIGINAL_VALUE"]}: ${node.rawValue})`;
                        }
                        break;
                    case SOURCE[TAB_ANALOG]:
                    case SOURCE[TAB_DISCRETE]:
                        name += ` [${node.chId}]`;
                        break;
                    case TARGET[TAB_SETPOINTS]:
                    case TARGET[TAB_ANALOG]:
                    case TARGET[TAB_DISCRETE]:
                        name += ` [${node.linkedId}]`;
                        break;
                    default:
                        break;
                }
            }
            return Object.assign({
                toggled: sourceTree.toggled[id],
                children: sourceTree.children[id],
                active: isNodeActive(sourceTree.active, id),
                customStyle,
                icon,
                disabled,
                visible: !(rpaSetpointsState.bindedFlag && nodeBinding)
            }, node, {
                    name: name
                });
        }
    }, (_, initialProps) => { //mapDispachToProps
        const { id } = initialProps;
        return (dispatch) => {
            return {
                onToggle: function ({ id, active, folder }) {
                    if (folder) {
                        dispatch(toggleNode({ sourceId, id }));
                    }
                    if (active) {
                        return;
                    }
                    dispatch(selectNode({ sourceId, id }));
                },
                /* Use rename function to handle double click */
                renameFile: function ({ id, children, disabled }) {
                    /* Ignore double click on folders or if rpa setpoints disabled */
                    if (children || disabled) {
                        return;
                    }
                    dispatch(bindSetpoints());
                }
            }
        }
    });
}

class SetpointsTree extends React.Component {

    constructor(props) {
        super(props);

        if (props.selectedNode) {
            this.expandNode(props.selectedNode.id);
        }

        this.connectNodeFunction = createNodeConnector(props.sourceId, props.objectcard);

        this.changeFilter = this.changeFilter.bind(this);
    }

    changeFilter(value) {
        this.props.changeFilter(value)
    }

    printFilter() {
        if (this.props.primary) {
            return null;
        }
        return (<div className="npt-rpasetpoints-primary-badge">
            <Debounce
                editable={true}
                valid={true}
                value={this.props.filter}
                format={null}
                children={this.props.messages["RPA_SETPOINTS_FILTER"]}
                containerStyle={{ height: "1.15rem" }}
                change={this.changeFilter}
                locale={this.props.locale} >
            </Debounce >
        </div>);
    }

    /* Blank function to prevent error  */
    expandNode() { }

    /* Blank function to prevent error  */
    fetchSubTree() { }


    componentWillReceiveProps(nextProps) {
        if (this.props.selectedNode != nextProps.selectedNode && nextProps.selectedNode) {
            this.expandNode(nextProps.selectedNode.id);
        }
    }

    render() {
        return (
            <div className="npt-rpasetpoints-tree" style={{ width: this.props.forcedWidth || this.props.width }}>
                <h4>
                    <FormattedMessage id={this.props.titleId} />
                    {this.printFilter()}
                </h4>
                <Filler updateDelay={5}>
                    <Tree
                        roots={this.props.roots}
                        connectNodeFunction={this.connectNodeFunction}
                        treeComponentId={"rpa-setpoints"}
                        expandNode={this.expandNode}
                        fetchSubTree={this.fetchSubTree}
                    />
                    <Menu
                        nodeById={this.props.nodeById}
                        treeComponentId={"rpa-setpoints"}
                        sourceId={this.props.sourceId}
                        showLinkedElement={this.props.showLinkedElement}
                    />
                </Filler>
            </div>
        );
    }
}

export default SetpointsTree;