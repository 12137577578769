import React from 'react';
import { FormattedMessage } from 'react-intl';

function getIcon(icon) {
    if (!icon) {
        return null;
    }
    let cls = "fa fa-fw " + icon;
    return (<i className={cls} aria-hidden="true"></i>);
}

class ContextMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            x: 0,
            y: 0,
            items: []
        };
    }

    preventEvent(reactEvent) {
        reactEvent.preventDefault();
        reactEvent.stopPropagation();
    }

    mouseClick(action, reactEvent) {
        action(reactEvent);
        this.hideMenu();
    }

    hideMenu() {
        this.setState({ items: [] });
    }

    componentDidMount() {
        $(window).on("mousedown.context-menu", (event) => {
            if (this.state.items.length == 0) {
                return;
            }
            this.hideMenu();
        });
    }

    componentWillUnmount() {
        $(window).off("mousedown.context-menu");
    }

    render() {
        if (this.state.items.length == 0) {
            return null;
        }
        return <div className="npt-table-context-menu" style={{ left: this.state.x, top: this.state.y }} onMouseDown={this.preventEvent}>
            {this.state.items.map((item) =>
                <a onClick={this.mouseClick.bind(this, item.action)}>{getIcon(item.icon)} {item.formattedId ? <FormattedMessage id={item.formattedId} /> : item.label}</a>)}
        </div>
    }
}

export default ContextMenu;


