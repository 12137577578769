import React from 'react';
import { FormattedMessage } from 'react-intl';
import Basic from './basic.jsx';
import { ADD_BUTTON_ICON_STYLE, REMOVE_BUTTON_ICON_STYLE } from './style.jsx';
import { MSG_TABLE_NO_DATA_TO_DISPLAY } from '../../messages.jsx';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { obtainData } from '../../../services/automation';
import { readFromClipboard } from '../../../services/clipboard.js';
import { getObjectcardHistory } from '../../../services/storage.js';

const DEFAULT_LINK_FORMAT = "objectcard/${$rdfId}"

const tableStye = {
    margin: "0px",
    borderRadius: "0px"
};

const containerStyle = {
    overflowX: 'auto'
};

const thStyle = {
    overflowWrap: "break-word",
    textOverflow: "clip",
    whiteSpace: "normal"
};

const toolbarButtonStyle = {
    padding: "2px 8px"
};

/*https://jsfiddle.net/m3mjo0f2/6/*/
function evalExpr(s, vars) {
    return s.replace(/\$\{(.+?)\}/g, function (q, g1) {
        return vars[g1] || "";
    });
}

function refFormatter(ref, _, refExtra) {
    //console.log("contextPath", refExtra.contextPath);
    //console.log("link", refExtra.link);
    //console.log("ref", ref);
    if (refExtra.disabled) {
        return (<a href="#" disabled={true} className={refExtra.className} style={refExtra.style}><i className="fa fa-eye" aria-hidden="true"></i></a>);
    }
    const cp = refExtra.contextPath;
    let link = evalExpr(refExtra.link || DEFAULT_LINK_FORMAT, ref);
    if (link.startsWith('/')) {
        link = link.substring(1);
    }
    return (<a href={`${cp}${link}`} className={refExtra.className} style={refExtra.style}><i className="fa fa-eye" aria-hidden="true"></i></a>);
}

function descrFormatter(cell, row) {
    let html = { __html: cell };
    return (<div dangerouslySetInnerHTML={html} />);
}

export const DEFAULT_FIELDS = {
    "key": {
        "getter": ref => ref.$rdfId,
        "label": "RDFID",
        "isKey": true,
        "hidden": true
    },
    "num": {
        "getter": (_, num) => num,
        "label": (<FormattedMessage
            id="OBJECTCARD_REFTABLE_NUMBER"
            defaultMessage="#"
            description="Number of item in table of references" />)
    },
    "label": {
        "getter": ref => ref.$label,
        "label": (<FormattedMessage
            id="OBJECTCARD_REFTABLE_NAME"
            defaultMessage="Name"
            description="Name of item in table of references" />)
    },
    "description": {
        "getter": ref => ref.$description,
        "label": (<FormattedMessage
            id="OBJECTCARD_REFTABLE_DESCRIPTION"
            defaultMessage="Description"
            description="Description of item in table of references" />),
        "dataFormat": descrFormatter
    },
    "ref": {
        "getter": ref => ref,
        "label": (<FormattedMessage
            id="OBJECTCARD_REFTABLE_CARD"
            defaultMessage="Card"
            description="Show card refered from table" />),
        "dataFormat": refFormatter
    }
}

class ReferenceTable extends Basic {

    constructor(props) {
        super(props);
        this.convertAndPasteRefs = this.convertAndPasteRefs.bind(this);
        this.handleManualPaste = this.handleManualPaste.bind(this);
        this.historyClicked = this.historyClicked.bind(this);
        this.pasteClicked = this.pasteClicked.bind(this);
        this.addClicked = this.addClicked.bind(this);
        this.removeClicked = this.removeClicked.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.state = { selected: [] };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.selected.length == 0) {
            return null;
        }
        const value = nextProps.value;
        if ($.type(value) != 'array' || value.length == 0) {
            return { selected: [] };
        }
        const keySet = value.reduce((acc, ref) => { acc[ref.$rdfId] = true; return acc; }, {});
        return { selected: prevState.selected.filter(key => keySet[key]) };
    }

    convertAndPasteRefs(text) {
        const refs = JSON.parse(text);
        if (!Array.isArray(refs)) {
            return;
        }
        this.props.pasteRefs(this.props.node.options.cls, this.props.node.multiple, false, refs);
    }

    handleManualPaste(reactEvent) {
        reactEvent.preventDefault();
        const clipboardData = reactEvent.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        $(".close", $("#manualPaste")).trigger("click");
        this.convertAndPasteRefs(pastedData);
    }

    historyClicked() {
        const references = getObjectcardHistory(this.props.node.options.cls);
        if (references === null) {
            return;
        }
        if (references.length === 0) {
            this.props.addWarning({ id: "OBJECTCARD_HISTORY_EMPTY" });
            return;
        }
        this.props.pasteRefs(this.props.node.options.cls, this.props.node.multiple, true, references);
    }

    pasteClicked() {
        readFromClipboard().then((text) => {
            this.convertAndPasteRefs(text);
        }, (reason) => {
            const options = {
                title: <FormattedMessage
                    id="OBJECTCARD_MANUAL_PASTE"
                    defaultMessage="Manual paste"
                    description="Title of manual paste modal" />,
                body: <div>
                    <div>
                        <FormattedMessage
                            id="OBJECTCARD_AUTO_PASTE_PROHIBITED"
                            defaultMessage="Auto paste is prohibited, please use form below to manually paste references."
                            description="Text that showns is paste from clipboard failed" />
                    </div>
                    <textarea style={{ resize: "none", width: "100%" }} onPaste={this.handleManualPaste}></textarea>
                </div>
            };
            this.props.openNamedModal("manualPaste", "common", options);
        });
    }

    onAdd(data) {
        if (this.props.node.options.tree) {
            this.props.link(data);
        } else if (this.props.node.options.table || this.props.node.options.finder) {
            if (this.props.node.multiple) {
                for (let row of data) {
                    this.props.link({ $rdfId: row.key, $label: row.label, $description: row.description, $class: row.class });
                }
            } else {
                this.props.link({ $rdfId: data.key, $label: data.label, $description: data.description, $class: data.class });
            }
        }
    }

    addClicked() {
        if (this.props.node.options.tree) {
            const options = {
                treeId: this.props.node.options.tree,
                nodeTypeId: this.props.node.options.nodeTypeId,
                size: this.props.node.options.modalSize,
                height: this.props.node.options.height,
                checkFunction: (node, callback) => {
                    return this.props.checkRef(this.props.node.options.cls, { $rdfId: node.$rdfId, $namespace: node.$namespace }, callback);
                }
            };
            this.props.openModal("navtree", options, this.onAdd);
        } else if (this.props.node.options.table || this.props.node.options.finder) {
            let parameters = Object.assign({}, this.props.node.options.parameters);
            if (this.props.node.options.parametersFromCard) {
                for (let key in this.props.node.options.parametersFromCard) {
                    const field = this.props.node.options.parametersFromCard[key];
                    parameters[key] = this.props.values[field];
                }
            }
            const options = {
                tableId: this.props.node.options.table || this.props.node.options.finder,
                forcedSelectType: this.props.node.multiple ? "checkbox" : "radio",
                forcedParameters: parameters,
                size: this.props.node.options.modalSize,
                height: this.props.node.options.height,
                filter: this.props.node.options.filter,
                type: this.props.node.options.finder ? "finder" : "table",
                checkFunction: (row, callback) => {
                    return this.props.checkRef(this.props.node.options.cls, { $rdfId: row.rdfId || row.key, $namespace: row.namespace }, callback);
                }
            };
            this.props.openModal("cimtable", options, this.onAdd);
        }
    }

    onRemove() {
        if (this.props.node.multiple) {
            const indexMap = this.props.value.reduce((acc, ref, idx) => { acc[ref.$rdfId] = idx; return acc; }, {});
            this.props.remove(this.state.selected.map((key) => indexMap[key]));
        } else {
            this.props.change({});
        }
    }

    removeClicked() {
        let options = {
            title: { id: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW_TITLE" },
            body: { id: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW" }
        }
        this.props.openModal("confirm", options, this.onRemove);
    }

    onSelect({ key }, isSelected, e) {
        if (isSelected) {
            this.setState({ selected: [...this.state.selected, key].sort() });
        } else {
            this.setState({ selected: this.state.selected.filter(it => it !== key) });
        }
        return false;
    }

    onSelectAll(isSelected, rows) {
        if (!isSelected) {
            this.setState({ selected: [] });
        } else {
            this.setState({ selected: rows.map(it => it.key) });
        }
        return false;
    }

    getHistoryButton(showText, multiple) {
        const style = showText ? {} : { padding: "2px" };
        return (<button type="button" className="btn btn-secondary" onClick={this.historyClicked} style={style}>
            <i className="fa fa-history fa-fw" aria-hidden="true"></i>
            {showText && <FormattedMessage
                id="OBJECTCARD_REFTABLE_HISTORY_BUTTON"
                defaultMessage="From history"
                description="User should click this button to add items into the table from view history" />}
        </button>);
    }

    getPasteButton(showText) {
        const style = showText ? {} : { padding: "2px" };
        return (<button type="button" className="btn btn-secondary" onClick={this.pasteClicked} style={style}>
            <i className="fa fa-paste fa-fw" aria-hidden="true"></i>
            {showText && <FormattedMessage
                id="OBJECTCARD_REFTABLE_PASTE_BUTTON"
                defaultMessage="Paste"
                description="User should click this button to add items into the table via references paste" />}
        </button>);
    }

    getAddButton(showText) {
        if (!this.haveAddingFunction()) {
            return null;
        }
        const style = showText ? {} : { padding: "2px" };
        return (<button type="button" className="btn btn-secondary" onClick={this.addClicked} style={style}>
            <i className="fa fa-plus-circle fa-fw" style={ADD_BUTTON_ICON_STYLE} aria-hidden="true"></i>
            {showText ? <FormattedMessage
                id="OBJECTCARD_REFTABLE_ADD_BUTTON"
                defaultMessage="Add"
                description="User should click this button to add items into the table" /> : null}
        </button>);
    }

    getRemoveButton(showText, removeDisabled) {
        const style = showText ? {} : { padding: "2px" };
        return (<button type="button" className="btn btn-secondary" onClick={this.removeClicked} disabled={removeDisabled} style={style}>
            <i className="fa fa-minus-circle fa-fw" style={REMOVE_BUTTON_ICON_STYLE} aria-hidden="true"></i>
            {showText ? <FormattedMessage
                id="OBJECTCARD_REFTABLE_REMOVE_BUTTON"
                defaultMessage="Remove"
                description="User should click this button to remove items from table" /> : null}
        </button>);
    }

    getToolbar() {
        const removeDisabled = (this.state.selected.length == 0);
        return (
            <div className="btn-group btn-group-xs pull-right" role="group" aria-label="...">
                {this.getHistoryButton(true, true)}
                {this.getPasteButton(true)}
                {this.getAddButton(true)}
                {this.getRemoveButton(true, removeDisabled)}
            </div>);
    }

    getRows() {
        const value = this.props.value;
        if ($.type(value) != 'array' || value.length == 0) {
            return []
        }
        const columns = this.getColumns();
        const rows = [];
        for (let idx in value) {
            const ref = value[idx];
            const num = parseInt(idx) + 1;
            const row = {};
            for (let col of columns) {
                if (col.path) {
                    row[col.field] = obtainData(col.path, ref);
                } else {
                    const defaultColumn = DEFAULT_FIELDS[col.field];
                    if (defaultColumn) {
                        row[col.field] = defaultColumn.getter(ref, num);
                    } else {
                        row[col.field] = ""
                    }
                }
            }
            rows.push(row);
        }
        return rows;
    }

    getRefExtra(className, style, disabled) {
        return {
            contextPath: this.props.contextPath,
            link: this.props.node.options.link,
            className, style, disabled
        }
    }

    isEditable() {
        return this.props.editable;
    }

    haveAddingFunction() {
        return this.props.node.options.tree || this.props.node.options.table || this.props.node.options.finder;
    }

    getColumns() {
        return this.props.node.options.columns || [ //Default columns
            { "field": "key" },
            { "field": "num" },
            { "field": "label" },
            { "field": "description" },
            { "field": "ref" }
        ]
    }

    getHeaderColumn(column) {
        const defaultColumn = DEFAULT_FIELDS[column.field];
        const label = column.label || (defaultColumn && defaultColumn.label) || column.field;
        const hidden = column.hidden || (defaultColumn && defaultColumn.hidden);
        const isKey = (defaultColumn && defaultColumn.isKey); //only default column could be key!
        const dataFormat = (defaultColumn && defaultColumn.dataFormat); //only default column could have special data format
        const optional = {};
        if (isKey) {
            optional.isKey = true;
        }
        if (dataFormat) {
            optional.dataFormat = dataFormat;
            optional.formatExtraData = this.getRefExtra();
        }
        if (hidden) {
            optional.hidden = true;
        }
        return (<TableHeaderColumn dataField={column.field} thStyle={thStyle} tdStyle={thStyle} {...optional} dataSort={true}><span>{label}</span></TableHeaderColumn>);
    }

    renderTable() {
        const editable = this.isEditable();
        const optional = {};
        if (editable) {
            optional.selectRow = {
                mode: 'checkbox',
                onSelect: this.onSelect,
                onSelectAll: this.onSelectAll,
                bgColor: '#80BEE6',
                //hideSelectColumn: !,
                clickToSelect: true,
                selected: this.state.selected
            };
        }
        const options = {
            noDataText: MSG_TABLE_NO_DATA_TO_DISPLAY
        }
        const columns = this.getColumns();
        const _this = this;
        const contents = (
            <BootstrapTable
                version='4'
                options={options}
                tableStyle={tableStye}
                containerStyle={containerStyle}
                data={this.getRows()}
                striped={true}
                hover={true}
                {...optional}>
                {columns.map(col => _this.getHeaderColumn(col))}
            </BootstrapTable>);
        const label = this.props.node.options.label || this.props.node.label;
        //Select view depending on form status and options
        if (editable) {
            return this.wrapToolbar(label, this.getToolbar(), contents);
        }
        return this.wrapLarge(label, contents);
    }

    getToolButton() {
        const editable = this.isEditable();;
        const hasValue = (this.props.value && this.props.value.$rdfId) ? true : false;
        if (!editable) {
            if (hasValue) { //We have some value
                return (<span className="input-group-btn">
                    {refFormatter(this.props.value, null, this.getRefExtra("btn btn-secondary", { padding: "2px" }))}
                </span>);
            }
            return (<span className="input-group-btn">
                {refFormatter(this.props.value, null, this.getRefExtra("btn btn-secondary", { padding: "2px" }, true))}
            </span>);
        }

        //////////////////////
        //Editable reference//
        //////////////////////
        if (hasValue) { //We have some value
            return (<span className="input-group-btn">
                {this.getRemoveButton(false, false)}
            </span>);
        }
        return (<span className="input-group-btn">
            {this.getHistoryButton(false, false)}
            {this.getPasteButton(false)}
            {this.getAddButton(false)}
        </span>);
    }

    renderInput() {
        const value = (this.props.value && this.props.value.$label) || "--";
        return this.wrapInput(this.props.node.label,
            <div className="input-group">
                <input type="text" className="form-control card-input" disabled="true" value={value} />
                {this.getToolButton()}
            </div>,
            this.props.node.description);
    }

    render() {
        if (this.props.node.multiple) {
            return this.renderTable();
        }
        return this.renderInput();
    }
}

export default ReferenceTable;