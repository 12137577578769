import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Breadcrumbs from './components/breadcrumbs/breadcrumbs.jsx';
import { getNavTreeState, selectObject } from './services/navtree';
import { openSubTree } from './actions/navtree.js';

const ATTR_BREADCRUMBS = "cim-tree-breadcrumbs";

const BREADCRUMBS_TREE_NOT_FOUND = <FormattedMessage
    id="BREADCRUMBS_TREE_NOT_FOUND"
    defaultMessage="Tree not found"
    description="Error that occured when breadcrumbs can't find correcponding tree" />


function createMapStateToProps(_, initialProps) {
    const { treeId } = initialProps;
    return (globalState) => {
        const navTreeState = getNavTreeState(globalState);
        if (!navTreeState || !navTreeState[treeId]) {
            return {
                messageId: "BREADCRUMBS_TREE_NOT_FOUND"
            }
        }
        const treeState = navTreeState[treeId];
        return {
            error: treeState.error,
            loading: treeState.loading,
            ready: treeState.ready,
            active: treeState.active,
            nodeById: treeState.nodeById
        };
    }
}

function createMapDispachToProps(_, initialProps) {
    const { treeId } = initialProps;
    return (dispatch) => {
        return {
            openNode: function (node) {
                selectObject(node);
                dispatch(openSubTree(treeId, node.id));
            }
        }
    }
}

export function createBreadcrumbsContainer() {
    return connect(
        createMapStateToProps,
        createMapDispachToProps
    )(Breadcrumbs);
}

//Initialization function
export default (dispatcher) => {
    //Register components
    dispatcher.registerComponent(ATTR_BREADCRUMBS, (elm) => {
        const treeId = $(elm).attr(ATTR_BREADCRUMBS);
        let BreadcrumbsContainer = createBreadcrumbsContainer();
        return (<BreadcrumbsContainer treeId={treeId} />);
    });
};

