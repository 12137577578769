import { SRCTREE } from '../constants/srctree';

////////////////////////////////////////
//Common code for reducers and actions//
////////////////////////////////////////
const initialTreeState = {
    ui: {
        children: [
            {
                loading: true,
                children: []
            }
        ]
    }
};

export function getInitialState() {
    return $.extend(true, {}, initialTreeState);
}

export function safeGetTree(state = {}, editorId) {
    var treeState = state[editorId];
    if (typeof treeState == 'undefined') {
        return getInitialState();
    }
    return treeState;
}


export function getSrcTreeState(globalState) {
    return globalState[SRCTREE];
}

export function findNodeInTree(treeState, path) {
    path = path.split('/');
    //Current object
    var parent = treeState.ui;
    //Traverse path
    for (let name of path) {
        if (name == '') {
            continue;
        }
        let found = null;
        let idx = null;
        for (let j in parent.children) {
            let child = parent.children[j];
            if (child.name == name) {
                idx = j;
                found = child;
                break;
            }
        }
        if (found == null) {
            console.log(`Failed to find ${name} in tree`);
            return null;
        }
        //Use found as new parent
        parent = found;
    }
    return parent;
}

export function renamePath(path, caption) {
    let idx = path.lastIndexOf('/');
    return path.substring(0, idx) + "/" + caption;
}

export function getFilename(path) {
    let idx = path.lastIndexOf('/');
    return path.substring(idx + 1);
}

export function getBasename(path) {
    let idx = path.lastIndexOf('/');
    return path.substring(0, idx);
}

/* Parse data to compatible with treebeard view */
export function parseTreeData(data) {
    let treebeardData = [];
    let treebeardMap = {};
    for (let path of data) {
        let partials = path.split("/");
        let id = "";
        let parent = null;
        for (let partial of partials) {
            if (partial) {
                id += "/" + partial;
            } else {
                partial = "/";
            }
            if (treebeardMap[id]) {
                parent = treebeardMap[id];
                continue;
            }
            treebeardMap[id] = { id, label: partial, name: partial, path: id };
            if (!parent) {
                treebeardData.push(treebeardMap[id]);
            } else {
                if (!parent.children) {
                    parent.children = [];
                    parent.directory = true;
                }
                parent.children.push(treebeardMap[id]);
            }
            parent = treebeardMap[id];
        }
        if (parent) {
            parent.path = path;
        }
    }
    return treebeardData;
}