import moment from 'moment';
import { addLocaleData } from 'react-intl';

//English (default). So no messagges are provided => load all default
import defaultMessages from '../messages/defaultMessages.json';

//Russian
import ruMessages from '../messages/ru.json';

//Spanish
import esMessages from '../messages/es.json';

//Basic locals
import { en, ru, es } from 'react-intl/locale-data';

//Add locals to application
addLocaleData([...en, ...ru, ...es]);

//Populate English local with default messages
const enMessages = {};
for (let file of defaultMessages) {
    for (let descr of file.descriptors) {
        enMessages[descr.id] = descr.defaultMessage;
    }
}

export const LOCALE_MAP = {
    en: enMessages,
    es: esMessages,
    ru: ruMessages
}

function fixLocale(locale) {
    if (typeof locale != 'string') {
        return 'en';
    }
    locale = locale.toLowerCase();
    if (!LOCALE_MAP[locale]) {
        return 'en';
    }
    return locale;
}

export function updateLocale(_locale) {
    //Fix locale name
    const locale = fixLocale(_locale);
    //Hack: Change locale in datepicker
    moment.locale(locale);
    //Hack (todo localize)
    moment.updateLocale(moment.locale(), { invalidDate: "Неверная дата" });
    //Correct locale name
    return locale;
}

export function initLocale(_locale) {
    //Fix locale name
    const locale = updateLocale(_locale);
    //Return initial state of i18n
    return { locale, messages: LOCALE_MAP[locale] };
}

