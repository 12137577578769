import { FormattedMessage } from 'react-intl';

export const loadingMessage = (<FormattedMessage
    id="TREEBEARD_LOADING"
    defaultMessage="Loading ..."
    description="Tree node is loading" />);

export const errorMessage = (<FormattedMessage
    id="TREEBEARD_ERROR"
    defaultMessage="Error"
    description="Tree node loading error" />);

export const noLabelMessage = (<FormattedMessage
    id="TREEBEARD_NO_LABEL"
    defaultMessage="No label"
    description="Tree node doesn't have label" />);

export const CAPTION_CHANGED = "TREE_NODE_CAPTION_CHANGED";
export const MOVE_NODE = "TREE_NODE_MOVE";