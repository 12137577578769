import { getFilename, getBasename } from '../services/npt-treebeard';

export const initialTreeState = {
    rootNodesIds: [],
    nodeById: {},
    children: {},
    toggled: {
        "": true
    },
    active: null,
    infoLoaded: false,
    loading: true,
    error: false
};

function removeElementFromArray(element, array) {
    for (let i = 0; i < array.length; ++i) {
        if (array[i] == element) {
            array.splice(i, 1);
            return;
        }
    }
}

function addElementToArrayWithCheck(element, array) {
    for (let i = 0; i < array.length; ++i) {
        if (array[i] == element) {
            return;
        }
    }
    array.push(element);
}

/* First go directories sorted by name then go nodes */
function nodeSortFunction(nodeById, a, b) {
    let nodeA = nodeById[a];
    let nodeB = nodeById[b];
    if (nodeB.directory) {
        if (!nodeA.directory) {
            return 1;
        }
    } else {
        if (nodeA.directory) {
            return -1;
        }
    }
    if (nodeB.label < nodeA.label) {
        return 1;
    } else if (nodeB.label > nodeA.label) {
        return -1;
    }
    return 0;
}

/* Sorting functions:
 * "default" - first displays folders in alphabetical order then nodes*/
function sortNodes(treeState, parentId) {
    switch (treeState.sortedBy) {
        case "default":
            if (parentId == null) {
                treeState.rootNodesIds.sort(nodeSortFunction.bind(this, treeState.nodeById));
            } else {
                treeState.children[parentId].sort(nodeSortFunction.bind(this, treeState.nodeById));
            }
            break;
        default:
            break;
    }
}

function recursiveParseTree(treeState, data, parentId) {
    for (let node of data) {
        if (parentId == null) {
            addElementToArrayWithCheck(node.id, treeState.rootNodesIds);
        } else {
            if (!treeState.children[parentId]) {
                treeState.children[parentId] = [];
            }
            addElementToArrayWithCheck(node.id, treeState.children[parentId]);
        }
        node.parentId = parentId;
        treeState.nodeById[node.id] = node;
        if (node.children) {
            treeState.children[node.id] = [];
            recursiveParseTree(treeState, node.children, node.id);
            sortNodes(treeState, node.id);
            delete (node.children)
        }
    }
    sortNodes(treeState, parentId);
}

export function waitForTreeHandler(state) {
    return Object.assign({}, initialTreeState, state, { rootNodesIds: [], loading: true });
}

export function receiveInfoHandler(state, { info }) {
    let treeState = Object.assign({}, state);
    treeState.infoLoaded = true;
    treeState.header = info;
    treeState.enabledFilters = {};
    if (info && info.filter && info.filter.option && $.isArray(info.filter.option)) {
        for (let option of info.filter.option) {
            if (typeof option.checked != "undefined") {
                treeState.enabledFilters[option.value] = Boolean(option.checked);
            } else {
                treeState.enabledFilters[option.value] = true;
            }
        }
    }
    return treeState;
}

export function receiveTreeHandler(state, { data }) {
    let treeState = Object.assign({}, state);
    treeState.rootNodesIds = treeState.rootNodesIds.slice();
    treeState.children = Object.assign({}, treeState.children);
    treeState.nodeById = Object.assign({}, treeState.nodeById);

    treeState.loading = false;

    recursiveParseTree(treeState, data, null);
    return treeState;
}

export function receiveTreeErrorHandler(state, { error }) {
    let treeState = Object.assign({}, state);
    treeState.loading = false;
    treeState.error = error;
    return treeState;
}

export function toggleSubTreeHandler(state, { id, toggled, noSelect }) {
    let treeState = Object.assign({}, state);
    if (!noSelect) {
        treeState.active = id;
    }
    if (treeState.children[id]) {
        treeState.toggled = Object.assign({}, treeState.toggled);
        treeState.toggled[id] = toggled;
    }
    return treeState;
}

export function toggleFilterHandler(state, { filter, value }) {
    let treeState = Object.assign({}, state);
    treeState.enabledFilters = Object.assign({}, treeState.enabledFilters);
    treeState.enabledFilters[filter] = value;
    return treeState;
}

function setupNodeChildrens(treeState, id) {
    if (id == null) {
        treeState.rootNodesIds = treeState.rootNodesIds.slice();
    } else if (!treeState.children[id]) {
        treeState.children[id] = [];
    } else {
        treeState.children[id] = treeState.children[id].slice();
    }
}

function addNode(treeState, node) {
    treeState.nodeById[node.id] = node;
    setupNodeChildrens(treeState, node.parentId);
    addElementToArrayWithCheck(node.id, treeState.children[node.parentId]);
    sortNodes(treeState, node.parentId);
}

function plainRemoveNode(treeState, id) {
    let node = treeState.nodeById[id];
    delete (treeState.nodeById[id]);
    if (node.parentId == null) {
        treeState.rootNodesIds = treeState.rootNodesIds.slice();
        removeElementFromArray(id, treeState.rootNodesIds);
    } else {
        treeState.children[node.parentId] = treeState.children[node.parentId].slice();
        removeElementFromArray(id, treeState.children[node.parentId]);
        if (treeState.children[node.parentId].length == 0) {
            delete (treeState.children[node.parentId]);
        }
    }
}

function removeNode(treeState, id) {
    if (id == null) {
        return;
    }
    if (treeState.children[id]) {
        for (let childId of treeState.children[id]) {
            removeNode(treeState, childId);
        }
        delete (treeState.children[id]);
    }
    plainRemoveNode(treeState, id);
}

function changeNodePath(node, newPath) {
    node.id = newPath;
    node.path = newPath;
    node.parentId = getBasename(newPath);
}

/* Check if all directories of new path exist and add if not */
function checkAddDirectories(treeState, path) {
    let directories = [];
    let position = path.indexOf("/");
    while (position != -1) {
        directories.push(path.substring(0, position));
        position = path.indexOf("/", position + 1);
    }
    let parentId = null;
    for (let directory of directories) {
        if (!treeState.nodeById[directory]) {
            let name = getFilename(directory);
            let node = {
                id: directory,
                path: directory,
                name: name,
                label: name,
                parentId: parentId,
                directory: true
            }
            addNode(treeState, node);
        }
        parentId = directory;
    }
}

function moveNode(treeState, id, movePath) {
    let node = treeState.nodeById[id];
    let toggled = treeState.toggled[id];
    checkAddDirectories(treeState, movePath);
    plainRemoveNode(treeState, id);
    changeNodePath(node, movePath);
    addNode(treeState, node);
    delete (treeState.toggled[id]);
    treeState.toggled[node.id] = toggled;
    /* Recursive move all children */
    if (treeState.children[id]) {
        for (let childId of treeState.children[id]) {
            let newPath = movePath + "/" + getFilename(childId);
            moveNode(treeState, childId, newPath);
        }
    }
}

function copyNode(treeState, id, movePath) {
    let node = Object.assign({}, treeState.nodeById[id]);
    let toggled = treeState.toggled[id];
    checkAddDirectories(treeState, movePath);
    changeNodePath(node, movePath);
    addNode(treeState, node);
    /* Recursive copy all children */
    if (treeState.children[id]) {
        for (let childId of treeState.children[id]) {
            let newPath = movePath + "/" + getFilename(childId);
            copyNode(treeState, childId, newPath);
        }
        sortNodes(treeState, id);
    }
}

function getParent(treeState, node) {
    if (typeof node.parentId == "undefined") {
        if (node.id && treeState.nodeById[node.id] && typeof treeState.nodeById[node.id].parentId != "undefined") {
            node.parentId = treeState.nodeById[node.id].parentId;
        } else {
            node.parentId = null;
        }
    }
    return node.parentId;
}

export function updateNodeHandler(state, { id, node, children, loading, error, movePath, type }) {
    let treeState = Object.assign({}, state);
    treeState.nodeById = Object.assign({}, treeState.nodeById);
    treeState.children = Object.assign({}, treeState.children);
    treeState.toggled = Object.assign({}, treeState.toggled);

    if (type == "remove") {
        let parentId = treeState.nodeById[id].parentId;
        if (parentId == null) {
            treeState.rootNodesIds = treeState.rootNodesIds.slice();
        } else {
            treeState.children[parentId] = treeState.children[parentId].slice();
        }
        removeNode(treeState, id);
        return treeState;
    }

    if (type == "move") {
        moveNode(treeState, id, movePath);
        treeState.nodeById[movePath].name = getFilename(treeState.nodeById[movePath].path);
        treeState.nodeById[movePath].label = treeState.nodeById[movePath].name;
        return treeState;
    }

    if (type == "copy") {
        copyNode(treeState, id, movePath);
        treeState.nodeById[movePath].name = getFilename(treeState.nodeById[movePath].path);
        treeState.nodeById[movePath].label = treeState.nodeById[movePath].name;
        return treeState;
    }

    treeState.nodeById[id] = Object.assign({}, treeState.nodeById[id], node);
    if (typeof node != "undefined") {
        node.parentId = getParent(treeState, node);
        if (type == "add") {
            setupNodeChildrens(treeState, node.parentId);
        }
        recursiveParseTree(treeState, [node], node.parentId);
    }

    if (typeof loading != "undefined") {
        treeState.nodeById[id].loading = loading;
    }

    if (typeof error != "undefined") {
        treeState.nodeById[id].error = error
    }

    if (typeof children != "undefined") {
        treeState.children[id] = [];
        recursiveParseTree(treeState, children, id);
    }
    return treeState;
}

export function openNodeHandler(state, { id }) {
    let treeState = Object.assign({}, state);
    treeState.toggled = Object.assign({}, treeState.toggled);

    treeState.active = id;
    let node = treeState.nodeById[id];
    if (!node) {
        console.error("Can't open node '" + id + "'");
        return treeState;
    }
    let parentId = node.parentId;
    while (typeof node.parentId != "undefined", node.parentId != null) {
        if (!treeState.toggled[node.parentId]) {
            treeState.toggled[node.parentId] = true;
        }
        node = treeState.nodeById[node.parentId];
    }
    return treeState;
}

function filterNode(node, { keyWords }) {
    for (let word of keyWords) {
        if (node.name.indexOf(word) == -1) {
            return false;
        }
    }
    return true;
}

function recursiveFilterNode(state, nodes, { keyWords }) {
    for (let i = nodes.length - 1; i >= 0; --i) {
        let filtered = filterNode(state.nodeById[nodes[i]], { keyWords });
        if (filtered) {
            continue;
        }
        if (state.children[nodes[i]]) {
            recursiveFilterNode(state, state.children[nodes[i]], { keyWords });
        }
        if (!state.children[nodes[i]] || !state.children[nodes[i]].length) {
            removeNode(state, nodes[i]);
        }
    }
}

export function filterTreeHandler(state, { keyWords }) {
    let treeState = Object.assign({}, state);
    treeState.nodeById = Object.assign({}, treeState.nodeById);
    treeState.children = Object.assign({}, treeState.children);
    recursiveFilterNode(treeState, treeState.rootNodesIds, { keyWords });
    return treeState;
}

/*
export {
    waitForTreeHandler,
    receiveInfoHandler,
    receiveTreeHandler,
    receiveTreeErrorHandler,
    toggleSubTreeHandler,
    updateNodeHandler,
    openNodeHandler,
    filterTreeHandler
}
*/