import React from 'react';
import { FormattedMessage } from 'react-intl';
import ClearButton from './clearbutton.jsx';
import CimTableBase from '../table/table.jsx';

const formattedMessageIdByCache = {
    "menu": "CACHE_MENU",
    "tableHeader": "CACHE_TABLE_HEADER",
    "tableData": "CACHE_TABLE_DATA",
    "finderSourceInfo": "CACHE_FINDER_SOURCE_INFO",
    "treeData": "CACHE_TREE_DATA",
    "treeNode": "CACHE_TREE_NODE",
    "treeSubject": "CACHE_TREE_SUBJECT",
    "treeChildren": "CACHE_TREE_CHILDREN",
    "treeParents": "CACHE_TREE_PARENTS",
    "criteriaTreeData": "CACHE_CRITERIA_TREE_DATA",
    "fragmentTreeData": "CACHE_FRAGMENT_TREE_DATA",
    "classTreeData": "CACHE_CLASS_TREE_DATA",
    "classInfo": "CACHE_CLASS_INFO",
    "simpleClassInfo": "CACHE_SIMPLE_CLASS_INFO",
    "relationBindings": "CACHE_RELATION_BINDINGS",
    "predicateBindings": "CACHE_PREDICATE_BINDINGS",
    "encodedLoginInfo": "CACHE_ENCODED_LOGIN_INFO",
    "iotEvent": "CACHE_IOT_EVENT",
    "subjectId": "CACHE_SUBJECT_ID",
    "query": "CACHE_QUERY",
    "loginPageInfo": "CACHE_LOGIN_INFO",
    "encodedLoginPageInfo": "CACHE_ENCODED_LOGIN_INFO",
    "usersByName": "CACHE_USERS_BY_NAME"
}

class Container extends React.PureComponent {

    constructor(props) {
        super(props);

        this.props.fetchList();

        this.clearFormatter = this.clearFormatter.bind(this);

        this.columns = [{
            field: "clear",
            width: 100,
            sortable: false,
            dataFormat: this.clearFormatter,
            label: <ClearButton
                clearCache={this.props.clearAllCache}
            />
        },
        {
            field: "label",
            width: 350,
            sortable: false,
            dataFormat: this.labelFormatter,
            label: <FormattedMessage
                id="CACHE_HEADER_LABEL"
                defaultMessage="Label"
                description="Label of cache label column header" />
        },
        {
            key: true,
            field: "id",
            width: 350,
            sortable: false,
            label: <FormattedMessage
                id="CACHE_HEADER_IDENTIFIER"
                defaultMessage="Identifier"
                description="Label of Identifier column header" />
        }];
    }

    labelFormatter(cell, row) {
        if(formattedMessageIdByCache[cell]){
            return (<FormattedMessage id={formattedMessageIdByCache[cell]} />);
        }
        return cell;
    }

    clearFormatter(cell, row) {
        return (<ClearButton
            id={row.id}
            clearCache={this.props.clearCache}
        />);
    }

    render() {

        const data = [];
        for (let id of this.props.list) {
            data.push({
                id: id,
                label: id
            });
        }

        return (<CimTableBase
            withoutFetch={true}
            data={data}
            header={this.columns}
            rowHeight={50}
            selectType="none"
            loading={this.props.loading}
            error={this.props.error}
            messages={this.props.messages}>
        </CimTableBase>);
    }
}

export default Container;


