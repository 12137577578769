import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tree from '../tree/tree.jsx';
import CimFiller from '../filler/cimfiller.jsx';
import DebounceInput from '../debounceinput.jsx';
import { createDropFileContainer } from '../../filedrop.jsx';
import { SRC_TREE_SEARCH_PLACEHOLDER } from '../../constants/srctree.js';
import { MODAL_STATUS_OK } from '../../constants/modal.js';

const fill = {
    height: "100%",
    width: "100%",
    overflowY: "hidden"
};

class SrcTree extends React.Component {

    constructor(props) {
        super(props);

        this.import = this.import.bind(this);
        this.execute = this.execute.bind(this);
        this.searchKeyDown = this.searchKeyDown.bind(this);
    }

    execute() {
        window.open(this.props.contextPath + "rest/script" + this.props.hashPath, "_blank");
    }

    import() {
        let _this = this;
        let _modal = null;
        const DropFile = createDropFileContainer(this.props.importUrl, "single", function () {
            if (!_modal) {
                return;
            }
            _modal.closeModal(MODAL_STATUS_OK);
            _this.props.fetchSubTree();
        });
        let options = {
            title: { id: "SRC_TREE_IMPORT" },
            body: (<DropFile hideUploadButton={true} registerProgressBar={(dropFileRef) => _this.dropFile = dropFileRef} />),
            onOk: function (modal) {
                if (!_this.dropFile
                    || !_this.dropFile.props
                    || !_this.dropFile.props.filedrop
                    || _this.dropFile.props.filedrop.started) {
                    return;
                }
                _modal = modal;
                _this.dropFile.triggerUpload();
            }
        }
        this.props.openModal("srcImport", "common", options);
    }

    searchKeyDown(reactEvent) {
        switch (reactEvent.keyCode) {
            case 13:
                this.props.search(this.props.searchString);
                break;
            default:
                break;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        /* After tree loaded select node */
        if (!prevProps.active
            && prevProps.loading == true
            && this.props.loading == false
            && this.props.hashPath
            && this.props.hashPath != "/") {
            this.props.openNode(this.props.hashPath);
        }
    }

    render() {
        return (
            <div style={fill}>
                <div className="card panel-src-tree">
                    <div className="card-header clearfix">
                        <h4 className="card-title pull-left" style={{ marginTop: 7 }}>
                            <strong>
                                <FormattedMessage
                                    id="SRCTREE_TITLE"
                                    defaultMessage="Explorer"
                                    description="Title of src tree" />
                            </strong>
                        </h4>
                        <div className="btn-toolbar pull-right" role="toolbar" aria-label="...">
                            <div className="btn-group btn-group-xs" role="group" aria-label="..." style={{ marginRight: 5 }} onKeyDown={this.searchKeyDown}>
                                <DebounceInput
                                    editable={true}
                                    valid={true}
                                    value={this.props.searchString}
                                    format={"string"}
                                    placeholder={this.props.messages["SRC_TREE_SEARCH_PLACEHOLDER"]}
                                    change={this.props.search}
                                    locale={this.props.locale} >
                                </DebounceInput >
                            </div>
                            {this.props.treeId == "scripteditor" ?
                                <div className="btn-group btn-group-xs" role="group" aria-label="...">
                                    <button type="button" className="btn btn-danger" onClick={this.execute}>
                                        <i className="fa fa-play fa-fw" aria-hidden="true"></i>
                                        <FormattedMessage
                                            id="SRCTREE_MENU_EXECUTE"
                                            defaultMessage="Execute"
                                            description="User should click the button to execute script" />
                                    </button>
                                </div>
                                :
                                null}
                            <div className="btn-group btn-group-xs" role="group" aria-label="...">
                                <button type="button" class="btn btn-secondary" onClick={this.import}>
                                    <em className="fa fa-fw fa-download"></em>
                                    <FormattedMessage id="SRCTREE_MENU_IMPORT"
                                        defaultMessage="Import"
                                        description="User should click this item to import tree node" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <CimFiller updateDelay={5}>
                        <div className="card-body panel-src-tree-body">
                            <Tree {...this.props} />
                        </div>
                    </CimFiller>
                </div>
            </div>
        );
    }
}

export default SrcTree;