import React from 'react';
import { FormattedMessage } from 'react-intl';
import debounce from '../services/debounce';
import moment from 'moment';
import { timepicker } from './timepicker.js';
import {
    validate,
    fromRedux,
    toRedux
} from '../services/formatvalidator.js';

const DEBOUNCE_INPUT_INVALID_FLOAT_FORMAT = (<FormattedMessage
    id="FORMAT_VALIDATOR_INVALID_FLOAT"
    defaultMessage="Invalid float format"
    description="Input contains invalid float format" />);

const DEBOUNCE_INPUT_INVALID_INTEGER_FORMAT = (<FormattedMessage
    id="FORMAT_VALIDATOR_INVALID_INTEGER"
    defaultMessage="Invalid integer format"
    description="Input contains invalid integer format" />);

const DEBOUNCE_INPUT_INVALID_DATE_FORMAT = (<FormattedMessage
    id="FORMAT_VALIDATOR_INVALID_DATE"
    defaultMessage="Invalid date format"
    description="Input contains invalid date format" />);

const DEBOUNCE_INPUT_INVALID_DATE_TIME_FORMAT = (<FormattedMessage
    id="FORMAT_VALIDATOR_INVALID_DATE_TIME"
    defaultMessage="Invalid date/time format"
    description="Input contains invalid date/time format" />);

const toolbarButtonStyle = {
    padding: "2px 8px"
};

class DebounceInput extends React.Component {

    constructor(props) {
        super(props);
        this.delayedHandleChange = this.delayedHandleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.blur = this.blur.bind(this);
        this.keyDown = this.keyDown.bind(this);
        this.changeDate = this.changeDate.bind(this);
    }

    finish() {
        if (this.debounceFunc) {
            this.debounceFunc.flush(); //Send change on finish
        }
        if (this.props.onFinish) {
            const prevValue = this.getValue();
            const result = validate(this.syncValue, this.props.format, this.props.options);
            if (result.valid && result.value != prevValue) {
                this.props.onFinish(result.value);
            }
        }
    }

    blur(event) {
        this.finish();
    }

    keyDown(event) {
        if (event.keyCode == '13') {
            this.finish();
            event.preventDefault();
        }
    }

    getValue() {
        return fromRedux(this.props.value, this.props.format, this.props.options);
    }

    debounce() {
        //Create debounce function on demand
        if (!this.debounceFunc) {
            if (this.props.debounceFactory) { //use supplied debounce factory
                this.debounceFunc = this.props.debounceFactory(this.delayedHandleChange);
            } else { //use default debounce implementaion
                this.debounceFunc = debounce(this.delayedHandleChange, 200);
            }
        }
        if (this.props.editable) { //If user is too fast it will cause send data after editable is closed
            this.debounceFunc();
        }
    }

    delayedHandleChange() {
        //console.log("Text.delayedHandleChange (value, editable): ", this.syncValue, this.props.editable);
        if (!this.props.editable) { //Do not send data to redux if element is already not editable
            console.error("DebounceInput.delayedHandleChange() in non-editable mode!!!!");
            return;
        }
        this.wasChanged = true;
        const prevValue = this.getValue();
        const result = validate(this.syncValue, this.props.format, this.props.options);
        if (prevValue == result.value && this.props.valid) { //Do not send events if everything is equal
            return;
        }
        if (result.valid) {
            if (this.props.change) {
                this.props.change(toRedux(result.value, this.props.format, this.props.options));
                if (this.datepicker) {
                    this.datepicker.datepicker("update", this.convertToDatepicker(result.value));
                }
                if (this.timepicker) {
                    this.timepicker.setTime(this.convertToDatepicker(result.value));
                }
            }
        } else {
            if (this.props.signalInvalidFormat) {
                this.props.signalInvalidFormat(result.error);
            }
        }
    }

    handleChange(event) {
        //console.log("DebounceInput.handleChange: ", event.target.value);
        this.syncValue = event.target.value; //Save value in class property so that changes will be synchronious!!!! 
        this.debounce(); //Schedule later redux update
    }

    componentWillReceiveProps(nextProps) {
        const nextValue = fromRedux(nextProps.value, nextProps.format, this.props.options);
        if (nextProps.editable) {
            if (!this.props.editable) {
                //Set flag that data was not changed (user did not typed anything)
                this.wasChanged = false;
                //Update state in transition to editable mode
                //We also update DOM because editable is changed in the same time as predicates when obtaining lock
                this.textInput.value = this.syncValue = nextValue;
            } else if (!this.wasChanged && this.textInput.value != nextValue) {
                //Text was changed by fill
                this.textInput.value = this.syncValue = nextValue;
                if (this.datepicker) {
                    this.datepicker.datepicker("update", this.convertToDatepicker(this.syncValue));
                }
                console.log("Changed by fill: ", this.syncValue);
            }
        } else if (!nextProps.editable && this.textInput && this.textInput.value != nextValue) {
            //Update DOM in non-editable mode (not as event but always for automation to work)
            this.textInput.value = nextValue;
        }
    }

    getPlaceholder() {
        if (this.props.placeholder) {
            return this.props.placeholder;
        }
        if (this.props.format == "float") {
            return "0.0";
        }
        if (this.props.format == "integer" || this.props.format == "int") {
            return "0";
        }
        return "";
    }

    getStyle(style) {
        if (!style) {
            style = {};
        }
        style.textAlign = "left";
        return style;
    }

    getFormatString() {
        if (this.props.format == "date") { //'L'
            return 'L';
        } else if (this.props.format == "dateTime") { //'L LTS'
            if (this.props.options && this.props.options.milliseconds) {
                return 'L HH:mm:ss.SSS';
            }
            return 'L LTS';
        }
        return null;
    }

    convertToDatepicker(value) {
        if (value == "") {
            return "";
        }
        const formatString = this.getFormatString();
        if (formatString == null) {
            return "";
        }
        return moment(value, formatString).toDate();
    }

    changeDate(e) {
        if (this.debounceFunc) {
            this.debounceFunc.flush(); //Send change on finish
        }
        if (!this.textInput) {
            return;
        }
        if (!e.date) {
            if (e.time) {
                /* Change only time without date */
                let value = this.getValue();
                let date;
                if (value) {
                    date = this.convertToDatepicker(value);
                }
                if (!date || isNaN(date.getTime())) {
                    date = new Date();
                }
                date.setHours(e.time.hours);
                date.setMinutes(e.time.minutes);
                date.setSeconds(e.time.seconds);
                date.setMilliseconds(e.time.milliseconds || 0);
                let formattedValue = moment(date).format(this.getFormatString());

                this.syncValue = this.textInput.value = formattedValue;
            } else {
                this.syncValue = this.textInput.value = "";
            }
        } else {
            if (this.props.format == "date") { //'L'
                this.syncValue = this.textInput.value = moment(e.date).format('L');
            } else if (this.props.format == "dateTime") { //'L LTS' or 'L HH:mm:ss.SSS'
                /* Change only date without time */
                let value = this.getValue();
                if (this.timepicker) {
                    e.date.setHours(this.timepicker.hours);
                    e.date.setMinutes(this.timepicker.minutes);
                    e.date.setSeconds(this.timepicker.seconds);
                    if (this.props.options && this.props.options.milliseconds) {
                        e.date.setMilliseconds(this.timepicker.milliseconds);
                    }
                } else if (value) {
                    let currentDate = this.convertToDatepicker(value);
                    e.date.setHours(currentDate.getHours());
                    e.date.setMinutes(currentDate.getMinutes());
                    e.date.setSeconds(currentDate.getSeconds());
                    if (this.props.options && this.props.options.milliseconds) {
                        e.date.setMilliseconds(currentDate.milliseconds);
                    }
                }
                this.syncValue = this.textInput.value = moment(e.date).format(this.getFormatString());
            }
        }
        this.delayedHandleChange();
        this.finish();
    }

    setupDatePicker(btn) {
        if (this.datepicker) {
            return;
        }
        this.datepicker = $(btn).datepicker({
            language: this.props.locale,
            autoclose: true,
            clearBtn: true
        }).on('changeDate', this.changeDate).datepicker("update", this.convertToDatepicker(this.getValue()));
        if (this.props.format == "dateTime") {
            let datepicker = $(this.datepicker).data("datepicker");
            /* Setup time template */
            if (!this.timepicker && datepicker) {
                this.timepicker = timepicker({
                    initialTime: this.convertToDatepicker(this.getValue()),
                    changeDate: (function (time, objectTime) {
                        this.changeDate({ time: objectTime });
                    }).bind(this),
                    showMilliseconds: Boolean(this.props.options && this.props.options.milliseconds)
                });
                let cell = $("<td colspan='7' class='timepicker-container'></td>").append("<hr>").append(this.timepicker.picker);
                let row = $("<tr></tr>").append(cell);
                $("tfoot", $(".datepicker-days", datepicker.picker)).prepend(row);
            }
        }
    }

    getLabel() {
        if (this.props.children) {
            return this.props.children;
        }
        return null;
    }

    getInput() {
        let type = "text";
        if (this.props.password) {
            type = "password";
        }
        const style = this.getStyle(this.props.style);
        //We use this hack with uncontrolled input to overcome IE problems:
        //updates from state are delayed because updates are handled in "another thread".
        //https://github.com/omcljs/om/issues/704
        return (<input type={type}
            defaultValue={this.getValue()}
            ref={(input) => this.textInput = input}
            onChange={this.handleChange}
            onBlur={this.blur}
            onKeyDown={this.keyDown}
            style={style}
            placeholder={this.getPlaceholder()}
            className={this.props.className ? this.props.className : ""}
            disabled={!this.props.editable} />);
    }

    getToolButton() {
        if (this.props.format == 'date' || this.props.format == 'dateTime') {
            return (<div className="input-group-append">
                <button
                    type="button"
                    disabled={!this.props.editable}
                    ref={(btn) => this.setupDatePicker(btn)}
                    style={toolbarButtonStyle}
                    className="input-group-text btn btn-secondary"><i className="fa fa-calendar" aria-hidden="true"></i>
                </button>
            </div>);
        }
        if (this.props.tool) {
            let toolBtns = Array.isArray(this.props.tool) ? this.props.tool : [this.props.tool];
            return (<div className="input-group-append">
                {toolBtns.map((tool) => <button
                    type="button"
                    disabled={!this.props.editable && !tool.enabled}
                    style={toolbarButtonStyle}
                    className={tool.className || "input-group-text btn btn-secondary"}
                    onClick={tool.onClick}>
                    <i className={`fa fa-${tool.icon}`} style={tool.iconStyle} aria-hidden="true"></i>
                </button>)}
            </div>);
        }
        return null;
    }



    render() {
        const label = this.getLabel();
        const tool = this.getToolButton();
        if (!label && !tool) {
            return this.getInput();
        }
        return (<div
            className="input-group input-group-sm"
            style={this.props.containerStyle}>
            {label}{this.getInput()}{tool}
        </div>);
    }
}

export default DebounceInput;