import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import shortid from 'shortid';
import Modal from '../modal.jsx';

import { MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../../messages.jsx';
import { sortedAdd } from '../../../services/table';

function findInArrayByKey(array, value, key) {
    let idx = 0;
    while (idx < array.length) {
        if (array[idx][key] == value) {
            return idx;
        }
        ++idx;
    }
    return -1;
}

function findInArray(array, value) {
    let idx = 0;
    while (idx < array.length) {
        if (array[idx] == value) {
            return idx;
        }
        ++idx;
    }
    return -1;
}

function clearSelection() {
    if (window.getSelection) {
        window.getSelection().removeAllRanges();
    } else { // старый IE
        document.selection.empty();
    }
}

function stopPropagation(event) {
    event.stopPropagation();
}

function compareColumns(column1, column2) {
    if (column1.label > column2.label) {
        return 1;
    }
    if (column1.label == column2.label) {
        return 0;
    }
    return -1;
}

function getColumnsOptions(columnSelection, normalizedColumns) {
    let options = [];
    columnSelection.map((columnId, index) => {
        let column = normalizedColumns.byId[columnId];
        options.push({ value: columnId, label: column.label });
    })
    return options;
}

class TableOptionsModal extends React.Component {

    constructor(props) {
        super(props);

        this.saveNewOptions = this.saveNewOptions.bind(this);
        this.addColumn = this.addColumn.bind(this);
        this.handleGrab = this.handleGrab.bind(this);

        this.state = {
            optionsColumns: [],
            selectedColumns: [],
            columnSelection: [],
            hiddenColumns: []
        }
    }

    handleGrab(event, index) {
        clearSelection();
        /* Drag only on left click */
        if (event.button != 0) {
            return;
        }
        event.stopPropagation();

        if (this.documentBinded) {
            $(document).trigger("mouseup");
        }
        this.documentBinded = true;

        let _this = this;
        const $element = $("<div class='cim-modal-dragging-element'>&nbsp;</div>");
        $("body").append($element);
        const elementHeight = 27;
        let y = event.clientY;

        $(document).on("mousemove.tableoptions", function (event) {
            clearSelection();
            let newY = event.clientY;
            $element.css("top", newY - 5);
            $element.css("left", event.clientX - 5);

            let indexSlice = Math.floor((newY - y) / elementHeight);
            if (indexSlice == 0) {
                return;
            }
            let newIndex = index + indexSlice;
            if (newIndex < 0) {
                indexSlice += 0 - newIndex;
                newIndex = 0;
            } else if (newIndex > _this.state.selectedColumns.length - 1) {
                indexSlice -= newIndex - _this.state.selectedColumns.length + 1;
                newIndex = _this.state.selectedColumns.length - 1;
            }
            if (index == newIndex) {
                return;
            }
            y += indexSlice * elementHeight;

            let selectedColumns = _this.state.selectedColumns.slice();
            let movingColumn = selectedColumns[index];
            selectedColumns.splice(index, 1);
            selectedColumns.splice(newIndex, 0, movingColumn);
            index = newIndex;
            _this.setState(Object.assign({}, _this.state, { selectedColumns }));
        });
        $(document).on("mouseup.tableoptions", function () {
            $(document).off(".tableoptions");
            $element.remove();
        });
    }

    getTitle() {
        return (<FormattedMessage
            id="NPT_TABLE_OPTIONS_TITLE"
            defaultMessage="Configure table"
            description="Configure table" />);
    }

    getBody() {
        let _this = this;
        return (<div className="container-fluid cim-modal-table-options">
            <div className="row form-group">
                <label for="columns" class="col-md-2 control-label">
                    <FormattedMessage
                        id="NPT_TABLE_OPTIONS_COLUMNS"
                        defaultMessage="Columns"
                        description="Columns" />
                </label>
                <div class="col-md-10" id="columns">
                    <div className="col-md-12 nopadding">
                        {this.state.columnSelection.length != 0 ?
                            <div className="col-md-12 input-group cim-finder-sidebar-fragment">
                                <Select
                                    name="column"
                                    className="minified-react-select"
                                    loadingPlaceholder={MSG_SELECT_LOADING}
                                    placeholder={MSG_SELECT_PLACEHOLDER}
                                    noResultsText={MSG_SELECT_NO_RESULTS}
                                    value={null}
                                    options={getColumnsOptions(this.state.columnSelection, this.state.normalizedColumns)}
                                    onChange={this.addColumn}
                                    clearable={false}
                                />
                            </div>
                            :
                            null}
                        {this.state.selectedColumns.map((columnId, index) => {
                            let column = _this.state.normalizedColumns.byId[columnId];
                            return column.autoGenerated ?
                                null
                                :
                                <div className="col-md-12 input-group input-group-sm cim-finder-sidebar-fragment table-column" onMouseDown={(event) => _this.handleGrab(event, index)}>
                                    <input type="text" className="form-control card-input" disabled="true" value={column.label} style={!column.dynamic && column.hidden ? { opacity: "0.5" } : null} />
                                    {column.dynamic ?
                                        <span className="input-group-append">
                                            <button className="btn btn-secondary btn-xs card-input cim-finder-sidebar-fragment-button" type="button" onMouseDown={stopPropagation} onClick={() => _this.removeColumn(columnId)}><i className="fa fa-times" aria-hidden="true"></i></button>
                                        </span>
                                        :
                                        null}
                                </div>
                        })}
                    </div>
                </div>
            </div>
        </div>);
    }

    parseOptions(options) {
        let normalizedColumns = {
            byId: {},
            allIds: []
        }
        let selectedColumnsPool = {};
        let selectedColumns = [];
        let columnSelection = [];
        let hiddenColumns = [];
        for (let column of options.header) {
            if (!column.field) {
                continue;
            }
            let id = shortid.generate();
            /* Check for duplicates */
            while (normalizedColumns.byId[id]) {
                id = shortid.generate();
            }
            normalizedColumns.byId[id] = column;
            normalizedColumns.allIds.push(id);
            if (!column.hidden) {
                selectedColumnsPool[column.field] = true;
                selectedColumns.push(id);
            } else if (!column.dynamic) {
                selectedColumnsPool[column.field] = true;
                hiddenColumns.push(id);
            }
        }

        for (let i = options.columns.length - 1; i >= 0; --i) {
            let column = options.columns[i];
            if (selectedColumnsPool[column.field] || !column.dynamic) {
                continue;
            }
            let id = shortid.generate();
            /* Check for duplicates */
            while (normalizedColumns.byId[id]) {
                id = shortid.generate();
            }
            normalizedColumns.byId[id] = column;
            normalizedColumns.allIds.push(id);
            sortedAdd(columnSelection, id, function (a, b) {
                let column2 = normalizedColumns.byId[b];
                return compareColumns(column, column2);
            });
        }
        this.setState(Object.assign({}, this.state, { normalizedColumns, columnSelection, selectedColumns, hiddenColumns }));
    }

    addColumn(newValueObject) {
        let columnId = newValueObject.value;
        if (typeof columnId == "undefined") {
            /* Happens when user use backspace on empty search */
            return;
        }
        let idx = findInArray(this.state.columnSelection, columnId);
        if (idx == -1) {
            return;
        }

        let nextState = Object.assign({}, this.state);
        nextState.columnSelection = nextState.columnSelection.slice();
        nextState.selectedColumns = nextState.selectedColumns.slice();

        nextState.columnSelection.splice(idx, 1);
        nextState.selectedColumns.push(columnId);
        this.setState(nextState);
    }

    removeColumn(columnId) {
        let idx = findInArray(this.state.selectedColumns, columnId);
        if (idx == -1) {
            return;
        }

        let nextState = Object.assign({}, this.state);
        nextState.columnSelection = nextState.columnSelection.slice();
        nextState.selectedColumns = nextState.selectedColumns.slice();

        let column = nextState.normalizedColumns.byId[columnId];
        sortedAdd(nextState.columnSelection, columnId, function (a, b) {
            let column2 = nextState.normalizedColumns.byId[b];
            return compareColumns(column, column2);
        });
        nextState.selectedColumns.splice(idx, 1);
        this.setState(nextState);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.options != nextProps.options) {
            this.parseOptions(this.props.options);
        }
    }

    componentDidMount() {
        this.parseOptions(this.props.options);
    }

    saveNewOptions() {
        let selectedColumns = [];
        let _this = this;
        const addColumns = function (columns) {
            for (let selectedColumnId of columns) {
                let column = _this.state.normalizedColumns.byId[selectedColumnId];
                if (column.autoGenerated) {
                    continue;
                }
                if (column.dynamic) {
                    column = Object.assign({}, column);
                    column.hidden = false;
                }
                selectedColumns.push(column);
            }
        }
        addColumns(this.state.selectedColumns);
        addColumns(this.state.hiddenColumns);
        return { headers: selectedColumns };
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for TableOptions*/
            title={this.getTitle()}
            body={this.getBody()}
            /**Specific callbacks for TableOptions*/
            result={this.saveNewOptions} />);
    }
}

export default TableOptionsModal;


