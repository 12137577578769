import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

import { MSG_BUTTON_SAVE, MSG_BUTTON_ADD, MSG_BUTTON_CANCEL, MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../../messages.jsx';
import {
    CLASSCARD_NOT_EMPTY_FIELD,
    CLASSCARD_MULTIPLICITY,
    CLASSCARD_DESCRIPTION,
    CLASSCARD_IDENTIFIER,
    CLASSCARD_LABEL
} from '../../../constants/classcard';
import { sortByAttr } from '../../../services/classcard';

const fullSize = { width: "100%", hetght: "100%" };
const relationDropdownStyle = { minWidth: "auto", width: "100%", padding: "5px" };

class RelationPopupBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            predicate: props.relation,
            reversePredicate: props.relation.reversePredicate,
            relationType: props.relation.classRelationInfo.relationType
        };

        this.validation = {
            predicate: {
                name: true,
                label: true
            },
            reversePredicate: {
                name: true,
                label: true
            }
        }

        this.selectClasses = [];
        if (props.allClasses) {
            for (let entry of props.allClasses.list) {
                let classData = Object.assign({}, entry);
                classData.name = entry.namespace ? entry.namespace + ":" + entry.name : entry.name;
                this.selectClasses.push(classData);
            }
            this.selectClasses = sortByAttr(this.selectClasses, "label");
        }
    }

    setInversion(inversion) {
        if (this.state.inverseRelation == inversion) {
            return;
        }
        this.setState(Object.assign({}, this.state, { inverseRelation: inversion }));
    }

    inputChangeHandler() {

    }

    selectChangeHandler() {

    }

    printClass(classData, classLabelId) {
        return (
            <div className="col-sm-4" style={{ textAlign: "center", color: "#3B87A5" }}>
                <div className="col-sm-12">
                    <h4>
                        <FormattedMessage id={classLabelId} />
                    </h4>
                </div>
                <div className="col-sm-12">
                    <h4>
                        {classData.label}
                    </h4>
                </div>
            </div>);
    }

    printRelation() {
        let classData = this.props.classData;
        let reverseClass = this.state.predicate.classRelationInfo.peerClass;
        if (this.state.predicate.classId == reverseClass.id) {
            classData = reverseClass;
            reverseClass = this.props.classData;
        }
        return (
            <div className="offset-sm-2 col-sm-10 row">
                {this.printClass(classData, "CLASSCARD_RELATION_POPUP_FIRST_CLASS")}
                <div className="col-sm-4" style={{ textAlign: "center" }}>
                    <div className={"btn-group direction-selector relation-" + this.props.relationTypeMap[this.state.relationType].relation.toLowerCase()}>
                        <button type="button" className={"btn btn-secondary dropdown-toggle disabled"} data-toggle="dropdown">
                            <span className={"direction-arrow " + (this.state.inverseRelation ? "image-inverse" : "")}>&nbsp;</span>
                        </button>
                        <div className="dropdown-menu" style={relationDropdownStyle}>
                            <a className='dropdown-item direction-arrow' onClick={() => this.setInversion(false)}>&nbsp;</a>
                            <a className='dropdown-item direction-arrow image-inverse' onClick={() => this.setInversion(true)}>&nbsp;</a>
                        </div>
                    </div>
                </div>
                {this.printClass(reverseClass, "CLASSCARD_RELATION_POPUP_SECOND_CLASS")}
            </div>);
    }

    printLabels() {
        return (
            <div className="row form-group">
                <div className="col-sm-12">
                    <label for="attributeName" className="control-label pull-right">
                        {CLASSCARD_IDENTIFIER}
                    </label>
                </div>
                <div className="col-sm-12">
                    <label for="attributeName" className="control-label pull-right">
                        {CLASSCARD_LABEL}
                    </label>
                </div>
                <div className="col-sm-12" style={{ marginBottom: 8 }}>
                    <label for="multiplicity" className="control-label pull-right">
                        {CLASSCARD_MULTIPLICITY}
                    </label>
                </div>
                <div className="col-sm-12">
                    <label for="attributeDescription" className="control-label pull-right">
                        {CLASSCARD_DESCRIPTION}
                    </label>
                </div>
            </div>);
    }

    printPredicate(predicateName) {
        let predicate = this.state[predicateName];
        let valid = this.validation[predicateName];
        let disabled = true;
        return (
            <div style={{ padding: "0px 15px" }}>
                <div className="row form-group">
                    <input type="text" id={predicateName + "Name"} className={"form-control" + (valid.name ? "" : " is-invalid")} value={predicate.name} onChange={this.inputChangeHandler.bind(this, predicateName, "name")} disabled={disabled} />
                    {!valid.name && <div className="invalid-feedback text-danger">{CLASSCARD_NOT_EMPTY_FIELD}</div>}
                </div>
                <div className="row form-group">
                    <input type="text" id={predicateName + "Label"} className={"form-control" + (valid.label ? "" : " is-invalid")} value={predicate.label} onChange={this.inputChangeHandler.bind(this, predicateName, "label")} disabled={disabled} />
                    {!valid.label && <div className="invalid-feedback text-danger">{CLASSCARD_NOT_EMPTY_FIELD}</div>}
                </div>
                <div className="row form-group">
                    <Select
                        name="multiplicity"
                        loadingPlaceholder={MSG_SELECT_LOADING}
                        placeholder={MSG_SELECT_PLACEHOLDER}
                        noResultsText={MSG_SELECT_NO_RESULTS}
                        value={predicate.multiplicity}
                        backspaceRemoves={false}
                        clearable={false}
                        searchable={false}
                        disabled={disabled}
                        options={this.props.multiplicityList}
                        labelKey={"template"}
                        onChange={this.selectChangeHandler.bind(this, predicateName, "multiplicity")}
                    />
                </div>
                <div className="row form-group">
                    <textarea type="text" id={predicateName + "Description"} className="form-control" value={predicate.description} onChange={this.inputChangeHandler.bind(this, predicateName, "description")} disabled={disabled} rows="3" />
                </div>
            </div>);
    }

    render() {
        return (
            <div style={fullSize} className="row">
                {this.printRelation()}
                <div className="col-sm-2">
                    {this.printLabels()}
                </div>
                <div className="col-sm-5">
                    {this.printPredicate("predicate")}
                </div>
                {this.state.reversePredicate ?
                    <div className="col-sm-5">
                        {this.printPredicate("reversePredicate")}
                    </div>
                    :
                    <div className="col-sm-5 disabled-side">
                        <div className="d-flex align-items-center">
                            <FormattedMessage
                                id="CLASSCARD_NO_REVERSE_PREDICATE"
                                defaultMessage="Relation doesn't have reverse predicate"
                                description="Relation doesn't have reverse predicate" />
                        </div>
                    </div>
                }
            </div >);
    }
}

export default RelationPopupBody;


