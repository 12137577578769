import * as Action from '../constants/alert';
import shortid from 'shortid';

export function addCustomAlert(messageText, dispatch) {
    let type = Action.ALERT_DANGER;
    if (messageText.indexOf("::") != -1) {
        [type, messageText] = messageText.split("::");
    }
    dispatch(addAlert(type, messageText));
}

///////////
//Actions//
///////////
export function addAlert(type, message) {
    let id = shortid.generate();
    //Send to redux
    return {
        type: Action.ADD_ALERT,
        payload: { id, type, message }
    }
}

export function removeAlert(id) {
    //Send to redux
    return {
        type: Action.REMOVE_ALERT,
        payload: { id }
    }
}