import React from 'react';
import { FormattedMessage } from 'react-intl';

class Basic extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    getFieldId() {
        return `fieldId${this.props.node.id}`; //TODO get predicate name with namespace
    }

    mandatory() {
        if (/*this.props.editable &&*/ this.props.node.mandatory) {
            return (<span className="text-danger">*</span>);
        }
        return null;
    }

    rowClass() {
        let cls = "row card-row";
        if (!this.props.valid) {
            cls += " is-invalid";
        }
        return cls;
    }

    inputClass() {
        let cls = "";
        if (!this.props.valid) {
            cls += " is-invalid";
        }
        return cls;
    }

    errorText() {
        if (this.props.error.id) {
            return (<FormattedMessage id={this.props.error.id} values={this.props.error.values} />);
        }
        return this.props.error;
    }

    error() {
        if (this.props.showErrors && !this.props.valid) {
            return (<p className="form-text invalid-feedback">{this.errorText()}</p>);
        }
        return null;
    }

    errorFooter() {
        if (this.props.showErrors && !this.props.valid) {
            return (<div className="card-footer has-error text-danger">
                <p className="form-text">{this.errorText()}</p>
            </div>);
        }
        return null;
    }

    gridLayout() {
        let labelOffset = this.props.node.options['grid-label-offset'];
        if (typeof labelOffset != 'number') {
            labelOffset = 0;
        }
        let inputOffset = this.props.node.options['grid-input-offset'];
        if (typeof inputOffset != 'number') {
            inputOffset = 0;
        }

        let labelWidth = this.props.node.options['grid-label-width'];
        let inputWidth = this.props.node.options['grid-input-width'];

        if (typeof labelWidth != 'number' && typeof inputWidth == 'number') {
            labelWidth = 12 - inputWidth - inputOffset - labelOffset;
        } else if (typeof labelWidth == 'number' && typeof inputWidth != 'number') {
            inputWidth = 12 - labelWidth - inputOffset - labelOffset;
        } else if (typeof labelWidth != 'number' && typeof inputWidth != 'number') {
            labelWidth = 6;
            inputWidth = 6;
        }
        let labelCls = 'col-md-' + labelWidth.toFixed();
        if (labelOffset > 0) {
            labelCls += ' col-md-offset-' + labelOffset.toFixed();
        }
        let inputCls = 'col-md-' + inputWidth.toFixed();
        if (inputOffset > 0) {
            inputCls += ' col-md-offset-' + inputOffset.toFixed();
        }
        return { labelCls, inputCls };
    }

    /**
     * Child components should call this method to wrap input elements of any type
     * Perpose of this method correctly layout input
     */
    wrapInput(label, input, description) {
        const id = this.getFieldId();
        const g = this.gridLayout();
        const style = this.props.visible ? null : { display: "none" };
        if (!input.props) {
            input.props = {
                className: ""
            };
        }
        input.props.className = input.props.className + this.inputClass();
        return <div className={this.rowClass()} style={style}>
            <label htmlFor={id} className={`${g.labelCls} control-label text-right`} >
                <span data-toggle="tooltip" data-placement="top" title={description}>{label}</span>
                {this.mandatory()}
            </label>
            <div className={g.inputCls}>
                {input}
                {this.error()}
            </div>
        </div>;
    }

    /**
     * Special wrapper for checkbox
     */
    wrapCheckbox(label, checkbox, description) {
        const g = this.gridLayout();
        const style = this.props.visible ? null : { display: "none" };
        return (
            <div className={this.rowClass()} style={style}>
                <div className={g.labelCls}><span> </span></div>
                <div className={g.inputCls}>
                    <div className="checkbox">
                        <label>
                            {checkbox}
                            <span data-toggle="tooltip" data-placement="top" title={description}>{label}</span>
                            {this.mandatory()}
                        </label>
                        {this.error()}
                    </div>
                </div>
            </div>);
    }

    /**
     * Child components should call this method to wrap large objects like
     * tables
     */
    wrapLarge(label, largeObject) {
        const style = this.props.visible ? null : { display: "none" };
        return (
            <div className="card" style={style}>
                <div className="card-header bg-info">
                    <h6 className="card-title font-weight-bold">{label} {this.mandatory()}</h6>
                </div>
                <div className="card-body">
                    {largeObject}
                </div>
                {this.errorFooter()}
            </div>);
    }

    wrapToolbar(label, toolbar, largeObject) {
        const style = this.props.visible ? null : { display: "none" };
        return (
            <div className="card" style={style}>
                <div className="card-header clearfix bg-info">
                    <h6 className="card-title pull-left font-weight-bold">{label} {this.mandatory()}</h6>
                    {toolbar}
                </div>
                <div className="card-body">
                    {largeObject}
                </div>
                {this.errorFooter()}
            </div>);
    }

    render() {
        return (<span>Basic component</span>);
    }
}

export default Basic;