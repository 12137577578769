'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { VelocityComponent } from 'velocity-react';
import { loadingMessage, errorMessage, noLabelMessage } from '../common.jsx';
import { FormattedMessage } from 'react-intl';

const Loading = ({ style }) => {
    return <div style={style}>
        {loadingMessage}
    </div>;
};
Loading.propTypes = {
    style: PropTypes.object
};

const Error = ({ style }) => {
    return <div style={style}>
        {errorMessage}
    </div>;
};
Error.propTypes = {
    style: PropTypes.object
};

const Toggle = ({ style }) => {
    const { height, width } = style;
    const midHeight = height * 0.5;
    const points = `0,0 0,${height} ${width},${midHeight}`;

    return (
        <div style={style.base}>
            <div style={style.wrapper}>
                <svg height={height} width={width}>
                    <polygon points={points}
                        style={style.arrow} />
                </svg>
            </div>
        </div>
    );
};
Toggle.propTypes = {
    style: PropTypes.object
};

const Header = ({ name, style }) => {
    return (
        <div style={style.base}>
            <div style={style.title}>
                {name ? name : noLabelMessage}
            </div>
        </div>
    );
};
Header.propTypes = {
    style: PropTypes.object,
    name: PropTypes.string,
    formattedName: PropTypes.string
};

@Radium
class Container extends React.Component {

    printName() {
        if (this.props.formattedName) {
            return <FormattedMessage id={this.props.formattedName} />;
        }
        return this.props.name;
    }

    render() {
        const { style, decorators, terminal, onClick } = this.props;

        return (
            <div onClick={onClick}
                ref={ref => this.clickableRef = ref}
                style={style.container}>
                {!terminal ? this.renderToggle() : null}

                <decorators.Header
                    {...this.props}
                    name={this.printName()}
                    style={style.header} />
            </div>
        );
    }

    renderToggle() {
        const { animations } = this.props;

        if (!animations) {
            return this.renderToggleDecorator();
        }

        return (
            <VelocityComponent animation={animations.toggle.animation}
                duration={animations.toggle.duration}
                ref={ref => this.velocityRef = ref}>
                {this.renderToggleDecorator()}
            </VelocityComponent>
        );
    }

    renderToggleDecorator() {
        const { style, decorators } = this.props;

        return <decorators.Toggle style={style.toggle} />;
    }
}
Container.propTypes = {
    style: PropTypes.object.isRequired,
    decorators: PropTypes.object.isRequired,
    terminal: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    animations: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool
    ]).isRequired
};

export default {
    Loading,
    Error,
    Toggle,
    Header,
    Container
};
