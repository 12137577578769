function getScrollableParents(element) {
    const scrollableParents = [];
    let parentNode = element.parentNode;
    while (parentNode != document) {
        const overflow = getComputedStyle(parentNode).overflow;
        if (typeof overflow != "string") {
            parentNode = parentNode.parentNode;
            continue;
        }
        if (overflow.indexOf("auto") != -1 || overflow.indexOf("scroll") != -1) {
            scrollableParents.push(parentNode);
        }
        parentNode = parentNode.parentNode;
    }
    return scrollableParents;
}

/* After dropdown menu had shown we must modify its' view to overflow any parent containers */
function fixDropdownMenuView(dropdownElement, selectElement, options = { scrollInterception: null, preventDeletion: false, preventScrollUnbind: false }) {
    if (!dropdownElement || !selectElement) {
        console.error("Can't fix dropdown menu: one of required html elements is missing");
        return null;
    }
    dropdownElement.style["position"] = "fixed";
    /* After element was fixed layout may change because of scrollbar,
     * so we will use select element to get all properties we need */
    const selectRect = selectElement.getBoundingClientRect();
    dropdownElement.style["width"] = `${selectRect.width}px`;
    dropdownElement.style["left"] = `${selectRect.left}px`;
    dropdownElement.style["top"] = `${selectRect.bottom}px`;
    /* When we fix menu it can overfloat visible window; to prevent
     * this check if bottom line of menu is lower than window height*/
    const menuRect = dropdownElement.getBoundingClientRect();
    if (menuRect.bottom > window.innerHeight) {
        dropdownElement.style["top"] = "auto";
        dropdownElement.style["bottom"] = `${window.innerHeight - selectRect.top - 1}px`;
    }
    /* Fixed menu's view on html scroll is very difficult to handle,
     * so we must find all scrollable parents and Intercept scroll action */
    const scrollableParents = getScrollableParents(selectElement);
    $(scrollableParents).on("scroll.select.dropdown.fixed", function () {
        if (options.preventScrollUnbind !== true) {
            $(scrollableParents).off("scroll.select.dropdown.fixed");
        }
        if (typeof options.scrollInterception == "function") {
            options.scrollInterception(scrollableParents);
        }
        if (options.preventDeletion !== true) {
            dropdownElement.remove();
        }
    });
    /* Return binded parent elements and cancel function */
    return {
        scrollableParents: scrollableParents,
        cancel: function () {
            $(scrollableParents).off("scroll.select.dropdown.fixed");
        }
    };
}

export class SelectObserver {

    selectElement;
    selectInput;
    observer;
    dropdownOptions;

    constructor(selectElement, selectInput) {
        this.selectElement = selectElement || null;
        this.selectInput = selectInput || null;
        this.observer = null;
        this.dropdownOptions = null;
    }

    changeTarget(selectElement, selectInput) {
        this.stop();
        this.selectElement = selectElement || null;
        this.selectInput = selectInput || null;
    }

    start(selectElement, selectInput) {
        this.stop();
        if (selectElement && selectInput) {
            this.changeTarget(selectElement, selectInput);
        }
        const element = this.selectElement;
        const input = this.selectInput;
        if (!element || !input) {
            return;
        }
        this.observer = new MutationObserver((mutations) => {
            const removedNodes = mutations[0].removedNodes;
            if (removedNodes && removedNodes.length != 0) {
                if (this.dropdownOptions) {
                    this.dropdownOptions.cancel();
                }
            }
            const addedNodes = mutations[0].addedNodes;
            if (!addedNodes || addedNodes.length == 0) {
                return;
            }
            const selectMenu = addedNodes[0];
            this.dropdownOptions = fixDropdownMenuView(selectMenu, input, {
                preventDeletion: true,
                scrollInterception: function (scrollableParents) {
                    $(document.activeElement).blur();
                }
            });
        });
        this.observer.observe(element, {
            childList: true
        });
    }

    stop() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
        if (this.dropdownOptions) {
            this.dropdownOptions.cancel();
            this.dropdownOptions = null;
        }
    }
}