import React from 'react';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import TagSelect from '../../tagselect/tagselect.jsx';
import MaskedInput from '../../srceditor/maskedinput.jsx';

import { MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../../messages.jsx';
import {
    CLASSCARD_NOT_EMPTY_FIELD,
    CLASSCARD_PACKAGE,
    CLASSCARD_IDENTIFIER,
    CLASSCARD_LABEL,
    CLASSCARD_NAMESPACE,
    CLASSCARD_PARENTS_CLASSES,
    CLASSCARD_STEREOTYPE,
    CLASSCARD_STORE_TYPE,
    CLASSCARD_DEPTH,
    CLASSCARD_DESCRIPTION,
    DEPTHS,
    NAME_SYMBOLS,
    NAME_FIRST_SYMBOL,
    LABEL_SYMBOLS,
    LABEL_FIRST_SYMBOL
} from '../../../constants/classcard';
import { sortByAttr } from '../../../services/classcard';

const fullSize = { width: "100%", hetght: "100%" };

function removeItemsFromArray(array, items, check) {
    let deletingItems = items.slice();
    arrayCycle: for (let i = array.length - 1; i >= 0; --i) {
        for (let j = deletingItems.length - 1; j >= 0; --j) {
            if (check(array[i], deletingItems[j])) {
                deletingItems.splice(j, 1);
                array.splice(i, 1);
                if (deletingItems.length == 0) {
                    break arrayCycle;
                }
                continue arrayCycle;
            }
        }
    }
}

function parseStereotype(stereotypeList) {
    let parsedStereotypeList = [];
    for (let stereotypeItem of stereotypeList) {
        parsedStereotypeList.push({ value: stereotypeItem.value, label: stereotypeItem.stereotype || "--" });
    }
    return parsedStereotypeList;
}

function parseStoreType(storeTypeList) {
    let parsedStoreTypeList = [];
    for (let storeTypeItem of storeTypeList) {
        parsedStoreTypeList.push({ value: storeTypeItem.value, label: <FormattedMessage id={"CLASSCARD_STORE_TYPE_" + storeTypeItem.storeType.toUpperCase()} /> });
    }
    return parsedStoreTypeList;
}

class ClassPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            parentClasses: []
        };

        this.suggestions = props.allClasses ? props.allClasses.list.slice() : [];
        for (let suggestion of this.suggestions) {
            suggestion.className = suggestion.name;
            if (suggestion.namespace) {
                suggestion.className = suggestion.namespace + ":" + suggestion.className;
            }
        }

        this.stereotypeList = parseStereotype(props.stereotypeList);
        this.storeTypeList = parseStoreType(props.storeTypeList);
        this.namespaceList = sortByAttr(props.namespaceList, "prefix");

        if (props.classData.id) {
            this.state.name = props.classData.name;
            this.state.label = props.classData.label;
            this.state.package = props.packages.packageById[props.classData.packageId] || {};
            this.state.depth = props.classData.enumerationDepth ? props.classData.enumerationDepth : 1;
            this.state.description = props.classData.description;
            this.state.namespace = props.namespaceMap[props.classData.namespaceId];
            this.state.id = props.classData.id;
            this.changeStoreType(this.state, props.classData.storeType != null ? props.classData.storeType : 0);
            this.changeStereotype(this.state, props.classData.stereotype != null ? props.classData.stereotype : "null");
        } else {
            this.disablePackageSelect = true;
            this.state.package = props.packages.packageById[props.classData.packageId];
            this.state.namespace = props.namespaceMap[this.state.package.namespaceId];
            this.state.depth = 1;
            this.changeStoreType(this.state, 0);
            this.changeStereotype(this.state, "null");
        }

        if (props.classParents) {
            for (let classData of props.classParents) {
                if (classData.classId == props.classData.id) {
                    continue;
                }
                classData.name = classData.peerClass.name;
                classData.id = classData.parentClassId;
                classData.className = classData.peerClass.namespace.prefix + ":" + classData.name;
                this.state.parentClasses.push(classData);
            }
            removeItemsFromArray(this.suggestions, this.state.parentClasses, function (a, b) { return a.id == b.id; });
        }

        this.validName = this.validateName(this.state.name);
        this.validLabel = this.validateLabel(this.state.label);


        this.changeStoreType = this.changeStoreType.bind(this);
        this.changeStereotype = this.changeStereotype.bind(this);
        this.changePackageHandler = this.changePackageHandler.bind(this);
        this.changeNamespaceHandler = this.changeNamespaceHandler.bind(this);
        this.addParentClassHandler = this.addParentClassHandler.bind(this);
        this.removeParentClassHandler = this.removeParentClassHandler.bind(this);
    }

    validateName(name) {
        return !!name;
    }

    validateLabel(label) {
        return !!label;
    }

    changeStoreType(state, storeTypeValue) {
        state["storeType"] = storeTypeValue;
        state["storeTypeName"] = this.props.storeTypeMap[storeTypeValue].storeType;
    }

    changeStereotype(state, stereotypeValue) {
        state["stereotypeId"] = stereotypeValue;
        state["stereotypeName"] = this.props.stereotypeMap[stereotypeValue].stereotype;
    }

    selectChangeHandler(variable, newValue) {
        let newState = Object.assign({}, this.state);
        switch (variable) {
            case "storeType":
                this.changeStoreType(newState, newValue.value);
                break;
            case "stereotypeId":
                this.changeStereotype(newState, newValue.value);
                break;
            default:
                newState[variable] = newValue.value;
                break;
        }
        this.setState(newState);
    }

    maskedInputChangeHandler(variable, newValue) {
        if (this.state[variable] == newValue) {
            return;
        }
        let newState = Object.assign({}, this.state);
        newState[variable] = newValue;
        switch (variable) {
            case "name":
                this.validName = this.validateName(newValue);
                break;
            case "label":
                this.validLabel = this.validateLabel(newValue);
                break;
        }
        this.setState(newState);
    }

    inputChangeHandler(variable, reactEvent, event) {
        let newValue = reactEvent.nativeEvent.target.value;
        this.maskedInputChangeHandler(variable, newValue);
    }

    changePackageHandler(newValue) {
        if (newValue.id == this.state.package.id) {
            return;
        }
        this.setState(Object.assign({}, this.state, { package: this.props.packages.packageById[newValue.id] }));
    }

    addParentClassHandler(parentData) {
        for (let i = this.suggestions.length - 1; i >= 0; --i) {
            if (this.suggestions[i] == parentData) {
                this.suggestions = this.suggestions.slice();
                this.suggestions.splice(i, 1);
                let parentClasses = this.state.parentClasses.slice();
                parentClasses.push(parentData);
                this.setState(Object.assign({}, this.state, { parentClasses }));
                return;
            }
        }
    }

    removeParentClassHandler(parentData) {
        for (let i = this.state.parentClasses.length - 1; i >= 0; --i) {
            if (this.state.parentClasses[i] == parentData) {
                this.suggestions = this.suggestions.slice();
                this.suggestions.push(parentData);
                let parentClasses = this.state.parentClasses.slice();
                parentClasses.splice(i, 1);
                this.setState(Object.assign({}, this.state, { parentClasses }));
                return;
            }
        }
    }

    changeNamespaceHandler(newValue) {
        if (this.state.namespace.id == newValue.id) {
            return;
        }
        this.setState(Object.assign({}, this.state, { namespace: this.props.namespaceMap[newValue.id] }));
    }

    render() {
        return (<div style={fullSize} className="form-horizontal">
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="classId" className="control-label pull-right">{CLASSCARD_PACKAGE}</label>
                </div>
                <div className="col-sm-8">
                    <Select
                        name="packageName"
                        loadingPlaceholder={MSG_SELECT_LOADING}
                        placeholder={MSG_SELECT_PLACEHOLDER}
                        noResultsText={MSG_SELECT_NO_RESULTS}
                        value={this.state.package.id}
                        valueKey={"id"}
                        backspaceRemoves={false}
                        clearable={false}
                        disabled={this.disablePackageSelect}
                        options={this.props.packages.list}
                        onChange={this.changePackageHandler}
                    />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="classId" className="control-label pull-right">{CLASSCARD_NAMESPACE}</label>
                </div>
                <div className="col-sm-8">
                    <Select
                        name="namespace"
                        loadingPlaceholder={MSG_SELECT_LOADING}
                        placeholder={MSG_SELECT_PLACEHOLDER}
                        noResultsText={MSG_SELECT_NO_RESULTS}
                        value={this.state.namespace.id}
                        valueKey={"id"}
                        backspaceRemoves={false}
                        clearable={false}
                        options={this.namespaceList}
                        labelKey={"prefix"}
                        onChange={this.changeNamespaceHandler}
                    />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="classId" className="control-label pull-right">{CLASSCARD_IDENTIFIER}</label>
                </div>
                <div className={"col-sm-8"} >
                    <MaskedInput
                        className={"form-control" + (this.validName ? "" : " is-invalid")}
                        id={"className"}
                        value={this.state.name}
                        onChange={this.maskedInputChangeHandler.bind(this, "name")}
                        allowedSymbols={NAME_SYMBOLS}
                        firstSymbol={NAME_FIRST_SYMBOL}
                    />
                    {this.validName ? null : <div className="invalid-feedback text-danger">{CLASSCARD_NOT_EMPTY_FIELD}</div>}
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="classLabel" className="control-label pull-right">{CLASSCARD_LABEL}</label>
                </div>
                <div className={"col-sm-8"}>
                    <MaskedInput
                        className={"form-control" + (this.validLabel ? "" : " is-invalid")}
                        id={"classLabel"}
                        value={this.state.label}
                        onChange={this.maskedInputChangeHandler.bind(this, "label")}
                        allowedSymbols={LABEL_SYMBOLS}
                        firstSymbol={LABEL_FIRST_SYMBOL}
                    />
                    {this.validLabel ? null : <div className="invalid-feedback text-danger">{CLASSCARD_NOT_EMPTY_FIELD}</div>}
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="classLabel" className="control-label pull-right">{CLASSCARD_PARENTS_CLASSES}</label>
                </div>
                <div className="col-sm-8">
                    <TagSelect
                        tags={this.state.parentClasses}
                        placeholder={"Выберите класс предка"}
                        suggestions={this.suggestions}
                        displayField={"className"}
                        selectHandler={this.addParentClassHandler}
                        deleteHandler={this.removeParentClassHandler}
                    />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="classLabel" className="control-label pull-right">{CLASSCARD_STEREOTYPE}</label>
                </div>
                <div className="col-sm-8">
                    <Select
                        name="stereotypes"
                        loadingPlaceholder={MSG_SELECT_LOADING}
                        placeholder={MSG_SELECT_PLACEHOLDER}
                        noResultsText={MSG_SELECT_NO_RESULTS}
                        value={this.state.stereotypeId}
                        backspaceRemoves={false}
                        clearable={false}
                        options={this.stereotypeList}
                        onChange={this.selectChangeHandler.bind(this, "stereotypeId")}
                    />
                </div>
            </div>
            {this.state.stereotypeId == 0 ?
                <div className="row form-group">
                    <div className="col-sm-4">
                        <label for="classLabel" className="control-label pull-right">{CLASSCARD_STORE_TYPE}</label>
                    </div>
                    <div className="col-sm-8">
                        <Select
                            name="storeType"
                            loadingPlaceholder={MSG_SELECT_LOADING}
                            placeholder={MSG_SELECT_PLACEHOLDER}
                            noResultsText={MSG_SELECT_NO_RESULTS}
                            value={this.state.storeType}
                            backspaceRemoves={false}
                            clearable={false}
                            options={this.storeTypeList}
                            onChange={this.selectChangeHandler.bind(this, "storeType")}
                        />
                    </div>
                </div>
                :
                null
            }
            {this.state.stereotypeId == 2 ?
                <div className="row form-group">
                    <div className="col-sm-4">
                        <label for="classLabel" className="control-label pull-right">{CLASSCARD_DEPTH}</label>
                    </div>
                    <div className="col-sm-8">
                        <Select
                            name="depth"
                            loadingPlaceholder={MSG_SELECT_LOADING}
                            placeholder={MSG_SELECT_PLACEHOLDER}
                            noResultsText={MSG_SELECT_NO_RESULTS}
                            value={this.state.depth}
                            backspaceRemoves={false}
                            clearable={false}
                            options={DEPTHS}
                            onChange={this.selectChangeHandler.bind(this, "depth")}
                        />
                    </div>
                </div>
                :
                null
            }
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="classLabel" className="control-label pull-right">{CLASSCARD_DESCRIPTION}</label>
                </div>
                <div className="col-sm-8">
                    <textarea type="text" id="description" className="form-control" value={this.state.description} onChange={this.inputChangeHandler.bind(this, "description")} rows="3" />
                </div>
            </div>
        </div>);
    }
}

export default ClassPopup;


