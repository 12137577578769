import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { MSG_ALL, MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../messages.jsx';
import { marginTop, paddingTop, disabledSelect, FINDER_SELECTION_AREA } from '../../constants/finder';
import { toggleButtonStyle } from '../../constants/finder';

function deleteKeysFromArray(array, valueArray) {
    valueArray = valueArray.slice();
    for (let i = array.length - 1; i >= 0; --i) {
        for (let j = valueArray.length - 1; j >= 0; --j) {
            if (array[i] == valueArray[j]) {
                array.splice(i, 1);
                valueArray.splice(j, 1);
                break;
            }
        }
        if (valueArray.length == 0) {
            return;
        }
    }
}

function getSelectOptions(normalizedArray, localSelection) {
    let options = [];
    localSelection.map(function (fragmentId, index) {
        let object = normalizedArray.byId[fragmentId];
        options.push({ value: object.id, label: object.label })
    });
    return options;
}

export default class SideBar extends Component {
    constructor(props) {
        super(props);

        this.changeFragmentHandler = this.changeFragmentHandler.bind(this);
        this.printSelectionElement = this.printSelectionElement.bind(this);
    }

    changeFragmentHandler(newValueObject) {
        let newFragmentId = newValueObject.value;
        if (typeof newFragmentId == "undefined") {
            /* Happens when user use backspace on empty search */
            return;
        }
        this.props.selectFragment(null, newFragmentId);
    }

    createChangeClassHandler(level) {
        let _this = this;
        return function (newValueObject) {
            let newClassId = newValueObject.value;
            if (typeof newClassId == "undefined") {
                /* Happens when user use backspace on empty search */
                return;
            }
            _this.props.selectClass(null, newClassId, level);
        }
    }

    createSelectClassHandler(level) {
        let _this = this;
        return function (oldClassId, newClassId) {
            _this.props.selectClass(oldClassId, newClassId, level);
        }
    }

    /*renderReportFormMap() {
        let { reportFormMap } = this.props.content;
        let selectedForm = this.props.content.currentSelectState.selectedForm;
        if (selectedForm === undefined) {
            return (
                <div>
                    <label><b>Форма отчёта</b></label>
                    <select className='col-md-12' onChange={this.onChangeForm} value={selectedForm}>
                        <option>Выберите форму отчёта</option>
                        {Object.keys(reportFormMap).map(i => <option key={i} value={i}>{reportFormMap[i].title}</option>)}
                    </select>
                </div>
            );
        } else {
            return (
                <div>
                    <label><b>Форма отчёта</b></label>
                    <select className='col-md-12' onChange={this.onChangeForm} value={selectedForm}>
                        {Object.keys(reportFormMap).map(i => <option key={i} value={i}>{reportFormMap[i].title}</option>)}
                    </select>
                </div>
            );
        }
    }*/

    printSelect(normalizedObjects, objectId, isLoading, isFetched, selection, className, isSingle, changeHandler, openHandler) {
        let value;
        let localSelection = selection.slice();
        if (objectId) {
            value = objectId;
            localSelection.unshift(objectId);
        } else {
            value = null;
        }

        let _this = this;
        return <div className={className}>
            <Select
                name="select"
                className="minified-react-select"
                loadingPlaceholder={MSG_SELECT_LOADING}
                placeholder={MSG_SELECT_PLACEHOLDER}
                noResultsText={MSG_SELECT_NO_RESULTS}
                isLoading={isLoading}
                onOpen={!isFetched ? openHandler : null}
                value={value}
                options={getSelectOptions(normalizedObjects, localSelection)}
                onChange={changeHandler}
                clearable={false}
            />
        </div>
    }

    printDisabledSelect() {
        return <div className="col-md-12 input-group">
            <Select
                name="select"
                className="minified-react-select"
                loadingPlaceholder={MSG_SELECT_LOADING}
                placeholder={MSG_SELECT_PLACEHOLDER}
                noResultsText={MSG_SELECT_NO_RESULTS}
                value=""
                options={null}
                clearable={false}
                disabled={true}
            />
        </div>
    }

    printSelectionElement(normalizedObjects, className, isLoading, isFetched, selectionFlag, selection, selectedObjects, changeHandler, selectHandler, openHandler) {
        let _this = this;
        return <div className="col-md-12 nopadding">
            {selectionFlag ?
                this.printSelect(normalizedObjects, null, isLoading, isFetched, selection, className, selectedObjects.length == 0, changeHandler, openHandler)
                :
                (selectedObjects.length == 0 ? this.printDisabledSelect() : null)}
            {selectedObjects.map((objectId, index) => {
                let object = normalizedObjects.byId[objectId];
                return <div className="col-md-12 input-group input-group-sm cim-finder-sidebar-fragment">
                    <input type="text" className="form-control card-input" disabled="true" value={object.label} />
                    <span className="input-group-append">
                        <button className="btn btn-secondary btn-xs card-input cim-finder-sidebar-fragment-button h-100" type="button" onClick={() => selectHandler(objectId, null, objectId)}><i className="fa fa-times" aria-hidden="true"></i></button>
                    </span>
                </div>
            }
            )}
        </div>
    }

    printSelection(fragments, level, selectedParents) {
        let selection = [];
        let selectedFragments = level.selected;
        if (selectedParents == null) {
            /* Possible only for OST selection */
            selection = fragments.rootIds.slice();
        } else {
            for (let parentId of selectedParents) {
                selection = selection.concat(fragments.children[parentId]);
            }
        }

        /* Remove already selected ids from selection array */
        deleteKeysFromArray(selection, selectedFragments);

        /* Check if all dependencies was downloaded */
        let isLoading = false;
        let isFetched = true;
        if (selectedParents == null) {
            isLoading = fragments.loading[null];
            isFetched = fragments.fetched[null];
        } else {
            for (let parentId of selectedParents) {
                if (fragments.loading[parentId]) {
                    isLoading = true;
                    isFetched = false;
                    break;
                }
                if (!fragments.fetched[parentId] && isFetched) {
                    isFetched = false;
                }
            }
        }
        /* Check if selection is disabled */
        let selectionFlag = selection.length != 0 || isLoading || !isFetched;

        let className = "col-md-12 input-group cim-finder-sidebar-fragment";

        return this.printSelectionElement(fragments, className, isLoading, isFetched, selectionFlag, selection, selectedFragments, this.changeFragmentHandler, this.props.selectFragment, this.props.fetchFragments.bind(this, selectedParents));
    }

    printClassSelection(classes, classLevel, level) {
        let selection = classLevel.selectionIds.slice();
        let selectedClasses = classLevel.selected;

        /* Remove already selected ids from selection array */
        deleteKeysFromArray(selection, selectedClasses);

        /* Check if all dependencies was downloaded */
        let isLoading = classes.loading[classLevel.id];
        let isFetched = classes.fetched[classLevel.id];
        /* Check if selection is disabled */
        let selectionFlag = selection.length != 0 || isLoading || !isFetched;

        let className = "col-md-12 input-group cim-finder-sidebar-fragment";

        return this.printSelectionElement(this.props.classes, className, isLoading, isFetched, selectionFlag, selection, selectedClasses, this.createChangeClassHandler(level), this.createSelectClassHandler(level), this.props.fetchClasses.bind(this, classLevel.id));
    }

    parseSelectedFragments(fragments, sideBar) {
        if (!sideBar.fragmentLevels) {
            return;
        }
        for (let i = 0; i < sideBar.fragmentLevels.length; ++i) {
            let level = sideBar.fragmentLevels[i];
            level.selection = this.printSelection(fragments, level, i == 0 ? null : sideBar.fragmentLevels[i - 1].selected);
        }
    }

    parseSelectedClasses(classes, sideBar) {
        if (!sideBar.classLevels) {
            return;
        }
        for (let i = 0; i < sideBar.classLevels.length; ++i) {
            let level = sideBar.classLevels[i];
            level.selection = this.printClassSelection(classes, level, i);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.sideBar && !nextProps.sideBar) {
            return;
        }
        if (this.props.fragments != nextProps.fragments || this.props.sideBar != nextProps.sideBar) {
            this.parseSelectedFragments(nextProps.fragments, nextProps.sideBar);
        }
        if (this.props.classes != nextProps.classes
            || this.props.sideBar != nextProps.sideBar
            || this.props.sideBar.classLevels != nextProps.sideBar.classLevels) {
            this.parseSelectedClasses(nextProps.classes, nextProps.sideBar);
        }
    }

    componentDidMount() {
        if (this.props.sideBar) {
            let forceUpdate = false;
            if (this.props.fragments) {
                this.parseSelectedFragments(this.props.fragments, this.props.sideBar);
                forceUpdate = true;
            }
            if (this.props.classes && this.props.sideBar.classLevels) {
                this.parseSelectedClasses(this.props.classes, this.props.sideBar);
                forceUpdate = true;
            }
            if (forceUpdate) {
                this.forceUpdate();
            }
        }
    }

    render() {
        return (
            <div className='col-md-2 pull-left cim-finder-sidebar' style={{ height: "100%", overflowY: "auto", padding: "0px" }}>
                {this.props.sideBar.fragmentLevels ?
                    <div className='card'>
                        <div className="cim-finder-table-heading card-header bg-info">
                            <h6 className="card-title font-weight-bold">
                                {FINDER_SELECTION_AREA}
                            </h6>
                            {this.props.minimize ? <span className="fa fa-window-minimize" style={toggleButtonStyle} onClick={this.props.minimize}>&nbsp;</span> : null}
                        </div>
                        <div className='card-body panel-bar'>

                            {this.props.sideBar.fragmentLevels.map((level, index) => <div key={index} className='col-md-12'>
                                <label style={paddingTop}>
                                    <b>
                                        {level.name}
                                    </b>
                                </label>
                                {level.selection}
                            </div>)}
                        </div>
                    </div>
                    :
                    null}

                {(this.props.sideBar.classLevels && this.props.sideBar.classLevels.length != 0) ?
                    this.props.sideBar.classLevels.map((level, index) => <div className='card'>
                        <div className="cim-finder-table-heading card-header bg-info">
                            <h6 className="card-title font-weight-bold">
                                <span>{level.name}</span>
                            </h6>
                            {this.props.minimize ? <span className="fa fa-window-minimize" style={toggleButtonStyle} onClick={this.props.minimize}>&nbsp;</span> : null}
                        </div>
                        <div className='card-body panel-bar'>
                            <div key={index} className='col-md-12'>
                                {level.selection}
                            </div>
                        </div>
                    </div>)
                    :
                    null}
            </div>
        );
    }
}