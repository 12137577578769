 
import CimMap from './components/map/cimmap.jsx'; 

import { FormattedMessage } from 'react-intl';
  
const ATTR_CIM_MAP = "cim-map";
 

function checkAttr(elm, name) { 
    let value = $(elm).attr(name);
    if (!(typeof value == 'string')) {
        return null;
    }
    value = $.trim(value);
    if (value.length == 0) {
        return null;
    }
    return value;
}
 
if (typeof NPTPlatform != 'undefined' && typeof NPTPlatform.dispatcher != 'undefined') {
    
}

export default (dispatcher) => {
    //Register reducer 
    //Register components
    dispatcher.registerComponent(ATTR_CIM_MAP, (elm) => { 
        let path = checkAttr(elm, ATTR_CIM_MAP); 

        return (<CimMap path={path}   />);
    }); 
}