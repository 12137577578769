import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../modal.jsx';
import Tree from '../../tree/tree.jsx';
import { connectNavTree } from '../../../services/navtree';
import { MSG_WEEK_DAY_THURSDAY } from '../../messages.jsx';

const treeStyle = {
    height: "500px",
    overflowY: "auto"
}

const NavTreeContainer = connectNavTree(Tree);

class NavTreeModal extends React.Component {

    checkStack;
    validatedNodes;

    constructor(props) {
        super(props);

        this.checkStack = [];
        this.validatedNodes = {};

        this.selectObject = this.selectObject.bind(this);
        this.getResult = this.getResult.bind(this);
    }

    checkFinish(isValid, callback) {
        this.checkStack.shift();
        callback(isValid);
        if (this.checkStack.length != 0) {
            this.recursiveCheck(this.checkStack[0].data, this.checkStack[0].callback);
        }
    }

    recursiveCheck(data, callback) {
        if (typeof this.validatedNodes[data.$rdfId] != "undefined") {
            this.checkFinish(this.validatedNodes[data.$rdfId], callback);
            return;
        }
        this.props.options.checkFunction(data, (isValid) => {
            this.validatedNodes[data.$rdfId] = isValid;
            this.checkFinish(isValid, callback);
        });
    }

    checkData(data, callback) {
        this.checkStack.push({ data, callback });
        /* If stack contains more than one element - then chain of checks will be evaluated directly from inside of checking cycle */
        if (this.checkStack.length != 1) {
            return;
        }
        this.recursiveCheck(data, callback);
    }

    getTitle() {
        return (<FormattedMessage
            id="NAVTREE_MODAL_TITLE"
            defaultMessage="Please select object"
            description="User should select object to be added into table" />);
    }

    getBody() {
        //Create tree component
        const style = Object.assign({}, treeStyle);
        style.height = this.props.options.height || style.height;
        return (
            <div style={style} className="cim-tree-modal">
                <NavTreeContainer
                    treeId={this.props.options.treeId}
                    selectObject={this.selectObject}
                    isModal={true} />
            </div>
        );
    }

    getResult() {
        if (!this.state) {
            return null;
        }
        return this.state.result;
    }

    setResult(data) {
        if (!data || typeof this.props.options.checkFunction != "function") {
            this.setState({ result: data });
            return;
        }
        this.checkData(data, (isValid) => {
            if (!isValid) {
                this.setState({ result: null });
                return;
            }
            this.setState({ result: data });
        });
    }

    selectData(node) {
        if (node && node.data && node.data.$rdfId) {
            if (this.props.options.nodeTypeId) {
                let nodeTypeIdList = [];

                if (typeof this.props.options.nodeTypeId == "string") {
                    nodeTypeIdList = [this.props.options.nodeTypeId];
                } else if (Array.isArray(this.props.options.nodeTypeId)) {
                    nodeTypeIdList = this.props.options.nodeTypeId;
                }

                let found = false;
                for (let typeId of nodeTypeIdList) {
                    if (node.typeId == typeId) {
                        found = true;
                        this.setResult(node.data);
                        console.log("Select data by typeId:", node);
                        break;
                    }
                }
                if (!found) {
                    this.setResult(null);
                    console.log("Unselect data by typeId:", node);
                }
            } else {
                console.log("Select data without typeId:", node);
                this.setResult(node.data);
            }
        } else {
            console.log("Unselect data without data:", node);
            this.setResult(null);
        }
    }

    selectFragment(node) {
        if (node.data && node.data.$fragment) {
            if (this.props.options.nodeTypeId) {
                let nodeTypeIdList = [];

                if (typeof this.props.options.nodeTypeId == "string") {
                    nodeTypeIdList = [this.props.options.nodeTypeId];
                } else if (Array.isArray(this.props.options.nodeTypeId)) {
                    nodeTypeIdList = this.props.options.nodeTypeId;
                }

                let found = false;
                for (let typeId of nodeTypeIdList) {
                    if (node.typeId == typeId) {
                        found = true;
                        this.setResult(node.data.$fragment);
                        console.log("Select fragment by typeId:", node);
                        break;
                    }
                }
                if (!found) {
                    this.setResult(null);
                    console.log("Unselect fragment by typeId:", node);
                }
            } else {
                console.log("Select fragment without typeId:", node);
                this.setResult(node.data.$fragment);
            }
        } else {
            console.log("Unselect fragment without data:", node);
            this.setResult(null);
        }
    }

    selectObject(node) {
        if (this.props.options.selectFragment) {
            this.selectFragment(node);
        } else {
            this.selectData(node);
        }
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for NavTreeModal*/
            title={this.getTitle()}
            body={this.getBody()}
            /**Specific callbacks for NavTreeModal*/
            result={this.getResult} />);
    }
}

export default NavTreeModal;


