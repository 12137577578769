import React from 'react';
import PropTypes from 'prop-types';

const wordBoundaryRegExp = /[\s\.,]+/;

function generateWordCallback(selected) {
    return function (targetValue) {
        if (typeof targetValue != 'string') {
            return false;
        }
        targetValue = targetValue.toLowerCase();
        let wordList = targetValue.split(wordBoundaryRegExp);
        for (let word of wordList) {
            if (selected[word]) {
                return true;
            }
        }
        return false;
    }
}

function generateStringCallback(selected) {
    return function (targetValue) {
        if (typeof targetValue != 'string') {
            return false;
        }
        targetValue = targetValue.toLowerCase();
        return selected[targetValue];
    }
}

function stringLimit(limits, value, format = "") {
    if (typeof value != 'string' || value == "") {
        return;
    }
    if (!limits.valueSet) {
        limits.valueSet = {};
    }
    const key = value.toLowerCase();
    limits.valueSet[key] = format.toLowerCase() == "html" ? escapeHtml(value) : value;
}

function stringLimitFinish(limits) {
    limits.values = [];
    if (!limits.valueSet) {
        return;
    }
    for (let key in limits.valueSet) {
        let record = {
            key,
            value: limits.valueSet[key]
        }
        limits.values.push(record);
    }
    limits.values.sort(function (a, b) {
        if (a.key > b.key) {
            return 1;
        }
        if (a.key < b.key) {
            return -1;
        }
        // a должно быть равным b
        return 0;
    });
    delete limits.valueSet;
}

function wordLimit(limits, value, format = "") {
    if (!value) {
        return;
    }
    if (format.toLowerCase() == "html") {
        value = escapeHtml(value);
    }
    if (!limits.valueSet) {
        limits.valueSet = {};
    }
    let wordList = value.split(wordBoundaryRegExp);
    for (let word of wordList) {
        if (typeof word != "string" || word == "") {
            continue;
        }
        limits.valueSet[word.toLowerCase()] = word;
    }
}

function escapeHtml(string) {
    let htmlElement = $(string)[0];
    if (!htmlElement) {
        return string;
    }
    return htmlElement.innerText;
}

class StringFilter extends React.Component {

    constructor(props) {
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
        this.apply = this.apply.bind(this);
        this.clear = this.clear.bind(this);
        this.getPanelLimits = this.getPanelLimits.bind(this);
        this.state = { selected: null };
    }

    apply({ selected, cleared }) {
        if (cleared) {
            this.clear();
            return;
        }
        if (!selected) {
            return;
        }
        let callback = null;
        if (this.props.type == 'word') {
            callback = generateWordCallback(selected);
        } else {
            callback = generateStringCallback(selected);
        }
        this.props.filterHandler({ callback, type: this.props.type, selected: selected });
        this.setState({ selected });
    }

    clear() {
        this.props.filterHandler();
        this.setState({ selected: null });
    }

    clickHandler() {
        let options = {
            type: this.props.type,
            label: this.props.label,
            size: this.props.size,
            selected: this.state.selected,
            limits: this.getPanelLimits()
        }
        this.props.openModal("stringfilter", options, this.apply);
    }

    getPanelLimits() {
        let data = this.props.getFilteredRows();
        let limits = {};
        let limitCalc = null;
        if (this.props.type == 'word') {
            limitCalc = wordLimit;
        } else {
            limitCalc = stringLimit;
        }
        for (let row of data) {
            let value = (row.bindedData && row.bindedData[this.props.field]) || row[this.props.field];
            limitCalc(limits, value, this.props.format);
        }
        stringLimitFinish(limits);
        return limits;
    }

    render() {
        const activeStyle = { color: "#90EE90" };
        let optional = {};
        if (this.props.active) {
            optional.style = activeStyle;
        }
        return (<i className="fa fa-filter" aria-hidden="true" {...optional} onClick={this.clickHandler}></i>);
    }
}

StringFilter.propTypes = {
    filterHandler: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    getFilteredRows: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default StringFilter;