import React from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import Modal from '../modal.jsx';
import Container from '../../table/container.jsx';
import Table from '../../table/table.jsx';
import { connectTable, findInArrayByKeys } from '../../../services/table';

const tableStyle = {
    height: "500px"
}

const FinderContainer = connectTable(Container);
const TableContainer = connectTable(Table);

class CimTableModal extends React.Component {

    checkStack;
    validatedRows;

    constructor(props) {
        super(props);

        this.checkStack = [];
        this.validatedRows = {};

        this.selectObject = this.selectObject.bind(this);
        this.getResult = this.getResult.bind(this);
    }

    checkFinish(isValid, callback) {
        this.checkStack.shift();
        callback(isValid);
        if (this.checkStack.length != 0) {
            this.recursiveCheck(this.checkStack[0].data, this.checkStack[0].callback);
        }
    }

    recursiveCheck(data, callback) {
        if (!Array.isArray(data)) {
            data = [data];
        }
        const promises = [];
        for (let row of data) {
            const promise = new Promise((resolve, reject) => {
                if (typeof this.validatedRows[row.key] != "undefined") {
                    if (this.validatedRows[row.key]) {
                        resolve(true);
                        return;
                    }
                    reject();
                    return;
                }
                this.props.options.checkFunction(row, (isValid) => {
                    this.validatedRows[row.key] = isValid;
                    if (isValid) {
                        resolve(true);
                        return;
                    }
                    reject();
                });
            });
            promises.push(promise);
        }

        Promise.all(promises).then((values) => {
            this.checkFinish(true, callback);
        }, (reason) => {
            this.checkFinish(false, callback);
        });
    }

    checkData(data, callback) {
        this.checkStack.push({ data, callback });
        /* If stack contains more than one element - then chain of checks will be evaluated directly from inside of checking cycle */
        if (this.checkStack.length != 1) {
            return;
        }
        this.recursiveCheck(data, callback);
    }

    getTitle() {
        return (<FormattedMessage
            id="CIMTABLE_MODAL_TITLE"
            defaultMessage="Please select object"
            description="User should select object to be added into table" />);
    }

    getBody() {
        let optional = {}
        if (this.props.options.forcedSelectType) {
            optional.forcedSelectType = this.props.options.forcedSelectType;
        }
        if (this.props.options.parameters) {
            optional.forcedParameters = this.props.options.parameters;
        }
        const Container = (this.props.options.type && this.props.options.type == "finder") ? FinderContainer : TableContainer;
        const style = Object.assign({}, tableStyle);
        style.height = this.props.options.height || style.height;
        //Create table component
        return (<div style={style} className="cim-table-modal">
            <Container
                tableId={this.props.options.tableId}
                embedded={true}
                onSelect={this.selectObject}
                initialFilter={this.props.options.filter}
                {...optional} />
        </div>);
    }

    getResult() {
        if (!this.state || !this.state.result) {
            return null;
        }
        if (this.props.options.forcedSelectType == "radio") {
            return this.state.result[0];
        }
        return this.state.result;
    }

    setResult(data) {
        if (!data || typeof this.props.options.checkFunction != "function") {
            this.setState({ result: data });
            return;
        }
        this.checkData(data, (isValid) => {
            if (!isValid) {
                return;
            }
            this.setState({ result: data });
        });
    }

    selectData(rows) {
        let result = [];
        for (let data of rows) {
            if (data.key) {
                console.log("Select data:", data);
                result.push(data);
            } else {
                console.log("Unselect data without data");
            }
        }
        if (result.length == 0) {
            this.setResult(null);
        } else {
            this.setResult(result);
        }
    }

    selectFragments(rows) {
        let result = [];
        for (let data of rows) {
            if (data.$fragment) {
                console.log("Select fragment:", node);
                result.push(data.$fragment);
            } else {
                console.log("Unselect fragment without data");
            }
        }
        if (result.length == 0) {
            this.setResult(null);
        } else {
            this.setResult(result);
        }
    }

    selectObject({ data, selected }) {
        let keys = [];
        for (let key in selected) {
            if (key != "length" && selected[key]) {
                keys.push(key);
            }
        }
        let rows = findInArrayByKeys({ array: data, keys, key: "key" });
        if (this.props.options.selectFragment) {
            this.selectFragments(rows);
        } else {
            this.selectData(rows);
        }
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for NavTreeModal*/
            title={this.getTitle()}
            body={this.getBody()}
            /**Specific callbacks for NavTreeModal*/
            result={this.getResult} />);
    }
}

export default CimTableModal;


