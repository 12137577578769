import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    SAVE_STATE_START,
    SAVE_STATE_UPLOADS_READY,
    SAVE_STATE_SHOW_ERRORS
} from '../../constants/objectcard';

class SaveStatus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            closed: true
        };

        this.closeAlert = this.closeAlert.bind(this);
    }

    closeAlert() {
        this.setState({ closed: true });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.saveState != this.props.saveState || prevProps.errorInfo != this.props.errorInfo) {
            if (this.props.saveState == SAVE_STATE_SHOW_ERRORS) {
                this.setState({ closed: false }); //Signal to show save status bar
            } else {
                this.setState({ closed: true }); //Signal to hide save status bar
            }
        }
    }

    render() {
        if (this.state.closed || !this.props.errorInfo || !this.props.errorInfo.emptyHiddenMandatory) {
            return null;
        }
        return (<div className="alert alert-warning">
            <FormattedMessage
                id="OBJECTCARD_SAVE_ERROR_HIDDEN_MANDATORY"
                defaultMessage="Hidden mandatory fields wasn't filled: {feilds}"
                description="Show error on empty hidden mandatory fields"
                values={{ feilds: this.props.errorInfo.emptyHiddenMandatory.join(", ") }} />
            <button type="button" class="close float-right" onClick={this.closeAlert}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>);
    }
}

export default SaveStatus;


