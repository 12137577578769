import Button from './button.jsx'
import { MSG_BUTTON_UPLOAD, MSG_BUTTON_CANCEL } from '../messages.jsx'
import { FormattedMessage } from 'react-intl';

class ProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0
        }

        this.triggerUpload = this.triggerUpload.bind(this);
    }

    //Total upload progress callback
    handleUploadProgress(_this, uploadProgress) {
        _this.setState({ progress: uploadProgress });
    }

    componentDidMount() {
        if (this.props.registerProgressBar) {
            this.props.registerProgressBar(this);
        }
    }

    triggerUpload() {
        this.setState({ progress: 0, started: true });
        this.props.startUpload({
            files: this.props.filedrop.filesToUpload,
            uploadProgress: (uploadProgress) => this.handleUploadProgress(this, uploadProgress)
        })
    }

    render() {
        var _this = this;
        return (
            this.props.filedrop.started ?
                <div className="progress">
                    <div className="progress-bar"
                        role="progressbar"
                        aria-valuenow={this.state.progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${this.state.progress}%` }}>
                        <FormattedMessage
                            id="FILEDROP_UPLOAD_COMPLETED"
                            defaultMessage="{progress}% Completed"
                            description="Upload status"
                            values={{ progress: this.state.progress }} />
                    </div>
                </div> :
                <div className="card">
                    <div className="btn-toolbar pull-right">
                        <div className="btn-group btn-group-xs">
                            {this.props.hideUploadButton ?
                                null :
                                <Button icon='upload' message={MSG_BUTTON_UPLOAD} onClick={this.triggerUpload}></Button>
                            }
                            <Button icon='close' message={MSG_BUTTON_CANCEL} onClick={this.props.clearUploadMap}></Button>
                        </div>
                    </div>
                    <div>
                        <FormattedMessage
                            id="FILEDROP_FILES_TO_UPLOAD"
                            defaultMessage="Files to upload"
                            description="Files to upload"
                            values={{ progress: this.state.progress }} />:
                        </div>
                    <div className="card-body">
                        {this.props.filedrop.filesToUpload.map((file, index) => <div key={index} className="col-md-12">
                            {file.name}
                        </div>)}
                    </div>
                </div>
        )
    }
}

export default ProgressBar;