import moment from 'moment';

export function getFileName(label, type, filename) {
    let ext = type.toLowerCase();
    const undIndex = ext.lastIndexOf('_');
    if (undIndex > 0) {
        ext = ext.substring(undIndex + 1);
    }
    if (filename) {
        return parseFilename(filename) + "." + ext;
    }
    return label + "." + ext;
}

/* Find in string word inside {} */
function getSpecialWord(string, startIdx) {
    let start = string.indexOf("{", startIdx);
    if (start == -1) {
        return null;
    }
    let end = string.indexOf("}", start);
    if (end == -1) {
        return null;
    }
    ++end;
    return {
        start,
        end,
        value: string.substring(start, end)
    }
}

/* Common values for replace */
function replaceValue(value) {
    switch (value.toLowerCase()) {
        case "{date}":
            return moment(new Date()).format('L');
        case "{time}":
            return moment(new Date()).format('LTS');
    }
    return value;
}

/* Parse filename and try to replace common values */
function parseFilename(filename) {
    let idx = 0;
    let word = getSpecialWord(filename, idx);
    while (word) {
        filename = filename.replace(word.value, replaceValue(word.value))
        idx = word.end + 1;
        word = getSpecialWord(filename, idx);
    }
    return filename;
}