import { FormattedMessage } from 'react-intl';

export const FILEDROP = "FILEDROP";

//Actions
export const REGISTER_UPLOAD = "@@FILEDROP_REGISTER_UPLOAD";
export const START_UPLOAD = "@@FILEDROP_START_UPLOAD";
export const CLEAR_UPLOAD = "@@FILEDROP_CLEAR_UPLOAD";
export const UPLOAD_FINISHED = "@@FILEDROP_UPLOAD_FINISHED";


//////////////////////
//Formatted messages//
//////////////////////
export const FILEDROP_UPLOAD_SUCCESS = <FormattedMessage
    id="FILEDROP_UPLOAD_SUCCESS"
    defaultMessage="File(s) was successfully uploaded."
    description="Alert text on successful upload." />

export const FILEDROP_UPLOAD_ERROR = <FormattedMessage
    id="FILEDROP_UPLOAD_ERROR"
    defaultMessage="Error occured while uploading file(s)."
    description="Alert text on failed upload." />