import React, { Component, PropTypes } from 'react';
import RichTextEditor from 'react-rte';
import { EVENT_FLUSH_DEBOUNCE } from '../../../constants/objectcard.js';
import debounce from '../../../services/debounce';
import {
    fromRedux,
    toRedux 
} from '../../../services/formatvalidator.js';

class Description extends Component {

    constructor(props) {
        super(props);
        //Set initial state as redux store value
        this.state = { value: RichTextEditor.createEmptyValue(), markup: '', editable: false };
        this.flushDebounce = this.flushDebounce.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.delayedHandleChange = this.delayedHandleChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //Editable changed or we not in editable state
        if (prevState.editable != nextProps.editable || !nextProps.editable) {
            //When we are not editing we receive data from redux
            const markup = fromRedux(nextProps.value, "html");
            let state = null;
            if (markup != prevState.markup) {
                state = { value: RichTextEditor.createValueFromString(markup, 'html'), markup };
            }
            if (prevState.editable != nextProps.editable) {
                if (!state) {
                    state = {};
                }
                state.editable = nextProps.editable;
            }
            return state;
        }
        return null;
    }

    flushDebounce(event, info) {
        if (this.props.formId == info.formId && this.debounce) {
            console.log("Flush debounce: ", this.props.nodeId);
            this.debounce.flush();
        }
    }

    componentDidMount() {
        $(document).bind(EVENT_FLUSH_DEBOUNCE, this.flushDebounce);
    }

    componentWillUnmount() {
        $(document).unbind(EVENT_FLUSH_DEBOUNCE, this.flushDebounce);
    }

    delayedHandleChange() {
        if (!this.props.editable) { //Do not send data to redux if element is already not editable
            console.error("Description.delayedHandleChange() in non-editable mode!!!!");
            return;
        }
        const markup = this.state.value.toString('html');
        if (markup != this.props.value) {
            this.props.change(markup);
        }
    }

    handleChange(value) {
        this.setState({ value });
        if (!this.debounce) {
            this.debounce = debounce(this.delayedHandleChange, 200); //200 ms by default
        }
        this.debounce();
    }

    render() {
        return (
            <RichTextEditor
                readOnly={!this.state.editable}
                value={this.state.value}
                onChange={this.handleChange} />
        );
    }
}

export default Description;