import * as Action from '../constants/modal';

const initialState = {
    modalList: [
        //List of currently openned modals
    ]
};

function openModal(state, payload) {
    const id = payload.id;
    if (!id) {
        return state;
    }
    for (const modal of state.modalList) {
        if (modal.id == id) { //the same modal was openned
            return state;
        }
    }
    const modalList = state.modalList.concat(payload);
    return Object.assign({}, state, { modalList });
}

function updateModal(state, payload) {
    const id = payload.id;
    if (!id) {
        return state;
    }
    if(!payload.options){
        delete(payload.options);
    }
    for (const i in state.modalList) {
        if (state.modalList[i].id == id) { //found modal
            const modalList = state.modalList.slice();
            modalList[i] = Object.assign({}, modalList[i], payload);
            return Object.assign({}, state, { modalList });
        }
    }
    return state;
}

function closeModal(state, {id}) {
    let modalList = state.modalList.filter(function (p) {
        return p.id != id;
    });
    return Object.assign({}, state, { modalList });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.OPEN_MODAL: return openModal(state, action.payload);
        case Action.UPDATE_MODAL: return updateModal(state, action.payload);
        case Action.CLOSE_MODAL: return closeModal(state, action.payload);
        default: return state;
    }
}