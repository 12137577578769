import React from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import Form from '../../objectcard/form.jsx';
import { connectObjectCard, buildInnerCardId } from '../../../services/objectcard';

/**Wrapper to fetch data */
class FormWrapper extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (typeof this.props.formRdfId == 'string') { //Initial object to be loaded
            this.props.fetchSubject(this.props.formRdfId, this.props.formNamespace);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.formRdfId != nextProps.formRdfId || this.props.formNamespace != nextProps.formNamespace) {
            if (typeof nextProps.formRdfId == 'string') {
                this.props.fetchSubject(nextProps.formRdfId, nextProps.formNamespace, true);
            }
        }
    }

    render() {
        return (<Form {...this.props} />);
    }

}

const FormWrapperContainer = connectObjectCard(FormWrapper);

class Card extends React.Component {

    constructor(props) {
        super(props);
    }

    buildEmptyCard() {
        return (<div></div>);
    }

    buildCard(ref) {
        if ((typeof ref != "object" || $.isEmptyObject(ref))) {
            return this.buildEmptyCard();
        }
        if (!ref.$rdfId) {
            return this.buildEmptyCard();
        }
        //Generate card store id
        const id = buildInnerCardId(this.props.formId, this.props.nodeId, ref.$namespace, ref.$rdfId);
        return (<FormWrapperContainer id={id} formRdfId={ref.$rdfId} formNamespace={ref.$namespace} />);
    }

    render() {
        //For single card
        if ($.type(this.props.value) != 'array') {
            return (<div>
                {this.buildCard(this.props.value)}
            </div>);
        }

        if (this.props.value.length == 0) {
            return this.buildEmptyCard();
        }

        const settings = {
            dots: true,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const _this = this;

        return (
            <Slider {...settings}>
                {this.props.value.map((file, index) => (<div key={index}>{_this.buildCard(file)}</div>))}
            </Slider>);
    }
}

export default Card;