import * as Action from '../constants/table';
import moment from 'moment';
import {
    getData,
    compileAutomationScripts,
    updatePageData,
    sortTable,
    filterTable,
    runAutomationBindings,
    applyAutomations,
    filterRows,
    sortRows
} from '../services/table';
import { retrieveFunction, obtainData } from '../services/automation';
import { removeStyle, addStyle } from '../services/styles';

import { STANDART_COLORS, MONTHS, CHOSEN_DATE, PLANNED_DATE } from '../constants/gant';
import { getWeekNumber, minScaleValue, roundDate, shadeBlendConvert, getRandomRGB, getCorrectDay } from '../services/gant';
import {
    importFinder,
    parseFinderOptions,
    classLevelsReceived,
    waitForClasses,
    classesReceived,
    fragmentLevelsReceived,
    waitForFragments,
    fragmentsReceived,
    waitForField,
    fieldsReceived,
    predicateReceived,
    changeRelation,
    addRelation,
    removeRelation,
    changeField,
    addNewCriteria,
    removeCriteria,
    unlockCriteriaRelations,
    removeCriteriaGroup,
    selectFragment,
    selectClass,
    objectcardReceived,
    objectcardErrorReceived
} from '../services/finder';
import { VIEW_TYPE } from '../constants/finder';

let initialState = {}; //url map

const initialFinder = {
    isFetching: false,
    isHidden: false,
    /* Fields from server */
    loadedFields: null,
    /* Enumerations from server */
    loadedEnumerations: null,
    /* Fragments from server */
    loadedFragments: null,
    /* Classes from server */
    loadedClasses: null,
    /* Map for fast reaching */
    classMap: {},
    /* Single criteria */
    criteria: {
        byId: {},
        allIds: []
    },
    /* Group of criteria (and) */
    criteriaGroup: {
        byId: {},
        allIds: []
    },
    /* List of criteria "and" groups */
    criteriaGroupList: [],
    sideBar: {
        fragmentLevels: null,
        selectedClass: null
    },
    /* Finder view type */
    view: VIEW_TYPE.ADD
};

///////////
//Utility//
///////////
function getColumnValue(row, column) {
    if (typeof column.path == "string" && column.path.indexOf(",") != -1) {
        const predicateList = column.path.split(",");
        for (let predicatePath of predicateList) {
            const value = obtainData(predicatePath, row);
            if (typeof value != " undefined" && value != null) {
                return value;
            }
        }
        return null;
    }
    return obtainData(column.path, row);
}

function createTableRow(row, columns) {
    let tableRow = {};
    if (row.fetched == false) {
        tableRow.fetched = false;
    }
    for (let column of columns) {
        tableRow[column.field] = row[column.field] || getColumnValue(row, column);
    }
    return tableRow;
}

function parseRows(rows, columns) {
    let parsedRows = [];
    for (let i = 0; i < rows.length; ++i) {
        let row = createTableRow(rows[i], columns);
        row.originalRowIdx = i;
        row.bindedData = {};
        parsedRows.push(row);
    }
    return parsedRows;
}

function parseColumns(tableData, columns, parsedColumns) {
    let headerList = [];
    let keyPathList = null;
    if ($.type(columns) != "array") {
        return [];
    }
    for (let column of columns) {
        if (parsedColumns[column.field]) {
            if (parsedColumns[column.field].hidden != column.hidden) {
                parsedColumns[column.field] = Object.assign({}, parsedColumns[column.field], { hidden: column.hidden })
            }
            headerList.push(parsedColumns[column.field]);
            continue;
        }
        column = Object.assign(column);
        parsedColumns[column.field] = column;
        if (typeof column.path == 'string') {
            let pathList = column.path.replace(':', '.').split(',');
            if (pathList.length > 1) {
                column.complexPath = {
                    pathList: []
                };
                for (let path of pathList) {
                    column.complexPath.pathList.push(path.split('.'));
                }
            } else {
                column.path = pathList[0].split('.');
            }
        }
        if (column.filterStrategy) {
            column.filterStrategy = column.filterStrategy.toLowerCase();
        }
        if (column.filterModalSize) {
            column.filterModalSize = column.filterModalSize.toLowerCase();
        }
        switch (column.format) {
            case "DATE_TIME":
                column.format = "dateTime";
                break;
            case "SELECT_COLUMN":
                column.format = "selectColumn";
                break;
            default:
                if (typeof column.format != "string") {
                    break;
                }
                column.format = column.format.toLowerCase();
                break;
        }
        if (column.key) {
            if (!keyPathList) {
                keyPathList = [];
            }
            keyPathList.push(column.path);
            delete column.key;
        }
        headerList.push(column);
    }
    let keyColumn;
    if (keyPathList) {
        keyColumn = { //Create key column
            key: true,
            hidden: true,
            field: 'key',
            label: 'key'
        };
        if (keyPathList.length > 1) {
            keyColumn.complexPath = {
                joinBy: "#",
                pathList: keyPathList
            };
        } else {
            keyColumn.path = keyPathList[0];
        }
    } else {
        keyColumn = { //Create key column
            key: true,
            hidden: true,
            field: 'key',
            label: 'key',
            path: ["$rdfId"]
        };
    }
    keyColumn.autoGenerated = true;

    if (tableData.selectType && tableData.selectType != "none") {
        let selectColumn = {
            key: false,
            select: true,
            sortable: false,
            autoGenerated: true,
            format: "selectColumn",
            width: 50,
            radio: tableData.selectType == "radio",
            path: []
        }
        headerList.unshift(selectColumn);
    }
    headerList.unshift(keyColumn);
    return headerList;
}

function parseParameters(parameters) {
    let parametersMap = {};
    if (!parameters) {
        return parametersMap;
    }
    for (let param of parameters) {
        parametersMap[param.name] = param;
    }
    return parametersMap;
}

function updateToolbar(tableData, header) {
    tableData.toolbar = header.toolbar;
    if (!tableData.toolbar) {
        return;
    }
    for (let item of tableData.toolbar) {
        switch (item.format) {
            case "SELECT_COLUMN":
                item.format = "selectColumn";
                break;
            case "DATE_TIME":
                column.format = "dateTime";
                break;
            default:
                if (typeof item.format != "string") {
                    break;
                }
                item.format = item.format.toLowerCase();
                break;
        }
    }
}

function updateInfo(tableData, header) {
    tableData.reports = header.reports;
    tableData.parameters = parseParameters(header.parameters);
    tableData.finderOptions = header.finderOptions;
    if (tableData.finderOptions && tableData.finderOptions.initialView) {
        tableData.finder = Object.assign(tableData.finder);
        tableData.finder.view = tableData.finderOptions.initialView.toLowerCase();
    }
    tableData.pagination = header.pagination;
    tableData.parsedColumns = {};
    tableData.orderBy = header.orderBy || null;
    updateColumns(tableData, header.column);
}

function updateColumns(tableData, columns) {
    let filteredColumns = columns;
    /**Filter disabled predicates */
    if (tableData.automation && tableData.automation.predicateFilterBinding) {
        const filterFunc = retrieveFunction(tableData.automation.predicateFilterBinding);
        filteredColumns = filteredColumns.filter((col) => !col.dynamic || filterFunc(col.path));
    };
    let parsedColumns = [];
    for (let i = 0; i < filteredColumns.length; ++i) {
        if (filteredColumns[i].field == "gantData") {
            filteredColumns[i].hidden = true;
        }
        if (filteredColumns[i].hidden && filteredColumns[i].dynamic) {
            continue;
        }
        parsedColumns.push(filteredColumns[i]);
    }
    tableData.columns = filteredColumns;
    tableData.header = parseColumns(tableData, parsedColumns, tableData.parsedColumns);
    tableData.initialHeaders = tableData.header.slice();
}

function setupItemOffsets(dateLimits, item) {
    item.relativeLeft = (item.from - dateLimits.from) / dateLimits.width;
    item.relativeRight = (item.to - dateLimits.from) / dateLimits.width;
}

function setupOffsets(dateLimits, data) {
    for (let item of data) {
        setupItemOffsets(dateLimits, item);
    }
}

/* Setup relative offsets of left and right values of data items */
function setupDataPositions(tableData) {
    for (let row of tableData.data) {
        if (!row.gantData || !tableData.gant || !tableData.gant.dateLimits || !tableData.gant.dateLimits.from || !tableData.gant.dateLimits.to) {
            continue;
        }
        setupOffsets(tableData.gant.dateLimits, row.gantData.chosenDate);
        setupOffsets(tableData.gant.dateLimits, row.gantData.plannedDate);
    }
}

function parseLocalDate(dateString) {
    let date = new Date(Date.parse(dateString));
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date.getTime();
}

function parseItems(data) {
    for (let i = 0; i < data.length; ++i) {
        let item = data[i];
        item.originalIndex = i;
        if (typeof item.from == "string") {
            item.from = parseLocalDate(item.from);
        }
        if (typeof item.to == "string") {
            item.to = parseLocalDate(item.to);
        }
    }
}

function parseGantData(rows) {
    for (let row of rows) {
        if (!row || !row.gantData) {
            continue;
        }
        parseItems(row.gantData.chosenDate);
        parseItems(row.gantData.plannedDate);
    }
}

function roundRowData(tableData, data, rowIdx, type) {
    for (let idx = 0; idx < data.length; ++idx) {
        let item = data[idx];
        let roundedFrom = roundDate(tableData.gant, item.from, tableData.gant.initialRounding, "from");
        let roundedTo = roundDate(tableData.gant, item.to, tableData.gant.initialRounding, "to");
        if (roundedFrom != item.from || roundedTo != item.to) {
            changeGantItem(tableData, type, rowIdx, idx, { from: roundedFrom, to: roundedTo });
        }
    }
}

function roundGantData(tableData, rows) {
    for (let row of rows) {
        if (!row.gantData) {
            continue;
        }
        roundRowData(tableData, row.gantData.chosenDate, row.originalRowIdx, CHOSEN_DATE);
        roundRowData(tableData, row.gantData.plannedDate, row.originalRowIdx, PLANNED_DATE);
    }
}

function setupGroups(tableData, data, isPlanned) {
    for (let item of data) {
        if (item.groupObject) {
            continue;
        }
        if (isPlanned) {
            item.groupObject = item.group;
            continue;
        }
        let itemGroups = [];
        if (Array.isArray(item.group)) {
            itemGroups = item.group;
        } else {
            itemGroups = [item.group];
        }
        for (let itemGroup of itemGroups) {
            let groupId = typeof itemGroup == "object" ? itemGroup.id : itemGroup;
            let group = findGroupById(tableData.gant.groups, groupId);
            if (!group) {
                group = {
                    id: groupId,
                    name: typeof itemGroup == "object" ? itemGroup.name : groupId,
                    color: getRandomRGB()
                }
                tableData.gant.groups = tableData.gant.groups.slice();
                tableData.gant.groups.push(group);
            }
            item.groupObject = group;
        }
    }
}

function setupGantDataGroups(tableData, rows) {
    for (let row of rows) {
        if (!row.gantData) {
            continue;
        }
        setupGroups(tableData, row.gantData.chosenDate);
        setupGroups(tableData, row.gantData.plannedDate, true);
    }
    setupGroupBorders(tableData);
}

function setupGroupBorders(tableData) {
    for (let group of tableData.gant.groups) {
        if (group.border) {
            continue;
        }
        group.border = shadeBlendConvert(-0.25, STANDART_COLORS[group.color] || group.color);
    }
}

function storeFetchedRows(tableData, rows, offset = 0, pageSize = 25) {
    for (let i = 0; i < Math.max(pageSize, rows.length); ++i) {
        tableData.fetchedRows[i + offset] = rows[i];
    }
}

function updateDataRows(tableData, rows) {
    parseGantData(rows);
    if (tableData.gant) {
        roundGantData(tableData, rows);
        setupGantDataGroups(tableData, rows);
    }
    filterRows(tableData);
    sortRows(tableData);
    rows = getData(tableData);
    applyAutomations(tableData, rows);
    runAutomationBindings(tableData, rows);
}

function updateData(tableData, data) {
    let rows = data.rows;
    tableData.data = parseRows(rows, tableData.columns);
    if (data.pageable) {
        if (typeof data.totalNumberOfElements != "undefined") {
            tableData.totalDataLength = data.totalNumberOfElements;
        }
        tableData.fetchedRows = {};
        storeFetchedRows(tableData, tableData.data, data.offset, data.limit);
    }
    if (data.fields) {
        tableData.fields = data.fields;
    }
    updateDataRows(tableData, tableData.data);
}

function changeGantItem(tableData, type, pageRowIdx, itemIdx, item) {
    if (!tableData.gant.syncField) {
        changeSingleGantItem(tableData, type, pageRowIdx, itemIdx, item);
        return;
    }
    const syncFieldValue = tableData.data[pageRowIdx][tableData.gant.syncField];
    if (typeof syncFieldValue === "undefined") {
        changeSingleGantItem(tableData, type, pageRowIdx, itemIdx, item);
        return;
    }
    for (let i = 0; i < tableData.data.length; ++i) {
        const row = tableData.data[i];
        if (row[tableData.gant.syncField] === syncFieldValue) {
            changeSingleGantItem(tableData, type, i, itemIdx, item);
        }
    }
}

function changeSingleGantItem(tableData, type, pageRowIdx, itemIdx, item) {
    if (item.from == item.to) {
        removeGantItem(tableData, type, rowIdx, itemIdx);
        return;
    }
    const gant = tableData.gant;
    const pageOffset = tableData.page ? (tableData.page - 1) * tableData.pageSize : 0;
    const rowIdx = pageOffset + pageRowIdx;
    gant.changeSet = Object.assign({}, gant.changeSet);
    if (!gant.changeSet[type]) {
        gant.changeSet[type] = { added: {}, changed: {}, removed: {} };
    }
    if (!gant.changeSet[type].changed[rowIdx]) {
        gant.changeSet[type].changed[rowIdx] = {};
    }
    gant.changeSet[type].changed[rowIdx][itemIdx] = { from: item.from, to: item.to };
    tableData.data[pageRowIdx].gantData[type][itemIdx] = Object.assign({}, tableData.data[pageRowIdx].gantData[type][itemIdx]);
    tableData.data[pageRowIdx].gantData[type][itemIdx].from = item.from;
    tableData.data[pageRowIdx].gantData[type][itemIdx].to = item.to;
}

function addGantItem(tableData, type, pageRowIdx, item, reverse) {
    const gant = tableData.gant;
    const pageOffset = (tableData.page - 1) * tableData.pageSize;
    const rowIdx = pageOffset + pageRowIdx;
    gant.changeSet = Object.assign({}, gant.changeSet);
    if (!gant.changeSet[type]) {
        gant.changeSet[type] = { added: {}, changed: {}, removed: {} };
    }

    let data = tableData.data[pageRowIdx].gantData[type];
    let index = 0;
    if (!reverse) {
        for (; index < data.length; ++index) {
            if (item.from > data[index].from) {
                continue;
            }
            if (data[index - 1] && item.from < data[index - 1].to) {
                if (item.to < data[index - 1].to) {
                    return;
                }
                item.from = data[index - 1].to;
            }
            if (item.to > data[index].from) {
                item.to = data[index].from;
            }
            break;
        }
    } else {
        index = data.length;
        for (; index > 0; --index) {
            if (item.to < data[index - 1].to) {
                continue;
            }
            if (data[index] && item.to > data[index].from) {
                if (item.from > data[index].from) {
                    return;
                }
                item.to = data[index].from;
            }
            if (item.from < data[index - 1].to) {
                item.from = data[index - 1].to;
            }
            break;
        }
    }
    if (item.to == item.from) {
        return;
    }
    data.splice(index, 0, item);

    setupItemOffsets(tableData.gant.dateLimits, item);
    item.group = tableData.gant.selectedGroup;
    item.groupObject = item.group;
    item.added = true;

    if (!gant.changeSet[type].added[rowIdx]) {
        gant.changeSet[type].added[rowIdx] = [];
    }
    gant.changeSet[type].added[rowIdx].push(item);
}

function removeGantItem(tableData, type, pageRowIdx, idx) {
    const gant = tableData.gant;
    if (gant.permissions.value != "full") {
        return;
    }
    const pageOffset = (tableData.page - 1) * tableData.pageSize;
    const rowIdx = pageOffset + pageRowIdx;
    const data = tableData.data[pageRowIdx].gantData[type];
    const item = data[idx];
    gant.changeSet = Object.assign({}, gant.changeSet);
    if (!gant.changeSet[type]) {
        gant.changeSet[type] = { added: {}, changed: {}, removed: {} };
    }
    if (item.added) {
        gant.changeSet[type].added[rowIdx] = gant.changeSet[type].added[rowIdx].slice();
        for (let dataIdx = 0; dataIdx < gant.changeSet[type].added[rowIdx].length; ++dataIdx) {
            if (gant.changeSet[type].added[rowIdx][dataIdx] == item) {
                gant.changeSet[type].added[rowIdx].splice(dataIdx, 1);
                if (gant.changeSet[type].added[rowIdx].length == 0) {
                    delete (gant.changeSet[type].added[rowIdx]);
                }
                break;
            }
        }
    } else {
        if (!gant.changeSet[type].removed[rowIdx]) {
            gant.changeSet[type].removed[rowIdx] = [];
        }
        gant.changeSet[type].removed[rowIdx].push(item);
    }
    data.splice(idx, 1);
}

function getStandartScaleHeaders(scale) {
    switch (scale) {
        case "year":
            return ["year"];
        case "month":
            return ["year", "month"];
        case "week":
            return ["year", "month", "week"];
        case "day":
            return ["year", "month", "day"];
        case "hour":
            return ["month", "day", "hour"];
        default:
            return ["year", "month", "day"];
    }
}

function tryGetLowerCase(value, defaultValue) {
    if (typeof value != "string") {
        return null;
    }
    return value.toLowerCase();
}

function tryParseInt(value, defaultValue) {
    if (typeof value == "undefined" || value == null) {
        return null;
    }
    value = parseInt(value);
    if (isNaN(value)) {
        return null;
    }
    return value;
}

function receiveGantOptions(tableData, gantOptions) {
    /* Initial gant state*/
    tableData.gant = {
        width: 0.5,
        headersHeight: tryParseInt(gantOptions.headersHeight),
        rowHeight: tryParseInt(gantOptions.rowHeight) || 80,
        fontSize: tryParseInt(gantOptions.fontSize) || 12,
        elementHeight: tryParseInt(gantOptions.elementHeight),
        elementPadding: tryParseInt(gantOptions.elementPadding) || 5,
        rowPadding: 5,
        minCellWidth: tryParseInt(gantOptions.minCellWidth) || 20,
        scale: tryGetLowerCase(gantOptions.scale) || "day",
        rounding: tryGetLowerCase(gantOptions.rounding) || "day",
        roundingType: tryGetLowerCase(gantOptions.roundingType) || "auto",
        dateLimits: { from: null, to: null, width: 1 },
        permissions: { value: "full", chosenOnly: false },
        saveFunction: gantOptions.saveFunction || null,
        fullWeeks: gantOptions.fullWeeks || false,
        singleElementOnRow: gantOptions.singleElementOnRow || false,
        groups: gantOptions.groups || [],
        syncField: gantOptions.syncField || "",
        plannedGroups: [
            { id: "plannedWork", name_id: "NPT_GANT_PLANNED_WORK", color: STANDART_COLORS["standartGray"], border: shadeBlendConvert(-0.25, STANDART_COLORS["standartGray"]) },
            { id: "plannedDocs", name_id: "NPT_GANT_PLANNED_DOCS", color: STANDART_COLORS["standartDarkGray"], border: shadeBlendConvert(-0.25, STANDART_COLORS["standartDarkGray"]) }
        ],
        /*Contains all changes after last successful saving*/
        changeSet: null,
        viewType: "read",
        selectedGroup: gantOptions.groups ? gantOptions.groups[0] : null
    };
    tableData.gant.initialRounding = tryGetLowerCase(gantOptions.initialRounding) || tableData.gant.roundingType;
    resetChangeSet(tableData);

    /* Calculate row padding */
    let itemsRows = 2;
    let elementsHeight = (tableData.gant.elementHeight + tableData.gant.elementPadding) * itemsRows - tableData.gant.elementPadding;
    if (tableData.gant.rowHeight - tableData.gant.rowPadding * 2 - elementsHeight < 0) {
        tableData.gant.elementHeight = (tableData.gant.rowHeight - tableData.gant.rowPadding * 2 - tableData.gant.elementPadding) / itemsRows + tableData.gant.elementPadding;
    } else {
        tableData.gant.rowPadding = (tableData.gant.rowHeight - elementsHeight) / 2;
    }

    /* Width value is in percentage - parse it */
    if (gantOptions.headersWidth) {
        let width = parseInt(gantOptions.headersWidth);
        if (!isNaN(width)) {
            tableData.gant.width = width / 100;
        }
    }
    tableData.gant.scaleHeaders = getStandartScaleHeaders(tableData.gant.scale);

    let border = 1;
    let headersPadding = 10;
    let minHeadersHeight = (tableData.gant.fontSize + border + headersPadding) * tableData.gant.scaleHeaders.length + 2; //1px for border between rows and 2px for bottom border of header
    if (!tableData.gant.headersHeight || tableData.gant.headersHeight < minHeadersHeight) {
        tableData.gant.headersHeight = minHeadersHeight;
    }

    if (gantOptions.dateLimits) {
        tableData.gant.dateLimits = {
            originalFrom: gantOptions.dateLimits.from,
            originalTo: gantOptions.dateLimits.to,
            expandFrom: gantOptions.dateLimits.expandFrom,
            expandTo: gantOptions.dateLimits.expandTo
        }
    }

    if (gantOptions.permissions) {
        tableData.gant.permissions = {
            value: tryGetLowerCase(gantOptions.permissions.value) || "full",
            chosenOnly: gantOptions.permissions.chosenOnly || false
        }
    }

    /* Planned groups are static, but name and color can be changed */
    if (gantOptions.gantPlannedGroups) {
        let plannedGroups = tableData.gant.plannedGroups;
        if (gantOptions.gantPlannedGroups.plannedWork) {
            plannedGroups[0].name = gantOptions.gantPlannedGroups.plannedWork.name;
            plannedGroups[0].color = STANDART_COLORS[gantOptions.gantPlannedGroups.plannedWork.color] || gantOptions.gantPlannedGroups.plannedWork.color || plannedGroups[0].color;
            plannedGroups[0].border = shadeBlendConvert(-0.25, plannedGroups[0].color);
        }
        if (gantOptions.gantPlannedGroups.plannedDocs) {
            plannedGroups[1].name = gantOptions.gantPlannedGroups.plannedDocs.name;
            plannedGroups[1].color = STANDART_COLORS[gantOptions.gantPlannedGroups.plannedDocs.color] || gantOptions.gantPlannedGroups.plannedDocs.color || plannedGroups[1].color;
            plannedGroups[1].border = shadeBlendConvert(-0.25, plannedGroups[1].color);
        }
    }

    updateScaleCells(tableData);
}

function parseDate(dateString) {
    const parts = dateString.split("-");
    const year = parseInt(parts[0]);
    let month = parseInt(parts[1]);
    const day = parseInt(parts[2]);
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
        console.error("Can't parse date string: ", dateString);
        return Date.now();
    }
    month = month - 1;
    return new Date(year, month, day).getTime();
}

function getBracersExpressions(date) {
    const expressions = [];
    let expr = getNextBracersExpression(date);
    while (expr != null) {
        expressions.push(expr.string);
        expr = getNextBracersExpression(date, expr.end);
    }
    return expressions;
}

function getNextBracersExpression(string, startIdx = 0) {
    const opening = string.indexOf("{", startIdx);
    if (opening == -1) {
        return null;
    }
    const closing = string.indexOf("}", opening);
    if (closing == -1) {
        return null;
    }
    return {
        start: opening,
        end: closing,
        string: string.substring(opening, closing + 1)
    }
}

function replaceFieldsValues(string, fields) {
    const match = string.match(/[a-zA-Z_]+/gi);
    if (match == null) {
        return string;
    }
    for (let param of match) {
        const value = fields[param];
        if (!value) {
            continue;
        }
        string = string.replace(param, value);
    }
    return string;
}

function evaluateMathExpr(mathString) {
    try {
        let result = eval(mathString);
        return result;
    } catch (ex) {
        console.error(`String "${mathString}" can't be parsed.`, ex);
        return mathString;
    }
}

/* Replace "{field}" with actual field value  */
function parseOriginalDate(date, fields) {
    if (!date) {
        return null;
    }
    if (fields) {
        const expressions = getBracersExpressions(date);
        for (let expr of expressions) {
            const mathString = replaceFieldsValues(expr.substring(1, expr.length - 1), fields);
            date = date.replace(expr, evaluateMathExpr(mathString));
        }
    }
    return parseDate(date);
}

function checkLimits(limits, data) {
    for (let item of data) {
        let from = item.from;
        let to = item.to;
        if (typeof from == "string") {
            from = parseLocalDate(from);
        }
        if (typeof to == "string") {
            to = parseLocalDate(to);
        }
        if (!limits.from || from < limits.from) {
            limits.from = from;
        }
        if (!limits.to || to > limits.to) {
            limits.to = to;
        }
    }
}

/* Get minimum and maximum date value from data */
function calculateLimits(data) {
    let limits = {
        from: null,
        to: null
    }
    if (!data) {
        return limits;
    }
    for (let row of data) {
        if (!row.gantData) {
            continue;
        }
        checkLimits(limits, row.gantData.chosenDate);
        checkLimits(limits, row.gantData.plannedDate);
    }
    return limits;
}

function addTime(date, time) {
    date.setTime(date.getTime() + time);
}

function getValueByScale(date, scale) {
    switch (scale) {
        case "hour":
            return date.getHours();
        case "day":
            return date.getDate();
        case "week":
            return getWeekNumber(date);
        case "month":
            return date.getMonth();
        case "year":
            return date.getFullYear();
    }
    return null
}

function checkValue(scale, date, prevValue) {
    let newValue = getValueByScale(date, scale);

    switch (scale) {
        case "week":
            if (newValue == 0) {
                return null;
            }
            if (newValue != 5) {
                break;
            }
            let nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
            if (getCorrectDay(nextMonth) < 4) {
                newValue = 1;
            }
            break;
    }

    if (newValue == prevValue) {
        return null;
    }
    return newValue;
}

function addCell(scale, cells, value, cellspan) {
    let cell = {
        value: value,
        cellspan: cellspan
    }
    switch (scale) {
        case "year":
            // cell.formatted = "NPT_GANT_YEAR";
            // cell.values = { year: value };
            break;
        case "month":
            cell.formatted = "NPT_GANT_MONTH_" + MONTHS[value];
            break;
        default:
            break;
    }
    cells[scale].push(cell);
}

/* Create scale cells for every scale due to date limits */
function updateScaleCells(tableData) {
    let cells = {
        hour: [],
        day: [],
        week: [],
        month: [],
        year: []
    }
    let currentWidth = {
        hour: 0,
        day: 0,
        week: 0,
        month: 0,
        year: 0
    }
    let totalCells = 0;
    let initialDate = new Date(tableData.gant.dateLimits.from);
    let values = {
        hour: getValueByScale(initialDate, "hour"),
        day: getValueByScale(initialDate, "day"),
        week: checkValue("week", initialDate, null),
        month: getValueByScale(initialDate, "month"),
        year: getValueByScale(initialDate, "year")
    }

    let step = null;
    if (tableData.gant.scale == "hour") {
        step = minScaleValue["hour"]();
    } else {
        step = minScaleValue["day"]();
    }

    let makeStep = function () {
        addTime(initialDate, step);
        ++totalCells;
        for (let scale of tableData.gant.scaleHeaders) {
            ++currentWidth[scale];
            let newValue = checkValue(scale, initialDate, values[scale]);
            if (newValue === null) {
                continue;
            }
            addCell(scale, cells, values[scale], currentWidth[scale]);
            values[scale] = newValue;
            currentWidth[scale] = 0;
        }
    }

    while (initialDate.getTime() < tableData.gant.dateLimits.to) {
        makeStep();
    }
    for (let scale in currentWidth) {
        if (currentWidth[scale] != 0) {
            addCell(scale, cells, getValueByScale(initialDate, scale), currentWidth[scale]);
        }
    }
    for (let scale in cells) {
        for (let cell of cells[scale]) {
            cell.width = cell.cellspan / totalCells;
        }
    }
    tableData.gant.cells = cells;
}

function updateDateLimits(tableData) {
    tableData.gant.dateLimits = Object.assign({}, tableData.gant.dateLimits);
    let dateLimits = tableData.gant.dateLimits;
    dateLimits.from = parseOriginalDate(dateLimits.originalFrom, tableData.fields);
    dateLimits.to = parseOriginalDate(dateLimits.originalTo, tableData.fields);

    let autoFrom = dateLimits.expandFrom || !dateLimits.from;
    let autoTo = dateLimits.expandTo || !dateLimits.to;
    if (autoFrom || autoTo) {
        let autoLimits = calculateLimits(getData(tableData));
        if (!dateLimits.from) {
            dateLimits.from = autoLimits.from;
        } else if (dateLimits.expandFrom && autoLimits.from) {
            dateLimits.from = Math.min(autoLimits.from, dateLimits.from);
        }
        if (!dateLimits.to) {
            dateLimits.to = autoLimits.to;
        } else if (dateLimits.expandTo && autoLimits.to) {
            dateLimits.to = Math.max(autoLimits.to, dateLimits.to);
        }
    }

    dateLimits.from = roundDate(tableData.gant, dateLimits.from);
    dateLimits.to = roundDate(tableData.gant, dateLimits.to);
    dateLimits.width = dateLimits.to - dateLimits.from;

    updateScaleCells(tableData);
}

function findGroupById(groups, groupId) {
    for (let group of groups) {
        if (group.id == groupId) {
            return group;
        }
    }
    return null;
}

function resetChangeSet(tableData) {
    tableData.gant.changeSet = {
        [PLANNED_DATE]: { added: {}, changed: {}, removed: {} },
        [CHOSEN_DATE]: { added: {}, changed: {}, removed: {} }
    }
}

function setupTotalPages(tableData) {
    tableData.totalPages = Math.ceil(tableData.totalDataLength / tableData.pageSize);
}

function prepareData(tableData, data) {
    if (tableData.orderBy && Array.isArray(data.rows)) {
        data.rows.sort(function (a, b) {
            if (typeof a == "undefined" || a == null || typeof b == "undefined" || b == null) {
                return 0;
            }
            if (a[tableData.orderBy] < b[tableData.orderBy]) {
                return -1;
            }
            if (a[tableData.orderBy] > b[tableData.orderBy]) {
                return 1;
            }
            return 0;
        });
    }
    return data;
}

////////////
//Reducers//
////////////
function waitForInfo(state, { tableId }) {
    let tableData = Object.assign({}, state[tableId], { loading: true, finder: initialFinder });
    return Object.assign({}, state, { [tableId]: tableData });
}

function infoReceived(state, { tableId, info }) {
    let tableData = Object.assign({}, state[tableId], {
        loading: false,
        selectType: tryGetLowerCase(info.header.selectType),
        selectedRows: { length: 0 }
    });
    if (info.header.automation) {
        tableData.automation = compileAutomationScripts(info.header.automation);
    }
    removeStyle(tableData.styleId); //Clean previous styles if needed
    if (info.header.stylesheet) {
        //Add stylesheet to document
        tableData.styleId = addStyle(info.header.stylesheet)
    }
    updateToolbar(tableData, info.header);
    updateInfo(tableData, info.header);
    if (info.header.gantOptions) {
        receiveGantOptions(tableData, info.header.gantOptions);
    }

    let nextState = Object.assign({}, state, { [tableId]: tableData });
    return dataReceived(nextState, { tableId, data: info.data });
}

function infoErrorReceived(state, { tableId, error }) {
    let tableData = Object.assign({}, state[tableId], { loading: false, error });
    return Object.assign({}, state, { [tableId]: tableData });
}

function waitForData(state, { tableId }) {
    let tableData = Object.assign({}, state[tableId], { loadingData: true, error: false });
    return Object.assign({}, state, { [tableId]: tableData });
}

/* If column format isn't defined - try to get it from data */
function updateHeadersFormat(tableData, data) {
    if (!data || !data.length || !tableData.header) {
        return;
    }
    let updatedHeaders = null;
    for (let i = 0; i < tableData.header.length; ++i) {
        const header = tableData.header[i];
        if (header.autoGenerated || header.format) {
            continue;
        }
        for (let row of data) {
            if (typeof row[header.field] == "undefined" || row[header.field] == null) {
                continue;
            }
            if (updatedHeaders == null) {
                updatedHeaders = tableData.header.slice();
            }
            updatedHeaders[i] = Object.assign({}, updatedHeaders[i]);
            switch (typeof row[header.field]) {
                case "number":
                    updatedHeaders[i].format = "number";
                    break;
                case "string":
                default:
                    updatedHeaders[i].format = "text";
                    break;

            }
            break;
        }
    }
    if (updatedHeaders != null) {
        tableData.header = updatedHeaders;
    }
}

function dataReceived(state, { tableId, data }) {
    let tableData = Object.assign({}, state[tableId], { loadingData: false, error: false });
    let nextState = Object.assign({}, state, { [tableId]: tableData });
    tableData.pageable = data.pageable;
    tableData.filters = {};
    prepareData(tableData, data);
    updateHeadersFormat(tableData, data.rows);
    updateData(tableData, data);
    updatePageData(tableData);
    if (tableData.gant) {
        tableData.gant = Object.assign({}, tableData.gant);
        updateDateLimits(tableData);
        setupDataPositions(tableData);
    }
    if (data.pageable) {
        let pageSize = data.limit || 25;
        let page = data.offset ? Math.floor(data.offset / pageSize) + 1 : 1;
        nextState = changePageSize(nextState, { tableId, pageSize });
        nextState = changePage(nextState, { tableId, page });
    }
    if (tableData.gant) {
        resetChangeSet(tableData);
    }
    return nextState;
}

function waitForPage(state, { tableId }) {
    let tableData = Object.assign({}, state[tableId], { loadingPageData: true, pageError: false });
    return Object.assign({}, state, { [tableId]: tableData });
}

function pageReceived(state, { tableId, data, page, pageSize }) {
    let tableData = Object.assign({}, state[tableId], { loadingPageData: false, pageError: false });
    let nextState = Object.assign({}, state, { [tableId]: tableData });
    tableData.fetchedRows = Object.assign({}, tableData.fetchedRows);

    prepareData(tableData, data);
    nextState[tableId].data = parseRows(data.rows, tableData.columns);

    let pageStart = (page - 1) * pageSize;
    storeFetchedRows(tableData, parseRows(data.rows, tableData.columns), pageStart, pageSize);
    if (tableData.pageSize != pageSize) {
        nextState = changePageSize(nextState, { tableId, pageSize });
    }
    if (tableData.page != page) {
        nextState = changePage(nextState, { tableId, page });
    }
    updateDataRows(nextState[tableId], nextState[tableId].data);
    updatePageData(nextState[tableId]);
    return nextState;
}

function dataErrorReceived(state, { tableId, error }) {
    let tableData = Object.assign({}, state[tableId], { loadingData: false, error });
    return Object.assign({}, state, { [tableId]: tableData });
}

function pageErrorReceived(state, { tableId, error }) {
    let tableData = Object.assign({}, state[tableId], { loadingPageData: false, pageError: error });
    return Object.assign({}, state, { [tableId]: tableData });
}

function initializeTableFields(state, { tableId, fields }) {
    if (!state[tableId]) {
        return state;
    }
    let tableData = Object.assign({}, state[tableId]);
    tableData.fields = fields;
    return Object.assign({}, state, { [tableId]: tableData });
}

function resetTableFields(state, { tableId }) {
    if (!state[tableId]) {
        return state;
    }
    let tableData = Object.assign({}, state[tableId]);
    tableData.fields = {};
    return Object.assign({}, state, { [tableId]: tableData });
}

function sortByColumn(state, { tableId, column }) {
    let tableData = sortTable(state[tableId], column);
    return Object.assign({}, state, { [tableId]: tableData });
}

function filterByColumn(state, { tableId, column, filter }) {
    let tableData = filterTable(state[tableId], column, filter);
    runAutomationBindings(tableData, getData(tableData));
    return Object.assign({}, state, { [tableId]: tableData });
}

function selectRow(state, { tableId, key }) {
    let tableData = Object.assign({}, state[tableId]);
    switch (tableData.selectType) {
        case "checkbox":
            tableData.selectedRows = Object.assign({}, tableData.selectedRows);
            tableData.selectedRows[key] = !tableData.selectedRows[key];
            if (tableData.selectedRows[key]) {
                ++tableData.selectedRows.length;
            } else {
                --tableData.selectedRows.length;
            }
            break;
        case "radio":
            tableData.selectedRows = {
                [key]: !tableData.selectedRows[key],
                length: tableData.selectedRows[key] ? 0 : 1
            }
            break;
        default:
            return state;
    }
    return Object.assign({}, state, { [tableId]: tableData });
}

function selectAllRows(state, { tableId, select }) {
    let tableData = Object.assign({}, state[tableId]);
    tableData.selectedRows = { length: 0 };
    if (select) {
        let data = getData(tableData);
        let selectedNumber = 0;
        for (let row of data) {
            if (typeof row.key == "undefined" || row.key == null) {
                continue;
            }
            tableData.selectedRows[row.key] = true;
            ++selectedNumber;
        }
        tableData.selectedRows.length = selectedNumber;
    }
    return Object.assign({}, state, { [tableId]: tableData });
}

function changePage(state, { tableId, page }) {
    let tableData = Object.assign({}, state[tableId]);
    tableData.page = page;
    updatePageData(tableData);
    return Object.assign({}, state, { [tableId]: tableData });
}

function changePageSize(state, { tableId, pageSize }) {
    let tableData = Object.assign({}, state[tableId]);
    let offset = tableData.pageSize * (tableData.page - 1);
    tableData.pageSize = pageSize;
    let page = Math.floor(offset / pageSize) + 1;
    tableData.page = page;
    setupTotalPages(tableData);
    updatePageData(tableData);
    return Object.assign({}, state, { [tableId]: tableData });
}

function changeViewType(state, { tableId, viewType }) {
    let tableData = Object.assign({}, state[tableId]);
    tableData.gant = Object.assign({}, tableData.gant);
    tableData.gant.viewType = viewType;
    return Object.assign({}, state, { [tableId]: tableData });
}

function changeSelectedGroup(state, { tableId, group }) {
    let tableData = Object.assign({}, state[tableId]);
    tableData.gant = Object.assign({}, tableData.gant);
    tableData.gant.selectedGroup = findGroupById(tableData.gant.groups, group);
    return Object.assign({}, state, { [tableId]: tableData });
}

function changeItem(state, { tableId, type, rowIndex, index, from, to }) {
    let tableData = Object.assign({}, state[tableId]);
    tableData.data = tableData.data.slice();
    tableData.data[rowIndex] = Object.assign({}, tableData.data[rowIndex]);
    tableData.data[rowIndex].gantData = Object.assign({}, tableData.data[rowIndex].gantData);
    tableData.data[rowIndex].gantData[type] = tableData.data[rowIndex].gantData[type].slice();
    tableData.data[rowIndex].gantData[type][index] = Object.assign({}, tableData.data[rowIndex].gantData[type][index]);
    from = roundDate(tableData.gant, from, tableData.gant.roundingType, "from");
    to = roundDate(tableData.gant, to, tableData.gant.roundingType, "to");
    changeGantItem(tableData, type, rowIndex, index, { from, to });
    if (tableData.pageable) {
        storeFetchedRows(tableData, tableData.data, (tableData.page - 1) * tableData.pageSize, tableData.pageSize);
        updatePageData(tableData);
    }
    return Object.assign({}, state, { [tableId]: tableData });
}

function addItem(state, { tableId, rowIndex, from, to }) {
    let tableData = Object.assign({}, state[tableId]);
    let type = tableData.gant.viewType;
    tableData.data = tableData.data.slice();
    tableData.data[rowIndex] = Object.assign({}, tableData.data[rowIndex]);
    tableData.data[rowIndex].gantData = Object.assign({}, tableData.data[rowIndex].gantData);
    tableData.data[rowIndex].gantData[type] = tableData.data[rowIndex].gantData[type].slice();
    from = roundDate(tableData.gant, from, tableData.gant.roundingType, "from");
    to = roundDate(tableData.gant, to, tableData.gant.roundingType, "to");
    let reverse = false;
    if (to < from) {
        let temp = from;
        from = to;
        to = temp;
        reverse = true;
    }
    addGantItem(tableData, type, rowIndex, { from, to }, reverse);
    if (tableData.pageable) {
        storeFetchedRows(tableData, tableData.data, (tableData.page - 1) * tableData.pageSize, tableData.pageSize);
        updatePageData(tableData);
    }
    return Object.assign({}, state, { [tableId]: tableData });
}

function removeItem(state, { tableId, type, rowIndex, index }) {
    let tableData = Object.assign({}, state[tableId]);
    tableData.data = tableData.data.slice();
    tableData.data[rowIndex] = Object.assign({}, tableData.data[rowIndex]);
    tableData.data[rowIndex].gantData = Object.assign({}, tableData.data[rowIndex].gantData);
    tableData.data[rowIndex].gantData[type] = tableData.data[rowIndex].gantData[type].slice();
    removeGantItem(tableData, type, rowIndex, index);
    if (tableData.pageable) {
        storeFetchedRows(tableData, tableData.data, (tableData.page - 1) * tableData.pageSize, tableData.pageSize);
        updatePageData(tableData);
    }
    return Object.assign({}, state, { [tableId]: tableData });
}

function saveGant(state, { tableId }) {
    let tableData = Object.assign({}, state[tableId]);
    tableData.gant = Object.assign({}, tableData.gant);
    resetChangeSet(tableData);
    return Object.assign({}, state, { [tableId]: tableData });
}

function compareHeaders(headers1, headers2) {
    if (headers1.length != headers2.length) {
        return false;
    }
    for (let i = 0; i < headers1.length; ++i) {
        if (headers1[i].field != headers2[i].field) {
            return false;
        }
    }
    return true;
}

function updateHeaders(state, { path, headers }) {
    let tableData = Object.assign({}, state[path]);
    tableData.parsedColumns = Object.assign({}, tableData.parsedColumns);
    let parsedHeaders = parseColumns(tableData, headers, tableData.parsedColumns);
    if (!compareHeaders(parsedHeaders, tableData.initialHeaders)) {
        tableData.header = parsedHeaders;
        tableData.dynamicHeaders = parsedHeaders;
    } else {
        tableData.header = tableData.initialHeaders;
        delete (tableData.dynamicHeaders);
    }
    return Object.assign({}, state, { [path]: tableData });
}

function changeFilterLink(state, { path, filterLink }) {
    let tableData = Object.assign({}, state[path], { filterLink });
    return Object.assign({}, state, { [path]: tableData });
}

////////////
// Finder //
////////////
function storeFinderFilter(state, { path, finderFilter }) {
    let tableData = Object.assign({}, state[path], { finderFilter });
    return Object.assign({}, state, { [path]: tableData });
}

function importFinderHandler(state, { path, finderData }) {
    let tableData = Object.assign({}, state[path], { finderFilter: finderData.finder });
    tableData.finder = Object.assign({}, tableData.finder);
    importFinder(tableData.finder, finderData);
    return Object.assign({}, state, { [path]: tableData });
}

function parseFinderOptionsHandler(state, { path, finderOptions }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    parseFinderOptions(tableData.finder, finderOptions);
    return Object.assign({}, state, { [path]: tableData });
}

function classLevelsReceivedHandler(state, { path, levels }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    classLevelsReceived(tableData.finder, levels);
    return Object.assign({}, state, { [path]: tableData });
}

function waitForClassesHandler(state, { path, levelId }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    waitForClasses(tableData.finder, levelId);
    return Object.assign({}, state, { [path]: tableData });
}

function classesReceivedHandler(state, { path, levelId, classes }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    classesReceived(tableData.finder, levelId, classes);
    return Object.assign({}, state, { [path]: tableData });
}

function waitForFragmentsHandler(state, { path, parentId }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    waitForFragments(tableData.finder, parentId);
    return Object.assign({}, state, { [path]: tableData });
}

function fragmentLevelsReceivedHandler(state, { path, levels }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    fragmentLevelsReceived(tableData.finder, levels);
    return Object.assign({}, state, { [path]: tableData });
}

function fragmentsReceivedHandler(state, { path, parentId, fragments }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    fragmentsReceived(tableData.finder, parentId, fragments);
    return Object.assign({}, state, { [path]: tableData });
}

function waitForFieldHandler(state, { path, parentId, data }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    waitForField(tableData.finder, parentId, data);
    return Object.assign({}, state, { [path]: tableData });
}

function fieldsReceivedHandler(state, { path, parentId, data }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    let filteredData = data;
    if (tableData.automation && tableData.automation.predicateFilterBinding) {
        const filterFunc = retrieveFunction(tableData.automation.predicateFilterBinding);
        filteredData = filteredData.filter((field) => filterFunc(field.predicate));
    };
    fieldsReceived(tableData.finder, parentId, filteredData);
    return Object.assign({}, state, { [path]: tableData });
}

function objectcardReceivedHandler(state, { path, rdfId, data }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    objectcardReceived(tableData.finder, rdfId, data);
    return Object.assign({}, state, { [path]: tableData });
}

function objectcardErrorReceivedHandler(state, { path, rdfId, error }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    objectcardErrorReceived(tableData.finder, rdfId, error);
    return Object.assign({}, state, { [path]: tableData });
}

function predicateReceivedHandler(state, { path, fieldId, predicateName, data }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    predicateReceived(tableData.finder, fieldId, predicateName, data);
    return Object.assign({}, state, { [path]: tableData });
}

function toggleFinderHidden(state, { path, hidden }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    if (typeof hidden == "undefined") {
        hidden = !tableData.finder.isHidden;
    }
    tableData.finder.isHidden = hidden;
    return Object.assign({}, state, { [path]: tableData });
}

function changeFinderViewType(state, { path, viewType }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    tableData.finder.view = viewType;
    return Object.assign({}, state, { [path]: tableData });
}

function changeCriteriaRelationHandler(state, { path, criteriaId, relation, relationIdx }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    changeRelation(tableData.finder, criteriaId, relation, relationIdx);
    return Object.assign({}, state, { [path]: tableData });
}

function addCriteriaRelationHandler(state, { path, criteriaId }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    addRelation(tableData.finder, criteriaId);
    return Object.assign({}, state, { [path]: tableData });
}

function removeCriteriaRelationHandler(state, { path, criteriaId, relationIdx }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    removeRelation(tableData.finder, criteriaId, relationIdx);
    return Object.assign({}, state, { [path]: tableData });
}

function removeCriteriaFieldHandler(state, { path, criteriaId, fieldId }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    changeField(tableData.finder, criteriaId, fieldId);
    return Object.assign({}, state, { [path]: tableData });
}

function addCriteriaHandler(state, { path, criteriaGroupId }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    addNewCriteria(tableData.finder, criteriaGroupId);
    return Object.assign({}, state, { [path]: tableData });
}

function removeCriteriaHandler(state, { path, criteriaId }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    removeCriteria(tableData.finder, criteriaId);
    return Object.assign({}, state, { [path]: tableData });
}

function unlockCriteriaRelationsHandler(state, { path, criteriaId }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    unlockCriteriaRelations(tableData.finder, criteriaId);
    return Object.assign({}, state, { [path]: tableData });
}

function removeCriteriaGroupHandler(state, { path, criteriaGroupId }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    removeCriteriaGroup(tableData.finder, criteriaGroupId);
    return Object.assign({}, state, { [path]: tableData });
}

function selectFragmentHandler(state, { path, oldFragmentId, newFragmentId }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    selectFragment(tableData.finder, oldFragmentId, newFragmentId);
    return Object.assign({}, state, { [path]: tableData });
}

function selectClassHandler(state, { path, oldClassId, newClassId, level }) {
    let tableData = Object.assign({}, state[path]);
    tableData.finder = Object.assign({}, tableData.finder);
    selectClass(tableData.finder, oldClassId, newClassId, level);
    return Object.assign({}, state, { [path]: tableData });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.WAIT_FOR_INFO: return waitForInfo(state, action.payload);
        case Action.INFO_RECEIVED: return infoReceived(state, action.payload);
        case Action.INFO_ERROR_RECEIVED: return infoErrorReceived(state, action.payload);
        case Action.WAIT_FOR_DATA: return waitForData(state, action.payload);
        case Action.DATA_RECEIVED: return dataReceived(state, action.payload);
        case Action.WAIT_FOR_PAGE: return waitForPage(state, action.payload);
        case Action.PAGE_RECEIVED: return pageReceived(state, action.payload);
        case Action.DATA_ERROR_RECEIVED: return dataErrorReceived(state, action.payload);
        case Action.PAGE_ERROR_RECEIVED: return pageErrorReceived(state, action.payload);
        case Action.INITIALIZE_TABLE_FIELDS: return initializeTableFields(state, action.payload);
        case Action.RESET_TABLE_FIELDS: return resetTableFields(state, action.payload);
        case Action.SORT_COLUMN: return sortByColumn(state, action.payload);
        case Action.FILTER_COLUMN: return filterByColumn(state, action.payload);
        case Action.SELECT_ROW: return selectRow(state, action.payload);
        case Action.SELECT_ALL_ROWS: return selectAllRows(state, action.payload);
        case Action.CHANGE_PAGE: return changePage(state, action.payload);
        case Action.CHANGE_PAGE_SIZE: return changePageSize(state, action.payload);
        case Action.CHANGE_VIEW_TYPE: return changeViewType(state, action.payload);
        case Action.CHANGE_SELECTED_GROUP: return changeSelectedGroup(state, action.payload);
        case Action.CHANGE_ITEM: return changeItem(state, action.payload);
        case Action.ADD_ITEM: return addItem(state, action.payload);
        case Action.REMOVE_ITEM: return removeItem(state, action.payload);
        case Action.SAVE_GANT: return saveGant(state, action.payload);

        case Action.UPDATE_HEADERS: return updateHeaders(state, action.payload);
        case Action.CHANGE_FILTER_LINK: return changeFilterLink(state, action.payload);
        case Action.STORE_FINDER_FILTER: return storeFinderFilter(state, action.payload);
        /**
         * Finder actions
         */
        case Action.IMPORT_FINDER: return importFinderHandler(state, action.payload);
        case Action.PARSE_FINDER_OPTIONS: return parseFinderOptionsHandler(state, action.payload);
        case Action.CLASS_LEVELS_RECEIVED: return classLevelsReceivedHandler(state, action.payload);
        case Action.WAIT_FOR_CLASSES: return waitForClassesHandler(state, action.payload);
        case Action.CLASSES_RECEIVED: return classesReceivedHandler(state, action.payload);
        case Action.FRAGMENT_LEVELS_RECEIVED: return fragmentLevelsReceivedHandler(state, action.payload);
        case Action.WAIT_FOR_FRAGMENTS: return waitForFragmentsHandler(state, action.payload);
        case Action.FRAGMENTS_RECEIVED: return fragmentsReceivedHandler(state, action.payload);
        case Action.WAIT_FOR_FIELD: return waitForFieldHandler(state, action.payload);
        case Action.FIELDS_RECEIVED: return fieldsReceivedHandler(state, action.payload);
        case Action.OBJECTCARD_RECEIVED: return objectcardReceivedHandler(state, action.payload);
        case Action.OBJECTCARD_ERROR_RECEIVED: return objectcardErrorReceivedHandler(state, action.payload);
        case Action.PREDICATE_RECEIVED: return predicateReceivedHandler(state, action.payload);
        case Action.TOGGLE_HIDDEN: return toggleFinderHidden(state, action.payload);
        case Action.CHANGE_FINDER_VIEW_TYPE: return changeFinderViewType(state, action.payload);
        /**
         * Criteria reducer actions
         */
        case Action.CHANGE_CRITERIA_RELATION: return changeCriteriaRelationHandler(state, action.payload);
        case Action.ADD_CRITERIA_RELATION: return addCriteriaRelationHandler(state, action.payload);
        case Action.REMOVE_CRITERIA_RELATION: return removeCriteriaRelationHandler(state, action.payload);
        case Action.CHANGE_CRITERIA_FIELD: return removeCriteriaFieldHandler(state, action.payload);
        case Action.ADD_CRITERIA: return addCriteriaHandler(state, action.payload);
        case Action.REMOVE_CRITERIA: return removeCriteriaHandler(state, action.payload);
        case Action.UNLOCK_CRITERIA_RELATIONS: return unlockCriteriaRelationsHandler(state, action.payload);
        case Action.REMOVE_CRITERIA_GROUP: return removeCriteriaGroupHandler(state, action.payload);
        /**
         * SideBar reducer actions
         */
        case Action.SELECT_FRAGMENT: return selectFragmentHandler(state, action.payload);
        case Action.SELECT_CLASS: return selectClassHandler(state, action.payload);
        default: return state;
    }
}