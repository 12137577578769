import React from 'react';
import { FormattedMessage } from 'react-intl';

class Breadcrumbs extends React.Component {

    constructor(props) {
        super(props);

        this.click = this.click.bind(this);
    }

    getItems() {
        const items = [];
        const parsedNodes = {};
        let node = this.props.nodeById[this.props.active];
        while (node && !parsedNodes[node.id]) {
            let item = {
                label: node.label,
                node: node
            };
            item.href = "#/?";
            if (item.namespace) {
                item.href += `namespace=${item.namespace}&`;
            }
            if (item.rdfId) {
                item.href += `object=${item.rdfId}`;
            }
            items.push(item);
            parsedNodes[node.id] = true;
            node = this.props.nodeById[node.parentId];
        }
        return items.reverse();
    }

    click(item, event) {
        event.preventDefault();
        this.props.openNode(item.node);
    }

    render() {
        if (this.props.error || this.props.errorId) {
            return <Error error={this.props.error} errorId={this.props.errorId} />;
        }
        if (this.props.messageId) {
            return <Message messageId={this.props.messageId} />;
        }
        if (this.props.loading) {
            return <Loading />;
        }
        if (!this.props.active) {
            return <Empty />;
        }
        return <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                {this.getItems().map((item) => <li class="breadcrumb-item"><a href={item.href} onClick={this.click.bind(this, item)}>{item.label}</a></li>)}
            </ol>
        </nav>;
    }
}


class Message extends React.Component {

    render() {
        let messageText = "";
        if (this.props.messageId) {
            messageText = <FormattedMessage id={this.props.messageId} />;
        }
        return <nav aria-label="breadcrumb">
            <ol class="breadcrumb">{messageText}</ol>
        </nav>;
    }
}

class Error extends React.Component {

    render() {
        let errorText = "Error";
        if (this.props.errorId) {
            errorText = <FormattedMessage id={this.props.errorId} />;
        } else if (this.props.error) {
            errorText = <span>{`Error ${this.props.error.status}: ${this.props.error.statusText}`}</span>;
        }
        return <div class="alert alert-danger" role="alert">{errorText}</div>
    }
}

const Loading = () => (<div class="alert alert-info" role="alert">
    <FormattedMessage
        id="BREADCRUMBS_LOADING"
        defaultMessage="Loading..."
        description="Corresponding tree is loading" />
</div>);

const Empty = () => (<div class="alert alert-info" role="alert">
    <FormattedMessage
        id="BREADCRUMBS_SELECT_NODE"
        defaultMessage="Select node in corresponding tree"
        description="Corresponding tree doesn't have active node" />
</div>);

export default Breadcrumbs;


