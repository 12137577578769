import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Modal from '../modal.jsx';

class StringFilterModal extends React.Component {

    constructor(props) {
        super(props);
        this.filterClearHandler = this.filterClearHandler.bind(this);
        this.saveNewFilter = this.saveNewFilter.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.checkSelected = this.checkSelected.bind(this);

        this.state = { selected: {} };
        if (props.options.selected) {
            this.state.selected = Object.assign({}, props.options.selected);
        } else { //by default select all
            let limits = props.options.limits;
            console.log("Default all selected value");
            for (let row of limits.values) {
                this.state.selected[row.key] = true;
            }
        }
    }

    getTitle() {
        if (this.props.options.type == 'word') {
            return (<FormattedMessage
                id="NPT_TABLE_WORD_FILTER_TITLE"
                defaultMessage="Please select words that field '{name}' contains"
                description="User should select words" values={{ name: this.props.options.label }} />);
        } else {
            return (<FormattedMessage
                id="NPT_TABLE_STRING_FILTER_TITLE"
                defaultMessage="Please select any variants of field '{name}'"
                description="User should select total titles" values={{ name: this.props.options.label }} />);
        }
    }

    onSelect(row, isSelected) {
        console.log("On select");
        let selected = null;
        if (!this.state.selected) {
            selected = {};
            for (let row of this.props.options.limits.values) {
                selected[row.key] = true;
            }
        } else {
            selected = Object.assign({}, this.state.selected);
        }
        selected[row.key] = isSelected;
        this.setState({ selected });
    }

    onSelectAll(isSelected, rows) {
        console.log("On select all")
        let selected = {};
        for (let row of rows) {
            selected[row.key] = isSelected;
        }
        this.setState({ selected });
    }

    getColumnLabel() {
        if (this.props.options.type == 'word') {
            return (<FormattedMessage
                id="NPT_TABLE_WORD_FILTER_LABEL"
                defaultMessage="Word"
                description="Word filter column label" />);
        } else {
            return (<FormattedMessage
                id="NPT_TABLE_STRING_FILTER_LABEL"
                defaultMessage="String"
                description="String filter column label" />);
        }
    }

    getBody() {
        console.log("StringFilter getPanel(), selected", this.state.selected);

        let limits = this.props.options.limits;

        const tableStyle = {
            margin: "1px 0px",
            borderRadius: "0px"
        };

        const containerStyle = {
            overflowX: 'auto'
        };

        let selectRow = {
            mode: "checkbox",
            clickToSelect: true,
            onSelect: this.onSelect,
            onSelectAll: this.onSelectAll,
            selected: []
        }

        if (this.state.selected) {
            console.log("Previous selected value");
            for (let key in this.state.selected) {
                if (this.state.selected[key]) {
                    selectRow.selected.push(key);
                }
            }
        }

        return (<BootstrapTable
            version='4'
            height="400px"
            scrollTop={'Top'}
            tableStyle={tableStyle}
            containerStyle={containerStyle}
            selectRow={selectRow}
            data={limits.values}
            search multiColumnSearch searchPlaceholder='...'
            striped={true}
            hover={true}
            ref="table">
            <TableHeaderColumn
                isKey={true}
                dataField="key"
                hidden={true}
                searchable={false}>KEY</TableHeaderColumn>
            <TableHeaderColumn
                dataField="value"
                dataSort={true}>{this.getColumnLabel()}</TableHeaderColumn>
        </BootstrapTable>)
    }

    getFooterButtons() {
        if (this.props.options.hideClearButton) {
            return null;
        }
        return [{
            onClick: this.filterClearHandler,
            className: "btn btn-danger",
            message: <FormattedMessage
                id="NPT_TABLE_CLEAR_FILTER"
                defaultMessage="Clear filter"
                description="User could clear applied filter" />
        }];
    }

    filterClearHandler({ onOk }) {
        this.cleared = true;
        onOk();
    }

    checkSelected() {
        /* Selected not take into account search value of table,
         * so we do it manually */
        if (!this.refs.table || !this.refs.table.state.data) {
            return true;
        }
        let selectedMap = this.state.selected;
        let filteredData = this.refs.table.state.data;
        let selectedKeys = {};
        for (let data of filteredData) {
            selectedKeys[data.key] = selectedMap[data.key];
        }
        this.selected = selectedKeys;
        return true;
    }

    saveNewFilter() {
        if (this.cleared) {
            return { cleared: true };
        }
        return { selected: (this.selected ? this.selected : this.state.selected) };
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for StringFilter*/
            title={this.getTitle()}
            body={this.getBody()}
            footerButtons={this.getFooterButtons()}
            /**Specific callbacks for StringFilter*/
            onOk={this.checkSelected}
            result={this.saveNewFilter} />);
    }
}

export default StringFilterModal;


