import { FormattedMessage } from 'react-intl';
import { VIEW_EDITOR_URL, JS_EDITOR_URL, CLASS_BASE_URL } from '../constants/classcard';

////////////////////////////////////////
//Common code for reducers and actions//
////////////////////////////////////////

export function createPackageId(id) {
    return "package" + id;
}

export function getPackageId(packageId) {
    return parseInt(packageId.replace("package", ""));
}

export function renamePath(path, caption) {
    let idx = path.lastIndexOf('/');
    return path.substring(0, idx) + "/" + caption;
}

export function getFilename(path) {
    let idx = path.lastIndexOf('/');
    return path.substring(idx + 1);
}

export function getBasename(path) {
    let idx = path.lastIndexOf('/');
    return path.substring(0, idx);
}

export function parseEnumerations(component, { multiplicity, relationType, stereotype, storeType }) {
    if (multiplicity) {
        component.multiplicityList = [];
        for (let multiplicityItem of multiplicity.list) {
            component.multiplicityList.push({ value: multiplicityItem.value, label: multiplicityItem.template });
        }
    }
    if (relationType) {
        component.relationTypeList = [];
        for (let relationTypeItem of relationType.list) {
            component.relationTypeList.push({ value: relationTypeItem.value, label: <FormattedMessage id={"CLASSCARD_RELATION_TYPE_" + relationTypeItem.relation.toUpperCase()} /> });
        }
    }
    if (stereotype) {
        component.stereotypeList = [];
        for (let stereotypeItem of stereotype.list) {
            let label;
            if (stereotypeItem.stereotype) {
                label = <FormattedMessage id={"CLASSCARD_STEREOTYPE_" + stereotypeItem.stereotype.toUpperCase()} />;
            } else {
                label = "--";
            }
            component.stereotypeList.push({ value: stereotypeItem.value, label });
        }
    }
    if (storeType) {
        component.storeTypeList = [];
        for (let storeTypeItem of storeType.list) {
            component.storeTypeList.push({ value: storeTypeItem.value, label: <FormattedMessage id={"CLASSCARD_STORE_TYPE_" + storeTypeItem.storeType.toUpperCase()} /> });
        }
    }
}

export function getClassLayoutPath(classData) {
    return `${CLASS_BASE_URL}/${classData.namespace.prefix}/${classData.name}`;
}

export function getViewLink(classData, contextPath) {
    return `${contextPath}${VIEW_EDITOR_URL}#${getClassLayoutPath(classData)}`;
}

export function getJsLink(classData, contextPath) {
    return `${contextPath}${JS_EDITOR_URL}#${CLASS_BASE_URL}/${classData.namespace.prefix}/${classData.name}`;
}

export function printBadge(link, type, messageId) {
    return <a href={link} target="_blank" className={"badge badge-" + type} style={{ marginLeft: 5 }}>
        <FormattedMessage id={messageId} />
    </a>
}

export function printClassInTree(classData, linkClassId, contextPath) {
    var icon = null;
    if (classData.stereotypeInfo) {
        switch (classData.stereotypeInfo.toLowerCase()) {
            case "enumeration":
                icon = <span class="fa bg-primary npt-tree-icon npt-tree-enumeration fa-large"></span>;
                break;
            case "compound":
                icon = <span class="fa bg-primary npt-tree-icon npt-tree-compound fa-large"></span>;
                break;
            default:
                break;
        }
    }
    let label = `[${classData.id}] ${classData.label} (${classData.namespace.prefix}:${classData.name})`;
    if (linkClassId) {
        label = <a href={`#/?id=${linkClassId}`} className="class-label">{icon} {label}</a>
    } else {
        label = <span className="font-weight-bold">{icon} {label}</span>
    }
    let viewLink = getViewLink(classData, contextPath);
    let jsLink = getJsLink(classData, contextPath);
    let layout = null;
    if (classData.layout) {
        layout = printBadge(viewLink + "/layout", "primary", "CLASSCARD_LAYOUT");
    }
    let automation = null;
    if (classData.automation) {
        automation = printBadge(viewLink + "/automation", "primary", "CLASSCARD_AUTOMATION");
    }
    let triggers = [];
    if (classData.triggerList) {
        for (let triggerId of classData.triggerList) {
            triggers.push(printBadge(jsLink + "/" + triggerId, "info", triggerId));
        }
    }
    return [
        label,
        layout,
        automation
    ].concat(triggers);
}

export function printInheritanceTree(classData, listId, linkId, contextPath) {
    if (!classData[listId]) {
        return null;
    }
    return (
        <ol className="npt-directory-list">
            {classData[listId].map((connectedClass, index) => {
                if (!connectedClass.peerClass) {
                    return null;
                }
                return (<li key={index}>
                    {printClassInTree(connectedClass.peerClass, connectedClass[linkId], contextPath)}
                    {printInheritanceTree(connectedClass.peerClass, listId, linkId, contextPath)}
                </li>)
            })}
        </ol>
    );
}

export function printEnumerationInTree(enumerationData, contextPath) {
    return [
        <a className="class-label" href={`${contextPath}objectcard/${enumerationData.$namespace}/${enumerationData.$rdfId}`} target="_blank">
            {enumerationData.$label}
        </a>,
        <span style={{ marginLeft: 5 }}>({enumerationData.$namespace}:{enumerationData.$rdfId})</span>
    ];
}

export function printEnumeration(enumerationData, contextPath) {
    if (!enumerationData.children) {
        return null;
    }
    return (
        <ol className="npt-directory-list">
            {enumerationData.children.map((enumeration, index) => {
                if (!enumeration.data) {
                    return null;
                }
                return (<li key={index}>
                    {printEnumerationInTree(enumeration.data, contextPath)}
                    {printEnumeration(enumeration, contextPath)}
                </li>)
            })}
        </ol>
    );
}

export function sortByAttr(list, attr) {
    let sortedList = list.slice();
    sortedList.sort(function (a, b) {
        let attr1 = a[attr] && a[attr].toLowerCase();
        let attr2 = b[attr] && b[attr].toLowerCase();
        if (attr1 < attr2)
            return -1;
        if (attr1 > attr2)
            return 1;
        return 0;
    });
    return sortedList;
}

export function parsePredicate(predicate) {
    predicate = Object.assign({}, predicate);
    predicate.namespacePrefix = predicate.namespace.prefix;
    predicate.reversePredicate = null;
    if (predicate.dataType != null) {
        return predicate;
    }
    if (!predicate.classRelationInfo) {
        predicate.dataType = { primitive: true };
        return predicate;
    }
    predicate.dataType = predicate.classRelationInfo.peerClass;
    predicate.dataType.primitive = false;
    return predicate;
}