import React, { Component } from 'react';
// import finder's components 
import SideBar from './sidebar.jsx';
import CriteriaBar from './criteriabar.jsx';
import { childOverflow } from '../../constants/finder';
import CimFiller from '../filler/cimfiller.jsx';

function renderChildren(props) {
    return React.Children.map(props.children, child => {
        if (!child) {
            return null;
        }
        //return React.cloneElement(child)
        return child;
    })
}

export default class Container extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) {
        /* If component was minimized/maximized - call resize action */
        if (this.props.finder.isHidden != prevProps.finder.isHidden) {
            $(window).trigger("resize");
        }
    }

    render() {
        let sideBarHidden = !this.props.finder.sideBar || (!this.props.finder.sideBar.fragmentLevels && !this.props.finder.sideBar.classLevels);
        return (
            <div className="cim-finder" style={{ width: this.props.width, height: this.props.height }}>
                <div className="nopadding" style={childOverflow}>
                    {this.props.finder.isHidden || sideBarHidden ?
                        null
                        :
                        <SideBar
                            isFetching={this.props.finder.isFetching}
                            fragments={this.props.finder.loadedFragments}
                            classes={this.props.finder.loadedClasses}
                            sideBar={this.props.finder.sideBar}
                            fetchFragments={this.props.fetchFragments}
                            selectFragment={this.props.selectFragment}
                            fetchClasses={this.props.fetchClasses}
                            selectClass={this.props.selectClass}
                            minimize={this.props.finder.criteriaTree ? null : () => this.props.toggleHidden()}
                        />}
                    <div className={this.props.finder.isHidden || sideBarHidden ? "col-md-12 nopadding" : "col-md-10 row nopadding"} style={childOverflow}>
                        <CriteriaBar
                            finder={this.props.finder}
                            finderOptions={this.props.finderOptions}
                            toggleHidden={this.props.toggleHidden}
                            fetchFields={this.props.fetchFields}
                            fetchObjectcard={this.props.fetchObjectcard}
                            changeCriteriaRelation={this.props.changeCriteriaRelation}
                            addCriteriaRelation={this.props.addCriteriaRelation}
                            removeCriteriaRelation={this.props.removeCriteriaRelation}
                            changeCriteriaField={this.props.changeCriteriaField}
                            addCriteria={this.props.addCriteria}
                            removeCriteria={this.props.removeCriteria}
                            removeCriteriaGroup={this.props.removeCriteriaGroup}
                            locale={this.props.locale}
                            view={this.props.finder.view}
                            changeFinderViewType={this.props.changeFinderViewType}
                            openModal={this.props.openModal}
                            addAlert={this.props.addAlert}
                        />
                        <CimFiller updateDelay={10} ref="tablefiller">
                            {renderChildren(this.props)}
                        </CimFiller>
                    </div>
                </div>
            </div >
        );
    }
}