'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { VelocityTransitionGroup } from 'velocity-react';

import NodeHeader from './header';

class TreeNode extends React.Component {
    constructor() {
        super();

        this.onClick = this.onClick.bind(this);
    }

    onClick(reactEvent) {
        if (this.props.onToggle) {
            this.props.onToggle(Object.assign({ ctrl: reactEvent.ctrlKey, shift: reactEvent.shiftKey }, this.props));
        }
    }

    animations() {
        const { treeAnimations, animations } = this.props;

        if (treeAnimations === false) {
            return false;
        }

        const anim = Object.assign({}, treeAnimations, animations);
        return {
            toggle: anim.toggle(this.props),
            drawer: anim.drawer(this.props)
        };
    }

    decorators() {
        // Merge Any Node Based Decorators Into The Pack
        const { treeDecorators, decorators } = this.props;
        let nodeDecorators = decorators || {};

        return Object.assign({}, treeDecorators, nodeDecorators);
    }

    componentWillMount() {
        if (this.props.children || this.props.loading || this.props.error) {
            this.children = this.renderChildren(this.decorators(), this.animations());
        }
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.children && this.props.children !== nextProps.children)
            || this.props.loading != nextProps.loading
            || this.props.error != nextProps.error) {
            this.children = this.renderChildren(this.decorators(), this.animations(), nextProps);
        }
    }

    render() {
        const { style } = this.props;

        if (this.props.visible == false) {
            return null;
        }

        const decorators = this.decorators();
        const animations = this.animations();

        return (
            <li ref={ref => this.topLevelRef = ref}
                style={style.base}>
                {this.renderHeader(decorators, animations)}

                {this.renderDrawer(decorators, animations)}
            </li>
        );
    }

    renderDrawer(decorators, animations) {
        const { toggled } = this.props;

        if (!animations && !toggled) {
            return null;
        } else if (!animations && toggled) {
            return this.renderChildren(decorators, animations);
        }

        const { animation, duration } = animations.drawer;
        let restAnimationInfo = Object.assign({}, animations.drawer);
        delete (restAnimationInfo.animation);
        delete (restAnimationInfo.duration);
        return (
            <VelocityTransitionGroup {...restAnimationInfo}
                ref={ref => this.velocityRef = ref}>
                {toggled ? this.children : null}
            </VelocityTransitionGroup>
        );
    }

    renderHeader(decorators, animations) {
        return (
            <NodeHeader
                {...this.props}
                animations={animations}
                decorators={decorators}
                onClick={this.onClick} />
        );
    }

    renderChildren(decorators, animations, props) {
        if (!props) {
            props = this.props;
        }
        const { treeAnimations, treeDecorators, loading, error, style } = props;

        if (loading) {
            return this.renderLoading(decorators);
        }

        if (error) {
            return this.renderError(decorators);
        }

        let children = props.children;
        if (!children) {
            return null;
        }

        return (
            <ul style={style.subtree}
                ref={ref => this.subtreeRef = ref}>
                {children.map((childId, index) => {
                    let Node = props.connectNodeFunction(TreeNode);
                    return (<Node {...this._eventBubbles()}
                        treeAnimations={treeAnimations}
                        treeDecorators={treeDecorators}
                        connectNodeFunction={props.connectNodeFunction}
                        key={childId}
                        id={childId}
                        style={style} />);
                }
                )}
            </ul>
        );
    }

    renderLoading(decorators) {
        const { style } = this.props;

        return (
            <ul style={style.subtree}>
                <li>
                    <decorators.Loading style={style.loading} />
                </li>
            </ul>
        );
    }

    renderError(decorators) {
        const { style } = this.props;

        return (
            <ul style={style.subtree}>
                <li>
                    <decorators.Error style={style.error} />
                </li>
            </ul>
        );
    }

    _eventBubbles() {
        const { onToggle } = this.props;

        return {
            onToggle
        };
    }
}

TreeNode.propTypes = {
    style: PropTypes.object.isRequired,
    decorators: PropTypes.object.isRequired,
    animations: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool
    ]).isRequired,
    onToggle: PropTypes.func
};

export default TreeNode;
