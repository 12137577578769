import { connect } from 'react-redux';
import _ModalsConnector from './components/modals/modalsconnector.jsx';
import _UnreadMessages from './components/modals/unreadmessages.jsx';
import { I18N } from './constants/i18n';
import { ALERT } from './constants/alert';
import { MODAL } from './constants/modal';
import { LOCATION } from './constants/location';
import { SECURITY } from './constants/security';
import { changeLocale } from './actions/i18n';
import { removeAlert } from './actions/alert';
import { MESSAGEBOX } from '../messagebox/actions/constants';

import alert from './reducers/alert';
import modal from './reducers/modal';

const ATTR_MODALS_CONNECTOR = "modals";
const ATTR_UNREAD_MESSAGES = "unread-messages";

//Map global (redux) state to locale state
function mapStateToProps(globalState) {
    return {
        locale: globalState[I18N].locale,
        messagebox: globalState[MESSAGEBOX],
        contextPath: globalState[LOCATION].contextPath,
        alerts: globalState[ALERT].alerts,
        username: globalState[SECURITY].username
    };
}

function mapDispachToProps(dispatch) {
    return {
        changeLocale: function (locale) {
            dispatch(changeLocale(locale));
        },
        removeAlert: function (id) {
            dispatch(removeAlert(id));
        }
    }
}

const ModalsConnector = connect(mapStateToProps, mapDispachToProps)(_ModalsConnector);
const UnreadMessages = connect(mapStateToProps)(_UnreadMessages);

export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(ALERT, alert);
    dispatcher.registerReducer(MODAL, modal);
    //Register components
    dispatcher.registerComponent(ATTR_MODALS_CONNECTOR, (elm) => {
        return (<ModalsConnector />);
    });
    dispatcher.registerComponent(ATTR_UNREAD_MESSAGES, (elm) => {
        const unreadMessages = $(elm).attr(ATTR_UNREAD_MESSAGES);
        return (<UnreadMessages unreadMessages={unreadMessages}/>);
    });
};