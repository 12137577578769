import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import I18NProvider from './components/provider.jsx'
import { LOCATION } from './constants/location';
import { I18N } from './constants/i18n';
import { CLASSCARD, CLASSCARD_TREE, TOGGLE_SUBTREE, CLASSCARD_CLASS } from './constants/classcard';

import ClassCard from './components/classcard/classcard.jsx';
import ClasscardTree from './components/classcard/tree.jsx';
import { classCardReducer, classCardTreeReducer } from './reducers/classcard';

import Menu from './components/tree/menu.jsx';

import {
    receiveEnumerations,
    receiveClass,
    receivePrimitiveTypes,
    receiveAllClasses,
    removePackage,
    removeClass,
    removePredicates,
    changeTab,
    fetchChildren,
    selectRows,
    deselectRows,
    fetchTree,
    toggleSubTree,
    selectObject,
    findClass,
    viewRelation,
    editPackage,
    editClass,
    editPredicate,
    editClassViaNode,
    editPackageViaNode,
    openExportPackageModal
} from './actions/classcard';
import { getPackageId } from './services/classcard';
import { plainToggleSubTree } from './actions/npt-treebeard';

import { openModal } from './actions/modal';

const ATTR_CLASSCARD = "cim-classcard";
const ATTR_CLASSCARD_TREE = "cim-classcard-tree";

function mapStateToProps(globalState) {
    return {
        locale: globalState[I18N].locale,
        messages: globalState[I18N].messages,
        object: globalState[LOCATION].params.object,
        hashParams: globalState[LOCATION].params,
        contextPath: globalState[LOCATION].contextPath,
        classCard: globalState[CLASSCARD]
    };
}

function mapDispachToProps(dispatch) {
    return {
        receiveEnumerations: function () {
            dispatch(receiveEnumerations());
        },
        receiveClass: function (classId) {
            dispatch(receiveClass(classId));
        },
        receivePrimitiveTypes: function () {
            dispatch(receivePrimitiveTypes());
        },
        receiveAllClasses: function () {
            dispatch(receiveAllClasses());
        },
        editClass: function (classData) {
            dispatch(editClass(classData));
        },
        removeClass: function (classData) {
            dispatch(removeClass(classData));
        },
        editPackage: function (packageData) {
            dispatch(editPackage(packageData));
        },
        removePredicates: function (predicates, classData) {
            dispatch(removePredicates(predicates, classData));
        },
        viewRelation: function (relation) {
            dispatch(viewRelation(relation));
        },
        editPredicate: function (predicate) {
            dispatch(editPredicate(predicate));
        },
        viewPredicate: function (predicate) {
            dispatch(editPredicate(predicate, true));
        },
        changeTab: function (tabName) {
            dispatch(changeTab(tabName));
        },
        fetchChildren: function (classId) {
            dispatch(fetchChildren(classId));
        },
        openModal: function (modalId, type, options, okCallback, cancelCallback, closeCallback) {
            dispatch(openModal(modalId, type, options, okCallback, cancelCallback, closeCallback));
        }
    }
}

function createClassCardContainer() {
    //Connect redux
    return connect(
        mapStateToProps,
        mapDispachToProps
    )(ClassCard);
}

/*******************/
/* Class card tree */
/*******************/
function getIconForStereotype(stereotypeMap, stereotypeId) {
    let stereotype = stereotypeMap[stereotypeId];
    if (!stereotype || !stereotype.stereotype) {
        return null;
    }
    switch (stereotype.stereotype.toLowerCase()) {
        case "enumeration":
            return "bg-primary npt-tree-icon npt-tree-enumeration";
        case "compound":
            return "bg-primary npt-tree-icon npt-tree-compound";
        default:
            break;
    }
    return null;
}

const connectNodeFunction = connect(function (_, initialProps) { //mapStateToProps
    const { id } = initialProps;
    return (globalState) => {
        const treeState = globalState[CLASSCARD_TREE];
        const classCardState = globalState[CLASSCARD];
        const node = treeState.nodeById[id];
        let icon = null;
        if (node.stereotype) {
            icon = getIconForStereotype(classCardState.stereotype.stereotypeByValue, node.stereotype);
        }
        return Object.assign({
            toggled: treeState.toggled[id],
            children: treeState.children[id],
            active: treeState.active == id,
            icon
        }, node);
    }
}, (_, initialProps) => { //mapDispachToProps
    const { id } = initialProps;
    return (dispatch) => {
        return {
            onToggle: function ({ id, loading, error, toggled }) {
                if (typeof id == "number") {
                    selectObject({ id }, dispatch);
                }
                if (loading || error) {
                    dispatch(toggleSubTree(id, !toggled));
                } else {
                    dispatch(plainToggleSubTree(TOGGLE_SUBTREE, id, !toggled));
                }
            }
        }
    }
});


function createTreeMapStateToProps() {
    return (globalState) => {
        return {
            roots: globalState[CLASSCARD_TREE].rootNodesIds,
            loading: globalState[CLASSCARD_TREE].loading,
            stereotype: globalState[CLASSCARD].stereotype,
            storeType: globalState[CLASSCARD].storeType,
            packages: globalState[CLASSCARD].packages,
            allClasses: globalState[CLASSCARD].allClasses,
            getNodeById: function (nodeId) {
                return globalState[CLASSCARD_TREE].nodeById[nodeId];
            }
        };
    }
}

function createTreeMapDispachToProps() {
    return (dispatch) => {
        return {
            fetchSubTree: function (nodeId) {
                if (typeof nodeId == "undefined" || nodeId == null) {
                    dispatch(fetchTree());
                } else {
                    dispatch(toggleSubTree(nodeId, false));
                }
            },
            openModal: function (modalId, type, options, okCallback, cancelCallback, closeCallback) {
                dispatch(openModal(modalId, type, options, okCallback, cancelCallback, closeCallback));
            },
            openExportPackageModal: function () {
                dispatch(openExportPackageModal());
            },
            findClass: function (classData) {
                dispatch(findClass(classData));
            },
            editPackage: function (packageData) {
                dispatch(editPackage(packageData));
            },
            editClass: function (classData) {
                dispatch(editClass(classData));
            },
            getAddActions: function (node, getNodeById) {
                if (typeof node.packageId != "undefined") {
                    return;
                }
                return [{
                    id: "addPackage",
                    label: CLASSCARD_CLASS,
                    onAction: () => dispatch(editClass({ packageId: getPackageId(node.id) }))
                }];
            },
            getRemoveAction: function (node) {
                return {
                    onAction: function () {
                        if (node.packageId) {
                            dispatch(removeClass());
                        } else {
                            dispatch(removePackage(getPackageId(node.id)));
                        }
                    }
                }
            },
            getEditAction: function (node) {
                return {
                    onAction: function () {
                        if (node.packageId) {
                            dispatch(editClassViaNode(node));
                        } else {
                            dispatch(editPackageViaNode(node));
                        }
                    }
                }
            }
        }
    }
}

function connectClasscardTree(Component) {
    //Connect redux to our navigation tree
    return connect(
        createTreeMapStateToProps(),
        createTreeMapDispachToProps()
    )(Component);
}

const ClassCardContainer = createClassCardContainer();
const SourceTreeContainer = connectClasscardTree(ClasscardTree);
const MenuTree = connectClasscardTree(Menu);

export default (dispatcher) => {
    //Register reducers
    dispatcher.registerReducer(CLASSCARD, classCardReducer);
    dispatcher.registerReducer(CLASSCARD_TREE, classCardTreeReducer);

    //Register components
    dispatcher.registerComponent(ATTR_CLASSCARD, (elm) => {
        let classId = $(elm).attr(ATTR_CLASSCARD);
        return (<ClassCardContainer classId={classId} />);
    });
    dispatcher.registerComponent(ATTR_CLASSCARD_TREE, (elm) => {
        let classId = $(elm).attr(ATTR_CLASSCARD);
        return (
            <div style={{ height: "100%" }}>
                <SourceTreeContainer connectNodeFunction={connectNodeFunction} />
                <MenuTree />
            </div >
        );
    });
};