import { connect, Provider } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { scriptCompiler, registerFunction, retrieveFunction } from './automation';
import { textWidth } from './domhacks';

import { TABLE, SORT_DESCEND, SORT_ASCEND } from '../constants/table';
import { LOCATION } from '../constants/location';
import { I18N } from '../constants/i18n';
import { SECURITY } from '../constants/security';

import { changeHash, getHashData } from '../actions/location';
import { openModal } from '../actions/modal';
import {
    fetchData,
    initializeTableFields,
    resetTableFields,
    sortColumn,
    filterColumn,
    changeParameters,
    connectedSelectRow,
    selectAllRows,
    changeViewType,
    changeSelectedGroup,
    downloadReport,
    buttonClick,
    changeItem,
    addItem,
    removeItem,
    saveGant,
    copySelectedRowsRefs,
    changePageSize,
    changePage,
    updateHeaders,
    toggleHidden,
    changeFinderViewType,
    initializeWithFilter,
    fetchFields,
    changeCriteriaRelation,
    addCriteriaRelation,
    removeCriteriaRelation,
    changeCriteriaField,
    addCriteria,
    removeCriteria,
    removeCriteriaGroup,
    fetchFragments,
    selectFragment,
    fetchClasses,
    selectClass,
    finderSearch,
    selectRow,
    fetchObjectcard
} from '../actions/table';
import { addAlert } from '../actions/alert';

const numberRegExp = /^(\-|\+)?([0-9]+(\.[0-9]*)?)$/;
const wordBoundaryRegExp = /[\s\.,]+/;

const LOADING_TEXT = (<FormattedMessage
    id="NPT_TABLE_LOADING"
    defaultMessage="Loading..."
    description="This message will while table data is loading" />);

const ERROR_TEXT = (<FormattedMessage
    id="NPT_TABLE_ERROR"
    defaultMessage="Error while loading data!"
    description="This message will displayed if data loading failed" />);

const initialAutomation = {
    cellClassBindings: null,
    valueBindings: null,
    textBindings: null,
    visibilityBindings: null,
    accumBindings: null,
    accumFilterBindings: null,
    accumInitialValues: null,
    clickBindings: null,
    postProcessBinding: null,
    predicateFilterBinding: null
};

export function getData(tableData) {
    return tableData.filteredPageData || tableData.sortedPageData || tableData.pageData || tableData.filteredData || tableData.sortedData || tableData.data;
}

function generateBindings(automation) {
    return {
        bindCellClass: function (col, func) { //function(cell, row, rowIndex, columnIndex)
            if (!automation.cellClassBindings) {
                automation.cellClassBindings = {};
            }
            automation.cellClassBindings[col] = registerFunction(func);
        },
        bindValue: function (col, func) { //function(cell, row)
            if (!automation.valueBindings) {
                automation.valueBindings = {};
            }
            automation.valueBindings[col] = registerFunction(func);
        },
        bindText: function (col, func) { //function(cell, row)
            if (!automation.textBindings) {
                automation.textBindings = {};
            }
            automation.textBindings[col] = registerFunction(func);
        },
        bindVisible: function (col, func) {//function(rows, fields)
            automation.visibilityBindings[col] = registerFunction(func);
        },
        bindClick: function (id, func) {//function(table)
            if (!automation.clickBindings) {
                automation.clickBindings = {};
            }
            automation.clickBindings[id] = registerFunction(func);
        },
        bindPostProcess: function (func) {//function(values)
            automation.postProcessBinding = registerFunction(func);
        },
        bindAccum: function (accum, initialValue, func) { //function (row, prev)
            if (!automation.accumInitialValues) {
                automation.accumInitialValues = {};
            }
            if (!automation.accumBindings) {
                automation.accumBindings = {};
            }
            automation.accumInitialValues[accum] = initialValue;
            automation.accumBindings[accum] = registerFunction(func);
        },
        bindFilterAccum: function (accum, initialValue, func) { //function (row, prev)
            if (!automation.accumInitialValues) {
                automation.accumInitialValues = {};
            }
            if (!automation.accumFilterBindings) {
                automation.accumFilterBindings = {};
            }
            automation.accumInitialValues[accum] = initialValue;
            automation.accumFilterBindings[accum] = registerFunction(func);
        },
        bindPredicateFilter: function (func) { //function (predicate) => boolean
            automation.predicateFilterBinding = registerFunction(func);
        }
    };
}

export function compileAutomationScripts(script) {
    let automation = Object.assign({}, initialAutomation);
    let bindings = generateBindings(automation);
    scriptCompiler(script, bindings);
    return automation;
}

export function runAccumulators(data, initial, values, bindings) {
    //Initialize accumulator values
    for (let accum in bindings) {
        values[accum] = initial[accum];
    }
    //Process data
    for (let row of data) {
        if (!row) {
            continue;
        }
        for (let accum in bindings) {
            let func = retrieveFunction(bindings[accum]);
            let prev = values[accum];
            values[accum] = func(row, prev);
        }
    }
}

export function checkFields(fields, oldHash, hash) {
    let newFields = null;
    for (let p in fields) {
        if (oldHash[p] && typeof hash[p] == "undefined") {
            hash[p] = null;
        }
        if (typeof hash[p] != "undefined" && hash[p] != fields[p]) {
            if (!newFields) {
                newFields = Object.assign({}, fields);
            }
            newFields[p] = hash[p];
        }
    }
    return newFields;
}

export function printLoading() {
    return (<span><i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>{LOADING_TEXT}</span>);
}

export function printError(error) {
    if (error && error.status && error.status >= 400 && error.status < 500) {
        return (<span><i className="fa fa-exclamation-triangle fa-3x fa-fw"></i>{error.responseText ? error.responseText : error.statusText}</span>);
    }
    return (<span><i className="fa fa-exclamation-triangle fa-3x fa-fw"></i>{ERROR_TEXT}</span>);
}

export function buildRequestParams(parameters, fields, hashParams) {
    var res = {};
    if (!hashParams) {
        hashParams = getHashData().params;
    }
    for (let p in parameters) {
        const value = fields[p] || hashParams[p];
        if (value) {
            res[p] = value;
        }
    }
    return res;
}

function getWidth(label) {
    return textWidth(label); //21 is for sorting and 19 for margins
}

export function getTextWidth(label, longestWord) {
    let width = getWidth(label);
    if (width > 100 && longestWord) { //Do no wrap for small columns
        width = getWidth(longestWord);
    }
    return width;
}

export function getColumnWidth(col) {
    return getTextWidth(col.label, col.labelInfo.longestWord) + 40;
}

export function sortedAdd(array, element, compare) {
    for (let i = 0; i < array.length; ++i) {
        if (compare(element, array[i]) > 0) {
            continue;
        }
        array.splice(i, 0, element);
        return;
    }
    array.push(element);
}

export function downloadFile(href, filename, filterLink) {
    /* Link attribute "download" works only in this two browsers */
    let isChromium = navigator.userAgent.toLowerCase().indexOf('chrome') != -1 || navigator.userAgent.toLowerCase().indexOf('safari') != -1;
    let composedLink = href;
    if (filterLink) {
        if (composedLink.indexOf("?") > 0) {
            composedLink += "&_filter=" + filterLink;
        } else {
            composedLink += "?_filter=" + filterLink;
        }
    }
    if (isChromium && (typeof getHashData().params.debug === "undefined")) {
        var link = $(`<a href="${composedLink}" download="${filename}"></a>`);
        link[0].click();
        return;
    }

    // Force file download (whether supported by server).
    if (composedLink.indexOf("?") > 0) {
        composedLink = composedLink.replace("?", "?download&_filename=" + filename + "&")
    } else {
        composedLink += "?download&_filename=" + filename;
    }
    window.open(composedLink);
}

export function findInArrayByKeys({ array, keys, key }) {
    let result = [];
    for (let obj of array) {
        for (let i = keys.length - 1; i >= 0; --i) {
            if (keys[i] == obj[key]) {
                result.push(obj);
                keys.splice(i, 1);
                break;
            }
        }
        if (keys.length == 0) {
            break;
        }
    }
    return result;
}

/*************************
 * Sorting and filtering *
 **************************/

function textSortRows(rows, field, order) {
    let sortedRows = rows.slice();
    sortedRows.sort(function (rowA, rowB) {
        if (rowA[field] == rowB[field]) {
            return 0;
        }
        let result = 1;
        if ((rowA[field] && !rowB[field]) || rowA[field] > rowB[field]) {
            result = -1;
        }
        return result * order;
    });
    return sortedRows;
}

function numberSortRows(rows, field, order) {
    let sortedRows = rows.slice();
    sortedRows.sort(function (rowA, rowB) {
        if (rowA[field] == rowB[field]) {
            return 0;
        }
        let result = 1;
        if ((rowA[field] && !rowB[field] && rowB[field] != 0) || Number(rowA[field]) > Number(rowB[field])) {
            result = -1;
        }
        return result * order;
    });
    return sortedRows;
}

function resetSorting(tableData) {
    tableData.sortedData = tableData.filteredData ? tableData.filteredData.slice() : tableData.data.slice();
    return;
}

function findColumnByField(tableData, field) {
    if (!tableData.columns) {
        return null;
    }
    for (let column of tableData.columns) {
        if (column.field == field) {
            return column;
        }
    }
    return null;
}

export function sortRows(tableData) {
    if (tableData.pageable) {
        /* Temp solution to prevent global sorting with pageable tables */
        return;
    }
    if (!tableData.sorting) {
        resetSorting(tableData);
        return;
    }
    let column = findColumnByField(tableData, tableData.sorting.column);
    if (!column) {
        resetSorting(tableData);
        return;
    }
    let data = tableData.filteredData ? tableData.filteredData : tableData.data;
    switch (column.format) {
        case "number":
            tableData.sortedPageData = numberSortRows(data, tableData.sorting.column, tableData.sorting.order);
            return;
        default:
            tableData.sortedData = textSortRows(data, tableData.sorting.column, tableData.sorting.order);
            return;
    }
}

function createDateFilter({ from, to }) {
    from = moment(from);
    to = moment(to);
    return function (value) {
        if (typeof value != 'string') {
            return false;
        }
        return from.isSameOrBefore(value) && to.isSameOrAfter(value);
    }
}

function createNumberFilter({ from, to }) {
    if (typeof from == "string") {
        from = parseFloat(from.replace(",", "."));
    }
    if (typeof to == "string") {
        to = parseFloat(to.replace(",", "."));
    }
    return function (value) {
        if (typeof value == 'string') {
            if (!numberRegExp.test(value)) {
                return false;
            }
            value = parseFloat(value);
        } else if (typeof value != 'number') {
            return false;
        }
        return value >= from && value <= to;
    }
}

function createHashFilter({ hash }) {
    /* TODO */
    return (value) => true;
}

function createWordFilter({ selected }) {
    return function (value, bindedValue) {
        if (typeof value != 'string') {
            return false;
        }
        value = value.toLowerCase();
        let wordList = value.split(wordBoundaryRegExp);
        for (let word of wordList) {
            if (selected[word]) {
                return true;
            }
        }
        return false;
    }
}

function createStringFilter({ selected }) {
    return function (value, bindedValue) {
        value = bindedValue || value;
        if (typeof value != 'string') {
            return false;
        }
        value = value.toLowerCase();
        return selected[value];
    }
}

function applyFilter(rows, field, filter) {
    let filteredRows = [];
    let filterFunc = null;
    switch (filter.type) {
        case "date":
        case "date_time":
            filterFunc = createDateFilter({ from: filter.from, to: filter.to });
            break;
        case "number":
            filterFunc = createNumberFilter({ from: filter.from, to: filter.to });
            break;
        case "hash":
            filterFunc = createHashFilter({ hash: filter.hash });
            break;
        case "word":
            filterFunc = createWordFilter({ selected: filter.selected });;
            break;
        default:
            filterFunc = createStringFilter({ selected: filter.selected });;
            break;
    }
    for (let row of rows) {
        const bindedValue = (row.bindedData && row.bindedData[field]) || null;
        if (filterFunc(row[field], bindedValue)) {
            filteredRows.push(row);
        }
    }
    return filteredRows;
}

/* Temp solution while server is not capable of filtering */
function filterPageData(tableData) {
    tableData.filteredPageData = tableData.pageData.slice();
    for (var filterId in tableData.filters) {
        tableData.filteredPageData = applyFilter(tableData.filteredPageData, filterId, tableData.filters[filterId]);
    }
}

function getPageData(tableData, page, pageSize) {
    let pageData = [];
    let offset = (page - 1) * pageSize;
    let rowsOnPage = pageSize;
    if (offset + pageSize > tableData.totalDataLength) {
        rowsOnPage = tableData.totalDataLength - offset;
    }
    for (let i = 0; i < rowsOnPage; ++i) {
        if (!tableData.fetchedRows[i + offset]) {
            continue;
        }
        pageData.push(tableData.fetchedRows[i + offset]);
    }
    return pageData;
}

function resetPageSorting(tableData) {
    tableData.sortedPageData = tableData.filteredPageData ? tableData.filteredPageData.slice() : tableData.pageData.slice();
    return;
}

/* Temp solution while server is not capable of sorting */
function sortPageData(tableData) {
    if (!tableData.sorting) {
        resetPageSorting(tableData);
        return;
    }
    let column = findColumnByField(tableData, tableData.sorting.column);
    if (!column) {
        resetPageSorting(tableData);
        return;
    }
    let data = tableData.filteredPageData ? tableData.filteredPageData : tableData.pageData;
    switch (column.format) {
        case "number":
            tableData.sortedPageData = numberSortRows(data, tableData.sorting.column, tableData.sorting.order);
            return;
        default:
            tableData.sortedPageData = textSortRows(data, tableData.sorting.column, tableData.sorting.order);
            return;
    }
}

export function updatePageData(tableData) {
    if (!tableData.pageable || !tableData.data) {
        return;
    }
    if (!tableData.page) {
        tableData.page = 1;
    }
    let page = Math.min(tableData.page, Math.floor(tableData.totalDataLength / tableData.pageSize) + 1);
    tableData.page = page;
    tableData.pageData = getPageData(tableData, page, tableData.pageSize);
    tableData.data = tableData.pageData;
    /* TODO: remove one-page filtering and sorting */
    filterPageData(tableData);
    sortPageData(tableData);
    let rows = getData(tableData);
    applyAutomations(tableData, rows);
    runAutomationBindings(tableData, rows);
}

export function sortTable(tableData, column) {
    tableData = Object.assign({}, tableData);
    if (!tableData.sorting || tableData.sorting.column != column) {
        tableData.sorting = {
            column,
            order: SORT_DESCEND
        }
    } else {
        if (tableData.sorting.order == SORT_DESCEND) {
            tableData.sorting = {
                column,
                order: SORT_ASCEND
            }
        } else {
            delete (tableData.sorting);
        }
    }
    sortRows(tableData);
    updatePageData(tableData);
    return tableData;
}

export function filterRows(tableData) {
    if (tableData.pageable) {
        /* Temp solution to prevent global filtering with pageable tables */
        return;
    }
    tableData.filteredData = tableData.data.slice();
    for (var filterId in tableData.filters) {
        tableData.filteredData = applyFilter(tableData.filteredData, filterId, tableData.filters[filterId]);
    }
}



function applyBindings(tableData, rows, type) {
    for (let rowIndex = 0; rowIndex < rows.length; ++rowIndex) {
        let row = rows[rowIndex];
        if (!row) {
            continue;
        }
        for (let columnIndex = 0; columnIndex < tableData.columns.length; ++columnIndex) {
            let field = tableData.columns[columnIndex].field;
            let bindings = tableData.automation[type][field];
            if (!bindings) {
                continue;
            }
            let bindingsFunc = retrieveFunction(bindings);
            if (!bindingsFunc) {
                continue;
            }
            row.bindedData[field] = bindingsFunc(row[field], row, rowIndex, columnIndex);
        }
    }
}

function applyTextBindings(tableData, rows) {
    if (!tableData.automation.textBindings) {
        return;
    }
    applyBindings(tableData, rows, "textBindings");
}

function applyValueBindings(tableData, rows) {
    if (!tableData.automation.valueBindings) {
        return;
    }
    applyBindings(tableData, rows, "valueBindings");
}

function columnClassNameFormatter(stylePrefix, func) {
    return function (cell, row, rowIndex, columnIndex) {
        let cls = func(cell, row, rowIndex, columnIndex);
        return stylePrefix + cls;
    }
}

function applyClassBindings(tableData, rows) {
    if (!tableData.automation.cellClassBindings || !tableData.styleId) {
        return;
    }
    for (let rowIndex = 0; rowIndex < rows.length; ++rowIndex) {
        let row = rows[rowIndex];
        if (!row) {
            continue;
        }
        row.classes = {};
        for (let columnIndex = 0; columnIndex < tableData.columns.length; ++columnIndex) {
            let field = tableData.columns[columnIndex].field;
            let bindings = tableData.automation.cellClassBindings[field];
            if (!bindings) {
                continue;
            }
            let columnClassNameFunc = retrieveFunction(bindings);
            if (!columnClassNameFunc) {
                continue;
            }
            row.classes[field] = columnClassNameFormatter(tableData.styleId, columnClassNameFunc)(row[field], row, rowIndex, columnIndex);
        }
    }
}

export function applyVisibilityAutomations(tableData, rows) {
    if (!tableData.automation.visibilityBindings || !tableData.header) {
        return;
    }
    for (let column of tableData.header) {
        const bindings = tableData.automation.cellClassBindings[column.field];
        if (!bindings) {
            continue;
        }
        const columnVisibilityFunc = retrieveFunction(bindings);
        if (!columnVisibilityFunc) {
            continue;
        }
        column.hidden = !columnVisibilityFunc(rows, tableData.fields);
    }
}

export function applyAutomations(tableData, rows) {
    if (!tableData.automation) {
        return;
    }
    applyValueBindings(tableData, rows);
    applyTextBindings(tableData, rows);
    applyClassBindings(tableData, rows);
    applyVisibilityAutomations(tableData, rows);
}

export function runAutomationBindings(tableData, rows) {
    if (!tableData.automation) {
        return;
    }
    if (tableData.automation.accumFilterBindings) {
        tableData.fields = Object.assign({}, tableData.fields);
        runAccumulators(rows, tableData.automation.accumInitialValues, tableData.fields, tableData.automation.accumFilterBindings);
    }
    //Postprocess
    if (tableData.automation.postProcessBinding) {
        const func = retrieveFunction(tableData.automation.postProcessBinding)
        func(tableData.fields, getData(tableData));
    }
}

export function filterTable(tableData, column, filter) {
    tableData = Object.assign({}, tableData);
    if (!tableData.filters) {
        tableData.filters = {};
    }
    if (!filter) {
        delete (tableData.filters[column]);
    } else {
        tableData.filters[column] = filter;
    }
    filterRows(tableData);
    sortRows(tableData);
    updatePageData(tableData);
    return tableData;
}

/******************************/




function getTableProps(globalState, { tableId, forcedSelectType, forcedParameters }) {
    let props = {
        locale: globalState[I18N].locale,
        hashParams: globalState[LOCATION].params,
        contextPath: globalState[LOCATION].contextPath
    }
    if (globalState[TABLE][tableId]) {
        props = Object.assign({}, props, globalState[TABLE][tableId]);
        props.originalData = props.data;
        props.data = props.sortedData || props.filteredData || props.data;
        if (props.pageable) {
            props.originalData = props.pageData;
            props.data = props.sortedPageData || props.filteredPageData || props.pageData;
        }
    }
    props.messages = globalState[I18N].messages;
    props.generalAccessRules = globalState[SECURITY].generalAccessRules;
    props.editable = !props.loading && !props.loadingData;

    props.selectType = forcedSelectType || props.selectType;
    props.parameters = forcedParameters || props.parameters;
    return props;
}

function getTableDispatcher(dispatch, { tableId }) {
    return {
        fetchData({ fields, parameters = null } = { fields: null, parameters: null }) {
            dispatch(fetchData(tableId, parameters, { force: false, fields }));
        },
        reloadData: function (parameters) {
            dispatch(fetchData(tableId, parameters, { force: true }));
        },
        initializeTableFields: function (fields) {
            dispatch(initializeTableFields(tableId, fields));
        },
        resetTableFields: function () {
            dispatch(resetTableFields(tableId));
        },
        sortColumn(column) {
            dispatch(sortColumn(tableId, column));
        },
        filterColumn(column, filter) {
            dispatch(filterColumn(tableId, column, filter));
        },
        changeItem(type, rowIndex, index, from, to) {
            dispatch(changeItem(tableId, type, rowIndex, index, from, to));
        },
        addItem(rowIndex, from, to) {
            dispatch(addItem(tableId, rowIndex, from, to));
        },
        removeItem(type, rowIndex, index) {
            dispatch(removeItem(tableId, type, rowIndex, index));
        },
        plainSelectRow(key, table) {
            dispatch(selectRow(tableId, key));
        },
        selectRow(key, table) {
            dispatch(connectedSelectRow(tableId, key, table));
        },
        selectAllRows(select) {
            dispatch(selectAllRows(tableId, select));
        },
        changeParameters(params) {
            dispatch(changeParameters(tableId, params));
        },
        buttonClick(id, table) {
            dispatch(buttonClick(tableId, id, table));
        },
        downloadReport(href, filename, data) {
            dispatch(downloadReport(tableId, href, filename, data));
        },
        changeViewType(viewType) {
            dispatch(changeViewType(tableId, viewType));
        },
        changeSelectedGroup(group) {
            dispatch(changeSelectedGroup(tableId, group));
        },
        saveGant() {
            dispatch(saveGant(tableId));
        },
        copySelectedRowsRefs(namespace, rdfId) {
            dispatch(copySelectedRowsRefs(tableId, namespace, rdfId));
        },
        changePageSize(pageSize) {
            dispatch(changePageSize(tableId, pageSize));
        },
        changePage(page) {
            dispatch(changePage(tableId, page));
        },
        updateHeaders: function (tableId, headers) {
            dispatch(updateHeaders(tableId, headers));
        },
        openModal(modalId, type, options, okCallback, cancelCallback, closeCallback) {
            dispatch(openModal(modalId, type, options, okCallback, cancelCallback, closeCallback));
        },
        addAlert(type, message) {
            dispatch(addAlert(type, message));
        },
        /* Finder actions */
        initializeWithFilter: function (filter) {
            dispatch(initializeWithFilter(tableId, filter));
        },
        toggleHidden: function (value) {
            dispatch(toggleHidden(tableId, value));
        },
        changeFinderViewType: function (viewType) {
            dispatch(changeFinderViewType(tableId, viewType));
        },
        //Criteria functions
        fetchFields: function (parentId) {
            dispatch(fetchFields(tableId, parentId));
        },
        fetchObjectcard: function (rdfId) {
            dispatch(fetchObjectcard(tableId, rdfId));
        },
        changeCriteriaRelation: function (criteriaId, relation, relationIdx) {
            dispatch(changeCriteriaRelation(tableId, criteriaId, relation, relationIdx))
        },
        addCriteriaRelation: function (criteriaId) {
            dispatch(addCriteriaRelation(tableId, criteriaId))
        },
        removeCriteriaRelation: function (openModal, criteriaId, relationIdx) {
            dispatch(removeCriteriaRelation(tableId, openModal, criteriaId, relationIdx))
        },
        changeCriteriaField: function (criteriaId, fieldId) {
            dispatch(changeCriteriaField(tableId, criteriaId, fieldId))
        },
        addCriteria: function (criteriaGroupId) {
            dispatch(addCriteria(tableId, criteriaGroupId))
        },
        removeCriteria: function (openModal, criteriaId) {
            dispatch(removeCriteria(tableId, openModal, criteriaId))
        },
        removeCriteriaGroup: function (criteriaGroupId) {
            dispatch(removeCriteriaGroup(tableId, criteriaGroupId))
        },
        finderSearch: function () {
            dispatch(finderSearch(tableId, {}, true));
        },
        //SideBar functions
        fetchFragments: function (fragmentsId) {
            dispatch(fetchFragments(tableId, fragmentsId));
        },
        selectFragment: function (oldFragmentId, newFragmentId) {
            dispatch(selectFragment(tableId, oldFragmentId, newFragmentId));
        },
        fetchClasses: function (levelId) {
            dispatch(fetchClasses(tableId, levelId));
        },
        selectClass: function (oldClassId, newClassId, level) {
            dispatch(selectClass(tableId, oldClassId, newClassId, level));
        }
    }
}

export function connectTable(Component) {
    return connect((_, initialProps) => { //mapStateToProps
        const { tableId, forcedSelectType, forcedParameters } = initialProps;
        return (globalState) => {
            return getTableProps(globalState, { tableId, forcedSelectType, forcedParameters });
        }
    }, (_, initialProps) => {
        const { tableId } = initialProps;
        return (dispatch) => {
            return getTableDispatcher(dispatch, { tableId });
        }
    })(Component);
}