import { getHashData, hashChanged } from './actions/location';

//Imports to open login panel
import { parseLoginInfo } from './services/login';
import { getContextPath } from './services/location';
import location from './reducers/location';
import { LOCATION, LOGIN_ERROR } from './constants/location';
import { openModal } from './actions/modal';

//Get servlet context path
const contextPath = getContextPath();

function wrapAjaxResponse(p) {
    return p.fail(function (error) {
        console.log(error);
        if (error.status == 401 && error.responseJSON) {
            console.log(error.responseJSON);
            $(document).trigger(LOGIN_ERROR, error.responseJSON);
        }
    });
}

export default (dispatcher) => {

    //Register reducer
    const initialState = getHashData();
    initialState.contextPath = contextPath;
    dispatcher.registerReducer(LOCATION, location, initialState);

    //Monkey patch jquery to work with ajax request from all pages (respect contextPath)
    function patchAjaxUrl(url) {
        return contextPath + url.substring(1);
    }
    let _jquery_ajax = $.ajax;
    $.ajax = function () {
        let url = arguments[0].url;
        if (url.startsWith("/") && !url.startsWith(contextPath)) {
            let patched = patchAjaxUrl(url);
            if (url != patched) {
                console.log("Patched AJAX URL: " + patched);
                let args = [].concat(arguments);
                args[0] = Object.assign({}, arguments[0], { url: patched });
                return wrapAjaxResponse(_jquery_ajax.apply(this, args));
            }
        }
        return wrapAjaxResponse(_jquery_ajax.apply(this, arguments));
    }

    //Listen to location changes and dispatch them to components
    dispatcher.registerEventHandler(window, "hashchange", (store) => {
        store.dispatch(hashChanged());
    });
    //Listen to login errors so we can open modal dialog
    dispatcher.registerEventHandler(document, LOGIN_ERROR, (store, _, loginInfo) => {
        console.log("Login error. Open dialog!", loginInfo);
        store.dispatch(openModal("loginForm", "login", { loginInfo, contextPath }));
    });
}