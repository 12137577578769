import React from 'react';
import { FormattedMessage } from 'react-intl';
import CardArea from './cardarea.jsx';

class Grid extends CardArea {

    constructor(props) {
        super(props);
    }

    render() {
        const style = this.props.visible ? null : { display: "none" };
        let className = "row card-row";
        if (this.props.node && this.props.node.options.cls) {
            className += ` ${this.props.node.options.cls}`;
        }
        return (
            <div className={className} style={style}>
                {this.getChildren()}
            </div>);
    }
}

export default Grid;