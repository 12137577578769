import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'react-select';
import { TIME_UNITS } from '../../constants/crontasks';
import { MSG_BUTTON_ADD, MSG_BUTTON_SAVE, MSG_BUTTON_CANCEL, MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../messages.jsx'
import { validateCronStringElement, validateMonthAndWeekDay, validateLabel, validatePath, validateTask, validateDescription, getValidCronValue } from '../../services/crontasks';

const PATH_TO_SCRIPTS_LIST = "/rest/scripteditor/list";

class ConfigurePanel extends React.Component {

    constructor(props) {
        super(props);
        let changing = !!props.task;
        let task = changing ?
            Object.assign({}, props.task, {
                second: getValidCronValue({ value: props.task.second.originalValue, type: "second" }),
                minute: getValidCronValue({ value: props.task.minute.originalValue, type: "minute" }),
                hour: getValidCronValue({ value: props.task.hour.originalValue, type: "hour" }),
                day: getValidCronValue({ value: props.task.day.originalValue, type: "day" }),
                month: getValidCronValue({ value: props.task.month.originalValue, type: "month" }),
                weekDay: getValidCronValue({ value: props.task.weekDay.originalValue, type: "weekDay" })
            }) : {
                label: "",
                scriptPath: "",
                description: "",
                second: TIME_UNITS.second[0].originalValue,
                minute: TIME_UNITS.minute[0].originalValue,
                hour: TIME_UNITS.hour[0].originalValue,
                day: TIME_UNITS.day[1].originalValue,
                month: TIME_UNITS.month[0].originalValue,
                weekDay: TIME_UNITS.weekDay[0].originalValue
            }
        this.state = {
            task: task,
            scriptPathList: [],
            changing: changing,
            manual: false
        }

        let _this = this;
        $.getJSON(PATH_TO_SCRIPTS_LIST).done(function (data) {
            let pathList = [];
            for (let i = 0; i < data.length; ++i) {
                pathList.push({ value: data[i], label: data[i] });
            }
            _this.setState(Object.assign({}, _this.state, { scriptPathList: pathList }))
        }).fail(function (error) {
            _this.setState(Object.assign({}, _this.state, { getScriptListError: true }))
        })
    }

    createChangeToManualInput(_this) {
        return function () {
            _this.setState(Object.assign({}, _this.state, { manual: true, help: false }));
        }
    }

    createChangeToSelectInput(_this) {
        return function () {
            _this.setState(Object.assign({}, _this.state, { manual: false }));
        }
    }

    formate(num, length) {
        let string = num.toString();
        while (string.length < length) {
            string = "0" + string;
        }
        return string;
    }

    createCallbackFunction(_this, { name }) {
        return function (reactEvent, event) {
            let newState = Object.assign({}, _this.state);
            newState.task = Object.assign({}, newState.task);
            newState.task[name] = getValidCronValue({ value: reactEvent.nativeEvent.target.value, type: name });
            if (!newState.taskChanged) {
                newState.taskChanged = true;
            }
            _this.setState(newState);
        }
    }

    createSelectCallback(_this, { name }) {
        return function (newValue) {
            let newState = Object.assign({}, _this.state);
            newState.task = Object.assign({}, newState.task);
            newState.task[name] = newValue;
            if (!newState.taskChanged) {
                newState.taskChanged = true;
            }
            _this.setState(newState);
        }
    }

    closeCallback(_this) {
        return function (reactEvent, event) {
            _this.props.close();
        }
    }

    okCallback(_this) {
        return function (reactEvent, event) {
            if (_this.state.taskChanged) {
                _this.setState(Object.assign({}, _this.state, { taskChanged: false }));
            }
            _this.props.ok({ task: _this.state.task });
        }
    }

    getMessage(formattedMessage) {
        try {
            return this.props.intl.formatMessage({ id: formattedMessage.props.id });
        } catch (e) {
            return formattedMessage;
        }
    }

    render() {

        let isValid = {
            second: validateCronStringElement({ value: this.state.task.second, type: "second" }),
            minute: validateCronStringElement({ value: this.state.task.minute, type: "minute" }),
            hour: validateCronStringElement({ value: this.state.task.hour, type: "hour" }),
            day: validateCronStringElement({ value: this.state.task.day, type: "day" }),
            month: validateCronStringElement({ value: this.state.task.month, type: "month" }),
            weekDay: validateCronStringElement({ value: this.state.task.weekDay, type: "weekDay" }),
            label: validateLabel({ label: this.state.task.label }),
            path: validatePath({ path: this.state.task.scriptPath }),
            description: validateDescription({ description: this.state.task.description })
        }
        let monthAndWeekDayIsValid;
        if (!(monthAndWeekDayIsValid = validateMonthAndWeekDay({ monthDay: this.state.task.day, weekDay: this.state.task.weekDay }))) {
            isValid.day = false;
            isValid.weekDay = false;
        }

        return (
            <div className="modal" style={{ display: "block", position: "fixed", top: "20px" }}>
                <div className="modal-dialog">
                    <div className="modal-content" style={{ width: "650px" }}>
                        <div className="modal-header">
                            <h4 className="modal-title">
                                {this.state.changing ?
                                    <FormattedMessage
                                        id="CRON_TASKS_DIALOG_CHANGE_ELEMENT"
                                        defaultMessage="Change element"
                                        description="Change element" /> :
                                    <FormattedMessage
                                        id="CRON_TASKS_DIALOG_ADD_ELEMENT"
                                        defaultMessage="Add element"
                                        description="Add element" />}
                            </h4>
                            <button type="button" className="close" aria-hidden="true" onClick={this.closeCallback(this)}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal col-md-12 nopadding">
                                <fieldset>
                                    <div className="form-group row">
                                        <label htmlFor="label" className="col-sm-2 control-label">
                                            <FormattedMessage
                                                id="CRON_TASKS_LABEL"
                                                defaultMessage="Label"
                                                description="Label" />
                                        </label>
                                        <div className={"col-sm-10"}>
                                            <input id="label" className={"form-control form-control-sm " + (isValid.label ? "is-valid" : "is-invalid")} value={this.state.task.label} onChange={this.createCallbackFunction(this, { name: "label" })}>
                                            </input>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="scriptPath" className="col-sm-2 control-label">
                                            <FormattedMessage
                                                id="CRON_TASKS_PATH_TO_SCRIPT"
                                                defaultMessage="Path to script"
                                                description="Path to script" />
                                        </label>
                                        <div className={"col-sm-10 " + (isValid.path ? "is-valid" : "is-invalid")}>
                                            <Select
                                                ref="pathSelect"
                                                searchable="true"
                                                loadingPlaceholder={MSG_SELECT_LOADING}
                                                placeholder={MSG_SELECT_PLACEHOLDER}
                                                noResultsText={MSG_SELECT_NO_RESULTS}
                                                simpleValue
                                                value={this.state.task.scriptPath}
                                                onChange={this.createSelectCallback(this, { name: "scriptPath" })}
                                                options={this.state.scriptPathList}></Select>
                                        </div>
                                    </div>
                                    <div className="form-group row cim-crontasks-string-maker">
                                        <label htmlFor="headers" className="col-sm-2 control-label">
                                            <FormattedMessage
                                                id="CRON_TASKS_RUN"
                                                defaultMessage="Run"
                                                description="Run" />
                                        </label>
                                        {this.state.manual ? <div className="col-sm-10 form-group row">
                                            <div className="col-sm-12 form-group">
                                                <div className="col-sm-12">
                                                    <button type="button" className="btn btn-secondary" onClick={this.createChangeToSelectInput(this)}>
                                                        <FormattedMessage
                                                            id="CRON_TASKS_BUTTON_CHANGE_TO_SELECT"
                                                            defaultMessage="Select input"
                                                            description="Select input" />
                                                    </button>
                                                    <a className="btn btn-secondary" target="blank" href="http://www.quartz-scheduler.org/documentation/quartz-2.x/tutorials/crontrigger.html">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_BUTTON_SHOW_HELP"
                                                            defaultMessage="Help"
                                                            description="Help" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 form-group row">
                                                <div className="col-sm-2 ">
                                                    <FormattedMessage
                                                        id="CRON_TASKS_TIME_UNIT_SECOND"
                                                        defaultMessage="Second"
                                                        description="Second" />:
                                                </div>
                                                <div className={"col-sm-4"}>
                                                    <input className={"form-control" + (isValid.second ? "" : " is-invalid")} value={this.state.task.second} onChange={this.createCallbackFunction(this, { name: "second" })} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="cim-crontask-notation">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_NOTATION_ALLOWED_STANDART"
                                                            defaultMessage="Allowed ,-*/"
                                                            description="Allowed ,-*/ characters" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 form-group row">
                                                <div className="col-sm-2 ">
                                                    <FormattedMessage
                                                        id="CRON_TASKS_TIME_UNIT_MINUTE"
                                                        defaultMessage="Minute"
                                                        description="Minute" />:
                                                </div>
                                                <div className={"col-sm-4"}>
                                                    <input className={"form-control" + (isValid.minute ? "" : " is-invalid")} value={this.state.task.minute} onChange={this.createCallbackFunction(this, { name: "minute" })} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="cim-crontask-notation">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_NOTATION_ALLOWED_STANDART"
                                                            defaultMessage="Allowed ,-*/"
                                                            description="Allowed ,-*/ characters" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 form-group row">
                                                <div className="col-sm-2 ">
                                                    <FormattedMessage
                                                        id="CRON_TASKS_TIME_UNIT_HOUR"
                                                        defaultMessage="Hour"
                                                        description="Hour" />:
                                                </div>
                                                <div className={"col-sm-4"}>
                                                    <input className={"form-control" + (isValid.hour ? "" : " is-invalid")} value={this.state.task.hour} onChange={this.createCallbackFunction(this, { name: "hour" })} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="cim-crontask-notation">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_NOTATION_ALLOWED_STANDART"
                                                            defaultMessage="Allowed ,-*/"
                                                            description="Allowed ,-*/ characters" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 form-group row">
                                                <div className="col-sm-2 ">
                                                    <FormattedMessage
                                                        id="CRON_TASKS_TIME_UNIT_DAY"
                                                        defaultMessage="Day"
                                                        description="Day" />:
                                                </div>
                                                <div className={"col-sm-4"}>
                                                    <input className={"form-control" + (isValid.day ? "" : " is-invalid")} value={this.state.task.day} onChange={this.createCallbackFunction(this, { name: "day" })} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="cim-crontask-notation">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_NOTATION_ALLOWED_MONTH_DAY"
                                                            defaultMessage="Allowed ,-*/?LW"
                                                            description="Allowed ,-*/?LW characters" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 form-group row">
                                                <div className="col-sm-2 ">
                                                    <FormattedMessage
                                                        id="CRON_TASKS_TIME_UNIT_MONTH"
                                                        defaultMessage="Month"
                                                        description="Month" />:
                                                </div>
                                                <div className={"col-sm-4"}>
                                                    <input className={"form-control" + (isValid.month ? "" : " is-invalid")} value={this.state.task.month} onChange={this.createCallbackFunction(this, { name: "month" })} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="cim-crontask-notation">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_NOTATION_ALLOWED_STANDART"
                                                            defaultMessage="Allowed ,-*/"
                                                            description="Allowed ,-*/ characters" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 form-group row">
                                                <div className="col-sm-2 ">
                                                    <FormattedMessage
                                                        id="CRON_TASKS_TIME_UNIT_WEEK_DAY"
                                                        defaultMessage="Week day"
                                                        description="Week day" />:
                                                </div>
                                                <div className={"col-sm-4"}>
                                                    <input className={"form-control" + (isValid.weekDay ? "" : " is-invalid")} value={this.state.task.weekDay} onChange={this.createCallbackFunction(this, { name: "weekDay" })} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="cim-crontask-notation">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_NOTATION_ALLOWED_WEEK_DAY"
                                                            defaultMessage="Allowed ,-*/?L#"
                                                            description="Allowed ,-*/?L# characters" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 cim-crontask-notation" style={monthAndWeekDayIsValid ? null : { color: "red" }} >
                                                <FormattedMessage
                                                    id="CRON_TASKS_NOTE_MANUAL_INPUT"
                                                    defaultMessage="Note: due to technical specification of cron string one of fields &quot;day of month&quot; and &quot;day of week&quot; must be setted to value &quot;?&quot;."
                                                    description="Note: due to technical specification of cron string one of fields 'day of month' and 'day of week' must be setted to value '?'." />
                                            </div>
                                        </div>
                                            :
                                            <div className="col-sm-10 form-group row">
                                                <div className="col-sm-12 ">
                                                    <button type="button" className="btn btn-secondary" onClick={this.createChangeToManualInput(this)}>
                                                        <FormattedMessage
                                                            id="CRON_TASKS_BUTTON_CHANGE_TO_MANUAL"
                                                            defaultMessage="Manual input"
                                                            description="Manual input" />
                                                    </button>
                                                </div>
                                                <div className="col-sm-4 ">
                                                    <div className="col-sm-12 ">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_TIME_UNIT_SECOND"
                                                            defaultMessage="Second"
                                                            description="Second" />:
                                                </div>
                                                    <select size="9" className={isValid.second ? "" : "cim-crontask-select-error"} value={this.state.task.second} onChange={this.createCallbackFunction(this, { name: "second" })}>
                                                        {TIME_UNITS.second.map((option, index) => <option key={index} value={option.originalValue}>{this.getMessage(option.value)}</option>)}
                                                    </select>
                                                </div>
                                                <div className="col-sm-4 ">
                                                    <div className="col-sm-12 ">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_TIME_UNIT_MINUTE"
                                                            defaultMessage="Minute"
                                                            description="Minute" />:
                                                </div>
                                                    <select size="9" className={isValid.minute ? "" : "cim-crontask-select-error"} value={this.state.task.minute} onChange={this.createCallbackFunction(this, { name: "minute" })}>
                                                        {TIME_UNITS.minute.map((option, index) => <option key={index} value={option.originalValue}>{this.getMessage(option.value)}</option>)}
                                                    </select>
                                                </div>
                                                <div className="col-sm-4 ">
                                                    <div className="col-sm-12 ">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_TIME_UNIT_HOUR"
                                                            defaultMessage="Hour"
                                                            description="Hour" />:
                                                </div>
                                                    <select size="9" className={isValid.hour ? "" : "cim-crontask-select-error"} value={this.state.task.hour} onChange={this.createCallbackFunction(this, { name: "hour" })}>
                                                        {TIME_UNITS.hour.map((option, index) => <option key={index} value={option.originalValue}>{this.getMessage(option.value)}</option>)}
                                                    </select>
                                                </div>
                                                <div className="col-sm-4 ">
                                                    <div className="col-sm-12 ">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_TIME_UNIT_DAY"
                                                            defaultMessage="Day"
                                                            description="Day" />:
                                                </div>
                                                    <select size="7" className={isValid.day ? "" : "cim-crontask-select-error"} value={this.state.task.day} onChange={this.createCallbackFunction(this, { name: "day" })}>
                                                        {TIME_UNITS.day.map((option, index) => <option key={index} value={option.originalValue}>{this.getMessage(option.value)}</option>)}
                                                    </select>
                                                </div>
                                                <div className="col-sm-4 ">
                                                    <div className="col-sm-12 ">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_TIME_UNIT_MONTH"
                                                            defaultMessage="Month"
                                                            description="Month" />:
                                                </div>
                                                    <select size="7" className={isValid.month ? "" : "cim-crontask-select-error"} value={this.state.task.month} onChange={this.createCallbackFunction(this, { name: "month" })}>
                                                        {TIME_UNITS.month.map((option, index) => <option key={index} value={option.originalValue}>{this.getMessage(option.value)}</option>)}
                                                    </select>
                                                </div>
                                                <div className="col-sm-4 ">
                                                    <div className="col-sm-12 ">
                                                        <FormattedMessage
                                                            id="CRON_TASKS_TIME_UNIT_WEEK_DAY"
                                                            defaultMessage="Week day"
                                                            description="Week day" />:
                                                </div>
                                                    <select size="7" className={isValid.weekDay ? "" : "cim-crontask-select-error"} value={this.state.task.weekDay} onChange={this.createCallbackFunction(this, { name: "weekDay" })}>
                                                        {TIME_UNITS.weekDay.map((option, index) => <option key={index} value={option.originalValue}>{this.getMessage(option.value)}</option>)}
                                                    </select>
                                                </div>
                                                <div className="col-sm-12 cim-crontask-notation" style={monthAndWeekDayIsValid ? null : { color: "red" }}>
                                                    <FormattedMessage
                                                        id="CRON_TASKS_NOTE_SELECT_INPUT"
                                                        defaultMessage="Note: due to technical specification of cron string one of fields &quot;day of month&quot; and &quot;day of week&quot; must be setted to value &quot;doesn't matter&quot;."
                                                        description="Note: due to technical specification of cron string one of fields 'day of month' and 'day of week' must be setted to value 'doesn't matter'." />
                                                </div>
                                            </div>}
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="description" className="col-sm-2 control-label">
                                            <FormattedMessage
                                                id="CRON_TASKS_DESCRIPTION"
                                                defaultMessage="Description"
                                                description="Description of script" />
                                        </label>
                                        <div className={"col-sm-10"}>
                                            <textarea id="description" className={"form-control " + (isValid.description ? "is-valid" : "is-invalid")} value={this.state.task.description} onChange={this.createCallbackFunction(this, { name: "description" })}></textarea>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            {this.props.error && !this.state.taskChanged ? <div className="alert alert-dismissible alert-danger">
                                <strong><FormattedMessage
                                    id="CRON_TASKS_SAVE_ERROR"
                                    defaultMessage="Error while saving action."
                                    description="Error while saving action." /></strong>
                            </div> : null}
                            {this.state.getScriptListError ? <div className="alert alert-dismissible alert-danger">
                                <strong><FormattedMessage
                                    id="CRON_TASKS_GET_SCRIPT_LIST_ERROR"
                                    defaultMessage="Error while getting script list. Try to reopen window."
                                    description="Error while getting script list. Try to reopen window." /></strong>
                            </div> : null}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" disabled={!validateTask({ task: this.state.task })} onClick={this.okCallback(this)}>
                                {this.state.changing ? MSG_BUTTON_SAVE : MSG_BUTTON_ADD}
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={this.closeCallback(this)}>
                                {MSG_BUTTON_CANCEL}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(ConfigurePanel);