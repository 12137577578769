import { FormattedMessage } from 'react-intl';
import { MSG_BUTTON_CONFIRM, MSG_BUTTON_CANCEL } from '../messages.jsx';

class ConfirmDialog extends React.Component {

    render() {
        let style = {
            display: "block"
        }
        return (
            <div className="modal" style={style}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" aria-hidden="true" onClick={this.props.cancel}>&times;</button>
                            <h4 className="modal-title">
                                <FormattedMessage
                                    id="CRON_TASKS_DIALOG_CONFIRM_ACTION"
                                    defaultMessage="Confirm action"
                                    description="Confirm action" />
                            </h4>
                        </div>
                        <div className="modal-body">
                            {this.props.text}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.props.ok}>
                                {MSG_BUTTON_CONFIRM}
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={this.props.cancel}>
                                {MSG_BUTTON_CANCEL}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmDialog;