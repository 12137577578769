import React from 'react';
import { FormattedMessage } from 'react-intl';
import MaskedInput from './maskedinput.jsx';

class RestorePopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            path: props.path
        }

        this.changePath = this.changePath.bind(this);
    }

    changePath(e) {
        this.setState({ path: e.target.value });
    }

    render() {
        return (
            <div>
                <FormattedMessage
                    id="SRC_EDITOR_HISTORY_RESTORE_NEW_PATH"
                    defaultMessage="Enter new path to restore code"
                    description="Enter new path to restore code" />
                <MaskedInput
                    id="pathInput"
                    value={this.state.path}
                    style={{ width: "100%" }}
                    onChange={this.changePath}
                    startsWith={"/"}
                    initialFocus={true}
                />
            </div >
        );
    }
}

export default RestorePopup;


