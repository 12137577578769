import * as Action from '../constants/classcard';

import { FormattedMessage } from 'react-intl';
import {
    initialTreeState,
    waitForTreeHandler,
    receiveTreeHandler,
    receiveTreeErrorHandler,
    toggleSubTreeHandler,
    updateNodeHandler
} from './npt-treebeard';
import { getPackageId } from '../services/classcard';

//Initial reducer state
const initialState = {
    data: {},
    loadingState: null,
    primitiveTypes: {
        list: null,
        loadingState: null
    },
    allClasses: {
        list: null,
        loadingState: null
    },
    notification: {
        type: null,
        text: null
    },
    packages: {
        list: [],
        packageById: {}
    },
    multiplicity: {
        list: [],
        multiplicityByValue: {}
    },
    relationType: {
        list: [],
        relationTypeByValue: {}
    },
    stereotype: {
        list: [],
        stereotypeByValue: {}
    },
    storeType: {
        list: [],
        storeTypeByValue: {}
    },
    namespace: {
        list: [],
        namespaceById: {}
    },
    tab: null
};

function addNamespace(classes, namespace) {
    for (let classData of classes) {
        if (typeof classData.namespaceId == "undefined" || classData.namespaceId == null || !namespace.namespaceById[classData.namespaceId]) {
            continue;
        }
        classData.namespace = namespace.namespaceById[classData.namespaceId].prefix;
    }
}

function createStoreTypeEnum(storeTypeMap) {
    let storeTypeEnum = [];
    for (let storeTypeId in storeTypeMap) {
        storeTypeEnum.push({ value: parseInt(storeTypeId), storeType: storeTypeMap[storeTypeId] });
    }
    return storeTypeEnum;
}

function createStereotypeEnum(stereotypeMap) {
    let stereotypeEnum = [];
    for (let stereotypeId in stereotypeMap) {
        stereotypeEnum.push({ value: parseInt(stereotypeId), stereotype: stereotypeMap[stereotypeId] });
    }
    return stereotypeEnum;
}

function createMultiplicityEnum(multiplicityMap) {
    let multiplicityEnum = [];
    for (let multiplicityId in multiplicityMap) {
        multiplicityEnum.push({ value: parseInt(multiplicityId), template: multiplicityMap[multiplicityId] });
    }
    return multiplicityEnum;
}

function createRelationEnum(relationMap) {
    let relationEnum = [];
    for (let relationId in relationMap) {
        relationEnum.push({ value: parseInt(relationId), relation: relationMap[relationId] });
    }
    return relationEnum;
}

function receiveEnumerations(state, { multiplicityMap, relationMap, stereotypeMap, storeTypeMap, namespaceMap }) {
    let newState = Object.assign({}, state);

    newState.multiplicity = {
        list: [],
        multiplicityByValue: {}
    };
    let multiplicityEnum = createMultiplicityEnum(multiplicityMap);
    for (let multiplicity of multiplicityEnum) {
        newState.multiplicity.list.push(multiplicity);
        newState.multiplicity.multiplicityByValue[multiplicity.value] = multiplicity;
    }
    newState.multiplicity.list.sort((a, b) => a.value > b.value);

    newState.relationType = {
        list: [],
        relationTypeByValue: {}
    };
    let relationEnum = createRelationEnum(relationMap);
    for (let relationType of relationEnum) {
        newState.relationType.list.push(relationType);
        newState.relationType.relationTypeByValue[relationType.value] = relationType;
    }
    newState.relationType.list.sort((a, b) => a.value > b.value);

    newState.stereotype = {
        list: [],
        stereotypeByValue: {}
    };
    let stereotypeEnum = createStereotypeEnum(stereotypeMap);
    let objectStereotype = false;
    for (let stereotype of stereotypeEnum) {
        if (stereotype.stereotype.toLowerCase() == "object") {
            objectStereotype = true;
        }
        newState.stereotype.list.push(stereotype);
        newState.stereotype.stereotypeByValue[stereotype.value] = stereotype;
    }
    newState.stereotype.list.sort((a, b) => a.value > b.value);
    if (!objectStereotype) {
        objectStereotype = {
            stereotype: null,
            value: "null"
        };
        newState.stereotype.list.unshift(objectStereotype);
        newState.stereotype.stereotypeByValue[objectStereotype.value] = objectStereotype;
    }

    newState.storeType = {
        list: [],
        storeTypeByValue: {}
    };
    let storeTypeEnum = createStoreTypeEnum(storeTypeMap);
    for (let storeType of storeTypeEnum) {
        newState.storeType.list.push(storeType);
        newState.storeType.storeTypeByValue[storeType.value] = storeType;
    }
    newState.storeType.list.sort((a, b) => a.value > b.value);

    newState.namespace = {
        list: [],
        namespaceById: {}
    }
    for (let namespaceId in namespaceMap) {
        let namespace = namespaceMap[namespaceId];
        newState.namespace.list.push(namespace);
        newState.namespace.namespaceById[namespace.id] = namespace;
    }
    newState.namespace.list.sort((a, b) => a.id > b.id);
    if (newState.allClasses.list) {
        newState.allClasses = Object.assign({}, newState.allClasses);
        newState.allClasses.list = newState.allClasses.list.slice()
        for (let i = 0; i < newState.allClasses.list; ++i) {
            newState.allClasses.list[i] = Object.assign({}, newState.allClasses.list[i]);
        }
        addNamespace(newState.allClasses.list, newState.namespace);
    }
    return newState;
}

function waitForData(state) {
    return Object.assign({}, state, { loadingState: "loading", predicateList: [], relationList: [], parentList: [] });
}

function findReverseLink(curClass, predicate) {
    let reversePredicateList = predicate.classRelationInfo.peerClass.predicateList;
    for (let revPredicate of reversePredicateList) {
        if (revPredicate.classRelationInfo && revPredicate.classRelationInfo.peerClass.id == curClass.id) {
            return revPredicate.name;
        }
    };
    return null;
}

function receiveInfo(state, { resetTab, data }) {
    let newState = Object.assign({}, state, { data, loadingState: "done" });
    if (resetTab) {
        return changeTab(newState, { tabName: null });
    }
    return newState;
}

function receiveDataError(state, { error }) {
    console.error("Error while downloading data: ", error)
    return Object.assign({}, state, { loadingState: "error" });
}

function waitForTypes(state) {
    let primitiveTypes = Object.assign({}, state.primitiveTypes);
    primitiveTypes.loadingState = "loading";
    return Object.assign({}, state, { primitiveTypes });
}

function receiveTypes(state, { data }) {
    let primitiveTypes = Object.assign({}, state.primitiveTypes);
    primitiveTypes.loadingState = "done";
    primitiveTypes.list = data;
    primitiveTypes.typeById = {};
    for (let type of data) {
        primitiveTypes.typeById[type.id] = type;
    }
    return Object.assign({}, state, { primitiveTypes });
}

function receiveTypesError(state) {
    let primitiveTypes = Object.assign({}, state.primitiveTypes);
    primitiveTypes.loadingState = "error";
    return Object.assign({}, state, { primitiveTypes });
}

function receiveAllClasses(state, { data }) {
    let allClasses = Object.assign({}, state.allClasses);
    allClasses.loadingState = "done";
    allClasses.list = data;
    if (state.namespace) {
        addNamespace(data, state.namespace);
    }
    return Object.assign({}, state, { allClasses });
}

function receiveAllClassesError(state) {
    let allClasses = Object.assign({}, state.allClasses);
    allClasses.loadingState = "error";
    return Object.assign({}, state, { allClasses });
}

function receivePackages(state, { packages }) {
    let nextState = Object.assign({}, state);
    let packageMap = {};
    for (let packageData of packages) {
        packageMap[packageData.id] = packageData;
    }
    nextState.packages = {
        list: packages,
        packageById: packageMap
    }
    return nextState;
}

function changeTab(state, { tabName }) {
    return Object.assign({}, state, { tab: tabName, selectedRows: [] });
}

function childrenReceived(state, { classId, children }) {
    if (!state.data || state.data.id != classId) {
        return state;
    }
    let nextState = Object.assign({}, state);
    nextState.data = Object.assign({}, nextState.data);
    nextState.data.childrenList = children;
    return nextState;
}

export function classCardReducer(state = initialState, action) {
    switch (action.type) {
        case Action.RECEIVE_ENUMERATIONS: return receiveEnumerations(state, action.payload);
        case Action.CLASS_WAIT: return waitForData(state, action.payload);
        case Action.CLASS_INFO_RECEIVED: return receiveInfo(state, action.payload);
        case Action.CLASS_ERROR_RECEIVED: return receiveDataError(state, action.payload);
        case Action.TYPES_WAIT: return waitForTypes(state, action.payload);
        case Action.TYPES_RECEIVED: return receiveTypes(state, action.payload);
        case Action.TYPES_ERROR_RECEIVED: return receiveTypesError(state, action.payload);
        case Action.ALL_CLASSES_RECEIVED: return receiveAllClasses(state, action.payload);
        case Action.ALL_CLASSES_ERROR_RECEIVED: return receiveAllClassesError(state, action.payload);
        case Action.RECEIVE_PACKAGES: return receivePackages(state, action.payload);
        case Action.CHANGE_TAB: return changeTab(state, action.payload);
        case Action.SELECT_ROWS: return selectRows(state, action.payload);
        case Action.DESELECT_ROWS: return deselectRows(state, action.payload);
        case Action.CHILDREN_RECEIVED: return childrenReceived(state, action.payload);
        default: return state;
    }
}

export function classCardTreeReducer(state = initialTreeState, action) {
    switch (action.type) {
        case Action.WAIT_FOR_TREE: return waitForTreeHandler(state, action.payload);
        case Action.RECEIVE_TREE: return receiveTreeHandler(state, action.payload);
        case Action.RECEIVE_TREE_ERROR: return receiveTreeErrorHandler(state, action.payload);
        case Action.TOGGLE_SUBTREE: return toggleSubTreeHandler(state, action.payload);
        case Action.UPDATE_NODE: return updateNodeHandler(state, action.payload);
        default: return state;
    }
}

