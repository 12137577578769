import * as Action from '../constants/objectcard';
import { addAlert } from './alert';
import { generateSubjectId, getTypeRestrictionsError } from '../services/objectcard';
import { ALERT_DANGER, ALERT_WARNING } from '../constants/alert';

import {
    retrieveFunction,
    applyChanges,
    obtainData,
    checkMandatory,
    scriptCompiler,
    checkMinimumItems,
    checkMaximumItems
} from '../services/automation';

import {
    mergeSubjectValues,
    enqueUpload,
    ajaxUploadFiles,
    getUploadTaskList,
    clearUploadTasks
} from '../services/objectcard';

import {
    normalizePredicate,
    layoutAll,
    generateBindings,
    buildLabelInfo
} from '../services/layout';

import { openModal } from './modal';

import shortid from 'shortid';
import { saveToServer, cancelRPASetpointsUpdates } from './rpasetpoints';
import { LOCATION } from '../constants/location';
import { FormattedMessage } from 'react-intl';
import { BASE_TABLE_FILTER_URL, parseStringResponse } from './table';
import { getHashData } from './location';
import { storeObjectcardHistory } from '../services/storage';

//Basic url for all ajax requests
const SUBJECT_CLASS_LAYOUT_URL = "/rest/subject/layout";
const SUBJECT_CLASS_ENUMERATION_URL = "/rest/subject/enumeration";
const SUBJECT_FETCH_URL = "/rest/subject/entity";
const SUBJECT_LOCK_URL = "/rest/subject/lock";
const FRAGMENT_PATH = "/rest/fragment/path";
const FRAGMENT_TREE = "/rest/fragment/tree";
const SUBJECT_NEW_URL = "/rest/subject/new";
const VALIDATE_LINK_URL = "/rest/subject/validate";

const SUBJECT_EXPORT_URL = "rest/subject/export";

const MANDATORY_ERROR = { id: "OBJECTCARD_FIELD_IS_MANDATORY" };
const MINIMUM_ITEMS_ERROR = { id: "OBJECTCARD_LESS_THAN_MINIMUM_ITEMS" };
const MAXIMUM_ITEMS_ERROR = { id: "OBJECTCARD_MORE_THAN_MAXIMUM_ITEMS" };

function isEditable(data) {
    return data.$isNew || (data.$lock && data.$lock.status);
}

function makeLayout(classInfo) {
    if (!classInfo) {
        return null;
    }
    const layout = layoutAll(classInfo, false);
    return layout;
}

/**
 * data is optional parameter if not supplied it will be taken from card
 */
function makeState(card, store, data) {
    return {
        values: card.values[store] || {},
        validation: card.validation[store] || {},
        visibilityDemand: card.visibilityDemand[store] || {},
        lockDemand: card.lockDemand[store] || {},
        data: data || card.data[store] || {}
    }
}

function wrapValue(value) {
    if (typeof value == "undefined" || (typeof value == "object" && $.isEmptyObject(value))) {
        return null;
    }
    return value;
}

/**
 * values and data are mandatory
 * dispatch and store are optional parameter (used only for clicks)
 */
export function makeForm(values, data, dispatch, store) {
    const form = {
        obtain: function (p) {
            p = normalizePredicate(p);
            const value = values[p];
            if (typeof value != 'undefined') {
                return wrapValue(value);
            }
            const path = p.split(".");
            if (path[0].indexOf('$') == 0) { //Special symbol and special fields
                if (typeof values[path[0]] != 'undefined') {
                    const key = path.shift();  //remove first part from path
                    return wrapValue(obtainData(path, values[key]));
                }
                //Fallback to data
                //console.log("Obtain from data", path, data);
                return wrapValue(obtainData(path, data));
            }
            //Predicate must have at least three parts: namespace, classname, predicate name.
            //We use 4 because 3 parts were already checked at the begining!
            if (path.length < 4) {
                return wrapValue(value); //return undefined value
            }
            const p2 = [path.shift(), path.shift(), path.shift()].join(".");
            const value2 = values[p2]; //New value
            //console.log("Obtain from predicate sub value", p2, value2, path);
            if (!value2) {
                return wrapValue(value2); //return undefined or null
            }
            return wrapValue(obtainData(path, value2)); //path is rest of starting path without three elements, value2 is from values
        }
    };
    if (dispatch && store) {
        form.openModal = function (type, options, okCallback, cancelCallback, closeCallback) {
            const modalId = shortid.generate();
            dispatch(openModal(modalId, type, options, okCallback, cancelCallback, closeCallback));
        }
        form.reload = function () {
            dispatch(fetchSubject(store, data.$rdfId, data.$namespace, true));
        }
        form.change = function (predicate, value) {
            let nodeId = normalizePredicate(predicate);
            dispatch(change(store, nodeId, value));
        }
    }
    return form;
}

function makeDiff(store,
    layout,
    valuesDiff, //Current state
    { values, validation, visibilityDemand, lockDemand, data },
    invalidFormat) //List of invalid format nodes
{
    //Make validation and automation
    let nextValues = Object.assign({}, values, valuesDiff);
    //Bind values
    const form = makeForm(nextValues, data);
    const editable = isEditable(data);
    //Bind values only in editable mode
    if (editable) {
        //Make several iteration to obtain final result of value bindings
        for (let i = 0; i < 10; ++i) {
            let changed = false; //flag to exit from iterations
            for (let nodeId in layout.automation.valueBindings) {
                const funcId = layout.automation.valueBindings[nodeId];
                const func = retrieveFunction(funcId);
                try {
                    const value = func(form);
                    if (values[nodeId] != value && nextValues[nodeId] != value) {
                        valuesDiff[nodeId] = value;
                        changed = true;
                    }
                } catch (ex) {
                    console.log("Value binding error", nodeId, ex);
                }
            }
            if (!changed) { //nothing have been changed so exit from iterations
                break;
            }
            //Update next values
            nextValues = Object.assign({}, values, valuesDiff);
        }
    }
    //Process validation
    let validationDiff = null;
    for (let nodeId of layout.predicateIds) {
        const value = nextValues[nodeId];
        let error = false;
        if (invalidFormat && invalidFormat[nodeId]) { //If we have map of invalid format values
            error = invalidFormat[nodeId];
        }
        if (!error && layout.mandatorySet[nodeId]) { //Check mandatory first
            if (!checkMandatory(value)) {
                error = MANDATORY_ERROR;
            }
        }
        if (!error) {
            error = getTypeRestrictionsError(layout.byId[nodeId], value);
        }
        if (!error && !checkMinimumItems(layout.byId[nodeId], value)) {
            error = Object.assign({ values: { length: layout.byId[nodeId].options.min } }, MINIMUM_ITEMS_ERROR);
        }
        if (!error && !checkMaximumItems(layout.byId[nodeId], value)) {
            error = Object.assign({ values: { length: layout.byId[nodeId].options.max } }, MAXIMUM_ITEMS_ERROR);
        }
        if (!error && layout.automation.validationBindings[nodeId]) { //Check bindings
            const funcId = layout.automation.validationBindings[nodeId];
            const func = retrieveFunction(funcId);
            try {
                error = func(value, form);
            } catch (ex) {
                console.log("Validation binding error", nodeId, ex);
            }
        }
        const prevError = validation[nodeId];
        if (error != prevError) {
            if (!validationDiff) {
                validationDiff = {};
            }
            validationDiff[nodeId] = error;
        }
    }
    //Process visibility
    let visibilityDiff = null;
    for (let nodeId in layout.automation.visibilityBindings) {
        const funcId = layout.automation.visibilityBindings[nodeId];
        const func = retrieveFunction(funcId);
        try {
            const visible = func(form);
            if (visibilityDemand[nodeId] != visible) {
                if (!visibilityDiff) {
                    visibilityDiff = {};
                }
                visibilityDiff[nodeId] = visible;
            }
        } catch (ex) {
            console.log("Visibility binding error", nodeId, ex);
        }
    }
    //Process locks
    let lockDiff = null;
    //First: make common lock checks
    for (let funcId of layout.automation.commonLockChecks) {
        const func = retrieveFunction(funcId);
        for (let nodeId of layout.predicateIds) {
            try {
                const lock = func(form, Object.assign({}, layout.byId[nodeId], layout.byId[nodeId].options), nodeId);
                if (lockDemand[nodeId] != lock) {
                    if (!lockDiff) {
                        lockDiff = {};
                    }
                    lockDiff[nodeId] = lock;
                }
            } catch (ex) {
                console.log("Common lock checks error", nodeId, ex);
            }
        }
    }
    //Second: make specific lock checks for predicates
    for (let nodeId in layout.automation.lockBindings) {
        const funcId = layout.automation.lockBindings[nodeId];
        const func = retrieveFunction(funcId);
        try {
            const lock = func(form);
            if (lockDemand[nodeId] != lock) {
                if (!lockDiff) {
                    lockDiff = {};
                }
                lockDiff[nodeId] = lock;
            } else if (lockDiff && typeof lockDiff[nodeId] != "undefined") {
                delete (lockDiff[nodeId]);
            }
        } catch (ex) {
            console.log("Lock binding error", nodeId, ex);
        }
    }
    return { store, valuesDiff, validationDiff, visibilityDiff, lockDiff };
}

function syncDataAndLayout(globalState, store, data, layout, forceUpdate) {
    const card = globalState[Action.OBJECTCARD];
    const currentState = makeState(card, store, data);
    const valuesDiff = {};
    if (forceUpdate || !card.values[store]) {
        currentState.values = {};
        currentState.validation = {};
        currentState.visibilityDemand = {};
        currentState.lockDemand = {};
        const predicateIds = layout.predicateIds.slice();
        if (data.$ignore) {
            for (let p in data.$ignore) {
                predicateIds.push(`$ignore.${p}`);
            }
        }
        for (let nodeId of predicateIds) {
            const d = obtainData(nodeId, data);
            if (typeof d != "undefined") {
                valuesDiff[nodeId] = d;
            } else {
                valuesDiff[nodeId] = null; //not undefined!
            }
        }
        //Check if we need to prefill some data
        const hasFill = !$.isEmptyObject(layout.automation.fillBindings);
        const editable = isEditable(data);
        console.log("Check fill bindings:", editable, hasFill);
        if (editable && hasFill) {
            const form = makeForm(valuesDiff, data);
            for (let nodeId of predicateIds) {
                if (valuesDiff[nodeId] || !layout.automation.fillBindings[nodeId]) { //Do not fill ready fields
                    continue;
                }
                console.log("Process fill bindings:", nodeId);
                const funcId = layout.automation.fillBindings[nodeId];
                const func = retrieveFunction(funcId);
                try {
                    const d = func(form);
                    if (typeof d != "undefined" && d != null) {
                        valuesDiff[nodeId] = d;
                    }
                } catch (ex) {
                    console.log("Fill binding error", nodeId, ex);
                }
            }
        }
    }
    return makeDiff(store, layout, valuesDiff, currentState);
}

function recursiveScan(data, updatedSubjects) {
    if ($.type(data) == "object") {
        const subjectId = generateSubjectId(data);
        if (subjectId) {
            updatedSubjects.push(subjectId);
        }
        for (let key in data) {
            recursiveScan(data[key], updatedSubjects);
        }
    } else if ($.type(data) == "array") {
        for (let x of data) {
            recursiveScan(x, updatedSubjects);
        }
    }
}

function notifySubjectChanged(subject, notifyId) {
    //Notify interested modules about subject changes
    const subjectId = generateSubjectId(subject);
    //let updatedSubjects = [];
    //recursiveScan(data, updatedSubjects);
    let event = { updatedSubjects: [subjectId] };
    if (notifyId) {
        event.notifyId = notifyId;
    }
    $(document).trigger(Action.NOTIFY_SUBJECT_CHANGED, event);
}

function checkNew(store, globalState) {
    let card = globalState[Action.OBJECTCARD];
    let data = card.data[store];
    if (!data || !data.$isNew) {
        return false;
    }
    return true;
}

function getRdfId(store, globalState) {
    let card = globalState[Action.OBJECTCARD];
    return card.data[store] && card.data[store].$rdfId;
}

function getNamespace(store, globalState) {
    let card = globalState[Action.OBJECTCARD];
    return card.data[store] && card.data[store].$namespace;
}

function getLockStatus(store, globalState) {
    let card = globalState[Action.OBJECTCARD];
    let data = card.data[store];
    if (!data || !data.$lock) {
        return false;
    }
    return data.$lock.status;
}

function checkLayoutFetchNeeded(globalState, cls) {
    return (typeof globalState[Action.OBJECTCARD].layoutStatus[cls] == 'undefined');
}

function checkLockStatus(store, globalState, lock) {
    return (getLockStatus(store, globalState) == lock);
}

function checkSavePossible(store, globalState) {
    const card = globalState[Action.OBJECTCARD];
    const data = card.data[store];
    if (!data) {
        return false;
    }
    const areFilesUploaded = (card.saveState[store] == Action.SAVE_STATE_UPLOADS_READY);
    return (data.$isNew || getLockStatus(store, globalState)) && areFilesUploaded;
}

function checkSubjectFetchNeeded(store, globalState, rdfId, namespace) {
    let card = globalState[Action.OBJECTCARD];
    let data = card.data[store];
    if (!data) {
        return true;
    }
    return (data.$rdfId != rdfId || data.$namespace != namespace);
}

function composeSubjectClassLayoutUrl(cls) {
    const path = cls.split(":");
    return `${SUBJECT_CLASS_LAYOUT_URL}/${path[0]}/${path[1]}`;
}

function ajaxFetchLayout(cls, dispatch, getState) {
    console.log("AJAX call to fetch layout");
    dispatch(waitForLayout(cls));
    return $.get(composeSubjectClassLayoutUrl(cls), function (data) {
        dispatch(layoutReceived(cls, data, getState(), dispatch));
    }).fail(function (error) {
        dispatch(layoutErrorReceived(cls, error));
    });
}

function waitForLayout(cls) {
    return {
        type: Action.LAYOUT_WAIT,
        payload: { cls }
    }
}

function parseTableNode(tableNode, mainNode) {
    /** If current node isn't predicate - it is not column */
    if (!tableNode.p) {
        if (tableNode.content && Array.isArray(tableNode.content)) {
            for (let child of tableNode.content) {
                parseTableNode(child, mainNode);
            }
        }
        return;
    }

    const column = {
        id: tableNode.id,
        predicateId: tableNode.id,
        path: tableNode.id.split("."),
        label: tableNode.label,
        labelInfo: buildLabelInfo(tableNode.label),
        format: Action.UI_FORMAT[tableNode.ui] || "string"
    };
    /** Setup predicate for cell factory */
    switch (tableNode.ui) {
        case Action.UI_COMBOBOX:
            column.predicate = {
                classRelationInfo: {
                    peerClass: {
                        stereotypeInfo: "enumeration"
                    }
                }
            }
            break;
        case Action.UI_FILE:
            column.predicate = {
                dataType: {
                    name: "base64Binary"
                }
            }
            break;
        case Action.UI_FRAGMENT:
            column.predicate = {
                dataType: {
                    name: "anyURI"
                }
            }
            break;
        case Action.UI_OBJECT_TABLE:
            column.predicate = {
                classRelationInfo: {
                    peerClass: {},
                    relationTypeInfo: "composition"
                }
            }
            break;
        case Action.UI_REF_TABLE:
            column.predicate = {
                classRelationInfo: {
                    peerClass: {},
                    relationTypeInfo: "aggregation"
                }
            }
            break;
        case Action.UI_CHECKBOX:
            column.predicate = {
                dataType: {
                    name: "boolean"
                }
            }
            break;
        case Action.UI_DATE:
            column.predicate = {
                dataType: {
                    name: "date"
                }
            }
            break;
        case Action.UI_DATE_TIME:
            column.predicate = {
                dataType: {
                    name: "dateTime"
                }
            }
            break;
        case Action.UI_FLOAT:
            column.predicate = {
                dataType: {
                    name: "float"
                }
            }
            break;
        case Action.UI_INT:
            column.predicate = {
                dataType: {
                    name: "integer"
                }
            }
            break;
        default:
            column.predicate = {
                dataType: {
                    name: "string"
                }
            }
            break;
    }
    column.predicate["table-header-width"] = tableNode.options && tableNode.options.width;
    Object.assign(column, tableNode.options);
    mainNode.options.columns.push(column)
}

function parseTable(node) {
    node.options.columns = [];
    if (!node.options.layout || !node.options.layout.content || !Array.isArray(node.options.layout.content)) {
        return;
    }
    if (node.options["columns-order"]) {
        for (let i = 0; i < node.options["columns-order"].length; ++i) {
            node.options["columns-order"][i] = normalizePredicate(node.options["columns-order"][i]);
        }
    }

    for (let child of node.options.layout.content) {
        parseTableNode(child, node);
    }
    delete (node.options.layout);
}

function parsePredicate(node) {
    node.label = node.options.label || node.label;
}

function normalizeLayout(content, layout, parentId) {
    if (!content || !Array.isArray(content)) {
        return;
    }
    for (const node of content) {
        layout.byId[node.id] = node;
        if (!node.options) {
            node.options = {};
        }
        if (parentId) {
            layout.parentIdByChildId[node.id] = parentId;
            if (layout.childrenIdsByParentId[parentId]) {
                layout.childrenIdsByParentId[parentId].push(node.id);
            } else {
                layout.childrenIdsByParentId[parentId] = [node.id];
            }
        } else {
            layout.rootNodesIds.push(node.id);
        }
        //Check mandatory flag
        if (node.mandatory) {
            layout.mandatorySet[node.id] = true;
        }
        //Check predicate marker
        if (node.p) {
            parsePredicate(node);
            layout.predicateIds.push(node.id);
            delete node.p;
        }
        //Source parameter should be normalized
        if (node.options.src) {
            node.options.src = normalizePredicate(node.options.src);
        }
        //Add enumeration to fetch list
        if (node.ui == Action.UI_COMBOBOX && node.options.cls) {
            layout.fetchEnumerationsMap[node.options.cls] = true;
        }
        if (node.ui == Action.UI_OBJECT_TABLE) {
            parseTable(node);
        }
        if (node.ui == Action.UI_TAB) {
            layout.tabsIds.push(node.id);
        }
        //Recursive parse
        if (node.content) {
            normalizeLayout(node.content, layout, node.id);
            delete node.content;
        } else {
            node.leaf = true; //Node is leaf. It will not be deleted while removing empty.
        }
    }
}

function getAutomation(script, cls) {
    const normalizedAutomation = {
        optionsBindings: {},
        valueBindings: {},
        enumerationBindings: {},
        clickBindings: {},
        validationBindings: {},
        lockBindings: {},
        visibilityBindings: {},
        fillBindings: {},
        linkBindings: {},
        commonLockChecks: [] //Lock checks not specific for the predicate
    };
    if (script) {
        const bindings = generateBindings(normalizedAutomation, cls);
        console.log("Compile automation");
        console.log(script);
        scriptCompiler(script, bindings);
    }
    return normalizedAutomation;
}

/**
 * Search layout nodes for speciefic ui that require
 * functions to be runned before objectcard save function
 */
function getPreSaveFunctions(layout) {
    let preSave = {};
    if (!layout.byId) {
        return preSave;
    }
    for (let nodeId in layout.byId) {
        if (layout.byId[nodeId].ui == Action.UI_RPA_SETPOINTS) {
            preSave[Action.UI_RPA_SETPOINTS] = layout.byId[nodeId].options.src;
        }
    }
    return preSave;
}

function layoutReceived(cls, json, globalState, dispatch) {
    const card = globalState[Action.OBJECTCARD];

    const layout = {
        //Main information
        clsName: json.id,                                   //Class name to generate id
        toolbar: json.toolbar ? json.toolbar.buttons : [],  //Toolbar or empty toolbar
        override: null,                                     //TODO: Copy override property
        //User interface elements
        byId: {},                                           //Map between id and element definition
        parentIdByChildId: {},                              //Map between child id and parent id
        childrenIdsByParentId: {},                          //Map between parent id and it's children ids
        rootId: null,                                       //Root element id
        //Placeholders
        parentPlaceHolderId: null,                          //Parent place holder id (ui id).
        childPlaceHolderId: null,                           //Child place holder id (ui id).
        selfPlaceHolderId: null,                            //Self placeholder id (ui id).
        predicateIds: [],                                   //List of all predicates
        mandatorySet: {},                                   //Map of mandatory fields
        //Automation
        automation: getAutomation(json.automation, cls),
        //Values from layout fetch
        rootNodesIds: [],
        fetchEnumerationsMap: {},
    }

    /** Manually create root node */
    const rootId = "__npt.objectcard.root";
    layout.rootId = rootId
    layout.byId[rootId] = {
        id: rootId,
        options: {}
    };
    layout.childrenIdsByParentId[layout.rootId] = [];

    //Add special fields to make available for placeholders
    const specialList = [
        {
            id: "$label",
            ui: Action.UI_LABEL,
            options: {},
            leaf: true
        },
        {
            id: "$description",
            ui: Action.UI_DESCRIPTION,
            options: {},
            leaf: true
        }
    ];
    for (let special of specialList) {
        layout.byId[special.id] = special;
        layout.predicateIds.push(special.id);
        //Add to parent but not to child list of parent (so that those items will not be displayed by default)
        layout.parentIdByChildId[special.id] = layout.rootId;
    }

    layout.tabsIds = [];
    normalizeLayout(json.content, layout);
    /** Add normalized roots to root node */
    for (let nodeId of layout.rootNodesIds) {
        layout.parentIdByChildId[nodeId] = rootId;
        layout.childrenIdsByParentId[layout.rootId].push(nodeId);
    }
    for (let enumerationClsName in layout.fetchEnumerationsMap) {
        if (card.enumerationCls[enumerationClsName] && !card.enumerationCls[enumerationClsName].error) {
            continue;
        }
        ajaxFetchEnumeration(dispatch, enumerationClsName);
    }
    delete (layout.rootNodesIds);
    delete (layout.fetchEnumerationsMap);

    layout.preSave = getPreSaveFunctions(layout);

    layout.childPlaceHolderId = layout.byId["$child"] ? "$child" : null;
    layout.parentPlaceHolderId = layout.byId["$parent"] ? "$parent" : null;
    layout.selfPlaceHolderId = layout.byId["$self"] ? "$self" : null;
    //Check if we need to update data stores
    let storeDiff = null;
    for (let store in card.data) {
        if (card.data[store].$class == cls) {
            if (!storeDiff) {
                storeDiff = [];
            }
            storeDiff.push(syncDataAndLayout(globalState, store, card.data[store], layout, false));
        }
    }
    return {
        type: Action.LAYOUT_RECEIVED,
        payload: { cls, layout, storeDiff }
    }
}

function layoutErrorReceived(rdfId, error) {
    return {
        type: Action.LAYOUT_ERROR_RECEIVED,
        payload: { rdfId, error }
    }
}

function composeSubjectClassEnumerationUrl(cls) {
    const path = cls.split(":");
    return `${SUBJECT_CLASS_ENUMERATION_URL}/${path[0]}/${path[1]}`;
}

export function ajaxFetchEnumeration(dispatch, cls) {
    console.log("AJAX call to fetch enumeration:", cls);
    dispatch(waitForEnumeration(cls));
    return $.get(composeSubjectClassEnumerationUrl(cls), function (data) {
        dispatch(enumerationReceived(cls, data));
    }).fail(function (error) {
        dispatch(enumerationErrorReceived(cls, error));
    });
}

function waitForEnumeration(cls) {
    return {
        type: Action.ENUMERATION_WAIT,
        payload: { cls }
    }
}

function enumerationReceived(cls, data) {
    return {
        type: Action.ENUMERATION_RECEIVED,
        payload: { cls, data }
    }
}

function enumerationErrorReceived(cls, error) {
    return {
        type: Action.ENUMERATION_ERROR_RECEIVED,
        payload: { cls, error }
    }
}

function ajaxLockSubject(dispatch, store, rdfId, namespace, lock) {
    console.log("AJAX call to lock subject");
    const operation = lock ? Action.SUBJECT_OPERATION_LOCK : Action.SUBJECT_OPERATION_UNLOCK;
    $.post(SUBJECT_LOCK_URL, { rdfId, namespace, acquire: lock }, function (data) {
        dispatch(subjectReceived(store, operation, data, null));
    }).fail(function (error) {
        if (error.status == 403) {
            dispatch(addAlert(ALERT_DANGER, { id: "MSG_STATUS_FORBIDDEN" }));
        } else {
            dispatch(addAlert(ALERT_DANGER, { id: "OBJECTCARD_LOCK_FAILED" }));
        }
        dispatch(subjectErrorReceived(store, operation, error, null));
    });
}

function composeSubjectUrl(rdfId, namespace) {
    if (namespace) {
        return `${SUBJECT_FETCH_URL}/${namespace}/${rdfId}`;
    }
    return `${SUBJECT_FETCH_URL}/${rdfId}`;
}

function composeCreateUrl(className, parent, parentRef, prototype) {
    const parts = className.split(":");
    const createUrl = `${SUBJECT_NEW_URL}/${parts[0]}/${parts[1]}`;
    const params = {};
    if (parent) {
        params.parent = parent;
    }
    if (parentRef) {
        params["parent-ref"] = parentRef;
    }
    if (prototype) {
        params._prototype = prototype;
    }
    if ($.isEmptyObject(params)) {
        return createUrl;
    }
    return createUrl + "?" + $.param(params);
}

function ajaxFetchSubject(store, rdfId, namespace, force, dispatch) {
    console.log("AJAX call to fetch subject");
    dispatch(waitForSubject(store, rdfId, namespace));
    return $.get(composeSubjectUrl(rdfId, namespace), function (data) {
        storeObjectcardHistory(data)
        dispatch(subjectReceived(store, Action.SUBJECT_OPERATION_GET, data, null));
        if (force) { //If somebody forced to reload object card then subject is likely to be changed!
            notifySubjectChanged(data);
        }
    }).fail(function (error) {
        dispatch(subjectErrorReceived(store, Action.SUBJECT_OPERATION_GET, error, null));
    });
}

function ajaxCreateNewSubject(store, className, parent, parentRef, prototype, notifyId, initialData, dispatch) {
    console.log("AJAX call to create new subject");
    return $.get(composeCreateUrl(className, parent, parentRef, prototype), function (data) {
        if (typeof initialData == "object") {
            Object.assign(data, initialData);
        }
        dispatch(subjectReceived(store, Action.SUBJECT_OPERATION_CREATE, data, notifyId));
    }).fail(function (error) {
        dispatch(subjectErrorReceived(store, Action.SUBJECT_OPERATION_GET, error, notifyId));
    });
}

function waitForSubject(store, rdfId, namespace) {
    return {
        type: Action.SUBJECT_WAIT,
        payload: { store, rdfId, namespace }
    }
}

function doSubjectReceived(globalState, store, operation, subject, layout, notifyId) {
    let diff = null; //Data difference
    if (layout) {
        diff = syncDataAndLayout(globalState, store, subject, layout, true);
    }
    return {
        type: Action.SUBJECT_RECEIVED,
        payload: { store, operation, subject, layout, diff, notifyId }
    }
}

function subjectReceived(store, operation, subject, notifyId) {
    return function (dispatch, getState) {
        //Dispatch subject data
        const layout = getState()[Action.OBJECTCARD].layoutCache[subject.$class]; //Check if layout is ready
        dispatch(doSubjectReceived(getState(), store, operation, subject, layout, notifyId));
        //Fetch layout if needed
        if (checkLayoutFetchNeeded(getState(), subject.$class)) {
            ajaxFetchLayout(subject.$class, dispatch, getState);
        }
    }
}

function subjectErrorReceived(store, operation, error, notifyId) {
    return {
        type: Action.SUBJECT_ERROR_RECEIVED,
        payload: { store, operation, error, notifyId }
    }
}

function composeSubjectSaveUrl(rdfId) {
    return `${SUBJECT_FETCH_URL}`;
}

function ajaxSaveSubject(dispatch, store, data, notifyId) {
    console.log("AJAX call to save subject");
    dispatch(waitForSaveSubject(store));
    return $.ajax({
        contentType: 'application/json',
        data: JSON.stringify(data),
        dataType: 'json',
        success: function (data) { //save subject done
            dispatch(subjectReceived(store, Action.SUBJECT_OPERATION_SAVE, data, notifyId));
            notifySubjectChanged(data, notifyId);
        },
        error: function (error) { //save subject error
            if (error.status == 400) {
                dispatch(addAlert(ALERT_DANGER, error.responseText));
            } else {
                dispatch(addAlert(ALERT_DANGER, { id: "OBJECTCARD_SAVE_FAILED" }))
            }
            dispatch(subjectErrorReceived(store, Action.SUBJECT_OPERATION_SAVE, error, notifyId));
        },
        processData: false,
        type: 'POST',
        url: composeSubjectSaveUrl()
    });
}

function waitForSaveSubject(store) {
    return {
        type: Action.SUBJECT_SAVE_WAIT,
        payload: { store }
    }
}

function runPreSave(dispatch, layout, callback) {
    let binded = false;
    for (let funcId in layout.preSave) {
        if (funcId == Action.UI_RPA_SETPOINTS) {
            binded = true;
            /* Remove all previously binded save funcitons */
            $(window).unbind('rpasetpoints.save');
            $(window).bind('rpasetpoints.save', (event, rdfId) => {
                $(window).unbind('rpasetpoints.save');
                callback({ [layout.preSave[funcId]]: rdfId || "" });
            });
            dispatch(saveToServer());
        }
    }
    if (!binded) {
        callback();
    }
}

function waitForFragmentTree(fragmentRdfId) {
    return {
        type: Action.SUBJECT_FRAGMENT_TREE_WAIT,
        payload: { fragmentRdfId }
    }
}

function fragmentTreeDataReceived(fragmentRdfId, data) {
    return {
        type: Action.SUBJECT_FRAGMENT_TREE_DATA_RECEIVED,
        payload: { fragmentRdfId, data }
    }
}

function fragmentTreeErrorReceived(fragmentRdfId, error) {
    return {
        type: Action.SUBJECT_FRAGMENT_TREE_ERROR_RECEIVED,
        payload: { fragmentRdfId, error }
    }
}

function composeFragmentPathUrl(fragmentRdfId) {
    let nodeUrl = FRAGMENT_PATH;
    if (!fragmentRdfId) {
        return nodeUrl;
    }
    return nodeUrl + "/" + fragmentRdfId;
}

async function ajaxFetchFragmentPath(fragmentRdfId, dispatch) {
    return $.getJSON(composeFragmentPathUrl(fragmentRdfId), function (data) {
        return data;
    }).fail(function (error) {
        console.warn("Can't get node path from server");
        return null;
    });
}

function parseFragmentTreeNode(node) {
    if (!node || typeof node !== "object" || !node.r) {
        console.warn("Invalid tree node:", node);
        return null;
    }
    return {
        id: node.r,
        rdfId: node.r,
        name: node.l || "?",
        isLeaf: false
    };
}

function parseFragmentTreeData(data) {
    const parsedNodes = [];
    const nodeList = data.l;
    if (!Array.isArray(nodeList)) {
        return parsedNodes;
    }
    for (let node of nodeList) {
        const parsedNode = parseFragmentTreeNode(node);
        if (parsedNode === null) {
            continue;
        }
        parsedNodes.push(parsedNode);
    }
    return parsedNodes;
}

function composeFragmentTreeUrl(fragmentRdfId) {
    let nodeUrl = FRAGMENT_TREE;
    if (!fragmentRdfId) {
        return nodeUrl;
    }
    return nodeUrl + "/" + fragmentRdfId;
}

export function ajaxFetchFragmentTree(fragmentRdfId, dispatch) {
    dispatch(waitForFragmentTree(fragmentRdfId));
    return $.getJSON(composeFragmentTreeUrl(fragmentRdfId), function (data) {
        dispatch(fragmentTreeDataReceived(fragmentRdfId, parseFragmentTreeData(data)));
    }).fail(function (error) {
        console.warn("Can't get tree data from server");
        dispatch(fragmentTreeErrorReceived(fragmentRdfId, error));
    });
}

///////////
//Actions//
///////////
export function fetchFragmentInitialNode(fragmentRdfId) {
    return async function (dispatch, getState) {
        let fragmentPath;
        if (!fragmentRdfId) {
            fragmentPath = [];
        } else {
            fragmentPath = await ajaxFetchFragmentPath(fragmentRdfId, dispatch);
            if (!fragmentPath) {
                return;
            }
        }
        fragmentPath.unshift(null);
        for (let nodeId of fragmentPath) {
            ajaxFetchFragmentTree(nodeId, dispatch)
        }
    }
}

export function fetchFragmentChildren(fragmentRdfId) {
    return async function (dispatch, getState) {
        const fragmentTreeState = getState()[Action.OBJECTCARD].fragmentTree;
        if (!fragmentRdfId) {
            if (fragmentTreeState.roots.length === -1) {
                ajaxFetchFragmentTree(null, dispatch);
            }
            return;
        }
        if (typeof fragmentTreeState.childrenById[fragmentRdfId] === "undefined"
            && (!fragmentTreeState.nodeById[fragmentRdfId] || !fragmentTreeState.nodeById[fragmentRdfId].loading)) {
            ajaxFetchFragmentTree(fragmentRdfId, dispatch);
        }
    }
}

export function addNewSubject(store, subject, notifyId) {
    return subjectReceived(store, Action.SUBJECT_OPERATION_CREATE, subject, notifyId);
}

export function createNewSubject(store, className, parent, parentRef, prototype, notifyId, initialData) {
    return function (dispatch, getState) {
        ajaxCreateNewSubject(store, className, parent, parentRef, prototype, notifyId, initialData, dispatch);
    }
}

export function fetchSubject(store, rdfId, namespace, force) {
    return function (dispatch, getState) {
        if (force || checkSubjectFetchNeeded(store, getState(), rdfId, namespace)) {
            ajaxFetchSubject(store, rdfId, namespace, force, dispatch);
        }
    }
}

export function lockSubject(store) {
    return function (dispatch, getState) {
        if (getRdfId(store, getState()) && checkLockStatus(store, getState(), false)) { //object is ready and lock is false
            ajaxLockSubject(dispatch, store, getRdfId(store, getState()), getNamespace(store, getState()), true);
        }
    }
}

export function unlockSubject(store) {
    return function (dispatch, getState) {
        if (getRdfId(store, getState()) && checkLockStatus(store, getState(), true)) { //object is ready and lock is true
            ajaxLockSubject(dispatch, store, getRdfId(store, getState()), getNamespace(store, getState()), false);
            dispatch(cancelRPASetpointsUpdates());
        }
    }
}

export function saveSubject(store, deferred) {
    return function (dispatch, getState) {
        if (deferred) {
            const card = getState()[Action.OBJECTCARD];
            //Make deep copy of data
            const subject = mergeSubjectValues(card.data[store], card.values[store]);
            deferred.resolve(subject);
        } else if (checkSavePossible(store, getState())) {
            let card = getState()[Action.OBJECTCARD];
            const layout = card.layoutCache[card.data[store].$class];
            runPreSave(dispatch, layout, (changedPredicates) => {
                card = getState()[Action.OBJECTCARD];
                //Get notify id
                const notifyId = card.data[store].$notifyId;
                //Make deep copy of data
                const subject = mergeSubjectValues(card.data[store], Object.assign({}, card.values[store], changedPredicates));
                ajaxSaveSubject(dispatch, store, subject, notifyId);
            });
        } else {
            console.error("Cannot save. Save state must be with ready uploads, lock required or subject must be new: ", getLockStatus(store, getState()));
        }
    }
}

function subjectChangeData(payload) {
    return {
        type: Action.SUBJECT_CHANGE_DATA, payload,
    }
}

export function change(store, nodeId, data, options) {
    return function (dispatch, getState) {
        const card = getState()[Action.OBJECTCARD];
        const valuesDiff = { [nodeId]: data };
        const invalidFormat = (options && options.invalidFormat) ? { [nodeId]: options.invalidFormat } : null;
        //Dispatch difference
        const layout = card.layoutCache[card.data[store].$class];
        dispatch(subjectChangeData(makeDiff(store, layout, valuesDiff, makeState(card, store), invalidFormat)));
    }
}

function linkSubject(store, nodeId, valuesDiff, getState) {
    const card = getState()[Action.OBJECTCARD];
    const layout = card.layoutCache[card.data[store].$class];
    return subjectChangeData(makeDiff(store, layout, valuesDiff, makeState(card, store)));
}

function refreshLinkSubject(linkSubject) {
    return {
        $namespace: linkSubject.$namespace,
        $rdfId: linkSubject.$rdfId,
        $label: linkSubject.$label,
        $description: linkSubject.$description
    };
}

export function link(store, nodeId, data) {
    return function (dispatch, getState) {
        const card = getState()[Action.OBJECTCARD];
        const values = card.values[store] || {}; //Get current values
        const layout = card.layoutCache[card.data[store].$class];
        const multiple = layout.byId[nodeId] && layout.byId[nodeId].multiple ? true : false;
        const valuesDiff = {};
        if (multiple) {
            if ($.type(values[nodeId]) != 'array') {
                valuesDiff[nodeId] = [data];
            } else {
                valuesDiff[nodeId] = values[nodeId].concat(data);
            }
        } else {
            valuesDiff[nodeId] = data;
        }
        const funcId = layout.automation.linkBindings[nodeId];
        if (funcId) {
            //We have link action to do so we need to fetch subject from server
            $.get(composeSubjectUrl(data.$rdfId, data.$namespace), function (subject) {
                //Refresh subject information with label and description
                if (multiple) {
                    valuesDiff[nodeId].pop();
                    valuesDiff[nodeId].push(refreshLinkSubject(subject));
                } else {
                    valuesDiff[nodeId] = refreshLinkSubject(subject);
                }
                const nextValues = Object.assign({}, card.values[store], valuesDiff);
                const form = makeForm(nextValues, card.data[store]);
                const func = retrieveFunction(funcId);
                try {
                    const result = func(form, subject);
                    //Check if we need to fill other predicates
                    if (result && $.type(result) == 'object') {
                        for (let predicateNodeId of layout.predicateIds) {
                            const d = obtainData(predicateNodeId, result);
                            if (d) {
                                valuesDiff[predicateNodeId] = d;
                            }
                        }
                    }
                    dispatch(linkSubject(store, nodeId, valuesDiff, getState));
                } catch (ex) {
                    console.log("Link binding error", nodeId, ex);
                }
            }).fail(function (error) {
                if (error.status == 403) {
                    dispatch(addAlert(ALERT_DANGER, { id: "MSG_STATUS_FORBIDDEN" }));
                } else {
                    dispatch(addAlert(ALERT_DANGER, { id: "OBJECTCARD_LINK_FAILED" }));
                }
            });
        } else {
            //Dispatch difference immediately
            dispatch(linkSubject(store, nodeId, valuesDiff, getState));
        }
    }
}

export function add(store, nodeId, data) {
    return function (dispatch, getState) {
        const card = getState()[Action.OBJECTCARD];
        const values = card.values[store] || {}; //Get current values
        const valuesDiff = {};
        if ($.type(values[nodeId]) != 'array') {
            valuesDiff[nodeId] = [data];
        } else {
            valuesDiff[nodeId] = values[nodeId].concat(data);
        }
        //Dispatch difference
        const layout = card.layoutCache[card.data[store].$class];
        dispatch(subjectChangeData(makeDiff(store, layout, valuesDiff, makeState(card, store))));
    }
}

export function remove(store, nodeId, indexList) {
    return function (dispatch, getState) {
        const card = getState()[Action.OBJECTCARD];
        const values = card.values[store] || {}; //Get current values
        const valuesDiff = {};
        const removeMap = {};
        if ($.type(indexList) == 'array') { //index list
            for (let idx of indexList) {
                removeMap[idx] = true;
            }
        } else { //one index
            removeMap[indexList] = true;
        }
        console.log("remove", nodeId, indexList);
        if ($.type(values[nodeId]) == 'array') {
            valuesDiff[nodeId] = values[nodeId].filter((_, currentIndex) => !removeMap[currentIndex]);
        }
        //Dispatch difference
        const layout = card.layoutCache[card.data[store].$class];
        dispatch(subjectChangeData(makeDiff(store, layout, valuesDiff, makeState(card, store))));
    }
}

export function changeAt(store, nodeId, index, data) {
    return function (dispatch, getState) {
        const card = getState()[Action.OBJECTCARD];
        const values = card.values[store] || {}; //Get current values
        const valuesDiff = {};
        console.log("changeAt", nodeId, index, data);
        if ($.type(values[nodeId]) == 'array') {
            valuesDiff[nodeId] = values[nodeId].map((x, currentIndex) => {
                if (index != currentIndex) {
                    return x;
                }
                return data;
            });
        } else {
            console.error("changeAt is not an array", values[nodeId]);
        }
        //Dispatch difference
        const layout = card.layoutCache[card.data[store].$class];
        dispatch(subjectChangeData(makeDiff(store, layout, valuesDiff, makeState(card, store))));
    }
}

export function registerUpload(store, nodeId, files, multiple) {
    return function (dispatch, getState) {
        //Prepare data for redux
        let data = null;
        if (multiple) {
            data = [];
        }
        console.log(files);
        for (let file of files) {
            //Add data to upload queue
            const key = enqueUpload(file);
            //Create redux data
            let actionData = {
                $uploadKey: key,
                $tmpName: file.name,
                $label: file.name,
                $description: "",
                $contentType: file.type,
                $contentSize: file.size
            }
            if (multiple) {
                data.push(actionData);
            } else {
                data = actionData;
            }
        }
        //Send data to redux
        const card = getState()[Action.OBJECTCARD];
        const values = card.values[store] || {}; //Get current values
        const valuesDiff = {};
        if ($.type(data) == 'array') {//Special case for multiple uploads
            if ($.type(values[nodeId]) == 'array') {
                valuesDiff[nodeId] = values[nodeId].concat(data);
            } else {
                valuesDiff[nodeId] = data;
            }
        } else {
            valuesDiff[nodeId] = data;
        }
        //Dispatch difference
        const layout = card.layoutCache[card.data[store].$class];
        dispatch(subjectChangeData(makeDiff(store, layout, valuesDiff, makeState(card, store))));
    }
}

export function patchUpload(store, nodeId, path, sha1) {
    return function (dispatch, getState) {
        const card = getState()[Action.OBJECTCARD];
        const values = card.values[store] || {}; //Get current values
        const valuesDiff = {};
        if (path.length == 0) {
            //Shallow copy
            valuesDiff[nodeId] = Object.assign({}, values[nodeId], { $sha1: sha1 });
            delete valuesDiff[nodeId].$uploadKey;
        } else {
            //Make deep copy
            const current = values[nodeId];
            let obj;
            if ($.type(current) == 'array') {
                obj = [];
                for (let f of current) {
                    obj.push($.extend(true, {}, f));
                }
            } else {
                obj = $.extend(true, {}, current);
            }
            valuesDiff[nodeId] = obj;
            for (let p of path) {
                obj = obj[p];
            }
            obj.$sha1 = sha1;
            delete obj.$uploadKey;
        }
        //Dispatch difference
        const layout = card.layoutCache[card.data[store].$class];
        dispatch(subjectChangeData(makeDiff(store, layout, valuesDiff, makeState(card, store))));
    }
}

export function initializeStore(store, subject, layoutClass) {
    return function (dispatch, getState) {
        const cachedLayout = getState()[Action.OBJECTCARD].layoutCache[subject.$class]; //Check if layout is ready
        const layout = cachedLayout ? cachedLayout : makeLayout(layoutClass);
        //Dispatch open popup
        dispatch(doSubjectReceived(getState(), store, Action.SUBJECT_OPERATION_INIT, subject, layout, null));
        //Fetch layout if needed
        if (!layoutClass && !cachedLayout && checkLayoutFetchNeeded(getState(), subject.$class)) {
            ajaxFetchLayout(subject.$class, dispatch, getState);
        }
    }
}

export function destroyStore(store) {
    return {
        type: Action.SUBJECT_DESTROY_STORE,
        payload: { store }
    }
}

export function startSave(store) {
    const event = {
        formId: store
    };
    $(document).trigger(Action.EVENT_FLUSH_DEBOUNCE, event);
    console.log("Debounce ready!");
    return {
        type: Action.START_SAVE,
        payload: { store }
    }
}

export function cancelSave(store) {
    return {
        type: Action.CANCEL_SAVE,
        payload: { store }
    }
}

export function changeSaveState(store, saveState) {
    return {
        type: Action.CHANGE_SAVE_STATE,
        payload: { store, saveState }
    }
}

export function click(store, buttonId) {
    return function (dispatch, getState) {
        const card = getState()[Action.OBJECTCARD];
        const values = card.values[store];
        const data = card.data[store];
        const layout = card.layoutCache[data.$class];
        const form = makeForm(values, data, dispatch, store);
        const funcId = layout.automation.clickBindings[buttonId];
        const func = retrieveFunction(funcId);
        try {
            func(form);
        } catch (ex) {
            console.log("Click binding error", buttonId, ex);
        }
    }
}

export function reportClick(store, buttonId, href, params) {
    return function (dispatch, getState) {
        const card = getState()[Action.OBJECTCARD];
        const values = card.values[store];
        const data = card.data[store];
        const layout = card.layoutCache[data.$class];
        const funcId = layout.automation.clickBindings[buttonId];
        if (funcId) {
            const form = makeForm(values, data, dispatch, store);
            const func = retrieveFunction(funcId);
            try {
                const overrideParams = func(form, { filename: params.filename });
                params.filename = overrideParams.filename || params.filename;
            } catch (ex) {
                console.log("Report click binding error", buttonId, ex);
            }
        }
        href += "?" + $.param(params);
        downloadFile(href, params.filename);
    }
}


export function uploadFiles(store, handleUploadProgress) {
    return function (dispatch, getState) {
        const card = getState()[Action.OBJECTCARD];
        const values = card.values[store];
        const uploadTasks = getUploadTaskList(values);
        if (uploadTasks.length > 0) { //With uploads
            console.log("Upload tasks:", uploadTasks);
            ajaxUploadFiles(uploadTasks, (task, data) => dispatch(patchUpload(store, task.nodeId, task.path, data.sha1)), handleUploadProgress).then(function () { //uploads done
                clearUploadTasks(uploadTasks);
                dispatch(changeSaveState(store, Action.SAVE_STATE_UPLOADS_READY));
            }, function (error) { //Uploads error
                const saveState = getState()[Action.OBJECTCARD].saveState[store];
                if (saveState) { //If save was in progress then show error
                    dispatch(addAlert(ALERT_DANGER, { id: "OBJECTCARD_UPLOAD_FAILED" }));
                    dispatch(cancelSave(store));
                } else {
                    console.log("Upload was aborted!!!");
                }
            });
        } else { //Without uploads
            dispatch(changeSaveState(store, Action.SAVE_STATE_UPLOADS_READY));
        }
    }
}

export function listenerPredicateChanged(predicateId, value) {
    return function (dispatch, getState) {
        $(window).trigger("objectcard.predicate.update", predicateId, value);
    }
}

export function changeTab(store, tabId, navId) {
    return {
        type: Action.CHANGE_TAB,
        payload: { store, tabId, navId }
    }
}

function getObjectRef(globalState, namespace, rdfId) {
    const contextPath = globalState[LOCATION].contextPath;
    let link = `${contextPath}objectcard/`;
    if (namespace) {
        link += `${namespace}/`;
    }
    link += rdfId;
    return <a href={link}><i className="fa fa-eye" aria-hidden="true"></i></a>
}

function composeValidateLinkUrl(relatedClass, reference) {
    const link = `${VALIDATE_LINK_URL}/${relatedClass.replace(":", "/")}`;
    let search = "?";
    if (reference.$namespace) {
        search += `namespace=${reference.$namespace}&`;
    }
    search += `rdfId=${reference.$rdfId}`;
    return `${link}${search}`;
}

async function ajaxValidateLink(contextPath, relatedClass, reference) {
    return $.get(composeValidateLinkUrl(relatedClass, reference), function (data) {
        return data;
    }).fail(function (error) {
        return null;
    });
}

async function validateLinks(contextPath, relatedClass, references) {
    const validLinks = [];
    const promises = [];
    for (let reference of references) {
        promises.push(ajaxValidateLink(contextPath, relatedClass, reference));
    }
    await Promise.all(promises).then(values => {
        for (let value of values) {
            if (value == null) {
                continue;
            }
            validLinks.push(value);
        }
    });
    return validLinks;
}

function filterReferences(references, data) {
    if (!Array.isArray(data)) {
        return references;
    }
    return references.filter((reference) => {
        if (!reference.$rdfId) {
            return false;
        }
        for (let row of data) {
            if (row.$rdfId == reference.$rdfId) {
                return false;
            }
        }
        return true;
    });
}

export function pasteRefs(store, nodeId, relatedClass, isMultiple, isFromHistory, references) {
    return async function (dispatch, getState) {
        const card = getState()[Action.OBJECTCARD];
        const contextPath = getState()[LOCATION].contextPath;
        const data = obtainData(nodeId, card.data[store]);
        if (!references && isFromHistory) {
            dispatch(addAlert(ALERT_WARNING, { id: "OBJECTCARD_HISTORY_EMPTY" }));
            return;
        }

        const validLinks = await validateLinks(contextPath, relatedClass, filterReferences(references, data));
        if (!validLinks || !validLinks.length) {
            dispatch(addAlert(ALERT_WARNING, { id: "OBJECTCARD_VALIDATION_EMPTY" }));
            return;
        }

        /**Traverse history timestamp values to valid links array */
        if (isFromHistory) {
            const timestampMap = {};
            for (let ref of references) {
                timestampMap[ref.$rdfId] = ref.timestamp;
            }
            for (let row of validLinks) {
                row.timestamp = moment(timestampMap[row.rdfId]).format("L LTS");
            }
        }

        /**Add counter values */
        for (let i = 0; i < validLinks.length; ++i) {
            validLinks[i].count = i + 1;
        }

        const tableStyle = {
            margin: "1px 0px",
            borderRadius: "0px",
            border: "none"
        };
        const containerStyle = {
            overflowX: 'auto'
        };
        let selectRow = {
            mode: isMultiple ? "checkbox" : "radio",
            clickToSelect: true,
            selected: []
        }
        const onSelect = (row, isSelected) => {
            if (!isMultiple) {
                selectRow.selected = [row.rdfId];
                return;
            }
            if (isSelected) {
                selectRow.selected.push(row.rdfId);
                return;
            }
            selectRow.selected = selectRow.selected.filter(key => key != row.rdfId);
        }
        const onSelectAll = (isSelected) => {
            selectRow.selected = [];
            if (!isSelected) {
                return;
            }
            for (let row of validLinks) {
                selectRow.selected.push(row.rdfId);
            }
        }
        selectRow.onSelect = onSelect;
        selectRow.onSelectAll = onSelectAll;
        if (!isMultiple) {
            selectRow.selected.push(validLinks[0].rdfId);
        } else if (!isFromHistory) {
            /**Pre-select all rows if user paste them from clipboard */
            for (let row of validLinks) {
                selectRow.selected.push(row.rdfId);
            }
        }
        let options = {
            title: { id: "OBJECTCARD_CONFIRM_REFERENCES_PASTE" },
            body: (<BootstrapTable
                version='4'
                scrollTop={'Top'}
                tableStyle={tableStyle}
                containerStyle={containerStyle}
                selectRow={selectRow}
                data={validLinks}
                className="npt-table-bootstrap">
                <TableHeaderColumn
                    isKey={true}
                    dataField="rdfId"
                    hidden={true}>
                </TableHeaderColumn>
                <TableHeaderColumn
                    width={50}
                    dataField="count">
                    <FormattedMessage id="OBJECTCARD_REFTABLE_NUMBER" />
                </TableHeaderColumn>
                {isFromHistory && <TableHeaderColumn
                    width={160}
                    dataField="timestamp">
                    <FormattedMessage
                        id="OBJECTCARD_REFTABLE_HISTORY_TIMESTAMP"
                        defaultMessage="View timestamp"
                        description="Timestamp of objectcard viewing" />
                </TableHeaderColumn>}
                <TableHeaderColumn
                    width={150}
                    dataField="label">
                    <FormattedMessage id="OBJECTCARD_REFTABLE_NAME" />
                </TableHeaderColumn>
                <TableHeaderColumn
                    width={170}
                    dataField="description">
                    <FormattedMessage id="OBJECTCARD_REFTABLE_DESCRIPTION" />
                </TableHeaderColumn>
            </BootstrapTable>)
        }
        dispatch(openModal("confirmPopup", "confirm", options, () => {
            const selectedLinks = validLinks.filter(row => selectRow.selected.indexOf(row.rdfId) != -1);
            if (selectedLinks.length == 0) {
                return;
            }
            for (let linkData of selectedLinks) {
                dispatch(link(store, nodeId, { $rdfId: linkData.rdfId, $label: linkData.label, $description: linkData.description }));
            }
        }));
    }
}

export function checkRef(relatedClass, reference, callback) {
    return async function (dispatch, getState) {
        const contextPath = getState()[LOCATION].contextPath;
        ajaxValidateLink(contextPath, relatedClass, reference).then((result) => {
            callback(Boolean(result));
        }, (error) => {
            callback(false);
        });
    }
}

async function ajaxGetFilterLink(filter, cp) {
    if (typeof filter != "string") {
        filter = JSON.stringify(filter);
    }
    return $.ajax({
        url: BASE_TABLE_FILTER_URL,
        method: "POST",
        dataType: 'text',
        processData: false,
        data: filter,
        headers: { 'Content-Type': 'application/json' }
    }).done(function (filterLink) {
        return parseStringResponse(filterLink);
    }).fail(function (error) {
        return null;
    });
}

export function linkClick(link, filter) {
    return async function (dispatch, getState) {
        if (!filter) {
            window.open(link);
            return;
        }
        let filterSha1;
        try {
            filterSha1 = await ajaxGetFilterLink(filter, getState()[LOCATION].contextPath);
        } catch (ex) {
            console.error(ex);
            dispatch(addAlert(ALERT_DANGER, { id: "OBJECTCARD_UPLOAD_FILTER_FAILED" }));
            return;
        }
        window.open(`${link}#/?_filter=${filterSha1}`);
    }
}

export function downloadFile(href, filename) {
    /* Link attribute "download" works only in this two browsers */
    let isChromium = navigator.userAgent.toLowerCase().indexOf('chrome') != -1 || navigator.userAgent.toLowerCase().indexOf('safari') != -1;
    let composedLink = href;
    if (isChromium && (typeof getHashData().params.debug === "undefined")) {
        var link = $(`<a href="${composedLink}" download="${filename || ''}" ></a > `);
        link[0].click();
        return;
    }

    // Force file download (whether supported by server).
    if (composedLink.indexOf("?") > 0) {
        composedLink = composedLink.replace("?", "?download&")
    } else {
        composedLink += "?download";
    }
    if (filename) {
        composedLink += "&_filename=" + filename;
    }
    window.open(composedLink);
}

export function exportCard(rdfId, namespace, type) {
    return function (dispatch, getState) {
        const contextPath = getState()[LOCATION].contextPath;
        let href = `${contextPath}${SUBJECT_EXPORT_URL}`;
        if (namespace) {
            if (typeof namespace == "object") {
                namespace = namespace.$prefix;
            }
            href += `/${namespace}`;
        }
        href += `/${rdfId}?type=${type}`;
        downloadFile(href);
    }
}