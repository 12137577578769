import React from 'react';
import Basic from './basic.jsx';
import DebounceInput from '../../debounceinput.jsx';
import { EVENT_FLUSH_DEBOUNCE, UI_FORMAT, UI_FLOAT } from '../../../constants/objectcard.js';
import debounce from '../../../services/debounce';
import TextArea from './textarea.jsx';
import { printFloat } from '../../../services/number.js';

class Text extends Basic {

    constructor(props) {
        super(props);
        this.debounceFactory = this.debounceFactory.bind(this);
        this.flushDebounce = this.flushDebounce.bind(this);
        this.change = this.change.bind(this);
        this.signalInvalidFormat = this.signalInvalidFormat.bind(this);
    }

    flushDebounce(event, info) {
        if (this.props.formId == info.formId && this.debounce) {
            console.log("Flush debounce: ", this.props.nodeId);
            this.debounce.flush();
        }
    }

    componentDidMount() {
        $(document).bind(EVENT_FLUSH_DEBOUNCE, this.flushDebounce);
    }

    componentWillUnmount() {
        $(document).unbind(EVENT_FLUSH_DEBOUNCE, this.flushDebounce);
    }

    debounceFactory(handleChange) {
        console.log("Create from debounce factory!");
        this.debounce = debounce(handleChange, 200); //200 ms by default
        return this.debounce;
    }

    change(value) {
        const id = this.props.node.id;
        const transform = this.props.node.options.transform; //May be set from options
        if (transform) { //for server side transformation
            this.props.change({ $value: value, $transform: transform });
        } else {
            this.props.change(value);
        }
    }

    signalInvalidFormat(error) {
        this.props.change({}, { invalidFormat: { id: error } });
    }

    getInput() {
        let value = this.props.value;
        if (this.props.node.ui == UI_FLOAT) {
            if (typeof this.props.node.options.accuracy == "number") {
                const accuracy = parseInt(this.props.node.options.accuracy);
                const parsedValue = parseFloat(value).toFixed(accuracy);
                if (!isNaN(parsedValue)) {
                    value = parsedValue;
                }
            } else if (value && value.toString().search(/[eE]/) != -1) {
                value = printFloat(value);
            }
        }
        return (<DebounceInput
            editable={this.props.editable}
            valid={this.props.valid}
            value={value}
            locale={this.props.locale}
            className="form-control card-input"
            password={this.props.node.options && this.props.node.options.password}
            options={this.props.node.options}
            format={UI_FORMAT[this.props.node.ui]}
            debounceFactory={this.debounceFactory}
            change={this.change}
            signalInvalidFormat={this.signalInvalidFormat} />);
    }

    render() {
        if (this.props.node.options.multiline) {
            return <TextArea {...this.props} />;
        }
        return this.wrapInput(this.props.node.label, this.getInput(), this.props.node.description);
    }
}

export default Text;