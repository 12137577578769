import * as Action from '../constants/crontasks.js';

const initialState = {
    scriptsMap: {},
    data: [],
    waitingForData: false,
    menu: {
        selectedRows: []
    },
    configurePanel: {
        called: false
    },
    confirmDialog: {
        called: false,
        ok: null,
        cancel: null,
        error: false
    }
};

function parseCronString(cron, callback) {
    let cronElements = cron.split(" ");
    let second = Action.TIME_UNITS.second[0];
    let minute = Action.TIME_UNITS.minute[0];
    let hour = Action.TIME_UNITS.hour[0];
    let day = Action.TIME_UNITS.day[0];
    let month = Action.TIME_UNITS.month[0];
    let weekDay = Action.TIME_UNITS.weekDay[0];
    for (let i = 0; i < Action.TIME_UNITS.second.length; ++i) {
        if (Action.TIME_UNITS.second[i].originalValue == cronElements[0]) {
            second = Action.TIME_UNITS.second[i];
            break;
        }
    }
    for (let i = 0; i < Action.TIME_UNITS.minute.length; ++i) {
        if (Action.TIME_UNITS.minute[i].originalValue == cronElements[1]) {
            minute = Action.TIME_UNITS.minute[i];
            break;
        }
    }
    for (let i = 0; i < Action.TIME_UNITS.hour.length; ++i) {
        if (Action.TIME_UNITS.hour[i].originalValue == cronElements[2]) {
            hour = Action.TIME_UNITS.hour[i];
            break;
        }
    }
    for (let i = 0; i < Action.TIME_UNITS.day.length; ++i) {
        if (Action.TIME_UNITS.day[i].originalValue == cronElements[3]) {
            day = Action.TIME_UNITS.day[i];
            break;
        }
    }
    for (let i = 0; i < Action.TIME_UNITS.month.length; ++i) {
        if (Action.TIME_UNITS.month[i].originalValue == cronElements[4]) {
            month = Action.TIME_UNITS.month[i];
            break;
        }
    }
    for (let i = 0; i < Action.TIME_UNITS.weekDay.length; ++i) {
        if (Action.TIME_UNITS.weekDay[i].originalValue == cronElements[5]) {
            weekDay = Action.TIME_UNITS.weekDay[i];
            break;
        }
    }
    callback({ second, minute, hour, day, month, weekDay });
}

function parseDataToLocal(data) {
    for (let i = 0; i < data.length; ++i) {
        parseCronString(data[i].cron, function ({ second, minute, hour, day, month, weekDay }) {
            data[i] = Object.assign(data[i], { second, minute, hour, day, month, weekDay });
        })
    }
    return data;
}

function updateData(state, data) {
    for (let i = 0; i < data.length; ++i) {
        if (typeof state.scriptsMap[data[i].id] == "undefined") {
            state.scriptsMap[data[i].id] = state.data.length;
            data[i].tableIndex = state.data.length + 1;
            data[i].key = data[i].tableIndex;
            state.data.push(data[i]);
        } else {
            state.data[state.scriptsMap[data[i].id]] = Object.assign({}, state.data[state.scriptsMap[data[i].id]], data[i]);
        }
    }
}

function waitForData(state) {
    return Object.assign({}, state, { waitingForData: true })
}

function receiveData(state, { data }) {
    let newState = Object.assign({}, state, { waitingForData: false });
    data = parseDataToLocal(data);
    newState.scriptsMap = {};
    newState.data = [];
    newState.menu = { selectedRows: [] };
    updateData(newState, data);
    return newState;
}

function receiveDataError(state, { error }) {
    console.error("Can't recieve data", error);
    return Object.assign({}, state, { waitingForData: false })
}

function saveScriptError(state, { error }) {
    console.error("Can't save element", error);
    let newState = Object.assign({}, state);
    newState.configurePanel = Object.assign({}, newState.configurePanel, { error: true });
    return newState;
}

function selectRow(state, { data, selected }) {
    let newState = Object.assign({}, state);
    newState.menu = Object.assign({}, newState.menu);
    newState.menu.selectedRows = [];
    for(let row of data){
        if(selected[row.key]){
            newState.menu.selectedRows.push(row);
        }
    }
    return newState;
}

function callConfigurePanel(state, { task }) {
    let newState = Object.assign({}, state);
    newState.configurePanel = {
        called: true,
        error: false,
        task
    };
    return newState;
}

function closeConfigurePanel(state) {
    let newState = Object.assign({}, state);
    newState.configurePanel = {
        called: false
    };
    return newState;
}

function callConfirmDialog(state, { ok, cancel }) {
    let newState = Object.assign({}, state);
    newState.confirmDialog = {
        called: true,
        ok,
        cancel
    }
    return newState;
}

function closeConfirmDialog(state) {
    let newState = Object.assign({}, state);
    newState.confirmDialog = {
        called: false
    }
    return newState;
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.WAIT_FOR_DATA: return waitForData(state, action.payload);
        case Action.RECEIVE_DATA: return receiveData(state, action.payload);
        case Action.RECEIVE_DATA_ERROR: return receiveDataError(state, action.payload);
        case Action.SAVE_SCRIPT_ERROR: return saveScriptError(state, action.payload);
        case Action.SELECT_ROW: return selectRow(state, action.payload);
        case Action.CALL_CONFIGURE_PANEL: return callConfigurePanel(state, action.payload);
        case Action.CLOSE_CONFIGURE_PANEL: return closeConfigurePanel(state, action.payload);
        case Action.CALL_CONFIRM_DIALOG: return callConfirmDialog(state, action.payload);
        case Action.CLOSE_CONFIRM_DIALOG: return closeConfirmDialog(state, action.payload);
        default: return state;
    }
}