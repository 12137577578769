import { SIGNALS, SETPOINTS, COMMON_VENDORS, VOLTAGE_LEVEL, BAY_LEVEL, VENDOR_LEVEL, FUNCTION_LEVEL, SHARED_SETPOINTS_IDS } from "../constants/rpasetpoints";

/**
 * RPA setpoints parser
 */

/**********************
 *   Format parsers   *
 **********************/
function parseCommonSetpoint(setpoint) {
    return {
        id: setpoint.id,
        linkedId: setpoint.idFromReferenceFile,
        dimension: setpoint.dimension,
        isEnum: setpoint.isLogical,
        primary: setpoint.primary,
        name: setpoint.nameFromReferenceFile,
        rawValue: setpoint.rawValue,
        value: setpoint.value
    };
}

function isNumberEnum(enumeration) {
    if (!Array.isArray(enumeration) || enumeration.length == 0) {
        return;
    }
    for (let value of enumeration) {
        /* Test is any of enumeration values isn't in format "number - description" */
        if (isNaN(Number(value.split(/[-–=]/)[0]))) {
            return false;
        }
    }
    return true;
}

function tryGetNumberRange(enumeration) {
    const rangeEnumeration = [];
    for (let value of enumeration) {
        if (value.search(/[-–]/) == -1) {
            return enumeration;
        }
        /* Test is any of enumeration values isn't in format "number - number" */
        const parts = value.split(/[-–]/);
        if (parts.length != 2) {
            return enumeration;
        }
        let minValue = Number(parts[0]);
        let maxValue = Number(parts[1]);
        if (isNaN(minValue) || isNaN(maxValue)) {
            return enumeration;
        }
        for (let i = minValue; i <= maxValue; ++i) {
            rangeEnumeration.push(i.toString());
        }
    }
    return rangeEnumeration;
}

function parseToLocalSetpoint(node, parentId) {
    let comparedId = node.fullLinkedId;
    if (Array.isArray(comparedId)) {
        comparedId = comparedId[0];
        comparedId = comparedId.substring(0, comparedId.length - node.linkedId.length - 1);
        comparedId = comparedId.substring(0, comparedId.lastIndexOf("_"));
        comparedId += "_shared";
        comparedId += "_" + node.linkedId;
    }
    let parsedSetpoint = {
        id: parentId + "_" + node.linkedId,
        originalId: node.id,
        linkedId: node.linkedId,
        comparedId: comparedId,
        name: node.name,
        isEnum: node.isEnum,
        value: node.value != "null" ? node.value : getValueFromRawValue(node.rawValue),
        rawValue: node.rawValue,
        enumeration: node.enumeration || (node.isEnum ? getEnumerationFromRawValue(node.rawValue) : null),
        dimension: node.dimension,
        primary: node.primary
    }
    if (parsedSetpoint.enumeration) {
        parsedSetpoint.isNumber = isNumberEnum(parsedSetpoint.enumeration);
        if (parsedSetpoint.isNumber) {
            parsedSetpoint.enumeration = tryGetNumberRange(parsedSetpoint.enumeration);
        }
    }
    return parsedSetpoint;
}

function parseToServerSetpoint(node) {
    return {
        id: node.originalId,
        linkedId: node.linkedId,
        fullLinkedId: node.nodeIdList || node.comparedId,
        name: node.name,
        isEnum: node.isEnum,
        isNumber: node.isNumber,
        value: node.value,
        rawValue: node.rawValue,
        enumeration: node.enumeration,
        dimension: node.dimension,
        primary: node.primary
    }
}

function parseToLocalSignal(signal) {
    return {
        linkedId: signal.linkedId,
        comparedId: signal.fullLinkedId,
        ccbm: signal.ccbm,
        chId: signal.chId,
        channelMultiplier: signal.channelMultiplier,
        channelOffset: signal.channelOffset,
        iecChannelId: signal.iecChannelId,
        index: signal.index,
        max: signal.max,
        min: signal.min,
        ph: signal.ph,
        primary: signal.primary,
        ps: signal.ps,
        secondary: signal.secondary,
        skew: signal.skew,
        unit: signal.unit
    };
}

function parseToServerSignal(signal) {
    return {
        linkedId: signal.linkedId,
        fullLinkedId: signal.comparedId,
        ccbm: signal.ccbm,
        chId: signal.chId,
        channelMultiplier: signal.channelMultiplier,
        channelOffset: signal.channelOffset,
        iecChannelId: signal.iecChannelId,
        index: signal.index,
        max: signal.max,
        min: signal.min,
        ph: signal.ph,
        primary: signal.primary,
        ps: signal.ps,
        secondary: signal.secondary,
        skew: signal.skew,
        unit: signal.unit
    };
}

/**************************************
 *   Getters for complicated values   *
 **************************************/
/* Parse file data vendor to get folder name */
function getVendorName(entry) {
    for (let vendorId in COMMON_VENDORS) {
        if (entry.vendor.indexOf(vendorId) == 0) {
            return COMMON_VENDORS[vendorId]
        }
    }
    return entry.vendor.split(" ")[0].split("_")[0];
}

/* Parse file data bay type to get folder name */
function getBayType(entry) {
    return entry.bayType;
}

function getId(entry) {
    return entry.voltageLevel + "_" + entry.vendor + "_" + entry.bayType + "_" + entry.name;
}

function getKeyWords(entry) {
    return entry.vendor.replace(/_/g, " ").split(" ");
}

function getEnumByDivider(enumString, divider) {
    const enumList = [];
    const splittedEnumString = enumString.split(divider);
    for (let value of splittedEnumString) {
        enumList.push(value.trim());
    }
    return enumList;
}

/* Parse string and found bracers that placed in ending of string */
function getBracersPosition(rawValue) {
    let startPosition = rawValue.indexOf(" (");
    if (startPosition == -1 || rawValue[rawValue.length] != ")") {
        return -1;
    }
    const bracerOpens = [startPosition + 1];
    let currentPosition = startPosition + 2;
    for (; currentPosition < rawValue.length - 1; ++currentPosition) {
        if (rawValue[currentPosition] == "(") {
            bracerOpens.push(currentPosition);
        } else if (rawValue[currentPosition] == ")") {
            bracerOpens.pop();
        }
    }
    if (bracerOpens.length == 1) {
        return bracerOpens[0];
    }
    return -1;
}

function getEnumStringFromRawValue(rawValue) {
    const newRowPosition = rawValue.indexOf("\n");
    const bracersPosition = getBracersPosition(rawValue);
    if (newRowPosition == -1 && bracersPosition == -1) {
        return null;
    }
    let dividerPosition = -1;
    if (newRowPosition == -1) {
        dividerPosition = bracersPosition;
    } else if (bracersPosition == -1) {
        dividerPosition = newRowPosition;
    } else {
        dividerPosition = newRowPosition < bracersPosition ? newRowPosition : bracersPosition;
    }
    let enumString = rawValue.substring(dividerPosition + 1);
    if (enumString[0] == "(" && enumString[enumString.length - 1] == ")") {
        enumString = enumString.substring(1, enumString.length - 1);
    }
    return enumString;
}

function trimEnumValues(enumeration, dividers) {
    let dividersRegExp = '[';
    for (let divider of dividers) {
        dividersRegExp += divider;
    }
    dividersRegExp += ']';
    const regExp = new RegExp(`^${dividersRegExp}+|${dividersRegExp}+$`, "g");
    for (let i = 0; i < enumeration.length; ++i) {
        enumeration[i] = enumeration[i].replace(regExp, "");
    }
}

export function getEnumerationFromRawValue(rawValue) {
    if (!rawValue && typeof rawValue != "string") {
        return null;
    }
    /* Trim enum values */
    rawValue = getEnumByDivider(rawValue, "\n").join("\n");
    const enumString = getEnumStringFromRawValue(rawValue);
    if (enumString == null) {
        return null;
    }
    const dividers = ["\n", ";", ",", "/"];
    let enumeration = null;
    for (let divider of dividers) {
        enumeration = getEnumByDivider(enumString, divider);
        if (enumeration.length != 1) {
            trimEnumValues(enumeration, dividers);
            break;
        }
    }
    return enumeration;
}

export function getValueFromRawValue(rawValue) {
    if (!rawValue) {
        return "null";
    }
    const floatValue = parseFloat(rawValue.replace(/,/, "."));
    if (isNaN(floatValue)) {
        /** Infinity (english) */
        if (rawValue.startsWith("oo")) {
            return "oo";
        }
        /** Infinity (russian) */
        if (rawValue.startsWith("оо")) {
            return "оо";
        }
        return rawValue;
    }
    return floatValue.toString();
}

/*********************************
 *   Common Utility parsers   *
 *********************************/
function isFolder(node) {
    return Boolean(node.folder);
}

/*********************************
 *   Setpoints Utility parsers   *
 *********************************/
function parseCommonSetpointsEntry(entry) {
    if (!entry.setpointsFromReferenceFile) {
        return null;
    }
    let setpoints = [];
    for (let setpoint of entry.setpointsFromReferenceFile) {
        setpoints.push(parseCommonSetpoint(setpoint));
    }
    return {
        id: getId(entry),
        name: entry.name,
        fileName: entry.fileName,
        bayType: entry.bayType,
        vendor: entry.vendor,
        setpoints: setpoints,
        keyWords: getKeyWords(entry)
    };
}

function addSetpoints(funciton, setpoints) {
    for (let setpoint of setpoints) {
        funciton.push(setpoint);
    }
}

function addEntryToVendor(vendor, entry) {
    if (!vendor[entry.name]) {
        vendor[entry.name] = {
            size: 0,
            fileNames: {}
        };
    }
    if (!vendor[entry.name].fileNames[entry.fileName]) {
        vendor[entry.name].fileNames[entry.fileName] = [];
        vendor[entry.name].size++;
    }
    addSetpoints(vendor[entry.name].fileNames[entry.fileName], entry.setpoints);
    if (vendor[entry.name].fileNames[entry.fileName].length == 0) {
        delete (vendor[entry.name].fileNames[entry.fileName]);
        vendor[entry.name].size--;
        if (vendor[entry.name].size == 0) {
            delete (vendor[entry.name]);
        }
    }
}

function addVendorToBay(bay, entry) {
    if (!entry) {
        return;
    }
    let vendor = getVendorName(entry);
    if (!bay[vendor]) {
        bay[vendor] = {};
    }
    addEntryToVendor(bay[vendor], entry);
}

function prepareVendor(vendor) {
    let preparedVendor = {};
    for (let nodeName in vendor) {
        for (let fileName in vendor[nodeName].fileNames) {
            if (vendor[nodeName].size == 1) {
                preparedVendor[nodeName] = vendor[nodeName].fileNames[fileName];
                break;
            }
            preparedVendor[nodeName + ` (${fileName})`] = vendor[nodeName].fileNames[fileName];
        }
    }
    return preparedVendor;
}

/* Parse to tree structure */
function parseCommonSetpointsToTree(voltages) {
    const rootNodes = [];
    for (let voltageId in voltages) {
        let voltage = {
            id: voltageId,
            name: voltageId,
            level: VOLTAGE_LEVEL,
            folder: true,
            children: []
        }
        rootNodes.push(voltage);
        for (let bayType in voltages[voltageId]) {
            let bay = {
                id: voltage.id + "_" + bayType,
                name: bayType,
                level: BAY_LEVEL,
                folder: true,
                children: []
            }
            voltage.children.push(bay);
            for (let vendorName in voltages[voltageId][bayType]) {
                const linkedIdMap = {};
                let sharedFolder = null;
                let vendor = {
                    id: bay.id + "_" + vendorName,
                    name: vendorName,
                    level: VENDOR_LEVEL,
                    folder: true,
                    children: []
                }
                bay.children.push(vendor);
                voltages[voltageId][bayType][vendorName] = prepareVendor(voltages[voltageId][bayType][vendorName]);
                for (let nodeName in voltages[voltageId][bayType][vendorName]) {
                    let node = {
                        id: vendor.id + "_" + nodeName,
                        name: nodeName,
                        level: FUNCTION_LEVEL,
                        folder: true,
                        children: []
                    }
                    vendor.children.push(node);
                    for (let child of voltages[voltageId][bayType][vendorName][nodeName]) {
                        const setpoint = parseToLocalSetpoint(child, node.id);
                        if (!linkedIdMap[setpoint.linkedId]) {
                            linkedIdMap[setpoint.linkedId] = [];
                        }
                        linkedIdMap[setpoint.linkedId].push(setpoint);
                        node.children.push(setpoint);
                    }
                }
                /* Check if vendor have shared setpoints */
                for (let linkedId in linkedIdMap) {
                    if (linkedIdMap[linkedId].length <= 1 || !SHARED_SETPOINTS_IDS[linkedId]) {
                        continue;
                    }
                    if (sharedFolder == null) {
                        sharedFolder = {
                            id: vendor.id + "_shared",
                            name: "shared",
                            formattedName: "RPA_SETPOINTS_IS_SHARED",
                            folder: true,
                            children: []
                        }
                        vendor.children.unshift(sharedFolder);
                    }
                    const setpoint = Object.assign({
                        isShared: true,
                        nodeIdList: []
                    }, linkedIdMap[linkedId][0]);
                    setpoint.id = sharedFolder.id + "_" + setpoint.linkedId;
                    for (let node of linkedIdMap[linkedId]) {
                        node.visible = false;
                        setpoint.nodeIdList.push(node.id);
                    }
                    sharedFolder.children.push(setpoint);
                }
            }
        }
    }
    return rootNodes;
}

function parseServerNode(node, parentId, nodeType) {
    let name = node.name || node.chId;
    let parsedNode = {
        id: parentId ? parentId + "_" + name : name,
        name: name,
        folder: Boolean(node.nodes || node.children)
    };
    if (!parsedNode.folder) {
        let nodeId = node.id ? parentId + "_" + node.id : parsedNode.id;
        return Object.assign(
            parsedNode,
            nodeType == SETPOINTS ? parseToLocalSetpoint(node) : parseToLocalSignal(node),
            { linkedId: node.linkedId || null, id: nodeId }
        );
    }
    parsedNode.children = [];
    if (node.children) {
        for (let child of node.children) {
            parsedNode.children.push(parseServerNode(child, parsedNode.id, nodeType));
        }
    }
    if (node.nodes) {
        for (let child of node.nodes) {
            parsedNode.children.push(parseServerNode(child, parsedNode.id, nodeType));
        }
    }
    return parsedNode;
}

/* Parse local tree to functions objects */
function parseToServerFunctions(setpoints, nodeType) {
    let functions = [];
    for (let setpoint of setpoints) {
        if (!isFolder(setpoint)) {
            functions.push(nodeType == SETPOINTS ? parseToServerSetpoint(setpoint) : parseToServerSignal(setpoint));
            continue;
        }
        let functionObj = {
            name: setpoint.name
        };
        functions.push(functionObj);
        if (!setpoint.children || setpoint.children.length == 0) {
            continue;
        }
        functionObj.children = parseToServerFunctions(setpoint.children, nodeType);
    }
    return functions;
}

/* Parse from tree structure */
function parseFromTree(tree, parentId) {
    let nodes = [];
    let folderNodes = typeof parentId == "undefined" ? tree.rootNodesIds : tree.children[parentId];
    for (let nodeId of folderNodes) {
        let node = Object.assign({}, tree.nodeById[nodeId]);
        if (tree.children[nodeId]) {
            node.children = parseFromTree(tree, nodeId);
        }
        nodes.push(node);
    }
    return nodes;
}

function getLinkedParentNameList(setpoint) {
    let fullLinkedIdList;
    if (Array.isArray(setpoint.fullLinkedId)) {
        fullLinkedIdList = setpoint.fullLinkedId;
    } else {
        fullLinkedIdList = [setpoint.fullLinkedId];
    }
    const linkedParentNameMap = {};
    const parentNameList = [];
    for (let linkedId of fullLinkedIdList) {
        const linkedIdParts = linkedId.replace(/\(.*\)/g, "").split("_");
        const parentName = linkedIdParts[linkedIdParts.length - 2].trim();
        if (linkedParentNameMap[parentName]) {
            continue;
        }
        linkedParentNameMap[parentName] = true;
        parentNameList.push(parentName);
    }
    return parentNameList;
}

function addSetpointToMap(setpoint, treeMap) {
    const parentNameList = getLinkedParentNameList(setpoint);
    for (let parentName of parentNameList) {
        if (!treeMap[parentName]) {
            treeMap[parentName] = [];
        }
        treeMap[parentName].push(setpoint);
    }
}

function recursiveGetLinkedSetpointsMap(childrenSetpoints, parsedTree = {}) {
    if (!childrenSetpoints || !childrenSetpoints.length) {
        return parsedTree;
    }
    for (let node of childrenSetpoints) {
        if (node.children) {
            parsedTree = recursiveGetLinkedSetpointsMap(node.children, parsedTree);
            continue;
        }
        if (!node.linkedId) {
            continue;
        }
        addSetpointToMap(node, parsedTree);
    }
    return parsedTree;
}

function parseLinkedSetpointToServer(setpoint) {
    let value = setpoint.value;
    if (setpoint.isEnum && setpoint.isNumber) {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            value = parsedValue.toString();
        }
    }
    return {
        linkedId: setpoint.linkedId,
        name: setpoint.name,
        dimension: setpoint.dimension,
        value: value
    };
}

/* Parse tree to get linked setpoints with functions */
function parseLinkedSetpoints(setpointsTree, commonSetpoints) {
    const linkedSetpointsMap = recursiveGetLinkedSetpointsMap(setpointsTree);

    if (linkedSetpointsMap.shared) {
        for (let sharedSetpoint of linkedSetpointsMap.shared) {
            const linkedSetpoint = commonSetpoints.nodeById[sharedSetpoint.fullLinkedId];
            if(!linkedSetpoint){
                continue;
            }
            for (let nodeId of linkedSetpoint.nodeIdList) {
                const setpoint = Object.assign({}, sharedSetpoint, { fullLinkedId: nodeId });
                addSetpointToMap(setpoint, linkedSetpointsMap);
            }
        }
        delete (linkedSetpointsMap.shared);
    }

    const linkedSetpointsTree = [];
    for (let functionName in linkedSetpointsMap) {
        const functionNode = {
            name: functionName,
            children: []
        }
        for (let setpoint of linkedSetpointsMap[functionName]) {
            functionNode.children.push(parseLinkedSetpointToServer(setpoint));
        }
        linkedSetpointsTree.push(functionNode);
    }
    return linkedSetpointsTree;
}

/*******************************
 *   Signals Utility parsers   *
 *******************************/
function parseUploadSignals(signals) {
    let rootNodes = [];
    for (let signal of signals) {
        rootNodes.push(parseSignalsNode(signal));
    }
    return rootNodes;
}

function parseSignalsNode(signal) {
    let parsedNode = {
        id: signal.chId,
        name: signal.chId,
        folder: false
    };
    return Object.assign(parsedNode, parseToLocalSignal(signal));
}

/**********************
 *   Main parsers   *
 **********************/
export function parseCommonSetpoints(fileData) {
    if (!fileData.functions || !fileData.functions.length) {
        return [];
    }
    let voltages = {};
    let bayTypesMap = {};
    for (let entry of fileData.functions) {
        if (!voltages[entry.voltageLevel]) {
            voltages[entry.voltageLevel] = {};
        }
        let bayType = getBayType(entry);
        if (!bayTypesMap[bayType]) {
            bayTypesMap[bayType] = true;
        }
        if (!voltages[entry.voltageLevel][bayType]) {
            voltages[entry.voltageLevel][bayType] = {};
        }
        addVendorToBay(voltages[entry.voltageLevel][bayType], parseCommonSetpointsEntry(entry));
    }
    let bayTypes = [];
    for (let bayType in bayTypesMap) {
        bayTypes.push(bayType);
    }
    return { commonSetpoints: parseCommonSetpointsToTree(voltages), bayTypes: bayTypes };
}

export function parseCommonSignals(fileData) {
    let signals = {
        analog: [],
        discrete: []
    };
    for (let node of fileData.analog) {
        signals.analog.push(parseServerNode(node, null, SIGNALS));
    }
    for (let node of fileData.discrete) {
        signals.discrete.push(parseServerNode(node, null, SIGNALS));
    }
    return signals;
}

export function parseToLocalSetpoints(fileData) {
    if (!fileData.functions || !fileData.functions.length) {
        return [];
    }
    let rootNodes = [];
    for (let node of fileData.functions) {
        rootNodes.push(parseServerNode(node, null, SETPOINTS));
    }
    return rootNodes;
}

export function parseToLocalSignals(fileData) {
    let signals = {
        analog: parseUploadSignals(fileData.analogChannels),
        discrete: parseUploadSignals(fileData.statusChannels)
    }
    return signals;
}

export function parseToServerData({ commonSetpoints, setpoints, analog, discrete, bayType }) {
    let serverFile = {
        analog: parseToServerFunctions(parseFromTree(analog), SIGNALS),
        discrete: parseToServerFunctions(parseFromTree(discrete), SIGNALS),
        setpoints: parseToServerFunctions(parseFromTree(setpoints), SETPOINTS),
        bayType: bayType || null
    }
    serverFile.linkedSetpoints = parseLinkedSetpoints(serverFile.setpoints, commonSetpoints);
    return serverFile;
}

export function parseFromServerData({ setpoints, analog, discrete, bayType }) {
    let parsed = {
        setpoints: [],
        analog: [],
        discrete: [],
        bayType: bayType || null
    }
    for (let node of setpoints) {
        parsed.setpoints.push(parseServerNode(node, null, SETPOINTS));
    }
    for (let node of analog) {
        parsed.analog.push(parseServerNode(node, null, SIGNALS));
    }
    for (let node of discrete) {
        parsed.discrete.push(parseServerNode(node, null, SIGNALS));
    }
    return parsed;
}

function checkVoltage(voltageRange, voltage) {
    if (!voltage) {
        return true;
    }
    const minAcceptableVoltage = parseFloat(voltageRange.substring(0, voltageRange.indexOf("-")));
    const maxAcceptableVoltage = parseFloat(voltageRange.substring(voltageRange.indexOf("-") + 1));

    const minVoltage = voltage.min || voltage;
    const maxVoltage = voltage.max || voltage;

    if (maxVoltage < minAcceptableVoltage || minVoltage > maxAcceptableVoltage) {
        return false;
    }
    return true;
}

function checkVendor(vendor) {
    if (!vendor) {
        return false;
    }
    return Boolean(COMMON_VENDORS[vendor]);
}

function checkBayType(bayType) {
    return Boolean(bayType);
}

function modifyCommonSetpointsByVoltage(commonSetpoints, voltage) {
    const rootNodesIds = [];
    for (let nodeId of commonSetpoints.rootNodesIds) {
        commonSetpoints.nodeById[nodeId] = Object.assign({}, commonSetpoints.nodeById[nodeId]);
        const node = commonSetpoints.nodeById[nodeId];
        if (!checkVoltage(node.name, voltage)) {
            continue;
        }
        for (let childId of commonSetpoints.children[nodeId]) {
            commonSetpoints.nodeById[childId] = Object.assign({}, commonSetpoints.nodeById[childId]);
            const childNode = commonSetpoints.nodeById[childId];
            if (node.name != "6-750 кВ") {
                childNode.name += ` ${node.name}`;
            }
            rootNodesIds.push(childId);
        }
    }
    commonSetpoints.rootNodesIds = rootNodesIds;
}

function modifyCommonSetpointsByVendor(commonSetpoints, vendor, parentId = null) {
    const parentChildren = parentId == null ? commonSetpoints.rootNodesIds : commonSetpoints.children[parentId];
    for (let i = parentChildren.length - 1; i >= 0; --i) {
        const nodeId = parentChildren[i];
        const node = commonSetpoints.nodeById[nodeId];
        if (node.level < BAY_LEVEL) {
            modifyCommonSetpointsByVendor(commonSetpoints, vendor, nodeId);
            continue;
        }
        let nodeChildren = [];
        for (let childId of commonSetpoints.children[nodeId]) {
            const functionNode = commonSetpoints.nodeById[childId];
            if (functionNode.name == COMMON_VENDORS[vendor]) {
                nodeChildren = commonSetpoints.children[childId].slice();
            }
        }
        if (nodeChildren.length == 0) {
            parentChildren.splice(i, 1);
            continue;
        }
        commonSetpoints.children[nodeId] = nodeChildren;
    }
}

function modifyCommonSetpointsByBayType(commonSetpoints, bayType) {
    let rootNodesIds = [];
    for (let i = 0; i < commonSetpoints.rootNodesIds.length; ++i) {
        const nodeId = commonSetpoints.rootNodesIds[i];
        const bayTypeNode = commonSetpoints.nodeById[nodeId];
        if (bayTypeNode.name.indexOf(bayType) != -1) {
            rootNodesIds.push(nodeId);
        }
    }
    if (rootNodesIds.length == 1) {
        rootNodesIds = commonSetpoints.children[rootNodesIds[0]];
    }
    commonSetpoints.rootNodesIds = rootNodesIds;
}

function modifyCommonSignalsByBayType(commonSignals, nodeId, bayType) {
    const checkNodesIds = nodeId ? commonSignals.children[nodeId] : commonSignals.rootNodesIds;
    let childrenIds = [];
    for (let i = 0; i < checkNodesIds.length; ++i) {
        const nodeId = checkNodesIds[i];
        const node = commonSignals.nodeById[nodeId];
        if (node.name.indexOf(bayType) != -1 || node.name == "Общие сигналы") {
            childrenIds.push(nodeId);
        }
    }
    if (childrenIds.length == 1) {
        childrenIds = commonSignals.children[childrenIds[0]];
    }
    if (nodeId == null) {
        commonSignals.rootNodesIds = childrenIds;
    } else {
        commonSignals.children[nodeId] = childrenIds;
    }
}

export function modifyCommonSetpointsView(commonSetpoints, vendor, voltage, bayType) {
    commonSetpoints.rootNodesIds = commonSetpoints.rootNodesIds.slice();
    commonSetpoints.children = Object.assign({}, commonSetpoints.children);
    commonSetpoints.nodeById = Object.assign({}, commonSetpoints.nodeById);
    modifyCommonSetpointsByVoltage(commonSetpoints, voltage);
    if (checkVendor(vendor)) {
        modifyCommonSetpointsByVendor(commonSetpoints, vendor);
    }
    if (checkBayType(bayType)) {
        modifyCommonSetpointsByBayType(commonSetpoints, bayType);
    }
}

export function modifyCommonSignalsView(commonAnalogSignals, commonDiscreteSignals, bayType) {
    commonAnalogSignals.rootNodesIds = commonAnalogSignals.rootNodesIds.slice();
    commonDiscreteSignals.rootNodesIds = commonDiscreteSignals.rootNodesIds.slice();
    commonAnalogSignals.children = Object.assign({}, commonAnalogSignals.children);
    commonDiscreteSignals.children = Object.assign({}, commonDiscreteSignals.children);
    if (checkBayType(bayType)) {
        modifyCommonSignalsByBayType(commonAnalogSignals, "Ток", bayType);
        modifyCommonSignalsByBayType(commonAnalogSignals, "Напряжение", bayType);
        modifyCommonSignalsByBayType(commonDiscreteSignals, null, bayType);
    }
}