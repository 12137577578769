import initializeSecurity from './security.jsx';
import initializei18n from './i18n.jsx';
import initializeLocation from './location.jsx';
import initializeModals from './modals.jsx';
import initializeServerConfig from './serverconfig.jsx';
import initializeDivider from './divider.jsx';
import initializeTabs from './tabs.jsx';
import initializeFiller from './filler.jsx';
import initializeNavTree from './navtree.jsx';
import initializeSrcTree from './srctree.jsx';
import initializeTreeSearch from './treesearch.jsx';
import initializeObjectCard from './objectcard.jsx';
import initializeSrcEditor from './srceditor.jsx';
import initializeTable from './table.jsx';
import initializeHistory from './history.jsx';
import initializeCronTasks from './crontasks.jsx';
import initializeEventTasks from './eventtasks.jsx';
import initializeFileDrop from './filedrop.jsx';
import initializeClassCard from './classcard.jsx';
import initializeCache from './cache.jsx';
import initializeLogin from './login.jsx';
import initializeRPASetpoints from './rpasetpoints.jsx';
import initializeBreadcrumbs from './treebreadcrumbs.jsx';
import initializePlayer from './player.jsx';
import initializeCimMap from './cimmap.jsx';
import initializePlugin from './plugin.jsx';


import FillerComponent from './components/filler/cimfiller.jsx';
import DividerComponent from './components/divider/divider.jsx';
import DebounceComponent from './components/debounceinput.jsx';
import NPTTreeComponent from './components/tree/tree.jsx';
import NPTTableComponent from './components/table/table.jsx';
import NPTTableToolbarComponent from './components/table/toolbar.jsx';
import NPTFileDropComponent from './components/filedrop/filedrop.jsx';
import { ObjectCard as NPTObjectcardComponent } from './objectcard.jsx';
import * as LocationActions from './actions/location';
import * as AlertActions from './actions/alert';
import * as ModalActions from './actions/modal';
import * as NPTTreeActions from './actions/npt-treebeard';
import * as NPTFileDropActions from './actions/filedrop';
import * as I18NConstants from './constants/i18n';
import * as LocationConstants from './constants/location';
import * as AlertConstants from './constants/alert';
import * as ServerConfigConstants from './constants/serverconfig';
import * as NPTTreeReducers from './reducers/npt-treebeard';

if (typeof NPTPlatform != 'undefined' && typeof NPTPlatform.dispatcher != 'undefined') {
    const dispatcher = NPTPlatform.dispatcher;
    initializeSecurity(dispatcher);
    initializei18n(dispatcher);
    initializeLocation(dispatcher);
    initializeModals(dispatcher);
    initializeServerConfig(dispatcher);
    initializeFiller(dispatcher);
    initializeDivider(dispatcher);
    initializeTabs(dispatcher);

    initializeNavTree(dispatcher);
    initializeTreeSearch(dispatcher);
    initializeObjectCard(dispatcher);
    initializeTable(dispatcher)
    initializeHistory(dispatcher);
    initializeLogin(dispatcher);
    initializeRPASetpoints(dispatcher);
    initializeBreadcrumbs(dispatcher);
    initializePlayer(dispatcher);

    /* TODO: move to admin */
    initializeSrcTree(dispatcher);
    initializeSrcEditor(dispatcher);
    initializeCronTasks(dispatcher);
    initializeEventTasks(dispatcher);
    initializeFileDrop(dispatcher);
    initializeClassCard(dispatcher);
    initializeCache(dispatcher);

    initializeCimMap(dispatcher);

    initializePlugin(dispatcher);
}

export {
    /**Constants*/
    I18NConstants,
    LocationConstants,
    AlertConstants,
    ServerConfigConstants,
    /**Actions*/
    LocationActions,
    AlertActions,
    ModalActions,
    NPTTreeActions,
    NPTFileDropActions,
    /**Components*/
    FillerComponent,
    DividerComponent,
    DebounceComponent,
    NPTTreeComponent,
    NPTTableToolbarComponent,
    NPTTableComponent,
    NPTFileDropComponent,
    NPTObjectcardComponent,
    /**Reducers*/
    NPTTreeReducers
};