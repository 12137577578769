import { FormattedMessage } from 'react-intl';

//Type constants
export const TYPE = {
    STRING: 0,
    NUMBER: 1,
    BOOLEAN: 2,
    FILE: 3,
    FRAGMENT: 4,
    REFERENCE: 5,
    TABLE: 6,
    ENUMERATION: 7,
    DATE: 8
}

//View type constants
export const VIEW_TYPE = {
    EDIT: "edit",
    ADD: "add"
}

//style consts
export const removeButtonStyle = { border: 'none', background: 'none', outline: 'none' }
export const orRemoveButtonStyle = { border: 'none', background: 'none', outline: 'none', marginRight: '14px' }
export const orCriteriaTitle = { marginBottom: '15px' }
export const disabledSelect = { background: '#c4c5c6', color: '#515356' }
export const buttonsStyle = { marginLeft: '5px' }
export const childOverflow = { overflowY: 'hidden', height: '100%' }
export const toggleButtonStyle = {
    cursor: "pointer",
    position: "absolute",
    top: "5px",
    right: "8px"
}
export const paddingTop = { paddingTop: '15px' }
export const marginTop = { marginTop: '15px' }
export const grayColor = { backgroundColor: '#ebe6e3' }

export const MSG_CONFIRM_REMOVE_CRITERION = (
    <FormattedMessage
        id="MSG_CONFIRM_REMOVE_CRITERION"
        defaultMessage="Do you really want to remove criterion"
        description="Do you really want to remove criterion" />)

export const MSG_CONFIRM_REMOVE_RELATION = (
    <FormattedMessage
        id="MSG_CONFIRM_REMOVE_RELATION"
        defaultMessage="Do you really want to remove relation"
        description="Do you really want to remove relation" />)

export const FINDER_SELECTION_AREA = (
    <FormattedMessage
        id="FINDER_SELECTION_AREA"
        defaultMessage="Selection area"
        description="Selection area" />)

export const FINDER_SELECTION_CRITERIA = (
    <FormattedMessage
        id="FINDER_SELECTION_CRITERIA"
        defaultMessage="Selection criteria"
        description="Selection criteria" />)

//Type lists
export const numberRelationTypeList = [
    {
        value: "equal",
        label: <FormattedMessage
            id="FINDER_NUMBER_EQUAL"
            defaultMessage="Equal"
            description="Equal" />
    }, {
        value: "notEqual",
        label: <FormattedMessage
            id="FINDER_NUMBER_NOT_EQUAL"
            defaultMessage="Not equal"
            description="Not equal" />
    }, {
        value: "moreOrEqual",
        label: <FormattedMessage
            id="FINDER_NUMBER_MORE_OR_EQUAL"
            defaultMessage="More or equal"
            description="More or equal" />
    }, {
        value: "lessOrEqual",
        label: <FormattedMessage
            id="FINDER_NUMBER_LESS_OR_EQUAL"
            defaultMessage="Less or equal"
            description="Less or equal" />
    }, {
        value: "more",
        label: <FormattedMessage
            id="FINDER_NUMBER_MORE"
            defaultMessage="More"
            description="More" />
    }, {
        value: "less",
        label: <FormattedMessage
            id="FINDER_NUMBER_LESS"
            defaultMessage="Less"
            description="Less" />
    }, {
        value: "between",
        label: <FormattedMessage
            id="FINDER_NUMBER_BETWEEN"
            defaultMessage="Between"
            description="Between" />
    }]

export const dateRelationTypeList = [
    {
        value: "equal",
        label: <FormattedMessage
            id="FINDER_DATE_EQUAL"
            defaultMessage="On the date"
            description="On the date" />
    }, {
        value: "notEqual",
        label: <FormattedMessage
            id="FINDER_DATE_NOT_EQUAL"
            defaultMessage="Not on the date"
            description="Not on the date" />
    }, {
        value: "moreOrEqual",
        label: <FormattedMessage
            id="FINDER_DATE_MORE_OR_EQUAL"
            defaultMessage="On the date or after"
            description="On the date or after" />
    }, {
        value: "lessOrEqual",
        label: <FormattedMessage
            id="FINDER_DATE_LESS_OR_EQUAL"
            defaultMessage="On the date or before"
            description="On the date or before" />
    }, {
        value: "more",
        label: <FormattedMessage
            id="FINDER_DATE_MORE"
            defaultMessage="After the date"
            description="After the date" />
    }, {
        value: "less",
        label: <FormattedMessage
            id="FINDER_DATE_LESS"
            defaultMessage="Before the date"
            description="Before the date" />
    }, {
        value: "between",
        label: <FormattedMessage
            id="FINDER_DATE_BETWEEN"
            defaultMessage="Between the dates"
            description="Between the dates" />
    }]

export const stringRelationTypeList = [
    {
        value: "contains",
        label: <FormattedMessage
            id="FINDER_CONTAINS"
            defaultMessage="Contains"
            description="Contains" />
    }, {
        value: "notContains",
        label: <FormattedMessage
            id="FINDER_NOT_CONTAINS"
            defaultMessage="Not contains"
            description="Not contains" />
    }, {
        value: "equalIgnoreCase",
        label: <FormattedMessage
            id="FINDER_EQUAL_IGNORE_CASE"
            defaultMessage="Equal (ignore case)"
            description="Equal (ignore case)" />
    }, {
        value: "notEqualIgnoreCase",
        label: <FormattedMessage
            id="FINDER_NOT_EQUAL_IGNORE_CASE"
            defaultMessage="Not equal (ignore case)"
            description="Not equal (ignore case)" />
    }]

export const fileRelationTypeList = [
    {
        value: "attached",
        label: <FormattedMessage
            id="FINDER_FILE_ATTACHED"
            defaultMessage="Attached"
            description="Attached" />
    },
    {
        value: "notAttached",
        label: <FormattedMessage
            id="FINDER_FILE_NOT_ATTACHED"
            defaultMessage="Not attached"
            description="Not attached" />
    }]

export var isFileRelation = {};
for (let relation of fileRelationTypeList) {
    isFileRelation[relation.value] = true;
}

export const fragmentRelationTypeList = [
    {
        value: "equal",
        label: <FormattedMessage
            id="FINDER_EQUAL_FRAGMENT_ID"
            defaultMessage="Fragment id"
            description="Fragment id" />
    }]

export const referenceRelationTypeList = [
    {
        value: "equal",
        label: <FormattedMessage
            id="FINDER_EQUAL_RDF_ID"
            defaultMessage="Rdf id"
            description="Rdf id" />
    }]

export const enumerationRelationTypeList = [
    {
        value: "equal",
        label: <FormattedMessage
            id="FINDER_ENUMERATION_EQUAL"
            defaultMessage="Equal to selected"
            description="Equal to selected" />
    }, {
        value: "notEqual",
        label: <FormattedMessage
            id="FINDER_ENUMERATION_NOT_EQUAL"
            defaultMessage="Not equal to selected"
            description="Not equal to selected" />
    }]

export const booleanRelationTypeList = [
    {
        value: "equal",
        label: <FormattedMessage
            id="FINDER_EQUAL_BOOLEAN"
            defaultMessage="Value"
            description="Boolean value" />
    }]

export const booleanRelationValueList = [
    {
        value: "true",
        label: <FormattedMessage
            id="FINDER_TRUE"
            defaultMessage="True"
            description="True" />
    }, {
        value: "false",
        label: <FormattedMessage
            id="FINDER_FALSE"
            defaultMessage="False"
            description="False" />
    }]

//////////////////////
//Formatted messages//
//////////////////////
export const FINDER_REFERENCE_PASTE_WRONG_DATA = <FormattedMessage
    id="FINDER_REFERENCE_PASTE_WRONG_DATA"
    defaultMessage="Clipboard data have wrong format"
    description="Clipboard data have wrong format" />

export const FINDER_DOWNLOAD_SUBJECT_HEADER_ERROR = <FormattedMessage
    id="FINDER_DOWNLOAD_SUBJECT_HEADER_ERROR"
    defaultMessage="Error occured while downloading subject header"
    description="Error occured while downloading subject header" />

export const FINDER_DOWNLOAD_SUBJECT_HEADER_ACCESS_DENIED = <FormattedMessage
    id="FINDER_DOWNLOAD_SUBJECT_HEADER_ACCESS_DENIED"
    defaultMessage="User has insufficient rights to get subject header"
    description="User has insufficient rights to get subject header" />
