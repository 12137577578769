import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TOOLBAR_STYLE } from './styles.jsx';
import InlineEdit from 'react-edit-inplace';
import { openHistory } from '../../services/srceditor';

class Toolbar extends React.Component {

    constructor(props) {
        super(props);

        if (props.lock) {
            this.locked = props.lock.status;
        } else {
            this.locked = false;
        }

        this.clipboard = $("<div class='clipboard clipboard-container'></div>")

        this.edit = this.edit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.history = this.history.bind(this);
        this.changeMetainfo = this.changeMetainfo.bind(this);
        this.copyScript = this.copyScript.bind(this);
        this.descriptionChanged = this.descriptionChanged.bind(this);
    }

    edit() {
        this.props.editCurrentCode();
    }

    cancel() {
        this.props.cancelEditCurrentCode();
    }

    save() {
        this.props.saveCode();
    }

    changeMetainfo() {
        this.props.changeMetainfo(this.props.entity);
    }

    history() {
        openHistory(this.props.editorId, this.props.entity.path, this.props.openModal);
    }

    copyScript() {
        this.clipboard.attr("data-clipboard-text", this.props.script);
        $("body").append(this.clipboard);
        this.clipboard.trigger("click");
        this.clipboard.remove();
    }

    descriptionChanged(data) {
        this.props.changeDescription(data.description);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.lock != nextProps.lock) {
            this.locked = nextProps.lock.status;
        }
    }

    render() {
        let editButton = (<button type="button" className="btn btn-secondary" onClick={this.edit}>
            <i className="fa fa-pencil fa-fw" aria-hidden="true"></i>
            <FormattedMessage
                id="SOURCEEDITOR_EDIT_OBJECT"
                defaultMessage="Edit"
                description="User should click the button to edit card" />
        </button>);
        if (this.props.edit) {
            editButton = (<button type="button" className="btn btn-secondary" onClick={this.cancel}>
                <i className="fa fa-ban fa-fw" aria-hidden="true"></i>
                <FormattedMessage
                    id="SOURCEEDITOR_CANCEL_EDIT_OBJECT"
                    defaultMessage="Cancel"
                    description="User should click the button to cancel object changes" />
            </button>);
        }
        return (
            <div className="row" style={TOOLBAR_STYLE}>
                <div className="col-md-12">
                    <div className="pull-left" style={{ maxWidth: "50%" }}><h4><b>{this.props.entity.description}</b></h4></div>
                    <div className="btn-toolbar pull-right" role="toolbar" aria-label="...">
                        <div className="btn-group btn-group-xs" role="group" aria-label="...">
                            {editButton}
                            <button type="button" className="btn btn-secondary" onClick={this.save} disabled={!this.locked}>
                                <i className="fa fa-upload fa-fw" aria-hidden="true"></i>
                                <FormattedMessage
                                    id="SOURCEEDITOR_SAVE_OBJECT"
                                    defaultMessage="Save"
                                    description="User should click the button to save changes" />
                            </button>
                        </div>
                        <div className="btn-group btn-group-xs" role="group" aria-label="...">
                            <button type="button" className="btn btn-secondary" onClick={this.copyScript}>
                                <i className="fa fa-copy fa-fw" aria-hidden="true"></i>
                                <FormattedMessage
                                    id="SOURCEEDITOR_COPY_TEXT"
                                    defaultMessage="Copy to clipboard"
                                    description="User should click the button to get script text" />
                            </button>
                        </div>
                        <div className="btn-group btn-group-xs" role="group" aria-label="...">
                            <button type="button" className="btn btn-secondary" onClick={this.changeMetainfo}>
                                <i className="fa fa-cog fa-fw" aria-hidden="true"></i>
                                <FormattedMessage
                                    id="SOURCEEDITOR_EDIT_METAINFO"
                                    defaultMessage="Edit properties"
                                    description="User should click the button to edit metainfo of script" />
                            </button>
                        </div>
                        <div className="btn-group btn-group-xs" role="group" aria-label="...">
                            <button type="button" className="btn btn-secondary" onClick={this.history}>
                                <i className="fa fa-history fa-fw" aria-hidden="true"></i>
                                <FormattedMessage
                                    id="SOURCEEDITOR_OBJECT_HISTORY"
                                    defaultMessage="History"
                                    description="User should click the button to get object history" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

export default Toolbar;


