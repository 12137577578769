import { NAVTREE, STATUS_NOT_LOADED, TOGGLE_SUBTREE } from '../constants/navtree';
import {
    fetchInfo,
    fetchSubTree,
    expandNode,
    toggleSubTree,
    toggleFilter,
    addChild,
    deleteNode,
    moveChild,
    copyNodeRef,
    copyNodeWithDescendantsRefs
} from '../actions/navtree';
import { LOCATION } from '../constants/location';
import { SECURITY } from '../constants/security';
import { connect } from 'react-redux';
import { openModal } from '../actions/modal';
import { changeHash, getHashData } from '../actions/location';
import { initialTreeState } from '../reducers/npt-treebeard';
import shortid from 'shortid';

////////////////////////////////////////
//Common code for reducers and actions//
////////////////////////////////////////
const initialNavTreeState = Object.assign({
    nodeIdByRdfId: {},
    infoLoaded: false,
    loading: true,
    ready: false,
    error: null
}, initialTreeState);

export function selectObject(node) {
    if (node.data && node.data.$rdfId) {
        let ref = { params: { object: node.data.$rdfId, select: null, node_type: node.typeId } };
        if (node.data.$namespace) {
            ref.params.namespace = node.data.$namespace;
        }
        changeHash(ref);
    }
}

/* Check if tree have filter and node satisfies requirements */
function checkFilter(node, treeHeader, enabledFilters) {
    if (!treeHeader || !treeHeader.filter || !node.cache || !node.cache[treeHeader.filter.key]) {
        return true;
    }
    if ($.isArray(node.cache[treeHeader.filter.key])) {
        for (let value of node.cache[treeHeader.filter.key]) {
            if (enabledFilters[value]) {
                return true;
            }
        }
    } else if (enabledFilters[node.cache[treeHeader.filter.key]]) {
        return true;
    }
    return false;
}

const connectNode = function (treeId, propsSelectObject, isModal) {
    return connect(function (_, initialProps) { //mapStateToProps
        const { id } = initialProps;
        return (globalState) => {
            const treeState = safeGetTree(getNavTreeState(globalState), treeId);
            const node = treeState.nodeById[id];
            return Object.assign({}, node, {
                toggled: treeState.toggled[id],
                children: node.leaf ? null : treeState.children[id],
                active: treeState.active == id,
                directory: !node.leaf,
                visible: node.visible == false ? false : checkFilter(node, treeState.header, treeState.enabledFilters)
            });
        }
    }, (_, initialProps) => { //mapDispachToProps
        const { id } = initialProps;
        return (dispatch) => {
            return {
                onToggle: function ({ toggled, active }) {
                    if (!active) {
                        if (typeof propsSelectObject == "function") {
                            propsSelectObject(this);
                        }
                        if (!isModal) {
                            selectObject(this);
                        }
                    }
                    if (!this.leaf || !active) {
                        dispatch(toggleSubTree(treeId, id, !toggled));
                    }
                }
            }
        }
    });
}

export function getInitialState() {
    return initialNavTreeState;
}

export function safeGetTree(state = {}, treeId) {
    var treeState = state[treeId];
    if (typeof treeState == 'undefined') {
        return getInitialState();
    }
    return treeState;
}

export function getNavTreeState(globalState) {
    return globalState[NAVTREE];
}

export function getNodePath(treeState, nodeId, path) {
    path.push(nodeId);
    let node = treeState.nodeById[nodeId];
    if (!node || typeof node.parentId == "undefined" || node.parentId == null) {
        return path;
    }
    return getNodePath(treeState, node.parentId, path);
}

function generateCollect(node, collectCallback, isCopy) {
    return function () {
        collectCallback(node, isCopy); //Deep copy of node
    }
}

export function connectNavTree(Component) {
    return connect((_, initialProps) => { //mapStateToProps
        const { treeId } = initialProps;
        return (globalState) => {
            const treeState = safeGetTree(getNavTreeState(globalState), treeId);
            const rdfId = globalState[LOCATION].params.object;
            const namespace = globalState[LOCATION].params.namespace;
            return {
                superUser: globalState[SECURITY].superUser,
                hashSelect: globalState[LOCATION].params.select,
                roots: treeState.rootNodesIds,
                finder: treeState.finder,
                infoLoaded: treeState.infoLoaded,
                loading: treeState.loading,
                ready: treeState.ready,
                error: treeState.error,
                header: treeState.header,
                active: treeState.active,
                enabledFilters: treeState.enabledFilters,
                initialRdfId: rdfId,
                initialNamespace: namespace,
                getNodeById: function (nodeId) {
                    return treeState.nodeById[nodeId];
                },
                getNodeByRdfId: function (nodeRdfId) {
                    let nodeId = treeState.nodeIdByRdfId[nodeRdfId];
                    return treeState.nodeById[nodeId];
                }
            };
        }
    }, (_, initialProps) => {
        const { treeId, expandRoot, disableMove, isModal } = initialProps;
        return (dispatch, ownProps) => {
            return {
                fetchInfo: function () {
                    dispatch(fetchInfo(treeId));
                },
                fetchSubTree: function (parentId) {
                    dispatch(fetchSubTree(treeId, parentId, expandRoot));
                },
                expandNode: function (rdfId, namespace) {
                    dispatch(expandNode(treeId, rdfId, namespace));
                },
                toggleNode: function (node, toggled) {
                    if (node.id) {
                        dispatch(toggleSubTree(treeId, node.id, toggled));
                    }
                },
                toggleFilter: function (filter, value) {
                    dispatch(toggleFilter(treeId, filter, value));
                },
                copyNodeRef: function(node){
                    dispatch(copyNodeRef(treeId, node));
                },
                copyNodeWithDescendantsRefs: function(nodeId){
                    dispatch(copyNodeWithDescendantsRefs(treeId, nodeId));
                },
                connectNodeFunction: connectNode(treeId, ownProps.selectObject, isModal),
                getAddActions: function (node, getNodeById) {
                    if (!node.addActions) {
                        return null;
                    }
                    const nodeId = node.id;
                    return node.addActions.map(function (addAction) {
                        let clickAction;
                        let nodeData = node.data;
                        while (node && !node.data && node.parentId) {
                            node = getNodeById(node.parentId);
                        }
                        if (addAction.table && node && node.data) {
                            let parameters = {
                                object: node.data.$rdfId,
                                addId: addAction.id
                            }
                            if (node.data.$namespace) {
                                parameters.namespace = node.data.$namespace;
                            }
                            clickAction = function (header) {
                                dispatch(openModal("treeAddTable", "cimtable", {
                                    size: header && header.modalSize && header.modalSize.toLowerCase(),
                                    tableId: addAction.table,
                                    forcedSelectType: "radio",
                                    parameters
                                }, function (result) {
                                    dispatch(addChild(treeId, nodeId, addAction.id, null, result.key));
                                }));
                            }
                        } else {
                            clickAction = function () {
                                dispatch(addChild(treeId, nodeId, addAction.id));
                            }
                        }
                        return {
                            id: addAction.id,
                            label: addAction.label,
                            onAction: clickAction
                        };
                    });
                },
                getRemoveAction: function (node) {
                    if (!node.data || node.deleteLock) {
                        return null;
                    }
                    const nodeId = node.id;
                    return {
                        onAction: function () {
                            const modalId = shortid.generate();
                            console.log("Remove node:", node.name, node);
                            const options = {
                                title: { id: "MSG_CONFIRM_ACTION" },
                                body: { id: "NAVTREE_DELETE_CONFIRM", values: { node: node.name } }
                            }
                            dispatch(openModal(modalId, "confirm", options, function () {
                                let isSelectedNode = false;
                                if (node.data && node.data.$rdfId) {
                                    const hashData = getHashData();
                                    if (node.data.$namespace == hashData.params.namespace
                                        && node.data.$rdfId == hashData.params.object) {
                                        isSelectedNode = true;
                                    }
                                }
                                dispatch(deleteNode(treeId, nodeId, isSelectedNode));
                            }));
                        }
                    }
                },
                getCollectNodeAction: function (node, collectCallback, isCopy) { //Collect means copy/cut. Copy required data from node
                    if (!node.data || disableMove) {
                        return null;
                    }
                    return {
                        onAction: generateCollect(node, collectCallback, isCopy)
                    };
                },
                getCopyAction: function (source, targetNode) { //Source is data from getCollectNodeAction
                    if (!targetNode.addActions || disableMove) { //We do not know how to create nodes
                        return null;
                    }
                    const nodeId = targetNode.id;
                    for (let addAction of targetNode.addActions) {
                        if (source.data && addAction.clazz == source.data.$class) { //check if classes match
                            return {
                                id: addAction.id,
                                label: addAction.label,
                                onAction: function () {
                                    dispatch(addChild(treeId, nodeId, addAction.id, source.id));
                                }
                            };
                        }
                    }
                    return null;
                },
                getCutAction: function (source, targetNode) { //Source is data from getCollectNodeAction
                    if (!targetNode.addActions || disableMove) { //We do not know how to create nodes
                        return null;
                    }
                    const nodeId = targetNode.id;
                    for (let addAction of targetNode.addActions) {
                        if (source.data && addAction.clazz == source.data.$class) { //check if classes match
                            return {
                                id: addAction.id,
                                label: addAction.label,
                                onAction: function () {
                                    dispatch(moveChild(treeId, nodeId, addAction.id, source.id));
                                }
                            };
                        }
                    }
                    return null;
                },
                showError: function (error) {
                    const options = {
                        title: { id: "MSG_ERROR" },
                        body: error
                    }
                    dispatch(openModal("showError", "common", options));
                },
                openModal: function (modalId, type, options, okCallback, cancelCallback, closeCallback) {
                    dispatch(openModal(modalId, type, options, okCallback, cancelCallback, closeCallback));
                }
            }
        }
    })(Component);
}