import * as Action from '../constants/alert';

function addAlert(state, alert) {
    let alerts = [].concat(state.alerts, alert);
    return Object.assign({}, state, { alerts });
}

function removeAlert(state, { id }) {
    let alerts = state.alerts.filter(alert => (alert.id != id));
    return Object.assign({}, state, { alerts });
}

export default (state = { alerts: [] }, action) => {
    switch (action.type) {
        case Action.ADD_ALERT: return addAlert(state, action.payload);
        case Action.REMOVE_ALERT: return removeAlert(state, action.payload);
        default: return state;
    }
}