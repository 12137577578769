import * as Action from '../constants/eventtasks.js';

const initialState = {
    loading: true,
    data: null,
    error: null,
    scriptList: null,
    scriptListError: null,
    sourceByName: {},
    sourceList: null,
    sourceListError: null,
    selectedRows: []
};

/******************
 * Util functions *
 ******************/

function setupCompositeNames(nextState) {
    for (let i = 0; i < nextState.data.length; ++i) {
        const source = nextState.sourceByName[nextState.data[i].server];
        if (source == null) {
            continue;
        }
        nextState.data[i] = Object.assign(nextState.data[i]);
        nextState.data[i].compositeSourceName = source.compositeName;
    }
}

/*************
 * Receivers *
 *************/

function receiveEventsLoading(state) {
    const nextState = Object.assign({}, state);
    nextState.loading = true;
    return nextState;
}

function receiveEventsData(state, { data }) {
    const nextState = Object.assign({}, state);
    nextState.loading = false;
    nextState.data = data;
    if (nextState.sourceList) {
        setupCompositeNames(nextState);
    }
    return nextState;

}

function receiveEventsError(state, { error }) {
    const nextState = Object.assign({}, state);
    nextState.loading = false;
    nextState.error = error;
    return nextState;
}

function receiveScriptsData(state, { data }) {
    const nextState = Object.assign({}, state);
    nextState.scriptList = data;
    nextState.scriptListError = null;
    return nextState;

}

function receiveScriptsError(state, { error }) {
    const nextState = Object.assign({}, state);
    nextState.scriptList = null;
    nextState.scriptListError = error;
    return nextState;
}

function receiveSourcesData(state, { data }) {
    const nextState = Object.assign({}, state);
    nextState.sourceByName = {};
    for (let source of data) {
        nextState.sourceByName[source.server] = source;
    }
    if (nextState.data) {
        nextState.data = nextState.data.slice();
        setupCompositeNames(nextState);
    }
    nextState.sourceList = data;
    nextState.sourceListError = null;
    return nextState;

}

function receiveSourcesError(state, { error }) {
    const nextState = Object.assign({}, state);
    nextState.sourceByName = {};
    nextState.sourceList = null;
    nextState.sourceListError = error;
    return nextState;
}

function receiveSingleEvent(state, { data }) {
    const nextState = Object.assign({}, state);
    nextState.data = nextState.data.slice();
    let found = false;
    for (let i = 0; i < nextState.data.length; ++i) {
        if (nextState.data[i].id == data.id) {
            found = true;
            nextState.data[i] = Object.assign({}, nextState.data[i], data);
            break;
        }
    }
    if (!found) {
        nextState.data.push(data);
    }
    return nextState;
}

function receiveDeletedEvent(state, { event }) {
    const nextState = Object.assign({}, state);
    nextState.data = nextState.data.slice();
    for (let i = 0; i < nextState.data.length; ++i) {
        if (nextState.data[i].id == event.id) {
            nextState.data.splice(i, 1);
            break;
        }
    }
    return nextState;
}

function selectRow(state, { data, selected }) {
    const nextState = Object.assign({}, state);
    nextState.selectedRows = [];
    for (let row of data) {
        if (selected[row.key]) {
            nextState.selectedRows.push(row);
        }
    }
    return nextState;
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.SEND_EVENTS_LOADING: return receiveEventsLoading(state, action.payload);
        case Action.SEND_EVENTS_DATA: return receiveEventsData(state, action.payload);
        case Action.SEND_EVENTS_ERROR: return receiveEventsError(state, action.payload);

        case Action.SEND_SCRIPTS_DATA: return receiveScriptsData(state, action.payload);
        case Action.SEND_SCRIPTS_ERROR: return receiveScriptsError(state, action.payload);

        case Action.SEND_SOURCES_DATA: return receiveSourcesData(state, action.payload);
        case Action.SEND_SOURCES_ERROR: return receiveSourcesError(state, action.payload);

        case Action.SEND_SINGLE_EVENT: return receiveSingleEvent(state, action.payload);
        case Action.SEND_DELETED_EVENT: return receiveDeletedEvent(state, action.payload);

        case Action.SELECT_ROW: return selectRow(state, action.payload);
        default: return state;
    }
}