import React from 'react';
import { FormattedMessage } from 'react-intl';
import LoginGroup from './logingroup.jsx';
import PasswordGroup from './passwordgroup.jsx';
import DomainGroup from './domaingroup.jsx';
import DomainList from './domainlist.jsx';

const SUBMIT_TEXT = (<FormattedMessage
    id="LOGIN_PANEL_LOGIN_BUTTON"
    defaultMessage="Log in"
    description="Button to submit login information" />);

const domainLoginRe = /^([\w\.\-]+)\\([\w\.\-]+)$/;

function checkBasicDomain(domain, basicDomain) {
    return (domain.lastIndexOf(basicDomain) == (domain.length - basicDomain.length));
}

class LoginPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            valid: false,
            useDomain: props.ldapAuth
        };
        //Event listeners
        this.onDomainChange = this.onDomainChange.bind(this);
        this.onLoginChange = this.onLoginChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onUseDomainChange = this.onUseDomainChange.bind(this);
        //Periodic validate for autofill
        this.onValidate = this.onValidate.bind(this);
        //Submit listener
        this.onSubmit = this.onSubmit.bind(this);
        //Rest login listener
        this.onRestLogin = this.onRestLogin.bind(this);
        this.onSuccessLogin = this.onSuccessLogin.bind(this);
        this.onFailLogin = this.onFailLogin.bind(this);
    }

    componentDidMount() {
        this.validateTimer = setInterval(this.onValidate, 500);
    }

    componentWillUnmount() {
        clearInterval(this.validateTimer);
    }

    onSubmit(event) {
        console.log("On submit");
        if (!this.state.valid) {
            event.preventDefault();
        }
    }

    onSuccessLogin(info) {
        this.setState({ waitRest: false });
        if (this.props.onSuccess) {
            this.props.onSuccess(info);
        }
    }

    onFailLogin(info) {
        this.setState({
            waitRest: false,
            restLoginError: true,
            restLoginErrorClass: info.exception,
            restLoginErrorMessage: info.message
        });
        if (this.props.onFail) {
            this.props.onFail(info);
        }
    }

    onRestLogin() {
        const successCallback = this.onSuccessLogin;
        const failCallback = this.onFailLogin;
        let params = { username: this.usernameInput.value, password: this.passwordInput.value };
        if (this.props.ldapAuth && this.useDomainInput && this.useDomainInput.checked) { //useDomain?
            params.ad = "on";
        }
        this.setState({ waitRest: true });
        $.post("/rest/login", params, function (data) {
            console.log("Rest login result:", data);
            if (data.loggedIn) {
                successCallback(data);
            } else {
                failCallback(data);
            }
        }).fail(function (error) {
            failCallback(error);
        });
    }

    onLoginChange(login) {
        this.validate();
    }

    onPasswordChange(password) {
        this.validate();
    }

    onDomainChange(domain) {
        if (domain) { //Check for empty domain
            const login = this.loginInput.value;
            const m = login.match(domainLoginRe);
            if (m) {
                this.loginInput.value = domain + "\\" + m[2];
            }
        }
        this.validate();
    }

    onUseDomainChange(useDomain) {
        this.setState({ useDomain: this.props.ldapAuth && useDomain });
        this.validate();
    }

    onValidate() {
        //console.log("Periodic validate");
        this.validate();
    }

    validate() {
        const login = (this.loginInput && this.loginInput.value) ? this.loginInput.value.trim() : "";
        const password = this.passwordInput ? this.passwordInput.value : "";
        const useDomain = this.props.ldapAuth && this.useDomainInput && this.useDomainInput.checked;
        const domain = this.domainListInput ? this.domainListInput.value : "";
        let username = login;
        let valid = login ? true : false;
        if (valid && useDomain) {
            if (login.indexOf("\\") >= 0) { //login is with domain information
                const m = login.match(domainLoginRe);
                valid = m ? true : false;
                if (valid) {
                    username = m[1].toLowerCase() + "\\" + m[2];
                    if (this.props.domains) { //Check against supplied list of domains
                        const domainByNetbios = this.props.netbiosMap[m[1].toUpperCase()];
                        if (domainByNetbios) {
                            username = domainByNetbios.fqdn.toLowerCase() + "\\" + m[2];
                            //!!!!Change domain name
                            this.domainListInput.value = domainByNetbios.name;
                        } else {
                            let fqdn = m[1].toUpperCase();
                            if (this.props.basicDomain) {
                                const basicDomain = this.props.basicDomain.toUpperCase();
                                if (!checkBasicDomain(fqdn, basicDomain)) {
                                    fqdn += basicDomain;
                                }
                            }
                            const domainByFQDN = this.props.fqdnMap[fqdn];
                            if (domainByFQDN) {
                                username = domainByFQDN.fqdn.toLowerCase() + "\\" + m[2];
                                //!!!!Change domain name
                                this.domainListInput.value = domainByFQDN.name;
                            } else {
                                username = fqdn.toLowerCase() + "\\" + m[2];
                                this.domainListInput.value = "";
                            }
                        }
                    } else { //We do not have domain list
                        let fqdn = m[1].toUpperCase();
                        if (this.props.basicDomain) {
                            const basicDomain = this.props.basicDomain.toUpperCase();
                            if (!checkBasicDomain(fqdn, basicDomain)) {
                                fqdn += basicDomain;
                            }
                        }
                        username = fqdn.toLowerCase() + "\\" + m[2];
                    }
                }
            } else { //login without domain information
                const domainByNetbios = domain && this.props.netbiosMap && this.props.netbiosMap[domain];
                if (domainByNetbios) {
                    username = domainByNetbios.fqdn.toLowerCase() + "\\" + login;
                } else {
                    valid = false;
                }
            }
        }
        //Check password at the end
        valid = (valid && password) ? true : false;
        this.usernameInput.value = username;
        this.setState({ valid });
    }

    getSubmitButton() {
        if (this.props.rest) {
            return (<button disabled={!this.state.valid} className="btn btn-primary" onClick={this.onRestLogin}>
                <FormattedMessage id="LOGIN_PANEL_LOGIN_BUTTON" />
            </button>);
        }
        return (<button type="submit" disabled={!this.state.valid} className="btn btn-primary">
            <FormattedMessage id="LOGIN_PANEL_LOGIN_BUTTON" />
        </button>);
    }

    getFormFields() {
        return (<div className="col-md-12">
            <input
                type="hidden"
                name="username"
                ref={(input) => { this.usernameInput = input; }} />
            <LoginGroup
                ldapAuth={this.state.useDomain}
                setupInput={(input) => { this.loginInput = input; }}
                onChange={this.onLoginChange} />
            <PasswordGroup
                setupInput={(input) => { this.passwordInput = input; }}
                onChange={this.onPasswordChange} />
            <DomainList
                ldapAuth={this.state.useDomain}
                setupInput={(input) => { this.domainListInput = input; }}
                domains={this.props.domains}
                onChange={this.onDomainChange} />
            <DomainGroup
                ldapAuth={this.props.ldapAuth}
                setupInput={(input) => { this.useDomainInput = input; }}
                onChange={this.onUseDomainChange} />
            <div className="form-group text-center">
                {this.getSubmitButton()}
            </div>
        </div>);
    }

    getForm() {
        if (this.props.rest) { //rest request to login
            return (<form className="col-md-12 nopadding" role="form">
                {this.getFormFields()}
            </form>)
        }
        return (<form className="col-md-12 nopadding" role="form" method="post" action="login" onSubmit={this.onSubmit}>
            {this.getFormFields()}
        </form>);
    }

    isError() {
        if (window.location.search && window.location.search.indexOf('error') >= 0) {
            return true;
        }
        return this.state.restLoginError ? true : false;
    }

    getErrorClassInfo() {
        const cls = $('meta[name=login-error-class]').attr("content");
        if (typeof cls != 'undefined') {
            return cls;
        }
        return this.state.restLoginErrorClass;
    }

    getErrorMessageInfo() {
        const msg = $('meta[name=login-error-message]').attr("content");
        if (typeof msg != 'undefined') {
            return msg;
        }
        return this.state.restLoginErrorMessage;
    }

    isLogout() {
        return (window.location.search && window.location.search.indexOf('logout') >= 0) ? true : false;
    }

    getErrorMessage() {
        const cls = this.getErrorClassInfo();
        const message = this.getErrorMessageInfo();
        if (cls == "LockedException") {
            if (message) {
                return (<FormattedMessage
                    id="LOGIN_PANEL_LOCKED_WM"
                    defaultMessage="User is blocked: {message}"
                    description="Inform user about block" values={{ message }} />);
            } else {
                return (<FormattedMessage
                    id="LOGIN_PANEL_LOCKED"
                    defaultMessage="User is blocked"
                    description="Inform user about block" />);
            }
        }
        if (cls == "AuthenticationServiceException") {
            return (<FormattedMessage
                id="LOGIN_PANEL_DB_ERROR"
                defaultMessage="User database error"
                description="Inform user about user database error" />);
        }
        if (cls == "AccountExpiredException") {
            return (<FormattedMessage
                id="LOGIN_PANEL_ACCOUNT_EXPIRED"
                defaultMessage="Account expired"
                description="Inform user about account expired error" />);
        }
        if (cls == "CredentialsExpiredException") {
            return (<FormattedMessage
                id="LOGIN_PANEL_CREDENTIALS_EXPIRED"
                defaultMessage="Credentials expired"
                description="Inform user about credentials expired error" />);
        }
        return (<FormattedMessage
            id="LOGIN_PANEL_BAD_CREDENTIALS"
            defaultMessage="Invalid username or password!"
            description="Inform user about invalid username and password" />)
    }

    getErrorRow() {
        return (
            <div className="alert alert-danger" role="alert" style={{ marginBottom: "0px" }}>
                <span className="fa fa-fw fa-exclamation-triangle" aria-hidden="true"> </span>
                {this.getErrorMessage()}
            </div>);
    }

    getLogoutRow() {
        return (
            <div className="alert alert-info" role="alert" style={{ marginBottom: "0px" }}>
                <span className="fa fa-fw fa-exclamation-triangle" aria-hidden="true"> </span>
                <FormattedMessage
                    id="LOGIN_PANEL_LOGOUT"
                    defaultMessage="Logout from system!"
                    description="Inform user that logout from system is complete" />
            </div>);
    }

    getFooter() {
        if (!this.isError() && !this.isLogout()) {
            return null;
        }
        return (<div className="card-footer">
            {this.isError() && this.getErrorRow()}
            {this.isLogout() && this.getLogoutRow()}
        </div>);
    }

    getBody() {
        //Waiting
        if (this.state.waitRest) { //Waiting for rest auth to complete
            return (<div className="card-body">
                <span>
                    <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    <FormattedMessage
                        id="LOGIN_PANEL_WAITING_FOR_REST"
                        defaultMessage="Waiting for authentification to complete..."
                        description="Inform user that authentification is in progress" />
                </span>
            </div>);
        }
        //Ready
        return (<div className="card-body">
            <div className="container-fluid">
                <div className="row">
                    <h3 className="text-center">
                        <FormattedMessage
                            id="LOGIN_PANEL_LOGIN_INVITATION"
                            defaultMessage="Please Login"
                            description="Label to invite user to login" />
                    </h3>
                </div>
                <div className="row">
                    <form className="col-md-12 nopadding" role="form" method="post" action="login" onSubmit={this.onSubmit}>
                        {this.getFormFields()}
                    </form>
                </div>
            </div>
        </div>);
    }

    getLogo() {
        if (!this.props.logo) {
            return this.props.contextPath + "resources/images/epsa_logo.png";
        }
        const url = this.props.logo;
        const contextPath = this.props.contextPath;
        if (url.startsWith("/") && !url.startsWith(contextPath)) {
            return contextPath + url.substring(1);
        }
        return url;
    }

    render() {
        return (
            <div className="card login-panel">
                <div className="card-header">
                    <div className="row">
                        <a><img src={this.getLogo()} /></a>
                    </div>
                </div>
                {this.getBody()}
                {this.getFooter()}
            </div>);
    }
}

export default LoginPanel;