import { FormattedMessage } from 'react-intl';

export const RPA_SETPOINTS = "rpasetpoints";

/* Links */
export const UPLOAD_FILE_URL = "/rest/file/upload";
export const DONWLOAD_FILE_URL = "/rest/file/download";

export const UPLOAD_SETPOINTS_URL = "/rest/rpasetpoints/setpoints";
export const UPLOAD_SIGNALS_URL = "/rest/rpasetpoints/signals";

/* Constants */
export const FILE_SELECTION = 0;
export const FILE_UPLOADING = 1;
export const READY = 2;
export const ERROR = 3;

export const VOLTAGE_LEVEL = 0;
export const BAY_LEVEL = 1;
export const VENDOR_LEVEL = 2;
export const FUNCTION_LEVEL = 3;

export const COMMON_SETPOINTS = "commonSetpoints";
export const COMMON_SIGNALS = "commonSignals";
export const SETPOINTS = "setpoints";
export const SIGNALS = "signals";

export const TAB_SETPOINTS = "setpoints";
export const TAB_ANALOG = "analog";
export const TAB_DISCRETE = "discrete";

export const SOURCE_TREE = "source";
export const TARGET_TREE = "target";

export const SOURCE = {
    [TAB_SETPOINTS]: "setpoints",
    [TAB_ANALOG]: "analogSignals",
    [TAB_DISCRETE]: "discreteSignals"
};
export const TARGET = {
    [TAB_SETPOINTS]: "commonSetpoints",
    [TAB_ANALOG]: "commonAnalogSignals",
    [TAB_DISCRETE]: "commonDiscreteSignals"
};

export const reverse = {
    [SOURCE[TAB_SETPOINTS]]: TARGET[TAB_SETPOINTS],
    [TARGET[TAB_SETPOINTS]]: SOURCE[TAB_SETPOINTS],
    [SOURCE[TAB_ANALOG]]: TARGET[TAB_ANALOG],
    [TARGET[TAB_ANALOG]]: SOURCE[TAB_ANALOG],
    [SOURCE[TAB_DISCRETE]]: TARGET[TAB_DISCRETE],
    [TARGET[TAB_DISCRETE]]: SOURCE[TAB_DISCRETE]
}

export const COMMON_VOLTAGES = [6, 10, 20, 27.5, 35, 110, 150, 220, 330, 500, 750];

export const COMMON_VENDORS_IDS = ["ekra", "siemens", "relematika", "abb"];

export const COMMON_EXTENSIONS = {
    "excel": "*.xlsx",
    "doc": "*.doc",
    "xml": "*.xml",
    "csv": "*.csv",
    "txt": "*.txt"
};

export const COMMON_VENDORS = {
    "relematika": "Релематика",
    "Релематика": "Релематика",
    "ekra": "ЭКРА",
    "Экра": "ЭКРА",
    "siemens": "Siemens",
    "Siemens": "Siemens",
    "abb": "ABB",
    "ABB": "ABB", //English
    "АВВ": "ABB"  //Russian
}

export const ENCODE_NAMES = {
    "ASCII": "ASCII",
    "UTF-8": "UTF-8"
}

export const ENCODE_VALUES = {
    "ASCII": "windows-1251",
    "UTF-8": "utf-8"
}

export const SHARED_SETPOINTS_IDS = {
    "202": true,
    "203": true,
    "204": true,
    "205": true,
    "206": true,
    "207": true,
    "208": true,
    "240": true,
    "311": true,
    "312": true,
    "321": true,
    "322": true,
    "331": true,
    "332": true,
    "361": true,
    "362": true,
    "512": true,
    "513": true,
    "1101": true,
    "1105": true,
    "Uperv": true,
    "Uvtor": true,
    "Iperv": true,
    "Ivtor": true,
    "Iterm": true,
    "Iprim": true,
    "Isec": true,
    "CTprim": true,
    "CTsec": true,
    "Sr": true,
    "Sr1": true,
    "Ir1": true,
    "Ur1": true,
    "Sr2": true,
    "Ir2": true,
    "Ur2": true,
    "Sr3": true,
    "Ir3": true,
    "Ur3": true,
    "U1b": true,
    "I1b": true,
    "I4b": true
}

/* Actions */
export const CHANGE_PARAMETER = "@@RPA_SETPOINTS_CHANGE_PARAMETER";
export const CHANGE_TAB = "@@RPA_SETPOINTS_CHANGE_TAB";

export const SET_OPTIONS = "@@RPA_SETPOINTS_SET_OPTIONS";

export const COMMON_SETPOINTS_RECEIVE = "@@RPA_SETPOINTS_COMMON_SETPOINTS_RECEIVE";
export const COMMON_SETPOINTS_RECEIVE_ERROR = "@@RPA_SETPOINTS_COMMON_SETPOINTS_RECEIVE_ERROR";
export const COMMON_SIGNALS_RECEIVE = "@@RPA_SETPOINTS_COMMON_SIGNALS_RECEIVE";
export const COMMON_SIGNALS_RECEIVE_ERROR = "@@RPA_SETPOINTS_COMMON_SIGNALS_RECEIVE_ERROR";
export const SETPOINTS_RECEIVE = "@@RPA_SETPOINTS_SETPOINTS_RECEIVE";
export const SETPOINTS_RECEIVE_ERROR = "@@RPA_SETPOINTS_SETPOINTS_RECEIVE_ERROR";
export const SIGNALS_RECEIVE = "@@RPA_SETPOINTS_SIGNALS_RECEIVE";
export const SIGNALS_RECEIVE_ERROR = "@@RPA_SETPOINTS_SIGNALS_RECEIVE_ERROR";

export const FILE_RESET = "@@RPA_SETPOINTS_FILE_RESET";
export const FILE_RECEIVE = "@@RPA_SETPOINTS_FILE_RECEIVE";
export const FILE_RECEIVE_ERROR = "@@RPA_SETPOINTS_FILE_RECEIVE_ERROR";

export const SELECT_NODE = "@@RPA_SETPOINTS_SELECT_NODE";
export const TOGGLE_NODE = "@@RPA_SETPOINTS_TOGGLE_NODE";

export const UNBIND_SETPOINTS = "@@RPA_SETPOINTS_UNBIND_SETPOINTS";
export const BIND_SETPOINTS = "@@RPA_SETPOINTS_BIND_SETPOINTS";
export const SET_WITHOUT_CONFIRMATION = "@@RPA_SETPOINTS_SET_WITHOUT_CONFIRMATION";
export const SET_BINDED_FLAG = "@@RPA_SETPOINTS_SET_BINDED_FLAG";
export const SET_DETAILED_VIEW = "@@RPA_SETPOINTS_SET_DETAILED_VIEW";
export const CHANGE_FILTER = "@@RPA_SETPOINTS_CHANGE_FILTER";

export const CHANGE_DETAILS_PARAMETER = "@@RPA_SETPOINTS_CHANGE_DETAILS_PARAMETER";

export const SHOW_LINKED_ELEMENT = "@@RPA_SETPOINTS_SHOW_LINKED_ELEMENT";

export const CHANGE_CONFIG = "@@RPA_SETPOINTS_CHANGE_CONFIG";

export const CANCEL_UPDATES = "@@RPA_SETPOINTS_CANCEL_UPDATES";


/* Common messages */
export const PARSE_ERROR = (<FormattedMessage
    id="RPA_SETPOINTS_PARSE_ERROR"
    defaultMessage="Error occured during file parsing"
    description="Error occured during file parsing" />);

export const SHARED_SETPOINTS_ERROR = (<FormattedMessage
    id="RPA_SETPOINTS_SHARED_SETPOINTS_ERROR"
    defaultMessage="Error occured during shared setpoints parsing: Mandatory functions weren't binded."
    description="Error occured during shared setpoints parsing" />);

export const TAB_SETPOINTS_MESSAGE = (<FormattedMessage
    id="RPA_SETPOINTS_TAB_SETPOINTS"
    defaultMessage="Setpoints"
    description="Label of setpoints tab" />);

export const TAB_ANALOG_MESSAGE = (<FormattedMessage
    id="RPA_SETPOINTS_TAB_ANALOG"
    defaultMessage="Analog"
    description="Label of analog signals tab" />);

export const TAB_DISCRETE_MESSAGE = (<FormattedMessage
    id="RPA_SETPOINTS_TAB_DISCRETE"
    defaultMessage="Discrete"
    description="Label of discrete signals tab" />);

export const UNBIND = (<FormattedMessage
    id="RPA_SETPOINTS_UNBIND"
    defaultMessage="Unbind"
    description="Unbind button label" />);

export const BIND = (<FormattedMessage
    id="RPA_SETPOINTS_BIND"
    defaultMessage="Bind"
    description="Bind button label" />);

export const NOT_BINDED = (<FormattedMessage
    id="RPA_SETPOINTS_NOT_BINDED"
    defaultMessage="Not binded"
    description="Not binded checkbox label" />);

export const WITHOUT_CONFIRM = (<FormattedMessage
    id="RPA_SETPOINTS_WITHOUT_CONFIRM"
    defaultMessage="Without confirm"
    description="Without confirm checkbox label" />);

export const DETAILED_VIEW = (<FormattedMessage
    id="RPA_SETPOINTS_DETAILED_VIEW"
    defaultMessage="Detailed"
    description="Detailed view" />);

export const RPA_SETPOINTS_DOWNLOAD = (<FormattedMessage
    id="RPA_SETPOINTS_DOWNLOAD"
    defaultMessage="Download"
    description="Download button label" />);

export const RPA_SETPOINTS_SAVE = (<FormattedMessage
    id="RPA_SETPOINTS_SAVE"
    defaultMessage="Save"
    description="Save button label" />);

export const CONFIRM_ACTION = (<FormattedMessage
    id="RPA_SETPOINTS_CONFIRM_ACTION"
    defaultMessage="Confirm action"
    description="Confirm modal title" />);

export const UNBIND_SETPOINTS_CONFIRM = (<FormattedMessage
    id="RPA_SETPOINTS_UNBIND_SETPOINTS_CONFIRM"
    defaultMessage="Confirm delete binding of setpoints '{sourceName}' and '{targetName}'"
    description="Unbind setpoints confirm text" />);

export const BIND_SETPOINTS_CONFIRM = (<FormattedMessage
    id="RPA_SETPOINTS_BIND_SETPOINTS_CONFIRM"
    defaultMessage="Confirm binding setpoints '{sourceName}' and '{targetName}'"
    description="Bind setpoints confirm text" />);

export const DETAILS = (<FormattedMessage
    id="RPA_SETPOINTS_DETAILS"
    defaultMessage="Details"
    description="Details of the selected node" />);

export const COMMON_TEMPLATE_TREE = (<FormattedMessage
    id="RPA_SETPOINTS_COMMON_TEMPLATE_TREE"
    defaultMessage="Analysis subsystem"
    description="Setpoints from common template tree title" />);

export const COMMON_CONFIG_TREE = (<FormattedMessage
    id="RPA_SETPOINTS_COMMON_CONFIG_TREE"
    defaultMessage="RPA device"
    description="Setpoints from common config tree title" />);

export const PRIMARY_TREE = (<FormattedMessage
    id="RPA_SETPOINTS_PRIMARY_TREE"
    defaultMessage="Primary tree"
    description="Text of primary tree badge" />);

export const SAVE_SUCCESS = (<FormattedMessage
    id="RPA_SETPOINTS_SAVE_SUCCESS"
    defaultMessage="File was successfully saved!"
    description="Text of successful file save" />);

export const SAVE_ERROR = (<FormattedMessage
    id="RPA_SETPOINTS_SAVE_ERROR"
    defaultMessage="Error occured while saving file!"
    description="Text of failed file save" />);

export const UPLOAD_SETPOINTS = (<FormattedMessage
    id="RPA_SETPOINTS_UPLOAD_SETPOINTS"
    defaultMessage="Setpoints file"
    description="Text of setpoints filedrop" />);

export const UPLOAD_SIGNALS = (<FormattedMessage
    id="RPA_SETPOINTS_UPLOAD_SIGNALS"
    defaultMessage="Oscillography file *.CFG COMTRADE"
    description="Text of oscillography file filedrop" />);

export const FILE = (<FormattedMessage
    id="RPA_SETPOINTS_FILE"
    defaultMessage="File"
    description="Label of fail filedrop button" />);

export const UPDATE_SETPOINTS = (<FormattedMessage
    id="RPA_SETPOINTS_UPDATE_SETPOINTS"
    defaultMessage="Update setpoints"
    description="Label of update setpoints file link" />);

export const UPDATE_SIGNALS = (<FormattedMessage
    id="RPA_SETPOINTS_UPDATE_SIGNALS"
    defaultMessage="Update *.cfg"
    description="Label of update COMTRADE file link" />);

export const UPDATE_FILE = (<FormattedMessage
    id="RPA_SETPOINTS_UPDATE_FILE"
    defaultMessage="Update file"
    description="Title of file update modal" />);

export const IMPORT_FILE = (<FormattedMessage
    id="RPA_SETPOINTS_IMPORT_FILE"
    defaultMessage="Import file"
    description="Title of file import modal" />);

export const RESET_FILE = (<FormattedMessage
    id="RPA_SETPOINTS_RESET_FILE"
    defaultMessage="Reset file"
    description="Title of file reset modal" />);

export const CONFIRM_FILE_RESET = (<FormattedMessage
    id="RPA_SETPOINTS_CONFIRM_FILE_RESET"
    defaultMessage="Confirm file reset"
    description="Body of file reset modal" />);

export const CONFIGURATIONS = (<FormattedMessage
    id="RPA_SETPOINTS_CONFIGURATIONS"
    defaultMessage="Configurations"
    description="Title of configurations modal" />);

export const ORIGINAL = (<FormattedMessage
    id="RPA_SETPOINTS_ORIGINAL_VALUE"
    defaultMessage="Original"
    description="Label of original value" />);

export const FILTER = (<FormattedMessage
    id="RPA_SETPOINTS_FILTER"
    defaultMessage="Filter:"
    description="Text of filter badge" />);

export const BAY_TYPE = (<FormattedMessage
    id="RPA_SETPOINTS_BAY_TYPE"
    defaultMessage="Select the type of protected object:"
    description="Label of bay type selector" />);

export const SELECT_BAY_TYPE = (<FormattedMessage
    id="RPA_SETPOINTS_SELECT_BAY_TYPE"
    defaultMessage="Select type..."
    description="Placeholder of bay type selector" />);

export const VENDOR = (<FormattedMessage
    id="RPA_SETPOINTS_VENDOR"
    defaultMessage="Vendor:"
    description="Label of vendor selector" />);

export const SELECT_VENDOR = (<FormattedMessage
    id="RPA_SETPOINTS_SELECT_VENDOR"
    defaultMessage="Select vendor..."
    description="Placeholder of vendor selector" />);

export const VOLTAGE = (<FormattedMessage
    id="RPA_SETPOINTS_VOLTAGE"
    defaultMessage="Voltage:"
    description="Label of voltage selector" />);

export const SELECT_VOLTAGE = (<FormattedMessage
    id="RPA_SETPOINTS_SELECT_VOLTAGE"
    defaultMessage="Select voltage..."
    description="Placeholder of voltage selector" />);

export const LOADING = (<FormattedMessage
    id="RPA_SETPOINTS_LOADING"
    defaultMessage="Loading..."
    description="Loading text" />);

export const KILOVOLT = (<FormattedMessage
    id="RPA_SETPOINTS_KILOVOLT"
    defaultMessage="kV"
    description="Kilovolt short name" />);

export const VENDOR_EKRA = (<FormattedMessage
    id="RPA_SETPOINTS_VENDOR_EKRA"
    defaultMessage="Ekra"
    description="Local name of vendor Ekra" />);

export const VENDOR_SIEMENS = (<FormattedMessage
    id="RPA_SETPOINTS_VENDOR_SIEMENS"
    defaultMessage="Siemens"
    description="Local name of vendor Siemens" />);

export const VENDOR_RELEMATICA = (<FormattedMessage
    id="RPA_SETPOINTS_VENDOR_RELEMATIKA"
    defaultMessage="Relematika"
    description="Local name of vendor Relematika" />);

export const VENDOR_ABB = (<FormattedMessage
    id="RPA_SETPOINTS_VENDOR_ABB"
    defaultMessage="ABB"
    description="Local name of vendor ABB" />);

export const SETPOINTS_NAME = (<FormattedMessage
    id="RPA_SETPOINTS_NAME"
    defaultMessage="Name"
    description="Label of name property" />);

export const ORIGINAL_ID = (<FormattedMessage
    id="RPA_SETPOINTS_ORIGINAL_ID"
    defaultMessage="Original id"
    description="Label of original id property" />);

export const LINKED_ID = (<FormattedMessage
    id="RPA_SETPOINTS_LINKED_ID"
    defaultMessage="Linked id"
    description="Label of linked id property" />);

export const LINKED_NAME = (<FormattedMessage
    id="RPA_SETPOINTS_LINKED_NAME"
    defaultMessage="Linked name"
    description="Label of linked name property" />);

export const IS_ENUM = (<FormattedMessage
    id="RPA_SETPOINTS_IS_ENUM"
    defaultMessage="Is enum"
    description="Label of is enum property" />);

export const IS_SHARED = (<FormattedMessage
    id="RPA_SETPOINTS_IS_SHARED"
    defaultMessage="Is shared"
    description="Label of is shared property" />);

export const SHARED = (<FormattedMessage
    id="RPA_SETPOINTS_SHARED"
    defaultMessage="Shared"
    description="Label of shared folder" />);

export const VALUE = (<FormattedMessage
    id="RPA_SETPOINTS_VALUE"
    defaultMessage="Value"
    description="Label of value property" />);

export const RAW_VALUE = (<FormattedMessage
    id="RPA_SETPOINTS_RAW_VALUE"
    defaultMessage="Raw value"
    description="Label of raw value property" />);

export const DIMENSION = (<FormattedMessage
    id="RPA_SETPOINTS_DIMENSION"
    defaultMessage="Dimension"
    description="Label of dimension property" />);

export const IS_PRIMARY = (<FormattedMessage
    id="RPA_SETPOINTS_IS_PRIMARY"
    defaultMessage="Is primary"
    description="Label of is primary property" />);

export const CHANNEL_ID = (<FormattedMessage
    id="RPA_SETPOINTS_CHANNEL_ID"
    defaultMessage="Channel id"
    description="Label of channel id property" />);

export const CCBM = (<FormattedMessage
    id="RPA_SETPOINTS_CCBM"
    defaultMessage="Ccbm"
    description="Label of ccbm property" />);

export const CHANNEL_MULTIPLIER = (<FormattedMessage
    id="RPA_SETPOINTS_CHANNEL_MULTIPLIER"
    defaultMessage="Channel multiplier"
    description="Label of channel multiplier property" />);

export const CHANNEL_OFFSET = (<FormattedMessage
    id="RPA_SETPOINTS_CHANNEL_OFFSET"
    defaultMessage="Channel offset"
    description="Label of channel offset property" />);

export const IEC_CHANNEL_ID = (<FormattedMessage
    id="RPA_SETPOINTS_IEC_CHANNEL_ID"
    defaultMessage="IEC channel id"
    description="Label of IEC channel id property" />);

export const INDEX = (<FormattedMessage
    id="RPA_SETPOINTS_INDEX"
    defaultMessage="Index"
    description="Label of index property" />);

export const MAX = (<FormattedMessage
    id="RPA_SETPOINTS_MAX"
    defaultMessage="Max"
    description="Label of max property" />);

export const MIN = (<FormattedMessage
    id="RPA_SETPOINTS_MIN"
    defaultMessage="Min"
    description="Label of min property" />);

export const PH = (<FormattedMessage
    id="RPA_SETPOINTS_PH"
    defaultMessage="Phase"
    description="Label of ph property" />);

export const PRIMARY = (<FormattedMessage
    id="RPA_SETPOINTS_PRIMARY"
    defaultMessage="Primary"
    description="Label of primary property" />);

export const PS = (<FormattedMessage
    id="RPA_SETPOINTS_PS"
    defaultMessage="Primary/secondary"
    description="Label of primary/secondary property" />);

export const SECONDARY = (<FormattedMessage
    id="RPA_SETPOINTS_SECONDARY"
    defaultMessage="Secondary"
    description="Label of secondary property" />);

export const SKEW = (<FormattedMessage
    id="RPA_SETPOINTS_SKEW"
    defaultMessage="Skew"
    description="Label of skew property" />);

export const UNIT = (<FormattedMessage
    id="RPA_SETPOINTS_UNIT"
    defaultMessage="Unit"
    description="Label of unit property" />);

export const RPA_SETPOINTS_SELECT_BIND_VALUE = (<FormattedMessage
    id="RPA_SETPOINTS_SELECT_BIND_VALUE"
    defaultMessage="Select bind value"
    description="Title of bind value selection modal" />);

export const RPA_SETPOINTS_CANT_RECOGNIZE_VALUE = (<FormattedMessage
    id="RPA_SETPOINTS_CANT_RECOGNIZE_VALUE"
    defaultMessage={`Value "{value}" wasn't found in enumeration. Please, select appropriate value:`}
    description="Body of bind value selection modal" />);