import { FormattedMessage } from "react-intl";

//Global tree state path
export const OBJECTCARD = "objectcard";

/////////////////////
//Card state status//
/////////////////////

//Events
export const EVENT_ADD_NEW_SUBJECT = "OBJECTCARD_ADD_NEW_SUBJECT";
//Flush debounce in object card
export const EVENT_FLUSH_DEBOUNCE = "EVENT_OBJECT_CARD_FLUSH_DEBOUNCE";


//Layout status
export const STATUS_LOADING = "loading";
export const STATUS_ERROR = "error";
export const STATUS_READY = "ready";

//Main data id
export const DATA_MAIN_ID = "__main__";

//Actions
export const LAYOUT_RECEIVED = "@@OBJECTCARD_LAYOUT_RECEIVED";
export const LAYOUT_WAIT = "@@OBJECTCARD_LAYOUT_WAIT";
export const LAYOUT_ERROR_RECEIVED = "@@OBJECTCARD_LAYOUT_ERROR_RECEIVED";

export const ENUMERATION_RECEIVED = "@@OBJECTCARD_ENUMERATION_RECEIVED";
export const ENUMERATION_WAIT = "@@OBJECTCARD_ENUMERATION_WAIT";
export const ENUMERATION_ERROR_RECEIVED = "@@OBJECTCARD_ENUMERATION_ERROR_RECEIVED";

export const CHANGE_TAB = "@SUBJECT_CHANGE_TAB";

export const START_SAVE = "@@OBJECTCARD_START_SAVE";
export const CANCEL_SAVE = "@@OBJECTCARD_CANCEL_SAVE";
export const CHANGE_SAVE_STATE = "@@OBJECTCARD_CHANGE_SAVE_STATE";
export const SUBJECT_SAVE_WAIT = "@SUBJECT_SAVE_WAIT";

export const SUBJECT_WAIT = "@@OBJECTCARD_SUBJECT_WAIT";
export const SUBJECT_RECEIVED = "@@OBJECTCARD_SUBJECT_RECEIVED";
export const SUBJECT_ERROR_RECEIVED = "@@OBJECTCARD_SUBJECT_ERROR_RECEIVED";
export const SUBJECT_CHANGE_DATA = "@@OBJECTCARD_SUBJECT_CHANGE_DATA";
export const SUBJECT_FRAGMENT_TREE_WAIT = "@@OBJECTCARD_SUBJECT_FRAGMENT_TREE_WAIT";
export const SUBJECT_FRAGMENT_TREE_DATA_RECEIVED = "@@OBJECTCARD_SUBJECT_FRAGMENT_TREE_DATA_RECEIVED";
export const SUBJECT_FRAGMENT_TREE_ERROR_RECEIVED = "@@OBJECTCARD_SUBJECT_FRAGMENT_TREE_ERROR_RECEIVED";
export const SUBJECT_DESTROY_STORE = "@@OBJECTCARD_DESTROY_STORE"; //Destroy store

export const SUBJECT_OPERATION_INIT = "@@OBJECTCARD_SUBJECT_OPERATION_INIT";
export const SUBJECT_OPERATION_CREATE = "@@OBJECTCARD_SUBJECT_OPERATION_CREATE";
export const SUBJECT_OPERATION_GET = "@@OBJECTCARD_SUBJECT_OPERATION_GET";
export const SUBJECT_OPERATION_LOCK = "@@OBJECTCARD_SUBJECT_OPERATION_LOCK";
export const SUBJECT_OPERATION_UNLOCK = "@@OBJECTCARD_SUBJECT_OPERATION_UNLOCK";
export const SUBJECT_OPERATION_SAVE = "@@OBJECTCARD_SUBJECT_OPERATION_SAVE";

//Save states
export const SAVE_STATE_START = "start";
export const SAVE_STATE_SHOW_ERRORS = "show_errors";
export const SAVE_STATE_UPLOADS_READY = "uploads_ready";
export const SAVE_STATE_WAIT_SERVER = "wait_server";


//Layout constants
export const CHILD_PLACEHOLDER = 'child-placeholder';
export const PARENT_PLACEHOLDER = 'parent-placeholder';
export const SELF_PLACEHOLDER = 'self-placeholder';
export const PREDICATE_PLACEHOLDER = 'predicate-placeholder';

//General purpose
export const UI_CARD_AREA = "card-area";

//Panels
export const UI_PANEL = "p";

//Group
export const UI_GROUP = "group";

//Grid
export const UI_GRID = "g";
export const UI_GRID_CELL = "g-c";

//Tabs
export const UI_TAB_NAV = "t-n";
export const UI_TAB_HEADER = "tab-header";
export const UI_TAB = "t-i";

//Simple inputs
export const UI_TEXT = "i-txt";
export const UI_FLOAT = "i-flt";
export const UI_INT = "i-int";
export const UI_DATE = "i-d";
export const UI_DATE_TIME = "i-dt";
export const UI_TEXT_AREA = "input-text-area";
export const UI_CHECKBOX = "i-chk";
export const UI_FRAGMENT = "i-frg";
export const UI_FILE = "i-fl";
export const UI_LABEL = "i-lbl";
export const UI_DESCRIPTION = "i-dsc";




//Enumeration
export const UI_COMBOBOX = "i-cmb";

//Refence table
export const UI_REF_TABLE = "t-ref";

//Object table
export const UI_OBJECT_TABLE = "t-obj";

//Image display
export const UI_IMAGE = "u-img";

//Table display
export const UI_TABLE = "table";

//Card display
export const UI_CARD = "u-c";

//RPA setpoints display
export const UI_RPA_SETPOINTS = "u-sp";

//Plugin display
export const UI_PLUGIN = "u-plg";

//Link display
export const UI_LINK = "u-lnk";

//Button display
export const UI_BUTTON = "u-btn";

//Notify about subject changes
export const NOTIFY_SUBJECT_CHANGED = "NOTIFY_SUBJECT_CHANGED";

export const UI_FORMAT = {
    [UI_TEXT]: "string",
    [UI_FLOAT]: "float",
    [UI_INT]: "int",
    [UI_DATE]: "date",
    [UI_DATE_TIME]: "dateTime"
}

//Messages
export const OBJECTCARD_VALIDATION_EMPTY = (<FormattedMessage
    id="OBJECTCARD_VALIDATION_EMPTY"
    defaultMessage="Validation function return empty result"
    description="Validation function return empty result" />);

export const OBJECTCARD_HISTORY_EMPTY = (<FormattedMessage
    id="OBJECTCARD_HISTORY_EMPTY"
    defaultMessage="Objectcard view history is empty for specified class"
    description="Objectcard history is empty" />);

export const OBJECTCARD_UPLOAD_FILTER_FAILED = (<FormattedMessage
    id="OBJECTCARD_UPLOAD_FILTER_FAILED"
    defaultMessage="Filter upload failed"
    description="Filter upload failed" />);

export const OBJECTCARD_CONFIRM_REFERENCES_PASTE = (<FormattedMessage
    id="OBJECTCARD_CONFIRM_REFERENCES_PASTE"
    defaultMessage="References to be added:"
    description="Text of references paste confirm modal" />);

export const OBJECTCARD_INT_VALUE_TOO_BIG = (<FormattedMessage
    id="OBJECTCARD_INT_VALUE_TOO_BIG"
    defaultMessage="Value is too big for integer type"
    description="Value is too big for integer type" />);