import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Criteria from './criteria.jsx';
import { MSG_OR } from '../messages.jsx';
import { buttonsStyle, toggleButtonStyle, FINDER_SELECTION_CRITERIA, VIEW_TYPE } from '../../constants/finder';

export default class CriteriaBar extends Component {
    constructor(props) {
        super(props);

        this.removeCriteriaHandler = this.removeCriteriaHandler.bind(this);
        this.removeCriteriaRelationHandler = this.removeCriteriaRelationHandler.bind(this);
    }

    removeCriteriaRelationHandler(criteriaId, relationIdx) {
        this.props.removeCriteriaRelation(this.props.openModal, criteriaId, relationIdx);
    }

    removeCriteriaHandler(criteriaId) {
        this.props.removeCriteria(this.props.openModal, criteriaId);
    }

    printExpandBar() {
        return (
            <div className='cim-finder-table card nopadding col'>
                <div className="cim-finder-table-heading card-header cim-finder-expand-row nopadding bg-info" onClick={() => this.props.toggleHidden()}>
                    <div className="card-title font-weight-bold">
                        <FormattedMessage
                            id="FINDER_EXPAND_SELECTION"
                            defaultMessage="Expand selection"
                            description="Expand selection" />
                        ...
                    </div>
                    <span className="fa fa-window-maximize" style={toggleButtonStyle}>&nbsp;</span>
                </div>
            </div>);
    }

    printCriteriaTree() {
        if (!this.props.finder.criteriaTree) {
            return null;
        }
        return (
            <div className={"cim-finder-criteria-tree" + (this.props.view == VIEW_TYPE.EDIT ? " view-type-edit" : "")}>
                <div className='cim-finder-table card'>
                    <div className="cim-finder-table-heading card-header bg-info">
                        <h6 className="card-title font-weight-bold">
                            {FINDER_SELECTION_CRITERIA}
                        </h6>
                        <span className="fa fa-window-minimize" style={toggleButtonStyle} onClick={() => this.props.toggleHidden()}>&nbsp;</span>
                    </div>
                    <div className='cim-finder-table-body'>
                        <div className='cim-finder-table-header-row'>
                            <div className="col-md-7 cim-finder-table-header" style={{ borderRight: "none" }}>
                                <FormattedMessage
                                    id="FINDER_TABLE_PARAMETER"
                                    defaultMessage="Parameter"
                                    description="Parameter" />
                            </div>
                            <div className="col-md-5" style={{ display: "flex", borderLeft: "1px solid #ddd" }}>
                                <div className="col-md-5 cim-finder-table-header">
                                    <FormattedMessage
                                        id="FINDER_TABLE_RELATION"
                                        defaultMessage="Relation"
                                        description="Relation" />
                                </div>
                                <div className="col-md-5 cim-finder-table-header">
                                    <FormattedMessage
                                        id="FINDER_TABLE_VALUE"
                                        defaultMessage="Value"
                                        description="Value" />
                                </div>
                                <div className="col-md-2 cim-finder-table-header">
                                    <div class="btn-group" role="group">
                                        <button type="button" class={"col btn btn-secondary" + (this.props.view == VIEW_TYPE.EDIT ? " active" : "")} onClick={this.props.changeFinderViewType.bind(this, VIEW_TYPE.EDIT)}>
                                            <i className="fa fa-pencil" aria-hidden="true">&nbsp;</i>
                                        </button>
                                        <button type="button" class={"col btn btn-secondary" + (this.props.view == VIEW_TYPE.ADD ? " active" : "")} onClick={this.props.changeFinderViewType.bind(this, VIEW_TYPE.ADD)}>
                                            <i className="fa fa-plus" aria-hidden="true">&nbsp;</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.finder.criteriaGroupList.map((criteriaGroupId, index) => <div className='cim-finder-table-row-group'>
                            {index != 0 ?
                                <div className='cim-finder-table-row-or'>
                                    <span className='advancedGrayscale'>
                                        {MSG_OR}
                                    </span>
                                </div> : null}
                            <Criteria
                                key={index}
                                id={criteriaGroupId}
                                finder={this.props.finder}
                                finderOptions={this.props.finderOptions}
                                fetchFields={this.props.fetchFields}
                                fetchObjectcard={this.props.fetchObjectcard}
                                changeCriteriaRelation={this.props.changeCriteriaRelation}
                                addCriteriaRelation={this.props.addCriteriaRelation}
                                removeCriteriaRelation={this.removeCriteriaRelationHandler}
                                changeCriteriaField={this.props.changeCriteriaField}
                                addCriteria={this.props.addCriteria}
                                removeCriteria={this.removeCriteriaHandler}
                                removeCriteriaGroup={this.props.removeCriteriaGroup}
                                locale={this.props.locale}
                                view={this.props.view}
                                openModal={this.props.openModal}
                                addAlert={this.props.addAlert}
                            />
                            {this.props.view == VIEW_TYPE.ADD && <div className='cim-finder-table-row-buttons'>
                                <button type='button' className='btn btn-secondary btn-xs' onClick={() => { this.props.addCriteria(criteriaGroupId) }} style={buttonsStyle}>
                                    <span className="pull-left">
                                        <i className="fa fa-plus" aria-hidden="true">&nbsp;</i>
                                    </span>
                                    <FormattedMessage
                                        id="FINDER_ADD_AND_CRITERION"
                                        defaultMessage="AND"
                                        description="Label of add 'and' criterion button" />
                                </button>
                            </div>}
                        </div>)}
                    </div>
                </div>
                {this.props.view == VIEW_TYPE.ADD && <div style={{ marginTop: "5px", textAlign: "center" }}>
                    <button
                        type='button'
                        className='btn btn-secondary btn-xs'
                        onClick={() => { this.props.addCriteria() }}
                        style={buttonsStyle}>
                        <span className="pull-left">
                            <i className="fa fa-code-fork" aria-hidden="true">&nbsp;</i>
                        </span>
                        <FormattedMessage
                            id="FINDER_ADD_OR_CRITERION"
                            defaultMessage="OR"
                            description="Label of add 'or' criterion button" />
                    </button>
                </div>}
            </div>);
    }

    render() {
        if (this.props.finder.isHidden) {
            return this.printExpandBar();
        }
        return this.printCriteriaTree();
    }
}
