import * as Action from '../constants/i18n';
import { updateLocale } from '../services/i18n';

///////////
//Actions//
///////////
export function changeLocale(locale) {
    //Send to redux
    return {
        type: Action.CHANGE_LOCALE,
        payload: { locale: updateLocale(locale) }
    }
}