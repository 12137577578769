import { decorators } from '../npt-treebeard/index';
import TreeNodeHeader from './header.jsx';
import { loadingMessage } from '../npt-treebeard/common.jsx';

//Generate context aware decorators
export default function (treeProps) {

    let d = Object.assign({}, decorators);

    d.Header = (props) => {
        return (<TreeNodeHeader {...treeProps} {...props} loadingMessage={loadingMessage} />);
    };

    d.Toggle = (props) => {
        const style = props.style;
        const iconClass = 'fa fa-angle-right';
        return (
            <div style={style.base}>
                <div style={style.wrapper}>
                    <i className={iconClass} />
                </div>
            </div>
        );
    };

    return d;
};