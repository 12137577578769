import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';

class TripleHistory extends React.Component {

    constructor(props) {
        super(props);
    }

    getPredicate(triple) {
        if (!triple.predicate) {
            return "-";
        }
        return triple.predicate.label;
    }

    getPrevValue(triple) {
        if (!triple.prevValue) {
            return "-";
        }
        if (triple.prevValue.$label) {
            return triple.prevValue.$label;
        } else if (triple.predicate && triple.predicate.dataType) {
            switch (triple.predicate.dataType.name) {
                case "date": //date
                    return moment(triple.prevValue).format("L");
                case "dateTime": //dateTime
                    return moment(triple.prevValue).format("L LTS");
            }
        }
        return triple.prevValue;
    }

    getValue(triple) {
        if (!triple.value) {
            return "-";
        }
        if (triple.value.$label) {
            return triple.value.$label;
        } else if (triple.predicate && triple.predicate.dataType) {
            switch (triple.predicate.dataType.name) {
                case "date": //date
                    return moment(triple.value).format("L");
                case "dateTime": //dateTime
                    return moment(triple.value).format("L LTS");
            }
        }
        return triple.value;
    }

    render() {
        let tableData = this.props.data.map((triple) => {
            return {
                id: triple.id,
                name: this.getPredicate(triple),
                prevValue: this.getPrevValue(triple),
                value: this.getValue(triple)
            }
        })
        return (
            <div>
                <h4><FormattedMessage
                    id="SUBJECT_HISTORY_TRIPLE_HEADER"
                    defaultMessage="Predicate changes:"
                    description="Predicate changes table header" />
                </h4>
                <BootstrapTable version='4' data={tableData} striped={true} hover={true}>
                    <TableHeaderColumn isKey={true} hidden={true} dataField='id'>ID</TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='name'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_TRIPLE_PREDICATE_NAME"
                            defaultMessage="Name"
                            description="Name of predicate that has been changed" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='prevValue'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_TRIPLE_PREV_VALUE"
                            defaultMessage="Previous value"
                            description="Previous value of predicate" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='value'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_TRIPLE_VALUE"
                            defaultMessage="Value"
                            description="Value of predicate at the specified time" />
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default TripleHistory;


