import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {
    CLASSCARD_CLASS_HAVE_NO_RELATIONS,
    CLASSCARD_ID_OF_PREDICATE,
    CLASSCARD_NAME_OF_PREDICATE,
    CLASSCARD_TYPE_OF_RELATION,
    CLASSCARD_MULTIPLICITY,
    CLASSCARD_REVERSE_PREDICATE,
    CLASSCARD_LINK_TO_THE_CLASS
} from '../../../constants/classcard';
import { parsePredicate } from '../../../services/classcard';

const relationOptions = {
    noDataText: CLASSCARD_CLASS_HAVE_NO_RELATIONS
};

class RelationTable extends React.Component {

    constructor(props) {
        super(props);
        this.CLASSCARD_ASSOCIATION = this.props.messages["CLASSCARD_RELATION_TYPE_ASSOCIATION"];
        this.CLASSCARD_AGGREGATION = this.props.messages["CLASSCARD_RELATION_TYPE_AGGREGATION"];
        this.CLASSCARD_COMPOSITION = this.props.messages["CLASSCARD_RELATION_TYPE_COMPOSITION"];

        this.relationList = [];
        this.parsePredicateList(props, props.predicateList);

        this.relationLabelFormatter = this.relationLabelFormatter.bind(this);
        this.relationTypeFormatter = this.relationTypeFormatter.bind(this);
        this.multiplicityTypeFormatter = this.multiplicityTypeFormatter.bind(this);
        this.relationClassFormatter = this.relationClassFormatter.bind(this);
        this.reversePredicateFormatter = this.reversePredicateFormatter.bind(this);
    }

    parseRelationData(relationList) {
        for (let relation of relationList) {
            relation.relationId = relation.classRelationInfo.id;
            relation.relationType = relation.classRelationInfo.relationType;
            relation.reverseClassId = relation.classRelationInfo.peerClass.id;
            relation.reverseClassName = relation.classRelationInfo.peerClass.name;
            relation.reverseClassNamespace = relation.classRelationInfo.peerClass.namespace.prefix;
        }
    }

    parsePredicateList(props, dataList) {
        this.relationList = [];

        for (let data of dataList) {
            if (data.classRelationInfo) {
                let predicate = parsePredicate(data);
                if (predicate.classRelationInfo.inversePredicateInfo) {
                    predicate.reversePredicate = parsePredicate(predicate.classRelationInfo.inversePredicateInfo);
                    predicate.reversePredicate.classRelationInfo = predicate.classRelationInfo;
                    predicate.reversePredicate.reversePredicate = predicate;
                }
                this.relationList.push(predicate);
            }
        }
        this.parseRelationData(this.relationList);
    }

    labelClick(row, reactEvent) {
        reactEvent.stopPropagation();
        reactEvent.preventDefault();
        this.props.viewRelation(row);
    }

    relationLabelFormatter(cell, row) {
        return (<a href="" style={{ cursor: "pointer" }} onClick={this.labelClick.bind(this, row)} >
            {row.namespace && row.namespace.prefix}:{cell}
        </a >)
    }

    relationTypeFormatter(cell, row) {
        let relation = this.props.relationTypeByValue[cell];
        if (!relation) {
            return null;
        }
        let tooltip = this["CLASSCARD_" + relation.relation.toUpperCase()];
        return (<div className={"relation-" + relation.relation.toLowerCase()}>
            <button type="button" className="nobutton" data-toggle="tooltip" data-placement="top" title="" data-original-title={tooltip}>
                <span className="direction-arrow">&nbsp;</span>
            </button>
        </div>)
    }

    multiplicityTypeFormatter(cell, row) {
        return this.props.multiplicityByValue[cell] && this.props.multiplicityByValue[cell].template;
    }

    relationClassFormatter(cell, row) {
        return <a href={`#/?id=${cell}`}>
            {row.reverseClassNamespace}:{row.reverseClassName}
        </a>
    }

    reversePredicateFormatter(cell, row) {
        if (!cell) {
            return null;
        }
        return (<a href="" style={{ cursor: "pointer" }} onClick={this.labelClick.bind(this, cell)} >
            {cell.namespace && cell.namespace.prefix}:{cell.name}
        </a >)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.predicateList != nextProps.predicateList) {
            this.parsePredicateList(nextProps, nextProps.predicateList);
        }
    }

    render() {
        let _this = this;
        let relationSelectRowProp;
        if (this.props.noSelection) {
            relationSelectRowProp = {
                mode: "none"
            }
        } else {
            relationSelectRowProp = {
                mode: 'none',
                bgColor: '#e4e4e4',
                clickToSelect: false,
                onSelect: function (row, isSelected) {
                    isSelected ? _this.props.selectRows([row]) : _this.props.deselectRows([row]);
                },
                onSelectAll: function (isSelected, rows) {
                    isSelected ? _this.props.selectRows(rows) : _this.props.deselectRows(rows);
                },
                selected: this.props.selectedRows
            };
        }

        return <BootstrapTable
            version='4'
            className={"columns-normal-wrap"}
            maxHeight={this.props.height}
            width={this.props.width}
            data={this.relationList}
            striped={true}
            hover={true}
            options={relationOptions}
            selectRow={relationSelectRowProp}
            keyField='id'>
            <TableHeaderColumn dataField="relationId" dataSort={true}>
                {CLASSCARD_ID_OF_PREDICATE}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort={true} dataFormat={this.relationLabelFormatter}>
                {CLASSCARD_NAME_OF_PREDICATE}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="relationType" dataSort={true} dataFormat={this.relationTypeFormatter}>
                {CLASSCARD_TYPE_OF_RELATION}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="multiplicity" dataSort={true} dataFormat={this.multiplicityTypeFormatter}>
                {CLASSCARD_MULTIPLICITY}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="reverseClassId" dataSort={true} dataFormat={this.relationClassFormatter}>
                {CLASSCARD_LINK_TO_THE_CLASS}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="reversePredicate" dataSort={true} dataFormat={this.reversePredicateFormatter}>
                {CLASSCARD_REVERSE_PREDICATE}
            </TableHeaderColumn>
        </BootstrapTable>
    }
}

export default RelationTable;