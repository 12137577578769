import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import {
    CLASSCARD_ID_OF_PACKAGE,
    CLASSCARD_IDENTIFIER,
    CLASSCARD_LABEL
} from '../../../constants/classcard';

class ExportPopup extends React.Component {

    constructor(props) {
        super(props);
        this.selected = [];

        this.onSelect = this.onSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
    }

    onSelect(row, isSelected, event) {
        if (isSelected) {
            this.selected.push(row);
            return;
        }
        for (let i = 0; i < this.selected.length; ++i) {
            if (this.selected[i].id == row.id) {
                this.selected.splice(i, 1);
                return;
            }
        }
    }
    onSelectAll(isSelected, rows) {
        if (isSelected) {
            this.selected = rows.slice();
            return;
        }
        this.selected = [];
    }

    render() {
        let selectRowProp = {
            mode: "checkbox",
            bgColor: '#d4d4d4',
            clickToSelect: false,
            onSelect: this.onSelect,
            onSelectAll: this.onSelectAll,
        };

        console.log(this.props.packageList)

        return (
            <BootstrapTable version="4" data={this.props.packageList} striped={true} hover={true} selectRow={selectRowProp} maxHeight='700px' tableStyle={{ borderRadius: '0px' }} >
                <TableHeaderColumn dataField={"id"} isKey={true} dataSort={true}>{CLASSCARD_ID_OF_PACKAGE}</TableHeaderColumn>
                <TableHeaderColumn dataField={"name"} dataSort={true}>{CLASSCARD_IDENTIFIER}</TableHeaderColumn>
                <TableHeaderColumn dataField={"label"} dataSort={true}>{CLASSCARD_LABEL}</TableHeaderColumn>
            </BootstrapTable>);
    }
}

export default ExportPopup;


