export const MODAL = "modal";

//Actions
export const OPEN_MODAL = "@@MODAL_OPEN";
export const UPDATE_MODAL = "@@MODAL_UPDATE";
export const CLOSE_MODAL = "@@MODAL_CLOSE";

//Status
export const MODAL_STATUS_OK = "OK";
export const MODAL_STATUS_CANCEL = "CANCEL";
export const MODAL_STATUS_CLOSE = "CLOSE";