import * as Action from '../constants/srctree';
import CodePopup from '../components/srceditor/codepopup.jsx';
import { ajaxSaveCode, ajaxDeleteCode, waitForCode, composeEntityUrl } from './srceditor';
import { openNode, updateNode, receiveTree, receiveTreeError, waitForTree, plainToggleSubTree } from './npt-treebeard';
import { changeHash } from './location';
import { renamePath, getFilename, parseTreeData } from '../services/srctree';
import { addAlert } from './alert';
import { openModal } from './modal';
import { ALERT_SUCCESS, ALERT_DANGER } from '../constants/alert';
import { SRCEDITOR } from '../constants/srceditor';
import { LOCATION } from '../constants/location';

//Basic url for all ajax requests

/////////////////////
//Utility functions//
/////////////////////
function composeUrl(editorId) {
    return `/rest/${editorId}/list`;
}

function composeMoveUrl(editorId, path, newPath) {
    return `/rest/${editorId}/move?source=${path}&target=${newPath}`;
}

function composeCopyUrl(editorId, path, newPath) {
    return `/rest/${editorId}/copy?source=${path}&target=${newPath}`;
}

function ajaxFetchTree(editorId, dispatch) {
    console.log("AJAX call to fetch source tree");
    dispatch(waitForTree(Action.WAIT_FOR_TREE));
    return $.get(composeUrl(editorId), function (data) {
        dispatch(receiveTree(Action.RECEIVE_TREE, data, parseTreeData(data)));
    }).fail(function (error) {
        dispatch(receiveTreeError(Action.RECEIVE_TREE_ERROR, error));
    });
}

function ajaxDeleteDirectory(editorId, path, dispatch, onSuccess, onError) {
    console.log("AJAX call to remove directory");
    return $.ajax({
        type: 'DELETE',
        url: composeEntityUrl(editorId, path + "/")
    }).done(function () {
        dispatch(addAlert(ALERT_SUCCESS, { id: "SRC_EDITOR_DIRECTORY_REMOVE_SUCCESS", values: { path: path } }));
        if (typeof onSuccess == "function") {
            onSuccess();
        }
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "SRC_EDITOR_DIRECTORY_REMOVE_ERROR", values: { path: path } }));
        console.error("Error while directory removing: ", error);
        if (typeof onError == "function") {
            onError(error);
        }
    });
}

export function selectObject(node) {
    if (node.path && !node.inplace) {
        changeHash({ path: node.path });
    }
}

export function openCodePopup(dispatch, title, props, sourceTypes, onOk) {
    let nodePopup = null;
    let options = {
        title: title,
        body: (<CodePopup
            sourceTypes={sourceTypes}
            {...props}
            ref={(ref) => nodePopup = ref}
        />),
        onOk: (modal) => onOk(modal, nodePopup.state)
    }
    dispatch(openModal("codePopup", "common", options));
}

/* Create alternate path: file -> file (1)
 * Using to duplicate files */
function getAlternatePath(originalPath, nodeById) {
    let i = 1;
    let path = originalPath + "_" + i;
    while (nodeById[path]) {
        ++i;
        path = originalPath + "_" + i;
    }
    return path;
}

function ajaxSearchCode(editorId, directory, value, dispatch) {
    let searchParams = {};
    if (value) {
        searchParams.search = value;
        if (directory) {
            searchParams.path = directory;
        }
    }
    dispatch(searchCode(value));
    return $.get(composeUrl(editorId) + `?${$.param(searchParams)}`, function (data) {
        changeHash({ params: { path: directory, search: value } });
        dispatch(receiveTree(Action.RECEIVE_TREE, data, parseTreeData(data)));
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "SRC_TREE_SEARCH_ERROR" }));
    });
}

///////////
//Actions//
///////////
export function fetchTree(editorId) {
    return function (dispatch, getState) {
        const hashParams = getState()[LOCATION].params;
        if (hashParams.path || hashParams.search) {
            dispatch(search(editorId, hashParams.search, hashParams.path || null));
        } else {
            //Fetch subtree if needed
            ajaxFetchTree(editorId, dispatch);
        }
    }
}

export function addNode(editorId, path) {
    return function (dispatch, getState) {
        let node = parseTreeData([path])[0];
        const treeState = getState()[Action.SRCTREE];
        while (treeState.children[node.id] && node && node.children) {
            node = node.children[0];
        }
        if (!node) {
            console.error("Node at path \"" + path + "\" already exists.")
            ajaxFetchTree(dispatch, editorId);
            return;
        }
        node.parentId = getParentFolder(node.path);
        let child = node;
        node.directory = !!treeState.children[node.id];
        while (child && child.children) {
            child.directory = true;
            child = child.children[0];
        }
        const children = node.children;
        delete (node.children);
        dispatch(updateNode(Action.UPDATE_NODE, { id: node.id, node, children }, "add"));
    }
}

export function getParentFolder(path) {
    let folder = path.substring(0, path.lastIndexOf("/"));
    return folder;
}

function removeNode(editorId, id, directory) {
    return function (dispatch, getState) {
        const treeState = getState()[Action.SRCTREE];
        const editorState = getState()[SRCEDITOR][editorId];
        let parentId = getParentFolder(id);
        while (parentId != "" && treeState.children[parentId].length <= 1 && !treeState.nodeMap[parentId]) {
            id = parentId;
            parentId = getParentFolder(parentId);
        }
        dispatch(updateNode(Action.UPDATE_NODE, { id, directory }, "remove"));
        if (editorState && editorState.entity && editorState.entity.path && editorState.entity.path.indexOf(id) >= 0) {
            dispatch(waitForCode(editorId, null));
        }
    }
}

export function moveNode(editorId, oldPath, newPath) {
    return function (dispatch, getState) {
        const editorState = getState()[SRCEDITOR][editorId];
        dispatch(updateNode(Action.UPDATE_NODE, { id: oldPath, movePath: newPath }, "move"));
        if (editorState && editorState.entity && editorState.entity.path.indexOf(oldPath) >= 0) {
            let newActivePath = editorState.entity.path.replace(oldPath, newPath)
            dispatch(openNode(Action.OPEN_NODE, newActivePath));
            selectObject({ path: newActivePath }, dispatch);
        }
    }
}

export function copyNode(editorId, oldPath, newPath) {
    return function (dispatch, getState) {
        dispatch(updateNode(Action.UPDATE_NODE, { id: oldPath, movePath: newPath }, "copy"));
    }
}

export function ajaxAddCode(editorId, node, modal, dispatch) {
    ajaxSaveCode(editorId, node, modal, dispatch, function (data) {
        dispatch(addNode(editorId, data.path));
        selectObject({ path: data.path }, dispatch);
        dispatch(openNode(Action.OPEN_NODE, data.path));
    });
};

export function ajaxMoveNode(editorId, path, newPath, directory, dispatch) {
    console.log("AJAX call to move source code");
    return $.ajax({
        type: 'POST',
        url: composeMoveUrl(editorId, path + (directory ? "/" : ""), newPath + (directory ? "/" : ""))
    }).done(function () {
        dispatch(addAlert(ALERT_SUCCESS, { id: "SRC_EDITOR_CODE_MOVE_SUCCESS" }));
        dispatch(moveNode(editorId, path, newPath));
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "SRC_EDITOR_CODE_MOVE_ERROR" }));
        console.error("Error while code moving: ", error);
    });
}

export function tryChangeNodeCaption(editorId, path, newPath, directory) {
    return function (dispatch, getState) {
        const treeState = getState()[Action.SRCTREE];
        if (treeState.nodeById[newPath]) {
            dispatch(addAlert(ALERT_DANGER, { id: "SRC_EDITOR_CODE_ALREADY_EXIST", values: { path } }));
            dispatch(cancelEditNode(editorId, path));
        } else {
            ajaxMoveNode(editorId, path, newPath, directory, dispatch);
        }
    }
}

function ajaxCopyNode(editorId, path, newPath, directory, dispatch) {
    console.log("AJAX call to copy source code");
    return $.ajax({
        type: 'POST',
        url: composeCopyUrl(editorId, path + (directory ? "/" : ""), newPath + (directory ? "/" : ""))
    }).done(function () {
        dispatch(addAlert(ALERT_SUCCESS, { id: "SRC_EDITOR_CODE_COPY_SUCCESS" }));
        dispatch(copyNode(editorId, path, newPath));
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "SRC_EDITOR_CODE_COPY_ERROR" }));
        console.error("Error while code copying: ", error);
    });
}

export function copyCode(editorId, path, newPath, directory) {
    return function (dispatch, getState) {
        const treeState = getState()[Action.SRCTREE];
        if (treeState.nodeById[newPath]) {
            newPath = getAlternatePath(newPath, treeState.nodeById);
        }
        ajaxCopyNode(editorId, path, newPath, directory, dispatch);
    }
}

export function removeDirectory(editorId, path) {
    return function (dispatch, getState) {
        const options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: { id: "SRC_EDITOR_CONFIRM_REMOVE_DIRECTORY", values: { path: path } }
        }
        dispatch(openModal("confirmRemoveDirectory", "confirm", options, function () {
            ajaxDeleteDirectory(editorId, path, dispatch, function () {
                dispatch(removeNode(editorId, path, true));
            });
        }));
    }
};

export function removeCode(editorId, path) {
    return function (dispatch, getState) {
        const options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: { id: "SRC_EDITOR_CONFIRM_REMOVE_CODE", values: { path: path } }
        }
        dispatch(openModal("confirmRemoveCode", "confirm", options, function () {
            ajaxDeleteCode(editorId, path, dispatch, function () {
                dispatch(removeNode(editorId, path));
            });
        }));
    }
};

export function beginEditNode(editorId, path) {
    return {
        type: Action.EDIT_NODE_START,
        payload: { path: path }
    }
}

export function cancelEditNode(editorId, path) {
    return {
        type: Action.EDIT_NODE_CANCEL,
        payload: null
    }
}

export function search(editorId, value, path) {
    return function (dispatch, getState) {
        const treeState = getState()[Action.SRCTREE];
        let directory = path || null;
        if (typeof path == "undefined") {
            let activeNode = treeState.nodeById[treeState.active];
            if (activeNode && activeNode.directory && activeNode.directory != "/") {
                directory = treeState.active + "/";
            }
        }
        if (directory == "/") {
            directory = null;
        }
        ajaxSearchCode(editorId, directory, value, dispatch);
    }
}

export function searchCode(searchString) {
    return {
        type: Action.SEARCH_CODE,
        payload: { searchString }
    }
}