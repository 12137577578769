import * as Action from '../constants/navtree.js';
import {
    receiveInfoHandler,
    receiveTreeHandler,
    receiveTreeErrorHandler,
    toggleSubTreeHandler,
    toggleFilterHandler,
    updateNodeHandler,
    openNodeHandler
} from './npt-treebeard';
import { safeGetTree } from '../services/navtree.js';
import { generateSubjectId } from '../services/objectcard';

/* Map of trees */
const initialState = {};

////////////
//Reducers//
////////////
function updateNodesRdfIdMap(treeState, nodes) {
    if (!nodes || !nodes.length) {
        return;
    }
    treeState.nodeIdByRdfId = Object.assign({}, treeState.nodeIdByRdfId);
    for (let object of nodes) {
        if (object.data && object.data.$rdfId && object.id) {
            treeState.nodeIdByRdfId[generateSubjectId(object.data)] = object.id;
        }
    }
}

function receiveTreeError(state, payload) {
    /* Use default treebeard reducer */
    let treeState = receiveTreeErrorHandler(safeGetTree(state, payload.treeId), payload);
    return Object.assign({}, state, { [payload.treeId]: treeState });
}

function receiveInfo(state, payload) {
    /* Use default treebeard reducer */
    let treeState = receiveInfoHandler(safeGetTree(state, payload.treeId), payload);
    return Object.assign({}, state, { [payload.treeId]: treeState });
}

function receiveTree(state, payload) {
    /* Use default treebeard reducer */
    let treeState = receiveTreeHandler(safeGetTree(state, payload.treeId), payload);
    updateNodesRdfIdMap(treeState, payload.data)
    return Object.assign({}, state, { [payload.treeId]: treeState });
}

function toggleSubTree(state, payload) {
    /* Use default treebeard reducer */
    let treeState = toggleSubTreeHandler(safeGetTree(state, payload.treeId), payload);
    return Object.assign({}, state, { [payload.treeId]: treeState });
}

function toggleFilter(state, payload) {
    /* Use default treebeard reducer */
    let treeState = toggleFilterHandler(safeGetTree(state, payload.treeId), payload);
    return Object.assign({}, state, { [payload.treeId]: treeState });
}

function updateNode(state, payload) {
    /* Use default treebeard reducer */
    let treeState = updateNodeHandler(safeGetTree(state, payload.treeId), payload);
    updateNodesRdfIdMap(treeState, payload.children)
    return Object.assign({}, state, { [payload.treeId]: treeState });
}

function openNode(state, payload) {
    /* Use default treebeard reducer */
    let treeState = openNodeHandler(safeGetTree(state, payload.treeId), payload);
    return Object.assign({}, state, { [payload.treeId]: treeState });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.RECEIVE_INFO: return receiveInfo(state, action.payload);
        case Action.RECEIVE_TREE: return receiveTree(state, action.payload);
        case Action.RECEIVE_TREE_ERROR: return receiveTreeError(state, action.payload);
        case Action.TOGGLE_SUBTREE: return toggleSubTree(state, action.payload);
        case Action.TOGGLE_FILTER: return toggleFilter(state, action.payload);
        case Action.UPDATE_NODE: return updateNode(state, action.payload);
        case Action.OPEN_NODE: return openNode(state, action.payload);
        default: return state;
    }
}
