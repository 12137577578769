import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    SAVE_STATE_START,
    SAVE_STATE_UPLOADS_READY
} from '../../constants/objectcard';

class UploadStatus extends React.Component {

    constructor(props) {
        super(props);
        this.handleUploadProgress = this.handleUploadProgress.bind(this);
        this.state = { uploadProgress: null };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.saveState != this.props.saveState) {
            console.log("Upload save state", this.props.saveState);
            if (this.props.saveState == SAVE_STATE_START) {
                this.setState({ uploadProgress: 0 }); //Signal to show upload progress bar
                this.props.uploadFiles(this.handleUploadProgress);
            } else {
                this.setState({ uploadProgress: null }); //Signal to hide upload progress bar
            }
        }
    }

    //Total upload progress callback
    handleUploadProgress(uploadProgress) {
        if (!this.props.saveState) { //somebody clicked cancel
            return false;
        }
        this.setState({ uploadProgress });
        return true;
    }

    render() {
        if (!this.state.uploadProgress) {
            return null;
        }
        return (<div className="progress">
            <div className="progress-bar"
                role="progressbar"
                aria-valuenow={this.state.uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${this.state.uploadProgress}%` }}>
                <FormattedMessage
                    id="OBJECTCARD_UPLOAD_COMPLETED"
                    defaultMessage="{progress}% Completed"
                    description="Upload status"
                    values={{ progress: this.state.uploadProgress }} />
            </div>
        </div>);
    }
}

export default UploadStatus;


