import PropTypes from 'prop-types';


class Tag extends React.Component {

    constructor(props) {
        super(props);

        this.contentText = "";
        switch (typeof this.props.tag) {
            case "object":
                this.contentText = this.props.tag[props.displayField ? props.displayField : "label"];
                break;
            default:
                this.contentText = this.props.tag;
                break;
        }

        this.removeTag = this.removeTag.bind(this);
    }

    removeTag() {
        this.props.deleteHandler(this.props.tag);
    }

    render() {
        return (<button type="button" className="btn tag-block">
            {this.contentText}
            <button type="button" className="close" aria-label="Close" onClick={this.removeTag}>
                <span aria-hidden="false">&times;</span>
            </button>
        </button>);
    }
}

Tag.propTypes = {
    tag: PropTypes.any.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    displayField: PropTypes.string
};

export default Tag;    