import { getSizes, getPadding } from './services/filler'

const ATTR_FILLER = "cim-filler";

function setupSizes(elm, sizes) {
    let $elm = $(elm);
    let style = { padding: $elm.css("padding") };
    let padding = getPadding(style);
    let height = sizes.height - padding[0] - padding[2];
    let width = sizes.width - padding[1] - padding[3];
    if (height < 10) {
        height = 10;
    }
    if (width < 10) {
        width = 10;
    }
    $elm.height(height);
    $elm.width(width);
}

//Initialization function
export default function (dispatcher) {
    //Register components
    dispatcher.registerComponent(ATTR_FILLER, (elm) => {
        let $elm = $(elm);
        $elm.addClass("cim-filler");
        let parent = $elm.parent();

        /* Check type of filler (vertical (by default) or horizontal) */
        let horizontal = typeof $(elm).attr("horizontal") != "undefined" ? true : false;

        let handler = function (event) {
            let sizes = getSizes({ filler: elm, parent: parent[0], horizontal });
            setupSizes(elm, sizes);
        };
        $(window).on("resize.cimfiller", handler);
        $(elm).on("remove", function (event) {
            $(window).off("resize.cimfiller", handler);
        });
        handler();
        return null;
    });
};

