import React from 'react';
import { FormattedMessage } from 'react-intl';

const defaultPageSizes = [10, 25, 50, 100];

class Pagination extends React.Component {

    constructor(props) {
        super(props);

        this.pageSizes = this.getPageSizes(props);

        this.tryChangePage = this.tryChangePage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
    }

    getPageSizes(props = this.props) {
        if (!props.pagination || !props.pagination.selection) {
            return defaultPageSizes;
        }
        try {
            const paramSelection = props.pagination.selection.split(",");
            const parsedSelection = [];
            for (let item of paramSelection) {
                const intValue = parseInt(item);
                if (isNaN(intValue)) {
                    throw new Error("Selection values must be an integer");
                }
                parsedSelection.push(intValue);
            }
            return parsedSelection;
        } catch (e) {
            console.error(e);
            return defaultPageSizes;
        }
    }

    checkCurrentPageSize(props = this.props) {
        if (!props.pagination || !props.pagination.size) {
            return;
        }
        try {
            const pageSize = parseInt(props.pagination.size);
            if (isNaN(pageSize)) {
                throw new Error("Page size value must be an integer");
            }
            if (pageSize < 1) {
                throw new Error("Page size value must be >= 1");
            }
            if (pageSize != props.pageSize) {
                this.changePageSize(pageSize);
            }
        } catch (e) {
            console.error(e);
            return;
        }
    }

    tryChangePage(element, reactEvent) {
        let newPage = parseInt(element.value);
        if (newPage == this.props.page || isNaN(newPage)) {
            if (newPage != element.value) {
                this.setPageInputValue(this.props.page);
            }
            return;
        }
        if (newPage < 1 || newPage > this.props.totalPages) {
            this.setPageInputValue(this.props.page);
            return;
        }
        this.props.changePage(newPage);
    }

    setPageInputValue(value) {
        this.pageInputElement.value = value;
    }

    printPageSize() {
        const disabledControls = this.props.loadingPageData;
        return (<span className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="pageSizeMenu" data-toggle="dropdown" disabled={disabledControls}>{this.props.pageSize}</button>
            <div className="dropdown-menu" aria-labelledby="pageSizeMenu">
                {this.pageSizes.map((pageSize) => <a className={"dropdown-item" + (this.props.pageSize == pageSize ? " active" : "")} onClick={this.changePageSize.bind(this, pageSize)}>{pageSize}</a>)}
            </div>
        </span>);
    }

    changePageSize(pageSize) {
        if (pageSize == this.props.pageSize) {
            return;
        }
        this.props.changePageSize(pageSize);
    }

    printPageSizeInfo() {
        return (
            <div className="npt-table-pagination-info col-sm-6">
                <span className="form-group">
                    {this.printPageSize()}
                </span>
                <span className="form-group">
                    <FormattedMessage
                        id="NPT_TABLE_PER_PAGE"
                        defaultMessage="per page"
                        description="Table pagination text" />
                </span>
            </div>);
    }

    printPageInfo() {
        const total = this.props.totalDataLength || 0;
        const page = this.props.page || 1;
        let from = total == 0 ? 0 : (page - 1) * this.props.pageSize + 1;
        let to = Math.min(total, page * this.props.pageSize);
        return (
            <FormattedMessage
                id="NPT_TABLE_PAGE_INFO"
                defaultMessage="{from}-{to} of {total}"
                description="Table pagination info"
                values={{ from, to, total }} />);
    }

    printPaginationItem(text, callback, { cls = "", active, disabled }) {
        if (active) {
            cls += " active";
        }
        if (disabled) {
            cls += " disabled";
        }
        return <span className={"npt-table-pagination-item " + cls} onClick={active || disabled ? null : callback}>{text}</span>;
    }

    printPagination() {
        if (this.props.loading || this.props.loadingData || !this.props.totalPages) {
            return null;
        }
        let inputWidth = 15 + this.props.totalPages.toString().length * 10;
        if (this.pageInput) {
            this.pageInput.value = this.props.page;
        }
        const disabledControls = this.props.loadingPageData;
        const disabledLeft = disabledControls || this.props.page == 1;
        const disabledRight = disabledControls || this.props.page == this.props.totalPages;
        return (
            <div className="npt-table-pagination-panel col-sm-6 d-flex justify-content-end">
                <div className="form-group">
                    {this.printPageInfo()}
                </div>
                <div className="form-group">
                    {this.printPaginationItem("", this.props.changePage.bind(this, 1), { cls: 'i fa fa-angle-double-left', disabled: disabledLeft })}
                    {this.printPaginationItem("", this.props.changePage.bind(this, this.props.page - 1), { cls: 'i fa fa-angle-left', disabled: disabledLeft })}
                </div>
                <div className="form-group group-separate d-flex">
                    <div class="input-group input-group-sm">
                        <form onSubmit={this.tryChangePage.bind(this, this.pageInputElement)}>
                            <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                style={{ width: inputWidth }}
                                ref={(ref) => this.pageInput = ref}
                                defaultValue={this.props.page}
                                disabled={disabledControls}
                                onBlur={this.tryChangePage.bind(this, this.pageInputElement)} />
                        </form>
                    </div>
                    <FormattedMessage
                        id="NPT_TABLE_OF_PAGES"
                        defaultMessage="of"
                        description="Page number x OF y" />
                    <span> {this.props.totalPages}</span>
                </div>
                <div className="form-group">
                    {this.printPaginationItem("", this.props.changePage.bind(this, this.props.page + 1), { cls: 'i fa fa-angle-right', disabled: disabledRight })}
                    {this.printPaginationItem("", this.props.changePage.bind(this, this.props.totalPages), { cls: 'i fa fa-angle-double-right', disabled: disabledRight })}
                </div>
            </div>);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.page && this.props.page != nextProps.page) {
            this.setPageInputValue(nextProps.page);
        }
    }

    componentDidMount() {
        this.pageInputElement = ReactDOM.findDOMNode(this.pageInput);
        this.checkCurrentPageSize();
    }

    componentDidUpdate(prevProps) {
        if (!this.pageInputElement || this.props.pageable != prevProps.pageable) {
            this.pageInputElement = ReactDOM.findDOMNode(this.pageInput);
        }
    }

    render() {
        if (!this.props.pageable) {
            return null;
        }
        return (
            <div className="npt-table-pagination d-flex">
                {this.printPageSizeInfo()}
                {this.printPagination()}
            </div>
        );
    }
}

export default Pagination;
