import { TABLE } from './constants/table';
import tableReducer from './reducers/table';
import Table from './components/table/container.jsx';
import Legend from './components/table/gant/legend.jsx';
import { connectTable } from './services/table';

const ATTR_NPT_TABLE = "npt-table";
const ATTR_NPT_LEGEND = "npt-gant-legend";
const ATTR_CIM_TABLE = "cim-table";
const ATTR_CIM_GANT = "cim-gant";
const ATTR_CIM_LEGEND = "cim-gant-legend";
const ATTR_COLLAPSE_ON_RESIZE = "collapse-on-resize";

/********************* Initialization *********************/
export default function (dispatcher) {
    //Register reducer
    dispatcher.registerReducer(TABLE, tableReducer);
    //Register components
    dispatcher.registerComponent(ATTR_CIM_TABLE, (elm) => {
        const tableId = $(elm).attr(ATTR_CIM_TABLE);
        const collapseOnResize = Boolean(typeof $(elm).attr(ATTR_COLLAPSE_ON_RESIZE) != "undefined");
        const TableContainer = connectTable(Table);
        return (<TableContainer tableId={tableId} collapseOnResize={collapseOnResize} />);
    });
    dispatcher.registerComponent(ATTR_NPT_TABLE, (elm) => {
        const tableId = $(elm).attr(ATTR_NPT_TABLE);
        const collapseOnResize = Boolean(typeof $(elm).attr(ATTR_COLLAPSE_ON_RESIZE) != "undefined");
        const TableContainer = connectTable(Table);
        return (<TableContainer tableId={tableId} collapseOnResize={collapseOnResize} />);
    });
    dispatcher.registerComponent(ATTR_CIM_GANT, (elm) => {
        const tableId = $(elm).attr(ATTR_CIM_GANT);
        const collapseOnResize = Boolean(typeof $(elm).attr(ATTR_COLLAPSE_ON_RESIZE) != "undefined");
        const TableContainer = connectTable(Table);
        return (<TableContainer tableId={tableId} collapseOnResize={collapseOnResize} />);
    });
    dispatcher.registerComponent(ATTR_CIM_LEGEND, (elm) => {
        const tableId = $(elm).attr(ATTR_CIM_LEGEND);
        const LegendContainer = connectTable(Legend);
        return (<LegendContainer tableId={tableId} />);
    });
    dispatcher.registerComponent(ATTR_NPT_LEGEND, (elm) => {
        const tableId = $(elm).attr(ATTR_NPT_LEGEND);
        const LegendContainer = connectTable(Legend);
        return (<LegendContainer tableId={tableId} />);
    });
};

