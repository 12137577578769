import React from 'react';
import { Grid } from 'react-virtualized';

import CimFiller from '../../filler/cimfiller.jsx';

import GantHeader from './header.jsx';
import GantItem from './item.jsx';
import BackgroundRow from './background-row.jsx';
import { READ, PLANNED_DATE, CHOSEN_DATE } from '../../../constants/gant';
import { OVERSCAN_ROW_COUNT, DEFAULT_ROW_HEIGHT, VERTICAL, HORIZONTAL } from '../../../constants/table';

const gridInitialCls = "npt-gant-list text-dark";

class Gant extends React.Component {

    constructor(props) {
        super(props);

        this.scrollLeft = 0;
        this.actualWidth = 1000;

        this.hoveredScrollers = {
            [VERTICAL]: false,
            [HORIZONTAL]: false
        };

        this.calculateCellsWidth(this.props.cells, this.props.width);
        this.calculateActualWidth(this.parsedCells, this.props.cells, this.props.width);

        this.renderDefaultRow = this.renderDefaultRow.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
        this.noContentRenderer = this.noContentRenderer.bind(this);
        this.handleGridScroll = this.handleGridScroll.bind(this);
    }

    renderScaleCells() {
        if (!this.parsedCells[this.props.scale]) {
            return null;
        }
        return this.parsedCells[this.props.scale].map((cell) =>
            <div className="npt-gant-scale-cell" style={{ left: cell.left, width: cell.width }}>
            </div>)
    }

    changeItem(type, rowIndex, index, { from, to }) {
        this.props.changeItem(type, rowIndex, index, from, to);
    }

    addItem(rowIndex, { from, to }) {
        this.props.addItem(rowIndex, from, to);
    }

    removeItem(type, rowIndex, index) {
        this.props.removeItem(type, rowIndex, index);
    }

    printGantItemList(row, data, groups, rowIndex, type) {
        let offsetTop = this.props.rowPadding + (this.props.elementHeight + this.props.elementPadding) * rowIndex;
        return data.map((item, index) => <GantItem
            top={offsetTop}
            groups={groups}
            rowIndex={rowIndex}
            prevItem={data[index - 1]}
            item={item}
            nextItem={data[index + 1]}
            type={type}
            rowWidth={this.actualWidth}
            height={this.props.elementHeight}
            dateLimits={this.props.dateLimits}
            viewType={this.props.viewType}
            permissions={this.props.permissions}
            registerHandler={this.props.registerHandler}
            removeHandler={this.props.removeHandler}
            changeItem={this.changeItem.bind(this, type, row.originalRowIdx, index)}
            removeItem={this.removeItem.bind(this, type, row.originalRowIdx, index)}
            callContextMenu={this.props.callContextMenu}
        />)
    }

    printBackgroundRow(row) {
        return (
            <BackgroundRow
                row={row}
                singleElementOnRow={this.props.singleElementOnRow}
                viewType={this.props.viewType}
                permissions={this.props.permissions}
                rowWidth={this.actualWidth}
                dateLimits={this.props.dateLimits}
                registerHandler={this.props.registerHandler}
                removeHandler={this.props.removeHandler}
                addItem={this.addItem.bind(this, row.originalRowIdx)}
            />)
    }

    renderDefaultRow({ row }) {
        if (!row.gantData) {
            return null
        }
        return [
            this.printBackgroundRow(row),
            this.renderScaleCells(),
            this.printGantItemList(row, row.gantData.chosenDate, this.props.groups, 0, CHOSEN_DATE),
            this.printGantItemList(row, row.gantData.plannedDate, this.props.plannedGroups, 1, PLANNED_DATE)]
    }

    rowRenderer({
        isScrolling, // The Grid is currently being scrolled
        isVisible,   // This cell is visible within the grid (eg it is not an overscanned cell)
        key,         // Unique key within array of cells
        parent,      // Reference to the parent Grid (instance)
        rowIndex,    // Vertical (row) index of cell
        style        // Style object to be applied to cell (to position it);
        // This must be passed through to the rendered cell element.
    }) {
        let row = this.props.data[rowIndex];
        return <div key={key} className="npt-gant-row" style={style}>
            {this.renderDefaultRow({ row })}
        </div>
    }

    noContentRenderer() {
        const bordersWidth = 2;
        if (this.props.loadingData) {
            return <div className="npt-gant-row" style={{ textAlign: "center", width: this.actualWidth - bordersWidth, display: "block" }}>
                {this.props.messages["NPT_TABLE_LOADING"]}
            </div>;
        }
        return <div className="npt-gant-row" style={{ textAlign: "center", width: this.actualWidth - bordersWidth, display: "block" }}>
            {this.props.messages["NPT_TABLE_NO_DATA"]}
        </div>;
    }

    handleGridScroll({ clientHeight, clientWidth, scrollHeight, scrollLeft, scrollTop, scrollWidth }) {
        this.scrollLeft = scrollLeft;
        if (typeof this.props.onScroll == "function") {
            this.props.onScroll({ clientHeight, clientWidth, scrollHeight, scrollLeft, scrollTop, scrollWidth });
        }
        this.forceUpdate();
    }

    calculateCellsWidth(cells, headerWidth) {
        let offsetLeft;
        cells = Object.assign({}, cells);
        for (let scale in cells) {
            offsetLeft = 0;
            cells[scale] = cells[scale].slice();
            for (let i = 0; i < cells[scale].length; ++i) {
                let width = cells[scale][i].width * headerWidth;
                cells[scale][i] = Object.assign({}, cells[scale][i], { left: offsetLeft, width });
                offsetLeft += width;
            }
        }
        this.parsedCells = cells;
    }

    calculateActualWidth(parsedCells, cells, headerWidth) {
        let multiplier = 1;
        for (let scale in parsedCells) {
            if (scale != this.props.scale) {
                continue;
            }
            for (let cell of parsedCells[this.props.scale]) {
                if (cell.width < this.props.minCellWidth) {
                    multiplier = Math.max(multiplier, this.props.minCellWidth / cell.width);
                }
            }
        }
        this.actualWidth = headerWidth * multiplier;
        if (multiplier != 1) {
            this.calculateCellsWidth(cells, this.actualWidth);
        }
    }

    handleGridHover(reactEvent) {
        let gridContext = this;
        let hoverState = {
            [VERTICAL]: false,
            [HORIZONTAL]: false
        };
        let gridRect = gridContext.gridElement.getBoundingClientRect();
        let rightSide = gridRect.width + gridRect.x;
        if (rightSide - reactEvent.pageX < 10) {
            hoverState[VERTICAL] = true;
        }
        let bottomSide = gridRect.height + gridRect.y;
        if (bottomSide - reactEvent.pageY < 10) {
            hoverState[HORIZONTAL] = true;
        }
        if (gridContext.hoveredScrollers[VERTICAL] != hoverState[VERTICAL] || gridContext.hoveredScrollers[HORIZONTAL] != hoverState[HORIZONTAL]) {
            gridContext.hoveredScrollers = hoverState;
            gridContext.forceUpdate();
        }
    }

    setGridRef(ref) {
        this.grid = ref;
        this.gridElement = ReactDOM.findDOMNode(this.grid);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.width != nextProps.width
            || this.props.cells != nextProps.cells) {
            this.calculateCellsWidth(nextProps.cells, nextProps.width);
            this.calculateActualWidth(this.parsedCells, nextProps.cells, nextProps.width);
        }
    }

    render() {
        let gridCls = gridInitialCls + (this.hoveredScrollers[VERTICAL] ? " hoverVertical" : "") + (this.hoveredScrollers[HORIZONTAL] ? " hoverHorizontal" : "") + (this.props.viewType != READ ? " editable" : "");
        return ([
            <div className="npt-table-header col">
                <div className="row" style={{ width: this.actualWidth, height: this.props.headersHeight, position: "relative", left: -this.scrollLeft }}>
                    <GantHeader
                        width={this.actualWidth}
                        height={this.props.headersHeight}
                        scaleHeaders={this.props.scaleHeaders}
                        cells={this.parsedCells}
                        scrollLeft={this.scrollLeft}
                    />
                </div>
            </div >,
            <CimFiller onMouseMove={this.handleGridHover.bind(this)} updateDelay={15}>
                <Grid
                    className={gridCls}
                    data={this.props.loadingData ? [] : this.props.data}

                    scrollTop={this.props.scrollTop}

                    cellRenderer={this.rowRenderer}
                    noContentRenderer={this.noContentRenderer}

                    columnCount={1}
                    columnWidth={this.actualWidth}
                    rowCount={!this.props.loadingData && this.props.data ? this.props.data.length : null}
                    rowHeight={this.props.rowHeight || DEFAULT_ROW_HEIGHT}
                    overscanRowCount={this.props.overscanRowCount || OVERSCAN_ROW_COUNT}

                    onScroll={this.handleGridScroll}
                    ref={this.setGridRef.bind(this)}
                />
            </CimFiller>
        ]);
    }
}

export default Gant;
