import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getIcon, getSelectedNode, canBeBinded, isLinked } from '../../services/rpasetpoints';
import { READY, TAB_SETPOINTS, TAB_ANALOG, TAB_DISCRETE } from '../../constants/rpasetpoints';


class Toolbar extends React.Component {

    constructor(props) {
        super(props);

        this.checkDisabledUnbinding = this.checkDisabledUnbinding.bind(this);
        this.checkDisabledBinding = this.checkDisabledBinding.bind(this);

        this.state = {
            tabs: [
                {
                    id: TAB_ANALOG,
                    formattedId: "RPA_SETPOINTS_TAB_ANALOG"
                }, {
                    id: TAB_DISCRETE,
                    formattedId: "RPA_SETPOINTS_TAB_DISCRETE"
                }, {
                    id: TAB_SETPOINTS,
                    formattedId: "RPA_SETPOINTS_TAB_SETPOINTS"
                }
            ],
            items: [
                {
                    id: "additional",
                    type: "dropdown",
                    hideFunction: (props) => props.disabled || props.state != READY,
                    children: [
                        {
                            id: "config",
                            type: "button",
                            icon: "fa-cog",
                            formattedId: "RPA_SETPOINTS_CONFIGURATIONS",
                            hideFunction: (props) => props.state != READY,
                            callback: props.showConfig
                        }
                    ]
                },
                {
                    id: "file",
                    type: "dropdown",
                    icon: "fa-file",
                    formattedId: "RPA_SETPOINTS_FILE",
                    hideFunction: (props) => props.disabled,
                    children: [
                        {
                            id: "updateSetpoints",
                            type: "button",
                            icon: "fa-refresh",
                            formattedId: "RPA_SETPOINTS_UPDATE_SETPOINTS",
                            hideFunction: (props) => props.state != READY,
                            callback: props.updateSetpointsFile
                        },
                        {
                            id: "updateSignals",
                            type: "button",
                            icon: "fa-refresh",
                            formattedId: "RPA_SETPOINTS_UPDATE_SIGNALS",
                            hideFunction: (props) => props.state != READY,
                            callback: props.updateSignalsFile
                        },
                        {
                            id: "importFile",
                            type: "button",
                            icon: "fa-upload",
                            formattedId: "RPA_SETPOINTS_IMPORT_FILE",
                            callback: props.importFile
                        },
                        {
                            id: "fileReset",
                            type: "button",
                            icon: "fa-trash",
                            formattedId: "RPA_SETPOINTS_RESET_FILE",
                            hideFunction: (props) => props.state != READY,
                            callback: props.confirmFileReset
                        }
                    ]
                },
                {
                    id: "download",
                    type: "button",
                    icon: "fa-download",
                    formattedId: "RPA_SETPOINTS_DOWNLOAD",
                    disabledFunction: (props) => props.state != READY,
                    ignoreCommonDisabled: true,
                    callback: props.saveToFileSystem
                },
                {
                    id: "save",
                    type: "button",
                    icon: "fa-save",
                    formattedId: "RPA_SETPOINTS_SAVE",
                    disabledFunction: (props) => props.state != READY,
                    hideFunction: (props) => props.embedded,
                    callback: props.saveToServer
                },
                {
                    id: "detailedView",
                    type: "checkbox",
                    icon: "fa-id-card",
                    formattedId: "RPA_SETPOINTS_DETAILED_VIEW",
                    checkedFunction: (props) => props.detailedView,
                    // hideFunction: (props) => !props.superUser,
                    callback: props.changeDetailedView
                },
                {
                    id: "onlyNotBinded",
                    type: "checkbox",
                    icon: "fa-filter",
                    formattedId: "RPA_SETPOINTS_NOT_BINDED",
                    checkedFunction: (props) => props.bindedFlag,
                    callback: props.changeBindedFlag
                },
                {
                    id: "winthoutConfirm",
                    type: "checkbox",
                    icon: "fa-check-circle",
                    formattedId: "RPA_SETPOINTS_WITHOUT_CONFIRM",
                    checkedFunction: (props) => props.withoutConfirmation,
                    hideFunction: (props) => props.disabled,
                    callback: props.changeConfirmationRequired
                },
                {
                    id: "unbindSetpoints",
                    type: "button",
                    icon: "fa-chain-broken",
                    formattedId: "RPA_SETPOINTS_UNBIND",
                    disabledFunction: this.checkDisabledUnbinding,
                    hideFunction: (props) => props.disabled,
                    callback: props.unbindSetpoints
                },
                {
                    id: "bindSetpoints",
                    type: "button",
                    icon: "fa-link",
                    formattedId: "RPA_SETPOINTS_BIND",
                    disabledFunction: this.checkDisabledBinding,
                    hideFunction: (props) => props.disabled,
                    callback: props.bindSetpoints
                }
            ]
        }
    }

    checkDisabledUnbinding(props) {
        if (this.props.detailedView) {
            return true;
        }
        const sourceNode = getSelectedNode(props.source);
        const targetNode = getSelectedNode(props.target);
        return !isLinked(sourceNode, targetNode);
    }

    checkDisabledBinding(props) {
        if (this.props.detailedView) {
            return true;
        }
        const sourceNode = getSelectedNode(props.source);
        const targetNode = getSelectedNode(props.target);
        if (!sourceNode || !targetNode) {
            return true;
        }
        const sourceParent = props.source.nodeById[sourceNode.parentId];
        const targetParent = props.target.nodeById[targetNode.parentId];
        return !canBeBinded(sourceNode, targetNode, sourceParent, targetParent);
    }

    isDisabled(item) {
        let disabled = item.disabled || false;
        if (this.props.disabled && !item.ignoreCommonDisabled) {
            disabled = true;
        } else if (item.disabledFunction) {
            disabled = item.disabledFunction(this.props);
        }
        return disabled;
    }

    printName(item) {
        if (item.formattedId) {
            return <FormattedMessage id={item.formattedId} />;
        }
        return item.label || item.name;
    }

    printLink(item) {
        if (item.hideFunction && item.hideFunction(this.props)) {
            return null;
        }
        return (
            <a className="dropdown-item" onClick={item.callback}>
                {getIcon(item.icon)} {this.printName(item)}
            </a>
        );
    }

    printDropdown(item) {
        let disabled = this.isDisabled(item);
        return (
            <div key={item.id} className="form-group">
                <div className="btn-group">
                    <button type="button" className={"btn btn-secondary dropdown-toggle" + (disabled ? " disabled" : "")} data-toggle="dropdown">
                        {getIcon(item.icon)} {this.printName(item)}
                    </button>

                    <div className="dropdown-menu">
                        {item.children.map((child) => this.printLink(child))}
                    </div>
                </div>


            </div>);
    }

    printButton(item) {
        let disabled = this.isDisabled(item);
        return (
            <div key={item.id} className="form-group">
                <button type="button" className={"btn btn-secondary" + (disabled ? " disabled" : "")} onClick={item.callback}>
                    {getIcon(item.icon)} {this.printName(item)}
                </button>
            </div>);
    }

    printCheckbox(item) {
        return (
            <div key={item.id} className="form-group">
                <div className="input-group">
                    <span style={{ padding: "2px" }} className="input-group-prepend input-group-text" >
                        {getIcon(item.icon)} {this.printName(item)}
                    </span>
                    <div className="form-control" style={{ padding: "6px 2px" }}>
                        <input type="checkbox" checked={item.checkedFunction ? item.checkedFunction(this.props) : item.checked} onChange={item.callback} />
                    </div>
                </div>
            </div>);
    }

    printItem(item) {
        if (item.hideFunction && item.hideFunction(this.props)) {
            return null;
        }
        switch (item.type) {
            case "dropdown":
                return this.printDropdown(item);
            case "checkbox":
                return this.printCheckbox(item);
            case "button":
            default:
                return this.printButton(item);
        }
    }

    printTab(tab) {
        let active = this.props.activeTab == tab.id;
        return (
            <button type="button" className={"btn btn-secondary" + (active ? " active" : "")} onClick={this.props.changeTab.bind(this, tab.id)}>
                {getIcon(tab.icon)} {<FormattedMessage id={tab.formattedId} />}
            </button>
        );
    }

    printTabs() {
        if (this.props.state != READY) {
            return null;
        }
        return (
            <div className="npt-rpasetpoints-toolbar-tabs form-group">
                {this.state.tabs.map((tab) => this.printTab(tab))}
            </div>
        );
    }

    render() {
        return (
            <div className={"npt-rpasetpoints-toolbar"}>
                {this.printTabs()}
                <form className={"form-inline col-md-12 nopadding"}>
                    {this.state.items.map((item) => this.printItem(item))}
                </form>
            </div>
        );
    }
}

export default Toolbar;