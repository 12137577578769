import LoginPanel from './components/login/loginpanel.jsx'
import { parseLoginInfo } from './services/login';
import { getContextPath } from './services/location';

//Get servlet context path
const contextPath = getContextPath();

const ATTR_LOGIN_FORM = "login-form";

function getLoginInfo() {
    const jsonBase64Encoded = $('meta[name=login-info]').attr("content");
    return parseLoginInfo(jsonBase64Encoded);
}

//Initialization function
export default (dispatcher) => {
    const loginInfo = getLoginInfo();
    //Register components
    dispatcher.registerComponent(ATTR_LOGIN_FORM, (elm) => {
        return (<LoginPanel {...loginInfo} contextPath={contextPath}/>);
    });
}

