import React from 'react';
import { FormattedMessage } from 'react-intl';

import Toolbar from "./toolbar.jsx";
import CardArea from "./cardarea/cardarea.jsx";
import CimFiller from '../filler/cimfiller.jsx';

class ClassCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = { treeId: null, nodeTypeId: null };
        this.selectedRows = [];

        this.selectRows = this.selectRows.bind(this);
        this.deselectRows = this.deselectRows.bind(this);
        this.deselectAllRows = this.deselectAllRows.bind(this);
    }

    selectRows(rows) {
        this.selectedRows = this.selectedRows.slice();
        for (let row of rows) {
            this.selectedRows.push(row.id);
        }
        this.forceUpdate();
    }

    deselectRows(rows) {
        this.selectedRows = this.selectedRows.slice();
        for (let row of rows) {
            for (let i = 0; i < this.selectedRows.length; ++i) {
                if (row.id == this.selectedRows[i]) {
                    this.selectedRows.splice(i, 1);
                    break;
                }
            }
        }
        this.forceUpdate();
    }

    deselectAllRows() {
        this.selectedRows = [];
        this.forceUpdate();
    }

    componentDidMount() {
        this.props.receiveEnumerations();
        this.props.receivePrimitiveTypes();
        this.props.receiveAllClasses();
        if (this.props.hashParams.id) {
            this.props.receiveClass(this.props.hashParams.id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.hashParams.id != nextProps.hashParams.id) {
            this.props.receiveClass(nextProps.hashParams.id);
        }
        if (this.props.classCard.data.predicateList != nextProps.classCard.data.predicateList && nextProps.classCard.tab == "predicateList") {
            /* Remove deleted rows from selection*/
            selectedRowsCycle: for (let i = this.selectedRows.length - 1; i >= 0; --i) {
                for (let predicate of nextProps.classCard.data.predicateList) {
                    if (predicate.id == this.selectedRows[i]) {
                        continue selectedRowsCycle;
                    }
                }
                this.selectedRows.splice(i, 1);
            }
        }
        if (this.props.classCard.relationList != nextProps.classCard.relationList && nextProps.classCard.tab == "relations") {
            /* Remove deleted rows from selection*/
            selectedRowsCycle: for (let i = this.selectedRows.length - 1; i >= 0; --i) {
                for (let relation of nextProps.classCard.relationList) {
                    if (relation.id == this.selectedRows[i]) {
                        continue selectedRowsCycle;
                    }
                }
                this.selectedRows.splice(i, 1);
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.classCard != nextProps.classCard
            || this.props.hashParams.id != nextProps.hashParams.id) {
            return true;
        }
        return false;
    }

    render() {
        switch (this.props.classCard.loadingState) {
            case "loading":
                return (
                    <div className="alert alert-warning" role="alert">
                        <FormattedMessage
                            id="CLASSCARD_CLASS_IS_LOADING"
                            defaultMessage="Please wait. Information about class is loading."
                            description="Please wait. Information about class is loading." />
                    </div>);
            case "error":
                return (
                    <div className="alert alert-danger" role="alert">
                        <FormattedMessage
                            id="CLASSCARD_LOADING_ERROR"
                            defaultMessage="Warning! Error while downloading class information. Try to reload page."
                            description="Warning! Error while downloading class information. Try to reload page." />
                    </div>);
            case "done":
                return (
                    <div className="npt-fill-size">
                        <Toolbar
                            contextPath={this.props.contextPath}
                            classData={this.props.classCard.data}
                            editClass={this.props.editClass}
                            removeClass={this.props.removeClass} />
                        <CardArea
                            {...this.props}
                            selectRows={this.selectRows}
                            deselectRows={this.deselectRows}
                            deselectAllRows={this.deselectAllRows}
                            selectedRows={this.selectedRows} />
                    </div>);
            default:
                return (
                    <div className="alert alert-info" role="alert">
                        <FormattedMessage
                            id="CLASSCARD_SELECT_CLASS"
                            defaultMessage="Select class to be shown in the card"
                            description="User should select class to be shown in the card" />
                    </div>);
        }
    }
}

export default ClassCard;