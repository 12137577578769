import * as Action from '../constants/navtree';
import { addAlert } from './alert';
import { changeHash } from './location';
import { ALERT_DANGER, ALERT_SUCCESS } from '../constants/alert';
import { getNavTreeState, safeGetTree, selectObject } from '../services/navtree';
import { EVENT_ADD_NEW_SUBJECT } from '../constants/objectcard.js';
import { composeUrl, composeFetchInfoUrl, composeFetchUrl, composeFetchPathUrl, composeAddUrl, composeMoveUrl } from '../services/npt-treebeard';
import { copyTextToClipboard } from '../services/clipboard';



/////////////////////
//Utility functions//
/////////////////////
function ajaxFetchInfo(treeId, dispatch) {
    console.log("AJAX call to fetchInfo");
    return $.get(composeFetchInfoUrl(treeId), function (info) {
        dispatch(receiveInfo(treeId, info));
    }).fail(function (error) {
        dispatch(receiveInfo(treeId, null));
    });
}

function checkFetchNeeded(globalState, treeId, id) {
    let treeState = safeGetTree(getNavTreeState(globalState), treeId);
    let loading, error;
    if (id != null) {
        if (!treeState.nodeById[id]) {
            return true;
        }
        loading = treeState.nodeById[id].loading;
        error = treeState.nodeById[id].error;
    } else {
        loading = treeState.loading;
        error = treeState.error;
    }
    return loading || error;
}

async function plainAjaxFetchSubTree(treeId, id) {
    return $.get(composeFetchUrl(treeId, id), function (children) {
        return children;
    }).fail(function (error) {
        return { error };
    });
}

async function ajaxFetchSubTree(treeId, id, dispatch, expandRoot) {
    //dispatch(waitForSubtree(treeId, id));
    const children = await plainAjaxFetchSubTree(treeId, id);
    if (children.error) {
        dispatch(receiveSubTreeError(treeId, id, children.error));
        return;
    }

    dispatch(receiveSubTree(treeId, id, children));
    if (id == null && expandRoot) {
        /* Expand root nodes */
        for (let i = children.length - 1; i >= 0; --i) {
            dispatch(toggleSubTree(treeId, children[i].id, true));
            if (i == 0) {
                selectObject(children[i]);
            }
        }
    }
}

function ajaxUpdateNode(treeId, node, dispatch) {
    console.log("AJAX call to updateNode");
    return $.get(composeFetchUrl(treeId, node.parentId), function (children) {
        for (let child of children) {
            if (child.id == node.id) {
                dispatch(updateNode({ treeId, id: child.id, node: child }));
            }
        }
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "NAVTREE_UPDATE_FAILED", values: { label: node.label } }))
    });
}

/* Open node with parents */
function ajaxExpandNode(globalState, treeId, rdfId, namespace, reload, dispatch) {
    console.log("AJAX call to expand node: " + rdfId);
    if (reload) {
        dispatch(waitForSubtree(treeId, null));
    }
    return $.get(composeFetchPathUrl(treeId, rdfId, namespace), function (path) {
        /* Path comes in reverse order (path[0] == node) */
        const nodeId = path[0] || null;
        /* Add root to fetching array */
        path.push(null);
        /* Remove from array already fetched nodes */
        for (let i = path.length - 1; i >= 0; --i) {
            if (!checkFetchNeeded(globalState, treeId, path[i])) {
                path.splice(i, 1);
            }
        }
        /* If array is empty -> we don't need fetch anything */
        if (path.length == 0) {
            dispatch(openSubTree(treeId, nodeId));
            return;
        }
        /* Reverse order and create list of nodes for fetching */
        path = path.reverse();
        const requestList = path.reduce(function (lst, id) {
            lst.push($.get(composeFetchUrl(treeId, id)));
            return lst;
        }, []);
        /* Download all nodes */
        $.when.apply($, requestList).done(function () {
            /* Update childrens of all parent nodes and open last node */
            for (let i = 0; i < path.length - 1; ++i) {
                let children = arguments[i][0];
                dispatch(receiveSubTree(treeId, path[i], children));
            }
            /* Open node */
            dispatch(openSubTree(treeId, nodeId));
        });
    }).fail(function (error) {
        ajaxFetchSubTree(treeId, null, dispatch); //Fallback!
    });
}

function ajaxAddChild(treeId, id, addId, params, dispatch) {
    console.log("AJAX call to add child");
    return $.get(composeAddUrl(treeId, id, addId, params), function (data) {
        //Send event to objectcard
        let event = {
            notifyId: id,
            data: data
        };
        $(document).trigger(EVENT_ADD_NEW_SUBJECT, event);
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "NAVTREE_ADD_FAILED" }))
    });
}

function ajaxMoveChild(treeId, id, addId, sourceId, dispatch) {
    console.log("AJAX call to move child");
    return $.ajax({
        type: 'POST',
        url: composeMoveUrl(treeId, id, addId, sourceId),
        success: function (data) {
            //Fetch parent
            let parentId = data.sourceParentNodeId;
            setTimeout(function () { //Wait for server to update cache
                ajaxFetchSubTree(treeId, parentId, dispatch).then(function () {
                    ajaxFetchSubTree(treeId, id, dispatch)
                })
            }, 300);
        },
        error: function (error) {
            dispatch(addAlert(ALERT_DANGER, { id: "NAVTREE_MOVE_FAILED" }))
        }
    });
}

function ajaxDeleteNode(treeId, id, isSelectedNode, dispatch) {
    console.log("AJAX call to delete node");
    return $.ajax({
        type: 'DELETE',
        url: composeFetchUrl(treeId, id),
        success: function (data) {
            //Fetch parent
            let parentId = data.parentId;
            setTimeout(function () { //Wait for server to update cache
                if (isSelectedNode) {
                    changeHash({ params: { object: "", namespace: null } });
                }
                ajaxFetchSubTree(treeId, parentId, dispatch);
            }, 300);
        },
        error: function (error) {
            dispatch(addAlert(ALERT_DANGER, { id: "NAVTREE_DELETE_FAILED" }))
        }
    });
}

function setupDirectories(children) {
    if (!children) {
        return;
    }
    for (let child of children) {
        if (child.leaf) {
            continue;
        }
        child.children = [];
        child.loading = true;
    }
}

function getNamespace(node) {
    if (!node.data || !node.data.$namespace) {
        return null;
    }
    switch (typeof node.data.$namespace) {
        case "string":
            return node.data.$namespace;
        case "object":
            return node.data.$namespace.$prefix;
    }
    return null;
}

async function recursiveGetNodeRef(globalState, treeId, nodeId, fetchMap, fetchList, refs, fetchNodeData = null) {
    const treeState = safeGetTree(getNavTreeState(globalState), treeId);
    if (fetchMap[nodeId]) {
        return null;
    }
    const node = treeState.nodeById[nodeId] || fetchNodeData;
    if (node.data && node.data.$rdfId) {
        refs.push({ $namespace: getNamespace(node), $rdfId: node.data.$rdfId });
    }
    let children;
    let fetchData;
    if (checkFetchNeeded(globalState, treeId, nodeId)) {
        fetchData = await plainAjaxFetchSubTree(treeId, nodeId);
        if (fetchData.error) {
            throw new Error(`Can't receive tree node with id "${nodeId}"`);
        }
        fetchMap[nodeId] = fetchData;
        fetchList.push({ id: nodeId });
        children = fetchMap[nodeId];
    } else {
        children = treeState.children[nodeId];
        fetchMap[nodeId] = children;
    }
    if (!children) {
        return null;
    }
    let promises = [];
    for (let child of children) {
        const childId = fetchData ? child.id : child;
        const childData = fetchData ? child : null;
        const promise = recursiveGetNodeRef(globalState, treeId, childId, fetchMap, fetchList, refs, childData);
        if (promise != null) {
            promises.push(promise);
        }
    }
    if (promises.length != 0) {
        await Promise.all(promises);
    }
    return null;
}


///////////
//Actions//
///////////
function receiveTree(treeId, data) {
    return {
        type: Action.RECEIVE_TREE,
        payload: { treeId, data }
    }
}

function receiveTreeError(treeId, error = true) {
    return {
        type: Action.RECEIVE_TREE_ERROR,
        payload: { treeId, error }
    }
}

function updateNode({ treeId, id, node, loading, error, children }) {
    return {
        type: Action.UPDATE_NODE,
        payload: { treeId, id, node, loading, error, children }
    }
}

function receiveInfo(treeId, info) {
    return {
        type: Action.RECEIVE_INFO,
        payload: { treeId, info }
    }
}

function receiveSubTree(treeId, id, children) {
    setupDirectories(children);
    if (id == null) {
        return {
            type: Action.RECEIVE_TREE,
            payload: { treeId, data: children }
        }
    }
    return updateNode({ treeId, id, loading: false, error: false, children });
}

function receiveSubTreeError(treeId, id, error = true) {
    if (id == null) {
        return {
            type: Action.RECEIVE_TREE_ERROR,
            payload: { treeId, error }
        }
    }
    return updateNode({ treeId, id, loading: false, error });
}

function waitForSubtree(treeId, id) {
    return updateNode({ treeId, id, loading: true, error: false });
}

function doToggleSubTree(treeId, id, toggled) {
    return {
        type: Action.TOGGLE_SUBTREE,
        payload: { treeId, id, toggled }
    }
}

export function toggleFilter(treeId, filter, value) {
    return {
        type: Action.TOGGLE_FILTER,
        payload: { treeId, filter, value }
    }
}

export function openSubTree(treeId, id) {
    return {
        type: Action.OPEN_NODE,
        payload: { treeId, id }
    }
}

function receiveAddChild(treeId, id, childId, data) {
    return {
        type: Action.RECEIVE_SUBTREE,
        payload: { treeId, id, data }
    }
}

export function fetchInfo(treeId) {
    return function (dispatch, getState) {
        ajaxFetchInfo(treeId, dispatch);
    }
}

export function fetchSubTree(treeId, id, expandRoot) {
    return function (dispatch, getState) {
        //Fetch subtree if needed
        if (checkFetchNeeded(getState(), treeId, id)) {
            ajaxFetchSubTree(treeId, id, dispatch, expandRoot);
        }
    }
}

export function expandNode(treeId, rdfId, namespace, reload) {
    return function (dispatch, getState) {
        //Fetch subtree if needed
        const globalState = getState();
        if (checkFetchNeeded(globalState, treeId, rdfId)) {
            ajaxExpandNode(globalState, treeId, rdfId, namespace, reload, dispatch);
        }
    }
}

export function toggleSubTree(treeId, id, toggled) {
    return function (dispatch, getState) {
        //Toggle subtree
        dispatch(doToggleSubTree(treeId, id, toggled));
        //Fetch subtree if needed
        if (checkFetchNeeded(getState(), treeId, id)) {
            ajaxFetchSubTree(treeId, id, dispatch);
        }
    }
}

export function addChild(treeId, id, addId, sourceId, prototype) {
    return function (dispatch, getState) {
        ajaxAddChild(treeId, id, addId, { copy: sourceId, prototype }, dispatch);
    };
}

export function moveChild(treeId, id, addId, sourceId) {
    return function (dispatch, getState) {
        ajaxMoveChild(treeId, id, addId, sourceId, dispatch);
    };
}

export function subjectsChanged(subjectList, notifyId) {
    return function (dispatch, getState) {
        console.log("Navigation tree actions (notify id): ", notifyId);
        console.log("Navigation tree actions (subjects where changed): ", subjectList);
        let allTrees = getNavTreeState(getState());
        for (let treeId in allTrees) {
            let treeState = allTrees[treeId];
            for (let subjectId of subjectList) {
                /* Update parent node childrens */
                const nodeId = treeState.nodeIdByRdfId[subjectId];
                if (!nodeId) {
                    continue;
                }
                const node = treeState.nodeById[nodeId];
                if (!node) {
                    continue;
                }
                ajaxUpdateNode(treeId, node, dispatch);
            }
            if (notifyId) {
                const node = treeState.nodeById[notifyId];
                if (!node.loading) {
                    console.log("Updated from notifyId : " + notifyId);
                    ajaxFetchSubTree(treeId, notifyId, dispatch);
                }
            }
        }
    }
}

export function deleteNode(treeId, id, isSelectedNode) {
    return function (dispatch, getState) {
        ajaxDeleteNode(treeId, id, isSelectedNode, dispatch);
    };
}

export function copyNodeRef(treeId, node) {
    return async function (dispatch, getState) {
        const ref = JSON.stringify([{ "$rdfId": node.data.$rdfId, "$namespace": node.data.$namespace || null }]);

        if (copyTextToClipboard(ref)) {
            dispatch(addAlert(ALERT_SUCCESS, { id: "NAVTREE_REF_COPY_SUCCESS" }));
        } else {
            dispatch(addAlert(ALERT_DANGER, { id: "NAVTREE_REF_COPY_ERROR" }));
        }
    };
}

export function copyNodeWithDescendantsRefs(treeId, nodeId) {
    return async function (dispatch, getState) {
        const fetchMap = {};
        const fetchList = [];
        let nodeWithDescendantsRefs = [];
        try {
            await recursiveGetNodeRef(getState(), treeId, nodeId, fetchMap, fetchList, nodeWithDescendantsRefs);
        } catch (ex) {
            console.error(ex);
            dispatch(addAlert(ALERT_DANGER, { id: "NAVTREE_REF_COPY_ERROR" }));
            return;
        }

        if (copyTextToClipboard(JSON.stringify(nodeWithDescendantsRefs))) {
            dispatch(addAlert(ALERT_SUCCESS, { id: "NAVTREE_REF_COPY_SUCCESS" }));
        } else {
            dispatch(addAlert(ALERT_DANGER, { id: "NAVTREE_REF_COPY_ERROR" }));
        }

        for (let item of fetchList) {
            dispatch(receiveSubTree(treeId, item.id, fetchMap[item.id]));
        }
    };
}