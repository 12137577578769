import React from 'react';
import { FormattedMessage } from 'react-intl';

class PasswordGroup extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }

        this.onChange = this.onChange.bind(this);
        this.toggleVisible = this.toggleVisible.bind(this);
    }

    onChange(event) {
        this.props.onChange(event.target.value);
    }

    toggleVisible(event) {
        this.setState({ visible: !this.state.visible });
    }

    /* Use style props due to outdated bootstrap */
    render() {
        return (<div className="form-group">
            <label htmlFor="passwordInput">
                <FormattedMessage
                    id="LOGIN_PANEL_PASSWORD_LABEL"
                    defaultMessage="Password:"
                    description="Label for password field" />
            </label>
            <div className="input-group" style={{ display: "flex" }}>
                <input id="passwordInput" type={this.state.visible ? "text" : "password"} className="form-control" name="password" ref={this.props.setupInput} onChange={this.onChange} style={{ width: "1%", flex: "1 1 auto" }} />
                <div>
                    <button type="button" style={{ height: "100%", marginLeft: "-1px" }} onClick={this.toggleVisible}>
                        <i className={"fa fa-fw " + (this.state.visible ? "fa-eye-slash" : "fa-eye")} />
                    </button>
                </div>
            </div>
        </div>);
    }
}

export default PasswordGroup;