import * as LocationConstants from '../constants/location';

/////////////////
//   Utility   //
/////////////////
function buildHash(data) {
    var hashString = "";
    for (var p in data.params) {
        if (hashString != "") {
            hashString += "&";
        }
        hashString += p + "=" + data.params[p];
    }
    return data.path + "?" + hashString;
}

/////////////////
//   Actions   //
/////////////////
export function changeHash(params) {
    return function (dispatch, getState) {
        const hashData = Object.assign({}, getState()[LocationConstants.LOCATION]);
        hashData.path = hashData.path || "";
        hashData.params = Object.assign({}, hashData.params);

        for (var p in params) {
            if (params[p] == null) {
                delete (hashData.params[p]);
                continue;
            }
            hashData.params[p] = params[p];
        }
        window.location.hash = buildHash(hashData);
    }
}