import React from 'react';
import { DragSource, DropTarget } from 'react-dnd'
import { ContextMenuTrigger } from "react-contextmenu";
import { MENU_ID } from '../../constants/navtree';
import InlineEdit from 'react-edit-inplace';
import { noLabelMessage, CAPTION_CHANGED, MOVE_NODE } from '../npt-treebeard/common.jsx';

const NODE_DND_ID = "TreeNodeHeader";

let ctrlPressed = false;

function generateCollect(result) {
    return function (data, isCopy) {
        result.collected = data;
        result.isCopy = isCopy;
    }
}

const dragSource = {
    canDrag(props, monitor) {
        console.log(props)
        if (!props.getCollectNodeAction || props.inplace) {
            return false;
        }
        const result = { treeId: props.treeId };
        const action = props.getCollectNodeAction(props, generateCollect(result), ctrlPressed);
        if ($.type(action) != 'object') {
            return false;
        }
        action.onAction();
        if (result.collected) { //Test if collected data exists
            return true;
        }
        return false;
    },
    beginDrag(props) {
        const result = { treeId: props.treeId };
        const action = props.getCollectNodeAction(props, generateCollect(result), ctrlPressed);
        action.onAction();
        return result;
    }
};

const dragTarget = {
    canDrop(props, monitor) {
        const item = monitor.getItem();
        if (props.treeId != item.treeId) {
            return false;
        }
        //console.log("Can drop:", item);
        let pasteAction = null;
        if (item.isCopy) {
            pasteAction = props.getCopyAction(item.collected, props);
        } else {
            pasteAction = props.getCutAction(item.collected, props);
        }
        if ($.type(pasteAction) != 'object') {
            return false;
        }
        return true;
    },
    drop(props, monitor) {
        const item = monitor.getItem();
        if (props.treeId != item.treeId) {
            return {};
        }
        let pasteAction = null;
        if (item.isCopy) {
            pasteAction = props.getCopyAction(item.collected, props);
        } else {
            pasteAction = props.getCutAction(item.collected, props);
        }
        if ($.type(pasteAction) != 'object') {
            return {};
        }
        const options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: { id: item.isCopy ? "SRC_TREE_CONFIRM_COPY_NODE" : "SRC_TREE_CONFIRM_MOVE_NODE", values: { path: props.label } }
        }
        props.openModal("confirmPaste", "confirm", options, () => pasteAction.onAction());
        /*If you return an object, it is going to become the drop result and will be available to the drag source in its endDrag method as monitor.getDropResult().*/
        return { treeId: props.treeId, nodeId: props.id };
    }
}

function collectDrag(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

function collectDrop(connect) {
    return {
        connectDropTarget: connect.dropTarget()
    }
}

function collectContextMenuData(props) {
    const nodeId = props.id;
    return function () {
        return { nodeId };
    }
}

class TreeNodeHeader extends React.Component {

    constructor(props) {
        super(props);

        this.checkCtrl = this.checkCtrl.bind(this);
        this.captionChanged = this.captionChanged.bind(this);
        this.handleInlineEdit = this.handleInlineEdit.bind(this);
    }

    checkCtrl(reactEvent) {
        ctrlPressed = reactEvent.ctrlKey;
    }

    captionChanged({ caption }) {
        const data = {
            treeId: this.props.treeId,
            nodeId: this.props.id,
            directory: this.props.directory,
            caption: caption
        };
        this.props.changeNodeCaption(data);
        $(document).trigger(CAPTION_CHANGED + "." + this.props.treeId, data);
    }

    /* Manual change value of input after filters */
    handleInputChange(ref, event) {
        let value = event.target.value.trim();
        if (this.props.allowedSymbols) {
            let reg = new RegExp("[^" + this.props.allowedSymbols + "]", "g");
            value = value.replace(reg, "");
        }
        event.target.value = value;
        ref.setState({
            text: event.target.value
        });
    }

    handleInlineEdit(ref) {
        if (!ref) {
            return;
        }
        /* Setup edit cancel */
        ref.cancelEditing = this.props.cancelRenameFile.bind(this, { id: this.props.id });
        ref.textChanged = this.handleInputChange.bind(this, ref);
    }

    render() {
        const style = Object.assign({}, this.props.style, this.props.customStyle);
        const isBranch = typeof this.props.directory == "undefined" ? !!this.props.children : this.props.directory;
        let iconType = this.props.icon || (isBranch ? 'fa-folder' : 'fa-file-text');
        let iconColor = this.props.iconColor || (isBranch ? '#ddd649' : '#CCC');
        if (this.props.error || this.props.e) {
            iconType = 'fa-warning';
            iconColor = '#F00';
        }
        let nodeCaption = this.props.label ? this.props.label : this.props.name;
        if (!nodeCaption) {
            nodeCaption = noLabelMessage;
        } else if (this.props.inplace) {
            nodeCaption = (<InlineEdit
                editing={true}
                text={nodeCaption}
                paramName="caption"
                change={this.captionChanged}
                ref={this.handleInlineEdit}
                onBlur={() => alert("BLUR")} />);
        }
        const iconClass = `fa ${iconType} fa-large`;
        const iconStyle = Object.assign({ marginRight: '5px', color: iconColor }, style.icon);

        if (typeof this.props.id == "undefined") { //Initial node state (without context menu)
            return (<div style={style.base}>
                <div style={style.title} onDoubleClick={this.props.renameFile ? this.props.renameFile.bind(this, this.props) : null}>
                    <i className={iconClass} style={iconStyle} />
                    {nodeCaption}
                </div>
            </div>);
        }
        const { isDragging, connectDragSource, connectDropTarget } = this.props;
        return connectDragSource(connectDropTarget(<div style={style.base} onMouseDown={this.checkCtrl}>
            <div style={style.title} onDoubleClick={this.props.renameFile ? this.props.renameFile.bind(this, this.props) : null}>
                <ContextMenuTrigger id={MENU_ID + this.props.treeComponentId} holdToDisplay={-1} collect={collectContextMenuData({ id: this.props.id })}>
                    <i className={iconClass} style={iconStyle} />
                    {nodeCaption}
                </ContextMenuTrigger>
            </div>
        </div>));
    }
}

export default DropTarget(NODE_DND_ID, dragTarget, collectDrop)(
    DragSource(NODE_DND_ID, dragSource, collectDrag)(TreeNodeHeader));