import { connect } from 'react-redux';
import { I18N } from '../constants/i18n';
import { SRCEDITOR, STATUS_EMPTY } from '../constants/srceditor';
import HistoryPopup from '../components/srceditor/historypopup.jsx';
import { fetchHistoryData, ajaxRestoreCode } from '../actions/srceditor';
import { SERVER_CONFIG } from '../constants/serverconfig';

////////////////////////////////////////
//Common code for reducers and actions//
////////////////////////////////////////
const initialEditorState = {
    status: STATUS_EMPTY,
    entity: {
        /*
        id: null, 
        description: "", 
        mime: "",
        path: "",
        code: ""
        */
    },
    history: {}
};

export function getInitialState() {
    return $.extend(true, {}, initialEditorState);
}

export function safeGetEditor(state = {}, editorId) {
    let editorState = state[editorId];
    if (typeof editorState == 'undefined') {
        return getInitialState();
    }
    return editorState;
}

export function getSrcEditorState(globalState) {
    return globalState[SRCEDITOR];
}

function connectHistoryPopup(editorId, path) {
    return connect((globalState) => {
        return {
            history: globalState[SRCEDITOR][editorId] ? globalState[SRCEDITOR][editorId].history[path] : null,
            locale: globalState[I18N].locale,
            messages: globalState[I18N].messages,
            timeDifference: globalState[SERVER_CONFIG].timeDifference
        };
    }, (dispatch) => {
        return {
            fetchData: function (parameters) {
                dispatch(fetchHistoryData(editorId, path));
            },
            restore: function (id, path, modal) {
                dispatch(ajaxRestoreCode(editorId, id, path, modal));
            }
        }
    })(HistoryPopup);
}

export function openHistory(editorId, path, openModal) {
    const HistoryContainer = connectHistoryPopup(editorId, path);
    const options = {
        title: { id: "SRC_EDITOR_HISTORY_TITLE" },
        body: (<HistoryContainer
            openModal={openModal}
        />),
        size: "large"
    };
    openModal("historyPopup", "common", options);
}