import { FormattedMessage } from 'react-intl';

//Global tree state path
export const CIMGANT = "cimgant";

export const VIEW_TYPE_READ = "NPT_GANT_VIEW_TYPE_READ";
export const VIEW_TYPE_EDIT = "NPT_GANT_VIEW_TYPE_EDIT";
export const VIEW_TYPE_EDIT_PLANNING = "NPT_GANT_VIEW_TYPE_EDIT_PLANNING";
export const VIEW_TYPE_EDIT_CHOSEN = "NPT_GANT_VIEW_TYPE_EDIT_CHOSEN";
export const VIEW_TYPE_ADD = "NPT_GANT_VIEW_TYPE_ADD";
export const VIEW_TYPE_ADD_PLANNING = "NPT_GANT_VIEW_TYPE_ADD_PLANNING";
export const VIEW_TYPE_ADD_CHOSEN = "NPT_GANT_VIEW_TYPE_ADD_CHOSEN";

export const READ = "read";
export const PLANNED_DATE = "plannedDate";
export const CHOSEN_DATE = "chosenDate";

//Url adresses
export const PATH_TO_GANT = "/rest/gant";

//Standart lists
export const VIEW_MODE_LIST = {
    read: [{ name: "Чтение", formattedId: VIEW_TYPE_READ, value: READ }],
    change: [
        { name: "Чтение", formattedId: VIEW_TYPE_READ, value: READ },
        { name: "Редактирование планируемого времени", formattedId: VIEW_TYPE_EDIT_PLANNING, value: PLANNED_DATE },
        { name: "Редактирование выбранного времени", formattedId: VIEW_TYPE_EDIT_CHOSEN, value: CHOSEN_DATE }
    ],
    full: [
        { name: "Чтение", formattedId: VIEW_TYPE_READ, value: READ },
        { name: "Редактирование/добавление планируемого времени", formattedId: VIEW_TYPE_ADD_PLANNING, value: PLANNED_DATE },
        { name: "Редактирование/добавление выбранного времени", formattedId: VIEW_TYPE_ADD_CHOSEN, value: CHOSEN_DATE }
    ],
    chosenOnly: {
        change: [
            { name: "Чтение", formattedId: VIEW_TYPE_READ, value: READ },
            { name: "Редактирование", formattedId: VIEW_TYPE_EDIT, value: CHOSEN_DATE }
        ],
        full: [
            { name: "Чтение", formattedId: VIEW_TYPE_READ, value: READ },
            { name: "Редактирование/добавление", formattedId: VIEW_TYPE_ADD, value: CHOSEN_DATE }
        ]
    }
};

export const MONTHS = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];

//Standart colors for draggable elements
export const STANDART_COLORS = {
    standartRed: "#B00",
    standartGreen: "#3D990F",
    standartBlue: "#007DCC",
    standartLightBlue: "#80BEE6",
    standartOrange: "#FE8421",
    standartGray: "rgb(200,200,200)",
    standartDarkGray: "rgb(100,100,100)"
};

//Actions
export const WAIT_FOR_DATA = "@@CIMGANT_WAIT_FOR_DATA";
export const DATA_UPDATE = "@@CIMGANT_DATA_UPDATE";
export const DATA_UPDATE_ERROR = "@@CIMGANT_DATA_UPDATE_ERROR";
export const OPTIONS_UPDATE = "@@CIMGANT_OPTIONS_UPDATE";
export const OPTIONS_UPDATE_ERROR = "@@CIMGANT_OPTIONS_UPDATE_ERROR";

export const CHANGE_GANT_SIZES = "@@CIMGANT_CHANGE_GANT_SIZES";

export const DRAGGABLE_CONTEXT_MENU_CALL = "@@CIMGANT_DRAGGABLE_CONTEXT_MENU_CALL";
export const DRAGGABLE_CONTEXT_MENU_SELECT = "@@CIMGANT_DRAGGABLE_CONTEXT_MENU_SELECT";
export const DRAGGABLE_CONTEXT_MENU_CLOSE = "@@CIMGANT_DRAGGABLE_CONTEXT_MENU_CLOSE";

export const CHANGE_VIEW_MODE = "@@CIMGANT_CHANGE_VIEW_MODE";
export const CHANGE_VIEW_GROUP = "@@CIMGANT_CHANGE_VIEW_GROUP";
export const SAVE_GANT = "@@CIMGANT_SAVE_GANT";

export const RELEASE_ELEMENT = "@@CIMGANT_RELEASE_ELEMENT";
export const RELEASE_BACKGROUND = "@@CIMGANT_RELEASE_BACKGROUND";

export const SORT_ROWS = "@@CIMGANT_SORT_ROWS";
export const FILTER_ROWS = "@@CIMGANT_FILTER_ROWS";

//Define
export const CAPTURE_DRAG = 0;
export const CAPTURE_RESIZE_LEFT = 1;
export const CAPTURE_RESIZE_RIGHT = 2;

//////////////////////
//Formatted messages//
//////////////////////
export const NPT_GANT_PLANNED_WORK = <FormattedMessage
    id="NPT_GANT_PLANNED_WORK"
    defaultMessage='Planned work'
    description="Planned work" />

export const NPT_GANT_PLANNED_DOCS = <FormattedMessage
    id="NPT_GANT_PLANNED_DOCS"
    defaultMessage='Planned documents'
    description="Planned documents" />

export const NPT_GANT_YEAR = <FormattedMessage
    id="NPT_GANT_YEAR"
    defaultMessage='{year} y.'
    description="Year" />

export const NPT_GANT_MONTH_JANUARY = <FormattedMessage
    id="NPT_GANT_MONTH_JANUARY"
    defaultMessage='January'
    description="January" />

export const NPT_GANT_MONTH_FEBRUARY = <FormattedMessage
    id="NPT_GANT_MONTH_FEBRUARY"
    defaultMessage='February'
    description="February" />

export const NPT_GANT_MONTH_MARCH = <FormattedMessage
    id="NPT_GANT_MONTH_MARCH"
    defaultMessage='March'
    description="March" />

export const NPT_GANT_MONTH_APRIL = <FormattedMessage
    id="NPT_GANT_MONTH_APRIL"
    defaultMessage='April'
    description="April" />

export const NPT_GANT_MONTH_MAY = <FormattedMessage
    id="NPT_GANT_MONTH_MAY"
    defaultMessage='May'
    description="May" />

export const NPT_GANT_MONTH_JUNE = <FormattedMessage
    id="NPT_GANT_MONTH_JUNE"
    defaultMessage='June'
    description="June" />

export const NPT_GANT_MONTH_JULY = <FormattedMessage
    id="NPT_GANT_MONTH_JULY"
    defaultMessage='July'
    description="July" />

export const NPT_GANT_MONTH_AUGUST = <FormattedMessage
    id="NPT_GANT_MONTH_AUGUST"
    defaultMessage='August'
    description="August" />

export const NPT_GANT_MONTH_SEPTEMBER = <FormattedMessage
    id="NPT_GANT_MONTH_SEPTEMBER"
    defaultMessage='September'
    description="September" />

export const NPT_GANT_MONTH_OCTOBER = <FormattedMessage
    id="NPT_GANT_MONTH_OCTOBER"
    defaultMessage='October'
    description="October" />

export const NPT_GANT_MONTH_NOVEMBER = <FormattedMessage
    id="NPT_GANT_MONTH_NOVEMBER"
    defaultMessage='November'
    description="November" />

export const NPT_GANT_MONTH_DECEMBER = <FormattedMessage
    id="NPT_GANT_MONTH_DECEMBER"
    defaultMessage='December'
    description="December" />

export const NPT_GANT_VIEW_TYPE_READ = <FormattedMessage
    id="NPT_GANT_VIEW_TYPE_READ"
    defaultMessage='Read'
    description="Read" />

export const NPT_GANT_VIEW_TYPE_EDIT = <FormattedMessage
    id="NPT_GANT_VIEW_TYPE_EDIT"
    defaultMessage='Edit'
    description="Edit" />

export const NPT_GANT_VIEW_TYPE_EDIT_PLANNING = <FormattedMessage
    id="NPT_GANT_VIEW_TYPE_EDIT_PLANNING"
    defaultMessage='Edit planning date'
    description="Edit planning date" />

export const NPT_GANT_VIEW_TYPE_EDIT_CHOSEN = <FormattedMessage
    id="NPT_GANT_VIEW_TYPE_EDIT_CHOSEN"
    defaultMessage='Edit chosen date'
    description="Edit chosen date" />

export const NPT_GANT_VIEW_TYPE_ADD = <FormattedMessage
    id="NPT_GANT_VIEW_TYPE_ADD"
    defaultMessage='Add/edit'
    description="Add/edit" />

export const NPT_GANT_VIEW_TYPE_ADD_PLANNING = <FormattedMessage
    id="NPT_GANT_VIEW_TYPE_ADD_PLANNING"
    defaultMessage='Add/edit planning date'
    description="Add/edit planning date" />

export const NPT_GANT_VIEW_TYPE_ADD_CHOSEN = <FormattedMessage
    id="NPT_GANT_VIEW_TYPE_ADD_CHOSEN"
    defaultMessage='Add/edit chosen date'
    description="Add/edit chosen date" />