import * as Action from '../constants/rpasetpoints';
import * as ModalActions from './modal';
import * as AlertActions from './alert';
import * as FileDropActions from './filedrop';
import * as LocationConstants from '../constants/location';
import * as I18NConstants from '../constants/i18n';
import * as AlertConstants from '../constants/alert';
import * as ModalConstants from '../constants/modal';
import FileUploader from '../components/rpasetpoints/fileuploader.jsx';
import FileImporter from '../components/rpasetpoints/fileimporter.jsx';
import Configurations from '../components/rpasetpoints/configurations.jsx';
import { canBeBinded, getTabTree, isLinked, isFolder, isDataChanged } from '../services/rpasetpoints';

import default_setpoints from '../constants/default_setpoints';
import default_signals from '../constants/default_signals';
import { parseCommonSetpoints, parseToLocalSetpoints, parseToServerData, parseFromServerData, parseToLocalSignals, parseCommonSignals } from '../services/rpasetpoints-parser';


/////////////////
//   Utility   //
/////////////////
function buildHash(data) {
    let hash = `#${data.path}`;
    if ($.isEmptyObject(data.params)) {
        return hash;
    }
    hash += "?" + $.param(data.params);
    return hash;
}

function downloadFile(content, fileName, contentType) {
    var a = document.createElement("a");
    var file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

function getExtensionFromName(fileName) {
    let extIndex = fileName.lastIndexOf(".");
    if (extIndex == -1) {
        return "";
    }
    return fileName.substring(extIndex + 1);
}

////////////////
//Ajax actions//
////////////////
function ajaxDownloadFile(dispatch, file, success, fail) {
    return $.ajax({
        url: `${Action.DONWLOAD_FILE_URL}/${file}`,
        type: 'GET',
        contentType: 'application/json',
        processData: false,
        dataType: 'json',
    }).done(function (fileData) {
        if (typeof success == "function") {
            success(fileData);
        }
    }).fail(function (error) {
        dispatch(AlertActions.addAlert(AlertConstants.ALERT_DANGER, { id: "RPA_SETPOINTS_DOWNLOAD_ERROR" }));
        console.error(error);

        if (typeof fail == "function") {
            fail(error);
        }
    });
}

function ajaxUploadFile(dispatch, fileData, success, fail) {
    dispatch(FileDropActions.startUpload({
        files: [fileData],
        uploadProgress: () => { },
        uploadUrl: Action.UPLOAD_FILE_URL,
        successCallback: (data) => {
            if (typeof success == "function") {
                success(data);
            }
        },
        failCallback: (error) => {
            dispatch(AlertActions.addAlert(AlertConstants.ALERT_DANGER, { id: "RPA_SETPOINTS_SAVE_ERROR" }));
            console.error(error);

            if (typeof fail == "function") {
                fail(error);
            }
        }
    }));
}

function ajaxUploadSetpoints(dispatch, { file, extension, vendor, bayType, encode, ignoreCache }) {
    $.ajax({
        url: `${Action.UPLOAD_SETPOINTS_URL}?${$.param({ file, vendor, extension, encode })}`,
        type: 'GET',
        contentType: 'application/json',
        processData: false,
        dataType: 'json',
    }).done(function (setpoints) {
        dispatch(setpointsReceive(parseToLocalSetpoints(setpoints), vendor, bayType, ignoreCache));
    }).fail(function (error) {
        dispatch(AlertActions.addAlert(AlertConstants.ALERT_DANGER, { id: "RPA_SETPOINTS_PARSE_ERROR" }));
        console.error(error);
    });
}

function ajaxUploadSignals(dispatch, { file, encode, ignoreCache }) {
    $.ajax({
        url: `${Action.UPLOAD_SIGNALS_URL}?${$.param({ file, encode })}`,
        type: 'GET',
        contentType: 'application/json',
        processData: false,
        dataType: 'json',
    }).done(function (setpoints) {
        dispatch(signalsReceive(parseToLocalSignals(setpoints), ignoreCache));
    }).fail(function (error) {
        dispatch(AlertActions.addAlert(AlertConstants.ALERT_DANGER, { id: "RPA_SETPOINTS_PARSE_ERROR" }));
        console.error(error);
    });
}

function alertSharedSetpointsError(I18NState, notLinkedSetpoitns) {
    let alertSignals = `${I18NState.messages["RPA_SETPOINTS_SHARED_SETPOINTS_ERROR"]}\n`;
    const setpointsByFunctionName = {};
    for (let setpoint of notLinkedSetpoitns) {
        if (!setpointsByFunctionName[setpoint.functionName]) {
            setpointsByFunctionName[setpoint.functionName] = [];
        }
        setpointsByFunctionName[setpoint.functionName].push(setpoint.comparedId);
    }
    for (let functionName in setpointsByFunctionName) {
        alertSignals += `${functionName}:\n`;
        for (let comparedId of setpointsByFunctionName[functionName]) {
            alertSignals += `\t- ${comparedId}`;
        }
        alertSignals += "\n\n";
    }
    alert(alertSignals);
}

/////////////////
//Plain actions//
/////////////////
export function changeParameter(params) {
    return {
        type: Action.CHANGE_PARAMETER,
        payload: params
    };
}

export function changeTab(tabId) {
    return {
        type: Action.CHANGE_TAB,
        payload: { tabId }
    };
}

export function setOptions({ vendor, voltage, bayType }) {
    return {
        type: Action.SET_OPTIONS,
        payload: { vendor, voltage, bayType }
    };
}

export function commonSetpointsReceive({ commonSetpoints, bayTypes }) {
    return {
        type: Action.COMMON_SETPOINTS_RECEIVE,
        payload: { commonSetpoints, bayTypes }
    };
}

export function commonSetpointsReceiveError(error) {
    return {
        type: Action.COMMON_SETPOINTS_RECEIVE_ERROR,
        payload: { error }
    };
}

export function commonSignalsReceive(commonSignals) {
    return {
        type: Action.COMMON_SIGNALS_RECEIVE,
        payload: { commonSignals }
    };
}

export function commonSignalsReceiveError(error) {
    return {
        type: Action.COMMON_SIGNALS_RECEIVE_ERROR,
        payload: { error }
    };
}

export function setpointsReceive(setpoints, vendor, bayType, ignoreCache = false) {
    return {
        type: Action.SETPOINTS_RECEIVE,
        payload: { setpoints, vendor, bayType, ignoreCache }
    };
}

export function setpointsReceiveError(error) {
    return {
        type: Action.SETPOINTS_RECEIVE_ERROR,
        payload: { error }
    };
}

export function signalsReceive(signals, ignoreCache = true) {
    return {
        type: Action.SIGNALS_RECEIVE,
        payload: { signals, ignoreCache }
    };
}

export function signalsReceiveError(error) {
    return {
        type: Action.SIGNALS_RECEIVE_ERROR,
        payload: { error }
    };
}

export function fileReset() {
    return {
        type: Action.FILE_RESET,
        payload: null
    };
}

export function fileReceive(data) {
    return {
        type: Action.FILE_RECEIVE,
        payload: { data }
    };
}

export function fileReceiveError(error) {
    return {
        type: Action.FILE_RECEIVE_ERROR,
        payload: { error }
    };
}

export function selectNode({ sourceId, id }) {
    return {
        type: Action.SELECT_NODE,
        payload: { sourceId, id }
    };
}

export function toggleNode({ sourceId, id }) {
    return {
        type: Action.TOGGLE_NODE,
        payload: { sourceId, id }
    };
}

export function plainUnbindSetpoints({ sourceId, targetId }) {
    return {
        type: Action.UNBIND_SETPOINTS,
        payload: { sourceId, targetId }
    };
}

export function plainBindSetpoints({ sourceId, targetId, value }) {
    return {
        type: Action.BIND_SETPOINTS,
        payload: { sourceId, targetId, value }
    };
}

export function setWithoutConfirmation({ withoutConfirmation }) {
    return {
        type: Action.SET_WITHOUT_CONFIRMATION,
        payload: { withoutConfirmation }
    };
}

export function setBindedFlag({ bindedFlag }) {
    return {
        type: Action.SET_BINDED_FLAG,
        payload: { bindedFlag }
    };
}

export function setDetailedView({ detailedView }) {
    return {
        type: Action.SET_DETAILED_VIEW,
        payload: { detailedView }
    };
}

export function changeFilter(value) {
    return {
        type: Action.CHANGE_FILTER,
        payload: { value }
    };
}

export function changeDetailsParameter(field, value) {
    return {
        type: Action.CHANGE_DETAILS_PARAMETER,
        payload: { field, value }
    };
}

export function showLinkedElement(nodeId, sourceId) {
    return {
        type: Action.SHOW_LINKED_ELEMENT,
        payload: { nodeId, sourceId }
    };
}

export function changeConfig({ voltage, vendor, bayType }) {
    return {
        type: Action.CHANGE_CONFIG,
        payload: { voltage, vendor, bayType }
    };
}

export function cancelRPASetpointsUpdates() {
    return {
        type: Action.CANCEL_UPDATES,
        payload: null
    };
}

///////////
//Actions//
///////////
export function changeHash(params) {
    return function (dispatch, getState) {
        let hashData = Object.assign({}, getState()[LocationConstants.LOCATION]);
        hashData.path = hashData.path || "";
        hashData.params = Object.assign({}, hashData.params, params);
        for (let p in hashData.params) {
            if (hashData.params[p] == null) {
                delete (hashData.params[p]);
            }
        }
        window.location.hash = buildHash(hashData);
    }
}

export function loadCommonSetpoints() {
    return function (dispatch, getState) {
        dispatch(commonSetpointsReceive(parseCommonSetpoints(default_setpoints)));
    }
}

export function loadCommonSignals() {
    return function (dispatch, getState) {
        dispatch(commonSignalsReceive(parseCommonSignals(default_signals)));
    }
}

export function uploadSetpoints(fileData, vendor, bayType, encode) {
    return function (dispatch, getState) {
        const messages = getState()[I18NConstants.I18N].messages;
        ajaxUploadFile(dispatch, fileData, (data) => {
            ajaxUploadSetpoints(dispatch, {
                file: data.sha1,
                extension: getExtensionFromName(data.originalName),
                vendor: vendor,
                bayType: bayType,
                encode: encode
            });
        });
    }
}

export function uploadSignals(fileData, encode) {
    return function (dispatch, getState) {
        ajaxUploadFile(dispatch, fileData, (data) => {
            ajaxUploadSignals(dispatch, { file: data.sha1, encode: encode });
        });
    }
}

export function loadFromServer(fileData) {
    return function (dispatch, getState) {
        /* When user delete file link from hash fileData can be blank */
        if (!fileData) {
            dispatch(fileReset());
            dispatch(loadCommonSetpoints());
            dispatch(loadCommonSignals());
            return;
        }
        ajaxDownloadFile(dispatch, fileData,
            (data) => {
                if (getState()[LocationConstants.LOCATION].params.file != fileData) {
                    return;
                }
                dispatch(fileReceive(parseFromServerData(data)));
            },
            (error) => {
                if (getState()[LocationConstants.LOCATION].params.file != fileData) {
                    return;
                }
                dispatch(fileReceiveError(error));
            });
    }
}

export function unbindSetpoints() {
    return function (dispatch, getState) {
        const rpaSetpointsState = getState()[Action.RPA_SETPOINTS];
        const sourceTree = getTabTree(rpaSetpointsState, Action.SOURCE);
        const targetTree = getTabTree(rpaSetpointsState, Action.TARGET);
        const sourceNode = sourceTree.nodeById[sourceTree.active];
        const targetNode = targetTree.nodeById[targetTree.active];
        if (!isLinked(sourceNode, targetNode)) {
            return;
        }
        const unbindFunction = plainUnbindSetpoints;
        if (rpaSetpointsState.withoutConfirmation) {
            dispatch(unbindFunction({ sourceId: sourceNode.id, targetId: targetNode.id }));
        } else {
            let options = {
                title: {
                    id: "RPA_SETPOINTS_CONFIRM_ACTION"
                },
                body: {
                    id: "RPA_SETPOINTS_UNBIND_SETPOINTS_CONFIRM",
                    values: { sourceName: sourceNode.name, targetName: targetNode.name }
                }
            }
            dispatch(ModalActions.openModal("unbindSetpoints", "confirm", options, function () {
                dispatch(unbindFunction({ sourceId: sourceNode.id, targetId: targetNode.id }));
            }));
        }
    }
}

function bindSetpointsWithCheck({ sourceId, targetId, sourceName, sourceValue, sourceRawValue, targetEnumeration }) {
    return function (dispatch, getState) {
        if (!targetEnumeration) {
            dispatch(plainBindSetpoints({ sourceId, targetId }));
            return;
        }
        const checkValue = sourceValue && sourceValue != "null" ? sourceValue : sourceRawValue;
        for (let value of targetEnumeration) {
            if (checkValue == value) {
                dispatch(plainBindSetpoints({ sourceId, targetId, value }));
                return;
            }
        }
        let options = {
            title: {
                id: "RPA_SETPOINTS_SELECT_BIND_VALUE"
            },
            messages: [
                `<b>${sourceName} = ${checkValue}</b>`,
                {
                    id: "RPA_SETPOINTS_CANT_RECOGNIZE_VALUE",
                    values: { value: checkValue }
                }
            ],
            enumeration: targetEnumeration

        }
        dispatch(ModalActions.openModal("selectBindValue", "enum", options, function (result) {
            dispatch(plainBindSetpoints({
                sourceId: sourceId,
                targetId: targetId,
                value: result.value
            }));
        }));
    }
}

export function bindSetpoints() {
    return function (dispatch, getState) {
        const globalState = getState();
        const rpaSetpointsState = globalState[Action.RPA_SETPOINTS];
        const sourceTree = getTabTree(rpaSetpointsState, Action.SOURCE);
        const targetTree = getTabTree(rpaSetpointsState, Action.TARGET);
        const sourceNode = sourceTree.nodeById[sourceTree.active];
        const targetNode = targetTree.nodeById[targetTree.active];
        const sourceParent = sourceTree.nodeById[sourceNode.parentId];
        const targetParent = targetTree.nodeById[targetNode.parentId];
        if (!canBeBinded(sourceNode, targetNode, sourceParent, targetParent)) {
            return;
        }
        const bindFunction = bindSetpointsWithCheck;
        if (rpaSetpointsState.withoutConfirmation) {
            dispatch(bindFunction({
                sourceId: sourceNode.id,
                targetId: targetNode.id,
                sourceName: sourceNode.name,
                sourceValue: sourceNode.value,
                sourceRawValue: sourceNode.rawValue,
                targetEnumeration: targetNode.enumeration
            }));
        } else {
            let options = {
                title: {
                    id: "RPA_SETPOINTS_CONFIRM_ACTION"
                },
                body: {
                    id: "RPA_SETPOINTS_BIND_SETPOINTS_CONFIRM",
                    values: { sourceName: sourceNode.name, targetName: targetNode.name }
                }
            }
            dispatch(ModalActions.openModal("bindSetpoints", "confirm", options, function () {
                dispatch(bindFunction({
                    sourceId: sourceNode.id,
                    targetId: targetNode.id,
                    sourceName: sourceNode.name,
                    sourceValue: sourceNode.value,
                    sourceRawValue: sourceNode.rawValue,
                    targetEnumeration: targetNode.enumeration
                }));
            }));
        }
    }
}

export function changeConfirmationRequired() {
    return function (dispatch, getState) {
        const rpaSetpointsState = getState()[Action.RPA_SETPOINTS];
        dispatch(setWithoutConfirmation({ withoutConfirmation: !rpaSetpointsState.withoutConfirmation }));
    }
}

export function changeBindedFlag() {
    return function (dispatch, getState) {
        const rpaSetpointsState = getState()[Action.RPA_SETPOINTS];
        dispatch(setBindedFlag({ bindedFlag: !rpaSetpointsState.bindedFlag }));
    }
}

export function changeDetailedView() {
    return function (dispatch, getState) {
        const rpaSetpointsState = getState()[Action.RPA_SETPOINTS];
        dispatch(setDetailedView({ detailedView: !rpaSetpointsState.detailedView }));
    }
}

export function saveToFileSystem() {
    return function (dispatch, getState) {
        const rpaSetpointsState = getState()[Action.RPA_SETPOINTS];
        const bindedData = parseToServerData({
            commonSetpoints: rpaSetpointsState.commonSetpoints,
            setpoints: rpaSetpointsState.setpoints,
            analog: rpaSetpointsState.analogSignals,
            discrete: rpaSetpointsState.discreteSignals,
            bayType: rpaSetpointsState.bayType
        });
        if (bindedData.error) {
            alertSharedSetpointsError(getState()[I18NConstants.I18N], bindedData.notLinkedSetpoitns);
            return;
        }
        downloadFile(JSON.stringify(bindedData), 'setpoints.json', 'application/json');
    }
}

export function saveToServer(embedded) {
    return function (dispatch, getState) {
        const rpaSetpointsState = getState()[Action.RPA_SETPOINTS];
        if (rpaSetpointsState.uploadState.setpoints != Action.READY || rpaSetpointsState.uploadState.signals != Action.READY) {
            $(window).trigger('rpasetpoints.save', null);
            return;
        }
        if (!isDataChanged(rpaSetpointsState)) {
            $(window).trigger('rpasetpoints.save', rpaSetpointsState.file);
            return;
        }
        const bindedData = parseToServerData({
            commonSetpoints: rpaSetpointsState.commonSetpoints,
            setpoints: rpaSetpointsState.setpoints,
            analog: rpaSetpointsState.analogSignals,
            discrete: rpaSetpointsState.discreteSignals,
            bayType: rpaSetpointsState.bayType
        });
        if (bindedData.error) {
            alertSharedSetpointsError(getState()[I18NConstants.I18N], bindedData.notLinkedSetpoitns);
            return;
        }

        let file = new File([JSON.stringify(bindedData)], 'rpa_setpoints_bindings.json', { type: 'application/json' });
        dispatch(FileDropActions.startUpload({
            files: [file],
            uploadProgress: () => { },
            uploadUrl: Action.UPLOAD_FILE_URL,
            successCallback: (response) => {
                dispatch(changeHash({ file: response.sha1 }));
                $(window).trigger('rpasetpoints.save', response.sha1);
            },
            failCallback: (error) => {
                console.error(error);
            }
        }));
    }
}

function callUploadModal(dispatch, linkerState, messages, uploadId) {
    let uploadState = {
        [uploadId]: false
    }
    let hideFiledrop = {
        [Action.SETPOINTS]: true,
        [Action.SIGNALS]: true,
        [uploadId]: false
    }
    let uploadedData = null;
    let uploaderRef = null;

    let options = {
        title: {
            id: "RPA_SETPOINTS_UPDATE_FILE"
        },
        body: <FileUploader
            vendor={linkerState.vendor}
            bayType={linkerState.bayType}
            bayTypeList={linkerState.bayTypeList}
            modal={true}
            messages={messages}
            uploadState={uploadState}
            uploadSetpoints={(fileData, vendor, bayType, encode) => {
                ajaxUploadFile(dispatch, fileData, (data) => {
                    uploadedData = {
                        file: data.sha1,
                        extension: getExtensionFromName(data.originalName),
                        vendor: vendor,
                        bayType: bayType,
                        encode: encode
                    }
                    uploadState[uploadId] = Action.READY;
                    uploaderRef.props = Object.assign(uploaderRef.props, { uploadState });
                    uploaderRef.forceUpdate();
                    dispatch(ModalActions.updateModal("updateFile"));
                });
            }}
            uploadSignals={(fileData, encode) => {
                ajaxUploadFile(dispatch, fileData, (data) => {
                    uploadedData = {
                        file: data.sha1,
                        encode: encode
                    }
                    uploadState[uploadId] = Action.READY;
                    uploaderRef.props = Object.assign(uploaderRef.props, { uploadState });
                    uploaderRef.forceUpdate();
                    dispatch(ModalActions.updateModal("updateFile"));
                });
            }}
            hideFiledrop={hideFiledrop}
            ref={(ref) => uploaderRef = ref}
        />,
        onOk: (modal) => {
            uploadedData.ignoreCache = true
            switch (uploadId) {
                case Action.SETPOINTS:
                    ajaxUploadSetpoints(dispatch, uploadedData);
                    break;
                case Action.SIGNALS:
                    ajaxUploadSignals(dispatch, uploadedData);
                    break;
            }
            dispatch(setOptions(uploaderRef.state.options));
            modal.closeModal(ModalConstants.MODAL_STATUS_OK);
        },
        result: () => {
            return uploadedData;
        }
    }
    dispatch(ModalActions.openModal("updateFile", "common", options));
}

export function updateSetpointsFile() {
    return function (dispatch, getState) {
        const messages = getState()[I18NConstants.I18N].messages;
        const linkerState = getState()[Action.RPA_SETPOINTS];

        callUploadModal(dispatch, linkerState, messages, Action.SETPOINTS);
    }
}

export function updateSignalsFile() {
    return function (dispatch, getState) {
        const messages = getState()[I18NConstants.I18N].messages;
        const linkerState = getState()[Action.RPA_SETPOINTS];

        callUploadModal(dispatch, linkerState, messages, Action.SIGNALS);
    }
}

function callImportModal(dispatch, messages) {
    let uploadState = false;
    let uploadedFileSha1 = null;
    let uploaderRef = null;

    let options = {
        title: {
            id: "RPA_SETPOINTS_IMPORT_FILE"
        },
        body: <FileImporter
            messages={messages}
            uploadState={uploadState}
            changeFileSha1={(sha1) => {
                if (typeof sha1 != "string") {
                    sha1 = null;
                }
                uploadedFileSha1 = sha1;
                uploaderRef.props = Object.assign(uploaderRef.props, { fileSha1: sha1 });
                uploaderRef.forceUpdate();
                dispatch(ModalActions.updateModal("updateFile"));
            }}
            uploadFile={(fileData) => {
                ajaxUploadFile(dispatch, fileData, (data) => {
                    uploadedFileSha1 = data.sha1;
                    uploadState = Action.READY;
                    uploaderRef.props = Object.assign(uploaderRef.props, { uploadState, fileSha1: data.sha1 });
                    uploaderRef.forceUpdate();
                    dispatch(ModalActions.updateModal("updateFile"));
                });
            }}
            ref={(ref) => uploaderRef = ref}
        />,
        onOk: (modal) => {
            dispatch(changeHash({ file: uploadedFileSha1 }));
            modal.closeModal(ModalConstants.MODAL_STATUS_OK);
        },
        result: () => {
            return uploadedFileSha1;
        }
    }
    dispatch(ModalActions.openModal("updateFile", "common", options));
}

export function importFile() {
    return function (dispatch, getState) {
        const messages = getState()[I18NConstants.I18N].messages;

        callImportModal(dispatch, messages);
    }
}

export function confirmFileReset() {
    return function (dispatch, getState) {
        let options = {
            title: {
                id: "RPA_SETPOINTS_RESET_FILE"
            },
            body: { id: "RPA_SETPOINTS_CONFIRM_FILE_RESET" },
            onOk: (modal) => {
                dispatch(changeHash({ file: null }));
                modal.closeModal(ModalConstants.MODAL_STATUS_OK);
            }
        }
        dispatch(ModalActions.openModal("updateFile", "common", options));
    }
}

export function showConfig() {
    return function (dispatch, getState) {
        const messages = getState()[I18NConstants.I18N].messages;
        const linkerState = getState()[Action.RPA_SETPOINTS];
        let configurationsRef = null;
        let options = {
            title: {
                id: "RPA_SETPOINTS_CONFIGURATIONS"
            },
            body: <Configurations
                messages={messages}
                voltage={linkerState.voltage}
                vendor={linkerState.vendor}
                bayType={linkerState.bayType}
                bayTypeList={linkerState.bayTypeList}
                ref={(ref) => configurationsRef = ref}
            />,
            onOk: (modal) => {
                dispatch(changeConfig(configurationsRef.state));
                modal.closeModal(ModalConstants.MODAL_STATUS_OK);
            }
        }
        dispatch(ModalActions.openModal("configurations", "common", options));
    }
}