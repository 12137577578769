
import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * This is just a plain ol' React component.
 * the vjsComponent methods, player methods etc. are available via
 * the vjsComponent prop (`this.props.vjsComponent`)
 */
export default class EpisodeList extends React.PureComponent {
    render() {
        return (
            <div className="pt-1 pb-1 h-100" style={{ padding: "2px 0px" }}>
                <img src="/resources/images/logo.png" height="22px" />
            </div>
        );
    }
}