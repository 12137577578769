import { connect, Provider } from 'react-redux';
import I18NProvider from './components/provider.jsx';
import { I18N } from './constants/i18n';
import Container from './components/cache/container.jsx';
import {
    fetchList,
    clearCache,
    clearAllCache
} from './actions/cache';
import { CACHE } from './constants/cache.js';
import cacheReducer from './reducers/cache';

const ATTR_CACHE_CONTAINER = "cache-container";
const CacheContainer = createCacheContainer();

//Map global (redux) state to navigation tree state
function createMapStateToProps() {
    return function (globalState) {
        return {
            locale: globalState[I18N].locale,
            messages: globalState[I18N].messages,
            loading: globalState[CACHE].loading,
            error: globalState[CACHE].error,
            list: globalState[CACHE].list
        };
    }
}

function createMapDispachToProps() {
    return function (dispatch) {
        return {
            fetchList: function () {
                dispatch(fetchList());
            },
            clearCache: function ({ id }) {
                dispatch(clearCache({ id }));
            },
            clearAllCache: function () {
                dispatch(clearAllCache());
            }
        }
    }
}

function createCacheContainer() {
    //Connect redux to our navigation tree
    return connect(
        createMapStateToProps(),
        createMapDispachToProps()
    )(Container);
}

//Initialization function
export default (dispatcher) => {
    //Register reducers
    dispatcher.registerReducer(CACHE, cacheReducer);
    //Register components
    dispatcher.registerComponent(ATTR_CACHE_CONTAINER, (elm) => {
        return (<CacheContainer />);
    });
};

