import { connect } from 'react-redux';
import SrcTree from './components/srceditor/tree.jsx';
import SrcMenu from './components/srceditor/menu.jsx';
import { SRCTREE, TOGGLE_SUBTREE, OPEN_NODE, allowedNodeSymbols } from './constants/srctree';
import { SRCEDITOR } from './constants/srceditor';
import { LOCATION } from './constants/location';
import { I18N } from './constants/i18n';
import srctree from './reducers/srctree';

import {
    openCodePopup,
    fetchTree,
    addDirectory,
    addFile,
    fixCaption,
    addNode,
    ajaxAddCode,
    ajaxMoveNode,
    tryChangeNodeCaption,
    copyCode,
    removeDirectory,
    removeCode,
    beginEditNode,
    cancelEditNode,
    selectObject,
    search
} from './actions/srctree';
import { changeParam, ajaxSaveCode, ajaxDeleteCode, waitForCode } from './actions/srceditor';
import { plainToggleSubTree, openNode } from './actions/npt-treebeard';
import { getBasename, getFilename } from './services/npt-treebeard';
import { openModal } from './actions/modal';

const ATTR_SRC_TREE = "src-tree";
export const ATTR_SRC_TYPES = "src-types";

const connectNodeFunction = connect(function (_, initialProps) { //mapStateToProps
    const { id } = initialProps;
    return (globalState) => {
        const treeState = globalState[SRCTREE];
        return Object.assign({
            haveCode: treeState.nodeMap[id],
            toggled: treeState.toggled[id],
            children: treeState.children[id],
            active: treeState.active == id,
            inplace: id == treeState.editNodeId
        }, treeState.nodeById[id]);
    }
}, (_, initialProps) => { //mapDispachToProps
    const { id } = initialProps;
    return (dispatch) => {
        return {
            onToggle: function ({ loading, error, toggled, haveCode, active, directory }) {
                if (haveCode) {
                    selectObject({ path: id }, dispatch);
                }
                if (!active || directory) {
                    dispatch(plainToggleSubTree(TOGGLE_SUBTREE, id, !toggled));
                }
            }
        }
    }
});

function composeImportUrl(editorId, contextPath) {
    return `${contextPath}rest/${editorId}/import`;
}

function composeExportUrl(editorId, contextPath) {
    return `${contextPath}rest/${editorId}/export`;
}

function generateCollect(node, collectCallback, isCopy) {
    return function () {
        collectCallback(node, isCopy); //Deep copy of node
    }
}

//Map global (redux) state to navigation tree state
function createMapStateToProps(editorId, sourceTypes) {
    return (globalState) => {
        return {
            treeId: editorId,
            sourceTypes: sourceTypes,
            allowedSymbols: allowedNodeSymbols,
            messages: globalState[I18N].messages,
            contextPath: globalState[LOCATION].contextPath,
            hashParams: globalState[LOCATION].params,
            hashPath: globalState[LOCATION].path,
            roots: globalState[SRCTREE].rootNodesIds,
            nodeById: globalState[SRCTREE].nodeById,
            nodeMap: globalState[SRCTREE].nodeMap,
            active: globalState[SRCTREE].active,
            loading: globalState[SRCTREE].loading,
            editNodeId: globalState[SRCTREE].editNodeId,
            searchString: globalState[SRCTREE].searchString,
            editorState: globalState[SRCEDITOR][editorId],
            importUrl: composeImportUrl(editorId, globalState[LOCATION].contextPath),
            exportUrl: composeExportUrl(editorId, globalState[LOCATION].contextPath)
        };
    }
}

function createMapDispachToProps(editorId, sourceTypes) {
    return function (dispatch) {
        return {
            addFile: function (node, mime) {
                let props = Object.assign({ adding: true }, node);
                /* Using adding function only to directories */
                props.path += "/";
                props.startsWith = props.path;
                props.mime = mime;
                openCodePopup(dispatch, { id: "SRC_EDITOR_CONFIRM_ADD_CODE" }, props, sourceTypes, (modal, modalState) => {
                    ajaxAddCode(editorId, modalState, modal, dispatch);
                })
            },
            renameFile: function (node) {
                dispatch(beginEditNode(editorId, node.id));
            },
            cancelRenameFile: function (node) {
                dispatch(cancelEditNode(editorId, node.id));
            },
            removeNode: function (node) {
                if (node.directory) {
                    dispatch(removeDirectory(editorId, node.path));
                } else {
                    dispatch(removeCode(editorId, node.path));
                }
            },
            changeNodeCaption: function ({ treeId, nodeId, directory, caption }) {
                let newPath = nodeId.substring(0, nodeId.lastIndexOf("/") + 1) + caption;
                dispatch(tryChangeNodeCaption(editorId, nodeId, newPath, directory));
            },
            getCollectNodeAction: function (node, collectCallback, isCopy) { //Collect means copy/cut. Copy required data from node
                if (!node) {
                    return null;
                }
                return {
                    onAction: generateCollect(node, collectCallback, isCopy)
                };
            },
            getCopyAction: function (source, targetNode) { //Source is data from getCollectNodeAction
                if (source.path == targetNode.path || !targetNode.directory) {
                    return null;
                }
                let newPath = targetNode.path + "/" + getFilename(source.path);
                return {
                    onAction: function () {
                        dispatch(copyCode(editorId, source.id, newPath, source.directory));
                    }
                };
            },
            getCutAction: function (source, targetNode) { //Source is data from getCollectNodeActionlet
                if (!targetNode.directory) {
                    return null;
                }
                let newPath = targetNode.path + "/" + getFilename(source.path);
                if (source.path == newPath) {
                    return null;
                }
                return {
                    onAction: function () {
                        ajaxMoveNode(editorId, source.id, newPath, source.directory, dispatch);
                    }
                };
            },
            fetchSubTree: function () {
                dispatch(fetchTree(editorId));
            },
            openModal: function (modalId, type, options, okCallback, cancelCallback, closeCallback) {
                dispatch(openModal(modalId, type, options, okCallback, cancelCallback, closeCallback));
            },
            changeParam: function (param, description) {
                dispatch(changeParam(editorId, param, description));
            },
            search: function (value, path) {
                dispatch(search(editorId, value, path));
            },
            openNode: function (id) {
                dispatch(openNode(OPEN_NODE, id));
            },
            removeCode: function (path) {
                dispatch(removeCode(editorId, path));
            }
        }
    }
}

function createSourceTreeContainer(editorId, sourceTypes) {
    //Connect redux to our navigation tree
    return connect(
        createMapStateToProps(editorId, sourceTypes),
        createMapDispachToProps(editorId, sourceTypes)
    )(SrcTree);
}

function createMenuContainer(editorId, sourceTypes) {
    //Connect redux to our navigation tree
    return connect(
        createMapStateToProps(editorId, sourceTypes),
        createMapDispachToProps(editorId, sourceTypes)
    )(SrcMenu);
}

//Initialization function
export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(SRCTREE, srctree);
    //Register components
    dispatcher.registerComponent(ATTR_SRC_TREE, (elm) => {
        let editorId = $(elm).attr(ATTR_SRC_TREE);
        let sourceTypes = [];
        if (elm.hasAttribute(ATTR_SRC_TYPES)) {
            sourceTypes = $(elm).attr(ATTR_SRC_TYPES).split(",");
        }
        const SourceTreeContainer = createSourceTreeContainer(editorId, sourceTypes);
        const MenuContainer = createMenuContainer(editorId, sourceTypes);
        return (<div style={{ height: "100%" }}>
            <SourceTreeContainer treeComponentId={SRCTREE} connectNodeFunction={connectNodeFunction} />
            <MenuContainer />
        </div>)
    })
};

