import { TYPE_CATEGORY, TYPE_VIDEO, TYPE_TIMECODE } from "../constants/player";

/* Parse inner html content to setup gallery data */
export function parseGalleryElm(elm) {
    const data = [];
    const videoStore = {};
    for (let child of elm.childNodes) {
        parseItem(child, data, videoStore);
    }
    return { rootItems: data, videoStore };
}

function parseItem(item, data, videoStore) {
    if (!item.tagName) {
        return;
    }
    switch (item.tagName.toLowerCase()) {
        case "category":
            parseCategory(item, data, videoStore);
            break;
        case "nptvideo":
            parseVideo(item, data, videoStore);
            break;
        case "timecode":
            parseTimecode(item, data);
            break;
    }
    return;
}

function parseCategory(item, data, videoStore) {
    const category = {
        type: TYPE_CATEGORY,
        label: item.getAttribute("label"),
        children: []
    };
    for (let child of item.childNodes) {
        parseItem(child, category.children, videoStore);
    }
    data.push(category);
}

function parseVideo(item, data, videoStore) {
    const video = {
        type: TYPE_VIDEO,
        src: item.getAttribute("src"),
        label: item.getAttribute("label"),
        description: item.getAttribute("description"),
        timecodes: []
    };
    for (let child of item.childNodes) {
        parseItem(child, video.timecodes, videoStore);
    }
    videoStore[video.src] = video;
    data.push(video);
}

function parseTimecode(item, data) {
    const timecode = {
        type: TYPE_TIMECODE,
        time: item.getAttribute("time"),
        label: item.getAttribute("label")
    };
    data.push(timecode);
}