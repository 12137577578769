import { connect, Provider } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import I18NProvider from './components/provider.jsx';
import { I18N } from './constants/i18n';
import { FILEDROP } from './constants/filedrop';
import DropFile from './components/filedrop/filedrop.jsx';
import { registerUpload, startUpload, clearUploadMap } from './actions/filedrop.js';
import filedropReducer from './reducers/filedrop';

const ATTR_FILE_DROP = "file-drop";
const ATTR_UPLOAD_URL = "upload-url";
const ATTR_DROP_TYPE = "drop-type";

function createMapStateToProps(dropType) {
    return function (globalState) {
        return {
            locale: globalState[I18N].locale,
            filedrop: globalState[FILEDROP],
            multiple: dropType == "multiple"
        };
    }
}

function createMapDispachToProps(uploadUrl, successCallback, failCallback) {
    return function (dispatch) {
        return {
            registerUpload: function ({ files }) {
                dispatch(registerUpload({ files }));
            },
            startUpload: function ({ files, uploadProgress }) {
                dispatch(startUpload({ files, uploadProgress, uploadUrl, successCallback, failCallback }));
            },
            clearUploadMap: function () {
                dispatch(clearUploadMap());
            }
        }
    }
}


export function createDropFileContainer(uploadUrl, dropType, successCallback, failCallback) {
    return connect(
        createMapStateToProps(dropType),
        createMapDispachToProps(uploadUrl, successCallback, failCallback)
    )(DropFile);
}

//Initialization function
export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(FILEDROP, filedropReducer);

    //Register components
    dispatcher.registerComponent(ATTR_FILE_DROP, (elm) => {
        let uploadUrl = $(elm).attr(ATTR_UPLOAD_URL);
        let dropType = $(elm).attr(ATTR_DROP_TYPE);
        let DropFile = createDropFileContainer(uploadUrl, dropType);
        return (<DropFile />);
    });
};

