import React from 'react';
import { FormattedMessage } from 'react-intl';
import Basic from './basic.jsx';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import cellFactory from '../cellFactory.jsx';
import { MSG_TABLE_NO_DATA_TO_DISPLAY } from '../../messages.jsx';
import { ADD_BUTTON_ICON_STYLE, REMOVE_BUTTON_ICON_STYLE } from './style.jsx';
import shortid from 'shortid';
import { obtainData, retrieveFunction } from '../../../services/automation';
import { getColumnWidth } from '../../../services/table';
import { makeForm } from '../../../actions/objectcard.js';

//'<span class="order"><span class="dropdown"><span class="dropdown-toggle" style="margin: 10px 0px 10px 5px; color: rgb(204, 204, 204);"></span></span><span class="dropup"><span class="dropdown-toggle" style="margin: 10px 0px; color: rgb(204, 204, 204);"></span></span></span>'

/* Change order of columns and remove undefined columns */
export function filterColumns(columns, columnsOrder) {
    let sortedColumns = [];
    let originalColumnsOrder = [];
    for (let column of columns) {
        if (!column.predicateId) {
            originalColumnsOrder.push(null);
            continue;
        }
        originalColumnsOrder.push(column.predicateId);
    }
    for (let columnPath of columnsOrder) {
        for (let i = 0; i < originalColumnsOrder.length; ++i) {
            if (columnPath == originalColumnsOrder[i]) {
                sortedColumns.push(Object.assign({}, columns[i]));
                break;
            }
        }
    }
    return sortedColumns;
}

class ObjectTable extends Basic {

    constructor(props) {
        super(props);
        this.getRows = this.getRows.bind(this);
        this.getHeader = this.getHeader.bind(this);
        this.addClicked = this.addClicked.bind(this);
        this.editClicked = this.editClicked.bind(this);
        this.removeClicked = this.removeClicked.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.state = { selected: [] };

        this.cachedBy = {
            header: {
                node: null
            },
            rows: {
                node: null,
                value: null
            }
        };
        this.cachedValue = {
            header: null,
            rows: null
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.selected.length == 0) {
            return null;
        }
        const value = nextProps.value;
        if (nextProps.node.multiple) {
            if ($.type(value) != 'array' || value.length == 0) {
                return { selected: [] };
            }
            const keySet = value.reduce((acc, row) => { acc[row.$rdfId || row.$tmpKey] = true; return acc; }, {});
            return { selected: prevState.selected.filter(key => keySet[key]) };
        }
        if ($.type(value) != 'object') {
            return { selected: [] };
        }
        const key = value.$rdfId || value.$tmpKey;
        return { selected: prevState.selected.filter(it => it == key) };
    }

    onAdd(data) {
        if (this.props.node.multiple) {
            this.props.add(data)
        } else {
            this.props.change(data);
        }
    }

    addClicked(event) {
        let options = {
            initialData: {
                $isNew: true,
                $tmpKey: shortid.generate(),
                $class: this.nodeOptions.cls
            }
        };
        this.props.openModal("card", options, this.onAdd);
    }

    onEdit(index, data) {
        console.log("object table onEdit", index, data);
        if (typeof index != 'undefined') {
            this.props.changeAt(index, data);
        } else {
            this.props.change(data);
        }
    }

    editItem(data, index) {
        console.log("Edit object table: ", data, index);
        let lock = this.props.serverLock;
        if (lock) {
            //Copy lock
            lock = Object.assign({}, lock);
            //Install lock
            data = Object.assign({}, data, { $lock: lock });
        }
        this.props.openModal("card", { initialData: data }, this.onEdit.bind(this, index));
    }

    editClicked() {
        if (this.props.node.multiple) {
            const indexMap = this.props.value.reduce((acc, row, idx) => { acc[row.$rdfId || row.$tmpKey] = idx; return acc; }, {});
            const idx = indexMap[this.state.selected[0]];
            const row = this.props.value[idx];
            this.editItem(row, idx);
        } else {
            const row = this.props.value;
            this.editItem(row);
        }
    }

    onRemove() {
        if (this.props.node.multiple) {
            const indexMap = this.props.value.reduce((acc, row, idx) => { acc[row.$rdfId || row.$tmpKey] = idx; return acc; }, {});
            this.props.remove(this.state.selected.map((key) => indexMap[key]));
        } else {
            this.props.change({});
        }
    }

    removeClicked(event) {
        let options = {
            title: { id: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW_TITLE" },
            body: { id: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW" }
        }
        this.props.openModal("confirm", options, this.onRemove);
    }

    onSelect({ key }, isSelected, e) {
        if (isSelected) {
            this.setState({ selected: [...this.state.selected, key].sort() });
        } else {
            this.setState({ selected: this.state.selected.filter(it => it !== key) });
        }
        return false;
    }

    onSelectAll(isSelected, rows) {
        if (!isSelected) {
            this.setState({ selected: [] });
        } else {
            this.setState({ selected: rows.map(it => it.key) });
        }
        return false;
    }

    getToolbar() {
        let removeDisabled = (this.state.selected.length == 0);
        let editDisabled = (this.state.selected.length != 1);
        return (
            <div className="btn-group btn-group-xs pull-right" role="group" aria-label="...">
                <Button
                    generalAccessRules={this.props.generalAccessRules}
                    acl={this.nodeOptions.addAcl}
                    onClick={this.addClicked}
                    icon="plus-circle"
                    style={ADD_BUTTON_ICON_STYLE}
                    hidden={this.nodeOptions.addHidden} >
                    <FormattedMessage
                        id="OBJECTCARD_OBJTABLE_ADD_BUTTON"
                        defaultMessage="Add"
                        description="User should click this button to add items into the table" />
                </Button>
                <Button
                    generalAccessRules={this.props.generalAccessRules}
                    acl={this.nodeOptions.editAcl}
                    onClick={this.editClicked}
                    icon="pencil"
                    style={ADD_BUTTON_ICON_STYLE}
                    disabled={editDisabled}
                    hidden={this.nodeOptions.editHidden} >
                    <FormattedMessage
                        id="OBJECTCARD_OBJTABLE_EDIT_BUTTON"
                        defaultMessage="Edit"
                        description="User should click this button to edit item in the table" />
                </Button>
                <Button
                    generalAccessRules={this.props.generalAccessRules}
                    acl={this.nodeOptions.deleteAcl}
                    onClick={this.removeClicked}
                    icon="minus-circle"
                    style={REMOVE_BUTTON_ICON_STYLE}
                    disabled={removeDisabled}
                    hidden={this.nodeOptions.deleteHidden} >
                    <FormattedMessage
                        id="OBJECTCARD_OBJTABLE_REMOVE_BUTTON"
                        defaultMessage="Remove"
                        description="User should click this button to remove items from table" />
                </Button>
            </div>);
    }

    getCached(item) {
        for (let p in this.cachedBy[item]) {
            if (this.cachedBy[item][p] != this.props[p]) {
                return null;
            }
        }
        return this.cachedValue[item];
    }

    setCached(item, value) {
        for (let p in this.cachedBy[item]) {
            this.cachedBy[item][p] = this.props[p]
        }
        this.cachedValue[item] = value;
    }

    getWithCaching(item, method) {
        const cached = this.getCached(item);
        if (cached != null) {
            return cached;
        }
        const value = method();
        this.setCached(item, value);
        return value;
    }

    getHeader() {
        const thStyle = {
            overflowWrap: "break-word",
            textOverflow: "clip",
            whiteSpace: "normal"
        };
        let headerList = [];
        headerList.push(<TableHeaderColumn key={-1} isKey={true} dataField='key' hidden={true}>RDFID</TableHeaderColumn>);
        headerList.push(<TableHeaderColumn key={-2} dataField='num' dataSort={true} width={"45px"}>
            <FormattedMessage
                id="OBJECTCARD_OBJTABLE_NUMBER"
                defaultMessage="#"
                description="Number of item in table of references" />
        </TableHeaderColumn>);
        /* Sort columns if order is defined in options */
        let sortedColumns;
        if (this.nodeOptions["columns-order"]) {
            sortedColumns = filterColumns(this.nodeOptions.columns, this.nodeOptions["columns-order"]);
        } else {
            sortedColumns = this.nodeOptions.columns;
        }
        //Push predicates to the table
        for (let col of sortedColumns) {
            let options = cellFactory(col.predicate, this.props /*pass props as info*/);
            let w = col.predicate["table-header-width"];
            if (!w) {
                w = getColumnWidth(col);
            }
            headerList.push(<TableHeaderColumn key={col.id} width={w} thStyle={thStyle} tdStyle={thStyle} dataField={'col' + col.id} {...options}><span>{col.label}</span></TableHeaderColumn>);
        }
        return headerList;
    }

    getRow(num, rowData) {
        let row = {};
        row.key = rowData.$rdfId || rowData.$tmpKey;
        row.num = num;
        for (let col of this.nodeOptions.columns) {
            let cell = obtainData(col.path, rowData);
            row['col' + col.id] = cell;
        }
        return row;
    }

    getRows() {
        const cached = this.getCached("rows", this.props);
        if (cached != null) {
            return cached;
        }
        const value = this.props.value;
        const rows = [];
        if ($.type(value) == 'array') { //many objects
            for (let idx in value) {
                let num = parseInt(idx) + 1;
                rows.push(this.getRow(num, value[idx]));
            }
        } else if ($.type(value) == 'object') { //one object
            rows.push(this.getRow(1, value));
        }
        return rows;
    }

    getNodeOptions() {
        if (!this.props.node) {
            return {};
        }
        if (!this.props.layout || !this.props.layout.automation.optionsBindings[this.props.node.id]) {
            return this.props.node.options || {};
        }
        if (this.props.values == this.optionsBindedValues) {
            return this.nodeOptions;
        }
        this.optionsBindedValues = this.props.values;
        //Get custom options from bindings
        const funcId = this.props.layout.automation.optionsBindings[this.props.node.id];
        const func = retrieveFunction(funcId);
        const form = makeForm(this.props.values, this.props.data);
        try {
            const optionsDiff = func(form);
            return Object.assign({}, this.props.node.options, optionsDiff);
        } catch (ex) {
            console.error("Options binding error", this.props.node.id, ex);
        }
        return this.props.node.options || {};
    }

    render() {
        this.nodeOptions = this.getNodeOptions();

        let optional = {};
        if (this.props.editable) {
            optional.selectRow = {
                mode: 'checkbox',
                onSelect: this.onSelect,
                onSelectAll: this.onSelectAll,
                bgColor: '#80BEE6',
                clickToSelect: true,
                selected: this.state.selected
            };
        }
        const tableStye = {
            margin: "0px",
            borderRadius: "0px"
        };

        const containerStyle = {
            overflowX: 'auto'
        };

        const options = {
            noDataText: MSG_TABLE_NO_DATA_TO_DISPLAY
        }

        let contents = (
            <BootstrapTable
                version='4'
                options={options}
                tableStyle={tableStye}
                containerStyle={containerStyle}
                data={this.getWithCaching("rows", this.getRows)}
                striped={true}
                hover={true}
                {...optional}>
                {this.getWithCaching("header", this.getHeader)}
            </BootstrapTable>);

        //Select view depending on form status
        if (this.props.editable) {
            return this.wrapToolbar(this.props.node.label, this.getToolbar(), contents);
        }
        return this.wrapLarge(this.props.node.label, contents);
    }
}

/**
 * onClick: ()=>{},
 * style:{},
 * icon: string,
 * disabled: boolean
 */
class Button extends React.PureComponent {

    checkAccess() {
        if (!this.props.acl) {
            return true;
        }
        const aclList = this.props.acl.replace(/ /g, "").split(",");
        for (let aclRule of aclList) {
            if (this.props.generalAccessRules[aclRule]) {
                return true;
            }
        }
        return false;
    }

    isHidden() {
        return Boolean(this.props.hidden);
    }

    render() {
        if (!this.checkAccess() || this.isHidden()) {
            return null;
        }
        return <button type="button" className="btn btn-secondary" onClick={this.props.onClick} disabled={this.props.disabled}>
            <i className={`fa fa-${this.props.icon} fa-fw`} style={this.props.style} aria-hidden="true"></i>
            {this.props.children}
        </button>
    }
}

export default ObjectTable;