import * as Actions from '../constants/filedrop';
import { addAlert } from './alert';
import { ALERT_DANGER, ALERT_SUCCESS } from '../constants/alert';

const UPLOAD_FILE_URL = "/rest/file/upload";

function recursiveUploadSearch(data, path, taskList) {
    if ($.type(data) == 'array') {
        for (let idx in data) {
            path.push(parseInt(idx));
            recursiveUploadSearch(data[idx], path, taskList);
            path.pop();
        }
    } else if ($.type(data) == 'object') {
        if (typeof data.$uploadKey != 'undefined') {
            let task = {
                file: globalUploadMap[data.$uploadKey],
                path: [].concat(path) //copy path
            }
            if (typeof task.file != 'undefined') {
                taskList.push(task);
            } else {
                console.error("Unknown file key", data.$uploadKey);
            }
        } else {
            for (let p in data) {
                path.push(p);
                recursiveUploadSearch(data[p], path, taskList);
                path.pop();
            }
        }
    }
}

function ajaxUpload({ entry, index, loadedList, totalList, uploadComplete, uploadProgress, uploadUrl }) {
    let formData = new FormData();
    formData.append('file', entry);
    return $.ajax({
        url: uploadUrl ? uploadUrl : UPLOAD_FILE_URL,
        type: 'POST',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function (data) {
            uploadComplete(data);
        },
        xhr: function () { //to listen for upload status
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress", function (evt) {
                if (evt.lengthComputable) {
                    loadedList[index] = evt.loaded;
                    totalList[index] = evt.total;
                    //Sum of loaded
                    let loaded = 0;
                    for (let s of loadedList) {
                        loaded += s;
                    }
                    //Sum of total
                    let total = 0;
                    for (let s of totalList) {
                        total += s;
                    }
                    let percentComplete = parseInt((loaded / total) * 100);
                    uploadProgress(percentComplete);

                }
            }, false);
            return xhr;
        }
    });
}

export function registerUpload({ files }) {
    return {
        type: Actions.REGISTER_UPLOAD,
        payload: { files }
    };
}

export function uploadFiles(data, uploadComplete, uploadProgress, uploadUrl) {
    let uploads = [];
    let loadedList = [];
    let totalList = [];
    //Upload tasks
    for (let entry of data) {
        let index = loadedList.length;
        loadedList.push(0);
        totalList.push(0);
        uploads.push(ajaxUpload({ entry, index, loadedList, totalList, uploadComplete, uploadProgress, uploadUrl }));
    }
    return $.when.apply($, uploads);
}

export function startUpload({ files, uploadProgress, uploadUrl, successCallback, failCallback }) {
    return function (dispatch, getState) {
        let uploadList = [];
        uploadFiles(files, function (data) {
            uploadList.push(data);
        }, uploadProgress, uploadUrl).done(function (response) {
            dispatch(uploadFinished({ uploadList }));
            dispatch(addAlert(ALERT_SUCCESS, { id: "FILEDROP_UPLOAD_SUCCESS" }));
            if (typeof successCallback == "function") {
                successCallback(response);
            }
        }).fail(function (error) {
            dispatch(clearUploadMap());
            dispatch(addAlert(ALERT_DANGER, { id: "FILEDROP_UPLOAD_ERROR" }));
            console.error("Can't upload file(s):", error);
            if (typeof failCallback == "function") {
                failCallback();
            }
        });
        dispatch(plainStartUpload());
    };
}

function plainStartUpload() {
    return {
        type: Actions.START_UPLOAD,
        payload: null
    };
}

function uploadFinished({ uploadList }) {
    return {
        type: Actions.UPLOAD_FINISHED,
        payload: { uploadList }
    };
}

export function clearUploadMap() {
    return {
        type: Actions.CLEAR_UPLOAD,
        payload: null
    };
}