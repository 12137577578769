import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../modal.jsx';
import Plugin from '../../plugin/plugin.jsx';

class PluginModal extends React.Component {

    getTitle() {
        if (this.props.options.title) {
            return this.props.options.title;
        }
        return (<FormattedMessage
            id="PLUGIN_MODAL_TITLE"
            defaultMessage="Plugin modal"
            description="Plugin modal" />);
    }

    getBody() {
        return (<div className="container-fluid">
            <Plugin
                plugin={this.props.options.plugin}
                view={this.props.options.view}
                options={this.props.options.dataOptions}
            />
        </div>);
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for Plugin*/
            title={this.getTitle()}
            body={this.getBody()} />);
    }
}

export default PluginModal;