import serverConfigReducer, { setupInitialState } from './reducers/serverconfig';
import { SERVER_CONFIG } from './constants/serverconfig'

function getTimeDifference() {
    const serverTime = parseInt($('meta[name=server-time]').attr("content")) || Date.now();
    const localTime = Date.now();
    const difference = localTime - serverTime;
    if(Math.abs(difference) < 5000){
        return 0;
    }
    return difference;
}

function getVersionInfo() {
    const versionMeta = $('meta[name=version]').attr("content");
    if (!versionMeta) {
        return null;
    }
    try {
        return JSON.parse(versionMeta);
    } catch (ex) {
        console.error(ex);
        return null;
    }
}

//Initialization function
export default (dispatcher) => {
    const timeDifference = getTimeDifference();
    const versionInfo = getVersionInfo();
    setupInitialState({ timeDifference, versionInfo });
    //Register reducer
    dispatcher.registerReducer(SERVER_CONFIG, serverConfigReducer);
}

