import React from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { MSG_BUTTON_CLOSE, MSG_BUTTON_CONFIRM, MSG_BUTTON_WAIT } from '../messages.jsx'
import DebounceInput from '../debounceinput.jsx';

export const CHANGE_PASSWORD_DLG = "change-password-dialog";

export const MSG_CHANGE_PASSWORD = (
    <FormattedMessage id="USERMENU_CHANGE_PASSWORD"
        defaultMessage="Change password"
        description="Menu item to change password" />);

const REQUIREMENT_MIN_SIX_SYMBOLS = <FormattedMessage id="REQUIREMENT_MIN_SIX_SYMBOLS"
    defaultMessage="Minimum 6 symbols"
    description="Requirement of minimum 6 symbols to be used" />;

const REQUIREMENT_ONLY_NUMBERS_AND_LETTERS = <FormattedMessage id="REQUIREMENT_ONLY_NUMBERS_AND_LETTERS"
    defaultMessage="Only numbers and letters"
    description="Requirement of only numbers and letters to be used" />;

export const CHANGE_PASSWORD_URL = "rest/self/changepassword";

const initialState = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
    wrongPasswordError: false,
    pending: false,
    requirements: [
        {
            status: false,
            message: "REQUIREMENT_MIN_SIX_SYMBOLS",
            check: function (password) {
                return password.length >= 6;
            }
        },
        {
            status: false,
            message: "REQUIREMENT_ONLY_NUMBERS_AND_LETTERS",
            check: function (password) {
                return password && !(new RegExp('[^a-zA-Zа-яА-Я0-9]').test(password));
            }
        }
    ]
}

function checkPassword(password) {
    return password;
}

function checkRequirements(requirements) {
    for (let requirement of requirements) {
        if (!requirement.status) {
            return false;
        }
    }
    return true;
}

function setRequiremnts(nextState, password) {
    nextState.requirements = nextState.requirements.slice();
    for (let i = 0; i < nextState.requirements.length; ++i) {
        const requirement = Object.assign({}, nextState.requirements[i]);
        requirement.status = requirement.check(password);
        nextState.requirements[i] = requirement;
    }
}

class PasswordChangeDialog extends React.Component {

    constructor(props) {
        super(props);

        this.changePassword = this.changePassword.bind(this);

        this.state = Object.assign({}, initialState);
        setRequiremnts(this.state, this.state.newPassword);
    }

    changePassword() {
        this.setState(Object.assign({}, this.state, { pending: true }));
        let _this = this;
        $.post(this.props.contextPath + CHANGE_PASSWORD_URL + "?" + $.param({
            "current": this.state.currentPassword,
            "new": this.state.newPassword
        })).done(function (result) {
            _this.setState(Object.assign({}, initialState));
            $(ReactDOM.findDOMNode(_this)).modal("hide");
        }).fail(function (error) {
            /* Current password was wrong */
            _this.setState(Object.assign({}, _this.state, { wrongPasswordError: true, pending: false }));
            return;
        })
    }

    validate() {
        return checkPassword(this.state.currentPassword)
            && checkPassword(this.state.newPassword)
            && checkRequirements(this.state.requirements)
            && this.state.newPassword == this.state.newPasswordConfirmation
            && !this.state.wrongPasswordError;
    }

    changeHandler(valueName, newValue) {
        if ($.isEmptyObject(newValue)) {
            newValue = "";
        }
        if (this.state[valueName] == newValue) {
            return;
        }
        let nextState = Object.assign({}, this.state);
        if (valueName == "currentPassword" && this.state.wrongPasswordError) {
            nextState.wrongPasswordError = false;
        }
        if (valueName == "newPassword") {
            setRequiremnts(nextState, newValue);
        }
        nextState[valueName] = newValue;
        this.setState(nextState);
    }

    render() {
        let currentPasswordValid = checkPassword(this.state.currentPassword) && !this.state.wrongPasswordError;
        let newPasswordValid = checkPassword(this.state.newPassword) && checkRequirements(this.state.requirements);
        let confirmationPasswordValid = this.state.newPasswordConfirmation == this.state.newPassword;
        return (
            <div id={CHANGE_PASSWORD_DLG} className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">
                                {MSG_CHANGE_PASSWORD}
                            </h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form className="col-md-12 nopadding mb-2">
                                <div className={"form-group"}>
                                    <label htmlFor="prev-pwd">
                                        <FormattedMessage id="CHANGE_PASSWORD_PREV_PWD"
                                            defaultMessage="Previous password:"
                                            description="User should enter previous password to identify himself" />
                                    </label>
                                    <DebounceInput
                                        editable={!this.state.pending}
                                        password={true}
                                        className={"form-control" + (currentPasswordValid ? "" : " is-invalid")}
                                        value={this.state.currentPassword}
                                        change={this.changeHandler.bind(this, "currentPassword")} >
                                    </DebounceInput>
                                </div>
                                <div className={"form-group"}>
                                    <label htmlFor="pwd">
                                        <FormattedMessage id="CHANGE_PASSWORD_NEW_PWD"
                                            defaultMessage="New password:"
                                            description="User should enter new password" />
                                    </label>
                                    <DebounceInput
                                        editable={!this.state.pending}
                                        password={true}
                                        className={"form-control" + (newPasswordValid ? "" : " is-invalid")}
                                        value={this.state.newPassword}
                                        change={this.changeHandler.bind(this, "newPassword")} >
                                    </DebounceInput>
                                </div>
                                <div className={"form-group"}>
                                    <label htmlFor="conf-pwd">
                                        <FormattedMessage id="CHANGE_PASSWORD_CONF_NEW_PWD"
                                            defaultMessage="Confirm new password:"
                                            description="User should enter the same password once more" />
                                    </label>
                                    <DebounceInput
                                        editable={!this.state.pending}
                                        password={true}
                                        className={"form-control" + (confirmationPasswordValid ? "" : " is-invalid")}
                                        value={this.state.newPasswordConfirmation}
                                        change={this.changeHandler.bind(this, "newPasswordConfirmation")} >
                                    </DebounceInput>
                                </div>
                            </form>
                            {this.state.requirements.map((requirement) => <Recuirement status={requirement.status} message={requirement.message} />)}
                            {this.state.wrongPasswordError && <div class="alert alert-danger mt-2" role="alert">
                                <FormattedMessage id="CHANGE_PASSWORD_PREVIOUS_WRONG"
                                    defaultMessage="Previous password is wrond"
                                    description="Message of change password dialog whon previous password was set incorrectly" />
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.changePassword} disabled={this.state.pending || !this.validate()}>
                                {this.state.pending ? MSG_BUTTON_WAIT : MSG_BUTTON_CONFIRM}
                            </button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                {MSG_BUTTON_CLOSE}
                            </button>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

class Recuirement extends React.PureComponent {

    getOkIcon() {
        return <i className="fa fa-fw fa-check text-success"></i>;
    }

    getErrorIcon() {
        return <i className="fa fa-fw fa-times text-danger"></i>;
    }

    render() {
        return <div>
            {this.props.status ? this.getOkIcon() : this.getErrorIcon()}
            <FormattedMessage id={this.props.message} />
        </div>
    }
}

export default PasswordChangeDialog;