import React from 'react';
import { FormattedMessage } from 'react-intl';

class ClearButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.clearCache({ list: this.props.idList, id: this.props.id })}>
            <FormattedMessage
                id="CACHE_CLEAR_BUTTON"
                defaultMessage="Clear"
                description="Label of clear button" />
        </button>);
    }
}

export default ClearButton;


