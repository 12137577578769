import React from 'react';

function getErrorMessage(e) {
    try {
        return e.toString();
    } catch (e) {
        console.error(e);
    }
}

function reload() {
    document.location.reload();
}

export default class Plugin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: null
        };
    }

    async componentDidMount() {
        const imp = window.__NPT__.import;
        const plugins = window.__ui_plugins__;
        const path = plugins[this.props.plugin];
        if (!path) {
            this.setState({ error: "Plugin not found: " + this.props.plugin});
            return;
        }
        try {
            const module = await imp(path);
            if (typeof module.views != 'undefined') {
                if (typeof module.views[this.props.view] != 'undefined') {
                    this.setState({ view: module.views[this.props.view] });
                } else {
                    console.error("View not found in plugin " + this.props.view, module);
                    this.setState({ error: "View not found in plugin " + this.props.view})
                }
            } else {
                console.error("Plugin module is invalid", module);
                this.setState({ error: "Plugin module is invalid " + this.props.plugin})
            }
        } catch (e) {
            console.error("Plugin module was not loaded " + this.props.plugin, e)
            this.setState({ error: e })
        }
    }

    componentDidCatch(error, errorInfo) {
        console.error("Catch plugin module error", error, errorInfo)
        this.setState({
            error
        });
    }

    render() {
        const e = this.state.error;
        if (e) {
            return <div className="d-flex flex-column w-100 h-100 justify-content-center bg-light">
                <div class="alert alert-danger align-self-center" role="alert">
                    <p>{getErrorMessage(e)}</p>
                    <button onClick={() => reload()} type="button" class="btn btn-danger">
                        <i class="fa fa-refresh a-5x" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        }

        const View = this.state.view;
        if (View) {
            return <View {...this.props.options}/>
        }
        
        return <div></div>
    }
}