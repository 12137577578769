import { connect, Provider } from 'react-redux';
import SourceEditor from './components/srceditor/srceditor.jsx';
import { fetchCode, changeParam, editCurrentCode, cancelEditCurrentCode, saveCode, ajaxSaveCode, receiveCode } from './actions/srceditor';
import { openCodePopup, ajaxMoveNode } from './actions/srctree';
import { safeGetEditor, getSrcEditorState } from './services/srceditor';
import { FormattedMessage } from 'react-intl';
import { LOCATION } from './constants/location';
import { I18N } from './constants/i18n';
import { ATTR_SRC_TYPES } from './srctree.jsx';
import { openModal } from './actions/modal';
import { SRCEDITOR } from './constants/srceditor';
import srceditor from './reducers/srceditor';

const ATTR_SRC_EDITOR = "src-editor";
const ATTR_MIME_VIEW = "mime-view";

const RECEIVE_HISTORY_ERROR = (<FormattedMessage
    id="RECEIVE_HISTORY_ERROR"
    defaultMessage="Failed to load history"
    description="Inform user that history of source code was not loaded from server" />)

const RECEIVE_CODE_ERROR = (<FormattedMessage id="SRCEDITOR_RECEIVE_CODE_ERROR"
    defaultMessage="Failed to load code: {path}"
    description="Inform user that source code was not loaded from server" />)

const SAVE_CODE_ERROR = (<FormattedMessage id="SRCEDITOR_SAVE_CODE_ERROR"
    defaultMessage="Failed to save code: {path}"
    description="Inform user that source code was not saved to server" />)

//Map global (redux) state to navigation tree state
function createMapStateToProps(editorId, mimeView) {
    return function (globalState) {
        let editorState = safeGetEditor(getSrcEditorState(globalState), editorId);
        let edit = editorState.lock && editorState.lock.status;
        return {
            status: editorState.status,
            entity: editorState.entity,
            lock: editorState.lock,
            path: globalState[LOCATION].path,
            contextPath: globalState[LOCATION].contextPath,
            messages: globalState[I18N].messages,
            edit: edit,
            editorId: editorId,
            mimeView: mimeView
        };
    }
}

function createMapDispachToProps(editorId, sourceTypes) {
    return function (dispatch) {
        return {
            fetchCode: function (path) {
                dispatch(fetchCode(editorId, path));
            },
            changeCode: function (code) {
                dispatch(changeParam(editorId, "code", code));
            },
            saveCode: function () {
                dispatch(saveCode(editorId));
            },
            editCurrentCode: function () {
                dispatch(editCurrentCode(editorId))
            },
            cancelEditCurrentCode: function () {
                dispatch(cancelEditCurrentCode(editorId))
            },
            changeMetainfo: function (node) {
                openCodePopup(dispatch, { id: "SRC_EDITOR_CHANGE_CODE_METAINFO" }, node, sourceTypes, (modal, modalState) => {
                    let movePath = null;
                    if (modalState.path != node.path) {
                        movePath = modalState.path;
                        modalState.path = node.path;
                    }
                    modalState.code = node.code;
                    modalState.id = node.id;
                    ajaxSaveCode(editorId, modalState, modal, dispatch, function (data) {
                        dispatch(receiveCode(editorId, data.path, data));
                        if (movePath) {
                            ajaxMoveNode(editorId, data.path, movePath, false, dispatch);
                        }
                    });
                })
            },
            openModal: function (modalId, type, options, okCallback, cancelCallback, closeCallback) {
                dispatch(openModal(modalId, type, options, okCallback, cancelCallback, closeCallback));
            }
        }
    }
}

function createSourceEditorContainer(editorId, mimeView, sourceTypes) {
    //Connect redux to our navigation tree
    return connect(
        createMapStateToProps(editorId, mimeView),
        createMapDispachToProps(editorId, sourceTypes)
    )(SourceEditor);
}

//Initialization function
export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(SRCEDITOR, srceditor);
    //Register components
    dispatcher.registerComponent(ATTR_SRC_EDITOR, (elm) => {
        let editorId = $(elm).attr(ATTR_SRC_EDITOR);
        let mimeView = $(elm).attr(ATTR_MIME_VIEW);
        let sourceTypes = [];
        if (elm.hasAttribute(ATTR_SRC_TYPES)) {
            sourceTypes = $(elm).attr(ATTR_SRC_TYPES).split(",");
        }
        let SourceEditorContainer = createSourceEditorContainer(editorId, mimeView, sourceTypes);
        return (<SourceEditorContainer />);
    });
};

