import shortid from 'shortid';
import minimatch from "minimatch";
import {
    PREDICATE_PLACEHOLDER
} from '../constants/objectcard';

/**Layout tree for fast modification of layout*/
class LayoutTree {

    constructor(layout) {
        //Map between id and element in layout tree
        this.idMap = {};
        //Map between layout element and it's parent
        this.parentMap = {};
        //Map between predicate fully qualified name and it's id
        this.predicateMap = {};
        //Predicate placeholders
        this.predicatePlaceholders = [];
        //build maps
        this.build(layout);
    }

    build(node) {
        //Generate unique id for each node
        node.id = shortid.generate();
        //Save node in map
        this.idMap[node.id] = node;
        //Recognize predicate placeholders
        if (node.ui == PREDICATE_PLACEHOLDER) {
            this.predicatePlaceholders.push(node.id);
            return;
        }
        //Recognize predicates
        if ($.type(node.namespace) == "object" && typeof node.name == "string") {
             let uri = node.namespace.url + node.name;
             this.predicateMap[uri] = node.id;
             return;
        }
        //Continue with content
        let content = node.content;
        if ($.type(content) == "array") {
            for (let child of content) {
                //Skip non-objects!
                if ($.type(child) != "object") {
                    continue;
                }
                //Recursively build child
                this.build(child);
                //Save child to parent relation
                this.parentMap[child.id] = node.id;
            }
        }
    }

    findPredicate(uriGlob) {
        let mm = new minimatch.Minimatch(uriGlob, {nocomment : true});
        for (let uri in this.predicateMap) {
            /*if (uri.indexOf("name") >= 0) {
                console.log(uri);
            }*/
            if (mm.match(uri)) {
                return this.predicateMap[uri];
            }
        }
        console.error("Predicate not found: " + uriGlob);
        return null;
    }

    removeEmpty() {
        let found = null;
        for (let id in this.idMap) {
            let content = this.idMap[id].content;
            if ($.type(content) == "array" && content.length == 0) {
                found = id;
                break;
            }
        }
        if (found == null) {
            return false;
        }
        this.replace(found, null);
        return true;
    }

    removeAllEmpty() {
        //Continue till we will not found empty content
        while (this.removeEmpty()) {}
    }

    replacePlaceholders() {
        for (let id of this.predicatePlaceholders) {
            let ref = this.idMap[id].ref;
            if (typeof ref != 'string') {
                continue;
            }
            //Replace placeholder
            this.replace(id, this.findPredicate(ref));
        }
    }

    replace(id, replacementId) {
        let parentId = this.parentMap[id];
        if (typeof parentId == 'undefined') {
            console.error("Unknown parent for " + id);
            return;
        }
        //Get parent object
        let parent = this.idMap[parentId];
        if (replacementId == null) {    
            //We should remove element from parent content
            parent.content = parent.content.filter(function(e){
                return $.type(e) != "object" || e.id != id;
            });
        } else {
            let replacement = this.idMap[replacementId];
            let placeholder = this.idMap[id];
            let replacementParentId = this.parentMap[replacementId];
            if (typeof replacement == 'undefined' || typeof replacementParentId == 'undefined' || typeof placeholder == 'undefined') {
                console.error("Unknown replacement or parent for " + replacementId);
                return;
            }
            //We should copy placeholder options
            if (placeholder.options) {
                replacement = Object.assign({}, replacement, placeholder.options);
                //console.log("Replacement with options:", replacement);
            }
            let replacementParent = this.idMap[replacementParentId];
            //Remove from previous parent
            replacementParent.content = replacementParent.content.filter(function(e){
                return $.type(e) != "object" || e.id != replacementId;
            });
            //Do replace element
            parent.content = parent.content.map(function(e){
                if ($.type(e) != "object" || e.id == id) {
                    return replacement;
                }
                return e;
            });
            //Change parent in map
            this.parentMap[replacementId] = parentId;
        }
        //Remove from all maps
        delete this.idMap[id];
        delete this.parentMap[id];
        delete this.predicateMap[id];
    }
}

export default LayoutTree;