import { SOURCE, TARGET, SOURCE_TREE, TARGET_TREE, COMMON_VENDORS, COMMON_VENDORS_IDS } from "../constants/rpasetpoints";

export function getIcon(icon) {
    if (!icon) {
        return null;
    }
    let cls = "fa fa-fw " + icon;
    return (<i className={cls} aria-hidden="true"></i>);
}

export function getSelectedNode(tree) {
    if (!tree.active || !tree.nodeById[tree.active]) {
        return null;
    }
    return tree.nodeById[tree.active];
}

export function isFolder(node) {
    return Boolean(node.folder);
}

export function canBeBinded(sourceNode, targetNode, sourceParent, targetParent) {
    if (!sourceNode || !targetNode
        || isFolder(sourceNode)
        || isFolder(targetNode)
        || isLinked(sourceNode, targetNode)) {
        return false;
    }
    return true;
}

export function isLinked(sourceNode, targetNode) {
    if (!sourceNode || !targetNode) {
        return false;
    }
    if (isFolder(sourceNode) && isFolder(targetNode)) {
        return sourceNode.linkedName == targetNode.name;
    }
    if (!Array.isArray(sourceNode.comparedId)) {
        return sourceNode.comparedId == targetNode.id;
    }
    /* ComparedId is list */
    for (let comparedId of sourceNode.comparedId) {
        if (comparedId == targetNode.id) {
            return true;
        }
    }
    return false;
}

export function getTabTreeId(rpaSetpointsState, treeId, withoutFilter) {
    if (treeId == SOURCE_TREE) {
        return SOURCE[rpaSetpointsState.activeTab];
    }
    let targetId = TARGET[rpaSetpointsState.activeTab];
    if (!withoutFilter && rpaSetpointsState[targetId + "Filtered"]) {
        return targetId + "Filtered";
    }
    return targetId;
}

export function getTabTree(rpaSetpointsState, treeType, withoutFilter) {
    return rpaSetpointsState[getTabTreeId(rpaSetpointsState, treeType == SOURCE ? SOURCE_TREE : TARGET_TREE, withoutFilter)];
}

export function getVendorShortName(fullName) {
    fullName = fullName.toString().toLowerCase();
    for (let vendorId of COMMON_VENDORS_IDS) {
        if (fullName.indexOf(vendorId) != -1) {
            return vendorId;
        }
    }
    return null;
}

export function getVoltageLevel(stringVoltageLevel) {
    const parts = stringVoltageLevel.split(" ");
    for (let part of parts) {
        const number = parseFloat(part);
        if (isNaN(number)) {
            continue;
        }
        if (part.indexOf("-") == -1) {
            return number;
        }
        const minVoltage = parseFloat(part.substring(0, part.indexOf("-")));
        const maxVoltage = parseFloat(part.substring(part.indexOf("-") + 1));
        return { min: minVoltage, max: maxVoltage };
    }
    return null;
}

function isEqual(valueA, valueB) {
    if (typeof valueA == "undefined") {
        return typeof valueB == "undefined";
    }
    return valueA == valueB;
}

function isNodesEqual(dataA, dataB) {
    if (!dataB || !dataB.nodeById) {
        return false;
    }
    for (let id in dataA.nodeById) {
        const nodeA = dataA.nodeById[id];
        const nodeB = dataB.nodeById[id];
        if (!nodeB || !isEqual(nodeA.comparedId, nodeB.comparedId) || !isEqual(nodeA.value, nodeB.value)) {
            return false;
        }
    }
    return true;
}

export function isDataChanged(rpaSetpointsState) {
    return !isNodesEqual(rpaSetpointsState.setpoints, rpaSetpointsState.cachedState.setpoints)
        || !isNodesEqual(rpaSetpointsState.analogSignals, rpaSetpointsState.cachedState.analogSignals)
        || !isNodesEqual(rpaSetpointsState.discreteSignals, rpaSetpointsState.cachedState.discreteSignals);
}