'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import TreeNode from './node';
import defaultDecorators from './decorators';
import defaultTheme from '../themes/default';
import defaultAnimations from '../themes/animations';


class Treebeard extends React.Component {

    shouldComponentUpdate(nextProps) {
        if (this.props.roots !== nextProps.roots) {
            return true;
        }
        return false;
    }

    render() {
        const { animations, decorators, roots, onToggle, style } = this.props;

        return (
            <ul style={style.tree.base}
                ref={ref => this.treeBaseRef = ref}>
                {roots.map((id, index) => {
                    let Node = this.props.connectNodeFunction(TreeNode);
                    return (<Node treeAnimations={animations}
                        treeDecorators={decorators}
                        key={id}
                        id={id}
                        style={style.tree.node}
                        connectNodeFunction={this.props.connectNodeFunction} />);
                }
                )}
            </ul>
        );
    }
}

Treebeard.propTypes = {
    style: PropTypes.object,
    roots: PropTypes.array.isRequired,
    animations: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool
    ]),
    onToggle: PropTypes.func,
    connectNodeFunction: PropTypes.func.isRequired,
    decorators: PropTypes.object
};

Treebeard.defaultProps = {
    style: defaultTheme,
    animations: defaultAnimations,
    decorators: defaultDecorators
};

export default Treebeard;
