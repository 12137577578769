import React from "react";
// import { Map, TileLayer, Marker, Popup } from "react-leaflet";
const { Map: LeafletMap, TileLayer, Marker, Popup } = ReactLeaflet
import 'leaflet/dist/leaflet.css';
import './cimmap.css';

export default class CimMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = { zoom: 12, };

    }
    render() {
        const position = [51.505, -0.09]; 
                

        return ( 
                <LeafletMap center={position} zoom={this.state.zoom}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                    />
                    <Marker position={position}>
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                    </Marker>
                </LeafletMap> 
            )
    }
}