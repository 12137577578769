import * as Action from '../constants/srceditor.js';
import { safeGetEditor } from '../services/srceditor'

const initialState = {};

function waitForCode(state, { editorId, path }) {
    let editorState = safeGetEditor(state, editorId);
    editorState = Object.assign({}, editorState, { status: Action.STATUS_LOADING, error: false, entity: { path } });
    return Object.assign({}, state, { [editorId]: editorState });
}

function receiveCode(state, { editorId, path, data }) {
    let editorState = safeGetEditor(state, editorId);
    editorState = Object.assign({}, editorState, { status: Action.STATUS_READY, error: false, entity: data, lock: { status: false } });
    return Object.assign({}, state, { [editorId]: editorState });
}

function receiveCodeError(state, { editorId, error }) {
    let editorState = safeGetEditor(state, editorId);
    editorState = Object.assign({}, editorState, { status: Action.STATUS_ERROR, error });
    return Object.assign({}, state, { [editorId]: editorState });
}

function receiveHistory(state, { editorId, path, data }) {
    let editorState = safeGetEditor(state, editorId);
    editorState = Object.assign({}, editorState);
    editorState.history = Object.assign({}, editorState.history);
    editorState.history[path] = { data, error: null };
    return Object.assign({}, state, { [editorId]: editorState });
}

function receiveHistoryError(state, { editorId, path, error }) {
    let editorState = safeGetEditor(state, editorId);
    editorState = Object.assign({}, editorState);
    editorState.history = Object.assign({}, editorState.history);
    editorState.history[path] = { data: null, error };
    return Object.assign({}, state, { [editorId]: editorState });
}

function changeParam(state, { editorId, param, value }) {
    let editorState = safeGetEditor(state, editorId);
    let entity = Object.assign({}, editorState.entity, { [param]: value });
    editorState = Object.assign({}, editorState, { entity });
    return Object.assign({}, state, { [editorId]: editorState });
}

function editLockReceived(state, { editorId, lock }) {
    let editorState = safeGetEditor(state, editorId);
    let lockState = {
        status: lock
    };
    if (lock) {
        let backup = $.extend(true, {}, editorState.entity);
        editorState = Object.assign({}, editorState, { lock: lockState, backup });
    } else if (editorState.backup) {
        editorState = Object.assign({}, editorState, { lock: lockState, entity: editorState.backup });
        delete editorState.backup;
    }
    return Object.assign({}, state, { [editorId]: editorState });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.WAIT_FOR_CODE: return waitForCode(state, action.payload);
        case Action.RECEIVE_CODE: return receiveCode(state, action.payload);
        case Action.RECEIVE_CODE_ERROR: return receiveCodeError(state, action.payload);
        case Action.RECEIVE_HISTORY: return receiveHistory(state, action.payload);
        case Action.RECEIVE_HISTORY_ERROR: return receiveHistoryError(state, action.payload);
        case Action.CHANGE_PARAM: return changeParam(state, action.payload);
        case Action.EDIT_LOCK_RECEIVED: return editLockReceived(state, action.payload);
        default: return state;
    }
}