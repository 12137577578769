import Modal from './modals/modal.jsx';
import CardModal from './modals/templates/cardmodal.jsx';
import NavTreeModal from './modals/templates/navtreemodal.jsx';
import CimTableModal from './modals/templates/cimtablemodal.jsx';
import StringFilterModal from './modals/templates/stringfilter.jsx';
import NumberFilterModal from './modals/templates/numberfilter.jsx';
import DateFilterModal from './modals/templates/datefilter.jsx';
import DateTimeFilterModal from './modals/templates/datetimefilter.jsx';
import TableOptionsModal from './modals/templates/tableoptions.jsx';
import CommonModal from './modals/templates/common.jsx';
import LoginModal from './modals/templates/login.jsx';
import SelectEnumModal from './modals/templates/selectenum.jsx';
import QuizModal from './modals/templates/quiz.jsx';
import PluginModal from './modals/templates/plugin.jsx';

export default {
    "confirm": Modal, //Simple modal
    "card": CardModal,
    "navtree": NavTreeModal,
    "cimtable": CimTableModal,
    "stringfilter": StringFilterModal,
    "numberfilter": NumberFilterModal,
    "datefilter": DateFilterModal,
    "datetimefilter": DateTimeFilterModal,
    "tableoptions": TableOptionsModal,
    "common": CommonModal,
    "login": LoginModal,
    "enum": SelectEnumModal,
    "quiz": QuizModal,
    "plugin": PluginModal
};