import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../messages.jsx'
import { EVENT_TASKS_LOCAL_SERVER, LOCAL_SERVER_KEY } from '../../constants/eventtasks.js';

export default class EventDetailsModal extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            "id": null,
            "event": null,
            "label": null,
            "description": null,
            "scriptId": null,
            "scriptPath": null,
            "server": null
        }

        if (props.event) {
            Object.assign(this.state, props.event);
        }

        props.setDataReceiver(() => {
            return this.state;
        });

        props.setResultReceiver(() => {
            return this.isValid();
        });

        this.changeEvent = this.changeEvent.bind(this);
        this.changeLabel = this.changeLabel.bind(this);
        this.changeDescription = this.changeDescription.bind(this);
        this.changeScriptPath = this.changeScriptPath.bind(this);
        this.changeServer = this.changeServer.bind(this);
    }

    isValid() {
        return true;
    }

    getSources() {
        const sources = this.props.sourceList.slice();
        sources.unshift({ server: LOCAL_SERVER_KEY, compositeName: EVENT_TASKS_LOCAL_SERVER });
        return sources;
    }

    changeEvent(newValue) {
        if (this.state.event == newValue) {
            return;
        }
        this.setState(Object.assign({}, this.state, { event: newValue }));
    }

    changeLabel(newValue) {
        if (this.state.label == newValue) {
            return;
        }
        this.setState(Object.assign({}, this.state, { label: newValue }));
    }

    changeDescription(newValue) {
        if (this.state.description == newValue) {
            return;
        }
        this.setState(Object.assign({}, this.state, { description: newValue }));
    }

    changeScriptPath(newValue) {
        if (this.state.scriptPath == newValue) {
            return;
        }
        this.setState(Object.assign({}, this.state, { scriptPath: newValue }));
    }

    changeServer(newValue) {
        if (this.state.server == newValue) {
            return;
        }
        this.setState(Object.assign({}, this.state, { server: newValue }));
    }

    componentDidMount() {
        /* Update parent modal data and result receivers */
        this.props.updateParentModal();
    }

    render() {
        return <form className="form-horizontal col-md-12 nopadding">
            <fieldset>
                <Row>
                    <LabelWrapper>
                        <FormattedMessage id="EVENT_TASKS_EVENT" />:
                    </LabelWrapper>
                    <InputWrapper>
                        <Input isInvalid={false} value={this.state.event} onChange={this.changeEvent} />
                    </InputWrapper>
                </Row>
                <Row>
                    <LabelWrapper>
                        <FormattedMessage id="EVENT_TASKS_LABEL" />:
                    </LabelWrapper>
                    <InputWrapper>
                        <Input isInvalid={false} value={this.state.label} onChange={this.changeLabel} />
                    </InputWrapper>
                </Row>
                <Row>
                    <LabelWrapper>
                        <FormattedMessage id="EVENT_TASKS_DESCRIPTION" />:
                    </LabelWrapper>
                    <InputWrapper>
                        <Input isInvalid={false} value={this.state.description} onChange={this.changeDescription} />
                    </InputWrapper>
                </Row>
                <Row>
                    <LabelWrapper>
                        <FormattedMessage id="EVENT_TASKS_SCRIPT_PATH" />:
                    </LabelWrapper>
                    <InputWrapper>
                        <Select
                            ref="pathSelect"
                            searchable="true"
                            className={"Select-xs"}
                            loadingPlaceholder={MSG_SELECT_LOADING}
                            placeholder={MSG_SELECT_PLACEHOLDER}
                            noResultsText={MSG_SELECT_NO_RESULTS}
                            simpleValue
                            clearable={false}
                            value={this.state.scriptPath}
                            valueKey={"path"}
                            labelKey={"path"}
                            onChange={this.changeScriptPath}
                            options={this.props.scriptList || []}></Select>
                    </InputWrapper>
                </Row>
                <Row>
                    <LabelWrapper>
                        <FormattedMessage id="EVENT_TASKS_SOURCE" />:
                    </LabelWrapper>
                    <InputWrapper>
                        <Select
                            ref="serverSelect"
                            searchable="true"
                            className={"Select-xs"}
                            loadingPlaceholder={MSG_SELECT_LOADING}
                            placeholder={MSG_SELECT_PLACEHOLDER}
                            noResultsText={MSG_SELECT_NO_RESULTS}
                            simpleValue
                            clearable={false}
                            value={this.state.server}
                            valueKey={"server"}
                            labelKey={"compositeName"}
                            onChange={this.changeServer}
                            options={this.getSources() || []}></Select>
                    </InputWrapper>
                </Row>
            </fieldset>
        </form>;
    }
}

class Row extends React.PureComponent {
    render() {
        return <div className="form-group row mb-3">{this.props.children}</div>;
    }
}

class LabelWrapper extends React.PureComponent {
    render() {
        return <div className="col-sm-3 control-label pt-1">{this.props.children}</div>;
    }
}


class InputWrapper extends React.PureComponent {
    render() {
        return <div className="col-sm-9 form-control-sm">{this.props.children}</div>;
    }
}

class Input extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(reactEvent) {
        this.props.onChange(reactEvent.target.value);
    }

    render() {
        let className = "form-control form-control-sml";
        if (this.props.isInvalid) {
            className += " is-invalid";
        }
        return <input className={className} value={this.props.value} onChange={this.onChange} />;
    }
}