import { connect } from 'react-redux';
import shortid from 'shortid';

import {
    fetchSubject,
    createNewSubject,
    saveSubject,
    uploadFiles,
    lockSubject,
    unlockSubject,
    patchUpload,
    addNewSubject,
    initializeStore,
    destroyStore,
    startSave,
    cancelSave,
    changeSaveState,
    click,
    listenerPredicateChanged,
    exportCard,
    reportClick
} from '../actions/objectcard';

import { openModal } from '../actions/modal';

import { addAlert } from '../actions/alert';
import { ALERT_DANGER, ALERT_WARNING } from '../constants/alert';

import { OBJECTCARD, UI_TEXT, UI_TEXT_AREA, UI_INT } from '../constants/objectcard';
import { LOCATION } from '../constants/location';
import { SECURITY } from '../constants/security';
import { I18N } from '../constants/i18n';

//Tree support in cards
import { fetchSubTree, toggleSubTree } from '../actions/navtree';
import { getNavTreeState } from '../services/navtree';

const UPLOAD_URL = "/rest/file/upload";

const EXCEL_EXT = "xlsx";
const WORD_EXT = "docx";

/**Anti pattern! But nobody knows how to do it better.
Global upload map used to link between upload keys and real files
Each value is task
let task = {
    key: key, //pass unique key here
    file: file //pass file to be uploaded
}*/
const globalUploadMap = {};

export function generateSubjectId(subject) {
    let subjectId = "";
    if (typeof subject.$rdfId != 'undefined') {
        if (subject.$namespace) {
            subjectId = subject.$namespace + ":" + subject.$rdfId;
        } else {
            subjectId = subject.$rdfId;
        }
    }
    return subjectId;
}

function ajaxUpload(task, index, loadedList, totalList, uploadComplete, uploadProgress) {
    let formData = new FormData();
    formData.append('file', task.file);
    return $.ajax({
        url: UPLOAD_URL,
        type: 'POST',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function (data) {
            uploadComplete(task, data);
        },
        xhr: function () { //to listen for upload status
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress", function (evt) {
                if (evt.lengthComputable) {
                    loadedList[index] = evt.loaded;
                    totalList[index] = evt.total;
                    //Sum of loaded
                    let loaded = 0;
                    for (let s of loadedList) {
                        loaded += s;
                    }
                    //Sum of total
                    let total = 0;
                    for (let s of totalList) {
                        total += s;
                    }
                    let percentComplete = parseInt((loaded / total) * 100);
                    if (!uploadProgress(percentComplete)) {
                        console.log("Abort upload!");
                        xhr.abort();
                    }

                }
            }, false);
            return xhr;
        }
    });
}

function recursiveUploadSearch(nodeId, data, path, taskList) {
    if ($.type(data) == 'array') {
        for (let idx in data) {
            path.push(parseInt(idx));
            recursiveUploadSearch(nodeId, data[idx], path, taskList);
            path.pop();
        }
    } else if ($.type(data) == 'object') {
        if (typeof data.$uploadKey != 'undefined') {
            let task = {
                nodeId, //node id
                key: data.$uploadKey, //save key to clear upload map
                file: globalUploadMap[data.$uploadKey],
                path: [].concat(path) //copy path in node!
            }
            if (typeof task.file != 'undefined') {
                taskList.push(task);
            } else {
                console.error("Unknown file key", data.$uploadKey);
            }
        } else {
            for (let p in data) {
                path.push(p);
                recursiveUploadSearch(nodeId, data[p], path, taskList);
                path.pop();
            }
        }
    }
}

/**Map between node id and path of upload*/
export function getUploadTaskList(values) {
    const taskList = [];
    for (const nodeId in values) {
        const value = values[nodeId];
        recursiveUploadSearch(nodeId, value, [], taskList);
    }
    return taskList;
}

export function ajaxUploadFiles(taskList, uploadComplete, uploadProgress) {
    let uploads = [];
    let loadedList = [];
    let totalList = [];
    //Upload tasks
    for (let task of taskList) {
        let index = loadedList.length;
        loadedList.push(0);
        totalList.push(0);
        uploads.push(ajaxUpload(task, index, loadedList, totalList, uploadComplete, uploadProgress));
    }
    return $.when.apply($, uploads);
}

//Called by 
export function enqueUpload(file) {
    const key = shortid.generate();
    globalUploadMap[key] = file;
    return key;
}

export function getUpload(key) {
    return globalUploadMap[key];
}

export function clearUploadTasks(taskList) {
    if (taskList.length == 0) {
        return;
    }
    console.log("Before clearUploadTasks:", globalUploadMap);
    for (let task of taskList) {
        delete globalUploadMap[task.key];
    }
    console.log("After clearUploadTasks:", globalUploadMap);
}

export function mergeSubjectValues(subject, values) {
    //Make deep copy of subject
    subject = $.extend(true, {}, subject);
    delete subject.$notifyId;
    for (let id in values) {
        const value = values[id];
        if (value == null) {
            continue;
        }
        const path = id.split(".");
        let obj = subject;
        while (path.length > 1) {
            const key = path.shift();
            if ($.type(obj[key]) != 'object') {
                obj[key] = {};
            }
            obj = obj[key];
        }
        obj[path[0]] = value;
    }
    return subject;
}

function doConnectObjectCard(id, globalState) {
    const card = globalState[OBJECTCARD];
    const data = card.data[id];
    const saveState = card.saveState[id] || null;
    const errorInfo = card.errorInfo[id] || null;
    const layout = data && data.$class ? card.layoutCache[data.$class] : null;
    //Serverl lock (means editable)
    const serverLock = data && data.$lock;
    const serverLockReady = serverLock && serverLock.status;
    //Automation lock (means not editable)
    const editLocks = card.lock[id];
    const lock = (layout && editLocks && editLocks[layout.rootId]) ? true : false;
    //Subject is new (means editable)
    const isNew = data && data.$isNew;
    const editable = ((serverLockReady || isNew) && !lock) ? true : false;
    const empty = data ? false : true;
    const validation = card.validation[id];
    const valid = (layout && validation && !validation[layout.rootId]) ? true : false;
    //Server (ajax) error
    const error = data && data.$error;
    const errorOperation = data && data.$errorOperation;
    return {
        //Main card properties
        editable, lock, data, layout, saveState, errorInfo, valid, empty, isNew, error, errorOperation,
        //Global properties
        locale: globalState[I18N].locale,
        contextPath: globalState[LOCATION].contextPath,
        superUser: globalState[SECURITY].superUser,
        generalAccessRules: globalState[SECURITY].generalAccessRules,
        //Used for object card only: globally selected object
        object: globalState[LOCATION].params.object,
        namespace: globalState[LOCATION].params.namespace
    };
}

function doConnectPrintCard(id, globalState) {
    const card = globalState[OBJECTCARD];
    const props = doConnectObjectCard(id, globalState);
    props.layout = card.layoutCache;
    props.layoutStatus = card.layoutStatus;
    props.visibility = card.visibility;
    props.values = card.values;
    props.data = card.data;
    //Set qrcode print flag
    props.qrcode = globalState[LOCATION].params.qrcode ? globalState[LOCATION].params.qrcode != "false" : true;
    return props;
}

export function connectObjectCard(Component) {
    return connect((_, initialProps) => { //mapStateToProps
        const { id } = initialProps;
        return (globalState) => {
            return doConnectObjectCard(id, globalState);
        }
    }, (_, initialProps) => {
        const { id } = initialProps;
        return (dispatch) => {
            return {
                addNewSubject: function (data, notifyId) {
                    dispatch(addNewSubject(id, data, notifyId));
                },
                createNewSubject: function (className, parent, parentRef, prototype, notifyId, initialData) {
                    dispatch(createNewSubject(id, className, parent, parentRef, prototype, notifyId, initialData));
                },
                fetchSubject: function (rdfId, namespace, force) {
                    dispatch(fetchSubject(id, rdfId, namespace, force));
                },
                saveSubject: function (deferred) {
                    dispatch(saveSubject(id, deferred));
                },
                uploadFiles: function (handleUploadProgress) {
                    dispatch(uploadFiles(id, handleUploadProgress));
                },
                lockSubject: function () {
                    dispatch(lockSubject(id))
                },
                unlockSubject: function () {
                    dispatch(unlockSubject(id));
                },
                initializeStore: function (initialData, layoutClass) {
                    dispatch(initializeStore(id, initialData, layoutClass));
                },
                destroyStore: function () {
                    dispatch(destroyStore(id));
                },
                patchUpload: function (nodeId, path, sha1) {
                    dispatch(patchUpload(id, nodeId, path, sha1));
                },
                startSave: function () {
                    dispatch(startSave(id))
                },
                cancelSave: function () {
                    dispatch(cancelSave(id));
                },
                changeSaveState: function (saveState) {
                    dispatch(changeSaveState(id, saveState));
                },
                openModal: function (type, options, okCallback, cancelCallback, closeCallback) {
                    const modalId = shortid.generate();
                    dispatch(openModal(modalId, type, options, okCallback, cancelCallback, closeCallback));
                },
                addAlert: function (msg) {
                    dispatch(addAlert(ALERT_DANGER, msg));
                },
                addWarning: function (msg) {
                    dispatch(addAlert(ALERT_WARNING, msg));
                },
                click: function (buttonId) {
                    dispatch(click(id, buttonId));
                },
                reportClick: function (buttonId, href, params) {
                    dispatch(reportClick(id, buttonId, href, params));
                },
                exportToWord: function (rdfId, namespace) {
                    dispatch(exportCard(rdfId, namespace, WORD_EXT));
                },
                exportToExcel: function (rdfId, namespace) {
                    dispatch(exportCard(rdfId, namespace, EXCEL_EXT));
                }
            }
        }
    })(Component);
}

export function connectPrintCard(Component) {
    return connect((_, initialProps) => { //mapStateToProps
        const { id } = initialProps;
        return (globalState) => {
            return doConnectPrintCard(id, globalState);
        }
    }, (_, initialProps) => {
        return (dispatch) => {
            return {
                fetchSubject: function (id, rdfId, namespace, force) {
                    dispatch(fetchSubject(id, rdfId, namespace, force));
                }
            }
        }
    })(Component);
}

export function connectCardListener(Component) {
    return connect((_, initialProps) => { //mapStateToProps
        const { id } = initialProps;
        return (globalState) => {
            const card = globalState[OBJECTCARD];
            return {
                values: card.values[id]
            };
        }
    }, (_, initialProps) => {
        return (dispatch) => {
            return {
                listenerPredicateChanged: function (predicateId, value) {
                    dispatch(listenerPredicateChanged(predicateId, value))
                }
            }
        }
    })(Component);
}

export function buildInnerCardId(formId, nodeId, namespace, rdfId) {
    return formId + "_" + nodeId.replace(/\./g, "_") + "_" + (namespace ? namespace + "_" : "") + rdfId;
}

/* Returns error if value is incorrect and null otherwise */
export function getTypeRestrictionsError(layoutNode, value) {
    switch (layoutNode.ui) {
        case UI_TEXT:
        case UI_TEXT_AREA:
            /* Database now can handle long text values (over 1023 symbols) */
            break;
        case UI_INT:
            if (typeof value != "number") {
                value = parseFloat(value);
            }
            if (value > 2147483647 || value < -2147483648) {
                return { id: "OBJECTCARD_INT_VALUE_TOO_BIG" };
            }
            break;
        default:
            break;
    }
    return null;
}