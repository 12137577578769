import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Tree from '../tree/tree.jsx';
import CimFiller from '../filler/cimfiller.jsx';
import { MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../messages.jsx';
import { CLASSCARD_FIND_CLASS } from '../../constants/classcard';
import { sortByAttr } from '../../services/classcard';

class ClasscardTree extends React.Component {

    constructor(props) {
        super(props);
        this.state = { copyText: "", nodeToDelete: null, onDeleteAction: null };
        this.selectClasses = [];

        this.findClass = this.findClass.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.roots != this.props.roots
            || nextProps.allClasses != this.props.allClasses) {
            return true;
        }
        return false;
    }

    findClass(newValue) {
        this.props.findClass(this.selectClassesMap[newValue.value]);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.allClasses != this.props.allClasses) {
            this.selectClasses = [];
            this.selectClassesMap = {};
            for (let entry of nextProps.allClasses.list) {
                this.selectClasses.push({ value: entry.id, label: entry.namespace ? entry.namespace + ":" + entry.name : entry.name });
                if (entry.name != entry.label) {
                    this.selectClasses.push({ value: entry.id, label: entry.label });
                }
                this.selectClassesMap[entry.id] = entry;
            }
            this.selectClasses = sortByAttr(this.selectClasses, "label");
        }
    }

    render() {
        return (
            <div className="card" style={{ height: "100%", margin: "0px", overflow: "hidden" }}>
                <div className="card-header bg-info d-flex">
                    <div className="col-lg-6">
                        <h4 className="card-title">
                            <strong>
                                <FormattedMessage
                                    id="CLASSCARD_CLASSES_PACKAGES"
                                    defaultMessage="Classes packages"
                                    description="Classes packages" />
                            </strong>
                        </h4>
                    </div>
                    <div className="col-lg-6">
                        {this.props.loading ?
                            null
                            :
                            <div className="input-group flex-row">
                                <div className="col nopadding">
                                    <Select
                                        name="class2"
                                        isLoading={this.props.allClasses.loadingState == "loading"}
                                        loadingPlaceholder={MSG_SELECT_LOADING}
                                        placeholder={CLASSCARD_FIND_CLASS}
                                        noResultsText={MSG_SELECT_NO_RESULTS}
                                        value={null}
                                        backspaceRemoves={false}
                                        clearable={true}
                                        searchable={true}
                                        options={this.selectClasses}
                                        onChange={this.findClass}
                                    />
                                </div>
                                <span className="input-group-btn">
                                    <button type="button" onClick={this.props.openExportPackageModal} data-toggle="tooltip" data-placement="top" className="btn btn-secondary btn-sm" data-original-title="Экспорт" style={{ height: "100%" }}>
                                        <span className="fa fa-fw fa-download" aria-hidden="true"></span>
                                    </button>
                                </span>
                                <span className="input-group-btn">
                                    <button type="button" onClick={this.props.editPackage.bind(this, null)} data-toggle="tooltip" data-placement="top" className="btn btn-secondary btn-sm" data-original-title="Новый пакет" style={{ height: "100%" }}>
                                        <span className="fa fa-fw fa-plus" aria-hidden="true"></span>
                                    </button>
                                </span>
                            </div>
                        }
                    </div>
                    <div className="clearfix"></div>
                </div>
                <CimFiller updateDelay={5}>
                    <Tree
                        initialRdfId={this.props.initialObject && this.props.initialObject.rdfId}
                        roots={this.props.roots}
                        connectNodeFunction={this.props.connectNodeFunction}
                        expandNode={this.props.expandNode}
                        fetchSubTree={this.props.fetchSubTree} />
                </CimFiller>
            </div>);
    }
}

export default ClasscardTree;