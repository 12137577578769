import React from "react";
import videojs from 'video.js';
/* Import element to run component initializer */
import VjsEpisodeList from './vjsEpisodeList.jsx';

const defaultOptions = {
    autoplay: false,
    controls: true,
    sources: [{
        src: 'video.mp4',
        type: 'video/mp4'
    }]
}

export default class Player extends React.PureComponent {

    constructor(props) {
        super(props);

        this.initialized = false;
    }

    initializePlayer(src) {
        /* Set initialized flag to true */
        this.initialized = true;
        this.src = src;
        this.time = null;
        /* Compose default options and props src */
        const options = Object.assign({}, defaultOptions, { sources: this.getSources(src), autoplay: this.props.autoplay });
        const extendedProps = Object.assign({}, this.props, options);
        // instantiate Video.js
        this.player = videojs(this.videoNode, extendedProps, function onPlayerReady() {
            console.log('onPlayerReady', this)
        });

        /**
         * Fetch the controlBar component and add the new vjsEpisodeList component as a child
         * You can pass options here if desired in the second object.
         */
        this.player.getChild('controlBar').addChild('vjsEpisodeList', {});
    }

    destroyPlayer() {
        this.src = null;
        if (this.player) {
            this.player.dispose()
        }
    }

    updateSource(src) {
        if (!this.player) {
            return;
        }
        this.src = src;
        this.player.src(src);
    }

    setTime(time) {
        if (!this.player) {
            return;
        }
        this.time = time;
        this.player.currentTime(time);
    }

    getType(src) {
        if (this.props.type) {
            return this.props.type;
        }
        const ext = src.substring(src.indexOf(".") + 1);
        if (ext == "webm") {
            return "video/webm";
        }
        if (ext == "ogg" || ext == "ogv" || ext == "oga" || ext == "ogx" || ext == "spx" || ext == "opus" || ext == "ogm") {
            return "video/ogg";
        }
        return "video/mp4";
    }

    getPropsSrc() {
        let src = null;
        if (this.props.initialSrc) {
            src = this.props.initialSrc;
        } else if (this.props.hashParams && this.props.hashSrcParam) {
            src = this.props.hashParams[this.props.hashSrcParam] || null;
        }
        return src;
    }

    getPropsTime() {
        if (!this.props.initialTime) {
            return null;
        }
        const timeParts = this.props.initialTime.split(":").reverse();
        let seconds = parseInt(timeParts[0]);
        if (timeParts[1]) {
            seconds += parseInt(timeParts[1]) * 60;
        }
        if (timeParts[2]) {
            seconds += parseInt(timeParts[2]) * 60 * 60;
        }
        if (isNaN(seconds)) {
            return null;
        }
        return seconds;
    }

    getSources(src) {
        return [{
            src: src,
            type: this.getType(src)
        }]
    }

    componentDidMount() {
        const src = this.getPropsSrc();
        if (src) {
            this.initializePlayer(src);
            const time = this.getPropsTime();
            if (time) {
                this.setTime(time);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const src = this.getPropsSrc();
        const time = this.getPropsTime();
        if (this.initialized) {
            if (this.src != src) {
                this.updateSource(src);
            }
            if (this.time != time) {
                this.setTime(time);
            }
            return;
        }
        if (src) {
            this.initializePlayer(src);
            if (time) {
                this.setTime(time);
            }
        }
    }

    // destroy player on unmount
    componentWillUnmount() {
        this.destroyPlayer();
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        const src = this.getPropsSrc();
        if (!src) {
            return <VideoPlaceholder />;
        }
        return (
            <div className="w-100 h-100">
                <div data-vjs-player>
                    <video ref={node => this.videoNode = node} className="video-js" style={{ width: this.props.width, height: this.props.height }}></video>
                </div>
            </div>
        )
    }
}

class VideoPlaceholder extends React.PureComponent {
    render() {
        return null;
    }
}