import React from 'react';
import { FormattedMessage } from 'react-intl';
import CimTableBase from '../../table/table.jsx';
import { connectTable } from '../../../services/table';

const TableContainer = connectTable(CimTableBase);

class Table extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let optional = {}
        if (this.props.node.options && this.props.node.options.parameters) {
            optional.forcedParameters = this.props.node.options.parameters;
        }
        return (<TableContainer tableId={this.props.node.options.table} {...optional}/>);
    }
}

export default Table;