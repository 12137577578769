import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Modal from '../modal.jsx';

import { MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../../messages.jsx';


class SelectEnumModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.options.enumeration[0]
        };

        this.enumerationOptions = [];
        for (let value of props.options.enumeration) {
            this.enumerationOptions.push({ value: value, label: value });
        }

        this.changeValue = this.changeValue.bind(this);
        this.getResult = this.getResult.bind(this);
    }

    changeValue(option) {
        this.setState({ value: option.value });
    }

    getBody() {
        return (<div className="container-fluid">
            <div className="row">
                {this.props.options.messages && this.props.options.messages.map((message) => {
                    if (typeof message == "string") {
                        return <div dangerouslySetInnerHTML={{ __html: message }}></div>;
                    }
                    return <FormattedMessage
                        id={message.id}
                        values={message.values}
                    />
                })}
                <Select
                    name="enumValue"
                    loadingPlaceholder={MSG_SELECT_LOADING}
                    placeholder={MSG_SELECT_PLACEHOLDER}
                    noResultsText={MSG_SELECT_NO_RESULTS}
                    value={this.state.value}
                    backspaceRemoves={false}
                    clearable={false}
                    options={this.enumerationOptions}
                    onChange={this.changeValue}
                />
            </div>
        </div>);
    }

    getResult() {
        return { value: this.state.value };
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for Common*/
            title={this.props.options.title}
            body={this.getBody()}
            footer={this.props.options.footer}
            /**Specific callbacks for Common*/
            onOk={this.props.options.onOk}
            onClose={this.props.options.onClose}
            onCancel={this.props.options.onCancel}
            result={this.getResult} />);
    }
}

export default SelectEnumModal;


