//Global tree state path
export const TREESEARCH = "treesearch";

//Actions
export const WAIT_FOR_TREE_SEARCH = "@@TREESEARCH_WAIT_FOR_TREE_SEARCH";
export const RECEIVE_SEARCH_RESULT = "@@TREESEARCH_RECEIVE_SEARCH_RESULT";
export const RECEIVE_SEARCH_RESULT_ERROR = "@@TREESEARCH_RECEIVE_SEARCH_RESULT_ERROR";

export const RECEIVE_FINDER_OPTIONS = "@@TREESEARCH_RECEIVE_FINDER_OPTIONS";
export const RECEIVE_FINDER_OPTIONS_ERROR = "@@TREESEARCH_RECEIVE_FINDER_OPTIONS_ERROR";
export const CLASS_LEVELS_RECEIVED = "@@TREESEARCH_CLASS_LEVELS_RECEIVED";
export const WAIT_FOR_CLASSES = "@@TREESEARCH_WAIT_FOR_CLASSES";
export const CLASSES_RECEIVED = "@@TREESEARCH_CLASSES_RECEIVED";
export const FRAGMENT_LEVELS_RECEIVED = "@@TREESEARCH_FRAGMENT_LEVELS_RECEIVED";
export const WAIT_FOR_FRAGMENTS = "@@TREESEARCH_WAIT_FOR_FRAGMENTS";
export const FRAGMENTS_RECEIVED = "@@TREESEARCH_FRAGMENTS_RECEIVED";
export const SELECT_FRAGMENT = "@@TREESEARCH_SELECT_FRAGMENT";
export const SELECT_CLASS = "@@TREESEARCH_SELECT_CLASS";
export const TOGGLE_FINDER = "@@TREESEARCH_TOGGLE_FINDER";