import * as Action from '../constants/objectcard';

import { applyChanges, obtainData, checkMandatory } from '../services/automation';



//Initial reducer state
const initialState = {
    data: {
        //Data for each store
    },
    values: {
        //Values for each store
    },
    validation: {
        //Validation for each store
    },
    visibility: {
        //visibility for each store
    },
    visibilityDemand: {
        //visibility demand from automation
    },
    lock: {
        //lock for each store
    },
    lockDemand: {
        //lock demand from automation
    },
    saveState: {
        //Save state for each store
    },
    layoutStatus: {}, //Layout status: loading, ready, error
    layoutCache: {}, //Cache of class layouts,
    enumerationCls: {}, //Enumeration info
    tabs: {}, //Tabs info
    errorInfo: {},
    fragmentTree: { //Fragment tree
        loading: false,
        error: false,
        roots: [],
        childrenById: {},
        nodeById: {},
        parentIdByNodeId: {}
    }
};

/**
 * Check objectcard state and get errors info to properly show alerts
 */
function getErrorInfo(validation, visibility, layout) {
    const errorInfo = {};
    for (let predicateId in validation) {
        const error = validation[predicateId];
        if (error && typeof error == "object" && error.id == "OBJECTCARD_FIELD_IS_MANDATORY"
            && layout.byId[predicateId] && isHidden(predicateId, visibility, layout)) {
            if (!errorInfo.emptyHiddenMandatory) {
                errorInfo.emptyHiddenMandatory = [];
            }
            errorInfo.emptyHiddenMandatory.push(layout.byId[predicateId].label);
        }
    }
    return errorInfo;
}

/**
 * Check by class layout and objectcard visibility if predicate
 * with specified id is hidden
 */
function isHidden(predicateId, visibility, layout) {
    while (predicateId && predicateId != layout.rootId) {
        if (!visibility[predicateId]) {
            return true;
        }
        predicateId = layout.parentIdByChildId[predicateId];
    }
    return false;
}

/**
 * Leaf nodes are valid if they were not set invalid
 * Branch nodes are valid if they do not contain invalid children
 */
function rebuildValidationTree(validation, layout, nodeId) {
    const childrenIds = layout.childrenIdsByParentId[nodeId];
    if ($.type(childrenIds) != "array") {
        return validation[nodeId] ? false : true;
    }
    let valid = true;
    for (let childId of childrenIds) {
        //Recursively revalidate
        if (!rebuildValidationTree(validation, layout, childId)) {
            valid = false;
        }
    }
    validation[nodeId] = !valid;
    return valid;
}

/**
 * Leaf nodes are visible if they were not hidden 
 * Branch nodes ara visible if they were not hidden and have one visible child
 */
function rebuildVisibilityTree(visibility, layout, nodeId) {
    if (!visibility[nodeId]) { //Node was hidden!
        return false;
    }
    const childrenIds = layout.childrenIdsByParentId[nodeId];
    if ($.type(childrenIds) != "array") {
        return true; //Leaf nodes are visible if not hidden!
    }
    //Branches are visible if one of the child nodes is visible
    visibility[nodeId] = false;
    for (let childId of childrenIds) {
        //Recursively rebuild visibility tree
        if (rebuildVisibilityTree(visibility, layout, childId)) {
            visibility[nodeId] = true;
        }
    }
    return visibility[nodeId];
}

function makeVisible(layout, visibilityDemand) {
    const visibility = {};
    for (let nodeId in layout.byId) {
        const demand = visibilityDemand[nodeId];
        if (typeof demand == 'boolean') {
            visibility[nodeId] = demand;
        } else if (layout.byId[nodeId].hidden) {
            visibility[nodeId] = false;
        } else {
            visibility[nodeId] = true;
        }
    }
    return visibility;
}

/**
 * Locks are propogated from upped level to lower layers
 */
function propogateLock(lock, layout, nodeId) {
    const childrenIds = layout.childrenIdsByParentId[nodeId];
    if ($.type(childrenIds) != "array") { //no children
        return;
    }
    for (let childId of childrenIds) {
        lock[childId] = true;
        propogateLock(lock, layout, childId);
    }
}

/**
 * Leaf nodes are locked if they were locked or if parent was locked (lock is propogated)
 * Branch nodes are locked if they were locked or if all children are locked
 */
function rebuildLockTree(lock, layout, nodeId) {
    if (lock[nodeId]) {
        propogateLock(lock, layout, nodeId);
        return true;
    }
    const childrenIds = layout.childrenIdsByParentId[nodeId];
    if ($.type(childrenIds) != "array") {
        lock[nodeId] = false;
        return false;
    }
    if (childrenIds.length == 0) {
        lock[nodeId] = false; //Branch is not locked because it doesn't contain any children
    } else {
        lock[nodeId] = true; //Set lock by default for branches
        for (let childId of childrenIds) {
            if (!rebuildLockTree(lock, layout, childId)) {
                lock[nodeId] = false; //one of children is not locked. So branch is not locked
            }
        }
    }
    return lock[nodeId];
}

function layoutReceived(state, { cls, layout, storeDiff }) {
    let layoutStatus = Object.assign({}, state.layoutStatus, { [cls]: Action.STATUS_READY });
    let layoutCache = Object.assign({}, state.layoutCache, { [cls]: layout });
    let values = state.values;
    let validation = state.validation;
    let visibilityDemand = state.visibilityDemand;
    let visibility = state.visibility;
    let lockDemand = state.lockDemand;
    let lock = state.lock;
    if (storeDiff) {
        values = Object.assign({}, state.values);
        validation = Object.assign({}, state.validation);
        visibilityDemand = Object.assign({}, state.visibilityDemand);
        visibility = Object.assign({}, state.visibility);
        lockDemand = Object.assign({}, state.lockDemand);
        lock = Object.assign({}, state.lock);
        for (let diff of storeDiff) {
            const store = diff.store;
            values[store] = values[store] ? Object.assign({}, values[store], diff.valuesDiff || {}) : (diff.valuesDiff || {});
            validation[store] = diff.validationDiff || {};
            rebuildValidationTree(validation[store], layout, layout.rootId);
            visibilityDemand[store] = diff.visibilityDiff || {};
            visibility[store] = makeVisible(layout, visibilityDemand[store]);
            rebuildVisibilityTree(visibility[store], layout, layout.rootId);
            lockDemand[store] = diff.lockDiff || {};
            lock[store] = Object.assign({}, lockDemand[store]);
            rebuildLockTree(lock[store], layout, layout.rootId);
        }
    }
    return Object.assign({}, state, { layoutStatus, layoutCache, values, validation, visibilityDemand, visibility, lock, lockDemand });
}

function layoutError(state, { cls }) {
    let layoutStatus = Object.assign({}, state.layoutStatus, { [cls]: Action.STATUS_ERROR });
    return Object.assign({}, state, { layoutStatus });
}

function layoutWait(state, { cls }) {
    let layoutStatus = Object.assign({}, state.layoutStatus, { [cls]: Action.STATUS_LOADING });
    return Object.assign({}, state, { layoutStatus });
}

function enumerationReceived(state, { cls, data }) {
    const nextState = Object.assign({}, state);
    nextState.enumerationCls = Object.assign({}, nextState.enumerationCls);
    nextState.enumerationCls[cls] = { enumerationInfo: data };
    return nextState;
}

function enumerationError(state, { cls, error }) {
    const nextState = Object.assign({}, state);
    nextState.enumerationCls = Object.assign({}, nextState.enumerationCls);
    nextState.enumerationCls[cls] = {
        error: error
    };
    return nextState;
}

function enumerationWait(state, { cls }) {
    const nextState = Object.assign({}, state);
    nextState.enumerationCls = Object.assign({}, nextState.enumerationCls);
    nextState.enumerationCls[cls] = {
        loading: true
    };
    return nextState;
}

function changeTab(state, { store, tabId, navId }) {
    const nextState = Object.assign({}, state);
    nextState.tabs = Object.assign({}, nextState.tabs);
    nextState.tabs[store] = Object.assign({}, nextState.tabs[store]);
    nextState.tabs[store][navId] = { active: tabId };
    return nextState;
}

function waitForSaveSubject(state, { store }) {
    let saveState = Object.assign({}, state.saveState, { [store]: Action.SAVE_STATE_WAIT_SERVER });
    return Object.assign({}, state, { saveState });
}

function subjectWait(state, { store, rdfId, namespace }) {
    let data = Object.assign({}, state.data);
    data[store] = { $rdfId: rdfId, $namespace: namespace };
    const values = Object.assign({}, state.values);
    const validation = Object.assign({}, state.validation);
    const visibilityDemand = Object.assign({}, state.visibilityDemand);
    const visibility = Object.assign({}, state.visibility);
    const lockDemand = Object.assign({}, state.lockDemand);
    const lock = Object.assign({}, state.lock);
    const saveState = Object.assign({}, state.saveState);
    const tabs = Object.assign({}, state.tabs);
    delete values[store];
    delete validation[store];
    delete visibilityDemand[store];
    delete visibility[store];
    delete lockDemand[store];
    delete lock[store];
    delete saveState[store];
    delete tabs[store];
    return Object.assign({}, state, { data, values, validation, visibilityDemand, visibility, lock, lockDemand, saveState, tabs });
}

function subjectReceived(state, { store, operation, subject, layout, diff, notifyId }) {
    const data = Object.assign({}, state.data);
    if (operation == Action.SUBJECT_OPERATION_CREATE) {
        data[store] = Object.assign({}, subject, { $isNew: true, $notifyId: notifyId });
    } else {
        data[store] = subject;
    }
    let saveState = state.saveState;
    if (saveState[store]) {
        saveState = Object.assign({}, state.saveState);
        delete saveState[store];
    }
    const values = Object.assign({}, state.values);
    const validation = Object.assign({}, state.validation);
    const visibilityDemand = Object.assign({}, state.visibilityDemand);
    const visibility = Object.assign({}, state.visibility);
    const lockDemand = Object.assign({}, state.lockDemand);
    const lock = Object.assign({}, state.lock);
    let layoutStatus = state.layoutStatus;
    let layoutCache = state.layoutCache;
    if (layout && diff) {
        //Update store
        values[store] = diff.valuesDiff || {};
        validation[store] = diff.validationDiff || {};
        rebuildValidationTree(validation[store], layout, layout.rootId);
        visibilityDemand[store] = diff.visibilityDiff || {};
        visibility[store] = makeVisible(layout, visibilityDemand[store]);
        rebuildVisibilityTree(visibility[store], layout, layout.rootId);
        lockDemand[store] = diff.lockDiff || {};
        lock[store] = Object.assign({}, lockDemand[store]);
        rebuildLockTree(lock[store], layout, layout.rootId);
        //If initialize store then cache layout
        if (operation == Action.SUBJECT_OPERATION_INIT) {
            const cls = subject.$class;
            if (!layoutCache[cls]) {
                layoutStatus = Object.assign({}, layoutStatus, { [cls]: Action.STATUS_READY });
                layoutCache = Object.assign({}, layoutCache, { [cls]: layout });
            }
        }
    } else {
        delete values[store];
        delete validation[store];
        delete visibilityDemand[store];
        delete visibility[store];
        delete lockDemand[store];
        delete lock[store];
    }
    return Object.assign({}, state, { data, saveState, values, validation, visibilityDemand, visibility, lock, lockDemand, layoutStatus, layoutCache });
}

function destroyStore(state, { store }) {
    const data = Object.assign({}, state.data);
    const values = Object.assign({}, state.values);
    const validation = Object.assign({}, state.validation);
    const visibilityDemand = Object.assign({}, state.visibilityDemand);
    const visibility = Object.assign({}, state.visibility);
    const lockDemand = Object.assign({}, state.lockDemand);
    const lock = Object.assign({}, state.lock);
    const saveState = Object.assign({}, state.saveState);
    delete data[store];
    delete values[store];
    delete validation[store];
    delete visibilityDemand[store];
    delete visibility[store];
    delete lockDemand[store];
    delete lock[store];
    delete saveState[store];
    return Object.assign({}, state, { data, values, validation, visibilityDemand, visibility, lock, lockDemand, saveState });
}

function subjectErrorReceived(state, { store, operation, error, notifyId }) {
    const data = Object.assign({}, state.data);
    data[store] = Object.assign({}, data[store], { $error: error, $errorOperation: operation });
    let saveState = state.saveState;
    if (saveState[store]) {
        saveState = Object.assign({}, state.saveState);
        delete saveState[store];
    }
    return Object.assign({}, state, { data, saveState });
}

function subjectChangeData(state, { store, valuesDiff, validationDiff, visibilityDiff, lockDiff }) {
    //Make copy of data
    const values = Object.assign({}, state.values);
    //Apply all changes
    values[store] = Object.assign({}, values[store], valuesDiff);
    //Check validation
    let validation = state.validation;
    //If validation present
    if (validationDiff) { //We need to rebuild validation tree
        const layout = state.layoutCache[state.data[store].$class];
        validation = Object.assign({}, validation);
        validation[store] = Object.assign({}, validation[store] || {}, validationDiff);
        rebuildValidationTree(validation[store], layout, layout.rootId);
    }
    //If visibility present
    let visibility = state.visibility;
    let visibilityDemand = state.visibilityDemand;
    if (visibilityDiff) {
        const layout = state.layoutCache[state.data[store].$class];
        //Demand
        visibilityDemand = Object.assign({}, visibilityDemand || {});
        visibilityDemand[store] = Object.assign({}, visibilityDemand[store], visibilityDiff);
        //Visibility
        visibility = Object.assign({}, visibility);
        visibility[store] = makeVisible(layout, visibilityDemand[store]);
        rebuildVisibilityTree(visibility[store], layout, layout.rootId);
    }
    //If lock present
    let lock = state.lock;
    let lockDemand = state.lockDemand;
    if (lockDiff) {
        const layout = state.layoutCache[state.data[store].$class];
        //Demand
        lockDemand = Object.assign({}, lockDemand || {});
        lockDemand[store] = Object.assign({}, lockDemand[store], lockDiff);
        //Visibility
        lock = Object.assign({}, lock);
        lock[store] = Object.assign({}, lockDemand[store]);
        rebuildLockTree(lock[store], layout, layout.rootId);
    }
    return Object.assign({}, state, { values, validation, visibility, visibilityDemand, lock, lockDemand });
}

function waitFragmentTree(state, { fragmentRdfId }) {
    const nextState = Object.assign({}, state);
    const treeState = Object.assign({}, nextState.fragmentTree);
    if (fragmentRdfId === null) {
        treeState.loading = true;
    } else {
        treeState.nodeById = Object.assign({}, treeState.nodeById);
        treeState.nodeById[fragmentRdfId] = Object.assign({}, treeState.nodeById[fragmentRdfId], { loading: true });
    }
    nextState.fragmentTree = treeState;
    return nextState;
}

function receiveNodes(treeState, data) {
    const idList = [];
    if (!Array.isArray(data)) {
        return idList;
    }
    treeState.nodeById = Object.assign({}, treeState.nodeById);
    for (let node of data) {
        if (!node.id) {
            continue;
        }
        treeState.nodeById[node.id] = node;
        idList.push(node.id);
    }
    return idList;
}

function fragmentTreeDataRecieved(state, { fragmentRdfId, data }) {
    const nextState = Object.assign({}, state);
    const treeState = Object.assign({}, nextState.fragmentTree);
    const nodesIdList = receiveNodes(treeState, data);
    if (fragmentRdfId === null) {
        treeState.loading = false;
        treeState.roots = nodesIdList;
    } else {
        treeState.nodeById = Object.assign({}, treeState.nodeById);
        treeState.nodeById[fragmentRdfId] = Object.assign({}, treeState.nodeById[fragmentRdfId], { loading: false, children: nodesIdList });
        treeState.childrenById = Object.assign({}, treeState.childrenById);
        treeState.childrenById[fragmentRdfId] = nodesIdList;
        treeState.parentIdByNodeId = Object.assign({}, treeState.parentIdByNodeId);
        for (let childId of nodesIdList) {
            if (treeState.nodeById[childId].isLeaf) {
                treeState.childrenById[childId] = null;
            }
            treeState.parentIdByNodeId[childId] = fragmentRdfId;
        }
    }
    nextState.fragmentTree = treeState;
    return nextState;
}

function fragmentTreeErrorRecieved(state, { fragmentRdfId, error }) {
    const nextState = Object.assign({}, state);
    const treeState = Object.assign({}, nextState.fragmentTree);
    if (fragmentRdfId === null) {
        treeState.loading = false;
        treeState.error = error;
    } else {
        treeState.nodeById = Object.assign({}, treeState.nodeById);
        treeState.nodeById[fragmentRdfId] = Object.assign({}, treeState.nodeById[fragmentRdfId], { loading: false, error: error });
    }
    nextState.fragmentTree = treeState;
    return nextState;
}

function startSave(state, { store }) {
    const current = state.saveState[store];
    if (current && current != Action.SAVE_STATE_SHOW_ERRORS) { //Do not change if state is already in progress
        return state;
    }
    const layout = state.layoutCache[state.data[store].$class];
    const validation = state.validation[store];
    const valid = validation[layout.rootId] ? false : true; //check error for rootId
    const saveState = Object.assign({}, state.saveState, { [store]: valid ? Action.SAVE_STATE_START : Action.SAVE_STATE_SHOW_ERRORS });
    const errorInfo = Object.assign({}, state.saveState, { [store]: valid ? null : getErrorInfo(state.validation[store], state.visibility[store], layout) });
    return Object.assign({}, state, { saveState, errorInfo });
}

function cancelSave(state, { store }) {
    if (!state.saveState[store] || state.saveState[store] == Action.SAVE_STATE_WAIT_SERVER) { //Do not cancel if state is not in progress or we are already waiting for server
        return state;
    }
    let saveState = Object.assign({}, state.saveState);
    delete saveState[store];
    return Object.assign({}, state, { saveState });
}

function changeSaveState(state, { store, saveState }) {
    if (!state.saveState[store]) { //Do not change if state is not in progress
        return state;
    }
    let _saveState = Object.assign({}, state.saveState, { [store]: saveState });
    return Object.assign({}, state, { saveState: _saveState });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.LAYOUT_RECEIVED: return layoutReceived(state, action.payload);
        case Action.LAYOUT_WAIT: return layoutWait(state, action.payload);
        case Action.LAYOUT_ERROR_RECEIVED: return layoutError(state, action.payload);

        case Action.ENUMERATION_RECEIVED: return enumerationReceived(state, action.payload);
        case Action.ENUMERATION_WAIT: return enumerationWait(state, action.payload);
        case Action.ENUMERATION_ERROR_RECEIVED: return enumerationError(state, action.payload);

        case Action.CHANGE_TAB: return changeTab(state, action.payload);

        //Subject updates
        case Action.SUBJECT_WAIT: return subjectWait(state, action.payload);
        case Action.SUBJECT_RECEIVED: return subjectReceived(state, action.payload);
        case Action.SUBJECT_ERROR_RECEIVED: return subjectErrorReceived(state, action.payload);
        case Action.SUBJECT_DESTROY_STORE: return destroyStore(state, action.payload);

        //Save process
        case Action.START_SAVE: return startSave(state, action.payload);
        case Action.CANCEL_SAVE: return cancelSave(state, action.payload);
        case Action.CHANGE_SAVE_STATE: return changeSaveState(state, action.payload);
        case Action.SUBJECT_SAVE_WAIT: return waitForSaveSubject(state, action.payload);

        case Action.SUBJECT_CHANGE_DATA: return subjectChangeData(state, action.payload);

        case Action.SUBJECT_FRAGMENT_TREE_WAIT: return waitFragmentTree(state, action.payload);
        case Action.SUBJECT_FRAGMENT_TREE_DATA_RECEIVED: return fragmentTreeDataRecieved(state, action.payload);
        case Action.SUBJECT_FRAGMENT_TREE_ERROR_RECEIVED: return fragmentTreeErrorRecieved(state, action.payload);
        default: return state;
    }
}

