import React from 'react';
import Plugin from '../../plugin/plugin.jsx';

class PluginView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const options = Object.assign({}, this.props.node.options);
        const plugin = options.plugin;
        delete options.plugin;
        let view = 'index';
        if (typeof options.view) {
            view = options.view;
            delete options.view;
        }
        return (<Plugin plugin={plugin} view={view} options={options}/>);
    }
}

export default PluginView;