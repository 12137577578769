import React from 'react';
import PropTypes from 'prop-types';

class HashFilter extends React.Component {
    
    constructor(props) {
        super(props);
        this.isFiltered = this.isFiltered.bind(this);
    }

    componentDidMount() {
        this.hash = this.props.hashParams[this.props.param];
        console.log("HashFilter mount");
        this.changeFilter();
    }

    componentWillReceiveProps(nextProps) {
        let next = nextProps.hashParams[nextProps.param];
        if (this.hash != next) {
            this.hash = next;
            this.changeFilter();
        }
    }

    changeFilter() {
        if (this.hash) {
            console.log("Apply filter: " + this.hash);
            this.props.filterHandler({ callback: this.isFiltered, type: this.props.type, hash: this.hash });
        } else {
            console.log("Clean filter");
            this.props.filterHandler();
        }
    }

    isFiltered(targetValue) {
        if (!this.hash) {
            return true;
        }
        if (targetValue == null) {
            return false;
        }
        if ($.type(targetValue) == "object") { 
            return targetValue.$rdfId == this.hash;
        } else if ($.type(targetValue) == "array") {
            for (let obj of targetValue) {
                if (obj.$rdfId == this.hash) {
                    return true;
                }
            }
            return false;
        }
        return targetValue == this.hash;
    }

    render() {
        return (<div></div>);
    }
}

HashFilter.propTypes = {
    filterHandler: PropTypes.func.isRequired,
    hashParams: PropTypes.object.isRequired,
    param: PropTypes.string.isRequired
    
};

export default HashFilter;