function getInt(value) {
    let number = parseInt(value);
    return isNaN(number) ? 0 : number;
}

function getElementIdx(elm, parent) {
    let children = parent.children;
    if (!children) {
        return 0;
    }
    for (let i = 0; i < children.length; ++i) {
        if (children[i] == elm) {
            return i;
        }
    }
    return children.length;
}

function getBoundingClientRect(element) {
    let position = $(element).css("position");
    if (position == "fixed" || position == "absolute") {
        return null;
    }
    return element.getBoundingClientRect();
}

export function getSizes({ filler, parent, horizontal, style, forcedWidth, forcedHeight }) {
    let sizes;
    let elementRect = filler.getBoundingClientRect();
    let parentRect = parent.getBoundingClientRect();
    let width = forcedWidth ? forcedWidth : $(parent).width();
    let height = forcedHeight ? forcedHeight : $(parent).height();
    let elementIdx = getElementIdx(filler, parent);
    if (horizontal) {
        let size = width - elementRect.left + parentRect.left + getInt($(parent).css('padding-left'));
        if (elementIdx < parent.children.length) {
            for (let i = elementIdx; i < parent.children.length; ++i) {
                let rect = getBoundingClientRect(parent.children[i]);
                if (rect) {
                    size -= rect.width;
                }
            }
        }
        if (size < 0) {
            size = 0;
        }
        sizes = { height: height, width: size };
    } else {
        let size = height - elementRect.top + parentRect.top + getInt($(parent).css('padding-top'));
        if (elementIdx < parent.children.length) {
            for (let i = elementIdx + 1; i < parent.children.length; ++i) {
                let rect = getBoundingClientRect(parent.children[i]);
                if (rect) {
                    size -= rect.height;
                }
            }
        }
        if (size < 0) {
            size = 0;
        }
        sizes = { height: size, width: width };
    }
    return sizes;
}

export function getPadding(style) {
    let padding = [0, 0, 0, 0];
    if (style && style.padding) {
        padding = style.padding.split(" ");
        for (let i = 0; i < padding.length; ++i) {
            padding[i] = getInt(padding[i]);
        }
        switch (padding.length) {
            case 1:
                padding[1] = padding[0];
            case 2:
                padding[2] = padding[0];
            case 3:
                padding[3] = padding[1];
                break;
        }
    }
    return padding;
}