import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineEdit from 'react-edit-inplace';

const editIconStyle = {
    color: "#2dcc15"
};

const labelStyle = {
    fontSize: 14,
    fontWeight: "bold"
};

class Label extends React.Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(data) {
        this.props.change(data.label);
    }

    getLabelIcon() {
        if (!this.props.editable) {
            return null;
        }
        if (this.props.isNew) {
            return (<i className="fa fa-plus-square fa-lg fa-fw" aria-hidden="true" style={editIconStyle}></i>);
        } else {
            return (<i className="fa fa-pencil-square fa-lg fa-fw" aria-hidden="true" style={editIconStyle}></i>);
        }
    }

    getLabelText() {
        const label = this.props.value || " ";
        if (this.props.editable) {
            return (<InlineEdit
                text={label}
                paramName="label"
                style={labelStyle}
                change={this.onChange} />);
        }
        return (<span style={labelStyle}>{label}</span>);
    }

    render() {
        return (<span>{this.getLabelIcon()} {this.getLabelText()}</span>);
    }
}

export default Label;


