import React from 'react';
import { FormattedMessage } from 'react-intl';
import Toolbar from './toolbar.jsx';
import Table from './table.jsx';

export default class EventTasks extends React.PureComponent {

    constructor(props) {
        super(props);

        props.fetchData();
    }

    render() {
        if (this.props.loading) {
            return <LoadingPlaceholder />;
        }
        if (this.props.error) {
            return <ErrorPlaceholder error={this.props.error} />;
        }
        return <div className="w-100 h-100">
            <Toolbar
                openEventModal={this.props.openEventModal}
                openDeleteModal={this.props.openDeleteModal}
                selectedRows={this.props.selectedRows}
            />
            <Table
                messages={this.props.messages}
                data={this.props.data}
                selectedRows={this.props.selectedRows}
                sourceByName={this.props.sourceByName}
                selectRow={this.props.selectRow}
                openEventModal={this.props.openEventModal}
            />
        </div>;
    }
}

class LoadingPlaceholder extends React.PureComponent {
    render() {
        return <span>
            <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            <FormattedMessage
                id="EVENT_TASKS_LOADING"
                defaultMessage="Loading..."
                description="This message will while table data is loading" />
        </span>;
    }
}

class ErrorPlaceholder extends React.PureComponent {
    render() {
        const error = this.props.error;
        if (error && error.status && error.status >= 400 && error.status < 500) {
            return <span>
                <i className="fa fa-exclamation-triangle fa-3x fa-fw"></i>
                {error.responseText ? error.responseText : error.statusText}
            </span>;
        }
        return <span>
            <i className="fa fa-exclamation-triangle fa-3x fa-fw"></i>
            <FormattedMessage
                id="EVENT_TASKS_ERROR"
                defaultMessage="Error while loading data!"
                description="This message will displayed if data loading failed" />
        </span>;
    }
}