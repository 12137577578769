import React, { Component } from 'react';
import {
    TYPE,
    numberRelationTypeList,
    dateRelationTypeList,
    stringRelationTypeList,
    fileRelationTypeList,
    fragmentRelationTypeList,
    referenceRelationTypeList,
    enumerationRelationTypeList,
    booleanRelationValueList
} from '../../constants/finder';
import { MSG_OR, MSG_FROM, MSG_TO, MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../messages.jsx';
import DebounceInput from '../debounceinput.jsx';
import ComboBox from '../objectcard/inputs/combobox.jsx';
import ObservedSelect from '../select/observedselect.jsx';
import { readFromClipboard } from '../../services/clipboard';
import { ALERT_DANGER } from '../../constants/alert';
import { isRdfId } from '../../services/finder';

function getPredicateName(predicate) {
    let prefix = null;
    if (predicate.$namespace) {
        switch (typeof predicate.$namespace) {
            case "string":
                prefix = predicate.$namespace;
                break;
            case "object":
                prefix = predicate.$namespace.$prefix;
                break;
        }
    }
    return prefix ? (prefix + ":" + predicate.$rdfId) : predicate.$rdfId;
}

function getFieldName(field) {
    let prefix = null;
    if (field.namespace) {
        switch (typeof field.namespace) {
            case "string":
                prefix = field.namespace;
                break;
            case "object":
                prefix = field.namespace.prefix;
                break;
        }
    }
    return prefix ? (prefix + ":" + field.name) : field.name;
}


export default class Criterion extends Component {
    constructor(props) {
        super(props);

        this.relationsType = {};
        this.createChangeFieldHandler = this.createChangeFieldHandler.bind(this);
        this.createChangeRelationHandler = this.createChangeRelationHandler.bind(this);
        this.createChangeRelationValueHandler = this.createChangeRelationValueHandler.bind(this);
    }

    createChangeFieldHandler(field) {
        let _this = this;
        return function (newValueObject) {
            let fieldId = newValueObject.id;
            if (typeof fieldId == "undefined") {
                /* Happens when user use backspace on empty search */
                return;
            }
            if (fieldId == "") {
                _this.props.changeCriteriaField(_this.props.criteria.id, field.parentId);
                return;
            }
            _this.props.changeCriteriaField(_this.props.criteria.id, fieldId);
        }
    }

    createChangeRelationHandler(criteria, relationIdx) {
        let _this = this;
        return function (newValueObject) {
            let relationType = newValueObject.value;
            let relation = criteria.relations[relationIdx];
            relation.type = relationType;
            switch (relationType) {
                case "between":
                    relation.value = {
                        from: null,
                        to: null
                    }
                    break;
            }
            _this.props.changeCriteriaRelation(criteria.id, relation, relationIdx);
        }
    }

    createChangeRelationValueHandler(criteria, relationIdx, path) {
        let _this = this;
        /* Second argument using by comboboxes */
        return function (arg, predicate) {
            let newValue;
            if (predicate) {
                newValue = getPredicateName(predicate);
            } else if (typeof arg != "object") {
                newValue = arg;
            } else if (typeof arg.value != "undefined") {
                newValue = arg.value;
            } else if (arg.target) {
                newValue = arg.target.value;
            } else {
                return;
            }
            let relation = criteria.relations[relationIdx];
            if (path) {
                relation.value[path] = newValue;
            } else {
                relation.value = newValue;
            }
            if (!relation.type) {
                relation.type = _this.relationsType[relationIdx];
            }
            _this.props.changeCriteriaRelation(criteria.id, relation, relationIdx);
        }
    }

    printRelation(criteria, relation, field, relationIdx, isFileValue) {
        let _this = this;
        let options;
        let value = relation.type;
        let fieldType = field.type;

        let setOptions = function (typeList) {
            options = [];
            typeList.map((type) => {
                options.push({ value: type.value, label: type.label });
            })
            if (!value) {
                value = typeList[0].value;
                _this.relationsType[relationIdx] = value;
            }
        }
        switch (fieldType) {
            case TYPE.STRING:
                if (!field.dataType) {
                    setOptions(stringRelationTypeList);
                    break;
                }
                switch (field.dataType.name) {
                    case "date": //date
                        setOptions(numberRelationTypeList);
                        break;
                    default: //string
                        setOptions(stringRelationTypeList);
                        break;
                }
                break;
            case TYPE.NUMBER:
                setOptions(numberRelationTypeList);
                break;
            case TYPE.DATE:
                setOptions(dateRelationTypeList);
                break;
            case TYPE.BOOLEAN:
                this.relationsType[relationIdx] = "equal";
                return null
            case TYPE.FILE:
                if (!isFileValue) {
                    return null;
                }
                setOptions(fileRelationTypeList);
                break;
            case TYPE.FRAGMENT:
                setOptions(fragmentRelationTypeList);
                break;
            case TYPE.ENUMERATION:
                setOptions(enumerationRelationTypeList);
                break;
            case TYPE.REFERENCE:
            case TYPE.TABLE:
            default:
                setOptions(referenceRelationTypeList);
                break;
        }

        return <ObservedSelect
            name="relation-type"
            className="minified-react-select"
            loadingPlaceholder={MSG_SELECT_LOADING}
            placeholder={MSG_SELECT_PLACEHOLDER}
            noResultsText={MSG_SELECT_NO_RESULTS}
            value={value}
            options={options}
            onChange={this.createChangeRelationHandler(criteria, relationIdx)}
            clearable={false}
            searchable={false}
        />;
    }

    printRelationValue(criteria, relation, field, index) {
        if (relation.type == "between") {
            return <div className="col-md-12 p-0">
                <div className="d-flex flex-row col-md-12 p-0 m-0 text-left between-relation-value">
                    <span>
                        {MSG_FROM}
                    </span>
                    <div className="d-flex flex-fill">
                        <DebounceInput
                            editable={!this.props.criteria.locked}
                            valid={true}
                            value={relation.value.from}
                            format={field.format}
                            className={"form-control"}
                            change={this.createChangeRelationValueHandler(criteria, index, "from")}
                            locale={this.props.locale} >
                        </DebounceInput >
                    </div>
                </div>
                <div className="d-flex flex-row col-md-12 p-0 m-0 text-left between-relation-value">
                    <span>
                        {MSG_TO}
                    </span>
                    <div className="d-flex flex-fill">
                        <DebounceInput
                            editable={!this.props.criteria.locked}
                            valid={true}
                            value={relation.value.to}
                            format={field.format}
                            className={"form-control"}
                            change={this.createChangeRelationValueHandler(criteria, index, "to")}
                            locale={this.props.locale} >
                        </DebounceInput >
                    </div>
                </div>
            </div>
        }
        if (field.type == TYPE.BOOLEAN) {
            return <ObservedSelect
                name="relation-value"
                className="minified-react-select"
                loadingPlaceholder={MSG_SELECT_LOADING}
                placeholder={MSG_SELECT_PLACEHOLDER}
                noResultsText={MSG_SELECT_NO_RESULTS}
                value={relation.value}
                options={booleanRelationValueList}
                onChange={this.createChangeRelationValueHandler(criteria, index)}
                clearable={false}
                searchable={false}
            />;
        }
        if (field.type == TYPE.ENUMERATION) {
            try {
                const enumerationCls = { enumerationInfo: field.classRelationInfo.peerClass.enumerationInfo };
                const node = {
                    id: field.name,
                    options: {
                        depth: field.classRelationInfo.peerClass.enumerationDepth
                    }
                }
                return <ComboBox
                    node={node}
                    enumerationCls={enumerationCls}
                    editable={!this.props.criteria.locked}
                    value={relation.value}
                    link={this.createChangeRelationValueHandler(criteria, index).bind(this, null)}
                    allowPartialSelection={true}
                    hideLabel={true}
                    visible={true}></ComboBox>
            } catch (e) {
                return null;
            }
        }
        if (field.type == TYPE.FILE) {
            if (!relation.type) {
                this.relationsType[index] = fileRelationTypeList[0].value;
                this.props.changeCriteriaRelation(criteria.id, { type: this.relationsType[index], value: 0 }, index);
            }
            return this.printRelation(criteria, relation, field, index, true);
        }
        let value = relation.value;
        let tool = null;
        if (field.type == TYPE.REFERENCE) {
            tool = this.getReferenceTool(field, criteria, index, value);
            if (this.props.finder.loadedObjectcards.byId[value]) {
                value = this.props.finder.loadedObjectcards.byId[value].$label;
            }
        }
        return <DebounceInput
            editable={!this.props.criteria.locked && field.id}
            valid={true}
            value={value}
            editable={field.type != TYPE.REFERENCE}
            format={field.format}
            className={"form-control"}
            change={this.createChangeRelationValueHandler(criteria, index)}
            locale={this.props.locale}
            tool={tool} >
        </DebounceInput >
    }

    getReferenceTool(field, criteria, index, value) {
        const tool = [this.getPasteReferenceBtn(criteria, index)];
        if (field.name) {
            const fieldName = getFieldName(field);
            const treeSelection = this.getTreeSelection(fieldName);
            if (treeSelection != null) {
                tool.push(this.getTreeReferenceBtn(treeSelection, criteria, index));
            } else {
                const tableSelection = this.getTableSelection(fieldName);
                if (tableSelection != null) {
                    tool.push(this.getTableReferenceBtn(tableSelection, criteria, index));
                }
            }
        }
        if (value) {
            tool.push(this.getDeleteReferenceBtn(criteria, index));
        }
        return tool;
    }

    getSelectionPath(fieldName, selectionList) {
        if (!Array.isArray(selectionList)) {
            return null;
        }
        for (let selection of selectionList) {
            if (!Array.isArray(selection.predicates)) {
                continue;
            }
            for (let p of selection.predicates) {
                const re = new RegExp(p.replace(/\./g, "\.").replace(/\*/g, ".*"));
                if (re.test(fieldName)) {
                    return selection.path;
                }
            }
        }
        return null;
    }

    getTreeSelection(fieldName) {
        if (!this.props.finderOptions) {
            return null;
        }
        return this.getSelectionPath(fieldName, this.props.finderOptions.treeSelections);
    }

    getTableSelection(fieldName) {
        if (!this.props.finderOptions) {
            return null;
        }
        return this.getSelectionPath(fieldName, this.props.finderOptions.tableSelections);
    }

    getPasteReferenceBtn(criteria, index) {
        return {
            icon: "clipboard",
            enabled: true,
            onClick: this.pasteReferenceHandler.bind(this, criteria, index)
        }
    }

    getReferenceBtn() {
        return {
            icon: "plus-circle",
            enabled: true,
            iconStyle: { color: "green" }
        }
    }

    getDeleteReferenceBtn(criteria, index) {
        return {
            icon: "times",
            enabled: true,
            onClick: this.setReference.bind(this, criteria, index, {})
        }
    }

    getTreeReferenceBtn(treePath, criteria, index) {
        const button = this.getReferenceBtn();
        button.onClick = this.openTreeReferenceModal.bind(this, treePath, criteria, index);
        return button;
    }

    openTreeReferenceModal(treePath, criteria, index) {
        const options = {
            treeId: treePath,
            size: "large"
        };
        this.props.openModal("finder-reference", "navtree", options, this.setReference.bind(this, criteria, index));
    }

    getTableReferenceBtn(tablePath, criteria, index) {
        const button = this.getReferenceBtn();
        button.onClick = this.openTableReferenceModal.bind(this, tablePath, criteria, index);
        return button;
    }

    openTableReferenceModal(tablePath, criteria, index) {
        const options = {
            tableId: tablePath,
            forcedSelectType: "radio",
            size: "large",
            type: "table"
        };
        this.props.openModal("finder-reference", "cimtable", options, this.setReference.bind(this, criteria, index));
    }

    pasteReferenceHandler(criteria, index) {
        readFromClipboard().then((text) => {
            this.convertAndPasteRefs(criteria, index, text);
        }, (reason) => {
            const options = {
                title: <FormattedMessage id="OBJECTCARD_MANUAL_PASTE" />,
                body: <div>
                    <div>
                        <FormattedMessage id="OBJECTCARD_AUTO_PASTE_PROHIBITED" />
                    </div>
                    <textarea style={{ resize: "none", width: "100%" }} onPaste={this.handleManualPaste}></textarea>
                </div>
            };
            this.props.openNamedModal("manualPaste", "common", options);
        });
    }

    convertAndPasteRefs(criteria, index, text) {
        try {
            const refs = JSON.parse(text);
            text = refs;
        } catch (ex) {
            /* Pasted text may be rdfId string, so JSON.parse will fail, but pasted data is valid */
        }
        var clipboardData = text;
        if (Array.isArray(clipboardData)) {
            clipboardData = clipboardData[0];
        }
        let value = null;
        if (typeof clipboardData == "string") {
            value = clipboardData;
        } else if (typeof clipboardData == "object" && clipboardData.$rdfId) {
            value = clipboardData.$rdfId;
        }
        if (isRdfId(value)) {
            this.setReference(criteria, index, value);
            return;
        }
        this.props.addAlert(ALERT_DANGER, { id: "FINDER_REFERENCE_PASTE_WRONG_DATA" });
    }

    handleManualPaste(reactEvent) {
        reactEvent.preventDefault();
        const clipboardData = reactEvent.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        $(".close", $("#manualPaste")).trigger("click");
        this.convertAndPasteRefs(pastedData);
    }

    setReference(criteria, index, value) {
        if (typeof value == "object") {
            value = value.$rdfId || value.key;
        }
        if (value && !this.props.finder.loadedObjectcards.fetched[value]) {
            this.props.fetchObjectcard(value);
        }
        const changeHandler = this.createChangeRelationValueHandler(criteria, index);
        changeHandler(value);
    }

    printSelectionList(selectionList) {
        let selectSize;
        switch (selectionList.length) {
            case 1:
                selectSize = "col-md-12"
                break;
            case 2:
                selectSize = "col-md-6"
                break;
            default:
                selectSize = "col-md-4"
                break;
        }
        return selectionList.map((selectionObject, index) =>
            <div className={`cim-finder-table-cell-selection ${selectSize}`}>
                {index != selectionList.length - 1 ? <span className="fa fa-caret-right cim-combobox-element-separator">&nbsp;</span> : null}
                <ObservedSelect
                    name="field"
                    className="minified-react-select"
                    loadingPlaceholder={MSG_SELECT_LOADING}
                    placeholder={MSG_SELECT_PLACEHOLDER}
                    noResultsText={MSG_SELECT_NO_RESULTS}
                    isLoading={selectionObject.isLoading}
                    value={selectionObject.field ? selectionObject.field.id : ""}
                    valueKey="id"
                    options={selectionObject.selection}
                    onOpen={!selectionObject.isFetched ? this.props.fetchFields.bind(this, selectionObject.parentId) : null}
                    onChange={this.createChangeFieldHandler(selectionObject.field)}
                    clearable={false}
                />
            </div>);
    }

    generateRelationList(criteria, field, minHeight) {
        let _this = this;
        /* Selected field isn't last */
        if (!criteria.relations || criteria.relations.length == 0 || !field) {
            return [<div className="cim-finder-table-cell-relation-row" style={{ minHeight, borderTop: "none", borderBottom: "none" }}>
                <div className="col-md-5 cim-finder-table-cell">
                </div>
                <div className="col-md-5 cim-finder-table-cell">
                </div>
                <div className="col-md-2 no-gutter cim-finder-table-cell">
                    <div style={{ width: "100%" }}>
                        <div className='cim-finder-table-button card-input pull-right' onClick={() => { _this.props.removeCriteria(criteria.id) }}><i className="fa fa-times" aria-hidden="true"></i></div>
                    </div>
                </div>
            </div>]
        }

        let addButton = <div className='cim-finder-table-button card-input pull-right' onClick={() => { _this.props.addCriteriaRelation(criteria.id) }}><i className="fa fa-code-fork" aria-hidden="true"></i></div>
        let handler = criteria.relations.length == 1 ? this.props.removeCriteria : this.props.removeCriteriaRelation;

        /* Calculate min height for row */
        let orRowHeight = 22;
        let rowMinHeight = (minHeight - orRowHeight * (criteria.relations.length - 1)) / criteria.relations.length;

        let relationList = [];
        criteria.relations.map((relation, index) => {
            let style = {
                minHeight: rowMinHeight
            };
            if (index == 0) {
                style.borderTop = "none";
            }
            if (index == criteria.relations.length - 1) {
                style.borderBottom = "none";
            }
            relationList.push(<div className="cim-finder-table-cell-relation-row" style={style}>
                <div className="col-md-5 cim-finder-table-cell">
                    {this.printRelation(criteria, relation, field, index)}
                </div>
                <div className="col-md-5 cim-finder-table-cell">
                    {this.printRelationValue(criteria, relation, field, index)}
                </div>
                <div className="col-md-2 no-gutter cim-finder-table-cell">
                    <div style={{ width: "100%" }}>
                        <div className='cim-finder-table-button card-input pull-right' onClick={() => { handler(criteria.id, index) }}><i className="fa fa-times" aria-hidden="true"></i></div>
                        {index == criteria.relations.length - 1 ? addButton : null}
                    </div>
                </div>
            </div>)
        });
        return relationList;
    }

    render() {
        let _this = this;
        let field = null;
        let fieldId = this.props.criteria.fieldId;

        /* Get root fields */
        let rootSelection = [];
        for (let fieldId of this.props.finder.loadedFields.rootIds) {
            rootSelection.push(this.props.finder.loadedFields.byId[fieldId]);
        }

        let selectionList = [];
        if (fieldId) {
            field = this.props.finder.loadedFields.byId[fieldId];
            /* If field isn't last in sequence - add chosing field */
            if (!field.predicate) {
                let selection = [];
                for (let childFieldId of this.props.finder.loadedFields.children[fieldId]) {
                    selection.push(this.props.finder.loadedFields.byId[childFieldId])
                }
                selectionList.push({
                    selection: selection,
                    field: {
                        label: "--",
                        id: ""
                    },
                    isLoading: this.props.finder.loadedFields.loading[field.id],
                    isFetched: this.props.finder.loadedFields.fetched[field.id],
                    parentId: field.id
                });
            }

            selectionList.push({
                selection: null,
                field: field,
                isLoading: this.props.finder.loadedFields.loading[field.parentId],
                isFetched: this.props.finder.loadedFields.fetched[field.parentId],
                parentId: field.parentId
            });
            let parentId = field.parentId;
            while (parentId) {
                let parentField = this.props.finder.loadedFields.byId[parentId];

                /* Fill selection field of child */
                let previousSelection = [];
                for (let fieldId of this.props.finder.loadedFields.children[parentId]) {
                    previousSelection.push(this.props.finder.loadedFields.byId[fieldId])
                }
                selectionList[selectionList.length - 1].selection = previousSelection;

                selectionList.push({
                    selection: null,
                    field: parentField,
                    isLoading: this.props.finder.loadedFields.loading[parentId],
                    isFetched: this.props.finder.loadedFields.fetched[parentId],
                    parentId: parentId
                });
                parentId = parentField.parentId;
            }
            selectionList[selectionList.length - 1].selection = rootSelection;
            selectionList.reverse();
        } else {
            /* User didn't select anything - setup choosing field */
            selectionList.push({
                selection: rootSelection,
                field: {
                    label: "--",
                    id: ""
                },
                isLoading: this.props.finder.loadedFields.loading[null],
                isFetched: this.props.finder.loadedFields.fetched[null],
                parentId: null
            });
        }

        /* Calculate min relation height */
        let padding = 10;
        let rowHeight = 22;
        let minHeight = padding * 2 + rowHeight * (Math.ceil(selectionList.length / 3));

        let relationList = this.generateRelationList(this.props.criteria, field ? this.props.finder.loadedPredicates.byName[field.joinIdPredicate] : null, minHeight);
        for (let i = relationList.length - 1; i > 0; --i) {
            let row = (
                <div className='cim-finder-table-row-or'>
                    <span className='cim-finder-table-element-or advancedGrayscale'>
                        {MSG_OR}
                    </span>
                </div>);
            relationList.splice(i, 0, row);
        }

        return (<div className="cim-finder-table-row">
            <div className="col-md-7 cim-finder-table-cell row m-0">
                {this.printSelectionList(selectionList)}
            </div>
            <div className="col-md-5 nopadding cim-finder-table-cell-relation">
                {relationList.map((row, index) => row)}
            </div>
        </div>)
    }
}
