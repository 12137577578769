import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertContainer } from 'react-bs-notifier';
import { ALERT_INFO } from '../../constants/alert';


function generateDismiss(id, onDismiss) {
    return function() {
        console.log("Dismiss " + id);
        onDismiss(id);
    }
}

class AlertArea extends React.Component {

    constructor(props) {
        super(props);
        this.onDismiss = this.onDismiss.bind(this);
        this.generateAlert = this.generateAlert.bind(this);
    }

    onDismiss(id) {
        this.props.removeAlert(id);
    }

    generateAlert(alert) {
        let message = alert.message;
        if ($.type(message) == 'object') {
            if ($.type(message.values) == 'object') {
                message = (<FormattedMessage id={message.id} values={message.values} />);
            } else {
                message = (<FormattedMessage id={message.id} />);
            }
        }
        let dismissCallback = generateDismiss(alert.id, this.onDismiss);
        let type = alert.type;
        if (!type) {
            type = ALERT_INFO;
        }
        let timeout = alert.timeout;
        if (!timeout) {
            timeout = 1000;
        }
        return (<Alert key={alert.id} type={type} onDismiss={dismissCallback} timeout={timeout}>{message}</Alert>);
    }

    render() {
        return (
            <AlertContainer position="bottom-right">
                {this.props.alerts.map(this.generateAlert)}
            </AlertContainer>);
    }
}

export default AlertArea;