import React from 'react';
import { FormattedMessage } from 'react-intl';

class Legend extends React.Component {

    constructor(props) {
        super(props);
    }

    printGroup(group, index, className) {
        return (<div key={group.id ? group.id : index} className={className}><img style={{ background: group.color || "white", borderColor: group.border || "#000000" }} />- {group.name ? group.name : (group.name_id ? <FormattedMessage id={group.name_id} /> : null)}</div>);
    }

    componentDidUpdate() {
        if (this.props.gant) {
            $(window).trigger("resize");
        }
    }

    shouldComponentUpdate(nextProps) {
        if (!this.props.gant && nextProps.gant) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div className={"npt-gant-legend-container"}>
                <div className={"npt-gant-legend"}>
                    {this.props.gant && this.props.gant.groups.map((group, i) => this.printGroup(group, i, "chosen"))}
                    {this.props.gant && !this.props.hidePlanned && this.props.gant.plannedGroups.map((group, i) => this.printGroup(group, i, "planned"))}
                </div>
            </div>);
    }
}

export default Legend;
