import { FormattedMessage } from 'react-intl';
import { MSG_TABLE_NO_DATA_TO_DISPLAY } from '../messages.jsx';
import CimTableBase from '../table/table.jsx';
import CimFiller from '../filler/cimfiller.jsx';

class Table extends React.Component {

    constructor(props) {
        super(props);

        this.createFormatter = this.createFormatter.bind(this);
        this.timeFormatter = this.timeFormatter.bind(this);
        this.labelFormatter = this.labelFormatter.bind(this);
        this.columns = [];
    }

    createFormatter(type) {
        let func;
        switch (type) {
            case "label":
                func = this.labelFormatter;
                break;
            default:
                func = this.timeFormatter;
        }
        return function (cell, row) {
            return func(cell, row);
        }
    }

    timeFormatter(cell, row) {
        return cell.value;
    }

    labelFormatter(cell, row) {
        let _this = this;
        let func = function (reactEvent, event) {
            reactEvent.stopPropagation();
            _this.props.changeRow({ row });
        }
        return <a href="#" style={{ cursor: "pointer" }} onClick={func}>{cell}</a>;
    }

    getColumns() {
        return [
            {
                format: "selectColumn",
                sortable: false,
                select: true,
                radio: false,
                width: 50
            },
            {
                key: true,
                sortable: false,
                field: "tableIndex",
                align: "center",
                width: 50,
                label: "№"
            },
            {
                sortable: false,
                field: "label",
                dataFormat: this.createFormatter("label"),
                width: 200,
                label: <FormattedMessage
                    id="CRON_TASKS_LABEL"
                    defaultMessage="Label"
                    description="Label of script" />
            },
            {
                sortable: false,
                field: "scriptPath",
                width: 300,
                label: <FormattedMessage
                    id="CRON_TASKS_PATH_TO_SCRIPT"
                    defaultMessage="Path to script"
                    description="Path to script" />
            },
            {
                sortable: false,
                field: "second",
                width: 80,
                dataFormat: this.createFormatter("second"),
                label: <FormattedMessage
                    id="CRON_TASKS_TIME_UNIT_SECOND"
                    defaultMessage="Second"
                    description="Second" />
            },
            {
                sortable: false,
                field: "minute",
                width: 80,
                dataFormat: this.createFormatter("minute"),
                label: <FormattedMessage
                    id="CRON_TASKS_TIME_UNIT_MINUTE"
                    defaultMessage="Minute"
                    description="Minute" />
            },
            {
                sortable: false,
                field: "hour",
                width: 80,
                dataFormat: this.createFormatter("hour"),
                label: <FormattedMessage
                    id="CRON_TASKS_TIME_UNIT_HOUR"
                    defaultMessage="Hour"
                    description="Hour" />
            },
            {
                sortable: false,
                field: "day",
                width: 80,
                dataFormat: this.createFormatter("day"),
                label: <FormattedMessage
                    id="CRON_TASKS_TIME_UNIT_DAY"
                    defaultMessage="Day"
                    description="Day" />
            },
            {
                sortable: false,
                field: "month",
                width: 80,
                dataFormat: this.createFormatter("month"),
                label: <FormattedMessage
                    id="CRON_TASKS_TIME_UNIT_MONTH"
                    defaultMessage="Month"
                    description="Month" />
            },
            {
                sortable: false,
                field: "weekDay",
                width: 80,
                dataFormat: this.createFormatter("weekDay"),
                label: <FormattedMessage
                    id="CRON_TASKS_TIME_UNIT_WEEK_DAY"
                    defaultMessage="Week day"
                    description="Week day" />
            },
            {
                sortable: false,
                field: "cron",
                width: 120,
                label: <FormattedMessage
                    id="CRON_TASKS_CRON_STRING"
                    defaultMessage="Cron"
                    description="Cron" />
            },
            {
                sortable: false,
                field: "description",
                width: 300,
                label: <FormattedMessage
                    id="CRON_TASKS_DESCRIPTION"
                    defaultMessage="Description"
                    description="Description of script" />
            }
        ]
    }

    componentWillMount() {
        this.columns = this.getColumns();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.locale != nextProps.locale) {
            this.columns = this.getColumns();
        }
    }

    render() {
        return <CimFiller updateDelay={5}>
            <CimTableBase
                withoutFetch={true}
                data={this.props.data}
                messages={this.props.messages}
                header={this.columns}
                selectType="checkbox"
                selectedRows={this.props.selected}
                onSelect={this.props.selectRow}
            >
            </CimTableBase>
        </CimFiller>;
    }
}

export default Table;