import React from 'react';
import ReactDOM from 'react-dom';
import shortid from 'shortid';
import Toolbar from './toolbar.jsx';
import Table from './table.jsx';

import ContextMenu from './contextmenu/contextmenu.jsx';
import CimFiller from '../filler/cimfiller.jsx';
import Wrapper from '../filler/wrapper.jsx';
import Gant from './gant/gant.jsx';
import Pagination from './pagination.jsx';
import FinderContainer from '../finder/container.jsx';
import { checkFields, printLoading, printError } from '../../services/table';
import { VERTICAL, HORIZONTAL } from '../../constants/table';

class Container extends React.Component {

    constructor(props) {
        super(props);

        this.class = "";
        this.eventHandlers = [];
        this.isVisible = false;
        this.observer = null;

        this.scrollGant = this.scrollGant.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.registerHandler = this.registerHandler.bind(this);
        this.removeHandler = this.removeHandler.bind(this);
        this.callContextMenu = this.callContextMenu.bind(this);
    }

    checkVisibility(htmlElement = this.htmlElement) {
        if (!htmlElement) {
            return false;
        }
        return htmlElement.offsetParent != null;
    }

    checkVisibilityChange() {
        if (!this.checkVisibility()) {
            return false;
        }
        this.isVisible = true;
        this.forceUpdate();
        this.initializeTable();
        return true;
    }

    bindVisibilityUpdate() {
        if (!this.htmlElement) {
            return;
        }
        this.observer = new MutationObserver(() => {
            if (!this.checkVisibilityChange()) {
                return;
            }
            this.observer.disconnect();
        });
        this.observer.observe(this.htmlElement, { attributes: true });
        let node = this.htmlElement;
        while (node.parentNode && !this.checkVisibility(node.parentNode)) {
            node = node.parentNode;
        }
        this.observer.observe(node, { attributes: true });
    }

    unbindVisibilityUpdate() {
        if (!this.observer) {
            return;
        }
        this.observer.disconnect();
    }

    initializeTable() {
        if (!this.isVisible) {
            return;
        }
        if (this.props.initialFilter) {
            this.props.initializeWithFilter(this.props.initialFilter);
        } else {
            this.props.fetchData();
        }
    }

    fetchData(options) {
        if (!this.isVisible) {
            return;
        }
        this.props.fetchData({ fields: options && options.fields });
    }

    scrollGant({ clientHeight, clientWidth, scrollHeight, scrollLeft, scrollTop, scrollWidth }) {
        this.scrollTop = scrollTop;
        this.forceUpdate();
    }

    handleEvent(reactEvent) {
        for (let handler of this.eventHandlers) {
            if (handler.type != reactEvent.type) {
                continue;
            }
            handler.func(reactEvent);
        }
    }

    registerHandler(type, handler) {
        let id = shortid.generate();
        this.eventHandlers.push({
            id,
            type,
            func: handler
        });
        return id;
    }

    removeHandler(id) {
        for (let i = 0; i < this.eventHandlers.length; ++i) {
            if (this.eventHandlers[i].id == id) {
                this.eventHandlers.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    callContextMenu(event, items) {
        this.contextMenu.setState({ x: event.pageX, y: event.pageY, items });
    }

    printFinder() {
        return (
            <FinderContainer {...this.props} ref="container">
                {this.printTable()}
            </FinderContainer>);
    }

    printTable() {
        let gantWidth = 0;
        if (this.props.gant) {
            gantWidth = this.props.gant.width || 0.5;
        }
        let tableWidth = 1 - gantWidth;
        return [
            <Wrapper widthCoef={tableWidth}>
                <Table
                    {...this.props}
                    onScroll={this.scrollGant}
                    scrollTop={this.scrollTop}
                />
            </Wrapper>,
            this.props.gant ? <Wrapper widthCoef={gantWidth} className="npt-gant-wrapper">
                <Gant
                    {...this.props.gant}
                    messages={this.props.messages}
                    data={this.props.data}
                    loadingData={this.props.loadingData}
                    rowHeight={this.props.rowHeight}
                    onScroll={this.scrollGant}
                    scrollTop={this.scrollTop}
                    registerHandler={this.registerHandler}
                    removeHandler={this.removeHandler}
                    changeItem={this.props.changeItem}
                    addItem={this.props.addItem}
                    removeItem={this.props.removeItem}
                    callContextMenu={this.callContextMenu}
                />
            </Wrapper> : null
        ]
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.parameters != nextProps.parameters || this.props.hashParams != nextProps.hashParams) {
            let newFields = checkFields(nextProps.fields, this.props.hashParams, nextProps.hashParams);
            if (newFields != null) {
                this.fetchData({ fields: newFields });
            }
        }
        if (this.props.filterLink != nextProps.filterLink) {
            this.fetchData();
        }
    }

    componentDidMount() {
        this.htmlElement = ReactDOM.findDOMNode(this);
        this.isVisible = this.checkVisibility();
        if (this.isVisible) {
            this.initializeTable();
        } else {
            this.bindVisibilityUpdate();
        }
    }

    componentWillUnmount() {
        this.unbindVisibilityUpdate();
    }

    render() {
        if (!this.isVisible) {
            return <div></div>;
        }
        if (this.props.loading) {
            return printLoading();
        }
        if (this.props.error) {
            return printError(this.props.error);
        }
        return [
            <ContextMenu ref={(ref) => this.contextMenu = ref} />,
            <Toolbar {...this.props}></Toolbar>,
            <CimFiller updateDelay={5} style={{ fontSize: this.props.gant && this.props.gant.fontSize }} onMouseMove={this.handleEvent} onMouseUp={this.handleEvent}>
                {this.props.finderOptions ? this.printFinder() : this.printTable()}
            </CimFiller>,
            <Pagination {...this.props}></Pagination>
        ];
    }
}

export default Container;
