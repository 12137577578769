import * as Action from '../constants/srceditor';
import { addAlert } from './alert';
import { addNode } from './srctree';
import { openModal } from './modal';
import { ALERT_SUCCESS, ALERT_DANGER } from '../constants/alert';
import { safeGetEditor, getSrcEditorState } from '../services/srceditor';
import { MODAL_STATUS_OK } from '../constants/modal.js';
import { SERVER_CONFIG } from '../constants/serverconfig';
import { correctServerTimestamp } from '../services/serverconfig';

//Basic url for all ajax requests

/////////////////////
//Utility functions//
/////////////////////
export function composeEntityUrl(editorId, path) {
    return `/rest/${editorId}/entity${path}`;
}

function composeHistoryUrl(editorId, path) {
    return `/rest/${editorId}/history${path}`;
}

function composeRestoreUrl(editorId, id, path) {
    return `/rest/${editorId}/restore?id=${id}&path=${path}`;
}

function composeLockUrl(editorId, path, lock) {
    let action = lock ? 'lock' : 'unlock';
    return `/rest/${editorId}/${action}${path}`;
}

function ajaxFetchCode(editorId, path, dispatch) {
    console.log("AJAX call to fetch source code");
    dispatch(waitForCode(editorId, path));
    return $.get(composeEntityUrl(editorId, path), function (data) {
        dispatch(receiveCode(editorId, path, data));
    }).fail(function (error) {
        let options = {
            title: { id: "SRC_EDITOR_CREATE_CODE" },
            body: { id: "SRC_EDITOR_CONFIRM_CREATE_CODE" }
        }
        let confirm = function () {
            let mime = "";
            if (editorId == "vieweditor") {
                mime = "text/html";
            } else {
                mime = "application/json";
            }
            ajaxSaveCode(editorId, { code: "", description: "", mime, path }, null, dispatch, function (data) {
                dispatch(receiveCode(editorId, path, data));
            });
        }
        let cancel = function () {
            //defined in foundation/srceditor.jsx
            dispatch(addAlert(ALERT_DANGER, { id: "SRCEDITOR_RECEIVE_CODE_ERROR", values: { path } }));
            dispatch(receiveCodeError(editorId, error));
        }
        if (error.status == 404) {
            dispatch(openModal("addCode", "confirm", options, confirm, cancel, cancel));
        }
    });
}

const initialEditorParams = {
    description: "",
    code: "",
    mime: ""
}

export function ajaxSaveCode(editorId, data, modal, dispatch, onSuccess, onError) {
    console.log("AJAX call to save source code");
    return $.ajax({
        contentType: 'application/json',
        data: JSON.stringify(Object.assign({}, initialEditorParams, data)),
        dataType: 'json',
        processData: false,
        type: 'POST',
        url: composeEntityUrl(editorId, "")
    }).done(function (data) {
        if (modal) {
            modal.closeModal(MODAL_STATUS_OK);
        }
        dispatch(addAlert(ALERT_SUCCESS, { id: "SRC_EDITOR_CODE_SAVE_SUCCESS", values: { path: data.path } }));
        if (typeof onSuccess == "function") {
            onSuccess(data);
        }
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "SRC_EDITOR_CODE_SAVE_ERROR", values: { path: data.path } }));
        console.error("Error while code saving: ", error);
        if (typeof onError == "function") {
            onError(error);
        }
    });
}

export function ajaxDeleteCode(editorId, path, dispatch, onSuccess, onError) {
    console.log("AJAX call to remove source code");
    return $.ajax({
        type: 'DELETE',
        url: composeEntityUrl(editorId, path)
    }).done(function () {
        dispatch(addAlert(ALERT_SUCCESS, { id: "SRC_EDITOR_CODE_REMOVE_SUCCESS", values: { path: path } }));
        if (typeof onSuccess == "function") {
            onSuccess();
        }
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "SRC_EDITOR_CODE_REMOVE_ERROR", values: { path: path } }));
        console.error("Error while code removing: ", error);
        if (typeof onError == "function") {
            onError(error);
        }
    });
}

function checkLockStatus(editorState, editorId, lock) {
    return (typeof editorState.lock != 'undefined' && editorState.lock.status == lock);
}

function ajaxLockCode(editorId, path, lock, dispatch) {
    console.log("AJAX call to lock subject");
    return $.post(composeLockUrl(editorId, path, lock), function (data) {
        //dispatch(layoutReceived(cls, data));
        dispatch(editLockReceived(editorId, lock));
    }).fail(function (error) {
        //dispatch(layoutErrorReceived(cls, error));
        // dispatch(editLockReceived(editorId, lock));
        dispatch(addAlert(ALERT_DANGER, { id: "SRC_EDITOR_LOCK_GET_FAIL" }))
    });
}

function parseHistoryData(data, timeDifference) {
    if (!data || !Array.isArray(data)) {
        return data;
    }
    for (let entry of data) {
        /* Server stores timestamps in UTC time but without 'z' in the end of timestamp */
        if (typeof entry.operationTimestamp == "string" && entry.operationTimestamp.toLowerCase()[entry.operationTimestamp.length - 1] != "z") {
            entry.operationTimestamp += "Z";
        }
        /* Time on server isn't synchronized with local pc and we must add time difference between them to display correct time */
        entry.operationTimestamp = correctServerTimestamp(entry.operationTimestamp, timeDifference);
    }
    return data;
}


///////////
//Actions//
///////////
export function receiveCode(editorId, path, data) {
    return {
        type: Action.RECEIVE_CODE,
        payload: { editorId, path, data }
    }
}

function receiveCodeError(editorId, error) {
    return {
        type: Action.RECEIVE_CODE_ERROR,
        payload: { editorId, error },
        error: true
    }
}

export function waitForCode(editorId, path) {
    return {
        type: Action.WAIT_FOR_CODE,
        payload: { editorId, path }
    }
}

function receiveHistory(editorId, path, data) {
    return {
        type: Action.RECEIVE_HISTORY,
        payload: { editorId, path, data }
    }
}

function receiveHistoryError(editorId, path, error) {
    return {
        type: Action.RECEIVE_HISTORY_ERROR,
        payload: { editorId, error }
    }
}

function editLockReceived(editorId, lock) {
    return {
        type: Action.EDIT_LOCK_RECEIVED,
        payload: { editorId, lock }
    }
}

export function changeParam(editorId, param, value) {
    return {
        type: Action.CHANGE_PARAM,
        payload: { editorId, param, value }
    }
}

export function fetchCode(editorId, path) {
    return function (dispatch, getState) {
        ajaxFetchCode(editorId, path, dispatch);
    }
}

export function editCurrentCode(editorId) {
    return function (dispatch, getState) {
        let editorState = safeGetEditor(getSrcEditorState(getState()), editorId);
        if (checkLockStatus(editorState, editorId, false)) {
            ajaxLockCode(editorId, editorState.entity.path, true, dispatch);
        }
    }
}

export function cancelEditCurrentCode(editorId) {
    return function (dispatch, getState) {
        let editorState = safeGetEditor(getSrcEditorState(getState()), editorId);
        if (checkLockStatus(editorState, editorId, true)) {
            ajaxLockCode(editorId, editorState.entity.path, false, dispatch);
        }
    }
}

export function saveCode(editorId) {
    return function (dispatch, getState) {
        let editorState = safeGetEditor(getSrcEditorState(getState()), editorId);
        if (checkLockStatus(editorState, editorId, true)) {
            ajaxSaveCode(editorId, editorState.entity, null, dispatch, function (data) {
                dispatch(receiveCode(editorId, data.path, data));
            });
        }
    }
}

export function fetchHistoryData(editorId, path) {
    return function (dispatch, getState) {
        return $.get(composeHistoryUrl(editorId, path), function (data) {
            const timeDifference = getState()[SERVER_CONFIG].timeDifference;
            dispatch(receiveHistory(editorId, path, parseHistoryData(data, timeDifference)));
        }).fail(function (error) {
            dispatch(addAlert(ALERT_DANGER, { id: "SRCEDITOR_RECEIVE_HISTORY_ERROR" }));
            dispatch(receiveHistoryError(editorId, path, error));
        });
    }
}

export function ajaxRestoreCode(editorId, id, path, modal) {
    return function (dispatch, getState) {
        return $.ajax({
            type: 'POST',
            url: composeRestoreUrl(editorId, id, path)
        }).done(function (data) {
            dispatch(addNode(editorId, path));
            dispatch(addAlert(ALERT_SUCCESS, { id: "SRCEDITOR_RESTORE_CODE_SUCCESS" }));
            if (modal) {
                modal.closeModal(MODAL_STATUS_OK);
            }
        }).fail(function (error) {
            dispatch(addAlert(ALERT_DANGER, { id: "SRCEDITOR_RESTORE_CODE_ERROR" }));
        });
    }
}