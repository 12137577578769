import TreeSearchPanel from './components/search/treesearch.jsx';
import { TREESEARCH } from './constants/treesearch';
import treesearch from './reducers/treesearch';
import {
    search,
    selectSearchFragment,
    fetchFragments,
    selectSearchClass,
    fetchClasses,
    toggleFinder,
    getFinderOptions
} from './actions/treesearch';

const ATTR_CIM_TREE_SEARCH = "cim-tree-search";
const ATTR_CIM_TREE_PAGE = "cim-tree-page";

function createMapStateToProps(treeId) {
    return (globalState) => {
        return {
            loading: globalState[TREESEARCH].loading,
            ready: globalState[TREESEARCH].ready,
            finder: globalState[TREESEARCH].finder,
            error: globalState[TREESEARCH].error,
            request: globalState[TREESEARCH].request,
            result: globalState[TREESEARCH].result
        };
    }
}

function createMapDispachToProps(treeId) {
    return (dispatch) => {
        return {
            selectSearchFragment: function (oldFragmentId, newFragmentId) {
                dispatch(selectSearchFragment(oldFragmentId, newFragmentId));
            },
            fetchFragments: function (fragmentsId) {
                dispatch(fetchFragments(fragmentsId));
            },
            selectSearchClass: function (oldClassId, newClassId, level) {
                dispatch(selectSearchClass(oldClassId, newClassId, level));
            },
            fetchClasses: function (levelId) {
                dispatch(fetchClasses(levelId));
            },
            toggleFinder: function () {
                dispatch(toggleFinder());
            },
            getFinderOptions: function () {
                dispatch(getFinderOptions(treeId));
            },
            search: function (request) {
                dispatch(search(treeId, request));
            },
        }
    }
}


function createTreeSearchContainer(treeId) {
    //Connect redux to our navigation tree
    return connect(
        createMapStateToProps(treeId),
        createMapDispachToProps(treeId)
    )(TreeSearchPanel);
}

//Initialization function
export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(TREESEARCH, treesearch);
    //Register components
    dispatcher.registerComponent(ATTR_CIM_TREE_SEARCH, (elm) => {
        let treeId = $(elm).attr(ATTR_CIM_TREE_SEARCH);
        let page = $(elm).attr(ATTR_CIM_TREE_PAGE);
        if (!page) {
            page = "";
        }
        let TreeSearchContainer = createTreeSearchContainer(treeId);
        return (<TreeSearchContainer page={page} />);
    });
};

