import React from 'react';

class UnreadMessages extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let unreadMessages = this.props.messagebox.unread ? this.props.messagebox.unread : this.props.unreadMessages;
        if (!unreadMessages) {
            return null;
        }
        return (<span>
            <span className="badge badge-pill badge-primary" style={{ display: "inline", verticalAlign: "baseline" }}>
                {unreadMessages}
            </span>
        </span>);
    }
}

export default UnreadMessages;