import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import RestorePopup from './restorepopup.jsx';
import { MSG_STATUS_LOADING, MSG_STATUS_ERROR, MSG_TABLE_NO_DATA } from '../messages.jsx';

const tableStyle = {
    minHeight: "250px",
    maxHeight: "600px"
}

const options = {
    noDataText: MSG_TABLE_NO_DATA,
};

const HEADER_HEIGHT = 80;
const ROW_HEIGHT = 67;

const SRC_EDITOR_HISTORY_TITLE = <FormattedMessage
    id="SRC_EDITOR_HISTORY_TITLE"
    defaultMessage="History of changing"
    description="Title of history popup" />

const SRC_EDITOR_HISTORY_RESTORE = <FormattedMessage
    id="SRC_EDITOR_HISTORY_RESTORE"
    defaultMessage="Restore code"
    description="Tooltip of button that restore code" />

const SRC_EDITOR_HISTORY_RESTORE_CONFIRM = <FormattedMessage
    id="SRC_EDITOR_HISTORY_RESTORE_CONFIRM"
    defaultMessage="Confirm restore of code"
    description="Title of restore modal" />

const SRCEDITOR_RESTORE_CODE_SUCCESS = <FormattedMessage
    id="SRCEDITOR_RESTORE_CODE_SUCCESS"
    defaultMessage="Code was successfully restored"
    description="Code was successfully restored" />

const SRCEDITOR_RESTORE_CODE_ERROR = <FormattedMessage
    id="SRCEDITOR_RESTORE_CODE_ERROR"
    defaultMessage="Error occured while restore code"
    description="Error occured while restore code" />

const operationMessage = {
    0: <FormattedMessage
        id="SRCEDITOR_OPERATION_CREATE"
        defaultMessage="Create"
        description="Operation create" />,
    1: <FormattedMessage
        id="SRCEDITOR_OPERATION_UPDATE"
        defaultMessage="Update"
        description="Operation update" />,
    2: <FormattedMessage
        id="SRCEDITOR_OPERATION_DELETE"
        defaultMessage="Delete"
        description="Operation delete" />
}

class HistoryPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }

        this.restore = this.restore.bind(this);
        this.restoreFormatter = this.restoreFormatter.bind(this);
        this.pathFormatter = this.pathFormatter.bind(this);
        this.operationFormatter = this.operationFormatter.bind(this);
        this.dateTimeFormatter = this.dateTimeFormatter.bind(this);
        this.userFormatter = this.userFormatter.bind(this);
    }

    restore(cell, row) {
        let restorePopup = null;
        const options = {
            title: { id: "SRC_EDITOR_HISTORY_RESTORE_CONFIRM" },
            body: <RestorePopup
                path={row.path}
                ref={(ref) => restorePopup = ref}
            />,
            onOk: (modal) => this.props.restore(row.id, restorePopup.state.path, modal)
        };
        this.props.openModal("restorePopup", "common", options);
    }

    restoreFormatter(cell, row) {
        return <button
            type="button"
            className="btn btn-xs btn-secondary"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title={this.props.messages["SRC_EDITOR_HISTORY_RESTORE"]}
            onClick={this.restore.bind(this, cell, row)}>
            <i className="fa fa-reply fa-fw" aria-hidden="true"></i>
        </button>
    }

    pathFormatter(cell, row) {
        return cell.replace(/\//g, "/<wbr>");
    }

    operationFormatter(cell, row) {
        return operationMessage[cell];
    }

    dateTimeFormatter(cell, row) {
        return moment(cell).format('L LTS');
    }

    userFormatter(cell, row) {
        if (!cell) {
            return "--";
        }
        return cell.$label;
    }

    parseData(data) {
        return data.sort((a, b) => {
            if (!a.operationTimestamp || !b.operationTimestamp) {
                return -1;
            }
            return Date.parse(b.operationTimestamp) - Date.parse(a.operationTimestamp);
        });
    }

    componentDidMount() {
        window.setTimeout(() => this.props.fetchData(this.props.node), 200);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.history != this.props.history) {
            this.state.loading = false;
            this.state.error = nextProps.history.error;
            this.state.data = this.parseData(nextProps.history.data);
        }
    }

    render() {
        if (this.state.loading) {
            return (<span><i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>{MSG_STATUS_LOADING}</span>);
        } else if (this.state.error) {
            if (this.state.error.status && this.state.error.status >= 400 && this.state.error.status < 500) {
                return (<span><i className="fa fa-exclamation-triangle fa-3x fa-fw"></i>{this.state.error.responseText ? this.state.error.responseText : this.state.error.statusText}</span>);
            }
            return (<span><i className="fa fa-exclamation-triangle fa-3x fa-fw"></i>{MSG_STATUS_ERROR}</span>);
        }
        return (
            <div style={tableStyle}>
                <BootstrapTable version='4' data={this.state.data} striped={true} hover={true} options={options} minHeight={tableStyle.minHeight} maxHeight={tableStyle.maxHeight}>
                    <TableHeaderColumn dataField='' width='50px' dataFormat={this.restoreFormatter} tdStyle={{ textOverflow: "clip" }}></TableHeaderColumn>
                    <TableHeaderColumn dataField='id' isKey={true} dataSort={true} width='70px' >
                        <FormattedMessage
                            id='SRC_EDITOR_ID'
                            defaultMessage="Id"
                            description="Title of history table id column" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='path' width='200px' tdStyle={{ whiteSpace: 'normal', wordBreak: "break-word" }} dataSort={true} dataFormat={this.pathFormatter} >
                        <FormattedMessage
                            id='SRC_EDITOR_PATH' />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='operation' width='120px' dataSort={true} dataFormat={this.operationFormatter} >
                        <FormattedMessage
                            id='SRC_EDITOR_OPERATION'
                            defaultMessage="Operation"
                            description="Title of history table operation column" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='operationTimestamp' width='150px' dataSort={true} dataFormat={this.dateTimeFormatter} >
                        <FormattedMessage
                            id='SRC_EDITOR_OPERATION_TIMESTAMP'
                            defaultMessage="Operation time"
                            description="Title of history table operation timestamp column" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='user' width='150px' tdStyle={{ whiteSpace: 'normal', wordBreak: "break-word" }} dataSort={true} dataFormat={this.userFormatter} >
                        <FormattedMessage
                            id='SRC_EDITOR_USER'
                            defaultMessage="User"
                            description="Title of history table user column" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='mime' width='120px' dataSort={true} >
                        <FormattedMessage
                            id='SRC_EDITOR_MIME' />
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default HistoryPopup;


