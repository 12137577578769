export function updateNode(action, { id, node, children, loading, error, movePath }, type) {
    return {
        type: action,
        payload: { id, node, children, loading, error, movePath, type }
    }
}

export function receiveTree(action, originalData, data) {
    return {
        type: action,
        payload: { originalData, data }
    }
}

export function receiveTreeError(action, error) {
    return {
        type: action,
        payload: { error },
        error: true
    }
}

export function waitForTree(action) {
    return {
        type: action
    }
}

export function plainToggleSubTree(action, id, toggled) {
    return {
        type: action,
        payload: { id, toggled }
    }
}

export function openNode(action, id) {
    return {
        type: action,
        payload: { id }
    }
}