import React from 'react';

class Wrapper extends React.Component {

    render() {
        return React.Children.map(this.props.children, child => {
            if (!child) {
                return null;
            }
            let childProps = {
                width: this.props.width,
                height: this.props.height
            }
            if (this.props.widthCoef) {
                childProps.width = this.props.width * this.props.widthCoef;
            }
            if (this.props.heightCoef) {
                childProps.height = this.props.height * this.props.heightCoef;
            }
            if (this.props.widthOffset) {
                childProps.width -= this.props.widthOffset;
            }
            if (this.props.heightOffset) {
                childProps.height -= this.props.heightOffset;
            }
            return (<div className={"cim-filler cim-wrapper " + (this.props.className ? this.props.className : "")} style={Object.assign({}, childProps, this.props.style)}>
                {React.cloneElement(child, childProps)}
            </div>);
        });
    }
}

export default Wrapper;