import React from 'react';
import { FormattedMessage } from 'react-intl';
import Toolbar from './toolbar.jsx';
import Table from './table.jsx';
import ConfigurePanel from './configurepanel.jsx';
import ConfirmDialog from './confirmdialog.jsx';

class CronTasks extends React.Component {

    constructor(props) {
        super(props);

        props.updateScripts();

        this.selectRow = this.selectRow.bind(this);
        this.changeRow = this.changeRow.bind(this);

        this.callConfigurePanel = this.callConfigurePanel.bind(this);
        this.deleteRowCallback = this.deleteRowCallback.bind(this);
    }

    selectRow({ data, selected }) {
        this.props.selectRow({ data, selected });
    }

    changeRow({ row }) {
        this.props.callConfigurePanel({ task: row });
    }

    createCloseConfigurePanel(_this) {
        return function () {
            _this.props.closeConfigurePanel();
        }
    }

    callConfigurePanel({ task }) {
        this.props.callConfigurePanel({ task });
    }

    deleteRowCallback(reactEvent, event) {
        let _this = this;
        this.props.callConfirmDialog({
            ok: function () {
                _this.props.closeConfirmDialog();
                _this.props.deleteRows({ selectedRows: _this.props.crontasks.menu.selectedRows });
            },
            cancel: function () {
                _this.props.closeConfirmDialog();
            }
        })
    }

    render() {
        return (
            <div className="col-md-12 cim-crontask">
                {this.props.crontasks.confirmDialog.called ?
                    <ConfirmDialog
                        ok={this.props.crontasks.confirmDialog.ok}
                        cancel={this.props.crontasks.confirmDialog.cancel}
                        text={<FormattedMessage
                            id="CRON_TASKS_DELETE_DIALOG_BODY"
                            defaultMessage="Confirm deletion of {rowNum} rows."
                            description="Confirm deletion of {rowNum} rows."
                            values={{ rowNum: this.props.crontasks.menu.selectedRows.length }} />}>
                    </ConfirmDialog> : null}
                {this.props.crontasks.configurePanel.called ?
                    <ConfigurePanel
                        task={this.props.crontasks.configurePanel.task}
                        ok={this.props.okConfigurePanel}
                        close={this.props.closeConfigurePanel}
                        error={this.props.crontasks.configurePanel.error}>
                    </ConfigurePanel> : null}
                <Toolbar
                    selectedRows={this.props.crontasks.menu.selectedRows}
                    callback={{
                        addRow: this.callConfigurePanel,
                        deleteRow: this.deleteRowCallback
                    }}>
                </Toolbar>
                <Table
                    data={this.props.crontasks.data}
                    messages={this.props.messages}
                    selected={this.props.crontasks.selectedRowsKeys}
                    selectRow={this.selectRow}
                    changeRow={this.changeRow}>
                </Table>
            </div>);
    }
}

export default CronTasks;


