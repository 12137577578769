import React from 'react';
import { FormattedMessage } from 'react-intl';
import CardArea from './cardarea.jsx';

class Panel extends CardArea {

    constructor(props) {
        super(props);
    }

    render() {
        const className = "card col-md-12 nopadding " + (this.props.node.options.style ? this.props.node.options.style : "");
        const style = this.props.visible ? null : { display: "none" };
        return (
            <div className="row card-row" style={style}>
                <div className={className}>
                    {this.props.node.label && <div className="card-header bg-info">
                        <h6 className="card-title font-weight-bold">{this.props.node.label}</h6>
                    </div>}
                    <div className="card-body">
                        {this.getChildren()}
                    </div>
                </div>
            </div>);
    }
}

export default Panel;