import { FormattedMessage } from 'react-intl';

export const PROFILE_EDITOR_BASE_URL = "developer/profileeditor";

export const JS_EDITOR_URL = "developer/scripteditor";
export const VIEW_EDITOR_URL = "developer/vieweditor";
export const CLASS_BASE_URL = "/npt/platform/classes";
export const PREDICATE_BASE_URL = "/npt/platform/predicates";

export const NAME_FIRST_SYMBOL = "a-zA-Z_";
export const NAME_SYMBOLS = NAME_FIRST_SYMBOL + "0-9";
export const LABEL_FIRST_SYMBOL = null;
export const LABEL_SYMBOLS = null;

//Global tree state path
export const CLASSCARD = "classcard";

export const CLASSCARD_TREE = "classcardtree";

/////////////////////
//Card state status//
/////////////////////
export const RECEIVE_ENUMERATIONS = "@@CLASSCARD_RECEIVE_ENUMERATIONS";
export const CLASS_WAIT = "@@CLASSCARD_CLASS_WAIT";
export const CLASS_INFO_RECEIVED = "@@CLASSCARD_CLASS_INFO_RECEIVED";
export const CLASS_ERROR_RECEIVED = "@@CLASSCARD_CLASS_ERROR_RECEIVED";
export const TYPES_WAIT = "@@CLASSCARD_TYPES_WAIT";
export const TYPES_RECEIVED = "@@CLASSCARD_TYPES_RECEIVED";
export const TYPES_ERROR_RECEIVED = "@@CLASSCARD_TYPES_ERROR_RECEIVED";
export const ALL_CLASSES_RECEIVED = "@@CLASSCARD_ALL_CLASSES_RECEIVED";
export const ALL_CLASSES_ERROR_RECEIVED = "@@CLASSCARD_ALL_CLASSES_ERROR_RECEIVED";
export const RECEIVE_PACKAGES = "@@CLASSCARD_RECEIVE_PACKAGES";
export const CHANGE_TAB = "@@CLASSCARD_CHANGE_TAB";
export const SELECT_ROWS = "@@CLASSCARD_SELECT_ROWS";
export const DESELECT_ROWS = "@@CLASSCARD_DESELECT_ROWS";
export const CHILDREN_RECEIVED = "@@CLASSCARD_CHILDREN_RECEIVED";

////////////////////
//Cim tree actions//
////////////////////
export const WAIT_FOR_TREE = "@@CLASSCARD_WAIT_FOR_TREE";
export const RECEIVE_TREE = "@@CLASSCARD_RECEIVE_TREE";
export const RECEIVE_TREE_ERROR = "@@CLASSCARD_RECEIVE_TREE_ERROR";
export const TOGGLE_SUBTREE = "@@CLASSCARD_TOGGLE_SUBTREE";
export const UPDATE_NODE = "@@CLASSCARD_UPDATE_NODE";

////////////////////
//Common variables//
////////////////////

export const DEPTHS = [
    { name: "one", label: "Один уровень", value: 1 },
    { name: "two", label: "Два уровня", value: 2 },
    { name: "three", label: "Три уровня", value: 3 }
];

//////////////////////
//Formatted messages//
//////////////////////
export const CLASSCARD_NOT_EMPTY_FIELD = <FormattedMessage
    id="CLASSCARD_NOT_EMPTY_FIELD"
    defaultMessage="Field must not be empty."
    description="Field must not be empty." />

export const CLASSCARD_ENUMERATIONS_GET_ERROR = <FormattedMessage
    id="CLASSCARD_ENUMERATIONS_GET_ERROR"
    defaultMessage="Failed to download enumerations."
    description="Failed to download enumerations." />

export const CLASSCARD_SELECT_PRIMITIVES = <FormattedMessage
    id="CLASSCARD_SELECT_PRIMITIVES"
    defaultMessage="Primitives"
    description="Primitives" />

export const CLASSCARD_SELECT_CLASSES = <FormattedMessage
    id="CLASSCARD_SELECT_CLASSES"
    defaultMessage="Classes"
    description="Classes" />

export const CLASSCARD_PACKAGE = <FormattedMessage
    id="CLASSCARD_PACKAGE"
    defaultMessage="Package"
    description="Package" />

export const CLASSCARD_CLASS = <FormattedMessage
    id="CLASSCARD_CLASS"
    defaultMessage="Class"
    description="Class" />

export const CLASSCARD_IDENTIFIER = <FormattedMessage
    id="CLASSCARD_IDENTIFIER"
    defaultMessage="Identifier"
    description="Identifier" />

export const CLASSCARD_NAMESPACE = <FormattedMessage
    id="CLASSCARD_NAMESPACE"
    defaultMessage="Namespace"
    description="Namespace" />

export const CLASSCARD_LABEL = <FormattedMessage
    id="CLASSCARD_LABEL"
    defaultMessage="Label"
    description="Label" />

export const CLASSCARD_RELATION_POPUP_FIRST_CLASS = <FormattedMessage
    id="CLASSCARD_RELATION_POPUP_FIRST_CLASS"
    defaultMessage="Class 1"
    description="First class in relation popup window" />

export const CLASSCARD_RELATION_POPUP_SECOND_CLASS = <FormattedMessage
    id="CLASSCARD_RELATION_POPUP_SECOND_CLASS"
    defaultMessage="Class 2"
    description="Second class in relation popup window" />

export const CLASSCARD_PARENTS_CLASSES = <FormattedMessage
    id="CLASSCARD_PARENTS_CLASSES"
    defaultMessage="Parents classes"
    description="Parents classes" />

export const CLASSCARD_STEREOTYPE = <FormattedMessage
    id="CLASSCARD_STEREOTYPE"
    defaultMessage="Stereotype"
    description="Stereotype" />

export const CLASSCARD_STORE_TYPE = <FormattedMessage
    id="CLASSCARD_STORE_TYPE"
    defaultMessage="Store type"
    description="Store type" />

export const CLASSCARD_DEPTH = <FormattedMessage
    id="CLASSCARD_DEPTH"
    defaultMessage="Depth"
    description="Depth of enumeration" />

export const CLASSCARD_FIND_CLASS = <FormattedMessage
    id="CLASSCARD_FIND_CLASS"
    defaultMessage="Find class..."
    description="Placeholder for class searching select" />

export const CLASSCARD_EDIT_PACKAGE = <FormattedMessage
    id="CLASSCARD_EDIT_PACKAGE"
    defaultMessage="Edit package"
    description="Title of popup window that allow to edit selected package" />

export const CLASSCARD_CREATE_PACKAGE = <FormattedMessage
    id="CLASSCARD_CREATE_PACKAGE"
    defaultMessage="Create package"
    description="Title of popup window that allow to create new package" />

export const CLASSCARD_EDIT_CLASS = <FormattedMessage
    id="CLASSCARD_EDIT_CLASS"
    defaultMessage="Edit class"
    description="Title of popup window that allow to edit selected class" />

export const CLASSCARD_CREATE_CLASS = <FormattedMessage
    id="CLASSCARD_CREATE_CLASS"
    defaultMessage="Create class"
    description="Title of popup window that allow to create new class" />

export const CLASSCARD_VIEW_PREDICATE = <FormattedMessage
    id="CLASSCARD_VIEW_PREDICATE"
    defaultMessage="View predicate"
    description="Title of popup window that allow to view selected predicate" />

export const CLASSCARD_EDIT_PREDICATE = <FormattedMessage
    id="CLASSCARD_EDIT_PREDICATE"
    defaultMessage="Edit predicate"
    description="Title of popup window that allow to edit selected predicate" />

export const CLASSCARD_CREATE_PREDICATE = <FormattedMessage
    id="CLASSCARD_CREATE_PREDICATE"
    defaultMessage="Create predicate"
    description="Title of popup window that allow to create new predicate" />

export const CLASSCARD_VIEW_RELATION = <FormattedMessage
    id="CLASSCARD_VIEW_RELATION"
    defaultMessage="View relation"
    description="Title of popup window that allow to view selected relation" />

export const CLASSCARD_DATA_TYPE_OBJECT = <FormattedMessage
    id="CLASSCARD_DATA_TYPE_OBJECT"
    defaultMessage="Object"
    description="Single object data type" />

export const CLASSCARD_DATA_TYPE_OBJECTS = <FormattedMessage
    id="CLASSCARD_DATA_TYPE_OBJECTS"
    defaultMessage="Objects"
    description="Multiple objects data type" />

export const CLASSCARD_CLASS_HAVE_NO_RELATIONS = <FormattedMessage
    id="CLASSCARD_CLASS_HAVE_NO_RELATIONS"
    defaultMessage="Class doesn't have any relations."
    description="This class have no predicate with relation to another class." />

export const CLASSCARD_CLASS_HAVE_NO_PREDICATES = <FormattedMessage
    id="CLASSCARD_CLASS_HAVE_NO_PREDICATES"
    defaultMessage="Class doesn't have any predicates."
    description="This class have no predicates." />

export const CLASSCARD_CLASS_HAVE_NO_TRIGGERS = <FormattedMessage
    id="CLASSCARD_CLASS_HAVE_NO_TRIGGERS"
    defaultMessage="Class doesn't have any triggers."
    description="This class have no triggers." />

export const CLASSCARD_LABEL_OF_TRIGGER = <FormattedMessage
    id="CLASSCARD_LABEL_OF_TRIGGER"
    defaultMessage="Label"
    description="Label" />

export const CLASSCARD_LINK_OF_TRIGGER = <FormattedMessage
    id="CLASSCARD_LINK_OF_TRIGGER"
    defaultMessage="Link"
    description="Link" />

export const CLASSCARD_EXPORT_PACKAGE = <FormattedMessage
    id="CLASSCARD_EXPORT_PACKAGES"
    defaultMessage="Export packages"
    description="Export packages" />

export const CLASSCARD_ID_OF_PACKAGE = <FormattedMessage
    id="CLASSCARD_ID_OF_PACKAGE"
    defaultMessage="Id"
    description="Id of package" />

export const CLASSCARD_ID_OF_PREDICATE = <FormattedMessage
    id="CLASSCARD_ID_OF_PREDICATE"
    defaultMessage="Id"
    description="Id of predicate" />

export const CLASSCARD_NAME_OF_PREDICATE = <FormattedMessage
    id="CLASSCARD_NAME_OF_PREDICATE"
    defaultMessage="Name"
    description="Name" />

export const CLASSCARD_TYPE_OF_RELATION = <FormattedMessage
    id="CLASSCARD_TYPE_OF_RELATION"
    defaultMessage="Type of relation"
    description="Type of relation" />

export const CLASSCARD_LINK_TO_THE_CLASS = <FormattedMessage
    id="CLASSCARD_LINK_TO_THE_CLASS"
    defaultMessage="Link to the class"
    description="Link to the class" />

export const CLASSCARD_REVERSE_LINK_OF_THE_CLASS = <FormattedMessage
    id="CLASSCARD_REVERSE_LINK_OF_THE_CLASS"
    defaultMessage="Reverse link of the class"
    description="Reverse link of the class" />

export const CLASSCARD_LABEL_OF_PREDICATE = <FormattedMessage
    id="CLASSCARD_LABEL_OF_PREDICATE"
    defaultMessage="Label"
    description="Label of predicate" />

export const CLASSCARD_NAMESPACE_OF_PREDICATE = <FormattedMessage
    id="CLASSCARD_NAMESPACE_OF_PREDICATE"
    defaultMessage="Namespace"
    description="Namespace of predicate" />

export const CLASSCARD_TYPE_OF_DATA = <FormattedMessage
    id="CLASSCARD_TYPE_OF_DATA"
    defaultMessage="Type of data"
    description="Type of data" />

export const CLASSCARD_MULTIPLICITY = <FormattedMessage
    id="CLASSCARD_MULTIPLICITY"
    defaultMessage="Multiplicity"
    description="Multiplicity of the predicate" />

export const CLASSCARD_MANDATORY = <FormattedMessage
    id="CLASSCARD_MANDATORY"
    defaultMessage="Mandatory"
    description="Mandatory of the predicate" />

export const CLASSCARD_AUTOMATION = <FormattedMessage
    id="CLASSCARD_AUTOMATION"
    defaultMessage="Automation"
    description="Label of automation badge" />

export const CLASSCARD_LAYOUT_COLUMN = <FormattedMessage
    id="CLASSCARD_LAYOUT_COLUMN"
    defaultMessage="Layout"
    description="Label of layout column" />

export const CLASSCARD_REVERSE_PREDICATE = <FormattedMessage
    id="CLASSCARD_REVERSE_PREDICATE"
    defaultMessage="Reverse predicate"
    description="Label of reverse predicate column" />

export const CLASSCARD_LAYOUT = <FormattedMessage
    id="CLASSCARD_LAYOUT"
    defaultMessage="Layout"
    description="Layout of the predicate" />

export const CLASSCARD_NO_LAYOUT = <FormattedMessage
    id="CLASSCARD_NO_LAYOUT"
    defaultMessage="No layout"
    description="Pradicate doesn't have layout" />

export const CLASSCARD_EDIT = <FormattedMessage
    id="CLASSCARD_EDIT"
    defaultMessage="Edit"
    description="Edit" />

export const CLASSCARD_DESCRIPTION = <FormattedMessage
    id="CLASSCARD_DESCRIPTION"
    defaultMessage="Description"
    description="Description of the predicate/relation" />

export const CLASSCARD_RELATION_TYPE = <FormattedMessage
    id="CLASSCARD_RELATION_TYPE"
    defaultMessage="Relation type"
    description="Description of the predicate relation type" />

export const CLASSCARD_CREATE_REVERSE_PREDICATE = <FormattedMessage
    id="CLASSCARD_CREATE_REVERSE_PREDICATE"
    defaultMessage="Create reverse predicate"
    description="Create reverse predicate" />

export const CLASSCARD_PACKAGE_SAVE_SUCCESS = <FormattedMessage
    id="CLASSCARD_PACKAGE_SAVE_SUCCESS"
    defaultMessage='Package "{name}" was successfully saved'
    description="Alert when package was successfully saved" />

export const CLASSCARD_PACKAGE_SAVE_ERROR = <FormattedMessage
    id="CLASSCARD_PACKAGE_SAVE_ERROR"
    defaultMessage='Error occured while saving package "{name}"'
    description="Alert when package wasn't saved due to error" />

export const CLASSCARD_CONFIRM_PACKAGE_REMOVE = <FormattedMessage
    id="CLASSCARD_CONFIRM_PACKAGE_REMOVE"
    defaultMessage='Confirm removal of package "{name}"'
    description="Text of confirmal popup at removal of package" />

export const CLASSCARD_PACKAGE_REMOVE_SUCCESS = <FormattedMessage
    id="CLASSCARD_PACKAGE_REMOVE_SUCCESS"
    defaultMessage='Package "{name}" was removed successfully'
    description="Alert when package was removed successfully" />

export const CLASSCARD_PACKAGE_REMOVE_ERROR = <FormattedMessage
    id="CLASSCARD_PACKAGE_REMOVE_ERROR"
    defaultMessage='Error occured while remove package "{name}"'
    description="Alert when package wasn't removed due to error" />


export const CLASSCARD_CLASS_SAVE_SUCCESS = <FormattedMessage
    id="CLASSCARD_CLASS_SAVE_SUCCESS"
    defaultMessage='Class "{name}" was successfully saved'
    description="Alert when class was successfully saved" />

export const CLASSCARD_CLASS_SAVE_ERROR = <FormattedMessage
    id="CLASSCARD_CLASS_SAVE_ERROR"
    defaultMessage='Error occured while saving class "{name}"'
    description="Alert when class wasn't saved due to error" />

export const CLASSCARD_CONFIRM_CLASS_REMOVE = <FormattedMessage
    id="CLASSCARD_CONFIRM_CLASS_REMOVE"
    defaultMessage='Confirm removal of class "{name}"'
    description="Text of confirmal popup at removal of class" />

export const CLASSCARD_CLASS_REMOVE_SUCCESS = <FormattedMessage
    id="CLASSCARD_CLASS_REMOVE_SUCCESS"
    defaultMessage='Class "{name}" was removed successfully'
    description="Alert when class was removed successfully" />

export const CLASSCARD_CLASS_REMOVE_ERROR = <FormattedMessage
    id="CLASSCARD_CLASS_REMOVE_ERROR"
    defaultMessage='Error occured while remove class "{name}"'
    description="Alert when class wasn't removed due to error" />

export const CLASSCARD_RELATION_SAVE_SUCCESS = <FormattedMessage
    id="CLASSCARD_RELATION_SAVE_SUCCESS"
    defaultMessage='Relation "{name}" was successfully saved'
    description="Alert when relation was successfully saved" />

export const CLASSCARD_RELATION_SAVE_ERROR = <FormattedMessage
    id="CLASSCARD_RELATION_SAVE_ERROR"
    defaultMessage='Error occured while saving relation "{name}"'
    description="Alert when relation wasn't saved due to error" />

export const CLASSCARD_CONFIRM_RELATION_REMOVE = <FormattedMessage
    id="CLASSCARD_CONFIRM_RELATION_REMOVE"
    defaultMessage="Confirm removal of {num} realtions"
    description="Text of confirmal popup at removal of relations" />

export const CLASSCARD_RELATION_REMOVE_SUCCESS = <FormattedMessage
    id="CLASSCARD_RELATION_REMOVE_SUCCESS"
    defaultMessage='Relation "{name}" was removed successfully'
    description="Alert when relation was removed successfully" />

export const CLASSCARD_RELATION_REMOVE_ERROR = <FormattedMessage
    id="CLASSCARD_RELATION_REMOVE_ERROR"
    defaultMessage='Error occured while remove relation "{name}"'
    description="Alert when relation wasn't removed due to error" />

export const CLASSCARD_RELATION_REMOVE_NONE = <FormattedMessage
    id="CLASSCARD_RELATION_REMOVE_NONE"
    defaultMessage='None of selected relations was valid'
    description="Alert when all selected relations for remove was invalid" />

export const CLASSCARD_PREDICATE_SAVE_SUCCESS = <FormattedMessage
    id="CLASSCARD_PREDICATE_SAVE_SUCCESS"
    defaultMessage='Predicate "{name}" was successfully saved'
    description="Alert when predicate was successfully saved" />

export const CLASSCARD_PREDICATE_SAVE_ERROR = <FormattedMessage
    id="CLASSCARD_PREDICATE_SAVE_ERROR"
    defaultMessage='Error occured while saving predicate "{name}"'
    description="Alert when predicate wasn't saved due to error" />

export const CLASSCARD_CONFIRM_PREDICATE_REMOVE = <FormattedMessage
    id="CLASSCARD_CONFIRM_PREDICATE_REMOVE"
    defaultMessage="Confirm removal of {num} predicates"
    description="Text of confirmal popup at removal of predicates" />

export const CLASSCARD_PREDICATE_REMOVE_SUCCESS = <FormattedMessage
    id="CLASSCARD_PREDICATE_REMOVE_SUCCESS"
    defaultMessage='Predicate "{name}" was removed successfully'
    description="Alert when predicate was removed successfully" />

export const CLASSCARD_PREDICATE_REMOVE_ERROR = <FormattedMessage
    id="CLASSCARD_PREDICATE_REMOVE_ERROR"
    defaultMessage='Error occured while remove predicate "{name}"'
    description="Alert when predicate wasn't removed due to error" />

export const CLASSCARD_CHILDREN_RECEIVE_ERROR = <FormattedMessage
    id="CLASSCARD_CHILDREN_RECEIVE_ERROR"
    defaultMessage='Error occured while downloading children'
    description="Alert when children weren't downloaded" />

export const CLASSCARD_RELATION_TYPE_ASSOCIATION = <FormattedMessage
    id="CLASSCARD_RELATION_TYPE_ASSOCIATION"
    defaultMessage='Association'
    description="Association relation" />

export const CLASSCARD_RELATION_TYPE_AGGREGATION = <FormattedMessage
    id="CLASSCARD_RELATION_TYPE_AGGREGATION"
    defaultMessage='Aggregation'
    description="Aggregation relation" />

export const CLASSCARD_RELATION_TYPE_COMPOSITION = <FormattedMessage
    id="CLASSCARD_RELATION_TYPE_COMPOSITION"
    defaultMessage='Composition'
    description="Composition relation" />

export const CLASSCARD_STORE_TYPE_STRING = <FormattedMessage
    id="CLASSCARD_STORE_TYPE_STRING"
    defaultMessage='String'
    description="String store type" />

export const CLASSCARD_STORE_TYPE_NUMBER = <FormattedMessage
    id="CLASSCARD_STORE_TYPE_NUMBER"
    defaultMessage='Number'
    description="Number store type" />

export const CLASSCARD_STORE_TYPE_BOOLEAN = <FormattedMessage
    id="CLASSCARD_STORE_TYPE_BOOLEAN"
    defaultMessage='Boolean'
    description="Boolean store type" />

export const CLASSCARD_STORE_TYPE_TIMESTAMP = <FormattedMessage
    id="CLASSCARD_STORE_TYPE_TIMESTAMP"
    defaultMessage='Timestamp'
    description="Timestamp store type" />

export const CLASSCARD_STORE_TYPE_FILE = <FormattedMessage
    id="CLASSCARD_STORE_TYPE_FILE"
    defaultMessage='File'
    description="File store type" />

export const CLASSCARD_STORE_TYPE_FRAGMENT = <FormattedMessage
    id="CLASSCARD_STORE_TYPE_FRAGMENT"
    defaultMessage='Fragment'
    description="Fragment store type" />

export const CLASSCARD_STORE_TYPE_DECIMAL = <FormattedMessage
    id="CLASSCARD_STORE_TYPE_DECIMAL"
    defaultMessage='Decimal'
    description="Decimal store type" />