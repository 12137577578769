import * as Action from '../constants/crontasks';

//Path to scripts
const CRON_TASKS_MAIN = "/rest/cron/";
const CRON_TASKS_LIST_URL = CRON_TASKS_MAIN + "list";
const CRON_TASKS_ENTITY_URL = CRON_TASKS_MAIN + "entity";
const CRON_TASKS_DELETE_ENTITY_URL = CRON_TASKS_MAIN + "delete/";


function parseDataToServer(data) {
    return {
        cron: [data.second, data.minute, data.hour, data.day, data.month, data.weekDay].join(" "),
        description: data.description,
        id: data.id,
        label: data.label,
        scriptId: data.scriptId,
        scriptPath: data.scriptPath
    };
}

function waitForScripts() {
    return {
        type: Action.WAIT_FOR_DATA,
        payload: null
    }
}

function plainUpdateScripts(data) {
    return {
        type: Action.RECEIVE_DATA,
        payload: { data }
    }
}

function receiveScriptsError(error) {
    return {
        type: Action.RECEIVE_DATA_ERROR,
        payload: { error }
    }
}

function saveScriptError(error) {
    return {
        type: Action.SAVE_SCRIPT_ERROR,
        payload: { error }
    }
}

function ajaxGetScripts(dispatch) {
    return $.getJSON(CRON_TASKS_LIST_URL, function (data) {
        dispatch(plainUpdateScripts(data));
    }).fail(function (error) {
        dispatch(receiveScriptsError(error));
    });
}

function ajaxSaveScript(dispatch, { task }) {
    task = parseDataToServer(task);
    return $.ajax({
        contentType: 'application/json',
        data: JSON.stringify(task),
        dataType: 'json',
        processData: false,
        type: 'POST',
        url: CRON_TASKS_ENTITY_URL
    }).done(function (data) {
        updateScripts()(dispatch);
    }).fail(function (error) {
        dispatch(saveScriptError(error));
    });
}

function ajaxDeleteRow(dispatch, { id }) {
    return $.ajax({
        contentType: 'application/json',
        data: null,
        dataType: 'json',
        processData: false,
        type: 'DELETE',
        url: CRON_TASKS_DELETE_ENTITY_URL + id
    }).done(function (data) {
    }).fail(function (error) {
        console.error("Can't delete row. Error: ", error);
    });
}


///////////
//Actions//
///////////

export function updateScripts() {
    return function (dispatch, getState) {
        dispatch(waitForScripts());
        ajaxGetScripts(dispatch);
    }
}

export function selectRow({ data, selected }) {
    return {
        type: Action.SELECT_ROW,
        payload: { data, selected }
    }
}

export function deleteRows({ selectedRows }) {
    return function (dispatch, getState) {
        let promises = [];
        for (let i = 0; i < selectedRows.length; ++i) {
            promises.push(ajaxDeleteRow(dispatch, { id: selectedRows[i].id }));
        }
        $.when.apply(null, promises).always(function () {
            updateScripts()(dispatch);
        })
    }
}

export function callConfigurePanel({ task }) {
    return {
        type: Action.CALL_CONFIGURE_PANEL,
        payload: { task }
    }
}

export function saveTask({ task }) {
    return function (dispatch, getState) {
        ajaxSaveScript(dispatch, { task }).done(function () {
            dispatch(closeConfigurePanel());
        })
    }
}

export function closeConfigurePanel() {
    return {
        type: Action.CLOSE_CONFIGURE_PANEL,
        payload: null
    }
}

export function callConfirmDialog({ ok, cancel }) {
    return {
        type: Action.CALL_CONFIRM_DIALOG,
        payload: { ok, cancel }
    }
}

export function closeConfirmDialog() {
    return {
        type: Action.CLOSE_CONFIRM_DIALOG,
        payload: null
    }
}

