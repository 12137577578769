import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../modal.jsx';
import DebounceInput from '../../debounceinput.jsx';
import { ERROR_INVALID_FORMAT, ERROR_RANGE_ERROR, ERROR_RANGE_INTERSECTION } from "../modal.jsx";

const numberRegExp = /^(\-|\+)?([0-9]+(\.[0-9]*)?)$/;


class NumberFilterModal extends React.Component {

    constructor(props) {
        super(props);
        this.handleFromNumberChange = this.handleFromNumberChange.bind(this);
        this.handleToNumberChange = this.handleToNumberChange.bind(this);
        this.filterClearHandler = this.filterClearHandler.bind(this);
        this.saveNewFilter = this.saveNewFilter.bind(this);

        this.state = {
            fromNumber: (typeof props.options.fromNumber != "undefined" && props.options.fromNumber != null) ? props.options.fromNumber : props.options.limits.min,
            toNumber: (typeof props.options.toNumber != "undefined" && props.options.toNumber != null) ? props.options.toNumber : props.options.limits.max
        };
    }

    handleFromNumberChange(newValue) {
        if (typeof newValue != "string") {
            newValue = "";
        }
        let value = newValue;
        value = value.replace(",", ".");
        if (!numberRegExp.test(value)) {
            this.setState({ fromNumber: newValue, fromError: ERROR_INVALID_FORMAT });
            return;
        }
        value = parseFloat(value);
        if (value < parseFloat(this.props.options.originalLimits.min)) { //check limits
            this.setState({ fromNumber: newValue, fromError: ERROR_RANGE_ERROR });
            return;
        }
        let toNumberValue = this.state.toNumber || this.props.options.limits.max;
        let toNumber = parseFloat(toNumberValue);
        if (value > toNumber) {
            this.setState({ fromNumber: newValue, fromError: ERROR_RANGE_INTERSECTION, toError: ERROR_RANGE_INTERSECTION });
            return;
        }
        if (this.state.toError == ERROR_RANGE_INTERSECTION) {
            this.setState({ fromNumber: newValue, fromError: null, toError: null });
        } else {
            this.setState({ fromNumber: newValue, fromError: null });
        }
    }

    handleToNumberChange(newValue) {
        if (typeof newValue != "string") {
            newValue = "";
        }
        let value = newValue;
        value = value.replace(",", ".");
        if (!numberRegExp.test(value)) {
            this.setState({ toNumber: newValue, toError: ERROR_INVALID_FORMAT });
            return;
        }
        value = parseFloat(value);
        if (value > parseFloat(this.props.options.originalLimits.max)) { //check limits
            this.setState({ toNumber: newValue, toError: ERROR_RANGE_ERROR });
            return;
        }
        let fromNumberValue = this.state.fromNumber || this.props.options.limits.min;
        let fromNumber = parseFloat(fromNumberValue);
        if (value < fromNumber) {
            this.setState({ toNumber: newValue, fromError: ERROR_RANGE_INTERSECTION, toError: ERROR_RANGE_INTERSECTION });
            return;
        }
        if (this.state.fromError == ERROR_RANGE_INTERSECTION) {
            this.setState({ toNumber: newValue, fromError: null, toError: null });
        } else {
            this.setState({ toNumber: newValue, toError: null });
        }
    }


    getTitle() {
        return (<FormattedMessage
            id="NPT_TABLE_NUMBER_FILTER_TITLE"
            defaultMessage="Please select range of numbers for field '{name}'"
            description="User should select range of numbers in filter" values={{ name: this.props.options.label }} />);
    }

    getBody() {
        let limits = this.props.options.limits;
        let fromNumber = this.state.fromNumber || this.props.min;
        if (this.state.fromNumber == '') { //Check empty because javascript defaults empty as false
            fromNumber = this.state.fromNumber;
        }
        let toNumber = this.state.toNumber || this.props.max;
        if (this.state.toNumber == '') { //Check empty because javascript defaults empty as false
            toNumber = this.state.toNumber;
        }

        let error = {
            background: "red"
        }

        return (<div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <DebounceInput
                        className="form-control"
                        editable={true}
                        valid={!this.state.fromError}
                        style={this.state.fromError ? error : null}
                        value={fromNumber}
                        format={this.props.format}
                        change={this.handleFromNumberChange} >
                        <div className="input-group-prepend input-group-text">
                            <FormattedMessage
                                id="NPT_TABLE_NUMBER_FILTER_FROM"
                                defaultMessage="From: "
                                description="'From' word in Number filter" />
                        </div>
                    </DebounceInput>
                </div>
                <div className="col-md-6">
                    <DebounceInput
                        className="form-control"
                        editable={true}
                        valid={!this.state.toError}
                        style={this.state.toError ? error : null}
                        value={toNumber}
                        format={this.props.options.format}
                        change={this.handleToNumberChange} >
                        <div className="input-group-prepend input-group-text">
                            <FormattedMessage
                                id="NPT_TABLE_NUMBER_FILTER_TO"
                                defaultMessage="To: "
                                description="'To' word in Number filter" />
                        </div>
                    </DebounceInput>
                </div>
            </div>
        </div>);
    }

    getFooterButtons() {
        return [{
            onClick: this.filterClearHandler,
            className: "btn btn-danger",
            message: <FormattedMessage
                id="NPT_TABLE_CLEAR_FILTER"
                defaultMessage="Clear filter"
                description="User could clear applied filter" />
        }];
    }

    filterClearHandler({ onOk }) {
        this.cleared = true;
        onOk();
    }

    saveNewFilter() {
        if (this.cleared) {
            return { cleared: true };
        }
        if (this.state.fromError || this.state.toError) {
            return null;
        }
        return { fromNumber: this.state.fromNumber, toNumber: this.state.toNumber };
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for NumberFilter*/
            title={this.getTitle()}
            body={this.getBody()}
            footerButtons={this.getFooterButtons()}
            /**Specific callbacks for NumberFilter*/
            result={this.saveNewFilter} />);
    }
}

export default NumberFilterModal;


