import React from 'react';

class Link extends React.Component {

    constructor(props) {
        super(props);
    }

    getRef() {
        return `${this.props.contextPath}${this.props.node.options.ref}`;
    }

    handleClick(ref, reactEvent) {
        reactEvent.preventDefault();
        this.props.linkClick(ref, this.props.node.options.filter);
    }

    getIcon() {
        const icon = this.props.node.options.icon || "fa-link";
        if (!icon || icon == "none") {
            return null;
        }
        return <i className={`fa ${icon} fa-fw`} aria-hidden="true"></i>
    }

    shouldComponentUpdate(nextProps) {
        return this.props.contextPath != nextProps.contextPath && this.props.node != nextProps.node;
    }

    render() {
        const bg = this.props.node.options.bg || "primary";
        const ref = this.getRef();
        return <a className={`npt-objectcard-link btn btn-${bg}`} href={ref} onClick={this.handleClick.bind(this, ref)}>
            {this.getIcon()}
            <span>{this.props.node.label}</span>
        </a>
    }
}

export default Link;