import React from "react";
import { FormattedMessage } from 'react-intl';
import { TYPE_CATEGORY, TYPE_VIDEO } from "../../constants/player";
import Player from "./player.jsx";

export default class Gallery extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            playerData: null,
            playerTime: null,
            isTimecodesVisible: true
        };

        this.changeVideo = this.changeVideo.bind(this);
        this.toggleTimecodes = this.toggleTimecodes.bind(this);
        this.changeTime = this.changeTime.bind(this);
    }

    changeVideo(videoData) {
        this.props.changeHash({ [this.props.id]: videoData.src });
        this.setState(Object.assign({}, this.state, { playerData: videoData }));
    }

    toggleTimecodes() {
        this.setState(Object.assign({}, this.state, { isTimecodesVisible: !this.state.isTimecodesVisible }));
    }

    changeTime(time) {
        this.setState(Object.assign({}, this.state, { playerTime: time }));
    }

    componentDidMount() {
        const initialSrc = this.props.hashParams ? this.props.hashParams[this.props.id] : null;
        if (!initialSrc) {
            return;
        }
        if (this.props.data.videoStore[initialSrc]) {
            this.changeVideo(this.props.data.videoStore[initialSrc]);
        }
    }

    render() {
        return <div className="d-flex flex-fill flex-row overflow-hidden">
            <PlayerContainer
                data={this.state.playerData}
                isTimecodesVisible={this.state.isTimecodesVisible}
                time={this.state.playerTime}
                toggleTimecodes={this.toggleTimecodes}
                changeTime={this.changeTime}
            />
            <CategoryList
                items={this.props.data.rootItems}
                changeVideo={this.changeVideo}
                playerData={this.state.playerData}
            />
        </div>;
    }
}

class PlayerContainer extends React.PureComponent {
    render() {
        return <div className="player-container d-flex flex-fill flex-column">
            <div className="d-flex flex-fill overflow-hidden">
                <div className="player-video d-flex flex-fill">
                    <Video data={this.props.data} time={this.props.time} />
                </div>
                <TimecodesContainer
                    data={this.props.data}
                    visible={this.props.isTimecodesVisible}
                    toggleTimecodes={this.props.toggleTimecodes}
                    changeTime={this.props.changeTime}
                />
            </div>
            <PlayerLabel data={this.props.data} />
            <PlayerDescription data={this.props.data} />
        </div>;
    }
}

class PlayerLabel extends React.PureComponent {
    render() {
        if (!this.props.data) {
            return null;
        }
        return <div className="player-label">{this.props.data.label}</div>;
    }
}

class PlayerDescription extends React.PureComponent {
    render() {
        if (!this.props.data) {
            return null;
        }
        return <div className="player-description">{this.props.data.description}</div>;
    }
}

class Video extends React.PureComponent {
    render() {
        if (!this.props.data || !this.props.data.src) {
            return <VideoPlaceholder />;
        }
        return <Player width="100%" height="100%" initialSrc={this.props.data.src} initialTime={this.props.time} />;
    }
}

class TimecodesContainer extends React.PureComponent {
    render() {
        if (!this.props.data || !this.props.data.timecodes || !this.props.data.timecodes.length) {
            return null;
        }
        return <div className="player-timecodes d-flex flex-row">
            <div className={"player-timecodes-toggler" + (this.props.visible ? "" : " closed")} onClick={this.props.toggleTimecodes}>
                <span><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
            </div>
            <div className={"player-timecodes-content" + (this.props.visible ? "" : " hidden")}>
                {this.props.data.timecodes.map((timecode) => <Timecode data={timecode} changeTime={this.props.changeTime} />)}
            </div>
        </div>
    }
}

class Timecode extends React.PureComponent {

    changeTime(event) {
        event.preventDefault();
        this.props.changeTime(this.props.data.time);
    }

    render() {
        return <div className="player-timecode" onClick={this.changeTime.bind(this)}>
            <a>
                <span className="timecode-time">{this.props.data.time}</span>
                <span className="timecode-label">{this.props.data.label}</span>
            </a>
        </div>
    }
}

class VideoPlaceholder extends React.PureComponent {
    render() {
        return <div className="video-placeholder">
            <span class="info-icon">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
            </span>
            <span>
                <FormattedMessage
                    id="NPT_PLAYER_PLACEHOLDER"
                    defaultMessage="Select video to view"
                    description="Text of video placeholder" />
            </span>
        </div>
    }
}

class CategoryList extends React.PureComponent {
    render() {
        return <div className="categories-container">
            <div className="categories-content">
                {this.props.items.map((item) => <Item data={item} allowed={{ [TYPE_CATEGORY]: true }} changeVideo={this.props.changeVideo} playerData={this.props.playerData} />)}
            </div>
        </div>;
    }
}


class Item extends React.PureComponent {
    render() {
        if (this.props.allowed && !this.props.allowed[this.props.data.type]) {
            return null;
        }
        switch (this.props.data.type) {
            case TYPE_CATEGORY:
                return <Category data={this.props.data} changeVideo={this.props.changeVideo} playerData={this.props.playerData} />;
            case TYPE_VIDEO:
                return <VideoPreview data={this.props.data} changeVideo={this.props.changeVideo} playerData={this.props.playerData} />;
            default:
                return null;
        }
    }
}

class Category extends React.PureComponent {
    render() {
        return [
            <div className="category-label">{this.props.data.label}</div>,
            <div className="category-content">
                {this.props.data.children.map((item) => <Item data={item} allowed={{ [TYPE_VIDEO]: true }} changeVideo={this.props.changeVideo} playerData={this.props.playerData} />)}
            </div>
        ];
    }
}

class VideoPreview extends React.PureComponent {

    constructor(props) {
        super(props);

        this.changeVideo = this.changeVideo.bind(this);
    }

    changeVideo() {
        this.props.changeVideo(this.props.data);
    }

    render() {
        let className = "preview-container";
        if (this.props.playerData && this.props.playerData.src == this.props.data.src) {
            className += " active";
        }
        return <div className={className} onClick={this.changeVideo}>
            <div className="preview-video">
                <video src={this.props.data.src} />
            </div>
            <div className="preview-label">{this.props.data.label}</div>
        </div>;
    }
}