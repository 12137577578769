import * as Action from '../constants/modal';

/**This is hack: because we cannot pass callbacks to reducer (they are not serializable so we store them in global map) */
window.__nptModalCallbacks = window.__nptModalCallbacks || {};
var modalCallbacks = window.__nptModalCallbacks;

export function openModal(id, type, options, okCallback, cancelCallback, closeCallback) {
    modalCallbacks[id] = { okCallback, cancelCallback, closeCallback };
    return {
        type: Action.OPEN_MODAL,
        payload: { id, type, options }
    };
}

export function updateModal(id, options) {
    return {
        type: Action.UPDATE_MODAL,
        payload: { id, options }
    };
}

function runCallback(id, status, result) {
    let callbacks = modalCallbacks[id];
    if (!callbacks) {
        return;
    }
    //remove from map and call callbacks
    delete modalCallbacks[id];
    if (status == Action.MODAL_STATUS_OK) {
        if (callbacks.okCallback) {
            callbacks.okCallback(result);
        }
    } else if (status == Action.MODAL_STATUS_CANCEL) {
        if (callbacks.cancelCallback) {
            callbacks.cancelCallback();
        }
    } else if (status == Action.MODAL_STATUS_CLOSE) {
        if (callbacks.closeCallback) {
            callbacks.closeCallback();
        }
    }
}

export function closeModal(id, status, result) {
    runCallback(id, status, result);
    return {
        type: Action.CLOSE_MODAL,
        payload: { id }
    };
}