const ATTR_DIVIDER = "cim-divider";
const CIM_DIVIDER_LEFT = "left";
const CIM_DIVIDER_RIGHT = "right";

function toNumber(value) {
    if (typeof value == "number") {
        return value;
    }
    if (typeof value == "string") {
        if (value.indexOf("%") >= 0) {
            value = value.replace("%", "");
        } else if (value.indexOf("px", "") >= 0) {
            value = value.replace("px", "");
        }
    }
    value = Number(value);
    if (isNaN(value)) {
        return 0;
    }
    return value;
}

function getSizeMap(width, map, length) {
    let sizeMap = map.split(",");
    let flexibleMap = [];
    let reserved = 0;
    let freeWidth = 0;

    if (sizeMap.length > length) {
        sizeMap.slice(sizeMap.length, sizeMap.length - length);
    }

    for (let i = sizeMap.length; i < length; ++i) {
        sizeMap[i] = "*";
    }

    for (let i = 0; i < sizeMap.length; ++i) {
        if (sizeMap[i].indexOf("*") >= 0) {
            flexibleMap.push(i);
            continue;
        } else if (sizeMap[i].indexOf("%") >= 0) {
            sizeMap[i] = width * toNumber(sizeMap[i]) / 100;
        } else {
            sizeMap[i] = toNumber(sizeMap[i]);
        }
        reserved += sizeMap[i];
    }

    let flexibleNum = flexibleMap.length;
    if (flexibleNum > 0) {
        if (reserved < width) {
            freeWidth = width - reserved;
        }

        for (let idx of flexibleMap) {
            sizeMap[idx] = freeWidth / flexibleNum;
            reserved += sizeMap[idx];
        }
    }

    if (reserved != width) {
        for (let i = 0; i < sizeMap.length; ++i) {
            sizeMap[i] = sizeMap[i] / reserved * width;
        }
    }
    return sizeMap;
}

function resize(element1, element2, sizeChange, vertical) {
    if (sizeChange == 0) {
        return false;
    }

    let size1, size2;
    if (vertical) {
        size1 = element1.width();
        size2 = element2.width();
    } else {
        size1 = element1.height();
        size2 = element2.height();
    }

    let cursorOverflow = 0;
    let divider = {
        addCollapse: null,
        removeCollapse: null
    }
    if (sizeChange < 0) {
        if (size1 == 0) {
            return false;
        }
        if (size2 == 0) {
            divider.removeCollapse = CIM_DIVIDER_RIGHT;
        }
        size1 = size1 + sizeChange;
        if (size1 <= 0) {
            cursorOverflow = size1;
            sizeChange = sizeChange - size1;
            size1 = 0;
            divider.addCollapse = CIM_DIVIDER_LEFT;
        }
        size2 = size2 - sizeChange;
        if (vertical) {
            element1.width(size1);
            element2.width(size2);
        } else {
            element1.height(size1);
            element2.height(size2);
        }
    } else {
        if (size2 == 0) {
            return false;
        }
        if (size1 == 0) {
            divider.removeCollapse = CIM_DIVIDER_LEFT;
        }
        size2 = size2 - sizeChange;
        if (size2 <= 0) {
            cursorOverflow = -size2;
            sizeChange = sizeChange + size2;
            size2 = 0;
            divider.addCollapse = CIM_DIVIDER_RIGHT;
        }
        size1 = size1 + sizeChange;
        if (vertical) {
            element2.width(size2);
            element1.width(size1);
        } else {
            element2.height(size2);
            element1.height(size1);
        }
    }
    return { cursorOverflow, divider };
}

function clearSelection() {
    if (window.getSelection) {
        window.getSelection().removeAllRanges();
    } else { // старый IE
        document.selection.empty();
    }
}

//Initialization function
export default function (dispatcher) {
    //Register components
    dispatcher.registerComponent(ATTR_DIVIDER, (elm) => {
        let $elm = $(elm);
        $elm.addClass("cim-divider");
        let childrens = $elm.children();
        let containers = [];
        let collapsers = [];

        let vertical = $elm.attr("type") != "horizontal";
        let dividerSize = 5;
        let elementsSize;
        let sizeMap;
        let nonCollapsedSize;
        if (vertical) {
            elementsSize = $elm.width() - dividerSize * (childrens.length - 1);
        } else {
            elementsSize = $elm.height() - dividerSize * (childrens.length - 1);
        }
        sizeMap = getSizeMap(elementsSize, $elm.attr("size-map"), childrens.length);
        if ($elm.attr("default-size-map")) {
            nonCollapsedSize = getSizeMap(elementsSize, $elm.attr("default-size-map"), childrens.length);
        } else {
            nonCollapsedSize = sizeMap.slice();
        }

        $(window).on("resize.cimdivider", function (event) {
            if (vertical) {
                elementsSize = $elm.width() - dividerSize * (childrens.length - 1);
            } else {
                elementsSize = $elm.height() - dividerSize * (childrens.length - 1);
            }
            sizeMap = getSizeMap(elementsSize, sizeMap.join(","), childrens.length);
            nonCollapsedSize = getSizeMap(elementsSize, nonCollapsedSize.join(","), childrens.length);
            childrens.each(function (index, children) {
                let container = containers[index];
                if (vertical) {
                    container.width(sizeMap[index]);
                } else {
                    container.height(sizeMap[index]);
                }
            });
            $(window).trigger("resize.cimfiller");
        });

        let documentBinded = false;
        childrens.each(function (index, children) {
            let container;
            if (typeof containers[index] == "undefined") {
                container = $("<div class='cim-divider-element'></div>");
                containers[index] = container;
            } else {
                container = containers[index];
            }
            if (vertical) {
                container.addClass("cim-divider-element-vertical");
                container.width(sizeMap[index]);
            } else {
                container.addClass("cim-divider-element-horizontal");
                container.height(sizeMap[index]);
            }
            let innerContainer = $("<div class='cim-divider-element-inner'></div>");
            innerContainer.append(children);
            container.append(innerContainer);
            $elm.append(container);
            if (index != childrens.length - 1) {
                let collapser = $("<div class='cim-divider-line-collapser'></div>");
                collapsers[index] = collapser;
                let collapserLeft = $("<div class='cim-divider-line-collapser-side cim-divider-line-collapser-left'></div>");
                let collapserMiddle = $("<div class='cim-divider-line-collapser-middle'></div>");
                let collapserRight = $("<div class='cim-divider-line-collapser-side cim-divider-line-collapser-right'></div>");
                collapser.append(collapserMiddle).append(collapserLeft).append(collapserRight);
                let divider = $("<div class='cim-divider-line'></div>");
                divider.append(collapser);
                if (vertical) {
                    divider.addClass("cim-divider-line-vertical");
                    divider.width(dividerSize);
                    collapserMiddle.width(dividerSize);
                    collapserLeft.append("<div><div>&#9664</div></div>");
                    collapserRight.append("<div><div>&#9654</div></div>");
                } else {
                    divider.addClass("cim-divider-line-horizontal");
                    divider.height(dividerSize);
                    collapserMiddle.height(dividerSize);
                    collapserLeft.append("<div><div>&#9650</div></div>");
                    collapserRight.append("<div><div>&#9660</div></div>");
                }
                containers[index + 1] = $("<div class='cim-divider-element'></div>");

                let collapseToLeftClass = "cim-divider-line-collapsed-to-left";
                let collapseToRightClass = "cim-divider-line-collapsed-to-right";
                let handleResizeResults = function (result, change) {
                    let sizeChange = change - result.cursorOverflow;
                    sizeMap[index] += sizeChange;
                    sizeMap[index + 1] -= sizeChange;
                    if (result.divider.addCollapse != null) {
                        switch (result.divider.addCollapse) {
                            case CIM_DIVIDER_LEFT:
                                collapser.addClass(collapseToLeftClass);
                                if (index != 0) {
                                    collapsers[index - 1].addClass(collapseToRightClass);
                                }
                                break;
                            case CIM_DIVIDER_RIGHT:
                                collapser.addClass(collapseToRightClass);
                                if (index != collapsers.length - 1) {
                                    collapsers[index + 1].addClass(collapseToLeftClass);
                                }
                                break;
                        }
                    }
                    if (result.divider.removeCollapse != null) {
                        switch (result.divider.removeCollapse) {
                            case CIM_DIVIDER_LEFT:
                                collapser.removeClass(collapseToLeftClass);
                                if (index != 0) {
                                    collapsers[index - 1].removeClass(collapseToRightClass);
                                }
                                break;
                            case CIM_DIVIDER_RIGHT:
                                collapser.removeClass(collapseToRightClass);
                                if (index != collapsers.length - 1) {
                                    collapsers[index + 1].removeClass(collapseToLeftClass);
                                }
                                break;
                        }
                    }
                    $(window).trigger("resize.cimfiller");
                }

                let tryToCollapse = function (element, event, change) {
                    let timeout = window.setTimeout(function () {
                        element.off("mouseup");
                    }, 300);
                    element.on("mouseup", function (event) {
                        event.preventDefault();
                        event.stopPropagation();
                        event.stopImmediatePropagation();

                        clearTimeout(timeout);

                        element.off("mouseup");
                        $(document).off(".cimdivider");
                        documentBinded = false;

                        let result = resize(containers[index], containers[index + 1], change, vertical);
                        if (result) {
                            handleResizeResults(result, change);
                        }
                    });
                }

                collapserLeft.on("mousedown", function (event) {
                    let change;
                    if (nonCollapsedSize[index] != sizeMap[index]) {
                        change = nonCollapsedSize[index] - sizeMap[index];
                    } else {
                        change = -sizeMap[index];
                    }
                    tryToCollapse(collapserLeft, event, change);
                });

                collapserRight.on("mousedown", function (event) {
                    let change;
                    if (nonCollapsedSize[index] != sizeMap[index]) {
                        change = nonCollapsedSize[index];
                    } else {
                        change = sizeMap[index + 1];
                    }
                    tryToCollapse(collapserRight, event, change);
                });

                divider.on("mousedown", function (event) {
                    clearSelection();
                    if (documentBinded) {
                        $(document).trigger("mouseup.cimdivider");
                    }
                    let lastCursorPosition = vertical ? event.clientX : event.clientY;
                    $(document).on("mousemove.cimdivider", function (event) {
                        clearSelection();
                        let currentCursorPosition = vertical ? event.clientX : event.clientY;
                        let change = currentCursorPosition - lastCursorPosition;
                        let result = resize(containers[index], containers[index + 1], change, vertical);
                        if (result) {
                            lastCursorPosition = currentCursorPosition - result.cursorOverflow;
                            handleResizeResults(result, change);
                            nonCollapsedSize[index] = sizeMap[index];
                            nonCollapsedSize[index + 1] = sizeMap[index + 1];
                        }
                    });
                    $(document).on("mouseup.cimdivider", function (event) {
                        $(document).off(".cimdivider");
                        documentBinded = false;
                        $(window).trigger("resize.cimfiller");
                    });
                    documentBinded = true;
                })

                $elm.append(divider);
            }
        });

        /* Trigger resize event to update fillers */
        $(window).trigger("resize.cimfiller");
        return null;
    });
};

