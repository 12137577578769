import { FormattedMessage } from 'react-intl';

//Global periodic scripts state path
export const EVENTTASKS = "eventtasks";

//Common values
export const LOCAL_SERVER_KEY = "__local__";

//Actions
export const SEND_EVENTS_LOADING = "@@EVENTTASKS_SEND_EVENTS_LOADING";
export const SEND_EVENTS_DATA = "@@EVENTTASKS_SEND_EVENTS_DATA";
export const SEND_EVENTS_ERROR = "@@EVENTTASKS_SEND_EVENTS_ERROR";

export const SEND_SCRIPTS_DATA = "@@EVENTTASKS_SEND_SCRIPTS_DATA";
export const SEND_SCRIPTS_ERROR = "@@EVENTTASKS_SEND_SCRIPTS_ERROR";

export const SEND_SOURCES_DATA = "@@EVENTTASKS_SEND_SOURCES_DATA";
export const SEND_SOURCES_ERROR = "@@EVENTTASKS_SEND_SOURCES_ERROR";

export const SEND_SINGLE_EVENT = "@@EVENTTASKS_SEND_SINGLE_EVENT";
export const SEND_DELETED_EVENT = "@@EVENTTASKS_SEND_DELETED_EVENT";

export const SELECT_ROW = "@@EVENTTASKS_SELECT_ROW";

//Formatted Messages
export const EVENT_TASKS_LOCAL_SERVER = <FormattedMessage
    id="EVENT_TASKS_LOCAL_SERVER"
    defaultMessage="Local"
    description="Local server" />

export const EVENT_TASKS_CHANGE_TITLE = <FormattedMessage
    id="EVENT_TASKS_CHANGE_TITLE"
    defaultMessage="Change event"
    description="Title of event changing modal" />

export const EVENT_TASKS_ADD_TITLE = <FormattedMessage
    id="EVENT_TASKS_ADD_TITLE"
    defaultMessage="Add event"
    description="Title of event adding modal" />

export const EVENT_TASKS_CONFIRM_DELETION_TITLE = <FormattedMessage
    id="EVENT_TASKS_CONFIRM_DELETION_TITLE"
    defaultMessage="Confirm deletion"
    description="Title of confirm deletion modal" />

export const EVENT_TASKS_CONFIRM_DELETION_BODY = <FormattedMessage
    id="EVENT_TASKS_CONFIRM_DELETION_BODY"
    defaultMessage="Confirm deletion of {count} events"
    description="Body of confirm deletion modal" />

export const EVENT_TASKS_ADD_SUCCESS = <FormattedMessage
    id="EVENT_TASKS_ADD_SUCCESS"
    defaultMessage="Event task was successfully added"
    description="Alert of succesfull event task adding" />

export const EVENT_TASKS_SAVE_SUCCESS = <FormattedMessage
    id="EVENT_TASKS_SAVE_SUCCESS"
    defaultMessage="Event task was successfully saved"
    description="Alert of succesfull event task saving" />

export const EVENT_TASKS_SAVE_ERROR = <FormattedMessage
    id="EVENT_TASKS_SAVE_ERROR"
    defaultMessage="Error occured during event task saving"
    description="Alert of error occured on event task saving" />

export const EVENT_TASKS_DELETE_SUCCESS = <FormattedMessage
    id="EVENT_TASKS_DELETE_SUCCESS"
    defaultMessage="Event task was successfully deleted"
    description="Alert of succesfull event task deletion" />

export const EVENT_TASKS_DELETE_ERROR = <FormattedMessage
    id="EVENT_TASKS_DELETE_ERROR"
    defaultMessage="Error occured during event task deletion"
    description="Alert of error occured on event task deletion" />