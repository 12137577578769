import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ModalLibrary from '../modals.jsx';
import { MODAL } from '../../constants/modal.js'
import { closeModal } from '../../actions/modal.js'

class ModalStack extends React.Component {

    constructor(props) {
        super(props);
    }

    createModal(modal) {
        const Modal = ModalLibrary[modal.type];
        if (!Modal) {
            return null;
        }
        return (<Modal key={modal.id} id={modal.id} options={modal.options} closeModal={this.props.closeModal} />);
    }

    /* Bootstrap modal component can't handle multiple modals.
     * When top modal was closed bootstrap remove "modal-open" class from body and hide scrolling bar, so we add it back manually. */
    componentDidUpdate(prevProps) {
        if (this.props.modalList.length                                     //If we have any modals
            && prevProps.modalList.length > this.props.modalList.length     //If modal was closed
            && $("body").attr("class").indexOf("modal-open") == -1) {       //If "modal-open" class was removed from body
            $("body").addClass("modal-open");
        }
    }

    render() {
        //Hide stack div
        if (this.props.modalList.length == 0) { //Modals from redux
            return null;
        }
        return (<div>
            {this.props.modalList.map((modal) => this.createModal(modal))}
        </div>);
    }
}

export default connect((globalState) => { //mapStateToProps
    return globalState[MODAL]
}, (dispatch) => { //mapDispatchToProps
    return {
        closeModal: function (id, status, result) {
            dispatch(closeModal(id, status, result));
        }
    }
})(ModalStack);


