import { FormattedMessage } from 'react-intl';

//Global tree state path
export const SRCTREE = "srctree";

//Common consts
export const allowedNodeSymbols = "a-zA-Zа-яА-Я0-9_\\-";


//Actions
export const WAIT_FOR_TREE = "@@SRCTREE_WAIT_FOR_TREE";
export const TOGGLE_SUBTREE = "@@SRCTREE_TOGGLE_SUBTREE";
export const RECEIVE_TREE = "@@SRCTREE_RECEIVE_TREE";
export const RECEIVE_TREE_ERROR = "@@SRCTREE_RECEIVE_TREE_ERROR";
export const UPDATE_NODE = "@@SRCTREE_UPDATE_NODE";
export const OPEN_NODE = "@@SRCTREE_OPEN_NODE";
export const ADD_DIRECTORY = "@@SRCTREE_ADD_DIRECTORY";
export const ADD_FILE = "@@SRCTREE_ADD_FILE";
export const REMOVE_FILE = "@@SRCTREE_REMOVE_FILE";
export const RENAME_FILE = "@@SRCTREE_RENAME_FILE";
export const FIX_CAPTION = "@@SRCTREE_FIX_CAPTION";
export const MOVE_FILE = "@@SRCTREE_MOVE_FILE";

export const EDIT_NODE_START = "@@SRCTREE_EDIT_NODE_START";
export const EDIT_NODE_CANCEL = "@@SRCTREE_EDIT_NODE_CANCEL";

export const SEARCH_CODE = "@@SRCTREE_SEARCH_CODE";


//////////////////////
//Formatted messages//
//////////////////////
export const SRC_TREE_SEARCH = <FormattedMessage
    id="SRC_TREE_SEARCH"
    defaultMessage="Search in tree"
    description="Tree search popup title" />

export const SRC_TREE_SEARCH_ERROR = <FormattedMessage
    id="SRC_TREE_SEARCH_ERROR"
    defaultMessage="Error occured on search"
    description="Error occured on search" />

export const SRC_TREE_IMPORT = <FormattedMessage
    id="SRC_TREE_IMPORT"
    defaultMessage="Import tree."
    description="Import tree popup title." />

export const SRC_TREE_CONFIRM_COPY_NODE = <FormattedMessage
    id="SRC_TREE_CONFIRM_COPY_NODE"
    defaultMessage="Confirm copy of node to directory: '{path}'"
    description="Text of copy confirm popup" />

export const SRC_TREE_CONFIRM_MOVE_NODE = <FormattedMessage
    id="SRC_TREE_CONFIRM_MOVE_NODE"
    defaultMessage="Confirm move of node to directory: '{path}'"
    description="Text of move confirm popup" />

export const SRC_TREE_SEARCH_PLACEHOLDER = <FormattedMessage
    id="SRC_TREE_SEARCH_PLACEHOLDER"
    defaultMessage="Search..."
    description="Placeholder of search input" />