import { DATA_MAIN_ID } from './constants/objectcard';

import { OBJECTCARD } from './constants/objectcard';
import objectcard from './reducers/objectcard';

import _ObjectCard from './components/objectcard/objectcard.jsx';
import _PrintCard from './components/objectcard/printcard.jsx';

import { FormattedMessage } from 'react-intl';
import { connectObjectCard, connectPrintCard } from './services/objectcard';

const OBJECTCARD_UPLOAD_FAILED = (<FormattedMessage
    id="OBJECTCARD_UPLOAD_FAILED"
    defaultMessage="Failed to upload files to server"
    description="Inform user that files were not uploaded to server" />);

const OBJECTCARD_SAVE_FAILED = (<FormattedMessage
    id="OBJECTCARD_SAVE_FAILED"
    defaultMessage="Failed to save object"
    description="Inform user that object was not saved" />);

const OBJECTCARD_LOCK_FAILED = (<FormattedMessage
    id="OBJECTCARD_LOCK_FAILED"
    defaultMessage="Failed obtain lock to subject"
    description="Inform user that object lock was not obtained" />);

const OBJECTCARD_LINK_FAILED = (<FormattedMessage
    id="OBJECTCARD_LINK_FAILED"
    defaultMessage="Failed obtain subject to make link"
    description="Inform user that subject was not obtained to make link with" />);

const OBJECTCARD_SAVE_CHANGES_TITLE = (<FormattedMessage
    id="OBJECTCARD_SAVE_CHANGES_TITLE"
    defaultMessage="Save changes"
    description="Title of save changes dialog" />);

const OBJECTCARD_SAVE_CHANGES_BODY = (<FormattedMessage
    id="OBJECTCARD_SAVE_CHANGES_BODY"
    defaultMessage="Save changes to object {name}?"
    description="Ask user to save changes to object" />);

const OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW_TITLE = (<FormattedMessage
    id="OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW_TITLE "
    defaultMessage="Confirm action"
    description="Confirm action title" />);

const OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW = (<FormattedMessage
    id="OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW "
    defaultMessage="Please confirm the deletion of row in table"
    description="User should confirm that row will be deleted in the table" />);

const ATTR_OBJECTCARD = "cim-objectcard";
const ATTR_OBJECTCARD_PRINT = "cim-objectcard-print";

const ATTR_CLOSE_ON_READY = "cim-objectcard-close-on-ready";

const ATTR_PREFIX = "cim-objectcard-prefix";
const ATTR_RDF_ID = "cim-objectcard-rdf-id";

const ATTR_CLASS_NAME = "cim-objectcard-class-name";
const ATTR_PARENT = "cim-objectcard-parent";
const ATTR_PARENT_REF = "cim-objectcard-parent-ref";
const ATTR_PROTOTYPE = "cim-objectcard-prototype";

function checkAttr(elm, name) {
    let value = $(elm).attr(name);
    if (!(typeof value == 'string')) {
        return null;
    }
    value = $.trim(value);
    if (value.length == 0) {
        return null;
    }
    return value;
}

export const ObjectCard = connectObjectCard(_ObjectCard);
export const PrintCard = connectPrintCard(_PrintCard);

if (typeof NPTPlatform != 'undefined' && typeof NPTPlatform.dispatcher != 'undefined') {
    
}

export default (dispatcher) => {
    //Register reducer
    dispatcher.registerReducer(OBJECTCARD, objectcard);
    //Register components
    dispatcher.registerComponent(ATTR_OBJECTCARD, (elm) => {
        let id = checkAttr(elm, ATTR_OBJECTCARD);
        if (!id) {
            id = DATA_MAIN_ID;
        }
        const closeOnReady = checkAttr(elm, ATTR_CLOSE_ON_READY)
        let className = checkAttr(elm, ATTR_CLASS_NAME);
        let options = null;
        if (className) { //create action
            options = {
                className,
                parent: checkAttr(elm, ATTR_PARENT),
                parentRef: checkAttr(elm, ATTR_PARENT_REF),
                _prototype: checkAttr(elm, ATTR_PROTOTYPE),
                closeOnReady: closeOnReady && closeOnReady == "true"
            };
        } else {
            let rdfId = checkAttr(elm, ATTR_RDF_ID);
            if (rdfId) {
                options = {
                    rdfId,
                    prefix: checkAttr(elm, ATTR_PREFIX),
                    closeOnReady: closeOnReady && closeOnReady == "true"
                }
            }
        }

        return (<ObjectCard id={id} options={options} />);
    });
    dispatcher.registerComponent(ATTR_OBJECTCARD_PRINT, (elm) => {
        let objectId = $(elm).attr(ATTR_OBJECTCARD_PRINT);
        objectId = $.trim(objectId);
        return (<PrintCard id={DATA_MAIN_ID} objectId={objectId} />);
    });
}