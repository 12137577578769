import React from 'react';
import LoginPanel from '../../login/loginpanel.jsx';
import { MODAL_STATUS_OK } from '../../../constants/modal.js';

class LoginModal extends React.Component {

    constructor(props) {
        super(props);
        this.onSuccess = this.onSuccess.bind(this);
    }

    onSuccess(info) {
        $(this.modal).modal('hide');
    }

    componentDidMount() {
        $(this.modal).modal({ backdrop: "static" });
    }

    componentWillUnmount() {
        $(this.modal).off();
    }

    render() {
        const loginInfo = this.props.options.loginInfo;
        const contextPath = this.props.options.contextPath;
        return (<div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog" ref={(modal) => { this.modal = modal; }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <LoginPanel {...loginInfo} contextPath={contextPath} rest={true} onSuccess={this.onSuccess} />
                </div>
            </div>
        </div>);
    }
}

export default LoginModal;


