import { FormattedMessage } from "react-intl";

//Global tree state path
export const NAVTREE = "navtree";

/////////////////////
//Node state status//
/////////////////////

//For leaf nodes
export const STATUS_LEAF = "leaf"; //Means this node is leaf (do not need to load children)
//For branch nodes
export const STATUS_NOT_LOADED = "not-loaded"; //Means children should be loaded
export const STATUS_LOADING = "loading"; //Means children are loading
export const STATUS_ERROR = "error"; //Means children load error
export const STATUS_READY = "ready"; //Means children are ready


//Actions
export const TOGGLE_SUBTREE = "@@NAVTREE_TOGGLE_SUBTREE";
export const TOGGLE_FILTER = "@@NAVTREE_TOGGLE_FILTER";
export const RECEIVE_INFO = "@@NAVTREE_RECEIVE_INFO";
export const RECEIVE_TREE = "@@NAVTREE_RECEIVE_TREE";
export const RECEIVE_TREE_ERROR = "@@NAVTREE_RECEIVE_TREE_ERROR";
export const UPDATE_NODE = "@@NAVTREE_UPDATE_NODE";
export const OPEN_NODE = "@@NAVTREE_OPEN_NODE";
export const RECEIVE_SUBTREE = "@@NAVTREE_RECEIVE_SUBTREE";
export const RECEIVE_SUBTREE_ERROR = "@@NAVTREE_RECEIVE_SUBTREE_ERROR";


//UI ids
export const MENU_ID = "CONTEXT_MENU_ID_";
export const COPY_TEXT_FIELD_ID = "NAVTREE_COPY_TEXT_FIELD_ID_";
export const COPY_TEXT_DIALOG_ID = "NAVTREE_COPY_TEXT_DIALOG_ID_";
export const DELETE_CONFIRM_DIALOG_ID = "NAVTREE_DELETE_CONFIRM_DIALOG_ID_";


//Messages
export const COPY_SUCCESS = (<FormattedMessage
    id="NAVTREE_REF_COPY_SUCCESS"
    defaultMessage="References was successfully copied"
    description="Alert message of successful references copy command" />);

export const COPY_ERROR = (<FormattedMessage
    id="NAVTREE_REF_COPY_ERROR"
    defaultMessage="Error occured while references copying"
    description="Alert message of failed references copy command" />);