import React from 'react';
import SwitcherDialog from './switcherdlg.jsx';
import PasswordChangeDialog from './passwdchangedlg.jsx';
import AlertArea from './alert.jsx';
import ModalStack from './modalstack.jsx';

class ModalsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.onDismiss = this.onDismiss.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.changeLocale(event.target.value);
    }

    onDismiss(alert) {
        console.log("Dismiss",alert);
    }

    render() {
        return (
            <div>
                <SwitcherDialog {...this.props} />
                <PasswordChangeDialog {...this.props} />
                <AlertArea alerts={this.props.alerts} removeAlert={this.props.removeAlert}/>
                <ModalStack />
            </div>);
    }
}

export default ModalsContainer;