import Select from 'react-select';
import PropTypes from 'prop-types';
import Tag from './tag.jsx';
import { MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../messages.jsx';

function sortedAdd(array, item, func) {
    for (let i = 0; i < array.length; ++i) {
        if (func(array[i], item)) {
            array.splice(i, 0, item);
            return;
        }
    }
    array.push(item);
}


class TagSelect extends React.Component {

    constructor(props) {
        super(props);

        this.displayField = props.displayField ? props.displayField : "label";

        this.updateTags(props);

        this.selectTag = this.selectTag.bind(this);
    }

    updateTags(props) {
        this.options = [];
        this.dataMap = {};
        for (let suggestion of props.suggestions) {
            sortedAdd(this.options, { value: suggestion.id, label: suggestion[this.displayField] }, function (a, b) { return a.label.toLowerCase() > b.label.toLowerCase() });
            this.dataMap[suggestion.id] = suggestion;
        }
    }

    selectTag(newValue) {
        this.props.selectHandler(this.dataMap[newValue.value]);
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.suggestions != nextProps.suggestions
            || this.props.tags != nextProps.tags) {
            return true;
        }
        return false;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.suggestions != nextProps.suggestions) {
            this.updateTags(nextProps);
        }
    }

    render() {
        return (<div className="tag-select">
            {this.props.tags.map((tag) =>
                <Tag tag={tag} displayField={this.displayField} deleteHandler={this.props.deleteHandler}></Tag>
            )}
            <Select
                name="tagSelect"
                loadingPlaceholder={MSG_SELECT_LOADING}
                placeholder={this.props.placeholder ? this.props.placeholder : MSG_SELECT_PLACEHOLDER}
                noResultsText={MSG_SELECT_NO_RESULTS}
                value={null}
                options={this.options}
                onChange={this.selectTag}
                clearable={false}
            />
        </div>);
    }
}

TagSelect.propTypes = {
    tags: PropTypes.array.isRequired,
    suggestions: PropTypes.array.isRequired,
    selectHandler: PropTypes.func.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    displayField: PropTypes.string,
    placeholder: PropTypes.any
};

export default TagSelect;    