import React from 'react';

const DIVIDER_WIDTH = 5;

class Divider extends React.Component {

    constructor(props) {
        super(props);
    }

    stopPropagation(reactEvent) {
        reactEvent.stopPropagation();
        reactEvent.preventDefault();
    }

    grabAction(reactEvent) {
        reactEvent.preventDefault();
        this.props.grabAction(reactEvent);
    }

    printCollapsers(type) {
        if (this.props.withoutCollapsing) {
            return null;
        }
        if (this.props.collapsed) {
            return (
                <div className='cim-divider-line-collapser-side cim-divider-line-collapser-left' style={{ pointerEvents: "all" }} onMouseDown={this.stopPropagation} onClick={() => this.props.toggleAction(true)}>
                    <div>
                        <div>{type == "vertical" ? "◀" : "▲"}</div>
                    </div>
                </div>);
        }
        return (
            <div className='cim-divider-line-collapser-side cim-divider-line-collapser-right' style={{ pointerEvents: "all" }} onMouseDown={this.stopPropagation} onClick={() => this.props.toggleAction(false)}>
                <div>
                    <div>{type == "vertical" ? "▶" : "▼"}</div>
                </div>
            </div>);
    }

    render() {
        const type = this.props.type || "vertical";
        const sizes = {
            width: (type == "vertical" ? DIVIDER_WIDTH : null),
            height: (type == "horizontal" ? DIVIDER_WIDTH : null)
        }
        const style = Object.assign({}, sizes, this.props.style);
        if (this.props.collapsed) {
            style.pointerEvents = "none";
        }
        return <div className={'cim-divider-line cim-divider-line-' + type} style={style} onMouseDown={this.grabAction.bind(this)}>
            <div className='cim-divider-line-collapser'>
                <div className='cim-divider-line-collapser-middle' style={Object.assign({ pointerEvents: "all" }, sizes)}></div>
                {this.printCollapsers(type)}
            </div>
        </div>;
    }
}

export default Divider;
