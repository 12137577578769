import React from 'react';
import ReactDOM from 'react-dom';
import HashFilter from './filters/hashfilter.jsx';
import StringFilter from './filters/stringfilter.jsx';
import DateFilter from './filters/datefilter.jsx';
import DateTimeFilter from './filters/datetimefilter.jsx';
import NumberFilter from './filters/numberfilter.jsx';

import { SORT_ASCEND, SORT_DESCEND, FILTER_STRING } from '../../constants/table';

const LEFT = 0;
const RIGHT = 1;

function stopPropagation(reactEvent) {
    reactEvent.stopPropagation();
}

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.openFilter = this.openFilter.bind(this);
    }

    finishColumnResize(columnIndex) {
        //TODO: add final actions if we need some
    }

    getNeighborsIdx(columnIndex) {
        let neighbors = {
            left: null,
            right: null
        };
        for (let i = columnIndex - 1; i >= 0; --i) {
            if (this.props.columnsWidth[i] != 0) {
                neighbors.left = i;
                break;
            }
        }
        for (let i = columnIndex + 1; i < this.props.columnsWidth.length; ++i) {
            if (this.props.columnsWidth[i] != 0) {
                neighbors.right = i;
                break;
            }
        }
        return neighbors;
    }

    resizeColumn(columnIndex, neighbors, side, cursorChange) {
        if (!cursorChange) {
            return;
        }
        const leftIndex = side == RIGHT ? columnIndex : neighbors.left;
        const rightIndex = side == RIGHT ? neighbors.right : columnIndex;
        const enlargingCell = cursorChange >= 0 ? leftIndex : rightIndex;

        let direction = cursorChange >= 0 ? 1 : -1;
        let sizeChange = Math.abs(cursorChange);
        let actualSizeChange = 0;

        let widthChanges = {
            [enlargingCell]: sizeChange
        };

        for (let i = enlargingCell + direction; i >= 0 && i < this.props.columnsWidth.length; i += direction) {
            let columnWidth = this.props.getColumnWidth({ index: i });
            if (columnWidth <= this.props.minColumnWidth) {
                continue;
            }
            if (columnWidth - sizeChange >= this.props.minColumnWidth) {
                widthChanges[i] = -sizeChange;
                sizeChange = 0;
                break;
            }
            if (!this.props.collapseOnResize) {
                if (direction > 0) {
                    actualSizeChange = sizeChange;
                    sizeChange = 0;
                }
                break;
            }
            let widthChange = columnWidth - this.props.minColumnWidth;
            widthChanges[i] = -widthChange;
            sizeChange -= widthChange;
        }

        /* Subtract overflowed size */
        widthChanges[enlargingCell] -= sizeChange;
        cursorChange -= sizeChange * direction;

        let columnsWidth = this.props.columnsWidth.slice();
        for (let i in widthChanges) {
            columnsWidth[i] += widthChanges[i] / this.props.actualWidth;
        }
        this.resizeState.prevX += cursorChange;
        this.props.updateColumnSizes(columnsWidth, actualSizeChange)
    }

    grabResize(columnIndex, side, reactEvent) {
        reactEvent.preventDefault();
        reactEvent.stopPropagation();
        this.resizeState = {
            columnIndex,
            side,
            prevX: reactEvent.screenX
        }
        const neighbors = this.getNeighborsIdx(columnIndex);
        $(window).on("mousemove.npttable", (event) => {
            this.resizeColumn(columnIndex, neighbors, side, event.screenX - this.resizeState.prevX);
        });
        $(window).on("mouseup.npttable", (event) => {
            $(window).off("mousemove.npttable");
            $(window).off("mouseup.npttable");
            this.finishColumnResize(columnIndex);
            delete (this.resizeState);
        });
    }

    isSorted(column) {
        return this.props.sorting && column.field == this.props.sorting.column;
    }

    printSortElement(column) {
        if (column.sortable == false) {
            return null;
        }
        if (this.isSorted(column)) {
            return <span class={"order fa " + (this.props.sorting.order == SORT_ASCEND ? "fa-angle-up" : "fa-angle-down")}></span>
        }
        return null;
    }

    filterColumn(field, filter) {
        if (!filter) {
            this.props.filterColumn(field, null);
            return;
        }
        delete (filter.callback);
        this.props.filterColumn(field, filter);
    }

    openFilter(type, options, okCallback, cancelCallback, closeCallback) {
        const modalId = "npt-table-filter";
        this.props.openModal(modalId, type, options, okCallback, cancelCallback, closeCallback)
    }

    printFilterElement(column) {
        let Filter = null;
        let filterType = null;
        if (typeof column.filterStrategy == "string") {
            filterType = column.filterStrategy.toLowerCase();
            switch (filterType) {
                case 'date':
                    Filter = DateFilter;
                    break;
                case 'date_time':
                    Filter = DateTimeFilter;
                    break;
                case 'number':
                    Filter = NumberFilter;
                    break;
                default:
                    Filter = StringFilter;
                    break;
            }
        } else if (column.hashFilter) {
            filterType = "hash";
            Filter = HashFilter;
        } else {
            return null;
        }
        return (
            <span onClick={stopPropagation}>
                <Filter
                    type={filterType}
                    active={this.props.filtered}
                    label={column.label}
                    size={column.filterModalSize}
                    field={column.field}
                    format={column.format}
                    filterHandler={this.filterColumn.bind(this, column.field)}
                    getFilteredRows={() => this.props.data}
                    rows={this.props.data}
                    openModal={this.openFilter} />
            </span>);
    }

    printSelectionHeader() {
        if (this.props.column.radio) {
            return null;
        }
        let checked = null;
        this.indeterminated = null;
        switch (this.props.selectedRows.length) {
            case this.props.data.length:
                checked = true;
                break;
            case 0:
                checked = false;
                break;
            default:
                this.indeterminated = true;
                break;
        }
        return (
            <div className="npt-table-select-cell">
                {this.props.data.length != 0 && <input type="checkbox" checked={checked} onClick={() => this.props.selectAllRows(!checked && !this.indeterminated)} ref={(ref) => this.checkbox = ref}></input>}
            </div>);
    }

    printLabelColumn() {
        return (<span><b>{this.props.column.label}</b></span>);
    }

    componentDidUpdate() {
        if (!this.checkbox || !this.props.column.select || this.props.column.radio) {
            return;
        }
        ReactDOM.findDOMNode(this.checkbox).indeterminate = this.indeterminated;
    }

    render() {
        let cls = "npt-table-col-header bg-dark-primary border-dark-primary";
        if (this.props.column.sortable != false) {
            cls += " sortable";
        }
        if (this.isSorted(this.props.column)) {
            cls += " sorted";
        }
        return (
            <div
                className={cls}
                style={{
                    width: this.props.getColumnWidth({ index: this.props.index })
                }}
                onClick={this.props.column.sortable != false ? this.props.sortColumn.bind(this, this.props.column.field) : null}>
                {!this.props.first &&
                    <div
                        className="npt-table-col-resize left border-dark-primary"
                        onMouseDown={this.grabResize.bind(this, this.props.index, LEFT)}
                        onClick={stopPropagation}
                        style={{ left: 0 }}>
                    </div>
                }
                {this.props.column.select ? this.printSelectionHeader() : this.printLabelColumn()}
                {this.printSortElement(this.props.column)}
                {this.printFilterElement(this.props.column)}
                {!this.props.last &&
                    <div
                        className="npt-table-col-resize right border-dark-primary"
                        onMouseDown={this.grabResize.bind(this, this.props.index, RIGHT)}
                        onClick={stopPropagation}
                        style={{ right: 0 }}>
                    </div>
                }
            </div>);
    }
}

export default Header;
