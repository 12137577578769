import { FormattedMessage } from 'react-intl';

export const TABLE = "table";

export const SORT_ASCEND = 1;
export const SORT_DESCEND = -1;

export const FILTER_STRING = "string";

export const DEFAULT_ROW_HEIGHT = 80;
export const OVERSCAN_ROW_COUNT = 5;

export const VERTICAL = 0;
export const HORIZONTAL = 1

export const NPT_TABLE_SELECT_ROW_EVENT = "NPT_TABLE_SELECT_ROW";

//Actions
export const WAIT_FOR_INFO = "@@TABLE_WAIT_FOR_INFO";
export const INFO_RECEIVED = "@@TABLE_INFO_RECEIVED";
export const INFO_ERROR_RECEIVED = "@@TABLE_INFO_ERROR_RECEIVED";

export const WAIT_FOR_DATA = "@@TABLE_WAIT_FOR_DATA";
export const DATA_RECEIVED = "@@TABLE_DATA_RECEIVED";
export const DATA_ERROR_RECEIVED = "@@TABLE_DATA_ERROR_RECEIVED";

export const INITIALIZE_TABLE_FIELDS = "@@TABLE_INITIALIZE_TABLE_FIELDS";
export const RESET_TABLE_FIELDS = "@@TABLE_RESET_TABLE_FIELDS";

export const SORT_COLUMN = "@@TABLE_SORT_COLUMN";
export const FILTER_COLUMN = "@@TABLE_FILTER_COLUMN";

export const SELECT_ROW = "@@TABLE_SELECT_ROW";
export const SELECT_ALL_ROWS = "@@TABLE_SELECT_ALL_ROWS";

export const WAIT_FOR_PAGE = "@@TABLE_WAIT_FOR_PAGE";
export const PAGE_RECEIVED = "@@TABLE_PAGE_RECEIVED";
export const PAGE_ERROR_RECEIVED = "@@TABLE_PAGE_ERROR_RECEIVED";
export const CHANGE_PAGE = "@@TABLE_CHANGE_PAGE";
export const CHANGE_PAGE_SIZE = "@@TABLE_CHANGE_PAGE_SIZE";

export const CHANGE_VIEW_TYPE = "@@TABLE_CHANGE_VIEW_TYPE";
export const CHANGE_SELECTED_GROUP = "@@TABLE_CHANGE_SELECTED_GROUP";

export const CHANGE_ITEM = "@@TABLE_CHANGE_ITEM";
export const ADD_ITEM = "@@TABLE_ADD_ITEM";
export const REMOVE_ITEM = "@@TABLE_REMOVE_ITEM";
export const SAVE_GANT = "@@TABLE_SAVE_GANT";

export const UPDATE_HEADERS = "@@TABLE_UPDATE_HEADERS";
export const CHANGE_FILTER_LINK = "@@TABLE_CHANGE_FILTER_LINK";
export const STORE_FINDER_FILTER = "@@TABLE_STORE_FINDER_FILTER";

//Finder actions
export const CHANGE_CRITERIA_RELATION = '@@TABLE_FINDER_CHANGE_CRITERIA_RELATION';
export const ADD_CRITERIA_RELATION = '@@TABLE_FINDER_ADD_CRITERIA_RELATION';
export const REMOVE_CRITERIA_RELATION = '@@TABLE_FINDER_REMOVE_CRITERIA_RELATION';
export const CHANGE_CRITERIA_FIELD = '@@TABLE_FINDER_CHANGE_CRITERIA_FIELD';
export const ADD_CRITERIA = '@@TABLE_FINDER_ADD_CRITERIA';
export const REMOVE_CRITERIA = '@@TABLE_FINDER_REMOVE_CRITERIA';
export const LOCK_CRITERIA_RELATIONS = '@@TABLE_FINDER_LOCK_CRITERIA_RELATIONS';
export const UNLOCK_CRITERIA_RELATIONS = '@@TABLE_FINDER_UNLOCK_CRITERIA_RELATIONS';
export const REMOVE_CRITERIA_GROUP = '@@TABLE_FINDER_REMOVE_CRITERIA_GROUP';

export const IMPORT_FINDER = '@@TABLE_FINDER_IMPORT_FINDER';
export const PARSE_FINDER_OPTIONS = '@@TABLE_FINDER_PARSE_FINDER_OPTIONS';
export const TOGGLE_HIDDEN = '@@TABLE_FINDER_TOGGLE_HIDDEN';
export const CHANGE_FINDER_VIEW_TYPE = '@@TABLE_FINDER_CHANGE_VIEW_TYPE';

export const SELECT_FRAGMENT = '@@TABLE_FINDER_SELECT_FRAGMENT';
export const SELECT_CLASS = '@@TABLE_FINDER_SELECT_CLASS';

export const CLASS_LEVELS_RECEIVED = '@@TABLE_FINDER_CLASS_LEVELS_RECEIVED';
export const WAIT_FOR_CLASSES = '@@TABLE_FINDER_WAIT_FOR_CLASSES';
export const CLASSES_RECEIVED = '@@TABLE_FINDER_CLASSES_RECEIVED';
export const FRAGMENT_LEVELS_RECEIVED = '@@TABLE_FINDER_FRAGMENT_LEVELS_RECEIVED';
export const WAIT_FOR_FRAGMENTS = '@@TABLE_FINDER_WAIT_FOR_FRAGMENTS';
export const FRAGMENTS_RECEIVED = '@@TABLE_FINDER_FRAGMENTS_RECEIVED';
export const WAIT_FOR_FIELD = '@@TABLE_FINDER_WAIT_FOR_FIELD';
export const FIELDS_RECEIVED = '@@TABLE_FINDER_FIELDS_RECEIVED';
export const PREDICATE_RECEIVED = '@@TABLE_FINDER_PREDICATE_RECEIVED';
export const OBJECTCARD_RECEIVED = '@@TABLE_FINDER_OBJECTCARD_RECEIVED';
export const OBJECTCARD_ERROR_RECEIVED = '@@TABLE_FINDER_OBJECTCARD_ERROR_RECEIVED';

//////////////////////
//Formatted messages//
//////////////////////
export const NPT_GANT_REMOVE_ITEM = <FormattedMessage
    id="NPT_GANT_REMOVE_ITEM"
    defaultMessage="Remove"
    description="Gant context menu remove item button" />

export const NPT_GANT_SAVE_SUCCESS = <FormattedMessage
    id="NPT_GANT_SAVE_SUCCESS"
    defaultMessage="Gant graph was successfull saved"
    description="Alert on successful saving" />

export const NPT_GANT_SAVE_ERROR = <FormattedMessage
    id="NPT_GANT_SAVE_ERROR"
    defaultMessage="Error occured while saving gant graph"
    description="Alert on saving error" />

export const NPT_TABLE_NO_DATA = <FormattedMessage
    id="NPT_TABLE_NO_DATA"
    defaultMessage="No data"
    description="Table have no data" />

export const NPT_TABLE_PAGINATION = <FormattedMessage
    id="NPT_TABLE_PAGINATION"
    defaultMessage="Showing elements {from}-{to} of {total}. Size per page:"
    description="Standart table pagination text" />

export const NPT_TABLE_REF_COPY_SUCCESS = <FormattedMessage
    id="NPT_TABLE_REF_COPY_SUCCESS"
    defaultMessage="References was successfully copied"
    description="Alert on successful references copying" />

export const NPT_TABLE_REF_COPY_ERROR = <FormattedMessage
    id="NPT_TABLE_REF_COPY_ERROR"
    defaultMessage="Error occured while references copying"
    description="Alert on failed references copying" />