import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UI_CARD_AREA, SUBJECT_OPERATION_SAVE } from '../../constants/objectcard.js';
import { MSG_STATUS_FORBIDDEN, MSG_STATUS_NOT_FOUND, MSG_LOADING_ERROR } from '../messages.jsx'

import shortid from 'shortid';

import { createUi } from './library.jsx';

const OBJECTCARD_FIELD_IS_MANDATORY = (<FormattedMessage
    id="OBJECTCARD_FIELD_IS_MANDATORY"
    defaultMessage="Field is mandatory"
    description="User must fill field" />);

const OBJECTCARD_LESS_THAN_MINIMUM_ITEMS = (<FormattedMessage
    id="OBJECTCARD_LESS_THAN_MINIMUM_ITEMS"
    defaultMessage="Predicate must have minimum items: {length}"
    description="User must add predicate values" />);

const OBJECTCARD_MORE_THAN_MAXIMUM_ITEMS = (<FormattedMessage
    id="OBJECTCARD_MORE_THAN_MAXIMUM_ITEMS"
    defaultMessage="Predicate must have maximum items: {length}"
    description="User must remove predicate values" />);

const OBJECTCARD_VALUE_TOO_LONG = (<FormattedMessage
    id="OBJECTCARD_VALUE_TOO_LONG"
    defaultMessage="Value is too long (length: {length}, max: 1023)"
    description="User must shorten value" />);

const OBJECTCARD_CHECK_FIELDS = (<FormattedMessage
    id="OBJECTCARD_CHECK_FIELDS"
    defaultMessage="Please check if all fields are correctly filled"
    description="User check all fields" />);

const UI = createUi(UI_CARD_AREA);

class Form extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.error && this.props.errorOperation != SUBJECT_OPERATION_SAVE) {
            let error;
            switch (this.props.error.status) {
                case 400:
                    error = null;
                    break;
                case 403:
                    error = MSG_STATUS_FORBIDDEN;
                    break;
                case 404:
                    error = MSG_STATUS_NOT_FOUND;
                    break;
                default:
                    error = MSG_LOADING_ERROR;
                    break;
            }
            if (error) {
                return (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>);
            }
        }

        if (!this.props.layout) { //Layout is not ready
            return (
                <div className="alert alert-info" role="alert">
                    <FormattedMessage
                        id="OBJECTCARD_OBJECT_LOADING"
                        defaultMessage="Object is loading..."
                        description="User should wait while object is loading" />
                </div>);
        }

        return (<form className="col-md-12 nopadding">
            <UI key={this.props.layout.rootId} formId={this.props.id} nodeId={this.props.layout.rootId} className={this.props.cardAreaStyle} />
        </form>);
    }
}

export default Form;