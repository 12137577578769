import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';

const RELATION_REMOVED = (<FormattedMessage
    id="SUBJECT_HISTORY_RELATION_REMOVED"
    defaultMessage="Object was removed"
    description="Inform user that object was removed" />);


const OPERATIONS = {
    RELATION: {
        ADD: <FormattedMessage
            id="SUBJECT_HISTORY_RELATION_OPER_ADD"
            defaultMessage="Added relation"
            description="Relation was added" />,
        UPDATE: <FormattedMessage
            id="SUBJECT_HISTORY_RELATION_OPER_UPDATE"
            defaultMessage="Updated relation"
            description="Relation was updated" />,
        REMOVE: <FormattedMessage
            id="SUBJECT_HISTORY_RELATION_OPER_REMOVE"
            defaultMessage="Removed relation"
            description="Relation was removed" />
    },
    ENUMERATION: {
        ADD: <FormattedMessage
            id="SUBJECT_HISTORY_RELATION_OPER_ADD_ENUMERATION"
            defaultMessage="Added string"
            description="Enumeration was added" />,
        UPDATE: <FormattedMessage
            id="SUBJECT_HISTORY_RELATION_OPER_UPDATE_ENUMERATION"
            defaultMessage="Updated string"
            description="Enumeration was updated" />,
        REMOVE: <FormattedMessage
            id="SUBJECT_HISTORY_RELATION_OPER_REMOVE_ENUMERATION"
            defaultMessage="Removed string"
            description="Enumeration was removed" />
    }
}

function relationHistoryRefFormatter(contextPath, timestamp) {
    return function (cell, row) {
        if (cell == null) {
            return RELATION_REMOVED;
        }
        let namespace = "";
        if (row.value && row.value.$namespace) {
            namespace = row.value.$namespace;
        }
        if (namespace) {
            namespace = namespace + "/";
        }
        if (typeof timestamp == "number") {
            timestamp = new Date(timestamp).toISOString();
        }
        return (<a href={`${contextPath}history/${namespace + cell.$rdfId + (timestamp ? "#?timestamp=" + timestamp : "")}`}><i className="fa fa-history" aria-hidden="true"></i></a>)
    }
}

function relationRefFormatter(contextPath) {
    return function (cell, row) {
        if (cell == null) {
            return RELATION_REMOVED;
        }
        let namespace = "";
        if (row.value && row.value.$namespace) {
            namespace = row.value.$namespace;
        }
        if (namespace) {
            namespace = namespace + "/";
        }
        return (<a href={`${contextPath}objectcard/${namespace + cell.$rdfId}`}><i className="fa fa-eye" aria-hidden="true"></i></a>)
    }
}

function wrapOperation(operationId, enumeration, name) {
    if (name) {
        name = $.trim(name);
    }
    let operation;
    if (enumeration) {
        operation = OPERATIONS.ENUMERATION[operationId];
    } else {
        operation = OPERATIONS.RELATION[operationId];
    }
    return <div>{operation}{(name ? (": " + name) : "")}</div>
}

function operationFormatter(cell, row) {
    let label = null;
    if (row.value) {
        label = row.value.$label;
    }
    if (cell == 0) {
        return wrapOperation("ADD", row.enumeration, label);
    }
    if (cell == 1) {
        return wrapOperation("UPDATE", row.enumeration, label);
    }
    if (cell == 2) {
        return wrapOperation("REMOVE", row.enumeration, label);
    }
    return "-";
}

class RelationHistory extends React.Component {

    constructor(props) {
        super(props);
        this.tableData = this.getTableData(props.data);
        this.relationHistoryFormatter = relationHistoryRefFormatter(props.contextPath, props.timestamp)
        this.relationFormatter = relationRefFormatter(props.contextPath)
    }

    getPredicateName(triple) {
        if (!triple.predicate) {
            return "-";
        }
        return triple.predicate.label;
    }

    getTableData(data) {
        return data.map((r) => {
            return {
                id: r.id,
                name: this.getPredicateName(r),
                operation: r.operation,
                value: r.peerSubject,
                enumeration: Boolean(r.predicate.classRelationInfo.peerClass.enumerationInfo)
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data != nextProps.data) {
            this.tableData = this.getTableData(nextProps.data);
        }
        if (this.props.contextPath != nextProps.contextPath) {
            this.relationHistoryFormatter = relationHistoryRefFormatter(nextProps.contextPath, nextProps.timestamp)
            this.relationFormatter = relationRefFormatter(nextProps.contextPath)
        }
    }

    render() {
        return (
            <div>
                <h4><FormattedMessage
                    id="SUBJECT_HISTORY_RELATION_HEADER"
                    defaultMessage="Relation changes:"
                    description="Relation changes table header" />
                </h4>
                <BootstrapTable version='4' data={this.tableData} striped={true} hover={true}>
                    <TableHeaderColumn isKey={true} hidden={true} dataField='id'>ID</TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='name'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_RELATION_PREDICATE_NAME"
                            defaultMessage="Name"
                            description="Name of predicate that has been changed" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='operation' dataFormat={operationFormatter}>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_RELATION_OPERATION"
                            defaultMessage="Operation"
                            description="Relation operation" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='value' dataFormat={this.relationHistoryFormatter} dataAlign='center'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_RELATION_HISTORY"
                            defaultMessage="History of peer relation"
                            description="History of peer relation" />
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='value' dataFormat={this.relationFormatter} dataAlign='center'>
                        <FormattedMessage
                            id="SUBJECT_HISTORY_RELATION_CARD"
                            defaultMessage="Card of peer relation"
                            description="Open card of object" />
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default RelationHistory;


