import React from 'react';
import { FormattedMessage } from 'react-intl';

class DomainGroup extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.props.onChange(event.target.checked);
    }

    render() {
        if (!this.props.ldapAuth) {
            return null;
        }
        return (<div className="checkbox">
            <label>
                <input type="checkbox" name="ad" defaultChecked={true} onChange={this.onChange} ref={this.props.setupInput}/>
                <FormattedMessage
                    id="LOGIN_PANEL_USE_DOMAIN_LABEL"
                    defaultMessage="Use Active Directory"
                    description="Label for use domain authorization" />
            </label>
        </div>);
    }
}

export default DomainGroup;