import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import DebounceInput from "../debounceinput.jsx";

const defaultX = 10;
const defaultY = 10;
const minZoom = 0.5;
const maxZoom = 10;
const zoomPoints = [0.5, 0.75, 1, 1.5, 2, 3, 5, 7.5, 10];

const LARGER = 0;
const SMALLER = 1;

/**
 * Container that will wrap children with div
 * and add resizing element 
 */
class Resizer extends React.Component {

    render() {
        const style = Object.assign({ maxHeight: this.props.maxHeight, maxWidth: this.props.maxWidth }, this.props.style);
        return <div className="npt-resizer-container position-relative overflow-hidden">
            <ResizerElement {...this.props} />
            <div className="position-relative overflow-auto" style={style}>
                {this.props.children}
            </div>
        </div>
    }
}
Resizer.propTypes = {
    maxWidth: PropTypes.number,
    minWidth: PropTypes.number,
    zoom: PropTypes.number.isRequired,
    changeZoom: PropTypes.func.isRequired,
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
    zoomPoints: PropTypes.arrayOf(PropTypes.number),
    theme: PropTypes.string,
    fixed: PropTypes.bool
};

/**
 * Resizing element to be added inside parent
 * 
 * Parent sizes calculating automatically
 */
class ResizerElement extends React.Component {

    selfRef;
    parentRef;
    objectRef;
    nestedDocumentRef;

    constructor(props) {
        super(props);

        this.state = {
            position: {
                x: defaultX,
                y: defaultY
            },
            zoom: props.zoom || 1,
            minZoom: props.minZoom || minZoom,
            maxZoom: props.maxZoom || maxZoom,
            zoomPoints: props.zoomPoints || zoomPoints,
            theme: props.theme || "light",
            grabbed: false,
            grabParameters: null
        }
        this.filterZoomPoints();
        this.changeZoom(this.state.zoom);

        this.selfRef = null;
        this.parentRef = null;
        this.nestedDocumentRef = null;

        this.changeZoom = this.changeZoom.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.grabResizer = this.grabResizer.bind(this);
        this.releaseResizer = this.releaseResizer.bind(this);
    }

    filterZoomPoints(zoomPoints) {
        this.state.zoomPoints = this.state.zoomPoints.filter((point) => point >= this.state.minZoom && point <= this.state.maxZoom);
        if (this.state.minZoom < this.state.zoomPoints[0]) {
            this.state.zoomPoints.unshift(this.state.minZoom);
        }
        if (this.state.maxZoom > this.state.zoomPoints[this.state.zoomPoints.length - 1]) {
            this.state.zoomPoints.push(this.state.maxZoom);
        }
    }

    setParentRef(ref) {
        this.releaseResizer();
        this.parentRef = ref;
        this.objectRef = $("object", ref)[0] || null;
        if (this.objectRef) {
            $(this.objectRef).on("load", () => {
                this.nestedDocumentRef = this.objectRef.contentDocument || null;
            });
        }
        this.forceUpdate();
    }

    changeZoom(zoom) {
        if (zoom < this.state.minZoom) {
            zoom = this.state.minZoom;
        } else if (zoom > this.state.maxZoom) {
            zoom = this.state.maxZoom;
        }
        if (zoom == this.state.zoom) {
            return;
        }
        this.props.changeZoom(zoom);
    }

    zoomIn() {
        const zoom = this.tryFindZoomPoint(LARGER);
        this.changeZoom(zoom);
    }

    zoomOut() {
        const zoom = this.tryFindZoomPoint(SMALLER);
        this.changeZoom(zoom);
    }

    tryFindZoomPoint(relation) {
        let zoom = this.state.zoom;
        try {
            for (let i = 1; i < this.state.zoomPoints.length; ++i) {
                if (this.state.zoomPoints[i] < zoom) {
                    continue;
                }
                if (this.state.zoomPoints[i] == zoom) {
                    zoom = relation == LARGER ? this.state.zoomPoints[i + 1] : this.state.zoomPoints[i - 1];
                } else {
                    zoom = relation == LARGER ? this.state.zoomPoints[i] : this.state.zoomPoints[i - 1];
                }
                break;
            }
        } catch (e) {
            console.error("Can't get zoom point");
        }
        return zoom;
    }

    grabResizer(event) {
        if (event.target.localName == "input" || this.props.fixed) {
            return;
        }
        event.preventDefault();
        if (this.state.grabbed) {
            return;
        }
        this.bindElement(this.parentRef);
        if (this.nestedDocumentRef) {
            this.bindElement(this.nestedDocumentRef);
        }
        this.setState(Object.assign({}, this.state, { grabbed: true, grabParameters: { x: event.screenX, y: event.screenY } }));
    }

    moveResizer({ x, y }) {
        let differenceX = this.state.grabParameters.x - x;
        let differenceY = this.state.grabParameters.y - y;
        let newX = this.state.position.x - differenceX;
        let newY = this.state.position.y - differenceY;
        this.setState(Object.assign({}, this.state, {
            grabbed: true,
            grabParameters: { x: x, y: y },
            position: { x: newX, y: newY }
        }));
    }

    releaseResizer() {
        if (!this.state.grabbed) {
            return;
        }
        this.unbindElement(this.parentRef);
        if (this.nestedDocumentRef) {
            this.unbindElement(this.nestedDocumentRef);
        }
        this.setState(Object.assign({}, this.state, { grabbed: false, grabParameters: null }));
    }

    bindElement(htmlElement) {
        $(htmlElement).on("mousemove.resizer", (event) => {
            this.moveResizer({ x: event.screenX, y: event.screenY });
        });
        $(htmlElement).on("mouseup.resizer", (event) => {
            this.releaseResizer();
        });
    }

    unbindElement(htmlElement) {
        $(htmlElement).off("mousemove.resizer");
        $(htmlElement).off("mouseup.resizer");
    }

    printFixedSvgDecorators() {
        if (!this.props.fixed || !this.bgColor) {
            return null;
        }
        const rightTopOptions = {
            width: 10,
            height: 20,
            left: "100%",
            top: -1
        }
        const leftBottomOptions = {
            width: 20,
            height: 10,
            left: -1,
            top: "100%"
        }
        return [
            <div className="position-absolute" style={rightTopOptions}>
                <svg className="position-absolute" preserveAspectRatio="none" width={rightTopOptions.width} height={rightTopOptions.height} viewBox="0 0 100 100">
                    <path vector-effect="non-scaling-stroke" d="M 0 100 C 10 40, 40 20, 100 0 L 0 0" stroke="#ccc" fill={this.bgColor} />
                    <line vector-effect="non-scaling-stroke" x1="0" x2="90" y1="4" y2="4" stroke="#ccc" />
                </svg>
            </div>,
            <div className="position-absolute" style={leftBottomOptions}>
                <svg className="position-absolute" preserveAspectRatio="none" width={leftBottomOptions.width} height={leftBottomOptions.height} viewBox="0 0 100 100">
                    <path vector-effect="non-scaling-stroke" d="M 100 0 C 40 10, 20 40, 0 100 L 0 0" stroke="#ccc" fill={this.bgColor} />
                    <line vector-effect="non-scaling-stroke" x1="4" x2="4" y1="0" y2="90" stroke="#ccc" />
                </svg>
            </div>
        ];
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.zoom != nextProps.zoom) {
            this.state.zoom = nextProps.zoom;
        }
    }

    /* Set parent ref on element mount */
    componentDidMount() {
        this.selfRef = ReactDOM.findDOMNode(this);
        this.bgColor = window.getComputedStyle(this.selfRef, null).getPropertyValue("background-color");
        this.setParentRef(this.selfRef.parentNode);
    }

    /* Check parent ref on element update */
    componentDidUpdate() {
        try {
            if (!this.parentRef || this.selfRef.parentNode != this.parentRef) {
                this.setParentRef(this.selfRef.parentNode)
            }
        } catch (e) { }
    }

    render() {
        let className = `npt-resizer position-absolute btn-group-vertical bg-${this.state.theme}`;
        if (this.props.fixed) {
            className += " npt-resizer-fixed";
        }
        return <div
            className={className}
            style={{ left: this.state.position.x, top: this.state.position.y }}
            onMouseDown={this.grabResizer}
        >
            {this.printFixedSvgDecorators()}
            <Button icon="fa-plus" theme={this.state.theme} onClick={this.zoomIn} disabled={this.state.zoom >= this.state.maxZoom} />
            <Button icon="fa-minus" theme={this.state.theme} onClick={this.zoomOut} disabled={this.state.zoom <= this.state.minZoom} />
            <Breaker />
            <ZoomInput
                zoom={this.state.zoom}
                changeZoom={this.changeZoom}
                minZoom={this.state.minZoom}
                maxZoom={this.state.maxZoom}
            />
        </div>;
    }
}
ResizerElement.propTypes = {
    zoom: PropTypes.number.isRequired,
    changeZoom: PropTypes.func.isRequired,
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
    zoomPoints: PropTypes.arrayOf(PropTypes.number),
    theme: PropTypes.string,
    fixed: PropTypes.bool
};

class ZoomInput extends React.PureComponent {

    inputRef;

    constructor(props) {
        super(props);

        this.inputRef = null;

        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(value) {
        const nextZoom = value / 100;
        if (value == 0 || nextZoom < this.props.minZoom || nextZoom > this.props.maxZoom) {
            return;
        }
        this.props.changeZoom(nextZoom);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.zoom != nextProps.zoom) {
            this.inputRef.wasChanged = false;
        }
    }

    render() {
        return <div className="npt-resizer-input input-group">
            <DebounceInput
                editable={true}
                valid={true}
                /* Store value in percents */
                value={this.props.zoom * 100}
                format={"int"}
                className={"form-control"}
                change={this.changeValue}
                ref={(ref) => this.inputRef = ref}
            />
            <div className="input-group-append" >
                <span class="input-group-text" id="basic-addon2">%</span>
            </div>
        </div>;
    }
}
ZoomInput.propTypes = {
    zoom: PropTypes.number.isRequired,
    changeZoom: PropTypes.func.isRequired,
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number
};

class Button extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const optional = {};
        if (this.props.disabled) {
            optional.disabled = true;
        }
        return <div className="npt-resizer-button col-12">
            <button type="button" class={`btn btn-${this.props.theme} flex-shrink`} onClick={this.props.onClick} {...optional}>
                <i className={`fa ${this.props.icon} fa-fw`} aria-hidden="true"></i>
            </button>
        </div>;
    }
}
Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

class Breaker extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return <hr className="npt-resizer-breaker col-12" />;
    }
}
Breaker.propTypes = {
};

export default Resizer;