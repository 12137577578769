import React from 'react';
import { FormattedMessage } from 'react-intl';
import cellFactory from './cellFactory.jsx';
import { MSG_TABLE_NO_DATA } from '../messages.jsx';
import {
    UI_PANEL,
    UI_TAB,
    UI_REF_TABLE,
    UI_IMAGE,
    UI_FILE,
    STATUS_READY,
    UI_CARD,
    UI_DESCRIPTION,
    UI_BUTTON,
    UI_LINK,
    UI_RPA_SETPOINTS
} from '../../constants/objectcard';
import { obtainData } from '../../services/automation.js';
import { buildInnerCardId } from '../../services/objectcard';
import { DEFAULT_FIELDS } from './inputs/reftable.jsx';
import { filterColumns } from './inputs/objtable.jsx';

const PRINTCARD_SIMPLE_ROW = "simpleRow";
const PRINTCARD_SIMPLE_TABLE = "simpleTable";
const PRINTCARD_TABLE = "table";
const PRINTCARD_DIRECTORY = "directory";
const PRINTCARD_REF_TABLE = "reftable";
const PRINTCARD_UI_FILE = "input-file";
const PRINTCARD_UI_DESCRIPTION = "description";

function getNewTabName(tabName, title) {
    if (!tabName) {
        return title || "";
    }
    if (title) {
        return tabName + " - " + title;
    }
    return tabName;
}

function printValue(value) {
    if (typeof value == "undefined" || value == null || value == "") {
        return "--";
    }
    return value;
}

function toArray(value) {
    if (!value) {
        return [];
    }
    if (!Array.isArray(value)) {
        value = [value];
    }
    return value;
}

function toString(value) {
    if (Array.isArray(value)) {
        const arr = [];
        for (let item of value) {
            arr.push(toString(item));
        }
        return arr.join(" | ");
    }
    if (typeof value == "object" && typeof value.$label != "undefined") {
        return value.$label;
    }
    return value.toString();
}

function parseRefValues(values) {
    const parsedValues = [];
    for (let value of values) {
        const parsedValue = {
            value: "",
            description: ""
        };
        try {
            parsedValue.value = value.$label;
            parsedValue.description = value.$description;
        } catch (e) { }
        parsedValues.push(parsedValue);
    }
    return parsedValues;
}

class PrintCard extends React.Component {

    constructor(props) {
        super(props);
        this.printed = false;
        this.state = { directories: {} }
    }

    componentDidMount() {
        let ns = null;
        let rdfId = this.props.objectId;
        if (rdfId.indexOf(":") >= 0) {
            let parts = rdfId.split(":");
            ns = parts[0];
            rdfId = parts[1];
        }
        this.fetchedSubjects = { [rdfId]: true };
        this.cardIdMap = { [this.props.id]: true };
        this.props.fetchSubject(this.props.id, rdfId, ns);
    }

    getRow(num, rowData, leafOptions, isRefTable) {
        let row = {};
        row.key = num;
        row.num = num;
        for (let col of leafOptions.columns) {
            const colId = 'col' + (col.field || col.id);
            if (col.path) {
                let cell = obtainData(col.path, rowData);
                if (typeof cell == "boolean") {
                    cell = cell.toString();
                }
                row[colId] = cell;
                continue;
            }
            if (isRefTable) {
                const defaultColumn = DEFAULT_FIELDS[col.field];
                if (defaultColumn) {
                    row[colId] = defaultColumn.getter(rowData, num);
                    continue;
                }
            }
            row[colId] = ""
        }
        return row;
    }

    getTableData(mainData, leaf) {
        const isRefTable = leaf.ui == UI_REF_TABLE;
        let data = obtainData(leaf.id, mainData);
        let rows = [];
        if ($.type(data) == 'array') { //many objects
            for (let idx in data) {
                let num = parseInt(idx) + 1;
                rows.push(this.getRow(num, data[idx], leaf.options, isRefTable));
            }
        } else if ($.type(data) == 'object') { //one object
            rows.push(this.getRow(1, data, leaf.options, isRefTable));
        }
        return rows;
    }

    getHeaders(leaf, contextPath) {
        const isRefTable = leaf.ui == UI_REF_TABLE;
        let headerList = [];
        /* Sort columns if order is defined in options */
        let sortedColumns;
        if (leaf.options["columns-order"]) {
            sortedColumns = filterColumns(leaf.options.columns, leaf.options["columns-order"]);
        } else {
            sortedColumns = leaf.options.columns;
        }
        //Push predicates to the table
        for (let col of sortedColumns) {
            if (col.hidden) {
                continue;
            }
            const colId = 'col' + (col.field || col.id);
            let options = null;
            if (col.predicate) {
                options = cellFactory(col.predicate, { contextPath, print: true });
            }
            let header = { label: col.label, dataFormat: options && options.dataFormat ? options.dataFormat : null, dataField: colId };
            headerList.push(header);
            if (!isRefTable) {
                continue;
            }
            if (col.field == "ref") {
                headerList.pop();
                continue;
            }
            /* If column doesn't have label - try to get it from default columns */
            if (col.label) {
                continue;
            }
            const defaultColumn = DEFAULT_FIELDS[col.field];
            if (!defaultColumn) {
                continue;
            }
            if (defaultColumn.hidden) {
                headerList.pop();
                continue;
            }
            header.label = defaultColumn.label;
        }
        return headerList;
    }

    parseTableData(tableData, headers) {
        for (let row of tableData) {
            for (let header of headers) {
                if (header.dataFormat) {
                    row[header.dataField] = header.dataFormat(row[header.dataField]);
                }
            }
        }
    }

    checkDirectory(name) {
        if (typeof this.state.directories[name] == "undefined") {
            return false;
        }
        return true;
    }

    /* Adding element to directory (if it's exist) or create new (if it's doesn't exist) */
    addToDirectory(name, object, root) {
        if (this.checkDirectory(name)) {
            this.state.directories[name].push(object);
            return;
        }
        let directory = { type: PRINTCARD_DIRECTORY, value: name, content: [object] };
        this.state.directories[name] = directory.content;
        root.push(directory);
    }

    parseLeaf(cardId, tabName, leafId, parentContent, root) {
        let leaf = this.props.layout[this.props.data[cardId].$class].byId[leafId];
        if (this.props.values[cardId][leafId] == "undefined") {
            return null;
        }
        /* Ignore non-printable UI's */
        if (leaf.ui == UI_BUTTON || leaf.ui == UI_LINK || leaf.ui == UI_RPA_SETPOINTS) {
            return;
        }
        /* Fetch integrated cards if needed */
        if (leaf.ui == UI_CARD) {
            if (!leaf.options.src) {
                return;
            }
            let data = this.props.values[cardId][leaf.options.src];
            if (!data || !data.$rdfId) {
                return;
            }
            let id = buildInnerCardId(this.props.id, leafId, data.$namespace, data.$rdfId);

            if (this.printedCards[id]) {
                console.error("WARNING! Recursive print of card with id '" + id + "'!");
                return;
            }
            this.printedCards[id] = true;

            if (!this.fetchedSubjects[data.$rdfId]) {
                this.fetchedSubjects[data.$rdfId] = true;
                this.loading = true;
                this.props.fetchSubject(id, data.$rdfId, data.$namespace || null);
                return;
            }

            this.cardIdMap[id] = true;
            let cardData = this.props.data[id];
            if (!cardData || !cardData.$class) {
                return;
            }
            let layout = this.props.layout[cardData.$class];
            if (!layout) {
                return;
            }
            this.parseNode(id, tabName, layout.rootId, root, root);
            return;
        }
        if (typeof leaf.options.columns == "undefined") {
            /* This is simple data - use cellFactory to get formatter (if one needed) */
            let cellOptions = cellFactory(leaf.options, { contextPath: this.props.contextPath, print: true, reftable: true });
            let value = this.props.values[cardId][leafId];
            if (cellOptions && cellOptions.dataFormat) {
                value = cellOptions.dataFormat(value, null, this.props.contextPath);
            }
            if (leaf.ui == UI_REF_TABLE) {
                this.addToDirectory(tabName, { type: PRINTCARD_REF_TABLE, content: parseRefValues(toArray(value)), label: leaf.label, headers: [] }, root);
                return;
            }
            if (leaf.ui == UI_FILE) {
                this.addToDirectory(tabName, { type: PRINTCARD_UI_FILE, content: value, label: leaf.label }, root);
                return;
            }
            if (leaf.ui == UI_DESCRIPTION) {
                this.addToDirectory(null, { type: PRINTCARD_UI_DESCRIPTION, content: value, label: leaf.label, tabName }, root);
                return;
            }
            if (value && typeof value == "object" && !React.isValidElement(value)) {
                value = toString(value);
            }
            if (!leaf.label) {
                return;
            }
            parentContent.push({ type: PRINTCARD_SIMPLE_ROW, label: leaf.label, value });
            return;
        }
        /* This is table - use cellFactory to get formatter (if one needed) */
        let tableData = this.getTableData(this.props.data[cardId], leaf);
        let headers = this.getHeaders(leaf, this.props.contextPath);
        console.log(headers)
        this.parseTableData(tableData, headers);
        this.addToDirectory(tabName, { type: PRINTCARD_TABLE, label: leaf.label, headers, tableData }, root);
    }

    parseContentArray(cardId, content, tabName, parentContent, root) {
        if (!content) {
            return;
        }
        /* Return parsed array of content ignoring parent container */
        content.map((layoutId, index) => this.parseNode(cardId, tabName, layoutId, parentContent, root))
        return;
    }

    parseContainer(cardId, tabName, parentId, parentContent, root) {
        let data = this.props.data[cardId];
        let layoutNode = this.props.layout[data.$class].byId[parentId];
        let content = this.props.layout[data.$class].childrenIdsByParentId[parentId];
        let title = $.trim(layoutNode.options.title) ? layoutNode.options.title : $.trim(layoutNode.label) ? layoutNode.label : "";
        if (layoutNode.ui == UI_PANEL) {
            tabName = getNewTabName(tabName, title);
            /* Current container is panel */
            let table = { type: PRINTCARD_SIMPLE_TABLE, content: [] };
            this.addToDirectory(tabName, table, root);
            this.parseContentArray(cardId, content, tabName, table.content, root)
            return;
        }
        /* Setup tab name for panels */
        if (layoutNode.ui == UI_TAB && $.trim(layoutNode.label)) {
            tabName = getNewTabName(tabName, layoutNode.label);
        }
        /* Current layout type doesn't provide a container - adding childrens to parent element */
        this.parseContentArray(cardId, content, tabName, parentContent, root);
    }

    parseNode(cardId, tabName, parentId, parentContent, root) {
        if (!this.props.visibility[cardId][parentId]) {
            return;
        }
        let data = this.props.data[cardId];
        if (this.props.layout[data.$class].childrenIdsByParentId[parentId]) {
            /* Layout element is container - parse all children and add wrapping container if needed */
            this.parseContainer(cardId, tabName, parentId, parentContent, root);
            return;
        }
        /* This is not container - get element and add it to parent */
        this.parseLeaf(cardId, tabName, parentId, parentContent, root);
    }

    createElement(formattedRowData) {
        switch (formattedRowData.type) {
            case PRINTCARD_SIMPLE_ROW:
                return (<tr>
                    <td>{formattedRowData.label}</td>
                    <td>{printValue(formattedRowData.value)}</td>
                </tr>);
            case PRINTCARD_SIMPLE_TABLE:
                return (<div className="root-container">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "50%" }}>
                                    <FormattedMessage
                                        id="PRINTCARD_PARAMETER_NAME"
                                        defaultMessage="Name of parameter"
                                        description="Name of parameter" />
                                </th>
                                <th style={{ width: "50%" }}>
                                    <FormattedMessage
                                        id="PRINTCARD_PARAMETER_VALUE"
                                        defaultMessage="Value of parameter"
                                        description="Value of parameter" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="cim-printcart-header-numeric">1</td>
                                <td className="cim-printcart-header-numeric">2</td>
                            </tr>
                            {formattedRowData.content.map((element, index) => this.createElement(element))}
                        </tbody>
                    </table>
                </div>);
            case PRINTCARD_TABLE:
                return (<div className="root-container">
                    <table>
                        <caption className="text-right"><strong>{formattedRowData.label}</strong></caption>
                        <thead>
                            <tr>
                                {formattedRowData.headers.map((header, index) => <th style={{ width: `${100 / formattedRowData.headers.length}%` }}>{header.label}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {formattedRowData.headers.map((header, index) => <td className="cim-printcart-header-numeric">{index + 1}</td>)}
                            </tr>
                            {formattedRowData.tableData.length == 0 ?
                                <tr>
                                    <td colSpan={formattedRowData.headers.length} className="text-center">{MSG_TABLE_NO_DATA}</td>
                                </tr> : formattedRowData.tableData.map((row, index) => <tr>
                                    {formattedRowData.headers.map((header, index) => <td>{printValue(row[header.dataField])}</td>)}
                                </tr>)}
                        </tbody>
                    </table>
                </div>);
            case PRINTCARD_DIRECTORY:
                return (<div className="root-container">
                    <h4><strong>{formattedRowData.value}</strong></h4>
                    {formattedRowData.content.map((element, index) => this.createElement(element))}
                </div>);
            case PRINTCARD_UI_DESCRIPTION:
                return this.printDescription(formattedRowData.content, formattedRowData.tabName);
            case PRINTCARD_REF_TABLE:
                return (<div className="root-container">
                    <table>
                        <caption className="text-right"><strong>{formattedRowData.label}</strong></caption>
                        <thead>
                            <tr>
                                <th style={{ width: "50px" }}>
                                    <FormattedMessage
                                        id="PRINTCARD_REFTABLE_NUMBER"
                                        defaultMessage="#"
                                        description="Number of item in table of references" />
                                </th>
                                <th style={{ width: "50%" }}>
                                    <FormattedMessage
                                        id="PRINTCARD_REFTABLE_NAME"
                                        defaultMessage="Name"
                                        description="Name of item in table of references" />
                                </th>
                                <th style={{ width: "50%" }}>
                                    <FormattedMessage
                                        id="PRINTCARD_REFTABLE_DESCRIPTION"
                                        defaultMessage="Description"
                                        description="Description of item in table of references" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="cim-printcart-header-numeric">1</td>
                                <td className="cim-printcart-header-numeric">2</td>
                                <td className="cim-printcart-header-numeric">3</td>
                            </tr>
                            {(!formattedRowData.content || formattedRowData.content.length == 0) ?
                                <tr>
                                    <td colSpan={3} className="text-center">{MSG_TABLE_NO_DATA}</td>
                                </tr>
                                :
                                formattedRowData.content.map((row, index) => <tr>
                                    <td>{index + 1}</td>
                                    <td>{printValue(row.value)}</td>
                                    <td dangerouslySetInnerHTML={{ __html: printValue(row.description) }}></td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>);
            case PRINTCARD_UI_FILE:
                if (formattedRowData.content && $.type(formattedRowData.content) != "array") {
                    formattedRowData.content = [formattedRowData.content];
                }
                return (<div className="root-container">
                    <table>
                        <caption className="text-right"><strong>{formattedRowData.label}</strong></caption>
                        <thead>
                            <tr>
                                <th style={{ width: "50px" }}>
                                    <FormattedMessage
                                        id="PRINTCARD_UI_FILE_NUMBER"
                                        defaultMessage="#"
                                        description="Number of item in table of adjustments" />
                                </th>
                                <th style={{ width: "50%" }}>
                                    <FormattedMessage
                                        id="PRINTCARD_UI_FILE_NAME"
                                        defaultMessage="Filename"
                                        description="Name of file in table of adjustments" />
                                </th>
                                <th style={{ width: "50%" }}>
                                    <FormattedMessage
                                        id="PRINTCARD_UI_FILE_SIZE"
                                        defaultMessage="Size"
                                        description="Size of file in bytes" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="cim-printcart-header-numeric">1</td>
                                <td className="cim-printcart-header-numeric">2</td>
                                <td className="cim-printcart-header-numeric">3</td>
                            </tr>
                            {(!formattedRowData.content || formattedRowData.content.length == 0) ?
                                <tr>
                                    <td colSpan="3" className="text-center">{MSG_TABLE_NO_DATA}</td>
                                </tr>
                                :
                                formattedRowData.content.map((row, index) => <tr>
                                    <td>{index + 1}</td>
                                    <td>{printValue(row.$label)}</td>
                                    <td dangerouslySetInnerHTML={{ __html: printValue(row.$contentSize) }}></td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>);
            default:
                return null;
        }
    }

    /* return false if element is good or true if element must be removed */
    recursiveCleanElement(data) {
        if (!data.content) {
            return false;
        } else if (data.content.length == 0) {
            return true;
        } else {
            return this.recursiveCleanData(data.content);
        }
    }

    /* return false if element is good or true if element must be removed */
    recursiveCleanData(dataArray) {
        for (let i = dataArray.length - 1; i >= 0; --i) {
            if (this.recursiveCleanElement(dataArray[i])) {
                dataArray.splice(i, 1);
            }
        }
        return dataArray.length == 0;
    }

    printDescription(description, tabName) {
        return (<div className="root-container" style={{ pageBreakInside: "avoid" }}>
            <h4><strong><span>{tabName ? tabName + " - " : ""}</span><FormattedMessage
                id="PRINTCARD_DESCRIPTION"
                defaultMessage="Description (comment)"
                description="Description of subject" /></strong></h4>
            <table>
                <tbody>
                    <tr>
                        <td><div dangerouslySetInnerHTML={{ __html: printValue(description) }} style={{ minHeight: "150px" }}></div></td>
                    </tr>
                </tbody>
            </table>
        </div>);
    }

    createTables(formattedTableData) {
        return (<div>
            <div className="root-container" style={{ overflow: "hidden" }}>
                <div id="qrcode" style={{ paddingRight: "5px", float: "left" }}></div>
                <h3>{formattedTableData.title}</h3>
            </div>
            {formattedTableData.data.map((element, index) => this.createElement(element))}
        </div>);
    }

    checkPrintReady() {
        for (let id in this.cardIdMap) {
            let data = this.props.data[id];
            if (!data || !data.$class) {
                return false;
            }
            if (this.props.layoutStatus[data.$class] != STATUS_READY) {
                return false;
            }
        }
        return true;
    }

    printQRCode(element) {
        if (this.props.qrcode == false || !element) {
            return;
        }
        element.innerHTML = "";
        new QRCode(element, {
            text: document.location.href.replace("printcard", "objectcard"),
            width: 128,
            height: 128
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let data = this.props.data[this.props.id];
        if (!data || !data.$class) {
            return;
        }
        let layout = this.props.layout[data.$class];
        if (!layout) {
            return;
        }
        let element = document.getElementById("qrcode");
        this.printQRCode(element);
        if (element && !this.printed && this.props.layoutStatus && this.checkPrintReady()) {
            this.printed = true;
            window.print();
        }
    }

    render() {
        let data = this.props.data[this.props.id];
        if (!data || !data.$class) {
            return null;
        }
        let layout = this.props.layout[data.$class];
        if (!layout) {
            return null;
        }

        this.loading = false;
        let parsedNodes = [];
        this.printedCards = { [this.props.id]: true };
        this.state.directories = [];
        this.parseNode(this.props.id, null, layout.rootId, parsedNodes, parsedNodes);
        this.recursiveCleanData(parsedNodes);
        if (parsedNodes.length == 0 || this.loading || !this.checkPrintReady()) {
            return null;
        }
        let tables = this.createTables({ title: data["$label"], description: data["$description"], fragment: data["$fragment"], data: parsedNodes });
        return tables;

    }
}

export default PrintCard;