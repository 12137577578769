import React from 'react';
import { FormattedMessage } from 'react-intl';
import listener from 'react-contextmenu/modules/globalEventListener';
import { ContextMenu, MenuItem, SubMenu } from 'react-contextmenu';
import { SOURCE_TREE, TARGET_TREE } from '../../constants/rpasetpoints';
import { MENU_ID } from '../../constants/navtree';

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nodeId: null
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.showLinkedElement = this.showLinkedElement.bind(this);
    }

    componentDidMount() {
        this.listenId = listener.register(this.handleShow, this.handleHide);
    }

    componentWillUnmount() {
        if (this.listenId) {
            listener.unregister(this.listenId);
        }
    }

    handleShow(e) {
        if (this.props.sourceId == SOURCE_TREE) {
            return;
        }
        let node = this.props.nodeById[e.detail.data.nodeId];
        if (!node || node.folder || !node.comparedId) {
            node = null;
        }
        this.setState(Object.assign({}, this.state, { node }));
    }

    handleHide() {
        this.setState(Object.assign({}, this.state, { node: null }));
    }

    showLinkedElement() {
        if (!this.state.node) {
            return;
        }
        this.props.showLinkedElement(this.state.node.id, TARGET_TREE);
    }

    generateFindLinkedNodeMenuItem() {
        return (
            <MenuItem key={"findLinked"} onClick={this.showLinkedElement} >
                <span>
                    <i className="fa fa-search fa-fw"></i>
                    <FormattedMessage
                        id="RPA_SETPOINTS_SHOW_LINKED_ELEMENT"
                        defaultMessage="Show linked element"
                        description="User should click this item to open linked node in compared tree" />
                </span>
            </MenuItem>);
    }

    render() {
        if (!this.props.treeComponentId || this.props.sourceId == SOURCE_TREE) {
            return null;
        }
        let style = {};
        if (!this.state.node) {
            style.display = "none";
        }
        return (
            <ContextMenu style={style} id={MENU_ID + this.props.treeComponentId} >
                {this.state.node && this.generateFindLinkedNodeMenuItem()}
            </ContextMenu>)
    }
}

export default Menu;

