import React from 'react';
import FileDrop from '../filedrop/filedrop.jsx';
import { READY } from '../../constants/rpasetpoints';
import DebounceInput from '../debounceinput.jsx';
import { FormattedMessage } from 'react-intl';

class FileImporter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            importFile: null
        }

        this.uploadFile = this.uploadFile.bind(this);
    }

    uploadFile({ files }) {
        this.setState(Object.assign({}, this.state, { importFile: files[0].name }));
        this.props.uploadFile(files[0]);
    }

    printUploadedFile(fileName) {
        return (
            <div className="npt-rpasetpoints-fileuploaded">
                <span>{fileName}</span><i className='fa fa-fw fa-check'></i>
            </div>
        );
    }

    printUpload(label, uploadFunc, uploadedFile) {
        return (
            <div className={"npt-rpasetpoints-filedrop" + (this.props.uploadState == READY ? " uploaded" : "")}>
                <div className="npt-rpasetpoints-filedrop-label">{label}</div>
                {this.props.uploadState == READY ?
                    this.printUploadedFile(uploadedFile)
                    :
                    <FileDrop
                        multiple={false}
                        filedrop={{ filesToUpload: [] }}
                        registerUpload={uploadFunc}
                    />
                }
            </div>
        );
    }

    render() {
        return (
            <div className={"npt-rpasetpoints-files"}>
                <DebounceInput
                    editable={!this.state.importFile}
                    valid={true}
                    value={this.props.fileSha1}
                    className={"form-control"}
                    change={this.props.changeFileSha1}
                    label={"RPA_SETPOINTS_FILE_SHA1"}
                >
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">
                            <FormattedMessage
                                id="RPA_SETPOINTS_FILE_SHA1"
                                defaultMessage="File sha1"
                                description="Label of file sha1 input field" />
                        </span>
                    </div>
                </DebounceInput>
                {this.printUpload(this.props.messages["RPA_SETPOINTS_UPLOAD_FILE"], this.uploadFile, this.state.importFile)}
            </div>
        );
    }
}

export default FileImporter;