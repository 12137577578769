export function correctServerTimestamp(timestamp, timeDifference){
    if(!timestamp || !timeDifference){
        return timestamp;
    }
    const isString = typeof timestamp == "string";
    if(isString){
        let parsedTimestamp = Date.parse(timestamp);
        if(isNaN(parsedTimestamp)){
            console.error(`Can't parse timestamp "${timestamp}"`)
            return timestamp;
        }
        timestamp = parsedTimestamp;
    }
    timestamp += timeDifference;
    if(isString){
        timestamp = new Date(timestamp).toISOString();
    }
    return timestamp;
}