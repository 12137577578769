import * as Action from '../constants/table';
import shortid from 'shortid';
import { LOCATION } from '../constants/location';
import { I18N } from '../constants/i18n';
import { PATH_TO_GANT, CHOSEN_DATE, PLANNED_DATE } from '../constants/gant';
import { changeHash, getHashData } from './location'
import { downloadFile, getData } from '../services/table';
import { retrieveFunction } from '../services/automation';
import { getResult, isRdfId } from '../services/finder'
import { copyTextToClipboard } from '../services/clipboard';
import { addAlert, addCustomAlert } from './alert';
import { ALERT_SUCCESS, ALERT_DANGER, ALERT_WARNING, ALERT_INFO } from '../constants/alert';
import { BASE_FETCH_URL } from '../services/npt-treebeard';
import { getRandomRGB } from '../services/gant';
import { referenceRelationTypeList } from '../constants/finder';

export const BASE_TABLE_HEADER_URL = "/rest/table/header";
export const BASE_TABLE_INFO_URL = "/rest/table/info";
export const BASE_TABLE_DATA_URL = "/rest/table/data";
export const BASE_TABLE_FILTER_URL = "/rest/table/filter";
export const FINDER_CLASS_LEVELS_URL = "/rest/finder/classLevels";
export const FINDER_CLASSES_URL = "/rest/finder/classes";
export const FINDER_FRAGMENT_LEVELS_URL = "/rest/finder/levels";
export const FINDER_FRAGMENT_URL = "/rest/finder/fragments";
export const FINDER_CRITERIA_URL = "/rest/finder/criteria";
export const FINDER_DETAILED_CRITERIA_URL = "/rest/finder/detailed/criteria";
export const FINDER_PREDICATE_URL = "/rest/finder/predicate";
export const SUBJECT_HEADER_URL = "/rest/subject/header";

function cleanObject(object) {
    for (let p in object) {
        if (typeof object[p] == "undefined" || object[p] == null || object[p] == "") {
            delete (object[p]);
        }
    }
}

export function composeUrl(baseUrl, tableId, parameters) {
    let url = tableId;
    if (!url.startsWith("/")) {
        url = "/" + url;
    }
    let params = "";
    if (parameters) {
        cleanObject(parameters);
        params = "?" + $.param(parameters);
    }
    return `${baseUrl}${url}${params}`;
}

function composeSubjectHeaderUrl(rdfId, namespace) {
    if (namespace) {
        return `${SUBJECT_HEADER_URL}/${namespace}/${rdfId}`;
    }
    return `${SUBJECT_HEADER_URL}/${rdfId}`;
}

export function parseStringResponse(link) {
    if (!link) {
        return null;
    }
    if (link[0] == '"' && link[link.length - 1] == '"') {
        return link.substring(1, link.length - 1);
    }
    return link;
}

///////////
//Utility//
///////////
function getTableData(globalState, path) {
    const tableStateMap = globalState[Action.TABLE];
    return tableStateMap[path];
}

function getDataParams(tableData, hashParams, optional = {}) {
    let params = Object.assign({}, optional);
    if (!tableData) {
        return Object.assign(params, hashParams);
    }
    if (tableData.filterLink) {
        params._filter = tableData.filterLink;
    }
    if (!tableData.parameters) {
        return Object.assign(params, hashParams);
    }
    for (let p in tableData.parameters) {
        if (typeof optional[p] != "undefined") {
            params[p] = optional[p];
        } else {
            params[p] = hashParams[p] || tableData.fields[p];
        }
    }
    return params;
}

function findElementsInArray(array, elements, compareFunc, callbackFunc) {
    elements = elements.slice();
    for (let i = 0; i < array.length; ++i) {
        for (let j = 0; j < elements.length; ++j) {
            if (!compareFunc(array[i], elements[j])) {
                continue;
            }
            callbackFunc(array[i], i);
            elements.splice(j, 1);
            break;
        }
    }
}

function parseGantOptions(gantOptions) {
    if (gantOptions.gantGroups && gantOptions.gantGroups.group) {
        gantOptions.groups = gantOptions.gantGroups.group;
        for (let group of gantOptions.groups) {
            if (!group.color) {
                group.color = getRandomRGB();
            }
        }
    }
}

function ajaxFetchInfo(tableId, params, dispatch) {
    $.get(composeUrl(BASE_TABLE_INFO_URL, tableId, params), function (data) {
        if (data.header.gantOptions) {
            parseGantOptions(data.header.gantOptions);
        }
        dispatch(infoReceived(tableId, data));
        if (!data.header.finderOptions) {
            return;
        }
        dispatch(parseFinderOptions(tableId, data.header.finderOptions));
        if (data.header.finderOptions.classTree) {
            ajaxFetchClassLevels(tableId, data.header.finderOptions.classTree, dispatch);
        }
        if (data.header.finderOptions.fragmentTree) {
            ajaxFetchFragmentLevels(tableId, data.header.finderOptions.fragmentTree, dispatch);
        }
        if (params._filter || data.header.finderOptions.initialConditions) {
            dispatch(importFinder(tableId, data.data.fields.__filter, data.header.finderOptions, data.header.column, params._filter, data.header.finderOptions.initialConditions));
        }
    }).fail(function (error) {
        dispatch(infoErrorReceived(tableId, error));
    });
}

function ajaxFetchData(tableId, params, dispatch) {
    $.get(composeUrl(BASE_TABLE_DATA_URL, tableId, params), function (data) {
        dispatch(dataReceived(tableId, data));
    }).fail(function (error) {
        dispatch(dataErrorReceived(tableId, error));
    });
}

function ajaxFetchPage(tableId, params, dispatch) {
    $.get(composeUrl(BASE_TABLE_DATA_URL, tableId, params), function (data) {
        dispatch(pageReceived(tableId, params._p, params._ps, data));
    }).fail(function (error) {
        dispatch(pageErrorReceived(tableId, params._p, error));
    });
}

function getColumnList(headers) {
    let columnList;
    if (headers) {
        columnList = [];
        for (let column of headers) {
            if (column.autoGenerated || !column.field || column.hidden) {
                continue;
            }
            columnList.push(column.field);
        }
    } else {
        columnList = null;
    }
    return columnList;
}

/* Get dynamic headers from column list */
function makeHeaders(columns, columnList) {
    let headers = [];
    let columnMap = {};
    findElementsInArray(columns, columnList, function (column, columnId) {
        return column.field == columnId;
    }, function (column, idx) {
        if (column.dynamic && column.hidden) {
            column.hidden = false;
        }
        columnMap[column.field] = column;
    });
    for (let columnId of columnList) {
        if (!columnMap[columnId]) {
            continue;
        }
        headers.push(columnMap[columnId]);
    }
    return headers;
}

/**
 * Finder
 */
function ajaxFetchClassLevels(path, classTree, dispatch) {
    const url = composeUrl(FINDER_CLASS_LEVELS_URL, classTree);
    $.get(url, function (data) {
        dispatch(classLevelsReceived(path, data));
    }).fail(function (error) {
        console.error("Failed to download class levels: ", error);
    });
}

function ajaxFetchClasses(path, classTree, levelId, dispatch) {
    const url = composeUrl(FINDER_CLASSES_URL, classTree, { node: levelId });
    dispatch(waitForClasses(path, levelId));
    return $.get(url, function (data) {
        dispatch(classesReceived(path, levelId, data));
    }).fail(function (error) {
        console.error("Failed to download classes: ", error);
    });
}

function ajaxFetchFragmentLevels(path, fragmentsPath, dispatch) {
    const url = composeUrl(FINDER_FRAGMENT_LEVELS_URL, fragmentsPath);
    $.get(url, function (data) {
        dispatch(fragmentLevelsReceived(path, data));
    }).fail(function (error) {
        console.error("Failed to download fragment levels: ", error);
    });
}

function ajaxFetchFragments(path, fragmentTree, parentId, dispatch) {
    const url = composeUrl(FINDER_FRAGMENT_URL, fragmentTree, parentId ? { node: parentId } : null);
    dispatch(waitForFragments(path, parentId));
    return $.get(url, function (data) {
        dispatch(fragmentsReceived(path, parentId, data));
    }).fail(function (error) {
        console.error("Failed to download fragments: ", error);
    });
}

function ajaxFetchFields(path, criteriaTree, parentId, dispatch) {
    let params = {};
    if (parentId) {
        params.node = parentId;
    }
    const url = composeUrl(FINDER_CRITERIA_URL, criteriaTree, params);
    dispatch(waitForField(path, parentId))
    return $.get(url, function (data) {
        dispatch(fieldsReceived(path, parentId, data));
    }).fail(function (error) {
        console.error("Failed to download fields: ", error);
    });
}

function ajaxFetchObjectcard(path, rdfId, dispatch) {
    const url = composeSubjectHeaderUrl(rdfId);
    return $.get(url, function (data) {
        dispatch(objectcardReceived(path, rdfId, data));
    }).fail(function (error) {
        dispatch(objectcardErrorReceived(path, rdfId, error));
        if (error.status == 403) {
            dispatch(addAlert(ALERT_DANGER, { id: "FINDER_DOWNLOAD_SUBJECT_HEADER_ACCESS_DENIED" }));
        } else {
            dispatch(addAlert(ALERT_DANGER, { id: "FINDER_DOWNLOAD_SUBJECT_HEADER_ERROR" }));
        }
        console.error("Failed to download objectcard header: ", error);
    });
}

function ajaxFetchNodeChildren(path, criteriaTree, parentId, dispatch) {
    let params = {};
    if (parentId) {
        params.node = parentId;
    }
    const url = composeUrl(FINDER_DETAILED_CRITERIA_URL, criteriaTree, params);
    dispatch(waitForField(path, parentId))
    return $.get(url, function (data) {
        dispatch(fieldsReceived(path, parentId, data));
        return data;
    }).fail(function (error) {
        console.error("Failed to download fields: ", error);
        return null;
    });
}

function checkLoadedFieldsForPredicates(tableId, loadedFields, predicateList, afterFetch, dispatch) {
    for (let i = predicateList.length - 1; i >= 0; --i) {
        if (!loadedFields.idByPredicateName[predicateList[i]]) {
            continue;
        }
        const fieldId = loadedFields.idByPredicateName[predicateList[i]];
        ajaxFetchPredicate(tableId, loadedFields.byId[fieldId], dispatch).then(afterFetch);
        predicateList.splice(i, 1);
    }
}

function checkForPredicates(tableId, fields, predicateList, afterFetch, dispatch) {
    for (let field of fields) {
        if (!field.predicate) {
            continue;
        }
        for (let i = 0; i < predicateList.length; ++i) {
            if (getPredicateName(field) != predicateList[i]) {
                continue;
            }
            field.joinIdPredicate = getPredicateName(field);
            ajaxFetchPredicate(tableId, field, dispatch).then(afterFetch);
            predicateList.splice(i, 1);
            break;
        }
    }
}

function removeAlreadyFetchedElements(fetchingList, fetched) {
    for (let i = fetchingList.length - 1; i >= 0; --i) {
        if (fetched[fetchingList[i]]) {
            fetchingList.splice(i, 1);
        }
    }
}

function removeDuplicates(list) {
    if (!Array.isArray(list)) {
        return [];
    }
    return [...new Set(list)];
}

function recursiveFetchCriteriaTypes(tableId, criteriaTree, fetchMap, nodeId, dispatch, callback) {
    const fetchedMap = {};
    fetchedMap[nodeId] = [];
    ajaxFetchNodeChildren(tableId, criteriaTree, nodeId, dispatch).done(function (children) {
        let counter = 0;
        for (let node of children) {
            fetchedMap[nodeId].push(node);
            if (!fetchMap.childrenMap[node.typeId]) {
                continue;
            }
            ++counter;
            recursiveFetchCriteriaTypes(tableId, criteriaTree, fetchMap.childrenMap[node.typeId], node.id, dispatch, function (fetchedNodeMap) {
                Object.assign(fetchedMap, fetchedNodeMap);
                --counter;
                if (counter == 0) {
                    callback(fetchedMap);
                }
            });
        }
        if (counter == 0) {
            callback(fetchedMap);
            return;
        }
    }).fail(function () {
        callback({});
    });
}

function downloadNecessaryInitialCriteria(tableId, finderOptions, initialConditions, dispatch, callback) {
    if (!finderOptions || !finderOptions.criteriaTree || !Array.isArray(initialConditions)) {
        callback({});
        return;
    }

    const fetchMap = {
        root: true,
        childrenMap: {}
    };

    for (let orCondition of initialConditions) {
        if (!Array.isArray(orCondition.andConditions)) {
            continue;
        }
        for (let andCondition of orCondition.andConditions) {
            if (!andCondition.path) {
                continue;
            }
            const parts = andCondition.path.split(",");
            let fetchObj = fetchMap;
            for (let type of parts) {
                if (!fetchObj.childrenMap[type]) {
                    fetchObj.childrenMap[type] = {
                        type: type,
                        childrenMap: {}
                    };
                }
                fetchObj = fetchObj.childrenMap[type];
            }
        }
    }

    recursiveFetchCriteriaTypes(tableId, finderOptions.criteriaTree, fetchMap, null, dispatch, function (fetchedIdMap) {
        callback(fetchedIdMap);
    });
}

function getRelationParameters(relation, type) {
    if (type == "between") {
        const values = relation.value ? relation.value.split(",") : "";
        return [values[0] || "", values[1] || ""];
    }
    return [relation.value || ""];
}

/* Transform strings like "TEST_TEST" to camel case: "testTest" */
function upperToCamelCase(string) {
    return string.toLowerCase().replace(/_(.)/g, function ($1) { return $1.toUpperCase(); }).replace(/_/g, "");
}

function addInitialOrCondition(orBlocks, orCondition) {
    if (!Array.isArray(orCondition.andConditions)) {
        return;
    }
    const andPredicates = [];
    for (let andCondition of orCondition.andConditions) {
        if (!Array.isArray(andCondition.relations)) {
            continue;
        }
        const andPredicate = {
            predicate: andCondition.predicate,
            orConditions: []
        };
        for (let relation of andCondition.relations) {
            if (!relation.type) {
                continue;
            }
            const type = upperToCamelCase(relation.type);
            andPredicate.orConditions.push({
                operator: type,
                parameters: getRelationParameters(relation, type)
            });
        }
        andPredicates.push(andPredicate);
    }
    orBlocks.push({
        andPredicates: andPredicates
    });
}

function composeFinderData(finderData, initialConditions, fetchedCriteriaMap) {
    if (!Array.isArray(initialConditions)) {
        return finderData;
    }
    const existionMap = {};
    if (!finderData) {
        finderData = {
            finder: {
                orBlocks: []
            }
        };
    } else {
        finderData = Object.assign({}, finderData);
    }
    if (!finderData.finder) {
        finderData.finder = {
            orBlocks: []
        };
    } else {
        finderData.finder = Object.assign({}, finderData.finder);
    }
    if (finderData.finder.criteriaFetchList) {
        const filteredCriteriaFetchList = [];
        for (let criteriaId of finderData.finder.criteriaFetchList) {
            if (fetchedCriteriaMap[criteriaId]) {
                continue;
            }
            filteredCriteriaFetchList.push(criteriaId);
        }
        finderData.finder.criteriaFetchList = filteredCriteriaFetchList;
    }
    if (!finderData.finder.orBlocks) {
        finderData.finder.orBlocks = [];
    } else {
        finderData.finder.orBlocks = finderData.finder.orBlocks.slice();
    }
    for (let orBlock of finderData.finder.orBlocks) {
        if (orBlock.andPredicates.length != 1) {
            continue;
        }
        existionMap[orBlock.andPredicates[0].predicate] = true;
    }
    for (let orCondition of initialConditions) {
        if (existionMap[orCondition.predicate]) {
            continue;
        }
        addInitialOrCondition(finderData.finder.orBlocks, orCondition);
    }
    return finderData;
}

function downloadNecessaryObjectcard(tableId, tableData, finderData, dispatch) {
    if (!finderData || !finderData.finder) {
        return;
    }

    const fetchList = getRdfIdList(finderData.finder);
    const fetchedMap = tableData && tableData.finder && tableData.finder.loadedObjectcards && tableData.finder.loadedObjectcards.fetched || {};
    for (let rdfId of fetchList) {
        if (fetchedMap[rdfId]) {
            return;
        }
        ajaxFetchObjectcard(tableId, rdfId, dispatch);
    }
}

function downloadNecessaryFields(tableId, tableData, finderOptions, finderData, fetchedCriteriaMap, dispatch, afterCallback) {
    if (!finderData || !finderData.finder) {
        afterCallback();
        return;
    }

    if (!finderData.finder.criteriaFetchList) {
        finderData.finder.criteriaFetchList = [];
    }
    if (!finderData.finder.fragmentFetchList) {
        finderData.finder.fragmentFetchList = [];
    }
    if (!finderData.finder.classLevelFetchList) {
        finderData.finder.classLevelFetchList = [];
    }

    let predicateList = getPredicateList(finderData.finder);
    if (predicateList.length != 0) {
        finderData.finder.criteriaFetchList.unshift(null);
    }
    if (finderData.finder.fragmentList && finderData.finder.fragmentList.length != 0) {
        finderData.finder.fragmentFetchList.unshift(null);
    }

    if (fetchedCriteriaMap) {
        removeAlreadyFetchedElements(finderData.finder.criteriaFetchList, fetchedCriteriaMap);
    }
    if (tableData.finder && tableData.finder.loadedFields && tableData.finder.loadedFields.fetched) {
        removeAlreadyFetchedElements(finderData.finder.criteriaFetchList, tableData.finder.loadedFields.fetched);
    }
    if (tableData.finder && tableData.finder.loadedFragments && tableData.finder.loadedFragments.fetched) {
        removeAlreadyFetchedElements(finderData.finder.fragmentFetchList, tableData.finder.loadedFragments.fetched);
    }
    if (tableData.finder && tableData.finder.loadedClasses && tableData.finder.loadedClasses.fetched) {
        removeAlreadyFetchedElements(finderData.finder.classLevelFetchList, tableData.finder.loadedClasses.fetched);
    }
    if (tableData.finder && tableData.finder.loadedPredicates && tableData.finder.loadedPredicates.byName) {
        removeAlreadyFetchedElements(predicateList, tableData.finder.loadedPredicates.byName);
    }
    finderData.finder.criteriaFetchList = removeDuplicates(finderData.finder.criteriaFetchList);
    finderData.finder.fragmentFetchList = removeDuplicates(finderData.finder.fragmentFetchList);
    finderData.finder.classLevelFetchList = removeDuplicates(finderData.finder.classLevelFetchList);
    predicateList = removeDuplicates(predicateList);

    let downloaded = 0;
    let total = finderData.finder.criteriaFetchList.length
        + finderData.finder.fragmentFetchList.length
        + finderData.finder.classLevelFetchList.length
        + predicateList.length;

    if (total == 0) {
        afterCallback();
        return;
    }

    const afterFetch = function () {
        ++downloaded;
        if (downloaded == total) {
            afterCallback();
        }
    }
    if (tableData.finder && tableData.finder.loadedFields) {
        checkLoadedFieldsForPredicates(tableId, tableData.finder.loadedFields, predicateList, afterFetch, dispatch);
    }
    if (finderOptions.criteriaTree) {
        for (let fieldId of finderData.finder.criteriaFetchList) {
            if (fetchedCriteriaMap[fieldId]) {
                if (predicateList.length != 0) {
                    checkForPredicates(tableId, fetchedCriteriaMap[fieldId], predicateList, afterFetch, dispatch);
                }
                afterFetch();
                continue;
            }
            ajaxFetchFields(tableId, finderOptions.criteriaTree, fieldId, dispatch).then(function (fields) {
                if (predicateList.length == 0) {
                    return;
                }
                checkForPredicates(tableId, fields, predicateList, afterFetch, dispatch);
            }).then(afterFetch)
        }
    } else {
        console.error("Table model doesn't have criteria tree path.");
    }

    if (finderOptions.fragmentTree) {
        for (let fragmentId of finderData.finder.fragmentFetchList) {
            ajaxFetchFragments(tableId, finderOptions.fragmentTree, fragmentId, dispatch).then(afterFetch);
        }
    } else {
        console.error("Table model doesn't have fragment tree path.");
    }

    if (finderOptions.classTree) {
        for (let classLevelId of finderData.finder.classLevelFetchList) {
            ajaxFetchClasses(tableId, finderOptions.classTree, classLevelId, dispatch).then(afterFetch);
        }
    } else {
        console.error("Table model doesn't have class tree path.");
    }
}

function getRdfIdList(finder) {
    if (!finder.orBlocks) {
        return [];
    }
    let valuesMap = {};
    let valuesList = [];
    for (let orBlock of finder.orBlocks) {
        if (!orBlock.andPredicates) {
            continue;
        }
        for (let andPredicate of orBlock.andPredicates) {
            if (!andPredicate.orConditions) {
                continue;
            }
            for (let orCondition of andPredicate.orConditions) {
                if (orCondition.operator != referenceRelationTypeList[0].operator || !Array.isArray(orCondition.parameters)) {
                    continue;
                }
                const value = orCondition.parameters[0];
                if (!value || valuesMap[value] || !isRdfId(value)) {
                    continue;
                }
                valuesMap[value] = true;
                valuesList.push(value);
            }
        }
    }
    return valuesList;
}

function getPredicateList(finder) {
    if (!finder.orBlocks) {
        return [];
    }
    let predicateMap = {};
    let predicateList = [];
    for (let orBlock of finder.orBlocks) {
        if (!orBlock.andPredicates) {
            continue;
        }
        for (let andPredicate of orBlock.andPredicates) {
            if (andPredicate.predicate && !predicateMap[andPredicate.predicate]) {
                predicateMap[andPredicate.predicate] = true;
                predicateList.push(andPredicate.predicate);
            }
        }
    }
    return predicateList;
}

function getPredicateName(field) {
    if (field.joinId) {
        return field.joinId + "[" + field.predicate + "]";
    }
    return field.predicate;
}

function ajaxFetchPredicate(path, field, dispatch) {
    let params = {
        name: field.predicate
    };
    const url = FINDER_PREDICATE_URL + "?" + $.param(params);
    return $.get(url, function (data) {
        dispatch(predicateReceived(path, field.id, field.joinIdPredicate, data));
    }).fail(function (error) {
        console.error("Failed to download predicate: ", error);
    });
}

function ajaxUploadFilter(contextPath, filter) {
    return $.ajax({
        url: BASE_TABLE_FILTER_URL,
        method: "POST",
        dataType: 'text',
        processData: false,
        data: filter,
        headers: { 'Content-Type': 'application/json' }
    }).done(function (filterLink) {
        return parseStringResponse(filterLink);
    }).fail(function (error) {
        return null;
    })
}


/////////////////
//Plain actions//
/////////////////
function waitForInfo(tableId) {
    return {
        type: Action.WAIT_FOR_INFO,
        payload: { tableId }
    }
}

function infoReceived(tableId, info) {
    return {
        type: Action.INFO_RECEIVED,
        payload: { tableId, info }
    }
}

function infoErrorReceived(tableId, error) {
    return {
        type: Action.INFO_ERROR_RECEIVED,
        payload: { tableId, error }
    }
}

function waitForData(tableId) {
    return {
        type: Action.WAIT_FOR_DATA,
        payload: { tableId }
    }
}

function dataReceived(tableId, data) {
    return {
        type: Action.DATA_RECEIVED,
        payload: { tableId, data }
    }
}

function dataErrorReceived(tableId, error) {
    return {
        type: Action.DATA_ERROR_RECEIVED,
        payload: { tableId, error }
    }
}

function waitForPage(tableId) {
    return {
        type: Action.WAIT_FOR_PAGE,
        payload: { tableId }
    }
}

function pageReceived(tableId, page, pageSize, data) {
    return {
        type: Action.PAGE_RECEIVED,
        payload: { tableId, page, pageSize, data }
    }
}

function pageErrorReceived(tableId, page, error) {
    return {
        type: Action.PAGE_ERROR_RECEIVED,
        payload: { tableId, page, error }
    }
}

export function initializeTableFields(tableId, fields) {
    return {
        type: Action.INITIALIZE_TABLE_FIELDS,
        payload: { tableId, fields }
    }
}

export function resetTableFields(tableId) {
    return {
        type: Action.RESET_TABLE_FIELDS,
        payload: { tableId }
    }
}

export function sortColumn(tableId, column) {
    return {
        type: Action.SORT_COLUMN,
        payload: { tableId, column }
    }
}

export function filterColumn(tableId, column, filter) {
    return {
        type: Action.FILTER_COLUMN,
        payload: { tableId, column, filter }
    }
}

export function selectRow(tableId, key) {
    return {
        type: Action.SELECT_ROW,
        payload: { tableId, key }
    }
}

export function selectAllRows(tableId, select) {
    return {
        type: Action.SELECT_ALL_ROWS,
        payload: { tableId, select }
    }
}

export function changeViewType(tableId, viewType) {
    return {
        type: Action.CHANGE_VIEW_TYPE,
        payload: { tableId, viewType }
    }
}

export function changeSelectedGroup(tableId, group) {
    return {
        type: Action.CHANGE_SELECTED_GROUP,
        payload: { tableId, group }
    }
}

export function changeItem(tableId, type, rowIndex, index, from, to) {
    return {
        type: Action.CHANGE_ITEM,
        payload: { tableId, type, rowIndex, index, from, to }
    }
}

export function addItem(tableId, rowIndex, from, to) {
    return {
        type: Action.ADD_ITEM,
        payload: { tableId, rowIndex, from, to }
    }
}

export function removeItem(tableId, type, rowIndex, index) {
    return {
        type: Action.REMOVE_ITEM,
        payload: { tableId, type, rowIndex, index }
    }
}

export function sucessfulSave(tableId) {
    return {
        type: Action.SAVE_GANT,
        payload: { tableId }
    }
}

export function plainChangePageSize(tableId, pageSize) {
    return {
        type: Action.CHANGE_PAGE_SIZE,
        payload: { tableId, pageSize }
    }
}

export function plainChangePage(tableId, page) {
    return {
        type: Action.CHANGE_PAGE,
        payload: { tableId, page }
    }
}

/**
 * Finder
 */
export function changeFilterLink(path, filterLink) {
    return {
        type: Action.CHANGE_FILTER_LINK,
        payload: { path, filterLink }
    }
}

export function storeFinderFilter(path, finderFilter) {
    return {
        type: Action.STORE_FINDER_FILTER,
        payload: { path, finderFilter }
    }
}

function plainUpdateHeaders(path, headers) {
    return {
        type: Action.UPDATE_HEADERS,
        payload: { path, headers }
    }
}

function classLevelsReceived(path, levels) {
    return {
        type: Action.CLASS_LEVELS_RECEIVED,
        payload: { path, levels }
    }
}

function waitForClasses(path, levelId) {
    return {
        type: Action.WAIT_FOR_CLASSES,
        payload: { path, levelId }
    }
}

function classesReceived(path, levelId, classes) {
    return {
        type: Action.CLASSES_RECEIVED,
        payload: { path, levelId, classes }
    }
}

function fragmentLevelsReceived(path, levels) {
    return {
        type: Action.FRAGMENT_LEVELS_RECEIVED,
        payload: { path, levels }
    }
}

function waitForFragments(path, parentId) {
    return {
        type: Action.WAIT_FOR_FRAGMENTS,
        payload: { path, parentId }
    }
}

function fragmentsReceived(path, parentId, fragments) {
    return {
        type: Action.FRAGMENTS_RECEIVED,
        payload: { path, parentId, fragments }
    }
}

function waitForField(path, parentId, data) {
    return {
        type: Action.WAIT_FOR_FIELD,
        payload: { path, parentId, data }
    }
}

function fieldsReceived(path, parentId, data) {
    return {
        type: Action.FIELDS_RECEIVED,
        payload: { path, parentId, data }
    }
}

function objectcardReceived(path, rdfId, data) {
    return {
        type: Action.OBJECTCARD_RECEIVED,
        payload: { path, rdfId, data }
    }
}

function objectcardErrorReceived(path, rdfId, error) {
    return {
        type: Action.OBJECTCARD_ERROR_RECEIVED,
        payload: { path, rdfId, error }
    }
}

function predicateReceived(path, fieldId, predicateName, data) {
    return {
        type: Action.PREDICATE_RECEIVED,
        payload: { path, fieldId, predicateName, data }
    }
}

export function toggleHidden(path, hidden) {
    return {
        type: Action.TOGGLE_HIDDEN,
        payload: { path, hidden }
    }
}

export function changeFinderViewType(path, viewType) {
    return {
        type: Action.CHANGE_FINDER_VIEW_TYPE,
        payload: { path, viewType }
    }
}

export function changeCriteriaRelation(path, criteriaId, relation, relationIdx) {
    return {
        type: Action.CHANGE_CRITERIA_RELATION,
        payload: { path, criteriaId, relation, relationIdx }
    }
}

export function addCriteriaRelation(path, criteriaId) {
    return {
        type: Action.ADD_CRITERIA_RELATION,
        payload: { path, criteriaId }
    }
}

export function lockCriteriaRelations(path, criteriaId) {
    return {
        type: Action.LOCK_CRITERIA_RELATIONS,
        payload: { path, criteriaId }
    }
}

export function unlockCriteriaRelations(path, criteriaId) {
    return {
        type: Action.UNLOCK_CRITERIA_RELATIONS,
        payload: { path, criteriaId }
    }
}

export function plainRemoveCriteriaRelation(path, criteriaId, relationIdx) {
    return {
        type: Action.REMOVE_CRITERIA_RELATION,
        payload: { path, criteriaId, relationIdx }
    }
}

export function plainChangeCriteriaField(path, criteriaId, fieldId) {
    return {
        type: Action.CHANGE_CRITERIA_FIELD,
        payload: { path, criteriaId, fieldId }
    }
}

export function addCriteria(path, criteriaGroupId) {
    return {
        type: Action.ADD_CRITERIA,
        payload: { path, criteriaGroupId }
    }
}

export function plainRemoveCriteria(path, criteriaId) {
    return {
        type: Action.REMOVE_CRITERIA,
        payload: { path, criteriaId }
    }
}

export function removeCriteriaGroup(path, criteriaGroupId) {
    return {
        type: Action.REMOVE_CRITERIA_GROUP,
        payload: { path, criteriaGroupId }
    }
}

export function plainImportFinder(path, finderData) {
    return {
        type: Action.IMPORT_FINDER,
        payload: { path, finderData }
    }
}

export function parseFinderOptions(path, finderOptions) {
    return {
        type: Action.PARSE_FINDER_OPTIONS,
        payload: { path, finderOptions }
    }
}

export function selectFragment(path, oldFragmentId, newFragmentId) {
    return {
        type: Action.SELECT_FRAGMENT,
        payload: { path, oldFragmentId, newFragmentId }
    }
}

export function selectClass(path, oldClassId, newClassId, level) {
    return {
        type: Action.SELECT_CLASS,
        payload: { path, oldClassId, newClassId, level }
    }
}

///////////
//Actions//
///////////
export function fetchData(tableId, params, { force, fields }) {
    return function (dispatch, getState) {
        const globalState = getState();
        const tableData = globalState[Action.TABLE][tableId];
        const hashParams = globalState[LOCATION].params;
        const dataParams = getDataParams(tableData, hashParams, Object.assign({ _ps: tableData && tableData.pageSize }, params));
        if (!tableData) {
            dispatch(waitForInfo(tableId))
            ajaxFetchInfo(tableId, dataParams, dispatch);
        } else {
            dispatch(waitForData(tableId))
            ajaxFetchData(tableId, dataParams, dispatch);
        }
    }
}

export function changeParameters(tableId, params) {
    return function (dispatch, getState) {
        changeHash({ params });
    }
}

export function connectedSelectRow(tableId, key, table) {
    return function (dispatch, getState) {
        const globalState = getState();
        const tableData = globalState[Action.TABLE][tableId];
        dispatch(selectRow(tableId, key));
        if (!tableData.automation || !tableData.automation.clickBindings || !tableData.automation.clickBindings[Action.NPT_TABLE_SELECT_ROW_EVENT]) {
            return;
        }
        const func = retrieveFunction(tableData.automation.clickBindings[Action.NPT_TABLE_SELECT_ROW_EVENT]);
        func(key, table);
    }
}

export function downloadReport(tableId, href, filename, data) {
    return function (dispatch, getState) {
        const globalState = getState();
        const tableData = globalState[Action.TABLE][tableId];
        let finder = tableData.finderFilter ? tableData.finderFilter : null;
        let filterLink = tableData.filterLink ? tableData.filterLink : null;
        let columnList;
        if (tableData.dynamicHeaders) {
            columnList = [];
            for (let column of tableData.dynamicHeaders) {
                if (column.autoGenerated || column.hidden || !column.field) {
                    continue;
                }
                columnList.push(column.field);
            }
        } else {
            columnList = null;
        }

        let keylist = null;
        if (data) {
            keylist = [];
            for (let row of data) {
                keylist.push(row.key);
            }
        } else if (!columnList) {
            downloadFile(href, filename, filterLink);
            return;
        }

        let doneCallback = function (filterLink) {
            filterLink = parseStringResponse(filterLink);
            console.log("Link from server:", filterLink);
            downloadFile(href, filename, filterLink);
        }

        $.ajax({
            contentType: 'application/json',
            data: JSON.stringify({
                finder: finder,
                keyList: keylist,
                columnList: columnList
            }),
            dataType: 'text',
            processData: false,
            type: 'POST',
            url: BASE_TABLE_FILTER_URL
        })
            .done(doneCallback)
            .fail(function (error) {
                /* Sometimes get error with ctatus 200 */
                if (error.status == 200) {
                    doneCallback(error.responseText);
                    return;
                }
                console.error("Error while get selection:", error);
            })
    }
}

export function buttonClick(tableId, id, table) {
    return function (dispatch, getState) {
        const globalState = getState();
        const tableData = globalState[Action.TABLE][tableId];
        if (!tableData.automation || !tableData.automation.clickBindings || !tableData.automation.clickBindings[id]) {
            return;
        }
        const func = retrieveFunction(tableData.automation.clickBindings[id]);
        func(table);
    }
}

export function saveGant(tableId) {
    return function (dispatch, getState) {
        const globalState = getState();
        const tableData = globalState[Action.TABLE][tableId];
        const hashParams = globalState[LOCATION].params;
        const dataParams = getDataParams(tableData, hashParams);
        let path = tableData.gant.saveFunction.path;
        let data = tableData.fetchedRows || Object.assign({}, tableData.data);
        let changeSet = tableData.gant.changeSet;
        if (!path) {
            if (tableData.gant.saveFunction.function) {
                tableData.gant.saveFunction.function(data, changeSet);
            }
            return;
        }
        if (!data || !changeSet) {
            return;
        }

        if (tableData.pageable && tableData.page) {
            dataParams._p = tableData.page;
            dataParams._ps = tableData.pageSize;
        }

        let headerList = [];
        for (let column of tableData.columns) {
            if (column.hidden) {
                continue;
            }
            headerList.push(column);
        }
        let gantData = {
            'dataList': data,
            'headerList': headerList,
            'changeSet': {
                chosen: changeSet[CHOSEN_DATE],
                planned: changeSet[PLANNED_DATE],

            },
            'path': path,
            'timezone': (new Date().getTimezoneOffset() / -60).toFixed(2)
        }
        $.ajax({
            url: PATH_TO_GANT + "/entity",
            method: "POST",
            dataType: "json",
            processData: false,
            data: JSON.stringify(gantData),
            headers: { 'Content-Type': 'application/json;charset=UTF-8' }
        }).done(function () {
            dispatch(addAlert(ALERT_SUCCESS, { id: "NPT_GANT_SAVE_SUCCESS" }));
            dispatch(sucessfulSave(tableId));
            ajaxFetchData(tableId, dataParams, dispatch);
        }).fail(function (error) {
            if (error.status == 200) {
                dispatch(addAlert(ALERT_SUCCESS, { id: "NPT_GANT_SAVE_SUCCESS" }));
                dispatch(sucessfulSave(tableId));
                ajaxFetchData(tableId, dataParams, dispatch);
                return;
            }
            if (error.status == 406) {
                addCustomAlert(error.responseText, dispatch);
                return;
            }
            dispatch(addAlert(ALERT_DANGER, { id: "NPT_GANT_SAVE_ERROR" }));
            console.error("Error while saving changes: ", error);
        })
    }
}

export function copySelectedRowsRefs(tableId, namespaceColumn = "namespace", rdfIdColumn = "rdfId") {
    return function (dispatch, getState) {
        const globalState = getState();
        const tableData = globalState[Action.TABLE][tableId];
        if (!tableData.selectedRows) {
            return;
        }
        const data = getData(tableData);
        const refs = [];
        for (let row of data) {
            const rdfId = row[rdfIdColumn] || row.key;
            if (!rdfId || !tableData.selectedRows[row.key]) {
                continue;
            }
            refs.push({ $namespace: row[namespaceColumn] || null, $rdfId: rdfId });
        }

        if (copyTextToClipboard(JSON.stringify(refs))) {
            dispatch(addAlert(ALERT_SUCCESS, { id: "NPT_TABLE_REF_COPY_SUCCESS" }));
        } else {
            dispatch(addAlert(ALERT_DANGER, { id: "NPT_TABLE_REF_COPY_ERROR" }));
        }
    }
}

export function changePage(tableId, page) {
    return function (dispatch, getState) {
        const globalState = getState();
        const tableData = globalState[Action.TABLE][tableId];
        let offset = (page - 1) * tableData.pageSize;
        for (let i = offset; i < Math.min(tableData.pageSize + offset, tableData.totalDataLength); ++i) {
            if (!tableData.fetchedRows[i]) {
                const hashParams = globalState[LOCATION].params;
                const dataParams = getDataParams(tableData, hashParams, { _p: page, _ps: tableData.pageSize });
                dispatch(waitForPage(tableId))
                ajaxFetchPage(tableId, dataParams, dispatch);
                return;
            }
        }
        dispatch(plainChangePage(tableId, page));
    }
}

export function changePageSize(tableId, pageSize) {
    return function (dispatch, getState) {
        const globalState = getState();
        const tableData = globalState[Action.TABLE][tableId];
        let offset = (tableData.page - 1) * tableData.pageSize;
        let page = Math.floor(offset / pageSize) + 1;
        offset = (page - 1) * pageSize;
        for (let i = offset; i < Math.min(pageSize + offset, tableData.totalDataLength); ++i) {
            if (!tableData.fetchedRows[i]) {
                const hashParams = globalState[LOCATION].params;
                const dataParams = getDataParams(tableData, hashParams, { _p: page, _ps: pageSize });
                dispatch(waitForPage(tableId))
                ajaxFetchPage(tableId, dataParams, dispatch);
                return;
            }
        }
        dispatch(plainChangePageSize(tableId, pageSize));
    }
}

/**
 * Finder
 */

export function initializeWithFilter(path, filter) {
    return function (dispatch, getState) {
        let filterData;
        try {
            filterData = JSON.stringify(filter);
        } catch (ex) {
            console.error("Can't parse filter:", ex);
            return;
        }
        ajaxUploadFilter(getState()[LOCATION].contextPath, filterData).then((filterLink) => {
            if (filterLink) {
                changeHash({ params: { _filter: filterLink } });
            }
            dispatch(fetchData(path, null, { force: false, fields: null }));
        });
    }
}

export function fetchFields(path, parentId) {
    return function (dispatch, getState) {
        const tableData = getTableData(getState(), path);
        if (tableData.finder.loadedFields.fetched[parentId]) {
            return;
        }
        ajaxFetchFields(path, tableData.finder.criteriaTree, parentId, dispatch);
    }
}

export function fetchObjectcard(path, rdfId) {
    return function (dispatch, getState) {
        const tableData = getTableData(getState(), path);
        if (tableData.finder.loadedObjectcards.fetched[rdfId]) {
            return;
        }
        ajaxFetchObjectcard(path, rdfId, dispatch);
    }
}

export function removeCriteriaRelation(path, openModal, criteriaId, relationIdx) {
    return function (dispatch, getState) {
        const id = shortid.generate();
        let options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: { id: "MSG_CONFIRM_REMOVE_RELATION" }
        }
        openModal(id, "confirm", options, function () {
            dispatch(plainRemoveCriteriaRelation(path, criteriaId, relationIdx));
            dispatch(unlockCriteriaRelations(path, criteriaId));
        }, null, null);
    }
}

export function changeCriteriaField(path, criteriaId, fieldId) {
    return function (dispatch, getState) {
        const tableData = getTableData(getState(), path);
        const newField = tableData.finder.loadedFields.byId[fieldId];
        if (!newField) {
            console.error("Can't find field with id: '" + fieldId + "'");
            return;
        }
        if (newField.predicate && !tableData.finder.loadedPredicates.byName[newField.joinIdPredicate]) {
            ajaxFetchPredicate(path, newField, dispatch);
        }
        dispatch(plainChangeCriteriaField(path, criteriaId, fieldId));
    }
}

export function removeCriteria(path, openModal, criteriaId) {
    return function (dispatch, getState) {
        const id = shortid.generate();
        let options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: { id: "MSG_CONFIRM_REMOVE_CRITERION" }
        }
        openModal(id, "confirm", options, function () {
            dispatch(plainRemoveCriteria(path, criteriaId));
        }, null, null);
    }
}

function importFinder(tableId, finderData, finderOptions, columns, filterLink, initialConditions) {
    return function (dispatch, getState) {
        downloadNecessaryInitialCriteria(tableId, finderOptions, initialConditions, dispatch, function (fetchedCriteriaMap) {
            const tableData = getTableData(getState(), tableId);
            const compositeFinderData = composeFinderData(finderData, initialConditions, fetchedCriteriaMap);
            downloadNecessaryObjectcard(tableId, tableData, compositeFinderData, dispatch);
            downloadNecessaryFields(tableId, tableData, finderOptions, compositeFinderData, fetchedCriteriaMap, dispatch, function () {
                if (filterLink) {
                    dispatch(changeFilterLink(tableId, filterLink));
                }
                if (compositeFinderData) {
                    dispatch(plainImportFinder(tableId, compositeFinderData));
                }
            });
        });
        if (columns && finderData && finderData.columnList) {
            dispatch(plainUpdateHeaders(tableId, makeHeaders(columns, finderData.columnList)))
        }
    }
}

export function fetchFragments(path, parentFragmentIds) {
    return function (dispatch, getState) {
        if (parentFragmentIds == null) {
            parentFragmentIds = [null];
        }
        const tableData = getTableData(getState(), path);
        for (let fragmentId of parentFragmentIds) {
            if (tableData.finder.loadedFragments.fetched[fragmentId]) {
                continue;
            }
            ajaxFetchFragments(path, tableData.finder.fragmentTree, fragmentId, dispatch);
        }
    }
}

export function fetchClasses(path, levelId) {
    return function (dispatch, getState) {
        const tableData = getTableData(getState(), path);
        if (tableData.finder.loadedClasses.fetched[levelId]) {
            return;
        }
        ajaxFetchClasses(path, tableData.finder.classTree, levelId, dispatch);
    }
}

export function finderSearch(tableId, params, toggle) {
    return function (dispatch, getState) {
        let tableData = getTableData(getState(), tableId);
        let result = getResult(tableData.finder, getState()[I18N].messages);
        let columnList = getColumnList(tableData.dynamicHeaders);

        console.log("Generated report:", result);
        // if (validateFunction && !validateFunction(result)) {
        //     return;
        // }
        delete (result.allFieldsFilled);

        if (toggle) {
            dispatch(toggleHidden(tableId, true));
        }
        dispatch(updateFilterLink(tableId, params, result, columnList, dispatch));
    }
}

export function updateFilterLink(tableId, params, finder, columnList, dispatch) {
    return function (dispatch, getState) {
        const tableData = getTableData(getState(), tableId);


        let doneCallback = function (link) {
            link = parseStringResponse(link);
            console.log("Link from server:", link);
            let newParams = Object.assign({}, params, { _filter: link });
            changeHash({ params: newParams });
            dispatch(changeFilterLink(tableId, link));
            dispatch(storeFinderFilter(tableId, finder));
        }

        $.ajax({
            contentType: 'application/json',
            data: JSON.stringify({
                finder: finder,
                keyList: null,
                columnList: columnList
            }),
            dataType: 'text',
            success: doneCallback,
            error: function (error) {
                /* Sometimes get error with status 200 */
                if (error.status == 200) {
                    doneCallback(error.responseText);
                    return;
                }
                dispatch(dataErrorReceived(tableId, error));
                console.error("Error while get selection:", error);
            },
            processData: false,
            type: 'POST',
            url: BASE_TABLE_FILTER_URL
        })
    }
}

export function updateHeaders(tableId, headers) {
    return function (dispatch, getState) {
        const tableData = getTableData(getState(), tableId);
        let columnList = getColumnList(headers);
        let oldColumns = getColumnList(tableData.dynamicHeaders ? tableData.dynamicHeaders : tableData.initialHeaders);
        let columnsAddedFlag = false;
        /* Check if new columns were added */
        outerCycle: for (let columnId of columnList) {
            for (let i = 0; i < oldColumns.length; ++i) {
                if (columnId == oldColumns[i]) {
                    /* Remove matched column to reduce next columnId search time */
                    oldColumns.splice(i, 1);
                    continue outerCycle;
                }
            }
            /* Code below will be executed only if new column was found */
            columnsAddedFlag = true;
            break;
        }

        if (!columnsAddedFlag) {
            dispatch(plainUpdateHeaders(tableId, headers));
            return;
        }
        let filter = tableData.finderFilter ? tableData.finderFilter : null;
        dispatch(updateFilterLink(tableId, {}, filter, columnList, dispatch));
        dispatch(plainUpdateHeaders(tableId, headers));
    }
}