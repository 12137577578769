import React from 'react';
import { FormattedMessage } from 'react-intl';

class GantHeader extends React.Component {

    constructor(props) {
        super(props);
    }

    printScale(cells, index) {
        let height = this.props.height / this.props.scaleHeaders.length;
        let offsetTop = height * index;
        return cells.map((cell) =>
            <div className="npt-gant-col-header bg-dark-primary border-darker-primary" style={{ top: offsetTop, left: cell.left, width: cell.width, height }}>
                {cell.formatted ? <FormattedMessage
                    id={cell.formatted}
                    values={cell.values} />
                    :
                    <span>{cell.value}</span>
                }
            </div>);
    }

    render() {
        return (
            <div className="npt-gant-header col border-darker-primary">
                <div className="row" style={{ width: this.props.width, height: this.props.height }}>
                    {this.props.scaleHeaders.map((scale, index) => this.printScale(this.props.cells[scale], index))}
                </div>
            </div>);
    }
}

export default GantHeader;
