import * as Action from '../constants/classcard';
import { ALERT_DANGER, ALERT_SUCCESS } from '../constants/alert';
import { MODAL_STATUS_OK } from '../constants/modal.js';

import { FormattedMessage } from 'react-intl';

import { addAlert } from './alert';
import { updateNode, receiveTree, receiveTreeError, waitForTree, plainToggleSubTree } from './npt-treebeard';
import { changeHash, getHashData } from './location';
import { openModal } from './modal';

import PackagePopup from "../components/classcard/popup/packagepopup.jsx";
import ClassPopup from "../components/classcard/popup/classpopup.jsx";
import RelationPopupBody from "../components/classcard/popup/relationpopupbody.jsx";
import RelationPopupTitle from "../components/classcard/popup/relationpopuptitle.jsx";
import PredicatePopup from "../components/classcard/popup/predicatepopup.jsx";
import ExportPopup from "../components/classcard/popup/exportpopup.jsx";

import { createPackageId, getPackageId } from '../services/classcard';
import { downloadFile } from '../services/table';
import { LOCATION } from '../constants/location';

//Basic url for all ajax requests
const PROFILEEDITOR_URL = "/rest/profileditor";
const CLASSINFO_URL = PROFILEEDITOR_URL + "/classinfo?class_id=";
const PRIMITIVE_TYPES_URL = PROFILEEDITOR_URL + "/getdatatypelist";
const DICTIONARY_URL = PROFILEEDITOR_URL + "/dictionary";
const ALL_CLASSES_URL = PROFILEEDITOR_URL + "/allclasses";

const ADD_PACKAGE_URL = PROFILEEDITOR_URL + "/addpackage";
const SAVE_PACKAGE_URL = PROFILEEDITOR_URL + "/editpackage";
const REMOVE_PACKAGE_URL = PROFILEEDITOR_URL + "/removepackage";

const ADD_CLASS_URL = PROFILEEDITOR_URL + "/addclass";
const SAVE_CLASS_URL = PROFILEEDITOR_URL + "/editclass";
const REMOVE_CLASS_URL = PROFILEEDITOR_URL + "/removeclass";

const PACKAGE_DATA_URL = PROFILEEDITOR_URL + "/packagedata";
const PACKAGE_CLASSES_URL = PROFILEEDITOR_URL + "/packageclasses";

const CLASS_CHILDREN_URL = PROFILEEDITOR_URL + "/classchildreninfo";

const EDITOR_SERVICE_URL = PROFILEEDITOR_URL + "/editattributes";
const CREATE_PREDICATE = "CreatePredicate";
const UPDATE_PREDICATE = "UpdatePredicate";
const REMOVE_PREDICATE = "RemovePredicate";

const EXPORT_PACKAGES_URL = "rest/rdfmodel/export/profile";

///////////
//Utility//
///////////
function ajaxGetClass(classId, dispatch) {
    return $.getJSON(CLASSINFO_URL + classId).fail(function (error) {
        dispatch(classErrorReceived(error));
    });
}

function ajaxFetchClass(classId, resetTab, dispatch) {
    dispatch(waitForClass(classId));
    return ajaxGetClass(classId, dispatch).done(function (data) {
        dispatch(classInfoReceived(resetTab, data));
    });
}

function ajaxFetchTypes(dispatch) {
    dispatch(waitForTypes());
    return $.getJSON(PRIMITIVE_TYPES_URL, function (data) {
        dispatch(typesReceived(data));
    }).fail(function (error) {
        dispatch(typesErrorReceived(error));
    });
}

function ajaxFetchEnumerations(dispatch) {
    return $.getJSON(DICTIONARY_URL, function (data) {
        dispatch(plainReceiveEnumerations({
            storeTypeMap: data.storeTypeMap,
            stereotypeMap: data.stereoTypeMap,
            multiplicityMap: data.multiplicityMap,
            relationMap: data.relationMap,
            namespaceMap: data.namespaceMap
        }));
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "CLASSCARD_ENUMERATIONS_GET_ERROR" }));
    });
}

function ajaxSavePackage(dispatch, packageData, nodeById) {
    return $.ajax({
        url: typeof packageData.id == "undefined" ? ADD_PACKAGE_URL : SAVE_PACKAGE_URL,
        method: "POST",
        data: JSON.stringify({
            id: packageData.id,
            name: packageData.name,
            label: packageData.label,
            namespaceId: packageData.namespace.id,
            description: packageData.description
        }),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    }).done(function (newPackageData) {
        dispatch(addAlert(ALERT_SUCCESS, { id: "CLASSCARD_PACKAGE_SAVE_SUCCESS", values: { name: packageData.label } }));
        dispatch(fetchTree(nodeById));
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "CLASSCARD_PACKAGE_SAVE_ERROR", values: { name: packageData.label } }))
        console.error("Error while saving changes: ", error);
    });
}

function ajaxSaveClass(dispatch, classData, oldClassData, haveTree) {
    let parentClasses = [];
    for (let parent of classData.parentClasses) {
        parentClasses.push(parent.id);
    }

    let postClass = {
        'description': classData.description,
        'id': classData.id,
        'label': classData.label,
        'layout': classData.layout,
        'name': classData.name,
        'namespaceId': classData.namespace.id,
        'packageId': classData.package.id,
        'parentClasses': parentClasses,
        'prefix': classData.prefix,
        'stereotype': typeof classData.stereotypeId == "number" ? classData.stereotypeId : null,
        'storeType': classData.storeType
    }
    if (classData.stereotypeId == 2) {
        postClass.enumerationDepth = classData.depth;
    }
    return $.ajax({
        url: typeof classData.id == "undefined" ? ADD_CLASS_URL : SAVE_CLASS_URL,
        method: "POST",
        data: JSON.stringify(postClass),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    }).done(function (newClassData) {
        dispatch(addAlert(ALERT_SUCCESS, { id: "CLASSCARD_CLASS_SAVE_SUCCESS", values: { name: classData.label } }))
        ajaxFetchClass(newClassData.id, false, dispatch);

        if (!haveTree) {
            return;
        }
        if (typeof classData.id == "undefined") {
            ajaxUpdateNodeChildren(createPackageId(newClassData.packageId), dispatch);
        } else {
            if (oldClassData.packageId != newClassData.packageId) {
                ajaxUpdateNodeChildren(createPackageId(oldClassData.packageId), dispatch);
                ajaxUpdateNodeChildren(createPackageId(newClassData.packageId), dispatch);
            }
            dispatch(updateNode(Action.UPDATE_NODE, { id: classData.id, node: newClassData }));
        }
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "CLASSCARD_CLASS_SAVE_ERROR", values: { name: classData.label } }))
        console.error("Error while saving changes: ", error);
    });
}

function ajaxSavePredicate(dispatch, predicate, classData) {
    let postPredicate = {
        'namespaceId': predicate.namespace.id,
        'classId': classData.id,
        'multiplicity': predicate.multiplicity,
        'dataTypeId': predicate.dataType && predicate.dataType.primitive ? predicate.dataType.id : null,
        'name': createPredicateName(predicate.name, classData.name),
        'label': predicate.label,
        'description': predicate.description
    };
    let adding = typeof predicate.id == "undefined";
    if (!adding) {
        postPredicate.id = predicate.id;
    }
    return $.ajax({
        url: EDITOR_SERVICE_URL,
        method: "POST",
        data: JSON.stringify([{
            predicate: postPredicate,
            type: adding ? CREATE_PREDICATE : UPDATE_PREDICATE
        }]),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    }).done(function () {
        dispatch(addAlert(ALERT_SUCCESS, { id: "CLASSCARD_PREDICATE_SAVE_SUCCESS", values: { name: predicate.label } }));
        ajaxFetchClass(classData.id, false, dispatch);
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "CLASSCARD_PREDICATE_SAVE_ERROR", values: { name: predicate.label } }))
        console.error("Error while saving changes: ", error);
    });
}

function ajaxSaveRelation(dispatch, relationData, classData) {
    let predicate = {
        namespaceId: relationData.predicate.namespace.id,
        classId: classData.id,
        multiplicity: relationData.predicate.multiplicity,
        name: createPredicateName(relationData.predicate.name, classData.name),
        label: relationData.predicate.label,
        description: relationData.predicate.description
    };
    let relation = {
        classId: relationData.predicate.dataType.id,
        type: relationData.relationType.value,
        inverseRole: null
    };
    if (relationData.withReverse) {
        relation.inverseRole = {
            namespaceId: relationData.reversePredicate.namespace.id,
            classId: relationData.predicate.dataType.id,
            multiplicity: relationData.reversePredicate.multiplicity,
            name: createPredicateName(relationData.reversePredicate.name, relationData.predicate.dataType.name),
            label: relationData.reversePredicate.label,
            description: relationData.reversePredicate.description
        }
    }
    return $.ajax({
        url: EDITOR_SERVICE_URL,
        method: "POST",
        data: JSON.stringify([{
            predicate,
            relation,
            type: CREATE_PREDICATE
        }]),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    }).done(function () {
        dispatch(addAlert(ALERT_SUCCESS, { id: "CLASSCARD_RELATION_SAVE_SUCCESS", values: { name: relationData.predicate.label + " - " + (relation.inverseRole ? relation.inverseRole.label : "") } }));
        ajaxFetchClass(classData.id, false, dispatch);
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "CLASSCARD_RELATION_SAVE_ERROR", values: { name: relationData.predicate.label + " - " + (relation.inverseRole ? relation.inverseRole.label : "") } }));
        console.error("Error while adding relation: ", error);
    })
}

function ajaxRemovePackage(dispatch, packageData, nodeById) {
    $.post(REMOVE_PACKAGE_URL, { packageid: packageData.id }).done(function () {
        dispatch(addAlert(ALERT_SUCCESS, { id: "CLASSCARD_PACKAGE_REMOVE_SUCCESS", values: { name: packageData.label } }));
        dispatch(updateNode(Action.UPDATE_NODE, { id: createPackageId(packageData.id), children: [] }));
        dispatch(fetchTree(nodeById));
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "CLASSCARD_PACKAGE_REMOVE_ERROR", values: { name: packageData.label } }));
    });
}

function ajaxRemoveClass(dispatch, classData, haveTree) {
    $.post(REMOVE_CLASS_URL, { classid: classData.id }).done(function () {
        dispatch(addAlert(ALERT_SUCCESS, { id: "CLASSCARD_CLASS_REMOVE_SUCCESS", values: { name: classData.label } }));
        ajaxFetchClass(classData.id, false, dispatch);

        if (!haveTree) {
            return;
        }
        ajaxUpdateNodeChildren(createPackageId(classData.packageId), dispatch);
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "CLASSCARD_CLASS_REMOVE_ERROR", values: { name: classData.label } }));
    });
}

function ajaxRemovePredicates(dispatch, classData, predicateList) {
    let removalList = [];
    for (let predicate of predicateList) {
        removalList.push({
            predicate: {
                'namespaceId': classData.namespaceId,
                'classId': classData.id,
                'id': predicate.id,
                'multiplicity': predicate.multiplicity,
                'dataTypeId': predicate.dataType && predicate.dataType.primitive ? predicate.dataType.id : null,
                'name': createPredicateName(predicate.name, classData.name),
                'label': predicate.label,
                'description': predicate.description
            },
            type: REMOVE_PREDICATE
        });
    }
    $.ajax({
        url: EDITOR_SERVICE_URL,
        method: "POST",
        data: JSON.stringify(removalList),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    }).done(function () {
        for (let predicateWrapper of removalList) {
            dispatch(addAlert(ALERT_SUCCESS, { id: "CLASSCARD_PREDICATE_REMOVE_SUCCESS", values: { name: predicateWrapper.predicate.label } }));
        }
        ajaxFetchClass(classData.id, false, dispatch);
    }).fail(function (error) {
        for (let predicateWrapper of removalList) {
            dispatch(addAlert(ALERT_DANGER, { id: "CLASSCARD_PREDICATE_REMOVE_ERROR", values: { name: predicateWrapper.predicate.label } }));
        }
    });
}

function ajaxFetchChildren(dispatch, classId) {
    $.ajax({
        url: CLASS_CHILDREN_URL + "?class_id=" + classId,
        method: "GET",
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    }).done(function (classInfo) {
        dispatch(childrenReceived(classId, classInfo.childrenList));
    }).fail(function (error) {
        dispatch(addAlert(ALERT_DANGER, { id: "CLASSCARD_CHILDREN_RECEIVE_ERROR" }));
    });
}

/////////////////
//Plain actions//
/////////////////
function waitForClass() {
    return {
        type: Action.CLASS_WAIT,
        payload: null
    }
}

function classInfoReceived(resetTab, data) {
    return {
        type: Action.CLASS_INFO_RECEIVED,
        payload: { resetTab, data }
    }
}

function classErrorReceived(error) {
    return {
        type: Action.CLASS_ERROR_RECEIVED,
        payload: { error }
    }
}

function waitForTypes() {
    return {
        type: Action.TYPES_WAIT,
        payload: null
    }
}

function typesReceived(data) {
    return {
        type: Action.TYPES_RECEIVED,
        payload: { data }
    }
}

function typesErrorReceived(error) {
    return {
        type: Action.TYPES_ERROR_RECEIVED,
        payload: { error }
    }
}

function allClassesReceived(data) {
    return {
        type: Action.ALL_CLASSES_RECEIVED,
        payload: { data }
    }
}

function allClassesErrorReceived(error) {
    return {
        type: Action.ALL_CLASSES_ERROR_RECEIVED,
        payload: { error }
    }
}

export function plainReceiveEnumerations({ storeTypeMap, stereotypeMap, multiplicityMap, relationMap, namespaceMap }) {
    return {
        type: Action.RECEIVE_ENUMERATIONS,
        payload: { multiplicityMap, relationMap, stereotypeMap, storeTypeMap, namespaceMap }
    }
}

export function receiveClass(classId) {
    return function (dispatch, getState) {
        ajaxFetchClass(classId, true, dispatch);
    }
}

export function receivePackages(packages) {
    return {
        type: Action.RECEIVE_PACKAGES,
        payload: { packages }
    }
}

export function changeTab(tabName) {
    return {
        type: Action.CHANGE_TAB,
        payload: { tabName }
    }
}

export function selectRows(rows) {
    return {
        type: Action.SELECT_ROWS,
        payload: { rows }
    }
}

export function deselectRows(rows) {
    return {
        type: Action.DESELECT_ROWS,
        payload: { rows }
    }
}

function childrenReceived(classId, children) {
    return {
        type: Action.CHILDREN_RECEIVED,
        payload: { classId, children }
    }
}

///////////
//Actions//
///////////
export function receivePrimitiveTypes() {
    return function (dispatch, getState) {
        ajaxFetchTypes(dispatch);
    }
}

export function receiveEnumerations() {
    return function (dispatch, getState) {
        ajaxFetchEnumerations(dispatch);
    }
}

export function receiveAllClasses() {
    return function (dispatch, getState) {
        $.getJSON(ALL_CLASSES_URL, function (data) {
            dispatch(allClassesReceived(data));
        }).fail(function (error) {
            dispatch(allClassesErrorReceived(error));
        });
    }
}

export function fetchChildren(classId) {
    return function (dispatch, getState) {
        ajaxFetchChildren(dispatch, classId);
    }
}

function createPredicateName(predicateName, className) {
    if (predicateName.indexOf(className + ".") == 0) {
        return predicateName;
    }
    return className + "." + predicateName;
}

export function removePackage(packageId) {
    return function (dispatch, getState) {
        const globalState = getState();
        const classCard = globalState[Action.CLASSCARD];
        const treeState = globalState[Action.CLASSCARD_TREE];
        const packageData = classCard.packages.packageById[packageId];
        let options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: (<div>
                <h4 className="text-center">
                    <FormattedMessage
                        id="CLASSCARD_CONFIRM_PACKAGE_REMOVE"
                        values={{ name: packageData.label }} />
                </h4>
                <div className="alert alert-danger" role="alert">
                    <FormattedMessage
                        id="CLASSCARD_PACKAGE_REMOVE_ATTENTION"
                        defaultMessage="Attention! Package will be removed with all of its classes."
                        description="Attention that notify user that package will be removed with its classes" />
                </div>
            </div>)
        }
        dispatch(openModal("confirmPopup", "confirm", options, () => {
            ajaxRemovePackage(dispatch, packageData, treeState.nodeById);
        }));
    }
}

export function removeClass(classData) {
    return function (dispatch, getState) {
        const globalState = getState();
        const classCard = globalState[Action.CLASSCARD];
        const classCardTree = globalState[Action.CLASSCARD_TREE];
        if (!classData) {
            classData = classCard.data;
        }
        let options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: (<div>
                <h4 className="text-center">
                    <FormattedMessage
                        id="CLASSCARD_CONFIRM_CLASS_REMOVE"
                        values={{ name: classData.label }} />
                </h4>
                <div className="alert alert-warning" role="alert">
                    <FormattedMessage
                        id="CLASSCARD_CLASS_REMOVE_ATTENTION"
                        defaultMessage="Attention! Class will be removed with its attributes."
                        description="Attention that notify user that class will be removed with its attributes" />
                </div>
            </div>)
        }
        dispatch(openModal("confirmPopup", "confirm", options, () => {
            ajaxRemoveClass(dispatch, classData, !!classCardTree);
        }));
    }
}

export function removePredicates(predicateList, classData) {
    return function (dispatch, getState) {
        let options = {
            title: { id: "MSG_CONFIRM_ACTION" },
            body: { id: "CLASSCARD_CONFIRM_PREDICATE_REMOVE", values: { num: predicateList.length } }
        }
        dispatch(openModal("confirmPopup", "confirm", options, () => {
            ajaxRemovePredicates(dispatch, classData, predicateList);
        }));
    }
}


////////////////////////////
//Classcard tree utilities//
////////////////////////////
function openPackagePopup(dispatch, packageData, packages, namespace, nodeById) {
    let popupRef = null;
    let options = {
        title: { id: (packageData ? "CLASSCARD_EDIT_PACKAGE" : "CLASSCARD_CREATE_PACKAGE") },
        body: (<PackagePopup
            packageData={packageData}
            packageList={packages.list}
            namespaceMap={namespace.namespaceById}
            namespaceList={namespace.list}
            ref={(popup) => popupRef = popup}
        />),
        onOk: function (modal) {
            if (!popupRef
                || !popupRef.state
                || !popupRef.validName
                || !popupRef.validLabel) {
                return;
            }
            ajaxSavePackage(dispatch, popupRef.state, nodeById).done(function () {
                modal.closeModal(MODAL_STATUS_OK);
            });
        }
    }
    dispatch(openModal("packagePopup", "common", options));
}

function openClassPopup(dispatch, classData, stereotype, storeType, packages, namespace, allClasses, haveTree) {
    let popupRef = null;
    let options = {
        title: { id: (classData.id ? "CLASSCARD_EDIT_CLASS" : "CLASSCARD_CREATE_CLASS") },
        body: (<ClassPopup
            classParents={classData.parentList}
            classData={classData}
            stereotypeMap={stereotype.stereotypeByValue}
            stereotypeList={stereotype.list}
            storeTypeMap={storeType.storeTypeByValue}
            storeTypeList={storeType.list}
            packages={packages}
            allClasses={allClasses}
            namespaceMap={namespace.namespaceById}
            namespaceList={namespace.list}
            ref={(classPopup) => popupRef = classPopup}
        />),
        onOk: function (modal) {
            if (!popupRef
                || !popupRef.state
                || !popupRef.validName
                || !popupRef.validLabel) {
                return;
            }
            ajaxSaveClass(dispatch, popupRef.state, classData, haveTree).done(function () {
                modal.closeModal(MODAL_STATUS_OK);
            });
        }
    }
    dispatch(openModal("classPopup", "common", options));
}

function openRelationPopup(dispatch, relationData, classData, allClasses, relationType, multiplicity) {
    let options = {
        title: (<RelationPopupTitle
            relation={relationData}
            relationTypeList={relationType.list}
        />),
        body: (<RelationPopupBody
            classData={classData}
            relation={relationData}
            allClasses={allClasses}
            multiplicityList={multiplicity.list}
            relationTypeMap={relationType.relationTypeByValue}
        />)
    }
    dispatch(openModal("relationPopup", "common", options));
}

function openPredicatePopup(dispatch, predicateDate, classData, primitiveTypes, multiplicity, allClasses, relationType, stereotype, namespace, onlyView) {
    let predicatePopup = null;
    let titleId = "";
    if (onlyView) {
        titleId = "CLASSCARD_VIEW_PREDICATE";
    } else if (predicateDate) {
        titleId = "CLASSCARD_EDIT_PREDICATE";
    } else {
        titleId = "CLASSCARD_CREATE_PREDICATE";
    }
    let options = {
        title: { id: titleId },
        body: (<PredicatePopup
            disabled={onlyView}
            classData={classData}
            classList={allClasses.list}
            predicate={predicateDate}
            multiplicityList={multiplicity.list}
            dataTypeList={primitiveTypes.list}
            relationTypeMap={relationType.relationTypeByValue}
            relationTypeList={relationType.list}
            stereotypeMap={stereotype.stereotypeByValue}
            namespaceMap={namespace.namespaceById}
            namespaceList={namespace.list}
            ref={(predicateRef) => predicatePopup = predicateRef}
        />),
        onOk: (modal) => {
            if (!predicatePopup
                || !predicatePopup.state
                || !predicatePopup.validation.predicate.name
                || !predicatePopup.validation.predicate.label) {
                return;
            }
            if (typeof predicatePopup.state.predicate.id == "undefined" && predicatePopup.state.relationType != null) {
                if (predicatePopup.state.withReverse && (!predicatePopup.validation.predicate.name || !predicatePopup.validation.predicate.label)) {
                    return;
                }
                ajaxSaveRelation(dispatch, predicatePopup.state, classData).done(function () {
                    modal.closeModal(MODAL_STATUS_OK);
                });
                return;
            }
            ajaxSavePredicate(dispatch, predicatePopup.state.predicate, classData).done(function () {
                modal.closeModal(MODAL_STATUS_OK);
            });
        }
    }
    dispatch(openModal("predicatePopup", "common", options));
}

//////////////////////////
//Classcard tree actions//
//////////////////////////
function ajaxFetchTree(dispatch, nodeById) {
    console.log("AJAX call to fetch source tree");
    dispatch(waitForTree(Action.WAIT_FOR_TREE));
    return $.get(PACKAGE_DATA_URL, function (data) {
        dispatch(receivePackages(data));

        data = data.slice();
        for (let i = 0; i < data.length; ++i) {
            data[i] = Object.assign({}, data[i]);
            data[i].id = createPackageId(data[i].id);
            if (nodeById && nodeById[data[i].id] && nodeById[data[i].id].loading == false) {
                data[i].loading = false;
                continue;
            }
            data[i].loading = true;
            data[i].children = [];
        }

        dispatch(receiveTree(Action.RECEIVE_TREE, null, data));
    }).fail(function (error) {
        dispatch(receiveTreeError(Action.RECEIVE_TREE_ERROR, error));
    });
}

export function ajaxUpdateNodeChildren(id, dispatch) {
    $.get(PACKAGE_CLASSES_URL + "?package_id=" + getPackageId(id))
        .done(function (data) {
            /* Close node before updating */
            dispatch(updateNode(Action.UPDATE_NODE, { id, children: data, loading: false, error: false }));
        })
        .fail(function (error) {
            dispatch(updateNode(Action.UPDATE_NODE, { id, children: [], loading: false, error }));
        })
}

export function toggleSubTree(id, toggled) {
    return function (dispatch, getState) {
        dispatch(plainToggleSubTree(Action.TOGGLE_SUBTREE, id, toggled));
        ajaxUpdateNodeChildren(id, dispatch);
    }
}


export function fetchTree(nodeById) {
    return function (dispatch, getState) {
        ajaxFetchTree(dispatch, nodeById);
    }
}

export function selectObject({ id, inplace }, dispatch) {
    if (!id || inplace) {
        return;
    }
    let hashParams = getHashData().params;
    if (hashParams.id == id) {
        return;
    }
    changeHash({ params: { id } });
}

export function findClass(classData) {
    return function (dispatch, getState) {
        const treeState = getState()[Action.CLASSCARD_TREE];
        selectObject({ id: classData.id }, dispatch);
        if (treeState.loading || treeState.error || !classData.packageId) {
            return;
        }
        const node = treeState.nodeById[createPackageId(classData.packageId)];
        if (node.loading || node.error) {
            dispatch(toggleSubTree(node.id, true));
        } else {
            dispatch(plainToggleSubTree(Action.TOGGLE_SUBTREE, node.id, true));
        }
        dispatch(plainToggleSubTree(Action.TOGGLE_SUBTREE, classData.id, true));
    }
}

export function editPackage(packageData) {
    return function (dispatch, getState) {
        const globalState = getState();
        const state = globalState[Action.CLASSCARD];
        const treeState = globalState[Action.CLASSCARD_TREE];
        openPackagePopup(dispatch, packageData, state.packages, state.namespace, treeState.nodeById);
    }
}

export function viewRelation(relation) {
    return function (dispatch, getState) {
        const state = getState()[Action.CLASSCARD];
        openRelationPopup(dispatch, relation, state.data, state.allClasses, state.relationType, state.multiplicity);
    }
}

export function editClass(classData) {
    return function (dispatch, getState) {
        const state = getState()[Action.CLASSCARD];
        const treeState = getState()[Action.CLASSCARD_TREE];
        openClassPopup(dispatch, classData, state.stereotype, state.storeType, state.packages, state.namespace, state.allClasses, !!treeState);
    }
}

export function editPredicate(predicateData, disabled) {
    return function (dispatch, getState) {
        const state = getState()[Action.CLASSCARD];
        openPredicatePopup(dispatch, predicateData, state.data, state.primitiveTypes, state.multiplicity, state.allClasses, state.relationType, state.stereotype, state.namespace, disabled);
    }
}

export function editClassViaNode(node) {
    return function (dispatch, getState) {
        const state = getState()[Action.CLASSCARD];
        if (state.data && state.data.id == node.id) {
            dispatch(editClass(state.data));
        } else {
            ajaxGetClass(node.id, dispatch).done(function (classData) {
                dispatch(editClass(classData));
            });
        }
    }
}

export function editPackageViaNode(node) {
    return function (dispatch, getState) {
        const state = getState()[Action.CLASSCARD];
        const packageData = state.packages.packageById[getPackageId(node.id)];
        dispatch(editPackage(packageData));
    }
}

function exportPackages(contextPath, packageList) {
    let packagesUrl = "";
    let filenamePackages = "";
    for (let packageObject of packageList) {
        if (packagesUrl == "") {
            packagesUrl = "?";
            filenamePackages = " (packages №"
        } else {
            packagesUrl += "&";
            filenamePackages += ",";
        }
        packagesUrl += "package=" + packageObject.id;
        filenamePackages += packageObject.id;
    }
    if (filenamePackages != "") {
        filenamePackages += ")";
    }
    downloadFile(contextPath + EXPORT_PACKAGES_URL + packagesUrl, "profile" + filenamePackages + ".xml");
}

export function openExportPackageModal() {
    return function (dispatch, getState) {
        const state = getState()[Action.CLASSCARD];
        const contextPath = getState()[LOCATION].contextPath;
        const packageList = state.packages.list;

        let exportPopup = null;

        let options = {
            title: { id: "CLASSCARD_EXPORT_PACKAGES" },
            body: (<ExportPopup
                packageList={packageList}
                ref={(popupRef) => exportPopup = popupRef}
            />),
            onOk: (modal) => {
                if (!exportPopup) {
                    return;
                }
                modal.closeModal(MODAL_STATUS_OK);
                exportPackages(contextPath, exportPopup.selected);
            }
        }
        dispatch(openModal("exportPackages", "common", options));
    }
}