import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Modal from '../modal.jsx';

class CommonModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.options.initialState;
    }

    render() {
        return (<Modal
            /**Required options*/
            id={this.props.id}
            options={this.props.options}
            closeModal={this.props.closeModal}
            /**Specific panels for TableOptions*/
            title={this.props.options.title}
            body={this.props.options.body}
            footer={this.props.options.footer}
            /**Specific callbacks for TableOptions*/
            onOk={this.props.options.onOk}
            onClose={this.props.options.onClose}
            onCancel={this.props.options.onCancel}
            result={this.props.options.result} />);
    }
}

export default CommonModal;


