import React from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import { getUpload } from '../../../services/objectcard.js';
import Resizer from '../../resizer/resizer.jsx';

//https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
const isAbsolute = new RegExp('^([a-z]+://|//)', 'i');

const NS = "http://www.w3.org/2000/svg";

function addGlowFilter(element) {
    const defs = document.createElementNS(NS, "defs");

    const filter = document.createElementNS(NS, "filter");
    filter.setAttribute("id", "glow");
    filter.setAttribute("x", "-200%");
    filter.setAttribute("y", "-200%");
    filter.setAttribute("width", "500%");
    filter.setAttribute("height", "500%");

    const blur = document.createElementNS(NS, "feGaussianBlur");
    blur.setAttribute("stdDeviation", "1.5");
    blur.setAttribute("result", "coloredBlur");

    const merge = document.createElementNS(NS, "feMerge");
    const mergeNode1 = document.createElementNS(NS, "feMergeNode");
    mergeNode1.setAttribute("in", "coloredBlur");
    const mergeNode2 = document.createElementNS(NS, "feMergeNode");
    mergeNode2.setAttribute("in", "SourceGraphic");
    merge.appendChild(mergeNode1);
    merge.appendChild(mergeNode2);

    filter.appendChild(blur);
    filter.appendChild(merge);

    defs.appendChild(filter);
    element.appendChild(defs);
}

function parsePoint(stringPoint) {
    const coordinate = stringPoint.trim().replace(/[a-zA-Z]/, "").split(" ");
    return {
        x: parseFloat(coordinate[0]),
        y: parseFloat(coordinate[1])
    };
}

class Image extends React.Component {

    maxWidth;
    maxHeight;
    imageRef;
    bindedTabs;

    constructor(props) {
        super(props);
        this.changeZoom = this.changeZoom.bind(this);
        this.svgOnLoad = this.svgOnLoad.bind(this);

        this.state = {
            zoom: props.node.options && props.node.options.resizerOptions && props.node.options.resizerOptions.zoom || 1,
            initialHeight: null,
            initialWidth: null
        }

        this.maxWidth = this.getDimension(props.node.options ? props.node.options.width : null, 3000);
        this.maxHeight = this.getDimension(props.node.options ? props.node.options.height : null, this.maxWidth);

        this.imageRef = null;

        this.bindedTabs = {};

        this.changeTab = this.changeTab.bind(this);
    }

    /* All size checks were happened in resizer */
    changeZoom(zoom) {
        this.setState(Object.assign({}, this.state, { zoom }));
    }

    svgOnLoad(event) {
        this.bindedTabs = {};
        /*https://gist.github.com/leonderijke/c5cf7c5b2e424c0061d2*/
        const baseUrl = window.location.href.replace(window.location.hash, "");
        addGlowFilter($(event.target.contentDocument).find('svg')[0]);
        $(event.target.contentDocument).find('a').each((_, el) => {
            $(el).attr("target", "_top");
            const ref = $(el).attr("xlink:href");
            if (typeof ref == 'string' && !isAbsolute.test(ref) && ref.charAt(0) != '/') {
                if (this.isTabLink(ref)) {
                    const tabName = this.getTabLink(ref);
                    this.bindTab(el, tabName);
                    $(el).on("click", this.changeTab.bind(this, tabName));
                } else if (ref.charAt(0) == '#') {
                    $(el).attr("xlink:href", baseUrl + ref);
                } else {
                    $(el).attr("xlink:href", baseUrl + "/" + ref);
                }
            }
        });
        this.selectBindedElement();
    }

    isTabLink(ref) {
        return ref.startsWith("#?tab=");
    }

    getTabLink(ref) {
        return ref.replace("#?tab=", "");
    }

    bindTab(el, tab) {
        if (this.bindedTabs[tab]) {
            if (!Array.isArray(this.bindedTabs[tab].el)) {
                this.bindedTabs[tab].el = [this.bindedTabs[tab].el];
            }
            this.bindedTabs[tab].el.push(el);
            return;
        }
        const tabId = this.getTabIdByLink(tab);
        const navId = this.getNavIdByTabId(tabId);
        this.bindedTabs[tab] = {
            id: tabId,
            navId: navId,
            el: el,
            selected: false
        }
    }

    getTabIdByLink(tab) {
        if (!this.props.layout || !this.props.layout.tabsIds) {
            return null;
        }
        for (let tabId of this.props.layout.tabsIds) {
            if (tabId.endsWith(tab)) {
                return tabId;
            }
        }
        return null;
    }

    getNavIdByTabId(tabId) {
        if (!this.props.layout || !this.props.layout.parentIdByChildId[tabId]) {
            return null;
        }
        return this.props.layout.parentIdByChildId[tabId];
    }

    changeTab(tabName, event) {
        event.preventDefault();
        this.props.changeTab(this.bindedTabs[tabName].id, this.bindedTabs[tabName].navId);
    }

    buildEmptyImage() {
        return (<center><i className="fa fa-file-image-o fa-5x" aria-hidden="true"></i></center>);
    }

    getDimension(x, defaultValue) {
        if (typeof x == 'number') {
            return x;
        }
        if (typeof x == 'string') {
            const parsed = parseInt(x);
            if (!isNaN(parsed)) {
                return parsed;
            }
        }
        return defaultValue;
    }

    getImageStyle() {
        //https://www.w3.org/Style/Examples/007/center.en.html
        return {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            height: this.initialHeight ? this.initialHeight * this.state.zoom : "auto",
            width: this.initialWidth ? this.initialWidth * this.state.zoom : "auto",
            maxHeight: this.initialHeight ? null : this.maxHeight,
            maxWidth: this.initialWidth ? null : this.maxWidth
        };
    }

    getResizerOptions() {
        if (!this.props.node.options.resizing) {
            return {};
        }
        let resizerOptions = {
            maxWidth: this.maxWidth,
            maxHeight: this.maxHeight
        };
        if (this.props.node.options.resizerOptions) {
            Object.assign(resizerOptions, {
                minZoom: this.props.node.options.resizerOptions.minZoom,
                maxZoom: this.props.node.options.resizerOptions.maxZoom,
                zoomPoints: this.props.node.options.resizerOptions.zoomPoints,
                theme: this.props.node.options.resizerOptions.theme,
                fixed: this.props.node.options.resizerOptions.fixed,
            });
        }
        return resizerOptions;
    }

    getFileUploadData(file) {
        if ((typeof file != "object" || $.isEmptyObject(file))) {
            return null;
        }
        if (!file.$sha1 && !file.$uploadKey) {
            return null;
        }
        const upload = file.$uploadKey && getUpload(file.$uploadKey);
        if (upload && !upload.preview) {
            return null;
        }
        return upload;
    }

    buildImage(file, upload = this.getFileUploadData(file)) {
        if (upload === null) {
            return this.buildEmptyImage();
        }
        const cp = this.props.contextPath;
        const ref = upload ? upload.preview : `${cp}rest/file/download/${file.$sha1}`;
        const alt = upload ? upload.name : file.$originalName;
        const imgStyle = this.getImageStyle();
        if (file.$contentType == "image/svg+xml") {
            return (<object data={ref} type="image/svg+xml" onLoad={this.svgOnLoad} style={imgStyle} ref={(ref) => this.imageRef = ref}>{alt} (SVG is unsupported!)</object>);
        }
        return (<img src={ref} alt={alt} style={imgStyle} ref={(ref) => this.imageRef = ref} />);
    }

    removeGlowFromChildren(element) {
        if (Array.isArray(element)) {
            for (let item of element) {
                this.removeGlowFromChildren(item);
            }
            return;
        }
        $(".glow", element).remove();
    }

    addGlow(child) {
        const copy = $(child.cloneNode());
        copy.attr("filter", "url(#glow)");
        copy.css("filter", "url(#glow)");
        copy.addClass("glow");
        $(child.parentNode).prepend(copy);
    }

    /* Adding glow filter to horizontal/vertical line requires rect element as base */
    addStraightLineGlow(child, startPoint, endPoint) {
        const width = parseFloat($(child).css("stroke-width")) * 0.65;
        const $rect = $(document.createElementNS(NS, "rect"));
        $rect.attr("x", startPoint.x);
        $rect.attr("y", startPoint.y);
        $rect.attr("width", endPoint.x - startPoint.x);
        $rect.attr("height", endPoint.y - startPoint.y);
        if ($rect.attr("width") == 0) {
            $rect.attr("x", startPoint.x - width / 2);
            $rect.attr("width", width);
        } else {
            $rect.attr("y", startPoint.y - width / 2);
            $rect.attr("height", width);
        }
        $rect.css("fill", $(child).css("stroke"));
        $rect.css("filter", "url(#glow)");
        $rect.addClass("glow");
        $(child.parentNode).prepend($rect);
    }

    addPathGlow(child) {
        /* If path is horizontal/vertical line - we need to replace it with rectangle for correct glow effect */
        let horizontal = true;
        let vertical = true;

        const d = $(child).attr("d");

        if (d.search(/[a-kn-zA-KN-Z]/) != -1) {
            this.addGlow(child);
            return;
        }

        const points = d.replace("M", "").split(/[lmLM]/);
        const startPoint = parsePoint(points.splice(0, 1)[0]);
        let endPoint = null;
        for (let point of points) {
            endPoint = parsePoint(point);
            if (vertical && endPoint.x != startPoint.x) {
                vertical = false;
            }
            if (horizontal && endPoint.y != startPoint.y) {
                horizontal = false;
            }
            if (!horizontal && !vertical) {
                this.addGlow(child);
                return;
            }
        }
        if (!endPoint) {
            return;
        }
        if (horizontal) {
            this.addStraightLineGlow(child, startPoint, endPoint);
            return;
        }
        if (vertical) {
            this.addStraightLineGlow(child, startPoint, endPoint);
            return;
        }
    }

    addLineGlow(child) {
        const startPoint = {
            x: parseFloat($(child).attr("x1")),
            y: parseFloat($(child).attr("y1"))
        }
        const endPoint = {
            x: parseFloat($(child).attr("x2")),
            y: parseFloat($(child).attr("y2"))
        }
        if (startPoint.x == endPoint.x) {
            this.addStraightLineGlow(child, startPoint, endPoint);
            return;
        }
        if (startPoint.y == endPoint.y) {
            this.addStraightLineGlow(child, startPoint, endPoint);
            return;
        }
        this.addGlow(child);
    }

    addGlowToChildren(element) {
        if (Array.isArray(element)) {
            for (let item of element) {
                this.addGlowToChildren(item);
            }
            return;
        }
        $("rect", element).each((i, child) => this.addGlow(child));
        $("path", element).each((i, child) => this.addPathGlow(child));
        $("line", element).each((i, child) => this.addLineGlow(child));
        $("ellipse", element).each((i, child) => this.addGlow(child));
        $("polyline", element).each((i, child) => this.addGlow(child));
    }

    deselectBindedElement(props = this.props) {
        for (let tabName in this.bindedTabs) {
            if (this.bindedTabs[tabName].selected) {
                this.removeGlowFromChildren(this.bindedTabs[tabName].el);
            }
        }
    }

    selectBindedElement(props = this.props) {
        for (let tabName in this.bindedTabs) {
            const tabId = this.bindedTabs[tabName].id;
            const navId = this.bindedTabs[tabName].navId;
            const activeTabId = props.tabs && props.tabs[navId] && props.tabs[navId].active;
            if (tabId == activeTabId) {
                this.addGlowToChildren(this.bindedTabs[tabName].el);
                this.bindedTabs[tabName].selected = true;
            }
        }
    }

    readInitialSizes(elm) {
        this.initialHeight = elm.offsetHeight;
        this.initialWidth = elm.offsetWidth;
        if (!this.initialHeight || !this.initialWidth) {
            return;
        }
        if (this.state.zoom != 1) {
            this.forceUpdate();
        }
    }

    setupInitialWidth() {
        const image = ReactDOM.findDOMNode(this.imageRef);
        if (!image) {
            return;
        }
        if (image.contentDocument) {
            this.readInitialSizes(image);
            return;
        }
        image.onload = () => {
            this.readInitialSizes(image);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.tabs != nextProps.tabs) {
            this.deselectBindedElement(nextProps);
            this.selectBindedElement(nextProps);
        }
    }

    componentDidMount() {
        if (!this.props.node || !this.props.node.options.resizing) {
            return;
        }
        this.setupInitialWidth();
    }

    componentDidUpdate() {
        if(!this.initialHeight || !this.initialWidth) {
            console.warn("Objectcard image can't setup initial sizes (img, height, width):", ReactDOM.findDOMNode(this.imageRef), this.initialHeight, this.initialWidth)
        }
        if (!this.props.node || !this.props.node.options.resizing || (this.initialHeight && this.initialWidth)) {
            return;
        }
        this.setupInitialWidth();
    }

    render() {

        const style = this.props.visible ? null : { display: "none" };

        //For single image
        if ($.type(this.props.value) != 'array') {
            const upload = this.getFileUploadData(this.props.value);
            if (this.props.node && this.props.node.options.resizing && upload !== null) {
                return <Resizer
                    zoom={this.state.zoom}
                    changeZoom={this.changeZoom}
                    style={style}
                    {...this.getResizerOptions()}
                >
                    {this.buildImage(this.props.value, upload)}
                </Resizer>
            };
            return <div className="position-relative overflow-auto" style={Object.assign({ maxHeight: this.maxHeight, maxWidth: this.maxWidth }, style)}>
                {this.buildImage(this.props.value, upload)}
            </div>
        }

        if (this.props.value.length == 0) {
            return (<div style={style}>{this.buildEmptyImage()}</div>);
        }

        const settings = {
            dots: true,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const _this = this;

        return (
            <Slider {...settings} style={style}>
                {this.props.value.map((file, index) => (<div key={index}>{_this.buildImage(file)}</div>))}
            </Slider>);

        /*
        return (
            <div>
                {this.props.value.map((file, index) => (<div key={index}>{_this.buildImage(file)}</div>))}
            </div>);
        */
    }
}

export default Image;