import React from 'react';
import { FormattedMessage } from 'react-intl';

class DomainList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }
    
    onChange(event) {
        this.props.onChange(event.target.value);
    }

    render() {
        if (!this.props.ldapAuth || !this.props.domains) {
            return null;
        }
        return (<div className="form-group">
            <label htmlFor="domainSelect">
                <FormattedMessage
                    id="LOGIN_PANEL_DOMAIN_LIST_LABEL"
                    defaultMessage="Domains:"
                    description="Label for domains select" />
            </label>
            <select id="domainSelect" className="form-control" onChange={this.onChange} ref={this.props.setupInput}>
                <option value="">--</option>
                {this.props.domains.map((domain)=>{
                    return (<option key={domain.name} value={domain.name}>{domain.name}</option>)
                })}
            </select>
        </div>);
    }
}

export default DomainList;