import shortid from 'shortid';
import minimatch from "minimatch";

import {
    CHILD_PLACEHOLDER,
    PARENT_PLACEHOLDER,
    SELF_PLACEHOLDER,
    PREDICATE_PLACEHOLDER,
    UI_PANEL,
    UI_TEXT,
    UI_GRID,
    UI_GRID_CELL,
    UI_DATE,
    UI_FILE,
    UI_CHECKBOX,
    UI_COMBOBOX,
    UI_FRAGMENT,
    UI_REF_TABLE,
    UI_OBJECT_TABLE,
    UI_LABEL,
    UI_DESCRIPTION,
    POPUP_STATUS_OPENED
} from '../constants/objectcard';

import LayoutTree from './layouttree';

import {
    scriptCompiler,
    registerFunction
} from './automation';

export function normalizePredicate(predicate) {
    return predicate.replace(":", ".");
}

export function normalizeClass(cls) {
    return cls.replace(":", ".");
}

function buildDefaultLayout(cls) {
    var layout = {
        override: false,
        content: [
            {
                ui: CHILD_PLACEHOLDER
            },
            {
                ui: UI_PANEL,
                label: cls.label,
                content: [
                    {
                        ui: SELF_PLACEHOLDER
                    }
                ]
            }
        ]
    };
    return layout;
}

function buildPredicateFieldWithLayout(predicate) {
    if (typeof predicate.layout == 'string') {
        console.log("Predicate layout: " + predicate.layout);
        return $.extend(true, {}, predicate, JSON.parse(predicate.layout));
    }
    return $.extend(true, {}, predicate);
}

function trimString(s) {
    return s.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
}

export function buildLabelInfo(label) {
    let words = label.split(" ");
    let maxLength = 0;
    let longestWord = "";
    for (let word of words) {
        if (word.length > maxLength) {
            longestWord = word;
            maxLength = word.length;
        }
    }
    return { words, maxLength, longestWord };
}


/** Build field from predicate
 *  @param predicate is predicate information from classInfo
 *  @param parentPath is parent path for this predicate in redux store
 */
function buildPredicateField(predicate, parentPath) {
    let field = buildPredicateFieldWithLayout(predicate);
    //Create path for the field as parent + namespace + pridicate name splitted by dot
    //For instance, cim:IdentifiedObject.name = ["cim","IdentifiedObject","name"]
    field.path = parentPath.concat(field.namespace.prefix, field.name.split(".")); //Save path for backwards capability
    field.id = field.path.join(".");
    field.leaf = true; //Mark field as layout tree leaf so that it will not be deleted as empty
    if (typeof field.multiplicityInfo == 'undefined') {
        field.multiple = false;
        field.mandotary = false;
    } else {
        field.multiple = field.multiplicityInfo.indexOf('n') >= 0;
        field.mandotary = field.multiplicityInfo.indexOf(':1') >= 0;
    }
    if (typeof field.classRelationInfo != 'undefined' && field.classRelationInfo != null) {
        let peerClass = field.classRelationInfo.peerClass;
        if (peerClass.stereotypeInfo == "enumeration") {
            field.ui = UI_COMBOBOX;
        } else {
            let relationTypeInfo = field.classRelationInfo.relationTypeInfo.toLowerCase();
            if (relationTypeInfo == 'composition') {
                field.ui = UI_OBJECT_TABLE;
                field.columns = [];
                let predicateList = field.classRelationInfo.peerClass.predicateList;
                for (let p of predicateList) {
                    let label = trimString(p.label);
                    let col = {
                        id: p.id,
                        label: label,
                        labelInfo: buildLabelInfo(label),
                        path: [].concat(p.namespace.prefix, p.name.split(".")),
                        predicate: buildPredicateFieldWithLayout(p) //copy predicate to format cell
                    }
                    field.columns.push(col);
                }
            } else {
                field.ui = UI_REF_TABLE;
            }
        }
    } else if (typeof field.dataType != 'undefined' && field.dataType != null) {
        let dataType = field.dataType.name;
        if (dataType === 'anyURI') {
            field.ui = UI_FRAGMENT;
        } else if (dataType === "boolean") {
            field.ui = UI_CHECKBOX;
        } else if (dataType === "base64Binary") {
            field.ui = UI_FILE;
        } else {
            field.ui = UI_TEXT;
            field.format = dataType;
        }
    } else {
        field.ui = UI_TEXT;
    }
    return field;
};

function isArray(value) {
    return $.type(value) === "array";
}

function isObject(value) {
    return $.type(value) === "object";
}

function layoutInColumns(layout, id) {
    const numberOfColumns = layout.byId[id].layoutInColumns;
    const childrenIds = layout.childrenIdsByParentId[id];
    const len = childrenIds.length;
    layout.childrenIdsByParentId[id] = [];
    if (layout.byId[id].ui != UI_GRID) { //if not the add grid
        const grid = {
            id: layout.clsName + ".ui" + layout.counter,
            ui: UI_GRID
        };
        ++layout.counter;
        layout.byId[grid.id] = grid;
        layout.childrenIdsByParentId[id].push(grid.id);
        layout.parentIdByChildId[grid.id] = id;
        layout.childrenIdsByParentId[grid.id] = [];
        id = grid.id; //repalce element id with grid id
    }
    const cols = Math.trunc(12 / numberOfColumns);
    const numberOfInt = Math.trunc(len / numberOfColumns);
    const numberOfRest = len % numberOfColumns;
    const columns = [];
    let nxt = 0;
    for (let i = 0; i < numberOfColumns; ++i) {
        const column = {
            id: layout.clsName + ".ui" + layout.counter,
            ui: UI_GRID_CELL,
            cols: cols
        };
        ++layout.counter;
        //Add column content to grid
        layout.byId[column.id] = column;
        layout.childrenIdsByParentId[id].push(column.id);
        layout.parentIdByChildId[column.id] = id;
        layout.childrenIdsByParentId[column.id] = [];
        //Put integer itemns
        for (let j = 0; j < numberOfInt; ++j) {
            const childId = childrenIds[nxt];
            ++nxt;
            //Add contents to column
            layout.childrenIdsByParentId[column.id].push(childId);
            layout.parentIdByChildId[childId] = column.id;
        }
        if (i < numberOfRest) {
            const childId = childrenIds[nxt];
            ++nxt;
            layout.childrenIdsByParentId[column.id].push(childId);
            layout.parentIdByChildId[childId] = column.id;
        }
    }
}

//Recursive pass all nodes in layout
function normalizeLayout(parentId, node, layout) {
    const content = node.content; //save reference to node content for recursion
    node = Object.assign({}, node); //make shallow copy of node
    delete node.content; //remove content for ui element
    if (node.id) {
        node.id = layout.clsName + ".ui" + node.id;
    } else {
        node.id = layout.clsName + ".ui" + layout.counter;
        ++layout.counter;
    }
    layout.byId[node.id] = node; //save ui information
    if (parentId) { //insert to parent
        layout.parentIdByChildId[node.id] = parentId;
        if (!layout.childrenIdsByParentId[parentId]) {
            layout.childrenIdsByParentId[parentId] = [];
        }
        layout.childrenIdsByParentId[parentId].push(node.id);
    } else {
        layout.rootId = node.id;
    }
    //Recognize placeholders
    if (node.ui == PREDICATE_PLACEHOLDER) {
        if (node.ref) {
            layout.predicatePlaceHolderIds.push(node.id);
        } else {
            layout.nonPredicatePlaceholderIds.push(node.id);
        }
        return;
    }
    if (node.ui == SELF_PLACEHOLDER) {
        layout.selfPlaceHolderId = node.id;
        layout.nonPredicatePlaceholderIds.push(node.id);
        return;
    }
    if (node.ui == CHILD_PLACEHOLDER) {
        layout.childPlaceHolderId = node.id;
        layout.nonPredicatePlaceholderIds.push(node.id);
        return;
    }
    if (node.ui == PARENT_PLACEHOLDER) {
        layout.parentPlaceHolderId = node.id;
        layout.nonPredicatePlaceholderIds.push(node.id);
        return;
    }
    //Source parameter should be normalized
    if (node.src) {
        node.src = normalizePredicate(node.src);
    }
    //Check if we have content
    if ($.type(content) != "array") {
        node.leaf = true; //Node is leaf. It will not be deleted while removing empty.
        return;
    }
    //Check layout in columns
    if (typeof node.layoutInColumns == 'number' && node.layoutInColumns > 0 && node.layoutInColumns < 13) {
        layout.layoutInColumns.push(node.id);
    }
    for (const child of content) {
        //Skip non-objects!
        if ($.type(child) != "object") {
            continue;
        }
        normalizeLayout(node.id, child, layout); //recursive call for children
    }
}

function getClassName(cls) {
    return cls.namespace.prefix + "." + cls.name;
}

function layoutIt(cls, parentPath) {

    let layoutTree = cls.layout;

    //If layout is string then we need to parse it
    if (typeof layoutTree == 'string') {
        layoutTree = JSON.parse(layoutTree);
    }

    //If we do not have layout then we should build default one    
    if (layoutTree == null || !isObject(layoutTree)) {
        layoutTree = buildDefaultLayout(cls);
    }

    //Layout in normalized form
    const clsName = getClassName(cls);
    const layout = {
        //Main information
        clsName: clsName,                                   //Class name to generate id
        counter: 1,                                         //Counter to generate id
        toolbar: layoutTree.toolbar || [],                  //Toolbar or empty toolbar
        override: layoutTree.override || false,             //Copy override property
        //User interface elements
        byId: {},                                           //Map between id and element definition
        parentIdByChildId: {},                              //Map between child id and parent id
        childrenIdsByParentId: {},                          //Map between parent id and it's children ids
        rootId: null,                                       //Root element id
        //Placeholders
        parentPlaceHolderId: null,                          //Parent place holder id (ui id).
        childPlaceHolderId: null,                           //Child place holder id (ui id).
        selfPlaceHolderId: null,                            //Self placeholder id (ui id).
        predicatePlaceHolderIds: [],                        //List of predicate placeholders which must be applied in order.
        layoutInColumns: [],                                //List of ids to layout in columns
        predicateUrlMap: {},                                //Map of predicate URLs
        nonPredicatePlaceholderIds: [],                     //List of all placeholders to cleanup
        predicateIds: [],                                   //List of all predicates
        mandotarySet: {}                                    //Map of mandotary fields
    };

    const root = { id: layoutTree.id, content: layoutTree.content }; //Make root
    normalizeLayout(null, root, layout);
    //Protect from empty roots
    if (!layout.childrenIdsByParentId[layout.rootId]) {
        layout.childrenIdsByParentId[layout.rootId] = [];
    }
    //Build predicates
    let parentId = layout.rootId;
    let index = 0; //position to insert predicates
    if (layout.selfPlaceHolderId) {
        parentId = layout.parentIdByChildId[layout.selfPlaceHolderId];
        index = layout.childrenIdsByParentId[parentId].indexOf(layout.selfPlaceHolderId);
    }
    //Create normal predicates
    for (const p of cls.predicateList) {
        const field = buildPredicateField(p, parentPath);
        layout.predicateIds.push(field.id); //Add to list of id's
        layout.byId[field.id] = field;
        layout.predicateUrlMap[p.namespace.url + p.name] = field.id;
        layout.parentIdByChildId[field.id] = parentId;
        if (field.mandotary) {
            layout.mandotarySet[field.id] = true;
        }
    }
    //Generate virtual predicates
    if (isArray(layoutTree.virtual)) {
        for (const v of layoutTree.virtual) {
            if (typeof v.path != "string" || typeof v.dataType != "string" || typeof v.label != "string") {
                continue;
            }
            const path = v.path.replace(":", ".").split(".");
            const prefix = path.shift();
            const p = { //emulate predicate
                label: v.label,
                namespace: {
                    prefix: prefix
                },
                name: path.join("."),
                dataType: {
                    name: v.dataType
                },
                multiplicityInfo: "M:0..1", //optional
                systemDriven: true //do not allow editing
            };
            const field = buildPredicateField(p, parentPath);
            layout.predicateIds.push(field.id); //Add to list of id's
            layout.byId[field.id] = field;
            layout.parentIdByChildId[field.id] = parentId;
        }
    }
    //Now add predicates to parent children elements
    const spliceArgs = [index, 0].concat(layout.predicateIds); //build splice arguments
    Array.prototype.splice.apply(layout.childrenIdsByParentId[parentId], spliceArgs); //Insert new elements into the array

    console.log(layout);

    return layout;
}

function layoutMerge(childLayout, parentLayout) {

    console.log("Merge child:", childLayout, 'parent:', parentLayout);

    //Override means we put parent into child layout!
    //Now we need to understand
    //what layout is base layout and what layout is sub layout.
    //Base layout embeds other sub layout
    //1) If overrides=true then childLayout is base layout
    //2) Else parentLayout is base layout
    let baseLayout;
    let subLayout;
    let placeHolderId;

    if (childLayout.override) {
        baseLayout = childLayout;
        subLayout = parentLayout;
        placeHolderId = childLayout.parentPlaceHolderId;
    } else {
        baseLayout = parentLayout;
        subLayout = childLayout;
        placeHolderId = parentLayout.childPlaceHolderId;
    }

    //Create new layout
    const layout = {
        rootId: baseLayout.rootId,
        override: baseLayout.override,
        clsName: baseLayout.clsName,
        counter: baseLayout.counter,
        parentPlaceHolderId: parentLayout.parentPlaceHolderId,
        childPlaceHolderId: childLayout.childPlaceHolderId
    };

    //Merge elements
    layout.childPlaceHolderId = subLayout.childPlaceHolderId;
    layout.parentPlaceHolderId
    layout.byId = Object.assign({}, baseLayout.byId, subLayout.byId);
    layout.parentIdByChildId = Object.assign({}, baseLayout.parentIdByChildId, subLayout.parentIdByChildId);
    layout.childrenIdsByParentId = Object.assign({}, baseLayout.childrenIdsByParentId, subLayout.childrenIdsByParentId);
    layout.predicateUrlMap = Object.assign({}, baseLayout.predicateUrlMap, subLayout.predicateUrlMap);
    layout.layoutInColumns = [].concat(baseLayout.layoutInColumns, subLayout.layoutInColumns);
    layout.predicatePlaceHolderIds = [].concat(baseLayout.predicatePlaceHolderIds, subLayout.predicatePlaceHolderIds);
    layout.nonPredicatePlaceholderIds = [].concat(baseLayout.nonPredicatePlaceholderIds, subLayout.nonPredicatePlaceholderIds);
    layout.mandotarySet = Object.assign({}, baseLayout.mandotarySet, subLayout.mandotarySet);
    layout.predicateIds = [].concat(baseLayout.predicateIds, subLayout.predicateIds);
    layout.toolbar = [].concat(baseLayout.toolbar, subLayout.toolbar);

    //Replace subLayout root
    let parentId = layout.rootId;
    let index = 0;
    if (placeHolderId) {
        parentId = layout.parentIdByChildId[placeHolderId];
        index = layout.childrenIdsByParentId[parentId].indexOf(placeHolderId);
    }

    //Remove subLayout root
    delete layout.byId[subLayout.rootId];
    delete layout.childrenIdsByParentId[subLayout.rootId];

    //Reparent subLayout root children
    for (const id of subLayout.childrenIdsByParentId[subLayout.rootId]) {
        layout.parentIdByChildId[id] = parentId;
    }

    //Add subLayout root children to the new place
    const spliceArgs = [index, 0].concat(subLayout.childrenIdsByParentId[subLayout.rootId]); //build splice arguments
    Array.prototype.splice.apply(layout.childrenIdsByParentId[parentId], spliceArgs); //Insert new elements into the array
    //Return resulting layout
    return layout;
}

function findPrefixGlob(layout, glob) {
    glob = glob.replace(":", ".");
    const found = [];
    const mm = new minimatch.Minimatch(glob, { nocomment: true });
    for (const key in layout.byId) {
        if (!layout.byId.hasOwnProperty(key)) {
            continue;
        }
        if (mm.match(key)) {
            found.push(key);
        }
    }
    return found;
}

function findUrlGlob(layout, glob) {
    const found = [];
    const mm = new minimatch.Minimatch(glob, { nocomment: true });
    for (const key in layout.predicateUrlMap) {
        if (!layout.predicateUrlMap.hasOwnProperty(key)) {
            continue;
        }
        if (mm.match(key)) {
            found.push(layout.predicateUrlMap[key]);
        }
    }
    return found;
}

function removeIds(layout, ids) {
    for (const id of ids) {
        const parentId = layout.parentIdByChildId[id];
        layout.childrenIdsByParentId[parentId] = layout.childrenIdsByParentId[parentId].filter((x) => x != id);
        delete layout.byId[id];
        delete layout.parentIdByChildId[id];
        delete layout.childrenIdsByParentId[id];
    }
}

export function generateBindings(automation, cls) {
    return {
        clearAll: function () {
            for (const prop in automation) {
                if ($.type(automation[prop]) == 'object') {
                    automation[prop] = {};
                } else if ($.type(automation[prop]) == 'array') {
                    automation[prop] = [];
                }
            }
        },
        bindOptions: function (predicate, func) {
            if (typeof func == 'function') {
                automation.optionsBindings[normalizePredicate(predicate)] = registerFunction(func);
            } else {
                delete automation.optionsBindings[normalizePredicate(predicate)];
            }
        },
        bindValue: function (predicate, func) {
            if (typeof func == 'function') {
                automation.valueBindings[normalizePredicate(predicate)] = registerFunction(func);
            } else {
                delete automation.valueBindings[normalizePredicate(predicate)];
            }
        },
        bindEnumeration: function (predicate, func) {
            if (typeof func == 'function') {
                automation.enumerationBindings[normalizePredicate(predicate)] = registerFunction(func);
            } else {
                delete automation.enumerationBindings[id];
            }
        },
        bindValidation: function (predicate, func) {
            if (typeof func == 'function') {
                automation.validationBindings[normalizePredicate(predicate)] = registerFunction(func);
            } else {
                delete automation.validationBindings[normalizePredicate(predicate)];
            }
        },
        bindClick: function (id, func) {
            if (typeof func == 'function') {
                automation.clickBindings[id] = registerFunction(func);
            } else {
                delete automation.clickBindings[id];
            }
        },
        bindVisible: function (predicate, func) {
            if (typeof func == 'function') {
                automation.visibilityBindings[normalizePredicate(predicate)] = registerFunction(func);
            } else {
                delete automation.visibilityBindings[normalizePredicate(predicate)];
            }
        },
        bindUiVisible: function (nodeId, func) {
            const uiNodeId = `${normalizeClass(cls)}.ui${nodeId}`;
            if (typeof func == 'function') {
                automation.visibilityBindings[uiNodeId] = registerFunction(func);
            } else {
                delete automation.visibilityBindings[uiNodeId];
            }
        },
        bindFill: function (predicate, func) {
            if (typeof func == 'function') {
                automation.fillBindings[normalizePredicate(predicate)] = registerFunction(func);
            } else {
                delete automation.fillBindings[normalizePredicate(predicate)];
            }
        },
        bindLink: function (predicate, func) {
            if (typeof func == 'function') {
                automation.linkBindings[normalizePredicate(predicate)] = registerFunction(func);
            } else {
                delete automation.linkBindings[normalizePredicate(predicate)];
            }
        },
        bindLock: function (predicateOrFunc, func) {
            if (typeof predicateOrFunc == 'string') {
                if (typeof func == 'function') {
                    automation.lockBindings[normalizePredicate(predicateOrFunc)] = registerFunction(func);
                } else {
                    delete automation.lockBindings[normalizePredicate(predicateOrFunc)];
                }
            } else {
                if (typeof predicateOrFunc == "function") {
                    automation.commonLockChecks.push(registerFunction(predicateOrFunc));
                } else {
                    automation.commonLockChecks = [];
                }
            }
        }
    };
}

export function layoutAll(cls, withPlaceholders) {
    //Layout by class name
    const layoutByClassName = {};
    //List of automation script
    const scriptList = [];
    //Classes from current class through all parents
    const inheritanceList = [];
    //We search classes using width search. So that with multiple inheritance order is maintained.
    const queue = [cls];
    while (queue.length > 0) {
        const current = queue.shift();
        const clsName = getClassName(current);
        if (layoutByClassName[clsName]) {
            continue; //protect from infinite recursion
        }
        inheritanceList.push(clsName);
        layoutByClassName[clsName] = layoutIt(current, []);
        if (current.automation) {
            scriptList.push(current.automation);
        }
        for (const inreritanceInfo of current.parentList) {
            const parentClass = inreritanceInfo.peerClass;
            queue.push(parentClass);
        }
    }
    //Merge layout in reverse order from parent to child
    inheritanceList.reverse();
    //Print results
    console.log("Inheritance:", inheritanceList);
    console.log("Layouts:", layoutByClassName);
    const layout = inheritanceList.reduce((parentLayout, childClsName) => {
        console.log("reduce:", parentLayout, childClsName);
        if (!parentLayout) {
            return layoutByClassName[childClsName];
        }
        const childLayout = layoutByClassName[childClsName];
        return layoutMerge(childLayout, parentLayout);
    }, null);
    if (withPlaceholders) {
        return layout;
    }
    //Add special fields to make available for placeholders
    const specialList = [
        {
            id: "$label",
            ui: UI_LABEL,
            leaf: true
        },
        {
            id: "$description",
            ui: UI_DESCRIPTION,
            leaf: true
        }
    ];
    for (let special of specialList) {
        layout.byId[special.id] = special;
        layout.predicateIds.push(special.id);
        //Add to parent but not to child list of parent (so that those items will not be displayed by default)
        layout.parentIdByChildId[special.id] = layout.rootId;
    }
    //Apply predicate placeholders and remove them
    const used = {}; //Already used predicates
    for (const phId of layout.predicatePlaceHolderIds) {
        const placeholder = layout.byId[phId];
        const parentId = layout.parentIdByChildId[phId]; //placeholder parent 
        const index = layout.childrenIdsByParentId[parentId].indexOf(phId); //placeholder index
        const ref = layout.byId[phId].ref; //predicate reference
        let found = ref.indexOf("://") >= 0 ? findUrlGlob(layout, ref) : findPrefixGlob(layout, ref);
        found = found.filter((id) => {
            const u = used[id];
            used[id] = true;
            if (!u) {
                //Save previous parent
                const prevParentId = layout.parentIdByChildId[id];
                //Change parent
                layout.parentIdByChildId[id] = parentId;
                //Remove from previous parent
                layout.childrenIdsByParentId[prevParentId] = layout.childrenIdsByParentId[prevParentId].filter((x) => x != id);
                if (placeholder.options) {
                    console.log("Assign placeholder options: ", id, placeholder.options);
                    layout.byId[id] = Object.assign({}, layout.byId[id], placeholder.options);
                }
                return true;
            }
            return false;
        })
        const spliceArgs = [index, 1].concat(found); //build splice arguments.
        Array.prototype.splice.apply(layout.childrenIdsByParentId[parentId], spliceArgs); //Insert new elements into the array
        delete layout.byId[phId];
        delete layout.parentIdByChildId[phId];
    }
    delete layout.predicatePlaceHolderIds;
    //Remove placeholders
    removeIds(layout, layout.nonPredicatePlaceholderIds);
    delete layout.nonPredicatePlaceholderIds;
    //Remove empty
    let empty;
    do {
        empty = [];
        for (const id in layout.byId) { //traverse all keys
            if (!layout.byId.hasOwnProperty(id)) {
                continue;
            }
            if (id == layout.rootId) { //do not remove root
                continue;
            }
            const node = layout.byId[id];
            if (node.leaf) { //do not remove leafs
                continue;
            }
            if (!layout.childrenIdsByParentId[id] || layout.childrenIdsByParentId[id].length == 0) {
                empty.push(id);
            }
        }
        console.log("Remove empty:", empty);
        removeIds(layout, empty);
    } while (empty.length > 0); //continue we can find empty elements
    //Now layout contents in columns if necessary
    for (const id of layout.layoutInColumns) {
        if (!layout.childrenIdsByParentId[id]) { //May be we have deleted element as empty
            continue;
        }
        layoutInColumns(layout, id);
    }

    //Create automation
    scriptList.reverse(); //Reverse script list to apply them from parents to children
    layout.automation = {
        optionsBindings: {},
        valueBindings: {},
        enumerationBindings: {},
        clickBindings: {},
        validationBindings: {},
        lockBindings: {},
        visibilityBindings: {},
        fillBindings: {},
        linkBindings: {},
        commonLockChecks: [] //Lock checks not specific for the predicate
    };
    const bindings = generateBindings(layout.automation, cls);
    console.log("Compile automation");
    for (let script of scriptList) {
        console.log(script);
    }
    scriptCompiler(scriptList, bindings);
    console.log(layout);
    return layout;
};