import * as Action from '../constants/treesearch.js';
import {
    parseFinderOptions,
    selectFragment,
    selectClass,
    classLevelsReceived,
    waitForClasses,
    classesReceived,
    fragmentLevelsReceived,
    waitForFragments,
    fragmentsReceived
} from '../services/finder.js'

const initialState = {
    finder: null,
    ready: false,
    loading: true,
    error: null,
    request: null,
    result: null
};


////////////
//Reducers//
////////////
function waitForTreeSearch(state, { request }) {
    return Object.assign({}, state, { request, loading: true });
}

function receiveSearchResult(state, { result }) {
    return Object.assign({}, state, { loading: false, result });
}

function receiveSearchResultError(state, { error }) {
    return Object.assign({}, state, { loading: false, error });
}

function classLevelsReceivedHandler(state, { levels }) {
    let newState = Object.assign({}, state);
    newState.finder = Object.assign({}, newState.finder);
    classLevelsReceived(newState.finder, levels);
    return newState;
}

function waitForClassesHandler(state, { levelId }) {
    let newState = Object.assign({}, state);
    newState.finder = Object.assign({}, newState.finder);
    waitForClasses(newState.finder, levelId);
    return newState;
}

function classesReceivedHandler(state, { levelId, classes }) {
    let newState = Object.assign({}, state);
    newState.finder = Object.assign({}, newState.finder);
    classesReceived(newState.finder, levelId, classes);
    return newState;
}

function fragmentLevelsReceivedHandler(state, { levels }) {
    let newState = Object.assign({}, state);
    newState.finder = Object.assign({}, newState.finder);
    fragmentLevelsReceived(newState.finder, levels);
    return newState;
}

function waitForFragmentsHandler(state, { parentId }) {
    let newState = Object.assign({}, state);
    newState.finder = Object.assign({}, newState.finder);
    waitForFragments(newState.finder, parentId);
    return newState;
}

function fragmentsReceivedHandler(state, { parentId, fragments }) {
    let newState = Object.assign({}, state);
    newState.finder = Object.assign({}, newState.finder);
    fragmentsReceived(newState.finder, parentId, fragments);
    return newState;
}

function selectFragmentHandler(state, { oldFragmentId, newFragmentId }) {
    let newState = Object.assign({}, state);
    newState.finder = Object.assign({}, newState.finder);
    selectFragment(newState.finder, oldFragmentId, newFragmentId);
    return newState;
}

function selectClassHandler(state, { oldClassId, newClassId, level }) {
    let newState = Object.assign({}, state);
    newState.finder = Object.assign({}, newState.finder);
    selectClass(newState.finder, oldClassId, newClassId, level);
    return newState;
}

function toggleFinder(state, { }) {
    let newState = Object.assign({}, state);
    newState.finder = Object.assign({}, newState.finder);
    newState.finder.isShowed = !newState.finder.isShowed;
    return newState;
}

function receiveFinderOptions(state, { data }) {
    let newState = Object.assign({}, state);
    if (!data.finderOptions) {
        newState.finder = null;
    } else {
        newState.finder = Object.assign({}, newState.finder);
        newState.finder.isShowed = true;
        parseFinderOptions(newState.finder, data.finderOptions);
    }
    newState.ready = true;
    newState.loading = false;
    return newState;
}

function receiveFinderOptionsError(state, { error }) {
    return Object.assign({}, state, { ready: true, loading: false, error });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Action.WAIT_FOR_TREE_SEARCH: return waitForTreeSearch(state, action.payload);
        case Action.RECEIVE_SEARCH_RESULT: return receiveSearchResult(state, action.payload);
        case Action.RECEIVE_SEARCH_RESULT_ERROR: return receiveSearchResultError(state, action.payload);
        case Action.CLASS_LEVELS_RECEIVED: return classLevelsReceivedHandler(state, action.payload);
        case Action.WAIT_FOR_CLASSES: return waitForClassesHandler(state, action.payload);
        case Action.CLASSES_RECEIVED: return classesReceivedHandler(state, action.payload);
        case Action.FRAGMENT_LEVELS_RECEIVED: return fragmentLevelsReceivedHandler(state, action.payload);
        case Action.WAIT_FOR_FRAGMENTS: return waitForFragmentsHandler(state, action.payload);
        case Action.FRAGMENTS_RECEIVED: return fragmentsReceivedHandler(state, action.payload);
        case Action.SELECT_FRAGMENT: return selectFragmentHandler(state, action.payload);
        case Action.SELECT_CLASS: return selectClassHandler(state, action.payload);
        case Action.TOGGLE_FINDER: return toggleFinder(state, action.payload);
        case Action.RECEIVE_FINDER_OPTIONS: return receiveFinderOptions(state, action.payload);
        case Action.RECEIVE_FINDER_OPTIONS_ERROR: return receiveFinderOptionsError(state, action.payload);
        default: return state;
    }
}