import React from 'react';
import Basic from './basic.jsx';
import { EVENT_FLUSH_DEBOUNCE } from '../../../constants/objectcard.js';
import debounce from '../../../services/debounce';
import {
    validate,
    fromRedux,
    toRedux
} from '../../../services/formatvalidator.js';

class TextArea extends Basic {

    constructor(props) {
        super(props);

        this.state = {
            value: this.getValue(props)
        }

        this.delayedHandleChange = this.delayedHandleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.blur = this.blur.bind(this);
        this.keyDown = this.keyDown.bind(this);
        this.flushDebounce = this.flushDebounce.bind(this);
        this.change = this.change.bind(this);
    }

    flushDebounce(event, info) {
        if (this.props.formId == info.formId && this.debounceFunc) {
            console.log("Flush debounce: ", this.props.nodeId);
            this.debounceFunc.flush();
        }
    }

    componentDidMount() {
        $(document).bind(EVENT_FLUSH_DEBOUNCE, this.flushDebounce);
    }

    componentWillUnmount() {
        $(document).unbind(EVENT_FLUSH_DEBOUNCE, this.flushDebounce);
    }

    change(value) {
        const id = this.props.node.id;
        const transform = this.props.node.options.transform; //May be set from options
        if (transform) { //for server side transformation
            this.props.change({ $value: value, $transform: transform });
        } else {
            this.props.change(value);
        }
    }

    finish() {
        if (this.debounceFunc) {
            this.debounceFunc.flush(); //Send change on finish
        }
    }

    blur(event) {
        this.finish();
    }

    keyDown(event) {
        if (event.keyCode == '13') {
            this.finish();
        }
    }

    getValue(props = this.props) {
        return fromRedux(props.value, null);
    }

    getStyle(style) {
        if (!style) {
            style = {};
        }
        style.width = "100%";
        return style;
    }


    debounce() {
        //Create debounce function on demand
        if (!this.debounceFunc) {
            this.debounceFunc = debounce(this.delayedHandleChange, 200);
        }
        if (this.props.editable) { //If user is too fast it will cause send data after editable is closed
            this.debounceFunc();
        }
    }

    delayedHandleChange() {
        //console.log("TextArea.delayedHandleChange (value, editable): ", this.syncValue, this.props.editable);
        if (!this.props.editable) { //Do not send data to redux if element is already not editable
            console.error("TextArea.delayedHandleChange() in non-editable mode!!!!");
            return;
        }
        this.wasChanged = true;
        const prevValue = this.getValue();
        if (prevValue == this.syncValue) { //Do not send events if everything is equal
            return;
        }
        this.change(toRedux(this.syncValue, null));
    }

    handleChange(event) {
        //console.log("DebounceInput.handleChange: ", event.target.value);
        this.syncValue = event.target.value; //Save value in class property so that changes will be synchronious!!!! 
        this.state.value = this.syncValue;
        this.debounce(); //Schedule later redux update
        this.forceUpdate();
    }

    componentWillReceiveProps(nextProps) {
        const nextValue = fromRedux(nextProps.value, null);
        if (nextProps.editable) {
            if (!this.props.editable) {
                //Set flag that data was not changed (user did not typed anything)
                this.wasChanged = false;
                //Update state in transition to editable mode
                //We also update DOM because editable is changed in the same time as predicates when obtaining lock
                this.state.value = this.syncValue = nextValue;
            } else if (!this.wasChanged && this.state.value != nextValue) {
                //Text was changed by fill
                this.state.value = this.syncValue = nextValue;
                console.log("Changed by fill: ", this.syncValue);
            }
        } else if (!nextProps.editable && this.state.value != nextValue) {
            //Update DOM in non-editable mode (not as event but always for automation to work)
            this.state.value = nextValue;
        }
    }

    getInput() {
        //We use this hack with uncontrolled input to overcome IE problems:
        //updates from state are delayed because updates are handled in "another thread".
        //https://github.com/omcljs/om/issues/704
        return (<textarea
            rows={this.props.node.options.rows || 3}
            value={this.state.value}
            onChange={this.handleChange}
            onBlur={this.blur}
            onKeyDown={this.keyDown}
            style={this.getStyle(this.props.style)}
            className={this.props.className ? this.props.className : ""}
            disabled={!this.props.editable} />);
    }

    render() {
        return this.wrapInput(this.props.node.label, this.getInput(), this.props.node.description);
    }
}

export default TextArea;