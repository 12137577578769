import security from './reducers/security';
import { SECURITY } from './constants/security';
import { parseGeneralAccessRules } from './services/security';

export default (dispatcher) => {
    //Get meta contents (received from server)
    let username = $('meta[name=security-username]').attr("content");
    let superUser = $('meta[name=security-superuser]').attr("content");
    let rules = $('meta[name=security-general-access-rules]').attr("content");
    if (!username) {
        username = "";
    }
    if (!rules) {
        rules = "";
    }
    dispatcher.registerReducer(SECURITY, security, { //Initial state
        username,
        superUser: (superUser == "true"),
        generalAccessRules: parseGeneralAccessRules(rules)
    });
}