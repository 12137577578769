export default {
	"functions": [
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Релематика (ТОР 300 БСК 555)",
			"name": "ДТЗ",
			"fileName": "ДТЗ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток срабатывания ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток торможения 2-го участка ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения 2-го участка ТХ, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kt2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток торможения 3-го участка ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения 3-го участка ТХ, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kt3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания дифференциальной отсечки, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idto",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы БВГ",
					"nameFromTargetFile": null,
					"rawValue": "NактБлкf2\n(0-вывод, 1-постоянно, 2-автоматически)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "AktBlk2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка блокировки по второй гармонике, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "K2f1f",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 1-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 2-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 1-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 2-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Экра",
			"name": "ДТЗ",
			"fileName": "ДТЗ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystDZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток начала торможения ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It0DZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток торможения блокировки ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItmaxDZT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtDZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уровень бл. по 2 гармонике, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kblpo2gar",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Дифференциальная отсечка",
					"nameFromTargetFile": null,
					"rawValue": "Диф",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndifots",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания диф. отсечки, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idifots",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка на срабатывание дифф. отсечки, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VremaDiffOts",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны СН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны НН2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №1",
					"nameFromTargetFile": null,
					"rawValue": "Схема ВН",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №2",
					"nameFromTargetFile": null,
					"rawValue": "Схема СН",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №3",
					"nameFromTargetFile": null,
					"rawValue": "Схема НН1",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №4",
					"nameFromTargetFile": null,
					"rawValue": "Схема НН2",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона ВН",
					"nameFromTargetFile": null,
					"rawValue": "Сторона ВН",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона СН",
					"nameFromTargetFile": null,
					"rawValue": "Сторона СН",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона НН1",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН1",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона НН2",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН2",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ ВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_vn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ CН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_sn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_nn1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_nn2",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "ABB",
			"name": "ДТЗ",
			"fileName": "ДТЗ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции дифференциальной защиты трансформатора, Выкл/Вкл",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - 1)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Векторная группа двухобмоточного трансформатора (1-24), о.е.",
					"nameFromTargetFile": null,
					"rawValue": "VectorGroup 2W (1-24)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VectorGroup 2W",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Векторная группа трехобмоточного трансформатора (1-288), о.е.",
					"nameFromTargetFile": null,
					"rawValue": "VectorGroup 3W (1-288)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VectorGroup 3W",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вычитание тока нулевой последовательности, Выкл/Вкл",
					"nameFromTargetFile": null,
					"rawValue": "ZSCSub (0-1)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZSCSub",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номер тормозной характеристики",
					"nameFromTargetFile": null,
					"rawValue": "CharactNo\n(1 - 5)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CharactNo",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальная уставка срабатывания дифференциальной защиты в % от номинального тока опорной стороны Ir (Iном)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idmin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка дифференциальной защиты без торможения (дифотсечка) в % от Ir (Iном)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idunre",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Блокировка по второй гармонике, Условно/Всегда",
					"nameFromTargetFile": null,
					"rawValue": "StabByOption\n(0 - 1 )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StabByOption",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Блокировка дифференциальной защиты с торможением по второй гармонике (отношение второй гармоники к первой) в %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2/I1 ratio",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Принятое (включенное) ответвление обмотки \nвходного ТТ (1А или 5А)",
					"nameFromTargetFile": null,
					"rawValue": "Input CT Tap \n(1A, 5A)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Input CT Tap",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток высоковольтного (защитного) ТТ.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CT prim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток высоковольтного (защитного) ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CT sec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток первичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение первичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток вторичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение вторичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность первичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток первичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение первичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность вторичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток вторичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение вторичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность третичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток третичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение третичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur3",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Siemens (7UT613)",
			"name": "ДТЗ",
			"fileName": "ДТЗ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "ДиффЗащита",
					"nameFromTargetFile": null,
					"rawValue": "112",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ДиффЗащита",
					"nameFromTargetFile": null,
					"rawValue": "1201",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "I-Дифф>>,I/Iн0",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1231",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "I-Дифф>,I/Iн0",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1221",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "УголНаклона1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1241A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовая Точка1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1242A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "УголНаклона2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1243A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовая Точка2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1244A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Содержание 2 гармоники в токе I-Дифф",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1271",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торм.ток при броске тока намагнич. По 2 гарм.",
					"nameFromTargetFile": null,
					"rawValue": "1206",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1206",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Релематика ТОР 300 БСК 55Х",
			"name": "ЗПВГ",
			"fileName": "ЗПВГ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ЗП, % Iтерм",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС защиты, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "ABB",
			"name": "ЗПВГ",
			"fileName": "ЗПВГ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Значение пуска",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Start Value",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель для масштабирования  значения пуска",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Start value Mult",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени характеристик МЭК/ANSI",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Time multiplier",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени на срабатывание, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operate delay time",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выбор типа характеристики срабатывания",
					"nameFromTargetFile": null,
					"rawValue": "Тип кривой срабат",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operating curve type",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выбор типа характеристики возврата",
					"nameFromTargetFile": null,
					"rawValue": "Тип кривой возврата [Type of reset curve]\n(1=Мгновенная (Immediate) (токовая отсечка) \n2=Независимая (Def time reset) (независимая вы-\nдержка времени ) \n3=Инверсная (Inverse reset) (Возврат с инверсной выдержкой времени))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Type of reset curve",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Срабатывание",
					"nameFromTargetFile": null,
					"rawValue": "Активизация (Operation ) \n(1=on \n5=off)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное время срабатывания для характеристики МЭК IDMT, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Minimum operate time",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени на возврат",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Reset delay time",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр A для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve parameter A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр В для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve parameter B",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр С для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve arameter С",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр D для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve arameter D",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр E для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve parameter E",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Siemens 7UT613",
			"name": "ЗПВГ",
			"fileName": "ЗПВГ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "2001\n(ОТКЛ/ ВКЛ/\nРелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени I>, сек",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2016",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Релематика ТОР 300 БСК",
			"name": "МТЗ",
			"fileName": "МТЗ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Siemens 7UT613x",
			"name": "МТЗ",
			"fileName": "МТЗ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "2001\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2011",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2013",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2016",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Релематика (ТОР 300 БСК 555)",
			"name": "НзБ",
			"fileName": "НзБ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод сигнальной ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nсигн(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nsign",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания сигнальной ступени, % IтермНб",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isign",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС сигнальной ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsign",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод отключающей ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nоткл\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Notkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания отключающей ступени, % IтермНб",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС сигнальной ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Totkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток взода терминала небаланса в БСК, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermHb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "коэфициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Siemens (7SJ61)",
			"name": "НзБ",
			"fileName": "НзБ_БСК",
			"setpointsFromReferenceFile": []
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Релематика ТОР 300 БСК 55Х",
			"name": "ТЗНП",
			"fileName": "ТЗНП_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО утроенного тока нулевой последовательности 1 ступени, % 3I0термН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0st1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО утроенного тока нулевой последовательности 2 ступени, % 3I0термН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0st2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка блокировки по второй гармонике, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kf2f1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный утроенный ток нулевой последовательности ИТТ стороны нейтрали, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0pervN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный утроенный ток нулевой последовательности ИТТ стороны нейтрали, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0vtorN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный утроенный ток входа терминала нулевой последовательности стороны нейтрали, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0termN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Экра 2607 073...158",
			"name": "ТЗНП",
			"fileName": "ТЗНП_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО ТЗНП, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrTZNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT11 Задержка на срабатывание ТЗНП в защиту Т2, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT10 Задержка на отключение ШСВ, СВ от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT13 Задержка на отключение выключателя от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT13",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT14 Задержка на отключение трансформатора от ТЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT14",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ ВН, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "ABB RET521",
			"name": "ТЗНП",
			"fileName": "ТЗНП_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация защиты от замыканий на землю с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDef",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Временная характеристика для TEFх",
					"nameFromTargetFile": null,
					"rawValue": "CurveType\n(0 - DEF - независимая/\n1 - NI - нормально инверсная/\n2 - VI - очень инверсная/\n3 - EI - чрезвычайно инверсная/\n4 - LI - длительно инверсная/\n5 - LOG - логарифмическая (RXIDG))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CurveType",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для низкой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для обратнозависимой выдержки времени",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "K",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение низкой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harLow\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение высокой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harHigh\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отношение второй гармоники к первой, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2/I1 ratio",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальныйвторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Siemens 7UT6",
			"name": "ТЗНП",
			"fileName": "ТЗНП_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ Нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": "2401\n(ВКЛ/ ОТКЛ/РелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2401",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2411",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2412",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2413",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2414",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отстройка от броска тока намагничивания МТЗ земл",
					"nameFromTargetFile": null,
					"rawValue": "2402\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2402",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка составляющей 2-й гармоники для определения бросков тока намагничивания, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2441",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Релематика ТОР 300 БСК 55Х",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО утроенного тока нулевой последовательности 1 ступени, % Iтерм",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0st1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО утроенного тока нулевой последовательности 2 ступени, % Iтерм",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0st2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка блокировки по второй гармонике, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kf2f1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Экра 2607 073...158",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО ТЗНП, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrTZNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT11 Задержка на срабатывание ТЗНП в защиту Т2, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT10 Задержка на отключение ШСВ, СВ от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT13 Задержка на отключение выключателя от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT13",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT14 Задержка на отключение трансформатора от ТЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT14",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ ВН, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "ABB RET521",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация защиты от замыканий на землю с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDef",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Временная характеристика для TEFх",
					"nameFromTargetFile": null,
					"rawValue": "CurveType\n(0 - DEF - независимая/\n1 - NI - нормально инверсная/\n2 - VI - очень инверсная/\n3 - EI - чрезвычайно инверсная/\n4 - LI - длительно инверсная/\n5 - LOG - логарифмическая (RXIDG))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CurveType",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для низкой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для обратнозависимой выдержки времени",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "K",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение низкой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harLow\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение высокой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harHigh\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отношение второй гармоники к первой, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2/I1 ratio",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальныйвторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Siemens 7UT6",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ Нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ/ ОТКЛ/РелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ 3I0>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2214",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ 3I0>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2216",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ 3I0>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2211",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ 3I0>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2213",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отстройка от броска тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "2202\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка составляющей 2-й гармоники для определения бросков тока намагничивания, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2241",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Релематика ТОР 300 БСК 55Х",
			"name": "ТЗОП",
			"fileName": "ТЗОП_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока обратной последователь-ности, % Iтерм",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2srab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС защиты, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "ABB",
			"name": "ТЗОП",
			"fileName": "ТЗОП_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Значение пуска",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Start Value",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель для масштабирования  значения пуска",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Start value Mult",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени характеристик МЭК/ANSI",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Time multiplier",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени на срабатывание, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operate delay time",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выбор типа характеристики срабатывания",
					"nameFromTargetFile": null,
					"rawValue": "Тип кривой срабат",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operating curve type",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выбор типа характеристики возврата",
					"nameFromTargetFile": null,
					"rawValue": "Тип кривой возврата [Type of reset curve]\n(1=Мгновенная (Immediate) (токовая отсечка) \n2=Независимая (Def time reset) (независимая выдержка времени ) \n3=Инверсная (Inverse reset) (Возврат с инверсной выдержкой времени))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Type of reset curve",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Срабатывание",
					"nameFromTargetFile": null,
					"rawValue": "Активизация (Operation ) \n(1=on \n5=off)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное время срабатывания для характеристики МЭК IDMT, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Minimum operate time",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени на возврат",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Reset delay time",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр A для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve parameter A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр В для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve parameter B",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр С для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve arameter С",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр D для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve arameter D",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр E для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve parameter E",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Siemens 7UT613",
			"name": "ТЗОП",
			"fileName": "ТЗОП_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Несимметр. нагрузка (обратная последов.)",
					"nameFromTargetFile": null,
					"rawValue": "4001\n(ОТКЛ/ ВКЛ/\nРелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I2>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени I2>, сек",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4016",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Релематика ТОР 300 БСК",
			"name": "УРОВ",
			"fileName": "УРОВ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Работа УРОВ",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет;\n 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль РПВ при действии УРОВ на смежный \nвыключатель",
					"nameFromTargetFile": null,
					"rawValue": "NконтрРПВ \n(0 – нет; \n1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NсontrRPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Фазный ток УРОВ, % от Iтерм",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Замедление отключения смежных выключателей, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны вводов, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Батарея статического конденсатора",
			"vendor": "Siemens 7UT613x",
			"name": "УРОВ",
			"fileName": "УРОВ_БСК",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная УРОВ",
					"nameFromTargetFile": null,
					"rawValue": "7001\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог тока разомкнутого полюса ТТ М1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1121",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени Т2, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7016",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный номинальный ток ТТ М1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "512",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичный номинальный ток ТТ М1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "513",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Релематика",
			"name": "ДЗ",
			"fileName": "ДЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Отключение от ступени ДЗ",
					"nameFromTargetFile": null,
					"rawValue": "Nоткл \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N89",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Действительная часть коэффициента компенсации током нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sK0re",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Мнимая часть коэффициента компенсации током нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sK0im",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сопротивление срабатывания ступени ДЗ по каналу «фаза-земля», Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sZpe",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление срабатывания ступени ДЗ по каналу «фаза-земля», Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sRpe",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности по каналу «фаза-земля», градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sFYpe",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от внешних замыканий по каналу «фаза-земля», градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sFY4pe",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сопротивление срабатывания ступени ДЗ по \nканалу «фаза-фаза», Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sZpp",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное  сопротивление  срабатывания \nступени ДЗ по каналу «фаза-фаза», Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sRpp",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол  максимальной  чувствительности  по \nконтуру «фаза-фаза», градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sFYpp",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол  отстройки  от  внешних  замыканий  по \nконтуру «фаза-фаза», градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sFY4pp",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности ступени ДЗ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N77",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС  отключения  ступени  ДЗ  от \nмеждуфазных КЗ, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС отключения по каналам «фаза-земля» ступени ДЗ, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим ускорения ДЗ при включении на повреждение",
					"nameFromTargetFile": null,
					"rawValue": "NускСтАУ\n(0 – вывод, 1, 2, 3, 4, 5 – ненаправленный пуск ступеней 1, 2, 3, 4, 5 ДЗ; 6, 7, 8, 9, 10 – направленный пуск ступеней 1, 2, 3, 4, 5 ДЗ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N45",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим оперативного ускорения ДЗ",
					"nameFromTargetFile": null,
					"rawValue": "NускСтОУ\n(0 – вывод, 1, 2, 3, 4, 5 – ненаправленный пуск ступеней 1, 2, 3, 4, 5 ДЗ; 6, 7, 8, 9, 10 – направленный пуск ступеней 1, 2, 3, 4, 5 ДЗ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N93",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС в режиме автоматического ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT35",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС в режиме оперативного ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT36",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Максимальное переходное сопротивление при междуфазных замыканиях, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "gfc_sRFPP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Максимальное переходное сопротивление при замыканиях по каналу «фаза-земля», Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "gfc_sRFPE",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное сопротивление нагрузки по оси R, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "gfc_sRLd",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от нагрузочного режима, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "gfc_sArgLd",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол характеристики направленности в четвертый квадрант, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sArgDir",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол характеристики направленности отрицательных  переходных сопротивлений, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sArgNeg",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Usec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Экра",
			"name": "ДЗ",
			"fileName": "ДЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Коррект. множитель KKR коэф. компенсации тока 3Io по R",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KKR3I0R",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коррект. множитель KKX коэф. компенсации тока 3Io по X",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KKR3I0X",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики I ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xyst1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики I ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ryst1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики I ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон верхней части характеристики I ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon1st1kv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT33 Задержка на срабатывание I ст. ДЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst1stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики I ст. при КЗ на землю, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xzyst1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики I ст. при КЗ на землю, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rzyst1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона характеристики I ст. при КЗ на землю, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklonzem1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT48 Задержка на срабатывание I ст. ДЗ на землю, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tystzem1stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики II ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xyst2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики II ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ryst2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики II ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT35 Задержка на срабатывание II ст. ДЗ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst2stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики III ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xyst3st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики III ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ryst3st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики III ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon3st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT36 Задержка на срабатывание III ст. ДЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst3stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики IV ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xyst4st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики IV ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ryst4st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики IV ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon4st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT46 Задержка на срабатывание IV ст. ДЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst4stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики V ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xyst5st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики V ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ryst5st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики V ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon5st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT47 Задержка на срабатывание V ст. ДЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst5stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон левой части характеристики, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon2kv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон нижней правой части характеристики, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon4kv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R нагрузочного режима, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rnagr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол выреза нагрузочного режима, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ugolnagr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB28 Оперативно ускоряемая ступень ДЗ",
					"nameFromTargetFile": null,
					"rawValue": "Опер",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperUskorStDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT45 Задержка на сраб I,II или III ст.ДЗ при опер ускорении, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tsrOUDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB23 Ускоряемая ступень ДЗ при включении выключателя",
					"nameFromTargetFile": null,
					"rawValue": "Уск",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UskstDZpriVklV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT28 Время ввода ускорения при включении выключателя, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tvvprivklV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение переменного тока Uном, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "АВВ",
			"name": "ДЗ",
			"fileName": "ДЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация",
					"nameFromTargetFile": null,
					"rawValue": "Operation (0 - Отключена, 1 - Включена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Зона охвата по реактивному сопротивлению прямой последовательности, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "X1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление прямой последовательности для характеристического угла зоны, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "R1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Зона охвата по реактивному сопротивлению нулевой последовательности, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "X0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление нулевой последовательности для характеристического угла зоны, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "R0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Область активного сопротивления в месте КЗ, фаза-фаза,  Ом/контур",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RFPP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени отключения, фаза-фаза, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tPP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности",
					"nameFromTargetFile": null,
					"rawValue": "OperationDir (0 - Отключена; 1 - Ненаправленная; 1 - Прямая; 2 - Обратная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperationDir1(2,3,4,5)",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол направляющей во втором квадранте для прямого напрявления, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgNegRes",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол направляющей в четвертом квадранте для прямого направления, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgDir",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Зона охвата активного сопротивления в прямом направлении в области режима нагрузки, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RLdFw",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Зона охвата активного сопротивления в обратном направлении в области режима нагрузки, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RLdRv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол сектора нагрузки, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgLd",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Siemens",
			"name": "ДЗ",
			"fileName": "ДЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Формат Z0/Z1",
					"nameFromTargetFile": null,
					"rawValue": "237\n(RЕ/RL, ХЕ/ХL; К0)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "237",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.комп.нул.посл. RЕ/RL для Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1116",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.комп.нул.посл.ХЕ/ХL для Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1117",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.комп.нул.посл.RЕ/RL для Z1В...Z5",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1118",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.комп.нул.посл.ХЕ/ХL для Z1В...Z5",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1119",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент компенс.нул.посл. К0 для Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1120",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол компенс.нул.посл для ступени Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1121",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.компенс.нул.посл. К0, ступеней >Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол компен.нул.посл, ступеней >Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1123",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона характеристики ДЗ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1211",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z1",
					"nameFromTargetFile": null,
					"rawValue": "1301\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1301",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R Ст1 с полигональной характеристикой для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1302",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Ст1), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1303",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z1), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1304",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т1-однофаз, Выдержка для однофаз КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1305",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени T1 ступени Z1 при многофазных КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1306",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол скоса многоугольника (1. квадрант), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1307",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z2",
					"nameFromTargetFile": null,
					"rawValue": "1311\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R Ст2 для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Ст2), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1313",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z2), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1314",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т2-однофаз, Выдержка для однофаз КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1315",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т2-многофаз, Выдержка для многофаз КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1316",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z3",
					"nameFromTargetFile": null,
					"rawValue": "1321\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1321",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R Ст3 для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1322",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Ст3), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1323",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z3), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1324",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т3 Выдержка, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1325",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z4",
					"nameFromTargetFile": null,
					"rawValue": "1331\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1331",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R Ст4 для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1332",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Ст4), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1333",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z4), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1334",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т4 Выдержка, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1335",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z5",
					"nameFromTargetFile": null,
					"rawValue": "1341\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1341",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R Ст5 для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1342",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Ст5), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1343",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z5), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1344",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка Т5, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1345",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z1B (промежуточная ступень)",
					"nameFromTargetFile": null,
					"rawValue": "1351\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1351",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R(Z1B) для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1352",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Z1B), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1353",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z1B), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1354",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т1В-однофаз, Выдержка для однофаз КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1355",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т1В-многофаз, Выдержка для многофаз КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1356",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z6",
					"nameFromTargetFile": null,
					"rawValue": "1361",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1361",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "R(Z6), Сопротивление для ф-ф-КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1362",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "(Z6)+, РеакСопр. Направление Вперед, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1363",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z6), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1364",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка Т6, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1365",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Х(Z6)-, РеакСопр. Направление Назад, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1366",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Граница сектора нагрузки, (фаза-земля), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1241",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол сектора нагрузки, (фаза-земля), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1242",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Граница сектора нагрузки, (фаза-фаза), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1243",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол сектора нагрузки, (фаза-фаза), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1244",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение трансформатора\nнапряжения, вторичное (L-L), В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "204",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Релематика",
			"name": "ДЗЛ",
			"fileName": "ДЗЛ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания дифференциальной токовой отсечки, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdiffCut",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный дифференциальный ток срабатывания, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ibase",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный тормозной ток, о.е",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Itorm",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktorm",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отношение второй гармоники тока нулевой последовательности к первой гармонике тока нулевой последовательности, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kf2f1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа модуля определения КЗ в ЛЭП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности первой гармоники, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сопротивление срабатывания, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление срабатывания, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTR",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Расширение характеристики в третий квадрант, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTXADD",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от внешних КЗ, градуc",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFY4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол между положительной полуосью R и лучом характеристики в четвертом квадранте, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFYR",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол между положительной полуосью R и лучом характеристики во втором квадранте, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFYN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Длительность действия отключающих ИО при формировании сигнала «КЗ в ЛЭП», мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Dlit_KZvLEP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "ЭКРА",
			"name": "ДЗЛ",
			"fileName": "ДЗЛ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ДЗЛ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrDZL",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения дифференциальной защиты К1, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtormK1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ДТО, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrDTO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси X характеристики Z отв / Iном, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Хzotv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики Z отв / Iном, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rzotv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики Zотв, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Fzotv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон левой части ИО Z, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FIvZotv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон нижней правой части ИО Z, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FprZotv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB1 Работа на ВЛ с ответвлениями",
					"nameFromTargetFile": null,
					"rawValue": "Работа с ответвлениями \n(не предусмотрена /  по U / по РС)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB85 Дифференциальная токовая отсечка (ДТО)",
					"nameFromTargetFile": null,
					"rawValue": "ДТО \n(не предусмотрена/ предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NDTO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичная величина датчика аналогового входа Ia В1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorB1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичная величина датчика аналогового входа Ia В2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorB2",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "ABB",
			"name": "ДЗЛ",
			"fileName": "ДЗЛ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Режим работы функции DIFL",
					"nameFromTargetFile": null,
					"rawValue": "Operation \n(Выкл",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальный рабочий дифференциальный ток, % от I1b",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IMinOp",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение: наклон 1 характеристики, % от Ibias",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IDiffLvl1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток входа I1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1b",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Siemens",
			"name": "ДЗЛ",
			"fileName": "ДЗЛ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Дифф. Защита",
					"nameFromTargetFile": null,
					"rawValue": "112\n(ВКЛ, ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Состояние дифференциальной защиты",
					"nameFromTargetFile": null,
					"rawValue": "1201 \n(ВКЛ, ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "I-Дифф>:Уставка по току, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1210",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение при броске тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "2301\n(ВКЛ, ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2301",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Содержание 2-й гарм - опред брос.ток.нам,  %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2302",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока, вторичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "206",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Релематика",
			"name": "ДФЗ",
			"fileName": "ДФЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод в работу измерительных органов аварийной составляющей тока",
					"nameFromTargetFile": null,
					"rawValue": "NтягНагр\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО тока прямой последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО разности фазных токов, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "iffb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "i2b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО аварийной составляющей тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "di2b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО суммы токов обратной и нулевой последовательностей, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "i20b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО тока прямой последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "i1t",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО разности фазных токов, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ifft",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "i2t",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО аварийной составляющей тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "di2t",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО суммы токов обратной и нулевой последовательностей, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "i20t",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сопротивление срабатывания, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление замыкания, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Расширение характеристики в третий квадрант, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmxadd",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmfy",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от внешних замыканий, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmfy4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол характеристики направленности в четвертый квадрант, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmfyr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол характеристики направленности отрицательных переходных сопротивлений, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmfyn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Диаметр характеристики реле сопротивления, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zd",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zfy",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Расширение характеристики в третий квадрант, % от Zкруг",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zadd",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выбор характеристики реле сопротивления",
					"nameFromTargetFile": null,
					"rawValue": "NвыборРС\n(0 – полигональная, \n1 – круговая)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nrs",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент манипуляции тока прямой последовательности, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент манипуляции тока обратной последовательности, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент манипуляции тока нулевой последовательности, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности первой гармоники, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I0T",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сопротивление срабатывания, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление замыкания, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTR",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Расширение характеристики в третий квадрант, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTXADD",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от внешних замыканий, градуc",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFY4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от нагрузочного режима, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFYR",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол направленности во 2-й квадрант, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFYN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод в работу модуля определения КЗ в линии",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N17",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль направления мощности нулевой последовательности для блокировки при КЗ на ответвлении",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N16",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Длительность действия отключающих ИО при формировании сигнала «КЗ в ЛЭП», мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TdlitKZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальный ток работы прямонаправленного РНМНП, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tzn_sIdirFw",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности РНМНП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tzn_sKI0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активная составляющая сопротивления искусственного смещения точки подключения ТН (РНМНП), Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tzn_sRofs",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивная составляющая сопротивления искусственного смещения точки подключения ТН (РНМНП), Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tzn_sXofs",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО второй гармоники тока НП, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tzn_sIh2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол срабатывания первой ступени ОСФ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "dtc1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Экра",
			"name": "ДФЗ",
			"fileName": "ДФЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ИО М0, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrIOMo",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО по току обратной последовательности, блокирующего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPO2bl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО по току обратной последовательно отключающего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPOI2ot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО по разности фазных токов, блокирующего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPOIbl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО по разности фазных токов, отключающего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPOIot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО DIБЛ по скорости изменения тока обратной последовательности, блокирующего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPODI2bl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО DIОТ по скорости изменения тока обратной последовательности, отключающего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPODI2ot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка ОМ по коэффициенту k комбинированного фильтра токов, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kfiltra",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка ОСФ по углу блокирования действия защиты на отключение, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ygolblokirovki",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по реактивной составляющей сопротивления ИО ZОТВ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XZotv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по активной составляющей сопротивления ИО ZОТВ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RZotv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона характеристики ИО ZОТВ (Ф1), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaklonZotv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона нижней правой части характеристики ИО ZОТВ (ф2), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaklonIVkv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона нижней левой части характеристики ИО ZОТВ (ф3), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaklonIIkv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по реактивной составляющей сопротивления ИО ZОТ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xzotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по активной составляющей сопротивления ИО ZОТ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RZotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вычисленный угол наклона характеристики срабатывания ИО ZОТ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ФR",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB1 Работа с ответвлениями на ВЛ",
					"nameFromTargetFile": null,
					"rawValue": "Ответвления\n(предусмотрена / \nне предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Otvetvlenia",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB3_ДФЗ Работа в сети с тяговой нагрузкой",
					"nameFromTargetFile": null,
					"rawValue": "Работа с тягой \n(предусмотрена / \nне предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rabotastagoi",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB6_ДФЗ ПО DI",
					"nameFromTargetFile": null,
					"rawValue": "ПО DI\n(выведен, в работе)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PODI",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичная величина датчика аналогового входа Ia В1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PervanalvhlaB1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичная величина датчика аналогового входа Ia В1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VtoranalvhlaB1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичная величина датчика аналогового входа Ia В2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PervanalvhlaB2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичная величина датчика аналогового входа Ia В2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VtoranalvhlaB2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичная величина датчика аналогового входа Ua, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PervanalvhUa",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичная величина датчика аналогового входа Ua, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VtoranalvhUa",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выноса ТН на линию для ИО Мо",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KvynosTN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление нулевой последовательности линии, Ом/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "R0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Длина линии, км",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "L",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Релематика ТОР 300 КСЗ",
			"name": "МТЗ",
			"fileName": "МТЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Работа МТЗ при пуске защит терминала",
					"nameFromTargetFile": null,
					"rawValue": "Nреж\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nreg",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 1 ступени МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания фазного контура 1 ступени МТЗ, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1ступени МТЗ, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 2 ступени МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания фазного контура 2 ступени МТЗ, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iyst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени МТЗ, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uvtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Экра ШЭ2607 024",
			"name": "МТЗ",
			"fileName": "МТЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО I ст. МТЗ, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT14 Задержка на срабатывание I ст. МТЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB87 Вторая ступень МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "II ст",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО II ст. МТЗ, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iyst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT15 Задержка на срабатывание II ст. МТЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB90 Режим пуска по напряжению",
					"nameFromTargetFile": null,
					"rawValue": "Режим пуска по U \n(по U мин,\nпо Uмин или U2)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NpuskU",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ПО максимального напряжения по U2 МТЗ, В (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2yst",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания реле минимального напряжения МТЗ, В (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uminyst",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный переменный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение переменного тока Uном, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktt",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "ABB_REL561_2.3",
			"name": "МТЗ",
			"fileName": "МТЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Отключено, 1 - Включено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation_PTOC",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания, % I4b",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN_gt_I4b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени функции МТЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "_tP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Отключено, 1 - Включено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation_PTOC3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активизация ступени с низкой уставкой",
					"nameFromTargetFile": null,
					"rawValue": "Operation Low\n(0 - Отключено, 1- Не направленная,\n2 - Прямая,\n3 - Обратная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation_Low",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальный ток срабатывания для работы функции с зависимой выдержкой времени, % I1b",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I_gt_Low",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для ступени с низкой уставкой, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активизация  ступени с высокой уставкой",
					"nameFromTargetFile": null,
					"rawValue": "Operation High\n(0 - Отключено, 1- Не направленная,\n2 - Прямая,\n3 - Обратная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation High",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ступени с высокой уставкой, % I1b",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I_gt_High",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени с высокой уставкой, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Нижний угол прямонаправленной харак- теристики, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgDir",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Верхний угол прямонаправленной харак- теристики, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgNegRes",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовое напряжение входа U1, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U1b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток входа I1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток входа I4, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I4b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Siemens 7SA522х",
			"name": "МТЗ",
			"fileName": "МТЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Режим работы",
					"nameFromTargetFile": null,
					"rawValue": "2601\n(ВКЛ:при потерТН;\n/ ВКЛ: всегда акт;\nВЫКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2601",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени Iph>>, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2610",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени Iph>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2611",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод ускорения ступени I >> через дискретный\nвход",
					"nameFromTargetFile": null,
					"rawValue": "2614\n(НЕТ, ДА)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2614",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод ускорения ступени I >> при включении\nна повреждение",
					"nameFromTargetFile": null,
					"rawValue": "2615\n(НЕТ, ДА)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2615",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени Iph>, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2620",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени Iph>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2621",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод ускорения ступени I > через дискретный вход",
					"nameFromTargetFile": null,
					"rawValue": "2624\n(НЕТ, ДА)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2624",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод ускоренной ступени I> при включении на повреждение",
					"nameFromTargetFile": null,
					"rawValue": "2625\n(НЕТ, ДА)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2625",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение трансформатора\nнапряжения, первичное, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "203",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение трансформатора\nнапряжения, вторичное (L-L), В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "204",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока,\nпервичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока, вторичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "206",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Релематика (ТОР 300 КСЗ)",
			"name": "МФТО",
			"fileName": "МФТО_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Работа защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ТО",
					"nameFromTargetFile": null,
					"rawValue": "Nреж\n(0 – при вкл",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nreg",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ТО, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabF",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС фазной ТО, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrabF",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Экра (ШЭ2607  011_200)",
			"name": "МФТО",
			"fileName": "МФТО_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ускорение действия токовой отсечки при вкл. Выключателя",
					"nameFromTargetFile": null,
					"rawValue": "Уск",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "YskTOprivklV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО токовой отсечки, А втор",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPOTO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания токовой отсечки при вкл. выключателя, А втор",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrTOvklV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка на срабатывание токовой отсечки, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tsrTO",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "ABB (REL561)",
			"name": "МФТО",
			"fileName": "МФТО_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация \nфункции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Отключена, 1- Включена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания при междуфазных замыканиях, % I1b",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IP>>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток входа I1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации измерительного трансформатора тока, вход I1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1Scale",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Siemens (7SA522х)",
			"name": "МФТО",
			"fileName": "МФТО_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ф-я Мгн.откл.при включ. на КЗ является",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2401",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току I>>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2404",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Релематика",
			"name": "НВЧЗ",
			"fileName": "НВЧЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Сопротивление срабатывания, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Z",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление срабатывания, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "R",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от внешних КЗ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FY4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа ИО приращения тока при работе в сети с тяговой нагрузкой",
					"nameFromTargetFile": null,
					"rawValue": "NтягНагр (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NtyagNagr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Посыл блокирующего ВЧ-сигнала при выводе защиты",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NpuskPPvyv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС фиксации посыла блокирующего ВЧ-сигнала, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TzaderPusk",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Продление посыла блокирующего ВЧ-сигнала при определении режима реверса мощности, мс",
					"nameFromTargetFile": null,
					"rawValue": "TпродПуск (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TprodPusk",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока прямой  последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО приращения тока прямой последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "dI1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО приращения тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "dI2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока обратной последовательности с торможением от модуля первой гармоники тока прямой последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2torm",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения ИО тока обратной последовательности с торможением, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktorm",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Продление сигнала пуска блокировки при качаниях, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TprodBK",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока прямой последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1T",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2T",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО приращения тока прямой последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DI1T",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО приращения тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DI2T",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока обратной последовательности с торможением от модуля первой гармоники тока прямой последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2TORMT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения ИО тока обратной последовательности с торможением, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KTORMT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ИО компенсированного напряжения обратной последовательности, % от Uном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2T",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания прямонаправленного РНМОП, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdirFw",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности РНМОП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RNM2_sKI2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активная составляющая сопротивления искусственного смещения точки подключения ТН (РНМОП), Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RNM2_sRofs",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивная составляющая сопротивления искусственного смещения точки подключения ТН (РНМОП), Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RNM2_sXofs",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа ИО тока обратной последовательности с торможением от первой гармоники тока прямой последовательности",
					"nameFromTargetFile": null,
					"rawValue": "NвводIторм (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NvvodItorm",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сопротивление срабатывания, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Zotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление срабатывания, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Fyotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от внешних КЗ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FY4otkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол между положительной полуосью R и лучом характеристики в четвертом квадранте, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgDirotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол между положительной полуосью R и лучом характеристики во втором квадранте",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgNegotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности первой гармоники, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I0T",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сопротивление срабатывания, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление срабатывания, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTR",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Расширение характеристики в третий квадрант, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTXADD",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от внешних КЗ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFY4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол между положительной полуосью R и лучом характеристики в четвертом квадранте, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFYR",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол между положительной полуосью R и лучом характеристики во втором квадранте, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZMTFYN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа модуля определения КЗ в ЛЭП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N17",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль направления мощности нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N16",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Длительность действия отключающих ИО при формировании сигнала «КЗ в ЛЭП», мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Dlit_KZvLEP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности\nвторой гармоники, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0f2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания прямонаправленного РНМНП,  % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdirFw0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности РНМНП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RNM0_sKI0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активная составляющая сопротивления искусственного смещения точки подключения ТН (РНМНП), Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RNM0_sRofs",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивная составляющая сопротивления искусственного смещения точки подключения ТН (РНМНП), Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RNM0_sXofs",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока ШОН, % от IШОН, ном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ISHON",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль напряжения при включении выключателя",
					"nameFromTargetFile": null,
					"rawValue": "NконтрU (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N9",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа ускорения защиты при включении выключателя",
					"nameFromTargetFile": null,
					"rawValue": "Nввод(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Длительность ввода ускорения при включении выключателя, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT12",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС ускорения при включении выключателя, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT20",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС защиты, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток ШОН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IshonN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Usec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Экра",
			"name": "НВЧЗ",
			"fileName": "НВЧЗ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО I2бл, о.е. Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrRTI2bl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ПО U2бл, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UsrRNU2bl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО I2от, о.е. Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrRTI2ot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ПО U2от, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UsrRNU2ot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО Iт2от, о.е. Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrRTI2tot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО Iт2пуск, о.е. Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrRTI2pysk",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtRTIt2ot,It2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО 3Io, о.е. Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrRT3I0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по приращению I2 блокирующего ПО, о.е. Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DI2bl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по приращению I2 отключающего ПО, о.е. Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DI2otkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по приращению I1 блокирующего ПО, о.е. Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DI1bl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по приращению I1 отключающего ПО, о.е. Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DI1otkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по реактивной составляющей сопротивления ИО Zот, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xzotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по активной составляющей сопротивления ИО Zот, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rzotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона характеристики (от оси Х) ИО Zот, град",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaklonZotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона нижней правой части характеристики ИО Zот, град.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NakLIkvZotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона нижней левой части характеристики ИО Zот, град.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NakLIIkvZotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по реактивной составляющей сопротивления ИО Zбл, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XZbl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по активной составляющей сопро-тивления ИО Zбл, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RZbl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона характеристики ИО Zбл, град.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaklonZbl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по реактивной составляющей сопротивления ИО Zдоп,  Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xzdop",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по активной составляющей сопротивления ИО Zдоп, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rzdop",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона характеристики ИО Zдоп, град.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaklonZdop",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ХВ1 Работа с ответвлениями на ВЛ",
					"nameFromTargetFile": null,
					"rawValue": "Работа с отв",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RabotasotvVL",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ХВ12 Работа на ВЛ с тяговой нагрузкой",
					"nameFromTargetFile": null,
					"rawValue": "Работа с тягой (0 - предусмотрена/ 1 - не предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rabotastaygoy",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ХВ2 Действие I2от с торможением",
					"nameFromTargetFile": null,
					"rawValue": "Действие Iт2от (0 - предусмотрено/ 1 - не предусмотрено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DeystvieIt2ot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ХВ11 Пуск ВЧ при выводе защиты",
					"nameFromTargetFile": null,
					"rawValue": "Пуск ВЧ при Выводе Защиты (0 -предусмотрен/ 1 - не предусмотрен )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PyskVChpriVyvodeZashity",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ХВ4 Ускорение при включении выключателя",
					"nameFromTargetFile": null,
					"rawValue": "Уск",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uskorenieprivkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ХВ5 Контроль ускорения при включении\nвыключателя от напряжения на линии",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UskorenieU",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ПО минимального напряжения от ШОН, В (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UsrRNminShON",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT8 Время ввода ускорения при включении выключателя, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tvvprivklB",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный переменный ток Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение переменного тока Uном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ линейного выключателя",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KttLV",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Релематика ТОР 300 КСЗ",
			"name": "ТНЗНП",
			"fileName": "ТНЗНП_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Работа 1 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 1 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении; 1 – работа во всех режимах, кроме обратного направления; 2 – работа без контроля направленности; 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 1 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 2 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 2 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении; 1 – работа во всех режимах, кроме обратного направления; 2 – работа без контроля направленности; 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 2 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 3 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 3 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении; 1 – работа во всех режимах, кроме обратного направления; 2 – работа без контроля направленности; 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 3 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 3 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 4 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 4 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении; 1 – работа во всех режимах, кроме обратного направления; 2 – работа без контроля направленности; 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 4 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 4 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 5 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 5 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении; 1 – работа во всех режимах, кроме обратного направления; 2 – работа без контроля направленности; 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 5 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 5 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 6 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 6 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении; 1 – работа во всех режимах, кроме обратного направления; 2 – работа без контроля направленности; 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 6 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 6 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания прямонаправленного РНМНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdirRNMNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания обратнонаправленного РНМНП, % IпрямРНМНП",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrevRNMNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности РНМНП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FmchRNMNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активная составляющая сопротивления искусственного смещения точки подключения ТН (РНМНП), Ом (втор.)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RsmeshRNMNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивная составляющая сопротивления искусственного смещения точки подключения ТН (РНМНП), Ом (втор.)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XsmeshRNMNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное утроенное напряжение нулевой последовательности для работы РНМНП, % Uном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3U0min",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания прямонаправленного РНМОП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdirRNMOP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания обратнонаправленного РНМОП, % IпрямРНМОП",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrevRNMOP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности РНМОП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FmchRNMOP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активная составляющая сопротивления искусственного смещения точки подключения ТН (РНМОП), Ом (втор.)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RsmeshRNMOP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивная составляющая сопротивления искусственного смещения точки подключения ТН (РНМОП), Ом (втор.)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XsmeshRNMOP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное напряжение обратной последовательности для работы РНМОП, %Uф.ном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2min",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим автоматического ускорения ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "NускСт \n(0 – вывод; 1 – ненаправленный пуск ступени  1 ТЗНП; 2 – ненаправленный пуск ступени  2 ТЗНП; 3 – ненаправленный пуск ступени  3 ТЗНП; 4 – ненаправленный пуск ступени  4 ТЗНП; 5 – ненаправленный пуск ступени  5 ТЗНП; 6 – ненаправленный пуск ступени  6 ТЗНП; 7 – ненаправленный пуск ступени  7 ТЗНП; 8 – ненаправленный пуск ступени  8 ТЗНП; 9 – направленный пуск ступени  1 ТНЗНП; 10 – направленный пуск ступени  2 ТНЗНП; 11 – направленный пуск ступени  3 ТНЗНП; 12 – направленный пуск ступени  4 ТНЗНП; 13 – направленный пуск ступени  5 ТНЗНП; 14 – направленный пуск ступени  6 ТНЗНП; 15 – направленный пуск ступени  7 ТНЗНП; 16 – направленный пуск ступени  8 ТНЗНП)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nay",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС логики автоматического ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrabAY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим оперативного ускорения ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "NускСт \n(0 – вывод; 1 – ненаправленный пуск ступени  1 ТЗНП; 2 – ненаправленный пуск ступени  2 ТЗНП; 3 – ненаправленный пуск ступени  3 ТЗНП; 4 – ненаправленный пуск ступени  4 ТЗНП; 5 – ненаправленный пуск ступени  5 ТЗНП; 6 – ненаправленный пуск ступени  6 ТЗНП; 7 – ненаправленный пуск ступени  7 ТЗНП; 8 – ненаправленный пуск ступени  8 ТЗНП; 9 – направленный пуск ступени  1 ТНЗНП; 10 – направленный пуск ступени  2 ТНЗНП; 11 – направленный пуск ступени  3 ТНЗНП; 12 – направленный пуск ступени  4 ТНЗНП; 13 – направленный пуск ступени  5 ТНЗНП; 14 – направленный пуск ступени  6 ТНЗНП; 15 – направленный пуск ступени  7 ТНЗНП; 16 – направленный пуск ступени  8 ТНЗНП)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Noy",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС логики оперативного ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrabOY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Экра ШЭ2607",
			"name": "ТНЗНП",
			"fileName": "ТНЗНП_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО I ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT50 Задержка на срабатывание I ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB32 Контроль направленности I ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО II ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT51 Задержка на срабатывание II ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB33 Контроль направленности II ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО III ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT52 Задержка на срабатывание III ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB34 Контроль направленности III ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО IV ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT53 Задержка на срабатывание IV ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB35 Контроль направленности IV ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО V ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT59 Задержка на срабатывание V ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB39 Контроль направленности V ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB91 Направленность V ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Направлен",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Dir5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО VI ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT60 Задержка на срабатывание VI ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB40 Контроль направленности VI ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО Мо, блокирующий, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrRNMNPblk",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО Мо, разрешающий, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrRNMNPrazr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ИО Мо, разрешающий, В (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UsrRNMNPrazr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выноса ТН на линию для ИО Мо, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KvynosTN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление нулевой последовательности линии, Ом/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "R0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление нулевой последовательности линии, Ом/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "X0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Длина линии",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "L",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB36 Ускоряемая ступень ТНЗНП при включении выключателя",
					"nameFromTargetFile": null,
					"rawValue": "Ускор",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NyskVkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT49 Задержка на срабатыв. ускор. при вкл.выключателя от ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TyskVkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB37 Оперативно ускоряемая ступень ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Опер",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Noy",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT58 Задержка на сраб. II, III, IV ст. ТНЗНП при опер.ускорении, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrabOY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktt",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktn",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "АВВ_REL670",
			"name": "ТНЗНП",
			"fileName": "ТНЗНП_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Базисный ток, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ibase",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисное напряжение, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ubase",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристический угол реле (RCA), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "AngleRCA",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное напряжение поляризации, %UBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UPolMin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уровень минимального тока нулевой последовательности для определения направленности, %Ibase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN>Dir",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности ступени 1",
					"nameFromTargetFile": null,
					"rawValue": "DirMode1\n(Выкл / Ненаправленная / Прямое / Обратное)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirMode1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Тип времятоковой характеристики для ступени 1",
					"nameFromTargetFile": null,
					"rawValue": "Characterist1 \n(ANSI ЧрезвИнв / ANSI СильнИнв / ANSI НормИнв / ANSI / Независимая / ДлитЧрезвИнв / ДлитСильнИнв /  ДлитИнв / МЭК НормИнв / МЭК СильнИнв / МЭК Инверсная / МЭК ЧрезвИнв / МЭК КраткИнв / МЭК ДлитИнв / МЭК Независимая / Резерв / Программируемая (не рассматриваем!)/ RI-типа / RD-типа)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Characterist1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току нулевой последовательности ступени 1, %IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN1>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени ступени 1, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени зависимой от тока характеристики времени ступени 1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности ступени 2",
					"nameFromTargetFile": null,
					"rawValue": "DirMode2\n(Выкл / Ненаправленная / Прямое / Обратное)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirMode2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Тип времятоковой характеристики для ступени 2",
					"nameFromTargetFile": null,
					"rawValue": "Characterist2 \n(ANSI ЧрезвИнв / ANSI СильнИнв / ANSI НормИнв / ANSI / Независимая / ДлитЧрезвИнв / ДлитСильнИнв /  ДлитИнв / МЭК НормИнв / МЭК СильнИнв / МЭК Инверсная / МЭК ЧрезвИнв / МЭК КраткИнв / МЭК ДлитИнв / МЭК Независимая / Резерв / Программируемая / RI-типа / RD-типа)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Characterist2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току нулевой последовательности ступени 2, %IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN2>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени ступени 2, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени зависимой от тока характеристики времени ступени 2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности ступени 3",
					"nameFromTargetFile": null,
					"rawValue": "DirMode3\n(Выкл / Ненаправленная / Прямое / Обратное)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirMode3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Тип времятоковой характеристики для ступени 3",
					"nameFromTargetFile": null,
					"rawValue": "Characterist3 \n(ANSI ЧрезвИнв / ANSI СильнИнв / ANSI НормИнв / ANSI / Независимая / ДлитЧрезвИнв / ДлитСильнИнв /  ДлитИнв / МЭК НормИнв / МЭК СильнИнв / МЭК Инверсная / МЭК ЧрезвИнв / МЭК КраткИнв / МЭК ДлитИнв / МЭК Независимая / Резерв / Программируемая / RI-типа / RD-типа)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Characterist3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току нулевой последовательности ступени 3, %IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN3>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени ступени 3, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени зависимой от тока характеристики времени ступени 3",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности ступени 4",
					"nameFromTargetFile": null,
					"rawValue": "DirMode4\n(Выкл / Ненаправленная / Прямое / Обратное)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirMode4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Тип времятоковой характеристики для ступени 4",
					"nameFromTargetFile": null,
					"rawValue": "Characterist4 \n(ANSI ЧрезвИнв / ANSI СильнИнв / ANSI НормИнв / ANSI / Независимая / ДлитЧрезвИнв / ДлитСильнИнв /  ДлитИнв / МЭК НормИнв / МЭК СильнИнв / МЭК Инверсная / МЭК ЧрезвИнв / МЭК КраткИнв / МЭК ДлитИнв / МЭК Независимая / Резерв / Программируемая / RI-типа / RD-типа)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Characterist4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току нулевой последовательности ступени 4, %IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN4>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени ступени 4, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени зависимой от тока характеристики времени ступени 4",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim8",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim9",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim12",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec8",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec9",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec12",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Siemens 7SA522х",
			"name": "ТНЗНП",
			"fileName": "ТНЗНП_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Режим работы ступени 3I0>>>",
					"nameFromTargetFile": null,
					"rawValue": "3110\nforward (вперед)\nreverse (назад)\nnon-directional (без направления)\nInactive (Неактив)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3110",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току 3I0>>>, А  (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3111",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени Т 3I0>>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени 3I0>>",
					"nameFromTargetFile": null,
					"rawValue": "3120\nforward (вперед)\nreverse (назад)\nnon-directional (без направления)\nInactive (Неактив)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3120",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току 3I0>>, А  (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3121",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени Т 3I0>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени 3I0>",
					"nameFromTargetFile": null,
					"rawValue": "3130\nforward (вперед)\nreverse (назад)\nnon-directional (без направления)\nInactive (Неактив)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3130",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току 3I0>, А  (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3131",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени Т 3I0>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3132",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени 3I0р",
					"nameFromTargetFile": null,
					"rawValue": "3140\nforward (вперед)\nreverse (назад)\nnon-directional (без направления)\nInactive (Неактив)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3140",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току 3I0р, А  (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3141",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время действия МТЗ для характеристик по МЭК 3I0р, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3143",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время действия МТЗ для характеристик по АNSI 3I0р, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3144",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время действия МТЗ для логарифмических характеристик 3I0р, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3145",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Макс.время действия МТЗ для инв.хар.3I0р, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3146",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Дополнительная выдержка времени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3147",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "МЭК характеристика",
					"nameFromTargetFile": null,
					"rawValue": "3151\nНормал",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3151",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ANSI характеристика",
					"nameFromTargetFile": null,
					"rawValue": "3152\nИнверсная\nСокращ-инверсн\nДлит-инверсн\nУмерен-инверсн\nСильно-инверсн\nПредел-инверс\nРавн-инверсн",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3152",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Логарифмическая характеристика",
					"nameFromTargetFile": null,
					"rawValue": "3153\nЛогариф инверс",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3153",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Земл Защита",
					"nameFromTargetFile": null,
					"rawValue": "131\nВыведено\nМЭК ВрХМТока\nАNSI ВрХМТока\nЛогар ВрХМТока\nНезавис МТЗ\nU0 инверс\nSн инверс",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "131",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметры для определения направления (поляризация)",
					"nameFromTargetFile": null,
					"rawValue": "3160\nU0 + IУ или U2\nU0 + IУ\nс IУ только\nс U2 и I2\nМощностьНулПосл",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3160",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ALPHA, нижний предельный угол для направления \"вперед\", градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3162A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "BETA, верхний предельный угол для направления \"вперед\", градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3163A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное нулевое напряжение 3U0min, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3164",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное напряжение обратной последовательности 3U2min, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3166",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальный ток обратной последовательности 3I2min, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3167",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока, первичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Релематика(ТОР 300 КСЗ 510)",
			"name": "УРОВ",
			"fileName": "УРОВ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазный ток УРОВ, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль РПВ при действии УРОВ на смежный выключатель",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NcontrRPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Замедление отключения смежных выключателей, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Экра(ШЭ2607 0110211_200)",
			"name": "УРОВ",
			"fileName": "УРОВ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Подтверждение пуска УРОВ от сигнала РПВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XB5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка на срабатывание УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT16",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации тока линии",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktt",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "ABB (REL 670)",
			"name": "УРОВ",
			"fileName": "УРОВ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ibase",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы логики резервного отключения \n(по току(Current), по блок-контактам(Cont), по току и блок-контактам(Current&Cont))",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FunctionMode",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания для режима Current в % от Ibase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IP>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени на резервное отключение, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания для режима Current&Cont в % от IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I>BlkCont",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Линия",
			"vendor": "Siemens (7SA522x)",
			"name": "УРОВ",
			"fileName": "УРОВ_110_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ф-я УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3901",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог срабатывания I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3902",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени Т2, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3906",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль выключателя по блок/конт",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3909",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока,\nпервичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока, вторичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "206",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Релематика",
			"name": "ДЗ",
			"fileName": "ДЗ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Отключение от ступени ДЗ",
					"nameFromTargetFile": null,
					"rawValue": "Nоткл \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N89",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Действительная часть коэффициента компенсации током нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sK0re",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Мнимая часть коэффициента компенсации током нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sK0im",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сопротивление срабатывания ступени ДЗ по каналу «фаза-земля», Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sZpe",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление срабатывания ступени ДЗ по каналу «фаза-земля», Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sRpe",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности по каналу «фаза-земля», градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sFYpe",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от внешних замыканий по каналу «фаза-земля», градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sFY4pe",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сопротивление срабатывания ступени ДЗ по \nканалу «фаза-фаза», Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sZpp",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное  сопротивление  срабатывания \nступени ДЗ по каналу «фаза-фаза», Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sRpp",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол  максимальной  чувствительности  по \nконтуру «фаза-фаза», градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sFYpp",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол  отстройки  от  внешних  замыканий  по \nконтуру «фаза-фаза», градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sFY4pp",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности ступени ДЗ",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр\n(0 – ненапр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N77",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени ДЗ в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": "NрежОАПВ\n(0 – постоянный ввод, 1 – ввод в цикле ОАПВ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NregOAPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС  отключения  ступени  ДЗ  от \nмеждуфазных КЗ, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС отключения по каналам «фаза-земля» ступени ДЗ, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим ускорения ДЗ при включении на повреждение",
					"nameFromTargetFile": null,
					"rawValue": "NускСтАУ\n(0 – вывод, 1, 2, 3, 4, 5 – ненаправленный пуск ступеней 1, 2, 3, 4, 5 ДЗ; 6, 7, 8, 9, 10 – направленный пуск ступеней 1, 2, 3, 4, 5 ДЗ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N45",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим оперативного ускорения ДЗ",
					"nameFromTargetFile": null,
					"rawValue": "NускСтОУ\n(0 – вывод, 1, 2, 3, 4, 5 – ненаправленный пуск ступеней 1, 2, 3, 4, 5 ДЗ; 6, 7, 8, 9, 10 – направленный пуск ступеней 1, 2, 3, 4, 5 ДЗ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N93",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС в режиме автоматического ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT35",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС в режиме оперативного ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT36",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Максимальное переходное сопротивление при междуфазных замыканиях, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "gfc_sRFPP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Максимальное переходное сопротивление при замыканиях по каналу «фаза-земля», Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "gfc_sRFPE",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное сопротивление нагрузки по оси R, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "gfc_sRLd",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от нагрузочного режима, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "gfc_sArgLd",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол характеристики направленности в четвертый квадрант, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sArgDir",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол характеристики направленности отрицательных  переходных сопротивлений, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm_sArgNeg",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Usec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Экра",
			"name": "ДЗ",
			"fileName": "ДЗ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Коррект.Множитель KKR коэф.Компенсации тока 3I0 по R, о.е",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KKR3I0R",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коррект.Множитель KKR коэф.Компенсации тока 3I0 по X, о.е",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KKR3I0X",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики I ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xyst1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики I ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ryst1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики I ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон верхней части характеристики I ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon1st1kv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT20 Задержка на срабатывание I ст. ДЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst1stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики I ст. при КЗ на землю, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xzyst1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики I ст. при КЗ на землю, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rzyst1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона характеристики I ст. при КЗ на землю, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklonzem1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики II ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xyst2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики II ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ryst2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики II ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT22 Задержка на срабатывание II ст. ДЗ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst2stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики III ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xyst3st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики III ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ryst3st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики III ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon3st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT23 Задержка на срабатывание III ст. ДЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst3stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики IV ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xyst4st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики IV ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ryst4st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики IV ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon4st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность IV ст.",
					"nameFromTargetFile": null,
					"rawValue": "Направлен",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NapravlenIVst",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT24 Задержка на срабатывание IV ст. ДЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst4stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси Х характеристики V ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Xyst5st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R характеристики V ст., Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ryst5st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон характеристики V ст., градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon5st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность V ст.",
					"nameFromTargetFile": null,
					"rawValue": "Направлен",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NapravlenVst",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT25 Задержка на срабатывание V ст. ДЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst5stDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон левой части характеристики, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon2kv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Наклон нижней правой части характеристики, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Naklon4kv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по оси R нагрузочного режима, Ом",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rnagr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол выреза нагрузочного режима, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ugolnagr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB11 Оперативно ускоряемая ступень ДЗ",
					"nameFromTargetFile": null,
					"rawValue": "Опер",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperUskorStDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT26 Задержка на сраб I,II или III ст.ДЗ при опер ускорении, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tsrOUDZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение переменного тока Uном, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "АВВ",
			"name": "ДЗ",
			"fileName": "ДЗ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация",
					"nameFromTargetFile": null,
					"rawValue": "Operation (0 - Отключена, 1 - Включена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Зона охвата по реактивному сопротивлению прямой последовательности, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "X1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление прямой последовательности для характеристического угла зоны, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "R1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Зона охвата по реактивному сопротивлению нулевой последовательности, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "X0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление нулевой последовательности для характеристического угла зоны, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "R0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Область активного сопротивления в месте КЗ, фаза-фаза,  Ом/контур",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RFPP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Область активного сопротивления в месте КЗ между фазой и землей",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RFPE",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени отключения, фаза-фаза, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tPP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени отключения, фаза-земля, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tPE",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности",
					"nameFromTargetFile": null,
					"rawValue": "OperationDir (0 - Отключена; 1 - Ненаправленная; 2 - Прямая; 3 - Обратная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperationDir1(2,3,4,5)",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол направляющей во втором квадранте для прямого напрявления, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgNegRes",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол направляющей в четвертом квадранте для прямого направления, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgDir",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Зона охвата активного сопротивления в прямом направлении в области режима нагрузки, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RLdFw",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Зона охвата активного сопротивления в обратном направлении в области режима нагрузки, Ом/фаза",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RLdRv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол сектора нагрузки, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgLd",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Siemens",
			"name": "ДЗ",
			"fileName": "ДЗ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Формат Z0/Z1",
					"nameFromTargetFile": null,
					"rawValue": "237\n(RЕ/RL, ХЕ/ХL; К0)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "237",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.комп.нул.посл. RЕ/RL для Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1116",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.комп.нул.посл.ХЕ/ХL для Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1117",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.комп.нул.посл.RЕ/RL для Z1В...Z5",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1118",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.комп.нул.посл.ХЕ/ХL для Z1В...Z5",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1119",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент компенс.нул.посл. К0 для Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1120",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол компенс.нул.посл для ступени Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1121",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.компенс.нул.посл. К0, ступеней >Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол компен.нул.посл, ступеней >Z1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1123",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона характеристики ДЗ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1211",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z1",
					"nameFromTargetFile": null,
					"rawValue": "1301\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1301",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R Ст1 с полигональной характеристикой для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1302",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Ст1), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1303",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z1), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1304",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т1-однофаз, Выдержка для однофаз КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1305",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени T1 ступени Z1 при многофазных КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1306",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол скоса многоугольника (1. квадрант), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1307",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z2",
					"nameFromTargetFile": null,
					"rawValue": "1311\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R Ст2 для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Ст2), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1313",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z2), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1314",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т2-однофаз, Выдержка для однофаз КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1315",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т2-многофаз, Выдержка для многофаз КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1316",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z3",
					"nameFromTargetFile": null,
					"rawValue": "1321\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1321",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R Ст3 для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1322",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Ст3), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1323",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z3), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1324",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т3 Выдержка, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1325",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z4",
					"nameFromTargetFile": null,
					"rawValue": "1331\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1331",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R Ст4 для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1332",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Ст4), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1333",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z4), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1334",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т4 Выдержка, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1335",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z5",
					"nameFromTargetFile": null,
					"rawValue": "1341\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1341",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R Ст5 для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1342",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Ст5), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1343",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z5), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1344",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка Т5, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1345",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z1B (промежуточная ступень)",
					"nameFromTargetFile": null,
					"rawValue": "1351\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1351",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление R(Z1B) для КЗ фаза-фаза, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1352",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление X(Z1B), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1353",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z1B), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1354",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т1В-однофаз, Выдержка для однофаз КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1355",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т1В-многофаз, Выдержка для многофаз КЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1356",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени Z6",
					"nameFromTargetFile": null,
					"rawValue": "1361",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1361",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "R(Z6), Сопротивление для ф-ф-КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1362",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "(Z6)+, РеакСопр. Направление Вперед, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1363",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "RЕ(Z6), Сопротивление для ф-з КЗ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1364",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка Т6, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1365",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Х(Z6)-, РеакСопр. Направление Назад, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1366",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Граница сектора нагрузки, (фаза-земля), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1241",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол сектора нагрузки, (фаза-земля), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1242",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Граница сектора нагрузки, (фаза-фаза), Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1243",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол сектора нагрузки, (фаза-фаза), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1244",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение трансформатора\nнапряжения, вторичное (L-L), В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "204",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Релематика ТОР 300 ДЗЛ 62Х",
			"name": "ДЗЛ",
			"fileName": "ДЗЛ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания дифференциальной токовой отсечки, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdiffCut",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный дифференциальный ток срабатывания, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ibase",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный тормозной ток, о.е",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Itorm",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktorm",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "ЭКРА 2710 591",
			"name": "ДЗЛ",
			"fileName": "ДЗЛ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ДЗЛ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrDZL",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения дифференциальной защиты К1, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtormK1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ДТО, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrDTO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB85 Дифференциальная токовая отсечка (ДТО)",
					"nameFromTargetFile": null,
					"rawValue": "ДТО \n(0 - не предусмотрена/ 1 -предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NDTO",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "ABB REL561",
			"name": "ДЗЛ",
			"fileName": "ДЗЛ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Режим работы функции DIFL",
					"nameFromTargetFile": null,
					"rawValue": "Operation (0 - Отключена, 1 - Включена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальный рабочий дифференциальный ток, % от I1b",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IMinOp",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение: наклон 1 характеристики, % от Ibias",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IDiffLvl1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток входа I1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1b",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Siemens 7SD5xx",
			"name": "ДЗЛ",
			"fileName": "ДЗЛ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Дифф. Защита",
					"nameFromTargetFile": null,
					"rawValue": "112 (ВКЛ, ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Состояние дифференциальной защиты",
					"nameFromTargetFile": null,
					"rawValue": "1201 \n(ВКЛ, ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "I-Дифф>:Уставка по току, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1210",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока, вторичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "206",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Релематика_ТОР 300_ДФЗ_65Х",
			"name": "ДФЗ",
			"fileName": "ДФЗ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Длина линии, км",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "L",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Удельное активное сопротивление прямой последовательности линии, Ом/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "R10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Удельное индуктивное сопротивление прямой последовательности линии, Ом/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "X10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Удельная емкостная проводимость прямой последовательности линии, мкСм/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "B10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Удельная емкостная проводимость нулевой последовательности линии, мкСм/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "B00",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод в работу ИО аварийной составляющей тока",
					"nameFromTargetFile": null,
					"rawValue": "NвводАвар\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО тока прямой последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "i2b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО аварийной составляющей тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "di2b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО суммы токов обратной и нулевой последовательностей, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "i20b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО разности фазных токов, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "iffb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пусковой ИО напряжения обратной последовательности, % от Uном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "u2b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО тока прямой последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "i1t",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "i2t",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО аварийной составляющей тока обратной последовательности, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "di2t",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО суммы токов обратной и нулевой последовательностей, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "i20t",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО разности фазных токов, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ifft",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отключающий ИО напряжения обратной последовательности, % от Uном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "u2t",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сопротивление срабатывания, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zm",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление замыкания, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Расширение характеристики в третий квадрант, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmxadd",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmfy",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол отстройки от внешних замыканий, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmfy4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол характеристики направленности в четвертый квадрант, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmfyr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол характеристики направленности отрицательных переходных сопротивлений, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "zmfyn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выбор характеристики реле сопротивления",
					"nameFromTargetFile": null,
					"rawValue": "NвыборРС\n(0 – полигональная, \n1 – круговая)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nrs",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент манипуляции тока прямой последовательности, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент манипуляции тока обратной последовательности, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент манипуляции тока нулевой последовательности, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод компенсации емкостных токов",
					"nameFromTargetFile": null,
					"rawValue": "NвводКЁТ",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nket",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол срабатывания первой ступени ОСФ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "dtc1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Usec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Экра_ШЭ2710_581",
			"name": "ДФЗ",
			"fileName": "ДФЗ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Длина линии, км",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "L",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Удельное активное сопротивление прямой последовательности линии, Ом/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "R10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Удельное реактивное сопротивление прямой последовательности линии, Ом/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "X10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Удельная емкостная проводимость прямой последовательности линии, См/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "B10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Удельная емкостная проводимость нулевой последовательности линии, См/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "B00",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО по току обратной последовательности, блокирующего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPOI2bl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО по току обратной последовательности отключающего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPOI2ot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО DIБЛ по скорости изменения тока обратной последовательности, блокирующего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPODI2bl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО DIОТ по скорости изменения тока обратной последовательности, отключающего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPODI2ot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО по разности фазных токов, блокирующего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPOIbl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО по разности фазных токов, отключающего, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPOIot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ПО по напряжению обратной последовательности, блокирующего, В (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UsrPOU2bl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ПО по напряжению обратной последовательности, отключающего, В (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UsrPOU2ot",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка ОМ по коэффициенту k комбинированного фильтра токов, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kfiltra",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка ОСФ по углу блокирования действия защиты на отключение, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ygolblokirovki",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по реактивной составляющей сопротивления ИО ZОТ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XZotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по активной составляющей сопротивления ИО ZОТ, Ом (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RZotkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона характеристики срабатывания ИО ZОТ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ФR",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB2_ПО (U2-kI2)",
					"nameFromTargetFile": null,
					"rawValue": "ПО по U2 (0 - выведен/ 1 - в работе)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "POU2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичная величина трансформатора тока выключателя В1, А (1-я группа ТТ)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PervanalvhlaB1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичная величина трансформатора тока выключателя В1, А (1-я группа ТТ)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VtoranalvhlaB1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичная величина трансформатора тока выключателя В2, А (2-я группа ТТ)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PervanalvhlaB2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичная величина трансформатора тока выключателя В2, А (2-я группа ТТ)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VtoranalvhlaB2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичная величина трансформатора тока реактора (линии), А (3-я группа ТТ)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PervanalvhlaB3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичная величина трансформатора тока реактора (линии), А (3-я группа ТТ)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VtoranalvhlaB3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичная величина трансформатора напряжения, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PervanalvhUa",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичная величина трансформатора напряжения, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VtoranalvhUa",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Токовая цепь В2",
					"nameFromTargetFile": null,
					"rawValue": "ТТ В2\n(0 - используется, 1 - не используется)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TTB2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Токовая цепь В3",
					"nameFromTargetFile": null,
					"rawValue": "ТТ В3\n(0 - используется, 1 - не используется)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TTB3",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Релематика ТОР 300 КСЗ",
			"name": "МТЗ",
			"fileName": "МТЗ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Работа МТЗ при пуске защит терминала",
					"nameFromTargetFile": null,
					"rawValue": "Nреж\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nreg",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 1 ступени МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания фазного контура 1 ступени МТЗ, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1ступени МТЗ, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 2 ступени МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания фазного контура 2 ступени МТЗ, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iyst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени МТЗ, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uvtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Экра ШЭ2710 521_200",
			"name": "МТЗ",
			"fileName": "МТЗ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT14 Задержка на срабатывание МТЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный переменный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение переменного тока Uном, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktt",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "ABB_REL561_2.3",
			"name": "МТЗ",
			"fileName": "МТЗ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Отключено, 1 - Включено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation_PTOC",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания, % I4b",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN_gt_I4b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени функции МТЗ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "_tP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Отключено, 1 - Включено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation_PTOC3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активизация ступени с низкой уставкой",
					"nameFromTargetFile": null,
					"rawValue": "Operation Low\n(0 - Отключено, 1 - Ненаправленная,\n2 - Прямая,\n3 - Обратная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation_Low",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальный ток срабатывания для работы функции с зависимой выдержкой времени, % I1b",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I_gt_Low",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для ступени с низкой уставкой, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активизация  ступени с высокой уставкой",
					"nameFromTargetFile": null,
					"rawValue": "Operation High\n(0 - Отключено, 1 - Ненаправленная,\n2 - Прямая,\n3 - Обратная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation High",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ступени с высокой уставкой, % I1b",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I_gt_High",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени с высокой уставкой, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Нижний угол прямонаправленной харак- теристики, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgDir",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Верхний угол прямонаправленной харак- теристики, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ArgNegRes",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовое напряжение входа U1, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U1b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток входа I1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток входа I4, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I4b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Siemens 7SA522х",
			"name": "МТЗ",
			"fileName": "МТЗ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Режим работы",
					"nameFromTargetFile": null,
					"rawValue": "2601\n(ON:with VT loss /ВКЛ:при потерТН,\nON:always activ/ВКЛ: всегда акт,\nOFF/ВЫКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2601",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени Iph>>, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2610",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени Iph>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2611",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод ускорения ступени I >> через дискретный\nвход",
					"nameFromTargetFile": null,
					"rawValue": "2614\n(NO/НЕТ, YES/ДА)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2614",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод ускорения ступени I >> при включении\nна повреждение",
					"nameFromTargetFile": null,
					"rawValue": "2615\n(NO/НЕТ, YES/ДА)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2615",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени Iph>, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2620",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени Iph>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2621",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод ускорения ступени I > через дискретный вход",
					"nameFromTargetFile": null,
					"rawValue": "2624\n(NO/НЕТ, YES/ДА)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2624",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод ускоренной ступени I> при включении на повреждение",
					"nameFromTargetFile": null,
					"rawValue": "2625\n(NO/НЕТ, YES/ДА)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2625",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение трансформатора\nнапряжения, первичное, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "203",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение трансформатора\nнапряжения, вторичное (L-L), В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "204",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока, первичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока, вторичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "206",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Релематика (ТОР 300 КСЗ)",
			"name": "МФТО",
			"fileName": "МФТО_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Работа ТО",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ТО",
					"nameFromTargetFile": null,
					"rawValue": "Nреж\n(0 – при вкл",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nreg",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ТО, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabF",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС фазной ТО, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrabF",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время ввода ускорения защит при включении, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrabAY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Экра (ШЭ2710  521_200)",
			"name": "МФТО",
			"fileName": "МФТО_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ускорение действия токовой отсечки при вкл. Выключателя",
					"nameFromTargetFile": null,
					"rawValue": "Уск",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "YskTOprivklV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО токовой отсечки, А втор",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPOTO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания токовой отсечки при вкл. выключателя, А втор",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrTOvklV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка на срабатывание токовой отсечки, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tsrTO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка для отстройки от помех, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT50",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "ABB (REL561)",
			"name": "МФТО",
			"fileName": "МФТО_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация \nфункции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(Off, On)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания при междуфазных замыканиях, % I1b",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IP>>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток входа I1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1b",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации измерительного трансформатора тока, вход I1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I1Scale",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Siemens (7SA522х)",
			"name": "МФТО",
			"fileName": "МФТО_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ф-я Мгн.откл.при включ. на КЗ является",
					"nameFromTargetFile": null,
					"rawValue": "2401\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2401",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току I>>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2404",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Релематика ТОР 300 КСЗ",
			"name": "ТНЗНП",
			"fileName": "ТНЗНП_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Работа 1 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 1 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении, 1 – работа во всех режимах, кроме обратного направления, 2 – работа без контроля направленности, 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 1 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NregOAPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 2 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 2 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении, 1 – работа во всех режимах, кроме обратного направления, 2 – работа без контроля направленности, 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 2 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NregOAPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 3 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 3 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении, 1 – работа во всех режимах, кроме обратного направления, 2 – работа без контроля направленности, 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 3 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 3 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NregOAPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 4 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 4 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении, 1 – работа во всех режимах, кроме обратного направления, 2 – работа без контроля направленности, 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 4 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 4 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NregOAPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 5 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 5 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении, 1 – работа во всех режимах, кроме обратного направления, 2 – работа без контроля направленности, 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 5 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 5 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NregOAPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа 6 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nввод \n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности 6 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр \n(0 – работа при замыкании в прямом направлении, 1 – работа во всех режимах, кроме обратного направления, 2 – работа без контроля направленности, 3 – работа при замыкании в обратном направлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Утроенный ток нулевой последовательности срабатывания 6 ступени ТНЗНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 6 ступени ТНЗНП, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NregOAPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания прямонаправленного РНМНП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdirRNMNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания обратнонаправленного РНМНП, % IпрямРНМНП",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrevRNMNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности РНМНП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FmchRNMNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активная составляющая сопротивления искусственного смещения точки подключения ТН (РНМНП), Ом (втор.)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RsmeshRNMNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивная составляющая сопротивления искусственного смещения точки подключения ТН (РНМНП), Ом (втор.)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XsmeshRNMNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное утроенное напряжение нулевой последовательности для работы РНМНП, % Uном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3U0min",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания прямонаправленного РНМОП, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdirRNMOP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания обратнонаправленного РНМОП, % IпрямРНМОП",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrevRNMOP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности РНМОП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FmchRNMOP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активная составляющая сопротивления искусственного смещения точки подключения ТН (РНМОП), Ом (втор.)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "RsmeshRNMOP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивная составляющая сопротивления искусственного смещения точки подключения ТН (РНМОП), Ом (втор.)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XsmeshRNMOP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное напряжение обратной последовательности для работы РНМОП, %Uф.ном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2min",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим автоматического ускорения ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "NускСт \n(0 – вывод, 1, 2, 3, 4, 5, 6, 7, 8 – ненаправленный пуск ступеней 1, 2, 3, 4, 5, 6, 7, 8 ТНЗНП; 9, 10, 11, 12, 13, 14, 15, 16 – направленный пуск ступеней 1, 2, 3, 4, 5, 6, 7, 8 ТНЗНП)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nay",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС логики автоматического ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrabAY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим оперативного ускорения ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "NускСт \n(0 – вывод, 1, 2, 3, 4, 5, 6, 7, 8 – ненаправленный пуск ступеней 1, 2, 3, 4, 5, 6, 7, 8 ТНЗНП; 9, 10, 11, 12, 13, 14, 15, 16 – направленный пуск ступеней 1, 2, 3, 4, 5, 6, 7, 8 ТНЗНП)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Noy",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС логики оперативного ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrabOY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Экра ШЭ2710 521_200 ",
			"name": "ТНЗНП",
			"fileName": "ТНЗНП_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО I ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT50 Задержка на срабатывание I ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB32 Контроль направленности I ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB16 Блокировка I ст.ТНЗНП в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": "Блок",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Blok1stTZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО II ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT51 Задержка на срабатывание II ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB33 Контроль направленности II ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB17 Блокировка II ст.ТНЗНП в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": "Блок",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Blok2stTZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО III ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT52 Задержка на срабатывание III ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB34 Контроль направленности III ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB18 Блокировка III ст.ТНЗНП в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": "Блок",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Blok3stTZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО IV ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT53 Задержка на срабатывание IV ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB35 Контроль направленности IV ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB45 Блокировка IV ст.ТНЗНП в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": "Блок",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Blok4stTZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО V ст. ТНЗНП, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT59 Задержка на срабатывание V ст. ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tyst5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB39 Контроль направленности V ст. ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndir5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB46 Блокировка V ст.ТНЗНП в цикле ОАПВ",
					"nameFromTargetFile": null,
					"rawValue": "Блок",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Blok5stTZ",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО Мо, блокирующий, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrRNMNPblk",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО Мо, разрешающий, А (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrRNMNPrazr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ИО Мо, разрешающий, В (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UsrRNMNPrazr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выноса ТН на линию для ИО Мо, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KvynosTN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активное сопротивление нулевой последовательности линии, Ом/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "R0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Реактивное сопротивление нулевой последовательности линии, Ом/км (перв)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "X0",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Длина линии",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "L",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT49 Задержка на срабатыв. ускор. при вкл.выключателя от ТНЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TyskVkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "XB37 Оперативно ускоряемая ступень ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "Опер",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Noy",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT58 Задержка на сраб. II, III, IV ст. ТНЗНП при опер.ускорении, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrabOY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktt",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktn",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "АВВ_REL670",
			"name": "ТНЗНП",
			"fileName": "ТНЗНП_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Базисный ток, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ibase",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисное напряжение, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ubase",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристический угол реле (RCA), градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "AngleRCA",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное напряжение поляризации, %UBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UPolMin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уровень минимального тока нулевой последовательности для определения направленности, %Ibase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN>Dir",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности ступени 1",
					"nameFromTargetFile": null,
					"rawValue": "DirMode1\n(Выкл / Ненаправленная / Прямое / Обратное)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirMode1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Тип времятоковой характеристики для ступени 1",
					"nameFromTargetFile": null,
					"rawValue": "Characterist1 \n(ANSI ЧрезвИнв / ANSI СильнИнв / ANSI НормИнв / ANSI / Независимая / ДлитЧрезвИнв / ДлитСильнИнв /  ДлитИнв / МЭК НормИнв / МЭК СильнИнв / МЭК Инверсная / МЭК ЧрезвИнв / МЭК КраткИнв / МЭК ДлитИнв / МЭК Независимая / Резерв / Программируемая (не рассматриваем!)/ RI-типа / RD-типа)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Characterist1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току нулевой последовательности ступени 1, %IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN1>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени ступени 1, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени зависимой от тока характеристики времени ступени 1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности ступени 2",
					"nameFromTargetFile": null,
					"rawValue": "DirMode2\n(Выкл / Ненаправленная / Прямое / Обратное)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirMode2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Тип времятоковой характеристики для ступени 2",
					"nameFromTargetFile": null,
					"rawValue": "Characterist2 \n(ANSI ЧрезвИнв / ANSI СильнИнв / ANSI НормИнв / ANSI / Независимая / ДлитЧрезвИнв / ДлитСильнИнв /  ДлитИнв / МЭК НормИнв / МЭК СильнИнв / МЭК Инверсная / МЭК ЧрезвИнв / МЭК КраткИнв / МЭК ДлитИнв / МЭК Независимая / Резерв / Программируемая / RI-типа / RD-типа)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Characterist2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току нулевой последовательности ступени 2, %IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN2>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени ступени 2, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени зависимой от тока характеристики времени ступени 2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности ступени 3",
					"nameFromTargetFile": null,
					"rawValue": "DirMode3\n(Выкл / Ненаправленная / Прямое / Обратное)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirMode3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Тип времятоковой характеристики для ступени 3",
					"nameFromTargetFile": null,
					"rawValue": "Characterist3 \n(ANSI ЧрезвИнв / ANSI СильнИнв / ANSI НормИнв / ANSI / Независимая / ДлитЧрезвИнв / ДлитСильнИнв /  ДлитИнв / МЭК НормИнв / МЭК СильнИнв / МЭК Инверсная / МЭК ЧрезвИнв / МЭК КраткИнв / МЭК ДлитИнв / МЭК Независимая / Резерв / Программируемая / RI-типа / RD-типа)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Characterist3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току нулевой последовательности ступени 3, %IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN3>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени ступени 3, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени зависимой от тока характеристики времени ступени 3",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим направленности ступени 4",
					"nameFromTargetFile": null,
					"rawValue": "DirMode4\n(Выкл / Ненаправленная / Прямое / Обратное)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirMode4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Тип времятоковой характеристики для ступени 4",
					"nameFromTargetFile": null,
					"rawValue": "Characterist4 \n(ANSI ЧрезвИнв / ANSI СильнИнв / ANSI НормИнв / ANSI / Независимая / ДлитЧрезвИнв / ДлитСильнИнв /  ДлитИнв / МЭК НормИнв / МЭК СильнИнв / МЭК Инверсная / МЭК ЧрезвИнв / МЭК КраткИнв / МЭК ДлитИнв / МЭК Независимая / Резерв / Программируемая / RI-типа / RD-типа)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Characterist4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току нулевой последовательности ступени 4, %IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IN4>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени ступени 4, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени зависимой от тока характеристики времени ступени 4",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "k4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim8",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim9",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTPrim12",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec8",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec9",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VTsec12",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Siemens 7SA522х",
			"name": "ТНЗНП",
			"fileName": "ТНЗНП_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Режим работы ступени 3I0>>>",
					"nameFromTargetFile": null,
					"rawValue": "3110\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3110",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току 3I0>>>, А  (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3111",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени Т 3I0>>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени 3I0>>",
					"nameFromTargetFile": null,
					"rawValue": "3120\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3120",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току 3I0>>, А  (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3121",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени Т 3I0>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени 3I0>",
					"nameFromTargetFile": null,
					"rawValue": "3130\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3130",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току 3I0>, А  (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3131",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени Т 3I0>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3132",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы ступени 3I0р",
					"nameFromTargetFile": null,
					"rawValue": "3140\nвперед\nназад\nбез направления\nнеактив",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3140",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка срабатывания по току 3I0р, А  (втор)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3141",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время действия МТЗ для характеристик по МЭК 3I0р, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3143",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время действия МТЗ для характеристик по АNSI 3I0р, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3144",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время действия МТЗ для логарифмических характеристик 3I0р, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3145",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Макс.время действия МТЗ для инв.хар.3I0р, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3146",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Дополнительная выдержка времени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3147",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "МЭК характеристика",
					"nameFromTargetFile": null,
					"rawValue": "3151\nНормал-инверсн\nСильно-инверсн\nПредел-инверс\nДлит инверс",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3151",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ANSI характеристика",
					"nameFromTargetFile": null,
					"rawValue": "3152\nИнверсная\nСокращ-инверсн\nДлит-инверсн\nУмерен-инверсн\nСильно-инверсн\nПредел-инверс\nРавн-инверсн",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3152",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Логарифмическая характеристика",
					"nameFromTargetFile": null,
					"rawValue": "3153\nЛогариф инверс",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3153",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Земл Защита",
					"nameFromTargetFile": null,
					"rawValue": "131\nВыведено\nМЭК ВрХМТока\nАNSI ВрХМТока\nЛогар ВрХМТока\nНезавис МТЗ\nU0 инверс\nSн инверс",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "131",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметры для определения направления (поляризация)",
					"nameFromTargetFile": null,
					"rawValue": "3160\nU0 + IУ или U2\nU0 + IУ\nс IУ только\nс U2 и I2\nМощностьНулПосл",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3160",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ALPHA, нижний предельный угол для направления \"вперед\", градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3162А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "BETA, верхний предельный угол для направления \"вперед\", градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3163А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное нулевое напряжение 3U0min, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3164",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное напряжение обратной последовательности 3U2min, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3166",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальный ток обратной последовательности 3I2min, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3167",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока, первичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Релематика(ТОР 300 КСЗ)",
			"name": "УРОВ",
			"fileName": "УРОВ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазный ток УРОВ, % от Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль РПВ при действии УРОВ на смежный выключатель",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NcontrRPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Замедление отключения смежных выключателей, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Экра(ШЭ2710)",
			"name": "УРОВ",
			"fileName": "УРОВ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка на срабатывание УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации тока линии",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktt",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "ABB (REL 670)",
			"name": "УРОВ",
			"fileName": "УРОВ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ibase",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы логики резервного отключения \n(по току(Current), по блок-контактам(Cont), по току и блок-контактам(Current&Cont))",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FunctionMode",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания для режима Current в % от Ibase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IP>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени на резервное отключение, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания для режима Current&Cont в % от IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I>BlkCont",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Линия",
			"vendor": "Siemens (7SA522x)",
			"name": "УРОВ",
			"fileName": "УРОВ_330_ЛЭП",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ф-я УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3901",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог срабатывания I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3902",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени Т2, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3906",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль выключателя по блок/конт",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3909",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока, первичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток трансформаторов тока, вторичный, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "206",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Релематика (ТОР 200 Л 22)",
			"name": "ЗМН",
			"fileName": "ЗМН_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Напряжение срабатывания, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Usrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nreg",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uvtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Siemens (7SJ62)",
			"name": "ЗМН",
			"fileName": "ЗМН_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Состояние ЗМН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "150",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Рабочая величина для ЗащПонижНапр",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "615A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Защита от понижения напряжения",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "5101",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по напряжению ступени U<<(ф-з), В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "5110",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени U<<, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "5112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата U<<",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "5114A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Поключение ТН: 1-фазное",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "240",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичное номинальное напряжение, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичное номинальное напряжение, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "203",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Релематика (ТОР 200 Л22)",
			"name": "МТЗ",
			"fileName": "МТЗ_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы",
					"nameFromTargetFile": null,
					"rawValue": "Nреж\n(0 – ненапр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nregn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Действие на отключение",
					"nameFromTargetFile": null,
					"rawValue": "Nоткл\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Notkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристика срабатывания",
					"nameFromTargetFile": null,
					"rawValue": "NтипХар\n(0 – независим",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ntiphar",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания с действием на отключение, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Totkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы",
					"nameFromTargetFile": null,
					"rawValue": "Nреж\n(0 – ненапр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nregn2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Действие на отключение",
					"nameFromTargetFile": null,
					"rawValue": "Nоткл\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Notkl2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристика срабатывания",
					"nameFromTargetFile": null,
					"rawValue": "NтипХар\n(0 – независим",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ntiphar2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания с действием на отключение, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Totkl2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы",
					"nameFromTargetFile": null,
					"rawValue": "Nреж\n(0 – ненапр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nregn3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Действие на отключение",
					"nameFromTargetFile": null,
					"rawValue": "Nоткл\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Notkl3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристика срабатывания",
					"nameFromTargetFile": null,
					"rawValue": "NтипХар\n(0 – независим",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ntiphar3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания с действием на отключение, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Totkl3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Fmch",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Uvtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Siemens (7SJ62)",
			"name": "МТЗ",
			"fileName": "МТЗ_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ с незав./инвер.выд.времени",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1203",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1204",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1205",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ступень МТЗ I>> активна",
					"nameFromTargetFile": null,
					"rawValue": "1214A\n(Всегда\nс акт",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1214A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ступень МТЗ I>>> активна",
					"nameFromTargetFile": null,
					"rawValue": "1216A\n(Всегда\nс акт",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1216A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1217",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1218",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Измерение I>>>",
					"nameFromTargetFile": null,
					"rawValue": "1219A \n(Осн Гарм\nДейс Знач\nМгнов Знач)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1219A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Измерение I>>",
					"nameFromTargetFile": null,
					"rawValue": "1220A \n(Осн Гарм\nДейс Знач)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1220A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Измерение I>",
					"nameFromTargetFile": null,
					"rawValue": "1221A \n(Осн Гарм\nДейс Знач)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1221A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичное номинальное напряжение, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичное номинальное напряжение, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "203",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный номинальный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "204",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичный номинальный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Релематика (ТОР 200 Л22)",
			"name": "МФТО",
			"fileName": "МФТО_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы",
					"nameFromTargetFile": null,
					"rawValue": "Nреж\n(0 – ненапр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nregn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристика срабатывания",
					"nameFromTargetFile": null,
					"rawValue": "NтипХар\n(0 – независим",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ntiphar",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания с действием на отключение, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Totkl",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Экра (БЭ2502А01)",
			"name": "МФТО",
			"fileName": "МФТО_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Работа МТЗ-1",
					"nameFromTargetFile": null,
					"rawValue": "Раб",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Rab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания загрубленной МТЗ-1, А втор",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isr2MTZ1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ-1, А втор",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrMTZ1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ-1, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrMTZ1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Автоматическое загрубление уставки МТЗ-1",
					"nameFromTargetFile": null,
					"rawValue": "Авт",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Avtzagyst1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль направленности МТЗ-1",
					"nameFromTargetFile": null,
					"rawValue": "Контр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kontrnapr1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск по напряжению МТЗ-1",
					"nameFromTargetFile": null,
					"rawValue": "Пуск по U 1ст",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PyskpoU1st",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "ABB (SPAC 810-Л)",
			"name": "МФТО",
			"fileName": "МФТО_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод в действие 1-ой ступени МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "МТЗ 1-я ступень\n(0 - введена/ \n1 - выведена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выбор действия 1-ой ступени МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "Действие\n(0 - Ненаправлен",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Deystvie",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току срабатывания 1-ой ступени МТЗ для прямого направления во вторичных значениях, A",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isrprym",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка выдержки Т1 по времени срабатывания 1-ой ступени МТЗ для прямого направления, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "T1prym",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Siemens (7SJ62_64)",
			"name": "МФТО",
			"fileName": "МФТО_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичное номинальное напряжение, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичное номинальное напряжение, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "203",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный номинальный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "204",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичный номинальный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Релематика ТОР 200 Л 22",
			"name": "ТНЗНП",
			"fileName": "ТНЗНП_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы",
					"nameFromTargetFile": null,
					"rawValue": "Nреж\n(0 – ненапр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nreg1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Действие на отключение",
					"nameFromTargetFile": null,
					"rawValue": "Nоткл\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Notkl1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания с действием на отключение, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Totkl1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0yst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы",
					"nameFromTargetFile": null,
					"rawValue": "Nреж\n(0 – ненапр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nreg2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Действие на отключение",
					"nameFromTargetFile": null,
					"rawValue": "Nоткл\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Notkl2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания с действием на отключение, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Totkl2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Fmch",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа ускорения",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvodusk",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ускорение при пуске 1 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "NускТНЗНП1\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NuskTNZNP1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ускорение при пуске 2 ступени ТНЗНП",
					"nameFromTargetFile": null,
					"rawValue": "NускТНЗНП2\n(0 – вывод,\n1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NuskTNZNP2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tusk",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Siemens 7SJ62_64",
			"name": "ТНЗНП",
			"fileName": "ТНЗНП_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Уставка по току ступени IЕЕ>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3113",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени IЕЕ>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3114",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направление ступени IЕЕ>>",
					"nameFromTargetFile": null,
					"rawValue": "3115\n(В прям напр\nВ обратн напр\nНенаправленное)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3115",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени IЕЕ>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3117",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени IЕЕ>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3118",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направление ступени IЕЕ> / IЕЕр",
					"nameFromTargetFile": null,
					"rawValue": "3122\n(В прям напр\nВ обратн напр\nНенаправленное)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол коррекции для определения направления, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3124",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Релематика (ТОР 200 Л 22)",
			"name": "УРОВ",
			"fileName": "УРОВ_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль РПВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NcontrRPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-10 кВ",
			"bayType": "Присоединение",
			"vendor": "Siemens (7SJ62)",
			"name": "УРОВ",
			"fileName": "УРОВ_Присоединение_6-10кВ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль выключателя по блок/конт",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7004",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени УРОВ, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7005",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог пуска I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7006",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный номинальный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "204",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичный номинальный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Сборные шины",
			"vendor": "Релематика ТОР 300 ДЗО 50Х",
			"name": "ДЗО",
			"fileName": "ДЗО_СШ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фиксация присоединения Q1….QN",
					"nameFromTargetFile": null,
					"rawValue": "Nфикс1",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nfiks1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный дифференциальный ток срабатывания ИО ДЗО 1с.ш., % от Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Id1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный дифференциальный ток срабатывания ИО ДЗО 2с.ш., % от Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Id2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения QN, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervN*",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения QN, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorN*",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединения QN, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermN*",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Сборные шины",
			"vendor": "Siemens 7UT612х",
			"name": "ДЗО",
			"fileName": "ДЗО_СШ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Защищаемый объект",
					"nameFromTargetFile": null,
					"rawValue": "105 (3-фТрансформ;\n1-фТрансформ;\nАвтотрансформ;\nГенерат/Двигат;\n3ф Шины;\n1ф Шины)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "105",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Количество концов для 1-фазной защиты шин",
					"nameFromTargetFile": null,
					"rawValue": "107 (3/4/5/6/7)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "107",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Дифференциальная защита",
					"nameFromTargetFile": null,
					"rawValue": "1201 (ОТКЛ;\nВКЛ;\nРелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току Iдифф>, I/IнО",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1221",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Сборные шины",
			"vendor": "Siemens",
			"name": "ДЗШ",
			"fileName": "ДЗШ_СШ (Терминал_7SS)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Комбин.ШСВ",
					"nameFromTargetFile": null,
					"rawValue": "106А (да/нет)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "106А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Статус Присоед",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент стабилизации СШ селектив.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "6101",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог диф. тока Iд селектив., о.е. I/Iно",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "6102",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент стабилиз. контрольной зоны, о.е. /Iно",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "6103",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог диф. тока Iд Контр. Зоны , о.е. I/Iно",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "6104",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог тока стаб. Iст селектив. при ЗЗ , о.е. /Iно",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "6108А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог диф. тока Iд селектив. при ЗЗ , о.е. /Iно",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "6109А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог тока стаб. Iст КонтрЗоны при ЗЗ , о.е. /Iно",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "6110А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог диф. тока Iд Контр. Зоны при ЗЗ , о.е. /Iно",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "6111А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Переключение характеристики при ЗЗ",
					"nameFromTargetFile": null,
					"rawValue": "6320А (Разрешенный / Блокированный)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "6320А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ТТ Полярн",
					"nameFromTargetFile": null,
					"rawValue": "1101 (в направл линии/в направл шин)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1101",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Iн первичн",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1105",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Т ОТКЛ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1141",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Сборные шины",
			"vendor": "Релематика ТОР 300 ДЗШ 51Х",
			"name": "ДЗШ",
			"fileName": "ДЗШ_СШ (Терминал_7UT)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Qn, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervN*",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Qn, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorN*",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединения Qn, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermN*",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Фиксация присоединения Q1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nfiks1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Фиксация присоединения Q2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nfiks2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Фиксация присоединения Q3….QN",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nfiks3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный дифференциальный ток срабатывания ПО ДЗШ, % от Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdPO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный дифференциальный ток срабатывания ИО1 ДЗШ, % от Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdIO1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный дифференциальный ток срабатывания ИО2 ДЗШ, % от Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IdIO2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Использование ПО ДЗШ для контроля отключения с.ш. от ИО",
					"nameFromTargetFile": null,
					"rawValue": "NиспПО (0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NispPO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Сборные шины",
			"vendor": "Экра 2607 061",
			"name": "ДЗШ",
			"fileName": "ДЗШ_СШ (Терминал_7UT)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Присоединение Q1-2 зона",
					"nameFromTargetFile": null,
					"rawValue": "ПрисоедQ1-2зона (0 - откл/1 - ИО2)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N1-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Присоединение Q1-1 зона",
					"nameFromTargetFile": null,
					"rawValue": "ПрисоедQ1-1зона (0 - откл/1 - ИО1)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N1-1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Присоединение QN зона",
					"nameFromTargetFile": null,
					"rawValue": "ПрисоедQN* зона (0 - откл/1 - ПО+ИО1/ 2 - ПО+ИО2)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NN*",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ПО, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrPO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО1, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrIO1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО2, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrIO2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ Q1-2 c.ш",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervQ1-2/IvtorQ1-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ Q1-1 c.ш",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervQ1-1/IvtorQ1-1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ QN*",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervN/IvtorN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Сборные шины",
			"vendor": "ABB REB670",
			"name": "ДЗШ",
			"fileName": "ДЗШ_СШ (Терминал_7UT)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Режим работы дифференциальной защиты",
					"nameFromTargetFile": null,
					"rawValue": "Operation (0 - Отключена, 1 - Включена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation 1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уровень срабатывания дифференциальной защиты  в первичных амперах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DiffOperLev1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы дифференциальной защиты",
					"nameFromTargetFile": null,
					"rawValue": "Operation (0 - Отключена, 1 - Включена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уровень срабатывания дифференциальной защиты  в первичных амперах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DiffOperLev2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы функции контроля общей зоны",
					"nameFromTargetFile": null,
					"rawValue": "Operation (0 - Отключена, 1 - Включена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уровень срабатывания  в первичных амперах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperLevel",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Сборные шины",
			"vendor": "Siemens",
			"name": "ДЗШ",
			"fileName": "ДЗШ_СШ (Терминал_7UT)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "ДиффЗащита",
					"nameFromTargetFile": null,
					"rawValue": "1201 (ВКЛ, ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току I-Дифф>,  I/IнО",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1221",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току Iдифф>>, I/Iн0",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1231",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона 1 хар-ки срабатывания",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1241А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовая точка первого наклона х-ки, I/IнО",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1242А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол наклона 2 хар-ки срабатывания",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1243А",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовая точка второго наклона х-ки,  I/IнО",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1244А",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Сборные шины",
			"vendor": "Релематика (ТОР 300 ДЗШ 513)",
			"name": "УРОВ",
			"fileName": "УРОВ_СШ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания реле тока УРОВ, % от IтермN*",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа функции УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль РПВ при действии УРОВ на смежный выключатель",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NcontrRPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Замедление отключения смежных выключателей, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q1, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q2, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q3, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q3, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q3, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q4, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q4, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q4, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q5, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q5, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q5, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q6, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q6, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q6, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q7, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q7, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q7, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q8, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv8",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q8, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor8",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q8, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm8",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q9, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv9",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q9, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor9",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q9, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm9",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q10, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q10, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q10, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q11, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q11, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q11, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q12, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv12",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q12, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor12",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q12, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm12",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q13, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv13",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q13, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor13",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q13, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm13",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q14, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv14",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q14, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor14",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q14, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm14",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q15, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv15",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q15, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor15",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q15, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm15",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q16, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv16",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q16, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor16",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q16, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm16",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q17, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv17",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q17, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor17",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q17, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm17",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ присоединения Q18, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iperv18",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ присоединения Q18, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivtor18",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала, соответствующего присоединению Q18, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iterm18",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Сборные шины",
			"vendor": "Экра (ШЭ2607 061_200)",
			"name": "УРОВ",
			"fileName": "УРОВ_СШ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания реле тока УРОВ Q1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabQ1-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Подтверждение пуска УРОВ Q1 от сигнала KQC",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PodtUROVQ1-KQC",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания УРОВ Q1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrUROVQ1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Действие УРОВ Q1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UROVQ1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации трансформатора тока Q1-2 с.ш.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktt Q1-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания реле тока УРОВ Q3",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabQ3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Подтверждение пуска УРОВ Q3 от сигнала KQC",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PodtUROVQ3-KQC",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания УРОВ Q3",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrUROVQ3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Действие УРОВ Q3",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UROVQ3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации трансформатора тока Q3",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktt Q3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания реле тока УРОВ Q4",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabQ4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Подтверждение пуска УРОВ Q4 от сигнала KQC",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PodtUROVQ4-KQC",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания УРОВ Q4",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrUROVQ4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Действие УРОВ Q4",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UROVQ4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации трансформатора тока Q4",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ktt Q4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Сборные шины",
			"vendor": "ABB (REB 670)",
			"name": "УРОВ",
			"fileName": "УРОВ_СШ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовый ток, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ibase",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы логики резервного отключения \n(по току(Current), по блок-контактам(Cont), по току и блок-контактам(Current&Cont))",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "FunctionMode",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания для режима Current в % от Ibase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IP>",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени на резервное отключение, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "t2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания для режима Current&Cont в % от IBase",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I>BlkCont",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim7",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec8",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim8",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec9",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim9",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток подключенного ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec12",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTPrim12",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика",
			"name": "ДЗТ",
			"fileName": "ДЗТ_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndzt",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток срабатывания ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток торможения 2-го участка ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения 2-го участка ТХ, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kt2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток торможения 3-го участка ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения 3-го участка ТХ, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kt3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания дифференциальной отсечки, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idto",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы БВГ",
					"nameFromTargetFile": null,
					"rawValue": "NактБлкf2\n(0-вывод, 1-постоянно, 2-автоматически)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "AktBlk2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка блокировки по второй гармонике, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "K2f1f",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 1-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 2-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 3-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 4-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 5-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 6-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 1-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 2-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 3-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 4-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 5-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 6-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН,А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра",
			"name": "ДЗТ",
			"fileName": "ДЗТ_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystDZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток начала торможения ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It0DZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток торможения блокировки ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItmaxDZT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtDZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уровень бл. по 2 гармонике, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kblpo2gar",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Дифференциальная отсечка",
					"nameFromTargetFile": null,
					"rawValue": "Диф",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndifots",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания диф. отсечки, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idifots",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка на срабатывание дифф. отсечки, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VremaDiffOts",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны СН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны НН2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №1",
					"nameFromTargetFile": null,
					"rawValue": "Схема ВН (0 - Y, 1 - D)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №2",
					"nameFromTargetFile": null,
					"rawValue": "Схема СН (0 - Y, 1 - D)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №3",
					"nameFromTargetFile": null,
					"rawValue": "Схема НН1 (0 - Y, 1 - D)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №4",
					"nameFromTargetFile": null,
					"rawValue": "Схема НН2 (0 - Y, 1 - D)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона ВН",
					"nameFromTargetFile": null,
					"rawValue": "Сторона ВН (0 - нет, 1 - есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона СН",
					"nameFromTargetFile": null,
					"rawValue": "Сторона СН (0 - нет, 1 - есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона НН1",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН1 (0 - нет, 1 - есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона НН2",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН2 (0 - нет, 1 - есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ ВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_vn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ CН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_sn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_nn1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_nn2",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB",
			"name": "ДЗТ",
			"fileName": "ДЗТ_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции дифференциальной защиты трансформатора, Выкл/Вкл",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - 1)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Векторная группа двухобмоточного трансформатора (1-24), о.е.",
					"nameFromTargetFile": null,
					"rawValue": "VectorGroup 2W (1-24)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VectorGroup 2W",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Векторная группа трехобмоточного трансформатора (1-288), о.е.",
					"nameFromTargetFile": null,
					"rawValue": "VectorGroup 3W (1-288)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VectorGroup 3W",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вычитание тока нулевой последовательности, Выкл/Вкл",
					"nameFromTargetFile": null,
					"rawValue": "ZSCSub (0-1)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZSCSub",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номер тормозной характеристики",
					"nameFromTargetFile": null,
					"rawValue": "CharactNo\n(1 - 5)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CharactNo",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальная уставка срабатывания дифференциальной защиты в % от номинального тока опорной стороны Ir (Iном)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idmin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка дифференциальной защиты без торможения (дифотсечка) в % от Ir (Iном)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idunre",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Блокировка по второй гармонике, Условно/Всегда",
					"nameFromTargetFile": null,
					"rawValue": "StabByOption\n(0 - 1 )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StabByOption",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Блокировка дифференциальной защиты с торможением по второй гармонике (отношение второй гармоники к первой) в %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2/I1 ratio",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Принятое (включенное) ответвление обмотки \nвходного ТТ (1А или 5А)",
					"nameFromTargetFile": null,
					"rawValue": "Input CT Tap \n(1A, 5A)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Input CT Tap",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток высоковольтного (защитного) ТТ.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CT prim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток высоковольтного (защитного) ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CT sec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток первичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение первичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток вторичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение вторичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность первичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток первичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение первичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность вторичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток вторичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение вторичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность третичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток третичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение третичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur3",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens",
			"name": "ДЗТ",
			"fileName": "ДЗТ_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "ДиффЗащита",
					"nameFromTargetFile": null,
					"rawValue": "112 (Введено /Выведено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ДиффЗащита",
					"nameFromTargetFile": null,
					"rawValue": "1201 (ОТКЛ/ВКЛ/ РелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "I-Дифф>>,I/Iн0",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1231",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "I-Дифф>,I/Iн0",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1221",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "УголНаклона1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1241A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовая Точка1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1242A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "УголНаклона2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1243A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовая Точка2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1244A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Содержание 2 гармоники в токе I-Дифф",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1271",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торм.ток при броске тока намагнич. По 2 гарм.",
					"nameFromTargetFile": null,
					"rawValue": "1206 (ВКЛ/ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1206",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Соединение обмоток трансф. стороны 1",
					"nameFromTargetFile": null,
					"rawValue": "314 (Звезда (У)/ Треугольник (D)/ Зигзаг (Z))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "314",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Соединение обмоток трансф. стороны 2",
					"nameFromTargetFile": null,
					"rawValue": "324 (Звезда (У)/ Треугольник (D)/ Зигзаг (Z))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "324",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Соединение обмоток трансф. стороны 3",
					"nameFromTargetFile": null,
					"rawValue": "334 (Звезда (У)/ Треугольник (D)/ Зигзаг (Z))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "334",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Соединение обмоток трансф. стороны 4",
					"nameFromTargetFile": null,
					"rawValue": "344 (Звезда (У)/ Треугольник (D)/ Зигзаг (Z))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "344",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Соединение обмоток трансф. стороны 5",
					"nameFromTargetFile": null,
					"rawValue": "354 (Звезда (У)/ Треугольник (D)/ Зигзаг (Z))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "354",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 1 трансф-ра, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 2 трансф-ра, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "322",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 3 трансф-ра,МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "332",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 4 трансф-ра, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "342",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 5 трансф-ра, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "352",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 2,кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "321",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 3,кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "331",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 4,кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "341",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 5,кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "351",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "512",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 1,А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "513",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 2,А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "522",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "523",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 3, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "532",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 3, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "533",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 4, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "542",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 4, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "543",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 5, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "552",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 5, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "553",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика ТОР 300 ДЗТ 512..513",
			"name": "ЗП",
			"fileName": "ЗП_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока стороны ВН, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока стороны СН, % IтермСН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока стороны НН, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания защиты от перегрузки, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны CН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны CН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны CН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны НН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны НН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны НН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermNN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра 2607 041...153",
			"name": "ЗП",
			"fileName": "ЗП_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ЗП по стороне № 1 (ВН), А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrZPVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ЗП по стороне № 2 (СН), А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrZPSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ЗП по стороне НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrZPNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ЗП по стороне НН2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrZPNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка на срабатывание ЗП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT09",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Защита от перегрузки по стороне № 1 (ВН)",
					"nameFromTargetFile": null,
					"rawValue": "ХВ11\n(0 - не предусмотрена/\n1 - предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XB11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Защита от перегрузки по стороне № 2 (CН)",
					"nameFromTargetFile": null,
					"rawValue": "ХВ12\n(0 - не предусмотрена/\n1 - предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XB12",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Защита от перегрузки по стороне № 3 (НН1)",
					"nameFromTargetFile": null,
					"rawValue": "ХВ13\n(0 - не предусмотрена/\n1 - предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XB13",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Защита от перегрузки по стороне № 4 (НН2)",
					"nameFromTargetFile": null,
					"rawValue": "ХВ14\n(0 - не предусмотрена/\n1 - предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XB14",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB RET521",
			"name": "ЗП",
			"fileName": "ЗП_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperationVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDefVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHighVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHighVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperationSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDefSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHighSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHighSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperationNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDefNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHighNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHighNN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7UT613",
			"name": "ЗП",
			"fileName": "ЗП_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Защита от термической перегрузки",
					"nameFromTargetFile": null,
					"rawValue": "4201\n(ВКЛ/ ОТКЛ/Реле блокировано/Только сигнал)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току сигнальной ступени защиты от перегрузки, I/IнС",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4205",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 1 (линейное), кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 1, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный номинальный ток ТТ точки измерения 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "512",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичный номинальный ток точки измерения 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "513",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика ТОР 300 ДЗТ 512",
			"name": "МТЗ",
			"fileName": "МТЗ_ВН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 3 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск по напряжению 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3U\n(0 – вывод; 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 3 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра ШЭ2607 041",
			"name": "МТЗ",
			"fileName": "МТЗ_ВН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystVN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ ВН 2 ступень (СВ СН и НН вкл.), с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystVN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск МТЗ ВН по напряжению",
					"nameFromTargetFile": null,
					"rawValue": "Пуск МТЗ ВН по U \n(0 - предусмотрен / 1 - не предусмотрен)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PuskMTZVNpoU",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение переменного тока, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН ВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtnVN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB RET521",
			"name": "МТЗ",
			"fileName": "МТЗ_ВН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация трехфазной МТЗ с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Выкл/1 - Вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени низкой ступени, в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное время срабатывания в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tMin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность низкой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionLow \n(0 - Ненаправл/ \n1- в прямом направлении / \n2 - в обратном \nнаправлении )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность высокой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionHigh\n(0 - Ненаправл / \n1 - в прямом направлении / \n2 - в обратном \nнаправлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристический угол реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "rca",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол срабатывания реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "roa",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток первичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtsec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ctsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7UT612x",
			"name": "МТЗ",
			"fileName": "МТЗ_ВН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ с незав./инвер.выд.времени",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "120",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "2001\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2011",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2012",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2013",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2015",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2016",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ Ip",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2021",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ Ip",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2022",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ Ip",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2023",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.времени D ст.Ip",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2024",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Времятоковая характеристика возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2025",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характер МЭК",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2026",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характер ANSI",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2027",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первич. напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "240",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "203",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 1 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 2, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "321",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 2 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "322",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 3, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "331",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 3 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "332",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика ТОР 300 ДЗТ 512",
			"name": "МТЗ",
			"fileName": "МТЗ_НН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермНН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермНН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 3 ступени, % IтермНН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности ОНМ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод автоматического ускорения 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nуск1\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль направления мощности 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр3\n(0 – без контроля мощности, 1 – направление в объект, \n2 – направление в сеть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск по напряжению 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3U\n(0 – вывод; 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 3 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время ввода автоматического ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН стороны НН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН стороны НН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение входа терминала стороны НН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра ШЭ2607 041",
			"name": "МТЗ",
			"fileName": "МТЗ_НН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Сторона трансформатора №3 (НН1)",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН1\n(0 - нет, 1 - есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ НН1-1  ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystNN1-1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ НН1-2  ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystNN1-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН1-1 ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystNN1-1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН1-2  ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystNN1-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск МТЗ НН1 по напряжению",
					"nameFromTargetFile": null,
					"rawValue": "Пуск МТЗ НН1 поU\n(0 - предусмотрен / 1 - не предусмотрен)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PuskMTZNN1poU",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания мин. реле пуска по напряжению НН1, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UNN1min",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания реле обратной послед. НН1, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2NN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "РНМПП для МТЗ НН1",
					"nameFromTargetFile": null,
					"rawValue": "Напр-тьМТЗ НН1\n(0 - предусмотрено / 1 - не предусмотрено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprMTZNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направление РНМПП НН1",
					"nameFromTargetFile": null,
					"rawValue": "Напр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprRNMNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол макс. чувствительности РНМПП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UgolMaxChyvNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН1 с ускорением, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TMTZNN1uskQ3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Прием сигнала ''KQT Q3 (НН1)'' по входу",
					"nameFromTargetFile": null,
					"rawValue": "Вх",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VhKQTQ3NN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона трансформатора №4 (НН2)",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН2\n(нет, есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ НН2-1  ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystNN2-1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ НН2-2  ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystNN2-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН2-1 ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystNN2-1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН2-2  ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystNN2-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск МТЗ НН2 по напряжению",
					"nameFromTargetFile": null,
					"rawValue": "Пуск МТЗ НН2 поU\n(0 - предусмотрен / 1 - не предусмотрен)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PuskMTZNN2poU",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания мин. реле пуска по напряжению НН2, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UNN2min",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания реле обратной послед. НН2, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2NN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "РНМПП для МТЗ НН2",
					"nameFromTargetFile": null,
					"rawValue": "Напр-тьМТЗ НН2\n(0 - предусмотрено / 1 - не предусмотрено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprMTZNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направление РНМПП НН2",
					"nameFromTargetFile": null,
					"rawValue": "Напр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprRNMNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол макс. чувствительности РНМПП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UgolMaxChyvNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН2 с ускорением, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TMTZNN2usk",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Прием сигнала ''KQT Q4 (НН2)'' по входу",
					"nameFromTargetFile": null,
					"rawValue": "Вх",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VhKQTQ4NN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение переменного тока, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН НН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtnNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KttNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН НН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtnNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KttNN2",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB RET521",
			"name": "МТЗ",
			"fileName": "МТЗ_НН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация трехфазной МТЗ с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Выкл/1 - Вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени низкой ступени, в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное время срабатывания в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tMin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность низкой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionLow \n(0 - Ненаправл/ \n1 - в прямом направлении / \n2 - в обратном \nнаправлении )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность высокой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionHigh\n(0 - Ненаправл / \n1 - в прямом направлении / \n2 - в обратном \nнаправлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристический угол реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "rca",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол срабатывания реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "roa",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток третиччной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtsec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ctsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7UT612x",
			"name": "МТЗ",
			"fileName": "МТЗ_НН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "2001\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2011",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2012",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2013",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первич. напряжение стороны 2, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "243",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ стороны 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "207",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ стороны 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "208",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика ТОР 300 ДЗТ 512",
			"name": "МТЗ",
			"fileName": "МТЗ_СН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермCН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермCН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 3 ступени, % IтермСН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности ОНМ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод автоматического ускорения 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nуск1\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль направления мощности 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр3\n(0 – без контроля мощности, 1 – направление в объект, \n2 – направление в сеть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск по напряжению 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3U\n(0 – вывод; 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 3 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время ввода автоматического ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН стороны СН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН стороны СН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны СН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны СН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра ШЭ2607 041",
			"name": "МТЗ",
			"fileName": "МТЗ_СН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ СН-1 ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystSN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ СН-2 ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystSN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ СН 1 ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystSN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ СН 2 ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystSN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск МТЗ СН по напряжению",
					"nameFromTargetFile": null,
					"rawValue": "Пуск МТЗ СН поU\n(0 - предусмотрен / 1 - не предусмотрен)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PuskMTZSNpoU",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания мин. реле пуска по напряжению СН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "USNmin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания реле обратной послед. СН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "РНМПП для МТЗ СН",
					"nameFromTargetFile": null,
					"rawValue": "Напр-тьМТЗ СН\n(0 - предусмотрено / 1 - не предусмотрено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprMTZSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направление РНМПП СН",
					"nameFromTargetFile": null,
					"rawValue": "Напр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprRNMSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол макс. чувствительности РНМПП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UgolMaxChyv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ СН с ускорением на отключение Q3, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TMTZSNuskQ3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Прием сигнала ''KQT Q3 (СН)'' по входу",
					"nameFromTargetFile": null,
					"rawValue": "Вх",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VhKQTQ3SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение переменного тока, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН CН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtnSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ СН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KttSN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB RET521",
			"name": "МТЗ",
			"fileName": "МТЗ_СН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация трехфазной МТЗ с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Выкл/1 - Вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени низкой ступени, в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное время срабатывания в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tMin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность низкой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionLow \n(0 - Ненаправл/ \n1- в прямом направлении / \n2 - в обратном \nнаправлении )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность высокой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionHigh\n(0 - Ненаправл / \n1 - в прямом направлении / \n2 - в обратном \nнаправлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристический угол реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "rca",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол срабатывания реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "roa",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток вторичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtsec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ctsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7SA",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_110_Т(АТ)_7SA",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ от КЗ на землю",
					"nameFromTargetFile": null,
					"rawValue": "122\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристика для токовой защиты от КЗ \nна землю",
					"nameFromTargetFile": null,
					"rawValue": "113\n(Выведено/\nНезависим выдержка/\nМТЗ хар-ка МЭК/\nМТЗ хар-ка ANSI/\nХар-ка пользов/\nХарВозв Польз)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "113",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1304",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1305",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1302",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1303",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1317",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1318",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IEр, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1307",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для МЭК  характеристик, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1308",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для ANSI  характеристик, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1309",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристики МЭК",
					"nameFromTargetFile": null,
					"rawValue": "1311\n(Норм-инверсная/\nСильно-инверсная/\nПредел-инверсная/\nДлит-инверсная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристики ANSI",
					"nameFromTargetFile": null,
					"rawValue": "1312\n(Сильно-инверсная/\nИнверсная/\nСокращ-инверсная/\nДлит-инверсная/\nУмерен-инверсная/\nПредел-инверсная/\nОпределенно-инверсная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение при броске тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение при броске тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "122 \n(Выведено/ Введено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка составляющей 2-й гармоники для определения бросков тока намагничивания, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первич. напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "240",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "203",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 1 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 2, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "321",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 2 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "322",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 3, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "331",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 3 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "332",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика ТОР 300 РЗТ 526",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_110_Т(АТ)_7SJ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО утроенного тока нулевой последовательности, % 3I0терм",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0sr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС на отключение выключателя ВН смежного трансформатора, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsr1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС на деление шин ВН, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsr2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС на отключение выключателя ВН, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsr3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС на отключение трансформатора, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsr4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка блокировки по второй гармонике, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kf2f1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод блокировки по второй гармонике",
					"nameFromTargetFile": null,
					"rawValue": "NвводБВГ\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NvvodBVG",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток 3I0 ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0pervVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток 3I0 ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0vtorVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток 3I0 входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVN3I0",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра 2607 073...158",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_110_Т(АТ)_7SJ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО ТЗНП, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrTZNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT11 Задержка на срабатывание ТЗНП в защиту Т2, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT10 Задержка на отключение ШСВ, СВ от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT13 Задержка на отключение выключателя от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT13",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT14 Задержка на отключение трансформатора от ТЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT14",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ ВН, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB RET521",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_110_Т(АТ)_7SJ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация защиты от замыканий на землю с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDef",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Временная характеристика для TEFх",
					"nameFromTargetFile": null,
					"rawValue": "CurveType\n(0 - DEF - независимая/\n1 - NI - нормально инверсная/\n2 - VI - очень инверсная/\n3 - EI - чрезвычайно инверсная/\n4 - LI - длительно инверсная/\n5 - LOG - логарифмическая (RXIDG))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CurveType",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для низкой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для обратнозависимой выдержки времени",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "K",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение низкой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harLow\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение высокой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harHigh\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отношение второй гармоники к первой, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2/I1 ratio",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальныйвторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7SJ62-64х",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_110_Т(АТ)_7SJ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ от КЗ на землю",
					"nameFromTargetFile": null,
					"rawValue": "1301\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1301",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристика для токовой защиты от КЗ \nна землю",
					"nameFromTargetFile": null,
					"rawValue": "113\n(Выведено/\nНезависим выдержка/\nМТЗ хар-ка МЭК/\nМТЗ хар-ка ANSI/\nХар-ка пользов/\nХарВозв Польз)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "113",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1304",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1305",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1302",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1303",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1317",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1318",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IEр, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1307",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для МЭК  характеристик, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1308",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для ANSI  характеристик, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1309",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристики МЭК",
					"nameFromTargetFile": null,
					"rawValue": "1311\n(Норм-инверсная/\nСильно-инверсная/\nПредел-инверсная/\nДлит-инверсная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристики ANSI",
					"nameFromTargetFile": null,
					"rawValue": "1312\n(Сильно-инверсная/\nИнверсная/\nСокращ-инверсная/\nДлит-инверсная/\nУмерен-инверсная/\nПредел-инверсная/\nОпределенно-инверсная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение при броске тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение при броске тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "122 \n(Выведено/ Введено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка составляющей 2-й гармоники для определения бросков тока намагничивания, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный номинальный ток ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "204",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичный номинальный ток ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7UT",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_110_Т(АТ)_7UT",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ 3I0 с НВВ/ИВВ",
					"nameFromTargetFile": null,
					"rawValue": "122\n(Выведено\nНезависим Выд\nМТЗ Хар-каМЭК\nМТЗ Хар-каANSI\nХар-ка Пользов\nХарВозв Польз)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "МТЗ Нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ\nОТКЛ\nРелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отстройка от броска тока намагн. МТЗ 3I0",
					"nameFromTargetFile": null,
					"rawValue": "2202\n(ВКЛ\nОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени 3I0>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2211",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току 3I0>>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2212",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени 3I0>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2213",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени 3I0>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2214",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току 3I0>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2215",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ 3I0>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2216",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени 3I0р, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2221",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току 3I0p, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2222",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени 3I0р, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2223",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф. времени ступени 3I0р",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2224",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Хар-ка возврата МТЗ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2225",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристические кривые МЭК",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2226",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристические кривые ANSI",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2227",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отстройка от броска тока намагн. МТЗ 3I0",
					"nameFromTargetFile": null,
					"rawValue": "2202\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Составл. 2-й гармон. МТЗ 3I0, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2241",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первичное напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 1 трансф., МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первичное напряжение стороны 2, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "321",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 2 трансф., МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "322",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первичн. напряжение стороны 3, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "331",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 3 трансф., МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "332",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный рабочий номин. ток Стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "372",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный рабочий номин. ток Стороны 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "373",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный рабочий номин. ток Стороны 3, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "374",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика (ТОР 300 ДЗТ)",
			"name": "УРОВ",
			"fileName": "УРОВ_ВН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания реле тока УРОВ, % от IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Работа функции УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль РПВ при действии УРОВ на смежный выключатель",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NcontrRPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени на срабатывание УРОВ, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ВН, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра (ШЭ2607 041_230)",
			"name": "УРОВ",
			"fileName": "УРОВ_ВН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания реле тока УРОВ ВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "УРОВ ВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UROVVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Подтверждение пуска УРОВ ВН от сигнала KQC",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PodtUrovKQC",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания УРОВ ВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TsrabUROV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ ВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KttVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "110-220 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens (7UT612x)",
			"name": "УРОВ",
			"fileName": "УРОВ_ВН_110_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Мин.порог тока включ.выключателя для Ст1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "283",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Мин.порог тока включ.выключателя для Ст2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "284",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Устр. резерв. отказа выключателя (УРОВ)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "170",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Устр. резерв. отказа выключателя (УРОВ)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль выключателя по блок/конт",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7004",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени УРОВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7005",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "203",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ стороны 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "207",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ стороны 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "208",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика",
			"name": "ДЗТ",
			"fileName": "ДЗТ_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndzt",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток срабатывания ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток торможения 2-го участка ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения 2-го участка ТХ, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kt2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток торможения 3-го участка ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения 3-го участка ТХ, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kt3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания дифференциальной отсечки, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idto",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы БВГ",
					"nameFromTargetFile": null,
					"rawValue": "NактБлкf2\n(0-вывод, 1-постоянно, 2-автоматически)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "AktBlk2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка блокировки по второй гармонике, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "K2f1f",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 1-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 2-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 3-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 4-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 5-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения токовых цепей 6-го плеча",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Scheme_6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 1-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 2-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 3-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 4-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 5-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign5",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент выравнивания токов 6-го плеча, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kalign6",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН,А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ivn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра",
			"name": "ДЗТ",
			"fileName": "ДЗТ_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystDZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток начала торможения ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It0DZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток торможения блокировки ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItmaxDZT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtDZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уровень бл. по 2 гармонике, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kblpo2gar",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Дифференциальная отсечка",
					"nameFromTargetFile": null,
					"rawValue": "Диф",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndifots",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания диф. отсечки, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idifots",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка на срабатывание дифф. отсечки, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VremaDiffOts",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны СН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны НН2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №1",
					"nameFromTargetFile": null,
					"rawValue": "Схема ВН (0 - Y, 1 - D)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №2",
					"nameFromTargetFile": null,
					"rawValue": "Схема СН (0 - Y, 1 - D)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №3",
					"nameFromTargetFile": null,
					"rawValue": "Схема НН1 (0 - Y, 1 - D)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №4",
					"nameFromTargetFile": null,
					"rawValue": "Схема НН2 (0 - Y, 1 - D)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона ВН",
					"nameFromTargetFile": null,
					"rawValue": "Сторона ВН (0 - нет, 1 - есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона СН",
					"nameFromTargetFile": null,
					"rawValue": "Сторона СН (0 - нет, 1 - есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона НН1",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН1 (0 - нет, 1 - есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона НН2",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН2 (0 - нет, 1 - есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ ВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_vn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ CН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_sn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_nn1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_nn2",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB",
			"name": "ДЗТ",
			"fileName": "ДЗТ_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции дифференциальной защиты трансформатора, Выкл/Вкл",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - 1)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Векторная группа двухобмоточного трансформатора (1-24), о.е.",
					"nameFromTargetFile": null,
					"rawValue": "VectorGroup 2W (1-24)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VectorGroup 2W",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Векторная группа трехобмоточного трансформатора (1-288), о.е.",
					"nameFromTargetFile": null,
					"rawValue": "VectorGroup 3W (1-288)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VectorGroup 3W",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вычитание тока нулевой последовательности, Выкл/Вкл",
					"nameFromTargetFile": null,
					"rawValue": "ZSCSub (0-1)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZSCSub",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номер тормозной характеристики",
					"nameFromTargetFile": null,
					"rawValue": "CharactNo\n(1 - 5)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CharactNo",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальная уставка срабатывания дифференциальной защиты в % от номинального тока опорной стороны Ir (Iном)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idmin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка дифференциальной защиты без торможения (дифотсечка) в % от Ir (Iном)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idunre",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Блокировка по второй гармонике, Условно/Всегда",
					"nameFromTargetFile": null,
					"rawValue": "StabByOption\n(0 - 1 )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StabByOption",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Блокировка дифференциальной защиты с торможением по второй гармонике (отношение второй гармоники к первой) в %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2/I1 ratio",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Принятое (включенное) ответвление обмотки \nвходного ТТ (1А или 5А)",
					"nameFromTargetFile": null,
					"rawValue": "Input CT Tap \n(1A, 5A)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Input CT Tap",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток высоковольтного (защитного) ТТ.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CT prim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток высоковольтного (защитного) ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CT sec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток первичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение первичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток вторичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение вторичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность первичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток первичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение первичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность вторичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток вторичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение вторичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность третичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток третичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение третичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur3",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens",
			"name": "ДЗТ",
			"fileName": "ДЗТ_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "ДиффЗащита",
					"nameFromTargetFile": null,
					"rawValue": "112 (Введено /Выведено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ДиффЗащита",
					"nameFromTargetFile": null,
					"rawValue": "1201 (ОТКЛ/ВКЛ/ РелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "I-Дифф>>,I/Iн0",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1231",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "I-Дифф>,I/Iн0",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1221",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "УголНаклона1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1241A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовая Точка1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1242A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "УголНаклона2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1243A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовая Точка2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1244A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Содержание 2 гармоники в токе I-Дифф",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1271",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торм.ток при броске тока намагнич. По 2 гарм.",
					"nameFromTargetFile": null,
					"rawValue": "1206 (ВКЛ/ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1206",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Соединение обмоток трансф. стороны 1",
					"nameFromTargetFile": null,
					"rawValue": "314 (Звезда (У)/ Треугольник (D)/ Зигзаг (Z))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "314",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Соединение обмоток трансф. стороны 2",
					"nameFromTargetFile": null,
					"rawValue": "324 (Звезда (У)/ Треугольник (D)/ Зигзаг (Z))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "324",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Соединение обмоток трансф. стороны 3",
					"nameFromTargetFile": null,
					"rawValue": "334 (Звезда (У)/ Треугольник (D)/ Зигзаг (Z))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "334",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Соединение обмоток трансф. стороны 4",
					"nameFromTargetFile": null,
					"rawValue": "344 (Звезда (У)/ Треугольник (D)/ Зигзаг (Z))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "344",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Соединение обмоток трансф. стороны 5",
					"nameFromTargetFile": null,
					"rawValue": "354 (Звезда (У)/ Треугольник (D)/ Зигзаг (Z))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "354",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 1 трансф-ра, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 2 трансф-ра, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "322",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 3 трансф-ра,МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "332",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 4 трансф-ра, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "342",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 5 трансф-ра, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "352",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 2,кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "321",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 3,кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "331",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 4,кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "341",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 5,кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "351",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "512",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 1,А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "513",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 2,А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "522",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "523",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 3, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "532",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 3, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "533",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 4, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "542",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 4, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "543",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 5, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "552",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 5, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "553",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика ТОР 300 ДЗАТ 620",
			"name": "ЗП",
			"fileName": "ЗП_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока стороны ВН, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока стороны ОО, % IтермОО",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabOO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО тока стороны НН, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrabNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания защиты от перегрузки, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ОО, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervOO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ОО, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorOO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ОО, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermOO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны НН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны НН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны НН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermNN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра 2710 542",
			"name": "ЗП",
			"fileName": "ЗП_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ЗП стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrZPVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ЗП стороны общей обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrZPOO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ЗП стороны НН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrZPNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка на срабатывание ЗП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT25",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Защита от перегрузки по стороне ВН",
					"nameFromTargetFile": null,
					"rawValue": "ХВ42\n(0 - не предусмотрена/\n1 - предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XB42",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Защита от перегрузки по стороне общей обмотки",
					"nameFromTargetFile": null,
					"rawValue": "ХВ43\n(0 - не предусмотрена/\n1 - предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XB43",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Защита от перегрузки по стороне НН",
					"nameFromTargetFile": null,
					"rawValue": "ХВ44\n(0 - не предусмотрена/\n1 - предусмотрена)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "XB44",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB RET521",
			"name": "ЗП",
			"fileName": "ЗП_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperationVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDefVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHighVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHighVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperationOO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDefOO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHighOO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHighOO",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Активизация функции",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "OperationNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDefNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHighNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHighNN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7UT613",
			"name": "ЗП",
			"fileName": "ЗП_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Защита от термической перегрузки",
					"nameFromTargetFile": null,
					"rawValue": "4201\n(ВКЛ/ ОТКЛ/Реле блокировано/Только сигнал)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току сигнальной ступени защиты от перегрузки, I/IнС",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4205",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 1 (линейное), кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 1, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный номинальный ток ТТ точки измерения 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "512",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичный номинальный ток точки измерения 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "513",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика ТОР 300 ДЗТ 512",
			"name": "МТЗ",
			"fileName": "МТЗ_ВН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 3 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск по напряжению 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3U\n(0 – вывод; 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 3 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_VN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра ШЭ2607 041",
			"name": "МТЗ",
			"fileName": "МТЗ_ВН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystVN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ ВН 2 ступень (СВ СН и НН вкл.), с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystVN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск МТЗ ВН по напряжению",
					"nameFromTargetFile": null,
					"rawValue": "Пуск МТЗ ВН по U \n(0 - предусмотрен / 1 - не предусмотрен)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PuskMTZVNpoU",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение переменного тока, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН ВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtnVN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB RET521",
			"name": "МТЗ",
			"fileName": "МТЗ_ВН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация трехфазной МТЗ с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Выкл/1 - Вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени низкой ступени, в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное время срабатывания в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tMin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность низкой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionLow \n(0 - Ненаправл/ \n1- в прямом направлении / \n2 - в обратном \nнаправлении )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность высокой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionHigh\n(0 - Ненаправл / \n1 - в прямом направлении / \n2 - в обратном \nнаправлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристический угол реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "rca",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол срабатывания реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "roa",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток первичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtsec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ctsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7UT612x",
			"name": "МТЗ",
			"fileName": "МТЗ_ВН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ с незав./инвер.выд.времени",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "120",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "2001\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2011",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2012",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2013",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2015",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2016",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ Ip",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2021",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ Ip",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2022",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ Ip",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2023",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф.времени D ст.Ip",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2024",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Времятоковая характеристика возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2025",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характер МЭК",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2026",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характер ANSI",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2027",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первич. напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "240",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "203",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 1 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 2, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "321",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 2 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "322",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 3, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "331",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 3 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "332",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика ТОР 300 ДЗТ 512",
			"name": "МТЗ",
			"fileName": "МТЗ_НН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермНН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермНН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 3 ступени, % IтермНН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности ОНМ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод автоматического ускорения 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nуск1\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль направления мощности 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр3\n(0 – без контроля мощности, 1 – направление в объект, \n2 – направление в сеть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск по напряжению 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3U\n(0 – вывод; 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 3 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время ввода автоматического ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_NN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН стороны НН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН стороны НН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение входа терминала стороны НН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра ШЭ2607 041",
			"name": "МТЗ",
			"fileName": "МТЗ_НН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Сторона трансформатора №3 (НН1)",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН1\n(0 - нет, 1 - есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ НН1-1  ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystNN1-1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ НН1-2  ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystNN1-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН1-1 ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystNN1-1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН1-2  ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystNN1-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск МТЗ НН1 по напряжению",
					"nameFromTargetFile": null,
					"rawValue": "Пуск МТЗ НН1 поU\n(0 - предусмотрен / 1 - не предусмотрен)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PuskMTZNN1poU",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания мин. реле пуска по напряжению НН1, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UNN1min",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания реле обратной послед. НН1, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2NN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "РНМПП для МТЗ НН1",
					"nameFromTargetFile": null,
					"rawValue": "Напр-тьМТЗ НН1\n(0 - предусмотрено / 1 - не предусмотрено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprMTZNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направление РНМПП НН1",
					"nameFromTargetFile": null,
					"rawValue": "Напр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprRNMNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол макс. чувствительности РНМПП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UgolMaxChyvNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН1 с ускорением, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TMTZNN1uskQ3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Прием сигнала ''KQT Q3 (НН1)'' по входу",
					"nameFromTargetFile": null,
					"rawValue": "Вх",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VhKQTQ3NN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона трансформатора №4 (НН2)",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН2\n(нет, есть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ НН2-1  ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystNN2-1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ НН2-2  ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystNN2-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН2-1 ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystNN2-1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН2-2  ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystNN2-2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск МТЗ НН2 по напряжению",
					"nameFromTargetFile": null,
					"rawValue": "Пуск МТЗ НН2 поU\n(0 - предусмотрен / 1 - не предусмотрен)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PuskMTZNN2poU",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания мин. реле пуска по напряжению НН2, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UNN2min",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания реле обратной послед. НН2, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2NN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "РНМПП для МТЗ НН2",
					"nameFromTargetFile": null,
					"rawValue": "Напр-тьМТЗ НН2\n(0 - предусмотрено / 1 - не предусмотрено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprMTZNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направление РНМПП НН2",
					"nameFromTargetFile": null,
					"rawValue": "Напр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprRNMNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол макс. чувствительности РНМПП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UgolMaxChyvNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ НН2 с ускорением, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TMTZNN2usk",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Прием сигнала ''KQT Q4 (НН2)'' по входу",
					"nameFromTargetFile": null,
					"rawValue": "Вх",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VhKQTQ4NN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение переменного тока, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН НН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtnNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KttNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН НН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtnNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KttNN2",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB RET521",
			"name": "МТЗ",
			"fileName": "МТЗ_НН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация трехфазной МТЗ с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Выкл/1 - Вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени низкой ступени, в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное время срабатывания в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tMin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность низкой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionLow \n(0 - Ненаправл/ \n1 - в прямом направлении / \n2 - в обратном \nнаправлении )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность высокой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionHigh\n(0 - Ненаправл / \n1 - в прямом направлении / \n2 - в обратном \nнаправлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристический угол реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "rca",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол срабатывания реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "roa",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток третиччной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtsec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ctsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7UT612x",
			"name": "МТЗ",
			"fileName": "МТЗ_НН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "2001\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2011",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2012",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2013",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первич. напряжение стороны 2, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "243",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ стороны 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "207",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ стороны 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "208",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика ТОР 300 ДЗТ 512",
			"name": "МТЗ",
			"fileName": "МТЗ_СН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермCН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермCН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 3 ступени, % IтермСН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол максимальной чувствительности ОНМ, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод автоматического ускорения 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nуск1\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль направления мощности 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nнапр3\n(0 – без контроля мощности, 1 – направление в объект, \n2 – направление в сеть)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск по напряжению 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3U\n(0 – вывод; 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 3 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время ввода автоматического ускорения, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "MTZ_SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН стороны СН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН стороны СН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны СН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны СН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nominal",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра ШЭ2607 041",
			"name": "МТЗ",
			"fileName": "МТЗ_СН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ СН-1 ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystSN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания МТЗ СН-2 ступень, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystSN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ СН 1 ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystSN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ СН 2 ступень, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TystSN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск МТЗ СН по напряжению",
					"nameFromTargetFile": null,
					"rawValue": "Пуск МТЗ СН поU\n(0 - предусмотрен / 1 - не предусмотрен)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "PuskMTZSNpoU",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания мин. реле пуска по напряжению СН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "USNmin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания реле обратной послед. СН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "РНМПП для МТЗ СН",
					"nameFromTargetFile": null,
					"rawValue": "Напр-тьМТЗ СН\n(0 - предусмотрено / 1 - не предусмотрено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprMTZSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направление РНМПП СН",
					"nameFromTargetFile": null,
					"rawValue": "Напр",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NaprRNMSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол макс. чувствительности РНМПП, градус",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UgolMaxChyv",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания МТЗ СН с ускорением на отключение Q3, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "TMTZSNuskQ3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Прием сигнала ''KQT Q3 (СН)'' по входу",
					"nameFromTargetFile": null,
					"rawValue": "Вх",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VhKQTQ3SN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное напряжение переменного тока, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Unom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток Iном, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТН CН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtnSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ СН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KttSN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB RET521",
			"name": "МТЗ",
			"fileName": "МТЗ_СН_330_Т(АТ)",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация трехфазной МТЗ с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - Выкл/1 - Вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени в % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени низкой ступени, в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное время срабатывания в секундах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tMin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность низкой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionLow \n(0 - Ненаправл/ \n1- в прямом направлении / \n2 - в обратном \nнаправлении )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Направленность высокой ступени",
					"nameFromTargetFile": null,
					"rawValue": "DirectionHigh\n(0 - Ненаправл / \n1 - в прямом направлении / \n2 - в обратном \nнаправлении)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DirectionHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристический угол реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "rca",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Угол срабатывания реле, в градусах",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "roa",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток вторичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ТН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ТН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Vtsec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ctsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7SA",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_330_Т(АТ)_7SA",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ от КЗ на землю",
					"nameFromTargetFile": null,
					"rawValue": "122\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристика для токовой защиты от КЗ \nна землю",
					"nameFromTargetFile": null,
					"rawValue": "113\n(Выведено/\nНезависим выдержка/\nМТЗ хар-ка МЭК/\nМТЗ хар-ка ANSI/\nХар-ка пользов/\nХарВозв Польз)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "113",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1304",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1305",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1302",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1303",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1317",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1318",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IEр, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1307",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для МЭК  характеристик, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1308",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для ANSI  характеристик, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1309",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристики МЭК",
					"nameFromTargetFile": null,
					"rawValue": "1311\n(Норм-инверсная/\nСильно-инверсная/\nПредел-инверсная/\nДлит-инверсная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристики ANSI",
					"nameFromTargetFile": null,
					"rawValue": "1312\n(Сильно-инверсная/\nИнверсная/\nСокращ-инверсная/\nДлит-инверсная/\nУмерен-инверсная/\nПредел-инверсная/\nОпределенно-инверсная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение при броске тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение при броске тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "122 \n(Выведено/ Введено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка составляющей 2-й гармоники для определения бросков тока намагничивания, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первич. напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "240",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "203",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 1 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 2, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "321",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 2 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "322",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение стороны 3, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "331",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная полная мощность стороны 3 трансф, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "332",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика ТОР 300 РЗТ 526",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_330_Т(АТ)_7SJ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО утроенного тока нулевой последовательности, % 3I0терм",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0sr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС на отключение выключателя ВН смежного трансформатора, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsr1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС на деление шин ВН, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsr2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС на отключение выключателя ВН, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsr3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС на отключение трансформатора, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsr4",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка блокировки по второй гармонике, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kf2f1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод блокировки по второй гармонике",
					"nameFromTargetFile": null,
					"rawValue": "NвводБВГ\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NvvodBVG",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток 3I0 ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0pervVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток 3I0 ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0vtorVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток 3I0 входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVN3I0",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра 2607 073...158",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_330_Т(АТ)_7SJ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО ТЗНП, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrTZNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT11 Задержка на срабатывание ТЗНП в защиту Т2, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT10 Задержка на отключение ШСВ, СВ от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT13 Задержка на отключение выключателя от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT13",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT14 Задержка на отключение трансформатора от ТЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT14",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ ВН, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "ABB RET521",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_330_Т(АТ)_7SJ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация защиты от замыканий на землю с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDef",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Временная характеристика для TEFх",
					"nameFromTargetFile": null,
					"rawValue": "CurveType\n(0 - DEF - независимая/\n1 - NI - нормально инверсная/\n2 - VI - очень инверсная/\n3 - EI - чрезвычайно инверсная/\n4 - LI - длительно инверсная/\n5 - LOG - логарифмическая (RXIDG))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CurveType",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для низкой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для обратнозависимой выдержки времени",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "K",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение низкой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harLow\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение высокой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harHigh\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отношение второй гармоники к первой, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2/I1 ratio",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальныйвторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7SJ62-64х",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_330_Т(АТ)_7SJ",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ от КЗ на землю",
					"nameFromTargetFile": null,
					"rawValue": "1301\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1301",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристика для токовой защиты от КЗ \nна землю",
					"nameFromTargetFile": null,
					"rawValue": "113\n(Выведено/\nНезависим выдержка/\nМТЗ хар-ка МЭК/\nМТЗ хар-ка ANSI/\nХар-ка пользов/\nХарВозв Польз)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "113",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1304",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1305",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1302",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1303",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IE>>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1317",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ IE>>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1318",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ IEр, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1307",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для МЭК  характеристик, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1308",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для ANSI  характеристик, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1309",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристики МЭК",
					"nameFromTargetFile": null,
					"rawValue": "1311\n(Норм-инверсная/\nСильно-инверсная/\nПредел-инверсная/\nДлит-инверсная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристики ANSI",
					"nameFromTargetFile": null,
					"rawValue": "1312\n(Сильно-инверсная/\nИнверсная/\nСокращ-инверсная/\nДлит-инверсная/\nУмерен-инверсная/\nПредел-инверсная/\nОпределенно-инверсная)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение при броске тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение при броске тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "122 \n(Выведено/ Введено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка составляющей 2-й гармоники для определения бросков тока намагничивания, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный номинальный ток ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "204",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичный номинальный ток ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "205",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Siemens 7UT",
			"name": "ТЗНП",
			"fileName": "ТЗНП_ВН_330_Т(АТ)_7UT",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ 3I0 с НВВ/ИВВ",
					"nameFromTargetFile": null,
					"rawValue": "122\n(Выведено\nНезависим Выд\nМТЗ Хар-каМЭК\nМТЗ Хар-каANSI\nХар-ка Пользов\nХарВозв Польз)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "122",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "МТЗ Нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ\nОТКЛ\nРелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отстройка от броска тока намагн. МТЗ 3I0",
					"nameFromTargetFile": null,
					"rawValue": "2202\n(ВКЛ\nОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени 3I0>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2211",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току 3I0>>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2212",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени 3I0>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2213",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени 3I0>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2214",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току 3I0>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2215",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ 3I0>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2216",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени 3I0р, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2221",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току 3I0p, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2222",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени 3I0р, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2223",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэфф. времени ступени 3I0р",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2224",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Хар-ка возврата МТЗ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2225",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристические кривые МЭК",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2226",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Характеристические кривые ANSI",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2227",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отстройка от броска тока намагн. МТЗ 3I0",
					"nameFromTargetFile": null,
					"rawValue": "2202\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Составл. 2-й гармон. МТЗ 3I0, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2241",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первичное напряжение стороны 1, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "311",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 1 трансф., МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "312",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первичное напряжение стороны 2, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "321",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 2 трансф., МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "322",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. первичн. напряжение стороны 3, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "331",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номин. полная мощность стороны 3 трансф., МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "332",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный рабочий номин. ток Стороны 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "372",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный рабочий номин. ток Стороны 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "373",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный рабочий номин. ток Стороны 3, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "374",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Релематика",
			"name": "УРОВ",
			"fileName": "УРОВ_СН_330_Т(АТ)",
			"setpointsFromReferenceFile": []
		},
		{
			"voltageLevel": "330-750 кВ",
			"bayType": "Трансформатор",
			"vendor": "Экра",
			"name": "УРОВ",
			"fileName": "УРОВ_СН_330_Т(АТ)",
			"setpointsFromReferenceFile": []
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Управляемый шунтирующий реактор",
			"vendor": "Релематика ТОР 300 БСК",
			"name": "МТЗ",
			"fileName": "МТЗ_КОР_УШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермНН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермНН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 3 ступени, % IтермНН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N3st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск по напряжению 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3U\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nst3U",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 3 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ИО минимального напряжения, % UтермНН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Umin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ИО максимального напряжения обратной последовательности, % UтермНН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2max",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН стороны НН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UpervNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН стороны НН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UvtorNN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Управляемый шунтирующий реактор",
			"vendor": "Siemens 7UT612x",
			"name": "МТЗ",
			"fileName": "МТЗ_КОР_УШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "2001\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2011",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2012",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2013",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2014",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Управляемый шунтирующий реактор",
			"vendor": "Релематика ТОР 300 УШР",
			"name": "МТЗ",
			"fileName": "МТЗ_НН_УШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермНН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермНН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 3 ступени, % IтермНН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N3st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Пуск по напряжению 3 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст3U\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nst3U",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 3 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ИО минимального напряжения, % UтермНН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Umin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Напряжение срабатывания ИО максимального напряжения обратной последовательности, % UтермНН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "U2max",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны НН2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны НН2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны НН2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное первичное напряжение ИТН стороны НН, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UpervNN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное вторичное напряжение ИТН стороны НН, В",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "UvtorNN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Управляемый шунтирующий реактор",
			"vendor": "Siemens 7UT612x",
			"name": "МТЗ",
			"fileName": "МТЗ_НН_УШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "2001\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2013",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2011",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2012",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Управляемый шунтирующий реактор",
			"vendor": "Релематика ТОР 300 УШР",
			"name": "МТЗ",
			"fileName": "МТЗ_ОУР_УШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермОУ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермОУ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ОУ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervOY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ОУ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorOY",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ОУ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermOY",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Управляемый шунтирующий реактор",
			"vendor": "Siemens 7UT613x",
			"name": "МТЗ",
			"fileName": "МТЗ_ОУР_УШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "3I0 максимальная токовая \nзащита с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ/ ОТКЛ/Блок",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2011 (или 2212)",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2213",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Управляемый шунтирующий реактор",
			"vendor": "Siemens 7UT6",
			"name": "ТЗНП",
			"fileName": "ТЗНП_КОР_УШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ Нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ/ ОТКЛ/РелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ 3I0>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2211",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ 3I0>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2213",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Управляемый шунтирующий реактор",
			"vendor": "Siemens 7UT613",
			"name": "ТЗОП",
			"fileName": "ТЗОП_КОР_УШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Несимметр. нагрузка (обратная последов.)",
					"nameFromTargetFile": null,
					"rawValue": "4001\n(ОТКЛ/ ВКЛ/\nРелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I2>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени I2>, сек",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4016",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Релематика (ТОР 300 БСК 555)",
			"name": "ДЗР",
			"fileName": "ДЗР_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – вывод, 1 – ввод)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток срабатывания ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток торможения 2-го участка ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения 2-го участка ТХ, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kt2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Начальный ток торможения 3-го участка ТХ, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения 3-го участка ТХ, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kt3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания дифференциальной отсечки, % Iбаз",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idto",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Режим работы БВГ",
					"nameFromTargetFile": null,
					"rawValue": "NактБлкf2\n(0-вывод, 1-постоянно, 2-автоматически)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "AktBlk2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка блокировки по второй гармонике, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "K2f1f",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Экра",
			"name": "ДЗР",
			"fileName": "ДЗР_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IystDZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток начала торможения ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "It0DZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток торможения блокировки ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItmaxDZT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент торможения ДТЗ АТ, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "KtDZTAT",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уровень бл. по 2 гармонике, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kblpo2gar",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Дифференциальная отсечка",
					"nameFromTargetFile": null,
					"rawValue": "Диф",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ndifots",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания диф. отсечки, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idifots",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Задержка на срабатывание дифф. отсечки, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VremaDiffOts",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны СН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны НН1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базисный ток стороны НН2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IbazNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №1",
					"nameFromTargetFile": null,
					"rawValue": "Схема ВН",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №2",
					"nameFromTargetFile": null,
					"rawValue": "Схема СН",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №3",
					"nameFromTargetFile": null,
					"rawValue": "Схема НН1",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Схема соединения стороны №4",
					"nameFromTargetFile": null,
					"rawValue": "Схема НН2",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ShemaNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона ВН",
					"nameFromTargetFile": null,
					"rawValue": "Сторона ВН",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона СН",
					"nameFromTargetFile": null,
					"rawValue": "Сторона СН",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaSN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона НН1",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН1",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Сторона НН2",
					"nameFromTargetFile": null,
					"rawValue": "Сторона НН2",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StoronaNN2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Inom",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ ВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_vn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ CН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_sn",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_nn1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент трансформации ТТ НН2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ktt_nn2",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "ABB",
			"name": "ДЗР",
			"fileName": "ДЗР_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация функции дифференциальной защиты трансформатора, Выкл/Вкл",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 - 1)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Векторная группа двухобмоточного трансформатора (1-24), о.е.",
					"nameFromTargetFile": null,
					"rawValue": "VectorGroup 2W (1-24)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VectorGroup 2W",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Векторная группа трехобмоточного трансформатора (1-288), о.е.",
					"nameFromTargetFile": null,
					"rawValue": "VectorGroup 3W (1-288)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "VectorGroup 3W",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вычитание тока нулевой последовательности, Выкл/Вкл",
					"nameFromTargetFile": null,
					"rawValue": "ZSCSub (0-1)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ZSCSub",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номер тормозной характеристики",
					"nameFromTargetFile": null,
					"rawValue": "CharactNo\n(1 - 5)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CharactNo",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальная уставка срабатывания дифференциальной защиты в % от номинального тока опорной стороны Ir (Iном)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idmin",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка дифференциальной защиты без торможения (дифотсечка) в % от Ir (Iном)",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Idunre",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Блокировка по второй гармонике, Условно/Всегда",
					"nameFromTargetFile": null,
					"rawValue": "StabByOption\n(0 - 1 )",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "StabByOption",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Блокировка дифференциальной защиты с торможением по второй гармонике (отношение второй гармоники к первой) в %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2/I1 ratio",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Принятое (включенное) ответвление обмотки \nвходного ТТ (1А или 5А)",
					"nameFromTargetFile": null,
					"rawValue": "Input CT Tap \n(1A, 5A)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Input CT Tap",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток высоковольтного (защитного) ТТ.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CT prim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток высоковольтного (защитного) ТТ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CT sec",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток первичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение первичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток вторичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение вторичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность первичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток первичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение первичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность вторичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток вторичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение вторичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальная мощность третичной обмотки трансформатора, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Sr3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток третичной обмотки, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ir3",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальное междуфазное напряжение третичной обмотки, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ur3",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Siemens (7UT613)",
			"name": "ДЗР",
			"fileName": "ДЗР_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "ДиффЗащита",
					"nameFromTargetFile": null,
					"rawValue": "112 (Введено/Выведено)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "112",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ДиффЗащита",
					"nameFromTargetFile": null,
					"rawValue": "1201 (ОТКЛ/ВКЛ/РелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "I-Дифф>>,I/Iн0",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1231",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "I-Дифф>,I/Iн0",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1221",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "УголНаклона1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1241A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовая Точка1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1242A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "УголНаклона2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1243A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Базовая Точка2",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1244A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Содержание 2 гармоники в токе I-Дифф",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1271",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торм.ток при броске тока намагнич. По 2 гарм.",
					"nameFromTargetFile": null,
					"rawValue": "1206 (ВКЛ/ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1206",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент возврата, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "krb",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Релематика ТОР 300 ШР",
			"name": "МТЗ ВН",
			"fileName": "МТЗ_ВН_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания 1 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания 2 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Ist2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N1st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2 \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "N2st",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DTst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Siemens 7UT613x",
			"name": "МТЗ ВН",
			"fileName": "МТЗ_ВН_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная МТЗ",
					"nameFromTargetFile": null,
					"rawValue": "2001\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2011",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2012",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2013",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ I>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2015",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ I>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2016",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ном. напряжение генератора/двигателя, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "361",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ном.полная мощность генератора/двигателя, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "362",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Перв. номин. ток ТТ точка измерения 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "512",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичн. номин. ток ТТ точка измерения 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "513",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Релематика (ТОР 300 ШР)",
			"name": "Поперечная дифференциальная токовая защита (ПДТЗ)",
			"fileName": "ПДЗР_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания, % Iном",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Время срабатывания, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Экра (ШЭ2710 541)",
			"name": "Поперечная ДТЗ ШР",
			"fileName": "ПДЗР_ШР",
			"setpointsFromReferenceFile": []
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Релематика ТОР 300 ШР 651",
			"name": "ТЗНП ВН",
			"fileName": "ТЗНП ВН_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО утроенного тока нулевой последовательности 1 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0st1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО утроенного тока нулевой последовательности 2 ступени, % IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0st2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка блокировки по второй гармонике, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kf2f1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Экра 2607 073...158",
			"name": "ТЗНП ВН",
			"fileName": "ТЗНП ВН_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО ТЗНП, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrTZNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT11 Задержка на срабатывание ТЗНП в защиту Т2, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT10 Задержка на отключение ШСВ, СВ от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT13 Задержка на отключение выключателя от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT13",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT14 Задержка на отключение трансформатора от ТЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT14",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ ВН, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "ABB RET521",
			"name": "ТЗНП ВН",
			"fileName": "ТЗНП ВН_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация защиты от замыканий на землю с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDef",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Временная характеристика для TEFх",
					"nameFromTargetFile": null,
					"rawValue": "CurveType\n(0 - DEF - независимая/\n1 - NI - нормально инверсная/\n2 - VI - очень инверсная/\n3 - EI - чрезвычайно инверсная/\n4 - LI - длительно инверсная/\n5 - LOG - логарифмическая (RXIDG))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CurveType",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для низкой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для обратнозависимой выдержки времени",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "K",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение низкой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harLow\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение высокой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harHigh\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отношение второй гармоники к первой, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2/I1 ratio",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальныйвторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Siemens 7UT6",
			"name": "ТЗНП ВН",
			"fileName": "ТЗНП ВН_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ Нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ/ ОТКЛ/РелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ 3I0>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2214",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току 3I0>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2215",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ 3I0>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2216",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ 3I0>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2211",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току 3I0>>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2212",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ 3I0>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2213",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отстройка от броска тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "2202\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка составляющей 2-й гармоники для определения бросков тока намагничивания, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2241",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ном. напряжение генератора/двигателя, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "361",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ном.полная мощность генератора/двигателя, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "362",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Релематика ТОР 300 ШР 651",
			"name": "ТЗНП НВ",
			"fileName": "ТЗНП НВ_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ввод 1 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст1\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод 2 ступени",
					"nameFromTargetFile": null,
					"rawValue": "Nст2\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ввод защиты",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО утроенного тока нулевой последовательности 1 ступени, % IтермВНР1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0st1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ток срабатывания ИО утроенного тока нулевой последовательности 2 ступени, % IтермВНР1",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "3I0st2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 1 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tst1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "ВВС 2 ступени, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tst2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка блокировки по второй гармонике, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Kf2f1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны расшепленной обмотки 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervVNR1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны расшепленной обмотки 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorVNR1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны расшепленной обмотки 1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVNR1",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны расшепленной обмотки 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervVNR2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны расшепленной обмотки 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorVNR2",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны расшепленной обмотки 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVNR2",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Экра 2607 073...158",
			"name": "ТЗНП НВ",
			"fileName": "ТЗНП НВ_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Ток срабатывания ПО ТЗНП, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsrTZNP",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT11 Задержка на срабатывание ТЗНП в защиту Т2, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT11",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT10 Задержка на отключение ШСВ, СВ от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT10",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT13 Задержка на отключение выключателя от ТЗНП, c",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT13",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "DT14 Задержка на отключение трансформатора от ТЗНП, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "DT14",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ ВН, А ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Iprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Isec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "ABB RET521",
			"name": "ТЗНП НВ",
			"fileName": "ТЗНП НВ_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Активизация защиты от замыканий на землю с выдержкой времени",
					"nameFromTargetFile": null,
					"rawValue": "Operation\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток, определяемый пользователем, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IrUserDef",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка низкой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка высокой ступени, % от Ir",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IsetHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Временная характеристика для TEFх",
					"nameFromTargetFile": null,
					"rawValue": "CurveType\n(0 - DEF - независимая/\n1 - NI - нормально инверсная/\n2 - VI - очень инверсная/\n3 - EI - чрезвычайно инверсная/\n4 - LI - длительно инверсная/\n5 - LOG - логарифмическая (RXIDG))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CurveType",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для низкой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Независимая выдержка времени для высокой ступени, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "tDefHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Коэффициент времени для обратнозависимой выдержки времени",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "K",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение низкой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harLow\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harLow",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Торможение высокой ступени по 2-ой гармонике",
					"nameFromTargetFile": null,
					"rawValue": "2harHigh\n(0 – выкл, 1 – вкл)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2harHigh",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отношение второй гармоники к первой, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "I2/I1 ratio",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTprim",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальныйвторичный ток ТТ, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "CTsec",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Siemens 7UT6",
			"name": "ТЗНП НВ",
			"fileName": "ТЗНП НВ_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "МТЗ Нулевой последовательности",
					"nameFromTargetFile": null,
					"rawValue": "2201\n(ВКЛ/ ОТКЛ/РелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2201",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ 3I0>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2214",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току 3I0>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2215",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ 3I0>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2216",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени МТЗ 3I0>>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2211",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току 3I0>>, о.е.",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2212",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени МТЗ 3I0>>, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2213",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Отстройка от броска тока намагничивания",
					"nameFromTargetFile": null,
					"rawValue": "2202\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2202",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка составляющей 2-й гармоники для определения бросков тока намагничивания, %",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "2241",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ном. напряжение генератора/двигателя, кВ",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "361",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Ном.полная мощность генератора/двигателя, МВА",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "362",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ТТ стороны 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "207",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ТТ стороны 2, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "208",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "ABB",
			"name": "ТЗОП",
			"fileName": "ТЗОП_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Значение пуска",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Start Value",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель для масштабирования  значения пуска",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Start value Mult",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Множитель времени характеристик МЭК/ANSI",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Time multiplier",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени на срабатывание, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operate delay time",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выбор типа характеристики срабатывания",
					"nameFromTargetFile": null,
					"rawValue": "Тип кривой срабат",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operating curve type",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выбор типа характеристики возврата",
					"nameFromTargetFile": null,
					"rawValue": "Тип кривой возврата [Type of reset curve]\n(1=Мгновенная (Immediate) (токовая отсечка) \n2=Независимая (Def time reset) (независимая вы-\nдержка времени ) \n3=Инверсная (Inverse reset) (Возврат с инверсной выдержкой времени))",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Type of reset curve",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Срабатывание",
					"nameFromTargetFile": null,
					"rawValue": "Активизация (Operation ) \n(1=on \n5=off)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Operation",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Минимальное время срабатывания для характеристики МЭК IDMT, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Minimum operate time",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени на возврат",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Reset delay time",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр A для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve parameter A",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр В для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve parameter B",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр С для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve arameter С",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр D для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": "Параметр кривой D (Curve \nparameter D)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve arameter D",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Параметр E для характеристики, программируемой пользователем",
					"nameFromTargetFile": null,
					"rawValue": "Параметр кривой E (Curve \nparameter E)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Curve parameter E",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Siemens 7UT613",
			"name": "ТЗОП",
			"fileName": "ТЗОП_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Несимметр. нагрузка (обратная последов.)",
					"nameFromTargetFile": null,
					"rawValue": "4001\n(ОТКЛ/ВКЛ/РелеБлокировано)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Уставка по току ступени I2>, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4014",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени ступени I2>, сек",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "4016",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Релематика ТОР 300 ШР",
			"name": "УРОВ",
			"fileName": "УРОВ_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Работа УРОВ",
					"nameFromTargetFile": null,
					"rawValue": "Nввод\n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Nvvod",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Контроль РПВ при действии УРОВ на смежный \nвыключатель",
					"nameFromTargetFile": null,
					"rawValue": "NконтрРПВ \n(0 – нет, 1 – да)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "NсontrRPV",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Фазный ток УРОВ, % от IтермВН",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Israb",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Замедление отключения смежных выключателей, мс",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "Tsrab",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный первичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IpervVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный вторичный ток ИТТ стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "IvtorVN",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Номинальный ток входа терминала стороны ВН, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "ItermVN",
					"id": null,
					"value": "null"
				}
			]
		},
		{
			"voltageLevel": "6-750 кВ",
			"bayType": "Шунтирующий реактор",
			"vendor": "Siemens 7UT613x",
			"name": "УРОВ",
			"fileName": "УРОВ_ШР",
			"setpointsFromReferenceFile": [
				{
					"nameFromReferenceFile": "Фазная УРОВ",
					"nameFromTargetFile": null,
					"rawValue": "7001\n(ВКЛ/ ОТКЛ)",
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": true,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7001",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Порог тока разомкнутого полюса ТТ М1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "1121",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Выдержка времени Т2, с",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "7016",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Первичный номинальный ток ТТ М1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "512",
					"id": null,
					"value": "null"
				},
				{
					"nameFromReferenceFile": "Вторичный номинальный ток ТТ М1, А",
					"nameFromTargetFile": null,
					"rawValue": null,
					"valueFromReferenceFile": "null",
					"dimension": null,
					"isLogical": false,
					"idFromTargetFile": null,
					"idFromReferenceFile": "513",
					"id": null,
					"value": "null"
				}
			]
		}
	]
}