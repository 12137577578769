import React from 'react';
import { FormattedMessage } from 'react-intl';
import listener from 'react-contextmenu/modules/globalEventListener';
import { ContextMenu, MenuItem, SubMenu } from 'react-contextmenu';
import { MENU_ID } from '../../constants/navtree';
import { SRCTREE } from '../../constants/srctree';
import { openHistory } from '../../services/srceditor';

const ADDITIONAL_TITLE = <FormattedMessage
    id="SRCTREE_MENU_ADDITIONAL_ITEM"
    defaultMessage="Additionally..."
    description="User should click this item to open additional items" />;

const ADD_TITLE = <span>
    <i className="fa fa-plus-circle fa-fw"></i>
    <FormattedMessage
        id="SRCTREE_MENU_ADD_ITEM"
        defaultMessage="Add"
        description="User should click this item to add tree node" />
</span>;

const mimeTypes = {
    "application/x-directory": (<FormattedMessage id="SRCETREE_MIME_DIRECTORY"
        defaultMessage="Add directory"
        description="Type of file to be added to source tree: application/x-directory" />),
    "text/plain": (<FormattedMessage id="SRCETREE_MIME_PLAIN"
        defaultMessage="Add ordinary text file"
        description="Type of file to be added to source tree: text/plain" />),
    "text/html": (<FormattedMessage id="SRCETREE_MIME_HTML"
        defaultMessage="Add HTML Page"
        description="Type of file to be added to source tree: text/html" />),
    "text/css": (<FormattedMessage id="SRCETREE_MIME_CSS"
        defaultMessage="Add css file"
        description="Type of file to be added to source tree: text/css" />),
    "text/xml": (<FormattedMessage id="SRCETREE_MIME_XML"
        defaultMessage="Add XML file"
        description="Type of file to be added to source tree: text/xml" />),
    "application/javascript": (<FormattedMessage id="SRCETREE_MIME_JAVASCRIPT"
        defaultMessage="Add JavaScript file"
        description="Type of file to be added to source tree: application/javascript" />),
    "application/json": (<FormattedMessage id="SRCETREE_MIME_JSON"
        defaultMessage="Add JSON file"
        description="Type of file to be added to source tree: application/json" />)
};

function getLabelOfMime(type) {
    if (typeof mimeTypes[type] != 'undefined') {
        return mimeTypes[type];
    }
    return (<FormattedMessage id="SRCETREE_MIME_UNKNOWN"
        defaultMessage="Add file of {type}"
        description="Type unknown file type" values={{ type }} />);
}

class SrcMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = { node: null };
        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.collect = this.collect.bind(this);
        this.menuId = MENU_ID + SRCTREE;
    }

    componentDidMount() {
        this.listenId = listener.register(this.handleShow, this.handleHide);
    }

    componentWillUnmount() {
        if (this.listenId) {
            listener.unregister(this.listenId);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.node && this.state.node.loading) { //Try to get new version of node
            const node = nextProps.nodeById[this.state.node.id];
            console.log("Try to find new node in tree: ", node);
            this.setState({ node });
        }
    }

    handleShow(e) {
        console.log("Context menu (handle show): ", e.detail.id, this.menuId);
        if (e.detail.id !== this.menuId)
            return;
        const node = this.props.nodeById[e.detail.data.nodeId];
        if (node && node.loading) {
            //Alway try to load subtree
            this.props.fetchSubTree(node.id);
        }
        this.setState({ node });
    }

    handleHide() {
        this.setState({ node: null });
    }

    collect(data, isCopy) {
        console.log("Collected: ", data, " is copy: ", isCopy);
        this.setState({ collected: data, isCopy });
    }

    generateAddSubMenu() {
        //Get current state of node
        const node = this.state.node;
        if (!node.directory) {
            return null;
        }
        return (
            <SubMenu title={ADD_TITLE}>
                {this.props.sourceTypes.map((mime) =>
                    <MenuItem key="addFile" onClick={this.props.addFile.bind(this, this.state.node, mime)}>
                        {getLabelOfMime(mime)}
                    </MenuItem>
                )}
            </SubMenu>);
    }

    generateRenameSubMenu() {
        /* Root node */
        if (!this.state.node.id) {
            return null;
        }
        return (
            <MenuItem key="rename" onClick={this.props.renameFile.bind(this, this.state.node)}>
                <span>
                    <i className="fa fa-pencil fa-fw"></i>
                    <FormattedMessage
                        id="SRCTREE_MENU_RENAME_ITEM"
                        defaultMessage="Rename"
                        description="User should click this item to rename tree node" />
                </span>
            </MenuItem>);
    }

    generatePasteSubMenu() {
        if (!this.state.collected) {
            return null;
        }
        if (this.state.isCopy && !this.props.getCopyAction) {
            return null;
        }
        if (!this.state.isCopy && !this.props.getCutAction) {
            return null;
        }
        //Get current state of node
        const node = this.state.node;
        let pasteAction = null;
        if (this.state.isCopy) {
            pasteAction = this.props.getCopyAction(this.state.collected, node);
        } else {
            pasteAction = this.props.getCutAction(this.state.collected, node);
        }

        if ($.type(pasteAction) != 'object') {
            return null;
        }
        return (
            <MenuItem key="paste" onClick={pasteAction.onAction}>
                <span>
                    <i className="fa fa-clipboard fa-fw"></i>
                    <FormattedMessage
                        id="SRCTREE_MENU_PASTE_ITEM"
                        defaultMessage="Paste"
                        description="User should click this item to paste tree node" />
                </span>
            </MenuItem>);
    }

    generateCopySubMenu() {
        if (!this.state.node.id || !this.props.getCollectNodeAction) {
            return null;
        }
        let copyAction = this.props.getCollectNodeAction(this.state.node, this.collect, true);
        if ($.type(copyAction) != 'object') {
            return null;
        }
        return (
            <MenuItem key="copy" onClick={copyAction.onAction}>
                <span>
                    <i className="fa fa-clone fa-fw"></i>
                    <FormattedMessage
                        id="SRCTREE_MENU_COPY_ITEM"
                        defaultMessage="Copy"
                        description="User should click this item to copy tree node" />
                </span>
            </MenuItem>);
    }

    generateCutSubMenu() {
        if (!this.state.node.id || !this.props.getCollectNodeAction) {
            return null;
        }
        //Get current state of node
        let cutAction = this.props.getCollectNodeAction(this.state.node, this.collect, false);
        if ($.type(cutAction) != 'object') {
            return null;
        }
        return (
            <MenuItem key="cut" onClick={cutAction.onAction}>
                <span>
                    <i className="fa fa-scissors fa-fw"></i>
                    <FormattedMessage
                        id="SRCTREE_MENU_CUT_ITEM"
                        defaultMessage="Cut"
                        description="User should click this item to cut tree node" />
                </span>
            </MenuItem>);
    }

    export(node) {
        window.location.assign(this.props.exportUrl + "?path=" + node.path + (node.directory ? "/" : ""));
    }

    generateExportSubMenu() {
        return (
            <MenuItem key="export" onClick={this.export.bind(this, this.state.node)}>
                <span>
                    <em className="fa fa-fw fa-upload"></em>
                    <FormattedMessage id="SRCTREE_MENU_EXPORT_ITEM"
                        defaultMessage="Export"
                        description="User should click this item to export tree node" />
                </span>
            </MenuItem>);
    }

    history(node) {
        openHistory(this.props.treeId, node.path + (node.directory ? "/" : ""), this.props.openModal);
    }

    generateHistorySubMenu() {
        return (
            <MenuItem key="history" onClick={this.history.bind(this, this.state.node)}>
                <span>
                    <i className="fa fa-history fa-fw"></i>
                    <FormattedMessage id="SRCTREE_MENU_HISTORY"
                        defaultMessage="History"
                        description="User should click this item to get node history" />
                </span>
            </MenuItem>);
    }

    generateRemoveSubMenu() {
        /* Root node */
        if (!this.state.node.id) {
            return null;
        }
        return (
            <MenuItem key="remove" onClick={this.props.removeNode.bind(this, this.state.node)}>
                <span>
                    <i className="fa fa-trash fa-fw"></i>
                    <FormattedMessage
                        id="SRCTREE_MENU_REMOVE_ITEM"
                        defaultMessage="Remove"
                        description="User should click this item to remove tree node" />
                </span>
            </MenuItem>);
    }

    generateAdditionalSubMenu() {
        /**.clipboard class is from foundation/app.jsx */
        return (
            <SubMenu title={ADDITIONAL_TITLE}>
                <MenuItem key={"copyPath"}>
                    <span className="clipboard" data-clipboard-text={this.state.node.path}>
                        <FormattedMessage
                            id="SRCTREE_MENU_COPY_PATH"
                            defaultMessage="Copy node path"
                            description="User should click this item to copy node path" />
                    </span>
                </MenuItem>
            </SubMenu>);
    }

    render() {
        return (
            <ContextMenu id={this.menuId}>
                {this.state.node && this.generateAddSubMenu()}
                {this.state.node && this.generateRenameSubMenu()}
                {this.state.node && this.generatePasteSubMenu()}
                {this.state.node && this.generateCopySubMenu()}
                {this.state.node && this.generateCutSubMenu()}
                {this.state.node && this.generateExportSubMenu()}
                {this.state.node && this.generateHistorySubMenu()}
                {this.state.node && this.generateRemoveSubMenu()}
                {this.state.node && this.generateAdditionalSubMenu()}
            </ContextMenu>)
    }
}

export default SrcMenu;

