import React from 'react';
import { Grid } from 'react-virtualized';
import { FormattedMessage } from 'react-intl';

import Header from './header.jsx';

import CimFiller from '../filler/cimfiller.jsx';
import { printLoading, printError, sortTable, filterTable, filterRows, sortRows } from '../../services/table';
import { OVERSCAN_ROW_COUNT, DEFAULT_ROW_HEIGHT, VERTICAL, HORIZONTAL } from '../../constants/table';

const minColumnWidth = 40;

const gridInitialCls = "npt-table-list text-dark";

const DIRECT_ORDER = -1;
const REVERSE_ORDER = 1;

class Table extends React.PureComponent {

    constructor(props) {
        super(props);

        this.scrollLeft = 0;
        this.actualWidth = 1000;
        if (this.props.header && this.props.header.length != 0) {
            this.actualWidth = this.getActualWidth();
            this.columnsWidth = this.getColumnsWidth();
        }
        this.hoveredScrollers = {
            [VERTICAL]: false,
            [HORIZONTAL]: false
        };
        this.state = {
            data: props.data,
            columns: props.header,
            selectedRows: props.selectedRows || { length: 0 }
        };

        this.cellRenderer = this.cellRenderer.bind(this);
        this.getCellClass = this.getCellClass.bind(this);
        this.noContentRenderer = this.noContentRenderer.bind(this);

        this.updateColumnSizes = this.updateColumnSizes.bind(this);
        this.handleGridScroll = this.handleGridScroll.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
        this.getColumnWidth = this.getColumnWidth.bind(this);

        this.selectRow = this.selectRow.bind(this);
        this.localSelectRow = this.localSelectRow.bind(this);
        this.localSelectAllRows = this.localSelectAllRows.bind(this);
        this.localSortColumn = this.localSortColumn.bind(this);
        this.localFilterColumn = this.localFilterColumn.bind(this);

        if (props.embedded) {
            this.props.fetchData({ parameters: props.forcedParameters });
        }

        if (props.onSelect && props.selectedRows) {
            props.onSelect({ data: this.getData(), selected: props.selectedRows });
        }
    }

    getData() {
        return this.state.sortedData || this.state.filteredData || this.state.data || this.props.data;
    }

    selectRow(key) {
        if (typeof this.props.selectRow == "function") {
            this.props.selectRow(key, this)
        } else {
            this.localSelectRow(key);
        }
    }

    localSelectRow(key) {
        this.state.selectedRows = Object.assign({}, this.state.selectedRows);
        this.state.selectedRows[key] = !this.state.selectedRows[key];
        this.state.selectedRows.length += (this.state.selectedRows[key] ? 1 : -1);
        if (this.props.onSelect) {
            this.props.onSelect({ data: this.getData(), selected: this.state.selectedRows })
        }
    }

    localSelectAllRows(selected) {
        this.state.selectedRows = Object.assign({}, this.state.selectedRows);
        let data = this.getData();
        for (let row of data) {
            this.state.selectedRows[row.key] = selected;
        }
        this.state.selectedRows.length = selected ? data.length : 0;
        if (this.props.onSelect) {
            this.props.onSelect({ data: this.getData(), selected: this.state.selectedRows })
        }
    }

    renderDefaultCell({ column, columnIndex, key, cell, row, rowIndex, style }) {
        if (cell && typeof cell == "object") {
            return cell.toString();
        }
        return cell;
    }

    renderSelectCell({ column, columnIndex, key, cell, row, rowIndex, style }) {
        let haveKey = typeof row.key != "undefined" && row.key != null;
        return <div className="npt-table-select-cell">
            <input type={column.radio ? "radio" : "checkbox"} disabled={!haveKey} checked={Boolean(this.state.selectedRows[row.key])} onClick={() => this.selectRow(row.key)}></input>
            {!haveKey && <div className="text-danger">
                <FormattedMessage
                    id="TABLE_NO_KEY"
                    defaultMessage="Doesn't have any key"
                    description="Row doesn't have any key to select" />
            </div>}
        </div>;
    }

    renderDateCell({ column, columnIndex, key, cell, row, rowIndex, style }) {
        if (!cell) {
            return "";
        }
        return moment(cell).format('L');
    }

    renderDateTimeCell({ column, columnIndex, key, cell, row, rowIndex, style }) {
        if (!cell) {
            return "";
        }
        const m = moment(cell);
        return m.format('L') + ' ' + m.format('LTS');
    }

    renderHTMLCell({ column, columnIndex, key, cell, row, rowIndex, style }) {
        return { __html: cell };
    }

    renderCurrencyCell({ column, columnIndex, key, cell, row, rowIndex, style }) {
        if (!column.currencyName) {
            return null;
        }

        if (typeof cell == 'string') {
            cell = parseFloat(cell);
        } else if (typeof cell != 'number') {
            return "";
        }

        if (!column.currencyCents) {
            cell = cell.toFixed(0);
            return cell + " " + column.currencyName;
        }

        cell = cell.toFixed(2);
        let parts = cell.split('.');
        return parts[0] + " " + column.currencyName + " " + parts[1] + " " + column.currencyCents;
    }

    renderListCell({ column, columnIndex, key, cell, row, rowIndex, style }) {
        if (!cell || typeof cell.join != "function") {
            return null;
        }
        return cell.join(", ");
    }

    renderFileRefCell({ column, columnIndex, key, cell, row, rowIndex, style }) {
        const file = cell;
        if (!file || !file.$sha1) {
            return "--";
        }
        const filename = file.$originalName || file.$label || file.$sha1;
        return (<a href={`${this.props.contextPath}rest/file/download/${file.$sha1}`} download={filename}><i className="fa fa-download" aria-hidden="true"></i></a>);
    }

    renderSubjectRefCell({ column, columnIndex, key, cell, row, rowIndex, style }) {
        if (Array.isArray(cell) && Array.isArray(row[column.subjectRef])) {
            const elements = [];
            for (let i = 0; i < cell.length; ++i) {
                if (i != 0) {
                    elements.push(<span> / </span>);
                }
                elements.push(<a href={`${this.props.contextPath}objectcard/${row[column.subjectRef][i]}`}>{cell[i]}</a>);
            }
            return elements;
        }
        return (<a href={`${this.props.contextPath}objectcard/${row[column.subjectRef]}`}>{cell}</a>);
    }

    getCellClass({ cell, row, columnIndex, rowIndex }) {
        return row.classes && row.classes[this.props.header[columnIndex].field] || "";
    }

    handleGridScroll({ clientHeight, clientWidth, scrollHeight, scrollLeft, scrollTop, scrollWidth }) {
        this.scrollLeft = scrollLeft;
        if (typeof this.props.onScroll == "function") {
            this.props.onScroll({ clientHeight, clientWidth, scrollHeight, scrollLeft, scrollTop, scrollWidth });
        }
        this.forceUpdate();
    }

    getColumnsWidth(props = this.props) {
        let widths = [];
        let usedWidth = 0;
        let autoColumns = 0;
        for (let column of props.header) {
            if (column.hidden) {
                widths.push(0);
                continue;
            }
            let width = parseInt(column.width);
            if (isNaN(width)) {
                widths.push(null);
                ++autoColumns;
                continue;
            }
            usedWidth += width;
            widths.push(width / this.actualWidth);
        }
        if (autoColumns != 0) {
            let availableWidth = 1 - usedWidth / this.actualWidth;
            for (let i = 0; i < widths.length; ++i) {
                if (widths[i] == null) {
                    widths[i] = availableWidth / autoColumns;
                }
            }
        } else if (this.widthMultiplier != 1) {
            for (let i = 0; i < widths.length; ++i) {
                widths[i] *= this.widthMultiplier;
            }
        }
        return widths;
    }

    getActualWidth(props = this.props) {
        this.widthMultiplier = 1;
        if (!props.header) {
            return props.width;
        }
        let width = 0;
        for (let column of props.header) {
            if (column.hidden) {
                continue;
            }
            width += parseInt(column.width) || minColumnWidth;
        }
        if (width < props.width) {
            this.widthMultiplier = props.width / width;
            return props.width;
        }
        return width;
    }

    getColumnWidth({ index }) {
        return this.columnsWidth[index] * this.actualWidth;
    }

    updateColumnSizes(columnsWidth, actualSizeChange) {
        if (actualSizeChange) {
            for (let i = 0; i < columnsWidth.length; ++i) {
                columnsWidth[i] = columnsWidth[i] * this.actualWidth;
            }
            this.actualWidth += actualSizeChange;
            for (let i = 0; i < columnsWidth.length; ++i) {
                columnsWidth[i] = columnsWidth[i] / this.actualWidth;
            }
        }
        this.columnsWidth = columnsWidth;
        this.grid.recomputeGridSize();
        this.forceUpdate();
    }

    cellRenderer({ columnIndex, key, rowIndex, style }) {
        const column = this.props.header[columnIndex];
        if (column.hidden) {
            return null;
        }
        const row = this.getData()[rowIndex];
        const cell = (row.bindedData && row.bindedData[column.field]) || row[column.field];

        let renderedCell = null;
        let innerHTML = null;
        if (column.dataFormat) {
            /* Back comatibility */
            renderedCell = column.dataFormat(cell, row);
        } else if (column.fileRef) {
            renderedCell = this.renderFileRefCell({ column, columnIndex, key, cell, row, rowIndex, style });
        } else if (column.subjectRef) {
            renderedCell = this.renderSubjectRefCell({ column, columnIndex, key, cell, row, rowIndex, style });
        } else {
            switch (column.format) {
                case "selectColumn":
                    renderedCell = this.renderSelectCell({ column, columnIndex, key, cell, row, rowIndex, style });
                    break;
                case "date":
                    renderedCell = this.renderDateCell({ column, columnIndex, key, cell, row, rowIndex, style });
                    break;
                case "dateTime":
                    renderedCell = this.renderDateTimeCell({ column, columnIndex, key, cell, row, rowIndex, style });
                    break;
                case "html":
                    innerHTML = this.renderHTMLCell({ column, columnIndex, key, cell, row, rowIndex, style });
                    break;
                case 'currency':
                    renderedCell = this.renderCurrencyCell({ column, columnIndex, key, cell, row, rowIndex, style });
                    break;
                case 'list':
                    renderedCell = this.renderListCell({ column, columnIndex, key, cell, row, rowIndex, style });
                    break;
                default:
                    renderedCell = this.renderDefaultCell({ column, columnIndex, key, cell, row, rowIndex, style });
                    break;
            }
        }

        let className = this.getCellClass({ cell, row, columnIndex, rowIndex });

        return (
            <div className={"npt-table-cell table-cell " + className} style={{ width: this.getColumnWidth({ index: columnIndex }) }} dangerouslySetInnerHTML={innerHTML}>
                {renderedCell}
            </div>
        );
    }

    rowRenderer({
        isScrolling, // The Grid is currently being scrolled
        isVisible,   // This cell is visible within the grid (eg it is not an overscanned cell)
        key,         // Unique key within array of cells
        parent,      // Reference to the parent Grid (instance)
        rowIndex,    // Vertical (row) index of cell
        style        // Style object to be applied to cell (to position it);
        // This must be passed through to the rendered cell element.
    }) {
        let row = this.getData()[rowIndex];
        return <div key={key} className={"npt-table-row" + (rowIndex % 2 == 0 ? " odd" : "")} style={style}>
            {row.loading && printLoading()}
            {row.error && printError(row.error)}
            {!row.loading && !row.error && this.props.header.map((column, columnIndex) => this.cellRenderer({ columnIndex, rowIndex }))}
        </div>
    }

    noContentRenderer() {
        if (this.props.loadingData || this.props.loadingPageData || this.props.loading) {
            /**On loading we will provide overflow loading alert */
            return <div className="npt-table-row odd" style={{ textAlign: "center", width: this.actualWidth, display: "block" }}>
                {/* {this.props.messages["NPT_TABLE_LOADING"]} */}
            </div>;
        }
        if (this.props.errorData || this.props.errorPageData || this.props.error) {
            return <div className="npt-table-row odd" style={{ textAlign: "center", width: this.actualWidth, display: "block" }}>
                {this.props.messages["NPT_TABLE_ERROR"]}
            </div>;
        }
        return <div className="npt-table-row odd" style={{ textAlign: "center", width: this.actualWidth, display: "block" }}>
            {this.props.messages["NPT_TABLE_NO_DATA"]}
        </div>;
    }

    localSortColumn(column) {
        let newState = sortTable(this.state, column);
        newState.sorting = newState.sorting || null;
        this.setState(newState);
    }

    localFilterColumn(column, filter) {
        let newState = filterTable(this.state, column, filter);
        this.setState(newState);
    }

    getTableColumns() {
        if (!this.props.header) {
            return null;
        }
        let filters = {};
        if (this.props.filterColumn) {
            filters = this.props.filters || filters;
        } else {
            filters = this.state.filters || filters;
        }
        return this.props.header.map((column, columnIndex) =>
            column.hidden ? null :
                <Header
                    collapseOnResize={this.props.collapseOnResize}
                    data={this.getData()}
                    filtered={Boolean(filters[column.field])}
                    first={columnIndex == 0}
                    last={columnIndex == this.props.header - 1}
                    column={column}
                    index={columnIndex}
                    sorting={this.state.sorting || this.props.sorting}
                    columnsWidth={this.columnsWidth}
                    actualWidth={this.actualWidth}
                    minColumnWidth={minColumnWidth}
                    getColumnWidth={this.getColumnWidth}
                    filterColumn={this.props.filterColumn || this.localFilterColumn}
                    sortColumn={this.props.sortColumn || this.localSortColumn}
                    selectedRows={this.state.selectedRows}
                    updateColumnSizes={this.updateColumnSizes}
                    selectAllRows={this.props.selectAllRows || this.localSelectAllRows}
                    openModal={this.props.openModal} />
        );
    }

    handleGridHover(reactEvent) {
        let gridContext = this;
        let hoverState = {
            [VERTICAL]: false,
            [HORIZONTAL]: false
        };
        let gridRect = gridContext.gridElement.getBoundingClientRect();
        let rightSide = gridRect.width + gridRect.x;
        if (rightSide - reactEvent.pageX < 10) {
            hoverState[VERTICAL] = true;
        }
        let bottomSide = gridRect.height + gridRect.y;
        if (bottomSide - reactEvent.pageY < 10) {
            hoverState[HORIZONTAL] = true;
        }
        if (gridContext.hoveredScrollers[VERTICAL] != hoverState[VERTICAL] || gridContext.hoveredScrollers[HORIZONTAL] != hoverState[HORIZONTAL]) {
            gridContext.hoveredScrollers = hoverState;
            gridContext.forceUpdate();
        }
    }

    setGridRef(ref) {
        this.grid = ref;
        this.gridElement = ReactDOM.findDOMNode(this.grid);
    }

    componentDidMount() {
        if (typeof this.props.initializeTableFields != "function") {
            return;
        }
        this.props.initializeTableFields(this.props.fields);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data != nextProps.data) {
            this.state.data = nextProps.data;
            if (!this.props.sortColumn || !this.props.filterColumn) {
                filterRows(this.state);
                sortRows(this.state);
            }
        }
        if (this.props.header != nextProps.header) {
            this.state.columns = nextProps.header;
        }
        if (this.props.width != nextProps.width && this.props.header == nextProps.header) {
            this.actualWidth = this.getActualWidth(nextProps);
            if (this.widthMultiplier != 1) {
                this.columnsWidth = this.getColumnsWidth(nextProps);
            }
        }
        if (this.props.header != nextProps.header) {
            this.actualWidth = this.getActualWidth(nextProps);
            this.columnsWidth = this.getColumnsWidth(nextProps);
        }
        if (this.props.selectedRows != nextProps.selectedRows) {
            this.state.selectedRows = nextProps.selectedRows;
            if (nextProps.onSelect) {
                nextProps.onSelect({ data: nextProps.data, selected: nextProps.selectedRows });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pageable != this.props.pageable) {
            $(window).trigger("resize.cimfiller");
        }
    }

    componentWillUnmount() {
        if (typeof this.props.resetTableFields != "function") {
            return;
        }
        this.props.resetTableFields();
    }

    /* Use one cell grid to wrap cells into rows and preserve horizontal scrolling */
    render() {
        if (this.props.loading) {
            return printLoading();
        }
        if (this.props.error) {
            return printError(this.props.error);
        }
        let gridCls = gridInitialCls;
        if (this.hoveredScrollers[VERTICAL]) {
            gridCls += " hoverVertical";
        }
        if (this.hoveredScrollers[HORIZONTAL]) {
            gridCls += " hoverHorizontal";
        }
        if (this.props.gant) {
            gridCls += " npt-table-have-gant";
        }
        const loading = this.props.loadingData || this.props.loadingPageData;
        return ([
            <div className="npt-table-header col">
                <div className="row" style={{ width: this.actualWidth, height: this.props.gant ? this.props.gant.headersHeight : null, position: "relative", left: -this.scrollLeft }}>
                    {this.getTableColumns()}
                </div>
            </div >,
            <CimFiller className="position-relative" onMouseMove={this.handleGridHover.bind(this)} updateDelay={15}>
                <LoadingOverflow loading={loading} />
                <Grid
                    className={gridCls}
                    data={this.getData()}
                    selectedRows={this.state.selectedRows || this.props.selectedRows}

                    scrollTop={this.props.scrollTop}

                    cellRenderer={this.rowRenderer}
                    noContentRenderer={this.noContentRenderer}

                    columnCount={1}
                    columnWidth={this.actualWidth}
                    rowCount={!loading && this.getData() ? this.getData().length : null}
                    rowHeight={this.props.rowHeight || DEFAULT_ROW_HEIGHT}
                    overscanRowCount={this.props.overscanRowCount || OVERSCAN_ROW_COUNT}

                    onScroll={this.handleGridScroll}
                    ref={this.setGridRef.bind(this)}>
                </Grid>
            </CimFiller>
        ]);
    }
}

const LoadingOverflow = React.memo((props) => {
    if (!props.loading) {
        return null;
    }
    return <div className="npt-table-overflow-panel npt-table-loading-panel">
        <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>
        <FormattedMessage id={"NPT_TABLE_LOADING"} />
    </div>
});

export default Table;
