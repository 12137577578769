import React from 'react';
import SetpointsTree from './setpointstree.jsx';
import Details from './details.jsx';
import Divider from '../divider/divider.jsx';
import { TARGET_TREE, SOURCE_TREE } from '../../constants/rpasetpoints';


const LEFT = 0;
const RIGHT = 1;

const MIN_WINDTH = 0.25;

class RPASetpoints extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            widths: {
                left: 0.5,
                right: 0.5
            }
        }

        this.grabDivider = this.grabDivider.bind(this);
        this.moveDivider = this.moveDivider.bind(this);
        this.releaseDivider = this.releaseDivider.bind(this);
    }

    getWidth(side) {
        let widths = this.state.grabbed || this.state.widths;
        return (this.props.width - 5) * (side == LEFT ? widths.left : widths.right);
    }

    grabDivider(reactEvent) {
        this.state.grabbed = {
            startX: reactEvent.pageX,
            currentX: reactEvent.pageX,
            left: this.state.widths.left,
            right: this.state.widths.right
        }
        this.forceUpdate();
    }

    moveDivider(reactEvent) {
        if (!this.state.grabbed) {
            return;
        }
        this.state.grabbed.currentX = reactEvent.pageX;
        let widthChange = (this.state.grabbed.currentX - this.state.grabbed.startX) / this.props.width;
        this.state.grabbed.left = this.state.widths.left + widthChange;
        if (this.state.grabbed.left < MIN_WINDTH) {
            this.state.grabbed.left = MIN_WINDTH
        } else if (this.state.grabbed.left > 1 - MIN_WINDTH) {
            this.state.grabbed.left = 1 - MIN_WINDTH
        }
        this.state.grabbed.right = 1 - this.state.grabbed.left;
        this.forceUpdate();
        $(window).trigger("resize.cimfiller.react");
    }

    releaseDivider(reactEvent) {
        if (!this.state.grabbed) {
            return;
        }
        this.state.widths.left = this.state.grabbed.left;
        this.state.widths.right = this.state.grabbed.right;
        this.state.grabbed = null;
        this.forceUpdate();
    }

    /* TODO: add printing of left side */
    printSide() {
        const selectedNode = this.props.source.active ? this.props.source.nodeById[this.props.source.active] : null;
        const primary = true;
        if (this.props.detailedView) {
            return <Details
                node={selectedNode}
                activeTab={this.props.activeTab}
                primary={primary}
                changeDetailsParameter={this.props.changeDetailsParameter}
                width={this.getWidth(RIGHT)}
                disabled={this.props.disabled}
            />;
        }
        return <SetpointsTree
            titleId={"RPA_SETPOINTS_COMMON_TEMPLATE_TREE"}
            locale={this.props.locale}
            messages={this.props.messages}
            filter={this.props.filter}
            roots={this.props.target.rootNodesIds}
            nodeById={this.props.target.nodeById}
            activeTab={this.props.activeTab}
            selectedNode={selectedNode}
            sourceId={TARGET_TREE}
            primary={primary}
            forcedWidth={this.getWidth(RIGHT)}
            changeFilter={this.props.changeFilter}
            objectcard={this.props.objectcard}
            detailedView={this.props.detailedView}
            showLinkedElement={this.props.showLinkedElement}
        />;
    }

    render() {
        return (
            <div className="rpasetpoints-container npt-fill-size flex-row" onMouseMove={this.moveDivider} onMouseUp={this.releaseDivider}>
                <SetpointsTree
                    titleId={"RPA_SETPOINTS_COMMON_CONFIG_TREE"}
                    locale={this.props.locale}
                    roots={this.props.source.rootNodesIds}
                    activeTab={this.props.activeTab}
                    selectedNode={this.props.source.active ? this.props.source.nodeById[this.props.source.active] : null}
                    sourceId={SOURCE_TREE}
                    primary={true}
                    forcedWidth={this.getWidth(LEFT)}
                    objectcard={this.props.objectcard}
                    detailedView={this.props.detailedView}
                    changeDetailsParameter={this.props.changeDetailsParameter}
                />
                <Divider withoutCollapsing={true} grabAction={this.grabDivider} />
                {this.printSide()}
            </div>);
    }
}

export default RPASetpoints;