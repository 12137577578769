import { FormattedMessage } from 'react-intl';

//Global tree state path
export const SRCEDITOR = "srceditor";

//Actions
export const WAIT_FOR_CODE = "@@SRCEDITOR_WAIT_FOR_CODE";
export const RECEIVE_CODE = "@@SRCEDITOR_RECEIVE_CODE";
export const RECEIVE_CODE_ERROR = "@@SRCEDITOR_RECEIVE_CODE_ERROR";
export const RECEIVE_HISTORY = "@@SRCEDITOR_RECEIVE_HISTORY";
export const RECEIVE_HISTORY_ERROR = "@@SRCEDITOR_RECEIVE_HISTORY_ERROR";
export const CHANGE_PARAM = "@@SRCEDITOR_CHANGE_PARAM";
export const EDIT_LOCK_RECEIVED = "@@SRCEDITOR_EDIT_LOCK_RECEIVED";


//STATUS
export const STATUS_EMPTY = "EMPTY";
export const STATUS_LOADING = "LOADING";
export const STATUS_READY = "READY";
export const STATUS_ERROR = "ERROR";

//////////////////////
//Formatted messages//
//////////////////////
export const SRC_EDITOR_LOCK_GET_FAIL = <FormattedMessage
    id="SRC_EDITOR_LOCK_GET_FAIL"
    defaultMessage='Failed to get edit lock'
    description="Failed to get edit lock" />

export const SRC_EDITOR_CREATE_CODE = <FormattedMessage
    id="SRC_EDITOR_CREATE_CODE"
    defaultMessage='Create code'
    description="Create code modal title" />

export const SRC_EDITOR_CONFIRM_CREATE_CODE = <FormattedMessage
    id="SRC_EDITOR_CONFIRM_CREATE_CODE"
    defaultMessage="Code wasn't found. Do you want create it?"
    description="Create code modal body" />

export const SRC_EDITOR_CODE_SAVE_SUCCESS = <FormattedMessage
    id="SRC_EDITOR_CODE_SAVE_SUCCESS"
    defaultMessage='Code at path "{path}" was saved successfully'
    description="Success alert on saving code." />

export const SRC_EDITOR_CODE_SAVE_ERROR = <FormattedMessage
    id="SRC_EDITOR_CODE_SAVE_ERROR"
    defaultMessage='Error occured while saving code at path "{path}"'
    description="Error alert on saving code." />

export const SRC_EDITOR_DIRECTORY_REMOVE_SUCCESS = <FormattedMessage
    id="SRC_EDITOR_DIRECTORY_REMOVE_SUCCESS"
    defaultMessage='Directory was successfully removed from path "{path}"'
    description="Success alert on removing directory." />

export const SRC_EDITOR_DIRECTORY_REMOVE_ERROR = <FormattedMessage
    id="SRC_EDITOR_DIRECTORY_REMOVE_ERROR"
    defaultMessage='Error occured while removing directory from path "{path}"'
    description="Error alert on removing directory." />

export const SRC_EDITOR_CODE_REMOVE_SUCCESS = <FormattedMessage
    id="SRC_EDITOR_CODE_REMOVE_SUCCESS"
    defaultMessage='Code was successfully removed from path "{path}"'
    description="Success alert on removing code." />

export const SRC_EDITOR_CODE_REMOVE_ERROR = <FormattedMessage
    id="SRC_EDITOR_CODE_REMOVE_ERROR"
    defaultMessage='Error occured while removing code from path "{path}"'
    description="Error alert on removing code." />

export const SRC_EDITOR_CONFIRM_ADD_CODE = <FormattedMessage
    id="SRC_EDITOR_CONFIRM_ADD_CODE"
    defaultMessage='Confirm adding code'
    description="Confirm popup text on saving code." />

export const SRC_EDITOR_CONFIRM_REMOVE_DIRECTORY = <FormattedMessage
    id="SRC_EDITOR_CONFIRM_REMOVE_DIRECTORY"
    defaultMessage='Confirm removing of directory with path "{path}"'
    description="Confirm popup text on removing directory." />

export const SRC_EDITOR_CONFIRM_REMOVE_CODE = <FormattedMessage
    id="SRC_EDITOR_CONFIRM_REMOVE_CODE"
    defaultMessage='Confirm removing of code with path "{path}"'
    description="Confirm popup text on removing code." />

export const SRC_EDITOR_CHANGE_CODE_METAINFO = <FormattedMessage
    id="SRC_EDITOR_CHANGE_CODE_METAINFO"
    defaultMessage='Change code metainfo'
    description="Confirm popup text on change metainfo of code." />

export const SRC_EDITOR_CODE_MOVE_SUCCESS = <FormattedMessage
    id="SRC_EDITOR_CODE_MOVE_SUCCESS"
    defaultMessage='Code was successfully moved'
    description="Success alert on code moving." />

export const SRC_EDITOR_CODE_MOVE_ERROR = <FormattedMessage
    id="SRC_EDITOR_CODE_MOVE_ERROR"
    defaultMessage='Error occured while code moving'
    description="Error alert on code moving." />

export const SRC_EDITOR_CODE_COPY_SUCCESS = <FormattedMessage
    id="SRC_EDITOR_CODE_COPY_SUCCESS"
    defaultMessage='Code was successfully copied'
    description="Success alert on code copy." />

export const SRC_EDITOR_CODE_COPY_ERROR = <FormattedMessage
    id="SRC_EDITOR_CODE_COPY_ERROR"
    defaultMessage='Error occured while code copying'
    description="Error alert on code copying." />

export const SRC_EDITOR_CODE_ALREADY_EXIST = <FormattedMessage
    id="SRC_EDITOR_CODE_ALREADY_EXIST"
    defaultMessage='Code by path "{path}" already exist'
    description="Error alert on code rename." />