const ATTR_PLUGIN = "cim-plugin";

import Plugin from './components/plugin/plugin.jsx';

if (typeof window.__ui_plugins__ == 'undefined') {
    //Fake initialization
    window.__ui_plugins__ = {};
    console.error("No plugin information found!")
}

/**
 * Global instance of module registry
 */
 const modulesRegistry = {
    modules: {},
    resolvers: {},
    rejectors: {}
}

/**
 * Function which implements dynamic import of modules (views, cards, etc.)
 * 
 * @param path to module
 */
function _import(path) {
    //Check if we have already requested this module
    if (typeof modulesRegistry.modules[path] != 'undefined') {
        return modulesRegistry.modules[path];
    }
    //Create new promise
    const promise = new Promise(function (resolve, reject) {
        modulesRegistry.resolvers[path] = resolve;
        modulesRegistry.rejectors[path] = reject;

        //Append script
        const script = document.createElement("script");
        script.src = path;
        script.onerror = () => {
            reject(`Fail to load module ${path}`)
        }
        document.body.appendChild(script);

    });
    //Add to registry
    modulesRegistry.modules[path] = promise;
    return promise;
}

function _resolve(path, module) {
    //Path is string
    if (typeof path == 'string') {
        const r = modulesRegistry.resolvers[path];
        if (!r) {
            //Module was already resolved or was not requested
            return;
        }
        //Resolve module 
        r(module);
        return;
    }

    //Path is regular expresion!
    const regexp = path;
    for (let p in modulesRegistry.resolvers) {
        if (regexp.test(p)) {
            console.log(`Resolved path from regular expression: ${p}`)
            const r = modulesRegistry.resolvers[p];
            r(module);
            break;
        }
    }
}

if (typeof window.__NPT__ == 'undefined') {
    window.__NPT__ = {};
}

//Libraries required by plugins
window.__NPT__.React = window.React;
window.__NPT__.ReactDOM = window.ReactDOM;
window.__NPT__.ReactRedux = window.ReactRedux;

//Export plugin API to other plugins
window.__NPT__.Components = {
    Plugin: Plugin
}

//Import module (fetch from server)
window.__NPT__.import = _import;

//Resolve module (used in module javascript file)
window.__NPT__.resolve = _resolve;

//Initialization function
export default function (dispatcher) {

    //Register components
    dispatcher.registerComponent(ATTR_PLUGIN, (elm) => {
       const pluginId = $(elm).attr(ATTR_PLUGIN);
       let plugin = pluginId;
       let view = 'index';
       const idx = pluginId.indexOf('/');
       if (idx > 0) {
           plugin = pluginId.substr(0, idx);
           view = pluginId.substr(idx + 1);
       }
       let options = {}
       const optionsValue = $(elm).attr("data-options");
       if (typeof optionsValue == 'string') {
           try {
               let jsOptions = optionsValue.trim();
               if (!jsOptions.startsWith("{")) {
                   jsOptions = "{" + jsOptions + "}"
               }
               options = eval("(" + jsOptions + ")");
           } catch (error) {
               console.error(error);
           }
       }
       return (<Plugin plugin={plugin} view={view} options={options}/>);
    });

};

