import React from 'react';
import { FormattedMessage } from 'react-intl';
import CimFiller from '../../filler/cimfiller.jsx';
import TabContainer from '../tab/container.jsx';

class CardArea extends React.Component {

    constructor(props) {
        super(props);

        this.fetchedChildren = false;
    }

    fetchChildren() {
        if (!this.fetchedChildren) {
            this.props.fetchChildren(this.props.classCard.data.id);
            this.fetchedChildren = true;
        }
    }

    changeTab(tab) {
        if (tab == "children") {
            this.fetchChildren();
        }
        this.props.deselectAllRows()
        this.props.changeTab(tab);
    }

    checkActive(tab) {
        if (this.props.classCard.tab == tab) {
            return " in active";
        }
        return "";
    }

    componentDidMount() {
        /* Bootstrap 4 hack */
        window.setTimeout(() => {
            $(this.defaultTab).trigger("click");
        }, 100)
    }

    render() {
        return (
            <div className="cim-filler-flex flex-column overflow-auto">
                <ul className="col nav nav-tabs">
                    <li className="nav-item">
                        <a className={"nav-link" + this.checkActive("predicateList")} ref={(ref) => this.defaultTab = ref} data-toggle="tab" href="#predicateList" onClick={() => this.changeTab("predicateList")}>
                            <FormattedMessage
                                id="CLASSCARD_TAB_PREDICATE_LIST"
                                defaultMessage="Predicate list"
                                description="Name of tab that contains list of class predicates." />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link" + this.checkActive("relations")} data-toggle="tab" href="#relations" onClick={() => this.changeTab("relations")}>
                            <FormattedMessage
                                id="CLASSCARD_TAB_RELAIONS"
                                defaultMessage="Relations"
                                description="Name of tab that contains info about relations of current class with others." />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link" + this.checkActive("children")} data-toggle="tab" href="#children" onClick={() => this.changeTab("children")}>
                            <FormattedMessage
                                id="CLASSCARD_TAB_CHILDREN"
                                defaultMessage="Children"
                                description="Name of tab that contains info about children of current class." />
                        </a>
                    </li>
                    {this.props.classCard.data && this.props.classCard.data.enumerationInfo &&
                        <li className="nav-item">
                            <a className={"nav-link" + this.checkActive("enumeration")} data-toggle="tab" href="#enumeration" onClick={() => this.changeTab("enumeration")}>
                                <FormattedMessage
                                    id="CLASSCARD_TAB_ENUMERATION"
                                    defaultMessage="Enumeration"
                                    description="Name of tab that contains info about enumeration children of current class." />
                            </a>
                        </li>}
                </ul>
                <TabContainer {...this.props} />
            </div>
        );
    }
}

export default CardArea;