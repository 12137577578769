import React from 'react';
import debounce from '../../services/debounce';
import { getSizes, getPadding } from '../../services/filler'

function getInt(value) {
    let number = parseInt(value);
    return isNaN(number) ? 0 : number;
}

function renderChildren(props, sizes, style) {
    let padding = getPadding(style);
    let width = sizes.width - padding[1] - padding[3];
    let height = sizes.height - padding[0] - padding[2];
    return React.Children.map(props.children, child => {
        if (!child) {
            return null;
        }
        let childProps = {}
        /* Check if user manual setted sizes of inner element */
        if (!child.props.forcedHeight) {
            childProps.height = height;
        }
        if (!child.props.forcedWidth) {
            childProps.width = width;
        }
        /* Forwarding data for finder container */
        if (props.data) {
            childProps.data = props.data;
        }
        return React.cloneElement(child, childProps)
    })
}

function isEqualSizes(prevSizes, sizes) {
    if (!prevSizes) {
        return !sizes;
    } else if (!sizes) {
        return false;
    }
    if (prevSizes.width != sizes.width) {
        return false;
    }
    if (prevSizes.height != sizes.height) {
        return false;
    }
    return true;
}

class CimFiller extends React.Component {

    constructor(props) {
        super(props);
        this.sizes = {
            width: 0,
            height: 0
        };
        this.prevSizes = {
            width: 0,
            height: 0
        };
    }

    componentDidMount() {
        this.filler = ReactDOM.findDOMNode(this);
        this.parent = this.filler.parentNode;
        this.horizontal = false;
        if (this.props && this.props.horizontal) {
            this.horizontal = true;
        }

        let filler = this.filler;
        let parent = this.parent;
        let horizontal = this.horizontal;
        let _this = this;

        if (!this.props.static) {
            this.handler = debounce(function () {
                /* Update element and setup correct height */
                _this.forceUpdate();
            }, this.props.updateDelay || 100);
            $(window).on("resize.cimfiller.react", this.handler);
        }
        /* Update element and setup correct height */
        this.forceUpdate();
    }

    componentWillUnmount() {
        if (!this.props.static) {
            $(window).off("resize.cimfiller.react", this.handler);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.callback && !isEqualSizes(this.prevSizes, this.sizes)) {
            this.prevSizes = Object.assign(this.prevSizes, this.sizes);
            this.props.callback(this.sizes);
        }
    }

    render() {
        const className = `cim-filler ${this.props.className ? this.props.className : ""}`;
        if (!this.parent) {
            return <div className={className}>
                {this.props.children}
            </div>;
        }
        this.sizes = getSizes({
            filler: this.filler,
            parent: this.parent,
            horizontal: this.horizontal,
            style: this.props.style,
            forcedWidth: this.props.forcedWidth,
            forcedHeight: this.props.forcedHeight
        });
        if (this.props.style) {
            this.sizes = Object.assign(this.sizes, this.props.style);
        }
        return (
            <div {...this.props} className={className} style={this.sizes}>
                {renderChildren(this.props, this.sizes, this.props.style)}
            </div>
        );
    }
}

export default CimFiller;