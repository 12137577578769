import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EVENT_ADD_NEW_SUBJECT, SAVE_STATE_SHOW_ERRORS } from '../../constants/objectcard';
import ListenerComponent from './listener.jsx';
import Toolbar from './toolbar.jsx';
import UploadStatus from './uploadstatus.jsx';
import SaveStatus from './savestatus.jsx';
import Form from './form.jsx';
import {
    SAVE_STATE_START,
    SAVE_STATE_WAIT_SERVER,
    SAVE_STATE_UPLOADS_READY
} from '../../constants/objectcard';
import shortid from 'shortid';
import { connectCardListener } from '../../services/objectcard';

const Listener = connectCardListener(ListenerComponent);

class ObjectCard extends React.Component {

    constructor(props) {
        super(props);
        this.addNewSubjectEvent = this.addNewSubjectEvent.bind(this);
        this.edit = this.edit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
    }


    edit() {
        this.props.lockSubject();
    }

    cancel() {
        if (this.props.saveState && this.props.saveState != SAVE_STATE_SHOW_ERRORS) { //Save is in progress
            this.props.cancelSave(); //Try to stop save progress
            return; //But now we cannot do anything more so return false
        }
        if (this.closeOnReady) {
            window.close();
            return;
        }
        if (this.props.isNew) {
            if (typeof this.props.object == 'string') { //Fetch selected object
                this.props.fetchSubject(this.props.object, this.props.namespace);
            } else { //Nothing to do but destroy the store
                this.props.destroyStore();
            }
        } else {
            this.props.unlockSubject();
        }

    }

    save() {
        this.props.startSave();
    }

    showSaveChages(callback) {
        const name = this.props.data.$label || "";
        const options = {
            title: { id: "OBJECTCARD_SAVE_CHANGES_TITLE" },
            body: { id: "OBJECTCARD_SAVE_CHANGES_BODY", values: { name } }
        }
        const _this = this;
        this.props.openModal("confirm", options, function () { //okCallback
            console.log("showSaveChages: OK");
            _this.afterEdit = callback;
            _this.save();
        }, function () { //cancelCallback
            console.log("showSaveChages: cancel");
            if (_this.props.data.$isNew) { //Nothing to do!
                _this.afterEdit = null; //Clean previous after edit callback
                callback();
            } else {
                _this.afterEdit = callback;
                _this.cancel();
            }
        });
    }

    addNewSubjectEvent(event, info) { //normally called from trees
        console.log("addNewSubjectEvent", info);
        if (this.props.editable) {
            const _this = this;
            this.showSaveChages(function () {
                console.log("Add new subject after save or cancel");
                _this.props.addNewSubject(info.data, info.notifyId);
            });
        } else {
            this.props.addNewSubject(info.data, info.notifyId);
        }
    }

    componentDidMount() {
        if (this.props.options) {
            if (this.props.options.className) {
                this.props.createNewSubject(
                    this.props.options.className,
                    this.props.options.parent,
                    this.props.options.parentRef,
                    this.props.options._prototype);
                this.closeOnReady = this.props.options.closeOnReady;
            } else {
                this.props.fetchSubject(this.props.options.rdfId, this.props.options.prefix);
                this.closeOnReady = this.props.options.closeOnReady;
            }
        } else {
            if (typeof this.props.object == 'string') { //Initial object to be loaded
                this.props.fetchSubject(this.props.object, this.props.namespace);
            }
            //Subscribe to add new subjects
            $(document).bind(EVENT_ADD_NEW_SUBJECT, this.addNewSubjectEvent);
        }
    }

    componentWillUnmount() {
        $(document).unbind(EVENT_ADD_NEW_SUBJECT, this.addNewSubjectEvent);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.saveState != nextProps.saveState) { //Check if save is ready
            if (!nextProps.saveState) { //Save was canceled or terminated
                if (!nextProps.error && this.props.saveState == SAVE_STATE_WAIT_SERVER) { //Subject was saved OK
                    if (this.closeOnReady) {
                        window.close();
                    }
                }
            } else if (nextProps.saveState == SAVE_STATE_UPLOADS_READY) { //Call save if uploads are ready
                this.props.saveSubject();
            }
        }

        if (this.props.fixed) { //nothing to do if object is fixed
            return;
        }

        //Some actions may be bound to transition from edit=true to edit=false
        const prevEditable = this.props.editable;
        const editable = nextProps.editable;
        if (prevEditable && !editable) {
            if (this.afterEdit) { //Was setup in showSaveChages()
                this.afterEdit();
                this.afterEdit = null;
            }
        }

        //Monitor selected objects
        if (this.props.object != nextProps.object || this.props.namespace != nextProps.namespace) {
            if (typeof nextProps.object == 'string') {
                //Object was in editing status. Ask to save
                if (this.props.editable) {
                    let _object = nextProps.object;
                    let _namespace = nextProps.namespace;
                    let _fetchSubject = this.props.fetchSubject;
                    this.showSaveChages(function () { //Wil be called on ready
                        console.log("***Fetch after save or cancel");
                        _fetchSubject(_object, _namespace);
                    });
                } else {
                    this.props.fetchSubject(nextProps.object, nextProps.namespace);
                }
            }
        } else if (this.props.empty && typeof nextProps.object == 'string') { //Refetch subject
            console.log("***Fetch from empty state");
            this.props.fetchSubject(nextProps.object, nextProps.namespace);
        }
    }

    getListener() {
        return (<Listener id={this.props.id} />);
    }

    getToolbar() {
        return (<Toolbar
            {...this.props}
            save={this.save}
            edit={this.edit}
            cancel={this.cancel} />);
    }

    getUploadStatus() {
        const style = {};
        if (this.props.saveState != SAVE_STATE_START) {
            style.display = "none";
        }
        return (<div className="row" style={style}>
            <div className="col-md-12"><UploadStatus {...this.props} /></div>
        </div>);
    }

    getSaveStatus() {
        const style = {};
        if (this.props.saveState != SAVE_STATE_SHOW_ERRORS) {
            style.display = "none";
        }
        return (<div className="row" style={style}>
            <div className="col-md-12"><SaveStatus saveState={this.props.saveState} errorInfo={this.props.errorInfo} /></div>
        </div>);
    }

    getForm() {
        return (<Form {...this.props} cardAreaStyle="card-scollable" />);
    }

    render() {
        if (this.props.empty) {
            return (
                <div className="alert alert-info" role="alert">
                    <FormattedMessage
                        id="OBJECTCARD_SELECT_OBJECT"
                        defaultMessage="Select object to be shown in the card"
                        description="User should select to be shown in the card" />
                </div>);
        }
        return (<div className="cim-objectcard-container" onScroll={() => { $('.datepicker').hide() }}>
            {this.getListener()}
            {this.getToolbar()}
            {this.getUploadStatus()}
            {this.getSaveStatus()}
            {this.getForm()}
        </div>);

    }
}

export default ObjectCard;