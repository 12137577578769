function parseAclString(stringAcl) {
    return stringAcl.replace(/ /g, "").split(",");
}

export function parseGeneralAccessRules(rules) {
    const ruleList = rules.split(',');
    const generalAccessRules = {};
    for (const _rule of ruleList) {
        const rule = $.trim(_rule);
        if (!rule) {
            continue;
        }
        generalAccessRules[rule] = true;
    }
    return generalAccessRules;
}

export function aclChecker(generalAccessRules) {
    let checker = {};
    checker.generalAccessRules = generalAccessRules;

    checker.updateAcl = (function () {
        switch (typeof this.generalAccessRules) {
            case "string":
                this.acl = parseAclString(this.generalAccessRules);
                break;
            case "object":
                if (this.generalAccessRules.length) {
                    this.acl = this.generalAccessRules;
                    break;
                }
            default:
                this.acl = [];
                break;
        }
    }).bind(checker);

    checker.updateAcl();

    checker.checkAccess = (function (acl) {
        for (let ace of acl) {
            if (this.generalAccessRules[ace]) {
                return true;
            }
        }
        return false;
    }).bind(checker);

    checker.checkAcl = (function (btn) {
        if (btn.acl) {
            let acl = parseAclString(btn.acl);
            if (!this.checkAccess(acl)) {
                return false;
            }
        }
        return true;
    }).bind(checker);

    checker.checkGroupAcl = (function (group) {
        group = group.slice();
        for (let i = group.length - 1; i >= 0; --i) {
            let item = group[i];
            if (!this.checkAcl(item)) {
                group.splice(i, 1);
            }
        }
        return group;
    }).bind(checker);

    checker.setGeneralAccessRules = (function (generalAccessRules) {
        this.generalAccessRules = generalAccessRules;
        this.updateAcl();
    }).bind(checker);

    return checker;
}