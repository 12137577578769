import Button from './button.jsx';
import { FormattedMessage } from 'react-intl';
import { MSG_BUTTON_ADD, MSG_BUTTON_DELETE } from '../messages.jsx';

class Toolbar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className={"npt-table-toolbar"}>
            <form className={"form-inline col-md-12 nopadding"}>
                <div className="btn-group btn-group-xs">
                    <Button icon='plus' onClick={this.props.callback.addRow}>{MSG_BUTTON_ADD}</Button>
                    <Button icon='minus' disabled={this.props.selectedRows.length == 0} onClick={this.props.callback.deleteRow}>{MSG_BUTTON_DELETE}</Button>
                </div>
            </form>
        </div>;
    }
}

export default Toolbar;


