import React from 'react';
import { FormattedMessage } from 'react-intl';
import Record from './record.jsx';
import moment from 'moment';

class TableHistory extends React.Component {

    constructor(props) {
        super(props);
    }

    getPredicate(triple) {
        if (!triple.predicate) {
            return "-";
        }
        return triple.predicate.label;
    }

    getRowDescription(row) {
        if (row.operation == 0) {
            return (<FormattedMessage
                id="SUBJECT_HISTORY_TABLE_OPER_ADD"
                defaultMessage="Row (ID: {id}) was added into the table: {table}"
                description="Row was added"
                values={{ id: row.peerSubjectId, table: row.predicate.label }} />);
        }
        if (row.operation == 2) {
            return (<FormattedMessage
                id="SUBJECT_HISTORY_TABLE_OPER_REMOVE"
                defaultMessage="Row (ID: {id}) was removed from the table: {table}"
                description="Row was removed"
                values={{ id: row.peerSubjectId, table: row.predicate.label }} />);
        }
        return (<FormattedMessage
            id="SUBJECT_HISTORY_TABLE_OPER_UPDATE"
            defaultMessage="Row (ID: {id}) was updated in the table: {table}"
            description="Row was updated"
            values={{ id: row.peerSubjectId, table: row.predicate.label }} />);
    }

    getRowLink(row) {
        if (!row.peerSubject) {
            return this.getRowDescription(row);
        }
        const subLink = row.peerSubject.$rdfId;
        const contextPath = this.props.contextPath;
        let namespace;
        if (row.peerSubject.$namespace) {
            namespace = row.peerSubject.$namespace + "/";
        }
        return (<span>
            {this.getRowDescription(row)}
            <span> | </span>
            <a href={`${contextPath}objectcard/${namespace + subLink}`}><i className="fa fa-eye" aria-hidden="true"></i></a>
            <span> | </span>
            <a href={`${contextPath}history/${namespace + subLink}`}><i className="fa fa-history" aria-hidden="true"></i></a>
        </span>);

    }

    getRowHistory(row) {
        if (!row.peerSubjectHistory) {
            return this.getRowLink(row);
        }
        return (<Record data={row.peerSubjectHistory} contextPath={this.props.contextPath} header={this.getRowLink(row)} />);
    }

    render() {
        return (
            <div>
                <h4><FormattedMessage
                    id="SUBJECT_HISTORY_TABLE_HEADER"
                    defaultMessage="Table changes:"
                    description="Table changes table header" />
                </h4>
                {this.props.data.map((row) => this.getRowHistory(row))}
            </div>
        );
    }
}

export default TableHistory;


