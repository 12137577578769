import React from 'react';
import Select from 'react-select';
import MaskedInput from '../../srceditor/maskedinput.jsx';

import { MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../../messages.jsx';
import {
    CLASSCARD_NOT_EMPTY_FIELD,
    CLASSCARD_NAMESPACE,
    CLASSCARD_IDENTIFIER,
    CLASSCARD_LABEL,
    CLASSCARD_DESCRIPTION,
    NAME_SYMBOLS,
    NAME_FIRST_SYMBOL,
    LABEL_SYMBOLS,
    LABEL_FIRST_SYMBOL
} from '../../../constants/classcard';
import { sortByAttr } from '../../../services/classcard';

const fullSize = { width: "100%", hetght: "100%" };

class PackagePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.namespaceList = sortByAttr(props.namespaceList, "prefix");

        if (props.packageData) {
            this.state.name = props.packageData.name;
            this.state.label = props.packageData.label;
            this.state.description = props.packageData.description;
            this.state.namespace = props.namespaceMap[props.packageData.namespaceId];
            this.state.id = props.packageData.id;
        } else {
            this.state.namespace = props.namespaceList[0];
        }

        this.validName = this.validateName(this.state.name);
        this.validLabel = this.validateLabel(this.state.label);

        this.maskedInputChangeHandler = this.maskedInputChangeHandler.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.changeNamespaceHandler = this.changeNamespaceHandler.bind(this);
    }

    validateName(newValue) {
        return !!newValue;
    }

    validateLabel(newValue) {
        return !!newValue;
    }

    maskedInputChangeHandler(variable, newValue) {
        if (this.state[variable] == newValue) {
            return;
        }
        let newState = Object.assign({}, this.state);
        newState[variable] = newValue;
        switch (variable) {
            case "name":
                this.validName = this.validateName(newValue);
                break;
            case "label":
                this.validLabel = this.validateLabel(newValue);
                break;
        }
        this.setState(newState);
    }

    inputChangeHandler(variable, reactEvent, event) {
        let newValue = reactEvent.nativeEvent.target.value;
        this.maskedInputChangeHandler(variable, newValue);
    }

    changeNamespaceHandler(newValue) {
        if (this.state.namespace.id == newValue.id) {
            return;
        }
        this.setState(Object.assign({}, this.state, { namespace: this.props.namespaceMap[newValue.id] }));
    }

    render() {
        return (<div style={fullSize} className="form-horizontal">
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="classId" className="control-label pull-right">{CLASSCARD_NAMESPACE}</label>
                </div>
                <div className="col-sm-8">
                    <Select
                        name="namespace"
                        loadingPlaceholder={MSG_SELECT_LOADING}
                        placeholder={MSG_SELECT_PLACEHOLDER}
                        noResultsText={MSG_SELECT_NO_RESULTS}
                        value={this.state.namespace.id}
                        valueKey={"id"}
                        backspaceRemoves={false}
                        clearable={false}
                        options={this.namespaceList}
                        labelKey={"prefix"}
                        onChange={this.changeNamespaceHandler}
                    />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="packageName" className="control-label pull-right">{CLASSCARD_IDENTIFIER}</label>
                </div>
                <div className={"col-sm-8"}>
                    <MaskedInput
                        className={"form-control" + (this.validName ? "" : " is-invalid")}
                        id={"packageName"}
                        value={this.state.name}
                        onChange={this.maskedInputChangeHandler.bind(this, "name")}
                        allowedSymbols={NAME_SYMBOLS}
                        firstSymbol={NAME_FIRST_SYMBOL}
                    />
                    {this.validName ? null : <div className="invalid-feedback text-danger">{CLASSCARD_NOT_EMPTY_FIELD}</div>}
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="classLabel" className="control-label pull-right">{CLASSCARD_LABEL}</label>
                </div>
                <div className={"col-sm-8"}>
                    <MaskedInput
                        className={"form-control" + (this.validLabel ? "" : " is-invalid")}
                        id={"packageLabel"}
                        value={this.state.label}
                        onChange={this.maskedInputChangeHandler.bind(this, "label")}
                        allowedSymbols={LABEL_SYMBOLS}
                        firstSymbol={LABEL_FIRST_SYMBOL}
                    />
                    {this.validLabel ? null : <div className="invalid-feedback text-danger">{CLASSCARD_NOT_EMPTY_FIELD}</div>}
                </div>
            </div>
            <div className="row form-group">
                <div className="col-sm-4">
                    <label for="classLabel" className="control-label pull-right">{CLASSCARD_DESCRIPTION}</label>
                </div>
                <div className="col-sm-8">
                    <textarea type="text" id="description" className="form-control" value={this.state.description} onChange={this.inputChangeHandler.bind(this, "description")} rows="3" />
                </div>
            </div>
        </div>);
    }
}

export default PackagePopup;


