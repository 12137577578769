import React from 'react';
import PropTypes from 'prop-types';
import Basic from './basic.jsx';
import ObservedSelect from '../../select/observedselect.jsx';
import { MSG_SELECT_LOADING, MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS } from '../../messages.jsx';
import { ADD_BUTTON_ICON_STYLE, REMOVE_BUTTON_ICON_STYLE } from './style.jsx';
import { retrieveFunction } from '../../../services/automation.js';
import { makeForm } from '../../../actions/objectcard.js';

function generateIdentifier(node) {
    if (!node || typeof node != "object") {
        return node;
    }
    let id = node.$rdfId;
    if (node.$namespace) {
        let prefix;
        switch (typeof node.$namespace) {
            case "string":
                prefix = node.$namespace;
                break;
            case "object":
                prefix = node.$namespace.$prefix;
                break;
        }
        id = prefix + ":" + id;
    }
    return id;
}

/**
 * Main combobox element with common functions
 * API:{
 *   allowPartialSelection?: boolean
 *   node: {
 *     id: string
 *     multiple: boolean
 *     options?: {
 *       showBtn: boolean
 *     }
 *   }
 *   enumerationCls: {
 *     error: boolean
 *     loading: boolean
 *     enumerationInfo: {
 *       children: {
 *         data: {
 *           $rdfId: string
 *         }
 *       }[]
 *     }
 *   }
 *   automation: {
 *     enumerationBindings: {
 *       [nodeId: strnig] : string
 *     }
 *   }
 *   values: {
 *     [k: string] : any
 *   }
 *   data: any (objectcard)
 *   values?: string
 *   hideLabel?: boolean
 *   link: () => void
 *   remove: () => void
 * }
 */
class ComboBox extends Basic {

    constructor(props) {
        super(props);

        this.state = {
            automationFilter: this.getEnumeraionFilter()
        }
    }

    getlabel() {
        try {
            return this.props.node.label;
        } catch (e) {
            return "";
        }
    }

    getDescription() {
        try {
            return this.props.node.description;
        } catch (e) {
            return "";
        }
    }

    isError() {
        try {
            return this.props.enumerationCls.error;
        } catch (e) {
            return false;
        }
    }

    isLoading() {
        try {
            return this.props.enumerationCls.loading;
        } catch (e) {
            return false;
        }
    }

    isEmpty() {
        try {
            return !this.props.enumerationCls.error
                && !this.props.enumerationCls.loading
                && this.props.enumerationCls.enumerationInfo.children.length == 0;
        } catch (e) {
            return true;
        }
    }

    isEditable() {
        return this.props.editable;
    }

    isMultiline() {
        try {
            return this.props.node.multiple;
        } catch (e) {
            return false;
        }
    }

    isPartialSelectionAllowed() {
        return this.props.allowPartialSelection;
    }

    getEnumerationInfo() {
        return this.props.enumerationCls.enumerationInfo;
    }

    /* Hide enumeration items via automation */
    getEnumeraionFilter(props = this.props) {
        try {
            const funcId = props.layout.automation.enumerationBindings[props.node.id];
            if (!funcId) {
                return null;
            }
            const func = retrieveFunction(funcId);
            const form = makeForm(props.values, props.data);
            try {
                return func.bind(this, form);
            } catch (ex) {
                console.log("Enumeration binding error", props.node.id, ex);
            }
        } catch (ex) {
            return null;
        }
        return null;
    }

    updateEnumerationFilter(props = this.props) {
        this.state.automationFilter = this.getEnumeraionFilter(props);
    }

    printError() {
        return this.printEmpty();
    }

    printLoading() {
        return this.printEmpty();
    }

    printEmpty() {
        return <SingleComboBox
            dynamicEnumeration={this.props.dynamicEnumeration}
            select={() => { }}
            link={() => { }}
            enumerationInfo={null}
            loading={this.isLoading()}
            error={this.isError()}
            empty={true}
        />;
    }

    printSingle() {
        return <SingleComboBox
            dynamicEnumeration={this.props.dynamicEnumeration}
            select={this.props.select}
            link={this.props.link}
            enumerationInfo={this.getEnumerationInfo()}
            error={this.isError()}
            loading={this.isLoading()}
            empty={this.isEmpty()}
            editable={this.isEditable()}
            allowPartialSelection={this.isPartialSelectionAllowed()}
            value={generateIdentifier(this.props.value)}
            automationFilter={this.state.automationFilter}
        />;
    }

    printMultiline() {
        return <MultilineComboBox
            dynamicEnumeration={this.props.dynamicEnumeration}
            select={this.props.select}
            link={this.props.link}
            remove={this.props.remove}
            enumerationInfo={this.getEnumerationInfo()}
            error={this.isError()}
            loading={this.isLoading()}
            empty={this.isEmpty()}
            editable={this.isEditable()}
            allowPartialSelection={this.isPartialSelectionAllowed()}
            values={this.props.value}
            automationFilter={this.state.automationFilter}
            showAddBtn={this.props.node && this.props.node.options && this.props.node.options.showBtn}
        />
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.node != nextProps.node || this.props.automation != nextProps.automation) {
            this.updateEnumerationFilter(nextProps);
        }
    }

    render() {
        const label = this.getlabel();
        const description = this.getDescription();
        let input = null;
        if (this.isLoading()) {
            input = this.printLoading();
        } else if (this.isError()) {
            input = this.printError();
        } else if (this.isEmpty()) {
            input = this.printEmpty();
        } else if (this.isMultiline()) {
            input = this.printMultiline();
        } else {
            input = this.printSingle();
        }
        if (this.props.hideLabel) {
            return <div className={"col-md-12 nopadding"}>
                {input}
            </div>
        }
        return this.wrapInput(label, input, description);
    }
}
ComboBox.propTypes = {
    visible: PropTypes.bool.isRequired,
    link: PropTypes.func.isRequired,
    enumerationCls: PropTypes.object,
    remove: PropTypes.func,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    empty: PropTypes.bool,
    editable: PropTypes.bool,
    allowPartialSelection: PropTypes.bool,
    hideLabel: PropTypes.bool,
    node: PropTypes.any,
    value: PropTypes.any,
    values: PropTypes.any,
    data: PropTypes.any,
    layout: PropTypes.any
};


/**
 * Single combobox / row of comboboxes
 */
class SingleComboBox extends React.Component {

    constructor(props) {
        super(props);

        const defaultValue = this.getDefaultValue();

        this.state = {
            optionsByIndex: [],
            optionsByValue: {},
            parentByValue: {
                [defaultValue]: defaultValue
            },
            nodeByValue: {
                [defaultValue]: {}
            },
            lastLevelsMap: {},
            selectedValues: [defaultValue]
        }

        if (this.isEnumerationReady(props)) {
            this.normalizeEnumeration(props.enumerationInfo, props.automationFilter);
            this.setupRoot();
            this.forceUpdate();
        }

        if (props.value) {
            this.selectValue(props.value);
        }

        if (props.editable && this.isEnumerationReady(props) && props.value) {
            this.changeInvalidValue(props);
        }

        this.changeHandler = this.changeHandler.bind(this);
    }

    isError() {
        return this.props.error;
    }

    isLoading() {
        return this.props.loading;
    }

    isEmpty() {
        return this.props.empty;
    }

    isEditable() {
        return this.props.editable;
    }

    isPartialSelectionAllowed() {
        return this.props.allowPartialSelection;
    }

    isEnumerationReady(props = this.props) {
        return props.enumerationInfo && typeof props.enumerationInfo == "object";
    }

    isLastLevel(value) {
        return Boolean(this.state.lastLevelsMap[value]);
    }

    isLastPrint(boxIndex) {
        return this.getValues().length == boxIndex + 1;
    }

    getDefaultValue() {
        return "";
    }

    getOptionsByIdx(boxIndex) {
        return this.state.optionsByIndex[boxIndex] || [];
    }

    getOptionsByValue(value) {
        return this.state.optionsByValue[value] || [];
    }

    getValues(state = this.state) {
        return state.selectedValues;
    }

    getSelectedValue(state = this.state) {
        for (let i = state.selectedValues.length - 1; i >= 0; --i) {
            if (state.selectedValues[i] != this.getDefaultValue()) {
                return state.selectedValues[i];
            }
        }
        return "";
    }

    getChildrenRdfIdMap(children) {
        let list = {};
        if (!Array.isArray(children)) {
            return list;
        }
        for (let child of children) {
            if (!child.data) {
                continue;
            }
            list[child.data.$rdfId] = true;
        }
        return list;
    }

    resetEnumState() {
        this.state.lastLevelsMap = {};
    }

    normalizeEnumeration(enumerationInfo, automationFilter, parentValue = this.getDefaultValue()) {
        if (!enumerationInfo.children || !Array.isArray(enumerationInfo.children)) {
            return;
        }
        const options = [{ value: "", label: "--" }];
        const childrenRdfIdList = this.getChildrenRdfIdMap(enumerationInfo.children);
        const filterMap = automationFilter && automationFilter(childrenRdfIdList);
        for (let child of enumerationInfo.children) {
            if (!child.data) {
                console.error("Enumeration child doesn't have data:", child);
                continue;
            }
            const node = child.data;
            if (filterMap && !filterMap[node.$rdfId]) {
                continue;
            }
            const value = generateIdentifier(node);
            const label = node.$label;
            this.state.nodeByValue[value] = node;
            this.state.parentByValue[value] = parentValue;
            options.push({ value: value, label: label });

            const isLast = child.lastLevel || child.children == null || typeof child.children == "undefined";
            if (isLast) {
                this.state.lastLevelsMap[value] = true;
            } else {
                this.normalizeEnumeration(child, automationFilter, value);
            }
        }
        this.state.optionsByValue[parentValue] = options.sort((a, b) => {
            if (a.label > b.label) {
                return 1;
            }
            if (a.label < b.label) {
                return -1;
            }
            return 0;
        });
    }

    setupRoot(isDynamicEnum) {
        if (!isDynamicEnum) {
            this.state.selectedValues = [this.getDefaultValue()];
        }
        this.state.optionsByIndex[0] = this.state.optionsByValue[this.getDefaultValue()];
    }

    compareValues(prevValue, value) {
        return prevValue == value || ($.isEmptyObject(prevValue) && $.isEmptyObject(value));
    }

    shouldUpdateValue(value, depth) {
        /* Last level selected */
        if (this.isLastLevel(value)) {
            return true;
        }
        /* Empty value selected */
        if (depth == 0 && value == this.getDefaultValue()) {
            return true;
        }
        /* Partial selection allowed */
        if (this.isPartialSelectionAllowed()) {
            return true;
        }
        return false;
    }

    changeValue(value, depth) {
        const newState = Object.assign({}, this.state);
        const selectedValues = this.getValues(this.state).slice(0, depth);
        const optionsByIndex = newState.optionsByIndex.slice(0, depth);
        selectedValues[depth] = value;
        optionsByIndex[depth] = this.getOptionsByValue(selectedValues[depth - 1] || this.state.parentByValue[value]);
        if (value != this.getDefaultValue() && !this.isLastLevel(value)) {
            selectedValues[depth + 1] = this.getDefaultValue();
            optionsByIndex[depth + 1] = this.getOptionsByValue(value);
        }
        newState.selectedValues = selectedValues;
        newState.optionsByIndex = optionsByIndex;

        if (!this.shouldUpdateValue(value, depth)) {
            if (typeof this.props.select === "function") {
                this.props.select(value);
            }
            this.setState(newState);
            return;
        }

        value = this.getSelectedValue(newState);
        const prevValue = this.getSelectedValue(this.state);
        if (!this.compareValues(value, prevValue)) {
            this.props.link(this.state.nodeByValue[value]);
        }
        this.setState(newState);
    }

    /* Modifies state! Be aware! */
    selectValue(value) {
        if (typeof value == "undefined") {
            return;
        }
        value = value || this.getDefaultValue();
        if (!this.state.nodeByValue[value]) {
            console.error(`Can't find enumeration node with value "${value}"`);
            if (value != this.getDefaultValue()) {
                this.selectValue(this.getDefaultValue());
            }
            return;
        }
        const currentValue = this.getSelectedValue();
        if (this.compareValues(currentValue, value)) {
            return;
        }
        const selectedValues = [];
        const optionsByIndex = [];
        do {
            selectedValues.push(value);

            value = this.state.parentByValue[value];
            if (typeof value == "undefined") {
                console.error(`Can't find parent enumeration node for value "${value}"`);
                return;
            }
            optionsByIndex.push(this.getOptionsByValue(value));
        } while (value != this.getDefaultValue())
        this.state.selectedValues = selectedValues.reverse();
        this.state.optionsByIndex = optionsByIndex.reverse();
    }

    getSelectionDepth() {
        return this.state.selectedValues.length;
    }

    filterOptions(initialOptions, filterString, values) {
        if (!filterString) {
            return initialOptions;
        }
        try {
            filterString = filterString.toLowerCase();
            return initialOptions.filter((option) => option.label.toString().toLowerCase().indexOf(filterString) >= 0);
        } catch (e) {
            console.log("Error while combobox filtering:", e);
            return initialOptions;
        }
    }

    /* Setup selected value to selection comboBox */
    changeHandler(depth, event) {
        let value;
        switch (typeof event) {
            case "undefined":
                return;
            case "object": //Html select
                value = event.target.value;
                break;
            default: //React select
                value = event;
                break;
        }
        this.changeValue(value, depth);
    }

    /* Prints comboBox element and add separator (if needed) */
    printComboBox(boxValue, boxIndex, className = "col-md-12") {
        let isLast = this.isLastPrint(boxIndex);
        let compositeClassName = className + " cim-combobox-element";
        if (isLast) {
            compositeClassName += " cim-combobox-element-last";
        }
        if (this.isError()) {
            compositeClassName += " form-control is-invalid";
        }
        return <div key={boxIndex} className={compositeClassName}>
            {!isLast && <span className="fa fa-caret-right cim-combobox-element-separator">&nbsp;</span>}
            {this.printSelect(boxValue, boxIndex)}
        </div>
    }

    printSelect(boxValue, boxIndex) {
        /* TODO: React-select doesn't have error handler */
        return <ObservedSelect
            searchable="true"
            loadingPlaceholder={MSG_SELECT_LOADING}
            placeholder={MSG_SELECT_PLACEHOLDER}
            noResultsText={MSG_SELECT_NO_RESULTS}
            simpleValue={true}
            value={boxValue}
            onChange={this.changeHandler.bind(this, boxIndex)}
            isLoading={this.isLoading()}
            disabled={!this.isEditable()}
            clearable={false}
            filterOptions={this.filterOptions.bind(this)}
            className="minified-react-select card-input"
            options={this.getOptionsByIdx(boxIndex)}></ObservedSelect>
    }

    printEmpty() {
        return this.printSelect(this.getDefaultValue(), 0);
    }

    changeInvalidValue(props = this.props) {
        if (!props.editable) {
            return;
        }
        if (typeof props.value == "undefined"
            || !this.isEnumerationReady(props)
            || props.value == this.getDefaultValue()
            || typeof this.state.nodeByValue[props.value] != "undefined") {
            return;
        }
        this.props.link(this.state.nodeByValue[this.getDefaultValue()]);
    }

    componentWillReceiveProps(nextProps) {
        const enumChanged = this.isEnumerationReady(nextProps)
            && (this.props.enumerationInfo != nextProps.enumerationInfo || this.props.automationFilter != nextProps.automationFilter);
        if (enumChanged) {
            this.resetEnumState();
            this.normalizeEnumeration(nextProps.enumerationInfo, nextProps.automationFilter);
            this.setupRoot(this.props.dynamicEnumeration);
        }

        if (this.props.value != nextProps.value
            || (this.props.editable != nextProps.editable && !nextProps.editable)) {
            this.selectValue(nextProps.value);
        }

        /**On enum change we must redo change of value after enum update to get correct children for last node */
        if (enumChanged) {
            const valueDepth = this.state.selectedValues.length - 1;
            const value = this.state.selectedValues[valueDepth];
            this.changeValue(value, valueDepth);
        }

        if (typeof nextProps.value != "undefined" && nextProps.editable && this.isEnumerationReady(nextProps)
            && (!this.props.editable || !this.isEnumerationReady(this.props))) {
            this.changeInvalidValue(nextProps);
        }
    }

    render() {
        if (this.isEmpty() || this.isLoading() || this.isError()) {
            return this.printEmpty();
        }
        let className;
        switch (this.getSelectionDepth()) {
            case 1:
                className = "col-md-12";
                break;
            case 2:
                className = "col-md-6";
                break;
            default:
                className = "col-md-4";
                break;
        }
        return <div className={"col-md-12 cim-combobox-row" + (this.isEditable() ? " cim-combobox-editable" : "")}>
            {this.getValues().map((value, index) => this.printComboBox(value, index, className))}
        </div>;
    }
}
SingleComboBox.propTypes = {
    link: PropTypes.func.isRequired,
    enumerationInfo: PropTypes.object.isRequired,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    empty: PropTypes.bool,
    editable: PropTypes.bool,
    allowPartialSelection: PropTypes.bool,
    value: PropTypes.string,
    automationFilter: PropTypes.function
};



/**
 * Miltiline combobox / row of comboboxes
 */
class MultilineComboBox extends React.Component {

    filteredEnumerationInfo;

    constructor(props) {
        super(props);

        this.state = {
            addValue: this.getDefaultValue()
        }

        this.filteredEnumerationInfo = this.filterEnumerationInfo(props.enumerationInfo, this.getValuesMap(props.values));

        this.changeAddValue = this.changeAddValue.bind(this);
        this.addHandler = this.addHandler.bind(this);
        this.removeHandler = this.removeHandler.bind(this);
    }

    isError() {
        return this.props.error;
    }

    isLoading() {
        return this.props.loading;
    }

    isEmpty() {
        return this.props.empty
            || (this.getValues().length == 0 && !this.isEditable());
    }

    isEditable() {
        return this.props.editable;
    }

    filterEnumerationInfo(enumerationInfo, valuesMap) {
        const info = Object.assign({}, enumerationInfo);
        if (info.data) {
            const id = generateIdentifier(info.data);
            if (id && valuesMap[id]) {
                return null;
            }
        }
        if (Array.isArray(info.children)) {
            const children = [];
            for (let child of info.children) {
                const filteredChild = this.filterEnumerationInfo(child, valuesMap);
                if (filteredChild == null) {
                    continue;
                }
                children.push(filteredChild);
            }
            if (children.length == 0) {
                return null;
            }
            info.children = children;
        }
        return info;
    }

    getValues() {
        if (!this.props.values) {
            return [];
        }
        if (Array.isArray(this.props.values)) {
            return this.props.values;
        }
        return [this.props.values];
    }

    getValuesMap(values) {
        const valuesMap = {};
        if (!values) {
            return valuesMap;
        }
        if (!Array.isArray(values)) {
            const id = generateIdentifier(values);
            valuesMap[id] = true;
            return valuesMap;
        }
        for (let value of values) {
            const id = generateIdentifier(value);
            valuesMap[id] = true;
        }
        return valuesMap;
    }

    getDefaultValue() {
        return "";
    }

    changeAddValue(value) {
        this.state.addValue = value;
        this.forceUpdate();
        if (this.props.showAddBtn || value == this.getDefaultValue()) {
            if (typeof this.props.select === "function") {
                this.props.select(value);
            }
            return;
        }
        this.addHandler();
    }

    getAddValue() {
        if ($.isEmptyObject(this.state.addValue)) {
            return this.getDefaultValue();
        }
        return this.state.addValue;
    }

    addHandler(event) {
        let addValue = this.getAddValue();
        if (addValue == this.getDefaultValue()) {
            return;
        }
        this.props.link(addValue);
    }

    removeHandler(rowIndex, event) {
        this.props.remove(rowIndex);
    }

    /* Prints comboBox element and add editing button (if needed) */
    printAddingComboBox() {
        if (!this.filteredEnumerationInfo) {
            return null;
        }
        return (<ComboboxWrapper adding={true} showAddBtn={this.props.showAddBtn} editable={this.isEditable()} link={this.addHandler}>
            <SingleComboBox
                dynamicEnumeration={this.props.dynamicEnumeration}
                select={this.props.select}
                link={this.changeAddValue}
                enumerationInfo={this.filteredEnumerationInfo}
                error={this.isError()}
                loading={this.isLoading()}
                empty={false}
                editable={true}
                allowPartialSelection={false}
                value={generateIdentifier(this.state.addValue)}
                automationFilter={this.props.automationFilter}
            />
        </ComboboxWrapper>);
    }

    /* Prints comboBox element and add editing button (if needed) */
    printExistComboBox(value, rowIndex) {
        return (<ComboboxWrapper adding={false} editable={this.isEditable()} link={this.removeHandler.bind(this, rowIndex)}>
            <SingleComboBox
                dynamicEnumeration={this.props.dynamicEnumeration}
                link={() => { }}
                enumerationInfo={this.props.enumerationInfo}
                error={false}
                loading={false}
                empty={false}
                editable={false}
                allowPartialSelection={false}
                value={generateIdentifier(value)}
                automationFilter={this.props.automationFilter}
            />
        </ComboboxWrapper>);
    }

    printEmpty() {
        return <div className={"col-md-12 flex-row without-btn" + (this.isEditable() ? " cim-combobox-editable" : "")}>
            {this.printExistComboBox(this.getDefaultValue(), 0)}
        </div>;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.editable != nextProps.editable && !nextProps.editable) {
            this.changeAddValue(this.getDefaultValue());
        }
        if (this.props.values != nextProps.values || this.props.enumerationInfo != nextProps.enumerationInfo) {
            this.filteredEnumerationInfo = this.filterEnumerationInfo(nextProps.enumerationInfo, this.getValuesMap(nextProps.values));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.values != prevProps.values) {
            this.changeAddValue(this.getDefaultValue());
            this.forceUpdate();
        }
    }

    render() {
        if (this.isEmpty()) {
            return this.printEmpty();
        }
        const editable = this.isEditable();
        let className = "col-md-12 flex-row p-0";
        if (editable) {
            className += " cim-combobox-editable";
        }
        return <div className={className}>
            {editable && this.printAddingComboBox()}
            {this.getValues().map((value, index) => this.printExistComboBox(value, index))}
        </div>
    }
}
MultilineComboBox.propTypes = {
    link: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    enumerationInfo: PropTypes.object.isRequired,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    empty: PropTypes.bool,
    editable: PropTypes.bool,
    allowPartialSelection: PropTypes.bool,
    showAddBtn: PropTypes.bool,
    values: PropTypes.string.isArray,
    automationFilter: PropTypes.function
};


/**
 * Wraper combobox component for adding/removing comboboxes
 */
class ComboboxWrapper extends React.Component {

    constructor(props) {
        super(props);
    }

    isEditable() {
        return this.props.editable;
    }

    isHaveButton() {
        if (this.props.adding && !this.props.showAddBtn) {
            return false;
        }
        return true;
    }

    printButton() {
        if (!this.isEditable()) {
            return null;
        }
        let button;
        if (this.props.adding) {
            /* Flag setted to true and showAddBtn option is true -> wrap element with adding button */
            button = (<i className="fa fa-plus-circle fa-fw" style={ADD_BUTTON_ICON_STYLE} aria-hidden="true" onClick={this.props.link}></i>);
        } else {
            /* Flag setted to false -> wrap element with delete button */
            button = (<i className="fa fa-minus-circle fa-fw" style={REMOVE_BUTTON_ICON_STYLE} aria-hidden="true" onClick={this.props.link}></i>);
        }
        return <div className="cim-combobox-button">
            <span>{button}</span>
        </div>;
    }

    render() {
        let className = "col-md-12 cim-combobox-multiple cim-combobox-row";
        const haveButton = this.isHaveButton();
        if (!haveButton) {
            className += " without-btn"
        }
        return <div className={className}>
            {this.props.children}
            {haveButton && this.printButton()}
        </div>
    }
}
ComboboxWrapper.propTypes = {
    link: PropTypes.func.isRequired,
    adding: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    showAddBtn: PropTypes.bool
};

export default ComboBox;