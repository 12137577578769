import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Criterion from './criterion.jsx';
import { buttonsStyle } from '../../constants/finder';

export default class Criteria extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let criteriaGroupId = this.props.id;
        let criteriaIdList = this.props.finder.criteriaGroup.byId[criteriaGroupId].criteriaList;
        if (criteriaIdList.length == 0) {
            return <div><FormattedMessage
                id="FINDER_NO_CRITERION"
                defaultMessage="No criterion"
                description="No criterion" /></div>
        }

        let criteriaList = [];
        for (let criteriaId of criteriaIdList) {
            criteriaList.push(this.props.finder.criteria.byId[criteriaId]);
        }
        return <div className='cim-finder-table-row-group'>
            {criteriaList.map((criteria, index) =>
                <Criterion
                    key={index}
                    criteria={criteria}
                    {...this.props}
                >
                </Criterion>)}
        </div>;
    }
}
