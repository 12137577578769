import React from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import CardArea from './cardarea.jsx';
import { getTabId } from './tabheader.jsx';

class Tab extends CardArea {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const selfRef = ReactDOM.findDOMNode(this);
        if (this.props.activeTab == this.props.nodeId) {
            $(selfRef).attr("class", "tab-pane fade active show");
        }
    }

    render() {
        const id = getTabId(this.props.formId, this.props.nodeId);
        const style = this.props.visible && !this.props.node.options.hidden ? null : { display: "none" };
        return (
            <div id={id} className="tab-pane fade" style={style}>
                <div className="container-fluid">
                    {this.getChildren()}
                </div>
            </div>);
    }
}

export default Tab;