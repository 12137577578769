
let initialState = {
    timeDifference: 0,
    versionInfo: null
}

/***************
 *   Utility   *
 ***************/

export function setupInitialState(state) {
    initialState = Object.assign({}, initialState, state);
}

/****************
 *   Reducers   *
 ****************/

export default (state = initialState, action) => {
    switch (action.type) {
        default: return state;
    }
}