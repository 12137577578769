import * as Actions from '../constants/location';

///////////////////////
//Parameters listener//
///////////////////////
function getHashPath(path) {
    path = $.trim(path);
    if (path.length == 0) {
        return "/";
    }
    if (!path.startsWith("/")) {
        path = "/" + path;
    }
    return path;
}

export function getHashData() {
    let data = { path: "/", params: {} };
    if (!window.location.hash || !window.location.hash.startsWith("#")) {
        return data;
    }
    let hash = window.location.hash.substring(1); //Remove starting #
    let idx = hash.indexOf('?');
    if (idx < 0) {
        data.path = getHashPath(hash);
        return data;
    }
    data.path = getHashPath(hash.substring(0, idx));
    hash = $.trim(hash.substring(idx + 1));
    if (hash.indexOf('=') < 0) {
        return data;
    }
    $.each(hash.split('&'), function (j, v) {
        let param = v.split('=');
        if (param.length == 2) {
            let key = decodeURIComponent($.trim(param[0]));
            let value = decodeURIComponent($.trim(param[1]));
            data.params[key] = value;
        }
    })
    return data;
}

function buildHash(data) {
    let hash = `#${data.path}`;
    if ($.isEmptyObject(data.params)) {
        return hash;
    }
    hash += "?" + $.param(data.params);
    return hash;
}

export function changeHash(dataChanges) {
    let data = getHashData();
    if (typeof dataChanges.path != 'undefined') {
        data.path = dataChanges.path;
    }
    if (typeof dataChanges.params != 'undefined') {
        data.params = Object.assign({}, data.params, dataChanges.params);
        for (let param in data.params) {
            if (data.params[param] === null || data.params[param] === "") {
                delete (data.params[param]);
            }
        }
    }
    $(window).trigger("hash.willChange", data);
    window.location.hash = buildHash(data);
}

export function hashChanged() {
    return {
        type: Actions.HASH_CHANGED,
        payload: getHashData()
    };
}

export function contextPathChanged(contextPath) {
    return {
        type: Actions.CONTEXT_PATH_CHANGED,
        payload: { contextPath }
    };
}