import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import TripleHistory from './triple.jsx';
import FileHistory from './file.jsx';
import FragmentHistory from './fragment.jsx';
import RelationHistory from './relation.jsx';
import TableHistory from './table.jsx';

function isTableRelation(rh) {
    let relationTypeInfo = rh.predicate.classRelationInfo.relationTypeInfo.toLowerCase();
    return relationTypeInfo == "composition";
}

class Record extends React.Component {

    constructor(props) {
        super(props);
    }

    getHeader() {
        if (this.props.header) {
            return this.props.header;
        }
        let time = moment(this.props.data.operationTimestamp).format('LLLL');
        let user = (<FormattedMessage
            id="SUBJECT_HISTORY_SYSTEM"
            defaultMessage="SYSTEM"
            description="System user" />);
        if (this.props.data.userId != null) {
            if (this.props.data.user != null) {
                user = this.props.data.user.$label;
            } else {
                user = (<FormattedMessage
                    id="SUBJECT_HISTORY_REMOVED_USER"
                    defaultMessage="User was removed"
                    description="Inform that user was removed" />);
            }
        }
        let values = {
            time: time,
            user: user,
            label: this.props.data.label
        }
        return (<FormattedMessage
            id="SUBJECT_HISTORY_RECORD_HEADER"
            defaultMessage="{time} | User: {user} | Label at the specified time: {label}"
            description="Header of one subject history event" values={values} />)
    }

    getTripleHistory() {
        if (this.props.data.tripleHistories == null || this.props.data.tripleHistories.length == 0) {
            return null;
        }
        return (<TripleHistory data={this.props.data.tripleHistories} />);
    }

    getFileHistory() {
        if (this.props.data.fileHistories == null || this.props.data.fileHistories.length == 0) {
            return null;
        }
        return (<FileHistory data={this.props.data.fileHistories} contextPath={this.props.contextPath} />);
    }

    getFragmentHistory() {
        if (this.props.data.fragmentHistories == null || this.props.data.fragmentHistories.length == 0) {
            return null;
        }
        return (<FragmentHistory data={this.props.data.fragmentHistories} contextPath={this.props.contextPath} />);
    }

    getRelationHistory() {
        if (this.props.data.relationHistoryRows == null || this.props.data.relationHistoryRows.length == 0) {
            return null;
        }
        return (<RelationHistory data={this.props.data.relationHistoryRows} contextPath={this.props.contextPath} timestamp={this.props.data.operationTimestamp} />);
    }

    getTableHistory() {
        if (this.props.data.tableRelationRows == null || this.props.data.tableRelationRows.length == 0) {
            return null;
        }
        return (<TableHistory data={this.props.data.tableRelationRows} contextPath={this.props.contextPath} />);
    }

    render() {
        let tripleHistory = this.getTripleHistory();
        let tableHistory = this.getTableHistory();
        let relationHistory = this.getRelationHistory();
        let fileHistory = this.getFileHistory();
        let fragmentHistory = this.getFragmentHistory();
        let haveBody = tripleHistory || tableHistory || relationHistory || fileHistory || fragmentHistory;
        if (!this.props.enum && !haveBody) {
            return null;
        }
        return (
            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">{this.getHeader()}</div>
                        {haveBody ?
                            <div className="card-body">
                                {tripleHistory}
                                {tableHistory}
                                {relationHistory}
                                {fileHistory}
                                {fragmentHistory}
                            </div>
                            :
                            null}
                    </div>
                </div>
            </div>
        );
    }
}

export default Record;


