import React from 'react';
import Select from 'react-select';

import { MSG_SELECT_PLACEHOLDER, MSG_SELECT_NO_RESULTS, MSG_SELECT_LOADING } from '../../messages.jsx';
import { CLASSCARD_VIEW_RELATION } from '../../../constants/classcard';

const selectStyle = { width: "200px" };
const titleStyle = { paddingRight: "5px", paddingTop: "5px", margin: "0px" };

class RelationPopupTitle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            relationType: props.relation.classRelationInfo.relationType
        };
    }

    render() {
        return (
            <div>
                <div className="pull-left">
                    <h3 style={titleStyle}>{CLASSCARD_VIEW_RELATION}:</h3>
                </div>
                <div className="pull-left" style={selectStyle}>
                    <Select
                        name="relationType"
                        loadingPlaceholder={MSG_SELECT_LOADING}
                        placeholder={MSG_SELECT_PLACEHOLDER}
                        noResultsText={MSG_SELECT_NO_RESULTS}
                        value={this.state.relationType}
                        backspaceRemoves={false}
                        clearable={false}
                        searchable={false}
                        disabled={true}
                        options={this.props.relationTypeList}
                        labelKey={"relation"}
                    />
                </div >
            </div >);
    }
}

export default RelationPopupTitle;


